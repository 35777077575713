import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./App.css";
import "./rbc.css";

const TimelineCalender = () => {
  const localizer = momentLocalizer(moment);

  const [tasks, setTask] = useState([
    {
      start: new Date(2023, 3, 15, 10, 0, 0),
      end: new Date(2023, 3, 15, 11, 0, 0),
      title: "Meeting",
    },
    {
      start: new Date(2023, 3, 15, 14, 0, 0),
      end: new Date(2023, 3, 15, 15, 0, 0),
      title: "Lunch",
    },
    {
      start: new Date(2023, 3, 15, 9, 0, 0),
      end: new Date(2023, 3, 15, 10, 0, 0),
      title: "Whateverasdasdq fvsdf wervcxvd",
    },
  ]);

  const truncatedTasks = tasks.map((task) => ({
    ...task,
    title: task.title.slice(0, 13) + (task.title.length > 13 ? "..." : ""),
  }));

  useEffect(() => {
    console.log(tasks);
  });
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Calendar
        localizer={localizer}
        events={truncatedTasks}
        views={"day"}
        view={"day"}
        step={60}
        showMultiDayTimes
        toolbar={false}
        defaultDate={new Date()}
        formats={{
          timeGutterFormat: "HH:mm",
        }}
      />
    </div>
  );
};

export default TimelineCalender;
