import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ReactComponent as MyIcon } from "./icons/menu_1.svg";
import { ReactComponent as MyIcon1 } from "./icons/menu_2.svg";
import { ReactComponent as MyIcon2 } from "./icons/menu_3.svg";
import { ReactComponent as MyIcon3 } from "./icons/menu_4.svg";
import { ReactComponent as MyIcon4 } from "./icons/menu_5.svg";
import { ReactComponent as MyIcon5 } from "./icons/menu_6.svg";
import { ReactComponent as FolderIcon } from "./icons/folder.svg";
import Cookies from "js-cookie";
import Modal from "react-modal";
import "./App.css";
import { ChromePicker } from "react-color";
import { useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/CheckCircleOutline";
import CheckedIcon from "@mui/icons-material/CheckCircle";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-mention/dist/quill.mention.min.css";
import Select from "@mui/material/Select";
import { ListItemText, ListItemIcon } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { TimePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import CrossIcon from "@mui/icons-material/Clear";
import { nanoid } from "nanoid";
import Calendar from "short-react-calendar";
import TimelineCalender from "./TimelineCalendar";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PreviewSkeleton from "./PreviewSkeleton";
import { TwitterPicker } from "react-color";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import LanIcon from "@mui/icons-material/Lan";
import GroupsIcon from "@mui/icons-material/Groups";
import GroupIcon from "@mui/icons-material/Group";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import WorkIcon from "@mui/icons-material/Work";
import HubIcon from "@mui/icons-material/Hub";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import TerminalIcon from "@mui/icons-material/Terminal";
import PaymentIcon from "@mui/icons-material/Payment";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import HandshakeIcon from "@mui/icons-material/Handshake";
import HandymanIcon from "@mui/icons-material/Handyman";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import EmailIcon from "@mui/icons-material/Email";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import DescriptionIcon from "@mui/icons-material/Description";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import BusinessIcon from "@mui/icons-material/Business";
import CallIcon from "@mui/icons-material/Call";
import CalculateIcon from "@mui/icons-material/Calculate";
import CookieIcon from "@mui/icons-material/Cookie";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import HeadsetIcon from "@mui/icons-material/Headset";
import Tour from "reactour";

const DashContent = (props) => {
  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      axios
        .get("/api/user-data", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const { name, is_admin, menu_items, profile_photo } = response.data;
          setName(name);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  const [isTourCompleted, setIsTourCompleted] = useState(
    Cookies.get("tourCompleted") === "true"
  );
  const handleTourClose = () => {
    Cookies.set("tourCompleted", "true", { expires: 365 });
    setIsTourCompleted(true);
  };
  const token = Cookies.get("token");
  const today = new Date();

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const dayOfWeek = days[today.getDay()];

  const day = today.getDate();

  const month = today.toLocaleString("default", { month: "long" });

  const [projectColor, setProjectColor] = useState(props.primaryColor);
  const [primaryColor, setPrimaryColor] = useState(props.primaryColor);

  const steps = [
    {
      selector: ".first-step",
      content: (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            style={{ color: primaryColor, fontSize: "22px", fontWeight: "600" }}
          >
            Welcome{" "}
          </div>
          <div style={{ color: "black", fontSize: "12px" }}>
            to your new dashboard.
          </div>
        </div>
      ),
    },
    {
      selector: ".second-step",
      content: (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            style={{ color: primaryColor, fontSize: "22px", fontWeight: "600" }}
          >
            Navigation{" "}
          </div>
          <div style={{ color: "black", fontSize: "12px" }}>
            All your available pages will always be listed in this section.
          </div>
        </div>
      ),
    },
    {
      selector: ".third-step",
      content: (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            style={{ color: primaryColor, fontSize: "22px", fontWeight: "600" }}
          >
            Invite team{" "}
          </div>
          <div style={{ color: "black", fontSize: "12px" }}>
            To invite your team, click here.
          </div>
        </div>
      ),
    },
    {
      selector: ".forth-step",
      content: (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            style={{ color: primaryColor, fontSize: "22px", fontWeight: "600" }}
          >
            Projects{" "}
          </div>
          <div style={{ color: "black", fontSize: "12px" }}>
            Let's start by creating your first project by clicking here.
          </div>
        </div>
      ),
    },
    {
      selector: ".fifth-step",
      content: (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            style={{ color: primaryColor, fontSize: "22px", fontWeight: "600" }}
          >
            Projects{" "}
          </div>
          <div style={{ color: "black", fontSize: "12px" }}>
            Your Recently created projects will appear here, likewise you can
            also use the navigation to head to your project page.
          </div>
        </div>
      ),
    },
    {
      selector: ".sixth-step",
      content: (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            style={{ color: primaryColor, fontSize: "22px", fontWeight: "600" }}
          >
            Task{" "}
          </div>
          <div style={{ color: "black", fontSize: "12px" }}>
            Your latest task will always be displayed here in the to-do list.
          </div>
        </div>
      ),
    },
    // ...
  ];

  useEffect(() => {
    setPrimaryColor(props.primaryColor);
  }, [props.primaryColor]);
  useEffect(() => {
    fetchCompanyDetails();
  }, []);

  const fetchCompanyDetails = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/company-details",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    console.log(data.company_logo);

    if (data.company_color) {
      setPrimaryColor(data.company_color);
    }
  };
  const dateString = `${dayOfWeek}, ${month} ${day}`;

  const [name, setName] = useState("user");

  const [userProjects, setUserProjects] = useState(props.userProjects);

  useEffect(() => {
    console.log("userprojects changed:", props.userProjects);
  }, [props.userProjects]);

  const [userTask, setUserTask] = useState([]);
  const [taskTitle, setTaskTitle] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [projectTitle, setProjectTitle] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [taskDueDate, setTaskDueDate] = useState("");
  const [taskDueTime, setTaskDueTime] = useState("");
  const [taskAssignedTo, setTaskAssignedTo] = useState("");
  const [projectAssignedTo, setProjectAssignedTo] = useState("");
  const [projectType, setProjectType] = useState("");
  const [availableUsers, setAvailableUsers] = useState([]);
  const [taskFormModalIsOpen, setTaskFormModalIsOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [projectSelectedUsers, setProjectSelectedUsers] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [projectFormModalIsOpen, setProjectFormModalIsOpen] = useState(false);

  const [existingIds, setExistingIds] = useState();

  const [selectIcon, setSelectIcon] = useState(false);
  const [projectIconSelected, setProjectIconSelected] = useState("Project1");
  const handleTaskButtonClick = async (e) => {
    e.preventDefault();
    const token = Cookies.get("token");

    const newId = nanoid(12);
    while (existingIds.includes(newId)) {
      newId = nanoid(12);
    }

    const newTask = {
      id: newId,
      task_type: "Default",
      task_assigned_to: selectedUsers,
      task_project_id: selectedProjects?.[0]?.toString() ?? "", // convert to string
      task_title: taskTitle,
      task_description: taskDescription,
      task_due_date: taskDueDate
        ? moment(taskDueDate).add(-2, "day").toISOString().substr(0, 10)
        : null, // add 1 day to date and extract date only
      task_due_time: taskDueTime
        ? taskDueTime.toISOString().substr(11, 5)
        : null, // extract time
      task_is_complete: false,
      task_checkbox: false,
    };

    try {
      const response = await fetch("https://ww1.premelo.com/api/add-task", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newTask),
      });

      if (response.ok) {
        console.log("Task added successfully");

        fetchCompanyTask();
        // Close the modal after the task has been added
        setTaskFormModalIsOpen(false);
      } else {
        console.error("Error adding task");
      }
    } catch (error) {
      console.error("Error adding task:", error);
    }
  };

  const handleProjectButtonClick = async (e) => {
    e.preventDefault();
    const token = Cookies.get("token");

    // Get the user ID based on the selected full name
    const selectedUser = availableUsers.find(
      (user) => user.fullName === taskAssignedTo
    );
    const projectAssignedToId = selectedUser ? selectedUser.id : "";

    const newProject = {
      project_assigned_to: projectSelectedUsers,
      project_name: projectTitle,
      project_description: projectDescription,
      project_type: projectType,
      project_color: projectColor,
      project_icon: projectIconSelected,
    };

    try {
      const response = await fetch("https://ww1.premelo.com/api/add-project", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newProject),
      });

      if (response.ok) {
        console.log("Project added successfully");
      } else {
        console.error("Error adding task");
      }
    } catch (error) {
      console.error("Error adding task:", error);
    }
    fetchCompanyProjects();
    setProjectFormModalIsOpen(false);
  };

  const handleChange = (value) => {
    setTaskDescription(value);
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "mention",
  ];

  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelectedUsers(value);
  };

  const handleProjectAssignChange = (event) => {
    const { value } = event.target;
    setProjectSelectedUsers(value);
  };

  const handleProjectSelectChange = (event) => {
    const { value } = event.target;
    setSelectedProjects(value);
  };

  const handleProjectTypeChange = (event) => {
    const { value } = event.target;
    setProjectType(value);
  };

  const handleSelectIcon = () => {
    setSelectIcon(true);
  };

  const projectIcon = (label) => {
    switch (label) {
      case "Project1":
        return (
          <DocumentScannerIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project2":
        return (
          <LanIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project3":
        return (
          <GroupsIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project4":
        return (
          <GroupIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project5":
        return (
          <AccessibilityNewIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project6":
        return (
          <AccountTreeIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project7":
        return (
          <AirplanemodeActiveIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project8":
        return (
          <ArchitectureIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project9":
        return (
          <AssignmentIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project10":
        return (
          <AudiotrackIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project11":
        return (
          <AccountBalanceIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project12":
        return (
          <WorkIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project13":
        return (
          <HubIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project14":
        return (
          <VolunteerActivismIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project15":
        return (
          <TerminalIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project16":
        return (
          <PaymentIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project17":
        return (
          <CameraAltIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project18":
        return (
          <ReportProblemIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project19":
        return (
          <Diversity3Icon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project20":
        return (
          <SportsEsportsIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project21":
        return (
          <LocalShippingIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project22":
        return (
          <RocketLaunchIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project23":
        return (
          <HandshakeIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project24":
        return (
          <HandymanIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project25":
        return (
          <SelfImprovementIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project26":
        return (
          <ElectricBoltIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project27":
        return (
          <EmailIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project28":
        return (
          <EscalatorWarningIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project29":
        return (
          <DescriptionIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project30":
        return (
          <DirectionsBoatIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project31":
        return (
          <BusinessIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project32":
        return (
          <CallIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project33":
        return (
          <CalculateIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project34":
        return (
          <CookieIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project35":
        return (
          <DesignServicesIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project36":
        return (
          <HeadsetIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );

      default:
        return null;
    }
  };
  const projectIconResized = (label) => {
    switch (label) {
      case "Project1":
        return (
          <DocumentScannerIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project2":
        return (
          <LanIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project3":
        return (
          <GroupsIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project4":
        return (
          <GroupIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project5":
        return (
          <AccessibilityNewIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project6":
        return (
          <AccountTreeIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project7":
        return (
          <AirplanemodeActiveIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project8":
        return (
          <ArchitectureIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project9":
        return (
          <AssignmentIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project10":
        return (
          <AudiotrackIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project11":
        return (
          <AccountBalanceIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project12":
        return (
          <WorkIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project13":
        return (
          <HubIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project14":
        return (
          <VolunteerActivismIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project15":
        return (
          <TerminalIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project16":
        return (
          <PaymentIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project17":
        return (
          <CameraAltIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project18":
        return (
          <ReportProblemIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project19":
        return (
          <Diversity3Icon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project20":
        return (
          <SportsEsportsIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project21":
        return (
          <LocalShippingIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project22":
        return (
          <RocketLaunchIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project23":
        return (
          <HandshakeIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project24":
        return (
          <HandymanIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project25":
        return (
          <SelfImprovementIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project26":
        return (
          <ElectricBoltIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project27":
        return (
          <EmailIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project28":
        return (
          <EscalatorWarningIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project29":
        return (
          <DescriptionIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project30":
        return (
          <DirectionsBoatIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project31":
        return (
          <BusinessIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project32":
        return (
          <CallIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project33":
        return (
          <CalculateIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project34":
        return (
          <CookieIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project35":
        return (
          <DesignServicesIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project36":
        return (
          <HeadsetIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );

      default:
        return null;
    }
  };
  const handleProjectIconSelected = (iconLabel) => {
    setProjectIconSelected(iconLabel);
    setSelectIcon(false);
  };

  const taskFormModalContent = (
    <div style={{}}>
      <form>
        <div
          style={{
            height: "700px",
            width: "100%",
            marginTop: "-10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div style={{ width: "100%" }}>
            <div style={{ marginTop: "30px", width: "100%" }}>
              <input
                className="task-title-input"
                style={{
                  color: primaryColor,
                  fontWeight: "800",
                }}
                type="text"
                id="taskTitle"
                placeholder="Type here to change task title"
                value={taskTitle}
                onChange={(e) => setTaskTitle(e.target.value)}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: "50px",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Select
                style={{ width: "100%", height: "50px" }}
                labelId="assigned-to-label"
                id="assigned-to-select"
                multiple
                value={selectedUsers}
                onChange={handleSelectChange}
                renderValue={(selected) => {
                  const selectedUsersNames = selected
                    .map((userId) => {
                      const user = availableUsers.find(
                        (user) => user.id === userId
                      );
                      return user.fullName;
                    })
                    .join(", ");
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Checkbox checked={selected.length > 0} />
                      <ListItemText primary={selectedUsersNames} />
                    </div>
                  );
                }}
              >
                {availableUsers.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    <Checkbox checked={selectedUsers.indexOf(user.id) > -1} />
                    <ListItemText primary={user.fullName} />
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginLeft: "30px",
              }}
            >
              <Select
                style={{ width: "100%", height: "50px" }}
                labelId="project-label"
                id="project-select"
                multiple
                value={selectedProjects}
                onChange={handleProjectSelectChange}
                renderValue={(selected) => {
                  const selectedProjectNames = selected
                    .map((projectId) => {
                      const project = userProjects.find(
                        (project) => project.id === projectId
                      );
                      return project.project_name;
                    })
                    .join(", ");
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Checkbox checked={selected.length > 0} />
                      <ListItemText primary={selectedProjectNames} />
                    </div>
                  );
                }}
              >
                {userProjects.map((project) => (
                  <MenuItem key={project.id} value={project.id}>
                    <Checkbox
                      checked={selectedProjects.indexOf(project.id) > -1}
                    />
                    <ListItemText primary={project.project_name} />
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <div
            style={{
              marginTop: "50px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: "30px",
              marginBottom: "50px",
            }}
          >
            {/* <label htmlFor="taskDueDate">Due Date:</label>
            <input
              style={{ width: "30%", borderRadius: "13px", padding: "5px" }}
              type="date"
              id="taskDueDate"
              value={taskDueDate}
              onChange={(e) => setTaskDueDate(e.target.value)}
            /> */}{" "}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={taskDueDate}
                onChange={(date) => setTaskDueDate(date)}
              />
              <TimePicker
                value={taskDueTime}
                onChange={(time) => setTaskDueTime(time)}
              />
            </LocalizationProvider>
            {/* <label htmlFor="taskDueTime">Due Time:</label>
            <input
              style={{
                width: "30%",
                marginRight: "0px",
                borderRadius: "13px",
                padding: "5px",
              }}
              type="time"
              id="taskDueTime"
              value={taskDueTime}
              onChange={(e) => setTaskDueTime(e.target.value)}
            /> */}
          </div>
          <div style={{ marginTop: "0px", width: "100%" }}>
            <label htmlFor="taskDescription">Task Description:</label>
            <ReactQuill
              value={taskDescription}
              onChange={handleChange}
              style={{ height: "300px" }}
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],
                  ["link", "image"],
                  ["clean"],
                ],
              }}
              formats={formats}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              marginTop: "auto",
            }}
          >
            <button
              style={{
                backgroundColor: primaryColor,
                color: "#ffffff",
                padding: "13px 40px",
                borderRadius: "15px",
                opacity: ".7",
              }}
              onClick={() => setTaskFormModalIsOpen(false)}
            >
              Cancel
            </button>
            <button
              style={{
                backgroundColor: primaryColor,
                color: "#ffffff",
                padding: "13px 40px",
                borderRadius: "15px",
              }}
              onClick={handleTaskButtonClick}
            >
              Add task
            </button>
          </div>
        </div>
      </form>
    </div>
  );

  const projectFormModalContent = (
    <div style={{ height: "80vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "20px",
          marginTop: "20px",
        }}
      >
        <CrossIcon
          className="mui-button"
          style={{ fontSize: "40px" }}
          onClick={() => setProjectFormModalIsOpen(false)}
        />
      </div>
      <form>
        <div
          style={{
            display: "flex",
            wdith: "100%",
            height: "80vh",
            marginTop: "40px",
          }}
        >
          {/* Left column to input */}
          <div
            style={{
              height: "100%",
              width: "35%",
              paddingLeft: "5%",
              marginTop: "-40px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <div style={{ display: "flex", alignItems: "end" }}>
              <div
                className="mui-button"
                style={{
                  marginTop: "20%",
                  width: "90px",
                  height: "90px",
                  borderRadius: "20px",
                  backgroundColor: projectColor,
                  color: "white",
                  marginRight: "25px",
                }}
                onClick={handleSelectIcon}
              >
                <div style={{ marginTop: "16px", marginLeft: "16px" }}>
                  {projectIcon(projectIconSelected)}
                </div>
              </div>
              <TwitterPicker
                style={{
                  width: "80px",
                  boxShadow: "none",
                  marginBottom: "-100px",
                }}
                onChange={(color, event) => setProjectColor(color.hex)}
              />
            </div>
            <div
              style={{
                fontSize: "14px",
                color: "rgba(0,0,0,0.7)",
              }}
            >
              <h1>New project</h1>
            </div>
            <div
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                width: "80%",
                marginBottom: "20px",
              }}
            >
              <div style={{ color: "rgba(0,0,0,0.4)", fontSize: "10px" }}>
                Project name:
              </div>
              <div>
                <input
                  style={{
                    height: "30px",
                    width: "80%",
                    border: "1px solid rgba(0,0,0,0.2)",
                    borderRadius: "10px",
                    padding: "0px 15px",
                    fontSize: "12px",
                  }}
                  type="text"
                  id="projectTitle"
                  value={projectTitle}
                  onChange={(e) => setProjectTitle(e.target.value)}
                />
              </div>
            </div>
            <div style={{ marginTop: "-10px" }}>
              <div style={{ color: "rgba(0,0,0,0.4)", fontSize: "10px" }}>
                Project type:
              </div>
              <div>
                <Select
                  style={{
                    width: "50%",
                    height: "30px",
                    borderRadius: "10px",
                    fontSize: "14px",
                    fontSize: "12px",
                  }}
                  value={projectType}
                  onChange={handleProjectTypeChange}
                >
                  <MenuItem
                    value={"Creative request"}
                    style={{ fontSize: "12px" }}
                  >
                    Creative request
                  </MenuItem>
                  <MenuItem
                    value={"Team management"}
                    style={{ fontSize: "12px" }}
                  >
                    Team management
                  </MenuItem>
                  <MenuItem
                    value={"Programming setup"}
                    style={{ fontSize: "12px" }}
                  >
                    Programming setup
                  </MenuItem>
                  <MenuItem
                    value={"Leads management"}
                    style={{ fontSize: "12px" }}
                  >
                    Leads management
                  </MenuItem>
                </Select>
              </div>
            </div>
            <div style={{ marginTop: "30px" }}>
              <button
                className="mui-button"
                style={{
                  backgroundColor: primaryColor,
                  color: "#ffffff",
                  fontWeight: "600",
                  padding: "8px 25px",
                  borderRadius: "10px",
                  border: "none",
                  fontSize: "12px",
                }}
                onClick={handleProjectButtonClick}
              >
                Create Project
              </button>
            </div>
          </div>
          {/* Right column to display preview */}
          <div
            style={{
              height: "100%",
              width: "60%",
              marginLeft: "-40px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
              borderRadius: "20px",
              marginTop: "-20px",
            }}
          >
            {selectIcon ? (
              <div style={{ marginTop: "50px", marginLeft: "50px" }}>
                <div
                  style={{
                    color: "rgba(0,0,0,0.7)",
                    fontSize: "25px",
                    borderBottom: "2px solid rgba(0,0,0,0.2)",
                    width: "70%",
                    paddingBottom: "20px",
                    marginBottom: "20px",
                  }}
                >
                  Select an Icon for your Project.
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    color: "rgba(0,0,0,0.5)",
                    width: "800px",
                    gap: "20px",
                  }}
                >
                  <DocumentScannerIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project1")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <LanIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project2")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <GroupsIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project3")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <GroupIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project4")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <AccessibilityNewIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project5")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <AccountTreeIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project6")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <AirplanemodeActiveIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project7")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <ArchitectureIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project8")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <AssignmentIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project9")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <AudiotrackIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project10")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <AccountBalanceIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project11")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <WorkIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project12")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <HubIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project13")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <VolunteerActivismIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project14")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <TerminalIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project15")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <PaymentIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project16")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <CameraAltIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project17")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <ReportProblemIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project18")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <Diversity3Icon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project19")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <SportsEsportsIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project20")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <LocalShippingIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project21")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <RocketLaunchIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project22")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <HandshakeIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project23")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <HandymanIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project24")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <SelfImprovementIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project25")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <ElectricBoltIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project26")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <EmailIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project27")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <EscalatorWarningIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project28")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <DescriptionIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project29")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <DirectionsBoatIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project30")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <BusinessIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project31")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <CallIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project32")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <CalculateIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project33")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <CookieIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project34")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <DesignServicesIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project35")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <HeadsetIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project36")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                </div>
              </div>
            ) : (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                >
                  <div
                    style={{
                      background: projectColor,
                      height: "60px",
                      width: "60px",
                      borderRadius: "10px",
                      color: "white",
                    }}
                  >
                    <div style={{ marginTop: "12px", marginLeft: "10px" }}>
                      {projectIconResized(projectIconSelected)}
                    </div>
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <div
                      style={{
                        fontSize: "22px",
                        fontWeight: "600",
                        color: "rgba(0,0,0,0.7)",
                        marginTop: "4px",
                      }}
                    >
                      {projectTitle ? projectTitle : "Project Name"}
                    </div>
                    <div
                      style={{
                        fontSize: "10px",
                        fontWeight: "400",
                        color: "rgba(0,0,0,0.7)",
                        marginTop: "-5px",
                      }}
                    >
                      Lorem Ipsum is simply dummy text.
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginLeft: "2%",
                    width: "96%",
                    borderBottom: "1px solid rgba(0,0,0,0.1)",
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "12px",
                      marginRight: "20px",
                      marginLeft: "5px",
                    }}
                  >
                    Task
                  </div>
                  <div
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "12px",
                      marginRight: "20px",
                    }}
                  >
                    Kanban
                  </div>
                  <div
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "12px",
                      marginRight: "20px",
                    }}
                  >
                    Calendar
                  </div>
                  <div
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "12px",
                      marginRight: "20px",
                    }}
                  >
                    Workflow
                  </div>
                  <div
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "12px",
                      marginRight: "20px",
                    }}
                  >
                    Repository
                  </div>
                </div>
                <div style={{ marginLeft: "30px" }}>
                  <PreviewSkeleton cards={2} />
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );

  const fetchUsers = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/user-available-team-members",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    const modifiedData = data.map((user) => {
      return {
        ...user,
        fullName: `${user.first_name} ${user.last_name}`,
      };
    });
    setAvailableUsers(modifiedData);
  };

  const fetchCompanyTask = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/retrieve-company-tasks",
      {
        headers: {
          Accept: `application/json`,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    console.log(data); // log the data variable
    setUserTask(data);
  };

  const openTaskFormModal = () => {
    setTaskFormModalIsOpen(true); // Show the modal
  };

  const openProjectFormModal = () => {
    setProjectFormModalIsOpen(true); // Show the modal
  };

  const fetchCompanyProjects = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/retrieve-company-projects",
      {
        headers: {
          Accept: `application/json`,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    // Extract status_options from all projects and combine them into one array
    const allStatusOptions = data.projects.flatMap((project) =>
      JSON.parse(project.status_options)
    );

    // Remove duplicates and set to setStatusOptions
    const uniqueStatusOptions = Array.from(new Set(allStatusOptions));

    setStatusOptions(uniqueStatusOptions);
    setUserProjects(data.projects);
    console.log(data.projects);
    props.onProjectListChange(data.projects);
  };

  useEffect(() => {}, [props.userProjects]);

  const handleProjectSelected = (
    projectId,
    projectName,
    projectColor,
    projectIcon
  ) => {
    console.log(projectId, projectName, projectColor, projectIcon);
    props.onProjectListSelected(
      projectId,
      projectName,
      projectColor,
      projectIcon
    );
  };

  const handleTaskCheckboxChange = async (e, taskId) => {
    const token = Cookies.get("token");
    const isComplete = e.target.checked ? 1 : 0;

    try {
      const response = await fetch(
        `https://ww1.premelo.com/api/update-task/${taskId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ task_is_complete: isComplete }),
        }
      );

      if (response.ok) {
        console.log("Task updated successfully");
        fetchCompanyTask();
      } else {
        console.error("Error updating task");
      }
    } catch (error) {
      console.error("Error updating task:", error);
    }
  };

  const fetchExistingTaskIds = async () => {
    const token = Cookies.get("token");
    const response = await fetch("https://ww1.premelo.com/api/all-task-id", {
      headers: {
        Accept: `application/json`,
        Authorization: `Bearer ${token}`,
      },
    });
    const existingIds = await response.json();
    setExistingIds(existingIds);
    // Use `existingIds` to generate new task IDs
  };
  useEffect(() => {
    // Call the fetchUsers function when the component mounts
    fetchExistingTaskIds();
    fetchUsers();
    fetchCompanyTask();
    fetchCompanyProjects();
  }, []);

  const tasksByDate = userTask.reduce((acc, task) => {
    const date = new Date(task.task_due_date);
    const dateString = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;

    if (!acc[dateString]) {
      acc[dateString] = [];
    }

    acc[dateString].push(task);

    return acc;
  }, {});

  const renderTileContent = ({ date, view }) => {
    if (view !== "month") {
      // Only render content for month view
      return null;
    }

    const dateString = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;

    if (!tasksByDate[dateString]) {
      // No tasks for this date
      return null;
    }
    const dayOfMonth = date.getDate();
    const colors = [
      "#a67b0f",
      "#b32727",
      "#742491",
      "#a67b0f",
      "#b32727",
      "#742491",
    ];
    const colorIndex = (dayOfMonth - 1) % colors.length;
    const color = colors[colorIndex];

    console.log("task by date", tasksByDate[dateString]);
    // Render a blue circle with a number indicating the number of tasks
    return (
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -75%)",
            borderRadius: "50%",
            width: "27px",
            height: "27px",
            background: `${color}`,
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {dayOfMonth}
        </div>
      </div>
    );
  };

  const customHeaderClassName = "custom-header"; // Custom class name for header tile

  return (
    <div style={{}}>
      {!isTourCompleted && (
        <Tour
          steps={steps}
          isOpen={true}
          onRequestClose={handleTourClose}
          className="my-tour"
        />
      )}
      <div
        style={{
          textAlign: "center",
          background: "rgba(255,255,255,0.5)",
          backdropFilter: "blur(20px)",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
          padding: "25px 0px 15px 0px",
          position:"relative",
          zIndex: 490
        }}
      >
        <h1 style={{ color: primaryColor, fontSize: "30px", margin: "0px" }}>
          Dashboard
        </h1>
        <p style={{ marginTop: "-5px", fontSize: "10px" }}>{dateString}</p>
        <p style={{ marginTop: "-12px", fontSize: "12px" }}>
          Welcome back, {name}!
        </p>
      </div>
      {/* Two new columns */}
      <div
        style={{
          padding: "0px 2%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "-10px",
          marginTop: "15px",
        }}
      >
        <h2
          style={{
            color: "rgba(0,0,0,0.8)",
            fontWeight: "600",
            fontSize: "15px",
          }}
        >
          Recently used
        </h2>
        <button
          className="mui-button forth-step"
          style={{
            backgroundColor: props.primaryColor,
            marginTop: "30px",
            color: "#ffffff",
            padding: "8px 30px",
            borderRadius: "12px",
            border: "none",
            fontWeight: "600",
            fontSize: "12px",
            cursor: "pointer",
            marginRight: "10px",
            marginTop: "-20px",
            height: "36px",
          }}
          onClick={openProjectFormModal}
        >
          Create Project
        </button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "0px 2% 0px 1%",
        }}
        className="fifth-step"
      >
        {userProjects.slice(0, 5).map((project, index) => {
          const projectTasks = userTask.filter(
            (task) => task.task_project_id === project.id
          );
          console.log("projecttasks", projectTasks);
          const userIds = projectTasks
            .map((task) => task.task_assigned_to)
            .reduce((acc, val) => acc.concat(val), []);
          console.log("userIds", userIds);
          const projectUsers = availableUsers.filter((user) =>
            userIds.includes(user.user_id)
          );
          console.log("available users", availableUsers);
          console.log("projectUsers", projectUsers);
          return (
            <div
              style={{ width: "19%", padding: "10px" }}
              className="folder-icon"
              key={project.id}
              onClick={() =>
                handleProjectSelected(
                  project.id,
                  project.project_name,
                  project.project_color,
                  project.project_icon
                )
              }
            >
              <div className="child-div-1">
                <div style={{ width: "90%" }}>
                  <h2
                    style={{
                      fontWeight: "400",
                      fontSize: "8px",
                      marginBottom: "-3px",
                    }}
                  >
                    Project name:
                  </h2>
                  <span
                    style={{
                      fontWeight: "800",
                      fontSize: "14px",
                      marginTop: "-20px",
                      lineHeight: "10px",
                    }}
                  >
                    {project.project_name
                      ? project.project_name.slice(0, 30) +
                        (project.project_name.length > 30 ? " ..." : "")
                      : "Untitled"}
                  </span>
                  <div style={{ marginTop: "10px" }}>
                    {projectUsers.map((user) => (
                      <div
                        style={{
                          backgroundColor: user.user_color,
                          color: "white",
                          display: "inline-flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "25px",
                          height: "25px",
                          borderRadius: "50%",
                          marginRight: "5px",
                          fontWeight: "600",
                          fontSize: "16px",
                          border: "2px solid #ffffff",
                          marginRight: "-11px",
                        }}
                        key={user.user_id}
                      >
                        <p style={{ fontWeight: "400", fontSize: "10px" }}>
                          {user.first_name.charAt(0)}
                          {user.last_name.charAt(0)}
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <FolderIcon />
            </div>
          );
        })}
        {[...Array(Math.max(0, 5 - userProjects.length))].map((_, index) => (
          <div
            style={{
              width: "19%",
              padding: "10px",
              opacity: 0.5,
            }}
            className="folder-greyed"
            onClick={openProjectFormModal}
            key={`gray-folder-${index}`}
          >
            <div
              className="child-div-1"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="project-greyed-icon"
                style={{ marginRight: "50px" }}
              >
                <AddCircleIcon className="add-circle-icon" />
              </div>
            </div>
            <FolderIcon style={{ color: "transparent" }} />
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
          height: "52vh",
          padding: "0px 1% 0px 1.5%",
        }}
      >
        <div
          className="sixth-step"
          style={{
            width: "45%",
            height: "80%",
            backgroundColor: "rgba(255,255,255,0.5)",
            borderRadius: "20px",
            padding: "30px 20px 10px 20px",
            marginRight: "2%",
            position: "relative",
            backdropFilter: "blur(20px)",
            boxShadow: "0px 5px 12px rgba(0,0,0,0.15)",
            // border: `1px solid ${primaryColor}`,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <h2
              style={{
                color: "rgba(0,0,0,0.8)",
                fontWeight: "600",
                fontSize: "15px",
                marginTop: "0px",
                paddingBottom: "0px",
                marginLeft: "0px",
              }}
            >
              To-do list
            </h2>
            {/* <button
              style={{
                backgroundColor: props.complementaryColor,
                color: "#ffffff",
                padding: "10px 30px",
                borderRadius: "12px",
                border: "none",
                fontWeight: "600",
                fontSize: "14px",
                cursor: "pointer",
                marginRight: "10px",
                marginTop: "-20px",
              }}
              onClick={openTaskFormModal}
            >
              Add Task
            </button> */}
          </div>
          <div
            className="to-do-scroll"
            style={{
              height: "80%",
              paddingRight: "10px",
            }}
          >
            {/*display user tasks*/}
            {userTask
              .sort((a, b) => a.task_is_complete - b.task_is_complete)
              .map((task, index) => (
                <div
                  className="task-item"
                  key={index}
                  style={{
                    padding: "0.1px 10px 0px 0px",
                    borderBottom: "1px solid rgba(0, 0, 0, .05)",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginBottom: "-3px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        marginLeft: "-10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ marginTop: "-3px", marginRight: "6px" }}>
                          <Checkbox
                            onChange={(e) =>
                              handleTaskCheckboxChange(e, task.id)
                            }
                            icon={<CheckIcon />}
                            checkedIcon={<CheckedIcon />}
                            checked={task.task_is_complete === 1}
                            sx={{
                              color: "rgba(0, 0, 0, .4)",
                              borderRadius: "50%",
                              "&.Mui-checked": {
                                color: props.complementaryColor,
                              },
                            }}
                          />
                        </div>
                        <div style={{ width: "250px" }}>
                          <div>
                            <h3
                              style={{
                                color: "#474747",
                                fontSize: "12px",
                                fontWeight: "600",
                                marginTop: "10px",
                              }}
                            >
                              {task.task_title &&
                                task.task_title.slice(0, 30) +
                                  (task.task_title.length > 30 ? " ..." : "")}
                            </h3>
                          </div>
                          <div>
                            <p
                              style={{
                                color: "#7C7C7C",
                                fontSize: "10px",
                                marginTop: "-15px",
                              }}
                            >
                              {task.task_description
                                ? (() => {
                                    const tempElement =
                                      document.createElement("div");
                                    tempElement.innerHTML =
                                      task.task_description;
                                    const plainText = tempElement.textContent;
                                    return plainText.substring(0, 40) + "...";
                                  })()
                                : "No description"}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div style={{ width: "150px" }}>
                        {task.task_status ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <div
                              style={{
                                padding: "4px 20px",
                                borderRadius: "10px",
                                marginTop: "-1px",
                                background: `${
                                  statusOptions.find(
                                    (option) =>
                                      option.value === task.task_status
                                  )?.color || "transparent"
                                }2A`,
                                color:
                                  statusOptions.find(
                                    (option) =>
                                      option.value === task.task_status
                                  )?.color || "transparent",
                              }}
                            >
                              <p
                                style={{
                                  margin: "0px",
                                  fontWeight: "600",
                                  fontSize: "11px",
                                }}
                              >
                                {task.task_status}
                              </p>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    {/* {task.task_due_date ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          color: "rgba(0,0,0,0.4",
                          fontSize: "12px",
                          textAlign: "right",
                          justifyContent: "flex-end",
                        }}
                      >
                        <EventBusyIcon
                          style={{
                            color: "rgba(0,0,0,0.3",
                            marginRight: "8px",
                            fontSize: "14px",
                          }}
                        />
                        <div style={{ width: "80px" }}>
                          {task.task_due_date}
                        </div>
                      </div>
                    ) : null} */}
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div
          className="dash-calender-holder"
          style={{
            width: "50%",
            height: "80%",
            backgroundColor: "rgba(255,255,255,0.5)",
            borderRadius: "20px",
            padding: "30px 20px 10px 20px",
            marginRight: "2%",
            position: "relative",
            backdropFilter: "blur(20px)",
            // border: `1px solid ${primaryColor}`,
          }}
        >
          <div style={{ display: "flex", height: "100%" }}>
            <div
              style={{
                width: "50%",
                padding: "0px 0px 0px 0px",
                marginTop: "-10px",
              }}
            >
              <Calendar
                next2Label={null}
                prev2Label={null}
                tileClassName={
                  ({ date, view }) =>
                    view === "month" && date.getMonth() === 3
                      ? customHeaderClassName
                      : null // Add custom class name to header tile for April
                }
                tileContent={renderTileContent}
              />
            </div>
            <div style={{ width: "60%" }}>
              <TimelineCalender />
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={taskFormModalIsOpen}
        onRequestClose={() => setTaskFormModalIsOpen(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            zIndex: 505,
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "700px",
            height: "720px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            borderRadius: "10px",
          },
        }}
      >
        {taskFormModalContent}
      </Modal>
      <Modal
        isOpen={projectFormModalIsOpen}
        onRequestClose={() => setProjectFormModalIsOpen(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            zIndex: 505,
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            height: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            borderRadius: "10px",
          },
        }}
      >
        {projectFormModalContent}
      </Modal>
    </div>
  );
};
export default DashContent;
