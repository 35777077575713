import React from "react";

const TestJs = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
    <div style={{ height: "30vh", background: "red", width: "50%" }}></div>
      <div style={{ height: "30vh", background: "green", width: "50%" }}></div>
    </div>
  );
};

export default TestJs;
