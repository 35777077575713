import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import axios from "axios";
import { GoogleOAuthProvider } from "@react-oauth/google";


axios.defaults.headers.common["X-CSRF-TOKEN"] = document
  .querySelector('meta[name="csrf-token"]')
  .getAttribute("content");

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <div>
    <Router>
      <GoogleOAuthProvider clientId="223781500930-af6qbm381kbjdca4hoiroomfkgu5r1vg.apps.googleusercontent.com">
        <App />
      </GoogleOAuthProvider>
    </Router>
  </div>
);
reportWebVitals();
