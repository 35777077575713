import React from "react";
import { useState } from "react";
import Cookies from "js-cookie";
import { useEffect } from "react";
import axios from "axios";

const WorkflowContent = (props) => {
  const token = Cookies.get("token");
  const [projectId, setProjectId] = useState(props.activeProjectId);

  const [availableUsers, setAvailableUsers] = useState([]);
  const [workflowRules, setWorkflowRules] = useState([]);

  const fetchExistingWorkflow = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      `https://ww1.premelo.com/api/workflow/${props.activeProjectId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    console.log(data);

    setWorkflowRules(data);
  };

  const fetchUsers = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/user-available-team-members",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();

    const transformedResponse = data.map((obj) => {
      return {
        id: obj.user_id,
        first_name: obj.first_name,
        last_name: obj.last_name,
        user_color: obj.user_color,
      };
    });

    console.log("transformed response", transformedResponse);
    const modifiedData = transformedResponse.map((user) => {
      return {
        ...user,
        fullName: `${user.first_name} ${user.last_name}`,
      };
    });
    console.log("available users with fullName:", modifiedData);
    setAvailableUsers(modifiedData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const condition = {
      task_type: formData.get("task_type"),
      project_name: formData.get("project_name"),
      task_assigned_to: parseInt(formData.get("task_assigned_to")),
      task_status: formData.get("task_status"),
      task_purpose: formData.get("task_purpose"),
    };
    const action = {
      update_task_type: formData.get("update_task_type"),
      update_project: formData.get("update_project"),
      update_assignee: parseInt(formData.get("update_assignee")),
      update_task_status: formData.get("update_task_status"),
      update_task_purpose: formData.get("update_task_purpose"),
    };

    const data = { condition, action };

    const response = await fetch(
      `https://ww1.premelo.com/api/projects/${projectId}/workflow-rules`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ condition, action }),
      }
    );

    if (response.ok) {
      console.log("Conditions and actions succesfully registered");
    } else {
      console.log("something went wrong somewhere!");
    }
    fetchExistingWorkflow();
  };

  useEffect(() => {
    fetchExistingWorkflow();
    fetchUsers();
  }, [props.activeProjectId]);

  const getSelectedUserFullName = (user) => {
    const selectedUserId = parseInt(user); // convert to number
    const selectedUser = availableUsers.find(
      (something) => something.id === selectedUserId
    );
    console.log(selectedUser);
    const selectedUserFullName = selectedUser
      ? selectedUser.fullName
      : "Unknown";
    return selectedUserFullName;
  };

  function deleteWorkflowRule(id) {
    const token = Cookies.get("token");

    axios
      .delete(`https://ww1.premelo.com/api/workflow-rules/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("Workflow rule deleted:", response.data.message);
        // TODO: Update state to remove the deleted rule from the UI
        setTimeout(() => {
          fetchExistingWorkflow();
        }, 600);
      })
      .catch((error) => {
        console.error("Failed to delete workflow rule:", error);
      });
  }

  return (
    <div
      className="to-do-scroll"
      style={{ padding: "10px 30px", height: "80vh" }}
    >
      <div style={{ marginTop: "10px" }}>
        <div
          style={{
            fontSize: "30px",
            color: "rgba(0,0,0,0.7)",
            fontWeight: "600",
          }}
        >
          Create new Workflow
        </div>
        <form onSubmit={handleSubmit}>
          <h2
            style={{
              fontSize: "17px",
              fontWeight: "600",
              color: "rgba(0,0,0,0.6)",
              marginBottom: "0px",
            }}
          >
            Conditions
          </h2>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "20%",
                fontSize: "10px",
                color: "rgba(0,0,0,0.4)",
              }}
            >
              Section:
            </div>
            <div
              style={{
                width: "20%",
                fontSize: "10px",
                color: "rgba(0,0,0,0.4)",
              }}
            >
              Task Assigned To:
            </div>
            <div
              style={{
                width: "20%",
                fontSize: "10px",
                color: "rgba(0,0,0,0.4)",
              }}
            >
              Task Status:
            </div>
            <div
              style={{
                width: "20%",
                fontSize: "10px",
                color: "rgba(0,0,0,0.4)",
              }}
            >
              Task Purpose:
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ width: "20%" }}>
              <input
                style={{
                  heigh: "35px",
                  padding: "5px",
                  borderRadius: "8px",
                  border: "1px solid rgba(0,0,0,0.5)",
                  background: "transparent",
                  width: "80%",
                  fontSize: "10px",
                }}
                type="text"
                id="task_type"
                name="task_type"
              />
            </div>
            <div style={{ width: "20%" }}>
              <select
                style={{
                  height: "24px",
                  width: "80%",
                  padding: "5px",
                  borderRadius: "8px",
                  border: "1px solid rgba(0,0,0,0.5)",
                  background: "transparent",
                  fontSize: "10px",
                }}
                id="task_assigned_to"
                name="task_assigned_to"
              >
                <option key={100} value=""></option>
                {availableUsers.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.fullName}
                  </option>
                ))}
              </select>
              {/* <input
                style={{
                  heigh: "35px",
                  padding: "5px",
                  borderRadius: "8px",
                  border: "1px solid rgba(0,0,0,0.5)",
                  background: "transparent",
                }}
                type="text"
                id="task_assigned_to"
                name="task_assigned_to"
              /> */}
            </div>
            <div style={{ width: "20%" }}>
              <input
                style={{
                  heigh: "35px",
                  padding: "5px",
                  borderRadius: "8px",
                  border: "1px solid rgba(0,0,0,0.5)",
                  background: "transparent",
                  width: "80%",
                  fontSize: "10px",
                }}
                type="text"
                id="task_status"
                name="task_status"
              />
            </div>
            <div style={{ width: "20%" }}>
              <input
                style={{
                  heigh: "35px",
                  padding: "5px",
                  borderRadius: "8px",
                  border: "1px solid rgba(0,0,0,0.5)",
                  background: "transparent",
                  width: "80%",
                  fontSize: "10px",
                }}
                type="text"
                id="task_purpose"
                name="task_purpose"
              />
            </div>
          </div>
          <h2
            style={{
              fontSize: "17px",
              fontWeight: "600",
              color: "rgba(0,0,0,0.6)",
              marginBottom: "0px",
            }}
          >
            Actions
          </h2>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "20%",
                fontSize: "10px",
                color: "rgba(0,0,0,0.4)",
              }}
            >
              Section:
            </div>
            <div
              style={{
                width: "20%",
                fontSize: "10px",
                color: "rgba(0,0,0,0.4)",
              }}
            >
              Assignee:
            </div>
            <div
              style={{
                width: "20%",
                fontSize: "10px",
                color: "rgba(0,0,0,0.4)",
              }}
            >
              Task Status:
            </div>
            <div
              style={{
                width: "20%",
                fontSize: "10px",
                color: "rgba(0,0,0,0.4)",
              }}
            >
              Task Purpose:
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div style={{ width: "20%" }}>
              <input
                style={{
                  heigh: "35px",
                  padding: "5px",
                  borderRadius: "8px",
                  border: "1px solid rgba(0,0,0,0.5)",
                  background: "transparent",
                  fontSize:"10px",
                  width:"80%"
                }}
                type="text"
                id="update_task_type"
                name="update_task_type"
              />
            </div>
            <div style={{ width: "20%" }}>
              <select
                style={{
                  height: "24px",
                  width: "80%",
                  padding: "5px",
                  borderRadius: "8px",
                  border: "1px solid rgba(0,0,0,0.5)",
                  background: "transparent",
                  fontSize:"10px",
                }}
                id="update_assignee"
                name="update_assignee"
              >
                <option key={100} value=""></option>
                {availableUsers.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.fullName}
                  </option>
                ))}
              </select>
              {/* <input
                style={{
                  heigh: "35px",
                  padding: "5px",
                  borderRadius: "8px",
                  border: "1px solid rgba(0,0,0,0.5)",
                  background: "transparent",
                }}
                type="text"
                id="update_assignee"
                name="update_assignee"
              /> */}
            </div>
            <div style={{ width: "20%" }}>
              <input
                style={{
                  heigh: "35px",
                  padding: "5px",
                  borderRadius: "8px",
                  border: "1px solid rgba(0,0,0,0.5)",
                  background: "transparent",
                  fontSize:"10px",
                  width:"80%"
                }}
                type="text"
                id="update_task_status"
                name="update_task_status"
              />
            </div>
            <div style={{ width: "20%" }}>
              <input
                style={{
                  heigh: "35px",
                  padding: "5px",
                  borderRadius: "8px",
                  border: "1px solid rgba(0,0,0,0.5)",
                  background: "transparent",
                  fontSize:"10px",
                  width:"80%"
                }}
                type="text"
                id="update_task_purpose"
                name="update_task_purpose"
              />
            </div>
          </div>
          <button className="mui-button"
            style={{
              marginTop: "20px",
              background: props.primaryColor,
              border: "none",
              color: "white",
              borderRadius: "10px",
              padding: "7px 20px",
              fontSize:"12px"
            }}
            type="submit"
          >
            Create Rule
          </button>
        </form>
      </div>
      {workflowRules && workflowRules.length > 0 && (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            borderTop: "1px solid rgba(0,0,0,0.2)",
            marginTop: "30px",
          }}
        >
          {workflowRules.map((workflow) => (
            <div
              key={workflow.id}
              style={{
                flexBasis: "24%",
                padding: "20px",
                boxSizing: "border-box",
                border: "1px solid rgba(0,0,0,0.2)",
                borderRadius: "10px",
                marginRight: "10px",
                marginTop: "20px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <button
                  style={{
                    color: "white",
                    background: props.primaryColor,
                    border: "none",
                    borderRadius: "10px",
                    padding: "5px 25px",
                  }}
                  className="mui-button"
                  onClick={() => deleteWorkflowRule(workflow.id)}
                >
                  Delete Rule
                </button>
              </div>
              <div>
                <div
                  style={{
                    fontSize: "18px",
                    color: "rgba(0,0,0,0.6)",
                    fontWeight: "600",
                  }}
                >
                  Conditions
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: "10px",
                  }}
                >
                  <div style={{ flexBasis: "50%" }}>
                    <div style={{ color: "rgba(0,0,0,0.4)", fontSize: "10px" }}>
                      section
                    </div>
                    <div
                      style={{
                        color: "rgba(0,0,0,0.6)",
                        fontSize: "13px",
                        marginBottom: "0px",
                      }}
                    >
                      {workflow.conditions.task_type ? (
                        <div>
                          <div
                            style={{
                              border: "1px solid green",
                              borderRadius: "5px",
                              padding: "0px 5px",
                              color: "green",
                              background: "rgba(11, 125, 19, 0.1)",
                              width: "120px",
                            }}
                          >
                            {workflow.conditions.task_type}
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{ color: "rgba(0,0,0,0.4)", fontSize: "13px" }}
                        >
                          None
                        </div>
                      )}
                    </div>
                  </div>
                  <div style={{ flexBasis: "50%" }}>
                    <div
                      style={{
                        color: "rgba(0,0,0,0.4)",
                        fontSize: "10px",
                        marginBottom: "0px",
                      }}
                    >
                      Assignee
                    </div>
                    <div style={{ color: "rgba(0,0,0,0.6)", fontSize: "13px" }}>
                      {workflow.conditions.task_assigned_to ? (
                        <div>
                          <div
                            style={{
                              border: "1px solid green",
                              borderRadius: "5px",
                              padding: "0px 5px",
                              color: "green",
                              background: "rgba(11, 125, 19, 0.1)",
                              width: "120px",
                            }}
                          >
                            {getSelectedUserFullName(
                              `${workflow.conditions.task_assigned_to}`
                            )}
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{ color: "rgba(0,0,0,0.4)", fontSize: "13px" }}
                        >
                          None
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: "10px",
                  }}
                >
                  <div style={{ flexBasis: "50%" }}>
                    <div
                      style={{
                        color: "rgba(0,0,0,0.4)",
                        fontSize: "10px",
                        marginBottom: "0px",
                      }}
                    >
                      Status
                    </div>
                    <div style={{ color: "rgba(0,0,0,0.6)", fontSize: "13px" }}>
                      {workflow.conditions.task_status ? (
                        <div>
                          <div
                            style={{
                              border: "1px solid green",
                              borderRadius: "5px",
                              padding: "0px 5px",
                              color: "green",
                              background: "rgba(11, 125, 19, 0.1)",
                              width: "120px",
                            }}
                          >
                            {workflow.conditions.task_status}
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{ color: "rgba(0,0,0,0.4)", fontSize: "13px" }}
                        >
                          None
                        </div>
                      )}
                    </div>
                  </div>
                  <div style={{ flexBasis: "50%" }}>
                    <div
                      style={{
                        color: "rgba(0,0,0,0.4)",
                        fontSize: "10px",
                        marginBottom: "0px",
                      }}
                    >
                      Purpose
                    </div>
                    <div style={{ color: "rgba(0,0,0,0.6)", fontSize: "13px" }}>
                      {workflow.conditions.task_purpose ? (
                        <div>
                          <div
                            style={{
                              border: "1px solid green",
                              borderRadius: "5px",
                              padding: "0px 5px",
                              color: "green",
                              background: "rgba(11, 125, 19, 0.1)",
                              width: "120px",
                            }}
                          >
                            {workflow.conditions.task_purpose}
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{ color: "rgba(0,0,0,0.4)", fontSize: "13px" }}
                        >
                          None
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    fontSize: "18px",
                    color: "rgba(0,0,0,0.6)",
                    fontWeight: "600",
                  }}
                >
                  Actions
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: "10px",
                  }}
                >
                  <div style={{ flexBasis: "50%" }}>
                    <div style={{ color: "rgba(0,0,0,0.4)", fontSize: "10px" }}>
                      section
                    </div>
                    <div
                      style={{
                        color: "rgba(0,0,0,0.6)",
                        fontSize: "13px",
                        marginBottom: "0px",
                      }}
                    >
                      {workflow.actions.update_task_type ? (
                        <div>
                          <div
                            style={{
                              border: "1px solid green",
                              borderRadius: "5px",
                              padding: "0px 5px",
                              color: "green",
                              background: "rgba(11, 125, 19, 0.1)",
                              width: "120px",
                            }}
                          >
                            {workflow.actions.update_task_type}
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{ color: "rgba(0,0,0,0.4)", fontSize: "13px" }}
                        >
                          None
                        </div>
                      )}
                    </div>
                  </div>
                  <div style={{ flexBasis: "50%" }}>
                    <div
                      style={{
                        color: "rgba(0,0,0,0.4)",
                        fontSize: "10px",
                        marginBottom: "0px",
                      }}
                    >
                      Assignee
                    </div>
                    <div style={{ color: "rgba(0,0,0,0.6)", fontSize: "13px" }}>
                      {workflow.actions.update_assignee ? (
                        <div>
                          <div
                            style={{
                              border: "1px solid green",
                              borderRadius: "5px",
                              padding: "0px 5px",
                              color: "green",
                              background: "rgba(11, 125, 19, 0.1)",
                              width: "120px",
                            }}
                          >
                            {getSelectedUserFullName(
                              `${workflow.actions.update_assignee}`
                            )}
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{ color: "rgba(0,0,0,0.4)", fontSize: "13px" }}
                        >
                          None
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: "10px",
                  }}
                >
                  <div style={{ flexBasis: "50%" }}>
                    <div
                      style={{
                        color: "rgba(0,0,0,0.4)",
                        fontSize: "10px",
                        marginBottom: "0px",
                      }}
                    >
                      Status
                    </div>
                    <div style={{ color: "rgba(0,0,0,0.6)", fontSize: "13px" }}>
                      {workflow.actions.update_task_status ? (
                        <div>
                          <div
                            style={{
                              border: "1px solid green",
                              borderRadius: "5px",
                              padding: "0px 5px",
                              color: "green",
                              background: "rgba(11, 125, 19, 0.1)",
                              width: "120px",
                            }}
                          >
                            {workflow.actions.update_task_status}
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{ color: "rgba(0,0,0,0.4)", fontSize: "13px" }}
                        >
                          None
                        </div>
                      )}
                    </div>
                  </div>
                  <div style={{ flexBasis: "50%" }}>
                    <div
                      style={{
                        color: "rgba(0,0,0,0.4)",
                        fontSize: "10px",
                        marginBottom: "0px",
                      }}
                    >
                      Purpose
                    </div>
                    <div style={{ color: "rgba(0,0,0,0.6)", fontSize: "13px" }}>
                      {workflow.actions.update_task_purpose ? (
                        <div>
                          <div
                            style={{
                              border: "1px solid green",
                              borderRadius: "5px",
                              padding: "0px 5px",
                              color: "green",
                              background: "rgba(11, 125, 19, 0.1)",
                              width: "120px",
                            }}
                          >
                            {workflow.actions.update_task_purpose}
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{ color: "rgba(0,0,0,0.4)", fontSize: "13px" }}
                        >
                          None
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default WorkflowContent;
