import React, { useState, useEffect } from "react";
import logo from "./images/CS_Favicon-01.png";
import { useNavigate } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import InfoIcon from "@mui/icons-material/Info";
import KommunicateChat from "./chatContact";
import CloseIcon from "@mui/icons-material/Close";

const ChatWithAgentMobile = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };
  const [primaryColor, setPrimaryColor] = useState("#5AB1BA");
  return (
    <div style={{ width: "100%" }}>
      {/* first segment */}
      <div
        style={{
          width: "100%",
          background: "#d5dee0",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "15px",
          }}
        >
          <div
            style={{
              marginTop: "20px",
              position: "fixed",
              zIndex: 200,
              top: 15,
              right: 15,
            }}
          >
            <CloseIcon
              className="mui-button"
              style={{ fontSize: "30px" }}
              onClick={handleClick}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "90%",
              padding: "35px 0px",
              textAlign: "left",
            }}
          >
            <div
              style={{ fontSize: "24px", fontWeight: "600", marginTop: "20px" }}
            >
              Chat with Premelo Agent.
            </div>
            <div style={{ fontSize: "12px", fontWeight: "300" }}>
              Now you can reach our agents with just a simple message, no more
              contact forms or whatnot, our agent typically replies in 5-10
              minutes.
            </div>
          </div>
        </div>
      </div>
      {/* Chat with Agent */}
      <div
        style={{
          width: "90%",
          height: "78vh",
          display: "flex",
          justifyContent: "center",
          paddingTop: "10px",
        }}
      >
        <iframe
          style={{
            border: "none",
            height: "80vh",
            width: "85%",
            color: "red",
          }}
          src="/km-script-1.html"
          seamless="true"
        ></iframe>
      </div>
    </div>
  );
};
export default ChatWithAgentMobile;
