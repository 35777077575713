import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-modal";
import CS_Favicon from "./images/CS_Favicon.png";
import backgroundImage from "./images/welcome_page_bg.png";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackgroundSvg } from "./images/welcome page base-01-01.svg";
import { ReactComponent as ForegroundSvg } from "./images/welcome page upper-01.svg";
import SignUpMobile from "./SignUpMobile";
import { GoogleLogin } from "@react-oauth/google";
import Cookies from "js-cookie";
import { v4 as uuid } from "uuid";

const SignUp = () => {
  useEffect(() => {
    const recordAnalytics = async () => {
      const generateduuid = `${Date.now().toString()}` + `${uuid()}`;
      const userAgent = window.navigator.userAgent.toLowerCase();
      const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/.test(
        userAgent
      );
      try {
        // Check if the user has a cookie named 'user_id'
        const userId = Cookies.get("analytics");

        if (userId) {
          // Existing user logic
          console.log("Existing User");
        } else {
          // New user logic
          console.log("New User");

          // Set a cookie named 'analytics' with an expiration of 7 days
          Cookies.set("analytics", generateduuid, { expires: 7 });
        }

        const response = await fetch(
          "https://ww1.premelo.com/api/record-analytics",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              page: `signup ${isMobile ? "mobile" : null} page`,
              user: userId ? "unique" : "new",
            }),
          }
        );
        const json = await response.json();
        console.log(json);

        // Handle the response as needed
      } catch (error) {
        console.log(error);
      }
    };

    recordAnalytics();
  }, []);

  const responseGoogle = (response) => {
    const credential = response;
    console.log(response);
    if (credential) {
      let requestData = {
        credential,
      };

      // Add company name if it is available and not 'Premelo'
      if (companyName && companyName !== "Premelo") {
        requestData.companyName = companyName;
      }

      fetch("https://ww1.premelo.com/api/auth/google", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.token) {
            console.log(data.token);
            console.log(data.redirect_url);
            // Store the token in local storage
            Cookies.set("token", data.token, { expires: 365 }); // store token in cookies
          }
          if (data.redirect_url === "/dashboard") {
            navigate("/dashboard"); // navigate to dashboard page
          } else if (data.redirect_url === "/fillinformation") {
            navigate("/fillinformation"); // navigate to fillinformation page
          } else {
            console.log("Unknown redirect URL:", data.redirectUrl);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const errorMessage = (error) => {
    console.log(error);
  };
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState(""); // Define modal message state
  const [companyName, setCompanyName] = useState("Premelo");

  const [isMobile, setIsMobile] = useState(false);
  // const [companyColor, setCompanyColor] = useState("green");
  const [companyColor, setCompanyColor] = useState("#1450db");
  const svgStyle = {
    fill: companyColor,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 0,
  };
  const svgStyle2 = {
    position: "absolute",
    top: -200,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 5,
  };

  const getCompanyColorByDomain = async () => {
    try {
      console.log(window.location.hostname);
      const response = await axios.get(
        "https://ww1.premelo.com/api/company-color-by-domain", // Replace with your API endpoint
        {
          params: {
            domain: `https://${window.location.hostname}`,
          },
        }
      );
      const data = response.data;
      if (data && data.company_color) {
        setCompanyColor(data.company_color);
        console.log(data.company_color);
      }
      if (data && data.company_name) {
        setCompanyName(data.company_name);
        console.log(data.company_name);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Call the function when the sign-up page loads
  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/.test(
      userAgent
    );
    setIsMobile(isMobile);
    getCompanyColorByDomain();
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("companyname")) {
      const name = urlParams.get("companyname");
      setCompanyName(name);
    }
    if (urlParams.has("companycolor")) {
      const color = urlParams.get("companycolor");
      setCompanyColor(color);
    }
  }, []);

  const [error, setError] = useState(null);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);

    let requestData = {
      name,
      email,
      password,
    };

    if (companyName && companyName !== "Premelo") {
      requestData.companyName = companyName;
    }

    try {
      const response = await axios.post(
        "https://ww1.premelo.com/api/signup",
        requestData
      );
      setModalMessage(response.data.message); // Set the message to display in the modal
      setModalIsOpen(true); // Show the modal
      console.log(response.data.access_token);
    } catch (error) {
      console.error(error);
    }
  };

  if (isMobile) {
    return <SignUpMobile />;
  }
  return (
    <div
      style={{
        alignItems: "center",
        height: "100vh",
        backgroundSize: "cover",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: 50,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <div
          style={{
            color: "white",
            fontSize: "35px",
            fontWeight: "800",
            padding: "25px",
            paddingLeft: "40px",
            zIndex: 20,
          }}
        >
          {companyName}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              justifyContent: "center",
              boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.3)",
              padding: "50px",
              borderRadius: "30px",
              background: "#fff",
            }}
          >
            <h1 style={{ fontSize: "45px", color: "#242424" }}>Sign up</h1>
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "0px",
                }}
              >
                <label
                  htmlFor="name"
                  style={{
                    width: "350px",
                    color: "#242424",
                    padding: "0px 0px 5px 20px",
                    opacity: "80%",
                  }}
                ></label>
                <input
                  type="text"
                  id="name"
                  placeholder="Name"
                  required
                  value={name}
                  onChange={(event) => setName(event.target.value)}
                  style={{
                    borderRadius: "20px",
                    padding: "10px 20px",
                    border: "1px solid #7d7d7d",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "10px",
                }}
              >
                <label
                  htmlFor="email"
                  style={{
                    width: "350px",
                    color: "#242424",
                    padding: "10px 0px 5px 20px",
                    opacity: "80%",
                  }}
                ></label>
                <input
                  type="email"
                  id="email"
                  placeholder="Email"
                  required
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  style={{
                    borderRadius: "20px",
                    padding: "10px 20px",
                    border: "1px solid #7d7d7d",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "10px",
                }}
              >
                <label
                  htmlFor="password"
                  style={{
                    width: "350px",
                    color: "#242424",
                    padding: "10px 0px 5px 20px",
                    opacity: "80%",
                  }}
                ></label>
                <input
                  type="password"
                  id="password"
                  placeholder="Password"
                  required
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  style={{
                    borderRadius: "20px",
                    padding: "10px 20px",
                    border: "1px solid #7d7d7d",
                  }}
                />
              </div>

              {error ? (
                <div
                  style={{ color: "red", fontSize: "12px", fontWeight: 600 }}
                >
                  {error}
                </div>
              ) : null}
              <button
                className="mui-button"
                type="submit"
                style={{
                  marginTop: "40px",
                  border: "0px solid #FFFFFF",
                  backgroundColor: companyColor,
                  padding: "15px 40px",
                  color: "#FFFFFF",
                  borderRadius: "30px",
                  fontWeight: "600",
                  textDecoration: "none",
                  transition: "all 0.3s ease",
                }}
              >
                Submit
              </button>
            </form>
            <div
              style={{
                color: "rgba(0,0,0,0.6)",
                fontSize: "14px",
                margin: "20px 0px 20px 0px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                textAlign: "center",
              }}
            >
              <hr
                style={{
                  width: "30%",
                  height: "1px",
                  backgroundColor: "rgba(0,0,0,0.2)",
                  border: "none",
                  margin: "10px auto",
                }}
              />
              Or sign in with
              <hr
                style={{
                  width: "30%",
                  height: "1px",
                  backgroundColor: "rgba(0,0,0,0.2)",
                  border: "none",
                  margin: "10px auto",
                }}
              />
            </div>
            <GoogleLogin onSuccess={responseGoogle} onError={errorMessage} />
            <div style={{ marginTop: "20px" }}>
              <Link
                to="/login"
                style={{
                  color: "rgba(0,0,0,0.5)",
                  fontSize: "12px",
                  fontWeight: "400",
                  transition: "all 0.3s ease",
                }}
              >
                If you have an account, click here to login.
              </Link>
            </div>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={() => setModalIsOpen(false)}
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 200,
                },
                content: {
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "25%",
                  height: "20%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#fff",
                  borderRadius: "4px",
                  boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
                  borderRadius: "20px",
                  zIndex: 300,
                },
              }}
            >
              <h2 style={{ marginTop: "5px", textAlign: "center" }}>
                Sign up successful
              </h2>
              <div style={{ marginTop: "-30px", textAlign: "center" }}>
                <p>{modalMessage}</p>
              </div>
              <button
                className="mui-button"
                style={{
                  marginTop: "10px",
                  padding: "10px 50px",
                  borderRadius: "20px",
                }}
                onClick={() => {
                  setModalIsOpen(false);
                  // Redirect to login page
                  navigate("/login");
                }}
              >
                Login
              </button>
            </Modal>
          </div>
        </div>
      </div>
      <BackgroundSvg style={svgStyle} />
      <ForegroundSvg style={svgStyle2} />
    </div>
  );
};

export default SignUp;
