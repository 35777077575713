import React, { useState, useEffect, useRef } from "react";
import { Country, State, City } from "country-state-city";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { cloneDeep, isEqual } from "lodash";
import EventIcon from "@mui/icons-material/Event";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const currentURL = window.location.href;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: currentURL,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <form onSubmit={handleSubmit} className="stripe_form">
      <PaymentElement options={paymentElementOptions} />
      <button disabled={!stripe}>Submit</button>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

const EventEzyLink = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const eventId = urlParams.get("eventId");

  const [currency, setCurrency] = useState("$");

  const stripe = useStripe();
  const [message, setMessage] = useState(null);

  const [eventArray, setEventArray] = useState(null);
  const [event, setEvent] = useState(null);
  const [companyColor, setCompanyColor] = useState("#1872d9");
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyName, setCompanyName] = useState(null);

  const [customerAccountDetails, setCustomerAccountDetails] = useState({
    id: null,
    username: null,
    email: null,
    created_at: null,
    order_history: null,
    checkout_details: null,
    cart: null,
    wishlist: null,
  });

  const handleGetEvent = async (eventId) => {
    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/web-builder/get-event-public",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: eventId }),
        }
      );
      const json = await response.json();
      console.log(json.events);
      setEventArray(json.events);
      if (json.company_color) {
        setCompanyColor(json.company_color);
      }
      setCompanyLogo(json.company_logo);
      setCompanyName(json.company_name);
      if (eventId) {
        const eventIndex = json.events.findIndex(
          (eve) => eve.id === parseFloat(eventId)
        );
        console.log(eventIndex);
        if (eventIndex > -1) {
          console.log(json.events[eventIndex]);
          setEvent(json.events[eventIndex]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [selectedVariations, setSelectedVariations] = useState({});
  const [calculatedPrice, setCalculatedPrice] = useState(null);

  const handleVariationChange = (variat, value, variationValue) => {
    setSelectedVariations({
      ...selectedVariations,
      [variat]: {
        value,
        variationValue,
      },
    });
    const selectedVariationsUpdate = {
      ...selectedVariations,
      [variat]: {
        value,
        variationValue,
      },
    };
    console.log(selectedVariationsUpdate);

    // Check if all variations have been selected
    const allVariationsSelected = Object.keys(event.variation).every(
      (variat) => selectedVariationsUpdate[variat].value !== "0"
    );

    console.log(allVariationsSelected);

    if (allVariationsSelected) {
      // Calculate the price based on selected variations
      let calculatedPrice = parseFloat(event.unit_price);
      Object.keys(selectedVariationsUpdate).forEach((variat) => {
        calculatedPrice += parseFloat(selectedVariationsUpdate[variat].value);
      });
      console.log(
        calculatedPrice.toLocaleString("en-US", {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
      setCalculatedPrice(
        calculatedPrice.toLocaleString("en-US", {
          style: "decimal",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      ); // Display 2 decimal places
    } else {
      // Not all variations have been selected, reset the calculated price
      setCalculatedPrice(null);
    }
  };

  const [selectedTicketIndex, setSelectedTicketIndex] = useState(null);

  const handleAddToCart = (product) => {
    const updatingCart = { ...customerAccountDetails };
    if (updatingCart.cart) {
      const productIndex = updatingCart.cart.findIndex(
        (prod) => prod.id === product.id
      );
      if (productIndex > -1) {
        if (updatingCart.cart[productIndex].variation === selectedTicketIndex) {
          updatingCart.cart[productIndex].quantity += 1;
        } else {
          updatingCart.cart.push({
            ...product,
            quantity: 1,
            variation: selectedTicketIndex,
            unit_price: parseFloat(
              event.ticket[selectedTicketIndex].price
            ).toLocaleString("en-US", {
              style: "decimal",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
            type: "event",
          });
        }
      } else {
        updatingCart.cart.push({
          ...product,
          quantity: 1,
          variation: selectedTicketIndex,
          unit_price: parseFloat(
            event.ticket[selectedTicketIndex].price
          ).toLocaleString("en-US", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          type: "event",
        });
      }
    } else {
      updatingCart.cart = [
        {
          ...product,
          quantity: 1,
          variation: selectedTicketIndex,
          unit_price: parseFloat(
            event.ticket[selectedTicketIndex].price
          ).toLocaleString("en-US", {
            style: "decimal",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          type: "event",
        },
      ];
    }

    setCustomerAccountDetails(updatingCart);
  };

  function handleEventClick(eventId) {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    url.searchParams.set("eventId", eventId); // Set the 'sku' parameter in the URL

    window.location.href = url.toString(); // Update the URL
  }

  const [doingPayment, setDoingPayment] = useState(false);
  const [commerceDetails, setCommerceDetails] = useState(null);

  let Country = require("country-state-city").Country;
  let State = require("country-state-city").State;

  const [shippingDetails, setShippingDetails] = useState({
    full_name: "",
    email_address: "",
    phone_number: "",
    address: "",
    zip_code: "",
    state: "",
    country: "",
  });

  const [stripeId, setStripeId] = useState(null);
  const [stripeObject, setStripeObject] = useState(null);

  const [currentState, setCurrentState] = useState(0);

  const updateStatusByPaymentIntent = async (status) => {
    const paymentIntent = new URLSearchParams(window.location.search).get(
      "payment_intent"
    );
    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/update-payment-intent-status",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paymentIntent: paymentIntent,
            status: status,
          }),
        }
      );
      const json = await response.json();

      console.log(json);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchStripeObject = async () => {
      // If there is no accountId, do not run the loadStripe function.
      if (stripeId) {
        const res = await loadStripe(
          "pk_live_51KaaxAB9rsZCCn0XJcWbdk3MNAufS1spGzsmm50VqAgxqzq9sXkJcwo1YyhYwKOF3aDBMgR80gVEzvYyvjFjy2P100J3QYgnSr",
          {
            stripeAccount: stripeId,
          }
        );
        // When we have got the Stripe object, pass it into our useState.
        setStripeObject(res);

        const clientSecret = new URLSearchParams(window.location.search).get(
          "payment_intent_client_secret"
        );
        console.log(clientSecret);

        if (clientSecret) {
          if (!stripe) {
            return;
          }

          // Retrieve the PaymentIntent
          res.retrievePaymentIntent(clientSecret).then((result) => {
            console.log(result.paymentIntent);
            switch (result.paymentIntent.status) {
              case "succeeded":
                setMessage("Success");
                updateStatusByPaymentIntent("Order Processing");
                setDoingPayment(true);
                setCurrentState(3);
                break;

              case "processing":
                setMessage("Processing");
                setDoingPayment(true);
                setCurrentState(3);
                break;

              case "requires_payment_method":
                // Redirect your user back to your payment page to attempt collecting
                // payment again
                setMessage("Paymentfailed");
                updateStatusByPaymentIntent("Payment Failed");
                setDoingPayment(true);
                setCurrentState(3);
                break;

              default:
                setMessage("Paymentfailed");
                updateStatusByPaymentIntent("Payment Failed");
                setDoingPayment(true);
                setCurrentState(3);
                break;
            }
          });
        }
      }
    };
    fetchStripeObject();
  }, [stripeId]);

  const handleGetSandC = async (eventId) => {
    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/stripe/getcands-ezye",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ eventId: parseFloat(eventId) }),
        }
      );
      const json = await response.json();
      setCommerceDetails(json.commerce_array);
      if (json.commerce_array) {
        if (json.commerce_array.currency) {
          if (json.commerce_array.currency.display) {
            setCurrency(json.commerce_array.currency.display);
          }
        }
      }
      if (json.commerce_array.stripe_account_id) {
        if (json.commerce_array.stripe_account_id.id) {
          setStripeId(json.commerce_array.stripe_account_id.id);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (eventId) {
      handleGetEvent(eventId);
      handleGetSandC(eventId);
    }
  }, []);

  const calculateTotalCart = (even) => {
    let total = 0;

    for (const eve of even) {
      total += parseFloat(eve.quantity) * parseFloat(eve.unit_price);
    }

    return total;
  };

  const [rsvpArray, setRsvpArray] = useState({
    rsvp: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
  });

  const [loading, setLoading] = useState(false);

  const handleTicketResponseArray = async () => {
    setLoading(true);
    const rsvp = { ...event };
    if (rsvp.response_array) {
      rsvp.response_array.push(rsvpArray);
    } else {
      rsvp.response_array = [rsvpArray];
    }
    console.log(rsvp.response_array);
    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/web/rsvp-response",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            form_id: event.id,
            response_array: rsvp.response_array,
          }),
        }
      );
      const json = await response.json();
      setLoading(false);
      console.log(json);
    } catch (error) {
      console.log(error);
    }
  };

  const handleShippingCalculation = () => {
    if (commerceDetails) {
      if (commerceDetails.shipping) {
        const countryShippingIndex = commerceDetails.shipping.findIndex(
          (shipping) => shipping.name === shippingDetails.country
        );
        if (countryShippingIndex !== -1) {
          const stateShippingIndex = commerceDetails.shipping[
            countryShippingIndex
          ].region.findIndex((state) => state.region === shippingDetails.state);
          console.log(stateShippingIndex);
          if (stateShippingIndex !== -1) {
            if (
              commerceDetails.shipping[countryShippingIndex].region[
                stateShippingIndex
              ].type === "Flat rate"
            ) {
              return parseFloat(
                commerceDetails.shipping[countryShippingIndex].region[
                  stateShippingIndex
                ].value
              );
            } else {
              return commerceDetails.shipping[countryShippingIndex].region[
                stateShippingIndex
              ].type;
            }
          } else {
            if (
              commerceDetails.shipping[countryShippingIndex].type ===
              "Flat rate"
            ) {
              return parseFloat(
                commerceDetails.shipping[countryShippingIndex].value
              );
            } else {
              return commerceDetails.shipping[countryShippingIndex].type;
            }
          }
        } else {
          if (commerceDetails.shipping[0].type === "Flat rate") {
            return parseFloat(commerceDetails.shipping[0].value);
          } else {
            return commerceDetails.shipping[0].type;
          }
        }
        console.log(countryShippingIndex);
      }
      return "Disable purchase";
    }
  };

  const [clientSecret, setClientSecret] = useState("");

  const appearance = {
    theme: "stripe",
  };

  const options = {
    // pass the client secret from the previous step
    clientSecret: clientSecret,
    // externalPaymentMethodTypes: [ 'external_grabpay_later']
  };

  useEffect(() => {
    if (currentState === 2) {
      if (handleShippingCalculation() !== "Disable purchase") {
        const value = parseFloat(
          calculateTotalCart(customerAccountDetails.cart)
        );

        const handleCreatePaymentIntent = async () => {
          try {
            const response = await fetch(
              "https://ww1.premelo.com/api/stripe/create-payment-intent-ezye",
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  amount: parseFloat(value) * 100,
                  currency: commerceDetails.currency.currency,
                  eventId: eventId,
                  customerDetails: JSON.stringify(customerAccountDetails),
                  shippingDetails: JSON.stringify(shippingDetails),
                  domain: "EzyLink",
                }),
              }
            );
            const json = await response.json();
            setClientSecret(json.clientSecret);
          } catch (error) {
            console.log(error);
          }
        };

        handleCreatePaymentIntent();
      }
    }
  }, [currentState]);

  function formatDate(inputDate) {
    // Parse the input date string
    const date = new Date(inputDate);

    // Define month names
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract day, month, and year
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    // Format the date as "DD Mon, YYYY"
    const formattedDate = `${day} ${monthNames[month]}, ${year}`;

    return formattedDate;
  }

  function formatTime(inputTime) {
    // Split the input time string into hours and minutes
    const [hourStr, minuteStr] = inputTime.split(":");

    // Convert hours and minutes to integers
    const hours = parseInt(hourStr, 10);
    const minutes = parseInt(minuteStr, 10);

    // Determine the period (am or pm)
    const period = hours >= 12 ? "pm" : "am";

    // Format the hours in 12-hour format
    const formattedHours = hours % 12 || 12; // Handle midnight (0) as 12

    // Format the time as "h:mm am/pm"
    const formattedTime = `${formattedHours}.${minutes
      .toString()
      .padStart(2, "0")} ${period}`;

    return formattedTime;
  }

  return (
    <div>
      {doingPayment ? (
        <>
          <div
            style={{
              position: "relative",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
              height: "100vh",
            }}
          >
            <div
              style={{
                display: "flex",
                padding: "0px 30px",
                alignItems: "center",
                position: "relative",
                zIndex: 1,
                boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
              }}
            >
              <img
                style={{ width: "80px", height: "80px", objectFit: "cover" }}
                src={companyLogo ? companyLogo : null}
              />
              <div
                style={{
                  marginLeft: "10px",
                  fontWeight: 700,
                  color: "rgba(0,0,0,0.7)",
                }}
              >
                {companyName}
              </div>
            </div>
            <div
              style={{
                height: "calc(100vh - 115px)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                background: "rgba(200,200,200,0.3)",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              <div
                style={{
                  background: "white",
                  padding: "30px",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                  width: "60%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "20px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        background:
                          currentState >= 0 ? "grey" : "rgba(0,0,0,0.1)",
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <LocalMallIcon
                        style={{
                          color: currentState >= 0 ? "white" : "grey",
                          fontSize: "26px",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        color: currentState >= 0 ? "grey" : "rgba(0,0,0,0.4)",
                        marginTop: "5px",
                        fontSize: "10px",
                      }}
                    >
                      Cart
                    </div>
                  </div>
                  <div
                    style={{
                      background:
                        currentState >= 1 ? "grey" : "rgba(0,0,0,0.1)",
                      height: "8px",
                      width: "70px",
                      borderRadius: "20px",
                      margin: "14px 10px",
                    }}
                  >
                    {currentState === 0 ? (
                      <div
                        className="animation_class"
                        style={{
                          background: "rgba(0,0,0,0.2)",
                          height: "8px",
                          borderRadius: "20px",
                        }}
                      />
                    ) : null}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        background:
                          currentState >= 1 ? "grey" : "rgba(0,0,0,0.1)",
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <LocalShippingIcon
                        style={{
                          color: currentState >= 1 ? "white" : "grey",
                          fontSize: "26px",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        color: currentState >= 1 ? "grey" : "rgba(0,0,0,0.4)",
                        marginTop: "5px",
                        fontSize: "10px",
                      }}
                    >
                      Shipping
                    </div>
                  </div>
                  <div
                    style={{
                      background:
                        currentState >= 2 ? "grey" : "rgba(0,0,0,0.1)",
                      height: "8px",
                      width: "70px",
                      borderRadius: "20px",
                      margin: "14px 10px",
                    }}
                  >
                    {currentState === 1 ? (
                      <div
                        className="animation_class"
                        style={{
                          background: "rgba(0,0,0,0.2)",
                          height: "8px",
                          borderRadius: "20px",
                        }}
                      />
                    ) : null}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        background:
                          currentState >= 2 ? "grey" : "rgba(0,0,0,0.1)",
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <AccountBalanceWalletIcon
                        style={{
                          color: currentState >= 2 ? "white" : "grey",
                          fontSize: "26px",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        color: currentState >= 2 ? "grey" : "rgba(0,0,0,0.4)",
                        marginTop: "5px",
                        fontSize: "10px",
                      }}
                    >
                      Payment
                    </div>
                  </div>
                  <div
                    style={{
                      background:
                        currentState >= 3 ? "grey" : "rgba(0,0,0,0.1)",
                      height: "8px",
                      width: "70px",
                      borderRadius: "20px",
                      margin: "14px 10px",
                    }}
                  >
                    {currentState === 2 ? (
                      <div
                        className="animation_class"
                        style={{
                          background: "rgba(0,0,0,0.2)",
                          height: "8px",
                          borderRadius: "20px",
                        }}
                      />
                    ) : null}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        background:
                          currentState >= 3 ? "grey" : "rgba(0,0,0,0.1)",
                        width: "35px",
                        height: "35px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <VerifiedUserIcon
                        style={{
                          color: currentState >= 3 ? "white" : "grey",
                          fontSize: "26px",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        color: currentState >= 3 ? "grey" : "rgba(0,0,0,0.4)",
                        marginTop: "5px",
                        fontSize: "10px",
                      }}
                    >
                      Confirm
                    </div>
                  </div>
                </div>
                <div>
                  {currentState === 0 ? (
                    <div style={{ fontSize: "12px" }}>
                      <div
                        style={{
                          display: "flex",
                          padding: "10px 0px",
                          width: "100%",
                          borderTop: "1px solid lightgrey",
                          borderBottom: "1px solid lightgrey",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ width: "20%" }}></div>
                        <div style={{ width: "15%" }}>Event</div>
                        <div style={{ width: "15%" }}>Price</div>
                        <div style={{ width: "15%" }}>Quantity</div>
                        <div style={{ width: "15%" }}>Subtotal</div>
                      </div>
                      {customerAccountDetails.cart
                        ? customerAccountDetails.cart.map((product, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                padding: "10px 0px",
                                width: "100%",
                                borderBottom: "1px solid lightgrey",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  width: "20%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <CloseIcon
                                  className="mui-button"
                                  onClick={() => {
                                    const updatingCart = {
                                      ...customerAccountDetails,
                                    };
                                    updatingCart.cart.splice(index, 1);
                                    setCustomerAccountDetails(updatingCart);
                                  }}
                                  style={{ color: "grey", marginRight: "15px" }}
                                />
                                <img
                                  src={product.img_src}
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                              <div style={{ width: "15%" }}>
                                {product.event_name}
                              </div>
                              <div style={{ width: "15%" }}>
                                {currency +
                                  " " +
                                  parseFloat(product.unit_price).toLocaleString(
                                    "en-US",
                                    {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                              </div>
                              <div
                                style={{
                                  width: "15%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <input
                                  style={{
                                    width: "50%",
                                    padding: "5px 15px",
                                    border: "1px solid lightgrey",
                                    outline: "none",
                                  }}
                                  type="number"
                                  value={product.quantity}
                                  min={1}
                                  onChange={(e) => {
                                    const updatingCart = {
                                      ...customerAccountDetails,
                                    };
                                    updatingCart.cart[index].quantity =
                                      parseFloat(e.target.value);
                                    setCustomerAccountDetails(updatingCart);
                                  }}
                                  onBlur={(e) => {
                                    if (
                                      parseFloat(e.target.value) === 0 ||
                                      e.target.value === ""
                                    ) {
                                      const updatingCart = {
                                        ...customerAccountDetails,
                                      };
                                      updatingCart.cart.splice(index, 1);
                                      setCustomerAccountDetails(updatingCart);
                                    }
                                  }}
                                />
                              </div>
                              <div style={{ width: "15%" }}>
                                {currency +
                                  " " +
                                  (
                                    parseFloat(product.quantity) *
                                    parseFloat(product.unit_price)
                                  ).toLocaleString("en-US", {
                                    style: "decimal",
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                  ) : currentState === 1 ? (
                    <div
                      style={{
                        fontSize: "12px",
                        display: "flex",
                        color: "grey",
                      }}
                    >
                      <div
                        style={{
                          width: "calc(60% - 40px)",
                          borderRight: "1px solid lightgrey",
                          padding: "30px 40px 30px 0px",
                        }}
                      >
                        <div>Checkout details</div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "5px 0px",
                          }}
                        >
                          <div style={{ width: "150px" }}>Full name</div>
                          <input
                            value={shippingDetails.full_name}
                            onChange={(e) =>
                              setShippingDetails({
                                ...shippingDetails,
                                full_name: e.target.value,
                              })
                            }
                            placeholder="Full name"
                            style={{
                              borderRadius: "10px",
                              padding: "4px 15px",
                              width: "calc(100% - 180px)",
                              border: "1px solid lightgrey",
                              outline: "none",
                              fontSize: "12px",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "5px 0px",
                          }}
                        >
                          <div style={{ width: "150px" }}>Email</div>
                          <input
                            value={shippingDetails.email_address}
                            onChange={(e) =>
                              setShippingDetails({
                                ...shippingDetails,
                                email_address: e.target.value,
                              })
                            }
                            placeholder="Email address"
                            style={{
                              borderRadius: "10px",
                              padding: "4px 15px",
                              width: "calc(100% - 180px)",
                              border: "1px solid lightgrey",
                              outline: "none",
                              fontSize: "12px",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "5px 0px",
                          }}
                        >
                          <div style={{ width: "150px" }}>Phone number</div>
                          <input
                            value={shippingDetails.phone_number}
                            onChange={(e) =>
                              setShippingDetails({
                                ...shippingDetails,
                                phone_number: e.target.value,
                              })
                            }
                            placeholder="Phone number"
                            style={{
                              borderRadius: "10px",
                              padding: "4px 15px",
                              width: "calc(100% - 180px)",
                              border: "1px solid lightgrey",
                              outline: "none",
                              fontSize: "12px",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "5px 0px",
                          }}
                        >
                          <div style={{ width: "150px" }}>Address</div>
                          <textarea
                            value={shippingDetails.address}
                            onChange={(e) =>
                              setShippingDetails({
                                ...shippingDetails,
                                address: e.target.value,
                              })
                            }
                            placeholder="Address"
                            style={{
                              borderRadius: "10px",
                              padding: "4px 15px",
                              width: "calc(100% - 180px)",
                              resize: "none",
                              height: "80px",
                              border: "1px solid lightgrey",
                              outline: "none",
                              fontSize: "12px",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "5px 0px",
                          }}
                        >
                          <div style={{ width: "150px" }}>Zip code</div>
                          <input
                            value={shippingDetails.zip_code}
                            onChange={(e) =>
                              setShippingDetails({
                                ...shippingDetails,
                                zip_code: e.target.value,
                              })
                            }
                            placeholder="Zip code"
                            style={{
                              borderRadius: "10px",
                              padding: "4px 15px",
                              width: "calc(100% - 180px)",
                              border: "1px solid lightgrey",
                              outline: "none",
                              fontSize: "12px",
                            }}
                          />
                        </div>
                        {shippingDetails.country === "" ||
                        shippingDetails.country === null ? null : (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "5px 0px",
                            }}
                          >
                            <div style={{ width: "150px" }}>State</div>
                            <select
                              placeholder="States"
                              value={shippingDetails.state}
                              onChange={(e) => {
                                const updatingState = { ...shippingDetails };
                                updatingState.state = e.target.value;
                                setShippingDetails(updatingState);
                              }}
                              style={{
                                padding: "4px 15px",
                                width: "calc(100% - 180px)",
                                borderRadius: "10px",
                                border: "1px solid rgba(125, 125, 125,0.5)",
                                border: "1px solid lightgrey",
                                outline: "none",
                                fontSize: "12px",
                              }}
                            >
                              {State.getStatesOfCountry(
                                shippingDetails.country
                              ).map((state) => (
                                <option value={state.isoCode}>
                                  {state.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "5px 0px",
                          }}
                        >
                          <div style={{ width: "150px" }}>Country</div>
                          <select
                            placeholder="Country"
                            value={shippingDetails.country}
                            onChange={(e) => {
                              const updatingCountry = { ...shippingDetails };
                              updatingCountry.country = e.target.value;
                              updatingCountry.state = State.getStatesOfCountry(
                                e.target.value
                              )[0].isoCode;
                              console.log(
                                State.getStatesOfCountry(e.target.value)
                              );
                              setShippingDetails(updatingCountry);
                            }}
                            style={{
                              padding: "4px 15px",
                              width: "calc(100% - 180px)",
                              borderRadius: "10px",
                              border: "1px solid rgba(125, 125, 125,0.5)",
                              border: "1px solid lightgrey",
                              outline: "none",
                              fontSize: "12px",
                            }}
                          >
                            {Country.getAllCountries().map((country) => (
                              <option value={country.isoCode}>
                                {country.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "calc(40% - 40px)",
                          padding: "30px 0px 30px 40px",
                        }}
                      >
                        <div style={{ marginBottom: "20px" }}>Cart totals</div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "10px 0px",
                            width: "100%",
                            borderTop: "1px solid lightgrey",
                            borderBottom: "1px solid lightgrey",
                          }}
                        >
                          <div>Subtotal</div>
                          <div>
                            {customerAccountDetails.cart
                              ? currency +
                                " " +
                                calculateTotalCart(
                                  customerAccountDetails.cart
                                ).toLocaleString("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : `${currency} 0.00`}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            padding: "10px 0px",
                            width: "100%",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>Total</div>
                          <div>
                            {customerAccountDetails.cart
                              ? currency +
                                " " +
                                calculateTotalCart(
                                  customerAccountDetails.cart
                                ).toLocaleString("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : `${currency} 0.00`}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : currentState === 2 ? (
                    clientSecret ? (
                      stripeObject ? (
                        <Elements options={options} stripe={stripeObject}>
                          <CheckoutForm />
                        </Elements>
                      ) : null
                    ) : null
                  ) : currentState === 3 ? (
                    <div>
                      <div
                        style={{
                          fontSize: "24px",
                          color: "rgba(0,0,0,0.6)",
                          fontWeight: 700,
                          textAlign: "center",
                          marginTop: "40px",
                        }}
                      >
                        {message === "Success"
                          ? "Payment Successful!"
                          : message === "Processing"
                          ? "Payment Processing"
                          : "Payment Failed"}
                      </div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div
                          style={{
                            fontSize: "12px",
                            color: "rgba(0,0,0,0.3)",
                            fontWeight: 400,
                            textAlign: "center",
                            maxWidth: "500px",
                            margin: "10px 0px 40px 0px",
                          }}
                        >
                          {message === "Success"
                            ? "Your payment is successful, you should receive an email shortly notifying that your payment has successfully gone through and our team is preparing your order"
                            : message === "Processing"
                            ? "Your payment is current being processed and you should receive an notification on your email as soon as we received your order payment."
                            : "Sadly your payment didn't went through, you can reach your banking provider and check on why the payment didn't went through, if you would like to retry the payment, go ahead an try placing another order."}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                {currentState === 3 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className="mui-button"
                      onClick={() => {
                        setDoingPayment(false);
                        const urlSearchParams = new URLSearchParams(
                          window.location.search
                        );
                        urlSearchParams.delete("payment_intent");
                        urlSearchParams.delete("payment_intent_client_secret");
                        urlSearchParams.delete("redirect_status");

                        // Replace the URL with the modified one (without the parameter)
                        window.history.replaceState(
                          null,
                          null,
                          `?${urlSearchParams.toString()}`
                        );
                      }}
                      style={{
                        padding: "4px 30px",
                        borderRadius: "20px",
                        border: "1px solid grey",
                        color: "grey",
                        background: "transparent",
                        textAlign: "center",
                      }}
                    >
                      Back to website
                    </div>
                  </div>
                ) : null}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  <div>
                    {currentState !== 0 && currentState !== 3 ? (
                      <div
                        className="mui-button"
                        onClick={() => {
                          setCurrentState(currentState - 1);
                        }}
                        style={{
                          padding: "4px 30px",
                          borderRadius: "20px",
                          border: "1px solid grey",
                          color: "grey",
                          background: "transparent",
                        }}
                      >
                        Back
                      </div>
                    ) : currentState === 0 ? (
                      <div
                        className="mui-button"
                        onClick={() => {
                          setDoingPayment(false);
                        }}
                        style={{
                          padding: "4px 30px",
                          borderRadius: "20px",
                          border: "1px solid grey",
                          color: "grey",
                          background: "transparent",
                        }}
                      >
                        Back
                      </div>
                    ) : null}
                  </div>
                  <div>
                    {currentState < 2 ? (
                      currentState === 0 ? (
                        <>
                          <div
                            className="mui-button"
                            onClick={() => {
                              setCurrentState(currentState + 1);
                              console.log(customerAccountDetails);
                            }}
                            style={{
                              padding: "4px 30px",
                              borderRadius: "20px",
                              border: "1px solid grey",
                              color: "white",
                              background: "grey",
                            }}
                          >
                            Next
                          </div>
                        </>
                      ) : currentState === 1 ? (
                        shippingDetails.full_name !== "" &&
                        shippingDetails.email_address !== "" &&
                        shippingDetails.phone_number !== "" &&
                        shippingDetails.address !== "" &&
                        shippingDetails.zip_code !== "" &&
                        shippingDetails.state !== "" &&
                        shippingDetails.country !== "" ? (
                          <div
                            className="mui-button"
                            onClick={() => {
                              setCurrentState(currentState + 1);
                            }}
                            style={{
                              padding: "4px 30px",
                              borderRadius: "20px",
                              border: "1px solid grey",
                              color: "white",
                              background: "grey",
                            }}
                          >
                            Proceed to payment
                          </div>
                        ) : null
                      ) : (
                        <div
                          className="mui-button"
                          onClick={() => {
                            setCurrentState(currentState + 1);
                          }}
                          style={{
                            padding: "4px 30px",
                            borderRadius: "20px",
                            border: "1px solid grey",
                            color: "white",
                            background: "grey",
                          }}
                        >
                          Confirm
                        </div>
                      )
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                fontSize: "10px",
                textAlign: "center",
                padding: "10px",
                background: "white",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
              }}
            >
              All rights reserved © {companyName ? companyName : null} | Build
              with Premelo
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              backgroundImage: `linear-gradient(to right, ${companyColor}1A, ${companyColor}7A)`,
              position: "relative",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
            }}
          >
            <img
              src={event ? event.img_src : null}
              style={{
                objectFit: "cover",
                height: "100%",
                width: "100%",
                position: "absolute",
                opacity: 0.3,
                filter: "blur(15px)",
              }}
            />
            <div
              style={{
                display: "flex",
                padding: "0px 30px",
                alignItems: "center",
                position: "absolute",
                zIndex: 1,
              }}
            >
              <img
                style={{ width: "80px", height: "80px", objectFit: "cover" }}
                src={companyLogo ? companyLogo : null}
              />
              <div
                style={{
                  marginLeft: "10px",
                  fontWeight: 700,
                  color: "rgba(0,0,0,0.7)",
                }}
              >
                {companyName}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                zIndex: 1,
              }}
            >
              <div style={{ width: "36%", padding: "0px 7%" }}>
                <div
                  style={{
                    fontWeight: 700,
                    color: "rgba(0,0,0,0.8)",
                    fontSize: "37px",
                    marginTop: "15%",
                  }}
                >
                  {event ? event.event_name : null}
                </div>
                <div
                  style={{
                    fontWeight: 400,
                    color: "rgba(0,0,0,0.7)",
                    fontSize: "14px",
                    lineHeight: "15px",
                  }}
                >
                  {event ? event.description : null}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <div
                    style={{
                      margin: "5px 15px 0px 0px",
                      fontWeight: 400,
                      color: "rgba(0,0,0,0.7)",
                      fontSize: "14px",
                      lineHeight: "15px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <EventIcon
                      style={{
                        color: "rgba(0,0,0,0.7)",
                        fontSize: "14px",
                        marginRight: "5px",
                      }}
                    />
                    {event ? formatDate(event.date) : null}
                  </div>
                  <div
                    style={{
                      margin: "5px 0px 0px 0px",
                      fontWeight: 400,
                      color: "rgba(0,0,0,0.7)",
                      fontSize: "14px",
                      lineHeight: "15px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <AccessTimeIcon
                      style={{
                        color: "rgba(0,0,0,0.7)",
                        fontSize: "14px",
                        marginRight: "5px",
                      }}
                    />
                    {event ? formatTime(event.time) : null}
                  </div>
                </div>

                {event ? (
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div style={{ marginTop: "10px", marginRight: "10px" }}>
                      <div style={{ marginTop: "0px" }}>
                        <select
                          style={{
                            background: "transparent",
                            borderRadius: "30px",
                            padding: "5px 15px",
                          }}
                          onChange={(e) => {
                            setSelectedTicketIndex(e.target.value);
                            setRsvpArray({
                              ...rsvpArray,
                              rsvp: event.ticket[e.target.value].name,
                            });
                          }}
                          value={selectedTicketIndex}
                        >
                          <option value={null}>Select a ticket</option>
                          {event.ticket.map((ticket, index) => (
                            <option key={index} value={index}>
                              {ticket.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div>
                  {selectedTicketIndex ? (
                    event.ticket ? (
                      event.ticket[selectedTicketIndex] ? (
                        event.ticket[selectedTicketIndex].type === "rsvp" ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "15px",
                            }}
                          >
                            <div style={{}}>Rsvp details</div>
                            <form
                              onSubmit={(e) => {
                                e.preventDefault();
                                handleTicketResponseArray();
                              }}
                            >
                              <div style={{ display: "flex" }}>
                                <div
                                  style={{
                                    marginTop: "5px",
                                    marginRight: "10px",
                                  }}
                                >
                                  <input
                                    value={rsvpArray.first_name}
                                    onChange={(e) => {
                                      setRsvpArray({
                                        ...rsvpArray,
                                        first_name: e.target.value,
                                      });
                                    }}
                                    required
                                    placeholder="First name"
                                    style={{
                                      background: "transparent",
                                      padding: "4px 18px",
                                      borderRadius: "30px",
                                      border: "1px solid grey",
                                      outline: "none",
                                    }}
                                  />
                                </div>
                                <div style={{ marginTop: "5px" }}>
                                  <input
                                    value={rsvpArray.last_name}
                                    onChange={(e) => {
                                      setRsvpArray({
                                        ...rsvpArray,
                                        last_name: e.target.value,
                                      });
                                    }}
                                    required
                                    placeholder="Last name"
                                    style={{
                                      background: "transparent",
                                      padding: "4px 18px",
                                      borderRadius: "30px",
                                      border: "1px solid grey",
                                      outline: "none",
                                    }}
                                  />
                                </div>
                              </div>
                              <div style={{ display: "flex" }}>
                                <div
                                  style={{
                                    marginTop: "5px",
                                    marginRight: "10px",
                                  }}
                                >
                                  <input
                                    value={rsvpArray.email}
                                    onChange={(e) => {
                                      setRsvpArray({
                                        ...rsvpArray,
                                        email: e.target.value,
                                      });
                                    }}
                                    required
                                    placeholder="Email"
                                    style={{
                                      background: "transparent",
                                      padding: "4px 18px",
                                      borderRadius: "30px",
                                      border: "1px solid grey",
                                      outline: "none",
                                    }}
                                  />
                                </div>
                                <div style={{ marginTop: "5px" }}>
                                  <input
                                    value={rsvpArray.phone_number}
                                    onChange={(e) => {
                                      setRsvpArray({
                                        ...rsvpArray,
                                        phone_number: e.target.value,
                                      });
                                    }}
                                    required
                                    placeholder="Phone number"
                                    style={{
                                      background: "transparent",
                                      padding: "4px 18px",
                                      borderRadius: "30px",
                                      border: "1px solid grey",
                                      outline: "none",
                                    }}
                                  />
                                </div>
                              </div>
                              <div style={{ marginTop: "10px" }}>
                                <button
                                  type="submit"
                                  className="mui-button"
                                  style={{
                                    fontWeight: 700,
                                    color: "rgba(0,0,0,0.7)",
                                    fontSize: "14px",
                                    lineHeight: "15px",
                                    background: companyColor,
                                    padding: "10px 25px",
                                    color: "white",
                                    borderRadius: "30px",
                                    border: "none",
                                    outline: "none",
                                  }}
                                >
                                  Submit
                                </button>
                              </div>
                            </form>
                          </div>
                        ) : (
                          <div>
                            <div style={{ marginTop: "15px" }}>
                              {selectedTicketIndex ? (
                                event.ticket[selectedTicketIndex] ? (
                                  <div
                                    style={{
                                      fontWeight: 700,
                                      color: "rgba(0,0,0,0.8)",
                                      fontSize: "25px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    {currency +
                                      " " +
                                      parseFloat(
                                        event.ticket[selectedTicketIndex].price
                                      ).toLocaleString("en-US", {
                                        style: "decimal",
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })}
                                  </div>
                                ) : (
                                  <div
                                    style={{
                                      fontWeight: 400,
                                      color: "rgba(0,0,0,0.4)",
                                      fontSize: "10px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Select ticket to see price
                                  </div>
                                )
                              ) : (
                                <div style={{}}>Select ticket to see price</div>
                              )}
                            </div>

                            <div
                              style={{
                                marginTop: "15px",
                                display: "flex",
                              }}
                            >
                              {selectedTicketIndex ? (
                                event.ticket[selectedTicketIndex] ? (
                                  <div
                                    className="mui-button"
                                    onClick={() => {
                                      handleAddToCart(event);
                                      setDoingPayment(true);
                                    }}
                                    style={{
                                      fontWeight: 700,
                                      color: "rgba(0,0,0,0.7)",
                                      fontSize: "14px",
                                      lineHeight: "15px",
                                      background: companyColor,
                                      padding: "10px 25px",
                                      color: "white",
                                      borderRadius: "30px",
                                      border: "none",
                                      outline: "none",
                                    }}
                                  >
                                    Buy now
                                  </div>
                                ) : null
                              ) : null}
                            </div>
                          </div>
                        )
                      ) : null
                    ) : null
                  ) : null}
                </div>
              </div>
              <div style={{ width: "50%", height: "70vh" }}>
                <img
                  src={event ? event.img_src : null}
                  style={{
                    objectFit: "cover",
                    height: "70vh",
                    width: "100%",
                  }}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              padding: "40px 10px 40px 10px",
              background: "rgba(200,200,200, 0.15)",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
            }}
          >
            <div
              style={{
                fontWeight: 700,
                color: "rgba(0,0,0,0.8)",
                fontSize: "32px",
                textAlign: "center",
              }}
            >
              Related Events
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                marginTop: "15px",
              }}
            >
              {eventArray
                ? eventArray.slice(0, 4).map((eve, index) => (
                    <div
                      onClick={() => handleEventClick(eve.id)}
                      className="mui-button"
                      key={index}
                      style={{
                        borderRadius: "8px",
                        overflow: "hidden",
                        width: "18%",
                        boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                        color: "rgba(0,0,0,0.6)",
                      }}
                    >
                      <img
                        style={{
                          objectFit: "cover",
                          width: "100%",
                          height: "180px",
                        }}
                        src={eve.img_src}
                      />
                      <div
                        style={{
                          color: "rgba(0,0,0,0.6)",
                          textAlign: "center",
                          fontSize: "14px",
                          padding: "10px",
                        }}
                      >
                        {eve.event_name.slice(0, 18) +
                          (eve.event_name.length > 18 ? " ..." : "")}
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
          <div
            style={{
              fontSize: "10px",
              textAlign: "center",
              padding: "10px",
              background: "white",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
            }}
          >
            All rights reserved © {companyName ? companyName : null} | Build
            with Premelo
          </div>
        </>
      )}
    </div>
  );
};

export default EventEzyLink;
