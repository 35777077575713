import React, { useState, useEffect } from "react";
import logo from "./images/CS_Favicon-01.png";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { fallDown as Menu } from "react-burger-menu";
import Hamburger from "hamburger-react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const TandCMobile = () => {
  const navigate = useNavigate();
  const [primaryColor, setPrimaryColor] = useState("#5AB1BA");
  const CustomTabs = styled(Tabs)({
    borderBottom: "1px solid #e8e8e8",
    "& .MuiTabs-indicator": {
      backgroundColor: "#d81b5e",
    },
    "& .MuiTabs-indicator:hover": {
      backgroundColor: "grey",
    },
    "& .MuiButtonBase-root": {
      paddingBottom: "0px",
    },
  });
  const CustomTab = styled(Tab)({
    textTransform: "none",
    fontWeight: "800",
    fontSize: "10px",
    height: "20px",
    color: "rgba(0,0,0,0.4)",
    "&:hover": {
      color: "grey",
      opacity: 1,
    },
    "&.Mui-selected": {
      color: "#d81b5e",
      fontSize: "10px",
    },
    "& .MuiButtonBase-root": {
      padding: "0px",
    },
  });
  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        style={{ height: "20px" }}
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <div>{children}</div>}
      </div>
    );
  };

  const a11yProps = (index) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  };
  const [value, setValue] = useState(1);

  const handleChange = (event, newValue) => {
    console.log("newValue:", newValue);
    setValue(newValue);
  };

  const [isOpen, setIsOpen] = useState(false);

  function handleToggleMenu() {
    setIsOpen(!isOpen);
  }

  return (
    <div id="outer-container" style={{ overflowX: "hidden" }}>
      <Menu
        right
        width={"100%"}
        isOpen={isOpen}
        customCrossIcon={false}
        customBurgerIcon={
          <Hamburger toggled={isOpen} toggle={handleToggleMenu} size={24} />
        }
      >
        <div>
          <div
            onClick={() => {
              navigate("/");
              window.scrollTo(0, 0);
            }}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              style={{
                color: "rgba(0,0,0,0.7)",
                textDecoration: "none",
                padding: "5px 0px",
              }}
            >
              Home
            </div>
            <NavigateNextIcon />
          </div>
          <hr
            style={{
              width: "100%",
              height: "0.01rem",
            }}
          />
          <div
            onClick={() => {
              navigate("/faq");
              window.scrollTo(0, 0);
            }}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              style={{
                color: "rgba(0,0,0,0.7)",
                textDecoration: "none",
                padding: "5px 0px",
              }}
            >
              Frequently Asked Questions
            </div>
            <NavigateNextIcon />
          </div>
          <hr
            style={{
              width: "100%",
              height: "0.01rem",
            }}
          />
          <div
            onClick={() => {
              navigate("/contactus");
              window.scrollTo(0, 0);
            }}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              style={{
                color: "rgba(0,0,0,0.7)",
                textDecoration: "none",
                padding: "5px 0px",
              }}
            >
              Contact Us
            </div>
            <NavigateNextIcon />
          </div>
          <hr
            style={{
              width: "100%",
              height: "0.01rem",
            }}
          />
          <div
            onClick={() => setIsOpen(false)}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              style={{
                color: "rgba(0,0,0,0.7)",
                textDecoration: "none",
                padding: "5px 0px",
              }}
            >
              Terms & Privacy
            </div>
            <NavigateNextIcon />
          </div>
          <div
            style={{
              width: "90%",
              textAlign: "center",
              padding: "15px 20px",
              background: "#d81b5e",
              color: "white",
              marginTop:"35px"
            }}
            onClick={() => {
              navigate("/login");
              window.scrollTo(0, 0);
            }}
          >
            Login
          </div>
          <div
            style={{
              width: "90%",
              textAlign: "center",
              padding: "15px 20px",
              border: "1px solid #d81b5e",
              color: "#d81b5e",
              marginTop:"15px"
            }}
            onClick={() => {
              navigate("/signup");
              window.scrollTo(0, 0);
            }}
          >
            Sign Up
          </div>
        </div>
      </Menu>
      <div style={{ width: "100%" }}>
        <div
          style={{
            background: "white",
            position: "fixed",
            zIndex: "2000",
            top: 0,
            height: "7vh",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
          }}
        >
          <div
            onClick={() => {
              navigate("/");
              window.scrollTo(0, 0);
            }}
            style={{
              height: "6vh",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "1px 0px",
              marginLeft: "20px",
            }}
          >
            <img src={logo} alt="Premelo logo" style={{ width: "50px" }} />
            <p
              style={{
                fontSize: "25px",
                fontWeight: "600",
                color: "black",
                marginTop: "18px",
              }}
            >
              Premelo
            </p>
          </div>
        </div>
        {/* first segment */}
        <div
          style={{
            marginTop: "7vh",
            padding: "50px 0px 0px 0px",
            textAlign: "center",
            background: "#d5dee0",
          }}
        >
          <div style={{ fontSize: "35px", fontWeight: "600" }}>
            Terms and Privacy
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginTop: "5px",
            }}
          >
            <div style={{ fontSize: "12px", fontWeight: "300", width: "90%" }}>
              We protect your data. Please read our Terms and Privacy Policy for
              more information.
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "50px",
            }}
          >
            <CustomTabs
              value={value}
              onChange={handleChange}
              aria-label="tabs"
              sx={{
                justifyContent: "flex-start",
                marginTop: "-30px",
                fontSize: "12px",
                height: "20px",
              }}
            >
              <CustomTab label="Privacy Policy" {...a11yProps(0)} />
              <CustomTab label="Cookie Policy" {...a11yProps(1)} />
              <CustomTab label="Terms & Conditions" {...a11yProps(2)} />
              <CustomTab label="Disclaimer" {...a11yProps(3)} />
            </CustomTabs>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "12px",
            fontWeight: "300",
            padding: "40px 0px",
            textAlign: "center",
            height: "60vh",
            overflowY: "scroll",
          }}
        >
          <div style={{ width: "70%" }}>
            <TabPanel value={value} index={0}>
              <div>
                <div
                  style={{
                    fontWeight: "600",
                    fontSize: "20px",
                    padding: "20px 0px",
                  }}
                >
                  Interpretation and Definitions
                </div>
                Interpretation The words of which the initial letter is
                capitalized have meanings defined under the following
                conditions. The following definitions shall have the same
                meaning regardless of whether they appear in singular or in
                plural. Definitions For the purposes of this Privacy Policy:
                means a unique account created for You to access our Service or
                parts of our Service. means an entity that controls, is
                controlled by or is under common control with a party, where
                "control" means ownership of 50% or more of the shares, equity
                interest or other securities entitled to vote for election of
                directors or other managing authority. refers to Premelo, the
                software program provided by the Company. (referred to as either
                "the Company", "We", "Us" or "Our" in this Agreement) refers to
                Cayden Studios (M) Sdn Bhd, No. 13, Jalan Angklung 33/20, Shah
                Alam Technology Park, Section 33, Shah Alam Selangor Malaysia.
                are small files that are placed on Your computer, mobile device
                or any other device by a website, containing the details of Your
                browsing history on that website among its many uses. refers to:
                Malaysia means any device that can access the Service such as a
                computer, a cellphone or a digital tablet. Personal Data is any
                information that relates to an identified or identifiable
                individual. refers to the Application or the Website or both.
                Service Provider means any natural or legal person who processes
                the data on behalf of the Company. It refers to third-party
                companies or individuals employed by the Company to facilitate
                the Service, to provide the Service on behalf of the Company, to
                perform services related to the Service or to assist the Company
                in analyzing how the Service is used. Usage Data refers to data
                collected automatically, either generated by the use of the
                Service or from the Service infrastructure itself (for example,
                the duration of a page visit). refers to Premelo, accessible
                from https://premelo.app means the individual accessing or using
                the Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable. Collecting and Using Your Personal Data Types of
                Data Collected Personal Data While using Our Service, We may ask
                You to provide Us with certain personally identifiable
                information that can be used to contact or identify You.
                Personally identifiable information may include, but is not
                limited to: Email address First name and last name Phone number
                Address, State, Province, ZIP/Postal code, City Usage Data Usage
                Data Usage Data is collected automatically when using the
                Service. Usage Data may include information such as Your
                Device's Internet Protocol address (e.g. IP address), browser
                type, browser version, the pages of our Service that You visit,
                the time and date of Your visit, the time spent on those pages,
                unique device identifiers and other diagnostic data. When You
                access the Service by or through a mobile device, We may collect
                certain information automatically, including, but not limited
                to, the type of mobile device You use, Your mobile device unique
                ID, the IP address of Your mobile device, Your mobile operating
                system, the type of mobile Internet browser You use, unique
                device identifiers and other diagnostic data. We may also
                collect information that Your browser sends whenever You visit
                our Service or when You access the Service by or through a
                mobile device. Information Collected while Using the Application
                While using Our Application, in order to provide features of Our
                Application, We may collect, with Your prior permission:
                Pictures and other information from your Device's camera and
                photo library We use this information to provide features of Our
                Service, to improve and customize Our Service. The information
                may be uploaded to the Company's servers and/or a Service
                Provider's server or it may be simply stored on Your device. You
                can enable or disable access to this information at any time,
                through Your Device settings. Tracking Technologies and Cookies
                We use Cookies and similar tracking technologies to track the
                activity on Our Service and store certain information. Tracking
                technologies used are beacons, tags, and scripts to collect and
                track information and to improve and analyze Our Service. The
                technologies We use may include: Cookies or Browser Cookies. A
                cookie is a small file placed on Your Device. You can instruct
                Your browser to refuse all Cookies or to indicate when a Cookie
                is being sent. However, if You do not accept Cookies, You may
                not be able to use some parts of our Service. Unless you have
                adjusted Your browser setting so that it will refuse Cookies,
                our Service may use Cookies. Web Beacons. Certain sections of
                our Service and our emails may contain small electronic files
                known as web beacons (also referred to as clear gifs, pixel
                tags, and single-pixel gifs) that permit the Company, for
                example, to count users who have visited those pages or opened
                an email and for other related website statistics (for example,
                recording the popularity of a certain section and verifying
                system and server integrity). Cookies can be "Persistent" or
                "Session" Cookies. Persistent Cookies remain on Your personal
                computer or mobile device when You go offline, while Session
                Cookies are deleted as soon as You close Your web browser. You
                can learn more about cookies on TermsFeed website article. We
                use both Session and Persistent Cookies for the purposes set out
                below: Necessary / Essential Cookies Type: Session Cookies
                Administered by: Us Purpose: These Cookies are essential to
                provide You with services available through the Website and to
                enable You to use some of its features. They help to
                authenticate users and prevent fraudulent use of user accounts.
                Without these Cookies, the services that You have asked for
                cannot be provided, and We only use these Cookies to provide You
                with those services. Cookies Policy / Notice Acceptance Cookies
                Type: Persistent Cookies Administered by: Us Purpose: These
                Cookies identify if users have accepted the use of cookies on
                the Website. Functionality Cookies Type: Persistent Cookies
                Administered by: Us Purpose: These Cookies allow us to remember
                choices You make when You use the Website, such as remembering
                your login details or language preference. The purpose of these
                Cookies is to provide You with a more personal experience and to
                avoid You having to re-enter your preferences every time You use
                the Website. For more information about the cookies we use and
                your choices regarding cookies, please visit our Cookies Policy
                or the Cookies section of our Privacy Policy. Use of Your
                Personal Data The Company may use Personal Data for the
                following purposes: To provide and maintain our Service,
                including to monitor the usage of our Service. To manage Your
                Account: to manage Your registration as a user of the Service.
                The Personal Data You provide can give You access to different
                functionalities of the Service that are available to You as a
                registered user. For the performance of a contract: the
                development, compliance and undertaking of the purchase contract
                for the products, items or services You have purchased or of any
                other contract with Us through the Service. To contact You: To
                contact You by email, telephone calls, SMS, or other equivalent
                forms of electronic communication, such as a mobile
                application's push notifications regarding updates or
                informative communications related to the functionalities,
                products or contracted services, including the security updates,
                when necessary or reasonable for their implementation. To
                provide You with news, special offers and general information
                about other goods, services and events which we offer that are
                similar to those that you have already purchased or enquired
                about unless You have opted not to receive such information. To
                manage Your requests: To attend and manage Your requests to Us.
                For business transfers: We may use Your information to evaluate
                or conduct a merger, divestiture, restructuring, reorganization,
                dissolution, or other sale or transfer of some or all of Our
                assets, whether as a going concern or as part of bankruptcy,
                liquidation, or similar proceeding, in which Personal Data held
                by Us about our Service users is among the assets transferred.
                For other purposes: We may use Your information for other
                purposes, such as data analysis, identifying usage trends,
                determining the effectiveness of our promotional campaigns and
                to evaluate and improve our Service, products, services,
                marketing and your experience. We may share Your personal
                information in the following situations: With Service Providers:
                We may share Your personal information with Service Providers to
                monitor and analyze the use of our Service, to contact You. For
                business transfers: We may share or transfer Your personal
                information in connection with, or during negotiations of, any
                merger, sale of Company assets, financing, or acquisition of all
                or a portion of Our business to another company. With
                Affiliates: We may share Your information with Our affiliates,
                in which case we will require those affiliates to honor this
                Privacy Policy. Affiliates include Our parent company and any
                other subsidiaries, joint venture partners or other companies
                that We control or that are under common control with Us. With
                business partners: We may share Your information with Our
                business partners to offer You certain products, services or
                promotions. With other users: when You share personal
                information or otherwise interact in the public areas with other
                users, such information may be viewed by all users and may be
                publicly distributed outside. With Your consent: We may disclose
                Your personal information for any other purpose with Your
                consent. Retention of Your Personal Data The Company will retain
                Your Personal Data only for as long as is necessary for the
                purposes set out in this Privacy Policy. We will retain and use
                Your Personal Data to the extent necessary to comply with our
                legal obligations (for example, if we are required to retain
                your data to comply with applicable laws), resolve disputes, and
                enforce our legal agreements and policies. The Company will also
                retain Usage Data for internal analysis purposes. Usage Data is
                generally retained for a shorter period of time, except when
                this data is used to strengthen the security or to improve the
                functionality of Our Service, or We are legally obligated to
                retain this data for longer time periods. Transfer of Your
                Personal Data Your information, including Personal Data, is
                processed at the Company's operating offices and in any other
                places where the parties involved in the processing are located.
                It means that this information may be transferred to — and
                maintained on — computers located outside of Your state,
                province, country or other governmental jurisdiction where the
                data protection laws may differ than those from Your
                jurisdiction. Your consent to this Privacy Policy followed by
                Your submission of such information represents Your agreement to
                that transfer. The Company will take all steps reasonably
                necessary to ensure that Your data is treated securely and in
                accordance with this Privacy Policy and no transfer of Your
                Personal Data will take place to an organization or a country
                unless there are adequate controls in place including the
                security of Your data and other personal information. Delete
                Your Personal Data You have the right to delete or request that
                We assist in deleting the Personal Data that We have collected
                about You. Our Service may give You the ability to delete
                certain information about You from within the Service. You may
                update, amend, or delete Your information at any time by signing
                in to Your Account, if you have one, and visiting the account
                settings section that allows you to manage Your personal
                information. You may also contact Us to request access to,
                correct, or delete any personal information that You have
                provided to Us. Please note, however, that We may need to retain
                certain information when we have a legal obligation or lawful
                basis to do so. Disclosure of Your Personal Data Business
                Transactions If the Company is involved in a merger, acquisition
                or asset sale, Your Personal Data may be transferred. We will
                provide notice before Your Personal Data is transferred and
                becomes subject to a different Privacy Policy. Law enforcement
                Under certain circumstances, the Company may be required to
                disclose Your Personal Data if required to do so by law or in
                response to valid requests by public authorities (e.g. a court
                or a government agency). Other legal requirements The Company
                may disclose Your Personal Data in the good faith belief that
                such action is necessary to: Comply with a legal obligation
                Protect and defend the rights or property of the Company Prevent
                or investigate possible wrongdoing in connection with the
                Service Protect the personal safety of Users of the Service or
                the public Protect against legal liability Security of Your
                Personal Data The security of Your Personal Data is important to
                Us, but remember that no method of transmission over the
                Internet, or method of electronic storage is 100% secure. While
                We strive to use commercially acceptable means to protect Your
                Personal Data, We cannot guarantee its absolute security.
                Children's Privacy Our Service does not address anyone under the
                age of 13. We do not knowingly collect personally identifiable
                information from anyone under the age of 13. If You are a parent
                or guardian and You are aware that Your child has provided Us
                with Personal Data, please contact Us. If We become aware that
                We have collected Personal Data from anyone under the age of 13
                without verification of parental consent, We take steps to
                remove that information from Our servers. If We need to rely on
                consent as a legal basis for processing Your information and
                Your country requires consent from a parent, We may require Your
                parent's consent before We collect and use that information.
                Links to Other Websites Our Service may contain links to other
                websites that are not operated by Us. If You click on a third
                party link, You will be directed to that third party's site. We
                strongly advise You to review the Privacy Policy of every site
                You visit. We have no control over and assume no responsibility
                for the content, privacy policies or practices of any third
                party sites or services. Changes to this Privacy Policy We may
                update Our Privacy Policy from time to time. We will notify You
                of any changes by posting the new Privacy Policy on this page.
                We will let You know via email and/or a prominent notice on Our
                Service, prior to the change becoming effective and update the
                "Last updated" date at the top of this Privacy Policy. You are
                advised to review this Privacy Policy periodically for any
                changes. Changes to this Privacy Policy are effective when they
                are posted on this page. Contact Us If you have any questions
                about this Privacy Policy, You can contact us: By email:
                info@premelo.com
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <div>
                <div
                  style={{
                    fontWeight: "600",
                    fontSize: "20px",
                    padding: "20px 0px",
                  }}
                >
                  Cookie Policies
                </div>
                This Cookie Policy explains how Cayden Studios (M) Sdn Bhd,
                doing business as Premelo ("Company,” "we,” "us,” and "our")
                uses cookies and similar technologies to recognize you when you
                visit our websites at https://premelo.app("Websites"). It
                explains what these technologies are and why we use them, as
                well as your rights to control our use of them. In some cases we
                may use cookies to collect personal information, or that becomes
                personal information if we combine it with other information.
                What are cookies? Cookies are small data files that are placed
                on your computer or mobile device when you visit a website.
                Cookies are widely used by website owners in order to make their
                websites work, or to work more efficiently, as well as to
                provide reporting information. Cookies set by the website owner
                (in this case, Cayden Studios (M) Sdn Bhd) are called "first
                party cookies". Cookies set by parties other than the website
                owner are called "third party cookies". Third party cookies
                enable third party features or functionality to be provided on
                or through the website (e.g. like advertising, interactive
                content and analytics). The parties that set these third party
                cookies can recognize your computer both when it visits the
                website in question and also when it visits certain other
                websites. Why do we use cookies? We use first and third party
                cookies for several reasons. Some cookies are required for
                technical reasons in order for our Websites to operate, and we
                refer to these as "essential" or "strictly necessary" cookies.
                Other cookies also enable us to track and target the interests
                of our users to enhance the experience on our Online Properties.
                Third parties serve cookies through our Websites for
                advertising, analytics and other purposes. This is described in
                more detail below. The specific types of first and third party
                cookies served through our Websites and the purposes they
                perform are described below (please note that the specific
                cookies served may vary depending on the specific Online
                Properties you visit): How can I control cookies? You have the
                right to decide whether to accept or reject cookies. You can
                exercise your cookie rights by setting your preferences in the
                Cookie Consent Manager. The Cookie Consent Manager allows you to
                select which categories of cookies you accept or reject.
                Essential cookies cannot be rejected as they are strictly
                necessary to provide you with services. The Cookie Consent
                Manager can be found in the notification banner and on our
                website. If you choose to reject cookies, you may still use our
                website though your access to some functionality and areas of
                our website may be restricted. You may also set or amend your
                web browser controls to accept or refuse cookies. As the means
                by which you can refuse cookies through your web browser
                controls vary from browser-to-browser, you should visit your
                browser's help menu for more information. In addition, most
                advertising networks offer you a way to opt out of targeted
                advertising. If you would like to find out more information,
                please visit http://www.aboutads.info/choices/ or
                http://www.youronlinechoices.com. The specific types of first
                and third party cookies served through our Websites and the
                purposes they perform are described in the table below (please
                note that the specific cookies served may vary depending on the
                specific Online Properties you visit): Essential website
                cookies: These cookies are strictly necessary to provide you
                with services available through our Websites and to use some of
                its features, such as access to secure areas. Name: Cayden
                Studios (M) Sdn Bhd Purpose: To access certain platform
                functionality Provider: https://premelo.app Service: Please
                refer to Premelo Privacy Policy Country: Malaysia Expires in: As
                long as the user has an account with us Performance and
                functionality cookies: These cookies are used to enhance the
                performance and functionality of our Websites but are
                non-essential to their use. However, without these cookies,
                certain functionality (like videos) may become unavailable.
                Name: Cayden Studios (M) Sdn Bhd Purpose: To ensure platform
                functionality Provider: https://premelo.app Service: Please
                refer to Premelo Privacy Policy Country: Malaysia Expires in: As
                long as the user has an account with us Analytics and
                customization cookies: These cookies collect information that is
                used either in aggregate form to help us understand how our
                Websites are being used or how effective our marketing campaigns
                are, or to help us customize our Websites for you. Name: Cayden
                Studios (M) Sdn Bhd Purpose: To further improve the platform
                performance Provider: https://premelo.app Service: Please refer
                to Premelo Privacy Policy Country: Malaysia Expires in: As long
                as the user has an account with us Advertising cookies: These
                cookies are used to make advertising messages more relevant to
                you. They perform functions like preventing the same ad from
                continuously reappearing, ensuring that ads are properly
                displayed for advertisers, and in some cases selecting
                advertisements that are based on your interests. Name: Cayden
                Studios (M) Sdn Bhd Purpose: To better targeting other audience
                to our platform Provider: https://premelo.app Service: Please
                refer to Premelo Privacy Policy Country: Malaysia Expires in: As
                long as the user has an account with us What about other
                tracking technologies, like web beacons? Cookies are not the
                only way to recognize or track visitors to a website. We may use
                other, similar technologies from time to time, like web beacons
                (sometimes called "tracking pixels" or "clear gifs"). These are
                tiny graphics files that contain a unique identifier that enable
                us to recognize when someone has visited our Websites or opened
                an e-mail including them. This allows us, for example, to
                monitor the traffic patterns of users from one page within a
                website to another, to deliver or communicate with cookies, to
                understand whether you have come to the website from an online
                advertisement displayed on a third-party website, to improve
                site performance, and to measure the success of e-mail marketing
                campaigns. In many instances, these technologies are reliant on
                cookies to function properly, and so declining cookies will
                impair their functioning. Do you use Flash cookies or Local
                Shared Objects? Websites may also use so-called "Flash Cookies"
                (also known as Local Shared Objects or "LSOs") to, among other
                things, collect and store information about your use of our
                services, fraud prevention and for other site operations. If you
                do not want Flash Cookies stored on your computer, you can
                adjust the settings of your Flash player to block Flash Cookies
                storage using the tools contained in the Website Storage
                Settings Panel. You can also control Flash Cookies by going to
                the Global Storage Settings Panel and following the instructions
                (which may include instructions that explain, for example, how
                to delete existing Flash Cookies (referred to "information" on
                the Macromedia site), how to prevent Flash LSOs from being
                placed on your computer without your being asked, and (for Flash
                Player 8 and later) how to block Flash Cookies that are not
                being delivered by the operator of the page you are on at the
                time). Please note that setting the Flash Player to restrict or
                limit acceptance of Flash Cookies may reduce or impede the
                functionality of some Flash applications, including,
                potentially, Flash applications used in connection with our
                services or online content. Do you serve targeted advertising?
                Third parties may serve cookies on your computer or mobile
                device to serve advertising through our Websites. These
                companies may use information about your visits to this and
                other websites in order to provide relevant advertisements about
                goods and services that you may be interested in. They may also
                employ technology that is used to measure the effectiveness of
                advertisements. This can be accomplished by them using cookies
                or web beacons to collect information about your visits to this
                and other sites in order to provide relevant advertisements
                about goods and services of potential interest to you. The
                information collected through this process does not enable us or
                them to identify your name, contact details or other details
                that directly identify you unless you choose to provide these.
                How often will you update this Cookie Policy? We may update this
                Cookie Policy from time to time in order to reflect, for
                example, changes to the cookies we use or for other operational,
                legal or regulatory reasons. Please therefore re-visit this
                Cookie Policy regularly to stay informed about our use of
                cookies and related technologies. The date at the top of this
                Cookie Policy indicates when it was last updated. Where can I
                get further information? If you have any questions about our use
                of cookies or other technologies, please email us at
                info@premelo.com or by post to: Cayden Studios (M) Sdn Bhd
                No.13, Jalan Angklung 33/20, Shah Alam Technology Park, Section
                33, 40400 Shah Alam, Selangor, Darul Ehsan.
              </div>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <div>
                <div
                  style={{
                    fontWeight: "600",
                    fontSize: "20px",
                    padding: "20px 0px",
                  }}
                >
                  AGREEMENT TO TERMS
                </div>
                These Terms and Conditions constitute a legally binding
                agreement made between you, whether personally or on behalf of
                an entity (“you”) and Cayden Studios (M) Sdn Bhd (“we,” “us” or
                “our”), concerning your access to and use of the [website
                name.com] website as well as any other media form, media
                channel, mobile website or mobile application related, linked,
                or otherwise connected thereto (collectively, the “Site”). You
                agree that by accessing the Site, you have read, understood, and
                agree to be bound by all of these Terms of Use. If you do not
                agree with all of these Terms of Use, then you are expressly
                prohibited from using the Site and you must discontinue use
                immediately. Supplemental terms and conditions or documents that
                may be posted on the Site from time to time are hereby expressly
                incorporated herein by reference. We reserve the right, in our
                sole discretion, to make changes or modifications to these Terms
                and Conditions at any time and for any reason. We will alert you
                about any changes by updating the “Last updated” date of these
                Terms and Conditions, and you waive any right to receive
                specific notice of each such change. It is your responsibility
                to periodically review these Terms and Conditions to stay
                informed of updates. You will be subject to, and will be deemed
                to have been made aware of and to have accepted, the changes in
                any revised Terms and Conditions by your continued use of the
                Site after the date such revised Terms and Conditions are
                posted. The information provided on the Site is not intended for
                distribution to or use by any person or entity in any
                jurisdiction or country where such distribution or use would be
                contrary to law or regulation or which would subject us to any
                registration requirement within such jurisdiction or country.
                Accordingly, those persons who choose to access the Site from
                other locations do so on their own initiative and are solely
                responsible for compliance with local laws, if and to the extent
                local laws are applicable. The Site is intended for users who
                are at least 18 years old. Persons under the age of 18 are not
                permitted to register for the Site. INTELLECTUAL PROPERTY RIGHTS
                Unless otherwise indicated, the Site is our proprietary property
                and all source code, databases, functionality, software, website
                designs, audio, video, text, photographs, and graphics on the
                Site (collectively, the “Content”) and the trademarks, service
                marks, and logos contained therein (the “Marks”) are owned or
                controlled by us or licensed to us, and are protected by
                copyright and trademark laws and various other intellectual
                property rights and unfair competition laws of the United
                States, foreign jurisdictions, and international conventions.
                The Content and the Marks are provided on the Site “AS IS” for
                your information and personal use only. Except as expressly
                provided in these Terms and Conditions, no part of the Site and
                no Content or Marks may be copied, reproduced, aggregated,
                republished, uploaded, posted, publicly displayed, encoded,
                translated, transmitted, distributed, sold, licensed, or
                otherwise exploited for any commercial purpose whatsoever,
                without our express prior written permission. Provided that you
                are eligible to use the Site, you are granted a limited license
                to access and use the Site and to download or print a copy of
                any portion of the Content to which you have properly gained
                access solely for your personal, non-commercial use. We reserve
                all rights not expressly granted to you in and to the Site, the
                Content and the Marks. USER REPRESENTATIONS By using the Site,
                you represent and warrant that: [(1) all registration
                information you submit will be true, accurate, current, and
                complete; (2) you will maintain the accuracy of such information
                and promptly update such registration information as necessary;]
                (3) you have the legal capacity and you agree to comply with
                these Terms and Conditions; [(4) you are not under the age of
                18;] (5) not a minor in the jurisdiction in which you reside[,
                or if a minor, you have received parental permission to use the
                Site]; (6) you will not access the Site through automated or
                non-human means, whether through a bot, script, or otherwise;
                (7) you will not use the Site for any illegal or unauthorized
                purpose; (8) your use of the Site will not violate any
                applicable law or regulation. If you provide any information
                that is untrue, inaccurate, not current, or incomplete, we have
                the right to suspend or terminate your account and refuse any
                and all current or future use of the Site (or any portion
                thereof). USER REGISTRATION You may be required to register with
                the Site. You agree to keep your password confidential and will
                be responsible for all use of your account and password. We
                reserve the right to remove, reclaim, or change a username you
                select if we determine, in our sole discretion, that such
                username is inappropriate, obscene, or otherwise objectionable.
                PROHIBITED ACTIVITIES You may not access or use the Site for any
                purpose other than that for which we make the Site available.
                The Site may not be used in connection with any commercial
                endeavors except those that are specifically endorsed or
                approved by us. As a user of the Site, you agree not to:
                systematically retrieve data or other content from the Site to
                create or compile, directly or indirectly, a collection,
                compilation, database, or directory without written permission
                from us. make any unauthorized use of the Site, including
                collecting usernames and/or email addresses of users by
                electronic or other means for the purpose of sending unsolicited
                email, or creating user accounts by automated means or under
                false pretenses. use a buying agent or purchasing agent to make
                purchases on the Site. use the Site to advertise or offer to
                sell goods and services. circumvent, disable, or otherwise
                interfere with security-related features of the Site, including
                features that prevent or restrict the use or copying of any
                Content or enforce limitations on the use of the Site and/or the
                Content contained therein. engage in unauthorized framing of or
                linking to the Site. trick, defraud, or mislead us and other
                users, especially in any attempt to learn sensitive account
                information such as user passwords; make improper use of our
                support services or submit false reports of abuse or misconduct.
                engage in any automated use of the system, such as using scripts
                to send comments or messages, or using any data mining, robots,
                or similar data gathering and extraction tools. interfere with,
                disrupt, or create an undue burden on the Site or the networks
                or services connected to the Site. attempt to impersonate
                another user or person or use the username of another user. sell
                or otherwise transfer your profile. use any information obtained
                from the Site in order to harass, abuse, or harm another person.
                use the Site as part of any effort to compete with us or
                otherwise use the Site and/or the Content for any
                revenue-generating endeavor or commercial enterprise. decipher,
                decompile, disassemble, or reverse engineer any of the software
                comprising or in any way making up a part of the Site. attempt
                to bypass any measures of the Site designed to prevent or
                restrict access to the Site, or any portion of the Site. harass,
                annoy, intimidate, or threaten any of our employees or agents
                engaged in providing any portion of the Site to you. delete the
                copyright or other proprietary rights notice from any Content.
                copy or adapt the Site’s software, including but not limited to
                Flash, PHP, HTML, JavaScript, or other code. upload or transmit
                (or attempt to upload or to transmit) viruses, Trojan horses, or
                other material, including excessive use of capital letters and
                spamming (continuous posting of repetitive text), that
                interferes with any party’s uninterrupted use and enjoyment of
                the Site or modifies, impairs, disrupts, alters, or interferes
                with the use, features, functions, operation, or maintenance of
                the Site. upload or transmit (or attempt to upload or to
                transmit) any material that acts as a passive or active
                information collection or transmission mechanism, including
                without limitation, clear graphics interchange formats (“gifs”),
                1×1 pixels, web bugs, cookies, or other similar devices
                (sometimes referred to as “spyware” or “passive collection
                mechanisms” or “pcms”). except as may be the result of standard
                search engine or Internet browser usage, use, launch, develop,
                or distribute any automated system, including without
                limitation, any spider, robot, cheat utility, scraper, or
                offline reader that accesses the Site, or using or launching any
                unauthorized script or other software. disparage, tarnish, or
                otherwise harm, in our opinion, us and/or the Site. use the Site
                in a manner inconsistent with any applicable laws or
                regulations. USER GENERATED CONTRIBUTIONS The Site may invite
                you to chat, contribute to, or participate in blogs, message
                boards, online forums, and other functionality, and may provide
                you with the opportunity to create, submit, post, display,
                transmit, perform, publish, distribute, or broadcast content and
                materials to us or on the Site, including but not limited to
                text, writings, video, audio, photographs, graphics, comments,
                suggestions, or personal information or other material
                (collectively, "Contributions"). Contributions may be viewable
                by other users of the Site and through third-party websites. As
                such, any Contributions you transmit may be treated as
                non-confidential and non-proprietary. When you create or make
                available any Contributions, you thereby represent and warrant
                that: the creation, distribution, transmission, public display,
                or performance, and the accessing, downloading, or copying of
                your Contributions do not and will not infringe the proprietary
                rights, including but not limited to the copyright, patent,
                trademark, trade secret, or moral rights of any third party. you
                are the creator and owner of or have the necessary licenses,
                rights, consents, releases, and permissions to use and to
                authorize us, the Site, and other users of the Site to use your
                Contributions in any manner contemplated by the Site and these
                Terms and Conditions. you have the written consent, release,
                and/or permission of each and every identifiable individual
                person in your Contributions to use the name or likeness of each
                and every such identifiable individual person to enable
                inclusion and use of your Contributions in any manner
                contemplated by the Site and these Terms and Conditions. your
                Contributions are not false, inaccurate, or misleading. your
                Contributions are not unsolicited or unauthorized advertising,
                promotional materials, pyramid schemes, chain letters, spam,
                mass mailings, or other forms of solicitation. your
                Contributions are not obscene, lewd, lascivious, filthy,
                violent, harassing, libelous, slanderous, or otherwise
                objectionable (as determined by us). your Contributions do not
                ridicule, mock, disparage, intimidate, or abuse anyone. your
                Contributions do not advocate the violent overthrow of any
                government or incite, encourage, or threaten physical harm
                against another. your Contributions do not violate any
                applicable law, regulation, or rule. your Contributions do not
                violate the privacy or publicity rights of any third party. your
                Contributions do not contain any material that solicits personal
                information from anyone under the age of 18 or exploits people
                under the age of 18 in a sexual or violent manner. your
                Contributions do not violate any federal or state law concerning
                child pornography, or otherwise intended to protect the health
                or well-being of minors; your Contributions do not include any
                offensive comments that are connected to race, national origin,
                gender, sexual preference, or physical handicap. your
                Contributions do not otherwise violate, or link to material that
                violates, any provision of these Terms and Conditions, or any
                applicable law or regulation. Any use of the Site in violation
                of the foregoing violates these Terms and Conditions and may
                result in, among other things, termination or suspension of your
                rights to use the Site. CONTRIBUTION LICENSE By posting your
                Contributions to any part of the Site [or making Contributions
                accessible to the Site by linking your account from the Site to
                any of your social networking accounts], you automatically
                grant, and you represent and warrant that you have the right to
                grant, to us an unrestricted, unlimited, irrevocable, perpetual,
                non-exclusive, transferable, royalty-free, fully-paid, worldwide
                right, and license to host, use, copy, reproduce, disclose,
                sell, resell, publish, broadcast, retitle, archive, store,
                cache, publicly perform, publicly display, reformat, translate,
                transmit, excerpt (in whole or in part), and distribute such
                Contributions (including, without limitation, your image and
                voice) for any purpose, commercial, advertising, or otherwise,
                and to prepare derivative works of, or incorporate into other
                works, such Contributions, and grant and authorize sublicenses
                of the foregoing. The use and distribution may occur in any
                media formats and through any media channels. This license will
                apply to any form, media, or technology now known or hereafter
                developed, and includes our use of your name, company name, and
                franchise name, as applicable, and any of the trademarks,
                service marks, trade names, logos, and personal and commercial
                images you provide. You waive all moral rights in your
                Contributions, and you warrant that moral rights have not
                otherwise been asserted in your Contributions. We do not assert
                any ownership over your Contributions. You retain full ownership
                of all of your Contributions and any intellectual property
                rights or other proprietary rights associated with your
                Contributions. We are not liable for any statements or
                representations in your Contributions provided by you in any
                area on the Site. You are solely responsible for your
                Contributions to the Site and you expressly agree to exonerate
                us from any and all responsibility and to refrain from any legal
                action against us regarding your Contributions. We have the
                right, in our sole and absolute discretion, (1) to edit, redact,
                or otherwise change any Contributions; (2) to re-categorize any
                Contributions to place them in more appropriate locations on the
                Site; and (3) to pre-screen or delete any Contributions at any
                time and for any reason, without notice. We have no obligation
                to monitor your Contributions. GUIDELINES FOR REVIEWS We may
                provide you areas on the Site to leave reviews or ratings. When
                posting a review, you must comply with the following criteria:
                (1) you should have firsthand experience with the person/entity
                being reviewed; (2) your reviews should not contain offensive
                profanity, or abusive, racist, offensive, or hate language; (3)
                your reviews should not contain discriminatory references based
                on religion, race, gender, national origin, age, marital status,
                sexual orientation, or disability; (4) your reviews should not
                contain references to illegal activity; (5) you should not be
                affiliated with competitors if posting negative reviews; (6) you
                should not make any conclusions as to the legality of conduct;
                (7) you may not post any false or misleading statements; (8) you
                may not organize a campaign encouraging others to post reviews,
                whether positive or negative. We may accept, reject, or remove
                reviews in our sole discretion. We have absolutely no obligation
                to screen reviews or to delete reviews, even if anyone considers
                reviews objectionable or inaccurate. Reviews are not endorsed by
                us, and do not necessarily represent our opinions or the views
                of any of our affiliates or partners. We do not assume liability
                for any review or for any claims, liabilities, or losses
                resulting from any review. By posting a review, you hereby grant
                to us a perpetual, non-exclusive, worldwide, royalty-free,
                fully-paid, assignable, and sublicensable right and license to
                reproduce, modify, translate, transmit by any means, display,
                perform, and/or distribute all content relating to reviews.
                SUBMISSIONS You acknowledge and agree that any questions,
                comments, suggestions, ideas, feedback, or other information
                regarding the Site ("Submissions") provided by you to us are
                non-confidential and shall become our sole property. We shall
                own exclusive rights, including all intellectual property
                rights, and shall be entitled to the unrestricted use and
                dissemination of these Submissions for any lawful purpose,
                commercial or otherwise, without acknowledgment or compensation
                to you. You hereby waive all moral rights to any such
                Submissions, and you hereby warrant that any such Submissions
                are original with you or that you have the right to submit such
                Submissions. You agree there shall be no recourse against us for
                any alleged or actual infringement or misappropriation of any
                proprietary right in your Submissions. THIRD-PARTY WEBSITES AND
                CONTENT The Site may contain (or you may be sent via the Site)
                links to other websites ("Third-Party Websites") as well as
                articles, photographs, text, graphics, pictures, designs, music,
                sound, video, information, applications, software, and other
                content or items belonging to or originating from third parties
                ("Third-Party Content"). Such Third-Party Websites and
                Third-Party Content are not investigated, monitored, or checked
                for accuracy, appropriateness, or completeness by us, and we are
                not responsible for any Third-Party Websites accessed through
                the Site or any Third-Party Content posted on, available
                through, or installed from the Site, including the content,
                accuracy, offensiveness, opinions, reliability, privacy
                practices, or other policies of or contained in the Third-Party
                Websites or the Third-Party Content. Inclusion of, linking to,
                or permitting the use or installation of any Third-Party
                Websites or any Third-Party Content does not imply approval or
                endorsement thereof by us. If you decide to leave the Site and
                access the Third-Party Websites or to use or install any
                Third-Party Content, you do so at your own risk, and you should
                be aware these Terms and Conditions no longer govern. You should
                review the applicable terms and policies, including privacy and
                data gathering practices, of any website to which you navigate
                from the Site or relating to any applications you use or install
                from the Site. Any purchases you make through Third-Party
                Websites will be through other websites and from other
                companies, and we take no responsibility whatsoever in relation
                to such purchases which are exclusively between you and the
                applicable third party. You agree and acknowledge that we do not
                endorse the products or services offered on Third-Party Websites
                and you shall hold us harmless from any harm caused by your
                purchase of such products or services. Additionally, you shall
                hold us harmless from any losses sustained by you or harm caused
                to you relating to or resulting in any way from any Third-Party
                Content or any contact with Third-Party Websites. SITE
                MANAGEMENT We reserve the right, but not the obligation, to: (1)
                monitor the Site for violations of these Terms and Conditions;
                (2) take appropriate legal action against anyone who, in our
                sole discretion, violates the law or these Terms and Conditions,
                including without limitation, reporting such user to law
                enforcement authorities; (3) in our sole discretion and without
                limitation, refuse, restrict access to, limit the availability
                of, or disable (to the extent technologically feasible) any of
                your Contributions or any portion thereof; (4) in our sole
                discretion and without limitation, notice, or liability, to
                remove from the Site or otherwise disable all files and content
                that are excessive in size or are in any way burdensome to our
                systems; (5) otherwise manage the Site in a manner designed to
                protect our rights and property and to facilitate the proper
                functioning of the Site. PRIVACY POLICY We care about data
                privacy and security. Please review our Privacy Policy. By using
                the Site, you agree to be bound by our Privacy Policy, which is
                incorporated into these Terms and Conditions. Please be advised
                the Site is hosted in the Singapore. If you access the Site from
                the European Union, Asia, or any other region of the world with
                laws or other requirements governing personal data collection,
                use, or disclosure that differ from applicable laws in the
                Malaysia, then through your continued use of the Site, you are
                transferring your data to the Malaysia, and you expressly
                consent to have your data transferred to and processed in the
                Malaysia. [Further, we do not knowingly accept, request, or
                solicit information from children or knowingly market to
                children. Therefore, in accordance with the U.S. Children’s
                Online Privacy Protection Act, if we receive actual knowledge
                that anyone under the age of 13 has provided personal
                information to us without the requisite and verifiable parental
                consent, we will delete that information from the Site as
                quickly as is reasonably practical.] COPYRIGHT INFRINGEMENTS We
                respect the intellectual property rights of others. If you
                believe that any material available on or through the Site
                infringes upon any copyright you own or control, please
                immediately notify us using the contact information provided
                below (a “Notification”). A copy of your Notification will be
                sent to the person who posted or stored the material addressed
                in the Notification. Please be advised that pursuant to federal
                law you may be held liable for damages if you make material
                misrepresentations in a Notification. Thus, if you are not sure
                that material located on or linked to by the Site infringes your
                copyright, you should consider first contacting an attorney.]
                TERM AND TERMINATION These Terms and Conditions shall remain in
                full force and effect while you use the Site. WITHOUT LIMITING
                ANY OTHER PROVISION OF THESE TERMS AND CONDITIONS, WE RESERVE
                THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR
                LIABILITY, DENY ACCESS TO AND USE OF THE SITE (INCLUDING
                BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR
                FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY
                REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE TERMS
                AND CONDITIONS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY
                TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE [YOUR
                ACCOUNT AND] ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY
                TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION. If we terminate
                or suspend your account for any reason, you are prohibited from
                registering and creating a new account under your name, a fake
                or borrowed name, or the name of any third party, even if you
                may be acting on behalf of the third party. In addition to
                terminating or suspending your account, we reserve the right to
                take appropriate legal action, including without limitation
                pursuing civil, criminal, and injunctive redress. MODIFICATIONS
                AND INTERRUPTIONS We reserve the right to change, modify, or
                remove the contents of the Site at any time or for any reason at
                our sole discretion without notice. However, we have no
                obligation to update any information on our Site. We also
                reserve the right to modify or discontinue all or part of the
                Site without notice at any time. We will not be liable to you or
                any third party for any modification, price change, suspension,
                or discontinuance of the Site. We cannot guarantee the Site will
                be available at all times. We may experience hardware, software,
                or other problems or need to perform maintenance related to the
                Site, resulting in interruptions, delays, or errors. We reserve
                the right to change, revise, update, suspend, discontinue, or
                otherwise modify the Site at any time or for any reason without
                notice to you. You agree that we have no liability whatsoever
                for any loss, damage, or inconvenience caused by your inability
                to access or use the Site during any downtime or discontinuance
                of the Site. Nothing in these Terms and Conditions will be
                construed to obligate us to maintain and support the Site or to
                supply any corrections, updates, or releases in connection
                therewith. GOVERNING LAW These Terms and Conditions and your use
                of the Site are governed by and construed in accordance with the
                laws of the State of Malaysia applicable to agreements made and
                to be entirely performed within the State of Malaysia, without
                regard to its conflict of law principles. DISPUTE RESOLUTION Any
                legal action of whatever nature brought by either you or us
                (collectively, the “Parties” and individually, a “Party”) shall
                be commenced or prosecuted in the state and federal courts
                located in Malaysia County, Selangor, and the Parties hereby
                consent to, and waive all defenses of lack of personal
                jurisdiction and forum non conveniens with respect to venue and
                jurisdiction in such state and federal courts. Application of
                the United Nations Convention on Contracts for the International
                Sale of Goods and the Uniform Computer Information Transaction
                Act (UCITA) are excluded from these Terms and Conditions. In no
                event shall any claim, action, or proceeding brought by either
                Party related in any way to the Site be commenced more than 5
                years after the cause of action arose. CORRECTIONS There may be
                information on the Site that contains typographical errors,
                inaccuracies, or omissions that may relate to the Site,
                including descriptions, pricing, availability, and various other
                information. We reserve the right to correct any errors,
                inaccuracies, or omissions and to change or update the
                information on the Site at any time, without prior notice.
              </div>
            </TabPanel>
            <TabPanel value={value} index={3}>
              <div>
                <div
                  style={{
                    fontWeight: "600",
                    fontSize: "20px",
                    padding: "20px 0px",
                  }}
                >
                  DISCLAIMER
                </div>
                THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
                AGREE THAT YOUR USE OF THE SITE AND OUR SERVICES WILL BE AT YOUR
                SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM
                ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE
                AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED
                WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS
                ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE
                CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO
                LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
                INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR
                PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
                ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR
                USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL
                INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY
                INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE,
                (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE
                TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD PARTY, AND/OR
                (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR
                ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE
                OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE
                VIA THE SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
                RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED
                BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR
                ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR
                OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY WAY
                BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND
                ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE
                PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY
                ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE
                CAUTION WHERE APPROPRIATE. LIMITATIONS OF LIABILITY IN NO EVENT
                WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU
                OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
                EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING
                LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES
                ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED
                OF THE POSSIBILITY OF SUCH DAMAGES. [NOTWITHSTANDING ANYTHING TO
                THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY
                CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL
                AT ALL TIMES BE LIMITED TO [THE LESSER OF] [THE AMOUNT PAID, IF
                ANY, BY YOU TO US DURING THE CURRENT MONTH PERIOD PRIOR TO ANY
                CAUSE OF ACTION ARISING. CERTAIN STATE LAWS DO NOT ALLOW
                LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION
                OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF
                THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND
                YOU MAY HAVE ADDITIONAL RIGHTS.] INDEMNIFICATION You agree to
                defend, indemnify, and hold us harmless, including our
                subsidiaries, affiliates, and all of our respective officers,
                agents, partners, and employees, from and against any loss,
                damage, liability, claim, or demand, including reasonable
                attorneys’ fees and expenses, made by any third party due to or
                arising out of: (1) [your Contributions]; (2) use of the Site;
                (3) breach of these Terms and Conditions; (4) any breach of your
                representations and warranties set forth in these Terms and
                Conditions; (5) your violation of the rights of a third party,
                including but not limited to intellectual property rights; or
                (6) any overt harmful act toward any other user of the Site with
                whom you connected via the Site. Notwithstanding the foregoing,
                we reserve the right, at your expense, to assume the exclusive
                defense and control of any matter for which you are required to
                indemnify us, and you agree to cooperate, at your expense, with
                our defense of such claims. We will use reasonable efforts to
                notify you of any such claim, action, or proceeding which is
                subject to this indemnification upon becoming aware of it. USER
                DATA We will maintain certain data that you transmit to the Site
                for the purpose of managing the Site, as well as data relating
                to your use of the Site. Although we perform regular routine
                backups of data, you are solely responsible for all data that
                you transmit or that relates to any activity you have undertaken
                using the Site. You agree that we shall have no liability to you
                for any loss or corruption of any such data, and you hereby
                waive any right of action against us arising from any such loss
                or corruption of such data. ELECTRONIC COMMUNICATIONS,
                TRANSACTIONS, AND SIGNATURES Visiting the Site, sending us
                emails, and completing online forms constitute electronic
                communications. You consent to receive electronic
                communications, and you agree that all agreements, notices,
                disclosures, and other communications we provide to you
                electronically, via email and on the Site, satisfy any legal
                requirement that such communication be in writing. YOU HEREBY
                AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS,
                AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES,
                POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY
                US OR VIA THE SITE. You hereby waive any rights or requirements
                under any statutes, regulations, rules, ordinances, or other
                laws in any jurisdiction which require an original signature or
                delivery or retention of non-electronic records, or to payments
                or the granting of credits by any means other than electronic
                means. MISCELLANEOUS These Terms and Conditions and any policies
                or operating rules posted by us on the Site constitute the
                entire agreement and understanding between you and us. Our
                failure to exercise or enforce any right or provision of these
                Terms and Conditions shall not operate as a waiver of such right
                or provision. These Terms and Conditions operate to the fullest
                extent permissible by law. We may assign any or all of our
                rights and obligations to others at any time. We shall not be
                responsible or liable for any loss, damage, delay, or failure to
                act caused by any cause beyond our reasonable control. If any
                provision or part of a provision of these Terms and Conditions
                is determined to be unlawful, void, or unenforceable, that
                provision or part of the provision is deemed severable from
                these Terms and Conditions and does not affect the validity and
                enforceability of any remaining provisions. There is no joint
                venture, partnership, employment or agency relationship created
                between you and us as a result of these Terms and Conditions or
                use of the Site. You agree that these Terms and Conditions will
                not be construed against us by virtue of having drafted them.
                You hereby waive any and all defenses you may have based on the
                electronic form of these Terms and Conditions and the lack of
                signing by the parties hereto to execute these Terms and
                Conditions. CONTACT US In order to resolve a complaint regarding
                the Site or to receive further information regarding use of the
                Site, please contact us at: Cayden Studios (M) Sdn Bhd
                info@premelo.com
              </div>
            </TabPanel>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TandCMobile;
