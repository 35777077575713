import React, { useState, useEffect } from "react";
import logo from "./images/CS_Favicon-01.png";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SettingsIcon from "@mui/icons-material/Settings";
import GridViewIcon from "@mui/icons-material/GridView";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { CirclePicker, SliderPicker } from "react-color";
import { ReactComponent as FolderIcon } from "./icons/folder.svg";
import "./App.css";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/CheckCircleOutline";
import GoogleIcon from "@mui/icons-material/Google";
import CheckedIcon from "@mui/icons-material/CheckCircle";
import Calendar from "short-react-calendar";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { useGoogleOneTapLogin, GoogleLogin } from "@react-oauth/google";
import axios from "axios";
import {
  Animator,
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  FadeIn,
  FadeOut,
  Move,
  MoveIn,
  MoveOut,
  Sticky,
  StickyIn,
  StickyOut,
  Zoom,
  ZoomIn,
  ZoomOut,
} from "react-scroll-motion";
import { ReactComponent as Test1 } from "./icons/testimonial logo-01.svg";
import { ReactComponent as Test2 } from "./icons/testimonial logo-02.svg";
import { ReactComponent as Test3 } from "./icons/testimonial logo-03.svg";
import { ReactComponent as Test4 } from "./icons/testimonial logo-04.svg";
import { ReactComponent as Test5 } from "./icons/testimonial logo-05.svg";
import dingSound from "./audio/Ding.wav";
import workflow from "./images/lightbulb workflow-01.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import points1 from "./images/image1of4.png";
import points2 from "./images/image2of4.png";
import points3 from "./images/image3of4.png";
import points4 from "./images/image4of4.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { fallDown as Menu } from "react-burger-menu";
import Hamburger from "hamburger-react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import StorefrontIcon from "@mui/icons-material/Storefront";
import InsightsIcon from "@mui/icons-material/Insights";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PollIcon from "@mui/icons-material/Poll";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import bg1 from "./images/bg_home_image.jpg";
import lbg1 from "./images/longer_bg.png";
import { v4 as uuid } from "uuid";
import Cookies from "js-cookie";

const HomepageMobile = () => {
  const navigate = useNavigate();
  const [primaryColor, setPrimaryColor] = useState("#5AB1BA");
  const [hideLogo, setHideLogo] = useState(false);
  const [companyName, setCompanyName] = useState("Premelo");
  useEffect(() => {
    const audio = new Audio(dingSound);
    const handleScroll = () => {
      setHideLogo(window.scrollY > 2600);
      if (window.scrollY === 2600) {
        audio.play();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);

  function handleToggleMenu() {
    setIsOpen(!isOpen);
  }

  const responseGoogle = (response) => {
    const credential = response;
    console.log(response);
    if (credential) {
      let requestData = {
        credential,
      };

      // Add company name if it is available and not 'Premelo'
      if (companyName && companyName !== "Premelo") {
        requestData.companyName = companyName;
      }

      fetch("https://ww1.premelo.com/api/auth/google", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.token) {
            console.log(data.token);
            console.log(data.redirect_url);
            // Store the token in local storage
            Cookies.set("token", data.token, { expires: 365 }); // store token in cookies
          }
          if (data.redirect_url === "/dashboard") {
            navigate("/dashboard"); // navigate to dashboard page
          } else if (data.redirect_url === "/fillinformation") {
            navigate("/fillinformation"); // navigate to fillinformation page
          } else {
            console.log("Unknown redirect URL:", data.redirectUrl);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const login = useGoogleOneTapLogin({
    onSuccess: (tokenResponse) => responseGoogle(tokenResponse),
    onError: (error) => console.log(error),
  });

  const errorMessage = (error) => {
    console.log(error);
  };
  return (
    <div id="outer-container" style={{ overflowX: "hidden" }}>
      <Menu
        right
        width={"100%"}
        isOpen={isOpen}
        customCrossIcon={false}
        customBurgerIcon={
          <Hamburger toggled={isOpen} toggle={handleToggleMenu} size={24} />
        }
      >
        <div>
          <div
            onClick={() => setIsOpen(false)}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              style={{
                color: "rgba(0,0,0,0.7)",
                textDecoration: "none",
                padding: "5px 0px",
              }}
            >
              Home
            </div>
            <NavigateNextIcon />
          </div>
          <hr
            style={{
              width: "100%",
              height: "0.01rem",
            }}
          />
          <div
            onClick={() => {
              navigate("/faq");
              window.scrollTo(0, 0);
            }}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              style={{
                color: "rgba(0,0,0,0.7)",
                textDecoration: "none",
                padding: "5px 0px",
              }}
            >
              Frequently Asked Questions
            </div>
            <NavigateNextIcon />
          </div>
          <hr
            style={{
              width: "100%",
              height: "0.01rem",
            }}
          />
          <div
            onClick={() => {
              navigate("/contactus");
              window.scrollTo(0, 0);
            }}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              style={{
                color: "rgba(0,0,0,0.7)",
                textDecoration: "none",
                padding: "5px 0px",
              }}
            >
              Contact Us
            </div>
            <NavigateNextIcon />
          </div>
          <hr
            style={{
              width: "100%",
              height: "0.01rem",
            }}
          />
          <div
            onClick={() => {
              navigate("/termsandcondition");
              window.scrollTo(0, 0);
            }}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              style={{
                color: "rgba(0,0,0,0.7)",
                textDecoration: "none",
                padding: "5px 0px",
              }}
            >
              Terms & Privacy
            </div>
            <NavigateNextIcon />
          </div>
          <div
            style={{
              width: "90%",
              textAlign: "center",
              padding: "15px 20px",
              background: "#d81b5e",
              color: "white",
              marginTop: "35px",
            }}
            onClick={() => {
              navigate("/login");
              window.scrollTo(0, 0);
            }}
          >
            Login
          </div>
          <div
            style={{
              width: "90%",
              textAlign: "center",
              padding: "15px 20px",
              border: "1px solid #d81b5e",
              color: "#d81b5e",
              marginTop: "15px",
            }}
            onClick={() => {
              navigate("/signup");
              window.scrollTo(0, 0);
            }}
          >
            Sign Up
          </div>
        </div>
      </Menu>
      <div>
        <div style={{ width: "100%" }}>
          <div
            style={{
              background: "white",
              position: "fixed",
              zIndex: "2000",
              top: 0,
              height: "7vh",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: "10px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
            }}
          >
            <div
              onClick={() => {
                navigate("/");
                window.scrollTo(0, 0);
              }}
              style={{
                height: "6vh",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: "1px 0px",
                marginLeft: "20px",
              }}
            >
              <img src={logo} alt="Premelo logo" style={{ width: "50px" }} />
              <p
                style={{
                  fontSize: "25px",
                  fontWeight: "600",
                  color: "black",
                  marginTop: "18px",
                }}
              >
                Premelo
              </p>
            </div>
          </div>
          {/* first segment */}
          <div style={{ height: "700px", position: "relative" }}>
            <img
              src={bg1}
              style={{
                width: "100%",
                height: "100%",
                top: 0,
                left: 0,
                objectFit: "cover",
                position: "absolute",
                opacity: 0.2,
              }}
            />
            <div
              style={{
                position: "absolute",
                zIndex: 5,
                top: 100,
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <div
                style={{
                  fontSize: "40px",
                  fontWeight: "800",
                  color: "black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "40px",
                }}
              >
                Start{" "}
                <div style={{ color: "#d81b5e", margin: "0px 10px" }}>
                  Digitalizing
                </div>
              </div>
              <div
                style={{
                  fontSize: "40px",
                  fontWeight: "800",
                  color: "black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Today
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <div
                  className="homepage-nav-side-3"
                  style={{
                    borderRadius: "10px",
                    border: "none",
                    marginTop: "20px",
                    fontSize: "14px",
                    padding: "10px",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                    width: "80px",
                    height: "90px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    navigate("/signup");
                    window.scrollTo(0, 0);
                  }}
                >
                  <WysiwygIcon style={{ fontSize: "50px" }} />
                  <div
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginBottom: "-2px",
                    }}
                  >
                    Build a
                  </div>
                  <div style={{ marginTop: "-5px" }}>Website</div>
                </div>
                <div
                  className="homepage-nav-side-3"
                  style={{
                    borderRadius: "10px",
                    border: "none",
                    marginTop: "20px",
                    fontSize: "14px",
                    padding: "10px",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                    width: "80px",
                    height: "90px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    navigate("/signup");
                    window.scrollTo(0, 0);
                  }}
                >
                  <StorefrontIcon style={{ fontSize: "50px" }} />
                  <div
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginBottom: "-2px",
                    }}
                  >
                    Sell
                  </div>
                  <div style={{ marginTop: "-5px" }}>Product</div>
                </div>
                <div
                  className="homepage-nav-side-3"
                  style={{
                    borderRadius: "10px",
                    border: "none",
                    marginTop: "20px",
                    fontSize: "14px",
                    padding: "10px",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                    width: "80px",
                    height: "90px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    navigate("/signup");
                    window.scrollTo(0, 0);
                  }}
                >
                  <InsightsIcon style={{ fontSize: "50px" }} />
                  <div
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginBottom: "-2px",
                    }}
                  >
                    Track
                  </div>
                  <div style={{ marginTop: "-5px" }}>Sales</div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <div
                  className="homepage-nav-side-3"
                  style={{
                    borderRadius: "10px",
                    border: "none",
                    marginTop: "20px",
                    fontSize: "14px",
                    padding: "10px",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                    width: "80px",
                    height: "90px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    navigate("/signup");
                    window.scrollTo(0, 0);
                  }}
                >
                  <ManageAccountsIcon style={{ fontSize: "50px" }} />
                  <div
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginBottom: "-2px",
                    }}
                  >
                    Manage
                  </div>
                  <div style={{ marginTop: "-5px" }}>Tasks</div>
                </div>
                <div
                  className="homepage-nav-side-3"
                  style={{
                    borderRadius: "10px",
                    border: "none",
                    marginTop: "20px",
                    fontSize: "14px",
                    padding: "10px",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                    width: "80px",
                    height: "90px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    navigate("/signup");
                    window.scrollTo(0, 0);
                  }}
                >
                  <PollIcon style={{ fontSize: "50px" }} />
                  <div
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginBottom: "-2px",
                    }}
                  >
                    Make
                  </div>
                  <div style={{ marginTop: "-5px" }}>Survey</div>
                </div>
                <div
                  className="homepage-nav-side-3"
                  style={{
                    borderRadius: "10px",
                    border: "none",
                    marginTop: "20px",
                    fontSize: "14px",
                    padding: "10px",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                    width: "80px",
                    height: "90px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    navigate("/signup");
                    window.scrollTo(0, 0);
                  }}
                >
                  <CalendarMonthIcon style={{ fontSize: "50px" }} />
                  <div
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginBottom: "-2px",
                    }}
                  >
                    Manage
                  </div>
                  <div style={{ marginTop: "-5px" }}>Events</div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  marginTop: "25px",
                }}
                onClick={()=> login()}
              >
                <GoogleLogin
                  onSuccess={responseGoogle}
                  onError={errorMessage}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  marginTop: "10px",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.7)",
                }}
              >
                By proceeding you agree to the
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  marginTop: "0px",
                  gap: "5px",
                  fontSize: "11px",
                  color: "rgba(0,0,0,0.7)",
                }}
              >
                <div
                  style={{ textDecoration: "underline" }}
                  onClick={() => navigate("/termsandcondition")}
                >
                  Terms of Service
                </div>{" "}
                and{" "}
                <div
                  style={{ textDecoration: "underline" }}
                  onClick={() => navigate("/termsandcondition")}
                >
                  Privacy Policy
                </div>
              </div>
            </div>
          </div>
          <div style={{ padding: "0px 0px" }}>
            <div
              style={{
                fontSize: "40px",
                fontWeight: "800",
                color: "black",
                //   marginTop: "120px",
                justifyContent: "center",
                textAlign: "center",
                alignItems: "center",
                marginTop: "0px",
              }}
            >
              <div style={{ paddingTop: "40px" }}>Create websites</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "30px",
                }}
              >
                <div> with</div>
                <div style={{ color: "#d81b5e", margin: "0px 10px" }}>
                  Template
                </div>
                <div> sections.</div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "20px",
                }}
              >
                and it's free-to-use
              </div>
            </div>
            <img
              src={lbg1}
              style={{ height: "450px", width: "100%", objectFit: "cover" }}
            />
          </div>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  fontSize: "40px",
                  fontWeight: "800",
                  color: "black",
                  //   marginTop: "120px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ paddingTop: "100px" }}>Manage your</div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div> team with</div>
                  <div style={{ color: "#d81b5e", marginLeft: "10px" }}>
                    Premelo.
                  </div>
                </div>
              </div>
              <button
                className="homepage-nav-side-1"
                style={{
                  padding: "10px 30px",
                  borderRadius: "50px",
                  border: "none",
                  marginTop: "20px",
                  fontSize: "14px",
                }}
                onClick={() => {
                  navigate("/signup");
                  window.scrollTo(0, 0);
                }}
              >
                Get started
              </button>
            </div>
            <div
              style={{
                width: "100%",
                height: "80vh",
                textAlign: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px",
                }}
              >
                <div style={{ width: "80%" }}>
                  <SliderPicker
                    style={{
                      boxShadow: "none",
                      marginBottom: "-100px",
                    }}
                    color={primaryColor}
                    onChange={(color) => setPrimaryColor(color.hex)}
                    onChangeComplete={(color) => setPrimaryColor(color.hex)}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "50vh",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px",
                  zIndex: 0,
                }}
              >
                <div
                  style={{
                    background: "white",
                    width: "85%",
                    height: "80vh",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "space-between",
                    height: "100%",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                    borderRadius: "15px",
                  }}
                >
                  <div
                    style={{
                      width: "15%",
                      height: "100%",
                      boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                      borderRadius: "15px 0px 0px 15px",
                    }}
                  >
                    <img
                      src={logo}
                      alt="premelo logo"
                      style={{
                        width: "40%",
                        marginTop: "15px",
                        borderRadius: "50%",
                        boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                      }}
                    />
                    <div
                      style={{
                        color: "rgba(0,0,0,0.5)",
                        fontSize: "10px",
                        marginTop: "20px",
                        textAlign: "left",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <GridViewIcon
                        style={{ fontSize: "15px", marginRight: "5px" }}
                      />
                    </div>
                    <div
                      style={{
                        color: "rgba(0,0,0,0.5)",
                        fontSize: "10px",
                        marginTop: "17px",
                        textAlign: "left",
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ContentCopyIcon
                        style={{ fontSize: "15px", marginRight: "5px" }}
                      />
                    </div>
                    <div
                      style={{
                        color: "rgba(0,0,0,0.5)",
                        fontSize: "10px",
                        marginTop: "17px",
                        textAlign: "left",
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CalendarTodayIcon
                        style={{ fontSize: "15px", marginRight: "5px" }}
                      />
                    </div>
                    <div
                      style={{
                        color: "rgba(0,0,0,0.5)",
                        fontSize: "10px",
                        marginTop: "17px",
                        textAlign: "left",
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <NotificationsNoneIcon
                        style={{ fontSize: "15px", marginRight: "5px" }}
                      />
                    </div>
                    <div
                      style={{
                        color: "rgba(0,0,0,0.5)",
                        fontSize: "10px",
                        marginTop: "17px",
                        textAlign: "left",
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <MonetizationOnIcon
                        style={{ fontSize: "15px", marginRight: "5px" }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      width: "85%",
                      height: "100%",
                      background: `${primaryColor}1A`,
                      borderRadius: "0px 15px 15px 0px",
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        color: primaryColor,
                        fontSize: "25px",
                        fontWeight: 600,
                        marginTop: "20px",
                      }}
                    >
                      Dashboard
                    </div>
                    <div
                      style={{
                        fontSize: "10px",
                        color: "rgba(0,0,0,0.6)",
                        marginTop: "-4px",
                      }}
                    >
                      Welcome back, User
                    </div>
                    <div
                      style={{
                        padding: "0px 30px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "12px",
                          textAlign: "left",
                          marginTop: "10px",
                          color: "rgba(0,0,0,0.6)",
                        }}
                      >
                        Recently used.
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "0px 2% 0px 1%",
                        marginTop: "10px",
                      }}
                    >
                      <div
                        style={{ width: "40%", marginRight: "5px" }}
                        className="folder-icon-1"
                      >
                        <div className="child-div-2">
                          <div style={{ width: "90%", textAlign: "left" }}>
                            <h2
                              style={{
                                fontWeight: "300",
                                fontSize: "6px",
                                marginBottom: "-10px",
                              }}
                            >
                              Project name:
                            </h2>
                            <span
                              style={{
                                fontWeight: "800",
                                fontSize: "10px",
                                marginTop: "-40px",
                                lineHeight: "1px",

                                color: "rgba(0,0,0,0.6)",
                              }}
                            >
                              Cayden Studios
                            </span>
                            <div style={{ marginTop: "0px" }}>
                              <div
                                style={{
                                  backgroundColor: primaryColor,
                                  color: "white",
                                  display: "inline-flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "50%",
                                  marginRight: "5px",
                                  fontWeight: "600",
                                  fontSize: "16px",
                                  border: "2px solid #ffffff",
                                  marginRight: "-11px",
                                }}
                              >
                                <p
                                  style={{
                                    fontWeight: "300",
                                    fontSize: "7px",
                                  }}
                                >
                                  PA
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <FolderIcon />
                      </div>
                      <div
                        style={{ width: "40%", marginRight: "5px" }}
                        className="folder-icon-1"
                      >
                        <div className="child-div-2">
                          <div style={{ width: "90%", textAlign: "left" }}>
                            <h2
                              style={{
                                fontWeight: "300",
                                fontSize: "6px",
                                marginBottom: "-10px",
                              }}
                            >
                              Project name:
                            </h2>
                            <span
                              style={{
                                fontWeight: "800",
                                fontSize: "10px",
                                marginTop: "-40px",
                                lineHeight: "1px",

                                color: "rgba(0,0,0,0.6)",
                              }}
                            >
                              Cayden Studios
                            </span>
                            <div style={{ marginTop: "0px" }}>
                              <div
                                style={{
                                  backgroundColor: primaryColor,
                                  color: "white",
                                  display: "inline-flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "50%",
                                  marginRight: "5px",
                                  fontWeight: "600",
                                  fontSize: "16px",
                                  border: "2px solid #ffffff",
                                  marginRight: "-11px",
                                }}
                              >
                                <p
                                  style={{
                                    fontWeight: "300",
                                    fontSize: "7px",
                                  }}
                                >
                                  PA
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <FolderIcon />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "0px 30px",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            fontSize: "12px",
                            textAlign: "left",
                            marginTop: "10px",
                            color: "rgba(0,0,0,0.6)",
                          }}
                        >
                          To-do list
                        </div>
                        <div
                          className="task-item"
                          style={{
                            padding: "0.1px 10px 0px 0px",
                            borderBottom: "1px solid rgba(0, 0, 0, .05)",
                            textAlign: "left",
                            width: "80%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginBottom: "-3px",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                                marginLeft: "-10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    marginTop: "-3px",
                                    marginRight: "6px",
                                  }}
                                >
                                  <Checkbox
                                    style={{ marginTop: "5px" }}
                                    icon={
                                      <CheckIcon style={{ fontSize: "15px" }} />
                                    }
                                    checkedIcon={
                                      <CheckedIcon
                                        style={{ fontSize: "15px" }}
                                      />
                                    }
                                  />
                                </div>
                                <div style={{ width: "100%" }}>
                                  <div>
                                    <h3
                                      style={{
                                        color: "#474747",
                                        fontSize: "10px",
                                        fontWeight: "600",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Frontal Campaign P..
                                    </h3>
                                  </div>
                                  <div>
                                    <p
                                      style={{
                                        color: "#7C7C7C",
                                        fontSize: "7px",
                                        marginTop: "-15px",
                                      }}
                                    >
                                      Could you prepare the frontal campa...
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="task-item"
                          style={{
                            padding: "0.1px 10px 0px 0px",
                            borderBottom: "1px solid rgba(0, 0, 0, .05)",
                            textAlign: "left",
                            width: "80%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginBottom: "-3px",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                                marginLeft: "-10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    marginTop: "-3px",
                                    marginRight: "6px",
                                  }}
                                >
                                  <Checkbox
                                    style={{ marginTop: "5px" }}
                                    icon={
                                      <CheckIcon style={{ fontSize: "15px" }} />
                                    }
                                    checkedIcon={
                                      <CheckedIcon
                                        style={{ fontSize: "15px" }}
                                      />
                                    }
                                  />
                                </div>
                                <div style={{ width: "100%" }}>
                                  <div>
                                    <h3
                                      style={{
                                        color: "#474747",
                                        fontSize: "10px",
                                        fontWeight: "600",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Frontal Campaign P..
                                    </h3>
                                  </div>
                                  <div>
                                    <p
                                      style={{
                                        color: "#7C7C7C",
                                        fontSize: "7px",
                                        marginTop: "-15px",
                                      }}
                                    >
                                      Could you prepare the frontal campa...
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="task-item"
                          style={{
                            padding: "0.1px 10px 0px 0px",
                            borderBottom: "1px solid rgba(0, 0, 0, .05)",
                            textAlign: "left",
                            width: "80%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginBottom: "-3px",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                                marginLeft: "-10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    marginTop: "-3px",
                                    marginRight: "6px",
                                  }}
                                >
                                  <Checkbox
                                    style={{ marginTop: "5px" }}
                                    icon={
                                      <CheckIcon style={{ fontSize: "15px" }} />
                                    }
                                    checkedIcon={
                                      <CheckedIcon
                                        style={{ fontSize: "15px" }}
                                      />
                                    }
                                  />
                                </div>
                                <div style={{ width: "100%" }}>
                                  <div>
                                    <h3
                                      style={{
                                        color: "#474747",
                                        fontSize: "10px",
                                        fontWeight: "600",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Frontal Campaign P..
                                    </h3>
                                  </div>
                                  <div>
                                    <p
                                      style={{
                                        color: "#7C7C7C",
                                        fontSize: "7px",
                                        marginTop: "-15px",
                                      }}
                                    >
                                      Could you prepare the frontal campa...
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="task-item"
                          style={{
                            padding: "0.1px 10px 0px 0px",
                            borderBottom: "1px solid rgba(0, 0, 0, .05)",
                            textAlign: "left",
                            width: "80%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginBottom: "-3px",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                                marginLeft: "-10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    marginTop: "-3px",
                                    marginRight: "6px",
                                  }}
                                >
                                  <Checkbox
                                    style={{ marginTop: "5px" }}
                                    icon={
                                      <CheckIcon style={{ fontSize: "15px" }} />
                                    }
                                    checkedIcon={
                                      <CheckedIcon
                                        style={{ fontSize: "15px" }}
                                      />
                                    }
                                  />
                                </div>
                                <div style={{ width: "100%" }}>
                                  <div>
                                    <h3
                                      style={{
                                        color: "#474747",
                                        fontSize: "10px",
                                        fontWeight: "600",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Frontal Campaign P..
                                    </h3>
                                  </div>
                                  <div>
                                    <p
                                      style={{
                                        color: "#7C7C7C",
                                        fontSize: "7px",
                                        marginTop: "-15px",
                                      }}
                                    >
                                      Could you prepare the frontal campa...
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="task-item"
                          style={{
                            padding: "0.1px 10px 0px 0px",
                            borderBottom: "1px solid rgba(0, 0, 0, .05)",
                            textAlign: "left",
                            width: "80%",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              marginBottom: "-3px",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                                marginLeft: "-10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    marginTop: "-3px",
                                    marginRight: "6px",
                                  }}
                                >
                                  <Checkbox
                                    style={{ marginTop: "5px" }}
                                    icon={
                                      <CheckIcon style={{ fontSize: "15px" }} />
                                    }
                                    checkedIcon={
                                      <CheckedIcon
                                        style={{ fontSize: "15px" }}
                                      />
                                    }
                                  />
                                </div>
                                <div style={{ width: "100%" }}>
                                  <div>
                                    <h3
                                      style={{
                                        color: "#474747",
                                        fontSize: "10px",
                                        fontWeight: "600",
                                        marginTop: "10px",
                                      }}
                                    >
                                      Frontal Campaign P..
                                    </h3>
                                  </div>
                                  <div>
                                    <p
                                      style={{
                                        color: "#7C7C7C",
                                        fontSize: "7px",
                                        marginTop: "-15px",
                                      }}
                                    >
                                      Could you prepare the frontal campa...
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <div
            style={{
              width: "85%",
            }}
          >
            <div>
              <div
                style={{
                  textAlign: "left",
                  fontSize: "35px",
                  fontWeight: "600",
                  marginTop: "-20px",
                }}
              >
                <div style={{ display: "flex", fontSize: "45px" }}>
                  <div>Premelo</div>
                  <div style={{ color: "#d81b5e", margin: "0px 10px" }}>
                    Plus
                  </div>
                </div>
                <div style={{ marginTop: "-5px" }}> let's you, own it all</div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
              }}
            >
              <div style={{ width: "100%" }}>
                <hr
                  style={{
                    width: "100%",
                    height: "0.02rem",
                    backgroundColor: "black",
                    border: "none",
                    margin: "10px auto",
                  }}
                />
                <div style={{ fontSize: "16px", fontWeight: "600" }}>
                  Customize it.
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "300",
                    marginTop: "5px",
                    color: "rgba(0,0,0,0.7)",
                  }}
                >
                  Premelo allows you to fully customize your workspace with your
                  logo and brand colors. With easy collaboration, multiple
                  projects, and streamlined task management, you can create a
                  workspace that's uniquely yours. Try Premelo today and
                  experience the power of personalized branding for your team's
                  productivity.
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <hr
                  style={{
                    width: "100%",
                    height: "0.02rem",
                    backgroundColor: "black",
                    border: "none",
                    margin: "10px auto",
                  }}
                />
                <div style={{ fontSize: "16px", fontWeight: "600" }}>
                  Connect your domain
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "300",
                    marginTop: "5px",
                    color: "rgba(0,0,0,0.7)",
                  }}
                >
                  Connect your work management platform to your own domain with
                  Premelo. This creates a professional image and reinforces
                  brand identity. Clients recognize and engage with your brand
                  while working together, improving overall customer
                  satisfaction and brand perception. Experience the benefits of
                  a connected workspace with Premelo.
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <hr
                  style={{
                    width: "100%",
                    height: "0.02rem",
                    backgroundColor: "black",
                    border: "none",
                    margin: "10px auto",
                  }}
                />
                <div style={{ fontSize: "16px", fontWeight: "600" }}>
                  Start inviting
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "300",
                    marginTop: "5px",
                    color: "rgba(0,0,0,0.7)",
                  }}
                >
                  With your brand new work management platform on Premelo, it's
                  time to invite your team, collaborators, and clients to start
                  collaborating and completing tasks together. Get started
                  today!
                </div>
              </div>
            </div>
            <div style={{ marginTop: "25px" }}>
              <button
                style={{
                  color: "white",
                  background: "rgba(0,0,0,1)",
                  padding: "10px 35px",
                  borderRadius: "25px",
                  fontSize: "17px",
                  fontWeight: "600",
                }}
                className="mui-button-home"
                onClick={() => {
                  navigate("/signup");
                  window.scrollTo(0, 0);
                }}
              >
                Get started
              </button>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            background: "black",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            textAlign: "center",
            marginTop: "70px",
            padding: "70px 0px",
          }}
        >
          <div style={{ width: "85%" }}>
            <div
              style={{
                fontWeight: "600",
                fontSize: "35px",
                lineHeight: "30px",
              }}
            >
              Premelo - Built for Brands,
            </div>
            <div
              style={{
                fontWeight: "600",
                fontSize: "35px",
                marginTop: "20px",
                lineHeight: "30px",
              }}
            >
              Made for Success.
            </div>
            <div
              style={{ fontWeight: "300", fontSize: "14px", marginTop: "20px" }}
            >
              Built for Brands, Made for Success. With over 600+ companies using
              our platform, customize your workspace, set your logo and brand
              colors, and experience easy collaboration and task management. Try
              Premelo today and empower your team's productivity and success.
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "50px",
                  marginTop: "35px",
                  width: "100%",
                }}
              >
                <Test1
                  style={{ width: "33%", color: "white", fill: "white" }}
                />
                <Test2
                  style={{ width: "33%", color: "white", fill: "white" }}
                />
                <Test3
                  style={{ width: "33%", color: "white", fill: "white" }}
                />
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "50px",
                  marginTop: "35px",
                  width: "100%",
                }}
              >
                <Test4
                  style={{ width: "33%", color: "white", fill: "white" }}
                />
                <Test5
                  style={{ width: "33%", color: "white", fill: "white" }}
                />
              </div>
            </div>
            <div style={{ marginTop: "40px" }}>
              <button
                style={{
                  color: "black",
                  background: "white",
                  padding: "10px 35px",
                  borderRadius: "25px",
                  fontSize: "17px",
                  fontWeight: "600",
                }}
                className="mui-button-home"
                onClick={() => {
                  navigate("/signup");
                  window.scrollTo(0, 0);
                }}
              >
                Get started
              </button>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            background: "#f0f0f0",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <div style={{ width: "100%" }}>
            <div
              style={{
                fontSize: "35px",
                fontWeight: "600",
                marginTop: "80px",
              }}
            >
              Take your
            </div>
            <div
              style={{
                fontSize: "35px",
                fontWeight: "600",
                marginTop: "-5px",
              }}
            >
              team's management
            </div>
            <div
              style={{
                fontSize: "35px",
                fontWeight: "600",
                marginTop: "-5px",
              }}
            >
              to greater heights.
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                textAlign: "center",
                marginTop: "40px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "85%",
                }}
              >
                <div style={{ width: "45%", textAlign: "left" }}>
                  <img src={points1} alt="" style={{ width: "100%" }} />
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      marginTop: "10px",
                    }}
                  >
                    Manage Tasks
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: "300",
                      marginTop: "10px",
                    }}
                  >
                    Premelo lets you create multiple projects and tasks with
                    ease, and track progress in real-time. Keep your team
                    organized and on the same page.
                  </div>
                </div>
                <div style={{ width: "45%", textAlign: "left" }}>
                  <img src={points2} alt="" style={{ width: "100%" }} />
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      marginTop: "10px",
                    }}
                  >
                    Customized Workflow
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: "300",
                      marginTop: "10px",
                    }}
                  >
                    Tailor your workflow to each project's unique needs and
                    requirements, ensuring maximum efficiency and productivity.
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                textAlign: "center",
                marginTop: "40px",
                marginBottom: "80px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "85%",
                }}
              >
                <div style={{ width: "45%", textAlign: "left" }}>
                  <img src={points3} alt="" style={{ width: "100%" }} />
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      marginTop: "10px",
                    }}
                  >
                    Branding Tools
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: "300",
                      marginTop: "10px",
                    }}
                  >
                    With Premelo's branding tools, set your logo and brand
                    colors for a fully branded workspace that aligns with your
                    company's image.
                  </div>
                </div>
                <div style={{ width: "45%", textAlign: "left" }}>
                  <img src={points4} alt="" style={{ width: "100%" }} />
                  <div
                    style={{
                      fontSize: "18px",
                      fontWeight: "600",
                      marginTop: "10px",
                    }}
                  >
                    Domain & Hosting
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      fontWeight: "300",
                      marginTop: "10px",
                    }}
                  >
                    Connect your domain for a professional image and
                    personalized workspace. Reinforce brand identity and take
                    full ownership of your work management platform.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            padding: "80px 0px",
            textAlign: "center",
            fontWeight: "500",
            fontSize: "30px",
          }}
        >
          <div>Transform Your Workspace with the #1 Tailored</div>
          <div>Team Management Tool</div>
          <div style={{ marginTop: "25px" }}>
            <button
              style={{
                color: "white",
                background: "rgba(0,0,0,1)",
                padding: "10px 35px",
                borderRadius: "25px",
                fontSize: "17px",
                fontWeight: "600",
              }}
              className="mui-button-home"
              onClick={() => {
                navigate("/signup");
                window.scrollTo(0, 0);
              }}
            >
              Get started
            </button>
          </div>
        </div>
        {/* Website Footer */}
        <hr
          style={{
            width: "100%",
            height: "0.02rem",
            backgroundColor: "black",
            border: "none",
            margin: "10px auto",
          }}
        />
        <div
          style={{
            width: "100%",
            padding: "10px 0px 20px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", width: "30%", marginLeft: "10%" }}>
            <FacebookIcon
              className="mui-button"
              style={{ fontSize: "20px", marginRight: "15px" }}
              onClick={() =>
                (window.location.href = "https://www.facebook.com/premeloapp")
              }
            />
            <InstagramIcon
              className="mui-button"
              style={{ fontSize: "20px", marginRight: "15px" }}
              onClick={() =>
                (window.location.href =
                  "https://www.instagram.com/premelo_app/")
              }
            />
            <YouTubeIcon
              className="mui-button"
              style={{ fontSize: "20px", marginRight: "15px" }}
              onClick={() =>
                (window.location.href = "https://www.youtube.com/@Premelo-Inc")
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "70%",
              fontSize: "16px",
              fontWeight: "300",
              textAlign: "right",
              justifyContent: "flex-end",
              marginRight: "10%",
            }}
          >
            <div>© 2023 Premelo, Inc.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomepageMobile;
