import React from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useState } from "react";
import { useEffect } from "react";
import { DataGrid, GridToolbar, GridToolbarContainer } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { v4 as uuid } from "uuid";
import Cookies from "js-cookie";
import ContactMailIcon from "@mui/icons-material/ContactMail";

const MyClient = (props) => {
  const [clientData, setClientData] = useState([]);

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    // Initial check
    handleResize();

    // Attach event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    getClient();
    getFLDetails();
  }, []);
  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      renderCell: (params) => (
        <div
          style={{
            color: `${props.primaryColor}`,
            fontWeight: "600",
            fontSize: "12px",
          }}
          className="mui-button-table"
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "clientCompany",
      headerName: "Company Name",
      width: 200,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      renderCell: (params) => (
        <a
          className="mui-button-table"
          style={{
            color: props.primaryColor,
            fontWeight: "600",
          }}
          href={`mailto:${params.value}`}
          target="_blank"
          rel="noreferrer"
        >
          {params.value}
        </a>
      ),
    },
    { field: "address", headerName: "Address", width: 300 },
    {
      field: "website",
      headerName: "Website",
      width: 200,
      renderCell: (params) => {
        const url = params.value;
        return (
          <a
            className="mui-button-table"
            href={
              url ? (url.startsWith("http") ? url : `https://${url}`) : null
            }
            target="_blank"
            rel="noreferrer"
            style={{
              color: props.primaryColor,
              fontWeight: "600",
            }}
          >
            {params.value}
          </a>
        );
      },
    },
  ];
  const [searchText, setSearchText] = useState("");

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const token = Cookies.get("token");
  const createOrUpdateClient = async () => {
    // Prepare the request body
    const clientData = [
      {
        id: 1,
        designation: "CEO",
        firstName: "Jonathan",
        lastName: "Gan Hong Wee",
        name: "Jonathan Gan Hong Wee",
        phoneNumber: "0102059677",
        email: "iaaijojojo@gmail.com",
        address:
          "No.3 jalan pilus tiga, section 27/24c, shah alam 40400 Selangor",
        website: "www.caydenstudios.com",
        clientCompany: "Cayden Studios Sdn Bhd",
        notes: [
          {
            createdby: "Jonathan Gan Hong Wee",
            createdByInitials: "JG",
            createdOn: "19 January 2023",
            description:
              "This client is one crazy ass client that has lots of lots of money",
          },
          {
            createdby: "Samantha Lee",
            createdByInitials: "SL",
            createdOn: "5 May 2023",
            description:
              "Jonathan is very passionate about his business and is always looking for new opportunities to grow. He can be demanding at times, but he is always fair and reasonable.",
          },
          {
            createdby: "Mark Johnson",
            createdByInitials: "MJ",
            createdOn: "10 April 2023",
            description:
              "Jonathan is a great client to work with. He is always open to new ideas and is willing to invest in his business to achieve his goals.",
          },
        ],
      },

      {
        id: 2,
        designation: "CEO",
        firstName: "Jessica",
        lastName: "Tan",
        name: "Jessica Tan",
        phoneNumber: "0123456789",
        email: "jessicatan@example.com",
        address: "123 Main Street, Anytown, USA",
        website: "www.jessicatan.com",
        clientCompany: "Tan Consulting",
        notes: [
          {
            createdby: "John Smith",
            createdByInitials: "JS",
            createdOn: "1 May 2023",
            description:
              "Jessica is a very organized and detail-oriented client. She is always responsive and great to work with.",
          },
          {
            createdby: "Jane Doe",
            createdByInitials: "JD",
            createdOn: "15 April 2023",
            description:
              "Jessica has very high standards and expects nothing but the best. She can be challenging at times, but her feedback is always constructive.",
          },
          {
            createdby: "Mark Johnson",
            createdByInitials: "MJ",
            createdOn: "30 March 2023",
            description:
              "Jessica is a successful businesswoman and has a great reputation in her industry. It's an honor to work with her.",
          },
        ],
      },

      {
        id: 3,
        designation: "CEO",
        firstName: "Michael",
        lastName: "Lim",
        name: "Micheal Lim",
        phoneNumber: "555-555-5555",
        email: "michaellim@example.com",
        address: "1234 Elm Street, Anytown, USA",
        website: "www.michaellim.com",
        clientCompany: "Lim Consulting",
        notes: [
          {
            createdby: "Jennifer Chen",
            createdByInitials: "JC",
            createdOn: "1 April 2023",
            description:
              "Michael is very knowledgeable and always willing to share his expertise. He is a pleasure to work with.",
          },
          {
            createdby: "David Kim",
            createdByInitials: "DK",
            createdOn: "15 March 2023",
            description:
              "Michael is a perfectionist and can be demanding at times, but he is always fair and reasonable.",
          },
          {
            createdby: "Lisa Patel",
            createdByInitials: "LP",
            createdOn: "28 February 2023",
            description:
              "Michael is a visionary and has a unique perspective on business. It's a privilege to work with him.",
          },
        ],
      },

      {
        id: 4,
        designation: "CEO",
        firstName: "Emily",
        lastName: "Koh",
        name: "Emily Koh",
        phoneNumber: "555-1234",
        email: "emilykoh@example.com",
        address: "5678 Oak Street, Anytown, USA",
        website: "www.emilykoh.com",
        clientCompany: "Koh Enterprises",
        notes: [
          {
            createdby: "Jason Chen",
            createdByInitials: "JC",
            createdOn: "1 January 2023",
            description:
              "Emily is a very driven and ambitious client. She has high expectations and demands the best from everyone around her.",
          },
          {
            createdby: "Jessica Li",
            createdByInitials: "JL",
            createdOn: "15 December 2022",
            description:
              "Emily is a creative and innovative business owner who is always looking for new ideas to grow her business.",
          },
          {
            createdby: "David Wong",
            createdByInitials: "DW",
            createdOn: "1 November 2022",
            description:
              "Emily is a pleasure to work with. She is always friendly and professional and is great at communicating her needs and goals.",
          },
        ],
      },

      {
        id: 5,
        designation: "CEO",
        firstName: "Daniel",
        lastName: "Ng",
        name: "Daniel Ng",
        phoneNumber: "0123456789",
        email: "danielng@example.com",
        address: "123 Main Street, Anytown, USA",
        website: "www.danielng.com",
        clientCompany: "Ng Consulting",
        notes: [
          {
            createdby: "John Smith",
            createdByInitials: "JS",
            createdOn: "1 May 2023",
            description:
              "Daniel is a very organized and detail-oriented client. He is always responsive and great to work with.",
          },
          {
            createdby: "Jane Doe",
            createdByInitials: "JD",
            createdOn: "15 April 2023",
            description:
              "Daniel is very focused and driven. He knows what he wants and is not afraid to take risks to achieve his goals.",
          },
          {
            createdby: "Mark Johnson",
            createdByInitials: "MJ",
            createdOn: "30 March 2023",
            description:
              "Daniel is a rising star in his industry. It's an honor to work with him and be a part of his success.",
          },
        ],
      },

      {
        id: 6,
        designation: "CEO",
        firstName: "Linda",
        lastName: "Tan",
        name: "Linda Tan",
        phoneNumber: "555-555-5555",
        email: "lindatan@example.com",
        address: "1234 Elm Street, Anytown, USA",
        website: "www.lindatan.com",
        clientCompany: "Tan Enterprises",
        notes: [
          {
            createdby: "Jennifer Chen",
            createdByInitials: "JC",
            createdOn: "1 April 2023",
            description:
              "Linda is a very creative and talented business owner. She has a great sense of style and is always coming up with new and innovative ideas.",
          },
          {
            createdby: "David Kim",
            createdByInitials: "DK",
            createdOn: "15 March 2023",
            description:
              "Linda is a perfectionist and demands the best from everyone around her. She can be challenging to work with at times, but her high standards always lead to great results.",
          },
          {
            createdby: "Lisa Patel",
            createdByInitials: "LP",
            createdOn: "28 February 2023",
            description:
              "Linda is very passionate about her business and is always looking for new ways to grow and expand. It's an exciting time to be working with her.",
          },
        ],
      },

      {
        id: 7,
        designation: "CEO",
        firstName: "David",
        lastName: "Tan",
        name: "David Tan",
        phoneNumber: "555-1234",
        email: "davidtan@example.com",
        address: "5678 Oak Street, Anytown, USA",
        website: "www.davidtan.com",
        clientCompany: "Tan Consulting",
        notes: [
          {
            createdby: "Jason Chen",
            createdByInitials: "JC",
            createdOn: "1 January 2023",
            description:
              "David is a very driven and ambitious client. He has high expectations and demands the best from everyone around him.",
          },
          {
            createdby: "Jessica Li",
            createdByInitials: "JL",
            createdOn: "15 December 2022",
            description:
              "David is a strategic thinker and always has a clear vision for his business. It's exciting to work with him and help bring his ideas to life.",
          },
          {
            createdby: "David Wong",
            createdByInitials: "DW",
            createdOn: "1 November 2022",
            description:
              "David is a pleasure to work with. He is always friendly and professional and is great at communicating his needs and goals.",
          },
        ],
      },

      {
        id: 8,
        designation: "CEO",
        firstName: "Chloe",
        lastName: "Lee",
        name: "Chloe Lee",
        phoneNumber: "0123456789",
        email: "chloeleelovesart@example.com",
        address: "123 Main Street, Anytown, USA",
        website: "www.chloeleelovesart.com",
        clientCompany: "Chloe Lee Art Studio",
        notes: [
          {
            createdby: "John Smith",
            createdByInitials: "JS",
            createdOn: "1 May 2023",
            description:
              "Chloe is a very talented artist and a passionate business owner. She has a unique style and is always pushing the boundaries of her craft.",
          },
          {
            createdby: "Jane Doe",
            createdByInitials: "JD",
            createdOn: "15 April 2023",
            description:
              "Chloe is very dedicated to her work and always puts her heart and soul into every project. It's inspiring to see her passion and creativity in action.",
          },
          {
            createdby: "Mark Johnson",
            createdByInitials: "MJ",
            createdOn: "30 March 2023",
            description:
              "Chloe is a rising star in the art world. It's an honor to work with her and be a part of her journey to success.",
          },
        ],
      },

      {
        id: 9,
        designation: "CEO",
        firstName: "Jasmine",
        lastName: "Wong",
        name: "Jasmine Wong",
        phoneNumber: "555-555-5555",
        email: "jasminewong@example.com",
        address: "1234 Elm Street, Anytown, USA",
        website: "www.jasminewong.com",
        clientCompany: "Wong Enterprises",
        notes: [
          {
            createdby: "Jennifer Chen",
            createdByInitials: "JC",
            createdOn: "1 April 2023",
            description:
              "Jasmine is a very detail-oriented client who always wants to ensure that every aspect of her business is perfect. She can be demanding at times, but her high standards always lead to great results.",
          },
          {
            createdby: "David Kim",
            createdByInitials: "DK",
            createdOn: "15 March 2023",
            description:
              "Jasmine is a strategic thinker who always has a clear vision for her business. She is great at communicating her ideas and collaborating with her team to achieve her goals.",
          },
          {
            createdby: "Lisa Patel",
            createdByInitials: "LP",
            createdOn: "28 February 2023",
            description:
              "Jasmine is a pleasure to work with. She is always friendly and professional and is great at balancing the big picture with the details.",
          },
        ],
      },

      {
        id: 10,
        designation: "CEO",
        firstName: "Ryan",
        lastName: "Goh",
        name: "Ryan Goh",
        phoneNumber: "555-1234",
        email: "ryangoh@example.com",
        address: "5678 Oak Street, Anytown, USA",
        website: "www.ryangoh.com",
        clientCompany: "Goh Consulting",
        notes: [
          {
            createdby: "Jason Chen",
            createdByInitials: "JC",
            createdOn: "1 January 2023",
            description:
              "Ryan is a very analytical client who always wants to ensure that every decision is based on data and facts. He can be a bit reserved at times, but he is always approachable and willing to listen.",
          },
          {
            createdby: "Jessica Li",
            createdByInitials: "JL",
            createdOn: "15 December 2022",
            description:
              "Ryan is a strategic thinker and has a great vision for his business. He is always looking for new opportunities to grow and expand.",
          },
          {
            createdby: "David Wong",
            createdByInitials: "DW",
            createdOn: "1 November 2022",
            description:
              "Ryan is a pleasure to work with. He is always professional and respectful, and he values the opinions and expertise of his team.",
          },
        ],
      },

      {
        id: 11,
        designation: "CEO",
        firstName: "Grace",
        lastName: "Chen",
        name: "Grace Chan",
        phoneNumber: "0123456789",
        email: "gracechen@example.com",
        address: "123 Main Street, Anytown, USA",
        website: "www.gracechen.com",
        clientCompany: "Chen Consulting",
        notes: [
          {
            createdby: "John Smith",
            createdByInitials: "JS",
            createdOn: "1 May 2023",
            description:
              "Grace is a very creative and passionate client. She has a great vision for her business and is always looking for new ways to bring her ideas to life.",
          },
          {
            createdby: "Jane Doe",
            createdByInitials: "JD",
            createdOn: "15 April 2023",
            description:
              "Grace is a great communicator and collaborator. She values the opinions and expertise of her team and is always open to new ideas and approaches.",
          },
          {
            createdby: "Mark Johnson",
            createdByInitials: "MJ",
            createdOn: "30 March 2023",
            description:
              "Grace is a rising star in her industry. It's an honor to work with her and be a part of her journey to success.",
          },
        ],
      },
    ];

    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/myclient-record",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ contact_data: clientData }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create/update client.");
      }

      const data = await response.json();
      console.log("Client created/updated:", data);
    } catch (error) {
      console.error(error);
    }
  };
  const getClient = async () => {
    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/get-myclient-record",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch client data.");
      }

      const data = await response.json();
      console.log(data.contact_data);
      const clients = JSON.parse(data.contact_data);
      console.log(Array.isArray(clients)); // Check if clientData is an array
      setClientData(clients);
    } catch (error) {
      console.error(error);
    }
  };

  const [userFLData, setUserFLData] = useState("");

  const getFLDetails = async () => {
    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/get-fldetails",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch fl details");
      }

      const data = await response.json();
      const userInitials = `${data.first_name.charAt(0)}${data.last_name.charAt(
        0
      )}`;
      setUserFLData(data);
      console.log(userInitials);
    } catch (error) {
      console.error(error);
    }
  };
  const lowercaseSearchText = searchText.toLowerCase();

  const filteredContacts = clientData.filter((contact) => {
    const name = `${contact.firstName} ${contact.lastName}`.toLowerCase();
    const phone = contact.phoneNumber?.toLowerCase();
    const email = contact.email?.toLowerCase();
    const address = contact.address?.toLowerCase();
    const website = contact.website?.toLowerCase();
    const company = contact.clientCompany?.toLowerCase();
    return (
      name.includes(lowercaseSearchText) ||
      (phone && phone.includes(lowercaseSearchText)) ||
      (email && email.includes(lowercaseSearchText)) ||
      (address && address.includes(lowercaseSearchText)) ||
      (website && website.includes(lowercaseSearchText)) ||
      (company && company.includes(lowercaseSearchText))
    );
  });

  const navigate = useNavigate();

  const ClientProfile = ({ clientData }) => {
    useEffect(() => {
      console.log(clientData);
    }, []);

    return (
      <div
        style={{
          width: "100%",
          height: windowHeight*0.87,
        }}
      >
        <div
          style={{
            display: "flex",
            background: "rgba(255,255,255,0.7)",
            backdropFilter: "blur(20px)",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
            borderRadius: "5px",
            height: "calc(100% - 30px)",
            width: "calc(100% - 30px)",
            marginTop: "10px",
            position: "relative",
            zIndex: 490,
            padding: "15px",
          }}
        >
          <div style={{ width: "25%" }}>
            <div style={{ padding: "15px 0px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#818b9c",
                    borderRadius: "50px",
                    width: "40px",
                    height: "40px",
                  }}
                >
                  <p style={{ color: "white" }}>
                    {clientData.firstName.charAt(0).toUpperCase()}
                    {clientData.lastName.charAt(0).toUpperCase()}
                  </p>
                </div>
                <div
                  style={{
                    fontSize: "18px",
                    marginLeft: "10px",
                    fontWeight: "600",
                  }}
                >
                  {`${clientData.firstName} ${clientData.lastName}`}
                </div>
              </div>
              <div
                style={{
                  marginLeft: "50px",
                  fontSize: "12px",
                  fontWeight: "300",
                  marginTop: "-1px",
                }}
              >
                {clientData.clientCompany ? (
                  <>
                    {clientData.designation} of {clientData.clientCompany}
                  </>
                ) : null}
              </div>
              <div
                style={{
                  marginLeft: "50px",
                  fontSize: "12px",
                  fontWeight: "300",
                  marginTop: "-1px",
                }}
              >
                {clientData.email}
              </div>
            </div>
            <hr
              style={{
                width: "100%",
                borderColor: "rgba(0,0,0,0.1)",
              }}
            />
            <div style={{ fontSize: "11px", marginTop: "15px" }}>
              <div style={{ fontSize: "16px", fontWeight: "600" }}>
                Contact Information
              </div>
              <p>Email: </p>
              <p style={{ marginTop: "-12px" }}>{clientData.email}</p>
              <p>Phone: </p>
              <p style={{ marginTop: "-12px" }}>{clientData.phoneNumber}</p>
              <p>Address: </p>
              <p style={{ marginTop: "-12px" }}>{clientData.address}</p>
              <p>Website:</p>
              <p style={{ marginTop: "-12px" }}>
                <a
                  href={
                    clientData.website
                      ? clientData.website.startsWith("http")
                        ? clientData.website
                        : `https://${clientData.website}`
                      : null
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  {clientData.website}
                </a>
              </p>
            </div>
          </div>
          <hr
            style={{
              margin: "0px",
              width: ".1px",
              borderColor: "rgba(0,0,0,0.1)",
            }}
          />
          <div style={{ width: "50%" }}>
            <div
              style={{
                padding: "3%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ fontSize: "16px", fontWeight: "600" }}>
                Client Notes
              </div>
              <div
                className="mui-button"
                style={{
                  background: props.complementaryColor,
                  display: "flex",
                  textAlign: "center",
                  alignItems: "center",
                  borderRadius: "10px",
                  padding: "7px 20px",
                  fontSize: "10px",
                  fontWeight: "600",
                  color: "white",
                }}
                onClick={() => setOpenCreateNotes(true)}
              >
                Create Notes
              </div>
            </div>
            <div style={{ padding: "10px 20px" }}>
              {clientData.notes.map((note, index) => (
                <div
                  key={index}
                  style={{
                    background: "white",
                    borderRadius: "15px",
                    fontSize: "10px",
                    padding: "12px",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                    }}
                  >
                    <div
                      style={{
                        margin: "0px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "#818b9c",
                          width: "25px",
                          height: "25px",
                          borderRadius: "50px",
                        }}
                      >
                        <p style={{ fontWeight: "600", color: "white" }}>
                          {note.createdByInitials}
                        </p>
                      </div>
                      <p style={{ marginLeft: "7px" }}>{note.createdby}</p>
                    </div>
                    <div>
                      <p style={{ margin: "0px" }}>
                        {new Date(note.createdOn).toLocaleString("en-US", {
                          day: "numeric",
                          month: "long",
                          year: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })}
                      </p>
                    </div>
                  </div>
                  <p style={{ margin: "0px" }}>{note.description}</p>
                </div>
              ))}
            </div>
          </div>
          <hr
            style={{
              margin: "0px",
              width: ".1px",
              borderColor: "rgba(0,0,0,0.1)",
            }}
          />
          <div style={{ width: "25%" }}>
            <div
              style={{
                padding: "3%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ fontSize: "16px", fontWeight: "600" }}>
                Ongoing-updates
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const [selectedClient, setSelectedClient] = useState("");
  const handleNameClick = (params) => {
    const id = params.row.id;
    const selectedClient = clientData.find((row) => row.id === id);
    setSelectedClient(selectedClient);
    console.log(selectedClient);
    setClientSelected(true);
  };

  const rows = filteredContacts.map((contact) => ({
    id: contact.id,
    designation: contact.designation,
    firstName: contact.firstName,
    lastName: contact.lastName,
    name: `${contact.firstName} ${contact.lastName}`,
    clientCompany: contact.clientCompany,
    phoneNumber: contact.phoneNumber,
    email: contact.email,
    address: contact.address,
    website: contact.website,
  }));
  const [clientSelected, setClientSelected] = useState(false);

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer>
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          <div style={{ marginRight: "10px", marginLeft: "7px" }}>
            {/* <input
              type="text"
              placeholder="Search contacts..."
              value={searchText}
              onChange={handleSearchChange}
              autoFocus={true}
              style={{
                fontSize: "10px",
                background: "transparent",
                borderRadius: "10px",
                outline: "none",
                border: "1px solid rgba(0,0,0,0.1)",
                padding: "6px 12px",
                width: "200px",
              }}
            /> */}
          </div>
          <div style={{ marginLeft: "auto" }}>
            <GridToolbar style={{ fontSize: "10px" }} />
          </div>
        </div>
      </GridToolbarContainer>
    );
  };

  const [openCreateClient, setOpenCreateClient] = useState(false);

  const [client, setClient] = useState({
    designation: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    address: "",
    website: "",
    clientCompany: "",
    notes: [],
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setClient((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    setOpenCreateClient(false);
    event.preventDefault();
    const newId = uuid();
    const name = `${client.firstName} ${client.lastName}`;
    const newClient = {
      id: newId,
      designation: client.designation,
      firstName: client.firstName,
      lastName: client.lastName,
      name: name,
      phoneNumber: client.phoneNumber,
      email: client.email,
      address: client.address,
      website: client.website,
      clientCompany: client.clientCompany,
      notes: client.notes,
    };

    try {
      const updatedData = [...clientData, newClient];
      console.log(updatedData); // Log the updated clientData array

      const response = await fetch(
        "https://ww1.premelo.com/api/myclient-record",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ contact_data: updatedData }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create/update client.");
      }

      const data = await response.json();
      console.log("Client created/updated:", data);

      // Update the state with the updated client data
      setClientData(updatedData);
    } catch (error) {
      console.error(error);
    }

    setClient({
      designation: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      address: "",
      website: "",
      clientCompany: "",
      notes: [],
    });
  };

  const openCreateClientContent = (
    <div>
      <div
        style={{
          background: props.primaryColor,
          height: "50px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ margin: "0px 12px", color: "white", fontSize: "15px" }}>
          Create New Contact
        </div>
        <div style={{ margin: "0px 12px", color: "white", fontSize: "12px" }}>
          <CancelIcon
            className="mui-button"
            style={{ color: "white", fontSize: "18px" }}
            onClick={() => setOpenCreateClient(false)}
          />
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div style={{ padding: "0px 20px", marginTop: "12px" }}>
          <div style={{ fontSize: "13px", fontWeight: "600" }}>First Name</div>
          <input
            required
            style={{
              fontSize: "11px",
              fontWeight: "300",
              width: "95%",
              padding: "5.5px 8px",
              borderRadius: "7px",
              border: "1px solid lightgrey",
              background: `${props.primaryColor}1A`,
            }}
            type="text"
            id="firstName"
            name="firstName"
            value={client.firstName}
            onChange={handleInputChange}
          />
        </div>
        <div style={{ padding: "0px 20px", marginTop: "12px" }}>
          <div style={{ fontSize: "13px", fontWeight: "600" }}>Last Name</div>
          <input
            required
            style={{
              fontSize: "11px",
              fontWeight: "300",
              width: "95%",
              padding: "5.5px 8px",
              borderRadius: "7px",
              border: "1px solid lightgrey",
              background: `${props.primaryColor}1A`,
            }}
            type="text"
            id="lastName"
            name="lastName"
            value={client.lastName}
            onChange={handleInputChange}
          />
        </div>
        <div style={{ padding: "0px 20px", marginTop: "12px" }}>
          <div style={{ fontSize: "13px", fontWeight: "600" }}>
            Phone Number
          </div>
          <input
            style={{
              fontSize: "11px",
              fontWeight: "300",
              width: "95%",
              padding: "5.5px 8px",
              borderRadius: "7px",
              border: "1px solid lightgrey",
              background: `${props.primaryColor}1A`,
            }}
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            value={client.phoneNumber}
            onChange={handleInputChange}
          />
        </div>
        <div style={{ padding: "0px 20px", marginTop: "12px" }}>
          <div style={{ fontSize: "13px", fontWeight: "600" }}>Email</div>
          <input
            style={{
              fontSize: "11px",
              fontWeight: "300",
              width: "95%",
              padding: "5.5px 8px",
              borderRadius: "7px",
              border: "1px solid lightgrey",
              background: `${props.primaryColor}1A`,
            }}
            type="text"
            id="email"
            name="email"
            value={client.email}
            onChange={handleInputChange}
          />
        </div>
        <div style={{ padding: "0px 20px", marginTop: "12px" }}>
          <div style={{ fontSize: "13px", fontWeight: "600" }}>Address</div>
          <input
            style={{
              fontSize: "11px",
              fontWeight: "300",
              width: "95%",
              padding: "5.5px 8px",
              borderRadius: "7px",
              border: "1px solid lightgrey",
              background: `${props.primaryColor}1A`,
            }}
            type="text"
            id="address"
            name="address"
            value={client.address}
            onChange={handleInputChange}
          />
        </div>
        <div style={{ padding: "0px 20px", marginTop: "12px" }}>
          <div style={{ fontSize: "13px", fontWeight: "600" }}>
            Website Link
          </div>
          <input
            style={{
              fontSize: "11px",
              fontWeight: "300",
              width: "95%",
              padding: "5.5px 8px",
              borderRadius: "7px",
              border: "1px solid lightgrey",
              background: `${props.primaryColor}1A`,
            }}
            type="text"
            id="website"
            name="website"
            value={client.website}
            onChange={handleInputChange}
          />
        </div>
        <div style={{ padding: "0px 20px", marginTop: "12px" }}>
          <div style={{ fontSize: "13px", fontWeight: "600" }}>
            Company Name
          </div>
          <input
            style={{
              fontSize: "11px",
              fontWeight: "300",
              width: "95%",
              padding: "5.5px 8px",
              borderRadius: "7px",
              border: "1px solid lightgrey",
              background: `${props.primaryColor}1A`,
            }}
            type="text"
            id="clientCompany"
            name="clientCompany"
            value={client.clientCompany}
            onChange={handleInputChange}
          />
        </div>
        <div style={{ padding: "0px 20px", marginTop: "12px" }}>
          <div style={{ fontSize: "13px", fontWeight: "600" }}>Designation</div>
          <input
            style={{
              fontSize: "11px",
              fontWeight: "300",
              width: "95%",
              padding: "5.5px 8px",
              borderRadius: "7px",
              border: "1px solid lightgrey",
              background: `${props.primaryColor}1A`,
            }}
            type="text"
            id="designation"
            name="designation"
            value={client.designation}
            onChange={handleInputChange}
          />
        </div>
        <div
          style={{
            padding: "0px 20px",
            marginTop: "12px",
            textAlign: "right",
          }}
        >
          <button
            className="mui-button"
            style={{
              background: props.primaryColor,
              color: "white",
              borderRadius: "6px",
              padding: "7px 20px",
              border: "none",
              fontSize: "11px",
              fontWeight: "600",
            }}
            type="submit"
          >
            Create
          </button>
        </div>
      </form>
    </div>
  );

  const handleNotesSubmit = async (event) => {
    setOpenCreateNotes(false);
    event.preventDefault();
    console.log(userFLData);
    console.log(clientData);
    console.log(selectedClient.id);
    // Get the note content from the form
    const noteContent = event.target.elements.noteContent.value;
    console.log(noteContent);
    const currentDate = new Date();

    // Create the note object
    const note = {
      createdby: `${userFLData.first_name} ${userFLData.last_name}`,
      createdByInitials: `${userFLData.first_name.charAt(
        0
      )}${userFLData.last_name.charAt(0)}`,
      createdOn: currentDate.toISOString(), // Use ISO string format to include both date and time
      description: noteContent,
    };
    console.log(note);

    try {
      // Find the selected client in the clientData array
      const clientIndex = clientData.findIndex(
        (client) => client.id === selectedClient.id
      );

      console.log(clientIndex);
      if (clientIndex !== -1) {
        // Update the client's notes array with the new note
        const updatedClientData = [...clientData];
        updatedClientData[clientIndex].notes.push(note);

        // Optional: Sort the notes by createdOn date if needed
        updatedClientData[clientIndex].notes.sort(
          (a, b) => new Date(b.createdOn) - new Date(a.createdOn)
        );

        // Update the state with the updated client data
        setClientData(updatedClientData);

        // Send the updated client data to the API
        const response = await fetch(
          "https://ww1.premelo.com/api/myclient-record",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({ contact_data: updatedClientData }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to update client data.");
        }

        const data = await response.json();
        console.log("Client data updated:", data);
      }
    } catch (error) {
      console.error(error);
    }

    // Reset the form
    event.target.reset();
  };

  const openCreateClientNotes = (
    <div>
      <div
        style={{
          background: props.primaryColor,
          height: "50px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ margin: "0px 12px", color: "white", fontSize: "15px" }}>
          Create Notes
        </div>
        <div style={{ margin: "0px 12px", color: "white", fontSize: "12px" }}>
          <CancelIcon
            className="mui-button"
            style={{ color: "white", fontSize: "18px" }}
            onClick={() => setOpenCreateNotes(false)}
          />
        </div>
      </div>
      <form onSubmit={handleNotesSubmit}>
        <div style={{ padding: "0px 20px", marginTop: "12px" }}>
          <div style={{ fontSize: "13px", fontWeight: "600" }}>
            Notes content
          </div>
          <textarea
            style={{
              fontSize: "11px",
              fontWeight: "300",
              width: "95%",
              height: "70px",
              marginTop: "5px",
              padding: "5.5px 8px",
              borderRadius: "7px",
              border: "1px solid lightgrey",
              background: `${props.primaryColor}1A`,
            }}
            type="text"
            id="noteContent"
            name="noteContent"
            placeholder="Your client's updates ...."
          />
        </div>
        <div
          style={{
            padding: "0px 20px",
            marginTop: "12px",
            textAlign: "right",
          }}
        >
          <button
            className="mui-button"
            style={{
              background: props.primaryColor,
              color: "white",
              borderRadius: "6px",
              padding: "7px 20px",
              border: "none",
              fontSize: "11px",
              fontWeight: "600",
            }}
            type="submit"
          >
            Create
          </button>
        </div>
      </form>
    </div>
  );
  const [openCreateNotes, setOpenCreateNotes] = useState(false);

  const CustomNoRowsOverlay = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: "14px",
          fontWeight: "bold",
          color: "gray",
        }}
      >
        <div style={{ textAlign: "center", color: "rgba(0,0,0,0.1)" }}>
          <ContactMailIcon style={{ fontSize: "90px" }} />
          <div style={{ color: "rgba(0,0,0,0.2)" }}>No contacts found</div>
        </div>
      </div>
    );
  };
  return (
    <div style={{}}>
      {/* Header */}
      <div
        style={{
          display: "flex",
          borderBottom: "1px solid rgba(0,0,0,0.1)",
          alignItems: "center",
          background: "rgba(255,255,255,0.7)",
          backdropFilter: "blur(20px)",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
          padding: "30px 0px 10px 0px",
          position: "relative",
          zIndex: 490,
        }}
      >
        <div
          className="mui-button"
          style={{
            color: props.primaryColor,
            fontSize: "22px",
            fontWeight: "600",
            marginLeft: "20px",
            paddingBottom: "1%",
          }}
          onClick={() => setClientSelected(false)}
        >
          My Clients
        </div>
        <div
          style={{
            marginTop: "-7px",
            marginLeft: "13px",
          }}
        >
          <AddCircleIcon
            onClick={() => setOpenCreateClient(true)}
            className="mui-button-table"
            style={{
              fontSize: "20px",
              color: props.primaryColor,
            }}
          />
        </div>
      </div>
      {/* Body */}
      <div style={{ padding: "0% 2%" }}>
        {clientSelected ? (
          <ClientProfile clientData={selectedClient} />
        ) : (
          <div
            style={{
              marginTop: "15px",
              background: "rgba(255,255,255,0.7)",
              backdropFilter: "blur(20px)",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
              borderRadius: "5px",
              position: "relative",
              zIndex: 490,
            }}
          >
            <input
              type="text"
              placeholder="Search contacts..."
              value={searchText}
              onChange={handleSearchChange}
              autoFocus={true}
              style={{
                fontSize: "10px",
                background: "transparent",
                borderRadius: "10px",
                outline: "none",
                border: "1px solid rgba(0,0,0,0.1)",
                padding: "6px 12px",
                width: "200px",
                position: "relative",
                zIndex: "205",
                marginTop: "20px",
                marginLeft: "10px",
              }}
            />
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={200}
              style={{
                fontSize: "11px",
                height: "80vh",
                marginTop: "-35px",
                border: "none",
              }}
              rowHeight={35}
              onCellClick={(params) => {
                if (params.field === "name") {
                  handleNameClick(params);
                }
              }}
              slots={{
                toolbar: CustomToolbar,
                noRowsOverlay: CustomNoRowsOverlay,
              }}
            />
          </div>
        )}
      </div>
      <Modal
        isOpen={openCreateClient}
        onRequestClose={() => setOpenCreateClient(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            zIndex: 505,
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30%",
            height: "87%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            borderRadius: "10px",
            padding: "0px",
            borderColor: "transparent",
            outline: "none",
            border: "none",
          },
        }}
      >
        {openCreateClientContent}
      </Modal>
      <Modal
        isOpen={openCreateNotes}
        onRequestClose={() => setOpenCreateNotes(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            zIndex: 505,
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30%",
            height: "37%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            borderRadius: "10px",
            padding: "0px",
            borderColor: "transparent",
            outline: "none",
            border: "none",
          },
        }}
      >
        {openCreateClientNotes}
      </Modal>
    </div>
  );
};

export default MyClient;
