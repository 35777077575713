import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "./images/CS_Favicon-01.png";
import DefaultProfile from "./images/defaultprofile.jpg";
import { ReactComponent as MyIcon } from "./icons/menu_1.svg";
import { ReactComponent as MyIcon1 } from "./icons/menu_2.svg";
import { ReactComponent as MyIcon2 } from "./icons/menu_3.svg";
import { ReactComponent as MyIcon3 } from "./icons/menu_4.svg";
import { ReactComponent as MyIcon4 } from "./icons/menu_5.svg";
import { ReactComponent as MyIcon5 } from "./icons/menu_6.svg";
import { ReactComponent as FolderIcon } from "./icons/folder.svg";
import Cookies from "js-cookie";
import Modal from "react-modal";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import React, { useState } from "react";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import ListIcon from "@mui/icons-material/List";
import "./App.css";
import { useEffect } from "react";
import { MentionsInput, Mention } from "react-mentions";
import DashContentMobile from "./DashContentMobile";
import CRM from "./TaskContent";
import ProjectContent from "./ProjectContent";
import KanbanContent from "./KanbanContent";
import { ChromePicker } from "react-color";
import InventoryIcon from "@mui/icons-material/Inventory";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import ProjectBarLoadoutMobile from "./ProjectBarLoadoutMobile";
import NotificationContentMobile from "./NotificationContentMobile";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import StoreIcon from "@mui/icons-material/Store";
import SubscriptionContentMobile from "./SubscriptionContentMobile";
import WebIcon from "@mui/icons-material/Web";
import GridViewIcon from "@mui/icons-material/GridView";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import MenuIcon from "@mui/icons-material/Menu";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SettingsIcon from "@mui/icons-material/Settings";
import AddIcon from "@mui/icons-material/Add";
import CrossIcon from "@mui/icons-material/Clear";
import { ListItemText, ListItemIcon } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/CheckCircleOutline";
import CheckedIcon from "@mui/icons-material/CheckCircle";
import Select from "@mui/material/Select";
import Settings from "./Settings";
import CalendarContent from "./CalendarContent";
import { SettingsInputComponentSharp } from "@mui/icons-material";
import { TwitterPicker } from "react-color";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import LanIcon from "@mui/icons-material/Lan";
import GroupsIcon from "@mui/icons-material/Groups";
import GroupIcon from "@mui/icons-material/Group";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import WorkIcon from "@mui/icons-material/Work";
import HubIcon from "@mui/icons-material/Hub";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import TerminalIcon from "@mui/icons-material/Terminal";
import PaymentIcon from "@mui/icons-material/Payment";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import HandshakeIcon from "@mui/icons-material/Handshake";
import HandymanIcon from "@mui/icons-material/Handyman";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import DescriptionIcon from "@mui/icons-material/Description";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import BusinessIcon from "@mui/icons-material/Business";
import CallIcon from "@mui/icons-material/Call";
import CalculateIcon from "@mui/icons-material/Calculate";
import CookieIcon from "@mui/icons-material/Cookie";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import HeadsetIcon from "@mui/icons-material/Headset";
import PreviewSkeletonMobile from "./PreviewSkeletonMobile";
import { color } from "framer-motion";
import ProjectsMobile from "./ProjectsMobile";
import SettingsMobile from "./SettingsMobile";
import KommunicateChat from "./chatMobile";
import MyClientMobile from "./MyClientMobile";
import ProductMobile from "./ProductMobile";
import OrdersMobile from "./OrdersMobile";
import FormsMobile from "./FormsMobile";
import EventsMobile from "./EventsMobile";
import { v4 as uuid } from "uuid";

function DashboardMobile() {
  const [primaryColor, setPrimaryColor] = useState(
    Cookies.get("company_color") ?? "#1872d9"
  );
  const [companyLogo, setCompanyLogo] = useState(
    Cookies.get("company_logo") ?? null
  );
  const token = Cookies.get("token");
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState("");
  const [userId, setUserId] = useState("");
  const [profile_photo, setProfilePhoto] = useState(DefaultProfile);
  const [showText, setShowText] = useState(false);
  const [projectTitle, setProjectTitle] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [availableUsers, setAvailableUsers] = useState([]);
  const [availableUsersWithEmail, setAvailableUsersWithEmail] = useState([]);
  const [taskAssignedTo, setTaskAssignedTo] = useState("");

  const [projectSelectedUsers, setProjectSelectedUsers] = useState([]);
  const [projectType, setProjectType] = useState("");

  const [menuItems, setMenuItems] = useState([]);
  const secondaryColor = `rgba(${
    parseInt(primaryColor?.slice(1, 3), 16) ?? 0
  }, ${parseInt(primaryColor?.slice(3, 5), 16) ?? 0}, ${
    parseInt(primaryColor?.slice(5, 7), 16) ?? 0
  }, 0.05)`;

  const primaryRGB = primaryColor
    ? primaryColor
        .substring(1)
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
    : [0, 0, 0];

  const complementaryRGB = primaryRGB.map((color) => 255 - color);
  const complementaryColor = `rgb(${complementaryRGB.join(",")})`;

  const handleProjectTypeChange = (event) => {
    const { value } = event.target;
    setProjectType(value);
  };
  const [companyDomain, setCompanyDomain] = useState("");

  const fetchCompanyDetails = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/company-details",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    console.log(data.company_logo);
    const logoUrl = data.company_logo;
    setCompanyLogo(logoUrl);
    setCompanyDomain(data.company_domain);
    console.log(data.company_domain);

    if (data.company_color) {
      Cookies.set("company_color", data.company_color);
      setPrimaryColor(data.company_color);
    }
    if (logoUrl) {
      Cookies.set("company_logo", logoUrl);
      setCompanyLogo(logoUrl);
    }
  };

  const fetchUsersWithEmail = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/user-available-team-members-with-email",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    const modifiedData = data.map((user) => {
      return {
        ...user,
        fullName: `${user.first_name} ${user.last_name}`,
      };
    });
    setAvailableUsersWithEmail(modifiedData);
  };

  const getIcon = (label) => {
    switch (label) {
      case "Dashboard":
        return (
          <GridViewIcon
            style={{
              marginTop: "-3px",
              marginRight: "0px",
              marginBottom: "-6px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
            }}
          />
        );
      case "Projects":
        return (
          <ContentCopyIcon
            style={{
              marginTop: "-3px",
              marginRight: "0px",
              marginBottom: "-6px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
            }}
          />
        );
      case "News":
        return (
          <NewspaperIcon
            style={{
              marginTop: "-3px",
              marginRight: "0px",
              marginBottom: "-6px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
            }}
          />
        );
      case "Calendar":
        return (
          <CalendarTodayIcon
            style={{
              marginTop: "-3px",
              marginRight: "0px",
              marginBottom: "-6px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
            }}
          />
        );
      case "Kanban":
        return (
          <MyIcon3
            style={{
              marginTop: "-3px",
              marginRight: "0px",
              marginBottom: "-6px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
            }}
          />
        );
      case "Notification":
        return (
          <NotificationsNoneIcon
            style={{
              marginTop: "-3px",
              marginRight: "0px",
              marginBottom: "-6px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
            }}
          />
        );
      case "Subscription":
        return (
          <MonetizationOnIcon
            style={{
              marginTop: "-3px",
              marginRight: "0px",
              marginBottom: "-6px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
            }}
          />
        );
      case "Logout":
        return (
          <SettingsIcon
            style={{
              marginTop: "-3px",
              marginRight: "0px",
              marginBottom: "-6px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
            }}
          />
        );
      case "My Client":
        return (
          <RecentActorsIcon
            style={{
              marginTop: "-3px",
              marginRight: "0px",
              marginBottom: "-6px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
            }}
          />
        );
      case "Forms":
        return (
          <ListIcon
            style={{
              marginTop: "-3px",
              marginRight: "0px",
              marginBottom: "-6px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
            }}
          />
        );
      case "Sales Lead":
        return (
          <ContactPhoneIcon
            style={{
              marginTop: "-3px",
              marginRight: "0px",
              marginBottom: "-6px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
            }}
          />
        );
      case "Web Builder":
        return (
          <WebIcon
            style={{
              marginTop: "-3px",
              marginRight: "0px",
              marginBottom: "-6px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
            }}
          />
        );
      case "Products":
        return (
          <InventoryIcon
            style={{
              marginTop: "-3px",
              marginRight: "0px",
              marginBottom: "-6px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
            }}
          />
        );
      case "Orders":
        return (
          <StoreIcon
            style={{
              marginTop: "-3px",
              marginRight: "0px",
              marginBottom: "-6px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
            }}
          />
        );
      case "Events":
        return (
          <LocalActivityIcon
            style={{
              marginTop: "-3px",
              marginRight: "0px",
              marginBottom: "-6px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
            }}
          />
        );
      case "Settings":
        return (
          <SettingsIcon
            style={{
              marginTop: "-3px",
              marginRight: "0px",
              marginBottom: "-6px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
            }}
          />
        );
      default:
        return null;
    }
  };

  const [notifications, setNotifications] = useState([]);
  const unreadNotifications = notifications.filter(
    (notification) => !notification.read
  ).length;

  useEffect(() => {
    fetchCompanyDetails();
    const token = Cookies.get("token");
    if (!token) {
      Cookies.remove("token");
      navigate("/login"); // programmatically navigate to /home
    }
    if (token) {
      axios
        .get("/api/user-data", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const { name, is_admin, menu_items, profile_photo } = response.data;
          setName(name);
          setIsAdmin(is_admin ? true : false);
          console.log(is_admin ? true : false);
          // setMenuItems(menu_items);
          setProfilePhoto(profile_photo);
          setMenuItems([
            {
              label: "Dashboard",
              path: "/dashboard",
            },
            {
              label: "Projects",
              path: "/projects",
            },
            {
              label: "Notification",
              path: "/notification",
            },
            {
              label: "My Client",
              path: "/myclient",
            },
            {
              label: "Products",
              path: "/products",
            },
            {
              label: "Orders",
              path: "/orders",
            },
            {
              label: "Forms",
              path: "/forms",
            },
            {
              label: "Settings",
              path: "/settings",
            },
          ]);
        })
        .catch((error) => {
          console.error(error);
          Cookies.remove("token");
          navigate("/home"); // programmatically navigate to /home
        });
    }
    fetchCompanyProjects();
    fetchUserNotification();
    fetchUsersWithEmail();
    console.log(isAdmin);
  }, []);

  // setInterval(() => {
  //   fetchUserNotification();
  // }, 30000);

  const [name, setName] = useState("");
  const [LogoutModal, setLogoutModal] = useState(false);
  const openModal = () => {
    setLogoutModal(true); // Show the modal
  };

  const handleColorChange = (newColor) => {
    setPrimaryColor(newColor.hex);
  };

  const handleLogout = () => {
    axios
      .post(
        "https://ww1.premelo.com/api/logout",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        Cookies.remove("token");
        navigate("/home"); // programmatically navigate to /home
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleProjectListUpdate = (projects) => {
    setUserProjects(projects);
  };

  const handleProjectListSelected = (
    projectId,
    projectName,
    projectColor,
    projectIcon
  ) => {
    handleProjectView(projectId, projectName, projectColor, projectIcon);
  };

  const [userProjects, setUserProjects] = useState([]);
  const [selectedLink, setSelectedLink] = useState(
    localStorage.getItem("selectedLink") || "/dashboard"
  );

  useEffect(() => {
    // Store the selectedLink value in localStorage whenever it changes
    localStorage.setItem("selectedLink", selectedLink);
  }, [selectedLink]);
  const [rightColumnContent, setRightColumnContent] = useState(
    <DashContentMobile
      profile_photo={profile_photo}
      primaryColor={primaryColor}
      complementaryColor={complementaryColor}
      companyLogo={companyLogo}
      secondaryColor={secondaryColor}
      onProjectListChange={handleProjectListUpdate}
      onProjectListSelected={handleProjectListSelected}
      userProjects={userProjects}
    />
  );
  const [selectedProject, setSelectedProject] = useState("");

  const [activeProject, setActiveProject] = useState(
    localStorage.getItem("activeProject") || ""
  );

  useEffect(() => {
    localStorage.setItem("activeProject", activeProject);
  }, [activeProject]);

  const [activeProjectId, setActiveProjectId] = useState(
    localStorage.getItem("activeProjectId") || ""
  );

  useEffect(() => {
    localStorage.setItem("activeProjectId", activeProjectId);
  }, [activeProjectId]);

  const [activeProjectColor, setActiveProjectColor] = useState(
    localStorage.getItem("activeProjectColor") || ""
  );

  useEffect(() => {
    localStorage.setItem("activeProjectColor", activeProjectColor);
  }, [activeProjectColor]);

  const [activeProjectIcon, setActiveProjectIcon] = useState(
    localStorage.getItem("activeProjectIcon") || ""
  );

  useEffect(() => {
    localStorage.setItem("activeProjectIcon", activeProjectIcon);
  }, [activeProjectIcon]);

  const [projectFormModalIsOpen, setProjectFormModalIsOpen] = useState(false);
  const handleProjectButtonClick = async (e) => {
    e.preventDefault();
    const token = Cookies.get("token");

    // Get the user ID based on the selected full name
    const selectedUser = availableUsers.find(
      (user) => user.fullName === taskAssignedTo
    );
    const projectAssignedToId = selectedUser ? selectedUser.id : "";

    const newProject = {
      project_assigned_to: projectSelectedUsers,
      project_name: projectTitle,
      project_description: projectDescription,
      project_type: projectType,
      project_color: projectColor,
      project_icon: projectIconSelected,
    };

    try {
      const response = await fetch("https://ww1.premelo.com/api/add-project", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newProject),
      });

      if (response.ok) {
        console.log("Project added successfully");
      } else {
        console.error("Error adding task");
      }
    } catch (error) {
      console.error("Error adding task:", error);
    }
    fetchCompanyProjects();
    setProjectFormModalIsOpen(false);
  };

  const fetchCompanyProjects = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/retrieve-company-projects",
      {
        headers: {
          Accept: `application/json`,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    console.log(data.projects);
    setUserProjects(data.projects);
  };

  useEffect(() => {
    switch (selectedLink) {
      case "/dashboard":
        setRightColumnContent(
          <DashContentMobile
            profile_photo={profile_photo}
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            companyLogo={companyLogo}
            secondaryColor={secondaryColor}
            onProjectListChange={handleProjectListUpdate}
            onProjectListSelected={handleProjectListSelected}
            userProjects={userProjects}
            openProjectFormModal={openProjectFormModal}
          />
        );
        break;
      case "/events":
        setRightColumnContent(
          <EventsMobile
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            companyLogo={companyLogo}
            secondaryColor={secondaryColor}
            onProjectListChange={handleProjectListUpdate}
            onProjectListSelected={handleProjectListSelected}
            userProjects={userProjects}
            openProjectFormModal={openProjectFormModal}
          />
        );
        break;
      case "/forms":
        setRightColumnContent(
          <FormsMobile
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            companyLogo={companyLogo}
            secondaryColor={secondaryColor}
            onProjectListChange={handleProjectListUpdate}
            onProjectListSelected={handleProjectListSelected}
            userProjects={userProjects}
            openProjectFormModal={openProjectFormModal}
          />
        );
        break;
      case "/orders":
        setRightColumnContent(
          <OrdersMobile
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            companyLogo={companyLogo}
            secondaryColor={secondaryColor}
            onProjectListChange={handleProjectListUpdate}
            onProjectListSelected={handleProjectListSelected}
            userProjects={userProjects}
            openProjectFormModal={openProjectFormModal}
          />
        );
        break;
      case "/products":
        setRightColumnContent(
          <ProductMobile
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            companyLogo={companyLogo}
            secondaryColor={secondaryColor}
            onProjectListChange={handleProjectListUpdate}
            onProjectListSelected={handleProjectListSelected}
            userProjects={userProjects}
            openProjectFormModal={openProjectFormModal}
          />
        );
        break;
      case "/myclient":
        setRightColumnContent(
          <MyClientMobile
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            companyLogo={companyLogo}
            secondaryColor={secondaryColor}
            onProjectListChange={handleProjectListUpdate}
            onProjectListSelected={handleProjectListSelected}
            userProjects={userProjects}
            openProjectFormModal={openProjectFormModal}
          />
        );
        break;
      case "/projects":
        setRightColumnContent(
          <ProjectsMobile
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            secondaryColor={secondaryColor}
            activeProject={activeProject}
            activeProjectId={activeProjectId}
            activeProjectColor={activeProjectColor}
            activeProjectIcon={activeProjectIcon}
            onProjectListChange={handleProjectListUpdate}
            onProjectListSelected={handleProjectListSelected}
            openProjectFormModal={openProjectFormModal}
          />
        );
        break;
      case "/calendar":
        setRightColumnContent(
          <CalendarContent
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            secondaryColor={secondaryColor}
          />
        );
        break;
      case "/kanban":
        setRightColumnContent(
          <KanbanContent
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            secondaryColor={secondaryColor}
          />
        );
        break;
      case "/notification":
        setRightColumnContent(
          <NotificationContentMobile
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            secondaryColor={secondaryColor}
          />
        );
        break;
      case "/subscription":
        setRightColumnContent(
          <SubscriptionContentMobile
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            secondaryColor={secondaryColor}
          />
        );
        break;
      case "/task":
        setRightColumnContent(
          <ProjectBarLoadoutMobile
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            secondaryColor={secondaryColor}
            activeProject={activeProject}
            activeProjectId={activeProjectId}
            activeProjectColor={activeProjectColor}
            activeProjectIcon={activeProjectIcon}
            onProjectListChange={handleProjectListUpdate}
            onProjectListSelected={handleProjectListSelected}
            onProjectDelete={() =>
              setRightColumnContent(
                <DashContentMobile
                  profile_photo={profile_photo}
                  primaryColor={primaryColor}
                  companyLogo={companyLogo}
                  complementaryColor={complementaryColor}
                  secondaryColor={secondaryColor}
                  onProjectListChange={handleProjectListUpdate}
                  onProjectListSelected={handleProjectListSelected}
                  userProjects={userProjects}
                  openProjectFormModal={openProjectFormModal}
                />
              )
            }
          />
        );
        break;
      case "/settings":
        setRightColumnContent(
          <SettingsMobile
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            secondaryColor={secondaryColor}
            availableUsersWithEmail={availableUsersWithEmail}
            profileImageUrl={profile_photo}
            companyImageUrl={companyLogo}
            isAdmin={isAdmin}
            companyDomain={companyDomain}
          />
        );
        break;
      default:
        setRightColumnContent(
          <DashContentMobile
            profile_photo={profile_photo}
            primaryColor={primaryColor}
            companyLogo={companyLogo}
            complementaryColor={complementaryColor}
            secondaryColor={secondaryColor}
            onProjectListChange={handleProjectListUpdate}
            onProjectListSelected={handleProjectListSelected}
            userProjects={userProjects}
            openProjectFormModal={openProjectFormModal}
          />
        );
    }
  }, [
    selectedLink,
    Cookies.get("selectedProjectId"),
    Cookies.get("selectedProjectName"),
  ]);
  const projectIconResized = (label) => {
    switch (label) {
      case "Project1":
        return (
          <DocumentScannerIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project2":
        return (
          <LanIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project3":
        return (
          <GroupsIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project4":
        return (
          <GroupIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project5":
        return (
          <AccessibilityNewIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project6":
        return (
          <AccountTreeIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project7":
        return (
          <AirplanemodeActiveIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project8":
        return (
          <ArchitectureIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project9":
        return (
          <AssignmentIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project10":
        return (
          <AudiotrackIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project11":
        return (
          <AccountBalanceIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project12":
        return (
          <WorkIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project13":
        return (
          <HubIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project14":
        return (
          <VolunteerActivismIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project15":
        return (
          <TerminalIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project16":
        return (
          <PaymentIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project17":
        return (
          <CameraAltIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project18":
        return (
          <ReportProblemIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project19":
        return (
          <Diversity3Icon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project20":
        return (
          <SportsEsportsIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project21":
        return (
          <LocalShippingIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project22":
        return (
          <RocketLaunchIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project23":
        return (
          <HandshakeIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project24":
        return (
          <HandymanIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project25":
        return (
          <SelfImprovementIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project26":
        return (
          <ElectricBoltIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project27":
        return (
          <EmailIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project28":
        return (
          <EscalatorWarningIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project29":
        return (
          <DescriptionIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project30":
        return (
          <DirectionsBoatIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project31":
        return (
          <BusinessIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project32":
        return (
          <CallIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project33":
        return (
          <CalculateIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project34":
        return (
          <CookieIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project35":
        return (
          <DesignServicesIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project36":
        return (
          <HeadsetIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );

      default:
        return null;
    }
  };

  const openNotification = () => {
    setSelectedLink("/notification");
  };

  const openSettings = () => {
    setSelectedLink("/settings");
  };

  const handleProjectView = (
    projectId,
    projectName,
    projectColor,
    projectIcon
  ) => {
    setSelectedLink("/task");
    Cookies.set("selectedProjectId", projectId);
    Cookies.set("selectedProjectName", projectName);
    Cookies.set("selectedProjectIcon", projectIcon);
    Cookies.set("selectedProjectColor", projectColor);
    setActiveProject(projectName); // set the active project in Dashboard state
    setActiveProjectId(projectId); // set the active project in Dashboard state
    setActiveProjectColor(projectColor); // set the active project in Dashboard state
    setActiveProjectIcon(projectIcon); // set the active project in Dashboard state
    console.log("click on project", projectIcon);
  };

  const fetchUserNotification = async () => {
    const token = Cookies.get("token");
    const response = await fetch("https://ww1.premelo.com/api/notifications", {
      headers: {
        Accept: `application/json`,
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    console.log("notifications:", data);
    setNotifications(data);
  };
  const openProjectFormModal = () => {
    setProjectFormModalIsOpen(true); // Show the modal
  };

  const [projectIconSelected, setProjectIconSelected] = useState("Project1");

  const [selectIcon, setSelectIcon] = useState(false);
  const [projectColor, setProjectColor] = useState(primaryColor);
  const projectIcon = (label) => {
    switch (label) {
      case "Project1":
        return (
          <DocumentScannerIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project2":
        return (
          <LanIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project3":
        return (
          <GroupsIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project4":
        return (
          <GroupIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project5":
        return (
          <AccessibilityNewIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project6":
        return (
          <AccountTreeIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project7":
        return (
          <AirplanemodeActiveIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project8":
        return (
          <ArchitectureIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project9":
        return (
          <AssignmentIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project10":
        return (
          <AudiotrackIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project11":
        return (
          <AccountBalanceIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project12":
        return (
          <WorkIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project13":
        return (
          <HubIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project14":
        return (
          <VolunteerActivismIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project15":
        return (
          <TerminalIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project16":
        return (
          <PaymentIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project17":
        return (
          <CameraAltIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project18":
        return (
          <ReportProblemIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project19":
        return (
          <Diversity3Icon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project20":
        return (
          <SportsEsportsIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project21":
        return (
          <LocalShippingIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project22":
        return (
          <RocketLaunchIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project23":
        return (
          <HandshakeIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project24":
        return (
          <HandymanIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project25":
        return (
          <SelfImprovementIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project26":
        return (
          <ElectricBoltIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project27":
        return (
          <EmailIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project28":
        return (
          <EscalatorWarningIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project29":
        return (
          <DescriptionIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project30":
        return (
          <DirectionsBoatIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project31":
        return (
          <BusinessIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project32":
        return (
          <CallIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project33":
        return (
          <CalculateIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project34":
        return (
          <CookieIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project35":
        return (
          <DesignServicesIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project36":
        return (
          <HeadsetIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );

      default:
        return null;
    }
  };
  const handleSelectIcon = () => {
    setSelectIcon(true);
  };

  const handleProjectIconSelected = (iconLabel) => {
    setProjectIconSelected(iconLabel);
    setSelectIcon(false);
  };

  const projectFormModalContent = (
    <div style={{ height: "80vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "20px",
          marginTop: "20px",
        }}
      >
        <CrossIcon
          className="mui-button"
          style={{ fontSize: "40px" }}
          onClick={() => setProjectFormModalIsOpen(false)}
        />
      </div>
      <form>
        <div
          style={{
            wdith: "100%",
          }}
        >
          {/* Left column to input */}
          <div
            style={{
              height: "100%",
              width: "90%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{ display: "flex", marginLeft: "20px", marginTop: "20px" }}
            >
              <div
                className="mui-button"
                style={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "20px",
                  backgroundColor: projectColor,
                  color: "white",
                  marginRight: "25px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
                onClick={handleSelectIcon}
              >
                <div style={{ marginLeft: "10px" }}>
                  {projectIcon(projectIconSelected)}
                </div>
              </div>
              <TwitterPicker
                style={{
                  width: "150px",
                  boxShadow: "none",
                  marginBottom: "-100px",
                }}
                onChange={(color, event) => setProjectColor(color.hex)}
              />
            </div>
            <div
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                width: "80%",
                marginBottom: "10px",
                marginLeft: "20px",
              }}
            >
              <div style={{ color: "rgba(0,0,0,0.4)", fontSize: "13px" }}>
                Project name:
              </div>
              <div>
                <input
                  style={{
                    height: "35px",
                    width: "105%",
                    border: "1px solid rgba(0,0,0,0.2)",
                    borderRadius: "10px",
                    padding: "0px 15px",
                  }}
                  type="text"
                  id="projectTitle"
                  value={projectTitle}
                  onChange={(e) => setProjectTitle(e.target.value)}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "end",
                marginLeft: "20px",
                marginRight: "0px",
              }}
            >
              <div style={{ width: "50%" }}>
                <div style={{ color: "rgba(0,0,0,0.4)", fontSize: "13px" }}>
                  Project type:
                </div>
                <div>
                  <Select
                    style={{
                      width: "90%",
                      height: "40px",
                      borderRadius: "10px",
                      fontSize: "14px",
                    }}
                    value={projectType}
                    onChange={handleProjectTypeChange}
                  >
                    <MenuItem value={"Creative request"}>
                      Creative request
                    </MenuItem>
                    <MenuItem value={"Team management"}>
                      Team management
                    </MenuItem>
                    <MenuItem value={"Programming setup"}>
                      Programming setup
                    </MenuItem>
                    <MenuItem value={"Leads management"}>
                      Leads management
                    </MenuItem>
                  </Select>
                </div>
              </div>
              <div style={{ width: "50%" }}>
                <button
                  className="mui-button"
                  style={{
                    backgroundColor: primaryColor,
                    color: "#ffffff",
                    fontWeight: "600",
                    padding: "13px 40px",
                    borderRadius: "15px",
                    border: "none",
                  }}
                  onClick={handleProjectButtonClick}
                >
                  Create Project
                </button>
              </div>
            </div>
          </div>
          {/* Right column to display preview */}
          <div
            style={{
              width: "100%",
              marginLeft: "0px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
              borderRadius: "20px 20px 0px 0px",
              marginTop: "20px",
              paddingBottom: "30px",
            }}
          >
            {selectIcon ? (
              <div style={{ marginTop: "50px", padding: "20px 20px 0px 20px" }}>
                <div
                  style={{
                    fontSize: "22px",
                    fontWeight: "600",
                    color: "rgba(0,0,0,0.7)",
                    borderBottom: "2px solid rgba(0,0,0,0.2)",
                    width: "70%",
                    paddingBottom: "20px",
                    marginBottom: "20px",
                  }}
                >
                  Select an Icon for your Project.
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    color: "rgba(0,0,0,0.5)",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <DocumentScannerIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project1")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <LanIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project2")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <GroupsIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project3")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <GroupIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project4")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <AccessibilityNewIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project5")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <AccountTreeIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project6")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <AirplanemodeActiveIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project7")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <ArchitectureIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project8")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <AssignmentIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project9")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <AudiotrackIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project10")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <AccountBalanceIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project11")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <WorkIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project12")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <HubIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project13")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <VolunteerActivismIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project14")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <TerminalIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project15")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <PaymentIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project16")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <CameraAltIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project17")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <ReportProblemIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project18")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <Diversity3Icon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project19")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <SportsEsportsIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project20")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <LocalShippingIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project21")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <RocketLaunchIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project22")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <HandshakeIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project23")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <HandymanIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project24")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <SelfImprovementIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project25")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <ElectricBoltIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project26")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <EmailIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project27")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <EscalatorWarningIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project28")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <DescriptionIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project29")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <DirectionsBoatIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project30")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <BusinessIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project31")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <CallIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project32")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <CalculateIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project33")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <CookieIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project34")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <DesignServicesIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project35")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <HeadsetIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project36")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                </div>
              </div>
            ) : (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingTop: "20px",
                    marginLeft: "20px",
                  }}
                >
                  <div
                    style={{
                      background: projectColor,
                      height: "60px",
                      width: "60px",
                      borderRadius: "10px",
                      color: "white",
                    }}
                  >
                    <div style={{ marginTop: "12px", marginLeft: "10px" }}>
                      {projectIconResized(projectIconSelected)}
                    </div>
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <div
                      style={{
                        fontSize: "22px",
                        fontWeight: "600",
                        color: "rgba(0,0,0,0.7)",
                        marginTop: "10px",
                      }}
                    >
                      {projectTitle ? projectTitle : "Project Name"}
                    </div>
                    <div
                      style={{
                        fontSize: "10px",
                        fontWeight: "400",
                        color: "rgba(0,0,0,0.7)",
                        marginTop: "0px",
                      }}
                    >
                      Lorem Ipsum is simply dummy text.
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginLeft: "20px",
                    width: "96%",
                    borderBottom: "1px solid rgba(0,0,0,0.1)",
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "12px",
                      marginRight: "20px",
                      marginLeft: "5px",
                    }}
                  >
                    Task
                  </div>
                  <div
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "12px",
                      marginRight: "20px",
                    }}
                  >
                    Kanban
                  </div>
                  <div
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "12px",
                      marginRight: "20px",
                    }}
                  >
                    Calendar
                  </div>
                  <div
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "12px",
                      marginRight: "20px",
                    }}
                  >
                    Workflow
                  </div>
                </div>
                <div style={{ marginLeft: "30px" }}>
                  <PreviewSkeletonMobile cards={1} />
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );

  const [menuIsOpen, setMenuIsOpen] = useState(false);
  return (
    <div
      style={{
        // display: "flex",
        width: "100%",
        height: "90vh",
        position: "relative",
        overflowY: "auto",
        overflowX: "hidden",
        position: "relative",
        "--primary-color": primaryColor,
        "--complementary-color": complementaryColor,
      }}
    >
      <div
        style={{
          height: "90vh",
          width: "100%",
          background: primaryColor,
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            height: "60%",
            width: "100%",
            bottom: 0,
            backgroundImage:
              "linear-gradient(rgba(255,255,255,0), rgba(255,255,255,0.8))",
            zIndex: 1,
          }}
        ></div>
        <div
          style={{
            height: "100%",
            overflowY: "auto",
            overflowX: "hidden",
            position: "relative",
            zIndex: 2,
          }}
        >
          <div style={{ display: "flex", justifyContent: "right" }}>
            <div
              style={{
                display: "flex",
                marginTop: "20px",
                alignItems: "center",
                position: "relative",
                zIndex: 1500,
                height: "50px",
                width: "50px",
                background: "rgba(0,0,0,0.3)",
                borderRadius: "50%",
                marginRight: "15px",
                justifyContent: "center",
              }}
            >
              {/* <Badge
                className="mui-button"
                onClick={openNotification}
                color="primary"
                style={{ marginTop: "14px", marginRight: "20px", zIndex: 0 }}
                badgeContent={unreadNotifications}
              >
                <NotificationsIcon sx={{ fontSize: 20 }} />
              </Badge> */}
              <MenuIcon
                onClick={() => setMenuIsOpen(true)}
                style={{
                  color: "white",
                  fontSize: "35px",
                }}
              />
            </div>
          </div>
          {rightColumnContent}
        </div>
      </div>
      {/* Left column */}
      <div
        style={{
          position: "fixed",
          height: "90vh",
          width: "100vw",
          backgroundColor: "#ffffff",
          width: "100%",
          zIndex: "50",
          top: 0,
          left: menuIsOpen ? 0 : "100%",
          transition: "0.3s all ease-in-out",
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginTop: "15px",
                marginLeft: "15px",
              }}
            >
              <img style={{ width: "80px", objectFit: "contain" }} src={logo} />
              <div
                style={{
                  fontSize: "35px",
                  fontWeight: 600,
                  color: "rgba(0,0,0,0.8)",
                }}
              >
                {" "}
                Premelo
              </div>
            </div>
            <div style={{ marginRight: "15px", marginTop: "20px" }}>
              <CloseIcon
                onClick={() => setMenuIsOpen(false)}
                style={{ fontSize: "40px", color: "rgba(0,0,0,0.8)" }}
              />
            </div>
          </div>
          {/* <div style={{ width: "100%", display: "flex" }}>
          <hr
            style={{
              borderRadius: "10px",
              border: "none",
              outline: "none",
              background: "rgba(0,0,0,0.4)",
              width: "calc(100% - 40px)",
              height: "1px",
              margin: "10px 0px 10px 20px",
            }}
          />
        </div> */}
          {/* Navigation bar */}
          <nav
            style={{
              width: "100%",
              marginTop: "20px",
              flexWrap: "wrap",
              display: "flex",
              gap: "10px",
              marginLeft: "15px",
            }}
          >
            {menuItems.map((item, index) => (
              <div
                className="project-item mui-button"
                key={index}
                style={{
                  borderRadius: "10px",
                  marginBottom: "5px",
                  width: "calc(50% - 20px)",
                  textAlign: "left",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                  padding: "15px 0px",
                }}
                onClick={() => {
                  setSelectedLink(item.path);
                  setMenuIsOpen(false);
                }}
              >
                <div
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                    textAlign: "center",
                    alignItems: "center",
                    marginLeft: "10px",
                    display: "flex",
                  }}
                >
                  <div>{getIcon(item.label)}</div>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "rgba(0,0,0,0.4)",
                      fontWeight: "600",
                    }}
                  >
                    {item.label}
                  </div>
                </div>
              </div>
            ))}
          </nav>
        </div>
        <div
          className="mui-button third-step"
          style={{
            background: primaryColor,
            color: "white",
            borderRadius: "10px",
            marginBottom: "5px",
            width: "calc(50% - 20px)",
            textAlign: "left",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            padding: "15px 0px",
            marginLeft: "15px",
            marginBottom: "15px",
          }}
          onClick={() => {
            setLogoutModal(true);
            setMenuIsOpen(false);
          }}
        >
          <PersonAddIcon
            style={{
              color: "white",
              marginRight: "10px",
              fontSize: "18px",
              marginLeft: "10px",
              marginRight: "20px",
            }}
          />
          Logout
        </div>
      </div>
      {/* Right column */}

      <Modal
        isOpen={LogoutModal}
        onRequestClose={() => setLogoutModal(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 505,
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            height: "30%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",
            borderRadius: "4px",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            borderRadius: "20px",
          },
        }}
      >
        <h2 style={{ marginTop: "5px", textAlign: "center" }}>
          Confirm logout
        </h2>
        <div style={{ marginTop: "-30px", textAlign: "center" }}>
          <p>Confirm if you'd like to logout</p>
        </div>
        <button
          style={{
            marginTop: "10px",
            padding: "10px 50px",
            borderRadius: "20px",
            backgroundColor: primaryColor,
            color: "#ffffff",
          }}
          onClick={handleLogout}
        >
          Confirm
        </button>
      </Modal>
      <Modal
        isOpen={projectFormModalIsOpen}
        onRequestClose={() => setProjectFormModalIsOpen(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 400,
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            height: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            borderRadius: "10px",
            zIndex: 410,
          },
        }}
      >
        {projectFormModalContent}
      </Modal>
      <KommunicateChat
        primaryColor={primaryColor}
        style={{ marginBottom: "20px" }}
      />
    </div>
  );
}

export default DashboardMobile;
