import React, { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Cookies from "js-cookie";
import Calendar from "react-calendar";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Country, State, City } from "country-state-city";
import ScheduleIcon from "@mui/icons-material/Schedule";

const Orders = (props) => {
  
  const [currency, setCurrency] = useState("$");
  useEffect(() => {
    const getCurrencyAndShipping = async () => {
      const token = Cookies.get("token");
      const response = await fetch(
        "https://ww1.premelo.com/api/stripe/getcands",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      if (json.commerce_array) {
        if (json.commerce_array.currency) {
          if (json.commerce_array.currency.display) {
            setCurrency(json.commerce_array.currency.display);
          }
        }
      }
    };
    getCurrencyAndShipping();
  }, []);

  const [orders, setOrders] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [activeOrder, setActiveOrder] = useState(null);
  const token = Cookies.get("token");
  const [tracking, setTracking] = useState("");
  const [notes, setNotes] = useState({ message: "", created_at: "", time: "" });

  function formatDate(inputDate) {
    // Parse the input date string
    const date = new Date(inputDate);

    // Define month names
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract day, month, and year
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    // Format the date as "DD Mon, YYYY"
    const formattedDate = `${day} ${monthNames[month]}, ${year}`;

    return formattedDate;
  }

  function formatTime(inputTime) {
    // Split the input time string into hours and minutes
    const [hourStr, minuteStr] = inputTime.split(":");

    // Convert hours and minutes to integers
    const hours = parseInt(hourStr, 10);
    const minutes = parseInt(minuteStr, 10);

    // Determine the period (am or pm)
    const period = hours >= 12 ? "pm" : "am";

    // Format the hours in 12-hour format
    const formattedHours = hours % 12 || 12; // Handle midnight (0) as 12

    // Format the time as "h:mm am/pm"
    const formattedTime = `${formattedHours}.${minutes
      .toString()
      .padStart(2, "0")} ${period}`;

    return formattedTime;
  }

  function formatTimes(inputTime) {
    if (!(inputTime instanceof Date)) {
      return "Invalid Time";
    }

    // Get hours and minutes from the Date object
    const hours = inputTime.getHours();
    const minutes = inputTime.getMinutes();

    // Determine the period (am or pm)
    const period = hours >= 12 ? "pm" : "am";

    // Format the hours in 12-hour format
    const formattedHours = hours % 12 || 12; // Handle midnight (0) as 12

    // Format the time as "h:mm am/pm"
    const formattedTime = `${formattedHours}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;

    return formattedTime;
  }

  const handleUpdateOrderNotes = async (notes) => {
    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/update-order-notes",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            order_id: activeOrder.id,
            order_notes: notes,
          }),
        }
      );
      const json = await response.json();
      console.log(json);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateOrderTracking = async (notes) => {
    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/update-order-tracking",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            order_id: activeOrder.id,
            tracking: notes,
          }),
        }
      );
      const json = await response.json();
      console.log(json);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateOrderStatus = async (stat) => {
    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/update-order-status",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            order_id: activeOrder.id,
            status: stat,
          }),
        }
      );
      const json = await response.json();
      console.log(json);
    } catch (error) {
      console.log(error);
    }
  };

  const AddNotes = (e) => {
    const orderIndex = orders.findIndex((order) => order.id === activeOrder.id);
    const updatingOrders = [...orders];
    const updatingActiveOrder = { ...activeOrder };
    const updatingNote = { ...notes };
    updatingNote.created_at = formatDate(new Date());
    updatingNote.time = formatTimes(new Date());

    if (updatingOrders[orderIndex].order_notes) {
      updatingOrders[orderIndex].order_notes.unshift(updatingNote);
      updatingActiveOrder.order_notes.unshift(updatingNote);
    } else {
      updatingOrders[orderIndex].order_notes = [updatingNote];
      updatingActiveOrder.order_notes = [updatingNote];
    }
    handleUpdateOrderNotes(updatingOrders[orderIndex].order_notes);
    setActiveOrder(updatingActiveOrder);
    setOrders(updatingOrders);
    setNotes({ message: "", created_at: "" });
  };

  const addTracking = (e) => {
    const orderIndex = orders.findIndex((order) => order.id === activeOrder.id);
    const updatingOrders = [...orders];
    const updatingActiveOrder = { ...activeOrder };

    if (updatingOrders[orderIndex].tracking) {
      updatingOrders[orderIndex].tracking = tracking;
      updatingActiveOrder.tracking = tracking;
    } else {
      updatingOrders[orderIndex].tracking = tracking;
      updatingActiveOrder.tracking = tracking;
    }
    handleUpdateOrderTracking(updatingOrders[orderIndex].tracking);
    setActiveOrder(updatingActiveOrder);
    setOrders(updatingOrders);
    setNotes({ message: "", created_at: "" });
  };

  function isSameDay(date1, date2) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  const show = ({ date, view }) => {
    if (view === "month") {
      const eventOnDate = orders.some((order) =>
        isSameDay(date, new Date(order.created_at))
      );

      if (eventOnDate) {
        return (
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <div
              style={{
                marginTop: "3px",
                background: props.primaryColor,
                height: "6px",
                width: "6px",
                borderRadius: "50%",
              }}
            />
          </div>
        );
      }
    }
    return null;
  };

  function isWithinRange(date, startDate, endDate) {
    return date >= startDate && date <= endDate;
  }

  function isTheSameDay(date1, date2) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  const filterEventsByDate = () => {
    console.log("Events:", orders);
    console.log("Date Range:", dateRange);

    if (!dateRange) {
      // If dateRange is null, return all orders
      console.log(orders);
      return orders;
    } else if (dateRange[1] === null) {
      // If dateRange has one date and the second date is null
      const selectedDate = dateRange[0];

      return orders.filter((order) => {
        // Assuming the event.date property is a JavaScript Date object
        console.log(isTheSameDay(new Date(order.created_at), selectedDate));
        return isTheSameDay(new Date(order.created_at), selectedDate);
      });
    } else if (dateRange.length === 2) {
      // If dateRange has two dates, representing a date range
      const startDate = dateRange[0];
      const endDate = dateRange[1];

      return orders.filter((order) => {
        // Assuming the event.date property is a JavaScript Date object
        console.log(
          isWithinRange(new Date(order.created_at), startDate, endDate)
        );
        return isWithinRange(new Date(order.created_at), startDate, endDate);
      });
    } else {
      // Invalid dateRange, return an empty array or handle as needed
      console.log("Invalid dateRange");
      return [];
    }
  };

  const filteredOrders = filterEventsByDate()
    ? filterEventsByDate().filter((order) => {
        const status = order.status || ""; // Default to an empty string if tags is null

        const tagssAndCategories = [...status.split(" | ")];

        // Check if selectedFilters is null or empty
        if (!selectedFilters || selectedFilters.length === 0) {
          return true; // Return true if no filters are selected
        }

        // Use selectedFilters.some only if it's not null or empty
        return selectedFilters.some((filter) =>
          tagssAndCategories.includes(filter)
        );
      })
    : [];

  const searchedOrders = searchQuery
    ? filteredOrders
      ? filteredOrders.filter((event) => {
          const { customer_name } = event;
          const lowerCaseSearchQuery = searchQuery.toLowerCase();

          return (
            customer_name &&
            customer_name.toLowerCase().includes(lowerCaseSearchQuery)
          );
        })
      : []
    : filteredOrders;

  const getOrders = async () => {
    try {
      const response = await fetch("https://ww1.premelo.com/api/get-orders", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const json = await response.json();
      setOrders(json.orders);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrders();
  }, []);

  const handleFilterChange = (filter) => {
    if (selectedFilters.includes(filter)) {
      // Filter is already selected, remove it
      setSelectedFilters(selectedFilters.filter((f) => f !== filter));
    } else {
      // Filter is not selected, add it
      setSelectedFilters([...selectedFilters, filter]);
    }
  };

  const calculateOrderAmount = (orderss) => {
    let amount = 0;
    for (const order of orderss) {
      amount += parseFloat(order.quantity) * parseFloat(order.unit_price);
    }
    return amount;
  };

  let Country = require("country-state-city").Country;
  let State = require("country-state-city").State;

  const getCountryName = (country) => {
    const countryIndex = Country.getAllCountries().findIndex(
      (countries) => countries.isoCode === country
    );
    return Country.getAllCountries()[countryIndex].name;
  };

  const getStateName = (country, statecode) => {
    const stateIndex = State.getStatesOfCountry(country).findIndex(
      (state) => state.isoCode === statecode
    );

    return State.getStatesOfCountry(country)[stateIndex].name;
  };

  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          display: "flex",
          borderBottom: "1px solid rgba(0,0,0,0.1)",
          alignItems: "center",
          background: "rgba(255,255,255,0.7)",
          backdropFilter: "blur(20px)",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
          padding: "30px 0px 10px 0px",
          position:"relative",
          zIndex: 490
        }}
      >
        <div
          onClick={() => {
            setActiveOrder(null);
            setTracking("");
          }}
          className="mui-button"
          style={{
            marginLeft: "20px",
            width: activeOrder ? "30px" : "0px",
            opacity: activeOrder ? "1" : "0",
            transition: "all 0.4s ease-in-out",
            cursor: activeOrder ? "pointer" : "none",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ArrowBackIosIcon />
        </div>
        <div
          onClick={() => {
            setActiveOrder(null);
            setTracking("");
          }}
          className="mui-button"
          style={{
            color: props.primaryColor,
            fontSize: "22px",
            fontWeight: "600",
            display: "flex",
            alignItems: "center",
          }}
        >
          Order
        </div>
      </div>
      {activeOrder ? (
        <div
          style={{
            padding: "20px",
            width: "calc(100% - 40px)",
            height: "calc(100% - 115px)",
            display: "flex",
            alignItems: "flex-start",
            gap: "20px",
          }}
        >
          <div style={{ width: "calc(80% - 10px)" }}>
            <div
              style={{
                boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                backdropFilter: "blur(15px)",
                background: "rgba(255,255,255,0.7)",
                borderRadius: "5px",
                width: "calc(100% - 30px)",
                padding: "15px 15px 40px 15px",
                display: "flex",
                color: "grey",
                fontSize: "12px",
                fontWeight: 400,
                position:"relative",
                zIndex: 490
              }}
            >
              <div style={{ width: "37%" }}>
                <div
                  style={{
                    color: "rgba(0,0,0,0.7)",
                    fontWeight: 600,
                    fontSize: "20px",
                    display: "flex",
                    alignItems: "center",
                    height: "40px",
                  }}
                >
                  Order #{activeOrder.id} details
                </div>
                <div style={{ marginTop: "10px", fontSize: "8px" }}>
                  Date created
                </div>
                <div>{formatDate(new Date(activeOrder.created_at))}</div>
                <div style={{ marginTop: "10px", fontSize: "8px" }}>
                  Order status
                </div>
                <div>
                  <select
                    value={activeOrder.status}
                    onChange={(e) => {
                      const orderIndex = orders.findIndex(
                        (order) => order.id === activeOrder.id
                      );
                      const updatingOrders = [...orders];
                      const updatingActiveOrder = { ...activeOrder };

                      if (updatingOrders[orderIndex].status) {
                        updatingOrders[orderIndex].status = e.target.value;
                        updatingActiveOrder.status = e.target.value;
                      } else {
                        updatingOrders[orderIndex].status = e.target.value;
                        updatingActiveOrder.status = e.target.value;
                      }
                      handleUpdateOrderStatus(e.target.value);
                      setActiveOrder(updatingActiveOrder);
                      setOrders(updatingOrders);
                    }}
                    style={{
                      outline: "none",
                      border: "1px solid lightgrey",
                      borderRadius: "5px",
                      padding: "5px 20px",
                      background: "rgba(255,255,255,0.7)",
                      width: "80%",
                    }}
                  >
                    <option value={"Pending Payment"}>Pending Payment</option>
                    <option value={"Payment Failed"}>Payment Failed</option>
                    <option value={"Order Processing"}>Order Processing</option>
                    <option value={"Shipping"}>Shipping</option>
                    <option value={"Completed"}>Completed</option>
                    <option value={"Cancelled"}>Cancelled</option>
                    <option value={"Refunded"}>Refunded</option>
                    <option value={"Failed"}>Failed</option>
                    <option value={"Draft"}>Draft</option>
                  </select>
                </div>
              </div>
              <div style={{ width: "30%" }}>
                <div
                  style={{
                    marginTop: "20px",
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  Customer details
                </div>
                <div style={{ marginTop: "10px", fontSize: "8px" }}>
                  Order by
                </div>
                <div>{activeOrder.shipping_details.full_name}</div>
                <div style={{ marginTop: "10px", fontSize: "8px" }}>
                  Phone number
                </div>
                <div>{activeOrder.shipping_details.phone_number}</div>
                <div style={{ marginTop: "10px", fontSize: "8px" }}>
                  Email address
                </div>
                <div>{activeOrder.shipping_details.email_address}</div>
              </div>
              <div style={{ width: "30%" }}>
                <div
                  style={{
                    marginTop: "20px",
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  Delivery details
                </div>
                <div style={{ marginTop: "10px", fontSize: "8px" }}>
                  Address
                </div>
                <div>{activeOrder.shipping_details.address}</div>
                <div style={{ marginTop: "10px", fontSize: "8px" }}>
                  Zip code
                </div>
                <div>{activeOrder.shipping_details.zip_code}</div>
                <div style={{ marginTop: "10px", fontSize: "8px" }}>State</div>
                <div>
                  {getStateName(
                    activeOrder.shipping_details.country,
                    activeOrder.shipping_details.state
                  )}
                </div>
                <div style={{ marginTop: "10px", fontSize: "8px" }}>
                  Country
                </div>
                <div>
                  {getCountryName(activeOrder.shipping_details.country)}
                </div>
              </div>
            </div>
            <div
              style={{
                boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                backdropFilter: "blur(15px)",
                background: "rgba(255,255,255,0.7)",
                borderRadius: "5px",
                width: "calc(100% - 30px)",
                padding: "15px 15px 40px 15px",
                color: "grey",
                fontSize: "12px",
                fontWeight: 400,
                marginTop: "20px",
                position:"relative",
                zIndex: 490
              }}
            >
              <div
                style={{
                  display: "flex",
                  fontSize: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ width: "49%", textAlign: "left" }}>Item</div>
                <div style={{ width: "17%", textAlign: "center" }}>
                  Unit price
                </div>
                <div style={{ width: "17%", textAlign: "center" }}>
                  Quantity
                </div>
                <div style={{ width: "17%", textAlign: "right" }}>Total</div>
              </div>
              {activeOrder.order_items.map((product, index) => (
                <div
                  style={{
                    display: "flex",
                    fontSize: "12px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "10px 0px",
                  }}
                  key={index}
                >
                  <div
                    style={{
                      width: "49%",
                      textAlign: "left",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <img
                          src={product.img_src}
                          style={{
                            width: "50px",
                            height: "50px",
                            borderRadius: "5px",
                            marginRight: "10px",
                          }}
                        />
                      </div>
                      <div>
                        {product.type === "event"
                          ? product.event_name
                          : product.name}
                      </div>
                    </div>
                    <div
                      style={{
                        fontSize: "10px",
                      }}
                    >
                      {product.set_variation
                        ? Object.entries(product.variation).map(
                            ([key, value]) => (
                              <div key={key}>
                                {key}: {value.variationValue}
                              </div>
                            )
                          )
                        : null}
                    </div>
                  </div>
                  <div style={{ width: "17%", textAlign: "center" }}>
                    {product.unit_price}
                  </div>
                  <div style={{ width: "17%", textAlign: "center" }}>
                    {product.quantity}
                  </div>
                  <div style={{ width: "17%", textAlign: "right" }}>
                    {currency + " "}
                    {(
                      parseFloat(product.unit_price) *
                      parseFloat(product.quantity)
                    ).toLocaleString("en-US", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </div>
                </div>
              ))}
              <div
                style={{
                  width: "100%",
                  borderTop: "1px solid lightgrey",
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingTop: "15px",
                }}
              >
                <div style={{ marginRight: "10px" }}>Order total:</div>
                <div
                  style={{ fontSize: "20px", fontWeight: 600, color: "grey" }}
                >
                  {currency + " "}
                  {calculateOrderAmount(activeOrder.order_items).toLocaleString(
                    "en-US",
                    {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              width: "calc(20% - 10px)",
            }}
          >
            <div
              style={{
                boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                backdropFilter: "blur(15px)",
                background: "rgba(255,255,255,0.7)",
                borderRadius: "5px",
                position:"relative",
                zIndex: 490
              }}
            >
              <div
                style={{
                  height: "40px",
                  borderRadius: "5px 5px 0px 0px",
                  overflow: "hidden",
                  borderBottom: "1px solid lightgrey",
                }}
              >
                <div
                  style={{
                    height: "4px",
                    width: "100%",
                    background: props.primaryColor,
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "36px",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "10px",
                      color: "rgba(0,0,0,0.7)",
                      fontWeight: 600,
                      fontSize: "14px",
                    }}
                  >
                    Order tracking number
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div
                      style={{
                        background: "rgba(0,0,0,0.2)",
                        width: "9px",
                        height: "9px",
                        borderRadius: "10px",
                        marginRight: "7px",
                      }}
                    />
                    <div
                      style={{
                        background: "rgba(0,0,0,0.2)",
                        width: "9px",
                        height: "9px",
                        borderRadius: "10px",
                        marginRight: "7px",
                      }}
                    />
                    <div
                      style={{
                        background: "grey",
                        width: "9px",
                        height: "9px",
                        borderRadius: "10px",
                        marginRight: "14px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <form
                style={{ padding: "10px" }}
                onSubmit={(e) => {
                  e.preventDefault();
                  addTracking();
                }}
              >
                <div>
                  <textarea
                    required
                    value={tracking || ""}
                    placeholder="Type your notes here ..."
                    onChange={(e) => setTracking(e.target.value)}
                    style={{
                      outline: "none",
                      border: "1px solid lightgrey",
                      resize: "none",
                      width: "calc(100% - 10px)",
                      padding: "5px",
                      fontSize: "12px",
                      color: "grey",
                      borderRadius: "5px",
                      height: "40px",
                      background: "rgba(255,255,255,0.7)",
                    }}
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    className="mui-button"
                    type="submit"
                    style={{
                      background: props.primaryColor,
                      borderRadius: "5px",
                      padding: "5px 15px",
                      color: "white",
                      fontSize: "10px",
                      border: "none",
                      outline: "none",
                    }}
                  >
                    Add
                  </button>
                </div>
              </form>
            </div>
            <div
              style={{
                boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                backdropFilter: "blur(15px)",
                background: "rgba(255,255,255,0.7)",
                borderRadius: "5px",
                marginTop: "20px",
                position:"relative",
                zIndex: 490
              }}
            >
              <div
                style={{
                  height: "40px",
                  borderRadius: "5px 5px 0px 0px",
                  overflow: "hidden",
                  borderBottom: "1px solid lightgrey",
                }}
              >
                <div
                  style={{
                    height: "4px",
                    width: "100%",
                    background: props.primaryColor,
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "36px",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "10px",
                      color: "rgba(0,0,0,0.7)",
                      fontWeight: 600,
                      fontSize: "14px",
                    }}
                  >
                    Order notes
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div
                      style={{
                        background: "rgba(0,0,0,0.2)",
                        width: "9px",
                        height: "9px",
                        borderRadius: "10px",
                        marginRight: "7px",
                      }}
                    />
                    <div
                      style={{
                        background: "rgba(0,0,0,0.2)",
                        width: "9px",
                        height: "9px",
                        borderRadius: "10px",
                        marginRight: "7px",
                      }}
                    />
                    <div
                      style={{
                        background: "grey",
                        width: "9px",
                        height: "9px",
                        borderRadius: "10px",
                        marginRight: "14px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <form
                style={{ padding: "10px" }}
                onSubmit={(e) => {
                  e.preventDefault();
                  AddNotes();
                }}
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    color: "grey",
                  }}
                >
                  Add notes{" "}
                </div>
                <div>
                  <textarea
                    required
                    value={notes.message}
                    placeholder="Type your notes here ..."
                    onChange={(e) =>
                      setNotes({ ...notes, message: e.target.value })
                    }
                    style={{
                      outline: "none",
                      border: "1px solid lightgrey",
                      resize: "none",
                      width: "calc(100% - 10px)",
                      padding: "5px",
                      fontSize: "12px",
                      color: "grey",
                      borderRadius: "5px",
                      height: "70px",
                      background: "rgba(255,255,255,0.7)",
                    }}
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    className="mui-button"
                    type="submit"
                    style={{
                      background: props.primaryColor,
                      borderRadius: "5px",
                      padding: "5px 15px",
                      color: "white",
                      fontSize: "10px",
                      border: "none",
                      outline: "none",
                    }}
                  >
                    Create
                  </button>
                </div>
              </form>
              {activeOrder.order_notes
                ? activeOrder.order_notes.map((notes, index) => (
                    <div
                      key={index}
                      style={{ padding: "10px", marginBottom: "10px" }}
                    >
                      <div
                        style={{
                          fontSize: "12px",
                          color: "grey",
                          border: "1px solid lightgrey",
                          padding: "5px",
                          width: "calc(98% - 10px)",
                          borderRadius: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        {notes.message}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          fontSize: "8px",
                          color: "grey",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <CalendarMonthIcon
                            style={{
                              color: "grey",
                              fontSize: "15px",
                              marginRight: "5px",
                            }}
                          />
                          {notes.created_at}
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <ScheduleIcon
                            style={{
                              color: "grey",
                              fontSize: "15px",
                              marginRight: "5px",
                            }}
                          />
                          {notes.time}
                        </div>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              height: "calc(90% - 40px)",
              width: "calc(28% - 40px)",
              padding: "20px",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                background: "rgba(255,255,255,0.7)",
                backdropFilter: "blur(20px)",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                borderRadius: "5px",
                position:"relative",
                zIndex: 490
              }}
            >
              <div
                style={{
                  height: "40px",
                  borderRadius: "5px 5px 0px 0px",
                  overflow: "hidden",
                  borderBottom: "1px solid lightgrey",
                }}
              >
                <div
                  style={{
                    height: "4px",
                    width: "100%",
                    background: props.primaryColor,
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    height: "35px",
                  }}
                >
                  <div
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      width: "9px",
                      height: "9px",
                      borderRadius: "10px",
                      marginRight: "7px",
                    }}
                  />
                  <div
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      width: "9px",
                      height: "9px",
                      borderRadius: "10px",
                      marginRight: "7px",
                    }}
                  />
                  <div
                    style={{
                      background: "grey",
                      width: "9px",
                      height: "9px",
                      borderRadius: "10px",
                      marginRight: "14px",
                    }}
                  />
                </div>
              </div>
              <div style={{ padding: "20px" }}>
                <Calendar
                  allowPartialRange={true}
                  next2Label={null}
                  prev2Label={null}
                  selectRange={true}
                  className="event_calendar"
                  style={{ width: "100%" }}
                  tileContent={show}
                  onChange={(value, event) => {
                    setDateRange(value);
                  }}
                />
              </div>
              <div style={{ padding: "0px 20px 20px 20px" }}>
                <div
                  style={{
                    color: props.primaryColor,
                    fontWeight: 700,
                    fontSize: "16px",
                  }}
                >
                  Status:
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "5px",
                    marginTop: "5px",
                  }}
                >
                  {orders
                    ? orders
                        .reduce((filters, order) => {
                          const orderss = order.status || "";

                          const orders = [...orderss.split(" | ")];
                          orders.forEach((filter) => {
                            if (
                              filter.trim() !== "" &&
                              !filters.includes(filter)
                            ) {
                              filters.push(filter);
                            }
                          });
                          return filters;
                        }, [])
                        .map(
                          (filter) =>
                            filter.trim() !== "" && ( // Check if filter is not empty
                              <button
                                className="tag_and_category_button"
                                key={filter}
                                style={{
                                  margin: "0 2px",
                                  background: selectedFilters.includes(filter)
                                    ? props.primaryColor
                                    : "rgba(0,0,0,0.05)",
                                  color: selectedFilters.includes(filter)
                                    ? "white"
                                    : "rgba(0,0,0,0.7)",
                                  border: "none",
                                  padding: "5px 15px",
                                  borderRadius: "10px",
                                  cursor: "pointer",
                                  fontSize: "12px",
                                }}
                                onClick={() => handleFilterChange(filter)}
                              >
                                {filter}
                              </button>
                            )
                        )
                    : null}
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              height: "calc(90% - 40px)",
              width: "calc(72% - 20px)",
              padding: "20px 20px 20px 0px",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "40px",
                background: "rgba(255,255,255,0.7)",
                backdropFilter: "blur(20px)",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                position:"relative",
                zIndex:490
              }}
            >
              <SearchIcon style={{ color: "grey", marginLeft: "8px" }} />
              <input
                placeholder="Search events"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                style={{
                  width: "95%",
                  marginLeft: "10px",
                  border: "none",
                  outline: "none",
                  background: "transparent",
                }}
              />
            </div>
            <div
              style={{
                width: "calc(100%)",
                marginTop: "15px",
                height: "calc(91%)",
                overflowY: "auto",
                background: "rgba(255,255,255,0.7)",
                backdropFilter: "blur(20px)",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                borderRadius: "5px",
                position:"relative",
                zIndex: 490
              }}
            >
              <div
                onClick={() => {}}
                style={{
                  width: "100%",
                  textAlign: "center",
                  position: "relative",
                  borderBottom: "1px solid lightgrey",
                  color: "grey",
                  fontWeight: 600,
                  height: "40px",
                }}
              >
                <div
                  style={{
                    height: "4px",
                    width: "100%",
                    background: props.primaryColor,
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "36px",
                    fontSize: "14px",
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      color: "rgba(0,0,0,0.7)",
                      fontWeight: 600,
                      fontSize: "14px",
                      marginLeft: "10px",
                      textAlign: "left",
                    }}
                  >
                    Customer name
                  </div>
                </div>
              </div>
              {searchedOrders
                .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                .map((order, index) => (
                  <div
                    onClick={() => {
                      setActiveOrder(order);
                      setTracking(order.tracking ? order.tracking : "");
                    }}
                    className="mui-button"
                    key={index}
                    style={{
                      width: "calc(100% - 20px)",
                      textAlign: "center",
                      position: "relative",
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "7px 10px",
                      borderBottom: "1px solid lightgrey",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "55%",
                      }}
                    >
                      <div
                        style={{
                          color: "rgba(0,0,0,0.7)",
                          fontWeight: 600,
                          fontSize: "12px",
                          display: "flex",
                          alignItems: "center",
                          width: "25%",
                        }}
                      >
                        Order #{order.id}
                      </div>
                      <div
                        style={{
                          color: "grey",
                          fontWeight: 600,
                          fontSize: "12px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {order.customer_name ? order.customer_name : "Guest"}
                      </div>
                    </div>
                    <div
                      style={{
                        color: "grey",
                        fontWeight: 400,
                        fontSize: "9px",
                        display: "flex",
                        alignItems: "center",
                        width: "25%",
                      }}
                    >
                      {order.status}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "35%",
                      }}
                    >
                      <div
                        style={{
                          color: "grey",
                          fontWeight: 400,
                          fontSize: "9px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CalendarMonthIcon
                          style={{
                            fontSize: "14px",
                            marginRight: "5px",
                            color: "grey",
                          }}
                        />
                        {formatDate(new Date(order.created_at))}
                      </div>
                      <div
                        style={{
                          color: "rgba(0,0,0,0.8)",
                          fontWeight: 600,
                          fontSize: "12px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        
                        {currency + " " + calculateOrderAmount(order.order_items).toLocaleString(
                          "en-US",
                          {
                            style: "decimal",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Orders;
