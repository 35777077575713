import React from "react";
import logo from "./images/CS_Favicon-01.png";
import { useEffect } from "react";
import { useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const PlatformPerformance = () => {
  const [analytics, setAnalytics] = useState([]);
  useEffect(() => {
    const recordAnalytics = async () => {
      try {
        const response = await fetch(
          "https://ww1.premelo.com/api/get-analytics",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const json = await response.json();
        setAnalytics(json.analytics);

        // Handle the response as needed
      } catch (error) {
        console.log(error);
      }
    };

    recordAnalytics();
  }, []);
  const [signUpData, setSignUpData] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [homePage, setHomePage] = useState(0);
  const [signUpPage, setSignUpPage] = useState(0);
  const [doneSignUpPage, setDoneSignUpPage] = useState(0);
  const [dashboardPage, setDashboardPage] = useState(0);
  const [homePageMobile, setHomePageMobile] = useState(0);
  const [signUpPageMobile, setSignUpPageMobile] = useState(0);
  const [doneSignUpPageMobile, setDoneSignUpPageMobile] = useState(0);
  const [dashboardPageMobile, setDashboardPageMobile] = useState(0);
  const [newUserCount, setNewUserCount] = useState(0);
  const [existingUserCount, setExistingUserCount] = useState(0);
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Add leading zero for single-digit months and days
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    const currentDate = getCurrentDate();
    console.log(currentDate);
    let homePage = 0;
    let signUpPage = 0;
    let doneSignUpPage = 0;
    let dashboardPage = 0;
    let homePageMobile = 0;
    let signUpPageMobile = 0;
    let doneSignUpPageMobile = 0;
    let dashboardPageMobile = 0;
    let newUserCount = 0;
    let existingUserCount = 0;
    if (analytics.length > 0) {
      console.log(analytics);
      for (const ana of analytics) {
        // Check if the date of the analytics entry is equal to today's date
        if (ana.created_at.startsWith(currentDate)) {
          if (ana.page === "home mobile page") {
            homePageMobile += 1;
          }
          if (ana.page === "home null page") {
            homePage += 1;
          }
          if (ana.page === "signup mobile page") {
            signUpPageMobile += 1;
          }
          if (ana.page === "signup null page") {
            signUpPage += 1;
          }
          if (ana.page === "fillinformation mobile page") {
            doneSignUpPageMobile += 1;
          }
          if (ana.page === "fillinformation null page") {
            doneSignUpPage += 1;
          }
          if (ana.page === "dashboard mobile page") {
            dashboardPageMobile += 1;
          }
          if (ana.page === "dashboard null page") {
            dashboardPage += 1;
          }
          if (ana.user === "existing") {
            existingUserCount += 1;
          }
          if (ana.user === "unique") {
            newUserCount += 1;
          }
        }
      }
    }
    setHomePage(homePage);
    setSignUpPage(signUpPage);
    setDoneSignUpPage(doneSignUpPage);
    setDashboardPage(dashboardPage);
    setHomePageMobile(homePageMobile);
    setSignUpPageMobile(signUpPageMobile);
    setDoneSignUpPageMobile(doneSignUpPageMobile);
    setDashboardPageMobile(dashboardPageMobile);
    setNewUserCount(newUserCount);
    setExistingUserCount(existingUserCount);
  }, [analytics]);

  const filteredData = signUpData.filter((data) => {
    const date = new Date(data.date);
    return date >= dateRange.startDate && date <= dateRange.endDate;
  });

  const fetchSignUpStatitics = () => {
    fetch("https://ww1.premelo.com/api/signup-stats")
      .then((response) => response.json())
      .then((data) => {
        // Do something with the data
        console.log(data);
        setSignUpData(data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    fetchSignUpStatitics();
  }, []);

  return (
    <div style={{ background: "#e1e4e8", width: "100%" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          height: "60px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.5)",
          background: "white",
        }}
      >
        <div style={{ width: "40px", height: "40px" }}>
          <img
            src={logo}
            style={{
              width: "100%",
              objectFit: "contain", // or "cover"
              marginLeft: "30px",
            }}
            alt="company logo"
          />
        </div>
        <div
          style={{
            paddingLeft: "10px",
            fontSize: "20px",
            fontWeight: "600",
            marginLeft: "30px",
            color: "rgba(0,0,0,0.8)",
          }}
        >
          Premelo Statistics
        </div>
      </div>
      <div
        style={{
          padding: "30px",
          fontSize: "25px",
          fontWeight: "600",
          color: "rgba(0,0,0,0.7)",
        }}
      >
        Platform Performance (Pages)
      </div>
      <div style={{ display: "flex", flexWrap: "wrap", padding: "0px 30px" }}>
        <div style={{ width: "200px", marginRight: "20px" }}>
          <div
            style={{
              fontSize: "16px",
              fontWeight: "600",
              color: "rgba(0,0,0,0.8)",
            }}
          >
            User Count
          </div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div
              style={{
                width: "50%",
                background: "white",
                color: "rgba(0,0,0,0.8)",
                fontSize: "12px",
                textAlign: "center",
                padding: "10px 20px",
                marginRight: "10px",
                borderRadius: "10px",
              }}
            >
              {newUserCount}
            </div>
            <div
              style={{
                width: "50%",
                background: "white",
                color: "rgba(0,0,0,0.8)",
                fontSize: "12px",
                textAlign: "center",
                padding: "10px 20px",
                borderRadius: "10px",
              }}
            >
              {existingUserCount}
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "50%",
                color: "rgba(0,0,0,0.8)",
                fontSize: "12px",
                textAlign: "center",
                padding: "3px 20px",
                marginRight: "10px",
              }}
            >
              Unique
            </div>
            <div
              style={{
                width: "50%",
                color: "rgba(0,0,0,0.8)",
                fontSize: "12px",
                textAlign: "center",
                padding: "3px 20px",
              }}
            >
              Existing
            </div>
          </div>
        </div>
        <div style={{ width: "200px", marginRight: "20px" }}>
          <div
            style={{
              fontSize: "16px",
              fontWeight: "600",
              color: "rgba(0,0,0,0.8)",
            }}
          >
            Homepage
          </div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div
              style={{
                width: "50%",
                background: "white",
                color: "rgba(0,0,0,0.8)",
                fontSize: "12px",
                textAlign: "center",
                padding: "10px 20px",
                marginRight: "10px",
                borderRadius: "10px",
              }}
            >
              {homePage}
            </div>
            <div
              style={{
                width: "50%",
                background: "white",
                color: "rgba(0,0,0,0.8)",
                fontSize: "12px",
                textAlign: "center",
                padding: "10px 20px",
                borderRadius: "10px",
              }}
            >
              {homePageMobile}
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "50%",
                color: "rgba(0,0,0,0.8)",
                fontSize: "12px",
                textAlign: "center",
                padding: "3px 20px",
                marginRight: "10px",
              }}
            >
              Desktop
            </div>
            <div
              style={{
                width: "50%",
                color: "rgba(0,0,0,0.8)",
                fontSize: "12px",
                textAlign: "center",
                padding: "3px 20px",
              }}
            >
              Mobile
            </div>
          </div>
        </div>
        <div style={{ width: "200px", marginRight: "20px" }}>
          <div
            style={{
              fontSize: "16px",
              fontWeight: "600",
              color: "rgba(0,0,0,0.8)",
            }}
          >
            Sign Up page
          </div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div
              style={{
                width: "50%",
                background: "white",
                color: "rgba(0,0,0,0.8)",
                fontSize: "12px",
                textAlign: "center",
                padding: "10px 20px",
                marginRight: "10px",
                borderRadius: "10px",
              }}
            >
              {signUpPage}
            </div>
            <div
              style={{
                width: "50%",
                background: "white",
                color: "rgba(0,0,0,0.8)",
                fontSize: "12px",
                textAlign: "center",
                padding: "10px 20px",
                borderRadius: "10px",
              }}
            >
              {signUpPageMobile}
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "50%",
                color: "rgba(0,0,0,0.8)",
                fontSize: "12px",
                textAlign: "center",
                padding: "3px 20px",
                marginRight: "10px",
              }}
            >
              Desktop
            </div>
            <div
              style={{
                width: "50%",
                color: "rgba(0,0,0,0.8)",
                fontSize: "12px",
                textAlign: "center",
                padding: "3px 20px",
              }}
            >
              Mobile
            </div>
          </div>
        </div>
        <div style={{ width: "200px", marginRight: "20px" }}>
          <div
            style={{
              fontSize: "16px",
              fontWeight: "600",
              color: "rgba(0,0,0,0.8)",
            }}
          >
            Fill Information page
          </div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div
              style={{
                width: "50%",
                background: "white",
                color: "rgba(0,0,0,0.8)",
                fontSize: "12px",
                textAlign: "center",
                padding: "10px 20px",
                marginRight: "10px",
                borderRadius: "10px",
              }}
            >
              {doneSignUpPage}
            </div>
            <div
              style={{
                width: "50%",
                background: "white",
                color: "rgba(0,0,0,0.8)",
                fontSize: "12px",
                textAlign: "center",
                padding: "10px 20px",
                borderRadius: "10px",
              }}
            >
              {doneSignUpPageMobile}
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "50%",
                color: "rgba(0,0,0,0.8)",
                fontSize: "12px",
                textAlign: "center",
                padding: "3px 20px",
                marginRight: "10px",
              }}
            >
              Desktop
            </div>
            <div
              style={{
                width: "50%",
                color: "rgba(0,0,0,0.8)",
                fontSize: "12px",
                textAlign: "center",
                padding: "3px 20px",
              }}
            >
              Mobile
            </div>
          </div>
        </div>
        <div style={{ width: "200px", marginRight: "20px" }}>
          <div
            style={{
              fontSize: "16px",
              fontWeight: "600",
              color: "rgba(0,0,0,0.8)",
            }}
          >
            Dashboard page
          </div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <div
              style={{
                width: "50%",
                background: "white",
                color: "rgba(0,0,0,0.8)",
                fontSize: "12px",
                textAlign: "center",
                padding: "10px 20px",
                marginRight: "10px",
                borderRadius: "10px",
              }}
            >
              {dashboardPage}
            </div>
            <div
              style={{
                width: "50%",
                background: "white",
                color: "rgba(0,0,0,0.8)",
                fontSize: "12px",
                textAlign: "center",
                padding: "10px 20px",
                borderRadius: "10px",
              }}
            >
              {dashboardPageMobile}
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "50%",
                color: "rgba(0,0,0,0.8)",
                fontSize: "12px",
                textAlign: "center",
                padding: "3px 20px",
                marginRight: "10px",
              }}
            >
              Desktop
            </div>
            <div
              style={{
                width: "50%",
                color: "rgba(0,0,0,0.8)",
                fontSize: "12px",
                textAlign: "center",
                padding: "3px 20px",
              }}
            >
              Mobile
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          padding: "30px",
          fontSize: "25px",
          fontWeight: "600",
          color: "rgba(0,0,0,0.7)",
        }}
      >
        Platform Performance (Sign Up)
      </div>
      <div
        style={{
          padding: "0px 30px",
          width: "100%",
        }}
      >
        <div style={{ height: "auto", width: "40%" }}>
          <DateRangePicker
            ranges={[dateRange]}
            onChange={(range) => setDateRange(range.selection)}
          />
        </div>
        <div style={{ width: "100%" }}>
          <LineChart
            width={750}
            height={500}
            data={filteredData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" style={{ fontSize: "12px" }} />
            <YAxis style={{ fontSize: "12px" }} />
            <Tooltip />
            <Legend style={{ color: "#1872d9" }} />
            <Line
              type="monotone"
              dataKey="count"
              stroke="#1872d9"
              activeDot={{ r: 8 }}
              style={{ color: "#1872d9" }}
            />
          </LineChart>
        </div>
      </div>
    </div>
  );
};

export default PlatformPerformance;
