import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import React from "react";

const TableSkeleton = ({ cards }) => {
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      <div style={{width:"99%"}}>
        <div
          style={{ display: "flex", marginBottom: "20px", marginTop: "30px" }}
        >
          <Skeleton
            style={{ width: "30px", height: "30px", marginRight: "13px" }}
          />
          <Skeleton width={200} height={30} />
        </div>
        <div
          style={{
            marginBottom: "10px",
            background: "#ffffff",
            padding: "10px",
            borderRadius: "15px",
          }}
        >
          <Skeleton height={20} style={{ marginBottom: "10px" }} />
          <div style={{ display: "flex", marginBottom: "10px" }}>
            <Skeleton width={20} height={20} style={{ marginRight: "10px" }} />
            <Skeleton width={20} height={20} style={{ marginRight: "10px" }} />
            <Skeleton
              width={250}
              height={20}
              style={{ marginRight: "150px" }}
            />
            <Skeleton
              width={200}
              height={20}
              style={{ marginRight: "160px" }}
            />
            <Skeleton width={100} height={20} style={{ marginRight: "50px" }} />
            <Skeleton width={100} height={20} style={{ marginRight: "50px" }} />
            <Skeleton width={150} height={20} style={{ marginRight: "50px" }} />
            <Skeleton width={150} height={20} style={{ marginRight: "50px" }} />
          </div>
          <div style={{ display: "flex", marginBottom: "10px" }}>
            <Skeleton width={20} height={20} style={{ marginRight: "10px" }} />
            <Skeleton width={20} height={20} style={{ marginRight: "10px" }} />
            <Skeleton
              width={250}
              height={20}
              style={{ marginRight: "150px" }}
            />
            <Skeleton
              width={200}
              height={20}
              style={{ marginRight: "160px" }}
            />
            <Skeleton width={100} height={20} style={{ marginRight: "50px" }} />
            <Skeleton width={100} height={20} style={{ marginRight: "50px" }} />
            <Skeleton width={150} height={20} style={{ marginRight: "50px" }} />
            <Skeleton width={150} height={20} style={{ marginRight: "50px" }} />
          </div>
          <div style={{ display: "flex", marginBottom: "10px" }}>
            <Skeleton width={20} height={20} style={{ marginRight: "10px" }} />
            <Skeleton width={20} height={20} style={{ marginRight: "10px" }} />
            <Skeleton
              width={250}
              height={20}
              style={{ marginRight: "150px" }}
            />
            <Skeleton
              width={200}
              height={20}
              style={{ marginRight: "160px" }}
            />
            <Skeleton width={100} height={20} style={{ marginRight: "50px" }} />
            <Skeleton width={100} height={20} style={{ marginRight: "50px" }} />
          </div>
        </div>
        
      </div>
    ));
};
export default TableSkeleton;
