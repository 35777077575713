import { height, width } from "@mui/system";
import React from "react";
import { useState, useCallback, useRef } from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import logo from "./images/CS_Favicon-01.png";
import "react-phone-number-input/style.css";
import { useEffect } from "react";
import Cookies from "js-cookie";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Modal from "react-modal";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { TwitterPicker } from "react-color";
import NextPlanIcon from "@mui/icons-material/NextPlan";
import axios from "axios";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import LockIcon from "@mui/icons-material/Lock";

const SettingsMobile = (props) => {
  const { primaryColor, complementaryColor } = props;

  const [paymentContentFormIsOpen, setPaymentContentFormIsOpen] =
    useState(false);

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        style={{ height: "20px" }}
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <div>{children}</div>}
      </div>
    );
  };

  const a11yProps = (index) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  };

  const CustomTabs = styled(Tabs)({
    borderBottom: "1px solid #e8e8e8",
    "& .MuiTabs-indicator": {
      backgroundColor: primaryColor,
    },
    "& .MuiTabs-indicator:hover": {
      backgroundColor: complementaryColor,
    },
  });

  const CustomTab = styled(Tab)({
    textTransform: "none",
    fontWeight: "800",
    fontSize: "12px",
    color: "rgba(0,0,0,0.4)",
    "&:hover": {
      color: complementaryColor,
      opacity: 1,
    },
    "&.Mui-selected": {
      color: primaryColor,
      fontSize: "14px",
    },
  });
  const [value, setValue] = useState(2);
  const handleChange = (event, newValue) => {
    console.log("newValue:", newValue);
    setValue(newValue);
  };

  //Phone Number
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [availableUsers, setAvailableUsers] = useState(
    props.availableUsersWithEmail
  );
  const handlephonenumber = (event) => {
    setPhoneNumber(event.target.value);
  };

  //profilepicture
  const [editor, setEditor] = useState(null);
  const handleEditorReady = (editor) => {
    setEditor(editor);
  };

  const fetchUsersWithEmail = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/user-available-team-members-with-email",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    const modifiedData = data.map((user) => {
      return {
        ...user,
        fullName: `${user.first_name} ${user.last_name}`,
      };
    });
    setAvailableUsers(modifiedData);
  };

  const handleDeleteClick = () => {
    console.log("delete clicked");
  };
  const handleUpdateClick = () => {
    console.log("update clicked");
  };

  const [domain, setDomain] = useState("");
  const [companyColor, setCompanyColor] = useState(props.primaryColor);
  const [domainCompany, setDomainCompany] = useState("");
  const [companyDomain, setCompanyDomain] = useState(
    props.companyDomain ? props.companyDomain : ""
  );
  const [userCount, setUserCount] = useState("8");
  const [unlock, setUnlock] = useState(false);
  const getActiveSubscription = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/getActiveSubscription",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 404) {
      console.log("No active subscription found.");
      setUnlock(true);
    } else {
      const data = await response.json();
      setUserCount(data.quantity);
      setUnlock(false);
      const formattedDate = moment(data.expires_at).format("DD MMM YYYY");
    }
  };

  useEffect(() => {
    getActiveSubscription();
    fetchUsersDetails();
  }, []);

  const handleOpenModal = () => {
    setPaymentContentFormIsOpen(true);
    setDomainCompany(personalCompanyName);
    console.log(personalCompanyName);
  };

  const handleCompanyDomainChange = (event) => {
    setCompanyDomain(event.target.value);
  };

  const formatDomain = (domain) => {
    const regex = /^(?:https?:\/\/)?([\w.-]+)\.([\w.-]+)\.(\w+)$/i;
    const match = domain.match(regex);

    if (match) {
      const subdomain = match[1];
      const domainName = match[2].replace(".", "");
      const tld = match[3];
      return `www.${subdomain}${domainName}.${tld}`;
    } else {
      return domain;
    }
  };

  const [companyImageUrl, setCompanyImageUrl] = useState(null);
  const [personalImageUrl, setPersonalImageUrl] = useState(
    props.profileImageUrl
  );
  const [personalFirstName, setPersonalFirstName] = useState("");
  const [personalLastName, setPersonalLastName] = useState("");
  const [personalCompanyName, setPersonalCompanyName] = useState("");
  const [personalEmail, setPersonalEmail] = useState("");
  const [personalPhoneNumber, setPersonalPhoneNumber] = useState("");
  const [personalBio, setPersonalBio] = useState("");

  const fetchUsersDetails = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/profile-settings-details",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    setPersonalImageUrl(data.profile_photo);
    setPersonalFirstName(data.first_name);
    setPersonalLastName(data.last_name);
    setPersonalCompanyName(data.company_name);
    setPersonalEmail(data.email);
    setPersonalPhoneNumber(data.phone_number);
    setPersonalBio(data.bio);
    const dotComIndex = window.location.hostname;
    const encodedCompanyName = encodeURIComponent(data.company_name);
    const encodedColor = encodeURIComponent(props.primaryColor);
    {
      props.primaryColor
        ? setDomain(
            `https://${dotComIndex}/signup?companyname=${encodedCompanyName}&companycolor=${encodedColor}`
          )
        : setDomain(
            `https://${dotComIndex}/signup?companyname=${encodedCompanyName}`
          );
    }
    // setDomain(
    //   `http://${dotComIndex}/signup?companyname=${encodedCompanyName}&companycolor=${encodedColor}`
    // );
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files;
    console.log(e.target.files);

    const formData = new FormData();

    for (let i = 0; i < file.length; i++) {
      formData.append("files[]", file[i]);
    }

    try {
      const response = await axios.post(
        "https://ww1.premelo.com/api/upload-image",
        formData
      );
      const imageUrl = response.data.urls;
      setCompanyImageUrl(imageUrl[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePersonalImageUpload = async (e) => {
    const file = e.target.files;
    console.log(e.target.files);

    const formData = new FormData();

    for (let i = 0; i < file.length; i++) {
      formData.append("files[]", file[i]);
    }

    try {
      const response = await axios.post(
        "https://ww1.premelo.com/api/upload-image",
        formData
      );
      const imageUrl = response.data.urls;
      setPersonalImageUrl(imageUrl[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const [customizationSuccess, setCustomizationSuccess] = useState(false);

  const handleClick = () => {
    const fileInput = document.getElementById("imageInput");
    fileInput.click();
  };

  const handlePersonalClick = () => {
    const fileInput = document.getElementById("personalImageInput");
    fileInput.click();
  };
  const [dnsZoneIsOpen, setDnsZoneIsOpen] = useState(false);

  const handleCompanyCustomizationSubmit = async () => {
    const token = Cookies.get("token");
    try {
      const response = await axios.put(
        "https://ww1.premelo.com/api/company-customization",
        {
          ...(companyColor !== props.primaryColor && {
            company_color: companyColor,
          }),
          ...(companyImageUrl && { company_logo: companyImageUrl }),
          ...(companyDomain &&
            companyDomain !== "https://www.yourwebsite.com" &&
            companyDomain !== props.companyDomain && {
              company_domain: companyDomain,
            }),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCustomizationSuccess(true);
      setTimeout(() => {
        setCustomizationSuccess(false);
      }, 3000);
    } catch (error) {
      console.log(error);
    }
    const domainName = companyDomain.replace("https://", "");
    const domainNameWithoutWWW = companyDomain
      .replace("https://", "")
      .replace("www.", "");

    if (
      domainNameWithoutWWW === "yourwebsite.com" ||
      domainNameWithoutWWW === "" ||
      companyDomain === props.companyDomain
    ) {
      return;
    } else {
      await fetch(
        "https://api.vercel.com/v10/projects/premelo-crm-123/domains?teamId=team_6ZIQL5X06mBh5ybT8YD3IEQG",
        {
          body: JSON.stringify({
            name: domainName,
          }),
          headers: {
            Authorization: "Bearer 0jytwZWqE7ttKdzeKrRKN1FF",
            "Content-Type": "application/json",
          },
          method: "POST",
        }
      );
      await fetch(
        "https://api.vercel.com/v10/projects/premelo-crm-123/domains?teamId=team_6ZIQL5X06mBh5ybT8YD3IEQG",
        {
          body: JSON.stringify({
            name: domainNameWithoutWWW,
            redirect: domainName,
          }),
          headers: {
            Authorization: "Bearer 0jytwZWqE7ttKdzeKrRKN1FF",
            "Content-Type": "application/json",
          },
          method: "POST",
        }
      );
      setDnsZoneIsOpen(true);
      console.log("please add this to your DNS zone");
    }
  };

  const handleCompanyDomainChangeConfirmation = (e) => {
    const domainInput = e.target.value;
    const regex = /^http(s)?:\/\/(www\.)?([^\/]+)/i;
    const match = domainInput.match(regex);

    if (match && match.length > 3) {
      const companyDomain = `https://www.${match[3]}`;
      setCompanyDomain(companyDomain);
    } else {
      setCompanyDomain(domainInput);
    }
  };
  const [isUpdated, setIsUpdated] = useState(false);
  const handleUpdateProfile = async () => {
    const token = Cookies.get("token");
    try {
      const response = await axios.put(
        "https://ww1.premelo.com/api/update-profile-settings-details",
        {
          profile_photo: personalImageUrl,
          first_name: personalFirstName,
          last_name: personalLastName,
          phone_number: personalPhoneNumber,
          bio: personalBio,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
    } catch (error) {
      console.log(error);
    }
    setIsUpdated(true);
    setTimeout(() => {
      setIsUpdated(false);
    }, 3000);
  };

  const profileContent = (
    <div style={{}}>
      <div
        style={{
          borderBottom: "1px solid rgba(0,0,0,0.2",
          paddingBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems:"flex-start",
          width: "100%",
        }}
      >
        <div>
          <div
            style={{
              fontSize: "30px",
              color: "rgba(0, 0, 0,0.7)",
              fontWeight: "600",
            }}
          >
            Profile
          </div>
          <div style={{ fontSize: "13px", color: "rgba(0,0,0,0.4)" }}>
            Update your personal information here
          </div>
        </div>
        {isUpdated ? (
          <div
            className="mui-button"
            style={{
              padding: "7px 25px",
              background: "#28993d",
              color: "white",
              borderRadius: "10px",
            }}
          >
            Success
          </div>
        ) : (
          <div
            className="mui-button"
            onClick={handleUpdateProfile}
            style={{
              padding: "7px 25px",
              background: complementaryColor,
              color: "white",
              borderRadius: "10px",
            }}
          >
            Save
          </div>
        )}
      </div>
      {/* {Profile Row} */}
      <div
        style={{
          display: "flex",
          fontSize: "20px",
          color: "rgba(0, 0, 0,0.7)",
          paddingTop: "20px",
          borderBottom: "1px solid rgba(0,0,0,0.2",
          paddingBottom: "20px",
        }}
      >
        <div style={{ width: "50%" }}>
          <div
            style={{
              fontWeight: "600",
              fontSize: "20px",
              color: "rgba(0,0,0,0.6)",
            }}
          >
            Profile Photo
          </div>
          <div style={{ fontSize: "13px", color: "rgba(0,0,0,0.4)" }}>
            This will be displayed on your profile.
          </div>
          <div style={{ marginTop: "15px" }}>
            <button
              className="mui-button"
              style={{
                color: "white",
                backgroundColor: props.primaryColor,
                border: "none",
                fontSize: "14px",
                fontWeight: "600",
                padding: "10px 30px",
                borderRadius: "10px",
              }}
              onClick={handlePersonalClick}
            >
              Update
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "start",
            width: "50%",
          }}
        >
          <input
            id="personalImageInput"
            type="file"
            style={{ display: "none" }}
            onChange={handlePersonalImageUpload}
          />
          <div
            className="mui-button"
            style={{
              width: "140px",
              height: "140px",
              border: "2px solid rgba(125, 125, 125,0.5)",
              borderRadius: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              backgroundColor: "white",
              textAlign: "center",
              color: "rgba(125, 125, 125,0.5)",
              marginTop: "15px",
            }}
            onClick={handlePersonalClick}
          >
            {personalImageUrl ? (
              <img
                src={personalImageUrl}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="profile photo"
              />
            ) : (
              "Profile Picture"
            )}
          </div>
        </div>
      </div>
      {/* {First Row} */}
      <div
        style={{
          paddingTop: "20px",
          borderBottom: "1px solid rgba(0,0,0,0.2",
          paddingBottom: "20px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "400px",
            fontWeight: "600",
            fontSize: "20px",
            color: "rgba(0,0,0,0.6)",
          }}
        >
          Your name
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            width: "100%",
            marginTop: "10px",
          }}
        >
          <div
            style={{ width: "50%", fontSize: "12px", color: "rgba(0,0,0,0.4)" }}
          >
            First name
          </div>
          <div
            style={{ width: "50%", fontSize: "12px", color: "rgba(0,0,0,0.4)" }}
          >
            Last name
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            width: "100%",
          }}
        >
          <input
            className="profile-input"
            placeholder="First Name"
            type="text"
            name="firstname"
            value={personalFirstName}
            onChange={(e) => setPersonalFirstName(e.target.value)}
            style={{
              fontSize: "14px",
              width: "45%",
              borderRadius: "10px",
              border: "1px solid rgba(125, 125, 125,0.5)",
              padding: "7px",
            }}
          />
          <input
            className="profile-input"
            placeholder="Last Name"
            type="text"
            name="lastname"
            value={personalLastName}
            onChange={(e) => setPersonalLastName(e.target.value)}
            style={{
              fontSize: "14px",
              width: "45%",
              borderRadius: "10px",
              border: "1px solid rgba(125, 125, 125,0.5)",
              padding: "7px",
            }}
          />
        </div>
      </div>
      <div
        style={{
          paddingTop: "20px",
          borderBottom: "1px solid rgba(0,0,0,0.2",
          paddingBottom: "20px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "400px",
            fontWeight: "600",
            fontSize: "20px",
            color: "rgba(0,0,0,0.6)",
          }}
        >
          Company name
        </div>
        <input
          className="profile-input"
          placeholder="Company Name"
          type="text"
          name="companyname"
          value={personalCompanyName}
          onChange={(e) => setPersonalCompanyName(e.target.value)}
          disabled
          style={{
            fontSize: "14px",
            width: "95%",
            borderRadius: "10px",
            border: "1px solid rgba(125, 125, 125,0.5)",
            padding: "7px",
            marginTop: "10px",
          }}
        />
      </div>
      <div
        style={{
          paddingTop: "20px",
          borderBottom: "1px solid rgba(0,0,0,0.2",
          paddingBottom: "20px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "400px",
            fontWeight: "600",
            fontSize: "20px",
            color: "rgba(0,0,0,0.6)",
          }}
        >
          Email Address
        </div>
        <input
          className="profile-input"
          placeholder="example@example.com"
          type="text"
          name="email"
          value={personalEmail}
          disabled
          onChange={(e) => setPersonalEmail(e.target.value)}
          style={{
            fontSize: "14px",
            width: "95%",
            borderRadius: "10px",
            border: "1px solid rgba(125, 125, 125,0.5)",
            padding: "7px",
            marginTop: "10px",
          }}
        />
      </div>
      <div
        style={{
          paddingTop: "20px",
          borderBottom: "1px solid rgba(0,0,0,0.2",
          paddingBottom: "20px",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "400px",
            fontWeight: "600",
            fontSize: "20px",
            color: "rgba(0,0,0,0.6)",
          }}
        >
          Phone number
        </div>
        <input
          className="profile-input"
          placeholder="Phone Number"
          type="tel"
          name="phone"
          value={personalPhoneNumber}
          onChange={(e) => setPersonalPhoneNumber(e.target.value)}
          style={{
            fontSize: "14px",
            width: "95%",
            borderRadius: "10px",
            border: "1px solid rgba(125, 125, 125,0.5)",
            padding: "7px",
            marginTop: "10px",
          }}
        />
      </div>
    </div>
  );
  const teamContent = (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            fontSize: "30px",
            color: "rgba(0, 0, 0,0.7)",
            fontWeight: "600",
          }}
        >
          All users
        </div>
        <div
          className="mui-button"
          style={{
            display: "flex",
            alignItems: "center",
            background: props.primaryColor,
            color: "white",
            fontSize: "18px",
            fontWeight: "600",
            padding: "5px 25px",
            borderRadius: "10px",
          }}
          onClick={handleOpenModal}
        >
          <PersonAddIcon style={{ color: "white", marginRight: "10px" }} />
          Invite
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "40px",
          color: "rgba(0,0,0,0.6)",
          fontSize: "18px",
          fontWeight: "600",
          borderBottom: "2px solid rgba(0,0,0,0.2)",
          paddingBottom: "5px",
          width: "100%",
        }}
      >
        <div style={{ width: "45%" }}>Name</div>
        <div style={{ width: "45%" }}>Email</div>
        <div
          style={{ width: "5%", color: "rgba(0,0,0,0.4)", textAlign: "right" }}
        >
          {availableUsers.length}/{userCount}
        </div>
      </div>
      {availableUsers.map((user) => {
        const useInitials = `${user.first_name.charAt(
          0
        )}${user.last_name.charAt(0)}`;

        const dateString = user.created_at;
        const dateObject = new Date(dateString);
        const formattedDate = dateObject.toLocaleString("en-US", {
          day: "numeric",
          month: "long",
          year: "numeric",
        });

        return (
          <div
            key={user.user_id}
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "15px",
              color: "rgba(0,0,0,0.5)",
              fontSize: "15px",
              fontWeight: "400",
              borderBottom: "1px solid rgba(0,0,0,0.1)",
              paddingBottom: "13px",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", width: "50%" }}
            >
              <div
                style={{
                  height: "35px",
                  width: "35px",
                  borderRadius: "50%",
                  background: `${user.user_color}`,
                  color: "white",
                  marginRight: "10px",
                }}
              >
                <div style={{ marginTop: "6px", marginLeft: "6px" }}>
                  {useInitials}
                </div>
              </div>
              {user.fullName}
            </div>
            <div style={{ width: "60%" }}>{user.email}</div>
          </div>
        );
      })}
    </div>
  );
  const [companyImageUrlFromParent, setCompanyImageUrlFromParent] = useState(
    props.companyImageUrl
  );

  const customizationContent = (
    <div style={{ position: "relative", width: "100%", overflow: "hidden" }}>
      <div
        style={{
          borderBottom: "1px solid rgba(0,0,0,0.2",
          paddingBottom: "20px",
          position: "relative",
        }}
      >
        <div
          style={{
            fontSize: "30px",
            color: "rgba(0, 0, 0,0.7)",
            fontWeight: "600",
          }}
        >
          Customize your Platform
        </div>
        <div style={{ fontSize: "13px", color: "rgba(0,0,0,0.4)" }}>
          Enjoy the flexibility that comes with Premelo Plus.
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "60vh",
        }}
      >
        <div style={{ width: "30%" }}>
          <div style={{ marginTop: "50px" }}>
            <div style={{ color: "rgba(0,0,0,0.7)", fontSize: "14px" }}>
              Company logo
            </div>
            <input
              id="imageInput"
              type="file"
              style={{ display: "none" }}
              onChange={handleImageUpload}
            />
            <div
              className="mui-button"
              style={{
                width: "140px",
                height: "140px",
                border: "2px solid rgba(125, 125, 125,0.5)",
                borderRadius: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                backgroundColor: "white",
                textAlign: "center",
                color: "rgba(125, 125, 125,0.5)",
                marginTop: "15px",
              }}
              onClick={handleClick}
            >
              {companyImageUrl ? (
                <img
                  src={companyImageUrl}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  alt="company logo"
                />
              ) : props.companyImageUrl ? (
                <img
                  src={props.companyImageUrl}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  alt="company logo"
                />
              ) : (
                <img
                  src={logo}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  alt="company logo"
                />
              )}
            </div>
          </div>
          <div style={{ marginTop: "30px" }}>
            <div
              style={{
                color: "rgba(0,0,0,0.7)",
                fontSize: "14px",
                marginBottom: "10px",
              }}
            >
              UI Color tone
            </div>
            <TwitterPicker
              style={{ backgroundColor: "transparent" }}
              onChange={(color, event) => setCompanyColor(color.hex)}
            />
          </div>
          <div style={{ marginTop: "30px" }}>
            <div
              style={{
                color: "rgba(0,0,0,0.7)",
                fontSize: "14px",
                marginBottom: "10px",
              }}
            >
              Connect domain
            </div>
            <input
              placeholder="https://www."
              type="text"
              name="companyDomain"
              value={companyDomain}
              onChange={(e) => {
                const value = e.target.value;
                const newValue = value.replace(
                  /(https?:\/\/)?(www\.)?/i,
                  "https://www."
                );
                setCompanyDomain(newValue);
              }}
              style={{
                fontSize: "14px",
                width: "80%",
                borderRadius: "10px",
                border: "1px solid rgba(125, 125, 125,0.5)",
                padding: "7px",
              }}
            />
            {customizationSuccess ? (
              <div
                className="mui-button"
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "#28993d",
                  color: "white",
                  fontSize: "14px",
                  fontWeight: "600",
                  padding: "5px 25px",
                  borderRadius: "10px",
                  width: "100px",
                  marginTop: "45px",
                }}
                // onClick={handleOpenModal}
              >
                <CheckCircleIcon
                  style={{ color: "white", marginRight: "6px" }}
                />
                Success
              </div>
            ) : (
              <div
                className="mui-button"
                onClick={() => handleCompanyCustomizationSubmit()}
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: props.primaryColor,
                  color: "white",
                  fontSize: "14px",
                  fontWeight: "600",
                  padding: "5px 25px",
                  borderRadius: "10px",
                  width: "100px",
                  marginTop: "45px",
                }}
                // onClick={handleOpenModal}
              >
                <NextPlanIcon style={{ color: "white", marginRight: "6px" }} />
                Proceed
              </div>
            )}
          </div>
          <div></div>
        </div>
        <div
          style={{
            width: "70%",
            background: "white",
            marginTop: "40px",
            borderRadius: "20px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              background: companyColor,
              borderRadius: "20px 20px 0px 0px",
              padding: "10px 20px",
              color: "white",
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            <div style={{ marginLeft: "20px" }}>{companyDomain}</div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  height: "15px",
                  width: "15px",
                  borderRadius: "50%",
                  background: "white",
                  marginLeft: "10px",
                }}
              ></div>
              <div
                style={{
                  height: "15px",
                  width: "15px",
                  borderRadius: "50%",
                  background: "white",
                  marginLeft: "10px",
                }}
              ></div>
              <div
                style={{
                  height: "15px",
                  width: "15px",
                  borderRadius: "50%",
                  background: "white",
                  marginLeft: "10px",
                }}
              ></div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "15%",
                height: "100%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    border: "2px solid rgba(125, 125, 125,0.5)",
                    borderRadius: "100px",
                    display: "flex",
                    alignItems: "center",
                    overflow: "hidden",
                    backgroundColor: "white",
                    textAlign: "center",
                    color: "rgba(125, 125, 125,0.5)",
                    marginTop: "20px",
                    fontSize: "8px",
                  }}
                >
                  {companyImageUrl ? (
                    <img
                      src={companyImageUrl}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      alt="company logo"
                    />
                  ) : props.companyImageUrl ? (
                    <img
                      src={props.companyImageUrl}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      alt="company logo"
                    />
                  ) : (
                    <img
                      src={logo}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      alt="company logo"
                    />
                  )}
                </div>
              </div>
              <div
                style={{
                  width: "70%",
                  background: "rgba(0,0,0,0.1)",
                  height: "12px",
                  marginLeft: "25px",
                  marginTop: "20px",
                  borderRadius: "15px",
                }}
              ></div>
              <div
                style={{
                  width: "70%",
                  background: "rgba(0,0,0,0.1)",
                  height: "12px",
                  marginLeft: "25px",
                  marginTop: "15px",
                  borderRadius: "15px",
                }}
              ></div>
              <div
                style={{
                  width: "70%",
                  background: "rgba(0,0,0,0.1)",
                  height: "12px",
                  marginLeft: "25px",
                  marginTop: "15px",
                  borderRadius: "15px",
                }}
              ></div>
              <div
                style={{
                  width: "70%",
                  background: "rgba(0,0,0,0.1)",
                  height: "12px",
                  marginLeft: "25px",
                  marginTop: "15px",
                  borderRadius: "15px",
                }}
              ></div>
            </div>
            <div
              style={{
                height: "49vh",
                width: "83%",
                marginTop: "10px",
                background: `${companyColor}1A`,
                borderRadius: "15px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  color: companyColor,
                  fontSize: "14px",
                  fontWeight: "600",
                  marginTop: "30px",
                  textAlign: "center",
                }}
              >
                Dashboard
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "20%",
                    background: "rgba(0,0,0,0.1)",
                    height: "12px",
                    marginTop: "10px",
                    borderRadius: "15px",
                  }}
                ></div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  color: companyColor,
                  fontSize: "14px",
                  fontWeight: "600",
                  marginTop: "20px",
                  marginLeft: "40px",
                }}
              >
                Recently used
              </div>
              <div
                style={{
                  width: "93%",
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "0px 37px",
                }}
              >
                <div
                  style={{
                    width: "18%",
                    flexBasis: "18%",
                    background: "rgba(0,0,0,0.1)",
                    height: "120px",
                    marginTop: "10px",
                    borderRadius: "15px",
                    marginRight: "15px",
                  }}
                ></div>
                <div
                  style={{
                    width: "18%",
                    flexBasis: "18%",
                    background: "rgba(0,0,0,0.1)",
                    height: "120px",
                    marginTop: "10px",
                    borderRadius: "15px",
                    marginRight: "15px",
                  }}
                ></div>
                <div
                  style={{
                    width: "18%",
                    flexBasis: "18%",
                    background: "rgba(0,0,0,0.1)",
                    height: "120px",
                    marginTop: "10px",
                    borderRadius: "15px",
                    marginRight: "15px",
                  }}
                ></div>
                <div
                  style={{
                    width: "18%",
                    flexBasis: "18%",
                    background: "rgba(0,0,0,0.1)",
                    height: "120px",
                    marginTop: "10px",
                    borderRadius: "15px",
                    marginRight: "15px",
                  }}
                ></div>
                <div
                  style={{
                    width: "18%",
                    flexBasis: "18%",
                    background: "rgba(0,0,0,0.1)",
                    height: "120px",
                    marginTop: "10px",
                    borderRadius: "15px",
                    marginRight: "15px",
                  }}
                ></div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  color: companyColor,
                  fontSize: "14px",
                  fontWeight: "600",
                  marginTop: "20px",
                  marginLeft: "40px",
                }}
              >
                To-do List
              </div>
              <div
                style={{
                  width: "93%",
                  flexWrap: "wrap",
                  padding: "0px 37px",
                }}
              >
                <div
                  style={{
                    width: "98%",
                    background: "rgba(0,0,0,0.1)",
                    height: "15px",
                    marginTop: "10px",
                    borderRadius: "15px",
                    marginRight: "15px",
                  }}
                ></div>
                <div
                  style={{
                    width: "63%",
                    background: "rgba(0,0,0,0.1)",
                    height: "15px",
                    marginTop: "10px",
                    borderRadius: "15px",
                    marginRight: "15px",
                  }}
                ></div>
                <div
                  style={{
                    width: "82%",
                    background: "rgba(0,0,0,0.1)",
                    height: "15px",
                    marginTop: "10px",
                    borderRadius: "15px",
                    marginRight: "15px",
                  }}
                ></div>
                <div
                  style={{
                    width: "98%",
                    background: "rgba(0,0,0,0.1)",
                    height: "15px",
                    marginTop: "10px",
                    borderRadius: "15px",
                    marginRight: "15px",
                  }}
                ></div>
                <div
                  style={{
                    width: "74%",
                    background: "rgba(0,0,0,0.1)",
                    height: "15px",
                    marginTop: "10px",
                    borderRadius: "15px",
                    marginRight: "15px",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {unlock && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backdropFilter: "blur(6px)",
            zIndex: 999,
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            color: "rgba(0,0,0,0.8)",
          }}
        >
          <div
            style={{
              background: "rgba(1,1,1,0.2)",
              border: "1px solid rgba(0,0,0,0.2)",
              borderRadius: "12px",
              padding: "35px",
              marginTop: "60px",
            }}
          >
            <LockIcon style={{ fontSize: "80px" }} />
            <div style={{ fontSize: "30px", fontWeight: "600" }}>
              This feature is only available
            </div>
            <div style={{ fontSize: "20px", fontWeight: "400" }} v>
              on Premelo Plus
            </div>
          </div>
        </div>
      )}
    </div>
  );
  const [copied, setCopied] = useState(false);
  const handleCopyClick = () => {
    navigator.clipboard.writeText(domain);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  const handleCopyNotClick = () => {
    setCopied(false);
  };
  const [activeTab, setActiveTab] = useState();

  const [profileTab, setProfileTab] = useState(true);
  const [teamTab, setTeamTab] = useState(false);
  const [customizationTab, setCustomizationTab] = useState(false);

  const handleChangeProfileTab = () => {
    setProfileTab(true);
    setTeamTab(false);
    setCustomizationTab(false);
  };
  const handleChangeTeamTab = () => {
    setProfileTab(false);
    setTeamTab(true);
    setCustomizationTab(false);
  };
  const handleChangeCustomizationTab = () => {
    setProfileTab(false);
    setTeamTab(false);
    setCustomizationTab(true);
  };

  return (
    <div>
      <div
        style={{
          borderBottom: "1px solid rgba(0,0,0,0.1)",
          height: "auto",
        }}
      >
        <div
          style={{
            marginTop: "-35px",
            fontSize: "30px",
            fontWeight: "600",
            marginBottom: "20px",
            color: "white",
            marginLeft: "20px",
          }}
        >
          Settings
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            className="mui-button"
            onClick={() => handleChangeProfileTab()}
            style={{
              marginRight: "40px",
              color: "white",
              marginTop: "0px",
              marginLeft: "20px",
              marginBottom: "10px",
              fontSize: "14px",
              width: "50%",
              textAlign: "center",
            }}
          >
            Profile
          </div>
          <div
            className="mui-button"
            onClick={() => handleChangeTeamTab()}
            style={{
              marginRight: "40px",
              color: "white",
              marginTop: "0px",
              marginBottom: "10px",
              fontSize: "14px",
              width: "50%",
              textAlign: "center",
            }}
          >
            Team
          </div>
          {/* {props.isAdmin ? (
            <div
              className="mui-button"
              onClick={() => handleChangeCustomizationTab()}
              style={{
                marginRight: "40px",
                color: "white",
                marginTop: "0px",
                marginBottom: "10px",
                fontSize: "14px",
              }}
            >
              Customization
            </div>
          ) : null} */}
        </div>
      </div>
      <div
        className="to-do-scroll"
        style={{
          width: "100%",
          boxShadow: "0px 0px 10px rgba(255,255,255,0.1)",
          background: "white",
          borderRadius: "10px",
          paddingTop: "20px",
          overflow:"hidden",
          width: "94%",
          marginLeft: "3%",
        }}
      >
        <div style={{ padding: "0px 20px 0px 20px" }}>
          {profileTab ? profileContent : null}
          {teamTab ? teamContent : null}
          {customizationTab ? customizationContent : null}
        </div>
      </div>
      <Modal
        isOpen={paymentContentFormIsOpen}
        onRequestClose={() => setPaymentContentFormIsOpen(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 10,
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            height: availableUsers.length >= userCount ? "20.5%" : "30%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "white",
            boxShadow: "none",
            borderRadius: "10px",
            border: "none",
            overflow: "hidden",
          },
        }}
      >
        {availableUsers.length >= userCount ? (
          <div
            style={{
              padding: "0px 20px",
              display: "flex",
              alignItems: "center",
              height: "75%",
            }}
          >
            <div style={{ width: "30%" }}>
              <RunningWithErrorsIcon
                style={{ fontSize: "90px", color: primaryColor }}
              />
            </div>
            <div>
              <div
                style={{
                  fontSize: "25px",
                  color: "rgba(0, 0, 0,0.7)",
                  fontWeight: "600",
                }}
              >
                User limit reached.
              </div>
              <div style={{ fontSize: "12px", color: "rgba(0,0,0,0.6)" }}>
                To add more users, please reachout to your admin to upgrade your
                account.
              </div>
            </div>
          </div>
        ) : (
          <div style={{ padding: "0px 20px" }}>
            <div
              style={{
                fontSize: "25px",
                color: "rgba(0, 0, 0,0.7)",
                fontWeight: "600",
              }}
            >
              Inviting your team
            </div>
            <div style={{ fontSize: "12px", color: "rgba(0,0,0,0.6)" }}>
              is now simpler!
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  border: "1px solid rgba(0,0,0,0.2)",
                  color: "rgba(0,0,0,0.6)",
                  padding: "6px",
                  borderRadius: "10px",
                  marginTop: "20px",
                  width: "75%",
                  marginRight: "20px",
                  overflow: "hidden",
                  fontSize: "12px",
                }}
              >
                {domain}
              </div>
              {copied ? (
                <div
                  className="mui-button"
                  style={{
                    padding: "6px 25px",
                    background: "#28993d",
                    borderRadius: "10px",
                    color: "white",
                    marginTop: "20px",
                    width: "18%",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={handleCopyNotClick}
                >
                  <ContentCopyIcon
                    style={{ fontSize: "15px", marginRight: "7px" }}
                  />
                  Copied!
                </div>
              ) : (
                <div
                  className="mui-button"
                  style={{
                    padding: "6px 25px",
                    background: props.primaryColor,
                    borderRadius: "10px",
                    color: "white",
                    marginTop: "20px",
                    width: "18%",
                    textAlign: "center",
                  }}
                  onClick={handleCopyClick}
                >
                  Copy
                </div>
              )}
            </div>
            <div
              style={{
                fontSize: "12px",
                color: "rgba(0,0,0,0.6)",
                marginTop: "20px",
              }}
            >
              Hit copy and share your link to invite new team members.
            </div>
          </div>
        )}
      </Modal>
      <Modal
        isOpen={dnsZoneIsOpen}
        onRequestClose={() => setDnsZoneIsOpen(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30%",
            height: "30%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "white",
            boxShadow: "none",
            borderRadius: "10px",
            border: "none",
            overflow: "hidden",
          },
        }}
      >
        <div style={{ padding: "20px" }}>
          <div
            style={{
              fontSize: "30px",
              color: "rgba(0, 0, 0,0.7)",
              fontWeight: "600",
            }}
          >
            Just one more step.
          </div>
          <div style={{ fontSize: "16px", color: "rgba(0,0,0,0.6)" }}>
            head to your domain's DNS zone and add this records.
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "left",
              width: "92%",
              marginTop: "14px",
              padding: "2px 15px",
            }}
          >
            <div
              style={{
                width: "26%",
                color: "rgba(0,0,0,0.7)",
                fontSize: "16px",
              }}
            >
              Type
            </div>
            <div
              style={{
                width: "26%",
                color: "rgba(0,0,0,0.7)",
                fontSize: "16px",
              }}
            >
              Host
            </div>
            <div
              style={{
                width: "48%",
                color: "rgba(0,0,0,0.7)",
                fontSize: "16px",
              }}
            >
              Value
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "left",
              width: "92%",
              background: `${primaryColor}1A`,
              borderRadius: "12px",
              padding: "5px 15px",
            }}
          >
            <div
              style={{
                width: "26%",
                color: "rgba(0,0,0,0.7)",
                fontSize: "14px",
              }}
            >
              A
            </div>
            <div
              style={{
                width: "26%",
                color: "rgba(0,0,0,0.7)",
                fontSize: "14px",
              }}
            >
              @
            </div>
            <div
              style={{
                width: "48%",
                color: "rgba(0,0,0,0.7)",
                fontSize: "14px",
              }}
            >
              76.76.21.21
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "left",
              width: "92%",
              background: `${primaryColor}1A`,
              borderRadius: "12px",
              marginTop: "10px",
              padding: "5px 15px",
            }}
          >
            <div
              style={{
                width: "26%",
                color: "rgba(0,0,0,0.7)",
                fontSize: "14px",
              }}
            >
              CNAME
            </div>
            <div
              style={{
                width: "26%",
                color: "rgba(0,0,0,0.7)",
                fontSize: "14px",
              }}
            >
              www
            </div>
            <div
              style={{
                width: "48%",
                color: "rgba(0,0,0,0.7)",
                fontSize: "14px",
              }}
            >
              cname.vercel-dns.com.
            </div>
          </div>
          <div
            style={{
              fontSize: "16px",
              color: "rgba(0,0,0,0.6)",
              marginTop: "20px",
            }}
          >
            Once you've added the DNS records, your domain might take 24 hours
            to connect.
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SettingsMobile;
