import React, { useState, useEffect } from "react";
import logo from "./images/CS_Favicon-01.png";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import { fallDown as Menu } from "react-burger-menu";
import Hamburger from "hamburger-react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const ContactUsMobile = () => {
  const navigate = useNavigate();
  const [primaryColor, setPrimaryColor] = useState("#5AB1BA");

  const [isOpen, setIsOpen] = useState(false);

  function handleToggleMenu() {
    setIsOpen(!isOpen);
  }

  return (
    <div id="outer-container" style={{ overflowX: "hidden" }}>
      <Menu
        right
        width={"100%"}
        isOpen={isOpen}
        customCrossIcon={false}
        customBurgerIcon={
          <Hamburger toggled={isOpen} toggle={handleToggleMenu} size={24} />
        }
      >
        <div>
          <div
            onClick={() => {
              navigate("/");
              window.scrollTo(0, 0);
            }}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              style={{
                color: "rgba(0,0,0,0.7)",
                textDecoration: "none",
                padding: "5px 0px",
              }}
            >
              Home
            </div>
            <NavigateNextIcon />
          </div>
          <hr
            style={{
              width: "100%",
              height: "0.01rem",
            }}
          />
          <div
            onClick={() => {
              navigate("/faq");
              window.scrollTo(0, 0);
            }}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              style={{
                color: "rgba(0,0,0,0.7)",
                textDecoration: "none",
                padding: "5px 0px",
              }}
            >
              Frequently Asked Questions
            </div>
            <NavigateNextIcon />
          </div>
          <hr
            style={{
              width: "100%",
              height: "0.01rem",
            }}
          />
          <div
            onClick={() => setIsOpen(false)}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              style={{
                color: "rgba(0,0,0,0.7)",
                textDecoration: "none",
                padding: "5px 0px",
              }}
            >
              Contact Us
            </div>
            <NavigateNextIcon />
          </div>
          <hr
            style={{
              width: "100%",
              height: "0.01rem",
            }}
          />
          <div
            onClick={() => {
              navigate("/termsandcondition");
              window.scrollTo(0, 0);
            }}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              style={{
                color: "rgba(0,0,0,0.7)",
                textDecoration: "none",
                padding: "5px 0px",
              }}
            >
              Terms & Privacy
            </div>
            <NavigateNextIcon />
          </div>
          <div
            style={{
              width: "90%",
              textAlign: "center",
              padding: "15px 20px",
              background: "#d81b5e",
              color: "white",
              marginTop:"35px"
            }}
            onClick={() => {
              navigate("/login");
              window.scrollTo(0, 0);
            }}
          >
            Login
          </div>
          <div
            style={{
              width: "90%",
              textAlign: "center",
              padding: "15px 20px",
              border: "1px solid #d81b5e",
              color: "#d81b5e",
              marginTop:"15px"
            }}
            onClick={() => {
              navigate("/signup");
              window.scrollTo(0, 0);
            }}
          >
            Sign Up
          </div>
        </div>
      </Menu>
      <div style={{ width: "100%" }}>
        <div
          style={{
            background: "white",
            position: "fixed",
            zIndex: "2000",
            top: 0,
            height: "7vh",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
          }}
        >
          <div
            onClick={() => {
              navigate("/");
              window.scrollTo(0, 0);
            }}
            style={{
              height: "6vh",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "1px 0px",
              marginLeft: "20px",
            }}
          >
            <img src={logo} alt="Premelo logo" style={{ width: "50px" }} />
            <p
              style={{
                fontSize: "25px",
                fontWeight: "600",
                color: "black",
                marginTop: "18px",
              }}
            >
              Premelo
            </p>
          </div>
        </div>
        {/* first segment */}
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "40px 0px 40px 0px",
            marginTop: "7vh",
          }}
        >
          <div style={{ width: "80%" }}>
            <div style={{ fontSize: "45px", fontWeight: "600" }}>
              Contact Us
            </div>
            <div style={{ fontSize: "12px", fontWeight: "300", width: "100%" }}>
              Get in touch with us, right here right now. We want to answer your
              queries when you want it, and when you need it. Thus you can now
              directly get in contact with our agents with our chat system.
            </div>
          </div>
        </div>
        <hr style={{ width: "80%", height: "0.01rem solid grey" }} />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "20px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "80%",
            }}
          >
            <div style={{ width: "60%" }}>
              <div style={{ fontSize: "16px", fontWeight: "600" }}>
                Tour our FAQ
              </div>
              <div style={{ fontSize: "11px", fontWeight: "300" }}>
                FAQs are a great way to start to help answer all your questions,
                as they are filled with some of the most frequently asked
                question that are sometimes just very simple.
              </div>
            </div>
            <div>
              <div
                style={{
                  textDecoration: "underline",
                  fontSize: "15px",
                  marginTop: "10px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "300",
                }}
                className="mui-button"
                onClick={() => {
                  navigate("/faq");
                  window.scrollTo(0, 0);
                }}
              >
                Get Started
                <ArrowForwardIcon
                  style={{ marginLeft: "5px", fontSize: "18px" }}
                />
              </div>
            </div>
          </div>
        </div>
        <hr style={{ width: "80%", height: "0.01rem solid grey" }} />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "20px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "80%",
            }}
          >
            <div style={{ width: "60%" }}>
              <div style={{ fontSize: "16px", fontWeight: "600" }}>
                Talk to our agents.
              </div>
              <div style={{ fontSize: "11px", fontWeight: "300" }}>
                Now you can reach our agents with just a simple message, no more
                contact forms or whatnot, our agent typically replies in 5-10
                minutes to all your queries, whether you need help in creating a
                project to as simple as tying your shoe-laces, our agents are
                here for you.
              </div>
            </div>
            <div>
              <div
                style={{
                  textDecoration: "underline",
                  fontSize: "15px",
                  marginTop: "10px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "300",
                }}
                className="mui-button"
                onClick={() => {
                  navigate("/chatwithagent");
                  window.scrollTo(0, 0);
                }}
              >
                Get Started
                <ArrowForwardIcon
                  style={{ marginLeft: "5px", fontSize: "18px" }}
                />
              </div>
            </div>
          </div>
        </div>
        <hr style={{ width: "80%", height: "0.01rem solid grey" }} />
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "20px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "80%",
            }}
          >
            <div style={{ width: "60%" }}>
              <div style={{ fontSize: "16px", fontWeight: "600" }}>Careers</div>
              <div style={{ fontSize: "11px", fontWeight: "300" }}>
                Join our team and be part of a dynamic workplace! With a yearly
                hiring growth of 20% and quarterly hiring, we are committed to
                finding talented individuals to join our team. Check out the
                link on the right for available positions. We welcome fresh
                graduates as we believe new minds bring fresh ideas. Join us and
                be part of a company that values growth and innovation.
              </div>
            </div>
            <div>
              <div
                style={{
                  textDecoration: "underline",
                  fontSize: "15px",
                  marginTop: "10px",
                  display: "flex",
                  alignItems: "center",
                  fontWeight: "300",
                }}
                className="mui-button"
                data-tooltip-id="my-tooltip"
                data-tooltip-html="There are no current hiring positions available,<br /> stick around to find out more."
              >
                No Availability
              </div>
              <Tooltip
                id="my-tooltip"
                place="left"
                style={{ fontSize: "10px" }}
              />
            </div>
          </div>
        </div>
        {/* Website Footer */}
        <hr
          style={{
            width: "100%",
            height: "0.02rem",
            backgroundColor: "black",
            border: "none",
            margin: "10px auto",
          }}
        />
        <div
          style={{
            width: "100%",
            padding: "10px 0px 20px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", width: "30%", marginLeft: "10%" }}>
            <FacebookIcon
              className="mui-button"
              style={{ fontSize: "20px", marginRight: "15px" }}
              onClick={() =>
                (window.location.href = "https://www.facebook.com/premeloapp")
              }
            />
            <InstagramIcon
              className="mui-button"
              style={{ fontSize: "20px", marginRight: "15px" }}
              onClick={() =>
                (window.location.href =
                  "https://www.instagram.com/premelo_app/")
              }
            />
            <YouTubeIcon
              className="mui-button"
              style={{ fontSize: "20px", marginRight: "15px" }}
              onClick={() =>
                (window.location.href = "https://www.youtube.com/@Premelo-Inc")
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "70%",
              fontSize: "16px",
              fontWeight: "300",
              textAlign: "right",
              justifyContent: "flex-end",
              marginRight: "10%",
            }}
          >
            <div>© 2023 Premelo, Inc.</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactUsMobile;
