import React, { useState, useEffect, useRef } from "react";
import "../Builder.css";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import HandymanIcon from "@mui/icons-material/Handyman";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import WidgetsIcon from "@mui/icons-material/Widgets";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import Grid4x4Icon from "@mui/icons-material/Grid4x4";
import imageReference from "../images/imagePlaceholder.png";
import axios from "axios";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import AlignHorizontalCenterIcon from "@mui/icons-material/AlignHorizontalCenter";
import AlignHorizontalRightIcon from "@mui/icons-material/AlignHorizontalRight";
import AlignVerticalTopIcon from "@mui/icons-material/AlignVerticalTop";
import AlignVerticalCenterIcon from "@mui/icons-material/AlignVerticalCenter";
import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import { ChromePicker } from "react-color";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";
import SyncIcon from "@mui/icons-material/Sync";
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import { cloneDeep, isEqual } from "lodash";
import CollectionsIcon from "@mui/icons-material/Collections";
import SmartButtonIcon from "@mui/icons-material/SmartButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import Modal from "react-modal";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ListAltIcon from "@mui/icons-material/ListAlt";
import BadgeIcon from "@mui/icons-material/Badge";
import CategoryIcon from "@mui/icons-material/Category";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import EventIcon from "@mui/icons-material/Event";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { CircularProgress } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import PinDropIcon from "@mui/icons-material/PinDrop";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import CloseIcon from "@mui/icons-material/Close";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { Country, State, City } from "country-state-city";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import * as Icons from "react-icons/fa"; // Replace 'fa' with the desired icon library
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import "leaflet/dist/leaflet.css";
import logo from "../images/CS_Favicon-01.png";
import pexellogo from "../images/pexel_logo.png";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import MenuIcon from "@mui/icons-material/Menu";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import CheckoutForm from "./CheckoutForm";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const DynamicCheckoutElement = ({
  setPageDesigning,
  customerToken,
  setCustomerToken,
  customerAccountDetails,
  setCustomerAccountDetails,
  mobileDesigning,
  dynamicItem,
  pageDesigning,
  selectedElements,
  setNotInputFocus,
  onStyleChange,
  activeWindowStat,
  currency,
  setActiveWindowStat,
  focusElement,
  element,
  elements,
  onDrag,
  onDragStart,
  onDragEnd,
  onResizeStart,
  onResize,
  onResizeEnd,
  onFocus,
  onContentChange,
  onRefSet,
  onInputEditing,
  onInputBlur,
  setEditingInputWithArrow,
}) => {
  const [viewportWidth, setViewportWidth] = useState("1920");
  const [commerceDetails, setCommerceDetails] = useState(null);
  const stripe = useStripe();

  let Country = require("country-state-city").Country;
  let State = require("country-state-city").State;

  const [shippingDetails, setShippingDetails] = useState({
    full_name: "",
    email_address: "",
    phone_number: "",
    address: "",
    zip_code: "",
    state: "",
    country: "",
  });

  const [selectCheckDet, setSelectCheckDet] = useState("");

  const [stripeId, setStripeId] = useState(null);
  const [stripeObject, setStripeObject] = useState(null);

  const urlParams = new URLSearchParams(window.location.search);
  const domainEditing = urlParams.get("domain");

  const handleGetSandC = async () => {
    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/stripe/getcands-editor",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ domain: domainEditing }),
        }
      );
      const json = await response.json();
      setCommerceDetails(json.commerce_array);
      if (json.commerce_array.stripe_account_id) {
        if (json.commerce_array.stripe_account_id.id) {
          setStripeId(json.commerce_array.stripe_account_id.id);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    handleGetSandC();
    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [dragStartPosition, setDragStartPosition] = useState({ x: 0, y: 0 });
  const [editing, setEditing] = useState(false);
  const textareaRef = useRef(null);
  const containerRef = useRef(null);
  const [isResizing, setIsResizing] = useState(false);
  const [focus, setFocus] = useState(false);
  const [resizeStartPosition, setResizeStartPosition] = useState({
    x: 0,
    y: 0,
  });
  const [elementInitialWidth, setElementInitialWidth] = useState("");
  const [elementInitialHeight, setElementInitialHeight] = useState("");
  const [draggingOpacity, setDraggingOpacity] = useState(1);

  const handleDragStart = (event, elementId, touch) => {
    console.log("drag started", event, elementId);
    if (!touch) {
      event.dataTransfer.setData("text/plain", elementId);
      const dragImages = new Image();
      dragImages.src = ""; // Set an empty source
      event.dataTransfer.setDragImage(dragImages, 0, 0);
    }
    setFocus(true);

    const containerRect = containerRef.current.getBoundingClientRect();
    if (touch) {
      console.log("touch exist");
      const offsetX = touch.clientX - containerRect.left;
      const offsetY = touch.clientY - containerRect.top;

      setDragStartPosition({ x: offsetX, y: offsetY });
    } else {
      const offsetX = event.clientX - containerRect.left;
      const offsetY = event.clientY - containerRect.top;

      setDragStartPosition({ x: offsetX, y: offsetY });
    }

    onDragStart(event, elementId, touch);
  };

  const handleResizeStart = (event, touch) => {
    event.stopPropagation();
    setFocus(true);
    console.log("resize started");
    if (!touch) {
      const dragImage = new Image();
      dragImage.src = ""; // Set an empty source
      event.dataTransfer.setDragImage(dragImage, 0, 0);
    }
    setIsResizing(true);
    if (touch) {
      setResizeStartPosition({
        x: touch.clientX,
        y: touch.clientY,
      });
    } else {
      setResizeStartPosition({
        x: event.clientX,
        y: event.clientY,
      });
    }
    onResizeStart();

    if (mobileDesigning) {
      setElementInitialWidth(element.mwidth ? element.mwidth : element.width);
      setElementInitialHeight(
        element.mheight ? element.mheight : element.height
      );
    } else {
      setElementInitialWidth(element.width);
      setElementInitialHeight(element.height);
    }
  };

  const handleResize = (event, touch) => {
    event.stopPropagation();
    if (isResizing) {
      if (mobileDesigning) {
        if (touch) {
          const dx = touch.clientX - resizeStartPosition.x;
          const dy = touch.clientY - resizeStartPosition.y;
          const newWidth = elementInitialWidth + (dx / 400) * 100;
          const newHeight = elementInitialHeight + dy;
          onResize(newWidth, newHeight);
        } else {
          const dx = event.clientX - resizeStartPosition.x;
          const dy = event.clientY - resizeStartPosition.y;
          const newWidth = elementInitialWidth + (dx / 400) * 100;
          const newHeight = elementInitialHeight + dy;
          onResize(newWidth, newHeight);
        }
      } else {
        if (touch) {
          const dx = touch.clientX - resizeStartPosition.x;
          const dy = touch.clientY - resizeStartPosition.y;
          const newWidth = elementInitialWidth + (dx / viewportWidth) * 100;
          const newHeight = elementInitialHeight + dy;
          onResize(newWidth, newHeight);
        } else {
          const dx = event.clientX - resizeStartPosition.x;
          const dy = event.clientY - resizeStartPosition.y;
          const newWidth = elementInitialWidth + (dx / viewportWidth) * 100;
          const newHeight = elementInitialHeight + dy;
          onResize(newWidth, newHeight);
        }
      }
      // console.log("Resizing:", event.clientX); // Log the resizing values
      // console.log("Resizing:", resizeStartPosition.x); // Log the resizing values
      // console.log("Resizing:", newWidth, newHeight);

      // setResizeStartPosition({ x: event.clientX, y: event.clientY });
    }
  };

  const handleResizeX = (event, touch) => {
    event.stopPropagation();
    if (isResizing) {
      if (mobileDesigning) {
        if (touch) {
          const dx = touch.clientX - resizeStartPosition.x;
          const dy = touch.clientY - resizeStartPosition.y;
          console.log(dx, elementInitialWidth);
          const newWidth = elementInitialWidth + (dx / 400) * 100;
          const newHeight = elementInitialHeight;
          onResize(newWidth, newHeight);
        } else {
          const dx = event.clientX - resizeStartPosition.x;
          const dy = event.clientY - resizeStartPosition.y;
          console.log(dx, elementInitialWidth);
          const newWidth = elementInitialWidth + (dx / 400) * 100;
          const newHeight = elementInitialHeight;
          onResize(newWidth, newHeight);
        }
      } else {
        if (touch) {
          const dx = touch.clientX - resizeStartPosition.x;
          const dy = touch.clientY - resizeStartPosition.y;
          console.log(dx, elementInitialWidth);
          const newWidth = elementInitialWidth + (dx / viewportWidth) * 100;
          const newHeight = elementInitialHeight;
          onResize(newWidth, newHeight);
        } else {
          const dx = event.clientX - resizeStartPosition.x;
          const dy = event.clientY - resizeStartPosition.y;
          console.log(dx, elementInitialWidth);
          const newWidth = elementInitialWidth + (dx / viewportWidth) * 100;
          const newHeight = elementInitialHeight;
          onResize(newWidth, newHeight);
        }
      }
    }
  };

  const handleResizeY = (event, touch) => {
    event.stopPropagation();
    if (isResizing) {
      if (mobileDesigning) {
        if (touch) {
          const dx = touch.clientX - resizeStartPosition.x;
          const dy = touch.clientY - resizeStartPosition.y;
          const newWidth = elementInitialWidth;
          const newHeight = elementInitialHeight + dy;
          onResize(newWidth, newHeight);
        } else {
          const dx = event.clientX - resizeStartPosition.x;
          const dy = event.clientY - resizeStartPosition.y;
          const newWidth = elementInitialWidth;
          const newHeight = elementInitialHeight + dy;
          onResize(newWidth, newHeight);
        }
      } else {
        if (touch) {
          const dx = touch.clientX - resizeStartPosition.x;
          const dy = touch.clientY - resizeStartPosition.y;
          const newWidth = elementInitialWidth;
          const newHeight = elementInitialHeight + dy;
          onResize(newWidth, newHeight);
        } else {
          const dx = event.clientX - resizeStartPosition.x;
          const dy = event.clientY - resizeStartPosition.y;
          const newWidth = elementInitialWidth;
          const newHeight = elementInitialHeight + dy;
          onResize(newWidth, newHeight);
        }
      }
    }
  };

  const handleClick = (event) => {
    setFocus(true);
    onFocus(element.id, event);
  };

  useEffect(() => {
    onRefSet(containerRef);
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setFocus(false);
        onFocus(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleResizeEnd = () => {
    setIsResizing(false);
    onResizeEnd();
  };

  useEffect(() => {
    if (editing) {
      textareaRef.current.focus();
    }
  }, [editing]);

  const [primaryColor, setPrimaryColor] = useState(
    Cookies.get("company_color") ?? "#1872d9"
  );

  const stylingChoices = [
    {
      style_group: "Title",
      style_options: [
        {
          style_name: "titleFontFamily",
          style_display: "Font Family",
          style_type: "font",
        },
        {
          style_name: "titleTextAlign",
          style_display: "Text Align",
          style_type: "alignText",
        },
        {
          style_name: "titleFontSize",
          style_display: "Font Size",
          style_type: "pixel",
        },
        {
          style_name: "titleLineHeight",
          style_display: "Line Height",
          style_type: "pixel",
        },
        {
          style_name: "titleLetterSpacing",
          style_display: "Letter Spacing",
          style_type: "pixel",
        },
        {
          style_name: "titleFontWeight",
          style_display: "Font Weight",
          style_type: "fontWeight",
        },
        {
          style_name: "titleFontColor",
          style_display: "Font Color",
          style_type: "color",
        },
        {
          style_name: "titleTextShadow",
          style_display: "Text Shadow",
          style_type: "boxShadow",
        },
      ],
    },
    {
      style_group: "Description",
      style_options: [
        {
          style_name: "descriptionFontFamily",
          style_display: "Font Family",
          style_type: "font",
        },
        {
          style_name: "descriptionTextAlign",
          style_display: "Text Align",
          style_type: "alignText",
        },
        {
          style_name: "descriptionFontSize",
          style_display: "Font Size",
          style_type: "pixel",
        },
        {
          style_name: "descriptionLineHeight",
          style_display: "Line Height",
          style_type: "pixel",
        },
        {
          style_name: "descriptionLetterSpacing",
          style_display: "Letter Spacing",
          style_type: "pixel",
        },
        {
          style_name: "descriptionFontWeight",
          style_display: "Font Weight",
          style_type: "fontWeight",
        },
        {
          style_name: "descriptionFontColor",
          style_display: "Font Color",
          style_type: "color",
        },
        {
          style_name: "descriptionTextShadow",
          style_display: "Text Shadow",
          style_type: "boxShadow",
        },
      ],
    },
    {
      style_group: "Fields",
      style_options: [
        {
          style_name: "fieldFontFamily",
          style_display: "Font Family",
          style_type: "font",
        },
        {
          style_name: "fieldTextAlign",
          style_display: "Text Align",
          style_type: "alignText",
        },
        {
          style_name: "fieldFontSize",
          style_display: "Font Size",
          style_type: "pixel",
        },
        {
          style_name: "fieldLineHeight",
          style_display: "Line Height",
          style_type: "pixel",
        },
        {
          style_name: "fieldLetterSpacing",
          style_display: "Letter Spacing",
          style_type: "pixel",
        },
        {
          style_name: "fieldFontWeight",
          style_display: "Font Weight",
          style_type: "fontWeight",
        },
        {
          style_name: "fieldFontColor",
          style_display: "Font Color",
          style_type: "color",
        },
        {
          style_name: "fieldBorderRadius",
          style_display: "Border Radius",
          style_type: "pixel",
        },
        {
          style_name: "fieldBorderWidth",
          style_display: "Border Width",
          style_type: "pixel",
        },
        {
          style_name: "fieldBorderColor",
          style_display: "Border Color",
          style_type: "color",
        },
        {
          style_name: "fieldTextShadow",
          style_display: "Text Shadow",
          style_type: "boxShadow",
        },
        {
          style_name: "fieldBackgroundColor",
          style_display: "Background Color",
          style_type: "color",
        },
      ],
    },
    {
      style_group: "Buttons",
      style_options: [
        {
          style_name: "buttonFontFamily",
          style_display: "Font Family",
          style_type: "font",
        },
        {
          style_name: "buttonTextAlign",
          style_display: "Text Align",
          style_type: "alignText",
        },
        {
          style_name: "buttonFontSize",
          style_display: "Font Size",
          style_type: "pixel",
        },
        {
          style_name: "buttonLineHeight",
          style_display: "Line Height",
          style_type: "pixel",
        },
        {
          style_name: "buttonLetterSpacing",
          style_display: "Letter Spacing",
          style_type: "pixel",
        },
        {
          style_name: "buttonFontWeight",
          style_display: "Font Weight",
          style_type: "fontWeight",
        },
        {
          style_name: "buttonFontColor",
          style_display: "Font Color",
          style_type: "color",
        },
        {
          style_name: "buttonBoxShadow",
          style_display: "Text Shadow",
          style_type: "boxShadow",
        },
        {
          style_name: "buttonBorderWidth",
          style_display: "Border Width",
          style_type: "pixel",
        },
        {
          style_name: "buttonBorderColor",
          style_display: "Border Color",
          style_type: "color",
        },
        {
          style_name: "buttonBorderRadius",
          style_display: "Border Radius",
          style_type: "pixel",
        },
        {
          style_name: "buttonBackgroundColor",
          style_display: "Background Color",
          style_type: "color",
        },
      ],
    },
  ];

  const [colorState, setColorState] = useState({});
  // Function to update the color state
  const updateColorState = (style_name, color) => {
    setColorState((prevColorState) => ({
      ...prevColorState,
      [style_name]: color,
    }));
  };

  const defaultBoxShadow = {
    horizontal: "-10",
    vertical: "10",
    blur: "10",
    color: "rgba(0,0,0,1)",
  };

  const [boxShadowState, setBoxShadowState] = useState({});

  // Function to update the boxShadow state
  const updateBoxShadowState = (style_name, prop, value) => {
    setBoxShadowState((prevBoxShadowState) => ({
      ...prevBoxShadowState,
      [style_name]: {
        ...prevBoxShadowState[style_name],
        [prop]: value,
      },
    }));
  };

  const [openSections, setOpenSections] = useState(
    new Array(stylingChoices.length).fill(false)
  );

  // Function to toggle the section's open/closed state based on index
  const toggleSection = (index) => {
    const updatedOpenSections = [...openSections];
    updatedOpenSections[index] = !updatedOpenSections[index];
    setOpenSections(updatedOpenSections);
  };

  const [fileUploading, setFileUploading] = useState(false);

  const handleImageUploadElement = async (e, style_name) => {
    setFileUploading(true);
    const file = e.target.files;
    console.log(e.target.files);

    const formData = new FormData();

    for (let i = 0; i < file.length; i++) {
      formData.append("files[]", file[i]);
    }

    try {
      const response = await axios.post(
        "https://ww1.premelo.com/api/upload-image",
        formData
      );
      const imageUrl = response.data.urls;
      console.log(style_name, imageUrl[0]);
      onStyleChange(style_name, imageUrl[0]);
      setFileUploading(false);
    } catch (error) {
      console.log(error);
      setFileUploading(false);
    }
  };

  const handleClickImageInput = () => {
    const fileInput = document.getElementById("imageInputElement");
    fileInput.click();
  };

  const [imageModalStates, setImageModalStates] = useState(false);
  const [imageSearchQuery, setImageSearchQuery] = useState("");
  const [imageSearchResult, setImageSearchResult] = useState([
    {
      id: 1484806,
      src: {
        original:
          "https://images.pexels.com/photos/1484806/pexels-photo-1484806.jpeg",
        large2x:
          "https://images.pexels.com/photos/1484806/pexels-photo-1484806.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1484806/pexels-photo-1484806.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1484806/pexels-photo-1484806.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1484806/pexels-photo-1484806.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1484806/pexels-photo-1484806.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1484806/pexels-photo-1484806.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1484806/pexels-photo-1484806.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 4006576,
      src: {
        original:
          "https://images.pexels.com/photos/4006576/pexels-photo-4006576.jpeg",
        large2x:
          "https://images.pexels.com/photos/4006576/pexels-photo-4006576.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/4006576/pexels-photo-4006576.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/4006576/pexels-photo-4006576.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/4006576/pexels-photo-4006576.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/4006576/pexels-photo-4006576.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/4006576/pexels-photo-4006576.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/4006576/pexels-photo-4006576.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 4148984,
      src: {
        original:
          "https://images.pexels.com/photos/4148984/pexels-photo-4148984.jpeg",
        large2x:
          "https://images.pexels.com/photos/4148984/pexels-photo-4148984.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/4148984/pexels-photo-4148984.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/4148984/pexels-photo-4148984.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/4148984/pexels-photo-4148984.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/4148984/pexels-photo-4148984.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/4148984/pexels-photo-4148984.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/4148984/pexels-photo-4148984.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 4064423,
      src: {
        original:
          "https://images.pexels.com/photos/4064423/pexels-photo-4064423.jpeg",
        large2x:
          "https://images.pexels.com/photos/4064423/pexels-photo-4064423.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/4064423/pexels-photo-4064423.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/4064423/pexels-photo-4064423.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/4064423/pexels-photo-4064423.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/4064423/pexels-photo-4064423.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/4064423/pexels-photo-4064423.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/4064423/pexels-photo-4064423.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 2230015,
      src: {
        original:
          "https://images.pexels.com/photos/2230015/pexels-photo-2230015.jpeg",
        large2x:
          "https://images.pexels.com/photos/2230015/pexels-photo-2230015.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/2230015/pexels-photo-2230015.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/2230015/pexels-photo-2230015.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/2230015/pexels-photo-2230015.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/2230015/pexels-photo-2230015.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/2230015/pexels-photo-2230015.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/2230015/pexels-photo-2230015.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 3628700,
      src: {
        original:
          "https://images.pexels.com/photos/3628700/pexels-photo-3628700.jpeg",
        large2x:
          "https://images.pexels.com/photos/3628700/pexels-photo-3628700.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/3628700/pexels-photo-3628700.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/3628700/pexels-photo-3628700.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/3628700/pexels-photo-3628700.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/3628700/pexels-photo-3628700.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/3628700/pexels-photo-3628700.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/3628700/pexels-photo-3628700.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 3732667,
      src: {
        original:
          "https://images.pexels.com/photos/3732667/pexels-photo-3732667.jpeg",
        large2x:
          "https://images.pexels.com/photos/3732667/pexels-photo-3732667.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/3732667/pexels-photo-3732667.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/3732667/pexels-photo-3732667.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/3732667/pexels-photo-3732667.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/3732667/pexels-photo-3732667.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/3732667/pexels-photo-3732667.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/3732667/pexels-photo-3732667.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1181579,
      src: {
        original:
          "https://images.pexels.com/photos/1181579/pexels-photo-1181579.jpeg",
        large2x:
          "https://images.pexels.com/photos/1181579/pexels-photo-1181579.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1181579/pexels-photo-1181579.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1181579/pexels-photo-1181579.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1181579/pexels-photo-1181579.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1181579/pexels-photo-1181579.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1181579/pexels-photo-1181579.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1181579/pexels-photo-1181579.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1181424,
      src: {
        original:
          "https://images.pexels.com/photos/1181424/pexels-photo-1181424.jpeg",
        large2x:
          "https://images.pexels.com/photos/1181424/pexels-photo-1181424.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1181424/pexels-photo-1181424.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1181424/pexels-photo-1181424.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1181424/pexels-photo-1181424.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1181424/pexels-photo-1181424.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1181424/pexels-photo-1181424.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1181424/pexels-photo-1181424.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 3852204,
      src: {
        original:
          "https://images.pexels.com/photos/3852204/pexels-photo-3852204.jpeg",
        large2x:
          "https://images.pexels.com/photos/3852204/pexels-photo-3852204.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/3852204/pexels-photo-3852204.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/3852204/pexels-photo-3852204.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/3852204/pexels-photo-3852204.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/3852204/pexels-photo-3852204.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/3852204/pexels-photo-3852204.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/3852204/pexels-photo-3852204.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1250643,
      src: {
        original:
          "https://images.pexels.com/photos/1250643/pexels-photo-1250643.jpeg",
        large2x:
          "https://images.pexels.com/photos/1250643/pexels-photo-1250643.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1250643/pexels-photo-1250643.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1250643/pexels-photo-1250643.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1250643/pexels-photo-1250643.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1250643/pexels-photo-1250643.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1250643/pexels-photo-1250643.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1250643/pexels-photo-1250643.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1674666,
      src: {
        original:
          "https://images.pexels.com/photos/1674666/pexels-photo-1674666.jpeg",
        large2x:
          "https://images.pexels.com/photos/1674666/pexels-photo-1674666.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1674666/pexels-photo-1674666.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1674666/pexels-photo-1674666.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1674666/pexels-photo-1674666.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1674666/pexels-photo-1674666.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1674666/pexels-photo-1674666.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1674666/pexels-photo-1674666.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 2064826,
      src: {
        original:
          "https://images.pexels.com/photos/2064826/pexels-photo-2064826.jpeg",
        large2x:
          "https://images.pexels.com/photos/2064826/pexels-photo-2064826.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/2064826/pexels-photo-2064826.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/2064826/pexels-photo-2064826.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/2064826/pexels-photo-2064826.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/2064826/pexels-photo-2064826.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/2064826/pexels-photo-2064826.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/2064826/pexels-photo-2064826.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 6646919,
      src: {
        original:
          "https://images.pexels.com/photos/6646919/pexels-photo-6646919.jpeg",
        large2x:
          "https://images.pexels.com/photos/6646919/pexels-photo-6646919.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/6646919/pexels-photo-6646919.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/6646919/pexels-photo-6646919.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/6646919/pexels-photo-6646919.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/6646919/pexels-photo-6646919.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/6646919/pexels-photo-6646919.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/6646919/pexels-photo-6646919.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 4259140,
      src: {
        original:
          "https://images.pexels.com/photos/4259140/pexels-photo-4259140.jpeg",
        large2x:
          "https://images.pexels.com/photos/4259140/pexels-photo-4259140.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/4259140/pexels-photo-4259140.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/4259140/pexels-photo-4259140.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/4259140/pexels-photo-4259140.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/4259140/pexels-photo-4259140.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/4259140/pexels-photo-4259140.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/4259140/pexels-photo-4259140.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 2853592,
      src: {
        original:
          "https://images.pexels.com/photos/2853592/pexels-photo-2853592.jpeg",
        large2x:
          "https://images.pexels.com/photos/2853592/pexels-photo-2853592.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/2853592/pexels-photo-2853592.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/2853592/pexels-photo-2853592.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/2853592/pexels-photo-2853592.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/2853592/pexels-photo-2853592.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/2853592/pexels-photo-2853592.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/2853592/pexels-photo-2853592.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1486213,
      src: {
        original:
          "https://images.pexels.com/photos/1486213/pexels-photo-1486213.jpeg",
        large2x:
          "https://images.pexels.com/photos/1486213/pexels-photo-1486213.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1486213/pexels-photo-1486213.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1486213/pexels-photo-1486213.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1486213/pexels-photo-1486213.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1486213/pexels-photo-1486213.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1486213/pexels-photo-1486213.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1486213/pexels-photo-1486213.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 4057758,
      src: {
        original:
          "https://images.pexels.com/photos/4057758/pexels-photo-4057758.jpeg",
        large2x:
          "https://images.pexels.com/photos/4057758/pexels-photo-4057758.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/4057758/pexels-photo-4057758.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/4057758/pexels-photo-4057758.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/4057758/pexels-photo-4057758.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/4057758/pexels-photo-4057758.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/4057758/pexels-photo-4057758.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/4057758/pexels-photo-4057758.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 5637733,
      src: {
        original:
          "https://images.pexels.com/photos/5637733/pexels-photo-5637733.jpeg",
        large2x:
          "https://images.pexels.com/photos/5637733/pexels-photo-5637733.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/5637733/pexels-photo-5637733.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/5637733/pexels-photo-5637733.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/5637733/pexels-photo-5637733.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/5637733/pexels-photo-5637733.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/5637733/pexels-photo-5637733.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/5637733/pexels-photo-5637733.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 7330926,
      src: {
        original:
          "https://images.pexels.com/photos/7330926/pexels-photo-7330926.jpeg",
        large2x:
          "https://images.pexels.com/photos/7330926/pexels-photo-7330926.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/7330926/pexels-photo-7330926.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/7330926/pexels-photo-7330926.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/7330926/pexels-photo-7330926.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/7330926/pexels-photo-7330926.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/7330926/pexels-photo-7330926.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/7330926/pexels-photo-7330926.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 7533347,
      src: {
        original:
          "https://images.pexels.com/photos/7533347/pexels-photo-7533347.jpeg",
        large2x:
          "https://images.pexels.com/photos/7533347/pexels-photo-7533347.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/7533347/pexels-photo-7533347.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/7533347/pexels-photo-7533347.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/7533347/pexels-photo-7533347.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/7533347/pexels-photo-7533347.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/7533347/pexels-photo-7533347.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/7533347/pexels-photo-7533347.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 7217963,
      src: {
        original:
          "https://images.pexels.com/photos/7217963/pexels-photo-7217963.jpeg",
        large2x:
          "https://images.pexels.com/photos/7217963/pexels-photo-7217963.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/7217963/pexels-photo-7217963.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/7217963/pexels-photo-7217963.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/7217963/pexels-photo-7217963.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/7217963/pexels-photo-7217963.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/7217963/pexels-photo-7217963.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/7217963/pexels-photo-7217963.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 9037592,
      src: {
        original:
          "https://images.pexels.com/photos/9037592/pexels-photo-9037592.jpeg",
        large2x:
          "https://images.pexels.com/photos/9037592/pexels-photo-9037592.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/9037592/pexels-photo-9037592.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/9037592/pexels-photo-9037592.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/9037592/pexels-photo-9037592.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/9037592/pexels-photo-9037592.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/9037592/pexels-photo-9037592.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/9037592/pexels-photo-9037592.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 12118422,
      src: {
        original:
          "https://images.pexels.com/photos/12118422/pexels-photo-12118422.jpeg",
        large2x:
          "https://images.pexels.com/photos/12118422/pexels-photo-12118422.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/12118422/pexels-photo-12118422.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/12118422/pexels-photo-12118422.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/12118422/pexels-photo-12118422.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/12118422/pexels-photo-12118422.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/12118422/pexels-photo-12118422.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/12118422/pexels-photo-12118422.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16055440,
      src: {
        original:
          "https://images.pexels.com/photos/16055440/pexels-photo-16055440.jpeg",
        large2x:
          "https://images.pexels.com/photos/16055440/pexels-photo-16055440.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16055440/pexels-photo-16055440.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16055440/pexels-photo-16055440.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16055440/pexels-photo-16055440.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16055440/pexels-photo-16055440.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16055440/pexels-photo-16055440.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16055440/pexels-photo-16055440.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 9705768,
      src: {
        original:
          "https://images.pexels.com/photos/9705768/pexels-photo-9705768.jpeg",
        large2x:
          "https://images.pexels.com/photos/9705768/pexels-photo-9705768.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/9705768/pexels-photo-9705768.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/9705768/pexels-photo-9705768.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/9705768/pexels-photo-9705768.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/9705768/pexels-photo-9705768.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/9705768/pexels-photo-9705768.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/9705768/pexels-photo-9705768.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 15212752,
      src: {
        original:
          "https://images.pexels.com/photos/15212752/pexels-photo-15212752.jpeg",
        large2x:
          "https://images.pexels.com/photos/15212752/pexels-photo-15212752.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/15212752/pexels-photo-15212752.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/15212752/pexels-photo-15212752.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/15212752/pexels-photo-15212752.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/15212752/pexels-photo-15212752.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/15212752/pexels-photo-15212752.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/15212752/pexels-photo-15212752.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 12495575,
      src: {
        original:
          "https://images.pexels.com/photos/12495575/pexels-photo-12495575.png",
        large2x:
          "https://images.pexels.com/photos/12495575/pexels-photo-12495575.png?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/12495575/pexels-photo-12495575.png?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/12495575/pexels-photo-12495575.png?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/12495575/pexels-photo-12495575.png?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/12495575/pexels-photo-12495575.png?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/12495575/pexels-photo-12495575.png?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/12495575/pexels-photo-12495575.png?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16849804,
      src: {
        original:
          "https://images.pexels.com/photos/16849804/pexels-photo-16849804.jpeg",
        large2x:
          "https://images.pexels.com/photos/16849804/pexels-photo-16849804.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16849804/pexels-photo-16849804.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16849804/pexels-photo-16849804.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16849804/pexels-photo-16849804.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16849804/pexels-photo-16849804.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16849804/pexels-photo-16849804.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16849804/pexels-photo-16849804.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16756606,
      src: {
        original:
          "https://images.pexels.com/photos/16756606/pexels-photo-16756606.jpeg",
        large2x:
          "https://images.pexels.com/photos/16756606/pexels-photo-16756606.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16756606/pexels-photo-16756606.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16756606/pexels-photo-16756606.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16756606/pexels-photo-16756606.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16756606/pexels-photo-16756606.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16756606/pexels-photo-16756606.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16756606/pexels-photo-16756606.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 7656336,
      src: {
        original:
          "https://images.pexels.com/photos/7656336/pexels-photo-7656336.jpeg",
        large2x:
          "https://images.pexels.com/photos/7656336/pexels-photo-7656336.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/7656336/pexels-photo-7656336.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/7656336/pexels-photo-7656336.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/7656336/pexels-photo-7656336.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/7656336/pexels-photo-7656336.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/7656336/pexels-photo-7656336.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/7656336/pexels-photo-7656336.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16881386,
      src: {
        original:
          "https://images.pexels.com/photos/16881386/pexels-photo-16881386.jpeg",
        large2x:
          "https://images.pexels.com/photos/16881386/pexels-photo-16881386.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16881386/pexels-photo-16881386.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16881386/pexels-photo-16881386.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16881386/pexels-photo-16881386.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16881386/pexels-photo-16881386.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16881386/pexels-photo-16881386.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16881386/pexels-photo-16881386.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16907832,
      src: {
        original:
          "https://images.pexels.com/photos/16907832/pexels-photo-16907832.jpeg",
        large2x:
          "https://images.pexels.com/photos/16907832/pexels-photo-16907832.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16907832/pexels-photo-16907832.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16907832/pexels-photo-16907832.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16907832/pexels-photo-16907832.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16907832/pexels-photo-16907832.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16907832/pexels-photo-16907832.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16907832/pexels-photo-16907832.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16938786,
      src: {
        original:
          "https://images.pexels.com/photos/16938786/pexels-photo-16938786.jpeg",
        large2x:
          "https://images.pexels.com/photos/16938786/pexels-photo-16938786.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16938786/pexels-photo-16938786.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16938786/pexels-photo-16938786.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16938786/pexels-photo-16938786.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16938786/pexels-photo-16938786.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16938786/pexels-photo-16938786.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16938786/pexels-photo-16938786.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16941392,
      src: {
        original:
          "https://images.pexels.com/photos/16941392/pexels-photo-16941392.jpeg",
        large2x:
          "https://images.pexels.com/photos/16941392/pexels-photo-16941392.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16941392/pexels-photo-16941392.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16941392/pexels-photo-16941392.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16941392/pexels-photo-16941392.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16941392/pexels-photo-16941392.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16941392/pexels-photo-16941392.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16941392/pexels-photo-16941392.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16671959,
      src: {
        original:
          "https://images.pexels.com/photos/16671959/pexels-photo-16671959.jpeg",
        large2x:
          "https://images.pexels.com/photos/16671959/pexels-photo-16671959.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16671959/pexels-photo-16671959.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16671959/pexels-photo-16671959.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16671959/pexels-photo-16671959.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16671959/pexels-photo-16671959.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16671959/pexels-photo-16671959.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16671959/pexels-photo-16671959.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16733847,
      src: {
        original:
          "https://images.pexels.com/photos/16733847/pexels-photo-16733847.jpeg",
        large2x:
          "https://images.pexels.com/photos/16733847/pexels-photo-16733847.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16733847/pexels-photo-16733847.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16733847/pexels-photo-16733847.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16733847/pexels-photo-16733847.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16733847/pexels-photo-16733847.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16733847/pexels-photo-16733847.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16733847/pexels-photo-16733847.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16166109,
      src: {
        original:
          "https://images.pexels.com/photos/16166109/pexels-photo-16166109.jpeg",
        large2x:
          "https://images.pexels.com/photos/16166109/pexels-photo-16166109.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16166109/pexels-photo-16166109.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16166109/pexels-photo-16166109.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16166109/pexels-photo-16166109.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16166109/pexels-photo-16166109.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16166109/pexels-photo-16166109.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16166109/pexels-photo-16166109.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16853434,
      src: {
        original:
          "https://images.pexels.com/photos/16853434/pexels-photo-16853434.jpeg",
        large2x:
          "https://images.pexels.com/photos/16853434/pexels-photo-16853434.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16853434/pexels-photo-16853434.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16853434/pexels-photo-16853434.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16853434/pexels-photo-16853434.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16853434/pexels-photo-16853434.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16853434/pexels-photo-16853434.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16853434/pexels-photo-16853434.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16922807,
      src: {
        original:
          "https://images.pexels.com/photos/16922807/pexels-photo-16922807.png",
        large2x:
          "https://images.pexels.com/photos/16922807/pexels-photo-16922807.png?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16922807/pexels-photo-16922807.png?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16922807/pexels-photo-16922807.png?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16922807/pexels-photo-16922807.png?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16922807/pexels-photo-16922807.png?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16922807/pexels-photo-16922807.png?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16922807/pexels-photo-16922807.png?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16958033,
      src: {
        original:
          "https://images.pexels.com/photos/16958033/pexels-photo-16958033.jpeg",
        large2x:
          "https://images.pexels.com/photos/16958033/pexels-photo-16958033.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16958033/pexels-photo-16958033.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16958033/pexels-photo-16958033.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16958033/pexels-photo-16958033.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16958033/pexels-photo-16958033.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16958033/pexels-photo-16958033.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16958033/pexels-photo-16958033.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16977372,
      src: {
        original:
          "https://images.pexels.com/photos/16977372/pexels-photo-16977372.jpeg",
        large2x:
          "https://images.pexels.com/photos/16977372/pexels-photo-16977372.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16977372/pexels-photo-16977372.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16977372/pexels-photo-16977372.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16977372/pexels-photo-16977372.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16977372/pexels-photo-16977372.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16977372/pexels-photo-16977372.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16977372/pexels-photo-16977372.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16765277,
      src: {
        original:
          "https://images.pexels.com/photos/16765277/pexels-photo-16765277.jpeg",
        large2x:
          "https://images.pexels.com/photos/16765277/pexels-photo-16765277.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16765277/pexels-photo-16765277.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16765277/pexels-photo-16765277.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16765277/pexels-photo-16765277.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16765277/pexels-photo-16765277.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16765277/pexels-photo-16765277.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16765277/pexels-photo-16765277.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16462955,
      src: {
        original:
          "https://images.pexels.com/photos/16462955/pexels-photo-16462955.jpeg",
        large2x:
          "https://images.pexels.com/photos/16462955/pexels-photo-16462955.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16462955/pexels-photo-16462955.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16462955/pexels-photo-16462955.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16462955/pexels-photo-16462955.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16462955/pexels-photo-16462955.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16462955/pexels-photo-16462955.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16462955/pexels-photo-16462955.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 837358,
      src: {
        original:
          "https://images.pexels.com/photos/837358/pexels-photo-837358.jpeg",
        large2x:
          "https://images.pexels.com/photos/837358/pexels-photo-837358.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/837358/pexels-photo-837358.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/837358/pexels-photo-837358.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/837358/pexels-photo-837358.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/837358/pexels-photo-837358.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/837358/pexels-photo-837358.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/837358/pexels-photo-837358.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 39866,
      src: {
        original:
          "https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg",
        large2x:
          "https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 445109,
      src: {
        original:
          "https://images.pexels.com/photos/445109/pexels-photo-445109.jpeg",
        large2x:
          "https://images.pexels.com/photos/445109/pexels-photo-445109.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/445109/pexels-photo-445109.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/445109/pexels-photo-445109.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/445109/pexels-photo-445109.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/445109/pexels-photo-445109.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/445109/pexels-photo-445109.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/445109/pexels-photo-445109.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1709003,
      src: {
        original:
          "https://images.pexels.com/photos/1709003/pexels-photo-1709003.jpeg",
        large2x:
          "https://images.pexels.com/photos/1709003/pexels-photo-1709003.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1709003/pexels-photo-1709003.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1709003/pexels-photo-1709003.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1709003/pexels-photo-1709003.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1709003/pexels-photo-1709003.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1709003/pexels-photo-1709003.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1709003/pexels-photo-1709003.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 240561,
      src: {
        original:
          "https://images.pexels.com/photos/240561/pexels-photo-240561.jpeg",
        large2x:
          "https://images.pexels.com/photos/240561/pexels-photo-240561.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/240561/pexels-photo-240561.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/240561/pexels-photo-240561.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/240561/pexels-photo-240561.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/240561/pexels-photo-240561.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/240561/pexels-photo-240561.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/240561/pexels-photo-240561.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 889545,
      src: {
        original:
          "https://images.pexels.com/photos/889545/pexels-photo-889545.jpeg",
        large2x:
          "https://images.pexels.com/photos/889545/pexels-photo-889545.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/889545/pexels-photo-889545.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/889545/pexels-photo-889545.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/889545/pexels-photo-889545.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/889545/pexels-photo-889545.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/889545/pexels-photo-889545.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/889545/pexels-photo-889545.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 699459,
      src: {
        original:
          "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg",
        large2x:
          "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1245055,
      src: {
        original:
          "https://images.pexels.com/photos/1245055/pexels-photo-1245055.jpeg",
        large2x:
          "https://images.pexels.com/photos/1245055/pexels-photo-1245055.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1245055/pexels-photo-1245055.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1245055/pexels-photo-1245055.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1245055/pexels-photo-1245055.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1245055/pexels-photo-1245055.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1245055/pexels-photo-1245055.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1245055/pexels-photo-1245055.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 267961,
      src: {
        original:
          "https://images.pexels.com/photos/267961/pexels-photo-267961.jpeg",
        large2x:
          "https://images.pexels.com/photos/267961/pexels-photo-267961.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/267961/pexels-photo-267961.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/267961/pexels-photo-267961.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/267961/pexels-photo-267961.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/267961/pexels-photo-267961.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/267961/pexels-photo-267961.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/267961/pexels-photo-267961.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 903171,
      src: {
        original:
          "https://images.pexels.com/photos/903171/pexels-photo-903171.jpeg",
        large2x:
          "https://images.pexels.com/photos/903171/pexels-photo-903171.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/903171/pexels-photo-903171.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/903171/pexels-photo-903171.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/903171/pexels-photo-903171.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/903171/pexels-photo-903171.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/903171/pexels-photo-903171.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/903171/pexels-photo-903171.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1087735,
      src: {
        original:
          "https://images.pexels.com/photos/1087735/pexels-photo-1087735.jpeg",
        large2x:
          "https://images.pexels.com/photos/1087735/pexels-photo-1087735.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1087735/pexels-photo-1087735.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1087735/pexels-photo-1087735.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1087735/pexels-photo-1087735.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1087735/pexels-photo-1087735.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1087735/pexels-photo-1087735.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1087735/pexels-photo-1087735.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 746386,
      src: {
        original:
          "https://images.pexels.com/photos/746386/pexels-photo-746386.jpeg",
        large2x:
          "https://images.pexels.com/photos/746386/pexels-photo-746386.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/746386/pexels-photo-746386.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/746386/pexels-photo-746386.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/746386/pexels-photo-746386.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/746386/pexels-photo-746386.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/746386/pexels-photo-746386.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/746386/pexels-photo-746386.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 845457,
      src: {
        original:
          "https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg",
        large2x:
          "https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 34514,
      src: {
        original:
          "https://images.pexels.com/photos/34514/spot-runs-start-la.jpg",
        large2x:
          "https://images.pexels.com/photos/34514/spot-runs-start-la.jpg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/34514/spot-runs-start-la.jpg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/34514/spot-runs-start-la.jpg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/34514/spot-runs-start-la.jpg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/34514/spot-runs-start-la.jpg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/34514/spot-runs-start-la.jpg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/34514/spot-runs-start-la.jpg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1472334,
      src: {
        original:
          "https://images.pexels.com/photos/1472334/pexels-photo-1472334.jpeg",
        large2x:
          "https://images.pexels.com/photos/1472334/pexels-photo-1472334.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1472334/pexels-photo-1472334.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1472334/pexels-photo-1472334.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1472334/pexels-photo-1472334.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1472334/pexels-photo-1472334.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1472334/pexels-photo-1472334.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1472334/pexels-photo-1472334.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 762080,
      src: {
        original:
          "https://images.pexels.com/photos/762080/pexels-photo-762080.jpeg",
        large2x:
          "https://images.pexels.com/photos/762080/pexels-photo-762080.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/762080/pexels-photo-762080.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/762080/pexels-photo-762080.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/762080/pexels-photo-762080.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/762080/pexels-photo-762080.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/762080/pexels-photo-762080.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/762080/pexels-photo-762080.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 762527,
      src: {
        original:
          "https://images.pexels.com/photos/762527/pexels-photo-762527.jpeg",
        large2x:
          "https://images.pexels.com/photos/762527/pexels-photo-762527.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/762527/pexels-photo-762527.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/762527/pexels-photo-762527.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/762527/pexels-photo-762527.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/762527/pexels-photo-762527.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/762527/pexels-photo-762527.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/762527/pexels-photo-762527.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 853151,
      src: {
        original:
          "https://images.pexels.com/photos/853151/pexels-photo-853151.jpeg",
        large2x:
          "https://images.pexels.com/photos/853151/pexels-photo-853151.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/853151/pexels-photo-853151.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/853151/pexels-photo-853151.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/853151/pexels-photo-853151.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/853151/pexels-photo-853151.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/853151/pexels-photo-853151.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/853151/pexels-photo-853151.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 301952,
      src: {
        original:
          "https://images.pexels.com/photos/301952/pexels-photo-301952.jpeg",
        large2x:
          "https://images.pexels.com/photos/301952/pexels-photo-301952.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/301952/pexels-photo-301952.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/301952/pexels-photo-301952.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/301952/pexels-photo-301952.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/301952/pexels-photo-301952.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/301952/pexels-photo-301952.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/301952/pexels-photo-301952.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 2102416,
      src: {
        original:
          "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg",
        large2x:
          "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 53214,
      src: {
        original:
          "https://images.pexels.com/photos/53214/trekking-hiking-group-alpine-53214.jpeg",
        large2x:
          "https://images.pexels.com/photos/53214/trekking-hiking-group-alpine-53214.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/53214/trekking-hiking-group-alpine-53214.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/53214/trekking-hiking-group-alpine-53214.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/53214/trekking-hiking-group-alpine-53214.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/53214/trekking-hiking-group-alpine-53214.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/53214/trekking-hiking-group-alpine-53214.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/53214/trekking-hiking-group-alpine-53214.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 601170,
      src: {
        original:
          "https://images.pexels.com/photos/601170/pexels-photo-601170.jpeg",
        large2x:
          "https://images.pexels.com/photos/601170/pexels-photo-601170.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/601170/pexels-photo-601170.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/601170/pexels-photo-601170.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/601170/pexels-photo-601170.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/601170/pexels-photo-601170.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/601170/pexels-photo-601170.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/601170/pexels-photo-601170.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 307847,
      src: {
        original:
          "https://images.pexels.com/photos/307847/pexels-photo-307847.jpeg",
        large2x:
          "https://images.pexels.com/photos/307847/pexels-photo-307847.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/307847/pexels-photo-307847.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/307847/pexels-photo-307847.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/307847/pexels-photo-307847.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/307847/pexels-photo-307847.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/307847/pexels-photo-307847.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/307847/pexels-photo-307847.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 214576,
      src: {
        original:
          "https://images.pexels.com/photos/214576/pexels-photo-214576.jpeg",
        large2x:
          "https://images.pexels.com/photos/214576/pexels-photo-214576.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/214576/pexels-photo-214576.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/214576/pexels-photo-214576.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/214576/pexels-photo-214576.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/214576/pexels-photo-214576.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/214576/pexels-photo-214576.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/214576/pexels-photo-214576.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1822608,
      src: {
        original:
          "https://images.pexels.com/photos/1822608/pexels-photo-1822608.jpeg",
        large2x:
          "https://images.pexels.com/photos/1822608/pexels-photo-1822608.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1822608/pexels-photo-1822608.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1822608/pexels-photo-1822608.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1822608/pexels-photo-1822608.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1822608/pexels-photo-1822608.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1822608/pexels-photo-1822608.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1822608/pexels-photo-1822608.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 247304,
      src: {
        original:
          "https://images.pexels.com/photos/247304/pexels-photo-247304.jpeg",
        large2x:
          "https://images.pexels.com/photos/247304/pexels-photo-247304.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/247304/pexels-photo-247304.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/247304/pexels-photo-247304.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/247304/pexels-photo-247304.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/247304/pexels-photo-247304.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/247304/pexels-photo-247304.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/247304/pexels-photo-247304.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 460295,
      src: {
        original:
          "https://images.pexels.com/photos/460295/pexels-photo-460295.jpeg",
        large2x:
          "https://images.pexels.com/photos/460295/pexels-photo-460295.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/460295/pexels-photo-460295.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/460295/pexels-photo-460295.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/460295/pexels-photo-460295.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/460295/pexels-photo-460295.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/460295/pexels-photo-460295.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/460295/pexels-photo-460295.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 439818,
      src: {
        original:
          "https://images.pexels.com/photos/439818/pexels-photo-439818.jpeg",
        large2x:
          "https://images.pexels.com/photos/439818/pexels-photo-439818.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/439818/pexels-photo-439818.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/439818/pexels-photo-439818.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/439818/pexels-photo-439818.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/439818/pexels-photo-439818.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/439818/pexels-photo-439818.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/439818/pexels-photo-439818.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1125850,
      src: {
        original:
          "https://images.pexels.com/photos/1125850/pexels-photo-1125850.jpeg",
        large2x:
          "https://images.pexels.com/photos/1125850/pexels-photo-1125850.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1125850/pexels-photo-1125850.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1125850/pexels-photo-1125850.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1125850/pexels-photo-1125850.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1125850/pexels-photo-1125850.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1125850/pexels-photo-1125850.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1125850/pexels-photo-1125850.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 571169,
      src: {
        original:
          "https://images.pexels.com/photos/571169/pexels-photo-571169.jpeg",
        large2x:
          "https://images.pexels.com/photos/571169/pexels-photo-571169.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/571169/pexels-photo-571169.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/571169/pexels-photo-571169.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/571169/pexels-photo-571169.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/571169/pexels-photo-571169.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/571169/pexels-photo-571169.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/571169/pexels-photo-571169.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 927451,
      src: {
        original:
          "https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg",
        large2x:
          "https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1848565,
      src: {
        original:
          "https://images.pexels.com/photos/1848565/pexels-photo-1848565.jpeg",
        large2x:
          "https://images.pexels.com/photos/1848565/pexels-photo-1848565.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1848565/pexels-photo-1848565.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1848565/pexels-photo-1848565.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1848565/pexels-photo-1848565.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1848565/pexels-photo-1848565.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1848565/pexels-photo-1848565.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1848565/pexels-photo-1848565.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 819530,
      src: {
        original:
          "https://images.pexels.com/photos/819530/pexels-photo-819530.jpeg",
        large2x:
          "https://images.pexels.com/photos/819530/pexels-photo-819530.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/819530/pexels-photo-819530.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/819530/pexels-photo-819530.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/819530/pexels-photo-819530.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/819530/pexels-photo-819530.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/819530/pexels-photo-819530.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/819530/pexels-photo-819530.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 886285,
      src: {
        original:
          "https://images.pexels.com/photos/886285/pexels-photo-886285.jpeg",
        large2x:
          "https://images.pexels.com/photos/886285/pexels-photo-886285.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/886285/pexels-photo-886285.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/886285/pexels-photo-886285.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/886285/pexels-photo-886285.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/886285/pexels-photo-886285.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/886285/pexels-photo-886285.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/886285/pexels-photo-886285.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 673649,
      src: {
        original:
          "https://images.pexels.com/photos/673649/pexels-photo-673649.jpeg",
        large2x:
          "https://images.pexels.com/photos/673649/pexels-photo-673649.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/673649/pexels-photo-673649.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/673649/pexels-photo-673649.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/673649/pexels-photo-673649.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/673649/pexels-photo-673649.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/673649/pexels-photo-673649.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/673649/pexels-photo-673649.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 236171,
      src: {
        original:
          "https://images.pexels.com/photos/236171/pexels-photo-236171.jpeg",
        large2x:
          "https://images.pexels.com/photos/236171/pexels-photo-236171.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/236171/pexels-photo-236171.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/236171/pexels-photo-236171.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/236171/pexels-photo-236171.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/236171/pexels-photo-236171.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/236171/pexels-photo-236171.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/236171/pexels-photo-236171.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
  ]);
  const iconLibrary = Icons; // Use the imported icons directly
  const iconNames = Object.keys(iconLibrary);
  const [iconsSearchTerm, setIconSearchTerm] = useState("");
  const handleImageResults = async (e) => {
    e.preventDefault();
    console.log(imageSearchQuery);

    try {
      const response = await fetch(
        `https://api.pexels.com/v1/search/?per_page=80&query=${
          imageSearchQuery !== "" || imageSearchQuery !== null
            ? imageSearchQuery
            : "people"
        }`,
        {
          method: "GET",
          headers: {
            Authorization:
              "shCfvWEafCZBIccDqbbdQS7yiaWunt6NV3aLmMia5qtZcL1cJBkt7WOZ",
          },
        }
      );
      const json = await response.json();
      setImageSearchResult(json.photos);
    } catch (error) {
      console.log(error);
    }
  };
  const DisplayStylingChoice = (stylingChoice) => {
    return (
      <div
        style={{
          width: "100%",
          height: "calc(100% - 70px)",
          overflowY: "auto",
          overflowX: "hidden",
          marginTop: "5px",
        }}
      >
        {stylingChoice.map((groups, index) => (
          <div style={{ width: "100%" }} key={index}>
            <div
              className="mui-button"
              style={{
                padding: "10px",
                cursor: "pointer",
              }}
              onClick={() => toggleSection(index)}
            >
              <div
                style={{
                  fontSize: "15px",
                  border: `1px solid lightgrey`,
                  fontWeight: 600,
                  color: "grey",
                  borderRadius: "5px",
                  position: "relative",
                  overflow: "hidden",
                  background: "rgba(255,255,255,0.5)",
                }}
              >
                <div
                  style={{
                    background: primaryColor,
                    width: "100%",
                    position: "relative",
                    left: 0,
                    top: 0,
                    height: "4px",
                  }}
                />
                <div
                  style={{
                    padding: "10px 20px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: "rgba(0,0,0,0.8)",
                    }}
                  >
                    {groups.style_group}
                  </div>
                  {openSections[index] ? (
                    <RemoveCircleIcon style={{ color: "lightgrey" }} />
                  ) : (
                    <AddCircleIcon style={{ color: "lightgrey" }} />
                  )}{" "}
                </div>
              </div>
            </div>

            {openSections[index] && (
              <div
                style={{
                  padding: "2px 0px",
                  fontSize: "11px",
                  fontWeight: 400,
                  color: "grey",
                }}
              >
                {groups.style_options.map((child, index) => {
                  if (child.style_type === "font") {
                    return (
                      <div
                        key={index}
                        style={{ padding: "5px 30px", marginBottom: "10px" }}
                      >
                        <div style={{ marginBottom: "5px" }}>
                          {child.style_display}
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <select
                            value={
                              element[
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`
                              ] ??
                              element[
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`
                              ]
                            }
                            onClick={() => {
                              setNotInputFocus(false);
                              setEditingInputWithArrow(true);
                            }}
                            style={{
                              borderRadius: "6px",
                              outline: "1px solid lightgrey",
                              border: "none",
                              fontSize: "12px",
                              color: "grey",
                              padding: "5px 20px",
                              marginRight: "5px",
                              width: "60%",
                            }}
                            onChange={(e) => {
                              onStyleChange(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                e.target.value
                              );
                            }}
                          >
                            <option value="Roboto">Roboto</option>
                            <option value="Open Sans">Open Sans</option>
                            <option value="Montserrat">Montserrat</option>
                            <option value="Lato">Lato</option>
                            <option value="Poppins">Poppins</option>
                            <option value="Oswald">Oswald</option>
                            <option value="Raleway">Raleway</option>
                            <option value="Playfair Display">
                              Playfair Display
                            </option>
                            <option value="Ubuntu">Ubuntu</option>
                            <option value="Merriweather">Merriweather</option>
                            <option value="Inconsolata">Inconsolata</option>
                            <option value="PT Serif">PT Serif</option>
                            <option value="Manrope">Manrope</option>
                            <option value="Bebas Neue">Bebas Neue</option>
                            <option value="Libre Baskerville">
                              Libre Baskerville
                            </option>
                            <option value="Josefin Sans">Josefin Sans</option>
                            <option value="Dancing Script">
                              Dancing Script
                            </option>
                            <option value="Ephesis">Ephesis</option>
                            <option value="Teko">Teko</option>
                            <option value="Space Grotesk">Space Grotesk</option>
                            <option value="IBM Plex Mono">IBM Plex Mono</option>
                            <option value="Caveat">Caveat</option>
                            <option value="Shadows Into Light">
                              Shadows Into Light
                            </option>
                            <option value="Playpen Sans">Playpen Sans</option>
                            <option value="Permanent Marker">
                              Permanent Marker
                            </option>
                            <option value="Black Ops One">Black Ops One</option>
                            <option value="Agbalumo">Agbalumo</option>
                            <option value="Glass Antiqua">Glass Antiqua</option>
                            <option value="Russo One">Russo One</option>
                            <option value="Amatic SC">Amatic SC</option>
                            <option value="Cinzel">Cinzel</option>
                            <option value="Orbitron">Orbitron</option>
                            <option value="Kalam">Kalam</option>
                            <option value="Saira Condensed">
                              Saira Condensed
                            </option>
                            <option value="Great Vibes">Great Vibes</option>
                            <option value="Lobster Two">Lobster Two</option>
                            <option value="Yuji Mai">Yuji Mai</option>
                            <option value="Josefin Slab">Josefin Slab</option>
                            <option value="Cookie">Cookie</option>
                            <option value="Tourney">Tourney</option>
                            <option value="Advent Pro">Advent Pro</option>
                            <option value="Macondo">Macondo</option>
                            <option value="Playfair Display SC">
                              Playfair Display SC
                            </option>
                            <option value="Mate SC">Mate SC</option>
                            <option value="Creepster">Creepster</option>
                            <option value="Audiowide">Audiowide</option>
                            <option value="Monoton">Monoton</option>
                            <option value="Fredericka the Great">
                              Fredericka the Great
                            </option>
                            <option value="Julee">Julee</option>
                            <option value="Niconne">Niconne</option>
                            <option value="Rye">Rye</option>
                          </select>
                        </div>
                      </div>
                    );
                  }
                  if (child.style_type === "boolean") {
                    return (
                      <div
                        key={index}
                        style={{ padding: "5px 30px", marginBottom: "10px" }}
                      >
                        <div style={{ marginBottom: "5px", display: "flex" }}>
                          {child.style_display}
                          <input
                            type="checkbox"
                            checked={
                              element[
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`
                              ] ?? false
                            } // Use the checked attribute
                            min={0}
                            max={300}
                            onClick={() => {
                              setNotInputFocus(false);
                              setEditingInputWithArrow(true);
                            }}
                            style={{
                              borderRadius: "6px",
                              outline: "1px solid lightgrey",
                              border: "none",
                              fontSize: "12px",
                              color: "white",
                              padding: "5px 20px",
                              marginLeft: "5px",
                              resize: "none",
                              background: "rgba(50,50,50)",
                              accentColor: primaryColor,
                            }}
                            onChange={(e) => {
                              const isChecked = e.target.checked; // Check if the checkbox is checked
                              onStyleChange(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                isChecked
                              );
                            }}
                            onBlur={(e) => {
                              setNotInputFocus(true);
                              setEditingInputWithArrow(false);
                            }}
                          />
                        </div>
                      </div>
                    );
                  }
                  if (child.style_type === "customCode") {
                    return (
                      <div
                        key={index}
                        style={{ padding: "5px 30px", marginBottom: "10px" }}
                      >
                        <div style={{ marginBottom: "5px" }}>
                          {child.style_display}
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <textarea
                            type="number"
                            value={
                              element[
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`
                              ] ??
                              element[
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`
                              ]
                            }
                            min={0}
                            max={300}
                            onClick={() => {
                              setNotInputFocus(false);
                              setEditingInputWithArrow(true);
                            }}
                            style={{
                              borderRadius: "6px",
                              outline: "1px solid lightgrey",
                              border: "none",
                              fontSize: "12px",
                              color: "white",
                              padding: "5px 20px",
                              marginRight: "5px",
                              width: "calc(100% - 40px)",
                              resize: "none",
                              background: "rgba(50,50,50)",
                              height: "400px",
                            }}
                            onChange={(e) => {
                              onStyleChange(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                e.target.value
                              );
                            }}
                            onBlur={(e) => {
                              setNotInputFocus(true);
                              setEditingInputWithArrow(false);
                            }}
                          />
                        </div>
                      </div>
                    );
                  }
                  if (child.style_type === "chooseIcon") {
                    return (
                      <div
                        key={index}
                        style={{ padding: "5px 30px", marginBottom: "10px" }}
                      >
                        <div style={{ marginBottom: "5px" }}>
                          {child.style_display}
                        </div>
                        <div style={{ marginBottom: "5px" }}>
                          <input
                            placeholder="Search icons"
                            value={iconsSearchTerm}
                            onChange={(e) => setIconSearchTerm(e.target.value)}
                            style={{
                              borderRadius: "6px",
                              outline: "1px solid lightgrey",
                              border: "none",
                              fontSize: "12px",
                              color: "grey",
                              padding: "5px 20px",
                              marginRight: "5px",
                              width: "calc(100% - 40px)",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            height: "200px",
                            overflowY: "scroll",
                          }}
                        >
                          {iconsSearchTerm === ""
                            ? iconNames.slice(0, 150).map((iconName) => {
                                const Icon = iconLibrary[iconName];
                                return (
                                  <div
                                    key={iconName}
                                    onClick={() =>
                                      onStyleChange(
                                        mobileDesigning
                                          ? `m${child.style_name}`
                                          : `${child.style_name}`,
                                        <Icon />
                                      )
                                    }
                                    style={{
                                      cursor: "pointer",
                                      padding: "5px",
                                    }}
                                  >
                                    <Icon style={{ fontSize: 30 }} />
                                  </div>
                                );
                              })
                            : iconNames
                                .filter((iconName) =>
                                  iconName
                                    .toLowerCase()
                                    .includes(iconsSearchTerm.toLowerCase())
                                )
                                .map((iconName) => {
                                  const Icon = iconLibrary[iconName];
                                  return (
                                    <div
                                      key={iconName}
                                      onClick={() =>
                                        onStyleChange(
                                          mobileDesigning
                                            ? `m${child.style_name}`
                                            : `${child.style_name}`,
                                          iconName
                                        )
                                      }
                                      style={{
                                        cursor: "pointer",
                                        padding: "5px",
                                      }}
                                    >
                                      <Icon style={{ fontSize: 30 }} />
                                    </div>
                                  );
                                })}
                        </div>
                      </div>
                    );
                  }
                  if (child.style_type === "alignText") {
                    return (
                      <div
                        key={index}
                        style={{ padding: "5px 30px", marginBottom: "10px" }}
                      >
                        <div style={{ marginBottom: "5px" }}>
                          {child.style_display}
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <AlignHorizontalLeftIcon
                            style={{
                              color: "grey",
                              border: "1px solid lightgrey",
                              borderRadius: "6px",
                              fontSize: "14px",
                              padding: "5px",
                              marginRight: "10px",
                            }}
                            onClick={() =>
                              onStyleChange(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                "flex-start"
                              )
                            }
                          />
                          <AlignHorizontalCenterIcon
                            style={{
                              color: "grey",
                              border: "1px solid lightgrey",
                              borderRadius: "6px",
                              fontSize: "14px",
                              padding: "5px",
                              marginRight: "10px",
                            }}
                            onClick={() =>
                              onStyleChange(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                "center"
                              )
                            }
                          />
                          <AlignHorizontalRightIcon
                            style={{
                              color: "grey",
                              border: "1px solid lightgrey",
                              borderRadius: "6px",
                              fontSize: "14px",
                              padding: "5px",
                              marginRight: "10px",
                            }}
                            onClick={() =>
                              onStyleChange(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                "flex-end"
                              )
                            }
                          />
                        </div>
                      </div>
                    );
                  }
                  if (child.style_type === "videoSrc") {
                    return (
                      <div
                        key={index}
                        style={{ padding: "5px 30px", marginBottom: "10px" }}
                      >
                        <div style={{ marginBottom: "5px" }}>
                          {child.style_display}
                        </div>
                        <input
                          id="imageInputElement"
                          type="file"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            handleImageUploadElement(
                              e,
                              mobileDesigning
                                ? `m${child.style_name}`
                                : `${child.style_name}`
                            );
                          }}
                        />
                        {fileUploading ? (
                          <div
                            style={{
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                              borderRadius: "10px",
                              width: "150px",
                              height: "150px",
                              objectFit: "cover",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress
                              color="primary"
                              size={50}
                              style={{}}
                            />
                          </div>
                        ) : element[
                            mobileDesigning
                              ? `m${child.style_name}`
                              : `${child.style_name}`
                          ] ? (
                          <video
                            autoPlay={true}
                            loop={true}
                            muted
                            onClick={handleClickImageInput}
                            style={{
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                              borderRadius: "10px",
                              width: "150px",
                              height: "150px",
                              objectFit: "cover",
                              cursor: "pointer",
                            }}
                          >
                            <source
                              src={
                                element[
                                  mobileDesigning
                                    ? `m${child.style_name}`
                                    : `${child.style_name}`
                                ]
                              }
                            />
                          </video>
                        ) : (
                          <div
                            onClick={handleClickImageInput}
                            style={{
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                              borderRadius: "10px",
                              width: "150px",
                              height: "150px",
                              objectFit: "cover",
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <FileUploadIcon
                              style={{
                                color: "lightgrey",
                                fontSize: "50px",
                              }}
                            />
                          </div>
                        )}
                      </div>
                    );
                  }
                  if (child.style_type === "imageSrc") {
                    return (
                      <div
                        key={index}
                        style={{ padding: "5px 30px", marginBottom: "10px" }}
                      >
                        <div style={{ marginBottom: "5px" }}>
                          {child.style_display}
                        </div>
                        <input
                          id="imageInputElement"
                          type="file"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            handleImageUploadElement(
                              e,
                              mobileDesigning
                                ? `m${child.style_name}`
                                : `${child.style_name}`
                            );
                          }}
                        />
                        {fileUploading ? (
                          <div
                            style={{
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                              borderRadius: "10px",
                              width: "150px",
                              height: "150px",
                              objectFit: "cover",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress
                              color="primary"
                              size={50}
                              style={{}}
                            />
                          </div>
                        ) : element[
                            mobileDesigning
                              ? `m${child.style_name}`
                              : `${child.style_name}`
                          ] ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={
                                element[
                                  mobileDesigning
                                    ? `m${child.style_name}`
                                    : `${child.style_name}`
                                ]
                              }
                              onClick={handleClickImageInput}
                              style={{
                                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                                borderRadius: "10px",
                                width: "150px",
                                height: "150px",
                                objectFit: "cover",
                                cursor: "pointer",
                              }}
                            />
                            <div
                              style={{
                                padding: "8px 20px",
                                marginLeft: "15px",
                                color: "white",
                                background: primaryColor,
                              }}
                              onClick={() => setImageModalStates(true)}
                            >
                              Choose from library
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              onClick={handleClickImageInput}
                              style={{
                                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                                borderRadius: "10px",
                                width: "150px",
                                height: "150px",
                                objectFit: "cover",
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <FileUploadIcon
                                style={{
                                  color: "lightgrey",
                                  fontSize: "50px",
                                }}
                              />
                            </div>
                            <div
                              style={{
                                padding: "8px 20px",
                                marginLeft: "15px",
                                color: "white",
                                background: primaryColor,
                              }}
                              onClick={() => setImageModalStates(true)}
                            >
                              Choose from library
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  }
                  if (child.style_type === "pixel") {
                    return (
                      <div
                        key={index}
                        style={{ padding: "5px 30px", marginBottom: "10px" }}
                      >
                        <div style={{ marginBottom: "5px" }}>
                          {child.style_display}
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <input
                            type="number"
                            value={
                              element[
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`
                              ] ??
                              element[
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`
                              ]
                            }
                            min={0}
                            max={300}
                            onClick={() => {
                              setNotInputFocus(false);
                              setEditingInputWithArrow(true);
                            }}
                            style={{
                              borderRadius: "6px",
                              outline: "1px solid lightgrey",
                              border: "none",
                              fontSize: "12px",
                              color: "grey",
                              padding: "5px 20px",
                              marginRight: "5px",
                              width: "30%",
                            }}
                            onChange={(e) => {
                              onStyleChange(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                parseFloat(e.target.value)
                              );
                              console.log(parseFloat(0));
                            }}
                            onBlur={(e) => {
                              setNotInputFocus(true);
                              setEditingInputWithArrow(false);
                              if (parseFloat(e.target.value) >= 0.1) {
                                onStyleChange(
                                  mobileDesigning
                                    ? `m${child.style_name}`
                                    : `${child.style_name}`,
                                  parseFloat(e.target.value)
                                );
                              } else {
                                onStyleChange(
                                  mobileDesigning
                                    ? `m${child.style_name}`
                                    : `${child.style_name}`,
                                  0
                                );
                              }
                            }}
                          />
                          px
                        </div>
                      </div>
                    );
                  }
                  if (child.style_type === "percentage") {
                    return (
                      <div
                        key={index}
                        style={{ padding: "5px 30px", marginBottom: "10px" }}
                      >
                        <div style={{ marginBottom: "5px" }}>
                          {child.style_display}
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <input
                            className="range_selector"
                            type="range"
                            value={
                              element[
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`
                              ] ??
                              element[
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`
                              ]
                            }
                            min={0}
                            max={100}
                            onClick={() => {
                              setNotInputFocus(false);
                              setEditingInputWithArrow(true);
                            }}
                            style={{
                              borderRadius: "6px",
                              outline: "none",
                              border: "none",
                              fontSize: "12px",
                              color: "grey",
                              padding: "5px 0px",
                              marginRight: "5px",
                              width: "30%",
                              accentColor: primaryColor,
                            }}
                            onChange={(e) => {
                              if (
                                e.target.value === 0 ||
                                e.target.value === "0" ||
                                e.target.value === null
                              ) {
                                onStyleChange(
                                  mobileDesigning
                                    ? `m${child.style_name}`
                                    : `${child.style_name}`,
                                  0
                                );
                              } else {
                                onStyleChange(
                                  mobileDesigning
                                    ? `m${child.style_name}`
                                    : `${child.style_name}`,
                                  parseFloat(e.target.value)
                                );
                              }
                              console.log(parseFloat(0));
                            }}
                            onBlur={(e) => {
                              setNotInputFocus(true);
                              setEditingInputWithArrow(false);
                              if (parseFloat(e.target.value) >= 0.1) {
                                onStyleChange(
                                  mobileDesigning
                                    ? `m${child.style_name}`
                                    : `${child.style_name}`,
                                  parseFloat(e.target.value)
                                );
                              } else {
                                onStyleChange(
                                  mobileDesigning
                                    ? `m${child.style_name}`
                                    : `${child.style_name}`,
                                  0
                                );
                              }
                            }}
                          />
                          {
                            element[
                              mobileDesigning
                                ? `m${child.style_name}`
                                : `${child.style_name}`
                            ]
                          }
                          %
                        </div>
                      </div>
                    );
                  }
                  if (child.style_type === "fontWeight") {
                    return (
                      <div
                        key={index}
                        style={{ padding: "5px 30px", marginBottom: "10px" }}
                      >
                        <div style={{ marginBottom: "5px" }}>
                          {child.style_display}
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <select
                            type="number"
                            value={
                              element[
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`
                              ] ??
                              element[
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`
                              ]
                            }
                            onClick={() => {
                              setNotInputFocus(false);
                              setEditingInputWithArrow(true);
                            }}
                            onBlur={() => setNotInputFocus(true)}
                            onChange={(e) => {
                              onStyleChange(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                parseFloat(e.target.value)
                              );
                            }}
                            style={{
                              borderRadius: "6px",
                              outline: "1px solid lightgrey",
                              border: "none",
                              fontSize: "12px",
                              color: "grey",
                              padding: "5px 20px",
                              marginRight: "5px",
                              width: "40%",
                            }}
                          >
                            <option value={300}>Light</option>
                            <option value={450}>Normal</option>
                            <option value={600}>Semi-bold</option>
                            <option value={700}>Bold</option>
                            <option value={800}>Ultra-bold</option>
                          </select>
                        </div>
                      </div>
                    );
                  }
                  if (child.style_type === "imageFit") {
                    return (
                      <div
                        key={index}
                        style={{ padding: "5px 30px", marginBottom: "10px" }}
                      >
                        <div style={{ marginBottom: "5px" }}>
                          {child.style_display}
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <select
                            value={
                              element[
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`
                              ] ??
                              element[
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`
                              ]
                            }
                            onClick={() => {
                              setNotInputFocus(false);
                              setEditingInputWithArrow(true);
                            }}
                            onBlur={() => setNotInputFocus(true)}
                            onChange={(e) => {
                              onStyleChange(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                e.target.value
                              );
                            }}
                            style={{
                              borderRadius: "6px",
                              outline: "1px solid lightgrey",
                              border: "none",
                              fontSize: "12px",
                              color: "grey",
                              padding: "5px 20px",
                              marginRight: "5px",
                              width: "40%",
                            }}
                          >
                            <option value={"fill"}>Fill</option>
                            <option value={"cover"}>Cover fit</option>
                            <option value={"contain"}>Contain fit</option>
                          </select>
                        </div>
                      </div>
                    );
                  }
                  if (child.style_type === "color") {
                    const color =
                      colorState[
                        mobileDesigning
                          ? `m${child.style_name}`
                          : `${child.style_name}`
                      ] || "";
                    return (
                      <div
                        key={index}
                        style={{ padding: "5px 30px", marginBottom: "10px" }}
                      >
                        <div style={{ marginBottom: "5px" }}>
                          {child.style_display}
                        </div>
                        <ChromePicker
                          className="my_chrome_picker"
                          color={color}
                          onChange={(color) => {
                            const updatingActiveForm = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                            updateColorState(
                              mobileDesigning
                                ? `m${child.style_name}`
                                : `${child.style_name}`,
                              updatingActiveForm
                            );
                            onStyleChange(
                              mobileDesigning
                                ? `m${child.style_name}`
                                : `${child.style_name}`,
                              updatingActiveForm
                            );
                          }}
                          onChangeComplete={(color) => {
                            const updatingActiveForm = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                            updateColorState(
                              mobileDesigning
                                ? `m${child.style_name}`
                                : `${child.style_name}`,
                              updatingActiveForm
                            );
                            onStyleChange(
                              mobileDesigning
                                ? `m${child.style_name}`
                                : `${child.style_name}`,
                              updatingActiveForm
                            );
                          }}
                        />
                      </div>
                    );
                  }
                  if (child.style_type === "boxShadow") {
                    const boxShadow =
                      boxShadowState[
                        mobileDesigning
                          ? `m${child.style_name}`
                          : `${child.style_name}`
                      ] || defaultBoxShadow;
                    return (
                      <div
                        key={index}
                        style={{ padding: "5px 30px", marginBottom: "10px" }}
                      >
                        <div>{child.style_display}</div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "5px 0px",
                          }}
                        >
                          <input
                            type="number"
                            placeholder="Horizontal"
                            value={boxShadow.horizontal}
                            onClick={() => {
                              setNotInputFocus(false);
                              setEditingInputWithArrow(true);
                            }}
                            onBlur={() => setNotInputFocus(true)}
                            style={{
                              borderRadius: "6px",
                              outline: "1px solid lightgrey",
                              border: "none",
                              fontSize: "12px",
                              color: "grey",
                              padding: "5px 20px",
                              width: "10%",
                              marginRight: "4px",
                            }}
                            onChange={(e) => {
                              updateBoxShadowState(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                "horizontal",
                                e.target.value
                              );
                              // Format and apply boxShadow here
                              const formattedBoxShadow = `${parseFloat(
                                e.target.value
                              )}px ${
                                boxShadow.vertical ? boxShadow.vertical : 10
                              }px ${boxShadow.blur ? boxShadow.blur : 10}px ${
                                boxShadow.color
                                  ? boxShadow.color
                                  : "rgba(0,0,0,0.5)"
                              }`;
                              onStyleChange(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                formattedBoxShadow
                              );
                            }}
                          />{" "}
                          px
                          <input
                            type="number"
                            placeholder="Vertical"
                            value={boxShadow.vertical}
                            onClick={() => {
                              setNotInputFocus(false);
                              setEditingInputWithArrow(true);
                            }}
                            onBlur={() => setNotInputFocus(true)}
                            style={{
                              borderRadius: "6px",
                              outline: "1px solid lightgrey",
                              border: "none",
                              fontSize: "12px",
                              color: "grey",
                              padding: "5px 20px",
                              width: "10%",
                              marginLeft: "8px",
                              marginRight: "4px",
                            }}
                            onChange={(e) => {
                              updateBoxShadowState(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                "vertical",
                                e.target.value
                              );
                              // Format and apply boxShadow here
                              const formattedBoxShadow = `${
                                boxShadow.horizontal ? boxShadow.horizontal : 10
                              }px ${parseFloat(e.target.value)}px ${
                                boxShadow.blur ? boxShadow.blur : 10
                              }px ${
                                boxShadow.color
                                  ? boxShadow.color
                                  : "rgba(0,0,0,0.5)"
                              }`;
                              onStyleChange(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                formattedBoxShadow
                              );
                            }}
                          />{" "}
                          px
                          <input
                            type="number"
                            placeholder="Blur Radius"
                            value={boxShadow.blur}
                            onClick={() => {
                              setNotInputFocus(false);
                              setEditingInputWithArrow(true);
                            }}
                            onBlur={() => setNotInputFocus(true)}
                            style={{
                              borderRadius: "6px",
                              outline: "1px solid lightgrey",
                              border: "none",
                              fontSize: "12px",
                              color: "grey",
                              padding: "5px 20px",
                              width: "10%",
                              marginLeft: "8px",
                              marginRight: "4px",
                            }}
                            onChange={(e) => {
                              updateBoxShadowState(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                "blur",
                                e.target.value
                              );
                              // Format and apply boxShadow here
                              const formattedBoxShadow = `${
                                boxShadow.horizontal ? boxShadow.horizontal : 10
                              }px ${
                                boxShadow.vertical ? boxShadow.vertical : 10
                              }px ${parseFloat(e.target.value)}px ${
                                boxShadow.color
                                  ? boxShadow.color
                                  : "rgba(0,0,0,0.5)"
                              }`;
                              onStyleChange(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                formattedBoxShadow
                              );
                            }}
                          />{" "}
                          px
                        </div>
                        <div>
                          <ChromePicker
                            className="my_chrome_picker"
                            color={boxShadow.color}
                            onChange={(color) => {
                              const updatingActiveForm = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                              updateBoxShadowState(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                "color",
                                updatingActiveForm
                              );
                              // Format and apply boxShadow here
                              const formattedBoxShadow = `${
                                boxShadow.horizontal ? boxShadow.horizontal : 10
                              }px ${
                                boxShadow.vertical ? boxShadow.vertical : 10
                              }px ${
                                boxShadow.blur ? boxShadow.blur : 10
                              }px ${updatingActiveForm}`;
                              onStyleChange(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                formattedBoxShadow
                              );
                            }}
                            onChangeComplete={(color) => {
                              const updatingActiveForm = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                              updateBoxShadowState(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                "color",
                                updatingActiveForm
                              );
                              // Format and apply boxShadow here
                              const formattedBoxShadow = `${
                                boxShadow.horizontal ? boxShadow.horizontal : 10
                              }px ${
                                boxShadow.vertical ? boxShadow.vertical : 10
                              }px ${
                                boxShadow.blur ? boxShadow.blur : 10
                              }px ${updatingActiveForm}`;
                              console.log(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                formattedBoxShadow,
                                boxShadow
                              );
                              onStyleChange(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                formattedBoxShadow
                              );
                            }}
                          />
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const [activeOffset, setActiveOffset] = useState({
    x: 0,
    y: 0,
  });

  const [initialWindow, setInitialWindow] = useState({
    x: 0,
    y: 0,
  });

  const [dragging, setDragging] = useState(false);

  const handleDragStartWindows = (e, touch) => {
    setDragging(true);
    if (touch) {
      setInitialWindow({ x: activeWindowStat.x, y: activeWindowStat.y });
      setActiveOffset({ x: touch.clientX, y: touch.clientY });
    } else {
      setInitialWindow({ x: activeWindowStat.x, y: activeWindowStat.y });
      setActiveOffset({ x: e.clientX, y: e.clientY });
    }
  };

  const handleWindowsDrag = (e, touch) => {
    if (touch) {
      const updatingWindows = { ...activeWindowStat };
      if (e.clientX !== 0) {
        updatingWindows.x = initialWindow.x + touch.clientX - activeOffset.x;
      }
      if (e.clientY !== 0) {
        updatingWindows.y = initialWindow.y + touch.clientY - activeOffset.y;
      }
      setActiveWindowStat(updatingWindows);
      Cookies.set("windows_stat", updatingWindows);
    } else {
      const updatingWindows = { ...activeWindowStat };
      if (e.clientX !== 0) {
        updatingWindows.x = initialWindow.x + e.clientX - activeOffset.x;
      }
      if (e.clientY !== 0) {
        updatingWindows.y = initialWindow.y + e.clientY - activeOffset.y;
      }
      setActiveWindowStat(updatingWindows);
      Cookies.set("windows_stat", updatingWindows);
    }
  };

  const [currentState, setCurrentState] = useState(0);

  const updateStatusByPaymentIntent = async (status) => {
    const paymentIntent = new URLSearchParams(window.location.search).get(
      "payment_intent"
    );
    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/update-payment-intent-status",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paymentIntent: paymentIntent,
            status: status,
          }),
        }
      );
      const json = await response.json();

      console.log(json);
    } catch (error) {
      console.log(error);
    }
  };
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const fetchStripeObject = async () => {
      // If there is no accountId, do not run the loadStripe function.
      if (stripeId) {
        const res = await loadStripe(
          "pk_live_51KaaxAB9rsZCCn0XJcWbdk3MNAufS1spGzsmm50VqAgxqzq9sXkJcwo1YyhYwKOF3aDBMgR80gVEzvYyvjFjy2P100J3QYgnSr",
          {
            stripeAccount: stripeId,
          }
        );
        // When we have got the Stripe object, pass it into our useState.
        setStripeObject(res);

        const clientSecret = new URLSearchParams(window.location.search).get(
          "payment_intent_client_secret"
        );
        console.log(clientSecret);

        if (clientSecret) {
          if (!stripe) {
            return;
          }

          // Retrieve the PaymentIntent
          res.retrievePaymentIntent(clientSecret).then((result) => {
            console.log(result.paymentIntent);
            switch (result.paymentIntent.status) {
              case "succeeded":
                setMessage("Success");
                updateStatusByPaymentIntent("Order Processing");
                setCurrentState(3);
                break;

              case "processing":
                setMessage("Processing");
                setCurrentState(3);
                break;

              case "requires_payment_method":
                // Redirect your user back to your payment page to attempt collecting
                // payment again
                setMessage("Paymentfailed");
                updateStatusByPaymentIntent("Payment Failed");
                setCurrentState(3);
                break;

              default:
                setMessage("Paymentfailed");
                updateStatusByPaymentIntent("Payment Failed");
                setCurrentState(3);
                break;
            }
          });
        }
      }
    };
    fetchStripeObject();
  }, [stripeId]);

  const calculateTotalCart = (products) => {
    let total = 0;

    for (const prod of products) {
      total += parseFloat(prod.quantity) * parseFloat(prod.unit_price);
    }

    return total;
  };

  const handleShippingCalculation = () => {
    if (commerceDetails) {
      if (commerceDetails.shipping) {
        const countryShippingIndex = commerceDetails.shipping.findIndex(
          (shipping) => shipping.name === shippingDetails.country
        );
        if (countryShippingIndex !== -1) {
          const stateShippingIndex = commerceDetails.shipping[
            countryShippingIndex
          ].region.findIndex((state) => state.region === shippingDetails.state);
          console.log(stateShippingIndex);
          if (stateShippingIndex !== -1) {
            if (
              commerceDetails.shipping[countryShippingIndex].region[
                stateShippingIndex
              ].type === "Flat rate"
            ) {
              return parseFloat(
                commerceDetails.shipping[countryShippingIndex].region[
                  stateShippingIndex
                ].value
              );
            } else {
              return commerceDetails.shipping[countryShippingIndex].region[
                stateShippingIndex
              ].type;
            }
          } else {
            if (
              commerceDetails.shipping[countryShippingIndex].type ===
              "Flat rate"
            ) {
              return parseFloat(
                commerceDetails.shipping[countryShippingIndex].value
              );
            } else {
              return commerceDetails.shipping[countryShippingIndex].type;
            }
          }
        } else {
          if (commerceDetails.shipping[0].type === "Flat rate") {
            return parseFloat(commerceDetails.shipping[0].value);
          } else {
            return commerceDetails.shipping[0].type;
          }
        }
        console.log(countryShippingIndex);
      }
      return "Disable purchase";
    }
  };

  const [clientSecret, setClientSecret] = useState("");

  const appearance = {
    theme: "stripe",
  };

  const options = {
    // pass the client secret from the previous step
    clientSecret: clientSecret,
    // externalPaymentMethodTypes: [ 'external_grabpay_later']
  };
  // const [stripePromise, setStripePromise] = useState(loadStripe(
  //   "pk_live_51KaaxAB9rsZCCn0XJcWbdk3MNAufS1spGzsmm50VqAgxqzq9sXkJcwo1YyhYwKOF3aDBMgR80gVEzvYyvjFjy2P100J3QYgnSr",
  //   {
  //     stripeAccount: commerceDetails
  //       ? commerceDetails.stripe_account_id.id
  //       : "",
  //   }))

  useEffect(() => {
    if (currentState === 2) {
      if (handleShippingCalculation() !== "Disable purchase") {
        const value =
          handleShippingCalculation() === "Free shipping"
            ? parseFloat(calculateTotalCart(customerAccountDetails.cart))
            : handleShippingCalculation() === "Disable purchase"
            ? "Store doesn't ship to this country"
            : parseFloat(
                calculateTotalCart(customerAccountDetails.cart) +
                  handleShippingCalculation()
              );

        const handleCreatePaymentIntent = async () => {
          try {
            const response = await fetch(
              "https://ww1.premelo.com/api/stripe/create-payment-intent",
              {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                  amount: parseFloat(value) * 100,
                  currency: commerceDetails.currency.currency,
                  domain: domainEditing,
                  customerDetails: JSON.stringify(customerAccountDetails),
                  shippingDetails: JSON.stringify(shippingDetails),
                }),
              }
            );
            const json = await response.json();
            setClientSecret(json.clientSecret);
          } catch (error) {
            console.log(error);
          }
        };

        handleCreatePaymentIntent();
      }
    }
  }, [currentState]);

  const [loginState, setLoginState] = useState(false);
  const [userDetails, setUserDetails] = useState({
    full_name: "",
    email: "",
    password: "",
    domain_name: "www.hiveswift.com",
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [view, setView] = useState("signup");

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      setErrorMessage(null);
      const response = await fetch(
        "https://ww1.premelo.com/api/customer/register",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userDetails),
        }
      );
      if (response.status === 200) {
        const json = await response.json;
        console.log(json);
        setErrorMessage(null);
        setView("login");
      } else if (response.status === 404) {
        console.log("Unauthorized - Invalid email or password");
        setErrorMessage("Email has already been used");
        // You can handle unauthorized access here, such as displaying an error message.
      } else {
        console.log("An error occurred.");
        setErrorMessage("Email has already been used");
        // Handle other response statuses as needed.
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      setErrorMessage(null);
      const response = await fetch(
        "https://ww1.premelo.com/api/customer/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userDetails),
        }
      );
      if (response.status === 200) {
        const json = await response.json();
        console.log(json);
        Cookies.set("customer_token", json.token, { expires: 365 }); // store token in cookies
        if (json.token) {
          setCustomerToken(json.token);
          setErrorMessage(null);
          setLoginState(false);
        }
      } else if (response.status === 401) {
        console.log("Unauthorized - Invalid email or password");
        setErrorMessage("Invalid email or password");
        // You can handle unauthorized access here, such as displaying an error message.
      } else {
        console.log("An error occurred.");
        setErrorMessage("Invalid email or password");
        // Handle other response statuses as needed.
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateCart = async (cartItems) => {
    if (customerToken) {
      try {
        const response = await fetch(
          "https://ww1.premelo.com/api/customer/update-customer-cart",
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${customerToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ cart: cartItems }),
          }
        );
        const json = await response.json();
        console.log(json);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div
      className="text-element builder-element p-checkout"
      style={{
        // border: focusElement === element.id ? "1px solid #5e86c4" : "none",
        // opacity: draggingOpacity,
        position: "absolute",
        left: mobileDesigning
          ? element.position.mx
            ? `${element.position.mx}%`
            : `${element.position.x}%`
          : `${element.position.x}%`,
        top: mobileDesigning
          ? element.position.my
            ? element.position.my
            : element.position.y
          : element.position.y,
        zIndex: selectedElements.includes(element.id)
          ? 501
          : focusElement === element.id
          ? 501
          : 499,
        width: mobileDesigning
          ? element.mwidth
            ? (element.mwidth * 400) / 100
            : (element.width * 400) / 100
          : (element.width * viewportWidth) / 100,
        height: mobileDesigning
          ? element.mheight
            ? element.mheight
            : element.height
          : element.height,
        display: null,
        border: selectedElements.includes(element.id)
          ? "1px solid #5e86c4"
          : focusElement === element.id
          ? "1px solid #5e86c4"
          : "none",
      }}
      onClick={(event) => {
        event.stopPropagation(); // Prevent event propagation to section div
        // console.log("click with stop propogation")
        console.log(focusElement === element.id);
        handleClick(event);
      }}
      ref={containerRef}
      // onClick={() => setIsResizing(true)}
    >
      {focus ? (
        <div
          className="pop_up"
          style={{
            top: activeWindowStat.y,
            left: activeWindowStat.x,
            boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
            background: "rgba(255,255,255,0.7)",
            backdropFilter: "blur(15px)",
            transform: dragging ? "scale(1.02)" : "scale(1)",
            opacity: dragging ? 0.8 : 1,
            transition: "0.3s transform ease-in-out",
          }}
        >
          <div
            draggable
            onDragStart={(e) => handleDragStartWindows(e)}
            onDrag={(e) => handleWindowsDrag(e)}
            onTouchStart={(e) => {
              const touch = e.touches[0];
              handleDragStartWindows(e, touch);
            }}
            onTouchMove={(e) => {
              const touch = e.touches[0];
              handleWindowsDrag(e, touch);
            }}
            onDragOver={(event) => {
              event.preventDefault(); // Prevent "no drop" cursor
            }}
            onDragEnd={() => setDragging(false)}
            onTouchEnd={() => setDragging(false)}
            style={{
              touchAction: "none",
              height: "60px",
              width: "100%",
              borderRadius: "5px 5px 0px 0px",
              overflow: "hidden",
              borderBottom: "1px solid lightgrey",
              background: "rgba(255,255,255,0.5)",
            }}
          >
            <div
              style={{
                height: "5px",
                width: "100%",
                background: primaryColor,
              }}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "55px",
                cursor: "move",
              }}
            >
              <div
                style={{
                  margin: "0px 12px",
                  fontSize: "17px",
                  color: "rgba(0,0,0,0.8)",
                  fontWeight: 700,
                }}
              >
                Styling Drawer
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  style={{
                    background: "rgba(100,100,100,0.5)",
                    width: "9px",
                    height: "9px",
                    borderRadius: "10px",
                    marginRight: "7px",
                  }}
                />
                <div
                  style={{
                    background: "rgba(100,100,100,0.5)",
                    width: "9px",
                    height: "9px",
                    borderRadius: "10px",
                    marginRight: "7px",
                  }}
                />
                <div
                  style={{
                    background: "grey",
                    width: "9px",
                    height: "9px",
                    borderRadius: "10px",
                    marginRight: "14px",
                  }}
                />
              </div>
            </div>
          </div>
          {DisplayStylingChoice(stylingChoices)}
        </div>
      ) : null}
      <div
        draggable
        onDrag={(event) => onDrag(event, element.id, dragStartPosition)}
        onDragStart={(event) => {
          handleDragStart(event, element.id);
          setDraggingOpacity(0.001);
        }}
        onClick={() => setEditingInputWithArrow(false)}
        onDragEnd={() => {
          onDragEnd();
          setDraggingOpacity(1);
        }}
        onTouchStart={(event) => {
          const touch = event.touches[0];
          handleDragStart(event, element.id, touch);
          setDraggingOpacity(0.001);
        }}
        onTouchMove={(event) => {
          const touch = event.touches[0];
          onDrag(event, element.id, dragStartPosition, touch);
        }}
        onTouchEnd={() => {
          onDragEnd();
          setDraggingOpacity(1);
        }}
        style={{
          touchAction: "none",
          width: mobileDesigning
            ? element.mwidth
              ? (element.mwidth * 400) / 100
              : (element.width * 400) / 100
            : (element.width * viewportWidth) / 100,
          height: mobileDesigning
            ? element.mheight
              ? element.mheight
              : element.height
            : element.height,
          margin: "0px",
        }}
        className="builder-display p-checkout-holder"
      >
        {loginState ? (
          view === "signup" ? (
            <div
              className="p-checkout-signup-holder"
              style={{
                display: "flex",
                alignItems: "center",
                paddingTop: "30px",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div
                className="p-checkout-signup-title"
                style={{
                  textAlign:
                    (mobileDesigning
                      ? element.mtitleTextAlign || "center"
                      : element.titleTextAlign || "center") === "flex-start"
                      ? "left"
                      : (mobileDesigning
                          ? element.mtitleTextAlign || "center"
                          : element.titleTextAlign || "center") === "flex-end"
                      ? "right"
                      : "center",

                  fontFamily: mobileDesigning
                    ? element.mtitleFontFamily ||
                      element.titleFontFamily ||
                      "Roboto"
                    : element.titleFontFamily || "Roboto",

                  fontSize: mobileDesigning
                    ? element.mtitleFontSize || element.titleFontSize || 30
                    : element.titleFontSize || 30,

                  fontWeight: mobileDesigning
                    ? element.mtitleFontWeight || element.titleFontWeight || 700
                    : element.titleFontWeight || 700,

                  lineHeight: `${
                    mobileDesigning
                      ? element.mtitleLineHeight ||
                        element.titleLineHeight ||
                        (mobileDesigning
                          ? element.mtitleFontSize ||
                            element.titleFontSize ||
                            20
                          : element.titleFontSize || 20)
                      : element.titleLineHeight ||
                        (mobileDesigning
                          ? element.mtitleFontSize ||
                            element.titleFontSize ||
                            20
                          : element.titleFontSize || 20)
                  }px`,

                  letterSpacing: `${
                    mobileDesigning
                      ? element.mtitleLetterSpacing ||
                        element.titleLetterSpacing ||
                        0
                      : element.titleLetterSpacing || 0
                  }px`,

                  color: mobileDesigning
                    ? element.mtitleFontColor ||
                      element.titleFontColor ||
                      "rgba(0,0,0,0.8)"
                    : element.titleFontColor || "rgba(0,0,0,0.8)",

                  textShadow: mobileDesigning
                    ? element.mtitleTextShadow ||
                      element.titleTextShadow ||
                      "0px 0px 0px rgba(0,0,0,0.8)"
                    : element.titleTextShadow || "0px 0px 0px rgba(0,0,0,0.8)",
                }}
              >
                Welcome
              </div>
              <div
                className="p-checkout-login-field p-checkout-login-field-name"
                style={{ width: "40%" }}
              >
                <input
                  className="p-checkout-login-field-input p-checkout-login-field-input-name"
                  placeholder="Name"
                  value={userDetails.full_name}
                  onChange={(e) =>
                    setUserDetails({
                      ...userDetails,
                      full_name: e.target.value,
                    })
                  }
                  style={{
                    width: "calc(100% - 30px)",
                    padding: "8px 15px",
                    border: "none",
                    marginTop: "20px",
                    textAlign:
                      (mobileDesigning
                        ? element.mfieldTextAlign || "center"
                        : element.fieldTextAlign || "center") === "flex-start"
                        ? "left"
                        : (mobileDesigning
                            ? element.mfieldTextAlign || "center"
                            : element.fieldTextAlign || "center") === "flex-end"
                        ? "right"
                        : "center",

                        fontFamily: mobileDesigning
                          ? element.mfieldFontFamily ||
                            element.fieldFontFamily ||
                            "Roboto"
                          : element.fieldFontFamily || "Roboto",

                    fontSize: mobileDesigning
                      ? element.mfieldFontSize || element.fieldFontSize || 14
                      : element.fieldFontSize || 14,

                    fontWeight: mobileDesigning
                      ? element.mfieldFontWeight ||
                        element.fieldFontWeight ||
                        400
                      : element.fieldFontWeight || 400,

                    lineHeight: `${
                      mobileDesigning
                        ? element.mfieldLineHeight ||
                          element.fieldLineHeight ||
                          (mobileDesigning
                            ? element.mfieldFontSize ||
                              element.fieldFontSize ||
                              14
                            : element.fieldFontSize || 14)
                        : element.fieldLineHeight ||
                          (mobileDesigning
                            ? element.mfieldFontSize ||
                              element.fieldFontSize ||
                              14
                            : element.fieldFontSize || 14)
                    }px`,

                    letterSpacing: `${
                      mobileDesigning
                        ? element.mfieldLetterSpacing ||
                          element.fieldLetterSpacing ||
                          0
                        : element.fieldLetterSpacing || 0
                    }px`,

                    color: mobileDesigning
                      ? element.mfieldFontColor ||
                        element.fieldFontColor ||
                        "rgba(0,0,0,0.8)"
                      : element.fieldFontColor || "rgba(0,0,0,0.8)",

                    outline: `${
                      mobileDesigning
                        ? element.mfieldBorderWidth ||
                          element.fieldBorderWidth ||
                          1
                        : element.fieldBorderWidth || 1
                    }px solid ${
                      mobileDesigning
                        ? element.mfieldBorderColor ||
                          element.fieldBorderColor ||
                          "grey"
                        : element.fieldBorderColor || "grey"
                    }`,

                    borderRadius: mobileDesigning
                      ? element.mfieldBorderRadius
                        ? `${element.mfieldBorderRadius}px`
                        : element.fieldBorderRadius
                        ? `${element.fieldBorderRadius}px`
                        : 0
                      : element.fieldBorderRadius
                      ? `${element.fieldBorderRadius}px`
                      : 0,

                    boxShadow: mobileDesigning
                      ? element.mfieldTextShadow ||
                        element.fieldTextShadow ||
                        "0px 0px 0px rgba(0,0,0,0.8)"
                      : element.fieldTextShadow ||
                        "0px 0px 0px rgba(0,0,0,0.8)",

                    background: mobileDesigning
                      ? element.mfieldBackgroundColor ||
                        element.fieldBackgroundColor ||
                        "rgba(0,0,0,0)"
                      : element.fieldBackgroundColor || "rgba(0,0,0,0)",
                  }}
                />
              </div>
              <div
                className="p-checkout-login-field p-checkout-login-field-email"
                style={{ width: "40%" }}
              >
                <input
                  className="p-checkout-login-field-input p-checkout-login-field-input-email"
                  placeholder="Email"
                  value={userDetails.email}
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, email: e.target.value })
                  }
                  style={{
                    width: "calc(100% - 30px)",
                    padding: "8px 15px",
                    border: "none",
                    marginTop: "10px",
                    textAlign:
                      (mobileDesigning
                        ? element.mfieldTextAlign || "center"
                        : element.fieldTextAlign || "center") === "flex-start"
                        ? "left"
                        : (mobileDesigning
                            ? element.mfieldTextAlign || "center"
                            : element.fieldTextAlign || "center") === "flex-end"
                        ? "right"
                        : "center",

                        fontFamily: mobileDesigning
                          ? element.mfieldFontFamily ||
                            element.fieldFontFamily ||
                            "Roboto"
                          : element.fieldFontFamily || "Roboto",

                    fontSize: mobileDesigning
                      ? element.mfieldFontSize || element.fieldFontSize || 14
                      : element.fieldFontSize || 14,

                    fontWeight: mobileDesigning
                      ? element.mfieldFontWeight ||
                        element.fieldFontWeight ||
                        400
                      : element.fieldFontWeight || 400,

                    lineHeight: `${
                      mobileDesigning
                        ? element.mfieldLineHeight ||
                          element.fieldLineHeight ||
                          (mobileDesigning
                            ? element.mfieldFontSize ||
                              element.fieldFontSize ||
                              14
                            : element.fieldFontSize || 14)
                        : element.fieldLineHeight ||
                          (mobileDesigning
                            ? element.mfieldFontSize ||
                              element.fieldFontSize ||
                              14
                            : element.fieldFontSize || 14)
                    }px`,

                    letterSpacing: `${
                      mobileDesigning
                        ? element.mfieldLetterSpacing ||
                          element.fieldLetterSpacing ||
                          0
                        : element.fieldLetterSpacing || 0
                    }px`,

                    color: mobileDesigning
                      ? element.mfieldFontColor ||
                        element.fieldFontColor ||
                        "rgba(0,0,0,0.8)"
                      : element.fieldFontColor || "rgba(0,0,0,0.8)",

                    outline: `${
                      mobileDesigning
                        ? element.mfieldBorderWidth ||
                          element.fieldBorderWidth ||
                          1
                        : element.fieldBorderWidth || 1
                    }px solid ${
                      mobileDesigning
                        ? element.mfieldBorderColor ||
                          element.fieldBorderColor ||
                          "grey"
                        : element.fieldBorderColor || "grey"
                    }`,

                    borderRadius: mobileDesigning
                      ? element.mfieldBorderRadius
                        ? `${element.mfieldBorderRadius}px`
                        : element.fieldBorderRadius
                        ? `${element.fieldBorderRadius}px`
                        : 0
                      : element.fieldBorderRadius
                      ? `${element.fieldBorderRadius}px`
                      : 0,

                    boxShadow: mobileDesigning
                      ? element.mfieldTextShadow ||
                        element.fieldTextShadow ||
                        "0px 0px 0px rgba(0,0,0,0.8)"
                      : element.fieldTextShadow ||
                        "0px 0px 0px rgba(0,0,0,0.8)",

                    background: mobileDesigning
                      ? element.mfieldBackgroundColor ||
                        element.fieldBackgroundColor ||
                        "rgba(0,0,0,0)"
                      : element.fieldBackgroundColor || "rgba(0,0,0,0)",
                  }}
                />
              </div>
              <div
                className="p-checkout-login-field p-checkout-login-field-password"
                style={{ width: "40%" }}
              >
                <input
                  className="p-checkout-login-field-input p-checkout-login-field-input-password"
                  placeholder="Password"
                  type="password"
                  value={userDetails.password}
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, password: e.target.value })
                  }
                  style={{
                    width: "calc(100% - 30px)",
                    padding: "8px 15px",
                    border: "none",
                    marginTop: "10px",
                    textAlign:
                      (mobileDesigning
                        ? element.mfieldTextAlign || "center"
                        : element.fieldTextAlign || "center") === "flex-start"
                        ? "left"
                        : (mobileDesigning
                            ? element.mfieldTextAlign || "center"
                            : element.fieldTextAlign || "center") === "flex-end"
                        ? "right"
                        : "center",

                        fontFamily: mobileDesigning
                          ? element.mfieldFontFamily ||
                            element.fieldFontFamily ||
                            "Roboto"
                          : element.fieldFontFamily || "Roboto",

                    fontSize: mobileDesigning
                      ? element.mfieldFontSize || element.fieldFontSize || 14
                      : element.fieldFontSize || 14,

                    fontWeight: mobileDesigning
                      ? element.mfieldFontWeight ||
                        element.fieldFontWeight ||
                        400
                      : element.fieldFontWeight || 400,

                    lineHeight: `${
                      mobileDesigning
                        ? element.mfieldLineHeight ||
                          element.fieldLineHeight ||
                          (mobileDesigning
                            ? element.mfieldFontSize ||
                              element.fieldFontSize ||
                              14
                            : element.fieldFontSize || 14)
                        : element.fieldLineHeight ||
                          (mobileDesigning
                            ? element.mfieldFontSize ||
                              element.fieldFontSize ||
                              14
                            : element.fieldFontSize || 14)
                    }px`,

                    letterSpacing: `${
                      mobileDesigning
                        ? element.mfieldLetterSpacing ||
                          element.fieldLetterSpacing ||
                          0
                        : element.fieldLetterSpacing || 0
                    }px`,

                    color: mobileDesigning
                      ? element.mfieldFontColor ||
                        element.fieldFontColor ||
                        "rgba(0,0,0,0.8)"
                      : element.fieldFontColor || "rgba(0,0,0,0.8)",

                    outline: `${
                      mobileDesigning
                        ? element.mfieldBorderWidth ||
                          element.fieldBorderWidth ||
                          1
                        : element.fieldBorderWidth || 1
                    }px solid ${
                      mobileDesigning
                        ? element.mfieldBorderColor ||
                          element.fieldBorderColor ||
                          "grey"
                        : element.fieldBorderColor || "grey"
                    }`,

                    borderRadius: mobileDesigning
                      ? element.mfieldBorderRadius
                        ? `${element.mfieldBorderRadius}px`
                        : element.fieldBorderRadius
                        ? `${element.fieldBorderRadius}px`
                        : 0
                      : element.fieldBorderRadius
                      ? `${element.fieldBorderRadius}px`
                      : 0,

                    boxShadow: mobileDesigning
                      ? element.mfieldTextShadow ||
                        element.fieldTextShadow ||
                        "0px 0px 0px rgba(0,0,0,0.8)"
                      : element.fieldTextShadow ||
                        "0px 0px 0px rgba(0,0,0,0.8)",

                    background: mobileDesigning
                      ? element.mfieldBackgroundColor ||
                        element.fieldBackgroundColor ||
                        "rgba(0,0,0,0)"
                      : element.fieldBackgroundColor || "rgba(0,0,0,0)",
                  }}
                />
              </div>
              {errorMessage ? (
                <div
                  className="p-checkout-signup-error"
                  style={{ color: "red", marginTop: "10px", fontSize: "11px" }}
                >
                  {errorMessage ? errorMessage : null}
                </div>
              ) : null}
              <div className="p-checkout-signup-button-holder">
                <button
                  className="p-checkout-signup-button"
                  onClick={(e) => handleRegister(e)}
                  style={{
                    textAlign:
                      (mobileDesigning
                        ? element.mbuttonTextAlign || "center"
                        : element.buttonTextAlign || "center") === "flex-start"
                        ? "left"
                        : (mobileDesigning
                            ? element.mbuttonTextAlign || "center"
                            : element.buttonTextAlign || "center") ===
                          "flex-end"
                        ? "right"
                        : "center",

                        fontFamily: mobileDesigning
                          ? element.mbuttonFontFamily ||
                            element.buttonFontFamily ||
                            "Roboto"
                          : element.buttonFontFamily || "Roboto",

                    fontSize: mobileDesigning
                      ? element.mbuttonFontSize || element.buttonFontSize || 14
                      : element.buttonFontSize || 14,

                    fontWeight: mobileDesigning
                      ? element.mbuttonFontWeight ||
                        element.buttonFontWeight ||
                        700
                      : element.buttonFontWeight || 700,

                    lineHeight: `${
                      mobileDesigning
                        ? element.mbuttonLineHeight ||
                          element.buttonLineHeight ||
                          (mobileDesigning
                            ? element.mbuttonFontSize ||
                              element.buttonFontSize ||
                              14
                            : element.buttonFontSize || 14)
                        : element.buttonLineHeight ||
                          (mobileDesigning
                            ? element.mbuttonFontSize ||
                              element.buttonFontSize ||
                              14
                            : element.buttonFontSize || 14)
                    }px`,

                    letterSpacing: `${
                      mobileDesigning
                        ? element.mbuttonLetterSpacing ||
                          element.buttonLetterSpacing ||
                          0
                        : element.buttonLetterSpacing || 0
                    }px`,

                    color: mobileDesigning
                      ? element.mbuttonFontColor ||
                        element.buttonFontColor ||
                        "rgba(0,0,0,0.8)"
                      : element.buttonFontColor || "rgba(0,0,0,0.8)",

                    boxShadow: mobileDesigning
                      ? element.mbuttonBoxShadow ||
                        element.buttonBoxShadow ||
                        "0px 0px 0px rgba(0,0,0,0.8)"
                      : element.buttonBoxShadow ||
                        "0px 0px 0px rgba(0,0,0,0.8)",

                    border: "none",

                    borderRadius: mobileDesigning
                      ? element.mbuttonBorderRadius
                        ? `${element.mbuttonBorderRadius}px`
                        : element.buttonBorderRadius
                        ? `${element.buttonBorderRadius}px`
                        : 30
                      : element.buttonBorderRadius
                      ? `${element.buttonBorderRadius}px`
                      : 30,

                    outline: `${
                      mobileDesigning
                        ? element.mbuttonBorderWidth ||
                          element.buttonBorderWidth ||
                          1
                        : element.buttonBorderWidth || 1
                    }px solid ${
                      mobileDesigning
                        ? element.mbuttonBorderColor ||
                          element.buttonBorderColor ||
                          "grey"
                        : element.buttonBorderColor || "grey"
                    }`,

                    background: mobileDesigning
                      ? element.mbuttonBackgroundColor ||
                        element.buttonBackgroundColor ||
                        "transparent"
                      : element.buttonBackgroundColor || "transparent",
                    padding: "8px 30px",
                    marginTop: "10px",
                  }}
                >
                  Sign up
                </button>
              </div>
              <div
                className="p-checkout-signup-have-acc"
                onClick={() => {
                  setErrorMessage(null);
                  setView("login");
                }}
                style={{
                  marginTop: "20px",
                  fontSize: "8px",
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "grey",
                  fontWeight: "400",
                }}
              >
                Already have an account?
              </div>
            </div>
          ) : (
            <div
              className="p-checkout-login-holder"
              style={{
                display: "flex",
                alignItems: "center",
                paddingTop: "30px",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div
                className="p-checkout-login-title"
                style={{
                  textAlign:
                    (mobileDesigning
                      ? element.mtitleTextAlign || "center"
                      : element.titleTextAlign || "center") === "flex-start"
                      ? "left"
                      : (mobileDesigning
                          ? element.mtitleTextAlign || "center"
                          : element.titleTextAlign || "center") === "flex-end"
                      ? "right"
                      : "center",

                      fontFamily: mobileDesigning
                        ? element.mtitleFontFamily ||
                          element.titleFontFamily ||
                          "Roboto"
                        : element.titleFontFamily || "Roboto",

                  fontSize: mobileDesigning
                    ? element.mtitleFontSize || element.titleFontSize || 30
                    : element.titleFontSize || 30,

                  fontWeight: mobileDesigning
                    ? element.mtitleFontWeight || element.titleFontWeight || 700
                    : element.titleFontWeight || 700,

                  lineHeight: `${
                    mobileDesigning
                      ? element.mtitleLineHeight ||
                        element.titleLineHeight ||
                        (mobileDesigning
                          ? element.mtitleFontSize ||
                            element.titleFontSize ||
                            20
                          : element.titleFontSize || 20)
                      : element.titleLineHeight ||
                        (mobileDesigning
                          ? element.mtitleFontSize ||
                            element.titleFontSize ||
                            20
                          : element.titleFontSize || 20)
                  }px`,

                  letterSpacing: `${
                    mobileDesigning
                      ? element.mtitleLetterSpacing ||
                        element.titleLetterSpacing ||
                        0
                      : element.titleLetterSpacing || 0
                  }px`,

                  color: mobileDesigning
                    ? element.mtitleFontColor ||
                      element.titleFontColor ||
                      "rgba(0,0,0,0.8)"
                    : element.titleFontColor || "rgba(0,0,0,0.8)",

                  textShadow: mobileDesigning
                    ? element.mtitleTextShadow ||
                      element.titleTextShadow ||
                      "0px 0px 0px rgba(0,0,0,0.8)"
                    : element.titleTextShadow || "0px 0px 0px rgba(0,0,0,0.8)",
                }}
              >
                Welcome
              </div>
              <div
                className="p-checkout-login-field p-checkout-login-field-email"
                style={{ width: "40%" }}
              >
                <input
                  className="p-checkout-login-field-input p-checkout-login-field-input-email"
                  placeholder="Email"
                  value={userDetails.email}
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, email: e.target.value })
                  }
                  style={{
                    width: "calc(100% - 30px)",
                    padding: "8px 15px",
                    border: "none",
                    marginTop: "20px",
                    textAlign:
                      (mobileDesigning
                        ? element.mfieldTextAlign || "center"
                        : element.fieldTextAlign || "center") === "flex-start"
                        ? "left"
                        : (mobileDesigning
                            ? element.mfieldTextAlign || "center"
                            : element.fieldTextAlign || "center") === "flex-end"
                        ? "right"
                        : "center",

                        fontFamily: mobileDesigning
                          ? element.mfieldFontFamily ||
                            element.fieldFontFamily ||
                            "Roboto"
                          : element.fieldFontFamily || "Roboto",

                    fontSize: mobileDesigning
                      ? element.mfieldFontSize || element.fieldFontSize || 14
                      : element.fieldFontSize || 14,

                    fontWeight: mobileDesigning
                      ? element.mfieldFontWeight ||
                        element.fieldFontWeight ||
                        400
                      : element.fieldFontWeight || 400,

                    lineHeight: `${
                      mobileDesigning
                        ? element.mfieldLineHeight ||
                          element.fieldLineHeight ||
                          (mobileDesigning
                            ? element.mfieldFontSize ||
                              element.fieldFontSize ||
                              14
                            : element.fieldFontSize || 14)
                        : element.fieldLineHeight ||
                          (mobileDesigning
                            ? element.mfieldFontSize ||
                              element.fieldFontSize ||
                              14
                            : element.fieldFontSize || 14)
                    }px`,

                    letterSpacing: `${
                      mobileDesigning
                        ? element.mfieldLetterSpacing ||
                          element.fieldLetterSpacing ||
                          0
                        : element.fieldLetterSpacing || 0
                    }px`,

                    color: mobileDesigning
                      ? element.mfieldFontColor ||
                        element.fieldFontColor ||
                        "rgba(0,0,0,0.8)"
                      : element.fieldFontColor || "rgba(0,0,0,0.8)",

                    outline: `${
                      mobileDesigning
                        ? element.mfieldBorderWidth ||
                          element.fieldBorderWidth ||
                          1
                        : element.fieldBorderWidth || 1
                    }px solid ${
                      mobileDesigning
                        ? element.mfieldBorderColor ||
                          element.fieldBorderColor ||
                          "grey"
                        : element.fieldBorderColor || "grey"
                    }`,

                    borderRadius: mobileDesigning
                      ? element.mfieldBorderRadius
                        ? `${element.mfieldBorderRadius}px`
                        : element.fieldBorderRadius
                        ? `${element.fieldBorderRadius}px`
                        : 0
                      : element.fieldBorderRadius
                      ? `${element.fieldBorderRadius}px`
                      : 0,

                    boxShadow: mobileDesigning
                      ? element.mfieldTextShadow ||
                        element.fieldTextShadow ||
                        "0px 0px 0px rgba(0,0,0,0.8)"
                      : element.fieldTextShadow ||
                        "0px 0px 0px rgba(0,0,0,0.8)",

                    background: mobileDesigning
                      ? element.mfieldBackgroundColor ||
                        element.fieldBackgroundColor ||
                        "rgba(0,0,0,0)"
                      : element.fieldBackgroundColor || "rgba(0,0,0,0)",
                  }}
                />
              </div>
              <div
                className="p-checkout-login-field p-checkout-login-field-password"
                style={{ width: "40%" }}
              >
                <input
                  className="p-checkout-login-field-input p-checkout-login-field-input-password"
                  placeholder="Password"
                  type="password"
                  value={userDetails.password}
                  onChange={(e) =>
                    setUserDetails({ ...userDetails, password: e.target.value })
                  }
                  style={{
                    width: "calc(100% - 30px)",
                    padding: "8px 15px",
                    border: "none",
                    marginTop: "10px",
                    textAlign:
                      (mobileDesigning
                        ? element.mfieldTextAlign || "center"
                        : element.fieldTextAlign || "center") === "flex-start"
                        ? "left"
                        : (mobileDesigning
                            ? element.mfieldTextAlign || "center"
                            : element.fieldTextAlign || "center") === "flex-end"
                        ? "right"
                        : "center",

                        fontFamily: mobileDesigning
                          ? element.mfieldFontFamily ||
                            element.fieldFontFamily ||
                            "Roboto"
                          : element.fieldFontFamily || "Roboto",

                    fontSize: mobileDesigning
                      ? element.mfieldFontSize || element.fieldFontSize || 14
                      : element.fieldFontSize || 14,

                    fontWeight: mobileDesigning
                      ? element.mfieldFontWeight ||
                        element.fieldFontWeight ||
                        400
                      : element.fieldFontWeight || 400,

                    lineHeight: `${
                      mobileDesigning
                        ? element.mfieldLineHeight ||
                          element.fieldLineHeight ||
                          (mobileDesigning
                            ? element.mfieldFontSize ||
                              element.fieldFontSize ||
                              14
                            : element.fieldFontSize || 14)
                        : element.fieldLineHeight ||
                          (mobileDesigning
                            ? element.mfieldFontSize ||
                              element.fieldFontSize ||
                              14
                            : element.fieldFontSize || 14)
                    }px`,

                    letterSpacing: `${
                      mobileDesigning
                        ? element.mfieldLetterSpacing ||
                          element.fieldLetterSpacing ||
                          0
                        : element.fieldLetterSpacing || 0
                    }px`,

                    color: mobileDesigning
                      ? element.mfieldFontColor ||
                        element.fieldFontColor ||
                        "rgba(0,0,0,0.8)"
                      : element.fieldFontColor || "rgba(0,0,0,0.8)",

                    outline: `${
                      mobileDesigning
                        ? element.mfieldBorderWidth ||
                          element.fieldBorderWidth ||
                          1
                        : element.fieldBorderWidth || 1
                    }px solid ${
                      mobileDesigning
                        ? element.mfieldBorderColor ||
                          element.fieldBorderColor ||
                          "grey"
                        : element.fieldBorderColor || "grey"
                    }`,

                    borderRadius: mobileDesigning
                      ? element.mfieldBorderRadius
                        ? `${element.mfieldBorderRadius}px`
                        : element.fieldBorderRadius
                        ? `${element.fieldBorderRadius}px`
                        : 0
                      : element.fieldBorderRadius
                      ? `${element.fieldBorderRadius}px`
                      : 0,

                    boxShadow: mobileDesigning
                      ? element.mfieldTextShadow ||
                        element.fieldTextShadow ||
                        "0px 0px 0px rgba(0,0,0,0.8)"
                      : element.fieldTextShadow ||
                        "0px 0px 0px rgba(0,0,0,0.8)",

                    background: mobileDesigning
                      ? element.mfieldBackgroundColor ||
                        element.fieldBackgroundColor ||
                        "rgba(0,0,0,0)"
                      : element.fieldBackgroundColor || "rgba(0,0,0,0)",
                  }}
                />
              </div>
              {errorMessage ? (
                <div
                  className="p-checkout-login-error"
                  style={{ color: "red", marginTop: "10px", fontSize: "11px" }}
                >
                  {errorMessage ? errorMessage : null}
                </div>
              ) : null}
              <div className="p-checkout-login-button-holder">
                <button
                  className="p-checkout-login-button"
                  onClick={(e) => handleLogin(e)}
                  style={{
                    textAlign:
                      (mobileDesigning
                        ? element.mbuttonTextAlign || "center"
                        : element.buttonTextAlign || "center") === "flex-start"
                        ? "left"
                        : (mobileDesigning
                            ? element.mbuttonTextAlign || "center"
                            : element.buttonTextAlign || "center") ===
                          "flex-end"
                        ? "right"
                        : "center",

                        fontFamily: mobileDesigning
                          ? element.mbuttonFontFamily ||
                            element.buttonFontFamily ||
                            "Roboto"
                          : element.buttonFontFamily || "Roboto",

                    fontSize: mobileDesigning
                      ? element.mbuttonFontSize || element.buttonFontSize || 14
                      : element.buttonFontSize || 14,

                    fontWeight: mobileDesigning
                      ? element.mbuttonFontWeight ||
                        element.buttonFontWeight ||
                        700
                      : element.buttonFontWeight || 700,

                    lineHeight: `${
                      mobileDesigning
                        ? element.mbuttonLineHeight ||
                          element.buttonLineHeight ||
                          (mobileDesigning
                            ? element.mbuttonFontSize ||
                              element.buttonFontSize ||
                              14
                            : element.buttonFontSize || 14)
                        : element.buttonLineHeight ||
                          (mobileDesigning
                            ? element.mbuttonFontSize ||
                              element.buttonFontSize ||
                              14
                            : element.buttonFontSize || 14)
                    }px`,

                    letterSpacing: `${
                      mobileDesigning
                        ? element.mbuttonLetterSpacing ||
                          element.buttonLetterSpacing ||
                          0
                        : element.buttonLetterSpacing || 0
                    }px`,

                    color: mobileDesigning
                      ? element.mbuttonFontColor ||
                        element.buttonFontColor ||
                        "rgba(0,0,0,0.8)"
                      : element.buttonFontColor || "rgba(0,0,0,0.8)",

                    boxShadow: mobileDesigning
                      ? element.mbuttonBoxShadow ||
                        element.buttonBoxShadow ||
                        "0px 0px 0px rgba(0,0,0,0.8)"
                      : element.buttonBoxShadow ||
                        "0px 0px 0px rgba(0,0,0,0.8)",

                    border: "none",

                    borderRadius: mobileDesigning
                      ? element.mbuttonBorderRadius
                        ? `${element.mbuttonBorderRadius}px`
                        : element.buttonBorderRadius
                        ? `${element.buttonBorderRadius}px`
                        : 30
                      : element.buttonBorderRadius
                      ? `${element.buttonBorderRadius}px`
                      : 30,

                    outline: `${
                      mobileDesigning
                        ? element.mbuttonBorderWidth ||
                          element.buttonBorderWidth ||
                          1
                        : element.buttonBorderWidth || 1
                    }px solid ${
                      mobileDesigning
                        ? element.mbuttonBorderColor ||
                          element.buttonBorderColor ||
                          "grey"
                        : element.buttonBorderColor || "grey"
                    }`,

                    background: mobileDesigning
                      ? element.mbuttonBackgroundColor ||
                        element.buttonBackgroundColor ||
                        "transparent"
                      : element.buttonBackgroundColor || "transparent",
                    padding: "8px 30px",
                    marginTop: "10px",
                  }}
                >
                  Login
                </button>
              </div>
              <div
                className="p-checkout-don-have-acc"
                onClick={() => {
                  setErrorMessage(null);
                  setView("signup");
                }}
                style={{
                  marginTop: "20px",
                  fontSize: "8px",
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "grey",
                  fontWeight: "400",
                }}
              >
                Don't have an account?
              </div>
            </div>
          )
        ) : (
          <>
            <div
              className="p-checkout-process-row"
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <div
                className="p-checkout-process-row-col p-checkout-process-row-col-cart"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  className="p-checkout-process-row-col-icon-holder p-checkout-process-row-col-icon-holder-cart"
                  style={{
                    background: currentState >= 0 ? "grey" : "rgba(0,0,0,0.1)",
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LocalMallIcon
                    className="p-checkout-process-row-col-icon p-checkout-process-row-col-icon-cart"
                    style={{
                      color: currentState >= 0 ? "white" : "grey",
                      fontSize: "26px",
                    }}
                  />
                </div>
                <div
                  className="p-checkout-process-row-col-title p-checkout-process-row-col-title-cart"
                  style={{
                    color: currentState >= 0 ? "grey" : "rgba(0,0,0,0.4)",
                    marginTop: "5px",
                    fontSize: "10px",
                  }}
                >
                  Cart
                </div>
              </div>
              <div
                className="p-checkout-process-row-bar-1"
                style={{
                  background: currentState >= 1 ? "grey" : "rgba(0,0,0,0.1)",
                  height: "8px",
                  width: "70px",
                  borderRadius: "20px",
                  margin: "14px 10px",
                }}
              >
                {currentState === 0 ? (
                  <div
                    className="animation_class p-checkout-process-row-inner-bar-1"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      height: "8px",
                      borderRadius: "20px",
                    }}
                  />
                ) : null}
              </div>
              <div
                className="p-checkout-process-row-col p-checkout-process-row-col-shipping"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  className="p-checkout-process-row-col-icon-holder p-checkout-process-row-col-icon-holder-shipping"
                  style={{
                    background: currentState >= 1 ? "grey" : "rgba(0,0,0,0.1)",
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LocalShippingIcon
                    className="p-checkout-process-row-col-icon p-checkout-process-row-col-icon-shipping"
                    style={{
                      color: currentState >= 1 ? "white" : "grey",
                      fontSize: "26px",
                    }}
                  />
                </div>
                <div
                  className="p-checkout-process-row-col-title p-checkout-process-row-col-title-shipping"
                  style={{
                    color: currentState >= 1 ? "grey" : "rgba(0,0,0,0.4)",
                    marginTop: "5px",
                    fontSize: "10px",
                  }}
                >
                  Shipping
                </div>
              </div>
              <div
                className="p-checkout-process-row-bar-2"
                style={{
                  background: currentState >= 2 ? "grey" : "rgba(0,0,0,0.1)",
                  height: "8px",
                  width: "70px",
                  borderRadius: "20px",
                  margin: "14px 10px",
                }}
              >
                {currentState === 1 ? (
                  <div
                    className="animation_class p-checkout-process-row-inner-bar-2"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      height: "8px",
                      borderRadius: "20px",
                    }}
                  />
                ) : null}
              </div>
              <div
                className="p-checkout-process-row-col p-checkout-process-row-col-payment"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  className="p-checkout-process-row-col-icon-holder p-checkout-process-row-col-icon-holder-payment"
                  style={{
                    background: currentState >= 2 ? "grey" : "rgba(0,0,0,0.1)",
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <AccountBalanceWalletIcon
                    className="p-checkout-process-row-col-icon p-checkout-process-row-col-icon-payment"
                    style={{
                      color: currentState >= 2 ? "white" : "grey",
                      fontSize: "26px",
                    }}
                  />
                </div>
                <div
                  className="p-checkout-process-row-col-title p-checkout-process-row-col-title-payment"
                  style={{
                    color: currentState >= 2 ? "grey" : "rgba(0,0,0,0.4)",
                    marginTop: "5px",
                    fontSize: "10px",
                  }}
                >
                  Payment
                </div>
              </div>
              <div
                className="p-checkout-process-row-bar-3"
                style={{
                  background: currentState >= 3 ? "grey" : "rgba(0,0,0,0.1)",
                  height: "8px",
                  width: "70px",
                  borderRadius: "20px",
                  margin: "14px 10px",
                }}
              >
                {currentState === 2 ? (
                  <div
                    className="animation_class p-checkout-process-row-inner-bar-3"
                    style={{
                      background: "rgba(0,0,0,0.2)",
                      height: "8px",
                      borderRadius: "20px",
                    }}
                  />
                ) : null}
              </div>
              <div
                className="p-checkout-process-row-col p-checkout-process-row-col-confirm"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  className="p-checkout-process-row-col-icon-holder p-checkout-process-row-col-icon-holder-confirm"
                  style={{
                    background: currentState >= 3 ? "grey" : "rgba(0,0,0,0.1)",
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <VerifiedUserIcon
                    className="p-checkout-process-row-col-icon p-checkout-process-row-col-icon-confirm"
                    style={{
                      color: currentState >= 3 ? "white" : "grey",
                      fontSize: "26px",
                    }}
                  />
                </div>
                <div
                  className="p-checkout-process-row-col-title p-checkout-process-row-col-title-confirm"
                  style={{
                    color: currentState >= 3 ? "grey" : "rgba(0,0,0,0.4)",
                    marginTop: "5px",
                    fontSize: "10px",
                  }}
                >
                  Confirm
                </div>
              </div>
            </div>
            <div className="p-checkout-body-content">
              {currentState === 0 ? (
                <div
                  className="p-checkout-body-content-cart"
                  style={{ fontSize: "12px" }}
                >
                  {!mobileDesigning ? (
                    <div
                      className="p-checkout-body-content-cart-tab"
                      style={{
                        display: "flex",
                        padding: "10px 0px",
                        width: "100%",
                        borderTop: "1px solid lightgrey",
                        borderBottom: "1px solid lightgrey",
                        justifyContent: "space-between",
                        textAlign:
                          (mobileDesigning
                            ? element.mdescriptionTextAlign || "center"
                            : element.descriptionTextAlign || "center") ===
                          "flex-start"
                            ? "left"
                            : (mobileDesigning
                                ? element.mdescriptionTextAlign || "center"
                                : element.descriptionTextAlign || "center") ===
                              "flex-end"
                            ? "right"
                            : "center",

                            fontFamily: mobileDesigning
                              ? element.mdescriptionFontFamily ||
                                element.descriptionFontFamily ||
                                "Roboto"
                              : element.descriptionFontFamily || "Roboto",

                        fontSize: mobileDesigning
                          ? element.mdescriptionFontSize ||
                            element.descriptionFontSize ||
                            14
                          : element.descriptionFontSize || 14,

                        fontWeight: mobileDesigning
                          ? element.mdescriptionFontWeight ||
                            element.descriptionFontWeight ||
                            400
                          : element.descriptionFontWeight || 400,

                        lineHeight: `${
                          mobileDesigning
                            ? element.mdescriptionlineHeight ||
                              element.descriptionlineHeight ||
                              (mobileDesigning
                                ? element.mdescriptionFontSize ||
                                  element.descriptionFontSize ||
                                  14
                                : element.descriptionFontSize || 14)
                            : element.descriptionlineHeight ||
                              (mobileDesigning
                                ? element.mdescriptionFontSize ||
                                  element.descriptionFontSize ||
                                  14
                                : element.descriptionFontSize || 14)
                        }px`,

                        letterSpacing: `${
                          mobileDesigning
                            ? element.mdescriptionLetterSpacing ||
                              element.descriptionLetterSpacing ||
                              0
                            : element.descriptionLetterSpacing || 0
                        }px`,

                        color: mobileDesigning
                          ? element.mdescriptionFontColor ||
                            element.descriptionFontColor ||
                            "rgba(0,0,0,0.8)"
                          : element.descriptionFontColor || "rgba(0,0,0,0.8)",

                        textShadow: mobileDesigning
                          ? element.mdescriptionTextShadow ||
                            element.descriptionTextShadow ||
                            "0px 0px 0px rgba(0,0,0,0.8)"
                          : element.descriptionTextShadow ||
                            "0px 0px 0px rgba(0,0,0,0.8)",
                      }}
                    >
                      <div
                        className="p-checkout-body-content-cart-tab-left-margin"
                        style={{ width: "20%" }}
                      ></div>
                      <div
                        className="p-checkout-body-content-cart-tab-title p-checkout-body-content-cart-tab-title-product"
                        style={{ width: "20%" }}
                      >
                        Product
                      </div>
                      <div
                        className="p-checkout-body-content-cart-tab-title p-checkout-body-content-cart-tab-title-price"
                        style={{ width: "20%" }}
                      >
                        Price
                      </div>
                      <div
                        className="p-checkout-body-content-cart-tab-title p-checkout-body-content-cart-tab-title-quantity"
                        style={{ width: "20%" }}
                      >
                        Quantity
                      </div>
                      <div
                        className="p-checkout-body-content-cart-tab-title p-checkout-body-content-cart-tab-title-subtotal"
                        style={{ width: "20%" }}
                      >
                        Subtotal
                      </div>
                    </div>
                  ) : null}
                  {customerAccountDetails.cart
                    ? customerAccountDetails.cart.map((product, index) => (
                        <div
                          className={`p-checkout-cart-row p-checkout-cart-row-${index} `}
                          key={index}
                          style={{
                            display: "flex",
                            padding: "10px 0px",
                            width: "100%",
                            borderBottom: "1px solid lightgrey",
                            alignItems: "center",
                            justifyContent: "space-between",

                            textAlign:
                              (mobileDesigning
                                ? element.mdescriptionTextAlign || "center"
                                : element.descriptionTextAlign || "center") ===
                              "flex-start"
                                ? "left"
                                : (mobileDesigning
                                    ? element.mdescriptionTextAlign || "center"
                                    : element.descriptionTextAlign ||
                                      "center") === "flex-end"
                                ? "right"
                                : "center",

                                fontFamily: mobileDesigning
                                  ? element.mdescriptionFontFamily ||
                                    element.descriptionFontFamily ||
                                    "Roboto"
                                  : element.descriptionFontFamily || "Roboto",

                            fontSize: mobileDesigning
                              ? element.mdescriptionFontSize ||
                                element.descriptionFontSize ||
                                14
                              : element.descriptionFontSize || 14,

                            fontWeight: mobileDesigning
                              ? element.mdescriptionFontWeight ||
                                element.descriptionFontWeight ||
                                400
                              : element.descriptionFontWeight || 400,

                            lineHeight: `${
                              mobileDesigning
                                ? element.mdescriptionlineHeight ||
                                  element.descriptionlineHeight ||
                                  (mobileDesigning
                                    ? element.mdescriptionFontSize ||
                                      element.descriptionFontSize ||
                                      14
                                    : element.descriptionFontSize || 14)
                                : element.descriptionlineHeight ||
                                  (mobileDesigning
                                    ? element.mdescriptionFontSize ||
                                      element.descriptionFontSize ||
                                      14
                                    : element.descriptionFontSize || 14)
                            }px`,

                            letterSpacing: `${
                              mobileDesigning
                                ? element.mdescriptionLetterSpacing ||
                                  element.descriptionLetterSpacing ||
                                  0
                                : element.descriptionLetterSpacing || 0
                            }px`,

                            color: mobileDesigning
                              ? element.mdescriptionFontColor ||
                                element.descriptionFontColor ||
                                "rgba(0,0,0,0.8)"
                              : element.descriptionFontColor ||
                                "rgba(0,0,0,0.8)",

                            textShadow: mobileDesigning
                              ? element.mdescriptionTextShadow ||
                                element.descriptionTextShadow ||
                                "0px 0px 0px rgba(0,0,0,0.8)"
                              : element.descriptionTextShadow ||
                                "0px 0px 0px rgba(0,0,0,0.8)",
                          }}
                        >
                          <div
                            className={`p-checkout-cart-row-close-image p-checkout-cart-row-close-image-${index} `}
                            style={{
                              width: "20%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <CloseIcon
                              className={`mui-button p-checkout-cart-row-close-icon p-checkout-cart-row-close-icon-${index} `}
                              onClick={() => {
                                const updatingCart = {
                                  ...customerAccountDetails,
                                };
                                updatingCart.cart.splice(index, 1);
                                handleUpdateCart(updatingCart.cart);
                                setCustomerAccountDetails(updatingCart);
                              }}
                              style={{ color: "grey", marginRight: "15px" }}
                            />
                            <img
                              className={`p-checkout-cart-row-image p-checkout-cart-row-image-${index} `}
                              src={product.img_src}
                              style={{
                                width: "80px",
                                height: "80px",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                          <div
                            className={`p-checkout-cart-row-prod-details p-checkout-cart-row-prod-details-${index} `}
                            style={{
                              display: mobileDesigning ? "block" : "flex",
                              width: mobileDesigning ? "50%" : "80%",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div
                              className={`p-checkout-cart-row-prod-details-text p-checkout-cart-row-prod-name p-checkout-cart-row-prod-name-${index} `}
                              style={{
                                width: mobileDesigning ? "100%" : "25%",
                              }}
                            >
                              {product.name}
                            </div>
                            {!mobileDesigning ? (
                              <div
                                className={`p-checkout-cart-row-prod-details-text p-checkout-cart-row-prod-price p-checkout-cart-row-prod-price-${index} `}
                                style={{
                                  width: mobileDesigning ? "100%" : "25%",
                                  marginTop: mobileDesigning ? "10px" : "0px",
                                }}
                              >
                                {currency +
                                  " " +
                                  parseFloat(product.unit_price).toLocaleString(
                                    "en-US",
                                    {
                                      style: "decimal",
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )}
                              </div>
                            ) : null}
                            <div
                              className={`p-checkout-cart-row-prod-details-text p-checkout-cart-row-prod-quantity-holder p-checkout-cart-row-prod-quantity-holder-${index} `}
                              style={{
                                width: mobileDesigning ? "100%" : "25%",
                                marginTop: mobileDesigning ? "10px" : "0px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <input
                                className={`p-checkout-cart-row-prod-details-text p-checkout-cart-row-prod-quantity-input p-checkout-cart-row-prod-quantity-input-${index} `}
                                style={{
                                  width: "50%",
                                  padding: "5px 15px",
                                  border: "1px solid lightgrey",
                                  outline: "none",

                                  fontFamily: mobileDesigning
                                    ? element.mdescriptionFontFamily ||
                                      element.descriptionFontFamily ||
                                      "Roboto"
                                    : element.descriptionFontFamily || "Roboto",

                                  fontSize: mobileDesigning
                                    ? element.mdescriptionFontSize ||
                                      element.descriptionFontSize ||
                                      14
                                    : element.descriptionFontSize || 14,

                                  fontWeight: mobileDesigning
                                    ? element.mdescriptionFontWeight ||
                                      element.descriptionFontWeight ||
                                      400
                                    : element.descriptionFontWeight || 400,

                                  lineHeight: `${
                                    mobileDesigning
                                      ? element.mdescriptionlineHeight ||
                                        element.descriptionlineHeight ||
                                        (mobileDesigning
                                          ? element.mdescriptionFontSize ||
                                            element.descriptionFontSize ||
                                            14
                                          : element.descriptionFontSize || 14)
                                      : element.descriptionlineHeight ||
                                        (mobileDesigning
                                          ? element.mdescriptionFontSize ||
                                            element.descriptionFontSize ||
                                            14
                                          : element.descriptionFontSize || 14)
                                  }px`,

                                  letterSpacing: `${
                                    mobileDesigning
                                      ? element.mdescriptionLetterSpacing ||
                                        element.descriptionLetterSpacing ||
                                        0
                                      : element.descriptionLetterSpacing || 0
                                  }px`,

                                  color: mobileDesigning
                                    ? element.mdescriptionFontColor ||
                                      element.descriptionFontColor ||
                                      "rgba(0,0,0,0.8)"
                                    : element.descriptionFontColor ||
                                      "rgba(0,0,0,0.8)",

                                  boxShadow: mobileDesigning
                                    ? element.mdescriptionTextShadow ||
                                      element.descriptionTextShadow ||
                                      "0px 0px 0px rgba(0,0,0,0.8)"
                                    : element.descriptionTextShadow ||
                                      "0px 0px 0px rgba(0,0,0,0.8)",
                                }}
                                type="number"
                                value={product.quantity}
                                min={1}
                                onChange={(e) => {
                                  const updatingCart = {
                                    ...customerAccountDetails,
                                  };
                                  updatingCart.cart[index].quantity =
                                    parseFloat(e.target.value);
                                  handleUpdateCart(updatingCart.cart);
                                  setCustomerAccountDetails(updatingCart);
                                }}
                                onBlur={(e) => {
                                  if (
                                    parseFloat(e.target.value) === 0 ||
                                    e.target.value === ""
                                  ) {
                                    const updatingCart = {
                                      ...customerAccountDetails,
                                    };
                                    updatingCart.cart.splice(index, 1);
                                    handleUpdateCart(updatingCart.cart);
                                    setCustomerAccountDetails(updatingCart);
                                  }
                                }}
                              />
                            </div>
                            <div
                              className={`p-checkout-cart-row-prod-details-text p-checkout-cart-row-prod-ind-total-price p-checkout-cart-row-prod-ind-total-price-${index} `}
                              style={{
                                width: mobileDesigning ? "100%" : "25%",
                                marginTop: mobileDesigning ? "10px" : "0px",
                              }}
                            >
                              {currency +
                                " " +
                                (
                                  parseFloat(product.quantity) *
                                  parseFloat(product.unit_price)
                                ).toLocaleString("en-US", {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                            </div>
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              ) : currentState === 1 ? (
                <div
                  className="p-checkout-body-content-shipping"
                  style={{ fontSize: "12px", display: "flex", color: "grey" }}
                >
                  <div
                    className="p-checkout-body-content-shipping-delivery-details"
                    style={{
                      width: "calc(60% - 40px)",
                      borderRight: "1px solid lightgrey",
                      padding: "30px 40px 30px 0px",
                    }}
                  >
                    <div
                      className="p-checkout-body-content-shipping-delivery-details-titles"
                      style={{
                        textAlign:
                          (mobileDesigning
                            ? element.mtitleTextAlign || "center"
                            : element.titleTextAlign || "center") ===
                          "flex-start"
                            ? "left"
                            : (mobileDesigning
                                ? element.mtitleTextAlign || "center"
                                : element.titleTextAlign || "center") ===
                              "flex-end"
                            ? "right"
                            : "center",

                            fontFamily: mobileDesigning
                              ? element.mtitleFontFamily ||
                                element.titleFontFamily ||
                                "Roboto"
                              : element.titleFontFamily || "Roboto",

                        fontSize: mobileDesigning
                          ? element.mtitleFontSize ||
                            element.titleFontSize ||
                            30
                          : element.titleFontSize || 30,

                        fontWeight: mobileDesigning
                          ? element.mtitleFontWeight ||
                            element.titleFontWeight ||
                            700
                          : element.titleFontWeight || 700,

                        lineHeight: `${
                          mobileDesigning
                            ? element.mtitleLineHeight ||
                              element.titleLineHeight ||
                              (mobileDesigning
                                ? element.mtitleFontSize ||
                                  element.titleFontSize ||
                                  20
                                : element.titleFontSize || 20)
                            : element.titleLineHeight ||
                              (mobileDesigning
                                ? element.mtitleFontSize ||
                                  element.titleFontSize ||
                                  20
                                : element.titleFontSize || 20)
                        }px`,

                        letterSpacing: `${
                          mobileDesigning
                            ? element.mtitleLetterSpacing ||
                              element.titleLetterSpacing ||
                              0
                            : element.titleLetterSpacing || 0
                        }px`,

                        color: mobileDesigning
                          ? element.mtitleFontColor ||
                            element.titleFontColor ||
                            "rgba(0,0,0,0.8)"
                          : element.titleFontColor || "rgba(0,0,0,0.8)",

                        textShadow: mobileDesigning
                          ? element.mtitleTextShadow ||
                            element.titleTextShadow ||
                            "0px 0px 0px rgba(0,0,0,0.8)"
                          : element.titleTextShadow ||
                            "0px 0px 0px rgba(0,0,0,0.8)",
                        marginBottom: "15px",
                      }}
                    >
                      Delivery details
                    </div>
                    {customerAccountDetails ? (
                      customerAccountDetails.checkout_details ? (
                        customerAccountDetails.checkout_details.length > 0 ? (
                          <select
                            className="p-checkout-body-content-shipping-delivery-details-select"
                            value={selectCheckDet}
                            style={{
                              width: "100%",
                              padding: "8px 15px",
                              border: "none",
                              margin: "0px 0px 5px 0px",
                              textAlign:
                                (mobileDesigning
                                  ? element.mfieldTextAlign || "center"
                                  : element.fieldTextAlign || "center") ===
                                "flex-start"
                                  ? "left"
                                  : (mobileDesigning
                                      ? element.mfieldTextAlign || "center"
                                      : element.fieldTextAlign || "center") ===
                                    "flex-end"
                                  ? "right"
                                  : "center",

                                  fontFamily: mobileDesigning
                                    ? element.mfieldFontFamily ||
                                      element.fieldFontFamily ||
                                      "Roboto"
                                    : element.fieldFontFamily || "Roboto",

                              fontSize: mobileDesigning
                                ? element.mfieldFontSize ||
                                  element.fieldFontSize ||
                                  14
                                : element.fieldFontSize || 14,

                              fontWeight: mobileDesigning
                                ? element.mfieldFontWeight ||
                                  element.fieldFontWeight ||
                                  400
                                : element.fieldFontWeight || 400,

                              lineHeight: `${
                                mobileDesigning
                                  ? element.mfieldLineHeight ||
                                    element.fieldLineHeight ||
                                    (mobileDesigning
                                      ? element.mfieldFontSize ||
                                        element.fieldFontSize ||
                                        14
                                      : element.fieldFontSize || 14)
                                  : element.fieldLineHeight ||
                                    (mobileDesigning
                                      ? element.mfieldFontSize ||
                                        element.fieldFontSize ||
                                        14
                                      : element.fieldFontSize || 14)
                              }px`,

                              letterSpacing: `${
                                mobileDesigning
                                  ? element.mfieldLetterSpacing ||
                                    element.fieldLetterSpacing ||
                                    0
                                  : element.fieldLetterSpacing || 0
                              }px`,

                              color: mobileDesigning
                                ? element.mfieldFontColor ||
                                  element.fieldFontColor ||
                                  "rgba(0,0,0,0.8)"
                                : element.fieldFontColor || "rgba(0,0,0,0.8)",

                              outline: `${
                                mobileDesigning
                                  ? element.mfieldBorderWidth ||
                                    element.fieldBorderWidth ||
                                    1
                                  : element.fieldBorderWidth || 1
                              }px solid ${
                                mobileDesigning
                                  ? element.mfieldBorderColor ||
                                    element.fieldBorderColor ||
                                    "grey"
                                  : element.fieldBorderColor || "grey"
                              }`,

                              borderRadius: mobileDesigning
                                ? element.mfieldBorderRadius
                                  ? `${element.mfieldBorderRadius}px`
                                  : element.fieldBorderRadius
                                  ? `${element.fieldBorderRadius}px`
                                  : 0
                                : element.fieldBorderRadius
                                ? `${element.fieldBorderRadius}px`
                                : 0,

                              boxShadow: mobileDesigning
                                ? element.mfieldTextShadow ||
                                  element.fieldTextShadow ||
                                  "0px 0px 0px rgba(0,0,0,0.8)"
                                : element.fieldTextShadow ||
                                  "0px 0px 0px rgba(0,0,0,0.8)",

                              background: mobileDesigning
                                ? element.mfieldBackgroundColor ||
                                  element.fieldBackgroundColor ||
                                  "rgba(0,0,0,0)"
                                : element.fieldBackgroundColor ||
                                  "rgba(0,0,0,0)",
                            }}
                            onChange={(e) => {
                              console.log(e.target.value);
                              if (e.target.value !== "") {
                                setSelectCheckDet(e.target.value);
                                setShippingDetails(
                                  customerAccountDetails.checkout_details[
                                    e.target.value
                                  ]
                                );
                              }
                            }}
                          >
                            <option value={""}>Select checkout details</option>
                            {customerAccountDetails.checkout_details.map(
                              (det, index) => (
                                <option value={index}>{det.full_name}</option>
                              )
                            )}
                          </select>
                        ) : null
                      ) : null
                    ) : null}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "5px 0px",
                      }}
                    >
                      <div
                        style={{
                          width: "150px",
                          textAlign:
                            (mobileDesigning
                              ? element.mfieldTextAlign || "center"
                              : element.fieldTextAlign || "center") ===
                            "flex-start"
                              ? "left"
                              : (mobileDesigning
                                  ? element.mfieldTextAlign || "center"
                                  : element.fieldTextAlign || "center") ===
                                "flex-end"
                              ? "right"
                              : "center",

                              fontFamily: mobileDesigning
                                ? element.mfieldFontFamily ||
                                  element.fieldFontFamily ||
                                  "Roboto"
                                : element.fieldFontFamily || "Roboto",

                          fontSize: mobileDesigning
                            ? element.mfieldFontSize ||
                              element.fieldFontSize ||
                              14
                            : element.fieldFontSize || 14,

                          fontWeight: mobileDesigning
                            ? element.mfieldFontWeight ||
                              element.fieldFontWeight ||
                              400
                            : element.fieldFontWeight || 400,

                          lineHeight: `${
                            mobileDesigning
                              ? element.mfieldLineHeight ||
                                element.fieldLineHeight ||
                                (mobileDesigning
                                  ? element.mfieldFontSize ||
                                    element.fieldFontSize ||
                                    14
                                  : element.fieldFontSize || 14)
                              : element.fieldLineHeight ||
                                (mobileDesigning
                                  ? element.mfieldFontSize ||
                                    element.fieldFontSize ||
                                    14
                                  : element.fieldFontSize || 14)
                          }px`,

                          letterSpacing: `${
                            mobileDesigning
                              ? element.mfieldLetterSpacing ||
                                element.fieldLetterSpacing ||
                                0
                              : element.fieldLetterSpacing || 0
                          }px`,

                          color: mobileDesigning
                            ? element.mfieldFontColor ||
                              element.fieldFontColor ||
                              "rgba(0,0,0,0.8)"
                            : element.fieldFontColor || "rgba(0,0,0,0.8)",
                        }}
                      >
                        Full name
                      </div>
                      <input
                        value={shippingDetails.full_name}
                        onChange={(e) =>
                          setShippingDetails({
                            ...shippingDetails,
                            full_name: e.target.value,
                          })
                        }
                        placeholder="Full name"
                        style={{
                          borderRadius: "10px",
                          padding: "8px 15px",
                          width: "calc(100% - 180px)",
                          border: "none",
                          textAlign:
                            (mobileDesigning
                              ? element.mfieldTextAlign || "center"
                              : element.fieldTextAlign || "center") ===
                            "flex-start"
                              ? "left"
                              : (mobileDesigning
                                  ? element.mfieldTextAlign || "center"
                                  : element.fieldTextAlign || "center") ===
                                "flex-end"
                              ? "right"
                              : "center",

                              fontFamily: mobileDesigning
                                ? element.mfieldFontFamily ||
                                  element.fieldFontFamily ||
                                  "Roboto"
                                : element.fieldFontFamily || "Roboto",

                          fontSize: mobileDesigning
                            ? element.mfieldFontSize ||
                              element.fieldFontSize ||
                              14
                            : element.fieldFontSize || 14,

                          fontWeight: mobileDesigning
                            ? element.mfieldFontWeight ||
                              element.fieldFontWeight ||
                              400
                            : element.fieldFontWeight || 400,

                          lineHeight: `${
                            mobileDesigning
                              ? element.mfieldLineHeight ||
                                element.fieldLineHeight ||
                                (mobileDesigning
                                  ? element.mfieldFontSize ||
                                    element.fieldFontSize ||
                                    14
                                  : element.fieldFontSize || 14)
                              : element.fieldLineHeight ||
                                (mobileDesigning
                                  ? element.mfieldFontSize ||
                                    element.fieldFontSize ||
                                    14
                                  : element.fieldFontSize || 14)
                          }px`,

                          letterSpacing: `${
                            mobileDesigning
                              ? element.mfieldLetterSpacing ||
                                element.fieldLetterSpacing ||
                                0
                              : element.fieldLetterSpacing || 0
                          }px`,

                          color: mobileDesigning
                            ? element.mfieldFontColor ||
                              element.fieldFontColor ||
                              "rgba(0,0,0,0.8)"
                            : element.fieldFontColor || "rgba(0,0,0,0.8)",

                          outline: `${
                            mobileDesigning
                              ? element.mfieldBorderWidth ||
                                element.fieldBorderWidth ||
                                1
                              : element.fieldBorderWidth || 1
                          }px solid ${
                            mobileDesigning
                              ? element.mfieldBorderColor ||
                                element.fieldBorderColor ||
                                "grey"
                              : element.fieldBorderColor || "grey"
                          }`,

                          borderRadius: mobileDesigning
                            ? element.mfieldBorderRadius
                              ? `${element.mfieldBorderRadius}px`
                              : element.fieldBorderRadius
                              ? `${element.fieldBorderRadius}px`
                              : 0
                            : element.fieldBorderRadius
                            ? `${element.fieldBorderRadius}px`
                            : 0,

                          boxShadow: mobileDesigning
                            ? element.mfieldTextShadow ||
                              element.fieldTextShadow ||
                              "0px 0px 0px rgba(0,0,0,0.8)"
                            : element.fieldTextShadow ||
                              "0px 0px 0px rgba(0,0,0,0.8)",

                          background: mobileDesigning
                            ? element.mfieldBackgroundColor ||
                              element.fieldBackgroundColor ||
                              "rgba(0,0,0,0)"
                            : element.fieldBackgroundColor || "rgba(0,0,0,0)",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "5px 0px",
                      }}
                    >
                      <div
                        style={{
                          width: "150px",
                          textAlign:
                            (mobileDesigning
                              ? element.mfieldTextAlign || "center"
                              : element.fieldTextAlign || "center") ===
                            "flex-start"
                              ? "left"
                              : (mobileDesigning
                                  ? element.mfieldTextAlign || "center"
                                  : element.fieldTextAlign || "center") ===
                                "flex-end"
                              ? "right"
                              : "center",

                              fontFamily: mobileDesigning
                                ? element.mfieldFontFamily ||
                                  element.fieldFontFamily ||
                                  "Roboto"
                                : element.fieldFontFamily || "Roboto",

                          fontSize: mobileDesigning
                            ? element.mfieldFontSize ||
                              element.fieldFontSize ||
                              14
                            : element.fieldFontSize || 14,

                          fontWeight: mobileDesigning
                            ? element.mfieldFontWeight ||
                              element.fieldFontWeight ||
                              400
                            : element.fieldFontWeight || 400,

                          lineHeight: `${
                            mobileDesigning
                              ? element.mfieldLineHeight ||
                                element.fieldLineHeight ||
                                (mobileDesigning
                                  ? element.mfieldFontSize ||
                                    element.fieldFontSize ||
                                    14
                                  : element.fieldFontSize || 14)
                              : element.fieldLineHeight ||
                                (mobileDesigning
                                  ? element.mfieldFontSize ||
                                    element.fieldFontSize ||
                                    14
                                  : element.fieldFontSize || 14)
                          }px`,

                          letterSpacing: `${
                            mobileDesigning
                              ? element.mfieldLetterSpacing ||
                                element.fieldLetterSpacing ||
                                0
                              : element.fieldLetterSpacing || 0
                          }px`,

                          color: mobileDesigning
                            ? element.mfieldFontColor ||
                              element.fieldFontColor ||
                              "rgba(0,0,0,0.8)"
                            : element.fieldFontColor || "rgba(0,0,0,0.8)",
                        }}
                      >
                        Email
                      </div>
                      <input
                        value={shippingDetails.email_address}
                        onChange={(e) =>
                          setShippingDetails({
                            ...shippingDetails,
                            email_address: e.target.value,
                          })
                        }
                        placeholder="Email address"
                        style={{
                          borderRadius: "10px",
                          padding: "8px 15px",
                          width: "calc(100% - 180px)",
                          border: "none",
                          textAlign:
                            (mobileDesigning
                              ? element.mfieldTextAlign || "center"
                              : element.fieldTextAlign || "center") ===
                            "flex-start"
                              ? "left"
                              : (mobileDesigning
                                  ? element.mfieldTextAlign || "center"
                                  : element.fieldTextAlign || "center") ===
                                "flex-end"
                              ? "right"
                              : "center",

                              fontFamily: mobileDesigning
                                ? element.mfieldFontFamily ||
                                  element.fieldFontFamily ||
                                  "Roboto"
                                : element.fieldFontFamily || "Roboto",

                          fontSize: mobileDesigning
                            ? element.mfieldFontSize ||
                              element.fieldFontSize ||
                              14
                            : element.fieldFontSize || 14,

                          fontWeight: mobileDesigning
                            ? element.mfieldFontWeight ||
                              element.fieldFontWeight ||
                              400
                            : element.fieldFontWeight || 400,

                          lineHeight: `${
                            mobileDesigning
                              ? element.mfieldLineHeight ||
                                element.fieldLineHeight ||
                                (mobileDesigning
                                  ? element.mfieldFontSize ||
                                    element.fieldFontSize ||
                                    14
                                  : element.fieldFontSize || 14)
                              : element.fieldLineHeight ||
                                (mobileDesigning
                                  ? element.mfieldFontSize ||
                                    element.fieldFontSize ||
                                    14
                                  : element.fieldFontSize || 14)
                          }px`,

                          letterSpacing: `${
                            mobileDesigning
                              ? element.mfieldLetterSpacing ||
                                element.fieldLetterSpacing ||
                                0
                              : element.fieldLetterSpacing || 0
                          }px`,

                          color: mobileDesigning
                            ? element.mfieldFontColor ||
                              element.fieldFontColor ||
                              "rgba(0,0,0,0.8)"
                            : element.fieldFontColor || "rgba(0,0,0,0.8)",

                          outline: `${
                            mobileDesigning
                              ? element.mfieldBorderWidth ||
                                element.fieldBorderWidth ||
                                1
                              : element.fieldBorderWidth || 1
                          }px solid ${
                            mobileDesigning
                              ? element.mfieldBorderColor ||
                                element.fieldBorderColor ||
                                "grey"
                              : element.fieldBorderColor || "grey"
                          }`,

                          borderRadius: mobileDesigning
                            ? element.mfieldBorderRadius
                              ? `${element.mfieldBorderRadius}px`
                              : element.fieldBorderRadius
                              ? `${element.fieldBorderRadius}px`
                              : 0
                            : element.fieldBorderRadius
                            ? `${element.fieldBorderRadius}px`
                            : 0,

                          boxShadow: mobileDesigning
                            ? element.mfieldTextShadow ||
                              element.fieldTextShadow ||
                              "0px 0px 0px rgba(0,0,0,0.8)"
                            : element.fieldTextShadow ||
                              "0px 0px 0px rgba(0,0,0,0.8)",

                          background: mobileDesigning
                            ? element.mfieldBackgroundColor ||
                              element.fieldBackgroundColor ||
                              "rgba(0,0,0,0)"
                            : element.fieldBackgroundColor || "rgba(0,0,0,0)",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "5px 0px",
                      }}
                    >
                      <div
                        style={{
                          width: "150px",
                          textAlign:
                            (mobileDesigning
                              ? element.mfieldTextAlign || "center"
                              : element.fieldTextAlign || "center") ===
                            "flex-start"
                              ? "left"
                              : (mobileDesigning
                                  ? element.mfieldTextAlign || "center"
                                  : element.fieldTextAlign || "center") ===
                                "flex-end"
                              ? "right"
                              : "center",

                              fontFamily: mobileDesigning
                                ? element.mfieldFontFamily ||
                                  element.fieldFontFamily ||
                                  "Roboto"
                                : element.fieldFontFamily || "Roboto",

                          fontSize: mobileDesigning
                            ? element.mfieldFontSize ||
                              element.fieldFontSize ||
                              14
                            : element.fieldFontSize || 14,

                          fontWeight: mobileDesigning
                            ? element.mfieldFontWeight ||
                              element.fieldFontWeight ||
                              400
                            : element.fieldFontWeight || 400,

                          lineHeight: `${
                            mobileDesigning
                              ? element.mfieldLineHeight ||
                                element.fieldLineHeight ||
                                (mobileDesigning
                                  ? element.mfieldFontSize ||
                                    element.fieldFontSize ||
                                    14
                                  : element.fieldFontSize || 14)
                              : element.fieldLineHeight ||
                                (mobileDesigning
                                  ? element.mfieldFontSize ||
                                    element.fieldFontSize ||
                                    14
                                  : element.fieldFontSize || 14)
                          }px`,

                          letterSpacing: `${
                            mobileDesigning
                              ? element.mfieldLetterSpacing ||
                                element.fieldLetterSpacing ||
                                0
                              : element.fieldLetterSpacing || 0
                          }px`,

                          color: mobileDesigning
                            ? element.mfieldFontColor ||
                              element.fieldFontColor ||
                              "rgba(0,0,0,0.8)"
                            : element.fieldFontColor || "rgba(0,0,0,0.8)",
                        }}
                      >
                        Phone number
                      </div>
                      <input
                        value={shippingDetails.phone_number}
                        onChange={(e) =>
                          setShippingDetails({
                            ...shippingDetails,
                            phone_number: e.target.value,
                          })
                        }
                        placeholder="Phone number"
                        style={{
                          borderRadius: "10px",
                          padding: "8px 15px",
                          width: "calc(100% - 180px)",
                          border: "none",
                          textAlign:
                            (mobileDesigning
                              ? element.mfieldTextAlign || "center"
                              : element.fieldTextAlign || "center") ===
                            "flex-start"
                              ? "left"
                              : (mobileDesigning
                                  ? element.mfieldTextAlign || "center"
                                  : element.fieldTextAlign || "center") ===
                                "flex-end"
                              ? "right"
                              : "center",

                              fontFamily: mobileDesigning
                                ? element.mfieldFontFamily ||
                                  element.fieldFontFamily ||
                                  "Roboto"
                                : element.fieldFontFamily || "Roboto",

                          fontSize: mobileDesigning
                            ? element.mfieldFontSize ||
                              element.fieldFontSize ||
                              14
                            : element.fieldFontSize || 14,

                          fontWeight: mobileDesigning
                            ? element.mfieldFontWeight ||
                              element.fieldFontWeight ||
                              400
                            : element.fieldFontWeight || 400,

                          lineHeight: `${
                            mobileDesigning
                              ? element.mfieldLineHeight ||
                                element.fieldLineHeight ||
                                (mobileDesigning
                                  ? element.mfieldFontSize ||
                                    element.fieldFontSize ||
                                    14
                                  : element.fieldFontSize || 14)
                              : element.fieldLineHeight ||
                                (mobileDesigning
                                  ? element.mfieldFontSize ||
                                    element.fieldFontSize ||
                                    14
                                  : element.fieldFontSize || 14)
                          }px`,

                          letterSpacing: `${
                            mobileDesigning
                              ? element.mfieldLetterSpacing ||
                                element.fieldLetterSpacing ||
                                0
                              : element.fieldLetterSpacing || 0
                          }px`,

                          color: mobileDesigning
                            ? element.mfieldFontColor ||
                              element.fieldFontColor ||
                              "rgba(0,0,0,0.8)"
                            : element.fieldFontColor || "rgba(0,0,0,0.8)",

                          outline: `${
                            mobileDesigning
                              ? element.mfieldBorderWidth ||
                                element.fieldBorderWidth ||
                                1
                              : element.fieldBorderWidth || 1
                          }px solid ${
                            mobileDesigning
                              ? element.mfieldBorderColor ||
                                element.fieldBorderColor ||
                                "grey"
                              : element.fieldBorderColor || "grey"
                          }`,

                          borderRadius: mobileDesigning
                            ? element.mfieldBorderRadius
                              ? `${element.mfieldBorderRadius}px`
                              : element.fieldBorderRadius
                              ? `${element.fieldBorderRadius}px`
                              : 0
                            : element.fieldBorderRadius
                            ? `${element.fieldBorderRadius}px`
                            : 0,

                          boxShadow: mobileDesigning
                            ? element.mfieldTextShadow ||
                              element.fieldTextShadow ||
                              "0px 0px 0px rgba(0,0,0,0.8)"
                            : element.fieldTextShadow ||
                              "0px 0px 0px rgba(0,0,0,0.8)",

                          background: mobileDesigning
                            ? element.mfieldBackgroundColor ||
                              element.fieldBackgroundColor ||
                              "rgba(0,0,0,0)"
                            : element.fieldBackgroundColor || "rgba(0,0,0,0)",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "5px 0px",
                      }}
                    >
                      <div
                        style={{
                          width: "150px",
                          textAlign:
                            (mobileDesigning
                              ? element.mfieldTextAlign || "center"
                              : element.fieldTextAlign || "center") ===
                            "flex-start"
                              ? "left"
                              : (mobileDesigning
                                  ? element.mfieldTextAlign || "center"
                                  : element.fieldTextAlign || "center") ===
                                "flex-end"
                              ? "right"
                              : "center",

                              fontFamily: mobileDesigning
                                ? element.mfieldFontFamily ||
                                  element.fieldFontFamily ||
                                  "Roboto"
                                : element.fieldFontFamily || "Roboto",

                          fontSize: mobileDesigning
                            ? element.mfieldFontSize ||
                              element.fieldFontSize ||
                              14
                            : element.fieldFontSize || 14,

                          fontWeight: mobileDesigning
                            ? element.mfieldFontWeight ||
                              element.fieldFontWeight ||
                              400
                            : element.fieldFontWeight || 400,

                          lineHeight: `${
                            mobileDesigning
                              ? element.mfieldLineHeight ||
                                element.fieldLineHeight ||
                                (mobileDesigning
                                  ? element.mfieldFontSize ||
                                    element.fieldFontSize ||
                                    14
                                  : element.fieldFontSize || 14)
                              : element.fieldLineHeight ||
                                (mobileDesigning
                                  ? element.mfieldFontSize ||
                                    element.fieldFontSize ||
                                    14
                                  : element.fieldFontSize || 14)
                          }px`,

                          letterSpacing: `${
                            mobileDesigning
                              ? element.mfieldLetterSpacing ||
                                element.fieldLetterSpacing ||
                                0
                              : element.fieldLetterSpacing || 0
                          }px`,

                          color: mobileDesigning
                            ? element.mfieldFontColor ||
                              element.fieldFontColor ||
                              "rgba(0,0,0,0.8)"
                            : element.fieldFontColor || "rgba(0,0,0,0.8)",
                        }}
                      >
                        Address
                      </div>
                      <textarea
                        value={shippingDetails.address}
                        onChange={(e) =>
                          setShippingDetails({
                            ...shippingDetails,
                            address: e.target.value,
                          })
                        }
                        placeholder="Address"
                        style={{
                          borderRadius: "10px",
                          padding: "8px 15px",
                          width: "calc(100% - 180px)",
                          resize: "none",
                          height: "80px",
                          border: "none",
                          textAlign:
                            (mobileDesigning
                              ? element.mfieldTextAlign || "center"
                              : element.fieldTextAlign || "center") ===
                            "flex-start"
                              ? "left"
                              : (mobileDesigning
                                  ? element.mfieldTextAlign || "center"
                                  : element.fieldTextAlign || "center") ===
                                "flex-end"
                              ? "right"
                              : "center",

                              fontFamily: mobileDesigning
                                ? element.mfieldFontFamily ||
                                  element.fieldFontFamily ||
                                  "Roboto"
                                : element.fieldFontFamily || "Roboto",

                          fontSize: mobileDesigning
                            ? element.mfieldFontSize ||
                              element.fieldFontSize ||
                              14
                            : element.fieldFontSize || 14,

                          fontWeight: mobileDesigning
                            ? element.mfieldFontWeight ||
                              element.fieldFontWeight ||
                              400
                            : element.fieldFontWeight || 400,

                          lineHeight: `${
                            mobileDesigning
                              ? element.mfieldLineHeight ||
                                element.fieldLineHeight ||
                                (mobileDesigning
                                  ? element.mfieldFontSize ||
                                    element.fieldFontSize ||
                                    14
                                  : element.fieldFontSize || 14)
                              : element.fieldLineHeight ||
                                (mobileDesigning
                                  ? element.mfieldFontSize ||
                                    element.fieldFontSize ||
                                    14
                                  : element.fieldFontSize || 14)
                          }px`,

                          letterSpacing: `${
                            mobileDesigning
                              ? element.mfieldLetterSpacing ||
                                element.fieldLetterSpacing ||
                                0
                              : element.fieldLetterSpacing || 0
                          }px`,

                          color: mobileDesigning
                            ? element.mfieldFontColor ||
                              element.fieldFontColor ||
                              "rgba(0,0,0,0.8)"
                            : element.fieldFontColor || "rgba(0,0,0,0.8)",

                          outline: `${
                            mobileDesigning
                              ? element.mfieldBorderWidth ||
                                element.fieldBorderWidth ||
                                1
                              : element.fieldBorderWidth || 1
                          }px solid ${
                            mobileDesigning
                              ? element.mfieldBorderColor ||
                                element.fieldBorderColor ||
                                "grey"
                              : element.fieldBorderColor || "grey"
                          }`,

                          borderRadius: mobileDesigning
                            ? element.mfieldBorderRadius
                              ? `${element.mfieldBorderRadius}px`
                              : element.fieldBorderRadius
                              ? `${element.fieldBorderRadius}px`
                              : 0
                            : element.fieldBorderRadius
                            ? `${element.fieldBorderRadius}px`
                            : 0,

                          boxShadow: mobileDesigning
                            ? element.mfieldTextShadow ||
                              element.fieldTextShadow ||
                              "0px 0px 0px rgba(0,0,0,0.8)"
                            : element.fieldTextShadow ||
                              "0px 0px 0px rgba(0,0,0,0.8)",

                          background: mobileDesigning
                            ? element.mfieldBackgroundColor ||
                              element.fieldBackgroundColor ||
                              "rgba(0,0,0,0)"
                            : element.fieldBackgroundColor || "rgba(0,0,0,0)",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "5px 0px",
                      }}
                    >
                      <div
                        style={{
                          width: "150px",
                          textAlign:
                            (mobileDesigning
                              ? element.mfieldTextAlign || "center"
                              : element.fieldTextAlign || "center") ===
                            "flex-start"
                              ? "left"
                              : (mobileDesigning
                                  ? element.mfieldTextAlign || "center"
                                  : element.fieldTextAlign || "center") ===
                                "flex-end"
                              ? "right"
                              : "center",

                              fontFamily: mobileDesigning
                                ? element.mfieldFontFamily ||
                                  element.fieldFontFamily ||
                                  "Roboto"
                                : element.fieldFontFamily || "Roboto",

                          fontSize: mobileDesigning
                            ? element.mfieldFontSize ||
                              element.fieldFontSize ||
                              14
                            : element.fieldFontSize || 14,

                          fontWeight: mobileDesigning
                            ? element.mfieldFontWeight ||
                              element.fieldFontWeight ||
                              400
                            : element.fieldFontWeight || 400,

                          lineHeight: `${
                            mobileDesigning
                              ? element.mfieldLineHeight ||
                                element.fieldLineHeight ||
                                (mobileDesigning
                                  ? element.mfieldFontSize ||
                                    element.fieldFontSize ||
                                    14
                                  : element.fieldFontSize || 14)
                              : element.fieldLineHeight ||
                                (mobileDesigning
                                  ? element.mfieldFontSize ||
                                    element.fieldFontSize ||
                                    14
                                  : element.fieldFontSize || 14)
                          }px`,

                          letterSpacing: `${
                            mobileDesigning
                              ? element.mfieldLetterSpacing ||
                                element.fieldLetterSpacing ||
                                0
                              : element.fieldLetterSpacing || 0
                          }px`,

                          color: mobileDesigning
                            ? element.mfieldFontColor ||
                              element.fieldFontColor ||
                              "rgba(0,0,0,0.8)"
                            : element.fieldFontColor || "rgba(0,0,0,0.8)",
                        }}
                      >
                        Zip code
                      </div>
                      <input
                        value={shippingDetails.zip_code}
                        onChange={(e) =>
                          setShippingDetails({
                            ...shippingDetails,
                            zip_code: e.target.value,
                          })
                        }
                        placeholder="Zip code"
                        style={{
                          borderRadius: "10px",
                          padding: "8px 15px",
                          width: "calc(100% - 180px)",
                          border: "none",
                          textAlign:
                            (mobileDesigning
                              ? element.mfieldTextAlign || "center"
                              : element.fieldTextAlign || "center") ===
                            "flex-start"
                              ? "left"
                              : (mobileDesigning
                                  ? element.mfieldTextAlign || "center"
                                  : element.fieldTextAlign || "center") ===
                                "flex-end"
                              ? "right"
                              : "center",

                              fontFamily: mobileDesigning
                                ? element.mfieldFontFamily ||
                                  element.fieldFontFamily ||
                                  "Roboto"
                                : element.fieldFontFamily || "Roboto",

                          fontSize: mobileDesigning
                            ? element.mfieldFontSize ||
                              element.fieldFontSize ||
                              14
                            : element.fieldFontSize || 14,

                          fontWeight: mobileDesigning
                            ? element.mfieldFontWeight ||
                              element.fieldFontWeight ||
                              400
                            : element.fieldFontWeight || 400,

                          lineHeight: `${
                            mobileDesigning
                              ? element.mfieldLineHeight ||
                                element.fieldLineHeight ||
                                (mobileDesigning
                                  ? element.mfieldFontSize ||
                                    element.fieldFontSize ||
                                    14
                                  : element.fieldFontSize || 14)
                              : element.fieldLineHeight ||
                                (mobileDesigning
                                  ? element.mfieldFontSize ||
                                    element.fieldFontSize ||
                                    14
                                  : element.fieldFontSize || 14)
                          }px`,

                          letterSpacing: `${
                            mobileDesigning
                              ? element.mfieldLetterSpacing ||
                                element.fieldLetterSpacing ||
                                0
                              : element.fieldLetterSpacing || 0
                          }px`,

                          color: mobileDesigning
                            ? element.mfieldFontColor ||
                              element.fieldFontColor ||
                              "rgba(0,0,0,0.8)"
                            : element.fieldFontColor || "rgba(0,0,0,0.8)",

                          outline: `${
                            mobileDesigning
                              ? element.mfieldBorderWidth ||
                                element.fieldBorderWidth ||
                                1
                              : element.fieldBorderWidth || 1
                          }px solid ${
                            mobileDesigning
                              ? element.mfieldBorderColor ||
                                element.fieldBorderColor ||
                                "grey"
                              : element.fieldBorderColor || "grey"
                          }`,

                          borderRadius: mobileDesigning
                            ? element.mfieldBorderRadius
                              ? `${element.mfieldBorderRadius}px`
                              : element.fieldBorderRadius
                              ? `${element.fieldBorderRadius}px`
                              : 0
                            : element.fieldBorderRadius
                            ? `${element.fieldBorderRadius}px`
                            : 0,

                          boxShadow: mobileDesigning
                            ? element.mfieldTextShadow ||
                              element.fieldTextShadow ||
                              "0px 0px 0px rgba(0,0,0,0.8)"
                            : element.fieldTextShadow ||
                              "0px 0px 0px rgba(0,0,0,0.8)",

                          background: mobileDesigning
                            ? element.mfieldBackgroundColor ||
                              element.fieldBackgroundColor ||
                              "rgba(0,0,0,0)"
                            : element.fieldBackgroundColor || "rgba(0,0,0,0)",
                        }}
                      />
                    </div>
                    {shippingDetails.country === "" ||
                    shippingDetails.country === null ? null : (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "5px 0px",
                        }}
                      >
                        <div
                          style={{
                            width: "150px",
                            textAlign:
                              (mobileDesigning
                                ? element.mfieldTextAlign || "center"
                                : element.fieldTextAlign || "center") ===
                              "flex-start"
                                ? "left"
                                : (mobileDesigning
                                    ? element.mfieldTextAlign || "center"
                                    : element.fieldTextAlign || "center") ===
                                  "flex-end"
                                ? "right"
                                : "center",

                                fontFamily: mobileDesigning
                                  ? element.mfieldFontFamily ||
                                    element.fieldFontFamily ||
                                    "Roboto"
                                  : element.fieldFontFamily || "Roboto",

                            fontSize: mobileDesigning
                              ? element.mfieldFontSize ||
                                element.fieldFontSize ||
                                14
                              : element.fieldFontSize || 14,

                            fontWeight: mobileDesigning
                              ? element.mfieldFontWeight ||
                                element.fieldFontWeight ||
                                400
                              : element.fieldFontWeight || 400,

                            lineHeight: `${
                              mobileDesigning
                                ? element.mfieldLineHeight ||
                                  element.fieldLineHeight ||
                                  (mobileDesigning
                                    ? element.mfieldFontSize ||
                                      element.fieldFontSize ||
                                      14
                                    : element.fieldFontSize || 14)
                                : element.fieldLineHeight ||
                                  (mobileDesigning
                                    ? element.mfieldFontSize ||
                                      element.fieldFontSize ||
                                      14
                                    : element.fieldFontSize || 14)
                            }px`,

                            letterSpacing: `${
                              mobileDesigning
                                ? element.mfieldLetterSpacing ||
                                  element.fieldLetterSpacing ||
                                  0
                                : element.fieldLetterSpacing || 0
                            }px`,

                            color: mobileDesigning
                              ? element.mfieldFontColor ||
                                element.fieldFontColor ||
                                "rgba(0,0,0,0.8)"
                              : element.fieldFontColor || "rgba(0,0,0,0.8)",
                          }}
                        >
                          State
                        </div>
                        <select
                          placeholder="States"
                          value={shippingDetails.state}
                          onChange={(e) => {
                            const updatingState = { ...shippingDetails };
                            updatingState.state = e.target.value;
                            setShippingDetails(updatingState);
                          }}
                          style={{
                            padding: "8px 15px",
                            width: "calc(100% - 180px)",
                            borderRadius: "10px",
                            border: "none",
                            textAlign:
                              (mobileDesigning
                                ? element.mfieldTextAlign || "center"
                                : element.fieldTextAlign || "center") ===
                              "flex-start"
                                ? "left"
                                : (mobileDesigning
                                    ? element.mfieldTextAlign || "center"
                                    : element.fieldTextAlign || "center") ===
                                  "flex-end"
                                ? "right"
                                : "center",

                                fontFamily: mobileDesigning
                                  ? element.mfieldFontFamily ||
                                    element.fieldFontFamily ||
                                    "Roboto"
                                  : element.fieldFontFamily || "Roboto",

                            fontSize: mobileDesigning
                              ? element.mfieldFontSize ||
                                element.fieldFontSize ||
                                14
                              : element.fieldFontSize || 14,

                            fontWeight: mobileDesigning
                              ? element.mfieldFontWeight ||
                                element.fieldFontWeight ||
                                400
                              : element.fieldFontWeight || 400,

                            lineHeight: `${
                              mobileDesigning
                                ? element.mfieldLineHeight ||
                                  element.fieldLineHeight ||
                                  (mobileDesigning
                                    ? element.mfieldFontSize ||
                                      element.fieldFontSize ||
                                      14
                                    : element.fieldFontSize || 14)
                                : element.fieldLineHeight ||
                                  (mobileDesigning
                                    ? element.mfieldFontSize ||
                                      element.fieldFontSize ||
                                      14
                                    : element.fieldFontSize || 14)
                            }px`,

                            letterSpacing: `${
                              mobileDesigning
                                ? element.mfieldLetterSpacing ||
                                  element.fieldLetterSpacing ||
                                  0
                                : element.fieldLetterSpacing || 0
                            }px`,

                            color: mobileDesigning
                              ? element.mfieldFontColor ||
                                element.fieldFontColor ||
                                "rgba(0,0,0,0.8)"
                              : element.fieldFontColor || "rgba(0,0,0,0.8)",

                            outline: `${
                              mobileDesigning
                                ? element.mfieldBorderWidth ||
                                  element.fieldBorderWidth ||
                                  1
                                : element.fieldBorderWidth || 1
                            }px solid ${
                              mobileDesigning
                                ? element.mfieldBorderColor ||
                                  element.fieldBorderColor ||
                                  "grey"
                                : element.fieldBorderColor || "grey"
                            }`,

                            borderRadius: mobileDesigning
                              ? element.mfieldBorderRadius
                                ? `${element.mfieldBorderRadius}px`
                                : element.fieldBorderRadius
                                ? `${element.fieldBorderRadius}px`
                                : 0
                              : element.fieldBorderRadius
                              ? `${element.fieldBorderRadius}px`
                              : 0,

                            boxShadow: mobileDesigning
                              ? element.mfieldTextShadow ||
                                element.fieldTextShadow ||
                                "0px 0px 0px rgba(0,0,0,0.8)"
                              : element.fieldTextShadow ||
                                "0px 0px 0px rgba(0,0,0,0.8)",

                            background: mobileDesigning
                              ? element.mfieldBackgroundColor ||
                                element.fieldBackgroundColor ||
                                "rgba(0,0,0,0)"
                              : element.fieldBackgroundColor || "rgba(0,0,0,0)",
                          }}
                        >
                          {State.getStatesOfCountry(
                            shippingDetails.country
                          ).map((state) => (
                            <option value={state.isoCode}>{state.name}</option>
                          ))}
                        </select>
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "5px 0px",
                      }}
                    >
                      <div
                        style={{
                          width: "150px",
                          textAlign:
                            (mobileDesigning
                              ? element.mfieldTextAlign || "center"
                              : element.fieldTextAlign || "center") ===
                            "flex-start"
                              ? "left"
                              : (mobileDesigning
                                  ? element.mfieldTextAlign || "center"
                                  : element.fieldTextAlign || "center") ===
                                "flex-end"
                              ? "right"
                              : "center",

                              fontFamily: mobileDesigning
                                ? element.mfieldFontFamily ||
                                  element.fieldFontFamily ||
                                  "Roboto"
                                : element.fieldFontFamily || "Roboto",

                          fontSize: mobileDesigning
                            ? element.mfieldFontSize ||
                              element.fieldFontSize ||
                              14
                            : element.fieldFontSize || 14,

                          fontWeight: mobileDesigning
                            ? element.mfieldFontWeight ||
                              element.fieldFontWeight ||
                              400
                            : element.fieldFontWeight || 400,

                          lineHeight: `${
                            mobileDesigning
                              ? element.mfieldLineHeight ||
                                element.fieldLineHeight ||
                                (mobileDesigning
                                  ? element.mfieldFontSize ||
                                    element.fieldFontSize ||
                                    14
                                  : element.fieldFontSize || 14)
                              : element.fieldLineHeight ||
                                (mobileDesigning
                                  ? element.mfieldFontSize ||
                                    element.fieldFontSize ||
                                    14
                                  : element.fieldFontSize || 14)
                          }px`,

                          letterSpacing: `${
                            mobileDesigning
                              ? element.mfieldLetterSpacing ||
                                element.fieldLetterSpacing ||
                                0
                              : element.fieldLetterSpacing || 0
                          }px`,

                          color: mobileDesigning
                            ? element.mfieldFontColor ||
                              element.fieldFontColor ||
                              "rgba(0,0,0,0.8)"
                            : element.fieldFontColor || "rgba(0,0,0,0.8)",
                        }}
                      >
                        Country
                      </div>
                      <select
                        placeholder="Country"
                        value={shippingDetails.country}
                        onChange={(e) => {
                          const updatingCountry = { ...shippingDetails };
                          updatingCountry.country = e.target.value;
                          updatingCountry.state = State.getStatesOfCountry(
                            e.target.value
                          )[0].isoCode;
                          console.log(State.getStatesOfCountry(e.target.value));
                          setShippingDetails(updatingCountry);
                        }}
                        style={{
                          padding: "8px 15px",
                          width: "calc(100% - 180px)",
                          borderRadius: "10px",
                          border: "none",
                          textAlign:
                            (mobileDesigning
                              ? element.mfieldTextAlign || "center"
                              : element.fieldTextAlign || "center") ===
                            "flex-start"
                              ? "left"
                              : (mobileDesigning
                                  ? element.mfieldTextAlign || "center"
                                  : element.fieldTextAlign || "center") ===
                                "flex-end"
                              ? "right"
                              : "center",

                              fontFamily: mobileDesigning
                                ? element.mfieldFontFamily ||
                                  element.fieldFontFamily ||
                                  "Roboto"
                                : element.fieldFontFamily || "Roboto",

                          fontSize: mobileDesigning
                            ? element.mfieldFontSize ||
                              element.fieldFontSize ||
                              14
                            : element.fieldFontSize || 14,

                          fontWeight: mobileDesigning
                            ? element.mfieldFontWeight ||
                              element.fieldFontWeight ||
                              400
                            : element.fieldFontWeight || 400,

                          lineHeight: `${
                            mobileDesigning
                              ? element.mfieldLineHeight ||
                                element.fieldLineHeight ||
                                (mobileDesigning
                                  ? element.mfieldFontSize ||
                                    element.fieldFontSize ||
                                    14
                                  : element.fieldFontSize || 14)
                              : element.fieldLineHeight ||
                                (mobileDesigning
                                  ? element.mfieldFontSize ||
                                    element.fieldFontSize ||
                                    14
                                  : element.fieldFontSize || 14)
                          }px`,

                          letterSpacing: `${
                            mobileDesigning
                              ? element.mfieldLetterSpacing ||
                                element.fieldLetterSpacing ||
                                0
                              : element.fieldLetterSpacing || 0
                          }px`,

                          color: mobileDesigning
                            ? element.mfieldFontColor ||
                              element.fieldFontColor ||
                              "rgba(0,0,0,0.8)"
                            : element.fieldFontColor || "rgba(0,0,0,0.8)",

                          outline: `${
                            mobileDesigning
                              ? element.mfieldBorderWidth ||
                                element.fieldBorderWidth ||
                                1
                              : element.fieldBorderWidth || 1
                          }px solid ${
                            mobileDesigning
                              ? element.mfieldBorderColor ||
                                element.fieldBorderColor ||
                                "grey"
                              : element.fieldBorderColor || "grey"
                          }`,

                          borderRadius: mobileDesigning
                            ? element.mfieldBorderRadius
                              ? `${element.mfieldBorderRadius}px`
                              : element.fieldBorderRadius
                              ? `${element.fieldBorderRadius}px`
                              : 0
                            : element.fieldBorderRadius
                            ? `${element.fieldBorderRadius}px`
                            : 0,

                          boxShadow: mobileDesigning
                            ? element.mfieldTextShadow ||
                              element.fieldTextShadow ||
                              "0px 0px 0px rgba(0,0,0,0.8)"
                            : element.fieldTextShadow ||
                              "0px 0px 0px rgba(0,0,0,0.8)",

                          background: mobileDesigning
                            ? element.mfieldBackgroundColor ||
                              element.fieldBackgroundColor ||
                              "rgba(0,0,0,0)"
                            : element.fieldBackgroundColor || "rgba(0,0,0,0)",
                        }}
                      >
                        {Country.getAllCountries().map((country) => (
                          <option value={country.isoCode}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "calc(40% - 40px)",
                      padding: "30px 0px 30px 40px",
                    }}
                  >
                    <div
                      style={{
                        marginBottom: "20px",

                        fontFamily: mobileDesigning
                          ? element.mtitleFontFamily ||
                            element.titleFontFamily ||
                            "Roboto"
                          : element.titleFontFamily || "Roboto",

                        fontSize: mobileDesigning
                          ? element.mtitleFontSize ||
                            element.titleFontSize ||
                            30
                          : element.titleFontSize || 30,

                        fontWeight: mobileDesigning
                          ? element.mtitleFontWeight ||
                            element.titleFontWeight ||
                            700
                          : element.titleFontWeight || 700,

                        lineHeight: `${
                          mobileDesigning
                            ? element.mtitleLineHeight ||
                              element.titleLineHeight ||
                              (mobileDesigning
                                ? element.mtitleFontSize ||
                                  element.titleFontSize ||
                                  20
                                : element.titleFontSize || 20)
                            : element.titleLineHeight ||
                              (mobileDesigning
                                ? element.mtitleFontSize ||
                                  element.titleFontSize ||
                                  20
                                : element.titleFontSize || 20)
                        }px`,

                        letterSpacing: `${
                          mobileDesigning
                            ? element.mtitleLetterSpacing ||
                              element.titleLetterSpacing ||
                              0
                            : element.titleLetterSpacing || 0
                        }px`,

                        color: mobileDesigning
                          ? element.mtitleFontColor ||
                            element.titleFontColor ||
                            "rgba(0,0,0,0.8)"
                          : element.titleFontColor || "rgba(0,0,0,0.8)",

                        textShadow: mobileDesigning
                          ? element.mtitleTextShadow ||
                            element.titleTextShadow ||
                            "0px 0px 0px rgba(0,0,0,0.8)"
                          : element.titleTextShadow ||
                            "0px 0px 0px rgba(0,0,0,0.8)",
                      }}
                    >
                      Cart totals
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "10px 0px",
                        width: "100%",
                        borderTop: "1px solid lightgrey",
                        borderBottom: "1px solid lightgrey",
                        textAlign:
                          (mobileDesigning
                            ? element.mfieldTextAlign || "center"
                            : element.fieldTextAlign || "center") ===
                          "flex-start"
                            ? "left"
                            : (mobileDesigning
                                ? element.mfieldTextAlign || "center"
                                : element.fieldTextAlign || "center") ===
                              "flex-end"
                            ? "right"
                            : "center",

                            fontFamily: mobileDesigning
                              ? element.mfieldFontFamily ||
                                element.fieldFontFamily ||
                                "Roboto"
                              : element.fieldFontFamily || "Roboto",

                        fontSize: mobileDesigning
                          ? element.mfieldFontSize ||
                            element.fieldFontSize ||
                            14
                          : element.fieldFontSize || 14,

                        fontWeight: mobileDesigning
                          ? element.mfieldFontWeight ||
                            element.fieldFontWeight ||
                            400
                          : element.fieldFontWeight || 400,

                        lineHeight: `${
                          mobileDesigning
                            ? element.mfieldLineHeight ||
                              element.fieldLineHeight ||
                              (mobileDesigning
                                ? element.mfieldFontSize ||
                                  element.fieldFontSize ||
                                  14
                                : element.fieldFontSize || 14)
                            : element.fieldLineHeight ||
                              (mobileDesigning
                                ? element.mfieldFontSize ||
                                  element.fieldFontSize ||
                                  14
                                : element.fieldFontSize || 14)
                        }px`,

                        letterSpacing: `${
                          mobileDesigning
                            ? element.mfieldLetterSpacing ||
                              element.fieldLetterSpacing ||
                              0
                            : element.fieldLetterSpacing || 0
                        }px`,

                        color: mobileDesigning
                          ? element.mfieldFontColor ||
                            element.fieldFontColor ||
                            "rgba(0,0,0,0.8)"
                          : element.fieldFontColor || "rgba(0,0,0,0.8)",
                      }}
                    >
                      <div>Subtotal</div>
                      <div style={{ fontWeight: 600 }}>
                        {customerAccountDetails.cart
                          ? currency +
                            " " +
                            calculateTotalCart(
                              customerAccountDetails.cart
                            ).toLocaleString("en-US", {
                              style: "decimal",
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "$ 0.00"}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        padding: "10px 0px",
                        width: "100%",
                        borderBottom: "1px solid lightgrey",
                        alignItems: "center",
                        justifyContent: "space-between",
                        textAlign:
                          (mobileDesigning
                            ? element.mfieldTextAlign || "center"
                            : element.fieldTextAlign || "center") ===
                          "flex-start"
                            ? "left"
                            : (mobileDesigning
                                ? element.mfieldTextAlign || "center"
                                : element.fieldTextAlign || "center") ===
                              "flex-end"
                            ? "right"
                            : "center",

                            fontFamily: mobileDesigning
                              ? element.mfieldFontFamily ||
                                element.fieldFontFamily ||
                                "Roboto"
                              : element.fieldFontFamily || "Roboto",

                        fontSize: mobileDesigning
                          ? element.mfieldFontSize ||
                            element.fieldFontSize ||
                            14
                          : element.fieldFontSize || 14,

                        fontWeight: mobileDesigning
                          ? element.mfieldFontWeight ||
                            element.fieldFontWeight ||
                            400
                          : element.fieldFontWeight || 400,

                        lineHeight: `${
                          mobileDesigning
                            ? element.mfieldLineHeight ||
                              element.fieldLineHeight ||
                              (mobileDesigning
                                ? element.mfieldFontSize ||
                                  element.fieldFontSize ||
                                  14
                                : element.fieldFontSize || 14)
                            : element.fieldLineHeight ||
                              (mobileDesigning
                                ? element.mfieldFontSize ||
                                  element.fieldFontSize ||
                                  14
                                : element.fieldFontSize || 14)
                        }px`,

                        letterSpacing: `${
                          mobileDesigning
                            ? element.mfieldLetterSpacing ||
                              element.fieldLetterSpacing ||
                              0
                            : element.fieldLetterSpacing || 0
                        }px`,

                        color: mobileDesigning
                          ? element.mfieldFontColor ||
                            element.fieldFontColor ||
                            "rgba(0,0,0,0.8)"
                          : element.fieldFontColor || "rgba(0,0,0,0.8)",
                      }}
                    >
                      <div>Shipping</div>
                      <div style={{ fontWeight: 600 }}>
                        {shippingDetails.state !== "" &&
                        shippingDetails.country !== null
                          ? handleShippingCalculation() === "Free shipping" ||
                            handleShippingCalculation() === "Disable purchase"
                            ? handleShippingCalculation()
                            : currency +
                              " " +
                              handleShippingCalculation().toLocaleString(
                                "en-US",
                                {
                                  style: "decimal",
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                          : "Fill in delivery details"}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        padding: "10px 0px",
                        width: "100%",
                        alignItems: "center",
                        justifyContent: "space-between",
                        textAlign:
                          (mobileDesigning
                            ? element.mfieldTextAlign || "center"
                            : element.fieldTextAlign || "center") ===
                          "flex-start"
                            ? "left"
                            : (mobileDesigning
                                ? element.mfieldTextAlign || "center"
                                : element.fieldTextAlign || "center") ===
                              "flex-end"
                            ? "right"
                            : "center",

                            fontFamily: mobileDesigning
                              ? element.mfieldFontFamily ||
                                element.fieldFontFamily ||
                                "Roboto"
                              : element.fieldFontFamily || "Roboto",

                        fontSize: mobileDesigning
                          ? element.mfieldFontSize ||
                            element.fieldFontSize ||
                            14
                          : element.fieldFontSize || 14,

                        fontWeight: mobileDesigning
                          ? element.mfieldFontWeight ||
                            element.fieldFontWeight ||
                            400
                          : element.fieldFontWeight || 400,

                        lineHeight: `${
                          mobileDesigning
                            ? element.mfieldLineHeight ||
                              element.fieldLineHeight ||
                              (mobileDesigning
                                ? element.mfieldFontSize ||
                                  element.fieldFontSize ||
                                  14
                                : element.fieldFontSize || 14)
                            : element.fieldLineHeight ||
                              (mobileDesigning
                                ? element.mfieldFontSize ||
                                  element.fieldFontSize ||
                                  14
                                : element.fieldFontSize || 14)
                        }px`,

                        letterSpacing: `${
                          mobileDesigning
                            ? element.mfieldLetterSpacing ||
                              element.fieldLetterSpacing ||
                              0
                            : element.fieldLetterSpacing || 0
                        }px`,

                        color: mobileDesigning
                          ? element.mfieldFontColor ||
                            element.fieldFontColor ||
                            "rgba(0,0,0,0.8)"
                          : element.fieldFontColor || "rgba(0,0,0,0.8)",
                      }}
                    >
                      <div>Total</div>
                      <div style={{ fontWeight: 600 }}>
                        {customerAccountDetails.cart
                          ? handleShippingCalculation() === "Free shipping"
                            ? currency +
                              " " +
                              calculateTotalCart(
                                customerAccountDetails.cart
                              ).toLocaleString("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : handleShippingCalculation() === "Disable purchase"
                            ? "Store doesn't ship to this country"
                            : currency +
                              " " +
                              (
                                calculateTotalCart(
                                  customerAccountDetails.cart
                                ) + handleShippingCalculation()
                              ).toLocaleString("en-US", {
                                style: "decimal",
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                          : `${currency} 0.00`}
                      </div>
                    </div>
                  </div>
                </div>
              ) : currentState === 2 ? (
                clientSecret ? (
                  stripeObject ? (
                    <Elements options={options} stripe={stripeObject}>
                      <CheckoutForm />
                    </Elements>
                  ) : null
                ) : null
              ) : currentState === 3 ? (
                <div>
                  <div
                    style={{
                      marginTop: "40px",
                      textAlign:
                        (mobileDesigning
                          ? element.mtitleTextAlign || "center"
                          : element.titleTextAlign || "center") === "flex-start"
                          ? "left"
                          : (mobileDesigning
                              ? element.mtitleTextAlign || "center"
                              : element.titleTextAlign || "center") ===
                            "flex-end"
                          ? "right"
                          : "center",

                          fontFamily: mobileDesigning
                            ? element.mtitleFontFamily ||
                              element.titleFontFamily ||
                              "Roboto"
                            : element.titleFontFamily || "Roboto",

                      fontSize: mobileDesigning
                        ? element.mtitleFontSize || element.titleFontSize || 30
                        : element.titleFontSize || 30,

                      fontWeight: mobileDesigning
                        ? element.mtitleFontWeight ||
                          element.titleFontWeight ||
                          700
                        : element.titleFontWeight || 700,

                      lineHeight: `${
                        mobileDesigning
                          ? element.mtitleLineHeight ||
                            element.titleLineHeight ||
                            (mobileDesigning
                              ? element.mtitleFontSize ||
                                element.titleFontSize ||
                                20
                              : element.titleFontSize || 20)
                          : element.titleLineHeight ||
                            (mobileDesigning
                              ? element.mtitleFontSize ||
                                element.titleFontSize ||
                                20
                              : element.titleFontSize || 20)
                      }px`,

                      letterSpacing: `${
                        mobileDesigning
                          ? element.mtitleLetterSpacing ||
                            element.titleLetterSpacing ||
                            0
                          : element.titleLetterSpacing || 0
                      }px`,

                      color: mobileDesigning
                        ? element.mtitleFontColor ||
                          element.titleFontColor ||
                          "rgba(0,0,0,0.8)"
                        : element.titleFontColor || "rgba(0,0,0,0.8)",

                      textShadow: mobileDesigning
                        ? element.mtitleTextShadow ||
                          element.titleTextShadow ||
                          "0px 0px 0px rgba(0,0,0,0.8)"
                        : element.titleTextShadow ||
                          "0px 0px 0px rgba(0,0,0,0.8)",
                    }}
                  >
                    {message === "Success"
                      ? "Payment Successful!"
                      : message === "Processing"
                      ? "Payment Processing"
                      : "Payment Failed"}
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div
                      style={{
                        maxWidth: "500px",
                        margin: "10px 0px 40px 0px",
                        textAlign:
                          (mobileDesigning
                            ? element.mdescriptionTextAlign || "center"
                            : element.descriptionTextAlign || "center") ===
                          "flex-start"
                            ? "left"
                            : (mobileDesigning
                                ? element.mdescriptionTextAlign || "center"
                                : element.descriptionTextAlign || "center") ===
                              "flex-end"
                            ? "right"
                            : "center",

                            fontFamily: mobileDesigning
                              ? element.mdescriptionFontFamily ||
                                element.descriptionFontFamily ||
                                "Roboto"
                              : element.descriptionFontFamily || "Roboto",

                        fontSize: mobileDesigning
                          ? element.mdescriptionFontSize ||
                            element.descriptionFontSize ||
                            14
                          : element.descriptionFontSize || 14,

                        fontWeight: mobileDesigning
                          ? element.mdescriptionFontWeight ||
                            element.descriptionFontWeight ||
                            400
                          : element.descriptionFontWeight || 400,

                        lineHeight: `${
                          mobileDesigning
                            ? element.mdescriptionlineHeight ||
                              element.descriptionlineHeight ||
                              (mobileDesigning
                                ? element.mdescriptionFontSize ||
                                  element.descriptionFontSize ||
                                  14
                                : element.descriptionFontSize || 14)
                            : element.descriptionlineHeight ||
                              (mobileDesigning
                                ? element.mdescriptionFontSize ||
                                  element.descriptionFontSize ||
                                  14
                                : element.descriptionFontSize || 14)
                        }px`,

                        letterSpacing: `${
                          mobileDesigning
                            ? element.mdescriptionLetterSpacing ||
                              element.descriptionLetterSpacing ||
                              0
                            : element.descriptionLetterSpacing || 0
                        }px`,

                        color: mobileDesigning
                          ? element.mdescriptionFontColor ||
                            element.descriptionFontColor ||
                            "rgba(0,0,0,0.8)"
                          : element.descriptionFontColor || "rgba(0,0,0,0.8)",

                        textShadow: mobileDesigning
                          ? element.mdescriptionTextShadow ||
                            element.descriptionTextShadow ||
                            "0px 0px 0px rgba(0,0,0,0.8)"
                          : element.descriptionTextShadow ||
                            "0px 0px 0px rgba(0,0,0,0.8)",
                      }}
                    >
                      {message === "Success"
                        ? "Your payment is successful, you should receive an email shortly notifying that your payment has successfully gone through and our team is preparing your order"
                        : message === "Processing"
                        ? "Your payment is current being processed and you should receive an notification on your email as soon as we received your order payment."
                        : "Sadly your payment didn't went through, you can reach your banking provider and check on why the payment didn't went through, if you would like to retry the payment, go ahead an try placing another order."}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>

            {currentState === 3 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  className="mui-button"
                  onClick={() => {
                    setPageDesigning("Home");
                    const urlSearchParams = new URLSearchParams(
                      window.location.search
                    );
                    urlSearchParams.delete("payment_intent");
                    urlSearchParams.delete("payment_intent_client_secret");
                    urlSearchParams.delete("redirect_status");

                    // Replace the URL with the modified one (without the parameter)
                    window.history.replaceState(
                      null,
                      null,
                      `?${urlSearchParams.toString()}`
                    );
                  }}
                  style={{
                    textAlign:
                      (mobileDesigning
                        ? element.mbuttonTextAlign || "center"
                        : element.buttonTextAlign || "center") === "flex-start"
                        ? "left"
                        : (mobileDesigning
                            ? element.mbuttonTextAlign || "center"
                            : element.buttonTextAlign || "center") ===
                          "flex-end"
                        ? "right"
                        : "center",

                        fontFamily: mobileDesigning
                          ? element.mbuttonFontFamily ||
                            element.buttonFontFamily ||
                            "Roboto"
                          : element.buttonFontFamily || "Roboto",

                    fontSize: mobileDesigning
                      ? element.mbuttonFontSize || element.buttonFontSize || 14
                      : element.buttonFontSize || 14,

                    fontWeight: mobileDesigning
                      ? element.mbuttonFontWeight ||
                        element.buttonFontWeight ||
                        700
                      : element.buttonFontWeight || 700,

                    lineHeight: `${
                      mobileDesigning
                        ? element.mbuttonLineHeight ||
                          element.buttonLineHeight ||
                          (mobileDesigning
                            ? element.mbuttonFontSize ||
                              element.buttonFontSize ||
                              14
                            : element.buttonFontSize || 14)
                        : element.buttonLineHeight ||
                          (mobileDesigning
                            ? element.mbuttonFontSize ||
                              element.buttonFontSize ||
                              14
                            : element.buttonFontSize || 14)
                    }px`,

                    letterSpacing: `${
                      mobileDesigning
                        ? element.mbuttonLetterSpacing ||
                          element.buttonLetterSpacing ||
                          0
                        : element.buttonLetterSpacing || 0
                    }px`,

                    color: mobileDesigning
                      ? element.mbuttonFontColor ||
                        element.buttonFontColor ||
                        "rgba(0,0,0,0.8)"
                      : element.buttonFontColor || "rgba(0,0,0,0.8)",

                    boxShadow: mobileDesigning
                      ? element.mbuttonBoxShadow ||
                        element.buttonBoxShadow ||
                        "0px 0px 0px rgba(0,0,0,0.8)"
                      : element.buttonBoxShadow ||
                        "0px 0px 0px rgba(0,0,0,0.8)",

                    border: "none",

                    borderRadius: mobileDesigning
                      ? element.mbuttonBorderRadius
                        ? `${element.mbuttonBorderRadius}px`
                        : element.buttonBorderRadius
                        ? `${element.buttonBorderRadius}px`
                        : 30
                      : element.buttonBorderRadius
                      ? `${element.buttonBorderRadius}px`
                      : 30,

                    outline: `${
                      mobileDesigning
                        ? element.mbuttonBorderWidth ||
                          element.buttonBorderWidth ||
                          1
                        : element.buttonBorderWidth || 1
                    }px solid ${
                      mobileDesigning
                        ? element.mbuttonBorderColor ||
                          element.buttonBorderColor ||
                          "grey"
                        : element.buttonBorderColor || "grey"
                    }`,

                    background: mobileDesigning
                      ? element.mbuttonBackgroundColor ||
                        element.buttonBackgroundColor ||
                        "transparent"
                      : element.buttonBackgroundColor || "transparent",

                    padding: "8px 30px",
                  }}
                >
                  Back to website
                </div>
              </div>
            ) : null}
            <div
              className="p-checkout-bottom-row"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <div className="p-checkout-bottom-row-left-margin">
                {currentState !== 0 && currentState !== 3 ? (
                  <div
                    className="mui-button"
                    onClick={() => {
                      setCurrentState(currentState - 1);
                    }}
                    style={{
                      textAlign:
                        (mobileDesigning
                          ? element.mbuttonTextAlign || "center"
                          : element.buttonTextAlign || "center") ===
                        "flex-start"
                          ? "left"
                          : (mobileDesigning
                              ? element.mbuttonTextAlign || "center"
                              : element.buttonTextAlign || "center") ===
                            "flex-end"
                          ? "right"
                          : "center",

                          fontFamily: mobileDesigning
                            ? element.mbuttonFontFamily ||
                              element.buttonFontFamily ||
                              "Roboto"
                            : element.buttonFontFamily || "Roboto",

                      fontSize: mobileDesigning
                        ? element.mbuttonFontSize ||
                          element.buttonFontSize ||
                          14
                        : element.buttonFontSize || 14,

                      fontWeight: mobileDesigning
                        ? element.mbuttonFontWeight ||
                          element.buttonFontWeight ||
                          700
                        : element.buttonFontWeight || 700,

                      lineHeight: `${
                        mobileDesigning
                          ? element.mbuttonLineHeight ||
                            element.buttonLineHeight ||
                            (mobileDesigning
                              ? element.mbuttonFontSize ||
                                element.buttonFontSize ||
                                14
                              : element.buttonFontSize || 14)
                          : element.buttonLineHeight ||
                            (mobileDesigning
                              ? element.mbuttonFontSize ||
                                element.buttonFontSize ||
                                14
                              : element.buttonFontSize || 14)
                      }px`,

                      letterSpacing: `${
                        mobileDesigning
                          ? element.mbuttonLetterSpacing ||
                            element.buttonLetterSpacing ||
                            0
                          : element.buttonLetterSpacing || 0
                      }px`,

                      color: mobileDesigning
                        ? element.mbuttonFontColor ||
                          element.buttonFontColor ||
                          "rgba(0,0,0,0.8)"
                        : element.buttonFontColor || "rgba(0,0,0,0.8)",

                      boxShadow: mobileDesigning
                        ? element.mbuttonBoxShadow ||
                          element.buttonBoxShadow ||
                          "0px 0px 0px rgba(0,0,0,0.8)"
                        : element.buttonBoxShadow ||
                          "0px 0px 0px rgba(0,0,0,0.8)",

                      border: "none",

                      borderRadius: mobileDesigning
                        ? element.mbuttonBorderRadius
                          ? `${element.mbuttonBorderRadius}px`
                          : element.buttonBorderRadius
                          ? `${element.buttonBorderRadius}px`
                          : 30
                        : element.buttonBorderRadius
                        ? `${element.buttonBorderRadius}px`
                        : 30,

                      outline: `${
                        mobileDesigning
                          ? element.mbuttonBorderWidth ||
                            element.buttonBorderWidth ||
                            1
                          : element.buttonBorderWidth || 1
                      }px solid ${
                        mobileDesigning
                          ? element.mbuttonBorderColor ||
                            element.buttonBorderColor ||
                            "grey"
                          : element.buttonBorderColor || "grey"
                      }`,

                      background: mobileDesigning
                        ? element.mbuttonBackgroundColor ||
                          element.buttonBackgroundColor ||
                          "transparent"
                        : element.buttonBackgroundColor || "transparent",

                      padding: "8px 30px",
                    }}
                  >
                    Back
                  </div>
                ) : null}
              </div>
              <div className="p-checkout-bottom-next-button-holder">
                {currentState < 2 ? (
                  currentState === 0 ? (
                    <>
                      <div
                        className="mui-button p-checkout-next-button"
                        onClick={() => {
                          setCurrentState(currentState + 1);
                          console.log(customerAccountDetails);
                        }}
                        style={{
                          textAlign:
                            (mobileDesigning
                              ? element.mbuttonTextAlign || "center"
                              : element.buttonTextAlign || "center") ===
                            "flex-start"
                              ? "left"
                              : (mobileDesigning
                                  ? element.mbuttonTextAlign || "center"
                                  : element.buttonTextAlign || "center") ===
                                "flex-end"
                              ? "right"
                              : "center",

                              fontFamily: mobileDesigning
                                ? element.mbuttonFontFamily ||
                                  element.buttonFontFamily ||
                                  "Roboto"
                                : element.buttonFontFamily || "Roboto",

                          fontSize: mobileDesigning
                            ? element.mbuttonFontSize ||
                              element.buttonFontSize ||
                              14
                            : element.buttonFontSize || 14,

                          fontWeight: mobileDesigning
                            ? element.mbuttonFontWeight ||
                              element.buttonFontWeight ||
                              700
                            : element.buttonFontWeight || 700,

                          lineHeight: `${
                            mobileDesigning
                              ? element.mbuttonLineHeight ||
                                element.buttonLineHeight ||
                                (mobileDesigning
                                  ? element.mbuttonFontSize ||
                                    element.buttonFontSize ||
                                    14
                                  : element.buttonFontSize || 14)
                              : element.buttonLineHeight ||
                                (mobileDesigning
                                  ? element.mbuttonFontSize ||
                                    element.buttonFontSize ||
                                    14
                                  : element.buttonFontSize || 14)
                          }px`,

                          letterSpacing: `${
                            mobileDesigning
                              ? element.mbuttonLetterSpacing ||
                                element.buttonLetterSpacing ||
                                0
                              : element.buttonLetterSpacing || 0
                          }px`,

                          color: mobileDesigning
                            ? element.mbuttonFontColor ||
                              element.buttonFontColor ||
                              "rgba(0,0,0,0.8)"
                            : element.buttonFontColor || "rgba(0,0,0,0.8)",

                          boxShadow: mobileDesigning
                            ? element.mbuttonBoxShadow ||
                              element.buttonBoxShadow ||
                              "0px 0px 0px rgba(0,0,0,0.8)"
                            : element.buttonBoxShadow ||
                              "0px 0px 0px rgba(0,0,0,0.8)",

                          border: "none",

                          borderRadius: mobileDesigning
                            ? element.mbuttonBorderRadius
                              ? `${element.mbuttonBorderRadius}px`
                              : element.buttonBorderRadius
                              ? `${element.buttonBorderRadius}px`
                              : 30
                            : element.buttonBorderRadius
                            ? `${element.buttonBorderRadius}px`
                            : 30,

                          outline: `${
                            mobileDesigning
                              ? element.mbuttonBorderWidth ||
                                element.buttonBorderWidth ||
                                1
                              : element.buttonBorderWidth || 1
                          }px solid ${
                            mobileDesigning
                              ? element.mbuttonBorderColor ||
                                element.buttonBorderColor ||
                                "grey"
                              : element.buttonBorderColor || "grey"
                          }`,

                          background: mobileDesigning
                            ? element.mbuttonBackgroundColor ||
                              element.buttonBackgroundColor ||
                              "transparent"
                            : element.buttonBackgroundColor || "transparent",

                          padding: "8px 30px",
                        }}
                      >
                        {customerAccountDetails.id
                          ? "Next"
                          : "Proceed as guest"}
                      </div>
                      {customerToken ? null : (
                        <div
                          className="mui-button"
                          onClick={() => {
                            setLoginState(true);
                          }}
                          style={{
                            textDecoration: "underline",
                            fontSize: "10px",
                            cursor: "pointer",
                          }}
                        >
                          Login/signup to account
                        </div>
                      )}
                    </>
                  ) : currentState === 1 ? (
                    shippingDetails.full_name !== "" &&
                    shippingDetails.email_address !== "" &&
                    shippingDetails.phone_number !== "" &&
                    shippingDetails.address !== "" &&
                    shippingDetails.zip_code !== "" &&
                    shippingDetails.state !== "" &&
                    shippingDetails.country !== "" ? (
                      handleShippingCalculation() !== "Disable purchase" ? (
                        <div
                          className="mui-button"
                          onClick={() => {
                            setCurrentState(currentState + 1);
                          }}
                          style={{
                            textAlign:
                              (mobileDesigning
                                ? element.mbuttonTextAlign || "center"
                                : element.buttonTextAlign || "center") ===
                              "flex-start"
                                ? "left"
                                : (mobileDesigning
                                    ? element.mbuttonTextAlign || "center"
                                    : element.buttonTextAlign || "center") ===
                                  "flex-end"
                                ? "right"
                                : "center",

                                fontFamily: mobileDesigning
                                  ? element.mbuttonFontFamily ||
                                    element.buttonFontFamily ||
                                    "Roboto"
                                  : element.buttonFontFamily || "Roboto",

                            fontSize: mobileDesigning
                              ? element.mbuttonFontSize ||
                                element.buttonFontSize ||
                                14
                              : element.buttonFontSize || 14,

                            fontWeight: mobileDesigning
                              ? element.mbuttonFontWeight ||
                                element.buttonFontWeight ||
                                700
                              : element.buttonFontWeight || 700,

                            lineHeight: `${
                              mobileDesigning
                                ? element.mbuttonLineHeight ||
                                  element.buttonLineHeight ||
                                  (mobileDesigning
                                    ? element.mbuttonFontSize ||
                                      element.buttonFontSize ||
                                      14
                                    : element.buttonFontSize || 14)
                                : element.buttonLineHeight ||
                                  (mobileDesigning
                                    ? element.mbuttonFontSize ||
                                      element.buttonFontSize ||
                                      14
                                    : element.buttonFontSize || 14)
                            }px`,

                            letterSpacing: `${
                              mobileDesigning
                                ? element.mbuttonLetterSpacing ||
                                  element.buttonLetterSpacing ||
                                  0
                                : element.buttonLetterSpacing || 0
                            }px`,

                            background: mobileDesigning
                              ? element.mbuttonFontColor ||
                                element.buttonFontColor ||
                                "rgba(0,0,0,0.8)"
                              : element.buttonFontColor || "rgba(0,0,0,0.8)",

                            boxShadow: mobileDesigning
                              ? element.mbuttonBoxShadow ||
                                element.buttonBoxShadow ||
                                "0px 0px 0px rgba(0,0,0,0.8)"
                              : element.buttonBoxShadow ||
                                "0px 0px 0px rgba(0,0,0,0.8)",

                            border: "none",

                            borderRadius: mobileDesigning
                              ? element.mbuttonBorderRadius
                                ? `${element.mbuttonBorderRadius}px`
                                : element.buttonBorderRadius
                                ? `${element.buttonBorderRadius}px`
                                : 30
                              : element.buttonBorderRadius
                              ? `${element.buttonBorderRadius}px`
                              : 30,

                            outline: `${
                              mobileDesigning
                                ? element.mbuttonBorderWidth ||
                                  element.buttonBorderWidth ||
                                  1
                                : element.buttonBorderWidth || 1
                            }px solid ${
                              mobileDesigning
                                ? element.mbuttonBorderColor ||
                                  element.buttonBorderColor ||
                                  "grey"
                                : element.buttonBorderColor || "grey"
                            }`,

                            color: "white",

                            padding: "8px 30px",
                          }}
                        >
                          Proceed to payment
                        </div>
                      ) : null
                    ) : null
                  ) : (
                    <div
                      className="mui-button"
                      onClick={() => {
                        setCurrentState(currentState + 1);
                      }}
                      style={{
                        textAlign:
                          (mobileDesigning
                            ? element.mbuttonTextAlign || "center"
                            : element.buttonTextAlign || "center") ===
                          "flex-start"
                            ? "left"
                            : (mobileDesigning
                                ? element.mbuttonTextAlign || "center"
                                : element.buttonTextAlign || "center") ===
                              "flex-end"
                            ? "right"
                            : "center",

                            fontFamily: mobileDesigning
                              ? element.mbuttonFontFamily ||
                                element.buttonFontFamily ||
                                "Roboto"
                              : element.buttonFontFamily || "Roboto",

                        fontSize: mobileDesigning
                          ? element.mbuttonFontSize ||
                            element.buttonFontSize ||
                            14
                          : element.buttonFontSize || 14,

                        fontWeight: mobileDesigning
                          ? element.mbuttonFontWeight ||
                            element.buttonFontWeight ||
                            700
                          : element.buttonFontWeight || 700,

                        lineHeight: `${
                          mobileDesigning
                            ? element.mbuttonLineHeight ||
                              element.buttonLineHeight ||
                              (mobileDesigning
                                ? element.mbuttonFontSize ||
                                  element.buttonFontSize ||
                                  14
                                : element.buttonFontSize || 14)
                            : element.buttonLineHeight ||
                              (mobileDesigning
                                ? element.mbuttonFontSize ||
                                  element.buttonFontSize ||
                                  14
                                : element.buttonFontSize || 14)
                        }px`,

                        letterSpacing: `${
                          mobileDesigning
                            ? element.mbuttonLetterSpacing ||
                              element.buttonLetterSpacing ||
                              0
                            : element.buttonLetterSpacing || 0
                        }px`,

                        background: mobileDesigning
                          ? element.mbuttonFontColor ||
                            element.buttonFontColor ||
                            "rgba(0,0,0,0.8)"
                          : element.buttonFontColor || "rgba(0,0,0,0.8)",

                        boxShadow: mobileDesigning
                          ? element.mbuttonBoxShadow ||
                            element.buttonBoxShadow ||
                            "0px 0px 0px rgba(0,0,0,0.8)"
                          : element.buttonBoxShadow ||
                            "0px 0px 0px rgba(0,0,0,0.8)",

                        border: "none",

                        borderRadius: mobileDesigning
                          ? element.mbuttonBorderRadius
                            ? `${element.mbuttonBorderRadius}px`
                            : element.buttonBorderRadius
                            ? `${element.buttonBorderRadius}px`
                            : 30
                          : element.buttonBorderRadius
                          ? `${element.buttonBorderRadius}px`
                          : 30,

                        outline: `${
                          mobileDesigning
                            ? element.mbuttonBorderWidth ||
                              element.buttonBorderWidth ||
                              1
                            : element.buttonBorderWidth || 1
                        }px solid ${
                          mobileDesigning
                            ? element.mbuttonBorderColor ||
                              element.buttonBorderColor ||
                              "grey"
                            : element.buttonBorderColor || "grey"
                        }`,

                        color: mobileDesigning
                          ? element.mbuttonBackgroundColor ||
                            element.buttonBackgroundColor ||
                            "transparent"
                          : element.buttonBackgroundColor || "transparent",

                        padding: "8px 30px",
                      }}
                    >
                      Confirm
                    </div>
                  )
                ) : null}
              </div>
            </div>
          </>
        )}
        {commerceDetails ? null : (
          <div
            style={{
              background: "rgba(0,0,0,0.4)",
              height: "100%",
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ErrorOutlineIcon style={{ color: "white", fontSize: "60px" }} />
            <div
              style={{
                fontSize: "20px",
                color: "white",
                fontWeight: "600",
                marginTop: "10px",
              }}
            >
              Commerce details setup
            </div>
            <div
              style={{
                fontSize: "20px",
                color: "white",
                fontWeight: "600",
                marginTop: "-10px",
              }}
            >
              Not found
            </div>
            <div
              style={{
                fontSize: "12px",
                color: "white",
                fontWeight: "300",
                marginTop: "10px",
                textAlign: "center",
                lineHeight: "14px",
              }}
            >
              Head over to your settings to configure your payment
              <br /> methods and your delivery options in order to allow users
              <br />
              to make an order on your website
            </div>
          </div>
        )}
      </div>
      <div
        className="resize-handle"
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          cursor: "se-resize",
          background: "white",
          border: "1px solid #5e86c4",
          height: "10px",
          width: "10px",
          transform: "translate(50%, 50%)",
          opacity: focus ? 1 : 0,
          borderRadius: "50%",
          touchAction: "none",
        }}
        draggable
        onDragStart={(event) => handleResizeStart(event)}
        onDrag={(event) => handleResize(event)}
        onTouchStart={(event) => {
          const touch = event.touches[0];
          handleResizeStart(event, touch);
        }}
        onTouchMove={(event) => {
          const touch = event.touches[0];
          handleResize(event, touch);
        }}
        onDragEnd={handleResizeEnd}
      />
      <div
        className="resize-handle"
        style={{
          position: "absolute",
          bottom: "50%",
          right: 0,
          cursor: "e-resize",
          background: "white",
          border: "1px solid #5e86c4",
          height: "10px",
          width: "10px",
          transform: "translate(50%, 50%)",
          opacity: focus ? 1 : 0,
          borderRadius: "50%",
          touchAction: "none",
        }}
        draggable
        onDragStart={(event) => handleResizeStart(event)}
        onDrag={(event) => handleResizeX(event)}
        onTouchStart={(event) => {
          const touch = event.touches[0];
          handleResizeStart(event, touch);
        }}
        onTouchMove={(event) => {
          const touch = event.touches[0];
          handleResizeX(event, touch);
        }}
        onDragEnd={handleResizeEnd}
      />
      <div
        className="resize-handle"
        style={{
          position: "absolute",
          bottom: 0,
          right: "50%",
          cursor: "n-resize",
          background: "white",
          border: "1px solid #5e86c4",
          height: "10px",
          width: "10px",
          transform: "translate(50%, 50%)",
          opacity: focus ? 1 : 0,
          borderRadius: "50%",
          touchAction: "none",
        }}
        draggable
        onDragStart={(event) => handleResizeStart(event)}
        onDrag={(event) => handleResizeY(event)}
        onTouchStart={(event) => {
          const touch = event.touches[0];
          handleResizeStart(event, touch);
        }}
        onTouchMove={(event) => {
          const touch = event.touches[0];
          handleResizeY(event, touch);
        }}
        onDragEnd={handleResizeEnd}
      />
    </div>
  );
};

export default DynamicCheckoutElement;
