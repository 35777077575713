import React, { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Calendar from "react-calendar";
import SearchIcon from "@mui/icons-material/Search";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { CircularProgress } from "@mui/material";
import axios from "axios";
import imageReference from "./images/imagePlaceholder.png";
import { TimePicker, DatePicker } from "@mui/x-date-pickers";
import AddIcon from "@mui/icons-material/Add";
import Cookies from "js-cookie";
import { v4 as uuid } from "uuid";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LeakAddIcon from "@mui/icons-material/LeakAdd";
import { ToastContainer, toast } from "react-toastify";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
const Events = (props) => {
  const [currency, setCurrency] = useState("$");
  useEffect(() => {
    const getCurrencyAndShipping = async () => {
      const token = Cookies.get("token");
      const response = await fetch(
        "https://ww1.premelo.com/api/stripe/getcands",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      if (json.commerce_array) {
        if (json.commerce_array.currency) {
          if (json.commerce_array.currency.display) {
            setCurrency(json.commerce_array.currency.display);
          }
        }
      }
    };
    getCurrencyAndShipping();
  }, []);

  const [events, setEvents] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [windowsIsOpen, setWindowsIsOpen] = useState(false);
  const [activeEvent, setActiveEvent] = useState(null);
  const [activeWindowStat, setActiveWindowStat] = useState({
    x: 400,
    y: 150,
    width: "60vw",
    height: "80vh",
  });
  const [activeOffset, setActiveOffset] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const [initialWindow, setInitialWindow] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });

  const currentURL = window.location.href;
  const modifiedURL = currentURL.replace(/\/dashboard$/, "");

  const [editing, setEditing] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const token = Cookies.get("token");
  useEffect(() => {
    const handleGetEvents = async () => {
      try {
        const response = await fetch(
          "https://ww1.premelo.com/api/web-builder/get-event",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const json = await response.json();
        console.log(json);
        setEvents(json.events);
      } catch (error) {
        console.log(error);
      }
    };

    handleGetEvents();
  }, []);

  function isSameDay(date1, date2) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  const show = ({ date, view }) => {
    if (view === "month") {
      const eventOnDate = events.some((event) =>
        isSameDay(date, new Date(event.date))
      );

      if (eventOnDate) {
        return (
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <div
              style={{
                marginTop: "3px",
                background: props.primaryColor,
                height: "6px",
                width: "6px",
                borderRadius: "50%",
              }}
            />
          </div>
        );
      }
    }
    return null;
  };

  const handleFilterChange = (filter) => {
    if (selectedFilters.includes(filter)) {
      // Filter is already selected, remove it
      setSelectedFilters(selectedFilters.filter((f) => f !== filter));
    } else {
      // Filter is not selected, add it
      setSelectedFilters([...selectedFilters, filter]);
    }
  };

  const handleDragStart = (e) => {
    setInitialWindow({ x: activeWindowStat.x, y: activeWindowStat.y });
    setActiveOffset({ x: e.clientX, y: e.clientY });
  };

  const [touching, setTouching] = useState(false);
  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    setTouching(true);
    setInitialWindow({ x: activeWindowStat.x, y: activeWindowStat.y });
    setActiveOffset({ x: touch.clientX, y: touch.clientY });
  };

  const handleWindowsDrag = (e) => {
    const updatingWindows = { ...activeWindowStat };
    if (e.clientX !== 0) {
      updatingWindows.x = initialWindow.x + e.clientX - activeOffset.x;
    }
    if (e.clientY !== 0) {
      updatingWindows.y = initialWindow.y + e.clientY - activeOffset.y;
    }
    setActiveWindowStat(updatingWindows);
  };

  const handleTouchWindowsDrag = (e) => {
    const touch = e.touches[0];
    const updatingWindows = { ...activeWindowStat };
    if (touch.clientX !== 0) {
      updatingWindows.x = initialWindow.x + touch.clientX - activeOffset.x;
    }
    if (touch.clientY !== 0) {
      updatingWindows.y = initialWindow.y + touch.clientY - activeOffset.y;
    }
    setActiveWindowStat(updatingWindows);
  };

  const [fileUploading, setFileUploading] = useState(false);

  const handleImageUpload = async (e) => {
    setFileUploading(true);
    const file = e.target.files;
    console.log(e.target.files);

    const formData = new FormData();

    for (let i = 0; i < file.length; i++) {
      formData.append("files[]", file[i]);
    }

    try {
      const response = await axios.post(
        "https://ww1.premelo.com/api/upload-image",
        formData
      );
      const imageUrl = response.data.urls;
      const updatedActiveEvent = { ...activeEvent };
      updatedActiveEvent.img_src = imageUrl;
      setActiveEvent(updatedActiveEvent);
      setFileUploading(false);
    } catch (error) {
      console.log(error);
      setFileUploading(false);
    }
  };

  const handleClick = () => {
    const fileInput = document.getElementById("imageInput");
    fileInput.click();
  };

  function formatDate(inputDate) {
    // Parse the input date string
    const date = new Date(inputDate);

    // Define month names
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract day, month, and year
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    // Format the date as "DD Mon, YYYY"
    const formattedDate = `${day} ${monthNames[month]}, ${year}`;

    return formattedDate;
  }

  function formatTime(inputTime) {
    // Split the input time string into hours and minutes
    const [hourStr, minuteStr] = inputTime.split(":");

    // Convert hours and minutes to integers
    const hours = parseInt(hourStr, 10);
    const minutes = parseInt(minuteStr, 10);

    // Determine the period (am or pm)
    const period = hours >= 12 ? "pm" : "am";

    // Format the hours in 12-hour format
    const formattedHours = hours % 12 || 12; // Handle midnight (0) as 12

    // Format the time as "h:mm am/pm"
    const formattedTime = `${formattedHours}.${minutes
      .toString()
      .padStart(2, "0")} ${period}`;

    return formattedTime;
  }

  function isWithinRange(date, startDate, endDate) {
    return date >= startDate && date <= endDate;
  }

  function isTheSameDay(date1, date2) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  const filterEventsByDate = () => {
    console.log("Events:", events);
    console.log("Date Range:", dateRange);

    if (!dateRange) {
      // If dateRange is null, return all events
      return events;
    } else if (dateRange[1] === null) {
      // If dateRange has one date and the second date is null
      const selectedDate = dateRange[0];
      console.log("Selected Date:", selectedDate);

      return events.filter((event) => {
        // Assuming the event.date property is a JavaScript Date object
        console.log("Event Date:", event.date);
        return isTheSameDay(new Date(event.date), selectedDate);
      });
    } else if (dateRange.length === 2) {
      // If dateRange has two dates, representing a date range
      const startDate = dateRange[0];
      const endDate = dateRange[1];
      console.log("Start Date:", startDate);
      console.log("End Date:", endDate);

      return events.filter((event) => {
        // Assuming the event.date property is a JavaScript Date object
        console.log("Event Date:", event.date);
        return isWithinRange(new Date(event.date), startDate, endDate);
      });
    } else {
      // Invalid dateRange, return an empty array or handle as needed
      console.log("Invalid dateRange");
      return [];
    }
  };

  const filteredEvents = filterEventsByDate()
    ? filterEventsByDate().filter((event) => {
        const tags = event.tags || ""; // Default to an empty string if tags is null
        const categories = event.categories || ""; // Default to an empty string if categories is null

        const tagssAndCategories = [
          ...tags.split(" | "),
          ...categories.split(" | "),
        ];

        // Check if selectedFilters is null or empty
        if (!selectedFilters || selectedFilters.length === 0) {
          return true; // Return true if no filters are selected
        }

        // Use selectedFilters.some only if it's not null or empty
        return selectedFilters.some((filter) =>
          tagssAndCategories.includes(filter)
        );
      })
    : [];

  const searchedEvents = filteredEvents
    ? filteredEvents.filter((event) => {
        const { event_name } = event;
        const lowerCaseSearchQuery = searchQuery.toLowerCase();

        return (
          event_name && event_name.toLowerCase().includes(lowerCaseSearchQuery)
        );
      })
    : [];

  // Function to handle search input change
  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const [viewingAttendees, setViewingAttendees] = useState(false);
  const [purchasingAttendees, setPurchasingAttendees] = useState([]);
  const [orders, setOrders] = useState(null);

  const getOrders = async () => {
    try {
      const response = await fetch("https://ww1.premelo.com/api/get-orders", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const json = await response.json();
      setOrders(json.orders);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrders();
  }, []);

  useEffect(() => {
    if (activeEvent) {
      if (orders) {
        const updatingPurchasingAttendees = [...purchasingAttendees];
        for (const ord of orders) {
          for (const or of ord.order_items) {
            if (or.type === "event") {
              if (or.id === activeEvent.id) {
                if (or.variation) {
                  const order = {
                    quantity: or.quantity,
                    first_name: ord.customer_name,
                    email: ord.email_address,
                    phone_number: ord.phone_number,
                    ticket: or.ticket[parseFloat(or.variation)].name,
                  };
                  updatingPurchasingAttendees.push(order);
                }
              }
            }
          }
        }
        setPurchasingAttendees(updatingPurchasingAttendees);
      }
    }
  }, [activeEvent]);

  const handleDeleteEvent = async () => {
    const updatingEvent = {
      ...activeEvent,
    };

    const eventIndex = events.findIndex((ev) => ev.id === activeEvent.id);

    const updatingEvents = [...events];

    if (eventIndex !== -1) {
      setActiveEvent(null);
      setWindowsIsOpen(false);
      updatingEvents.splice(eventIndex, 1);
      setEvents(updatingEvents);
      try {
        const response = await fetch(
          "https://ww1.premelo.com/api/web-builder/delete-event",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(updatingEvent),
          }
        );
        const json = await response.json();
        console.log(json);
        updatingEvents[eventIndex] = json.data.event;
        setEvents(updatingEvents);
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div
      style={{
        height: "100%",
        position: "relative",
      }}
    >
      {activeEvent ? (
        <div
          style={{
            display: windowsIsOpen ? "inline-block" : "none",
            position: "fixed",
            top: activeWindowStat.y,
            left: activeWindowStat.x,
            width: activeWindowStat.width,
            height: activeWindowStat.height,
            background: "white",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
            zIndex: 100000,
            borderRadius: "5px",
            overflow: "hidden",
            transform: touching ? "scale(1.02)" : "scale(1)",
            opacity: touching ? 0.8 : 1,
            transition: "0.3s transform ease-in-out",
          }}
        >
          <div
            draggable
            onDragStart={(e) => handleDragStart(e)}
            onDrag={(e) => handleWindowsDrag(e)}
            onTouchStart={(e) => handleTouchStart(e)}
            onTouchMove={(e) => handleTouchWindowsDrag(e)}
            onTouchEnd={(e) => setTouching(false)}
            style={{
              height: "40px",
              cursor: "move",
              borderBottom: "1px solid lightgrey",
              touchAction: "none",
            }}
          >
            <div
              style={{
                height: "4px",
                width: "100%",
                background: props.primaryColor,
              }}
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                height: "36px",
              }}
            >
              <div
                style={{
                  marginLeft: "15px",
                  color: "rgba(0,0,0,0.8)",
                  fontSize: "14px",
                  fontWeight: 700,
                }}
              >
                {activeEvent.event_name}
              </div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    width: "9px",
                    height: "9px",
                    borderRadius: "10px",
                    marginRight: "7px",
                  }}
                />
                <div
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    width: "9px",
                    height: "9px",
                    borderRadius: "10px",
                    marginRight: "7px",
                  }}
                />
                <div
                  className="mui-button"
                  onClick={() => setWindowsIsOpen(false)}
                  style={{
                    background: "grey",
                    width: "9px",
                    height: "9px",
                    borderRadius: "10px",
                    marginRight: "14px",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              width: "100%",
              display: "flex",
              height: "40px",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {editing ? (
              <div
                onClick={async () => {
                  const updatingEvent = {
                    ...activeEvent,
                  };

                  const eventIndex = events.findIndex(
                    (ev) => ev.id === activeEvent.id
                  );

                  const updatingEvents = [...events];
                  if (eventIndex !== -1) {
                    try {
                      const response = await fetch(
                        "https://ww1.premelo.com/api/web-builder/create-update-event",
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                          },
                          body: JSON.stringify(updatingEvent),
                        }
                      );
                      const json = await response.json();
                      console.log(json);
                      updatingEvents[eventIndex] = json.data.event;
                      setEvents(updatingEvents);
                    } catch (error) {
                      console.log(error);
                    }
                  } else {
                    try {
                      const response = await fetch(
                        "https://ww1.premelo.com/api/web-builder/create-update-event",
                        {
                          method: "POST",
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                          },
                          body: JSON.stringify(updatingEvent),
                        }
                      );
                      const json = await response.json();
                      console.log(json);

                      updatingEvents.push(json.data.event);
                      setEvents(updatingEvents);
                    } catch (error) {
                      console.log(error);
                    }
                  }
                  setEditing(false);
                }}
                className="mui-button"
                style={{
                  marginRight: "10px",
                  padding: "0px 20px",
                  color: "white",
                  background: props.primaryColor,
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                Save
              </div>
            ) : (
              <div
                onClick={() => setEditing(true)}
                className="mui-button"
                style={{
                  marginRight: "10px",
                  padding: "0px 20px",
                  border: "1px solid grey",
                  color: "grey",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                Edit
              </div>
            )}
            <div
              className="mui-button"
              onClick={() => setViewingAttendees(!viewingAttendees)}
              style={{
                marginRight: "10px",
                padding: "0px 20px",
                border: "1px solid grey",
                color: "grey",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              {viewingAttendees ? "View event details" : "View Attendees"}
            </div>
            <div
              className="mui-button"
              onClick={() => handleDeleteEvent()}
              style={{
                marginRight: "10px",
                padding: "0px 20px",
                border: "1px solid red",
                color: "red",
                borderRadius: "8px",
                cursor: "pointer",
              }}
            >
              Delete
            </div>
          </div>
          <div
            style={{
              padding: "15px",
              overflowY: "scroll",
              overflowX: "hidden",
              height: `calc(${activeWindowStat.height} - 70px)`,
            }}
          >
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <div
                style={{ marginRight: "25px", width: "200px", height: "200px" }}
                onClick={handleClick}
              >
                {editing ? (
                  <>
                    <input
                      id="imageInput"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleImageUpload}
                    />
                    {fileUploading ? (
                      <div
                        style={{
                          boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                          borderRadius: "10px",
                          width: "200px",
                          height: "200px",
                          objectFit: "cover",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress
                          color="primary"
                          size={50}
                          style={{}}
                        />
                      </div>
                    ) : activeEvent.img_src ? (
                      <img
                        src={activeEvent.img_src}
                        style={{
                          boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                          borderRadius: "10px",
                          width: "200px",
                          height: "200px",
                          objectFit: "cover",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <div
                        style={{
                          boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                          borderRadius: "10px",
                          width: "200px",
                          height: "200px",
                          objectFit: "cover",
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FileUploadIcon
                          style={{ color: "lightgrey", fontSize: "50px" }}
                        />
                      </div>
                    )}
                  </>
                ) : activeEvent.img_src ? (
                  <img
                    src={activeEvent.img_src}
                    style={{
                      boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                      borderRadius: "10px",
                      width: "200px",
                      height: "200px",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <img
                    style={{
                      boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                      borderRadius: "10px",
                      width: "200px",
                      height: "200px",
                      objectFit: "cover",
                    }}
                    src={imageReference}
                  />
                )}
              </div>
              <div style={{ marginTop: "25px" }}>
                {editing ? (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <input
                      type="text"
                      placeholder="Event name"
                      value={activeEvent.event_name}
                      onChange={(e) => {
                        const updatingEvent = { ...activeEvent };
                        updatingEvent.event_name = e.target.value;
                        setActiveEvent(updatingEvent);
                      }}
                      style={{
                        color: props.primaryColor,
                        fontSize: "15px",
                        fontWeight: 700,
                        borderRadius: "7px",
                        border: "none",
                        outline: "1px solid lightgrey",
                        padding: "3px 5px",
                        width: "25vw",
                      }}
                    />
                    <input
                      type="text"
                      placeholder="Event description"
                      value={activeEvent.description}
                      onChange={(e) => {
                        const updatingEvent = { ...activeEvent };
                        updatingEvent.description = e.target.value;
                        setActiveEvent(updatingEvent);
                      }}
                      style={{
                        color: "grey",
                        fontSize: "10px",
                        fontWeight: 400,
                        borderRadius: "7px",
                        border: "none",
                        outline: "1px solid lightgrey",
                        marginTop: "5px",
                        padding: "3px 5px",
                        width: "25vw",
                      }}
                    />
                    <input
                      type="date"
                      value={activeEvent.date}
                      onChange={(e) => {
                        const updatingEvent = { ...activeEvent };
                        updatingEvent.date = e.target.value;
                        setActiveEvent(updatingEvent);
                      }}
                      placeholder="Event date"
                      style={{
                        color: "grey",
                        fontSize: "10px",
                        fontWeight: 400,
                        borderRadius: "7px",
                        border: "none",
                        outline: "1px solid lightgrey",
                        padding: "3px 5px",
                        marginTop: "5px",
                        width: "25vw",
                      }}
                    />
                    <input
                      type="time"
                      value={activeEvent.time}
                      onChange={(e) => {
                        const updatingEvent = { ...activeEvent };
                        updatingEvent.time = e.target.value;
                        setActiveEvent(updatingEvent);
                      }}
                      placeholder="Event time"
                      style={{
                        color: "grey",
                        fontSize: "10px",
                        fontWeight: 400,
                        borderRadius: "7px",
                        border: "none",
                        outline: "1px solid lightgrey",
                        padding: "3px 5px",
                        marginTop: "5px",
                        width: "25vw",
                      }}
                    />
                    <input
                      value={activeEvent.location}
                      onChange={(e) => {
                        const updatingEvent = { ...activeEvent };
                        updatingEvent.location = e.target.value;
                        setActiveEvent(updatingEvent);
                      }}
                      placeholder="Event location"
                      style={{
                        color: "grey",
                        fontSize: "10px",
                        fontWeight: 400,
                        borderRadius: "7px",
                        border: "none",
                        outline: "1px solid lightgrey",
                        padding: "3px 5px",
                        marginTop: "5px",
                        width: "25vw",
                      }}
                    />
                    <input
                      type="text"
                      placeholder="Tags, to add more than one space them out with ` | `"
                      value={activeEvent.tags}
                      onChange={(e) => {
                        const updatingEvent = { ...activeEvent };
                        updatingEvent.tags = e.target.value;
                        setActiveEvent(updatingEvent);
                      }}
                      style={{
                        color: "grey",
                        fontSize: "10px",
                        fontWeight: 400,
                        borderRadius: "7px",
                        border: "none",
                        outline: "1px solid lightgrey",
                        padding: "3px 5px",
                        marginTop: "5px",
                        width: "25vw",
                      }}
                    />
                    <input
                      type="text"
                      placeholder="Categories, to add more than one space them out with ` | `"
                      value={activeEvent.categories}
                      onChange={(e) => {
                        const updatingEvent = { ...activeEvent };
                        updatingEvent.categories = e.target.value;
                        setActiveEvent(updatingEvent);
                      }}
                      style={{
                        color: "grey",
                        fontSize: "10px",
                        fontWeight: 400,
                        borderRadius: "7px",
                        border: "none",
                        outline: "1px solid lightgrey",
                        padding: "3px 5px",
                        marginTop: "5px",
                        width: "25vw",
                      }}
                    />
                  </div>
                ) : (
                  <>
                    <div
                      style={{
                        color: props.primaryColor,
                        fontSize: "18px",
                        fontWeight: 700,
                        width: "25vw",
                      }}
                    >
                      {activeEvent.event_name}
                    </div>
                    <div
                      style={{
                        color: "grey",
                        fontSize: "12px",
                        fontWeight: 400,
                        width: "25vw",
                      }}
                    >
                      {activeEvent.description}
                    </div>
                    <hr
                      style={{
                        height: "0.1px",
                        width: "100%",
                        background: "lightgrey",
                        border: "none",
                        outline: "none",
                        width: "25vw",
                      }}
                    />
                    <div
                      style={{
                        color: "grey",
                        fontSize: "12px",
                        fontWeight: 400,
                        width: "25vw",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CalendarMonthIcon
                        style={{
                          color: "grey",
                          fontSize: "15px",
                          marginRight: "6px",
                        }}
                      />
                      {formatDate(activeEvent.date)}
                    </div>
                    <div
                      style={{
                        color: "grey",
                        fontSize: "12px",
                        fontWeight: 400,
                        width: "25vw",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <AccessTimeIcon
                        style={{
                          color: "grey",
                          fontSize: "15px",
                          marginRight: "6px",
                        }}
                      />{" "}
                      {formatTime(activeEvent.time)}
                    </div>
                    <div
                      style={{
                        color: "grey",
                        fontSize: "12px",
                        fontWeight: 400,
                        width: "25vw",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "5px",
                      }}
                    >
                      <LocationOnIcon
                        style={{
                          color: "grey",
                          fontSize: "15px",
                          marginRight: "6px",
                        }}
                      />
                      {activeEvent.location
                        ? activeEvent.location
                        : "No location set"}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        width: "300px",
                        gap: "5px",
                        margin: "10px 0px",
                        width: "25vw",
                      }}
                    >
                      {activeEvent.tags
                        ? activeEvent.tags.split(" | ").map((tag, index) =>
                            tag && tag !== "" ? (
                              <div
                                style={{
                                  padding: "2px 10px",
                                  background: props.primaryColor,
                                  color: "white",
                                  borderRadius: "6px",
                                  fontSize: "11px",
                                }}
                                key={index}
                              >
                                {tag}
                              </div>
                            ) : null
                          )
                        : null}
                      {activeEvent.categories
                        ? activeEvent.categories
                            .split(" | ")
                            .map((category, index) =>
                              category && category !== "" ? (
                                <div
                                  style={{
                                    padding: "2px 10px",
                                    background: props.primaryColor,
                                    color: "white",
                                    borderRadius: "6px",
                                    fontSize: "11px",
                                  }}
                                  key={index}
                                >
                                  {category}
                                </div>
                              ) : null
                            )
                        : null}
                    </div>
                  </>
                )}
              </div>
            </div>
            {viewingAttendees ? (
              <>
                <div
                  style={{
                    background: "white",
                    width: "100%",
                    borderRadius: "10px",
                    border: "1px solid lightgrey",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                    marginTop: "20px",
                  }}
                >
                  <div
                    style={{
                      color: props.primaryColor,
                      fontSize: "18px",
                      fontWeight: "700",
                      borderBottom: "1px solid lightgrey",
                      paddingBottom: "5px",
                      padding: "5px 0px 5px 10px",
                    }}
                  >
                    Events Attendees
                  </div>
                  {activeEvent.response_array
                    ? activeEvent.response_array.map((respo, index) => (
                        <div
                          key={index}
                          style={{
                            width: "calc(100% - 20px)",
                            justifyContent: "space-between",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "9px",
                            padding: "0px 10px",
                            height: "25px",
                            borderBottom: "1px solid rgba(220,220,220)",
                          }}
                        >
                          <div style={{ width: "20%" }}>
                            {respo.quantity ? respo.quantity : 1}x {respo.rsvp}
                          </div>
                          <div style={{ width: "15%" }}>{respo.first_name}</div>
                          <div style={{ width: "15%" }}>{respo.last_name}</div>
                          <div style={{ width: "30%" }}>{respo.email}</div>
                          <div style={{ width: "15%" }}>
                            {respo.phone_number}
                          </div>
                        </div>
                      ))
                    : null}
                  {purchasingAttendees.length > 0
                    ? purchasingAttendees.map((purc, index) => (
                        <div
                          key={index}
                          style={{
                            width: "calc(100% - 20px)",
                            justifyContent: "space-between",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "9px",
                            height: "25px",
                            padding: "0px 10px",
                            borderBottom:
                              index === purchasingAttendees.length - 1
                                ? "none"
                                : "1px solid rgba(220,220,220)",
                          }}
                        >
                          <div style={{ width: "20%" }}>
                            {purc.quantity ? purc.quantity : 1}x {purc.ticket}
                          </div>
                          <div style={{ width: "15%" }}>{purc.first_name}</div>
                          <div style={{ width: "15%" }}>
                            {purc.last_name ? purc.last_name : ""}
                          </div>
                          <div style={{ width: "30%" }}>{purc.email}</div>
                          <div style={{ width: "15%" }}>
                            {purc.phone_number}
                          </div>
                        </div>
                      ))
                    : null}
                </div>
              </>
            ) : (
              <>
                <div
                  style={{
                    background: "white",
                    width: "100%",
                    borderRadius: "10px",
                    border: "1px solid lightgrey",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                    marginTop: "20px",
                  }}
                >
                  <div
                    style={{
                      color: props.primaryColor,
                      fontSize: "18px",
                      fontWeight: "700",
                      borderBottom: "1px solid lightgrey",
                      paddingBottom: "5px",
                      padding: "5px 0px 5px 10px",
                    }}
                  >
                    Events Ticketing
                  </div>
                  <div
                    style={{
                      width: "calc(100% - 30px)",
                      height: "200px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                      padding: "15px",
                    }}
                  >
                    {editing ? (
                      activeEvent.ticket.length > 0 ? (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexWrap: "wrap",
                              width: "100%",
                              gap: "20px",
                            }}
                          >
                            {activeEvent.ticket.map((ticket, index) => (
                              <div
                                key={index}
                                style={{
                                  marginBottom: "20px",
                                  width: "calc(calc(100% / 3) - 20px)",
                                  border: "1px solid lightgrey",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    width: "5px",
                                    height: "100%",
                                    background: "lightgrey",
                                    marginLeft: "2px",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    width: "5px",
                                    height: "100%",
                                    background: "lightgrey",
                                    marginLeft: "2px",
                                  }}
                                ></div>
                                <div style={{ padding: "10px" }}>
                                  <input
                                    placeholder="Ticket name"
                                    value={ticket.name}
                                    onChange={(e) => {
                                      const updatingActiveEvent = {
                                        ...activeEvent,
                                      };
                                      updatingActiveEvent.ticket[index].name =
                                        e.target.value;

                                      setActiveEvent(updatingActiveEvent);
                                    }}
                                    type="text"
                                    style={{
                                      color: props.primaryColor,
                                      fontSize: "16px",
                                      fontWeight: 700,
                                      borderRadius: "7px",
                                      border: "none",
                                      outline: "1px solid lightgrey",
                                      padding: "5px",
                                      marginTop: "5px",
                                      width: "96%",
                                    }}
                                  />
                                  <select
                                    value={ticket.type}
                                    onChange={(e) => {
                                      const updatingActiveEvent = {
                                        ...activeEvent,
                                      };
                                      console.log(updatingActiveEvent);
                                      updatingActiveEvent.ticket[index].type =
                                        e.target.value;

                                      setActiveEvent(updatingActiveEvent);
                                    }}
                                    style={{
                                      color: "grey",
                                      fontSize: "12px",
                                      fontWeight: 400,
                                      borderRadius: "7px",
                                      border: "none",
                                      outline: "1px solid lightgrey",
                                      padding: "5px",
                                      marginTop: "5px",
                                      width: "100%",
                                    }}
                                  >
                                    <option value="ticket">Ticket</option>
                                    <option value="rsvp">RSVP</option>
                                  </select>
                                  {ticket.type === "rsvp" ? (
                                    <></>
                                  ) : (
                                    <div
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        alignItems: "center",
                                      }}
                                    >
                                      <input
                                        placeholder="Limit"
                                        type="number"
                                        value={ticket.limit}
                                        onChange={(e) => {
                                          const updatingActiveEvent = {
                                            ...activeEvent,
                                          };
                                          updatingActiveEvent.ticket[
                                            index
                                          ].limit = e.target.value;

                                          setActiveEvent(updatingActiveEvent);
                                        }}
                                        style={{
                                          color: "grey",
                                          fontSize: "12px",
                                          fontWeight: 400,
                                          borderRadius: "7px",
                                          border: "none",
                                          outline: "1px solid lightgrey",
                                          padding: "5px",
                                          marginTop: "5px",
                                          width: "40%",
                                          marginRight: "5%",
                                        }}
                                      />
                                      {" " + currency + " "}
                                      <input
                                        placeholder="Price"
                                        type="number"
                                        value={ticket.price}
                                        onChange={(e) => {
                                          const updatingActiveEvent = {
                                            ...activeEvent,
                                          };
                                          updatingActiveEvent.ticket[
                                            index
                                          ].price = e.target.value;

                                          setActiveEvent(updatingActiveEvent);
                                        }}
                                        style={{
                                          color: "grey",
                                          fontSize: "12px",
                                          fontWeight: 400,
                                          borderRadius: "7px",
                                          border: "none",
                                          outline: "1px solid lightgrey",
                                          padding: "5px",
                                          marginTop: "5px",
                                          width: "40%",
                                          marginLeft: "5px",
                                        }}
                                      />
                                    </div>
                                  )}
                                </div>
                                <div style={{ padding: "8px 8px 8px 0px" }}>
                                  <RemoveCircleOutlineIcon
                                    className="mui-button"
                                    style={{ color: "red" }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      const updatedactiveEvent = {
                                        ...activeEvent,
                                      };
                                      updatedactiveEvent.ticket.splice(
                                        index,
                                        1
                                      );
                                      setActiveEvent(updatedactiveEvent);
                                    }}
                                  />
                                </div>
                              </div>
                            ))}
                            <div
                              style={{
                                marginBottom: "20px",
                                width: "calc(calc(100% / 3) - 20px)",
                                border: "1px solid lightgrey",
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  width: "5px",
                                  height: "100%",
                                  background: "lightgrey",
                                  marginLeft: "2px",
                                }}
                              ></div>
                              <div
                                style={{
                                  width: "5px",
                                  height: "100%",
                                  background: "lightgrey",
                                  marginLeft: "2px",
                                }}
                              ></div>
                              <div
                                style={{
                                  padding: "10px",
                                  width: "90%",
                                  height: "83%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    background: props.primaryColor,
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "60px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const updatedactiveEvent = {
                                      ...activeEvent,
                                    };
                                    const newTicket = {
                                      type: "",
                                      name: "",
                                      limit: 0,
                                      price: 0,
                                      boought: 0,
                                    };
                                    updatedactiveEvent.ticket.push(newTicket);
                                    setActiveEvent(updatedactiveEvent);
                                  }}
                                >
                                  <AddIcon
                                    style={{ fontSize: "35px", color: "white" }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white",
                          }}
                        >
                          <div
                            style={{
                              background: props.primaryColor,
                              width: "50px",
                              height: "50px",
                              borderRadius: "60px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              const updatedactiveEvent = { ...activeEvent };
                              const newTicket = {
                                type: "",
                                name: "",
                                limit: 0,
                                price: 0,
                                boought: 0,
                              };
                              updatedactiveEvent.ticket.push(newTicket);
                              setActiveEvent(updatedactiveEvent);
                            }}
                          >
                            <AddIcon style={{ fontSize: "35px" }} />
                          </div>
                        </div>
                      )
                    ) : (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            gap: "20px",
                          }}
                        >
                          {activeEvent.ticket.map((ticket, index) => (
                            <div
                              key={index}
                              style={{
                                marginBottom: "20px",
                                width: "calc(calc(100% / 3) - 20px)",
                                border: "1px solid lightgrey",
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  width: "5px",
                                  height: "100%",
                                  background: "lightgrey",
                                  marginLeft: "2px",
                                }}
                              ></div>
                              <div
                                style={{
                                  width: "5px",
                                  height: "100%",
                                  background: "lightgrey",
                                  marginLeft: "2px",
                                }}
                              ></div>
                              <div
                                style={{
                                  padding: "10px",
                                  width: "calc(100% - 14px)",
                                }}
                              >
                                <div
                                  style={{
                                    color: props.primaryColor,
                                    fontSize: "16px",
                                    fontWeight: 700,
                                    marginTop: "5px",
                                    width: "96%",
                                  }}
                                >
                                  {ticket.name}
                                </div>
                                <div
                                  style={{
                                    color: "grey",
                                    fontSize: "12px",
                                    fontWeight: 400,
                                    width: "100%",
                                  }}
                                >
                                  Type: {ticket.type}
                                </div>
                                {ticket.type === "rsvp" ? (
                                  <></>
                                ) : (
                                  <>
                                    <hr
                                      style={{
                                        height: "0.1px",
                                        width: "100%",
                                        background: "lightgrey",
                                        border: "none",
                                        outline: "none",
                                      }}
                                    />
                                    <div
                                      style={{
                                        color: "grey",
                                        fontSize: "12px",
                                        fontWeight: 400,
                                        width: "100%",
                                      }}
                                    >
                                      Price:{" " + currency + " "}
                                      {parseFloat(ticket.price).toLocaleString(
                                        "en-US",
                                        {
                                          style: "decimal",
                                          minimumFractionDigits: 2,
                                          maximumFractionDigits: 2,
                                        }
                                      )}
                                    </div>
                                    <div
                                      style={{
                                        color: "grey",
                                        fontSize: "12px",
                                        fontWeight: 400,
                                        width: "100%",
                                      }}
                                    >
                                      Quantity: {ticket.limit}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    background: "white",
                    width: "100%",
                    borderRadius: "10px",
                    border: "1px solid lightgrey",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                    marginTop: "20px",
                  }}
                >
                  <div
                    style={{
                      color: props.primaryColor,
                      fontSize: "18px",
                      fontWeight: "700",
                      borderBottom: "1px solid lightgrey",
                      paddingBottom: "5px",
                      padding: "5px 0px 5px 10px",
                    }}
                  >
                    Events Attribute
                  </div>
                  <div
                    style={{
                      width: "calc(100% - 30px)",
                      height: "200px",
                      overflowY: "scroll",
                      overflowX: "hidden",
                      padding: "15px",
                    }}
                  >
                    {editing ? (
                      activeEvent.added_attribute.length > 0 ? (
                        <div>
                          {activeEvent.added_attribute.map(
                            (attribute, index) => (
                              <div key={index} style={{ marginBottom: "20px" }}>
                                <div
                                  style={{
                                    color: "grey",
                                    fontSize: "14px",
                                    marginBottom: "5px",
                                  }}
                                >
                                  Attribute {index + 1}
                                </div>
                                <div style={{ display: "flex", width: "100%" }}>
                                  <input
                                    className="attribute_input"
                                    type="text"
                                    name="attribute_name"
                                    value={attribute.attribute_name}
                                    placeholder="Attribute name"
                                    onChange={(e) => {
                                      const updatedEventAttribute = {
                                        ...activeEvent,
                                      };
                                      updatedEventAttribute.added_attribute[
                                        index
                                      ].attribute_name = e.target.value;

                                      setActiveEvent(updatedEventAttribute);
                                    }}
                                    style={{
                                      fontSize: "12px",
                                      background: "none",
                                      border: "1px solid lightgrey",
                                      outline: "none",
                                      borderRadius: "10px",
                                      width: "40%",
                                      color: "rgba(0,0,0,0.7)",
                                      fontWeight: "400",
                                      padding: "10px",
                                      marginRight: "10px",
                                    }}
                                  />

                                  <input
                                    className="attribute_input"
                                    type="text"
                                    name="attribute_value"
                                    value={attribute.attribute_value}
                                    placeholder="Attribute value"
                                    onChange={(e) => {
                                      const updatedEventAttribute = {
                                        ...activeEvent,
                                      };
                                      updatedEventAttribute.added_attribute[
                                        index
                                      ].attribute_value = e.target.value;

                                      setActiveEvent(updatedEventAttribute);
                                    }}
                                    style={{
                                      fontSize: "12px",
                                      background: "none",
                                      border: "1px solid lightgrey",
                                      outline: "none",
                                      borderRadius: "10px",
                                      width: "60%",
                                      color: "rgba(0,0,0,0.7)",
                                      fontWeight: "400",
                                      padding: "10px",
                                    }}
                                  />
                                </div>
                              </div>
                            )
                          )}
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              marginTop: "15px",
                            }}
                          >
                            <div
                              style={{
                                background: props.primaryColor,
                                width: "50px",
                                height: "50px",
                                borderRadius: "60px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                const updatedactiveEvent = {
                                  ...activeEvent,
                                };
                                const newEventAttribute = {
                                  attribute_name: "",
                                  attribute_value: "",
                                };
                                updatedactiveEvent.added_attribute.push(
                                  newEventAttribute
                                );
                                setActiveEvent(updatedactiveEvent);
                              }}
                            >
                              <AddIcon
                                style={{ fontSize: "35px", color: "white" }}
                              />
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "white",
                          }}
                        >
                          <div
                            style={{
                              background: props.primaryColor,
                              width: "50px",
                              height: "50px",
                              borderRadius: "60px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              const updatedactiveEvent = { ...activeEvent };
                              const newEventAttribute = {
                                attribute_name: "",
                                attribute_value: "",
                              };
                              updatedactiveEvent.added_attribute.push(
                                newEventAttribute
                              );
                              setActiveEvent(updatedactiveEvent);
                            }}
                          >
                            <AddIcon style={{ fontSize: "35px" }} />
                          </div>
                        </div>
                      )
                    ) : (
                      activeEvent.added_attribute.map((attribute, index) => (
                        <div key={index} style={{ marginBottom: "20px" }}>
                          <div
                            style={{
                              color: props.primaryColor,
                              fontSize: "16px",
                              fontWeight: 700,
                            }}
                          >
                            Attribute {index + 1}
                          </div>
                          <div style={{ display: "flex", width: "100%" }}>
                            <div
                              style={{
                                color: "grey",
                                fontSize: "12px",
                                fontWeight: 400,
                                marginRight: "5px",
                              }}
                            >
                              {attribute.attribute_name}:
                            </div>
                            <div
                              style={{
                                color: "grey",
                                fontSize: "12px",
                                fontWeight: 400,
                              }}
                            >
                              {attribute.attribute_value}
                            </div>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : null}
      <div
        style={{
          display: "flex",
          borderBottom: "1px solid rgba(0,0,0,0.1)",
          alignItems: "center",
          background: "rgba(255,255,255,0.7)",
          position: "relative",
          zIndex: 490,
          backdropFilter: "blur(20px)",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
          padding: "30px 0px 10px 0px",
        }}
      >
        <div
          style={{
            color: props.primaryColor,
            fontSize: "22px",
            fontWeight: "600",
            marginLeft: "20px",
            paddingBottom: "1%",
          }}
        >
          Events
        </div>
        <div
          style={{
            marginTop: "-7px",
            marginLeft: "13px",
          }}
        >
          <AddCircleIcon
            onClick={() => {
              const newEvent = {
                id: uuid(),
                event_name: "",
                img_src: "",
                date: `${new Date().getFullYear()}-${String(
                  new Date().getMonth() + 1
                ).padStart(2, "0")}-${String(new Date().getDate()).padStart(
                  2,
                  "0"
                )}`,
                ticket: [],
                location: "",
                time: "00:00:00",
                label: "",
                recurring_event: 0,
                recurring_sequence: "daily",
                description: "",
                tags: "",
                categories: "",
                added_attribute: [],
              };
              setActiveEvent(newEvent);
              setPurchasingAttendees([]);
              setEditing(true);
              setWindowsIsOpen(true);
            }}
            className="mui-button-table"
            style={{
              fontSize: "20px",
              color: props.primaryColor,
            }}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            height: "calc(90% - 40px)",
            width: "calc(28% - 40px)",
            padding: "20px",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              background: "rgba(255,255,255,0.7)",
              position: "relative",
              zIndex: 490,
              backdropFilter: "blur(20px)",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
              borderRadius: "5px",
            }}
          >
            <div
              style={{
                height: "40px",
                borderRadius: "5px 5px 0px 0px",
                overflow: "hidden",
                borderBottom: "1px solid lightgrey",
              }}
            >
              <div
                style={{
                  height: "4px",
                  width: "100%",
                  background: props.primaryColor,
                }}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  height: "35px",
                }}
              >
                <div
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    width: "9px",
                    height: "9px",
                    borderRadius: "10px",
                    marginRight: "7px",
                  }}
                />
                <div
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    width: "9px",
                    height: "9px",
                    borderRadius: "10px",
                    marginRight: "7px",
                  }}
                />
                <div
                  style={{
                    background: "grey",
                    width: "9px",
                    height: "9px",
                    borderRadius: "10px",
                    marginRight: "14px",
                  }}
                />
              </div>
            </div>
            <div style={{ padding: "20px" }}>
              <Calendar
                allowPartialRange={true}
                next2Label={null}
                prev2Label={null}
                selectRange={true}
                className="event_calendar"
                style={{ width: "100%" }}
                tileContent={show}
                onChange={(value, event) => {
                  setDateRange(value);
                }}
              />
            </div>
            <div style={{ padding: "0px 20px 20px 20px" }}>
              <div
                style={{
                  color: props.primaryColor,
                  fontWeight: 700,
                  fontSize: "16px",
                }}
              >
                Tags:
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                  marginTop: "5px",
                }}
              >
                {events
                  ? events
                      .reduce((filters, event) => {
                        const tag = event.tags || "";

                        const tags = [...tag.split(" | ")];
                        tags.forEach((filter) => {
                          if (
                            filter.trim() !== "" &&
                            !filters.includes(filter)
                          ) {
                            filters.push(filter);
                          }
                        });
                        return filters;
                      }, [])
                      .map(
                        (filter) =>
                          filter.trim() !== "" && ( // Check if filter is not empty
                            <button
                              className="tag_and_category_button"
                              key={filter}
                              style={{
                                margin: "0 2px",
                                background: selectedFilters.includes(filter)
                                  ? props.primaryColor
                                  : "rgba(0,0,0,0.05)",
                                color: selectedFilters.includes(filter)
                                  ? "white"
                                  : "rgba(0,0,0,0.7)",
                                border: "none",
                                padding: "5px 15px",
                                borderRadius: "10px",
                                cursor: "pointer",
                                fontSize: "12px",
                              }}
                              onClick={() => handleFilterChange(filter)}
                            >
                              {filter}
                            </button>
                          )
                      )
                  : null}
              </div>
            </div>
            <div style={{ padding: "0px 20px 20px 20px" }}>
              <div
                style={{
                  color: props.primaryColor,
                  fontWeight: 700,
                  fontSize: "16px",
                }}
              >
                Categories:
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                  marginTop: "5px",
                }}
              >
                {events
                  ? events
                      .reduce((filters, event) => {
                        const category = event.categories || "";

                        const categories = [...category.split(" | ")];
                        categories.forEach((filter) => {
                          if (
                            filter.trim() !== "" &&
                            !filters.includes(filter)
                          ) {
                            filters.push(filter);
                          }
                        });
                        return filters;
                      }, [])
                      .map(
                        (filter) =>
                          filter.trim() !== "" && (
                            <button
                              className="tag_and_category_button"
                              key={filter}
                              style={{
                                margin: "0 2px",
                                background: selectedFilters.includes(filter)
                                  ? props.primaryColor
                                  : "rgba(0,0,0,0.05)",
                                color: selectedFilters.includes(filter)
                                  ? "white"
                                  : "rgba(0,0,0,0.7)",
                                border: "none",
                                padding: "5px 15px",
                                borderRadius: "10px",
                                cursor: "pointer",
                                fontSize: "12px",
                              }}
                              onClick={() => handleFilterChange(filter)}
                            >
                              {filter}
                            </button>
                          )
                      )
                  : null}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            height: "calc(90% - 40px)",
            width: "calc(72% - 20px)",
            padding: "20px 20px 20px 0px",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "40px",
              background: "rgba(255,255,255,0.5)",
              backdropFilter: "blur(20px)",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
              borderRadius: "5px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <SearchIcon style={{ color: "grey", marginLeft: "8px" }} />
            <input
              placeholder="Search events"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{
                width: "95%",
                marginLeft: "10px",
                border: "none",
                outline: "none",
                background: "transparent",
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: searchedEvents.length > 0 ? "wrap" : "none",
              width: "100%",
              gap: "20px",
              marginTop: "20px",
              height: "91%",
              overflowY: "auto",
              justifyContent: searchedEvents.length > 0 ? "flex-start" : "center",
              alignItems: searchedEvents.length > 0 ? "flex-start" : "center",
            }}
          >
            {searchedEvents.length > 0 ? searchedEvents.map((event, index) => (
              <div
                onClick={() => {
                  const attributeNamesFromOtherEvents = new Set();

                  // Step 1: Extract unique attribute_name values from Events with non-empty added_attributes
                  events.forEach((p) => {
                    if (p.added_attribute && p.added_attribute.length > 0) {
                      p.added_attribute.forEach((attr) => {
                        attributeNamesFromOtherEvents.add(attr.attribute_name);
                      });
                    }
                  });

                  // Step 2: Add attributes with empty attribute_value to selectedEvent
                  const existingAttributeNames = new Set(
                    event.added_attribute.map((attr) => attr.attribute_name)
                  );

                  const attributesToAdd = Array.from(
                    attributeNamesFromOtherEvents
                  ).filter((attrName) => !existingAttributeNames.has(attrName));

                  const updatedAddedAttributes = [
                    ...event.added_attribute,
                    ...attributesToAdd.map((attrName) => ({
                      attribute_name: attrName,
                      attribute_value: "",
                    })),
                  ];

                  const updatedSelectedEvent = {
                    ...event,
                    added_attribute: updatedAddedAttributes,
                  };
                  setPurchasingAttendees([]);
                  setActiveEvent(updatedSelectedEvent);
                  setEditing(false);
                  setWindowsIsOpen(true);
                }}
                className="mui-button"
                key={index}
                style={{
                  width: "calc(50% - 15px)",
                  textAlign: "center",
                  height: "300px",
                  background: props.primaryColor,
                  borderRadius: "5px",
                  position: "relative",
                }}
              >
                {event.img_src ? (
                  <img
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      left: 0,
                      top: 0,
                      borderRadius: "5px",
                      objectFit: "cover",
                    }}
                    src={event.img_src}
                  />
                ) : (
                  <img
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      left: 0,
                      borderRadius: "5px",
                      top: 0,
                      objectFit: "cover",
                      opacity: 0.7,
                    }}
                    src={imageReference}
                  />
                )}
                <div
                  style={{
                    background: "rgba(255,255,255,0.8)",
                    backdropFilter: "blur(20px)",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                    width: "calc(92% - 20px)",
                    position: "absolute",
                    marginLeft: "4%",
                    bottom: "3%",
                    borderRadius: "10px",
                    textAlign: "left",
                    padding: "10px",
                  }}
                >
                  <div
                    style={{
                      color: props.primaryColor,
                      fontWeight: 700,
                      fontSize: "13px",
                    }}
                  >
                    {event.event_name}
                  </div>
                  <div
                    style={{
                      color: "grey",
                      fontWeight: 400,
                      fontSize: "9px",
                    }}
                  >
                    {event.description}
                  </div>
                  <hr
                    style={{
                      height: "0.1px",
                      width: "100%",
                      background: "grey",
                      border: "none",
                      outline: "none",
                    }}
                  />
                  <div
                    style={{
                      color: "grey",
                      fontWeight: 400,
                      fontSize: "9px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CalendarMonthIcon
                      style={{
                        fontSize: "14px",
                        marginRight: "5px",
                        color: "grey",
                      }}
                    />
                    {event.date}
                  </div>
                </div>
                <div
                  style={{
                    background: props.primaryColor,
                    position: "absolute",
                    color: "white",
                    padding: "4px 12px",
                    borderRadius: "7px",
                    top: "2%",
                    left: "2%",
                    fontSize: "12px",
                  }}
                >
                  {event.label}
                </div>
                <div
                  className="mui-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(
                      `${modifiedURL}/ezylinke?eventId=${event.id}`
                    );
                    toast.info("Ezylink copied to clipboard!", {
                      position: "top-right",
                      autoClose: 1500,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                  }}
                  style={{
                    background: "white",
                    position: "absolute",
                    color: "white",
                    padding: "4px 4px",
                    borderRadius: "5px",
                    top: "2%",
                    right: "2%",
                    fontSize: "12px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <LeakAddIcon style={{ color: "rgba(0,0,0,0.8)" }} />
                </div>
              </div>
            )) : 
            <div style={{ marginTop: "-50px" }}>
              <div
                style={{
                  fontSize: "30px",
                  fontWeight: 700,
                  color: "rgba(0,0,0,0.6)",
                  textAlign: "center",
                }}
              >
                No Events Created
              </div>
              <div
                style={{
                  fontSize: "15px",
                  fontWeight: 400,
                  color: "rgba(0,0,0,0.4)",
                  textAlign: "center",
                  marginTop: "-10px",
                }}
              >
                Let's create your first event
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "10px",
                }}
              >
                <div
                  className="grow mui-button"
                  onClick={() => {
                    const newEvent = {
                      id: uuid(),
                      event_name: "",
                      img_src: "",
                      date: `${new Date().getFullYear()}-${String(
                        new Date().getMonth() + 1
                      ).padStart(2, "0")}-${String(new Date().getDate()).padStart(
                        2,
                        "0"
                      )}`,
                      ticket: [],
                      location: "",
                      time: "00:00:00",
                      label: "",
                      recurring_event: 0,
                      recurring_sequence: "daily",
                      description: "",
                      tags: "",
                      categories: "",
                      added_attribute: [],
                    };
                    setActiveEvent(newEvent);
                    setPurchasingAttendees([]);
                    setEditing(true);
                    setWindowsIsOpen(true);
                  }}
                  style={{
                    fontSize: "15px",
                    fontWeight: 400,
                    color: "white",
                    textAlign: "center",
                    background: props.primaryColor,
                    borderRadius: "10px",
                    padding: "8px 30px",
                  }}
                >
                  Create Event
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Events;
