import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TaskContent from "./TaskContent";
import KanbanContent from "./KanbanContent";
import ProjectContent from "./ProjectContent";
import CRMMobile from "./TaskContentMobile";
import WorkflowContent from "./WorkflowContent";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import LanIcon from "@mui/icons-material/Lan";
import GroupsIcon from "@mui/icons-material/Groups";
import GroupIcon from "@mui/icons-material/Group";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import WorkIcon from "@mui/icons-material/Work";
import HubIcon from "@mui/icons-material/Hub";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import TerminalIcon from "@mui/icons-material/Terminal";
import PaymentIcon from "@mui/icons-material/Payment";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import HandshakeIcon from "@mui/icons-material/Handshake";
import HandymanIcon from "@mui/icons-material/Handyman";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import EmailIcon from "@mui/icons-material/Email";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import DescriptionIcon from "@mui/icons-material/Description";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import BusinessIcon from "@mui/icons-material/Business";
import CallIcon from "@mui/icons-material/Call";
import CalculateIcon from "@mui/icons-material/Calculate";
import CookieIcon from "@mui/icons-material/Cookie";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import HeadsetIcon from "@mui/icons-material/Headset";
import Cookies from "js-cookie";
import axios from "axios";
import { SliderPicker } from "react-color";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      style={{ height: "20px" }}
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
};

const ProjectBarLoadoutMobile = (props) => {
  const projectIcon = (label) => {
    switch (label) {
      case "Project1":
        return (
          <DocumentScannerIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project2":
        return (
          <LanIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project3":
        return (
          <GroupsIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project4":
        return (
          <GroupIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project5":
        return (
          <AccessibilityNewIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project6":
        return (
          <AccountTreeIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project7":
        return (
          <AirplanemodeActiveIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project8":
        return (
          <ArchitectureIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project9":
        return (
          <AssignmentIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project10":
        return (
          <AudiotrackIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project11":
        return (
          <AccountBalanceIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project12":
        return (
          <WorkIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project13":
        return (
          <HubIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project14":
        return (
          <VolunteerActivismIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project15":
        return (
          <TerminalIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project16":
        return (
          <PaymentIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project17":
        return (
          <CameraAltIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project18":
        return (
          <ReportProblemIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project19":
        return (
          <Diversity3Icon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project20":
        return (
          <SportsEsportsIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project21":
        return (
          <LocalShippingIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project22":
        return (
          <RocketLaunchIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project23":
        return (
          <HandshakeIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project24":
        return (
          <HandymanIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project25":
        return (
          <SelfImprovementIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project26":
        return (
          <ElectricBoltIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project27":
        return (
          <EmailIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project28":
        return (
          <EscalatorWarningIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project29":
        return (
          <DescriptionIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project30":
        return (
          <DirectionsBoatIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project31":
        return (
          <BusinessIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project32":
        return (
          <CallIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project33":
        return (
          <CalculateIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project34":
        return (
          <CookieIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project35":
        return (
          <DesignServicesIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project36":
        return (
          <HeadsetIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );

      default:
        return null;
    }
  };

  const { primaryColor, complementaryColor } = props;
  const [projectName, setProjectName] = useState(props.activeProject);
  const [projectId, setProjectId] = useState(props.activeProjectId);
  const [companyProject, setCompanyProject] = useState([]);
  const [projectDisplayIcon, setProjectDisplayIcon] = useState(
    props.activeProjectIcon
  );
  const [projectColor, setProjectColor] = useState(props.activeProjectColor);

  const CustomTabs = styled(Tabs)({
    borderBottom: "1px solid #e8e8e8",
    "& .MuiTabs-indicator": {
      backgroundColor: primaryColor,
    },
    "& .MuiTabs-indicator:hover": {
      backgroundColor: complementaryColor,
    },
  });

  const CustomTab = styled(Tab)({
    textTransform: "none",
    fontWeight: "800",
    fontSize: "12px",
    color: "rgba(0,0,0,0.4)",
    "&:hover": {
      color: complementaryColor,
      opacity: 1,
    },
    "&.Mui-selected": {
      color: primaryColor,
      fontSize: "14px",
    },
  });
  const [projectCustomizationModal, setProjectCustomizationModal] =
    useState(false);

  const handleProjectNameBlur = async () => {
    const token = Cookies.get("token");
    try {
      const response = await axios.post(
        `https://ww1.premelo.com/api/projects/update/${props.activeProjectId}`,
        {
          project_name: projectNameCustomization,
          project_color: projectColorCustomization,
          project_icon: projectIconCustomization,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
    } catch (error) {
      console.log(error);
    }

    // Make API call to update project name on server
    setProjectCustomizationModal(false);
  };

  const handleProjectDelete = async () => {
    const token = Cookies.get("token");
    try {
      const response = await axios.delete(
        `https://ww1.premelo.com/api/projects/delete/${props.activeProjectId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
    }

    // Make API call to update project name on server
    props.onProjectDelete();
    console.log("Project deleted:", props.activeProjectId);
  };

  const [projectNameCustomization, setProjectNameCustomization] = useState(
    props.activeProject
  );
  const [projectColorCustomization, setProjectColorCustomization] = useState(
    props.activeProjectColor
  );
  const [projectIconCustomization, setProjectIconCustomization] = useState(
    props.activeProjectIcon
  );

  const fetchCompanyProjects = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/retrieve-company-projects",
      {
        headers: {
          Accept: `application/json`,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    const filteredProjects = data.projects.filter(
      (project) => project.id === props.activeProjectId
    );
    console.log(filteredProjects);
    setCompanyProject(filteredProjects);
  };

  useEffect(() => {
    setProjectName(props.activeProject);
    setProjectId(props.activeProjectId);
    setValue(0);
    fetchCompanyProjects();
  }, [props.activeProject]);
  const [displayContent, setDisplayContent] = useState("");
  useEffect(() => {
    setDisplayContent(
      <CRMMobile
        primaryColor={primaryColor}
        complementaryColor={complementaryColor}
        secondaryColor={props.secondaryColor}
        activeProject={projectName}
        activeProjectId={projectId}
      />
    );
  }, []);
  //   const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    console.log("newValue:", newValue);
    setValue(newValue);
  };

  return (
    <div
      style={{
        marginTop: "-60px",
        width: "100%",
        background: props.primaryColor,
      }}
    >
      <div
        onClick={() => setProjectCustomizationModal(true)}
        style={{ display: "flex", marginBottom: "20px" }}
      >
        <div
          style={{
            marginTop: "20px",
            marginLeft: "20px",
            width: "50px",
            height: "50px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
            backgroundColor: projectColorCustomization,
            color: "white",
          }}
        >
          <div style={{ marginTop: "8px", marginLeft: "5px" }}>
            {projectIcon(projectIconCustomization)}
          </div>
        </div>
        <div
          style={{
            marginLeft: "10px",
            marginTop: "12px",
            color: "white",
            width: `60%`,
          }}
        >
          <h1
            style={{
              fontSize: "20px",
              lineHeight: `20px`,
              marginBottom: `20px`,
              width: `80%`,
            }}
          >
            {projectNameCustomization
              ? projectNameCustomization.slice(0, 30) +
                (projectNameCustomization.length > 30 ? `…` : ` `)
              : "Untitled."}
          </h1>
          <p style={{ fontSize: "12px", marginTop: "-15px", color: "white" }}>
            {projectNameCustomization} task can be handled here!
          </p>
        </div>
      </div>
      <div style={{ width: "100%", height: "80vh", overflow: "scroll" }}>
        <div style={{ display: "none" }}>
          <CustomTabs
            value={value}
            onChange={handleChange}
            aria-label="tabs"
            sx={{
              justifyContent: "flex-start",
              marginTop: "-20px",
              borderBottom: "1px solid rgba(0,0,0,0.5)",
            }}
          >
            <CustomTab label="Task" {...a11yProps(0)} />
            <CustomTab label="Kanban" {...a11yProps(1)} />
            <CustomTab label="Calendar" {...a11yProps(2)} />
            <CustomTab label="Workflow" {...a11yProps(3)} />
            {/* <CustomTab label="Repository" {...a11yProps(4)} /> */}
          </CustomTabs>
        </div>
        <TabPanel value={value} index={0}>
          <CRMMobile
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            secondaryColor={props.secondaryColor}
            activeProject={projectName}
            activeProjectId={projectId}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <KanbanContent
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            secondaryColor={props.secondaryColor}
            activeProject={projectName}
            activeProjectId={projectId}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ProjectContent
            primaryColor={primaryColor}
            activeProject={projectName}
            secondaryColor={props.secondaryColor}
            activeProjectId={projectId}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <WorkflowContent
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            activeProject={projectName}
            secondaryColor={props.secondaryColor}
            activeProjectId={projectId}
          />
        </TabPanel>
        <Modal
          isOpen={projectCustomizationModal}
          onRequestClose={() => setProjectCustomizationModal(false)}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              zIndex: "210",
            },
            content: {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              height: "80%",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              background: "#fff",
              boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
              borderRadius: "10px",
            },
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                marginBottom: "20px",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                  backgroundColor: projectColorCustomization,
                  color: "white",
                }}
              >
                <div style={{ marginTop: "10px", marginLeft: "10px" }}>
                  {projectIcon(projectIconCustomization)}
                </div>
              </div>
              <div style={{ marginLeft: "20px", width: "100%" }}>
                <div style={{ fontSize: "22px", fontWeight: "600" }}>
                  {projectNameCustomization
                    ? projectNameCustomization
                    : "No Project Name"}
                </div>
                <p
                  style={{
                    fontSize: "10px",
                    margin: "-5px 0px 0px 0px",
                    color: "rgba(0,0,0,0.7)",
                  }}
                >
                  All of {projectName} task can be handled here!
                </p>
              </div>
            </div>
            <div style={{ fontSize: "10px" }}>
              Change project name:
              <input
                type="text"
                value={projectNameCustomization}
                placeholder="Type your project name here"
                style={{
                  background: "transparent",
                  border: "1px solid grey",
                  fontWeight: "400",
                  fontSize: "15px",
                  marginTop: "5px",
                  padding: "0px 0px",
                  borderRadius: "10px",
                  width: "96%",
                  padding: "1% 2%",
                }}
                onChange={(e) => {
                  setProjectNameCustomization(e.target.value);
                }}
              />
            </div>
            <div style={{ fontSize: "10px" }}>
              <div style={{ margin: "20px 0px 5px 0px" }}>
                Change project base color:
              </div>
              <SliderPicker
                style={{
                  boxShadow: "none",
                  marginBottom: "-100px",
                }}
                color={projectColorCustomization}
                onChange={(color) => setProjectColorCustomization(color.hex)}
                onChangeComplete={(color) =>
                  setProjectColorCustomization(color.hex)
                }
              />
            </div>
            <div style={{ fontSize: "10px" }}>
              <div style={{ margin: "20px 0px 5px 0px" }}>
                Change project icon:
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  color: "rgba(0,0,0,0.5)",
                  width: "100%",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                <DocumentScannerIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project1")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <LanIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project2")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <GroupsIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project3")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <GroupIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project4")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <AccessibilityNewIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project5")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <AccountTreeIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project6")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <AirplanemodeActiveIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project7")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <ArchitectureIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project8")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <AssignmentIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project9")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <AudiotrackIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project10")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <AccountBalanceIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project11")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <WorkIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project12")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <HubIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project13")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <VolunteerActivismIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project14")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <TerminalIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project15")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <PaymentIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project16")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <CameraAltIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project17")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <ReportProblemIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project18")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <Diversity3Icon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project19")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <SportsEsportsIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project20")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <LocalShippingIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project21")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <RocketLaunchIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project22")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <HandshakeIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project23")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <HandymanIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project24")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <SelfImprovementIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project25")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <ElectricBoltIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project26")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <EmailIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project27")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <EscalatorWarningIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project28")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <DescriptionIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project29")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <DirectionsBoatIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project30")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <BusinessIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project31")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <CallIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project32")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <CalculateIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project33")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <CookieIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project34")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <DesignServicesIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project35")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
                <HeadsetIcon
                  className="mui-button"
                  onClick={() => setProjectIconCustomization("Project36")}
                  style={{
                    marginTop: "-5px",
                    marginRight: "0px",
                    fontSize: "30px",
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "12px",
                marginTop: "20px",
              }}
            >
              <div
                className="mui-button"
                onClick={handleProjectDelete}
                style={{
                  color: "red",
                  border: `1px solid red`,
                  background: `rgba(204, 8, 8, 0.1)`,
                  padding: "4px 10px",
                  borderRadius: "10px",
                }}
              >
                Delete Project
              </div>
              <div
                className="mui-button"
                onClick={handleProjectNameBlur}
                style={{
                  color: primaryColor,
                  border: `1px solid ${primaryColor}`,
                  background: `${primaryColor}1A`,
                  padding: "4px 10px",
                  borderRadius: "10px",
                }}
              >
                Save Project Details
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};
export default ProjectBarLoadoutMobile;
