import React, { useState, useEffect, useRef } from "react";
import "../Builder.css";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import HandymanIcon from "@mui/icons-material/Handyman";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import WidgetsIcon from "@mui/icons-material/Widgets";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import Grid4x4Icon from "@mui/icons-material/Grid4x4";
import imageReference from "../images/imagePlaceholder.png";
import axios from "axios";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import AlignHorizontalCenterIcon from "@mui/icons-material/AlignHorizontalCenter";
import AlignHorizontalRightIcon from "@mui/icons-material/AlignHorizontalRight";
import AlignVerticalTopIcon from "@mui/icons-material/AlignVerticalTop";
import AlignVerticalCenterIcon from "@mui/icons-material/AlignVerticalCenter";
import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import { ChromePicker } from "react-color";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";
import SyncIcon from "@mui/icons-material/Sync";
import VerticalAlignTopIcon from "@mui/icons-material/VerticalAlignTop";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import { cloneDeep, isEqual } from "lodash";
import CollectionsIcon from "@mui/icons-material/Collections";
import SmartButtonIcon from "@mui/icons-material/SmartButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import Modal from "react-modal";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ListAltIcon from "@mui/icons-material/ListAlt";
import BadgeIcon from "@mui/icons-material/Badge";
import CategoryIcon from "@mui/icons-material/Category";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import EventIcon from "@mui/icons-material/Event";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { CircularProgress } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import PinDropIcon from "@mui/icons-material/PinDrop";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import CloseIcon from "@mui/icons-material/Close";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { Country, State, City } from "country-state-city";
import { loadStripe } from "@stripe/stripe-js";
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import * as Icons from "react-icons/fa"; // Replace 'fa' with the desired icon library
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import "leaflet/dist/leaflet.css";
import logo from "../images/CS_Favicon-01.png";
import pexellogo from "../images/pexel_logo.png";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import MenuIcon from "@mui/icons-material/Menu";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";

const IconElement = ({
  selectedElements,
  setNotInputFocus,
  onStyleChange,
  activeWindowStat,
  setActiveWindowStat,
  focusElement,
  element,
  elements,
  onDrag,
  mobileDesigning,
  onDragStart,
  onDragEnd,
  onResizeStart,
  onResize,
  onResizeEnd,
  onFocus,
  onContentChange,
  onRefSet,
  onInputEditing,
  onInputBlur,
  setEditingInputWithArrow,
}) => {
  const [viewportWidth, setViewportWidth] = useState("1920");

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [content, setContent] = useState(element.content || "Paragraph text");
  const [dragStartPosition, setDragStartPosition] = useState({ x: 0, y: 0 });
  const [editing, setEditing] = useState(false);
  const textareaRef = useRef(null);
  const containerRef = useRef(null);
  const [isResizing, setIsResizing] = useState(false);
  const [focus, setFocus] = useState(false);
  const [resizeStartPosition, setResizeStartPosition] = useState({
    x: 0,
    y: 0,
  });
  const [elementInitialWidth, setElementInitialWidth] = useState("");
  const [elementInitialHeight, setElementInitialHeight] = useState("");
  const [draggingOpacity, setDraggingOpacity] = useState(1);
  const handleContentChange = (e) => {
    setContent(e.target.value);
    onContentChange(e.target.value);
  };

  const handleDragStart = (event, elementId, touch) => {
    console.log("drag started", event, elementId);
    if (!touch) {
      event.dataTransfer.setData("text/plain", elementId);
      const dragImages = new Image();
      dragImages.src = ""; // Set an empty source
      event.dataTransfer.setDragImage(dragImages, 0, 0);
    }
    setFocus(true);

    const containerRect = containerRef.current.getBoundingClientRect();
    if (touch) {
      console.log("touch exist");
      const offsetX = touch.clientX - containerRect.left;
      const offsetY = touch.clientY - containerRect.top;

      setDragStartPosition({ x: offsetX, y: offsetY });
    } else {
      const offsetX = event.clientX - containerRect.left;
      const offsetY = event.clientY - containerRect.top;

      setDragStartPosition({ x: offsetX, y: offsetY });
    }

    onDragStart(event, elementId, touch);
  };

  const handleResizeStart = (event, touch) => {
    event.stopPropagation();
    setFocus(true);
    console.log("resize started");
    if (!touch) {
      const dragImage = new Image();
      dragImage.src = ""; // Set an empty source
      event.dataTransfer.setDragImage(dragImage, 0, 0);
    }
    setIsResizing(true);
    if (touch) {
      setResizeStartPosition({
        x: touch.clientX,
        y: touch.clientY,
      });
    } else {
      setResizeStartPosition({
        x: event.clientX,
        y: event.clientY,
      });
    }
    onResizeStart();

    if (mobileDesigning) {
      setElementInitialWidth(element.mwidth ? element.mwidth : element.width);
      setElementInitialHeight(
        element.mheight ? element.mheight : element.height
      );
    } else {
      setElementInitialWidth(element.width);
      setElementInitialHeight(element.height);
    }
  };

  const handleResize = (event, touch) => {
    event.stopPropagation();
    if (isResizing) {
      if (mobileDesigning) {
        if (touch) {
          const dx = touch.clientX - resizeStartPosition.x;
          const dy = touch.clientY - resizeStartPosition.y;
          const newWidth = elementInitialWidth + (dx / 400) * 100;
          const newHeight = elementInitialHeight + dy;
          onResize(newWidth, newHeight);
        } else {
          const dx = event.clientX - resizeStartPosition.x;
          const dy = event.clientY - resizeStartPosition.y;
          const newWidth = elementInitialWidth + (dx / 400) * 100;
          const newHeight = elementInitialHeight + dy;
          onResize(newWidth, newHeight);
        }
      } else {
        if (touch) {
          const dx = touch.clientX - resizeStartPosition.x;
          const dy = touch.clientY - resizeStartPosition.y;
          const newWidth = elementInitialWidth + (dx / viewportWidth) * 100;
          const newHeight = elementInitialHeight + dy;
          onResize(newWidth, newHeight);
        } else {
          const dx = event.clientX - resizeStartPosition.x;
          const dy = event.clientY - resizeStartPosition.y;
          const newWidth = elementInitialWidth + (dx / viewportWidth) * 100;
          const newHeight = elementInitialHeight + dy;
          onResize(newWidth, newHeight);
        }
      }
      // console.log("Resizing:", event.clientX); // Log the resizing values
      // console.log("Resizing:", resizeStartPosition.x); // Log the resizing values
      // console.log("Resizing:", newWidth, newHeight);

      // setResizeStartPosition({ x: event.clientX, y: event.clientY });
    }
  };

  const handleResizeX = (event, touch) => {
    event.stopPropagation();
    if (isResizing) {
      if (mobileDesigning) {
        if (touch) {
          const dx = touch.clientX - resizeStartPosition.x;
          const dy = touch.clientY - resizeStartPosition.y;
          console.log(dx, elementInitialWidth);
          const newWidth = elementInitialWidth + (dx / 400) * 100;
          const newHeight = elementInitialHeight;
          onResize(newWidth, newHeight);
        } else {
          const dx = event.clientX - resizeStartPosition.x;
          const dy = event.clientY - resizeStartPosition.y;
          console.log(dx, elementInitialWidth);
          const newWidth = elementInitialWidth + (dx / 400) * 100;
          const newHeight = elementInitialHeight;
          onResize(newWidth, newHeight);
        }
      } else {
        if (touch) {
          const dx = touch.clientX - resizeStartPosition.x;
          const dy = touch.clientY - resizeStartPosition.y;
          console.log(dx, elementInitialWidth);
          const newWidth = elementInitialWidth + (dx / viewportWidth) * 100;
          const newHeight = elementInitialHeight;
          onResize(newWidth, newHeight);
        } else {
          const dx = event.clientX - resizeStartPosition.x;
          const dy = event.clientY - resizeStartPosition.y;
          console.log(dx, elementInitialWidth);
          const newWidth = elementInitialWidth + (dx / viewportWidth) * 100;
          const newHeight = elementInitialHeight;
          onResize(newWidth, newHeight);
        }
      }
    }
  };

  const handleResizeY = (event, touch) => {
    event.stopPropagation();
    if (isResizing) {
      if (mobileDesigning) {
        if (touch) {
          const dx = touch.clientX - resizeStartPosition.x;
          const dy = touch.clientY - resizeStartPosition.y;
          const newWidth = elementInitialWidth;
          const newHeight = elementInitialHeight + dy;
          onResize(newWidth, newHeight);
        } else {
          const dx = event.clientX - resizeStartPosition.x;
          const dy = event.clientY - resizeStartPosition.y;
          const newWidth = elementInitialWidth;
          const newHeight = elementInitialHeight + dy;
          onResize(newWidth, newHeight);
        }
      } else {
        if (touch) {
          const dx = touch.clientX - resizeStartPosition.x;
          const dy = touch.clientY - resizeStartPosition.y;
          const newWidth = elementInitialWidth;
          const newHeight = elementInitialHeight + dy;
          onResize(newWidth, newHeight);
        } else {
          const dx = event.clientX - resizeStartPosition.x;
          const dy = event.clientY - resizeStartPosition.y;
          const newWidth = elementInitialWidth;
          const newHeight = elementInitialHeight + dy;
          onResize(newWidth, newHeight);
        }
      }
    }
  };

  const handleClick = (event) => {
    setFocus(true);
    onFocus(element.id, event);
  };

  useEffect(() => {
    onRefSet(containerRef);
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setFocus(false);
        onFocus(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleResizeEnd = () => {
    setIsResizing(false);
    onResizeEnd();
  };

  useEffect(() => {
    if (editing) {
      textareaRef.current.focus();
    }
  }, [editing]);

  const [primaryColor, setPrimaryColor] = useState(
    Cookies.get("company_color") ?? "#1872d9"
  );

  const stylingChoices = [
    {
      style_group: "Icon Styling",
      style_options: [
        {
          style_name: "icon",
          style_display: "Select icon",
          style_type: "chooseIcon",
        },
        {
          style_name: "titleFontSize",
          style_display: "Icon Font Size",
          style_type: "pixel",
        },
        {
          style_name: "titleFontColor",
          style_display: "Icon Color",
          style_type: "color",
        },
      ],
    },
  ];

  const [colorState, setColorState] = useState({});
  // Function to update the color state
  const updateColorState = (style_name, color) => {
    setColorState((prevColorState) => ({
      ...prevColorState,
      [style_name]: color,
    }));
  };

  const defaultBoxShadow = {
    horizontal: "-10",
    vertical: "10",
    blur: "10",
    color: "rgba(0,0,0,1)",
  };

  const [boxShadowState, setBoxShadowState] = useState({});

  // Function to update the boxShadow state
  const updateBoxShadowState = (style_name, prop, value) => {
    setBoxShadowState((prevBoxShadowState) => ({
      ...prevBoxShadowState,
      [style_name]: {
        ...prevBoxShadowState[style_name],
        [prop]: value,
      },
    }));
  };

  const [openSections, setOpenSections] = useState(
    new Array(stylingChoices.length).fill(false)
  );

  // Function to toggle the section's open/closed state based on index
  const toggleSection = (index) => {
    const updatedOpenSections = [...openSections];
    updatedOpenSections[index] = !updatedOpenSections[index];
    setOpenSections(updatedOpenSections);
  };

  const [fileUploading, setFileUploading] = useState(false);

  const handleImageUploadElement = async (e, style_name) => {
    setFileUploading(true);
    const file = e.target.files;
    console.log(e.target.files);

    const formData = new FormData();

    for (let i = 0; i < file.length; i++) {
      formData.append("files[]", file[i]);
    }

    try {
      const response = await axios.post(
        "https://ww1.premelo.com/api/upload-image",
        formData
      );
      const imageUrl = response.data.urls;
      console.log(style_name, imageUrl[0]);
      onStyleChange(style_name, imageUrl[0]);
      setFileUploading(false);
    } catch (error) {
      console.log(error);
      setFileUploading(false);
    }
  };

  const handleClickImageInput = () => {
    const fileInput = document.getElementById("imageInputElement");
    fileInput.click();
  };

  const [imageModalStates, setImageModalStates] = useState(false);
  const [imageSearchQuery, setImageSearchQuery] = useState("");
  const [icon, setIcon] = useState(element.icon);
  const [imageSearchResult, setImageSearchResult] = useState([
    {
      id: 1484806,
      src: {
        original:
          "https://images.pexels.com/photos/1484806/pexels-photo-1484806.jpeg",
        large2x:
          "https://images.pexels.com/photos/1484806/pexels-photo-1484806.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1484806/pexels-photo-1484806.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1484806/pexels-photo-1484806.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1484806/pexels-photo-1484806.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1484806/pexels-photo-1484806.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1484806/pexels-photo-1484806.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1484806/pexels-photo-1484806.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 4006576,
      src: {
        original:
          "https://images.pexels.com/photos/4006576/pexels-photo-4006576.jpeg",
        large2x:
          "https://images.pexels.com/photos/4006576/pexels-photo-4006576.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/4006576/pexels-photo-4006576.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/4006576/pexels-photo-4006576.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/4006576/pexels-photo-4006576.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/4006576/pexels-photo-4006576.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/4006576/pexels-photo-4006576.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/4006576/pexels-photo-4006576.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 4148984,
      src: {
        original:
          "https://images.pexels.com/photos/4148984/pexels-photo-4148984.jpeg",
        large2x:
          "https://images.pexels.com/photos/4148984/pexels-photo-4148984.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/4148984/pexels-photo-4148984.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/4148984/pexels-photo-4148984.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/4148984/pexels-photo-4148984.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/4148984/pexels-photo-4148984.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/4148984/pexels-photo-4148984.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/4148984/pexels-photo-4148984.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 4064423,
      src: {
        original:
          "https://images.pexels.com/photos/4064423/pexels-photo-4064423.jpeg",
        large2x:
          "https://images.pexels.com/photos/4064423/pexels-photo-4064423.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/4064423/pexels-photo-4064423.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/4064423/pexels-photo-4064423.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/4064423/pexels-photo-4064423.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/4064423/pexels-photo-4064423.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/4064423/pexels-photo-4064423.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/4064423/pexels-photo-4064423.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 2230015,
      src: {
        original:
          "https://images.pexels.com/photos/2230015/pexels-photo-2230015.jpeg",
        large2x:
          "https://images.pexels.com/photos/2230015/pexels-photo-2230015.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/2230015/pexels-photo-2230015.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/2230015/pexels-photo-2230015.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/2230015/pexels-photo-2230015.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/2230015/pexels-photo-2230015.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/2230015/pexels-photo-2230015.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/2230015/pexels-photo-2230015.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 3628700,
      src: {
        original:
          "https://images.pexels.com/photos/3628700/pexels-photo-3628700.jpeg",
        large2x:
          "https://images.pexels.com/photos/3628700/pexels-photo-3628700.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/3628700/pexels-photo-3628700.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/3628700/pexels-photo-3628700.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/3628700/pexels-photo-3628700.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/3628700/pexels-photo-3628700.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/3628700/pexels-photo-3628700.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/3628700/pexels-photo-3628700.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 3732667,
      src: {
        original:
          "https://images.pexels.com/photos/3732667/pexels-photo-3732667.jpeg",
        large2x:
          "https://images.pexels.com/photos/3732667/pexels-photo-3732667.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/3732667/pexels-photo-3732667.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/3732667/pexels-photo-3732667.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/3732667/pexels-photo-3732667.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/3732667/pexels-photo-3732667.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/3732667/pexels-photo-3732667.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/3732667/pexels-photo-3732667.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1181579,
      src: {
        original:
          "https://images.pexels.com/photos/1181579/pexels-photo-1181579.jpeg",
        large2x:
          "https://images.pexels.com/photos/1181579/pexels-photo-1181579.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1181579/pexels-photo-1181579.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1181579/pexels-photo-1181579.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1181579/pexels-photo-1181579.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1181579/pexels-photo-1181579.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1181579/pexels-photo-1181579.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1181579/pexels-photo-1181579.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1181424,
      src: {
        original:
          "https://images.pexels.com/photos/1181424/pexels-photo-1181424.jpeg",
        large2x:
          "https://images.pexels.com/photos/1181424/pexels-photo-1181424.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1181424/pexels-photo-1181424.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1181424/pexels-photo-1181424.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1181424/pexels-photo-1181424.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1181424/pexels-photo-1181424.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1181424/pexels-photo-1181424.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1181424/pexels-photo-1181424.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 3852204,
      src: {
        original:
          "https://images.pexels.com/photos/3852204/pexels-photo-3852204.jpeg",
        large2x:
          "https://images.pexels.com/photos/3852204/pexels-photo-3852204.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/3852204/pexels-photo-3852204.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/3852204/pexels-photo-3852204.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/3852204/pexels-photo-3852204.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/3852204/pexels-photo-3852204.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/3852204/pexels-photo-3852204.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/3852204/pexels-photo-3852204.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1250643,
      src: {
        original:
          "https://images.pexels.com/photos/1250643/pexels-photo-1250643.jpeg",
        large2x:
          "https://images.pexels.com/photos/1250643/pexels-photo-1250643.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1250643/pexels-photo-1250643.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1250643/pexels-photo-1250643.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1250643/pexels-photo-1250643.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1250643/pexels-photo-1250643.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1250643/pexels-photo-1250643.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1250643/pexels-photo-1250643.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1674666,
      src: {
        original:
          "https://images.pexels.com/photos/1674666/pexels-photo-1674666.jpeg",
        large2x:
          "https://images.pexels.com/photos/1674666/pexels-photo-1674666.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1674666/pexels-photo-1674666.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1674666/pexels-photo-1674666.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1674666/pexels-photo-1674666.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1674666/pexels-photo-1674666.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1674666/pexels-photo-1674666.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1674666/pexels-photo-1674666.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 2064826,
      src: {
        original:
          "https://images.pexels.com/photos/2064826/pexels-photo-2064826.jpeg",
        large2x:
          "https://images.pexels.com/photos/2064826/pexels-photo-2064826.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/2064826/pexels-photo-2064826.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/2064826/pexels-photo-2064826.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/2064826/pexels-photo-2064826.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/2064826/pexels-photo-2064826.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/2064826/pexels-photo-2064826.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/2064826/pexels-photo-2064826.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 6646919,
      src: {
        original:
          "https://images.pexels.com/photos/6646919/pexels-photo-6646919.jpeg",
        large2x:
          "https://images.pexels.com/photos/6646919/pexels-photo-6646919.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/6646919/pexels-photo-6646919.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/6646919/pexels-photo-6646919.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/6646919/pexels-photo-6646919.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/6646919/pexels-photo-6646919.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/6646919/pexels-photo-6646919.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/6646919/pexels-photo-6646919.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 4259140,
      src: {
        original:
          "https://images.pexels.com/photos/4259140/pexels-photo-4259140.jpeg",
        large2x:
          "https://images.pexels.com/photos/4259140/pexels-photo-4259140.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/4259140/pexels-photo-4259140.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/4259140/pexels-photo-4259140.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/4259140/pexels-photo-4259140.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/4259140/pexels-photo-4259140.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/4259140/pexels-photo-4259140.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/4259140/pexels-photo-4259140.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 2853592,
      src: {
        original:
          "https://images.pexels.com/photos/2853592/pexels-photo-2853592.jpeg",
        large2x:
          "https://images.pexels.com/photos/2853592/pexels-photo-2853592.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/2853592/pexels-photo-2853592.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/2853592/pexels-photo-2853592.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/2853592/pexels-photo-2853592.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/2853592/pexels-photo-2853592.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/2853592/pexels-photo-2853592.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/2853592/pexels-photo-2853592.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1486213,
      src: {
        original:
          "https://images.pexels.com/photos/1486213/pexels-photo-1486213.jpeg",
        large2x:
          "https://images.pexels.com/photos/1486213/pexels-photo-1486213.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1486213/pexels-photo-1486213.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1486213/pexels-photo-1486213.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1486213/pexels-photo-1486213.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1486213/pexels-photo-1486213.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1486213/pexels-photo-1486213.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1486213/pexels-photo-1486213.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 4057758,
      src: {
        original:
          "https://images.pexels.com/photos/4057758/pexels-photo-4057758.jpeg",
        large2x:
          "https://images.pexels.com/photos/4057758/pexels-photo-4057758.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/4057758/pexels-photo-4057758.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/4057758/pexels-photo-4057758.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/4057758/pexels-photo-4057758.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/4057758/pexels-photo-4057758.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/4057758/pexels-photo-4057758.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/4057758/pexels-photo-4057758.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 5637733,
      src: {
        original:
          "https://images.pexels.com/photos/5637733/pexels-photo-5637733.jpeg",
        large2x:
          "https://images.pexels.com/photos/5637733/pexels-photo-5637733.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/5637733/pexels-photo-5637733.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/5637733/pexels-photo-5637733.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/5637733/pexels-photo-5637733.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/5637733/pexels-photo-5637733.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/5637733/pexels-photo-5637733.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/5637733/pexels-photo-5637733.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 7330926,
      src: {
        original:
          "https://images.pexels.com/photos/7330926/pexels-photo-7330926.jpeg",
        large2x:
          "https://images.pexels.com/photos/7330926/pexels-photo-7330926.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/7330926/pexels-photo-7330926.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/7330926/pexels-photo-7330926.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/7330926/pexels-photo-7330926.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/7330926/pexels-photo-7330926.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/7330926/pexels-photo-7330926.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/7330926/pexels-photo-7330926.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 7533347,
      src: {
        original:
          "https://images.pexels.com/photos/7533347/pexels-photo-7533347.jpeg",
        large2x:
          "https://images.pexels.com/photos/7533347/pexels-photo-7533347.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/7533347/pexels-photo-7533347.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/7533347/pexels-photo-7533347.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/7533347/pexels-photo-7533347.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/7533347/pexels-photo-7533347.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/7533347/pexels-photo-7533347.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/7533347/pexels-photo-7533347.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 7217963,
      src: {
        original:
          "https://images.pexels.com/photos/7217963/pexels-photo-7217963.jpeg",
        large2x:
          "https://images.pexels.com/photos/7217963/pexels-photo-7217963.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/7217963/pexels-photo-7217963.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/7217963/pexels-photo-7217963.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/7217963/pexels-photo-7217963.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/7217963/pexels-photo-7217963.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/7217963/pexels-photo-7217963.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/7217963/pexels-photo-7217963.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 9037592,
      src: {
        original:
          "https://images.pexels.com/photos/9037592/pexels-photo-9037592.jpeg",
        large2x:
          "https://images.pexels.com/photos/9037592/pexels-photo-9037592.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/9037592/pexels-photo-9037592.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/9037592/pexels-photo-9037592.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/9037592/pexels-photo-9037592.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/9037592/pexels-photo-9037592.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/9037592/pexels-photo-9037592.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/9037592/pexels-photo-9037592.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 12118422,
      src: {
        original:
          "https://images.pexels.com/photos/12118422/pexels-photo-12118422.jpeg",
        large2x:
          "https://images.pexels.com/photos/12118422/pexels-photo-12118422.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/12118422/pexels-photo-12118422.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/12118422/pexels-photo-12118422.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/12118422/pexels-photo-12118422.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/12118422/pexels-photo-12118422.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/12118422/pexels-photo-12118422.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/12118422/pexels-photo-12118422.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16055440,
      src: {
        original:
          "https://images.pexels.com/photos/16055440/pexels-photo-16055440.jpeg",
        large2x:
          "https://images.pexels.com/photos/16055440/pexels-photo-16055440.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16055440/pexels-photo-16055440.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16055440/pexels-photo-16055440.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16055440/pexels-photo-16055440.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16055440/pexels-photo-16055440.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16055440/pexels-photo-16055440.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16055440/pexels-photo-16055440.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 9705768,
      src: {
        original:
          "https://images.pexels.com/photos/9705768/pexels-photo-9705768.jpeg",
        large2x:
          "https://images.pexels.com/photos/9705768/pexels-photo-9705768.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/9705768/pexels-photo-9705768.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/9705768/pexels-photo-9705768.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/9705768/pexels-photo-9705768.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/9705768/pexels-photo-9705768.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/9705768/pexels-photo-9705768.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/9705768/pexels-photo-9705768.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 15212752,
      src: {
        original:
          "https://images.pexels.com/photos/15212752/pexels-photo-15212752.jpeg",
        large2x:
          "https://images.pexels.com/photos/15212752/pexels-photo-15212752.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/15212752/pexels-photo-15212752.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/15212752/pexels-photo-15212752.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/15212752/pexels-photo-15212752.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/15212752/pexels-photo-15212752.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/15212752/pexels-photo-15212752.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/15212752/pexels-photo-15212752.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 12495575,
      src: {
        original:
          "https://images.pexels.com/photos/12495575/pexels-photo-12495575.png",
        large2x:
          "https://images.pexels.com/photos/12495575/pexels-photo-12495575.png?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/12495575/pexels-photo-12495575.png?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/12495575/pexels-photo-12495575.png?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/12495575/pexels-photo-12495575.png?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/12495575/pexels-photo-12495575.png?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/12495575/pexels-photo-12495575.png?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/12495575/pexels-photo-12495575.png?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16849804,
      src: {
        original:
          "https://images.pexels.com/photos/16849804/pexels-photo-16849804.jpeg",
        large2x:
          "https://images.pexels.com/photos/16849804/pexels-photo-16849804.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16849804/pexels-photo-16849804.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16849804/pexels-photo-16849804.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16849804/pexels-photo-16849804.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16849804/pexels-photo-16849804.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16849804/pexels-photo-16849804.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16849804/pexels-photo-16849804.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16756606,
      src: {
        original:
          "https://images.pexels.com/photos/16756606/pexels-photo-16756606.jpeg",
        large2x:
          "https://images.pexels.com/photos/16756606/pexels-photo-16756606.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16756606/pexels-photo-16756606.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16756606/pexels-photo-16756606.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16756606/pexels-photo-16756606.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16756606/pexels-photo-16756606.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16756606/pexels-photo-16756606.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16756606/pexels-photo-16756606.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 7656336,
      src: {
        original:
          "https://images.pexels.com/photos/7656336/pexels-photo-7656336.jpeg",
        large2x:
          "https://images.pexels.com/photos/7656336/pexels-photo-7656336.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/7656336/pexels-photo-7656336.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/7656336/pexels-photo-7656336.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/7656336/pexels-photo-7656336.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/7656336/pexels-photo-7656336.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/7656336/pexels-photo-7656336.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/7656336/pexels-photo-7656336.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16881386,
      src: {
        original:
          "https://images.pexels.com/photos/16881386/pexels-photo-16881386.jpeg",
        large2x:
          "https://images.pexels.com/photos/16881386/pexels-photo-16881386.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16881386/pexels-photo-16881386.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16881386/pexels-photo-16881386.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16881386/pexels-photo-16881386.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16881386/pexels-photo-16881386.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16881386/pexels-photo-16881386.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16881386/pexels-photo-16881386.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16907832,
      src: {
        original:
          "https://images.pexels.com/photos/16907832/pexels-photo-16907832.jpeg",
        large2x:
          "https://images.pexels.com/photos/16907832/pexels-photo-16907832.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16907832/pexels-photo-16907832.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16907832/pexels-photo-16907832.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16907832/pexels-photo-16907832.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16907832/pexels-photo-16907832.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16907832/pexels-photo-16907832.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16907832/pexels-photo-16907832.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16938786,
      src: {
        original:
          "https://images.pexels.com/photos/16938786/pexels-photo-16938786.jpeg",
        large2x:
          "https://images.pexels.com/photos/16938786/pexels-photo-16938786.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16938786/pexels-photo-16938786.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16938786/pexels-photo-16938786.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16938786/pexels-photo-16938786.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16938786/pexels-photo-16938786.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16938786/pexels-photo-16938786.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16938786/pexels-photo-16938786.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16941392,
      src: {
        original:
          "https://images.pexels.com/photos/16941392/pexels-photo-16941392.jpeg",
        large2x:
          "https://images.pexels.com/photos/16941392/pexels-photo-16941392.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16941392/pexels-photo-16941392.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16941392/pexels-photo-16941392.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16941392/pexels-photo-16941392.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16941392/pexels-photo-16941392.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16941392/pexels-photo-16941392.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16941392/pexels-photo-16941392.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16671959,
      src: {
        original:
          "https://images.pexels.com/photos/16671959/pexels-photo-16671959.jpeg",
        large2x:
          "https://images.pexels.com/photos/16671959/pexels-photo-16671959.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16671959/pexels-photo-16671959.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16671959/pexels-photo-16671959.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16671959/pexels-photo-16671959.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16671959/pexels-photo-16671959.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16671959/pexels-photo-16671959.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16671959/pexels-photo-16671959.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16733847,
      src: {
        original:
          "https://images.pexels.com/photos/16733847/pexels-photo-16733847.jpeg",
        large2x:
          "https://images.pexels.com/photos/16733847/pexels-photo-16733847.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16733847/pexels-photo-16733847.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16733847/pexels-photo-16733847.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16733847/pexels-photo-16733847.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16733847/pexels-photo-16733847.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16733847/pexels-photo-16733847.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16733847/pexels-photo-16733847.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16166109,
      src: {
        original:
          "https://images.pexels.com/photos/16166109/pexels-photo-16166109.jpeg",
        large2x:
          "https://images.pexels.com/photos/16166109/pexels-photo-16166109.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16166109/pexels-photo-16166109.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16166109/pexels-photo-16166109.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16166109/pexels-photo-16166109.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16166109/pexels-photo-16166109.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16166109/pexels-photo-16166109.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16166109/pexels-photo-16166109.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16853434,
      src: {
        original:
          "https://images.pexels.com/photos/16853434/pexels-photo-16853434.jpeg",
        large2x:
          "https://images.pexels.com/photos/16853434/pexels-photo-16853434.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16853434/pexels-photo-16853434.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16853434/pexels-photo-16853434.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16853434/pexels-photo-16853434.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16853434/pexels-photo-16853434.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16853434/pexels-photo-16853434.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16853434/pexels-photo-16853434.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16922807,
      src: {
        original:
          "https://images.pexels.com/photos/16922807/pexels-photo-16922807.png",
        large2x:
          "https://images.pexels.com/photos/16922807/pexels-photo-16922807.png?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16922807/pexels-photo-16922807.png?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16922807/pexels-photo-16922807.png?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16922807/pexels-photo-16922807.png?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16922807/pexels-photo-16922807.png?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16922807/pexels-photo-16922807.png?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16922807/pexels-photo-16922807.png?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16958033,
      src: {
        original:
          "https://images.pexels.com/photos/16958033/pexels-photo-16958033.jpeg",
        large2x:
          "https://images.pexels.com/photos/16958033/pexels-photo-16958033.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16958033/pexels-photo-16958033.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16958033/pexels-photo-16958033.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16958033/pexels-photo-16958033.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16958033/pexels-photo-16958033.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16958033/pexels-photo-16958033.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16958033/pexels-photo-16958033.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16977372,
      src: {
        original:
          "https://images.pexels.com/photos/16977372/pexels-photo-16977372.jpeg",
        large2x:
          "https://images.pexels.com/photos/16977372/pexels-photo-16977372.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16977372/pexels-photo-16977372.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16977372/pexels-photo-16977372.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16977372/pexels-photo-16977372.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16977372/pexels-photo-16977372.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16977372/pexels-photo-16977372.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16977372/pexels-photo-16977372.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16765277,
      src: {
        original:
          "https://images.pexels.com/photos/16765277/pexels-photo-16765277.jpeg",
        large2x:
          "https://images.pexels.com/photos/16765277/pexels-photo-16765277.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16765277/pexels-photo-16765277.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16765277/pexels-photo-16765277.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16765277/pexels-photo-16765277.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16765277/pexels-photo-16765277.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16765277/pexels-photo-16765277.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16765277/pexels-photo-16765277.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16462955,
      src: {
        original:
          "https://images.pexels.com/photos/16462955/pexels-photo-16462955.jpeg",
        large2x:
          "https://images.pexels.com/photos/16462955/pexels-photo-16462955.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16462955/pexels-photo-16462955.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16462955/pexels-photo-16462955.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16462955/pexels-photo-16462955.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16462955/pexels-photo-16462955.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16462955/pexels-photo-16462955.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16462955/pexels-photo-16462955.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 837358,
      src: {
        original:
          "https://images.pexels.com/photos/837358/pexels-photo-837358.jpeg",
        large2x:
          "https://images.pexels.com/photos/837358/pexels-photo-837358.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/837358/pexels-photo-837358.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/837358/pexels-photo-837358.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/837358/pexels-photo-837358.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/837358/pexels-photo-837358.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/837358/pexels-photo-837358.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/837358/pexels-photo-837358.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 39866,
      src: {
        original:
          "https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg",
        large2x:
          "https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 445109,
      src: {
        original:
          "https://images.pexels.com/photos/445109/pexels-photo-445109.jpeg",
        large2x:
          "https://images.pexels.com/photos/445109/pexels-photo-445109.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/445109/pexels-photo-445109.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/445109/pexels-photo-445109.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/445109/pexels-photo-445109.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/445109/pexels-photo-445109.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/445109/pexels-photo-445109.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/445109/pexels-photo-445109.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1709003,
      src: {
        original:
          "https://images.pexels.com/photos/1709003/pexels-photo-1709003.jpeg",
        large2x:
          "https://images.pexels.com/photos/1709003/pexels-photo-1709003.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1709003/pexels-photo-1709003.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1709003/pexels-photo-1709003.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1709003/pexels-photo-1709003.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1709003/pexels-photo-1709003.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1709003/pexels-photo-1709003.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1709003/pexels-photo-1709003.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 240561,
      src: {
        original:
          "https://images.pexels.com/photos/240561/pexels-photo-240561.jpeg",
        large2x:
          "https://images.pexels.com/photos/240561/pexels-photo-240561.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/240561/pexels-photo-240561.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/240561/pexels-photo-240561.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/240561/pexels-photo-240561.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/240561/pexels-photo-240561.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/240561/pexels-photo-240561.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/240561/pexels-photo-240561.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 889545,
      src: {
        original:
          "https://images.pexels.com/photos/889545/pexels-photo-889545.jpeg",
        large2x:
          "https://images.pexels.com/photos/889545/pexels-photo-889545.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/889545/pexels-photo-889545.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/889545/pexels-photo-889545.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/889545/pexels-photo-889545.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/889545/pexels-photo-889545.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/889545/pexels-photo-889545.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/889545/pexels-photo-889545.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 699459,
      src: {
        original:
          "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg",
        large2x:
          "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1245055,
      src: {
        original:
          "https://images.pexels.com/photos/1245055/pexels-photo-1245055.jpeg",
        large2x:
          "https://images.pexels.com/photos/1245055/pexels-photo-1245055.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1245055/pexels-photo-1245055.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1245055/pexels-photo-1245055.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1245055/pexels-photo-1245055.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1245055/pexels-photo-1245055.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1245055/pexels-photo-1245055.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1245055/pexels-photo-1245055.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 267961,
      src: {
        original:
          "https://images.pexels.com/photos/267961/pexels-photo-267961.jpeg",
        large2x:
          "https://images.pexels.com/photos/267961/pexels-photo-267961.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/267961/pexels-photo-267961.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/267961/pexels-photo-267961.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/267961/pexels-photo-267961.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/267961/pexels-photo-267961.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/267961/pexels-photo-267961.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/267961/pexels-photo-267961.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 903171,
      src: {
        original:
          "https://images.pexels.com/photos/903171/pexels-photo-903171.jpeg",
        large2x:
          "https://images.pexels.com/photos/903171/pexels-photo-903171.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/903171/pexels-photo-903171.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/903171/pexels-photo-903171.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/903171/pexels-photo-903171.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/903171/pexels-photo-903171.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/903171/pexels-photo-903171.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/903171/pexels-photo-903171.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1087735,
      src: {
        original:
          "https://images.pexels.com/photos/1087735/pexels-photo-1087735.jpeg",
        large2x:
          "https://images.pexels.com/photos/1087735/pexels-photo-1087735.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1087735/pexels-photo-1087735.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1087735/pexels-photo-1087735.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1087735/pexels-photo-1087735.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1087735/pexels-photo-1087735.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1087735/pexels-photo-1087735.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1087735/pexels-photo-1087735.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 746386,
      src: {
        original:
          "https://images.pexels.com/photos/746386/pexels-photo-746386.jpeg",
        large2x:
          "https://images.pexels.com/photos/746386/pexels-photo-746386.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/746386/pexels-photo-746386.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/746386/pexels-photo-746386.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/746386/pexels-photo-746386.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/746386/pexels-photo-746386.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/746386/pexels-photo-746386.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/746386/pexels-photo-746386.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 845457,
      src: {
        original:
          "https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg",
        large2x:
          "https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 34514,
      src: {
        original:
          "https://images.pexels.com/photos/34514/spot-runs-start-la.jpg",
        large2x:
          "https://images.pexels.com/photos/34514/spot-runs-start-la.jpg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/34514/spot-runs-start-la.jpg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/34514/spot-runs-start-la.jpg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/34514/spot-runs-start-la.jpg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/34514/spot-runs-start-la.jpg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/34514/spot-runs-start-la.jpg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/34514/spot-runs-start-la.jpg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1472334,
      src: {
        original:
          "https://images.pexels.com/photos/1472334/pexels-photo-1472334.jpeg",
        large2x:
          "https://images.pexels.com/photos/1472334/pexels-photo-1472334.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1472334/pexels-photo-1472334.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1472334/pexels-photo-1472334.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1472334/pexels-photo-1472334.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1472334/pexels-photo-1472334.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1472334/pexels-photo-1472334.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1472334/pexels-photo-1472334.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 762080,
      src: {
        original:
          "https://images.pexels.com/photos/762080/pexels-photo-762080.jpeg",
        large2x:
          "https://images.pexels.com/photos/762080/pexels-photo-762080.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/762080/pexels-photo-762080.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/762080/pexels-photo-762080.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/762080/pexels-photo-762080.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/762080/pexels-photo-762080.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/762080/pexels-photo-762080.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/762080/pexels-photo-762080.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 762527,
      src: {
        original:
          "https://images.pexels.com/photos/762527/pexels-photo-762527.jpeg",
        large2x:
          "https://images.pexels.com/photos/762527/pexels-photo-762527.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/762527/pexels-photo-762527.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/762527/pexels-photo-762527.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/762527/pexels-photo-762527.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/762527/pexels-photo-762527.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/762527/pexels-photo-762527.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/762527/pexels-photo-762527.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 853151,
      src: {
        original:
          "https://images.pexels.com/photos/853151/pexels-photo-853151.jpeg",
        large2x:
          "https://images.pexels.com/photos/853151/pexels-photo-853151.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/853151/pexels-photo-853151.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/853151/pexels-photo-853151.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/853151/pexels-photo-853151.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/853151/pexels-photo-853151.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/853151/pexels-photo-853151.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/853151/pexels-photo-853151.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 301952,
      src: {
        original:
          "https://images.pexels.com/photos/301952/pexels-photo-301952.jpeg",
        large2x:
          "https://images.pexels.com/photos/301952/pexels-photo-301952.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/301952/pexels-photo-301952.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/301952/pexels-photo-301952.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/301952/pexels-photo-301952.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/301952/pexels-photo-301952.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/301952/pexels-photo-301952.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/301952/pexels-photo-301952.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 2102416,
      src: {
        original:
          "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg",
        large2x:
          "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 53214,
      src: {
        original:
          "https://images.pexels.com/photos/53214/trekking-hiking-group-alpine-53214.jpeg",
        large2x:
          "https://images.pexels.com/photos/53214/trekking-hiking-group-alpine-53214.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/53214/trekking-hiking-group-alpine-53214.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/53214/trekking-hiking-group-alpine-53214.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/53214/trekking-hiking-group-alpine-53214.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/53214/trekking-hiking-group-alpine-53214.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/53214/trekking-hiking-group-alpine-53214.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/53214/trekking-hiking-group-alpine-53214.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 601170,
      src: {
        original:
          "https://images.pexels.com/photos/601170/pexels-photo-601170.jpeg",
        large2x:
          "https://images.pexels.com/photos/601170/pexels-photo-601170.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/601170/pexels-photo-601170.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/601170/pexels-photo-601170.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/601170/pexels-photo-601170.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/601170/pexels-photo-601170.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/601170/pexels-photo-601170.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/601170/pexels-photo-601170.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 307847,
      src: {
        original:
          "https://images.pexels.com/photos/307847/pexels-photo-307847.jpeg",
        large2x:
          "https://images.pexels.com/photos/307847/pexels-photo-307847.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/307847/pexels-photo-307847.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/307847/pexels-photo-307847.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/307847/pexels-photo-307847.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/307847/pexels-photo-307847.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/307847/pexels-photo-307847.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/307847/pexels-photo-307847.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 214576,
      src: {
        original:
          "https://images.pexels.com/photos/214576/pexels-photo-214576.jpeg",
        large2x:
          "https://images.pexels.com/photos/214576/pexels-photo-214576.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/214576/pexels-photo-214576.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/214576/pexels-photo-214576.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/214576/pexels-photo-214576.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/214576/pexels-photo-214576.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/214576/pexels-photo-214576.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/214576/pexels-photo-214576.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1822608,
      src: {
        original:
          "https://images.pexels.com/photos/1822608/pexels-photo-1822608.jpeg",
        large2x:
          "https://images.pexels.com/photos/1822608/pexels-photo-1822608.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1822608/pexels-photo-1822608.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1822608/pexels-photo-1822608.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1822608/pexels-photo-1822608.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1822608/pexels-photo-1822608.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1822608/pexels-photo-1822608.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1822608/pexels-photo-1822608.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 247304,
      src: {
        original:
          "https://images.pexels.com/photos/247304/pexels-photo-247304.jpeg",
        large2x:
          "https://images.pexels.com/photos/247304/pexels-photo-247304.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/247304/pexels-photo-247304.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/247304/pexels-photo-247304.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/247304/pexels-photo-247304.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/247304/pexels-photo-247304.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/247304/pexels-photo-247304.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/247304/pexels-photo-247304.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 460295,
      src: {
        original:
          "https://images.pexels.com/photos/460295/pexels-photo-460295.jpeg",
        large2x:
          "https://images.pexels.com/photos/460295/pexels-photo-460295.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/460295/pexels-photo-460295.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/460295/pexels-photo-460295.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/460295/pexels-photo-460295.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/460295/pexels-photo-460295.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/460295/pexels-photo-460295.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/460295/pexels-photo-460295.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 439818,
      src: {
        original:
          "https://images.pexels.com/photos/439818/pexels-photo-439818.jpeg",
        large2x:
          "https://images.pexels.com/photos/439818/pexels-photo-439818.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/439818/pexels-photo-439818.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/439818/pexels-photo-439818.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/439818/pexels-photo-439818.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/439818/pexels-photo-439818.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/439818/pexels-photo-439818.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/439818/pexels-photo-439818.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1125850,
      src: {
        original:
          "https://images.pexels.com/photos/1125850/pexels-photo-1125850.jpeg",
        large2x:
          "https://images.pexels.com/photos/1125850/pexels-photo-1125850.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1125850/pexels-photo-1125850.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1125850/pexels-photo-1125850.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1125850/pexels-photo-1125850.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1125850/pexels-photo-1125850.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1125850/pexels-photo-1125850.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1125850/pexels-photo-1125850.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 571169,
      src: {
        original:
          "https://images.pexels.com/photos/571169/pexels-photo-571169.jpeg",
        large2x:
          "https://images.pexels.com/photos/571169/pexels-photo-571169.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/571169/pexels-photo-571169.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/571169/pexels-photo-571169.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/571169/pexels-photo-571169.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/571169/pexels-photo-571169.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/571169/pexels-photo-571169.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/571169/pexels-photo-571169.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 927451,
      src: {
        original:
          "https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg",
        large2x:
          "https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1848565,
      src: {
        original:
          "https://images.pexels.com/photos/1848565/pexels-photo-1848565.jpeg",
        large2x:
          "https://images.pexels.com/photos/1848565/pexels-photo-1848565.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1848565/pexels-photo-1848565.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1848565/pexels-photo-1848565.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1848565/pexels-photo-1848565.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1848565/pexels-photo-1848565.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1848565/pexels-photo-1848565.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1848565/pexels-photo-1848565.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 819530,
      src: {
        original:
          "https://images.pexels.com/photos/819530/pexels-photo-819530.jpeg",
        large2x:
          "https://images.pexels.com/photos/819530/pexels-photo-819530.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/819530/pexels-photo-819530.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/819530/pexels-photo-819530.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/819530/pexels-photo-819530.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/819530/pexels-photo-819530.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/819530/pexels-photo-819530.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/819530/pexels-photo-819530.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 886285,
      src: {
        original:
          "https://images.pexels.com/photos/886285/pexels-photo-886285.jpeg",
        large2x:
          "https://images.pexels.com/photos/886285/pexels-photo-886285.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/886285/pexels-photo-886285.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/886285/pexels-photo-886285.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/886285/pexels-photo-886285.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/886285/pexels-photo-886285.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/886285/pexels-photo-886285.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/886285/pexels-photo-886285.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 673649,
      src: {
        original:
          "https://images.pexels.com/photos/673649/pexels-photo-673649.jpeg",
        large2x:
          "https://images.pexels.com/photos/673649/pexels-photo-673649.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/673649/pexels-photo-673649.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/673649/pexels-photo-673649.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/673649/pexels-photo-673649.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/673649/pexels-photo-673649.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/673649/pexels-photo-673649.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/673649/pexels-photo-673649.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 236171,
      src: {
        original:
          "https://images.pexels.com/photos/236171/pexels-photo-236171.jpeg",
        large2x:
          "https://images.pexels.com/photos/236171/pexels-photo-236171.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/236171/pexels-photo-236171.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/236171/pexels-photo-236171.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/236171/pexels-photo-236171.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/236171/pexels-photo-236171.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/236171/pexels-photo-236171.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/236171/pexels-photo-236171.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
  ]);
  const iconLibrary = Icons; // Use the imported icons directly
  const iconNames = Object.keys(iconLibrary);
  const [iconsSearchTerm, setIconSearchTerm] = useState("");
  const handleImageResults = async (e) => {
    e.preventDefault();
    console.log(imageSearchQuery);

    try {
      const response = await fetch(
        `https://api.pexels.com/v1/search/?per_page=80&query=${
          imageSearchQuery !== "" || imageSearchQuery !== null
            ? imageSearchQuery
            : "people"
        }`,
        {
          method: "GET",
          headers: {
            Authorization:
              "shCfvWEafCZBIccDqbbdQS7yiaWunt6NV3aLmMia5qtZcL1cJBkt7WOZ",
          },
        }
      );
      const json = await response.json();
      setImageSearchResult(json.photos);
    } catch (error) {
      console.log(error);
    }
  };
  const DisplayStylingChoice = (stylingChoice) => {
    return (
      <div
        style={{
          width: "100%",
          height: "calc(100% - 70px)",
          overflowY: "auto",
          overflowX: "hidden",
          marginTop: "5px",
        }}
      >
        <div style={{ padding: "10px", width: "calc(100% - 20px)" }}>
          <div
            style={{
              color: "rgba(0,0,0,0.8)",
              fontSize: "15px",
              fontWeight: 600,
              padding: "3px",
            }}
          >
            URL link
          </div>
          <input
            placeholder="www.premelo.com"
            value={element["buttonUrl"] || ""}
            onChange={(e) => {
              onStyleChange("buttonUrl", e.target.value);
            }}
            style={{
              width: "calc(100% - 25px)",
              borderRadius: "5px",
              border: "1px solid lightgrey",
              fontSize: "13px",
              color: "rgba(0,0,0,0.8)",
              outline: "none",
              padding: "5px 10px",
            }}
          />
        </div>
        {stylingChoice.map((groups, index) => (
          <div style={{ width: "100%" }} key={index}>
            <div
              className="mui-button"
              style={{
                padding: "10px",
                cursor: "pointer",
              }}
              onClick={() => toggleSection(index)}
            >
              <div
                style={{
                  fontSize: "15px",
                  border: `1px solid lightgrey`,
                  fontWeight: 600,
                  color: "grey",
                  borderRadius: "5px",
                  position: "relative",
                  overflow: "hidden",
                  background: "rgba(255,255,255,0.5)",
                }}
              >
                <div
                  style={{
                    background: primaryColor,
                    width: "100%",
                    position: "relative",
                    left: 0,
                    top: 0,
                    height: "4px",
                  }}
                />
                <div
                  style={{
                    padding: "10px 20px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: "rgba(0,0,0,0.8)",
                    }}
                  >
                    {groups.style_group}
                  </div>
                  {openSections[index] ? (
                    <RemoveCircleIcon style={{ color: "lightgrey" }} />
                  ) : (
                    <AddCircleIcon style={{ color: "lightgrey" }} />
                  )}{" "}
                </div>
              </div>
            </div>

            {openSections[index] && (
              <div
                style={{
                  padding: "2px 0px",
                  fontSize: "11px",
                  fontWeight: 400,
                  color: "grey",
                }}
              >
                {groups.style_options.map((child, index) => {
                  if (child.style_type === "boolean") {
                    return (
                      <div
                        key={index}
                        style={{ padding: "5px 30px", marginBottom: "10px" }}
                      >
                        <div style={{ marginBottom: "5px", display: "flex" }}>
                          {child.style_display}
                          <input
                            type="checkbox"
                            checked={
                              element[
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`
                              ] ?? false
                            } // Use the checked attribute
                            min={0}
                            max={300}
                            onClick={() => {
                              setNotInputFocus(false);
                              setEditingInputWithArrow(true);
                            }}
                            style={{
                              borderRadius: "6px",
                              outline: "1px solid lightgrey",
                              border: "none",
                              fontSize: "12px",
                              color: "white",
                              padding: "5px 20px",
                              marginLeft: "5px",
                              resize: "none",
                              background: "rgba(50,50,50)",
                              accentColor: primaryColor,
                            }}
                            onChange={(e) => {
                              const isChecked = e.target.checked; // Check if the checkbox is checked
                              onStyleChange(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                isChecked
                              );
                            }}
                            onBlur={(e) => {
                              setNotInputFocus(true);
                              setEditingInputWithArrow(false);
                            }}
                          />
                        </div>
                      </div>
                    );
                  }
                  if (child.style_type === "customCode") {
                    return (
                      <div
                        key={index}
                        style={{ padding: "5px 30px", marginBottom: "10px" }}
                      >
                        <div style={{ marginBottom: "5px" }}>
                          {child.style_display}
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <textarea
                            type="number"
                            value={
                              element[
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`
                              ] ??
                              element[
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`
                              ]
                            }
                            min={0}
                            max={300}
                            onClick={() => {
                              setNotInputFocus(false);
                              setEditingInputWithArrow(true);
                            }}
                            style={{
                              borderRadius: "6px",
                              outline: "1px solid lightgrey",
                              border: "none",
                              fontSize: "12px",
                              color: "white",
                              padding: "5px 20px",
                              marginRight: "5px",
                              width: "calc(100% - 40px)",
                              resize: "none",
                              background: "rgba(50,50,50)",
                              height: "400px",
                            }}
                            onChange={(e) => {
                              onStyleChange(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                e.target.value
                              );
                            }}
                            onBlur={(e) => {
                              setNotInputFocus(true);
                              setEditingInputWithArrow(false);
                            }}
                          />
                        </div>
                      </div>
                    );
                  }
                  if (child.style_type === "chooseIcon") {
                    return (
                      <div
                        key={index}
                        style={{ padding: "5px 30px", marginBottom: "10px" }}
                      >
                        <div style={{ marginBottom: "5px" }}>
                          {child.style_display}
                        </div>
                        <div style={{ marginBottom: "5px" }}>
                          <input
                            placeholder="Search icons"
                            value={iconsSearchTerm}
                            onChange={(e) => setIconSearchTerm(e.target.value)}
                            style={{
                              borderRadius: "6px",
                              outline: "1px solid lightgrey",
                              border: "none",
                              fontSize: "12px",
                              color: "grey",
                              padding: "5px 20px",
                              marginRight: "5px",
                              width: "calc(100% - 40px)",
                            }}
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            height: "200px",
                            overflowY: "scroll",
                          }}
                        >
                          {iconsSearchTerm === ""
                            ? iconNames.slice(0, 150).map((iconName) => {
                                const Icon = iconLibrary[iconName];
                                return (
                                  <div
                                    key={iconName}
                                    onClick={() => {
                                      onStyleChange(
                                        mobileDesigning
                                          ? `m${child.style_name}`
                                          : `${child.style_name}`,
                                        iconName
                                      );
                                      setIcon(iconName);
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      padding: "5px",
                                    }}
                                  >
                                    <Icon style={{ fontSize: 30 }} />
                                  </div>
                                );
                              })
                            : iconNames
                                .filter((iconName) =>
                                  iconName
                                    .toLowerCase()
                                    .includes(iconsSearchTerm.toLowerCase())
                                )
                                .map((iconName) => {
                                  const Icon = iconLibrary[iconName];
                                  return (
                                    <div
                                      key={iconName}
                                      onClick={() => {
                                        onStyleChange(
                                          mobileDesigning
                                            ? `m${child.style_name}`
                                            : `${child.style_name}`,
                                          iconName
                                        );
                                        setIcon(iconName);
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        padding: "5px",
                                      }}
                                    >
                                      <Icon style={{ fontSize: 30 }} />
                                    </div>
                                  );
                                })}
                        </div>
                      </div>
                    );
                  }
                  if (child.style_type === "alignText") {
                    return (
                      <div
                        key={index}
                        style={{ padding: "5px 30px", marginBottom: "10px" }}
                      >
                        <div style={{ marginBottom: "5px" }}>
                          {child.style_display}
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <AlignHorizontalLeftIcon
                            style={{
                              color: "grey",
                              border: "1px solid lightgrey",
                              borderRadius: "6px",
                              fontSize: "14px",
                              padding: "5px",
                              marginRight: "10px",
                            }}
                            onClick={() =>
                              onStyleChange(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                "flex-start"
                              )
                            }
                          />
                          <AlignHorizontalCenterIcon
                            style={{
                              color: "grey",
                              border: "1px solid lightgrey",
                              borderRadius: "6px",
                              fontSize: "14px",
                              padding: "5px",
                              marginRight: "10px",
                            }}
                            onClick={() =>
                              onStyleChange(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                "center"
                              )
                            }
                          />
                          <AlignHorizontalRightIcon
                            style={{
                              color: "grey",
                              border: "1px solid lightgrey",
                              borderRadius: "6px",
                              fontSize: "14px",
                              padding: "5px",
                              marginRight: "10px",
                            }}
                            onClick={() =>
                              onStyleChange(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                "flex-end"
                              )
                            }
                          />
                        </div>
                      </div>
                    );
                  }
                  if (child.style_type === "videoSrc") {
                    return (
                      <div
                        key={index}
                        style={{ padding: "5px 30px", marginBottom: "10px" }}
                      >
                        <div style={{ marginBottom: "5px" }}>
                          {child.style_display}
                        </div>
                        <input
                          id="imageInputElement"
                          type="file"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            handleImageUploadElement(
                              e,
                              mobileDesigning
                                ? `m${child.style_name}`
                                : `${child.style_name}`
                            );
                          }}
                        />
                        {fileUploading ? (
                          <div
                            style={{
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                              borderRadius: "10px",
                              width: "150px",
                              height: "150px",
                              objectFit: "cover",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress
                              color="primary"
                              size={50}
                              style={{}}
                            />
                          </div>
                        ) : element[
                            mobileDesigning
                              ? `m${child.style_name}`
                              : `${child.style_name}`
                          ] ? (
                          <video
                            autoPlay={true}
                            loop={true}
                            muted
                            onClick={handleClickImageInput}
                            style={{
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                              borderRadius: "10px",
                              width: "150px",
                              height: "150px",
                              objectFit: "cover",
                              cursor: "pointer",
                            }}
                          >
                            <source
                              src={
                                element[
                                  mobileDesigning
                                    ? `m${child.style_name}`
                                    : `${child.style_name}`
                                ]
                              }
                            />
                          </video>
                        ) : (
                          <div
                            onClick={handleClickImageInput}
                            style={{
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                              borderRadius: "10px",
                              width: "150px",
                              height: "150px",
                              objectFit: "cover",
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <FileUploadIcon
                              style={{
                                color: "lightgrey",
                                fontSize: "50px",
                              }}
                            />
                          </div>
                        )}
                      </div>
                    );
                  }
                  if (child.style_type === "imageSrc") {
                    return (
                      <div
                        key={index}
                        style={{ padding: "5px 30px", marginBottom: "10px" }}
                      >
                        <div style={{ marginBottom: "5px" }}>
                          {child.style_display}
                        </div>
                        <input
                          id="imageInputElement"
                          type="file"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            handleImageUploadElement(
                              e,
                              mobileDesigning
                                ? `m${child.style_name}`
                                : `${child.style_name}`
                            );
                          }}
                        />
                        {fileUploading ? (
                          <div
                            style={{
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                              borderRadius: "10px",
                              width: "150px",
                              height: "150px",
                              objectFit: "cover",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <CircularProgress
                              color="primary"
                              size={50}
                              style={{}}
                            />
                          </div>
                        ) : element[
                            mobileDesigning
                              ? `m${child.style_name}`
                              : `${child.style_name}`
                          ] ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={
                                element[
                                  mobileDesigning
                                    ? `m${child.style_name}`
                                    : `${child.style_name}`
                                ]
                              }
                              onClick={handleClickImageInput}
                              style={{
                                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                                borderRadius: "10px",
                                width: "150px",
                                height: "150px",
                                objectFit: "cover",
                                cursor: "pointer",
                              }}
                            />
                            <div
                              style={{
                                padding: "8px 20px",
                                marginLeft: "15px",
                                color: "white",
                                background: primaryColor,
                              }}
                              onClick={() => setImageModalStates(true)}
                            >
                              Choose from library
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              onClick={handleClickImageInput}
                              style={{
                                boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                                borderRadius: "10px",
                                width: "150px",
                                height: "150px",
                                objectFit: "cover",
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <FileUploadIcon
                                style={{
                                  color: "lightgrey",
                                  fontSize: "50px",
                                }}
                              />
                            </div>
                            <div
                              style={{
                                padding: "8px 20px",
                                marginLeft: "15px",
                                color: "white",
                                background: primaryColor,
                              }}
                              onClick={() => setImageModalStates(true)}
                            >
                              Choose from library
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  }
                  if (child.style_type === "pixel") {
                    return (
                      <div
                        key={index}
                        style={{ padding: "5px 30px", marginBottom: "10px" }}
                      >
                        <div style={{ marginBottom: "5px" }}>
                          {child.style_display}
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <input
                            type="number"
                            value={
                              element[
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`
                              ] ??
                              element[
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`
                              ]
                            }
                            min={0}
                            max={300}
                            onClick={() => {
                              setNotInputFocus(false);
                              setEditingInputWithArrow(true);
                            }}
                            style={{
                              borderRadius: "6px",
                              outline: "1px solid lightgrey",
                              border: "none",
                              fontSize: "12px",
                              color: "grey",
                              padding: "5px 20px",
                              marginRight: "5px",
                              width: "30%",
                            }}
                            onChange={(e) => {
                              onStyleChange(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                parseFloat(e.target.value)
                              );
                              console.log(parseFloat(0));
                            }}
                            onBlur={(e) => {
                              setNotInputFocus(true);
                              setEditingInputWithArrow(false);
                              if (parseFloat(e.target.value) >= 0.1) {
                                onStyleChange(
                                  mobileDesigning
                                    ? `m${child.style_name}`
                                    : `${child.style_name}`,
                                  parseFloat(e.target.value)
                                );
                              } else {
                                onStyleChange(
                                  mobileDesigning
                                    ? `m${child.style_name}`
                                    : `${child.style_name}`,
                                  0
                                );
                              }
                            }}
                          />
                          px
                        </div>
                      </div>
                    );
                  }
                  if (child.style_type === "percentage") {
                    return (
                      <div
                        key={index}
                        style={{ padding: "5px 30px", marginBottom: "10px" }}
                      >
                        <div style={{ marginBottom: "5px" }}>
                          {child.style_display}
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <input
                            className="range_selector"
                            type="range"
                            value={
                              element[
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`
                              ] ??
                              element[
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`
                              ]
                            }
                            min={0}
                            max={100}
                            onClick={() => {
                              setNotInputFocus(false);
                              setEditingInputWithArrow(true);
                            }}
                            style={{
                              borderRadius: "6px",
                              outline: "none",
                              border: "none",
                              fontSize: "12px",
                              color: "grey",
                              padding: "5px 0px",
                              marginRight: "5px",
                              width: "30%",
                              accentColor: primaryColor,
                            }}
                            onChange={(e) => {
                              if (
                                e.target.value === 0 ||
                                e.target.value === "0" ||
                                e.target.value === null
                              ) {
                                onStyleChange(
                                  mobileDesigning
                                    ? `m${child.style_name}`
                                    : `${child.style_name}`,
                                  0
                                );
                              } else {
                                onStyleChange(
                                  mobileDesigning
                                    ? `m${child.style_name}`
                                    : `${child.style_name}`,
                                  parseFloat(e.target.value)
                                );
                              }
                              console.log(parseFloat(0));
                            }}
                            onBlur={(e) => {
                              setNotInputFocus(true);
                              setEditingInputWithArrow(false);
                              if (parseFloat(e.target.value) >= 0.1) {
                                onStyleChange(
                                  mobileDesigning
                                    ? `m${child.style_name}`
                                    : `${child.style_name}`,
                                  parseFloat(e.target.value)
                                );
                              } else {
                                onStyleChange(
                                  mobileDesigning
                                    ? `m${child.style_name}`
                                    : `${child.style_name}`,
                                  0
                                );
                              }
                            }}
                          />
                          {
                            element[
                              mobileDesigning
                                ? `m${child.style_name}`
                                : `${child.style_name}`
                            ]
                          }
                          %
                        </div>
                      </div>
                    );
                  }
                  if (child.style_type === "fontWeight") {
                    return (
                      <div
                        key={index}
                        style={{ padding: "5px 30px", marginBottom: "10px" }}
                      >
                        <div style={{ marginBottom: "5px" }}>
                          {child.style_display}
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <select
                            type="number"
                            value={
                              element[
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`
                              ] ??
                              element[
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`
                              ]
                            }
                            onClick={() => {
                              setNotInputFocus(false);
                              setEditingInputWithArrow(true);
                            }}
                            onBlur={() => setNotInputFocus(true)}
                            onChange={(e) => {
                              onStyleChange(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                parseFloat(e.target.value)
                              );
                            }}
                            style={{
                              borderRadius: "6px",
                              outline: "1px solid lightgrey",
                              border: "none",
                              fontSize: "12px",
                              color: "grey",
                              padding: "5px 20px",
                              marginRight: "5px",
                              width: "40%",
                            }}
                          >
                            <option value={300}>Light</option>
                            <option value={450}>Normal</option>
                            <option value={600}>Semi-bold</option>
                            <option value={700}>Bold</option>
                            <option value={800}>Ultra-bold</option>
                          </select>
                        </div>
                      </div>
                    );
                  }
                  if (child.style_type === "imageFit") {
                    return (
                      <div
                        key={index}
                        style={{ padding: "5px 30px", marginBottom: "10px" }}
                      >
                        <div style={{ marginBottom: "5px" }}>
                          {child.style_display}
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <select
                            value={
                              element[
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`
                              ] ??
                              element[
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`
                              ]
                            }
                            onClick={() => {
                              setNotInputFocus(false);
                              setEditingInputWithArrow(true);
                            }}
                            onBlur={() => setNotInputFocus(true)}
                            onChange={(e) => {
                              onStyleChange(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                e.target.value
                              );
                            }}
                            style={{
                              borderRadius: "6px",
                              outline: "1px solid lightgrey",
                              border: "none",
                              fontSize: "12px",
                              color: "grey",
                              padding: "5px 20px",
                              marginRight: "5px",
                              width: "40%",
                            }}
                          >
                            <option value={"fill"}>Fill</option>
                            <option value={"cover"}>Cover fit</option>
                            <option value={"contain"}>Contain fit</option>
                          </select>
                        </div>
                      </div>
                    );
                  }
                  if (child.style_type === "color") {
                    const color =
                      colorState[
                        mobileDesigning
                          ? `m${child.style_name}`
                          : `${child.style_name}`
                      ] || "";
                    return (
                      <div
                        key={index}
                        style={{ padding: "5px 30px", marginBottom: "10px" }}
                      >
                        <div style={{ marginBottom: "5px" }}>
                          {child.style_display}
                        </div>
                        <ChromePicker
                          className="my_chrome_picker"
                          color={color}
                          onChange={(color) => {
                            const updatingActiveForm = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                            updateColorState(
                              mobileDesigning
                                ? `m${child.style_name}`
                                : `${child.style_name}`,
                              updatingActiveForm
                            );
                            onStyleChange(
                              mobileDesigning
                                ? `m${child.style_name}`
                                : `${child.style_name}`,
                              updatingActiveForm
                            );
                          }}
                          onChangeComplete={(color) => {
                            const updatingActiveForm = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                            updateColorState(
                              mobileDesigning
                                ? `m${child.style_name}`
                                : `${child.style_name}`,
                              updatingActiveForm
                            );
                            onStyleChange(
                              mobileDesigning
                                ? `m${child.style_name}`
                                : `${child.style_name}`,
                              updatingActiveForm
                            );
                          }}
                        />
                      </div>
                    );
                  }
                  if (child.style_type === "boxShadow") {
                    const boxShadow =
                      boxShadowState[
                        mobileDesigning
                          ? `m${child.style_name}`
                          : `${child.style_name}`
                      ] || defaultBoxShadow;
                    return (
                      <div
                        key={index}
                        style={{ padding: "5px 30px", marginBottom: "10px" }}
                      >
                        <div>{child.style_display}</div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "5px 0px",
                          }}
                        >
                          <input
                            type="number"
                            placeholder="Horizontal"
                            value={boxShadow.horizontal}
                            onClick={() => {
                              setNotInputFocus(false);
                              setEditingInputWithArrow(true);
                            }}
                            onBlur={() => setNotInputFocus(true)}
                            style={{
                              borderRadius: "6px",
                              outline: "1px solid lightgrey",
                              border: "none",
                              fontSize: "12px",
                              color: "grey",
                              padding: "5px 20px",
                              width: "10%",
                              marginRight: "4px",
                            }}
                            onChange={(e) => {
                              updateBoxShadowState(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                "horizontal",
                                e.target.value
                              );
                              // Format and apply boxShadow here
                              const formattedBoxShadow = `${parseFloat(
                                e.target.value
                              )}px ${
                                boxShadow.vertical ? boxShadow.vertical : 10
                              }px ${boxShadow.blur ? boxShadow.blur : 10}px ${
                                boxShadow.color
                                  ? boxShadow.color
                                  : "rgba(0,0,0,0.5)"
                              }`;
                              onStyleChange(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                formattedBoxShadow
                              );
                            }}
                          />{" "}
                          px
                          <input
                            type="number"
                            placeholder="Vertical"
                            value={boxShadow.vertical}
                            onClick={() => {
                              setNotInputFocus(false);
                              setEditingInputWithArrow(true);
                            }}
                            onBlur={() => setNotInputFocus(true)}
                            style={{
                              borderRadius: "6px",
                              outline: "1px solid lightgrey",
                              border: "none",
                              fontSize: "12px",
                              color: "grey",
                              padding: "5px 20px",
                              width: "10%",
                              marginLeft: "8px",
                              marginRight: "4px",
                            }}
                            onChange={(e) => {
                              updateBoxShadowState(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                "vertical",
                                e.target.value
                              );
                              // Format and apply boxShadow here
                              const formattedBoxShadow = `${
                                boxShadow.horizontal ? boxShadow.horizontal : 10
                              }px ${parseFloat(e.target.value)}px ${
                                boxShadow.blur ? boxShadow.blur : 10
                              }px ${
                                boxShadow.color
                                  ? boxShadow.color
                                  : "rgba(0,0,0,0.5)"
                              }`;
                              onStyleChange(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                formattedBoxShadow
                              );
                            }}
                          />{" "}
                          px
                          <input
                            type="number"
                            placeholder="Blur Radius"
                            value={boxShadow.blur}
                            onClick={() => {
                              setNotInputFocus(false);
                              setEditingInputWithArrow(true);
                            }}
                            onBlur={() => setNotInputFocus(true)}
                            style={{
                              borderRadius: "6px",
                              outline: "1px solid lightgrey",
                              border: "none",
                              fontSize: "12px",
                              color: "grey",
                              padding: "5px 20px",
                              width: "10%",
                              marginLeft: "8px",
                              marginRight: "4px",
                            }}
                            onChange={(e) => {
                              updateBoxShadowState(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                "blur",
                                e.target.value
                              );
                              // Format and apply boxShadow here
                              const formattedBoxShadow = `${
                                boxShadow.horizontal ? boxShadow.horizontal : 10
                              }px ${
                                boxShadow.vertical ? boxShadow.vertical : 10
                              }px ${parseFloat(e.target.value)}px ${
                                boxShadow.color
                                  ? boxShadow.color
                                  : "rgba(0,0,0,0.5)"
                              }`;
                              onStyleChange(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                formattedBoxShadow
                              );
                            }}
                          />{" "}
                          px
                        </div>
                        <div>
                          <ChromePicker
                            className="my_chrome_picker"
                            color={boxShadow.color}
                            onChange={(color) => {
                              const updatingActiveForm = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                              updateBoxShadowState(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                "color",
                                updatingActiveForm
                              );
                              // Format and apply boxShadow here
                              const formattedBoxShadow = `${
                                boxShadow.horizontal ? boxShadow.horizontal : 10
                              }px ${
                                boxShadow.vertical ? boxShadow.vertical : 10
                              }px ${
                                boxShadow.blur ? boxShadow.blur : 10
                              }px ${updatingActiveForm}`;
                              onStyleChange(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                formattedBoxShadow
                              );
                            }}
                            onChangeComplete={(color) => {
                              const updatingActiveForm = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                              updateBoxShadowState(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                "color",
                                updatingActiveForm
                              );
                              // Format and apply boxShadow here
                              const formattedBoxShadow = `${
                                boxShadow.horizontal ? boxShadow.horizontal : 10
                              }px ${
                                boxShadow.vertical ? boxShadow.vertical : 10
                              }px ${
                                boxShadow.blur ? boxShadow.blur : 10
                              }px ${updatingActiveForm}`;
                              console.log(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                formattedBoxShadow,
                                boxShadow
                              );
                              onStyleChange(
                                mobileDesigning
                                  ? `m${child.style_name}`
                                  : `${child.style_name}`,
                                formattedBoxShadow
                              );
                            }}
                          />
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            )}
          </div>
        ))}
      </div>
    );
  };

  const [activeOffset, setActiveOffset] = useState({
    x: 0,
    y: 0,
  });

  const [initialWindow, setInitialWindow] = useState({
    x: 0,
    y: 0,
  });

  const [dragging, setDragging] = useState(false);

  const handleDragStartWindows = (e, touch) => {
    setDragging(true);
    if (touch) {
      setInitialWindow({ x: activeWindowStat.x, y: activeWindowStat.y });
      setActiveOffset({ x: touch.clientX, y: touch.clientY });
    } else {
      setInitialWindow({ x: activeWindowStat.x, y: activeWindowStat.y });
      setActiveOffset({ x: e.clientX, y: e.clientY });
    }
  };

  const handleWindowsDrag = (e, touch) => {
    if (touch) {
      const updatingWindows = { ...activeWindowStat };
      if (e.clientX !== 0) {
        updatingWindows.x = initialWindow.x + touch.clientX - activeOffset.x;
      }
      if (e.clientY !== 0) {
        updatingWindows.y = initialWindow.y + touch.clientY - activeOffset.y;
      }
      setActiveWindowStat(updatingWindows);
      Cookies.set("windows_stat", updatingWindows);
    } else {
      const updatingWindows = { ...activeWindowStat };
      if (e.clientX !== 0) {
        updatingWindows.x = initialWindow.x + e.clientX - activeOffset.x;
      }
      if (e.clientY !== 0) {
        updatingWindows.y = initialWindow.y + e.clientY - activeOffset.y;
      }
      setActiveWindowStat(updatingWindows);
      Cookies.set("windows_stat", updatingWindows);
    }
  };

  const PullIconForDisplay = (icon) => {
    if (icon) {
      const Icon = iconLibrary[icon];
      const BackupIcon = iconLibrary["FaFacebookSquare"];
      if (Icon) {
        return (
          <Icon
            style={{
              fontSize: mobileDesigning
                ? element.mtitleFontSize || element.titleFontSize || 30
                : element.titleFontSize || 30,

              color: mobileDesigning
                ? element.mtitleFontColor ||
                  element.titleFontColor ||
                  "rgba(0,0,0,0.8)"
                : element.titleFontColor || "rgba(0,0,0,0.8)",
            }}
          />
        );
      } else {
        return (
          <BackupIcon
            style={{
              fontSize: mobileDesigning
                ? element.mtitleFontSize || element.titleFontSize || 30
                : element.titleFontSize || 30,

              color: mobileDesigning
                ? element.mtitleFontColor ||
                  element.titleFontColor ||
                  "rgba(0,0,0,0.8)"
                : element.titleFontColor || "rgba(0,0,0,0.8)",
            }}
          />
        ); // Icon not found
      }
    } else {
      return null; // No icon chosen
    }
  };

  return (
    <div
      className="text-element builder-element"
      style={{
        // border: focusElement === element.id ? "1px solid #5e86c4" : "none",
        // opacity: draggingOpacity,
        position: "absolute",
        left: mobileDesigning
          ? element.position.mx
            ? `${element.position.mx}%`
            : `${element.position.x}%`
          : `${element.position.x}%`,
        top: mobileDesigning
          ? element.position.my
            ? element.position.my
            : element.position.y
          : element.position.y,
          zIndex: selectedElements.includes(element.id)
            ? 501
            : focusElement === element.id
            ? 501
            : 499,
        width: mobileDesigning
          ? element.mwidth
            ? (element.mwidth * 400) / 100
            : (element.width * 400) / 100
          : (element.width * viewportWidth) / 100,
        height: mobileDesigning
          ? element.mheight
            ? element.mheight
            : element.height
          : element.height,
        display: null,
        borderRadius: `${
          element.holderBorderRadius ? element.holderBorderRadius : 0
        }px`,
        border: selectedElements.includes(element.id)
          ? "1px solid #5e86c4"
          : focusElement === element.id
          ? "1px solid #5e86c4"
          : "none",
        outline: `${
          element.holderBorderWidth ? element.holderBorderWidth : 0
        }px solid ${
          element.holderBorderColor ? element.holderBorderColor : "black"
        }`,
        background: element.holderBackgroundColor
          ? element.holderBackgroundColor
          : "rgba(0,0,0,0)",
        boxShadow: element.holderBoxShadow
          ? element.holderBoxShadow
          : "0px 0px 0px rgba(0,0,0,0)",
      }}
      onClick={(event) => {
        event.stopPropagation(); // Prevent event propagation to section div
        // console.log("click with stop propogation")
        console.log(focusElement === element.id);
        handleClick(event);
      }}
      ref={containerRef}
      // onClick={() => setIsResizing(true)}
    >
      {focus ? (
        <div
          className="pop_up"
          style={{
            top: activeWindowStat.y,
            left: activeWindowStat.x,
            boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
            background: "rgba(255,255,255,0.7)",
            backdropFilter: "blur(15px)",
            transform: dragging ? "scale(1.02)" : "scale(1)",
            opacity: dragging ? 0.8 : 1,
            transition:"0.3s transform ease-in-out"
          }}
        >
          <div
            draggable
            onDragStart={(e) => handleDragStartWindows(e)}
            onDrag={(e) => handleWindowsDrag(e)}
            onTouchStart={(e) => {
              const touch = e.touches[0];
              handleDragStartWindows(e, touch);
            }}
            onTouchMove={(e) => {
              const touch = e.touches[0];
              handleWindowsDrag(e, touch);
            }}
            onDragOver={(event) => {
              event.preventDefault(); // Prevent "no drop" cursor
            }}
            onDragEnd={() => setDragging(false)}
            onTouchEnd={() => setDragging(false)}
            style={{
              touchAction: "none",
              height: "60px",
              width: "100%",
              borderRadius: "5px 5px 0px 0px",
              overflow: "hidden",
              borderBottom: "1px solid lightgrey",
              background: "rgba(255,255,255,0.5)",
            }}
          >
            <div
              style={{
                height: "5px",
                width: "100%",
                background: primaryColor,
              }}
            />

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                height: "55px",
                cursor: "move",
              }}
            >
              <div
                style={{
                  margin: "0px 12px",
                  fontSize: "17px",
                  color: "rgba(0,0,0,0.8)",
                  fontWeight: 700,
                }}
              >
                Styling Drawer
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  style={{
                    background: "rgba(100,100,100,0.5)",
                    width: "9px",
                    height: "9px",
                    borderRadius: "10px",
                    marginRight: "7px",
                  }}
                />
                <div
                  style={{
                    background: "rgba(100,100,100,0.5)",
                    width: "9px",
                    height: "9px",
                    borderRadius: "10px",
                    marginRight: "7px",
                  }}
                />
                <div
                  style={{
                    background: "grey",
                    width: "9px",
                    height: "9px",
                    borderRadius: "10px",
                    marginRight: "14px",
                  }}
                />
              </div>
            </div>
          </div>
          {DisplayStylingChoice(stylingChoices)}
        </div>
      ) : null}
      <div
        draggable
        onDrag={(event) => onDrag(event, element.id, dragStartPosition)}
        onDragStart={(event) => {
          handleDragStart(event, element.id);
          setDraggingOpacity(0.001);
        }}
        onClick={() => setEditingInputWithArrow(false)}
        onDragEnd={() => {
          onDragEnd();
          setDraggingOpacity(1);
        }}
        onTouchStart={(event) => {
          const touch = event.touches[0];
          handleDragStart(event, element.id, touch);
          setDraggingOpacity(0.001);
        }}
        onTouchMove={(event) => {
          const touch = event.touches[0];
          onDrag(event, element.id, dragStartPosition, touch);
        }}
        onTouchEnd={() => {
          onDragEnd();
          setDraggingOpacity(1);
        }}
        style={{
          touchAction: "none",
          width: mobileDesigning
            ? element.mwidth
              ? (element.mwidth * 400) / 100
              : (element.width * 400) / 100
            : (element.width * viewportWidth) / 100,
          height: mobileDesigning
            ? element.mheight
              ? element.mheight
              : element.height
            : element.height,
          margin: "0px",
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
        }}
        className="builder-display"
      >
        {icon ? PullIconForDisplay(icon) : null}
      </div>
      <div
        className="resize-handle"
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          cursor: "se-resize",
          background: "white",
          border: "1px solid #5e86c4",
          height: "10px",
          width: "10px",
          transform: "translate(50%, 50%)",
          opacity: focus ? 1 : 0,
          borderRadius: "50%",
          touchAction: "none",
        }}
        draggable
        onDragStart={(event) => handleResizeStart(event)}
        onDrag={(event) => handleResize(event)}
        onTouchStart={(event) => {
          const touch = event.touches[0];
          handleResizeStart(event, touch);
        }}
        onTouchMove={(event) => {
          const touch = event.touches[0];
          handleResize(event, touch);
        }}
        onDragEnd={handleResizeEnd}
      />
      <div
        className="resize-handle"
        style={{
          position: "absolute",
          bottom: "50%",
          right: 0,
          cursor: "e-resize",
          background: "white",
          border: "1px solid #5e86c4",
          height: "10px",
          width: "10px",
          transform: "translate(50%, 50%)",
          opacity: focus ? 1 : 0,
          borderRadius: "50%",
          touchAction: "none",
        }}
        draggable
        onDragStart={(event) => handleResizeStart(event)}
        onDrag={(event) => handleResizeX(event)}
        onTouchStart={(event) => {
          const touch = event.touches[0];
          handleResizeStart(event, touch);
        }}
        onTouchMove={(event) => {
          const touch = event.touches[0];
          handleResizeX(event, touch);
        }}
        onDragEnd={handleResizeEnd}
      />
      <div
        className="resize-handle"
        style={{
          position: "absolute",
          bottom: 0,
          right: "50%",
          cursor: "n-resize",
          background: "white",
          border: "1px solid #5e86c4",
          height: "10px",
          width: "10px",
          transform: "translate(50%, 50%)",
          opacity: focus ? 1 : 0,
          borderRadius: "50%",
          touchAction: "none",
        }}
        draggable
        onDragStart={(event) => handleResizeStart(event)}
        onDrag={(event) => handleResizeY(event)}
        onTouchStart={(event) => {
          const touch = event.touches[0];
          handleResizeStart(event, touch);
        }}
        onTouchMove={(event) => {
          const touch = event.touches[0];
          handleResizeY(event, touch);
        }}
        onDragEnd={handleResizeEnd}
      />
    </div>
  );
};

  export default IconElement;