import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import "./App.css";
import "./NoRbc.css";
import {
  Calendar,
  dateFnsLocalizer,
  Event as CalendarEvent,
} from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import addHours from "date-fns/addHours";
import startOfHour from "date-fns/startOfHour";
import Modal from "react-modal";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/CheckCircleOutline";
import CheckedIcon from "@mui/icons-material/CheckCircle";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-mention/dist/quill.mention.min.css";
import Select from "@mui/material/Select";
import { ListItemText, ListItemIcon } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { TimePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import CrossIcon from "@mui/icons-material/Clear";
import { MentionsInput, Mention } from "react-mentions";
import { Cookie } from "@mui/icons-material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CDatePicker from "react-datepicker";

function CalendarContent(props) {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    // Initial check
    handleResize();

    // Attach event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  function getFileIcon(fileName) {
    const extension = fileName.split(".").pop().toLowerCase();

    if (extension === "pdf") {
      return (
        <PictureAsPdfIcon
          style={{ fontSize: "40px", color: props.primaryColor }}
        />
      );
    } else if (["jpg", "jpeg", "png"].includes(extension)) {
      return (
        <ImageIcon style={{ fontSize: "40px", color: props.primaryColor }} />
      );
    } else {
      return (
        <InsertDriveFileIcon
          style={{ fontSize: "40px", color: props.primaryColor }}
        />
      );
    }
  }

  function getMessageFileIcon(fileName) {
    const extension = fileName.split(".").pop().toLowerCase();

    if (extension === "pdf") {
      return (
        <PictureAsPdfIcon
          style={{ fontSize: "25px", color: props.primaryColor }}
        />
      );
    } else if (["jpg", "jpeg", "png"].includes(extension)) {
      return (
        <ImageIcon style={{ fontSize: "25px", color: props.primaryColor }} />
      );
    } else {
      return (
        <InsertDriveFileIcon
          style={{ fontSize: "25px", color: props.primaryColor }}
        />
      );
    }
  }

  const locales = {
    "en-US": enUS,
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const now = new Date();
  const start = startOfHour(now);
  const end = addHours(start, 2);

  const DnDCalendar = withDragAndDrop(Calendar);

  const [statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    const fetchProjectAvailableStatus = async () => {
      const token = Cookies.get("token");
      const response = await fetch(
        `https://ww1.premelo.com/api/projects/${props.activeProjectId}/status-options`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log("data from api:", JSON.parse(data.status_options));
      console.log("data from default value:", statusOptions);
      setStatusOptions(JSON.parse(data.status_options));
    };
    fetchProjectAvailableStatus();

    fetchCompanyTask();

    fetchUsers();
  }, [props.activeProject]);
  const { primaryColor } = props;

  const [showStatusRow, setShowStatusRow] = useState(
    Cookies.get("showStatusRow") === "true" ? true : false
  );
  const [showTaskPurpose, setShowTaskPurpose] = useState(
    Cookies.get("showTaskPurpose") === "true" ? true : false
  );
  const [showPhoneNumber, setShowPhoneNumber] = useState(
    Cookies.get("showPhoneNumber") === "true" ? true : false
  );

  const [taskTitle, setTaskTitle] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [taskId, setTaskId] = useState("");
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [status, setStatus] = useState("");
  const [taskAssignedTo, setTaskAssignedTo] = useState("");
  const [isCompleted, setIsCompleted] = useState("");
  const [purpose, setPurpose] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [taskFormModalIsOpen, setTaskFormModalIsOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [taskAttachmentUrl, setTaskAttachmentUrl] = useState([]);
  const [modalMessages, setModalMessages] = useState({});
  const [message, setMessage] = useState("");
  const [attachmentUrl, setAttachmentUrl] = useState([]);
  const [taskAssignedBy, setTaskAssignedBy] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [customStatus, setCustomStatus] = useState("");
  const [customColor, setCustomColor] = useState("#701ba1");
  const [selectedOption, setSelectedOption] = useState("");

  const token = Cookies.get("token");

  const handleFileInputChange = async (event) => {
    const files = event.target.files;
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("files[]", files[i]);
    }

    try {
      const response = await axios.post(
        "https://ww1.premelo.com/api/upload-image",
        formData
      );

      console.log(response.data);
      setAttachmentUrl(
        response.data.urls.map((urls, index) => ({
          url: urls,
          fileName: response.data.fileNames[index],
        }))
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleMentionChange = (event, newValue) => {
    setMessage(newValue);
  };

  const renderSuggestion = (suggestion, search, highlightedDisplay) => {
    return <div style={{ borderRadius: "30px" }}>{highlightedDisplay}</div>;
  };

  const handleRemoveAttachment = (index) => {
    setAttachmentUrl((prev) => prev.filter((_, i) => i !== index));
  };

  const handleAddMessage = async () => {
    try {
      const urls = attachmentUrl.map((attachment) => ({
        url: attachment.url,
        fileName: attachment.fileName,
      }));

      // the task id is not being updated
      const response = await fetch(
        `https://ww1.premelo.com/api/task/${taskId}/message`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            task_id: taskId,
            message: message,
            attachment_url: urls,
          }),
        }
      );

      const data = await response.json();
      setMessage("");
      setAttachmentUrl([]);
    } catch (error) {
      console.error(error);
    }

    const response = await fetch(
      `https://ww1.premelo.com/api/task/${taskId}/getmessages`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    console.log(availableUsers);
    setModalMessages(data);
    const taskAttachments = await fetch(
      `https://ww1.premelo.com/api/task/${taskId}/attachments`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const attachedInTask = await taskAttachments.json();
    setTaskAttachmentUrl(
      attachedInTask.map((attachment) => ({
        url: attachment.attachment_url,
        fileName: attachment.file_name,
      }))
    );
  };

  const fetchUsers = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/user-available-team-members",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();

    const transformedResponse = data.map((obj) => {
      return {
        id: obj.user_id,
        first_name: obj.first_name,
        last_name: obj.last_name,
        user_color: obj.user_color,
      };
    });

    console.log("transformed response", transformedResponse);
    const modifiedData = transformedResponse.map((user) => {
      return {
        ...user,
        fullName: `${user.first_name} ${user.last_name}`,
      };
    });
    console.log("available users with fullName:", modifiedData);
    setAvailableUsers(modifiedData);
  };
  const [selectedRow, setSelectedRow] = useState("");
  const [tasks, setTask] = useState([
    {
      title: "title",
      start: "",
      end: end,
      allDay: "false",
      colorEvento: "red",
    },
  ]);

  const onEventResize = (data) => {
    const { start, end } = data;

    setTask((currentTasks) => {
      const firstTask = {
        start: new Date(start),
        end: new Date(end),
      };
      return [...currentTasks, firstTask];
    });
  };

  const onEventDrop = (data) => {
    const { event, start, end } = data;
    const dues = new Date(data.start).toLocaleDateString();

    const dateParts = dues.split("/");
    const year = dateParts[2];
    const month = dateParts[0].padStart(2, "0");
    const day = dateParts[1].padStart(2, "0");
    const outputDate = `${year}-${month}-${day}`;
    console.log("output date", outputDate);
    console.log("output date with adjustments", new Date(outputDate));

    console.log("item being changed", data);
    const newData = tasks.map((item) => {
      if (item.id === data.event.id) {
        console.log("item being changed", data);
        const updatedItem = {
          ...item,
          task_due_date: outputDate,
        };
        const updatedItemForCalendar = {
          ...item,
          task_due_date: outputDate,
          start: new Date(outputDate),
          end: new Date(outputDate),
          allDay: true,
        };
        fetch(`https://ww1.premelo.com/api/update-task/${updatedItem.id}`, {
          method: "PUT",
          body: JSON.stringify(updatedItem),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.error(error);
          });
        return updatedItemForCalendar;
      } else {
        console.log("no items being changed", item);
        return item;
      }
    });
    console.log("updated with item", newData);
    setDueDate(outputDate);
    setTask(newData);
  };

  const clickRef = useRef(null);

  const handleProjectSelectChange = (event) => {
    const { value } = event.target;
    setSelectedProjects(value);
  };

  const handleChange = (value) => {
    setTaskDescription(value);
  };

  const fetchCompanyTask = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/retrieve-company-tasks",
      {
        headers: {
          Accept: `application/json`,
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await response.json();
    console.log(data);
    const tasksData = data;
    const statusForTask = await fetch(
      "https://ww1.premelo.com/api/retrieve-company-projects",
      {
        headers: {
          Accept: `application/json`,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const forStatus = await statusForTask.json();
    const allStatusOptions = forStatus.projects.flatMap((project) =>
      JSON.parse(project.status_options)
    );

    const uniqueStatusOptions = Array.from(new Set(allStatusOptions));
    console.log(uniqueStatusOptions);

    const transformedTask = tasksData.map((item) => {
      const selectedStatus =
        uniqueStatusOptions.find((option) => option.value === item.task_status)
          ?.color || props.primaryColor;
      return {
        id: item.id,
        title: item.task_title,
        start: item.task_start_date
          ? new Date(item.task_start_date)
          : new Date(item.task_due_date),
        end: new Date(item.task_due_date),
        allDay: true,
        colorEvento: selectedStatus,
        task_assigned_to: item.task_assigned_to,
        task_due_date: item.task_due_date,
        task_created_date: item.task_created_date,
        task_description: item.task_description,
        task_is_complete: item.task_is_complete,
        task_status: item.task_status,
        task_purpose: item.task_purpose,
        task_phone_number: item.task_phone_number,
        task_assigned_by: item.task_assigned_by,
      };
    });

    // Update the events state with the transformed data
    setTask(transformedTask);
    console.log("transformed tasks", transformedTask);
  };

  const handleTaskButtonClick = async (calEvent) => {
    setModalMessages([]);
    setTaskAttachmentUrl([]);
    console.log(calEvent, "onSelectEvent");
    setTaskFormModalIsOpen(true);
    setSelectedRow(calEvent);
    setTaskId(calEvent.id);
    setTaskTitle(calEvent.title);
    setTaskDescription(calEvent.task_description);
    setSelectedUsers(calEvent.task_assigned_to);
    setDueDate(calEvent.task_due_date);
    setTaskAssignedTo(calEvent.task_assigned_to);
    setIsCompleted(calEvent.task_is_complete);
    setPhoneNumber(calEvent.task_phone_number);
    setPurpose(calEvent.task_purpose);
    setStatus(calEvent.task_status);
    setTaskAssignedBy(calEvent.task_assigned_by);
    setSelectedOption({
      label: calEvent.task_status,
      label: calEvent.task_status,
      color:
        statusOptions.find((option) => option.value === calEvent.task_status)
          ?.color || props.primaryColor,
    });

    const taskAttachments = await fetch(
      `https://ww1.premelo.com/api/task/${calEvent.id}/attachments`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const response = await fetch(
      `https://ww1.premelo.com/api/task/${calEvent.id}/getmessages`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await response.json();
    console.log("message data:", data);
    console.log(availableUsers);
    setModalMessages(data);

    const attachedInTask = await taskAttachments.json();
    setTaskAttachmentUrl(
      attachedInTask.map((attachment) => ({
        url: attachment.attachment_url,
        fileName: attachment.file_name,
      }))
    );
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setStatus(option.value);
    console.log(option.value);
    const newData = tasks.map((item) => {
      if (item.id === selectedRow.id) {
        const updatedItem = {
          ...item,
          task_status: option.value,
        };
        console.log(option);
        const updatedItemForCalendar = {
          ...item,
          task_status: option.value,
          colorEvento: option.color,
        };
        fetch(`https://ww1.premelo.com/api/update-task/${updatedItem.id}`, {
          method: "PUT",
          body: JSON.stringify(updatedItem),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {})
          .catch((error) => {
            console.error(error);
          });
        return updatedItemForCalendar;
      } else {
        return item;
      }
    });
    console.log(newData);
    setTask(newData);
    setIsOpen(false);
  };

  const handleCustomStatusChange = (event) => {
    setCustomStatus(event.target.value);
  };

  const handleCustomColorChange = (event) => {
    setCustomColor(event.target.value);
  };

  const handleCustomStatusCommit = async () => {
    setIsOpen(false);
    setStatus(customStatus);
    setSelectedOption({
      label: customStatus,
      label: customStatus,
      color: customColor,
    });
    console.log("custom status:", customStatus);

    const newData = tasks.map((item) => {
      if (item.id === selectedRow.id) {
        const updatedItem = {
          ...item,
          task_status: customStatus,
        };
        fetch(`https://ww1.premelo.com/api/update-task/${updatedItem.id}`, {
          method: "PUT",
          body: JSON.stringify(updatedItem),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.error(error);
          });
        return updatedItem;
      } else {
        return item;
      }
    });
    setStatus(customStatus);
    setTask(newData);

    const createdStatus = {
      label: customStatus,
      value: customStatus,
      color: customColor,
    };

    setStatusOptions([...statusOptions, createdStatus]);
    const newOptions = [...statusOptions, createdStatus];

    const response = await fetch(
      `https://ww1.premelo.com/api/projects/${props.activeProjectId}/status-options`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ status_options: newOptions }),
      }
    );

    const responseData = await response.json();
    setStatusOptions(responseData.project.status_options);
  };

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("files[]", files[i]);
    }

    try {
      const response = await axios.post(
        "https://ww1.premelo.com/api/upload-image",
        formData
      );

      console.log(response.data);
      const attachmentUrls = response.data.urls.map((url, index) => ({
        url: url,
        fileName: response.data.fileNames[index],
      }));

      // Call the uploadTaskAttachment API to store the attachment URLs and file names
      const taskAttachmentResponse = await fetch(
        `https://ww1.premelo.com/api/upload-attachments/${taskId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            attachment_url: attachmentUrls,
          }),
        }
      );

      const taskAttachments = await fetch(
        `https://ww1.premelo.com/api/task/${taskId}/attachments`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await taskAttachments.json();
      console.log("message data:", data);
      setTaskAttachmentUrl(
        data.map((attachment) => ({
          url: attachment.attachment_url,
          fileName: attachment.file_name,
        }))
      );
    } catch (error) {
      console.error(error);
    }
  };

  const fileInput = useRef(null);
  const taskFileInput = useRef(null);
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "mention",
  ];

  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelectedUsers(value);
  };

  const handleChangeDate = (dues) => {
    console.log("onblur initiated");

    const dateParts = dues.split("/");
    const year = dateParts[2];
    const month = dateParts[0].padStart(2, "0");
    const day = dateParts[1].padStart(2, "0");
    const outputDate = `${year}-${month}-${day}`;

    const newData = tasks.map((item) => {
      if (item.id === selectedRow.id) {
        const updatedItem = {
          ...item,
          task_due_date: outputDate,
        };
        const updatedItemForCalendar = {
          ...item,
          task_due_date: outputDate,
          start: outputDate ? new Date(outputDate) : new Date(outputDate),
          end: new Date(outputDate),
          allDay: true,
        };
        fetch(`https://ww1.premelo.com/api/update-task/${updatedItem.id}`, {
          method: "PUT",
          body: JSON.stringify(updatedItem),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.error(error);
          });
        return updatedItemForCalendar;
      } else {
        console.log("updated with item", item);
        return item;
      }
    });
    setDueDate(outputDate);
    setTask(newData);
  };

  const assignedByUser = availableUsers.find(
    (user) => user.id === taskAssignedBy
  );
  const assignedByInitials = assignedByUser
    ? `${assignedByUser.first_name.charAt(0)}${assignedByUser.last_name.charAt(
        0
      )}`
    : null;
  const assignedByFullName = assignedByUser
    ? `${assignedByUser.first_name} ${assignedByUser.last_name}`
    : null;

  const taskFormModalContent = (
    <div
      style={{
        padding: "0px 5% 5% 5%",
        width: "90%",
        height: "auto",
        height: "100%",
      }}
    >
      <div className="modal" style={{ height: "100%" }}>
        <div
          className="modal-content"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div>
            <form>
              <div
                style={{
                  height: "auto",
                  width: "100%",
                  marginTop: "0px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div style={{ width: "100%" }}>
                  <div style={{ marginTop: "30px", width: "100%" }}>
                    <input
                      className="task-title-input"
                      style={{
                        color: primaryColor,
                        fontWeight: "600",
                        fontSize: "20px",
                      }}
                      type="text"
                      id="taskTitle"
                      placeholder="Type here to change task title"
                      value={taskTitle}
                      onChange={(e) => setTaskTitle(e.target.value)}
                      onBlur={() => {
                        const newData = tasks.map((item) => {
                          if (item.id === selectedRow.id) {
                            const updatedItem = {
                              ...item,
                              task_title: taskTitle,
                            };
                            const updatedItemForCalendar = {
                              ...item,
                              title: taskTitle,
                              task_title: taskTitle,
                            };
                            fetch(
                              `https://ww1.premelo.com/api/update-task/${updatedItem.id}`,
                              {
                                method: "PUT",
                                body: JSON.stringify(updatedItem),
                                headers: {
                                  "Content-Type": "application/json",
                                  Authorization: `Bearer ${token}`,
                                },
                              }
                            )
                              .then((response) => {
                                console.log(response);
                              })
                              .catch((error) => {
                                console.error(error);
                              });
                            return updatedItemForCalendar;
                          } else {
                            return item;
                          }
                        });
                        setTask(newData);
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    width: "100%",
                    height: "5px",
                    marginTop: "35px",
                    marginBottom: "-14px",
                    fontSize: "11px",
                    color: "rgba(0,0,0,0.5)",
                  }}
                >
                  <div
                    style={{
                      width: "25%",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "10px",
                    }}
                  >
                    Created By:
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "25%",
                      height: "30px",
                      fontSize: "10px",
                    }}
                  >
                    Due Date:
                  </div>
                  <div style={{ width: "25%", fontSize: "10px" }}>Status:</div>
                  <div
                    style={{
                      width: "25%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: "10px",
                    }}
                  >
                    Assigned To:
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: "50px",
                    marginTop: "20px",
                    marginBottom: "30px",
                  }}
                >
                  {/* created by */}
                  <div
                    style={{
                      marginTop: "50px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      height: "30px",
                      marginBottom: "50px",
                    }}
                  >
                    <div
                      style={{
                        width: "25%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          fontWeight: "400",
                          fontSize: "8px",
                          width: "25px",
                          height: "25px",
                          backgroundColor: assignedByUser
                            ? assignedByUser.user_color
                            : null,
                          color: "white",
                          borderRadius: "50%",
                          marginRight: "6px",
                        }}
                      >
                        <p style={{ marginTop: "5px" }}>{assignedByInitials}</p>
                      </div>
                      <p
                        style={{
                          fontWeight: "400",
                          fontSize: "10px",
                          color: "rgba(0,0,0,0.6)",
                        }}
                      >
                        {assignedByFullName}
                      </p>
                    </div>
                    <div
                      style={{
                        marginTop: "50px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "25%",
                        height: "30px",
                        marginBottom: "50px",
                        marginRight: "-10px",
                      }}
                    >
                      <CalendarTodayIcon
                        style={{
                          marginLeft: "0px",
                          marginRight: "5px",
                          color: "rgba(0,0,0,0.4)",
                        }}
                      />
                      <div style={{ width: "80%" }}>
                        <CDatePicker
                          selected={dueDate ? new Date(dueDate) : null}
                          onChange={(date) =>
                            handleChangeDate(
                              new Date(date).toLocaleDateString()
                            )
                          }
                          dateFormat="yyyy/MM/dd"
                          className="form-control mui-button"
                          popperPlacement="top-end"
                          placeholderText="Select a date"
                        />
                      </div>
                    </div>
                    <div style={{ width: "25%" }}>
                      <div className="dropdown-container">
                        <button
                          value={status}
                          name="task_status"
                          className="dropdown-toggle"
                          onClick={(event) => {
                            event.preventDefault();
                            // setIsOpen(!isOpen);
                          }}
                          style={{
                            backgroundColor: selectedOption.color,
                            borderRadius: "50px",
                            padding: "8px 20px",
                            border: "none",
                            fontWeight: "600",
                            color: "white",
                            fontSize: "10px",
                          }}
                        >
                          {selectedOption.label || "Select status"}
                        </button>
                        {isOpen && (
                          <div className="dropdown-menu">
                            <div className="dropdown-options">
                              {statusOptions.map((option) => (
                                <div
                                  className="dropdown-option"
                                  key={option.value}
                                  onClick={() => handleOptionClick(option)}
                                >
                                  <div
                                    className="dropdown-option-color"
                                    style={{ backgroundColor: option.color }}
                                  >
                                    <div className="dropdown-option-label">
                                      {option.label}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className="dropdown-custom">
                              <div className="dropdown-custom-status">
                                <input
                                  className="custom-input"
                                  style={{ backgroundColor: customColor }}
                                  type="text"
                                  placeholder="Custom status"
                                  value={customStatus}
                                  onChange={handleCustomStatusChange}
                                />
                              </div>
                              <div className="dropdown-custom-color">
                                <input
                                  className="color-input"
                                  type="color"
                                  value={customColor}
                                  onChange={handleCustomColorChange}
                                />
                              </div>
                              <div className="dropdown-custom-commit">
                                <button
                                  type="button"
                                  style={{
                                    background: props.complementaryColor,
                                    color: "white",
                                  }}
                                  className="custom-button"
                                  onClick={handleCustomStatusCommit}
                                >
                                  Create
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "25%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Select
                        sx={{
                          boxShadow: "none",
                          ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        }}
                        style={{ width: "100%", height: "50px" }}
                        labelId="assigned-to-label"
                        id="assigned-to-select"
                        multiple
                        value={selectedUsers}
                        onChange={handleSelectChange}
                        onBlur={() => {
                          const newData = tasks.map((item) => {
                            if (item.id === selectedRow.id) {
                              const updatedItem = {
                                ...item,
                                task_assigned_to: selectedUsers,
                              };
                              fetch(
                                `https://ww1.premelo.com/api/update-task/${updatedItem.id}`,
                                {
                                  method: "PUT",
                                  body: JSON.stringify(updatedItem),
                                  headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${token}`,
                                  },
                                }
                              )
                                .then((response) => {
                                  console.log(response);
                                })
                                .catch((error) => {
                                  console.error(error);
                                });
                              return updatedItem;
                            } else {
                              return item;
                            }
                          });
                          setTask(newData);
                        }}
                        renderValue={(selected) => {
                          const selectedUsersNames = selected
                            .map((userId) => {
                              const user = availableUsers.find(
                                (user) => user.id === userId
                              );
                              console.log(userId);
                              console.log(availableUsers);
                              return user ? user.fullName : null;
                            })
                            .join(", ");

                          const selectedUsersIds = selected
                            .map((userId) => {
                              const user = availableUsers.find(
                                (user) => user.id === userId
                              );
                              return user ? `-${user.id}-` : null; // Add delimiter to beginning of user id
                            })
                            .join("");
                          console.log(selectedUsersIds);
                          const taskAssignedByUsersInModal =
                            availableUsers.filter((user) =>
                              selectedUsersIds.includes(`-${user.id}-`)
                            );
                          console.log(taskAssignedByUsersInModal);

                          const taskAssignedByInitialsInModal =
                            taskAssignedByUsersInModal.map((user) => ({
                              initials: `${user.first_name.charAt(
                                0
                              )}${user.last_name.charAt(0)}`,
                              color: user.user_color || null,
                            }));

                          return (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                height: "100%",
                              }}
                            >
                              {taskAssignedByInitialsInModal.map((initials) => (
                                <div
                                  style={{
                                    width: "25px",
                                    height: "25px",
                                    borderRadius: "50%",
                                    backgroundColor: initials.color,
                                    marginRight: "-5px",
                                    color: "white",
                                  }}
                                >
                                  <p
                                    style={{
                                      margin: "2px 0px 0px 7px",
                                      fontSize: "8px",
                                    }}
                                  >
                                    {initials.initials}
                                  </p>
                                </div>
                              ))}
                              <p
                                style={{
                                  fontSize: "10px",
                                  marginLeft: "9px",
                                  fontWeight: "400",
                                  color: "rgba(0,0,0,0.6)",
                                }}
                              >
                                {selectedUsersNames}
                              </p>
                            </div>
                          );
                        }}
                      >
                        {availableUsers.map((user) => (
                          <MenuItem key={user.id} value={user.id}>
                            <Checkbox
                              checked={selectedUsers.indexOf(user.id) > -1}
                            />
                            <ListItemText primary={user.fullName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "0px",
                    width: "100%",
                    fontSize: "12px",
                    color: "rgba(0,0,0,0.5)",
                    marginBottom: "10px",
                  }}
                >
                  <label
                    htmlFor="taskDescription"
                    style={{
                      fontSize: "10px",
                    }}
                  >
                    Task Description:
                  </label>
                  <ReactQuill
                    value={taskDescription}
                    onChange={setTaskDescription}
                    style={{ height: "100px", marginTop: "50px" }}
                    onBlur={() => {
                      const newData = tasks.map((item) => {
                        if (item.id === selectedRow.id) {
                          const updatedItem = {
                            ...item,
                            task_description: taskDescription,
                          };
                          fetch(
                            `https://ww1.premelo.com/api/update-task/${updatedItem.id}`,
                            {
                              method: "PUT",
                              body: JSON.stringify(updatedItem),
                              headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${token}`,
                              },
                            }
                          )
                            .then((response) => {
                              console.log(response);
                            })
                            .catch((error) => {
                              console.error(error);
                            });
                          return updatedItem;
                        } else {
                          return item;
                        }
                      });
                      setTask(newData);
                    }}
                    // onBlur={() => {
                    //   const newData = tableData.map((table) => {
                    //     if (table.taskType === selectedRow.task_type) {
                    //       const updatedData = table.data.map((item) => {
                    //         if (item.id === selectedRow.id) {
                    //           const updatedItem = {
                    //             ...item,
                    //             task_description: taskDescription,
                    //           };

                    //           fetch(
                    //             `https://ww1.premelo.com/api/update-task/${updatedItem.id}`,
                    //             {
                    //               method: "PUT",
                    //               body: JSON.stringify(updatedItem),
                    //               headers: {
                    //                 "Content-Type": "application/json",
                    //                 Authorization: `Bearer ${token}`,
                    //               },
                    //             }
                    //           )
                    //             .then((response) => {
                    //               console.log(response);
                    //             })
                    //             .catch((error) => {
                    //               console.error(error);
                    //             });

                    //           return updatedItem;
                    //         }
                    //         return item;
                    //       });
                    //       return {
                    //         ...table,
                    //         data: updatedData,
                    //       };
                    //     }
                    //     return table;
                    //   });
                    //   console.log(newData);
                    //   setTableData(newData);
                    // }}
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, false] }],
                        ["bold", "italic", "underline", "strike", "blockquote"],
                        [
                          { list: "ordered" },
                          { list: "bullet" },
                          { indent: "-1" },
                          { indent: "+1" },
                        ],
                        ["link", "image"],
                        ["clean"],
                      ],
                    }}
                    formats={formats}
                  />
                </div>
                <div
                  style={{
                    textAlign: "left",
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "100%",
                  }}
                >
                  <button
                    className="attachment-button mui-button"
                    style={{
                      color: "rgba(0,0,0,0.3)",
                      display: "flex",
                      alignItems: "center",
                      background: "transparent",
                      border: "none",
                      height: "45px",
                      width: "200px",
                      fontSize: "10px",
                    }}
                    type="button"
                    onClick={() => taskFileInput.current.click()}
                  >
                    <AttachFileIcon />
                    Add attachments
                  </button>
                  <input
                    type="file"
                    multiple
                    style={{ display: "none" }}
                    ref={taskFileInput}
                    onChange={handleFileUpload}
                  />
                </div>
                {taskAttachmentUrl && taskAttachmentUrl.length > 0 && (
                  <div
                    className="attachment-scroll"
                    style={{
                      marginTop: "5px",
                      display: "flex",
                      height: "100px",
                      width: "100%",
                    }}
                  >
                    {taskAttachmentUrl.map((attachment, index) => (
                      <div
                        style={{
                          width: "200px",
                          border: "1px solid rgba(0,0,0,0.15)",
                          borderRadius: "5px",
                          marginRight: "10px",
                          padding: "0px 20px",
                        }}
                      >
                        <a
                          key={index}
                          href={attachment.url}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            textAlign: "center",
                            textDecoration: "none",
                          }}
                        >
                          <div style={{ marginTop: "10px" }}>
                            {getFileIcon(attachment.fileName)}
                          </div>
                          <div
                            style={{
                              fontSize: "10px",
                              textDecoration: "none",
                              color: props.primaryColor,
                              marginBottom: "-3px",
                              lineHeight: "12px",
                            }}
                          >
                            {attachment.fileName.slice(0, 12)}
                          </div>
                          <div
                            style={{
                              fontSize: "10px",
                              textDecoration: "none",
                              color: "rgba(0,0,0,0.3)",
                            }}
                          >
                            {attachment.fileName.split(".").pop().toLowerCase()}
                          </div>
                        </a>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </form>
            <div
              style={{
                marginTop: "15px",
                width: "100%",
              }}
            >
              {modalMessages.length > 0 ? (
                modalMessages.map((message) => {
                  const createdAt = new Date(
                    message.message.created_at
                  ).toLocaleDateString();

                  // Get user details for the message user ID
                  const user = availableUsers.find(
                    (user) => user.id === message.message.message_user_id
                  );
                  const nameInitials =
                    user &&
                    user.first_name.charAt(0).toUpperCase() +
                      user.last_name.charAt(0).toUpperCase();

                  const userColor = user && user.user_color;

                  const userFullNameForMessage = user && user.fullName;

                  return (
                    <div
                      key={message.message.id}
                      style={{
                        borderTop: "1px solid rgba(0,0,0,0.15)",
                        marginBottom: "17px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div
                            style={{
                              background: userColor,
                              marginRight: "5px",
                              width: "25px",
                              height: "25px",
                              textAlign: "center",
                              borderRadius: "50%",
                              color: "white",
                              fontSize: "8px",
                            }}
                          >
                            <p style={{ marginTop: "6px" }}>{nameInitials}</p>
                          </div>
                          <p
                            style={{
                              fontSize: "10px",
                              color: "rgba(0,0,0,0.7)",
                            }}
                          >
                            {userFullNameForMessage}
                          </p>
                        </div>
                        <p
                          style={{
                            fontSize: "8px",
                            color: "rgba(0,0,0,0.4)",
                            marginTop: "12px",
                            marginLeft: "4px",
                          }}
                        >
                          {createdAt}
                        </p>
                      </div>
                      <div style={{ display: "flex", marginTop: "-20px" }}>
                        <div style={{ width: "40px" }} />
                        <div>
                          <p
                            style={{
                              fontSize: "12px",
                              color: "rgba(0,0,0,0.7)",
                            }}
                          >
                            {message.message.message
                              .split(/@\[([\w\s]+)\]\([\w]+\)/g)
                              .map((part, index) => {
                                if (index % 2 !== 0) {
                                  return (
                                    <span
                                      key={index}
                                      style={{ color: props.primaryColor }}
                                    >
                                      {part}
                                    </span>
                                  );
                                }
                                return <span key={index}>{part}</span>;
                              })}
                          </p>
                        </div>
                      </div>
                      <div
                        className="attachment-scroll"
                        style={{ display: "flex" }}
                      >
                        {message.attachments.map((attachment, index) => {
                          return (
                            <div style={{ width: "auto" }}>
                              <a
                                href={attachment.attachment_url}
                                target="_blank"
                                rel="noreferrer"
                                key={index}
                                style={{
                                  display: "flex",
                                  height: "20px",
                                  border: "1px solid rgba(0,0,0,0.15)",
                                  padding: "7px 20px",
                                  borderRadius: "5px",
                                  marginRight: "10px",
                                  textDecoration: "none",
                                  textAlign: "left",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ marginTop: "6px" }}>
                                  {getMessageFileIcon(attachment.file_name)}
                                </div>
                                <div
                                  style={{
                                    fontSize: "10px",
                                    textDecoration: "none",
                                    color: "rgba(0,0,0,0.5)",
                                    marginLeft: "6px",
                                  }}
                                >
                                  {attachment.file_name.slice(0, 40)}
                                </div>
                              </a>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div></div>
              )}
            </div>
          </div>
          <div
            style={{
              marginTop: "auto",
              width: "100%",
              borderTop: "1px solid rgba(0,0,0,0.2)",
              marginBottom: "10px",
            }}
          >
            <div
              className="mention-input"
              style={{ marginTop: "10px", fontSize: "12px" }}
            >
              <MentionsInput
                singleLine={false}
                style={{
                  height: "70px",
                  width: "100%",
                  suggestions: {
                    list: {
                      backgroundColor: "white",
                      border: "1px solid rgba(0,0,0,0.15)",
                      fontSize: 16,
                    },
                    item: {
                      padding: "5px 15px",
                      borderBottom: "1px solid rgba(0,0,0,0.15)",
                      "&focused": {
                        backgroundColor: primaryColor,
                      },
                    },
                  },
                }}
                value={message}
                onChange={handleMentionChange}
                suggestionsPortalHost={document.body}
              >
                <Mention
                  style={{ backgroundColor: primaryColor }}
                  trigger="@"
                  data={availableUsers.map((user) => ({
                    ...user,
                    display: user.fullName,
                  }))}
                  renderSuggestion={renderSuggestion}
                  displayTransform={(id, display) => {
                    if (typeof display === "object") {
                      return `@${display.fullName}`;
                    }
                    return `@${display}`;
                  }}
                />
              </MentionsInput>
              <div style={{ display: "flex" }}>
                {attachmentUrl.map((attachment, index) => (
                  <div key={index}>
                    <div
                      target="_blank"
                      rel="noreferrer"
                      style={{
                        display: "flex",
                        height: "20px",
                        border: "1px solid rgba(0,0,0,0.15)",
                        padding: "7px 20px",
                        borderRadius: "5px",
                        marginRight: "10px",
                        textDecoration: "none",
                        textAlign: "left",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ marginTop: "6px" }}>
                        {getMessageFileIcon(attachment.fileName)}
                      </div>
                      <a
                        href={attachment.url}
                        style={{
                          fontSize: "10px",
                          textDecoration: "none",
                          color: "rgba(0,0,0,0.5)",
                          marginLeft: "6px",
                        }}
                      >
                        {attachment.fileName.slice(0, 10) +
                          (attachment.fileName.length > 10 ? " ..." : "")}
                      </a>
                      <button
                        className="mui-button"
                        style={{
                          marginLeft: "10px",
                          background: "transparent",
                          color: primaryColor,
                          border: `1px solid ${primaryColor}`,
                          fontSize: "12px",
                        }}
                        onClick={() => handleRemoveAttachment(index)}
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <button
                  className="attachment-button mui-button"
                  style={{
                    color: "rgba(0,0,0,0.3)",
                    background: "transparent",
                    border: "none",
                    alignItems: "center",
                    display: "flex",
                    fontSize: "12px",
                  }}
                  type="button"
                  onClick={() => fileInput.current.click()}
                >
                  <AttachFileIcon />
                  Add attachments
                </button>
                <input
                  type="file"
                  multiple
                  style={{ display: "none" }}
                  ref={fileInput}
                  onChange={handleFileInputChange}
                />
                <button
                  className="mui-button"
                  style={{
                    background: props.primaryColor,
                    border: "none",
                    color: "white",
                    borderRadius: "10px",
                    padding: "5px 20px",
                    fontSize: "12px",
                  }}
                  onClick={handleAddMessage}
                >
                  Comment
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div style={{ height: windowHeight }}>
      <div
        style={{
          display: "flex",
          borderBottom: "1px solid rgba(0,0,0,0.1)",
          alignItems: "center",
          background: "rgba(255,255,255,0.7)",
          backdropFilter: "blur(20px)",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
          padding: "30px 0px 10px 0px",
          position: "relative",
          zIndex: 490,
        }}
      >
        <div
          className="mui-button"
          style={{
            color: props.primaryColor,
            fontSize: "22px",
            fontWeight: "600",
            marginLeft: "20px",
            paddingBottom: "1%",
          }}
        >
          Calendar
        </div>
      </div>
      <Modal
        isOpen={taskFormModalIsOpen}
        onRequestClose={() => setTaskFormModalIsOpen(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 505,
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "0px",
            width: "45%",
            height: "90vh",
            background: "#fff",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            borderRadius: "10px",
            overflowX: "none",
            overflowY: "scroll",
          },
        }}
      >
        {taskFormModalContent}
      </Modal>
      <div
        style={{
          padding: "15px",
          position: "relative",
          zIndex: 490,
          height: windowHeight - 140,
        }}
      >
        <DnDCalendar
          defaultView="month"
          events={tasks}
          localizer={localizer}
          onEventDrop={onEventDrop}
          onEventResize={onEventResize}
          views={["month"]}
          resizable
          style={{ height: "100%", marginTop: "10px" }}
          eventPropGetter={(tasks) => {
            const backgroundColor = tasks.colorEvento;
            const color = "#ffffff";
            const position = "relative";
            const border = "none";
            return { style: { backgroundColor, color, position, border } };
          }}
          onSelectEvent={handleTaskButtonClick}
        />
      </div>
    </div>
  );
}

export default CalendarContent;
