import React, { useState, useEffect, useRef } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { v4 as uuidv4 } from "uuid";
import Modal from "react-modal";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Cookies from "js-cookie";
import ListIcon from "@mui/icons-material/List";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ChromePicker } from "react-color";
import { CircularProgress } from "@mui/material";
import { cloneDeep, isEqual } from "lodash";
import LeakAddIcon from "@mui/icons-material/LeakAdd";
import { ToastContainer, toast } from "react-toastify";

function FormsMobile(props) {
  const [createFormModal, setCreateFormModal] = useState(false);
  const [newFormName, setNewFormName] = useState("");
  const [allForms, setAllForms] = useState([]);
  const [activeForm, setActiveForm] = useState(null);
  const [fieldDropdown, setFieldDropdown] = useState(false);
  const [choicesInput, setChoicesInput] = useState("");
  const [saving, setSaving] = useState(false);
  const token = Cookies.get("token");
  const [viewingSubmissions, setViewingSubmissions] = useState(false);

  useEffect(() => {
    const handleGetForms = async () => {
      try {
        const response = await fetch(
          "https://ww1.premelo.com/api/web-builder/get-form",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const json = await response.json();
        console.log(json);
        setAllForms(json.forms);
      } catch (error) {
        console.log(error);
      }
    };

    handleGetForms();
  }, []);

  const handleClickOutsideShowClient = (event) => {
    const showFieldContainer = document.getElementById("show_field_container");
    const fieldTrigger = document.getElementById("show_field_trigger");

    if (
      showFieldContainer &&
      !showFieldContainer.contains(event.target) &&
      !fieldTrigger.contains(event.target)
    ) {
      setFieldDropdown(false);
    }
  };

  useEffect(() => {
    if (fieldDropdown) {
      window.addEventListener("click", handleClickOutsideShowClient);
    } else {
      window.removeEventListener("click", handleClickOutsideShowClient);
    }

    return () => {
      window.removeEventListener("click", handleClickOutsideShowClient);
    };
  }, [fieldDropdown]);

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return; // Dragged outside of the droppable area
    }

    const items = [...activeForm.form_array];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const updatingActiveForm = { ...activeForm };
    updatingActiveForm.form_array = items;
    setActiveForm(updatingActiveForm);
  };

  const getDroppableStyle = (isDraggingOver) => ({
    background: "transparent",
  });

  const getDraggableStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? props.primaryColor : "transparent",
    color: isDragging ? "white" : "rgba(0,0,0,0.6)",
    fontSize: "12px",
    border: isDragging ? "none" : "1px solid rgba(0,0,0,0.6)",
    padding: "3px 10px",
    margin: "5px 0px",
    borderRadius: "5px",
    ...draggableStyle,
  });

  const currentURL = window.location.href;
  const modifiedURL = currentURL.replace(/\/dashboard$/, "");

  return (
    <div style={{ position: "relative", height: "100%" }}>
      {/* Header */}
      <Modal
        isOpen={createFormModal}
        onRequestClose={() => {
          setCreateFormModal(false);
          setNewFormName("");
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 4991,
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "500px",
            height: "200px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",
            borderRadius: "5px",
            padding: "0px",
            borderColor: "transparent",
            outline: "none",
            border: "none",
            zIndex: 4991,
            background: "rgba(255,255,255,0.5)",
            backdropFilter: "blur(20px)",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
          },
        }}
      >
        <div>
          <div
            style={{
              height: "40px",
              width: "100%",
              borderBottom: "1px solid lightgrey",
            }}
          >
            <div
              style={{
                background: props.primaryColor,
                height: "4px",
                width: "100%",
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "36px",
              }}
            >
              <div
                style={{
                  margin: "0px 12px",
                  color: "rgba(0,0,0,0.8)",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Create New Form
              </div>
              <div
                style={{
                  margin: "0px 12px",
                  color: "rgba(0,0,0,0.8)",
                  fontSize: "12px",
                }}
              >
                <CancelIcon
                  className="mui-button"
                  style={{ color: "grey", fontSize: "18px" }}
                  onClick={() => {
                    setCreateFormModal(false);
                    setNewFormName("");
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ padding: "0px 20px", marginTop: "20px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "0px 20px",
                marginTop: "12px",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  margin: "0px 0px 10px 0px",
                  color: "rgba(0,0,0,0.6)",
                }}
              >
                Name your form
              </div>
              <input
                style={{
                  borderRadius: "8px",
                  outline: "1px solid lightgrey",
                  border: "none",
                  padding: "7px 25px",
                  margin: "0px 0px 10px 0px",
                  width: "70%",
                }}
                value={newFormName}
                onChange={(e) => {
                  setNewFormName(e.target.value);
                  console.log(newFormName);
                }}
              />
              <div>
                <button
                  className="mui-button"
                  style={{
                    background: props.primaryColor,
                    color: "white",
                    borderRadius: "6px",
                    padding: "7px 20px",
                    border: "none",
                    fontSize: "11px",
                    fontWeight: "600",
                  }}
                  onClick={() => {
                    let newForm = {
                      form_title: "",
                      form_array: [],

                      title_styling: {
                        color: "rgba(0,0,0,0.8)",
                        fontSize: 25,
                        fontWeight: 700,
                        textAlign: "center",
                      },
                      field_styling: {
                        color: "rgba(0,0,0,0.6)",
                        borderRadius: 5,
                        border: "black",
                        background: "transparent",
                        fontSize: 14,
                        fontWeight: 700,
                        gap: 10,
                      },
                      button_styling: {
                        content: "Submit",
                        color: "white",
                        background: props.primaryColor,
                        borderRadius: 10,
                        fontSize: 18,
                        fontWeight: 600,
                      },
                    };
                    newForm.form_title = newFormName;
                    const updatedAllForms = [...allForms];
                    updatedAllForms.push(newForm);
                    setAllForms(updatedAllForms);
                    setCreateFormModal(false);
                    setNewFormName("");
                  }}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div
        style={{
          fontSize: "30px",
          fontWeight: "600",
          marginTop: "-35px",
          marginLeft: "20px",
          marginBottom: "15px",
          paddingBottom: "1%",
          color: "white",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: activeForm ? "30px" : "0px",
            marginTop: "7px",
            opacity: activeForm ? "1" : "0",
            transition: "all 0.4s ease-in-out",
            cursor: activeForm ? "pointer" : "none",
          }}
          onClick={() => {
            setActiveForm(null);
          }}
        >
          <ArrowBackIosNewIcon style={{ color: "white" }} />
        </div>
        <div
          onClick={() => {
            setActiveForm(null);
          }}
          style={{}}
        >
          Forms
        </div>
      </div>

      {/* body */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "75vh",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <div
          style={{
            width: "94%",
            marginLeft: "3%",
            padding: "15px 0px",
            height: "87%",
          }}
        >
          {activeForm ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "rgba(0,0,0,0.6)",
                gap: "15px",
                height: "100%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "10px",
                  background: "rgba(255,255,255,1)",
                  backdropFilter: "blur(20px)",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                }}
              >
                <div
                  style={{
                    height: "40px",
                    width: "100%",
                    borderRadius: "5px 5px 0px 0px",
                    overflow: "hidden",
                    borderBottom: "1px solid lightgrey",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "36px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        onClick={() => setViewingSubmissions(false)}
                        className="mui-button"
                        style={{
                          margin: "0px 12px",
                          color: "rgba(0,0,0,0.8)",
                          fontSize: "14px",
                          fontWeight: 600,
                          opacity: viewingSubmissions ? 0.5 : 1,
                        }}
                      >
                        Form Preview
                      </div>
                      <div
                        onClick={() => setViewingSubmissions(true)}
                        className="mui-button"
                        style={{
                          margin: "0px 12px",
                          color: "rgba(0,0,0,0.8)",
                          fontSize: "14px",
                          fontWeight: 600,
                          opacity: viewingSubmissions ? 1 : 0.5,
                        }}
                      >
                        View Submissions
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div
                        style={{
                          background: "rgba(0,0,0,0.2)",
                          width: "9px",
                          height: "9px",
                          borderRadius: "10px",
                          marginRight: "7px",
                        }}
                      />
                      <div
                        style={{
                          background: "rgba(0,0,0,0.2)",
                          width: "9px",
                          height: "9px",
                          borderRadius: "10px",
                          marginRight: "7px",
                        }}
                      />
                      <div
                        style={{
                          background: "grey",
                          width: "9px",
                          height: "9px",
                          borderRadius: "10px",
                          marginRight: "14px",
                        }}
                      />
                    </div>
                  </div>
                </div>
                {viewingSubmissions ? (
                  <>
                    <div
                      style={{
                        width: "calc(100% - 20px)",
                        height: "calc(94% - 20px)",
                        padding: "10px",
                        display: "flex",
                        overflowX: "scroll",
                        alignItems: "flex-start",
                        overflowY: "auto",
                      }}
                    >
                      {activeForm.form_array
                        ? activeForm.form_array.map((field, index) => (
                            <div key={index}>
                              <div
                                style={{
                                  textAlign: "center",
                                  width: "200px",
                                  paddingTop: "5px",
                                  paddingBottom: "10px",
                                  borderBottom: "1px solid lightgrey",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: 600,
                                    color: "rgba(0,0,0,0.7)",
                                    width: "200px",
                                  }}
                                >
                                  {field.id.slice(0, 10)} ...
                                </div>
                                <div
                                  style={{
                                    fontSize: "9px",
                                    fontWeight: 400,
                                    color: "rgba(0,0,0,0.5)",
                                    width: "200px",
                                    marginTop: "-5px",
                                  }}
                                >
                                  {field.type}
                                </div>
                              </div>
                              {activeForm.response_array
                                ? activeForm.response_array.map(
                                    (resp, index) => (
                                      <div
                                        key={index}
                                        style={{
                                          fontSize: "10px",
                                          fontWeight: 400,
                                          color: "rgba(0,0,0,0.7)",
                                          width: "200px",
                                          textAlign: "center",
                                          margin: "5px 0px",
                                          borderBottom: "1px solid lightgrey",
                                          height: "20px",
                                        }}
                                      >
                                        {resp[field.id]
                                          ? resp[field.id].toString()
                                          : null}
                                      </div>
                                    )
                                  )
                                : null}
                            </div>
                          ))
                        : null}
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      width: "90%",
                      height: "100%",
                      margin: "15px 0px 0px 5%",
                    }}
                  >
                    <div
                      style={{
                        color: activeForm.title_styling.color,
                        fontSize: activeForm.title_styling.fontSize,
                        fontWeight: activeForm.title_styling.fontWeight,
                        width: "100%",
                        display: "flex",
                        justifyContent: activeForm.title_styling.textAlign,
                      }}
                    >
                      {activeForm.form_title}
                    </div>
                    {activeForm.form_array.length > 0 ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: activeForm.title_styling.textAlign,
                            marginTop: `calc(${activeForm.field_styling.gap}px + 10px)`,
                            gap: `${activeForm.field_styling.gap}px`,
                          }}
                        >
                          {activeForm.form_array.map((fields, index) => {
                            if (fields.type === "string") {
                              return (
                                <input
                                  className="form_input"
                                  key={fields.id}
                                  type="text"
                                  required={fields.required}
                                  placeholder={`${fields.placeholder}`}
                                  style={{
                                    width: `calc(${fields.width} - 3% - ${activeForm.field_styling.gap}px)`,
                                    color: activeForm.field_styling.color,
                                    borderRadius:
                                      activeForm.field_styling.borderRadius,
                                    border: `1px solid ${activeForm.field_styling.border}`,
                                    background:
                                      activeForm.field_styling.background,
                                    fontSize: activeForm.field_styling.fontSize,
                                    fontWeight:
                                      activeForm.field_styling.fontWeight,
                                    padding: "10px",
                                    outline: "none",
                                  }}
                                />
                              );
                            }
                            if (fields.type === "email") {
                              return (
                                <input
                                  className="form_input"
                                  type="email"
                                  key={fields.id}
                                  required={fields.required}
                                  placeholder={`${fields.placeholder}`}
                                  style={{
                                    width: `calc(${fields.width} - 3% - ${activeForm.field_styling.gap}px)`,
                                    color: activeForm.field_styling.color,
                                    borderRadius:
                                      activeForm.field_styling.borderRadius,
                                    border: `1px solid ${activeForm.field_styling.border}`,
                                    background:
                                      activeForm.field_styling.background,
                                    fontSize: activeForm.field_styling.fontSize,
                                    fontWeight:
                                      activeForm.field_styling.fontWeight,
                                    padding: "10px",
                                    outline: "none",
                                  }}
                                />
                              );
                            }
                            if (fields.type === "phone_number") {
                              return (
                                <input
                                  className="form_input"
                                  type="tel"
                                  key={fields.id}
                                  required={fields.required}
                                  placeholder={`${fields.placeholder}`}
                                  style={{
                                    width: `calc(${fields.width} - 3% - ${activeForm.field_styling.gap}px)`,
                                    color: activeForm.field_styling.color,
                                    borderRadius:
                                      activeForm.field_styling.borderRadius,
                                    border: `1px solid ${activeForm.field_styling.border}`,
                                    background:
                                      activeForm.field_styling.background,
                                    fontSize: activeForm.field_styling.fontSize,
                                    fontWeight:
                                      activeForm.field_styling.fontWeight,
                                    padding: "10px",
                                    outline: "none",
                                  }}
                                />
                              );
                            }
                            if (fields.type === "select") {
                              return (
                                <select
                                  className="form_input"
                                  key={fields.id}
                                  required={fields.required}
                                  placeholder={`${fields.placeholder}`}
                                  style={{
                                    width: `calc(${fields.width} - ${activeForm.field_styling.gap}px)`,
                                    color: activeForm.field_styling.color,
                                    borderRadius:
                                      activeForm.field_styling.borderRadius,
                                    border: `1px solid ${activeForm.field_styling.border}`,
                                    background:
                                      activeForm.field_styling.background,
                                    fontSize: activeForm.field_styling.fontSize,
                                    fontWeight:
                                      activeForm.field_styling.fontWeight,
                                    padding: "10px",
                                    outline: "none",
                                  }}
                                >
                                  <option value={""}>Select an option</option>
                                  {fields.fields.map((choice, index) => (
                                    <option
                                      value={`${choice.value}`}
                                      key={index}
                                    >
                                      {choice.value}
                                    </option>
                                  ))}
                                </select>
                              );
                            }
                            if (fields.type === "checkbox") {
                              return (
                                <div
                                  style={{
                                    width: `calc(${fields.width} - ${activeForm.field_styling.gap}px)`,
                                    display: "flex",
                                    alignItems: "center",
                                    color: activeForm.field_styling.color,
                                    fontSize: activeForm.field_styling.fontSize,
                                    fontWeight:
                                      activeForm.field_styling.fontWeight,
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    key={fields.id}
                                    required={fields.required}
                                    placeholder={`${fields.placeholder}`}
                                    style={{
                                      width: `15px`,
                                      height: `15px`,
                                      color: activeForm.field_styling.color,
                                      borderRadius:
                                        activeForm.field_styling.borderRadius,
                                      border: `1px solid ${activeForm.field_styling.color}`,
                                      background:
                                        activeForm.field_styling.background,
                                      fontSize:
                                        activeForm.field_styling.fontSize,
                                      padding: "10px",
                                      outline: "none",
                                      margin: "0px 10px 0px 0px",
                                    }}
                                  />
                                  <div>{fields.placeholder}</div>
                                </div>
                              );
                            }
                            if (fields.type === "message") {
                              return (
                                <textarea
                                  className="form_input"
                                  key={fields.id}
                                  required={fields.required}
                                  placeholder={`${fields.placeholder}`}
                                  style={{
                                    width: `calc(${fields.width} - ${activeForm.field_styling.gap}px)`,
                                    color: activeForm.field_styling.color,
                                    borderRadius:
                                      activeForm.field_styling.borderRadius,
                                    border: `1px solid ${activeForm.field_styling.border}`,
                                    background:
                                      activeForm.field_styling.background,
                                    fontSize: activeForm.field_styling.fontSize,
                                    fontWeight:
                                      activeForm.field_styling.fontWeight,
                                    padding: "10px",
                                    outline: "none",
                                    resize: "none",
                                  }}
                                />
                              );
                            }
                          })}
                        </div>
                        <div
                          style={{
                            marginTop: `calc(${activeForm.field_styling.gap}px + 15px)`,
                            width: "100%",
                            display: "flex",
                            justifyContent: activeForm.title_styling.textAlign,
                          }}
                        >
                          <button
                            style={{
                              padding: "7px 25px",
                              color: activeForm.button_styling.color,
                              background: activeForm.button_styling.background,
                              borderRadius:
                                activeForm.button_styling.borderRadius,
                              fontSize: activeForm.button_styling.fontSize,
                              fontWeight: activeForm.button_styling.fontWeight,
                              border: "none",
                              outline: "none",
                              cursor: "pointer",
                            }}
                          >
                            {activeForm.button_styling.content}
                          </button>
                        </div>
                      </>
                    ) : (
                      <div
                        style={{
                          height: "85%",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Add fields to your form
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : allForms.length > 0 ? (
            allForms.map((form, index) => (
              <div
                onClick={() => {
                  setActiveForm(form);
                  console.log(form);
                }}
                key={index}
                className="mui-button"
                style={{
                  background: "rgba(255,255,255,1)",
                  backdropFilter: "blur(20px)",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                  padding: "10px 15px",
                  borderRadius: "5px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(0,0,0,0.6)",
                  marginBottom: "8px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div>{form.form_title}</div>

                <LeakAddIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(
                      `${modifiedURL}/ezylinkf?formId=${form.id}`
                    );
                    toast.info("Ezylink copied to clipboard!", {
                      position: "top-right",
                      autoClose: 1500,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                  }}
                  className="mui-button"
                  style={{ color: "rgba(0,0,0,0.8)" }}
                />
              </div>
            ))
          ) : (
            <div
              style={{
                width: "100%",
                background: "white",
                borderRadius: "10px",
                padding: "100px 0px",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  color: "rgba(0,0,0,0.3)",
                  width: "100%",
                  fontSize: "20px",
                }}
              >
                <div>No Forms Created</div>
                <div style={{ padding: "0px 40px", fontSize: "12px" }}>
                  Form creation tools are only accesible from desktop, if you'd
                  like to create a form, head over to the desktop site to start
                  creating your first form.
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default FormsMobile;
