import React, { useState, useEffect, useRef } from "react";

const FormEzyLink = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const formId = urlParams.get("formId");

  const [companyColor, setCompanyColor] = useState("#1872d9");
  const [companyLogo, setCompanyLogo] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [form, setForm] = useState(null);
  const [responseAr, setResponseAr] = useState({});

  const handleResponseUpdate = (title, value) => {
    setResponseAr({ ...responseAr, [title]: value });
    console.log({ ...responseAr, [title]: value });
  };

  const [loading, setLoading] = useState(false);

  const handleFormSubmission = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/web/form-response",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            form_id: form.id,
            response_array: responseAr,
          }),
        }
      );
      const json = await response.json();
      setLoading(false);
      console.log(json);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetForm = async (formId) => {
    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/web-builder/get-forms-public",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: formId }),
        }
      );
      const json = await response.json();
      if (json.company_color) {
        setCompanyColor(json.company_color);
      }
      setCompanyLogo(json.company_logo);
      setCompanyName(json.company_name);
      setForm(json.forms);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (formId) {
      handleGetForm(formId);
    }
  }, []);

  return (
    <div>
      <div
        style={{
          backgroundImage: `linear-gradient(to right, ${companyColor}1A, ${companyColor}7A)`,
          position: "relative",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
        }}
      >
        <div
          style={{
            display: "flex",
            padding: "0px 30px",
            alignItems: "center",
            position: "absolute",
            zIndex: 1,
          }}
        >
          <img
            style={{ width: "80px", height: "80px", objectFit: "cover" }}
            src={companyLogo ? companyLogo : null}
          />
          <div
            style={{
              marginLeft: "10px",
              fontWeight: 700,
              color: "rgba(0,0,0,0.7)",
            }}
          >
            {companyName}
          </div>
        </div>
        {form ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 35px)",
            }}
          >
            <div
              style={{
                width: "80%",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  fontWeight: 700,
                  color: "rgba(0,0,0,0.8)",
                  fontSize: "32px",
                }}
              >
                {form.form_title}
              </div>
              {form.form_array.length > 0 ? (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleFormSubmission();
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      width: "100%",
                      justifyContent: form.title_styling.textAlign,
                      marginTop: `calc(${form.field_styling.gap}px + 10px)`,
                      gap: `${form.field_styling.gap}px`,
                    }}
                  >
                    {form.form_array.map((fields, index) => {
                      if (fields.type === "string") {
                        return (
                          <input
                            className="form_input"
                            key={fields.id}
                            type="text"
                            required={fields.required}
                            placeholder={`${fields.placeholder}`}
                            value={responseAr[fields.id]}
                            onChange={(e) => {
                              handleResponseUpdate(fields.id, e.target.value);
                            }}
                            style={{
                              width: `calc(${fields.width} - 3% - ${form.field_styling.gap}px)`,
                              border: `none`,
                              padding: "10px",
                              background: "rgba(255,255,255,0.4)",
                              borderRadius: "5px",
                              border: "1px solid grey",
                              outline: "none",
                              fontWeight: 400,
                              color: "rgba(0,0,0,0.7)",
                              fontSize: "14px",
                              lineHeight: "15px",
                            }}
                          />
                        );
                      }
                      if (fields.type === "email") {
                        return (
                          <input
                            className="form_input"
                            type="email"
                            key={fields.id}
                            required={fields.required}
                            placeholder={`${fields.placeholder}`}
                            value={responseAr[fields.id]}
                            onChange={(e) => {
                              handleResponseUpdate(fields.id, e.target.value);
                            }}
                            style={{
                              width: `calc(${fields.width} - 3% - ${form.field_styling.gap}px)`,
                              border: `none`,
                              padding: "10px",
                              background: "rgba(255,255,255,0.4)",
                              borderRadius: "5px",
                              border: "1px solid grey",
                              outline: "none",
                              fontWeight: 400,
                              color: "rgba(0,0,0,0.7)",
                              fontSize: "14px",
                              lineHeight: "15px",
                            }}
                          />
                        );
                      }
                      if (fields.type === "phone_number") {
                        return (
                          <input
                            className="form_input"
                            type="tel"
                            key={fields.id}
                            required={fields.required}
                            placeholder={`${fields.placeholder}`}
                            value={responseAr[fields.id]}
                            onChange={(e) => {
                              handleResponseUpdate(fields.id, e.target.value);
                            }}
                            style={{
                              width: `calc(${fields.width} - 3% - ${form.field_styling.gap}px)`,
                              border: `none`,
                              padding: "10px",
                              background: "rgba(255,255,255,0.4)",
                              borderRadius: "5px",
                              border: "1px solid grey",
                              outline: "none",
                              fontWeight: 400,
                              color: "rgba(0,0,0,0.7)",
                              fontSize: "14px",
                              lineHeight: "15px",
                            }}
                          />
                        );
                      }
                      if (fields.type === "select") {
                        return (
                          <select
                            className="form_input"
                            key={fields.id}
                            required={fields.required}
                            placeholder={`${fields.placeholder}`}
                            value={responseAr[fields.id]}
                            onChange={(e) => {
                              handleResponseUpdate(fields.id, e.target.value);
                            }}
                            style={{
                              width: `calc(${fields.width} - ${form.field_styling.gap}px)`,
                              border: `none`,
                              padding: "10px",
                              background: "rgba(255,255,255,0.4)",
                              borderRadius: "5px",
                              border: "1px solid grey",
                              outline: "none",
                              fontWeight: 400,
                              color: "rgba(0,0,0,0.7)",
                              fontSize: "14px",
                              lineHeight: "15px",
                            }}
                          >
                            <option value={""}>Select an option</option>
                            {fields.fields.map((choice, index) => (
                              <option value={`${choice.value}`} key={index}>
                                {choice.value}
                              </option>
                            ))}
                          </select>
                        );
                      }
                      if (fields.type === "checkbox") {
                        return (
                          <div
                            style={{
                              width: `calc(${fields.width} - ${form.field_styling.gap}px)`,
                              display: "flex",
                              alignItems: "center",
                              color: form.field_styling.color,
                              fontSize: form.field_styling.fontSize,
                              fontWeight: form.field_styling.fontWeight,
                            }}
                          >
                            <input
                              type="checkbox"
                              key={fields.id}
                              required={fields.required}
                              placeholder={`${fields.placeholder}`}
                              value={responseAr[fields.id]}
                              onChange={(e) => {
                                handleResponseUpdate(
                                  fields.id,
                                  e.target.checked
                                );
                              }}
                              style={{
                                width: `20px`,
                                height: `20px`,
                                outline: `none`,
                                background: "rgba(255,255,255,0.4)",
                                borderRadius: "5px",
                                border: "1px solid grey",
                                outline: "none",
                                fontWeight: 400,
                                color: "rgba(0,0,0,0.7)",
                                fontSize: "14px",
                                lineHeight: "15px",
                                accentColor: companyColor,
                              }}
                            />
                            <div>{fields.placeholder}</div>
                          </div>
                        );
                      }
                      if (fields.type === "message") {
                        return (
                          <textarea
                            className="form_input"
                            key={fields.id}
                            required={fields.required}
                            placeholder={`${fields.placeholder}`}
                            value={responseAr[fields.id]}
                            onChange={(e) => {
                              handleResponseUpdate(fields.id, e.target.value);
                            }}
                            style={{
                              width: `calc(${fields.width} - ${form.field_styling.gap}px)`,
                              border: `none`,
                              padding: "10px",
                              background: "rgba(255,255,255,0.4)",
                              borderRadius: "5px",
                              border: "1px solid grey",
                              outline: "none",
                              fontWeight: 400,
                              color: "rgba(0,0,0,0.7)",
                              fontSize: "14px",
                              lineHeight: "15px",
                              resize: "none",
                            }}
                          />
                        );
                      }
                    })}
                  </div>
                  <div
                    style={{
                      marginTop: `calc(${form.field_styling.gap}px + 15px)`,
                      width: "100%",
                      display: "flex",
                      justifyContent: form.title_styling.textAlign,
                    }}
                  >
                    <button
                      type="submit"
                      className="mui-button"
                      style={{
                        fontWeight: 700,
                        color: "rgba(0,0,0,0.7)",
                        fontSize: "14px",
                        lineHeight: "15px",
                        background: companyColor,
                        padding: "10px 25px",
                        color: "white",
                        borderRadius: "30px",
                        border: "none",
                        outline: "none",
                      }}
                    >
                      {form.button_styling.content}
                    </button>
                  </div>
                </form>
              ) : (
                <div
                  style={{
                    height: "85%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  Add fields to your form
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
      <div
        style={{
          fontSize: "10px",
          textAlign: "center",
          padding: "10px",
          background: "white",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
        }}
      >
        All rights reserved © {companyName ? companyName : null} | Build with
        Premelo
      </div>
    </div>
  );
};

export default FormEzyLink;
