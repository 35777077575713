import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ReactComponent as MyIcon } from "./icons/menu_1.svg";
import { ReactComponent as MyIcon1 } from "./icons/menu_2.svg";
import { ReactComponent as MyIcon2 } from "./icons/menu_3.svg";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { ReactComponent as MyIcon3 } from "./icons/menu_4.svg";
import header from "./images/header_sample.png";
import { ReactComponent as MyIcon4 } from "./icons/menu_5.svg";
import { ReactComponent as MyIcon5 } from "./icons/menu_6.svg";
import { ReactComponent as FolderIcon } from "./icons/folder.svg";
import Cookies from "js-cookie";
import Modal from "react-modal";
import "./App.css";
import { ChromePicker } from "react-color";
import { useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import logo from "./images/CS_Favicon-01.png";
import CheckIcon from "@mui/icons-material/CheckCircleOutline";
import CheckedIcon from "@mui/icons-material/CheckCircle";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-mention/dist/quill.mention.min.css";
import Select from "@mui/material/Select";
import { ListItemText, ListItemIcon } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { TimePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import SettingsIcon from "@mui/icons-material/Settings";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import CrossIcon from "@mui/icons-material/Clear";
import { nanoid } from "nanoid";
import Calendar from "short-react-calendar";
import TimelineCalender from "./TimelineCalendar";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import PreviewSkeleton from "./PreviewSkeleton";
import { TwitterPicker } from "react-color";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import LanIcon from "@mui/icons-material/Lan";
import GroupsIcon from "@mui/icons-material/Groups";
import GroupIcon from "@mui/icons-material/Group";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import WorkIcon from "@mui/icons-material/Work";
import HubIcon from "@mui/icons-material/Hub";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import TerminalIcon from "@mui/icons-material/Terminal";
import PaymentIcon from "@mui/icons-material/Payment";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import HandshakeIcon from "@mui/icons-material/Handshake";
import HandymanIcon from "@mui/icons-material/Handyman";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import EmailIcon from "@mui/icons-material/Email";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import DescriptionIcon from "@mui/icons-material/Description";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import BusinessIcon from "@mui/icons-material/Business";
import CallIcon from "@mui/icons-material/Call";
import CalculateIcon from "@mui/icons-material/Calculate";
import CookieIcon from "@mui/icons-material/Cookie";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import HeadsetIcon from "@mui/icons-material/Headset";
import PreviewSkeletonMobile from "./PreviewSkeletonMobile";
import Tour from "reactour";
import DefaultProfile from "./images/defaultprofile.jpg";

const DashContentMobile = (props) => {
  const [profilePhoto, setProfilePhoto] = useState(DefaultProfile)
  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      axios
        .get("/api/user-data", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const { name, is_admin, menu_items, profile_photo } = response.data;
          setName(name);
          setProfilePhoto(profile_photo);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  const token = Cookies.get("token");
  const [openWebsiteSettingsModal, setOpenWebsiteSettingsModal] =
    useState(null);
  const [domainToCheck, setDomainToCheck] = useState(null);
  const [modalDomainStatus, setModalDomainStatus] = useState(null);
  const [creatingWebsite, setCreatingWebsite] = useState(false);
  const [setupDomain, setSetupDomain] = useState(false);
  const [setupDns, setSetupDns] = useState(false);
  const [landingPageDetails, setLandingPageDetails] = useState({
    project_name: "",
    domain_name: "",
  });
  const [responseDomain1, setResponseDomain1] = useState("");
  const [responseDomain2, setResponseDomain2] = useState("");

  const [screenshots, setScreenshots] = useState([]);

  // const captureAllScreenshots = (urls) => {
  //   setScreenshots([]); // Clear previous screenshots
  //   for (const url of urls) {
  //     captureScreenshot(url.domain_name);
  //   }
  // };
  useEffect(() => {
    console.log(screenshots);
  }, [screenshots]);

  useEffect(() => {
    if (domainToCheck) {
      const handleGetDomainStatus = async () => {
        try {
          const domainName = domainToCheck.replace("https://", "");
          const domainNameWithoutWWW = domainToCheck
            .replace("https://", "")
            .replace("www.", "");

          const responseDomain1 = await fetch(
            `https://api.vercel.com/v9/projects/landing-page-template/domains/${domainName}?teamId=team_eqXw3eswH4xmghXxuR7kYn01`,
            {
              headers: {
                Authorization: "Bearer 8DoSigQKDrHCYklrOAhwwBks",
              },
              method: "GET",
            }
          );
          const data1 = await responseDomain1.json();

          const responseDomain2 = await fetch(
            `https://api.vercel.com/v9/projects/landing-page-template/domains/${domainNameWithoutWWW}?teamId=team_eqXw3eswH4xmghXxuR7kYn01`,
            {
              headers: {
                Authorization: "Bearer 8DoSigQKDrHCYklrOAhwwBks",
              },
              method: "GET",
            }
          );
          const data2 = await responseDomain2.json();

          if (data1.verified === false) {
            console.log(data1.verification[0]);
            setResponseDomain1(data1.verification[0]);
          }
          if (data2.verified === false) {
            console.log(data2.verification[0]);
            setResponseDomain2(data2.verification[0]);
          }
        } catch (error) {
          console.log(error);
        }
      };
      handleGetDomainStatus();
    }
  }, [domainToCheck]);
  const [companyDomain, setCompanyDomain] = useState("");
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLandingPageDetails({ ...landingPageDetails, [name]: value });
  };
  const [allWeb, setAllWeb] = useState([]);

  useEffect(() => {
    const fetchWebsites = async () => {
      try {
        const response = await fetch(
          "https://ww1.premelo.com/api/web-builder/get-existing-web",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const json = await response.json();
        console.log(json.web);
        setAllWeb(json.web);
        // captureAllScreenshots(json.web);
      } catch (error) {
        console.log(error);
      }
    };

    fetchWebsites();
  }, []);

  const handleSubmit = async () => {
    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/web-builder/create-web-entry",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(landingPageDetails),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create/update client.");
      }

      const data = await response.json();
      console.log("Client created/updated:", data);

      const redirectUrl = `${
        window.location.origin
      }/webbuilder?domain=${encodeURIComponent(
        landingPageDetails.domain_name
      )}`;
      window.location.href = redirectUrl;
    } catch (error) {
      console.error(error);
    }
  };

  const [domainStatus, setDomainStatus] = useState([]);

  useEffect(() => {
    if (allWeb) {
      const fetchDomainStatus = async (web) => {
        const domainNameWithoutWWW = web.domain_name
          .replace("https://", "")
          .replace("www.", "");
        try {
          const response = await fetch(
            `https://api.vercel.com/v6/domains/${domainNameWithoutWWW}/config?teamId=team_eqXw3eswH4xmghXxuR7kYn01`,
            {
              headers: {
                Authorization: "Bearer 8DoSigQKDrHCYklrOAhwwBks",
              },
              method: "GET",
            }
          );
          const data = await response.json();
          return data.misconfigured;
        } catch (error) {
          console.log(error);
          return false; // or handle the error as needed
        }
      };

      const fetchAllDomainStatus = async () => {
        const promises = allWeb.map((web) => fetchDomainStatus(web));
        const verifiedStatus = await Promise.all(promises);
        console.log(verifiedStatus);
        setDomainStatus(verifiedStatus);
      };

      fetchAllDomainStatus();
    }
  }, [allWeb]);

  const handleSetupDomain = async () => {
    const domainName = companyDomain.replace("https://", "");
    const domainNameWithoutWWW = companyDomain
      .replace("https://", "")
      .replace("www.", "");

    console.log({ ...landingPageDetails, domain_name: domainName });
    setLandingPageDetails({ ...landingPageDetails, domain_name: domainName });

    try {
      await fetch(
        "https://api.vercel.com/v10/projects/landing-page-template/domains?teamId=team_eqXw3eswH4xmghXxuR7kYn01",
        {
          body: JSON.stringify({
            name: domainName,
          }),
          headers: {
            Authorization: "Bearer 8DoSigQKDrHCYklrOAhwwBks",
            "Content-Type": "application/json",
          },
          method: "POST",
        }
      );
      await fetch(
        "https://api.vercel.com/v10/projects/landing-page-template/domains?teamId=team_eqXw3eswH4xmghXxuR7kYn01",
        {
          body: JSON.stringify({
            name: domainNameWithoutWWW,
            redirect: domainName,
          }),
          headers: {
            Authorization: "Bearer 8DoSigQKDrHCYklrOAhwwBks",
            "Content-Type": "application/json",
          },
          method: "POST",
        }
      );
      const responseDomain1 = await fetch(
        `https://api.vercel.com/v9/projects/landing-page-template/domains/${domainName}?teamId=team_eqXw3eswH4xmghXxuR7kYn01`,
        {
          headers: {
            Authorization: "Bearer 8DoSigQKDrHCYklrOAhwwBks",
          },
          method: "GET",
        }
      );
      const data1 = await responseDomain1.json();

      const responseDomain2 = await fetch(
        `https://api.vercel.com/v9/projects/landing-page-template/domains/${domainNameWithoutWWW}?teamId=team_eqXw3eswH4xmghXxuR7kYn01`,
        {
          headers: {
            Authorization: "Bearer 8DoSigQKDrHCYklrOAhwwBks",
          },
          method: "GET",
        }
      );
      const data2 = await responseDomain2.json();

      if (data1.verified === false) {
        console.log(data1.verification[0]);
        setResponseDomain1(data1.verification[0]);
      }
      if (data2.verified === false) {
        console.log(data2.verification[0]);
        setResponseDomain2(data2.verification[0]);
      }
      setSetupDns(true);
    } catch (error) {
      console.log(error);
    }
  };

  const today = new Date();

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const [isTourCompleted, setIsTourCompleted] = useState(
    Cookies.get("tourCompleted") === "true"
  );
  const handleTourClose = () => {
    Cookies.set("tourCompleted", "true", { expires: 365 });
    setIsTourCompleted(true);
  };
  const dayOfWeek = days[today.getDay()];

  const day = today.getDate();

  const month = today.toLocaleString("default", { month: "long" });

  const [projectColor, setProjectColor] = useState(props.primaryColor);
  const [primaryColor, setPrimaryColor] = useState(props.primaryColor);
  const steps = [
    {
      selector: ".first-step",
      content: (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            style={{ color: primaryColor, fontSize: "22px", fontWeight: "600" }}
          >
            Welcome{" "}
          </div>
          <div style={{ color: "black", fontSize: "12px" }}>
            to your new dashboard.
          </div>
        </div>
      ),
    },
    {
      selector: ".second-step",
      content: (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            style={{ color: primaryColor, fontSize: "22px", fontWeight: "600" }}
          >
            Navigation{" "}
          </div>
          <div style={{ color: "black", fontSize: "12px" }}>
            All your available pages will always be listed in this section.
          </div>
        </div>
      ),
    },
    {
      selector: ".forth-step",
      content: (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            style={{ color: primaryColor, fontSize: "22px", fontWeight: "600" }}
          >
            Projects{" "}
          </div>
          <div style={{ color: "black", fontSize: "12px" }}>
            Let's start by creating your first project by clicking here.
          </div>
        </div>
      ),
    },
    {
      selector: ".fifth-step",
      content: (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            style={{ color: primaryColor, fontSize: "22px", fontWeight: "600" }}
          >
            Projects{" "}
          </div>
          <div style={{ color: "black", fontSize: "12px" }}>
            Your Recently created projects will appear here, likewise you can
            also use the navigation to head to your project page.
          </div>
        </div>
      ),
    },
    {
      selector: ".sixth-step",
      content: (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            style={{ color: primaryColor, fontSize: "22px", fontWeight: "600" }}
          >
            Task{" "}
          </div>
          <div style={{ color: "black", fontSize: "12px" }}>
            Your latest task will always be displayed here in the to-do list.
          </div>
        </div>
      ),
    },
    // ...
  ];
  useEffect(() => {
    setPrimaryColor(props.primaryColor);
  }, [props.primaryColor]);
  useEffect(() => {
    fetchCompanyDetails();
  }, []);

  const [companyLogo, setCompanyLogo] = useState(null);

  const fetchCompanyDetails = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/company-details",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    console.log(data.company_logo);
    if (data.company_logo) {
      setCompanyLogo(data.company_logo);
    }

    if (data.company_color) {
      setPrimaryColor(data.company_color);
    }
  };
  const dateString = `${dayOfWeek}, ${month} ${day}`;

  const [name, setName] = useState("user");

  const [userProjects, setUserProjects] = useState(props.userProjects);

  useEffect(() => {
    console.log("userprojects changed:", props.userProjects);
  }, [props.userProjects]);

  const [userTask, setUserTask] = useState([]);
  const [taskTitle, setTaskTitle] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [projectTitle, setProjectTitle] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [taskDueDate, setTaskDueDate] = useState("");
  const [taskDueTime, setTaskDueTime] = useState("");
  const [taskAssignedTo, setTaskAssignedTo] = useState("");
  const [projectAssignedTo, setProjectAssignedTo] = useState("");
  const [projectType, setProjectType] = useState("");
  const [availableUsers, setAvailableUsers] = useState([]);
  const [taskFormModalIsOpen, setTaskFormModalIsOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [projectSelectedUsers, setProjectSelectedUsers] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [projectFormModalIsOpen, setProjectFormModalIsOpen] = useState(false);

  const [existingIds, setExistingIds] = useState();

  const [selectIcon, setSelectIcon] = useState(false);
  const [projectIconSelected, setProjectIconSelected] = useState("Project1");
  const handleTaskButtonClick = async (e) => {
    e.preventDefault();
    const token = Cookies.get("token");

    const newId = nanoid(12);
    while (existingIds.includes(newId)) {
      newId = nanoid(12);
    }

    const newTask = {
      id: newId,
      task_type: "Default",
      task_assigned_to: selectedUsers,
      task_project_id: selectedProjects?.[0]?.toString() ?? "", // convert to string
      task_title: taskTitle,
      task_description: taskDescription,
      task_due_date: taskDueDate
        ? moment(taskDueDate).add(-2, "day").toISOString().substr(0, 10)
        : null, // add 1 day to date and extract date only
      task_due_time: taskDueTime
        ? taskDueTime.toISOString().substr(11, 5)
        : null, // extract time
      task_is_complete: false,
      task_checkbox: false,
    };

    try {
      const response = await fetch("https://ww1.premelo.com/api/add-task", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newTask),
      });

      if (response.ok) {
        console.log("Task added successfully");

        fetchCompanyTask();
        // Close the modal after the task has been added
        setTaskFormModalIsOpen(false);
      } else {
        console.error("Error adding task");
      }
    } catch (error) {
      console.error("Error adding task:", error);
    }
  };

  const handleProjectButtonClick = async (e) => {
    e.preventDefault();
    const token = Cookies.get("token");

    // Get the user ID based on the selected full name
    const selectedUser = availableUsers.find(
      (user) => user.fullName === taskAssignedTo
    );
    const projectAssignedToId = selectedUser ? selectedUser.id : "";

    const newProject = {
      project_assigned_to: projectSelectedUsers,
      project_name: projectTitle,
      project_description: projectDescription,
      project_type: projectType,
      project_color: projectColor,
      project_icon: projectIconSelected,
    };

    try {
      const response = await fetch("https://ww1.premelo.com/api/add-project", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newProject),
      });

      if (response.ok) {
        console.log("Project added successfully");
      } else {
        console.error("Error adding task");
      }
    } catch (error) {
      console.error("Error adding task:", error);
    }
    fetchCompanyProjects();
    setProjectFormModalIsOpen(false);
  };

  const handleChange = (value) => {
    setTaskDescription(value);
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "mention",
  ];

  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelectedUsers(value);
  };

  const handleProjectAssignChange = (event) => {
    const { value } = event.target;
    setProjectSelectedUsers(value);
  };

  const handleProjectSelectChange = (event) => {
    const { value } = event.target;
    setSelectedProjects(value);
  };

  const handleProjectTypeChange = (event) => {
    const { value } = event.target;
    setProjectType(value);
  };

  const handleSelectIcon = () => {
    setSelectIcon(true);
  };

  const projectIcon = (label) => {
    switch (label) {
      case "Project1":
        return (
          <DocumentScannerIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project2":
        return (
          <LanIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project3":
        return (
          <GroupsIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project4":
        return (
          <GroupIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project5":
        return (
          <AccessibilityNewIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project6":
        return (
          <AccountTreeIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project7":
        return (
          <AirplanemodeActiveIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project8":
        return (
          <ArchitectureIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project9":
        return (
          <AssignmentIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project10":
        return (
          <AudiotrackIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project11":
        return (
          <AccountBalanceIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project12":
        return (
          <WorkIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project13":
        return (
          <HubIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project14":
        return (
          <VolunteerActivismIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project15":
        return (
          <TerminalIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project16":
        return (
          <PaymentIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project17":
        return (
          <CameraAltIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project18":
        return (
          <ReportProblemIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project19":
        return (
          <Diversity3Icon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project20":
        return (
          <SportsEsportsIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project21":
        return (
          <LocalShippingIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project22":
        return (
          <RocketLaunchIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project23":
        return (
          <HandshakeIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project24":
        return (
          <HandymanIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project25":
        return (
          <SelfImprovementIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project26":
        return (
          <ElectricBoltIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project27":
        return (
          <EmailIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project28":
        return (
          <EscalatorWarningIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project29":
        return (
          <DescriptionIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project30":
        return (
          <DirectionsBoatIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project31":
        return (
          <BusinessIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project32":
        return (
          <CallIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project33":
        return (
          <CalculateIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project34":
        return (
          <CookieIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project35":
        return (
          <DesignServicesIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );
      case "Project36":
        return (
          <HeadsetIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "50px",
            }}
          />
        );

      default:
        return null;
    }
  };
  const projectIconResized = (label) => {
    switch (label) {
      case "Project1":
        return (
          <DocumentScannerIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project2":
        return (
          <LanIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project3":
        return (
          <GroupsIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project4":
        return (
          <GroupIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project5":
        return (
          <AccessibilityNewIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project6":
        return (
          <AccountTreeIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project7":
        return (
          <AirplanemodeActiveIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project8":
        return (
          <ArchitectureIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project9":
        return (
          <AssignmentIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project10":
        return (
          <AudiotrackIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project11":
        return (
          <AccountBalanceIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project12":
        return (
          <WorkIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project13":
        return (
          <HubIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project14":
        return (
          <VolunteerActivismIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project15":
        return (
          <TerminalIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project16":
        return (
          <PaymentIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project17":
        return (
          <CameraAltIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project18":
        return (
          <ReportProblemIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project19":
        return (
          <Diversity3Icon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project20":
        return (
          <SportsEsportsIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project21":
        return (
          <LocalShippingIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project22":
        return (
          <RocketLaunchIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project23":
        return (
          <HandshakeIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project24":
        return (
          <HandymanIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project25":
        return (
          <SelfImprovementIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project26":
        return (
          <ElectricBoltIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project27":
        return (
          <EmailIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project28":
        return (
          <EscalatorWarningIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project29":
        return (
          <DescriptionIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project30":
        return (
          <DirectionsBoatIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project31":
        return (
          <BusinessIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project32":
        return (
          <CallIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project33":
        return (
          <CalculateIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project34":
        return (
          <CookieIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project35":
        return (
          <DesignServicesIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project36":
        return (
          <HeadsetIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );

      default:
        return null;
    }
  };
  const projectIconResized1 = (label) => {
    switch (label) {
      case "Project1":
        return (
          <DocumentScannerIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project2":
        return (
          <LanIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project3":
        return (
          <GroupsIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project4":
        return (
          <GroupIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project5":
        return (
          <AccessibilityNewIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project6":
        return (
          <AccountTreeIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project7":
        return (
          <AirplanemodeActiveIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project8":
        return (
          <ArchitectureIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project9":
        return (
          <AssignmentIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project10":
        return (
          <AudiotrackIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project11":
        return (
          <AccountBalanceIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project12":
        return (
          <WorkIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project13":
        return (
          <HubIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project14":
        return (
          <VolunteerActivismIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project15":
        return (
          <TerminalIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project16":
        return (
          <PaymentIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project17":
        return (
          <CameraAltIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project18":
        return (
          <ReportProblemIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project19":
        return (
          <Diversity3Icon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project20":
        return (
          <SportsEsportsIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project21":
        return (
          <LocalShippingIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project22":
        return (
          <RocketLaunchIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project23":
        return (
          <HandshakeIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project24":
        return (
          <HandymanIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project25":
        return (
          <SelfImprovementIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project26":
        return (
          <ElectricBoltIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project27":
        return (
          <EmailIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project28":
        return (
          <EscalatorWarningIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project29":
        return (
          <DescriptionIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project30":
        return (
          <DirectionsBoatIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project31":
        return (
          <BusinessIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project32":
        return (
          <CallIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project33":
        return (
          <CalculateIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project34":
        return (
          <CookieIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project35":
        return (
          <DesignServicesIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );
      case "Project36":
        return (
          <HeadsetIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "30px",
              color:"rgba(0,0,0,0.7)"
            }}
          />
        );

      default:
        return null;
    }
  };
  const handleProjectIconSelected = (iconLabel) => {
    setProjectIconSelected(iconLabel);
    setSelectIcon(false);
  };

  const taskFormModalContent = (
    <div style={{}}>
      <form>
        <div
          style={{
            height: "700px",
            width: "100%",
            marginTop: "-10px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div style={{ width: "100%" }}>
            <div style={{ marginTop: "30px", width: "100%" }}>
              <input
                className="task-title-input"
                style={{
                  color: primaryColor,
                  fontWeight: "800",
                }}
                type="text"
                id="taskTitle"
                placeholder="Type here to change task title"
                value={taskTitle}
                onChange={(e) => setTaskTitle(e.target.value)}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: "50px",
              marginTop: "20px",
            }}
          >
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Select
                style={{ width: "100%", height: "50px" }}
                labelId="assigned-to-label"
                id="assigned-to-select"
                multiple
                value={selectedUsers}
                onChange={handleSelectChange}
                renderValue={(selected) => {
                  const selectedUsersNames = selected
                    .map((userId) => {
                      const user = availableUsers.find(
                        (user) => user.id === userId
                      );
                      return user.fullName;
                    })
                    .join(", ");
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Checkbox checked={selected.length > 0} />
                      <ListItemText primary={selectedUsersNames} />
                    </div>
                  );
                }}
              >
                {availableUsers.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    <Checkbox checked={selectedUsers.indexOf(user.id) > -1} />
                    <ListItemText primary={user.fullName} />
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginLeft: "30px",
              }}
            >
              <Select
                style={{ width: "100%", height: "50px" }}
                labelId="project-label"
                id="project-select"
                multiple
                value={selectedProjects}
                onChange={handleProjectSelectChange}
                renderValue={(selected) => {
                  const selectedProjectNames = selected
                    .map((projectId) => {
                      const project = userProjects.find(
                        (project) => project.id === projectId
                      );
                      return project.project_name;
                    })
                    .join(", ");
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Checkbox checked={selected.length > 0} />
                      <ListItemText primary={selectedProjectNames} />
                    </div>
                  );
                }}
              >
                {userProjects.map((project) => (
                  <MenuItem key={project.id} value={project.id}>
                    <Checkbox
                      checked={selectedProjects.indexOf(project.id) > -1}
                    />
                    <ListItemText primary={project.project_name} />
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
          <div
            style={{
              marginTop: "50px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: "30px",
              marginBottom: "50px",
            }}
          >
            {/* <label htmlFor="taskDueDate">Due Date:</label>
            <input
              style={{ width: "30%", borderRadius: "13px", padding: "5px" }}
              type="date"
              id="taskDueDate"
              value={taskDueDate}
              onChange={(e) => setTaskDueDate(e.target.value)}
            /> */}{" "}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={taskDueDate}
                onChange={(date) => setTaskDueDate(date)}
              />
              <TimePicker
                value={taskDueTime}
                onChange={(time) => setTaskDueTime(time)}
              />
            </LocalizationProvider>
            {/* <label htmlFor="taskDueTime">Due Time:</label>
            <input
              style={{
                width: "30%",
                marginRight: "0px",
                borderRadius: "13px",
                padding: "5px",
              }}
              type="time"
              id="taskDueTime"
              value={taskDueTime}
              onChange={(e) => setTaskDueTime(e.target.value)}
            /> */}
          </div>
          <div style={{ marginTop: "0px", width: "100%" }}>
            <label htmlFor="taskDescription">Task Description:</label>
            <ReactQuill
              value={taskDescription}
              onChange={handleChange}
              style={{ height: "300px" }}
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],
                  ["link", "image"],
                  ["clean"],
                ],
              }}
              formats={formats}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              marginTop: "auto",
            }}
          >
            <button
              style={{
                backgroundColor: primaryColor,
                color: "#ffffff",
                padding: "13px 40px",
                borderRadius: "15px",
                opacity: ".7",
              }}
              onClick={() => setTaskFormModalIsOpen(false)}
            >
              Cancel
            </button>
            <button
              style={{
                backgroundColor: primaryColor,
                color: "#ffffff",
                padding: "13px 40px",
                borderRadius: "15px",
              }}
              onClick={handleTaskButtonClick}
            >
              Add task
            </button>
          </div>
        </div>
      </form>
    </div>
  );

  const projectFormModalContent = (
    <div style={{ height: "80vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "20px",
          marginTop: "20px",
        }}
      >
        <CrossIcon
          className="mui-button"
          style={{ fontSize: "40px" }}
          onClick={() => setProjectFormModalIsOpen(false)}
        />
      </div>
      <form>
        <div
          style={{
            wdith: "100%",
          }}
        >
          {/* Left column to input */}
          <div
            style={{
              height: "100%",
              width: "90%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <div
              style={{ display: "flex", marginLeft: "20px", marginTop: "20px" }}
            >
              <div
                className="mui-button"
                style={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "20px",
                  backgroundColor: projectColor,
                  color: "white",
                  marginRight: "25px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
                onClick={handleSelectIcon}
              >
                <div style={{ marginLeft: "10px" }}>
                  {projectIcon(projectIconSelected)}
                </div>
              </div>
              <TwitterPicker
                style={{
                  width: "150px",
                  boxShadow: "none",
                  marginBottom: "-100px",
                }}
                onChange={(color, event) => setProjectColor(color.hex)}
              />
            </div>
            <div
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                width: "80%",
                marginBottom: "10px",
                marginLeft: "20px",
              }}
            >
              <div style={{ color: "rgba(0,0,0,0.4)", fontSize: "13px" }}>
                Project name:
              </div>
              <div>
                <input
                  style={{
                    height: "35px",
                    width: "105%",
                    border: "1px solid rgba(0,0,0,0.2)",
                    borderRadius: "10px",
                    padding: "0px 15px",
                  }}
                  type="text"
                  id="projectTitle"
                  value={projectTitle}
                  onChange={(e) => setProjectTitle(e.target.value)}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "end",
                marginLeft: "20px",
                marginRight: "0px",
              }}
            >
              <div style={{ width: "50%" }}>
                <div style={{ color: "rgba(0,0,0,0.4)", fontSize: "13px" }}>
                  Project type:
                </div>
                <div>
                  <Select
                    style={{
                      width: "90%",
                      height: "40px",
                      borderRadius: "10px",
                      fontSize: "14px",
                    }}
                    value={projectType}
                    onChange={handleProjectTypeChange}
                  >
                    <MenuItem value={"Creative request"}>
                      Creative request
                    </MenuItem>
                    <MenuItem value={"Team management"}>
                      Team management
                    </MenuItem>
                    <MenuItem value={"Programming setup"}>
                      Programming setup
                    </MenuItem>
                    <MenuItem value={"Leads management"}>
                      Leads management
                    </MenuItem>
                  </Select>
                </div>
              </div>
              <div style={{ width: "50%" }}>
                <button
                  className="mui-button"
                  style={{
                    backgroundColor: primaryColor,
                    color: "#ffffff",
                    fontWeight: "600",
                    padding: "13px 40px",
                    borderRadius: "15px",
                    border: "none",
                  }}
                  onClick={handleProjectButtonClick}
                >
                  Create Project
                </button>
              </div>
            </div>
          </div>
          {/* Right column to display preview */}
          <div
            style={{
              width: "100%",
              marginLeft: "0px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
              borderRadius: "20px 20px 0px 0px",
              marginTop: "20px",
              paddingBottom: "30px",
            }}
          >
            {selectIcon ? (
              <div style={{ marginTop: "50px", padding: "20px 20px 0px 20px" }}>
                <div
                  style={{
                    fontSize: "22px",
                    fontWeight: "600",
                    color: "rgba(0,0,0,0.7)",
                    borderBottom: "2px solid rgba(0,0,0,0.2)",
                    width: "70%",
                    paddingBottom: "20px",
                    marginBottom: "20px",
                  }}
                >
                  Select an Icon for your Project.
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    color: "rgba(0,0,0,0.5)",
                    width: "100%",
                    gap: "10px",
                  }}
                >
                  <DocumentScannerIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project1")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <LanIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project2")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <GroupsIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project3")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <GroupIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project4")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <AccessibilityNewIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project5")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <AccountTreeIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project6")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <AirplanemodeActiveIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project7")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <ArchitectureIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project8")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <AssignmentIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project9")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <AudiotrackIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project10")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <AccountBalanceIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project11")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <WorkIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project12")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <HubIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project13")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <VolunteerActivismIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project14")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <TerminalIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project15")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <PaymentIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project16")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <CameraAltIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project17")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <ReportProblemIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project18")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <Diversity3Icon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project19")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <SportsEsportsIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project20")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <LocalShippingIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project21")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <RocketLaunchIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project22")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <HandshakeIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project23")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <HandymanIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project24")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <SelfImprovementIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project25")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <ElectricBoltIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project26")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <EmailIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project27")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <EscalatorWarningIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project28")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <DescriptionIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project29")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <DirectionsBoatIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project30")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <BusinessIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project31")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <CallIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project32")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <CalculateIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project33")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <CookieIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project34")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <DesignServicesIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project35")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                  <HeadsetIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project36")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "50px",
                    }}
                  />
                </div>
              </div>
            ) : (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    paddingTop: "20px",
                    marginLeft: "20px",
                  }}
                >
                  <div
                    style={{
                      background: projectColor,
                      height: "60px",
                      width: "60px",
                      borderRadius: "10px",
                      color: "white",
                    }}
                  >
                    <div style={{ marginTop: "12px", marginLeft: "10px" }}>
                      {projectIconResized(projectIconSelected)}
                    </div>
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <div
                      style={{
                        fontSize: "22px",
                        fontWeight: "600",
                        color: "rgba(0,0,0,0.7)",
                        marginTop: "10px",
                      }}
                    >
                      {projectTitle ? projectTitle : "Project Name"}
                    </div>
                    <div
                      style={{
                        fontSize: "10px",
                        fontWeight: "400",
                        color: "rgba(0,0,0,0.7)",
                        marginTop: "0px",
                      }}
                    >
                      Lorem Ipsum is simply dummy text.
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginLeft: "20px",
                    width: "96%",
                    borderBottom: "1px solid rgba(0,0,0,0.1)",
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                    paddingBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "12px",
                      marginRight: "20px",
                      marginLeft: "5px",
                    }}
                  >
                    Task
                  </div>
                  <div
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "12px",
                      marginRight: "20px",
                    }}
                  >
                    Kanban
                  </div>
                  <div
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "12px",
                      marginRight: "20px",
                    }}
                  >
                    Calendar
                  </div>
                  <div
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "12px",
                      marginRight: "20px",
                    }}
                  >
                    Workflow
                  </div>
                </div>
                <div style={{ marginLeft: "30px" }}>
                  <PreviewSkeletonMobile cards={1} />
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );

  const fetchUsers = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/user-available-team-members",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    const modifiedData = data.map((user) => {
      return {
        ...user,
        fullName: `${user.first_name} ${user.last_name}`,
      };
    });
    setAvailableUsers(modifiedData);
  };

  const fetchCompanyTask = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/retrieve-company-tasks",
      {
        headers: {
          Accept: `application/json`,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    console.log("fetchcompanytask initiated"); // log the data variable
    setUserTask(data);
  };

  const openTaskFormModal = () => {
    setTaskFormModalIsOpen(true); // Show the modal
  };

  const openProjectFormModal = () => {
    setProjectFormModalIsOpen(true); // Show the modal
  };

  const fetchCompanyProjects = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/retrieve-company-projects",
      {
        headers: {
          Accept: `application/json`,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    // Extract status_options from all projects and combine them into one array
    const allStatusOptions = data.projects.flatMap((project) =>
      JSON.parse(project.status_options)
    );

    // Remove duplicates and set to setStatusOptions
    const uniqueStatusOptions = Array.from(new Set(allStatusOptions));

    setStatusOptions(uniqueStatusOptions);
    setUserProjects(data.projects);
    console.log(data.projects);
    props.onProjectListChange(data.projects);
  };

  useEffect(() => {}, [props.userProjects]);

  const handleProjectSelected = (
    projectId,
    projectName,
    projectColor,
    projectIcon
  ) => {
    console.log(projectId, projectName, projectColor, projectIcon);
    props.onProjectListSelected(
      projectId,
      projectName,
      projectColor,
      projectIcon
    );
  };

  const handleTaskCheckboxChange = async (e, taskId) => {
    const token = Cookies.get("token");
    const isComplete = e.target.checked ? 1 : 0;

    try {
      const response = await fetch(
        `https://ww1.premelo.com/api/update-task/${taskId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ task_is_complete: isComplete }),
        }
      );

      if (response.ok) {
        console.log("Task updated successfully");
        fetchCompanyTask();
      } else {
        console.error("Error updating task");
      }
    } catch (error) {
      console.error("Error updating task:", error);
    }
  };

  const fetchExistingTaskIds = async () => {
    const token = Cookies.get("token");
    const response = await fetch("https://ww1.premelo.com/api/all-task-id", {
      headers: {
        Accept: `application/json`,
        Authorization: `Bearer ${token}`,
      },
    });
    const existingIds = await response.json();
    setExistingIds(existingIds);
    // Use `existingIds` to generate new task IDs
  };

  useEffect(() => {
    // Call the fetchUsers function when the component mounts
    fetchExistingTaskIds();
    fetchUsers();
    fetchCompanyTask();
    fetchCompanyProjects();
  }, []);

  const tasksByDate = userTask.reduce((acc, task) => {
    const date = new Date(task.task_due_date);
    const dateString = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;

    if (!acc[dateString]) {
      acc[dateString] = [];
    }

    acc[dateString].push(task);

    return acc;
  }, {});

  const renderTileContent = ({ date, view }) => {
    if (view !== "month") {
      // Only render content for month view
      return null;
    }

    const dateString = `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;

    if (!tasksByDate[dateString]) {
      // No tasks for this date
      return null;
    }
    const dayOfMonth = date.getDate();
    const colors = [
      "rgba(172, 12, 95, 1)",
      "rgba(94, 12, 172, 1)",
      "#a67b0f",
      "#b32727",
      "#742491",
    ];
    const colorIndex = (dayOfMonth - 1) % colors.length;
    const color = colors[colorIndex];

    console.log("task by date", tasksByDate[dateString]);
    // Render a blue circle with a number indicating the number of tasks
    return (
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -75%)",
            borderRadius: "50%",
            width: "35px",
            height: "35px",
            background: color,
            color: "white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {dayOfMonth}
        </div>
      </div>
    );
  };
  const [currency, setCurrency] = useState("$");
  useEffect(() => {
    const getCurrencyAndShipping = async () => {
      const token = Cookies.get("token");
      const response = await fetch(
        "https://ww1.premelo.com/api/stripe/getcands",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      if (json.commerce_array) {
        if (json.commerce_array.currency) {
          if (json.commerce_array.currency.display) {
            setCurrency(json.commerce_array.currency.display);
          }
        }
      }
    };
    getCurrencyAndShipping();
  }, []);

  const [orders, setOrders] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [activeOrder, setActiveOrder] = useState(null);
  const [tracking, setTracking] = useState("");
  const [notes, setNotes] = useState({ message: "", created_at: "", time: "" });

  function formatDate(inputDate) {
    // Parse the input date string
    const date = new Date(inputDate);

    // Define month names
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Extract day, month, and year
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    // Format the date as "DD Mon, YYYY"
    const formattedDate = `${day} ${monthNames[month]}, ${year}`;

    return formattedDate;
  }

  function formatTime(inputTime) {
    // Split the input time string into hours and minutes
    const [hourStr, minuteStr] = inputTime.split(":");

    // Convert hours and minutes to integers
    const hours = parseInt(hourStr, 10);
    const minutes = parseInt(minuteStr, 10);

    // Determine the period (am or pm)
    const period = hours >= 12 ? "pm" : "am";

    // Format the hours in 12-hour format
    const formattedHours = hours % 12 || 12; // Handle midnight (0) as 12

    // Format the time as "h:mm am/pm"
    const formattedTime = `${formattedHours}.${minutes
      .toString()
      .padStart(2, "0")} ${period}`;

    return formattedTime;
  }

  function formatTimes(inputTime) {
    if (!(inputTime instanceof Date)) {
      return "Invalid Time";
    }

    // Get hours and minutes from the Date object
    const hours = inputTime.getHours();
    const minutes = inputTime.getMinutes();

    // Determine the period (am or pm)
    const period = hours >= 12 ? "pm" : "am";

    // Format the hours in 12-hour format
    const formattedHours = hours % 12 || 12; // Handle midnight (0) as 12

    // Format the time as "h:mm am/pm"
    const formattedTime = `${formattedHours}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;

    return formattedTime;
  }

  const handleUpdateOrderNotes = async (notes) => {
    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/update-order-notes",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            order_id: activeOrder.id,
            order_notes: notes,
          }),
        }
      );
      const json = await response.json();
      console.log(json);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateOrderTracking = async (notes) => {
    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/update-order-tracking",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            order_id: activeOrder.id,
            tracking: notes,
          }),
        }
      );
      const json = await response.json();
      console.log(json);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateOrderStatus = async (stat) => {
    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/update-order-status",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            order_id: activeOrder.id,
            status: stat,
          }),
        }
      );
      const json = await response.json();
      console.log(json);
    } catch (error) {
      console.log(error);
    }
  };

  const AddNotes = (e) => {
    const orderIndex = orders.findIndex((order) => order.id === activeOrder.id);
    const updatingOrders = [...orders];
    const updatingActiveOrder = { ...activeOrder };
    const updatingNote = { ...notes };
    updatingNote.created_at = formatDate(new Date());
    updatingNote.time = formatTimes(new Date());

    if (updatingOrders[orderIndex].order_notes) {
      updatingOrders[orderIndex].order_notes.unshift(updatingNote);
      updatingActiveOrder.order_notes.unshift(updatingNote);
    } else {
      updatingOrders[orderIndex].order_notes = [updatingNote];
      updatingActiveOrder.order_notes = [updatingNote];
    }
    handleUpdateOrderNotes(updatingOrders[orderIndex].order_notes);
    setActiveOrder(updatingActiveOrder);
    setOrders(updatingOrders);
    setNotes({ message: "", created_at: "" });
  };

  const addTracking = (e) => {
    const orderIndex = orders.findIndex((order) => order.id === activeOrder.id);
    const updatingOrders = [...orders];
    const updatingActiveOrder = { ...activeOrder };

    if (updatingOrders[orderIndex].tracking) {
      updatingOrders[orderIndex].tracking = tracking;
      updatingActiveOrder.tracking = tracking;
    } else {
      updatingOrders[orderIndex].tracking = tracking;
      updatingActiveOrder.tracking = tracking;
    }
    handleUpdateOrderTracking(updatingOrders[orderIndex].tracking);
    setActiveOrder(updatingActiveOrder);
    setOrders(updatingOrders);
    setNotes({ message: "", created_at: "" });
  };

  function isSameDay(date1, date2) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  const show = ({ date, view }) => {
    if (view === "month") {
      const eventOnDate = orders.some((order) =>
        isSameDay(date, new Date(order.created_at))
      );

      if (eventOnDate) {
        return (
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <div
              style={{
                marginTop: "3px",
                background: props.primaryColor,
                height: "6px",
                width: "6px",
                borderRadius: "50%",
              }}
            />
          </div>
        );
      }
    }
    return null;
  };

  function isWithinRange(date, startDate, endDate) {
    return date >= startDate && date <= endDate;
  }

  function isTheSameDay(date1, date2) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  const filterEventsByDate = () => {
    console.log("Events:", orders);
    console.log("Date Range:", dateRange);

    if (!dateRange) {
      // If dateRange is null, return all orders
      console.log(orders);
      return orders;
    } else if (dateRange[1] === null) {
      // If dateRange has one date and the second date is null
      const selectedDate = dateRange[0];

      return orders.filter((order) => {
        // Assuming the event.date property is a JavaScript Date object
        console.log(isTheSameDay(new Date(order.created_at), selectedDate));
        return isTheSameDay(new Date(order.created_at), selectedDate);
      });
    } else if (dateRange.length === 2) {
      // If dateRange has two dates, representing a date range
      const startDate = dateRange[0];
      const endDate = dateRange[1];

      return orders.filter((order) => {
        // Assuming the event.date property is a JavaScript Date object
        console.log(
          isWithinRange(new Date(order.created_at), startDate, endDate)
        );
        return isWithinRange(new Date(order.created_at), startDate, endDate);
      });
    } else {
      // Invalid dateRange, return an empty array or handle as needed
      console.log("Invalid dateRange");
      return [];
    }
  };

  const filteredOrders = filterEventsByDate()
    ? filterEventsByDate().filter((order) => {
        const status = order.status || ""; // Default to an empty string if tags is null

        const tagssAndCategories = [...status.split(" | ")];

        // Check if selectedFilters is null or empty
        if (!selectedFilters || selectedFilters.length === 0) {
          return true; // Return true if no filters are selected
        }

        // Use selectedFilters.some only if it's not null or empty
        return selectedFilters.some((filter) =>
          tagssAndCategories.includes(filter)
        );
      })
    : [];

  const searchedOrders = searchQuery
    ? filteredOrders
      ? filteredOrders.filter((event) => {
          const { customer_name } = event;
          const lowerCaseSearchQuery = searchQuery.toLowerCase();

          return (
            customer_name &&
            customer_name.toLowerCase().includes(lowerCaseSearchQuery)
          );
        })
      : []
    : filteredOrders;

  const getOrders = async () => {
    try {
      const response = await fetch("https://ww1.premelo.com/api/get-orders", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const json = await response.json();
      setOrders(json.orders);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrders();
  }, []);

  const handleFilterChange = (filter) => {
    if (selectedFilters.includes(filter)) {
      // Filter is already selected, remove it
      setSelectedFilters(selectedFilters.filter((f) => f !== filter));
    } else {
      // Filter is not selected, add it
      setSelectedFilters([...selectedFilters, filter]);
    }
  };

  const calculateOrderAmount = (orderss) => {
    let amount = 0;
    for (const order of orderss) {
      amount += parseFloat(order.quantity) * parseFloat(order.unit_price);
    }
    return amount;
  };

  let Country = require("country-state-city").Country;
  let State = require("country-state-city").State;

  const getCountryName = (country) => {
    const countryIndex = Country.getAllCountries().findIndex(
      (countries) => countries.isoCode === country
    );
    return Country.getAllCountries()[countryIndex].name;
  };

  const getStateName = (country, statecode) => {
    const stateIndex = State.getStatesOfCountry(country).findIndex(
      (state) => state.isoCode === statecode
    );

    return State.getStatesOfCountry(country)[stateIndex].name;
  };

  const customHeaderClassName = "custom-header"; // Custom class name for header tile

  return (
    <div style={{ marginTop: "-70px", paddingBottom: "20px" }}>
      {!isTourCompleted && (
        <Tour
          steps={steps}
          isOpen={true}
          onRequestClose={handleTourClose}
          className="my-tour"
        />
      )}
      <div>
        <img
          src={companyLogo ? companyLogo : header}
          style={{
            width: "100%",
            height: "100px",
            objectFit: "cover",
            marginBottom: "0px",
            opacity: 0.7
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          background: "white",
          justifyContent: "center",
          alignItems: "center",
          width: "60px",
          height: "60px",
          borderRadius: "50%",
          marginLeft: "15px",
          overflow: "hidden",
          position: "relative",
          zIndex: "2",
          marginTop: "-40px",
          border:"3px solid white"
        }}
      >
        <img
          src={profilePhoto ? profilePhoto : DefaultProfile}
          // src={DefaultProfile}
          style={{
            width: "60px",
            height: "60px",
            objectFit: "cover",
            marginBottom: "0px",
          }}
        />
      </div>
      <div
        style={{
          background: "white",
          position: "relative",
          zIndex: "1",
          marginTop: "-30px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            paddingTop: "30px",
          }}
        >
          <p
            style={{
              marginTop: "5px",
              marginLeft: "15px",
              fontSize: "30px",
              fontWeight: "600",
              marginBottom: "0px",
              color: "rgba(0,0,0,0.8)",
            }}
          >
            Dashboard
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            marginLeft: "17px",
          }}
        >
          <p
            style={{
              marginTop: "0px",
              fontSize: "12px",
              color: "rgba(0,0,0,0.8)",
            }}
          >
            Welcome, @{name}
            {/* ,{dateString} */}
          </p>
        </div>
      </div>
      {/* Two new columns */}
      <div
        style={{
          boxShadow: "0px 0px 10px rgba(255,255,255,0.1)",
          background: "white",
          borderRadius: "10px",
          marginTop: "20px",
          width: "94%",
          marginLeft: "3%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "-10px",
          }}
        >
          <h2
            style={{
              marginTop: "25px",
              color: "rgba(0,0,0,0.6)",
              fontWeight: "600",
              fontSize: "18px",
              marginLeft: "20px",
            }}
          >
            Recently used
          </h2>
          <AddCircleIcon
            className="forth-step"
            onClick={openProjectFormModal}
            style={{
              marginTop: "0px",
              fontSize: "30px",
              marginRight: "20px",
              color: primaryColor,
            }}
          />
        </div>
        <div
          className="fifth-step"
          style={{
            display: "flex",
            overflowX: "auto",
            overflowY: "hidden",
            width: "100%",
            height: "200px",
          }}
        >
          {userProjects.slice(0, 5).map((project, index) => {
            const projectTasks = userTask.filter(
              (task) => task.task_project_id === project.id
            );
            console.log("projecttasks", projectTasks);
            const userIds = projectTasks
              .map((task) => task.task_assigned_to)
              .reduce((acc, val) => acc.concat(val), []);
            console.log("userIds", userIds);
            const projectUsers = availableUsers.filter((user) =>
              userIds.includes(user.user_id)
            );
            console.log("available users", availableUsers);
            console.log("projectUsers", projectUsers);
            return (
              <div
                style={{ padding: "10px" }}
                className="folder-icon"
                key={project.id}
                onClick={() =>
                  handleProjectSelected(
                    project.id,
                    project.project_name,
                    project.project_color,
                    project.project_icon
                  )
                }
              >
                <div className="child-div-1" style={{ marginLeft: "10px" }}>
                  <div style={{ width: "90%" }}>
                    <div className="folder-icon-icon" style={{color:"rgba(0,0,0,0.7)", marginTop:"-10px"}}>
                      {projectIconResized1(project.project_icon)}
                    </div>
                    <h2
                      style={{
                        marginTop: "0px",
                        fontWeight: "400",
                        fontSize: "10px",
                        marginBottom: "-3px",
                      }}
                    >
                      Project name:
                    </h2>
                    <span
                      style={{
                        fontWeight: "800",
                        fontSize: "15px",
                        marginTop: "-20px",
                        lineHeight: "12px",
                      }}
                    >
                      {project.project_name
                        ? project.project_name.slice(0, 30) +
                          (project.project_name.length > 30 ? " ..." : "")
                        : "Untitled"}
                    </span>
                    <div style={{ marginTop: "10px" }}>
                      {projectUsers.map((user) => (
                        <div
                          style={{
                            backgroundColor: user.user_color,
                            color: "white",
                            display: "inline-flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "35px",
                            height: "35px",
                            borderRadius: "50%",
                            marginRight: "5px",
                            fontWeight: "600",
                            fontSize: "16px",
                            border: "2px solid #ffffff",
                            marginRight: "-11px",
                          }}
                          key={user.user_id}
                        >
                          <p style={{ fontWeight: "400", fontSize: "13px" }}>
                            {user.first_name.charAt(0)}
                            {user.last_name.charAt(0)}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <FolderIcon
                  style={{
                    fontSize: "210px",
                    width: "210px",
                    marginLeft: "10px",
                    marginRight: "-10px",
                  }}
                />
              </div>
            );
          })}
          <div
            style={{
              padding: "10px",
              opacity: 0.5,
            }}
            className="folder-greyed"
            onClick={openProjectFormModal}
            key={`gray-folder`}
          >
            <div
              className="child-div-1"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="project-greyed-icon"
                style={{ marginRight: "10px", marginTop: "-20px" }}
              >
                <AddCircleIcon className="add-circle-icon" />
              </div>
            </div>
            <FolderIcon
              style={{
                fontSize: "210px",
                width: "210px",
                marginLeft: "10px",
                marginRight: "-10px",
              }}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          width: "94%",
          marginLeft: "3%",
          borderRadius: "10px",
          background: "white",
          marginTop: "10px",
          boxShadow: "0px 0px 10px rgba(255,255,255,0.1)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "-10px",
          }}
        >
          <h2
            style={{
              color: "rgba(0,0,0,0.6)",
              fontWeight: "600",
              fontSize: "18px",
              marginLeft: "20px",
            }}
          >
            Orders Today
          </h2>
        </div>
        <div
          style={{ height: "350px", overflowY: "auto", overflowX: "hidden" }}
        >
          {searchedOrders.length > 0 ? (
            searchedOrders
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .map((order, index) => (
                <div
                  onClick={() => {
                    setActiveOrder(order);
                    setTracking(order.tracking ? order.tracking : "");
                  }}
                  className="mui-button"
                  key={index}
                  style={{
                    width: "calc(100% - 20px)",
                    textAlign: "center",
                    position: "relative",
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "7px 10px",
                    borderBottom: "1px solid lightgrey",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "55%",
                    }}
                  >
                    <div
                      style={{
                        color: "grey",
                        fontWeight: 600,
                        fontSize: "12px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {order.customer_name ? order.customer_name : "Guest"}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "45%",
                    }}
                  >
                    <div
                      style={{
                        color: "grey",
                        fontWeight: 400,
                        fontSize: "9px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CalendarMonthIcon
                        style={{
                          fontSize: "14px",
                          marginRight: "5px",
                          color: "grey",
                        }}
                      />
                      {formatDate(new Date(order.created_at))}
                    </div>
                    <div
                      style={{
                        color: "rgba(0,0,0,0.8)",
                        fontWeight: 600,
                        fontSize: "12px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {currency +
                        " " +
                        calculateOrderAmount(order.order_items).toLocaleString(
                          "en-US",
                          {
                            style: "decimal",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <div
              style={{
                textAlign: "center",
                color: "rgba(0,0,0,0.3)",
                width: "100%",
                fontSize: "20px",
                padding: "50px 0px",
              }}
            >
              <div>No Orders </div>
              <div style={{ padding: "0px 40px", fontSize: "12px" }}>
                You currently have no orders, but once you do, they'll appear
                here where you can update status to clients, check order details
                and so on.
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          width: "94%",
          marginLeft: "3%",
          borderRadius: "10px",
          background: "white",
          marginTop: "10px",
          boxShadow: "0px 0px 10px rgba(255,255,255,0.1)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "-10px",
          }}
        >
          <h2
            style={{
              color: "rgba(0,0,0,0.6)",
              fontWeight: "600",
              fontSize: "18px",
              marginLeft: "20px",
            }}
          >
            Website status
          </h2>
        </div>

        <div
          style={{ height: "350px", overflowY: "auto", overflowX: "hidden" }}
        >
          {allWeb.length > 0 ? (
            allWeb.map((web, index) => (
              <div
                key={index}
                className="create_web_hover"
                style={{
                  width: "94%",
                  marginLeft: "3%",
                  height: "300px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "white",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                  borderRadius: "12px",
                  position: "relative",
                  overflow: "hidden",
                  marginTop: "15px",
                }}
              >
                <iframe
                  src={`https://${web.domain_name}`}
                  title="Embedded Website"
                  width="1920px"
                  height="1920px" // Adjust the height as needed
                  style={{
                    overflow: "hidden",
                    width: "1920px",
                    height: "1920px",
                    scale: "0.22",
                    position: "absolute",
                    top: "-750px",
                    zIndex: 0,
                  }}
                ></iframe>
                <div
                  className="mui-button"
                  style={{
                    background: "rgba(255,255,255,0.2)",
                    zIndex: 5,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    height: "100%",
                    width: "100%",
                  }}
                ></div>
                <div
                  // className="create_web_hover_text"
                  style={{
                    position: "absolute",
                    width: "calc(100% - 20px)",
                    bottom: 0,
                    left: 0,
                    padding: "10px",
                    zIndex: 499,
                    borderTop: "1px solid lightgrey",
                    background: "white",
                    borderRadius: "0px 0px 10px 10px",
                  }}
                >
                  <div
                    className="blink"
                    style={{
                      position: "absolute",
                      top: 15,
                      right: 15,
                      borderRadius: "50%",
                      background: domainStatus[index] ? "red" : "green",
                      height: "8px",
                      width: "8px",
                      boxShadow: domainStatus[index]
                        ? "0px 0px 7px red"
                        : "0px 0px 7px green",
                    }}
                  >
                    {/* {domainStatus[index]
                      ? domainStatus[index].toString()
                      : null} */}
                  </div>
                  <div
                    style={{
                      color: props.primaryColor,
                      fontSize: "18px",
                      fontWeight: 700,
                    }}
                  >
                    {web.project_name}
                  </div>
                  <div
                    style={{
                      color: "grey",
                      fontSize: "10px",
                      fontWeight: 400,
                    }}
                  >
                    {web.domain_name}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div
              style={{
                textAlign: "center",
                color: "rgba(0,0,0,0.3)",
                padding: "50px 0px",
                width: "100%",
                fontSize: "20px",
              }}
            >
              <div>No Website Created</div>
              <div style={{ padding: "0px 40px", fontSize: "12px" }}>
                To start creating your website head over to the desktop site
                where you can click on the web builder on the menu and you'll be
                on your way to creating your first website.
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={taskFormModalIsOpen}
        onRequestClose={() => setTaskFormModalIsOpen(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "700px",
            height: "720px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            borderRadius: "10px",
          },
        }}
      >
        {taskFormModalContent}
      </Modal>
      <Modal
        isOpen={projectFormModalIsOpen}
        onRequestClose={() => setProjectFormModalIsOpen(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 400,
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            height: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            borderRadius: "10px",
            zIndex: 410,
          },
        }}
      >
        {projectFormModalContent}
      </Modal>
    </div>
  );
};
export default DashContentMobile;
