import React, { useState, useEffect } from "react";
import logo from "./images/CS_Favicon-01.png";
import { useNavigate } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { fallDown as Menu } from "react-burger-menu";
import Hamburger from "hamburger-react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const PricingMobile = () => {
  const navigate = useNavigate();
  const [primaryColor, setPrimaryColor] = useState("#5AB1BA");
  const [isOpen, setIsOpen] = useState(false);

  function handleToggleMenu() {
    setIsOpen(!isOpen);
  }

  return (
    <div id="outer-container" style={{ width: "100%", overflowX: "hidden" }}>
      <Menu
        right
        width={"100%"}
        isOpen={isOpen}
        customCrossIcon={false}
        customBurgerIcon={
          <Hamburger toggled={isOpen} toggle={handleToggleMenu} size={24} />
        }
      >
        <div>
          <div
            onClick={() => {
              navigate("/");
              window.scrollTo(0, 0);
            }}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              style={{
                color: "rgba(0,0,0,0.7)",
                textDecoration: "none",
                padding: "5px 0px",
              }}
            >
              Home
            </div>
            <NavigateNextIcon />
          </div>
          <hr
            style={{
              width: "100%",
              height: "0.01rem",
            }}
          />
          <div
            onClick={() => setIsOpen(false)}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              style={{
                color: "rgba(0,0,0,0.7)",
                textDecoration: "none",
                padding: "5px 0px",
              }}
            >
              Our Pricing
            </div>
            <NavigateNextIcon />
          </div>
          <hr
            style={{
              width: "100%",
              height: "0.01rem",
            }}
          />
          <div
            onClick={() => {
              navigate("/faq");
              window.scrollTo(0, 0);
            }}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              style={{
                color: "rgba(0,0,0,0.7)",
                textDecoration: "none",
                padding: "5px 0px",
              }}
            >
              Frequently Asked Questions
            </div>
            <NavigateNextIcon />
          </div>
          <hr
            style={{
              width: "100%",
              height: "0.01rem",
            }}
          />
          <div
            onClick={() => {
              navigate("/contactus");
              window.scrollTo(0, 0);
            }}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              style={{
                color: "rgba(0,0,0,0.7)",
                textDecoration: "none",
                padding: "5px 0px",
              }}
            >
              Contact Us
            </div>
            <NavigateNextIcon />
          </div>
          <hr
            style={{
              width: "100%",
              height: "0.01rem",
            }}
          />
          <div
            onClick={() => {
              navigate("/termsandcondition");
              window.scrollTo(0, 0);
            }}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              style={{
                color: "rgba(0,0,0,0.7)",
                textDecoration: "none",
                padding: "5px 0px",
              }}
            >
              Terms & Privacy
            </div>
            <NavigateNextIcon />
          </div>
          <div
            style={{
              width: "90%",
              textAlign: "center",
              padding: "15px 20px",
              background: "#d81b5e",
              color: "white",
              marginTop: "35px",
            }}
            onClick={() => {
              navigate("/login");
              window.scrollTo(0, 0);
            }}
          >
            Login
          </div>
          <div
            style={{
              width: "90%",
              textAlign: "center",
              padding: "15px 20px",
              border: "1px solid #d81b5e",
              color: "#d81b5e",
              marginTop: "15px",
            }}
            onClick={() => {
              navigate("/signup");
              window.scrollTo(0, 0);
            }}
          >
            Sign Up
          </div>
        </div>
      </Menu>
      <div style={{ width: "100%" }}>
        <div
          style={{
            background: "white",
            position: "fixed",
            zIndex: "2000",
            top: 0,
            height: "7vh",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
          }}
        >
          <div
            onClick={() => {
              navigate("/");
              window.scrollTo(0, 0);
            }}
            style={{
              height: "6vh",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "1px 0px",
              marginLeft: "20px",
            }}
          >
            <img src={logo} alt="Premelo logo" style={{ width: "50px" }} />
            <p
              style={{
                fontSize: "25px",
                fontWeight: "600",
                color: "black",
                marginTop: "18px",
              }}
            >
              Premelo
            </p>
          </div>
        </div>
        {/* first segment */}
        <div
          style={{
            padding: "50px 0px",
            textAlign: "center",
            background: "#d5dee0",
            marginTop: "50px",
          }}
        >
          <div style={{ fontSize: "12px", color: "rgba(0,0,0,0.6)" }}>
            Pricing
          </div>
          <div style={{ fontSize: "35px", fontWeight: "600" }}>
            Revolutionize Your Workflow. Start Free.
          </div>
          <div style={{ fontSize: "12px", fontWeight: "300" }}>
            Experience the power of Premelo's work management platform for free
            - no charge or commitment
          </div>
          <div style={{ fontSize: "12px", fontWeight: "300" }}>
            necessary. Upgrade to Premelo Plus for even more features.
          </div>
          <button
            style={{
              color: "white",
              background: "rgba(0,0,0,1)",
              padding: "10px 35px",
              borderRadius: "25px",
              fontSize: "17px",
              fontWeight: "600",
              marginTop: "20px",
            }}
            className="mui-button-home"
            onClick={() => {
              navigate("/signup");
              window.scrollTo(0, 0);
            }}
          >
            Get started
          </button>
        </div>
        {/* pricing table */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            fontWeight: "300",
          }}
        >
          <div
            style={{
              width: "100%",
              margin: "20px 0px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "12px",
              }}
            >
              <div
                style={{
                  width: "30%",
                  border: "0.01rem solid #d4d4d4",
                  borderRadius: "15px 0px 0px 0px",
                  padding: "10px",
                }}
              ></div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                }}
              >
                <div style={{ fontSize: "18px", fontWeight: "500" }}>Lite</div>
                <div>Perfect for managing</div>
                <div style={{ marginTop: "-5px" }}>teams.</div>
                <hr
                  style={{
                    width: "10%",
                    height: "0.02rem",
                    backgroundColor: "#a9b5b8",
                    border: "none",
                    margin: "10px auto",
                  }}
                />
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    marginTop: "10px",
                  }}
                >
                  Free
                </div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                }}
              >
                <div style={{ fontSize: "18px", fontWeight: "500" }}>Plus</div>
                <div>Scalable pricing for</div>
                <div style={{ marginTop: "-5px" }}>any team sizes.</div>
                <hr
                  style={{
                    width: "10%",
                    height: "0.02rem",
                    backgroundColor: "#a9b5b8",
                    border: "none",
                    margin: "10px auto",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ marginTop: "10px", marginRight: "5px" }}>
                    US$
                  </div>
                  <div
                    style={{
                      fontSize: "30px",
                      fontWeight: "600",
                      marginTop: "0px",
                    }}
                  >
                    12
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  borderRadius: "0px 15px 0px 0px",
                  padding: "10px",
                }}
              >
                <div style={{ fontSize: "18px", fontWeight: "500" }}>
                  Enterprise
                </div>
                <div>Get the full Premelo</div>
                <div style={{ marginTop: "-5px" }}>with teams {">"} 100</div>
                <hr
                  style={{
                    width: "10%",
                    height: "0.02rem",
                    backgroundColor: "#a9b5b8",
                    border: "none",
                    margin: "10px auto",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ marginTop: "10px", marginRight: "5px" }}>
                    US$
                  </div>
                  <div
                    style={{
                      fontSize: "30px",
                      fontWeight: "600",
                      marginTop: "0px",
                    }}
                  >
                    7
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "12px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "30%",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  height: "3vh",
                }}
              >
                <div>Custom Domain</div>
                <div>
                  <InfoIcon
                    style={{ fontSize: "18px" }}
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html="Connect your custom domain and allow your team, <br /> collaborators, and customers to access from your domain."
                  />
                  <Tooltip id="my-tooltip" place="right" />
                </div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div>-</div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "12px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "30%",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  height: "3vh",
                }}
              >
                <div>Remove Premelo Branding</div>
                <div>
                  <InfoIcon
                    style={{ fontSize: "18px" }}
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html="Allows users to change and automatically removes <br /> Premelo branding on dashboard."
                  />
                  <Tooltip id="my-tooltip" place="right" />
                </div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div>-</div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "12px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "30%",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  height: "3vh",
                }}
              >
                <div>Customized Platform</div>
                <div>
                  <InfoIcon
                    style={{ fontSize: "18px" }}
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html="Provides admins with Premelo's fully customizable <br /> features and tools to brand the dashboard theme."
                  />
                  <Tooltip id="my-tooltip" place="right" />
                </div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div>-</div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "12px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "30%",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  height: "3vh",
                }}
              >
                <div>Free SSL Certificate</div>
                <div>
                  <InfoIcon
                    style={{ fontSize: "18px" }}
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html="Users that connect their domain will be provided a <br /> free SSL Certificate making their website appear as <br/> secured."
                  />
                  <Tooltip id="my-tooltip" place="right" />
                </div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div>-</div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "12px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "30%",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  height: "3vh",
                }}
              >
                <div>Team Size</div>
                <div>
                  <InfoIcon
                    style={{ fontSize: "18px" }}
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html="Users allowed on the platform are decided base on the <br /> paackage subscribed."
                  />
                  <Tooltip id="my-tooltip" place="right" />
                </div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div>Limited to 8 Users</div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div>Up to 99 Users</div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div>Unlimited</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "12px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "30%",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  height: "3vh",
                }}
              >
                <div>Task Management Tools</div>
                <div>
                  <InfoIcon
                    style={{ fontSize: "18px" }}
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html="Premelo Task Management Tools comes free with any <br /> subscription, allowing anybody and everybody to utilize <br/> our tools."
                  />
                  <Tooltip id="my-tooltip" place="right" />
                </div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "12px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "30%",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  height: "3vh",
                }}
              >
                <div>Customized Workflow Building</div>
                <div>
                  <InfoIcon
                    style={{ fontSize: "18px" }}
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html="Premelo's customized workflow building allows <br /> users to create flows of task as if operational in their <br/> own company."
                  />
                  <Tooltip id="my-tooltip" place="right" />
                </div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "12px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "30%",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  height: "3vh",
                }}
              >
                <div>File Repositories</div>
                <div>
                  <InfoIcon
                    style={{ fontSize: "18px" }}
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html="As you would use any platform for file storage, you <br /> can now use Premelo's fast and effective file storage to store <br/> and retrieve your files quickly and swiftly."
                  />
                  <Tooltip id="my-tooltip" place="right" />
                </div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div>Unlimited</div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div>Unlimited</div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div>Unlimited</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "12px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "30%",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  height: "3vh",
                }}
              >
                <div>Access Control</div>
                <div>
                  <InfoIcon
                    style={{ fontSize: "18px" }}
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html="Control what your team sees and have access <br /> to in your platform."
                  />
                  <Tooltip id="my-tooltip" place="right" />
                </div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div>-</div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "12px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "30%",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  height: "3vh",
                }}
              >
                <div>Social Media Connect</div>
                <div>
                  <InfoIcon
                    style={{ fontSize: "18px" }}
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html="Connect your company's social media platforms <br /> allowing your marketing team to have publishing tools access <br/> all in one platform."
                  />
                  <Tooltip id="my-tooltip" place="right" />
                </div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div>-</div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                fontSize: "12px",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "30%",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  height: "3vh",
                }}
              >
                <div>Centralized Messaging</div>
                <div>
                  <InfoIcon
                    style={{ fontSize: "18px" }}
                    data-tooltip-id="my-tooltip"
                    data-tooltip-html="Provides your social media team access all in one place <br /> to reply to facebook, instagram and whatsapp messages on behalf <br/> of the company."
                  />
                  <Tooltip id="my-tooltip" place="right" />
                </div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div>-</div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
              </div>
              <div
                style={{
                  width: "23%",
                  textAlign: "center",
                  border: "0.01rem solid #d4d4d4",
                  padding: "10px",
                  height: "3vh",
                }}
              >
                <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
              </div>
            </div>
          </div>
        </div>

        {/* Website Footer */}
        <hr
          style={{
            width: "100%",
            height: "0.02rem",
            backgroundColor: "black",
            border: "none",
            margin: "10px auto",
          }}
        />
        <div
          style={{
            width: "100%",
            padding: "10px 0px 20px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", width: "30%", marginLeft: "10%" }}>
            <FacebookIcon
              className="mui-button"
              style={{ fontSize: "20px", marginRight: "15px" }}
              onClick={() =>
                (window.location.href = "https://www.facebook.com/premeloapp")
              }
            />
            <InstagramIcon
              className="mui-button"
              style={{ fontSize: "20px", marginRight: "15px" }}
              onClick={() =>
                (window.location.href =
                  "https://www.instagram.com/premelo_app/")
              }
            />
            <YouTubeIcon
              className="mui-button"
              style={{ fontSize: "20px", marginRight: "15px" }}
              onClick={() =>
                (window.location.href = "https://www.youtube.com/@Premelo-Inc")
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "70%",
              fontSize: "16px",
              fontWeight: "300",
              textAlign: "right",
              justifyContent: "flex-end",
              marginRight: "10%",
            }}
          >
            <div>© 2023 Premelo, Inc.</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PricingMobile;
