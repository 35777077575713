import React, { useState, useEffect } from "react";
import logo from "./images/CS_Favicon-01.png";
import { useNavigate } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import InfoIcon from "@mui/icons-material/Info";

const CentralizedMessaging = () => {
  const navigate = useNavigate();
  const [primaryColor, setPrimaryColor] = useState("#5AB1BA");
  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          height: "7vh",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "10px",
        }}
      >
        <div
          style={{
            height: "6vh",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "1px 0px",
            marginLeft: "40px",
          }}
        >
          <img src={logo} alt="Premelo logo" style={{ width: "42px" }} />
          <p
            style={{
              fontSize: "20px",
              fontWeight: "600",
              color: "black",
            }}
          >
            Premelo
          </p>
        </div>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <button
            onClick={() => navigate("/homepage")}
            className="homepage-nav-buttons"
            style={{
              height: "4.5vh",
              padding: "0px 20px",
              borderRadius: "50px",
              border: "none",
              background: "transparent",
              fontWeight: "600",
              fontSize: "12px",
            }}
          >
            Home
          </button>
          <button
            onClick={() => navigate("/faq")}
            className="homepage-nav-buttons"
            style={{
              height: "4.5vh",
              padding: "0px 20px",
              borderRadius: "50px",
              border: "none",
              background: "transparent",
              fontWeight: "600",
              fontSize: "12px",
            }}
          >
            FAQ
          </button>
          <button
            onClick={() => navigate("/contactus")}
            className="homepage-nav-buttons"
            style={{
              height: "4.5vh",
              padding: "0px 20px",
              borderRadius: "50px",
              border: "none",
              background: "transparent",
              fontWeight: "600",
              fontSize: "12px",
            }}
          >
            Contact
          </button>
          <button
            onClick={() => navigate("/termsandcondition")}
            className="homepage-nav-buttons"
            style={{
              height: "4.5vh",
              padding: "0px 20px",
              borderRadius: "50px",
              border: "none",
              background: "transparent",
              fontWeight: "600",
              fontSize: "12px",
            }}
          >
            Terms & Privacy
          </button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "40px",
          }}
        >
            <button
              className="homepage-nav-side-2"
              style={{
                marginRight: "15px",
                height: "4.5vh",
                padding: "0px 20px",
                borderRadius: "50px",
                border: "none",
                fontSize: "12px",
              }}
              onClick={() => {
                navigate("/signup");
                window.scrollTo(0, 0);
              }}
            >
              Sign Up
            </button>
            <button
              className="homepage-nav-side-1"
              style={{
                height: "4.5vh",
                padding: "0px 30px",
                borderRadius: "50px",
                border: "none",
                fontSize: "12px",
              }}
              onClick={() => {
                navigate("/login");
                window.scrollTo(0, 0);
              }}
            >
              Login
            </button>
        </div>
      </div>

      {/* first segment */}
      <div
        style={{
          padding: "50px 0px",
          textAlign: "center",
          background: "#d5dee0",
        }}
      >
        <div style={{ fontSize: "12px", color: "rgba(0,0,0,0.6)" }}>
          Product
        </div>
        <div style={{ fontSize: "35px", fontWeight: "600" }}>
          Centralized Messaging
        </div>
        <div style={{ fontSize: "12px", fontWeight: "300" }}>
          Got more questions on our product? Head over to our contact page and
          reach out to our agents for a
        </div>
        <div style={{ fontSize: "12px", fontWeight: "300" }}>
          more personalized experience.
        </div>
      </div>
      {/* Information */}
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <div
          style={{
            width: "50%",
            fontWeight: "300",
            fontSize: "12px",
            padding: "40px 0px",
          }}
        >
          <div>
            <p>
              At Premelo, we understand that communication is key when it comes
              to business success. That's why we've created a centralized
              messaging feature that allows you to connect your WhatsApp,
              Facebook, and Instagram accounts to Premelo Plus, so you can
              easily communicate with clients and teammates - all in one place.
            </p>
            <p>
              With Premelo Plus’ centralized messaging, you can reply to
              messages and communicate with clients and teammates directly from
              the platform. You can also receive updates when new message come
              in through WhatsApp, so you never miss an important message or
              opportunity.
            </p>
            <p>
              Our intuitive platform allows you to connect to your WhatsApp,
              Facebook, and Instagram accounts, so you can manage all your
              messaging in one place. You can assign messages to team members or
              assignees, so everyone knows who is responsible for each message.
            </p>
            <p>
              With Premelo Plus’ centralized messaging feature, you can
              streamline your communication, save time, and ensure that everyone
              is on the same page. And because our platform is designed to be
              easy to use, you can get started in just minutes, without any
              special training or technical expertise.
            </p>
            <p>
              Don't let communication breakdowns hold you back. Try Premelo
              Plus’ centralized messaging feature today and see how it can help
              you streamline your communication, boost your productivity, and
              take your business to the next level.
            </p>
          </div>
        </div>
      </div>
      {/* Website Footer */}
      <hr
        style={{
          width: "100%",
          height: "0.02rem",
          backgroundColor: "black",
          border: "none",
          margin: "10px auto",
        }}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "80%",
            justifyContent: "space-between",
            textAlign: "left",
            padding: "20px 0px",
          }}
        >
          <div style={{ width: "16%", fontSize: "12px", fontWeight: "300" }}>
            <div style={{ fontSize: "15px", fontWeight: "400" }}>Learn</div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/taskmanagement");
                window.scrollTo(0, 0);
              }}
            >
              Task Management
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/teaminvite");
                window.scrollTo(0, 0);
              }}
            >
              Team Invite
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/customworkflow");
                window.scrollTo(0, 0);
              }}
            >
              Custom Workflow
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/platformcustomization");
                window.scrollTo(0, 0);
              }}
            >
              Platform Customization
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/domainandhosting");
                window.scrollTo(0, 0);
              }}
            >
              Domain & Hosting
            </div>
          </div>
          <div style={{ width: "16%", fontSize: "12px", fontWeight: "300" }}>
            <div style={{ fontSize: "15px", fontWeight: "400" }}>Products</div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/workmanagement");
                window.scrollTo(0, 0);
              }}
            >
              Work Management
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/socialmediaconnect");
                window.scrollTo(0, 0);
              }}
            >
              Social Media Connect
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/internalrepositories");
                window.scrollTo(0, 0);
              }}
            >
              Internal Repositories
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/centralizedmessaging");
                window.scrollTo(0, 0);
              }}
            >
              Centralized Messaging
            </div>
          </div>
          <div style={{ width: "11%", fontSize: "12px", fontWeight: "300" }}>
            <div style={{ fontSize: "15px", fontWeight: "400" }}>Support</div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/contactus");
                window.scrollTo(0, 0);
              }}
            >
              Help Center
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/faq");
                window.scrollTo(0, 0);
              }}
            >
              FAQ
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/contactus");
                window.scrollTo(0, 0);
              }}
            >
              System Status
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/chatwithagent");
                window.scrollTo(0, 0);
              }}
            >
              Speak to our agents
            </div>
          </div>
          <div style={{ width: "16%", fontSize: "12px", fontWeight: "300" }}>
            <div style={{ fontSize: "15px", fontWeight: "400" }}>Company</div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/pressandannouncement");
                window.scrollTo(0, 0);
              }}
            >
              Press & Announcement
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/contactus");
                window.scrollTo(0, 0);
              }}
            >
              Careers
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/contactus");
                window.scrollTo(0, 0);
              }}
            >
              Sitemap
            </div>
          </div>
          <div style={{ width: "20%", fontSize: "12px", fontWeight: "300" }}>
            <img
              src={logo}
              alt="premelo logo"
              style={{ width: "30%", marginTop: "-5px" }}
            />
            <div style={{ marginTop: "-5px" }}>
              Premelo is a customizable work management platform that enables
              easy collaboration and real-time updates. Streamline workflow and
              boost productivity today.
            </div>
          </div>
        </div>
      </div>
      <hr
        style={{
          width: "100%",
          height: "0.02rem",
          backgroundColor: "black",
          border: "none",
          margin: "10px auto",
        }}
      />
      <div
        style={{
          width: "100%",
          padding: "10px 0px 20px 0px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", width: "50%", marginLeft: "10%" }}>
          <FacebookIcon
            className="mui-button"
            style={{ fontSize: "20px", marginRight: "15px" }}
            onClick={() =>
              (window.location.href = "https://www.facebook.com/premeloapp")
            }
          />
          <InstagramIcon
            className="mui-button"
            style={{ fontSize: "20px", marginRight: "15px" }}
            onClick={() =>
              (window.location.href = "https://www.instagram.com/premelo_app/")
            }
          />
          <YouTubeIcon
            className="mui-button"
            style={{ fontSize: "20px", marginRight: "15px" }}
            onClick={() =>
              (window.location.href = "https://www.youtube.com/@Premelo-Inc")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            width: "50%",
            fontSize: "12px",
            fontWeight: "300",
            textAlign: "right",
            justifyContent: "flex-end",
            marginRight: "10%",
          }}
        >
          <div
            className="mui-button"
            style={{ marginRight: "20px" }}
            onClick={() => {
              navigate("/termsandcondition");
              window.scrollTo(0, 0);
            }}
          >
            Terms & Privacy
          </div>
          <div
            className="mui-button"
            style={{ marginRight: "20px" }}
            onClick={() => {
              navigate("/contactus");
              window.scrollTo(0, 0);
            }}
          >
            Contact Us
          </div>
          <div>© 2023 Premelo, Inc.</div>
        </div>
      </div>
    </div>
  );
};
export default CentralizedMessaging;
