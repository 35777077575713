import React, { useEffect, useState } from "react";
import MobileScreen from "./MobileScreen";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./Login";
import SignUp from "./SignUp";
import Dashboard from "./Dashboard";
import FillInformation from "./FillInformation";
import Home from "./Home";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import DashContent from "./DashContent";
import MyTaskContent from "./MyTaskContent";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Provider } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import LoginMobile from "./LoginMobile";
import SignUpMobile from "./SignUpMobile";
import DashboardMobile from "./DashboardMobile";
import SubscriptionContentMobile from "./SubscriptionContentMobile";
import CRMMobile from "./TaskContentMobile";
import PlatformPerformance from "./PlatformPerformance";
import Homepage from "./homepage";
import Pricing from "./Pricing";
import FAQ from "./FAQ";
import TandC from "./TandC";
import ContactUs from "./Contact";
import TaskManagement from "./TaskManagement";
import TeamInvite from "./TeamInvite";
import CustomWorkflow from "./CustomWorkflow";
import PlatformCustomization from "./PlatformCustomization";
import DomainAndHosting from "./DomainAndHosting";
import WorkManagement from "./WorkManagement";
import SocialMediaConnect from "./SocialMediaConnect";
import InternalRepositories from "./InternalRepositories";
import CentralizedMessaging from "./CentralizedMessaging";
import PressAndAnnounement from "./PressAndAnnouncement";
import ChatWithAgent from "./ChatWithAgent";
import PricingMobile from "./PricingMobile";
import WebBuilder from "./WebBuilder";
import TestJs from "./TestJs";
import ProductEzyLink from "./ProductEzyLink";
import EventEzyLink from "./EventEzyLink";
import FormEzyLink from "./FormEzyLink";
import SectionMaker from "./sectionMaker";

const stripePromise = loadStripe(
  "pk_live_51KaaxAB9rsZCCn0XJcWbdk3MNAufS1spGzsmm50VqAgxqzq9sXkJcwo1YyhYwKOF3aDBMgR80gVEzvYyvjFjy2P100J3QYgnSr"
);

const options = {
};
Modal.setAppElement("#root");

function App() {
  // const [isMobile, setIsMobile] = useState(false);

  // useEffect(() => {
  //   const userAgent = window.navigator.userAgent.toLowerCase();
  //   const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/.test(
  //     userAgent
  //   );
  //   setIsMobile(isMobile);
  // }, []);

  // if (isMobile) {
  //   return <MobileScreen />;
  // }

  const toastOptions = {
    position: "top-center",
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Elements stripe={stripePromise}>
        <div>
          <Routes>
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/dashcontent" element={<DashContent />} />
            <Route path="/fillinformation" element={<FillInformation />} />
            <Route path="/mobilescreen" element={<MobileScreen />} />
            <Route path="/loginmobile" element={<LoginMobile />} />
            <Route path="/signupmobile" element={<SignUpMobile />} />
            <Route path="/dashboardmobile" element={<DashboardMobile />} />
            <Route path="/tablecontentmobile" element={<CRMMobile />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/termsandcondition" element={<TandC />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/taskmanagement" element={<TaskManagement />} />
            <Route path="/teaminvite" element={<TeamInvite />} />
            <Route path="/customworkflow" element={<CustomWorkflow />} />
            <Route path="/domainandhosting" element={<DomainAndHosting />} />
            <Route path="/workmanagement" element={<WorkManagement />} />
            <Route path="/pricingmobile" element={<PricingMobile />} />
            <Route path="/webbuilder" element={<WebBuilder />} />
            <Route path="/testjspage" element={<TestJs />} />
            <Route path="/ezylinkp" element={<ProductEzyLink />} />
            <Route path="/ezylinke" element={<EventEzyLink />} />
            <Route path="/ezylinkf" element={<FormEzyLink />} />
            <Route path="/sectionmaker97" element={<SectionMaker />} />
            <Route
              path="/centralizedmessaging"
              element={<CentralizedMessaging />}
            />
            <Route
              path="/pressandannouncement"
              element={<PressAndAnnounement />}
            />
            <Route path="/chatwithagent" element={<ChatWithAgent />} />
            <Route
              path="/internalrepositories"
              element={<InternalRepositories />}
            />
            <Route
              path="/socialmediaconnect"
              element={<SocialMediaConnect />}
            />
            <Route
              path="/platformcustomization"
              element={<PlatformCustomization />}
            />
            <Route
              path="/platformperformance97"
              element={<PlatformPerformance />}
            />
            <Route path="/" element={<Homepage />} />
            <Route
              path="/subscriptioncontentmobile"
              element={<SubscriptionContentMobile />}
            />
            <Route path="/homepage" element={<Navigate to="/" />} />
            <Route path="/home" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </Elements>
    </DndProvider>
  );
}

export default App;
