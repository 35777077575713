import React, { useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { v4 as uuid } from "uuid";
import Modal from "react-modal";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Cookies from "js-cookie";

const currencyOptions = [
  { value: "USD", label: "USD" },
  { value: "EUR", label: "EUR" },
  { value: "GBP", label: "GBP" },
  { value: "JPY", label: "JPY" }, // Japanese Yen
  { value: "CNY", label: "CNY" }, // Chinese Yuan
  { value: "KRW", label: "KRW" }, // South Korean Won
  { value: "INR", label: "INR" }, // Indian Rupee
  { value: "SGD", label: "SGD" }, // Singapore Dollar
  { value: "HKD", label: "HKD" }, // Hong Kong Dollar
  { value: "MYR", label: "MYR" }, // Malaysian Ringgit
  { value: "THB", label: "THB" }, // Thai Baht
  { value: "IDR", label: "IDR" }, // Indonesian Rupiah
  { value: "PHP", label: "PHP" }, // Philippine Peso
  { value: "VND", label: "VND" }, // Vietnamese Dong
  { value: "PKR", label: "PKR" }, // Pakistani Rupee
  { value: "BDT", label: "BDT" }, // Bangladeshi Taka
  { value: "LKR", label: "LKR" }, // Sri Lankan Rupee
  { value: "NPR", label: "NPR" }, // Nepalese Rupee
  { value: "MNT", label: "MNT" }, // Mongolian Tugrik
  // Add more currency options from Asia as needed
];

const defaultCurrency = "USD";

const columnsFromBackend = [
  {
    id: "unassigned-1",
    name: "Unassigned",
    closePercentage: "0%",
    items: [],
  },
  {
    id: "inprogress-1",
    name: "In Progress",
    closePercentage: "80%",
    items: [],
  },
  {
    id: "failed-1",
    name: "Failed",
    closePercentage: "0%",
    items: [],
  },
  {
    id: "done-1",
    name: "Done",
    closePercentage: "100%",
    items: [],
  },
];

const token = Cookies.get("token");

const createOrUpdateSalesLead = async () => {
  // Prepare the request body

  try {
    const response = await fetch(
      "https://ww1.premelo.com/api/saleslead-record",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ sales_data: columnsFromBackend }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to create/update client.");
    }

    const data = await response.json();
    console.log("Client created/updated:", data);
  } catch (error) {
    console.error(error);
  }
};

const dragEnd = async (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;
  const updatingColumn = [...columns];

  const [removed] = updatingColumn.splice(source.index, 1);
  updatingColumn.splice(destination.index, 0, removed);
  setColumns(updatingColumn);
  try {
    const response = await fetch(
      "https://ww1.premelo.com/api/saleslead-record",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ sales_data: updatingColumn }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to create/update client.");
    }

    const data = await response.json();
    console.log("Client created/updated:", data);
  } catch (error) {
    console.error(error);
  }
};

const onDragEnd = async (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;
  console.log(result);

  if (source.droppableId !== destination.droppableId) {
    const updatingColumn = [...columns];

    const [removed] = updatingColumn[source.droppableId].items.splice(
      source.index,
      1
    );
    removed.itemStatus = destination.droppableId; // update itemStatus
    updatingColumn[destination.droppableId].items.splice(
      destination.index,
      0,
      removed
    );
    console.log(updatingColumn);
    setColumns(updatingColumn);

    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/saleslead-record",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ sales_data: updatingColumn }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create/update client.");
      }

      const data = await response.json();
      console.log("Client created/updated:", data);
    } catch (error) {
      console.error(error);
    }
  } else {
    const updatingColumn = [...columns];
    const [removed] = updatingColumn[source.droppableId].items.splice(
      source.index,
      1
    );
    updatingColumn[source.droppableId].items.splice(
      destination.index,
      0,
      removed
    );

    setColumns(updatingColumn);

    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/saleslead-record",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ sales_data: updatingColumn }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create/update client.");
      }

      const data = await response.json();
      console.log("Client created/updated:", data);
    } catch (error) {
      console.error(error);
    }
  }
};

function SalesLead(props) {
  const [currency, setCurrency] = useState("$");
  useEffect(() => {
    const getCurrencyAndShipping = async () => {
      const token = Cookies.get("token");
      const response = await fetch(
        "https://ww1.premelo.com/api/stripe/getcands",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      if (json.commerce_array) {
        if (json.commerce_array.currency) {
          if (json.commerce_array.currency.display) {
            setCurrency(json.commerce_array.currency.display);
          }
        }
      }
    };
    getCurrencyAndShipping();
  }, []);

  const [columns, setColumns] = useState(columnsFromBackend);
  const [pricePrefix, setPricePrefix] = useState(
    Cookies.get("pricePrefix") ? Cookies.get("pricePrefix") : defaultCurrency
  );
  const [selectedCurrency, setSelectedCurrency] = useState(defaultCurrency);

  const getSalesLead = async () => {
    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/get-saleslead-record",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch client data.");
      }

      const data = await response.json();
      console.log(JSON.parse(data.sales_data));
      const leads = JSON.parse(data.sales_data);
      setColumns(leads);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getSalesLead();
    getClient();
  }, []);

  const calculateTotalValue = (items) => {
    const total = items.reduce(
      (acc, curr) => acc + parseFloat(curr.itemValue),
      0
    );
    return total.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const [pipelineSettingsModal, setPipelineSettingsModal] = useState(false);

  const addColumn = async () => {
    const newColumnId = `column-${columns.length + 1}`;
    const newColumn = {
      id: newColumnId,
      name: "",
      closePercentage: "",
      items: [],
    };
    const newColumns = [...columns];
    newColumns.push(newColumn);
    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/saleslead-record",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ sales_data: newColumns }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create/update client.");
      }

      const data = await response.json();
      console.log("Client created/updated:", data);

      // Update the state with the updated client data
      setColumns(newColumns);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemoveColumn = async (columnId) => {
    const newColumns = [...columns]; // Convert object to array

    // Move items to unassigned column
    const column = newColumns.find((column) => column.id === columnId.id);
    const columnItems = column?.items || []; // Use empty array as fallback if column does not exist
    newColumns.find((column) => column.id === "unassigned-1").items = [
      ...newColumns.find((column) => column.id === "unassigned-1").items,
      ...columnItems,
    ];

    // Remove column
    const columnIndex = newColumns.findIndex(
      (column) => column.id === columnId.id
    );
    newColumns.splice(columnIndex, 1);
    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/saleslead-record",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ sales_data: newColumns }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create/update client.");
      }

      const data = await response.json();
      console.log("Client created/updated:", data);

      // Update the state with the updated client data
      setColumns((prevColumns) => {
        return Object.assign({}, newColumns); // Convert array back to object
      });
    } catch (error) {
      console.error(error);
    }
  };

  const [openLeadDetails, setOpenLeadDetails] = useState(false);
  const [activeDeal, setActiveDeal] = useState("");
  const [myClientSampleData, setMyClientSampleData] = useState([]);
  const getClient = async () => {
    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/get-myclient-record",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch client data.");
      }

      const data = await response.json();
      console.log(data.contact_data);
      const clients = JSON.parse(data.contact_data);
      console.log(Array.isArray(clients)); // Check if clientData is an array
      setMyClientSampleData(clients);
    } catch (error) {
      console.error(error);
    }
  };
  const openLeadDetailsContent = (
    <div>
      <div
        style={{
          background: props.primaryColor,
          height: "50px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ margin: "0px 12px", color: "white", fontSize: "15px" }}>
          Lead Details
        </div>
        <div style={{ margin: "0px 12px", color: "white", fontSize: "12px" }}>
          <CancelIcon
            className="mui-button"
            style={{ color: "white", fontSize: "18px" }}
            onClick={() => setOpenLeadDetails(false)}
          />
        </div>
      </div>
      <div style={{ padding: "0px 20px", marginTop: "20px" }}>
        <div style={{ fontSize: "13px", fontWeight: "600" }}>Lead Name</div>
        <input
          style={{
            fontSize: "11px",
            fontWeight: "300",
            width: "95%",
            padding: "5.5px 8px",
            borderRadius: "7px",
            border: "1px solid lightgrey",
            background: `${props.primaryColor}1A`,
          }}
          type="textarea"
          value={activeDeal.content}
          onChange={(e) => {
            setActiveDeal({
              ...activeDeal,
              content: e.target.value,
            });
          }}
          onBlur={async () => {
            const newColumns = [...columns];
            const columnId = columns.findIndex((col) =>
              col.items.some((it) => it.id === activeDeal.id)
            );
            console.log("new columns", newColumns);
            console.log("column id", columnId);
            const index = newColumns[columnId].items.findIndex(
              (item) => item.id === activeDeal.id
            );
            if (index !== -1) {
              newColumns[columnId].items[index].content = activeDeal.content;
            }
            try {
              const response = await fetch(
                "https://ww1.premelo.com/api/saleslead-record",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                  body: JSON.stringify({ sales_data: newColumns }),
                }
              );

              if (!response.ok) {
                throw new Error("Failed to create/update client.");
              }

              const data = await response.json();
              console.log("Client created/updated:", data);

              // Update the state with the updated client data
              setColumns((prevColumns) => {
                console.log(newColumns);
                return newColumns;
              });
            } catch (error) {
              console.error(error);
            }
          }}
        />
      </div>
      <div style={{ padding: "0px 20px", marginTop: "12px" }}>
        <div style={{ fontSize: "13px", fontWeight: "600" }}>
          Lead Description
        </div>
        <input
          style={{
            fontSize: "11px",
            fontWeight: "300",
            width: "95%",
            padding: "5.5px 8px",
            borderRadius: "7px",
            border: "1px solid lightgrey",
            background: `${props.primaryColor}1A`,
          }}
          type="textarea"
          value={activeDeal.itemDescription}
          onChange={(e) => {
            setActiveDeal({
              ...activeDeal,
              itemDescription: e.target.value,
            });
          }}
          onBlur={async () => {
            const newColumns = [...columns];
            const columnId = columns.findIndex((col) =>
              col.items.some((it) => it.id === activeDeal.id)
            );
            console.log("new columns", newColumns);
            console.log("column id", columnId);
            const index = newColumns[columnId].items.findIndex(
              (item) => item.id === activeDeal.id
            );
            if (index !== -1) {
              newColumns[columnId].items[index].itemDescription =
                activeDeal.itemDescription;
            }
            try {
              const response = await fetch(
                "https://ww1.premelo.com/api/saleslead-record",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                  body: JSON.stringify({ sales_data: newColumns }),
                }
              );

              if (!response.ok) {
                throw new Error("Failed to create/update client.");
              }

              const data = await response.json();
              console.log("Client created/updated:", data);

              // Update the state with the updated client data
              setColumns((prevColumns) => {
                console.log(newColumns);
                return newColumns;
              });
            } catch (error) {
              console.error(error);
            }
          }}
        />
      </div>
      <div style={{ padding: "0px 20px", marginTop: "12px" }}>
        <div style={{ fontSize: "13px", fontWeight: "600" }}>Deal Value</div>
        <input
          style={{
            fontSize: "11px",
            fontWeight: "300",
            width: "95%",
            padding: "5.5px 8px",
            borderRadius: "7px",
            border: "1px solid lightgrey",
            background: `${props.primaryColor}1A`,
          }}
          type="textarea"
          value={activeDeal.itemValue}
          onChange={(e) => {
            setActiveDeal({
              ...activeDeal,
              itemValue: parseFloat(e.target.value),
            });
          }}
          onBlur={async () => {
            const newColumns = [...columns];
            const columnId = columns.findIndex((col) =>
              col.items.some((it) => it.id === activeDeal.id)
            );
            console.log("new columns", newColumns);
            console.log("column id", columnId);
            const index = newColumns[columnId].items.findIndex(
              (item) => item.id === activeDeal.id
            );
            if (index !== -1) {
              newColumns[columnId].items[index].itemValue =
                activeDeal.itemValue;
            }
            try {
              const response = await fetch(
                "https://ww1.premelo.com/api/saleslead-record",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                  body: JSON.stringify({ sales_data: newColumns }),
                }
              );

              if (!response.ok) {
                throw new Error("Failed to create/update client.");
              }

              const data = await response.json();
              console.log("Client created/updated:", data);

              // Update the state with the updated client data
              setColumns((prevColumns) => {
                console.log(newColumns);
                return newColumns;
              });
            } catch (error) {
              console.error(error);
            }
          }}
        />
      </div>
      <div style={{ padding: "0px 20px", marginTop: "12px" }}>
        <div style={{ fontSize: "13px", fontWeight: "600" }}>Priority</div>
        <select
          style={{
            fontSize: "11px",
            fontWeight: "300",
            width: "100%",
            padding: "5.5px 8px",
            borderRadius: "7px",
            border: "1px solid lightgrey",
            background: `${props.primaryColor}1A`,
          }}
          value={activeDeal.itemPriority}
          onChange={(e) => {
            setActiveDeal({
              ...activeDeal,
              itemPriority: e.target.value,
            });
          }}
          onBlur={async () => {
            const newColumns = [...columns];
            const columnId = columns.findIndex((col) =>
              col.items.some((it) => it.id === activeDeal.id)
            );
            console.log("new columns", newColumns);
            console.log("column id", columnId);
            const index = newColumns[columnId].items.findIndex(
              (item) => item.id === activeDeal.id
            );
            if (index !== -1) {
              newColumns[columnId].items[index].itemPriority =
                activeDeal.itemPriority;
            }
            try {
              const response = await fetch(
                "https://ww1.premelo.com/api/saleslead-record",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                  body: JSON.stringify({ sales_data: newColumns }),
                }
              );

              if (!response.ok) {
                throw new Error("Failed to create/update client.");
              }

              const data = await response.json();
              console.log("Client created/updated:", data);

              // Update the state with the updated client data
              setColumns((prevColumns) => {
                console.log(newColumns);
                return newColumns;
              });
            } catch (error) {
              console.error(error);
            }
          }}
        >
          <option value="">Select Priority</option>
          <option value="Low">Low</option>
          <option value="Medium">Medium</option>
          <option value="High">High</option>
        </select>
      </div>
      <div style={{ padding: "0px 20px", marginTop: "12px" }}>
        <div style={{ fontSize: "13px", fontWeight: "600" }}>
          Associated Client
        </div>
        <select
          style={{
            fontSize: "11px",
            fontWeight: "300",
            width: "100%",
            padding: "5.5px 8px",
            borderRadius: "7px",
            border: "1px solid lightgrey",
            background: `${props.primaryColor}1A`,
          }}
          value={activeDeal.itemClient}
          onChange={(e) => {
            setActiveDeal({
              ...activeDeal,
              itemClient: e.target.value,
            });
          }}
          onBlur={async () => {
            const newColumns = [...columns];
            const columnId = columns.findIndex((col) =>
              col.items.some((it) => it.id === activeDeal.id)
            );
            console.log("new columns", newColumns);
            console.log("column id", columnId);
            const index = newColumns[columnId].items.findIndex(
              (item) => item.id === activeDeal.id
            );
            if (index !== -1) {
              newColumns[columnId].items[index].itemClient =
                activeDeal.itemClient;
            }
            try {
              const response = await fetch(
                "https://ww1.premelo.com/api/saleslead-record",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                  body: JSON.stringify({ sales_data: newColumns }),
                }
              );

              if (!response.ok) {
                throw new Error("Failed to create/update client.");
              }

              const data = await response.json();
              console.log("Client created/updated:", data);

              // Update the state with the updated client data
              setColumns((prevColumns) => {
                console.log(newColumns);
                return newColumns;
              });
            } catch (error) {
              console.error(error);
            }
          }}
        >
          <option value="">Select Contact</option>
          {myClientSampleData.map((contacts) => {
            return <option value={contacts.name}>{contacts.name}</option>;
          })}
        </select>
      </div>
    </div>
  );
  const [confirmRemoveColumn, setConfirmRemoveColumn] = useState("");

  const [openCreateLead, setOpenCreateLead] = useState(false);

  const [item, setItem] = useState({
    id: null,
    content: "",
    itemDescription: "",
    itemStatus: 0,
    itemValue: 0,
    itemPriority: "",
    itemClient: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setItem({ ...item, [name]: value });
  };

  useEffect(() => {
    console.log(columns);
  }, [columns]);

  const handleSubmit = async (event) => {
    setOpenCreateLead(false);
    event.preventDefault();
    const newId = uuid();
    const newColumns = [...columns];
    const newItem = {
      id: newId,
      content: item.content,
      itemStatus: item.itemStatus === 0 ? columns[0].id : item.itemStatus,
      itemDescription: item.itemDescription,
      itemValue: item.itemValue,
      itemPriority: item.itemPriority,
      itemClient: item.itemClient,
    };
    console.log(newItem);
    const columnIndex = columns.findIndex(
      (col) => col.id === newItem.itemStatus
    );
    newColumns[columnIndex].items = [...newColumns[columnIndex].items, newItem];

    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/saleslead-record",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ sales_data: newColumns }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create/update client.");
      }

      const data = await response.json();
      console.log("Client created/updated:", data);

      // Update the state with the updated client data
      setColumns((prevColumns) => {
        console.log(newColumns);
        return newColumns;
      });
    } catch (error) {
      console.error(error);
    }
    setItem({
      id: null,
      content: "",
      itemDescription: "",
      itemStatus: 0,
      itemValue: 0,
      itemPriority: "",
      itemClient: "",
    });
  };

  const updatePipelineSettings = async () => {
    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/saleslead-record",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ sales_data: columns }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create/update client.");
      }

      const data = await response.json();
      console.log("Client created/updated:", data);
    } catch (error) {
      console.error(error);
    }
  };

  const getDroppableStyle = (isDraggingOver) => ({
    background: "transparent",
  });

  const getDraggableStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "red" : "white",
    color: isDragging ? "white" : "rgba(0,0,0,0.6)",
    fontSize: "12px",
    padding: "3px 10px",
    margin: "5px 0px",
    borderRadius: "5px",
    ...draggableStyle,
  });

  const openCreateLeadDetailsContent = (
    <div>
      <div
        style={{
          background: props.primaryColor,
          height: "50px",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ margin: "0px 12px", color: "white", fontSize: "15px" }}>
          Create New Lead
        </div>
        <div style={{ margin: "0px 12px", color: "white", fontSize: "12px" }}>
          <CancelIcon
            className="mui-button"
            style={{ color: "white", fontSize: "18px" }}
            onClick={() => setOpenCreateLead(false)}
          />
        </div>
      </div>
      <div style={{ padding: "0px 20px", marginTop: "20px" }}>
        <form onSubmit={handleSubmit}>
          <div style={{ padding: "0px 20px", marginTop: "12px" }}>
            <div style={{ fontSize: "13px", fontWeight: "600" }}>Lead Name</div>
            <input
              required
              style={{
                fontSize: "11px",
                fontWeight: "300",
                width: "95%",
                padding: "5.5px 8px",
                borderRadius: "7px",
                border: "1px solid lightgrey",
                background: `${props.primaryColor}1A`,
              }}
              type="text"
              id="content"
              name="content"
              value={item.content}
              onChange={handleInputChange}
            />
          </div>
          <div style={{ padding: "0px 20px", marginTop: "12px" }}>
            <div style={{ fontSize: "13px", fontWeight: "600" }}>
              Lead Description
            </div>
            <input
              required
              style={{
                fontSize: "11px",
                fontWeight: "300",
                width: "95%",
                padding: "5.5px 8px",
                borderRadius: "7px",
                border: "1px solid lightgrey",
                background: `${props.primaryColor}1A`,
              }}
              type="text"
              id="itemDescription"
              name="itemDescription"
              value={item.itemDescription}
              onChange={handleInputChange}
            />
          </div>
          <div style={{ padding: "0px 20px", marginTop: "12px" }}>
            <div style={{ fontSize: "13px", fontWeight: "600" }}>
              Deal Stage
            </div>
            <select
              style={{
                fontSize: "11px",
                fontWeight: "300",
                width: "100%",
                padding: "5.5px 8px",
                borderRadius: "7px",
                border: "1px solid lightgrey",
                background: `${props.primaryColor}1A`,
              }}
              id="itemStatus"
              name="itemStatus"
              value={item.itemStatus}
              onChange={handleInputChange}
            >
              {columns
                ? columns.map((column, index) => (
                  <option key={index} value={column.id}>
                    {column.name}
                  </option>
                ))
                : null}
            </select>
          </div>
          <div style={{ padding: "0px 20px", marginTop: "12px" }}>
            <div style={{ fontSize: "13px", fontWeight: "600" }}>
              Deal Value
            </div>
            <input
              required
              style={{
                fontSize: "11px",
                fontWeight: "300",
                width: "95%",
                padding: "5.5px 8px",
                borderRadius: "7px",
                border: "1px solid lightgrey",
                background: `${props.primaryColor}1A`,
              }}
              type="number"
              id="itemValue"
              name="itemValue"
              value={item.itemValue}
              onChange={handleInputChange}
            />
          </div>
          <div style={{ padding: "0px 20px", marginTop: "12px" }}>
            <div style={{ fontSize: "13px", fontWeight: "600" }}>Priority</div>
            <select
              style={{
                fontSize: "11px",
                fontWeight: "300",
                width: "100%",
                padding: "5.5px 8px",
                borderRadius: "7px",
                border: "1px solid lightgrey",
                background: `${props.primaryColor}1A`,
              }}
              id="itemPriority"
              name="itemPriority"
              value={item.itemPriority}
              onChange={handleInputChange}
            >
              <option value="">Select Priority</option>
              <option value="Low">Low</option>
              <option value="Medium">Medium</option>
              <option value="High">High</option>
            </select>
          </div>
          <div style={{ padding: "0px 20px", marginTop: "12px" }}>
            <div style={{ fontSize: "13px", fontWeight: "600" }}>
              Associated Client
            </div>
            <select
              style={{
                fontSize: "11px",
                fontWeight: "300",
                width: "100%",
                padding: "5.5px 8px",
                borderRadius: "7px",
                border: "1px solid lightgrey",
                background: `${props.primaryColor}1A`,
              }}
              id="itemClient"
              name="itemClient"
              value={item.itemClient}
              onChange={handleInputChange}
            >
              <option value="">Select Client</option>
              {myClientSampleData.map((contacts) => {
                return <option value={contacts.name}>{contacts.name}</option>;
              })}
            </select>
          </div>
          <div
            style={{
              padding: "0px 20px",
              marginTop: "12px",
              textAlign: "right",
            }}
          >
            <button
              className="mui-button"
              style={{
                background: props.primaryColor,
                color: "white",
                borderRadius: "6px",
                padding: "7px 20px",
                border: "none",
                fontSize: "11px",
                fontWeight: "600",
              }}
              type="submit"
            >
              Create
            </button>
          </div>
        </form>
      </div>
    </div>
  );
  const handleCurrencyChange = (event) => {
    setPricePrefix(event.target.value);
    Cookies.set("pricePrefix", event.target.value);
  };
  return (
    <div style={{}}>
      {/* Header */}
      <div
        style={{
          display: "flex",
          borderBottom: "1px solid rgba(0,0,0,0.1)",
          alignItems: "center",
          background: "rgba(255,255,255,0.7)",
          backdropFilter: "blur(20px)",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
          padding: "30px 0px 10px 0px",
          position: "relative",
          zIndex: 490,
        }}
      >
        <div
          style={{
            color: props.primaryColor,
            fontSize: "22px",
            fontWeight: "600",
            marginLeft: "20px",
            paddingBottom: "1%",
          }}
        >
          Sales Leads
        </div>
        <div
          style={{
            marginTop: "-7px",
            marginLeft: "13px",
          }}
        >
          <AddCircleIcon
            onClick={() => setOpenCreateLead(true)}
            className="mui-button-table"
            style={{
              fontSize: "20px",
              color: props.primaryColor,
            }}
          />
        </div>
        {/* <div
          onClick={() => createOrUpdateSalesLead()}
          style={{ border: "1px solid grey" }}
        >
          create first records
        </div> */}
      </div>
      {/* customization */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 10px",
          fontSize: "12px",
        }}
      >
        <div
          className="mui-button"
          style={{
            borderRadius: "10px",
            padding: "6px 20px",
            background: props.primaryColor,
            color: "white",
          }}
          onClick={() => setOpenCreateLead(true)}
        >
          Add new Lead
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {/* <select
            style={{
              marginRight: "15px",
              padding: "0px 10px",
              borderRadius: "10px",
              border: "none",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
              fontSize: "10px",
              fontWeight: "600",
            }}
            value={pricePrefix}
            onChange={handleCurrencyChange}
          >
            {currencyOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select> */}
          <div
            className="mui-button"
            style={{
              borderRadius: "10px",
              padding: "6px 20px",
              background: props.primaryColor,
              color: "white",
            }}
            onClick={() => setPipelineSettingsModal(true)}
          >
            Pipeline Settings
          </div>
        </div>
      </div>
      {/* Lead table */}
      <div
        style={{ display: "flex", justifyContent: "center", height: "100%" }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "82vh",
            overflowX: "scroll",
            overflowY: "hidden",
            padding: "10px",
          }}
        >
          <DragDropContext
            onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
          >
            {Object.entries(columns).map(([columnId, column], index) => {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "left",
                    width: "270px",
                    marginRight: "10px",
                    borderRadius: "10px",
                    height: "76vh",
                    background: "rgba(255,255,255,0.7)",
                    // backdropFilter: "blur(10px)",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                    position: "relative",
                    // zIndex: 490,
                  }}
                  key={columnId}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      width: "100%",
                      height: "14vh",
                      marginLeft: "10px",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        textAlign: "left",
                        margin: "0px",
                        fontSize: "18px",
                        fontWeight: "600",
                        display: "flex",
                        alignItems: "center",
                        marginTop: "15px",
                      }}
                    >
                      <div style={{ margin: "10px 0px 0px 10px " }}>
                        {column.name}
                      </div>
                      <div
                        style={{
                          color: "white",
                          borderRadius: "30px",
                          width: "18px",
                          height: "18px",
                          background: props.primaryColor,
                          fontSize: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "7px 0px 0px 6px ",
                        }}
                      >
                        {column.items.length}
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        fontSize: "10px",
                        fontWeight: "300",
                        alignItems: "center",
                        textAlign: "left",
                      }}
                    >
                      <p style={{ margin: "0px 5px 0px 10px" }}>Value:</p>
                      <p style={{ margin: "0px 3px 0px 0px" }}>{currency}</p>
                      <p style={{ margin: 0 }}>
                        {calculateTotalValue(column.items)}
                      </p>
                    </div>
                    <div
                      style={{
                        fontSize: "10px",
                        fontWeight: "300",
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      <p style={{ margin: " 0px 0px 0px 10px" }}>
                        Weighted Calculation:
                        {column.closePercentage}
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        textAlign: "left",
                        width: "100%",
                      }}
                    >
                      <hr
                        style={{
                          borderColor: "rgba(0,0,0,0.1)",
                          width: "80%",
                          height: "0.01px",
                          margin: "12px 0px 0px 10px",
                        }}
                      />
                    </div>
                  </div>
                  <div style={{ margin: 8, width: "98%" }}>
                    <Droppable droppableId={columnId} key={columnId}>
                      {(provided, snapshot) => {
                        return (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                              background: snapshot.isDraggingOver
                                ? "transparent"
                                : "transparent",
                              width: "250px",
                              height: "60vh",
                              overflowY: "auto",
                              marginLeft: "6px",
                              borderRadius: "10px",
                            }}
                          >
                            {column.items.map((item, index) => {
                              return (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id}
                                  index={index}
                                >
                                  {(provided, snapshot) => {
                                    return (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{
                                          userSelect: "none",
                                          borderRadius: "10px",
                                          padding: 16,
                                          margin: "0 0 8px 0",
                                          minHeight: "50px",
                                          backgroundColor: snapshot.isDragging
                                            ? `${props.primaryColor}7A`
                                            : props.primaryColor,
                                          color: "white",
                                          ...provided.draggableProps.style,
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "100%",
                                            display: "flex",
                                            justifyContent: "flex-end",
                                          }}
                                        >
                                          <div
                                            className="blink"
                                            style={{
                                              border:
                                                item.itemPriority === "Low"
                                                  ? " 1px solid white"
                                                  : item.itemPriority ===
                                                    "Medium"
                                                    ? " 1px solid white"
                                                    : item.itemPriority === "High"
                                                      ? " 1px solid white"
                                                      : " 1px solid transparent",
                                              background:
                                                item.itemPriority === "Low"
                                                  ? "lightgreen"
                                                  : item.itemPriority ===
                                                    "Medium"
                                                    ? "#ffcf30"
                                                    : item.itemPriority === "High"
                                                      ? "red"
                                                      : "transparent",
                                              boxShadow:
                                                item.itemPriority === "Low"
                                                  ? "0px 0px 5px lightgreen"
                                                  : item.itemPriority ===
                                                    "0px 0px 5px Medium"
                                                    ? "#ffcf30"
                                                    : item.itemPriority === "High"
                                                      ? "0px 0px 5px red"
                                                      : "transparent",
                                              width: "7px",
                                              height: "7px",
                                              borderRadius: "30px",
                                            }}
                                          ></div>
                                        </div>
                                        <div
                                          className="mui-button-table"
                                          onClick={() => {
                                            console.log("activedeal", item);
                                            setActiveDeal(item);
                                            setOpenLeadDetails(true);
                                          }}
                                          style={{
                                            fontSize: "14px",
                                            marginTop: "-12px",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {item.content}
                                        </div>
                                        <div
                                          style={{
                                            fontSize: "10px",
                                            fontWeight: "300",
                                          }}
                                        >
                                          {item.itemDescription}
                                        </div>
                                        <div
                                          style={{
                                            width: "80%",
                                            display: "flex",
                                            justifyContent: "flex-start",
                                          }}
                                        >
                                          <hr
                                            style={{
                                              width: "100%",
                                              height: "0.01px",
                                            }}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            fontSize: "10px",
                                            fontWeight: "300",
                                            display: "flex",
                                          }}
                                        >
                                          <p
                                            style={{
                                              margin: "0px 3px 0px 0px",
                                            }}
                                          >
                                            {currency}
                                          </p>
                                          <p style={{ margin: 0 }}>
                                            {item.itemValue
                                              .toLocaleString("en-US", {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                              })
                                              .replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ","
                                              )}
                                          </p>
                                        </div>
                                      </div>
                                    );
                                  }}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                  </div>
                </div>
              );
            })}
          </DragDropContext>
        </div>
      </div>
      <Modal
        isOpen={pipelineSettingsModal}
        onRequestClose={() => {
          setPipelineSettingsModal(false);
          setConfirmRemoveColumn("");
          updatePipelineSettings();
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 505
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30%",
            height: "87%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            borderRadius: "10px",
            padding: "0px",
            borderColor: "transparent",
            outline: "none",
            border: "none",
          },
        }}
      >
        <div
          style={{
            background: props.primaryColor,
            height: "50px",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ margin: "0px 12px", color: "white", fontSize: "15px" }}>
            Pipieline Settings
          </div>
          <div style={{ margin: "0px 12px", color: "white", fontSize: "12px" }}>
            <CancelIcon
              className="mui-button"
              style={{ color: "white", fontSize: "18px" }}
              onClick={() => {
                updatePipelineSettings();
                setPipelineSettingsModal(false);
              }}
            />
          </div>
        </div>
        <div style={{ margin: "10px 0px 0px 10px" }}>
          <div style={{ padding: "2px 10px", marginBottom: "5px" }}>
            <div
              style={{
                fontSize: "13px",
                fontWeight: "600",
                color: "lightgrey",
              }}
            >
              Unassigned
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "95%",
                marginTop: "6px",
              }}
            >
              <input
                style={{
                  fontSize: "12px",
                  fontWeight: "300",
                  width: "65%",
                  padding: "5.5px 8px",
                  borderRadius: "7px",
                  border: "1px solid lightgrey",
                  background: `${props.primaryColor}1A`,
                }}
                type="text"
                value="Unassigned"
                disabled
              />
              <input
                style={{
                  fontSize: "12px",
                  fontWeight: "300",
                  width: "25%",
                  padding: "5.5px 8px",
                  borderRadius: "7px",
                  border: "1px solid lightgrey",
                  background: `${props.primaryColor}1A`,
                }}
                type="text"
                value="0%"
                disabled
              />
            </div>
          </div>
          <DragDropContext
            onDragEnd={(result) => dragEnd(result, columns, setColumns)}
          >
            <Droppable
              droppableId="droppableFields"
            >
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getDroppableStyle(snapshot.isDraggingOver)}
                >
                  {Object.entries(columns).map(([columnId, column], index) => {
                    if (column.id !== "unassigned-1") {
                      return (
                        <Draggable
                          key={columnId}
                          draggableId={columnId}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                            >
                              <div
                                style={{
                                  padding: "2px 10px",
                                  marginBottom: "5px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "95%",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "600",
                                      color: snapshot.isDragging
                                        ? "black"
                                        : "rgba(0,0,0,0.7)",
                                    }}
                                  >
                                    {column.name}
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      alignItems: "center",
                                    }}
                                  >
                                    {confirmRemoveColumn === columnId ? (
                                      <div
                                        className="mui-button"
                                        onClick={() => {
                                          console.log(column);
                                          handleRemoveColumn(column);
                                          setConfirmRemoveColumn("");
                                        }}
                                        style={{
                                          border: "1px solid red",
                                          background: "rgba(227, 51, 39,0.1)",
                                          color: "red",
                                          fontSize: "10px",
                                          padding: "2px 10px",
                                          borderRadius: "5px",
                                          margin: "2px 0px",
                                        }}
                                      >
                                        Confirm Remove
                                      </div>
                                    ) : (
                                      <RemoveCircleOutlineIcon
                                        className="mui-button"
                                        style={{
                                          fontSize: "15px",
                                          color: "#d66c65",
                                        }}
                                        onClick={() =>
                                          setConfirmRemoveColumn(columnId)
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    width: "96%",
                                    marginTop: "6px",
                                  }}
                                >
                                  <input
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "300",
                                      width: "65%",
                                      padding: "5.5px 8px",
                                      borderRadius: "7px",
                                      border: "1px solid lightgrey",
                                      background: `${props.primaryColor}1A`,
                                    }}
                                    type="text"
                                    value={column.name}
                                    onChange={(e) => {
                                      const updatedColumn = [...columns];
                                      console.log(updatedColumn[columnId]);
                                      updatedColumn[columnId].name =
                                        e.target.value;
                                      setColumns(updatedColumn);
                                    }}
                                    onBlur={async (e) => {
                                      const newName = e.target.value;
                                      const newColumns = [...columns];
                                      newColumns[columnId].name = newName;
                                      try {
                                        const response = await fetch(
                                          "https://ww1.premelo.com/api/saleslead-record",
                                          {
                                            method: "POST",
                                            headers: {
                                              "Content-Type":
                                                "application/json",
                                              Authorization: `Bearer ${token}`,
                                            },
                                            body: JSON.stringify({
                                              sales_data: newColumns,
                                            }),
                                          }
                                        );

                                        if (!response.ok) {
                                          throw new Error(
                                            "Failed to create/update client."
                                          );
                                        }

                                        const data = await response.json();
                                        console.log(
                                          "Client created/updated:",
                                          data
                                        );
                                      } catch (error) {
                                        console.error(error);
                                      }
                                      // Save the new column name to the database or perform other actions
                                      console.log(
                                        "New column name:",
                                        column.name
                                      );
                                    }}
                                  />
                                  <input
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "300",
                                      width: "25%",
                                      padding: "5.5px 8px",
                                      borderRadius: "7px",
                                      border: "1px solid lightgrey",
                                      background: `${props.primaryColor}1A`,
                                    }}
                                    type="text"
                                    value={column.closePercentage}
                                    onChange={(e) => {
                                      const updatedColumn = [...columns];
                                      console.log(updatedColumn[columnId]);
                                      updatedColumn[columnId].closePercentage =
                                        e.target.value;
                                      setColumns(updatedColumn);
                                    }}
                                    onBlur={async (e) => {
                                      const newClosePercentage = e.target.value;
                                      const newColumns = [...columns];
                                      newColumns[columnId].closePercentage =
                                        newClosePercentage;
                                      try {
                                        const response = await fetch(
                                          "https://ww1.premelo.com/api/saleslead-record",
                                          {
                                            method: "POST",
                                            headers: {
                                              "Content-Type":
                                                "application/json",
                                              Authorization: `Bearer ${token}`,
                                            },
                                            body: JSON.stringify({
                                              sales_data: newColumns,
                                            }),
                                          }
                                        );

                                        if (!response.ok) {
                                          throw new Error(
                                            "Failed to create/update client."
                                          );
                                        }

                                        const data = await response.json();
                                        console.log(
                                          "Client created/updated:",
                                          data
                                        );
                                      } catch (error) {
                                        console.error(error);
                                      }
                                      // Save the new column name to the database or perform other actions
                                      console.log(
                                        "New close percentage:",
                                        column.closePercentage
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    }
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <button
            className="mui-button"
            style={{
              margin: "10px 0px 0px 10px",
              fontSize: "12px",
              fontWeight: "400",
              background: props.primaryColor,
              color: "white",
              padding: "8px 25px",
              borderRadius: "10px",
              border: "none",
            }}
            onClick={addColumn}
          >
            Add Column
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={openLeadDetails}
        onRequestClose={() => setOpenLeadDetails(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 505
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30%",
            height: "60%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            borderRadius: "10px",
            padding: "0px",
            borderColor: "transparent",
            outline: "none",
            border: "none",
          },
        }}
      >
        {openLeadDetailsContent}
      </Modal>
      <Modal
        isOpen={openCreateLead}
        onRequestClose={() => setOpenCreateLead(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 505
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30%",
            height: "72%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            borderRadius: "10px",
            padding: "0px",
            borderColor: "transparent",
            outline: "none",
            border: "none",
          },
        }}
      >
        {openCreateLeadDetailsContent}
      </Modal>
    </div>
  );
}

export default SalesLead;
