import React, { useState, useEffect, useRef } from "react";
import "./Builder.css";
import { v4 as uuid } from "uuid";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import HandymanIcon from "@mui/icons-material/Handyman";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import Grid4x4Icon from "@mui/icons-material/Grid4x4";
import WidgetsIcon from "@mui/icons-material/Widgets";
import imageReference from "./images/imagePlaceholder.png";
import Modal from "react-modal";
import axios from "axios";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import AlignHorizontalCenterIcon from "@mui/icons-material/AlignHorizontalCenter";
import AlignHorizontalRightIcon from "@mui/icons-material/AlignHorizontalRight";
import AlignVerticalTopIcon from "@mui/icons-material/AlignVerticalTop";
import AlignVerticalCenterIcon from "@mui/icons-material/AlignVerticalCenter";
import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom";
import pexellogo from "./images/pexel_logo.png";
import customCursor from "./images/pen_cursor-01-01.png";
import { ChromePicker } from "react-color";
import Cookies from "js-cookie";
import SyncIcon from "@mui/icons-material/Sync";
import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import { cloneDeep, isEqual, update } from "lodash";
import SmartButtonIcon from "@mui/icons-material/SmartButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import CategoryIcon from "@mui/icons-material/Category";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import { CircularProgress } from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-image-gallery/styles/css/image-gallery.css";
import CloseIcon from "@mui/icons-material/Close";
import "leaflet/dist/leaflet.css";
import ProductSearchElement from "./components/ProductSearchElement";
import NewsSearchElement from "./components/NewsSearchElement";
import EventsFilterElement from "./components/EventsFilterElement";
import ProductFilterElement from "./components/ProductFilterElement";
import NewsFilterElement from "./components/NewsFilterElement";
import ButtonElement from "./components/ButtonElement";
import ImageElement from "./components/ImageElement";
import VideoElement from "./components/VideoElement";
import FormElement from "./components/FormElement";
import ProductElement from "./components/ProductElement";
import EventElement from "./components/EventElement";
import ProductCarouselElement from "./components/ProductCarouselElement";
import EventsSearchElement from "./components/EventsSearchElement";
import EventsCarouselElement from "./components/EventsCarouselElement";
import NewsCarouselElement from "./components/NewsCarouselElement";
import MenuElement from "./components/MenuElement";
import ImageGalleryElement from "./components/ImageGalleryElement";
import BoxElement from "./components/BoxElement";
import TextElement from "./components/TextElement";
import SocialElement from "./components/SocialElement";
import HorizontalDividerElement from "./components/HorizontalDividerElement";
import VerticalDividerElement from "./components/VerticalDividerElement";
import IconElement from "./components/IconElement";
import AccordianElement from "./components/AccordianElement";
import SwitchTabElement from "./components/SwitchTabElement";
import LoginElement from "./components/LoginElement";
import DynamicAccountTabsElement from "./components/DynamicAccountTabsElement";
import DynamicAccountHeaderElement from "./components/DynamicAccountHeaderElement";
import DynamicPriceElement from "./components/DynamicPriceElement";
import DynamicTableAttributeElement from "./components/DynamicTableAttributeElement";
import DynamicGridAttributeElement from "./components/DynamicGridAttributeElement";
import DynamicListAttributeElement from "./components/DynamicListAttributeElement";
import DynamicHorizontalDetailsElement from "./components/DynamicHorizontalDetailsElement";
import DynamicDetailsElement from "./components/DynamicDetailsElement";
import DynamicImageElement from "./components/DynamicImageElement";
import DynamicDescriptionElement from "./components/DynamicDescriptionElement";
import DynamicCheckoutElement from "./components/DynamicCheckoutElement";
import DynamicTitleElement from "./components/DynamicTitleElement";
import DynamicCartElement from "./components/DynamicCartElement";
import MapElement from "./components/MapElement";
import CodeElement from "./components/CodeElement";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ImageIcon from "@mui/icons-material/Image";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import AppsIcon from "@mui/icons-material/Apps";
import ShareIcon from "@mui/icons-material/Share";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import StorageIcon from "@mui/icons-material/Storage";
import TabIcon from "@mui/icons-material/Tab";
import MapIcon from "@mui/icons-material/Map";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import CodeIcon from "@mui/icons-material/Code";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SearchIcon from "@mui/icons-material/Search";
import BlurLinearIcon from "@mui/icons-material/BlurLinear";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import CollectionsIcon from "@mui/icons-material/Collections";
import MenuIcon from "@mui/icons-material/Menu";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import FontLoader from "react-google-font-loader";
import { FaMousePointer } from "react-icons/fa";
import CreateIcon from '@mui/icons-material/Create';
import ShapeElement from "./components/ShapeElement";

const WebBuilder = () => {
  const [viewportWidth, setViewportWidth] = useState("1920");
  const [points, setPoints] = useState([]);

  const [strokeWidth, setStrokeWidth] = useState(5);
  const [strokeColor, setStrokeColor] = useState('#000000'); // Default: black
  const [fillColor, setFillColor] = useState('#ffffff'); // Default: white

  const canvasRef = useRef(null);
  const [canvasHeight, setCanvasHeight] = useState(0)
  const [isDrawing, setIsDrawing] = useState(false);
  const [activeTools, setActiveTools] = useState(1)
  const [mobileDesigning, setMobileDesigning] = useState(false);
  const myRef = React.createRef();
  const [pageDropdown, setPageDropdown] = useState(false);
  const [pageDesigning, setPageDesigning] = useState("Home");
  const [newPageName, setNewPageName] = useState("");
  const [templateIndex, setTemplateIndex] = useState(null);
  const [customerToken, setCustomerToken] = useState(
    Cookies.get("customer_token") || null
  );
  const [availableTemplates, setAvailableTemplates] = useState([]);
  const [createTemplateModalIsOpen, setCreateTemplateModelIsOpen] =
    useState(false);
  const [draggingElement, setDraggingElement] = useState("");
  const [draggingElementDetails, setDraggingElementDetails] = useState(null);
  const [draggingDisplay, setDraggingDisplay] = useState(null);
  const [penMouseOver, setPenMouseOver] = useState(null)
  const [penDrawingOver, setPenDrawingOver] = useState(null)

  useEffect(() => {
    if (activeTools === 2 && points.length >= 1) {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);
      // Draw the default style first
      if (points.length >= 1) {
        context.lineWidth = strokeWidth;
        context.strokeStyle = strokeColor;
        context.fillStyle = fillColor;

        context.beginPath();
        context.moveTo(points[0].x, points[0].y);

        for (let i = 1; i < points.length; i++) {
          if (points[i].curvature) {
            if (points[i].curvature.x > 0 && points[i].curvature.y > 0) {
              const prevPCX = points[i - 1].x + (points[i - 1].x - points[i - 1].curvature.x)
              const prevPCY = points[i - 1].y + (points[i - 1].y - points[i - 1].curvature.y)
              context.bezierCurveTo(prevPCX, prevPCY, points[i].curvature.x, points[i].curvature.y, points[i].x, points[i].y);
            }
          } else {
            context.lineTo(points[i].x, points[i].y);
          }
        }

        if (isDrawing) {
          if (penDrawingOver) {
            if (penDrawingOver.curvature) {
              if (penDrawingOver.curvature.x > 0 && penDrawingOver.curvature.y > 0) {
                const i = points.length

                if (i >= 1) {
                  const prevPCX = points[i - 1].x + (points[i - 1].x - points[i - 1].curvature.x)
                  const prevPCY = points[i - 1].y + (points[i - 1].y - points[i - 1].curvature.y)
                  context.bezierCurveTo(prevPCX, prevPCY, penDrawingOver.curvature.x, penDrawingOver.curvature.y, penDrawingOver.x, penDrawingOver.y);
                } else {
                  context.lineTo(penDrawingOver.x, penDrawingOver.y);
                }
              }
            } else {
              context.lineTo(penDrawingOver.x, penDrawingOver.y);
            }
          }
        }

        // Fill the shape with the specified fill color
        context.fill();

        // Draw the stroke with the specified stroke color and width
        context.stroke();
      }

      context.lineWidth = 1;
      context.strokeStyle = "#5e86c4";

      context.beginPath();
      context.moveTo(points[0].x, points[0].y);

      for (let i = 1; i < points.length; i++) {
        if (points[i].curvature) {
          if (points[i].curvature.x > 0 && points[i].curvature.y > 0) {
            const prevPCX = points[i - 1].x + (points[i - 1].x - points[i - 1].curvature.x)
            const prevPCY = points[i - 1].y + (points[i - 1].y - points[i - 1].curvature.y)
            context.bezierCurveTo(prevPCX, prevPCY, points[i].curvature.x, points[i].curvature.y, points[i].x, points[i].y);
          }
        } else {
          context.lineTo(points[i].x, points[i].y);
        }
      }
      if (isDrawing) {
        if (penDrawingOver) {
          if (penDrawingOver.curvature) {
            if (penDrawingOver.curvature.x > 0 && penDrawingOver.curvature.y > 0) {
              const i = points.length
              if (i >= 1) {
                const prevPCX = points[i - 1].x + (points[i - 1].x - points[i - 1].curvature.x)
                const prevPCY = points[i - 1].y + (points[i - 1].y - points[i - 1].curvature.y)
                context.bezierCurveTo(prevPCX, prevPCY, penDrawingOver.curvature.x, penDrawingOver.curvature.y, penDrawingOver.x, penDrawingOver.y);
              } else {

                context.lineTo(penDrawingOver.x, penDrawingOver.y);
              }
            }
          } else {
            context.lineTo(penDrawingOver.x, penDrawingOver.y);
          }
        }
      } else if (penMouseOver) {
        context.lineTo(penMouseOver.x, penMouseOver.y);
      }

      // Draw the stroke with the specified default style
      context.stroke();



      if (isDrawing) {
        if (penDrawingOver) {
          if (penDrawingOver.curvature) {
            if (penDrawingOver.curvature.x > 0 && penDrawingOver.curvature.y > 0) {
              context.lineWidth = 1;
              context.strokeStyle = "#5e86c4";

              context.beginPath();
              context.moveTo(penDrawingOver.curvature.x, penDrawingOver.curvature.y);
              const currentCOPX = penDrawingOver.x - penDrawingOver.curvature.x + penDrawingOver.x
              const currentCOPY = penDrawingOver.y - penDrawingOver.curvature.y + penDrawingOver.y

              context.lineTo(currentCOPX, currentCOPY);

              context.stroke();
            }
          }
        }
      }
    }
  }, [activeTools, points, strokeWidth, strokeColor, fillColor, penMouseOver]);


  const getSectionTemplates = async () => {
    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/get-templates",
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      const sec = data.elements;
      if (sec.length > 0) {
        setAvailableTemplates(sec);
        console.log(sec);
      }

      return data;
    } catch (error) {
      console.error("Error fetching web sections:", error);
      return null;
    }
  };

  const [currency, setCurrency] = useState("$");
  useEffect(() => {
    const getCurrencyAndShipping = async () => {
      const token = Cookies.get("token");
      const response = await fetch(
        "https://ww1.premelo.com/api/stripe/getcands",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      if (json.commerce_array) {
        if (json.commerce_array.currency) {
          if (json.commerce_array.currency.display) {
            setCurrency(json.commerce_array.currency.display);
          }
        }
      }
    };
    getCurrencyAndShipping();
    getSectionTemplates();
  }, []);

  const domain = new URLSearchParams(window.location.search).get("domain");
  const webId = new URLSearchParams(window.location.search).get("webID");

  const [customerAccountDetails, setCustomerAccountDetails] = useState({
    id: null,
    username: null,
    email: null,
    created_at: null,
    order_history: null,
    checkout_details: null,
    cart: null,
    wishlist: null,
  });
  const [stripeId, setStripeId] = useState("");

  useEffect(() => {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (clientSecret) {
      setPageDesigning("Checkout_Dynamic");
    }
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  const domainEditing = urlParams.get("domain");

  const handleGetCustomerDetails = async (customerToken1) => {
    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/customer/get-customer-details",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${customerToken1}`,
          },
        }
      );
      if (response.status === 200) {
        const json = await response.json();
        const updatingAccountDetails = { ...customerAccountDetails };
        if (customerAccountDetails.cart) {
          updatingAccountDetails.cart = [
            ...(customerAccountDetails.cart || []),
            ...(json.userDetails.cart || []),
          ];
        } else {
          updatingAccountDetails.cart = [...(json.userDetails.cart || [])];
        }
        console.log([
          ...(customerAccountDetails.cart || []),
          ...(json.userDetails.cart || []),
        ]);
        updatingAccountDetails.checkout_details =
          json.userDetails.checkout_details;
        updatingAccountDetails.created_at = json.userDetails.created_at;
        updatingAccountDetails.domain_name = json.userDetails.domain_name;
        updatingAccountDetails.email = json.userDetails.email;
        updatingAccountDetails.id = json.userDetails.id;
        updatingAccountDetails.order_history = json.userDetails.order_history;
        updatingAccountDetails.updated_at = json.userDetails.updated_at;
        updatingAccountDetails.username = json.userDetails.username;
        updatingAccountDetails.wishlist = json.userDetails.wishlist;

        setCustomerAccountDetails(updatingAccountDetails);
      } else if (response.status === 401) {
        Cookies.remove("customer_token");
        setCustomerToken(null);
        // setView("login");
        // You can handle unauthorized access here, such as displaying an error message.
      } else {
        console.log("An error occurred.");
        // Handle other response statuses as needed.
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (customerToken) {
      handleGetCustomerDetails(customerToken);
    }
  }, [customerToken]);

  const [activeWindowStat, setActiveWindowStat] = useState({
    x: 0,
    y: 170,
  });

  const [sectionResizing, setSectionResizing] = useState(false);

  const [primaryColor, setPrimaryColor] = useState(
    Cookies.get("company_color") ?? "#1872d9"
  );

  const [favicon, setFavicon] = useState(null);
  const [projectName, setProjectName] = useState(null);

  useEffect(() => {
    if (favicon && projectName) {
      console.log("favicon and projectname is changed");
      document.title = projectName; // Set the new title here
      const linkElement = document.querySelector("link[rel=icon]");
      linkElement.href = favicon;
    }
  }, [favicon, projectName]);

  useEffect(() => {
    const handleResize = () => {
      setViewportWidth(window.innerWidth - 6);
    };

    // Call handleResize initially to set the initial screen width
    handleResize();

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [resizingElementId, setResizingElementId] = useState(null);
  const [baseElement, setBaseElement] = useState([]);
  const [baseSections, setBaseSections] = useState([]);
  const [basePages, setBasePages] = useState([]);

  const [sections, setSections] = useState({
    Home: [],
    Product_Dynamic: [],
    Event_Dynamic: [],
    News_Dynamic: [],
    Checkout_Dynamic: [],
    My_Accounts_Dynamic: [],
    Cart_Dynamic: [],
  });
  const [mobileSections, setMobileSections] = useState([]);



  useEffect(() => {
    let pageHeight = 0
    if (mobileDesigning) {
      if (pageDesigning) {
        if (mobileSections[pageDesigning]) {
          const sectionss = mobileSections[pageDesigning]
          for (const sec of sectionss) {
            pageHeight += sec.mheight ? sec.mheight : sec.height
          }
        }
      }
    } else {
      if (pageDesigning) {
        if (sections[pageDesigning]) {
          const sectionss = sections[pageDesigning]
          for (const sec of sectionss) {
            pageHeight += sec.height
          }
        }
      }
    }
    setCanvasHeight(pageHeight)
  }, [sections, mobileSections])

  // Creates the sections
  useEffect(() => {
    let transformedDesktopData = {};
    const basePages1 = cloneDeep(basePages);
    const baseElement2 = cloneDeep(baseElement);
    const baseSections3 = cloneDeep(baseSections);

    const transformDataMobile = () => {
      // Initialize combinedData with each page name as a key
      basePages1.forEach((page) => {
        transformedDesktopData[page.name] = [];
      });

      baseSections3.forEach((section) => {
        if (transformedDesktopData[section.parent.id]) {
          transformedDesktopData[section.parent.id].push(section);
        } else if (section.id === "header" || section.id === "footer") {
          Object.keys(transformedDesktopData).forEach((pageName) => {
            transformedDesktopData[pageName].push(section);
          });
        }
      });
      function findKeyById(id, data) {
        for (const key in data) {
          const sectionArray = data[key];
          const foundSection = sectionArray.find(
            (section) => section.id === id
          );
          if (foundSection) {
            return key;
          }
        }
        return null; // Return null if not found
      }

      // Combine baseElement into relevant sections and boxes
      baseElement2.forEach((element) => {
        const parentSectionId = element.parent.id;

        const parentSectionKey = findKeyById(
          parentSectionId,
          transformedDesktopData
        );

        if (element.parent.type === "section") {
          if (parentSectionKey) {
            const sectionIndex = transformedDesktopData[
              parentSectionKey
            ].findIndex((section) => section.id === parentSectionId);

            const elementExist = transformedDesktopData[
              parentSectionKey
            ].findIndex((section) =>
              section.children.some((elements) => elements.id === element.id)
            );
            if (elementExist > -1) {
              const elementIndex = transformedDesktopData[parentSectionKey][
                elementExist
              ].children.findIndex((elements) => elements.id === element.id);
              if (elementIndex > -1) {
                transformedDesktopData[parentSectionKey][
                  elementExist
                ].children.splice(elementIndex, 1);
              }
            }
            // console.log(sectionIndex);
            if (sectionIndex !== -1) {
              if (
                !transformedDesktopData[parentSectionKey][
                  sectionIndex
                ].children.some((child) => child.id === element.id)
              ) {
                // console.log("inserting into section");
                transformedDesktopData[parentSectionKey][
                  sectionIndex
                ].children.push(element);
              }
            }
          }
        }
      });

      // console.log(transformedDesktopData);
      setSections(transformedDesktopData);
      transformedDesktopData = {};
    };

    transformDataMobile();
  }, [baseElement, baseSections, basePages]);

  useEffect(() => {
    let transformedMobileData = {};

    const basePages1 = cloneDeep(basePages);
    const baseElement2 = cloneDeep(baseElement);
    const baseSections3 = cloneDeep(baseSections);
    const transformDataMobile = () => {
      // Initialize combinedData with each page name as a key
      basePages1.forEach((page) => {
        transformedMobileData[page.name] = [];
      });

      // Combine baseSections into relevant pages
      baseSections3.forEach((section) => {
        if (transformedMobileData[section.parent.id]) {
          transformedMobileData[section.parent.id].push(section);
        } else if (section.id === "header" || section.id === "footer") {
          Object.keys(transformedMobileData).forEach((pageName) => {
            transformedMobileData[pageName].push(section);
          });
        }
      });
      function findKeyById(id, data) {
        for (const key in data) {
          const sectionArray = data[key];
          const foundSection = sectionArray.find(
            (section) => section.id === id
          );
          if (foundSection) {
            return key;
          }
        }
        return null; // Return null if not found
      }

      // Combine baseElement into relevant sections and boxes
      baseElement2.forEach((element) => {
        const sectionId = element.mobile.id;

        const sectionKey = findKeyById(sectionId, transformedMobileData);

        if (element.mobile.type === "section") {
          if (sectionKey) {
            const sectionIndex = transformedMobileData[sectionKey].findIndex(
              (section) => section.id === sectionId
            );

            const elementExist = transformedMobileData[sectionKey].findIndex(
              (section) =>
                section.children.some((elements) => elements.id === element.id)
            );
            if (elementExist > -1) {
              const elementIndex = transformedMobileData[sectionKey][
                elementExist
              ].children.findIndex((elements) => elements.id === element.id);
              if (elementIndex > -1) {
                transformedMobileData[sectionKey][elementExist].children.splice(
                  elementIndex,
                  1
                );
              }
            }

            if (sectionIndex !== -1) {
              if (
                !transformedMobileData[sectionKey][sectionIndex].children.some(
                  (child) => child.id === element.id
                )
              ) {
                // console.log("inserting into section");
                transformedMobileData[sectionKey][sectionIndex].children.push(
                  element
                );
              }
            }
          }
        }
      });

      setMobileSections(transformedMobileData);
      transformedMobileData = {};
    };

    transformDataMobile();
  }, [baseElement, baseSections, basePages]);

  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);

  // Listen for changes in the 'sections' state and update undo/redo stacks

  // Undo function
  const handleUndo = () => {
    console.log(undoStack);
    if (undoStack.length > 0) {
      const prevState = undoStack.pop();
      setSections(prevState);
    }
  };

  // Redo function
  const handleRedo = () => {
    if (redoStack.length > 0) {
      const nextState = redoStack.pop();
      setSections(nextState);
    }
  };

  const [draggedOverSectionId, setDraggedOverSectionId] = useState(null);
  const [draggedElementSectionId, setDraggedElementSectionId] = useState(null);
  const [draggingSectionPositionY, setDraggingSectionPositionY] = useState("");
  const [mouseOnSection, setMouseOnSection] = useState(null);
  const [scrollTop, setScrollTop] = useState(0);
  const [openRightToolbar, setOpenRightToolbar] = useState(true);
  const [openLeftToolbar, setOpenLeftToolbar] = useState(true);
  const [openGrid, setOpenGrid] = useState(true);
  const [focusElement, setFocusElement] = useState(null);
  const [focusElementType, setFocusElementType] = useState(null);
  const [fontSize, setFontSize] = useState("");
  const [fontWeight, setFontWeight] = useState("");
  const [lineHeight, setLineHeight] = useState("");
  const [letterSpacing, setLetterSpacing] = useState("");
  const [textAlign, setTextAlign] = useState("");
  const [textAlignItems, setTextAlignItems] = useState("");
  const [focusWidth, setFocusWidth] = useState("");
  const [focusHeight, setFocusHeight] = useState("");
  const [focusImage, setFocusImage] = useState("");
  const [focusBackgroundColor, setFocusBackgroundColor] = useState("");
  const [focusColor, setFocusColor] = useState("");
  const [srcImage, setSrcImage] = useState("");
  const [objectFit, setObjectFit] = useState("");
  const [alignmentGuides, setAlignmentGuides] = useState([]);
  const [draggingAlignmentGuides, setDraggingAlignmentGuides] = useState([]);
  const [showDraggingGuides, setShowDraggingGuides] = useState(false);
  const [snappingRate, setSnappingRate] = useState(5);
  const [selectedElements, setSelectedElements] = useState([]);
  const [selectedSection, setSelectedSection] = useState();
  const [selectedSectionIndex, setSelectedSectionIndex] = useState();
  const boundingBoxRef = useRef(null);
  const [boundingBoxDimensions, setBoundingBoxDimensions] = useState(null);
  const [copiedElement, setCopiedElement] = useState(null);
  const [notInputFocus, setNotInputFocus] = useState(true);
  const [editingInputWithArrow, setEditingInputWithArrow] = useState(false);
  const token = Cookies.get("token");
  const [companyDomain, setCompanyDomain] = useState("");
  const [publishLoading, setPublishLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [focusOpacity, setFocusOpacity] = useState("");
  const [focusBoxShadow, setFocusBoxShadow] = useState("");
  const [focusBorder, setFocusBorder] = useState("");
  const [focusBorderRadius, setFocusBorderRadius] = useState("");

  const handleAddPage = () => {
    if (newPageName.trim() === "") {
      return; // Don't add empty page names
    }
    const headerExistIndex = sections[pageDesigning].findIndex(
      (headerId) => headerId.id === "header"
    );
    console.log(headerExistIndex);
    if (headerExistIndex > -1) {
      const footerIndex = sections[pageDesigning].findIndex(
        (headerId) => headerId.id === "footer"
      );
      const headerCopy = cloneDeep(sections[pageDesigning][headerExistIndex]);
      const footerCopy = cloneDeep(sections[pageDesigning][footerIndex]);
      const footerUpdated = { ...footerCopy };
      const headerUpdated = { ...headerCopy };
      footerUpdated.position.y = headerCopy.height + 500;
      footerUpdated.parent = { type: "page", id: newPageName };
      headerUpdated.parent = { type: "page", id: newPageName };

      const newSection = {
        id: Date.now().toString() + "section_base",
        position: {
          x: 0,
          y: headerCopy.height,
        },
        height: 500,
        width: "100%",
        parent: { type: "page", id: newPageName },
        children: [],
        backgroundColor: "white",
        src: "",
        opacity: "100",
      };
      const newPage = {
        id: Date.now().toString() + "page_base",
        name: newPageName,
      };

      // setSections((prevSections) => ({
      //   ...prevSections,
      //   [newPageName]: [headerCopy, newSection, footerUpdated],
      // }));

      const updatingBaseSection = [...baseSections];
      updatingBaseSection.splice(1, 0, newSection);
      setBaseSections(updatingBaseSection);
      setBasePages([...basePages, newPage]);
    } else {
      const newSection = {
        id: Date.now().toString() + "section_base",
        position: {
          x: 0,
          y: 0,
        },
        height: 500,
        width: "100%",
        parent: { type: "page", id: newPageName },
        children: [],
        backgroundColor: "white",
        src: "",
        opacity: "100",
      };

      const newPage = {
        id: Date.now().toString() + "page_base",
        name: newPageName,
      };

      const updatingBaseSection = [...baseSections];
      updatingBaseSection.splice(1, 0, newSection);
      setBaseSections(updatingBaseSection);
      setBasePages([...basePages, newPage]);
    }
    setNewPageName(""); // Reset the input field
    setSelectedSectionIndex(null);
    setSelectedSection(null);
    setFocusElement(null);
    setFocusElementType(null);
    setPageDesigning(newPageName);
    console.log(sections);
  };

  const getWebSections = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const color = urlParams.get("domain");
      const web_id = urlParams.get("webID");
      const whatToSend = color ? { domain_name: color } : { web_id: parseFloat(web_id) }
      const response = await fetch(
        "https://ww1.premelo.com/api/web-builder/get-save-web",
        {
          body: JSON.stringify(whatToSend),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "POST",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();

      return data;
    } catch (error) {
      console.error("Error fetching web sections:", error);
      return null;
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const fetchSections = async () => {
      const webSections = await getWebSections();
      if (webSections) {
        const sec = webSections.pages;
        if (sec) {
          if (webSections.elements) {
            setBaseElement(webSections.elements);
          }
          if (webSections.sections) {
            setBaseSections(webSections.sections);
          }
          if (webSections.pages) {
            setBasePages(webSections.pages);
          }
          if (webSections.favicon) {
            setFavicon(webSections.favicon);
          }
          if (webSections.project_name) {
            setProjectName(webSections.project_name);
            console.log(webSections.project_name);
          }
        } else {
          setBasePages([
            { id: "2319293", name: "Home" },
            { id: "12354353", name: "Product_Dynamic" },
            { id: "12363564654", name: "Event_Dynamic" },
            { id: "231f234234ger359293", name: "News_Dynamic" },
            { id: "234265473", name: "Checkout_Dynamic" },
            { id: "234265qwweq473", name: "My_Accounts_Dynamic" },
            { id: "34625245353764", name: "Cart_Dynamic" },
          ]);
          setBaseSections([
            {
              backgroundColor: "#282828",
              children: [],
              height: 100,
              id: "header",
              opacity: "100",
              parent: { type: "page", id: "all" },
              position: { x: 0, y: 0, my: 0 },
              ref: null,
              src: null,
              width: "100%",
            },
            {
              id: "abcdef123456_Product_Dynamic",
              position: {
                x: 0,
                y: 100,
              },
              height: 500,
              mheight: 632,
              width: "100%",
              parent: { type: "page", id: "Product_Dynamic" },
              children: [],
              backgroundColor: "white",
              src: "",
              opacity: "100",
            },
            {
              id: "abcdef123456_Event_Dynamic",
              position: {
                x: 0,
                y: 100,
              },
              height: 500,
              width: "100%",
              parent: { type: "page", id: "Event_Dynamic" },
              children: [],
              backgroundColor: "white",
              src: "",
              opacity: "100",
            },
            {
              id: "abcdef123456_News_Dynamic",
              position: {
                x: 0,
                y: 100,
              },
              height: 500,
              width: "100%",
              parent: { type: "page", id: "News_Dynamic" },
              children: [],
              backgroundColor: "white",
              src: "",
              opacity: "100",
            },
            {
              id: "abcdef123456_Checkout_Dynamic",
              position: {
                x: 0,
                y: 100,
              },
              height: 611,
              mheight: 671,
              width: "100%",
              parent: { type: "page", id: "Checkout_Dynamic" },
              children: [],
              backgroundColor: "white",
              src: "",
              opacity: "100",
            },
            {
              id: "abcdef123456_My_Accounts_Dynamic",
              position: {
                x: 0,
                y: 100,
              },
              height: 624,
              mheight: 831,
              width: "100%",
              parent: { type: "page", id: "My_Accounts_Dynamic" },
              children: [],
              backgroundColor: "white",
              src: "",
              opacity: "100",
            },
            {
              id: "abcdef123456_Cart_Dynamic",
              position: {
                x: 0,
                y: 100,
              },
              height: 685,
              mheight: 920,
              width: "100%",
              parent: { type: "page", id: "Cart_Dynamic" },
              children: [],
              backgroundColor: "white",
              src: "",
              opacity: "100",
            },
            {
              id: "abcdef123456_Home",
              position: {
                x: 0,
                y: 100,
              },
              height: 500,
              width: "100%",
              parent: { type: "page", id: "Home" },
              children: [],
              backgroundColor: "white",
              src: "",
              opacity: "100",
            },
            {
              backgroundColor: "#282828",
              children: [],
              height: 300,
              id: "footer",
              opacity: "100",
              parent: { type: "page", id: "all" },
              position: { x: 0, y: 0, my: 0 },
              ref: null,
              src: null,
              width: "100%",
            },
          ]);
          setBaseElement([
            {
              alignItems: "flex-start",
              backgroundColor: "none",
              border: { px: "0", solid: "solid", color: "black" },
              borderRadius: "0",
              children: [],
              color: "#1f1f1f",
              content: "",
              fontSize: 14,
              fontWeight: 300,
              height: 557,
              id: "1699785312366",
              letterSpacing: 1,
              lineHeight: 15,
              mheight: 850,
              mobile: { type: "section", id: "abcdef123456_Cart_Dynamic" },
              mwidth: 93.38541666666667,
              objectFit: "cover",
              opacity: 100,
              parent: { type: "section", id: "abcdef123456_Cart_Dynamic" },
              position: { x: 16.25, y: 50, mx: 2.0833333333333335, my: 45 },
              ref: null,
              shadow: {
                horizontal: "0",
                vertical: "0",
                blur: "0",
                color: "black",
              },
              slide: null,
              src: "",
              textAlign: "left",
              type: "dynamic-cart-element",
              width: 67.13541666666667,
            },
            {
              alignItems: "flex-start",
              backgroundColor: "none",
              border: { px: "0", solid: "solid", color: "black" },
              borderRadius: "0",
              children: [],
              color: "#1f1f1f",
              content: "",
              fontSize: 14,
              fontWeight: 300,
              height: 498,
              id: "1699785234441",
              letterSpacing: 1,
              lineHeight: 15,
              mheight: 608,
              mobile: { type: "section", id: "abcdef123456_Checkout_Dynamic" },
              mwidth: 88.1048951048951,
              objectFit: "cover",
              opacity: 100,
              parent: { type: "section", id: "abcdef123456_Checkout_Dynamic" },
              position: {
                x: 17.916666666666668,
                y: 60,
                mx: 4.916666666666667,
                my: 40,
              },
              ref: null,
              shadow: {
                horizontal: "0",
                vertical: "0",
                blur: "0",
                color: "black",
              },
              slide: null,
              src: "",
              textAlign: "left",
              type: "dynamic-checkout-element",
              width: 65.1048951048951,
            },
            {
              alignItems: "flex-start",
              backgroundColor: "none",
              border: { px: "0", solid: "solid", color: "black" },
              borderRadius: "0",
              children: [],
              color: "#1f1f1f",
              content: "",
              fontSize: 14,
              fontWeight: 300,
              height: 546,
              id: "1699785286841",
              letterSpacing: 1,
              lineHeight: 15,
              mheight: 741,
              mobile: {
                type: "section",
                id: "abcdef123456_My_Accounts_Dynamic",
              },
              mwidth: 92.81510416666667,
              objectFit: "cover",
              opacity: 100,
              parent: {
                type: "section",
                id: "abcdef123456_My_Accounts_Dynamic",
              },
              position: {
                x: 17.916666666666668,
                y: 50,
                mx: 2.166666666666667,
                my: 65,
              },
              ref: null,
              shadow: {
                horizontal: "0",
                vertical: "0",
                blur: "0",
                color: "black",
              },
              slide: null,
              src: "",
              textAlign: "left",
              type: "dynamic-account-tabs-element",
              width: 63.81510416666667,
            },
            {
              alignItems: "flex-start",
              backgroundColor: "none",
              border: { px: "0", solid: "solid", color: "black" },
              borderRadius: "0",
              children: [],
              color: "#1f1f1f",
              content: "",
              fontSize: 14,
              fontWeight: 300,
              height: 352,
              id: "1699784646760",
              letterSpacing: 1,
              lineHeight: 15,
              mheight: 273,
              mobile: { type: "section", id: "abcdef123456_Product_Dynamic" },
              mwidth: 84.1328125,
              objectFit: "cover",
              opacity: 100,
              parent: { type: "section", id: "abcdef123456_Product_Dynamic" },
              position: {
                x: 16.666666666666668,
                y: 35,
                mx: 7.083333333333334,
                my: 25,
              },
              ref: null,
              shadow: {
                horizontal: "0",
                vertical: "0",
                blur: "0",
                color: "black",
              },
              slide: null,
              src: "",
              textAlign: "left",
              type: "dynamic-image-element",
              width: 31.1328125,
            },
            {
              alignItems: "flex-start",
              backgroundColor: "none",
              border: { px: "0", solid: "solid", color: "black" },
              borderRadius: "0",
              children: [],
              color: "#1f1f1f",
              content: "",
              fontSize: 14,
              fontWeight: 300,
              height: 34,
              id: "1699784665270",
              letterSpacing: 1,
              lineHeight: 15,
              mheight: 34,
              mobile: { type: "section", id: "abcdef123456_Product_Dynamic" },
              mtitleTextAlign: "center",
              mwidth: 80.47916666666667,
              objectFit: "cover",
              opacity: 100,
              parent: { type: "section", id: "abcdef123456_Product_Dynamic" },
              position: {
                x: 49.93464052287582,
                y: 96,
                mx: 9.166666666666668,
                my: 320,
              },
              ref: null,
              shadow: {
                horizontal: "0",
                vertical: "0",
                blur: "0",
                color: "black",
              },
              slide: null,
              src: "",
              textAlign: "left",
              type: "dynamic-title-element",
              width: 31.979166666666668,
            },
            {
              alignItems: "flex-start",
              backgroundColor: "none",
              border: { px: "0", solid: "solid", color: "black" },
              borderRadius: "0",
              children: [],
              color: "#1f1f1f",
              content: "",
              fontSize: 14,
              fontWeight: 300,
              height: 90,
              id: "1699784675319",
              letterSpacing: 1,
              lineHeight: 15,
              mheight: 90,
              mobile: { type: "section", id: "abcdef123456_Product_Dynamic" },
              mtitleTextAlign: "center",
              mwidth: 87.24739583333334,
              objectFit: "cover",
              opacity: 100,
              parent: { type: "section", id: "abcdef123456_Product_Dynamic" },
              position: {
                x: 49.93464052287582,
                y: 141,
                mx: 5.833333333333334,
                my: 360,
              },
              ref: null,
              shadow: {
                horizontal: "0",
                vertical: "0",
                blur: "0",
                color: "black",
              },
              slide: null,
              src: "",
              textAlign: "left",
              titleTextAlign: "flex-start",
              type: "dynamic-description-element",
              width: 33.997395833333336,
            },
            {
              alignItems: "flex-start",
              backgroundColor: "none",
              border: { px: "0", solid: "solid", color: "black" },
              borderRadius: "0",
              children: [],
              color: "#1f1f1f",
              content: "",
              fontSize: 14,
              fontWeight: 300,
              height: 165,
              id: "1699784702674",
              letterSpacing: 1,
              lineHeight: 15,
              mbuttonTextAlign: "center",
              mheight: 165,
              mobile: { type: "section", id: "abcdef123456_Product_Dynamic" },
              mpriceTextAlign: "center",
              mwidth: 83.45052083333333,
              objectFit: "cover",
              opacity: 100,
              parent: { type: "section", id: "abcdef123456_Product_Dynamic" },
              position: {
                x: 49.93464052287582,
                y: 201,
                mx: 7.916666666666667,
                my: 460,
              },
              priceTextAlign: "flex-start",
              ref: null,
              shadow: {
                horizontal: "0",
                vertical: "0",
                blur: "0",
                color: "black",
              },
              slide: null,
              src: "",
              textAlign: "left",
              type: "dynamic-price-element",
              width: 29.700520833333332,
            },
            {
              alignItems: "flex-start",
              backgroundColor: "white",
              border: { px: "0", solid: "solid", color: "black" },
              borderRadius: "0",
              buttonFontColor: "rgba(255, 255, 255, 1)",
              buttonTextAlign: "center",
              children: [],
              color: "#1f1f1f",
              content: "",
              flexWrap: 3,
              fontSize: 14,
              fontWeight: 300,
              height: 27,
              id: "1699784242378",
              images: [],
              letterSpacing: 1,
              lineHeight: 15,
              mheight: 42,
              mobile: { type: "section", id: "header" },
              mwidth: 15.2109375,
              objectFit: "cover",
              opacity: 100,
              parent: { type: "section", id: "header" },
              position: {
                x: 32.16666666666668,
                y: 40,
                mx: 80.08333333333334,
                my: 30,
              },
              ref: null,
              shadow: {
                horizontal: "0",
                vertical: "0",
                blur: "0",
                color: "lightgrey",
              },
              slide: null,
              src: "",
              textAlign: "left",
              type: "menu-element",
              width: 36.2109375,
            },
            {
              alignItems: "flex-start",
              backgroundColor: "none",
              border: { px: "0", solid: "solid", color: "black" },
              borderRadius: "0",
              children: [],
              color: "#1f1f1f",
              content: "",
              fontSize: 14,
              fontWeight: 300,
              height: 48,
              id: "1699784222122",
              letterSpacing: 1,
              lineHeight: 15,
              mheight: 46,
              mobile: { type: "section", id: "abcdef123456_Home" },
              mwidth: 53.29427083333333,
              objectFit: "cover",
              opacity: 100,
              parent: { type: "section", id: "header" },
              position: { x: 75, y: 27.5, mx: -55.41666666666667, my: 400 },
              ref: null,
              shadow: {
                horizontal: "0",
                vertical: "0",
                blur: "0",
                color: "black",
              },
              slide: null,
              src: null,
              textAlign: "left",
              type: "dynamic-account-header-element",
              width: 13.294270833333332,
            },
            {
              alignItems: "flex-start",
              backgroundColor: "white",
              border: { px: "0", solid: "solid", color: "black" },
              borderRadius: "0",
              children: [],
              color: "#1f1f1f",
              content: "",
              fontSize: 14,
              fontWeight: 300,
              height: 55,
              id: "1699433896902",
              letterSpacing: 1,
              lineHeight: 15,
              mheight: 55,
              mobile: { type: "section", id: "header" },
              mwidth: 15.091145833333332,
              objectFit: "cover",
              opacity: 100,
              parent: { type: "section", id: "header" },
              position: { x: 11.783333333333333, y: 25, mx: 5.283333333333333 },
              ref: null,
              shadow: {
                horizontal: "0",
                vertical: "0",
                blur: "0",
                color: "black",
              },
              slide: null,
              src: "https://storage.googleapis.com/projectcrm_bucket/654b4dc2c3884_calc_zdv-sk1c80.png?GoogleAccessId=antehive%40projectcrm-379714.iam.gserviceaccount.com&Expires=4855107523&Signature=WLfA8WwO8Syx83o37mSc0e56SNIoosToGSg%2FTbJi0su5wNRDrMZmbKnBSbHTI34ol92JJul6RIK41rEJbTuaoYr5xyT%2FBKtLVLTqgWItpqq%2FbgAoNsZMyKU4IvnuILtKBVncEGOXPO5cFUdjV7GDN7bOix4uXzezouN2iOPfG6hxJvvDvumN9DzGDu%2BjLo7vVTtws3%2FcZ6X%2BYbtSycEViMqNvFshixwiZWFQbInmBZNkElWOhUecVhi9JBQ3FRKZGcgnoeZHioCFr0GoBs2tbHf9GNbtYxUEA035jqsBsYsmKMwhpWacUpcWp3B9Xvs2q1babqM%2F4nzkYWd2V09JnA%3D%3D&generation=1699433922887693",
              textAlign: "left",
              type: "image-element",
              width: 3.841145833333333,
            },
            {
              alignItems: "flex-start",
              backgroundColor: "none",
              border: { px: "0", solid: "solid", color: "black" },
              borderRadius: "0",
              children: [],
              color: "#1f1f1f",
              content: "Your company\nname",
              fontSize: 14,
              fontWeight: 300,
              height: 43,
              id: "1699434225528",
              letterSpacing: 1,
              lineHeight: 15,
              mheight: 37,
              mobile: { type: "section", id: "header" },
              mwidth: 40.604166666666664,
              objectFit: "cover",
              opacity: 100,
              parent: { type: "section", id: "header" },
              position: { x: 16.666666666666668, y: 32.5, mx: 28.75, my: 37.5 },
              ref: null,
              shadow: {
                horizontal: "0",
                vertical: "0",
                blur: "0",
                color: "black",
              },
              slide: null,
              src: null,
              textAlign: "left",
              titleFontColor: "rgba(255, 255, 255, 1)",
              titleFontSize: 18,
              titleTextAlign: "flex-start",
              type: "text-element",
              width: 10.104166666666666,
            },
            {
              alignItems: "flex-start",
              backgroundColor: "none",
              border: { px: "0", solid: "solid", color: "black" },
              borderRadius: "0",
              children: [],
              color: "#1f1f1f",
              content: "Welcome to your website\nbuilder",
              fontSize: 14,
              fontWeight: 300,
              height: 80,
              id: "1699432999008",
              letterSpacing: 1,
              lineHeight: 15,
              mheight: 42,
              mobile: { type: "section", id: "abcdef123456_Home" },
              mtitleFontSize: 21,
              mtitleLineHeight: 21,
              mwidth: 73.09635416666666,
              objectFit: "cover",
              opacity: 100,
              parent: { type: "section", id: "abcdef123456_Home" },
              position: {
                x: 33.088235294117645,
                y: 103,
                mx: 12.268229166666671,
                my: 107,
              },
              ref: null,
              shadow: {
                horizontal: "0",
                vertical: "0",
                blur: "0",
                color: "black",
              },
              slide: null,
              src: null,
              textAlign: "left",
              titleFontSize: 0,
              titleLineHeight: 33,
              type: "text-element",
              width: 33.346354166666664,
            },
            {
              alignItems: "flex-start",
              backgroundColor: "none",
              border: { px: "0", solid: "solid", color: "black" },
              borderRadius: "0",
              children: [],
              color: "#1f1f1f",
              content:
                "Everything you need to build your website is on the left, which are called elements. Everything you need to edit each element is located in the pop-up window which appears when you click on this element. \n\nWhat i am inside is called a section and that is adjustable on the right panel. (But you gotta click on the section first). Aside from that, pretty much everything is a drag and drop feature here!\n\nEnjoy our free website builder.\n\nOh and also templates are coming soon as well, just give us a little time to add that in a few weeks time.\n\nLove and kisses <3 from the dev team at Premelo.",
              fontSize: 14,
              fontWeight: 300,
              height: 203,
              id: "1699433031428",
              letterSpacing: 1,
              lineHeight: 15,
              mheight: 214,
              mobile: { type: "section", id: "abcdef123456_Home" },
              mtitleFontSize: 10,
              mtitleLineHeight: 14,
              mwidth: 80.96614583333333,
              objectFit: "cover",
              opacity: 100,
              parent: { type: "section", id: "abcdef123456_Home" },
              position: {
                x: 26.83823529411765,
                y: 195.5,
                mx: 8.333333333333336,
                my: 167,
              },
              ref: null,
              shadow: {
                horizontal: "0",
                vertical: "0",
                blur: "0",
                color: "black",
              },
              slide: null,

              src: null,
              textAlign: "left",
              titleFontSize: 13,
              titleFontWeight: 300,
              titleLineHeight: 16,
              type: "text-element",
              width: 45.71614583333333,
            },
          ]);
        }
      } else {
        console.log("Failed to fetch web sections.");
      }
    };

    fetchSections();
  }, []);

  const handleToggleHeaderAndFooter = () => {
    setSelectedSection(null);
    setSelectedSectionIndex(null);
    const updatedSections = { ...sections };
    const updatingBaseSections = [...baseSections];

    const headerIndex = sections[pageDesigning].findIndex(
      (header) => header.id === "header"
    );

    console.log(headerIndex);

    if (headerIndex > -1) {
      for (const page in updatedSections) {
        if (Object.hasOwnProperty.call(updatedSections, page)) {
          const pageSections = updatedSections[page];
          const headerIndex = pageSections.findIndex(
            (header) => header.id === "header"
          );

          if (headerIndex > -1) {
            const totalSections = pageSections.length - 2;

            for (let i = 1; i < pageSections.length; i++) {
              const changingSectionInsertionIndex =
                updatingBaseSections.findIndex(
                  (sectionssss) => sectionssss.id === pageSections[i].id
                );
              if (
                updatingBaseSections[changingSectionInsertionIndex].position.my
              ) {
                updatingBaseSections[
                  changingSectionInsertionIndex
                ].position.my -= pageSections[0].height;
              } else {
                updatingBaseSections[
                  changingSectionInsertionIndex
                ].position.my =
                  updatingBaseSections[changingSectionInsertionIndex].position
                    .y - pageSections[0].height;
              }
              updatingBaseSections[changingSectionInsertionIndex].position.y -=
                pageSections[0].height;
            }
          }
        }
      }
      const baseHeaderIndex = updatingBaseSections.findIndex(
        (sec) => sec.id === "header"
      );
      const baseFooterIndex = updatingBaseSections.findIndex(
        (sec) => sec.id === "footer"
      );
      updatingBaseSections.splice(baseFooterIndex, 1);
      updatingBaseSections.splice(baseHeaderIndex, 1);
      setBaseSections(updatingBaseSections);
    } else {
      //* header doesnt exist, add header and footer

      const updatedSections = { ...sections };
      const updatingBaseSections = [...baseSections];

      for (const page in updatedSections) {
        if (Object.hasOwnProperty.call(updatedSections, page)) {
          const pageSections = updatedSections[page];
          const totalSections = pageSections.length - 1;

          for (let i = 0; i < pageSections.length; i++) {
            const changingSectionInsertionIndex =
              updatingBaseSections.findIndex(
                (sectionssss) => sectionssss.id === pageSections[i].id
              );
            if (
              updatingBaseSections[changingSectionInsertionIndex].position.my
            ) {
              updatingBaseSections[
                changingSectionInsertionIndex
              ].position.my += 100;
            } else {
              updatingBaseSections[changingSectionInsertionIndex].position.my =
                updatingBaseSections[changingSectionInsertionIndex].position.y +
                100;
            }
            updatingBaseSections[
              changingSectionInsertionIndex
            ].position.y += 100;
          }
        }
      }

      const newHeader = {
        id: "header",
        position: {
          x: 0,
          y: 0,
          my: 0,
        },
        height: 100,
        parent: { type: "page", id: "all" },
        width: "100%",
        children: [],
        backgroundColor: "#282828",
        src: "",
        opacity: "100",
      };

      const newFooter = {
        id: "footer",
        position: {
          x: 0,
          y: 0,
          my: 0,
        },
        height: 300,
        width: "100%",
        parent: { type: "page", id: "all" },
        children: [],
        backgroundColor: "#282828",
        src: "",
        opacity: "100",
      };

      updatingBaseSections.splice(0, 0, newHeader);
      updatingBaseSections.push(newFooter);

      setBaseSections(updatingBaseSections);
    }
  };

  const handleSaveClick = async () => {
    // Call the function with your sections array
    const urlParams = new URLSearchParams(window.location.search);
    const color = urlParams.get("domain");
    const web_id = urlParams.get("webID");

    console.log("saving");

    const removedRefForBaseElement = [...baseElement];

    for (const base of removedRefForBaseElement) {
      base.ref = null;
    }

    const removedRefForBaseSections = [...baseSections];

    for (const sect of removedRefForBaseSections) {
      sect.ref = null;
    }
    const whatToSend = color ? {
      domain_name: color,
      webs: removedRefForBaseElement,
      mobile_webs: removedRefForBaseSections,
      pages: basePages,
      favicon: favicon ? favicon : null,
      project_name: projectName ? projectName : "Unnamed Website",
    } : {
      web_id: parseFloat(web_id),
      webs: removedRefForBaseElement,
      mobile_webs: removedRefForBaseSections,
      pages: basePages,
      favicon: favicon ? favicon : null,
      project_name: projectName ? projectName : "Unnamed Website",
    }

    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/web-builder/update-save-web",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(whatToSend),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to update web builder.");
      }
      setSaveLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePublishClick = async () => {
    // Call the function with your sections array
    const urlParams = new URLSearchParams(window.location.search);
    const color = urlParams.get("domain");
    const web_id = urlParams.get("webID");

    console.log("saving");

    const removedRefForBaseElement = [...baseElement];

    for (const base of removedRefForBaseElement) {
      base.ref = null;
    }

    const removedRefForBaseSections = [...baseSections];

    for (const sect of removedRefForBaseSections) {
      sect.ref = null;
    }

    const whatToSend = color ? {
      domain_name: color,
      webs: removedRefForBaseElement,
      mobile_webs: removedRefForBaseSections,
      pages: basePages,
      favicon: favicon ? favicon : null,
      project_name: projectName ? projectName : "Unnamed Website",
    } : {
      web_id: parseFloat(web_id),
      webs: removedRefForBaseElement,
      mobile_webs: removedRefForBaseSections,
      pages: basePages,
      favicon: favicon ? favicon : null,
      project_name: projectName ? projectName : "Unnamed Website",
    }

    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/web-builder/update-web",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(whatToSend),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to update web builder.");
      }
      setPublishLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const handleCopy = (event) => {
      // Check if there's a selected element, and if so, copy it to the state
      if (focusElement) {
        const sectionIndex = sections[pageDesigning].findIndex((element) =>
          element.children.some((child) => child.id === focusElement)
        );
        console.log(sectionIndex);
        if (sectionIndex !== -1) {
          const elementIndex = sections[pageDesigning][
            sectionIndex
          ].children.findIndex((element) => element.id === focusElement);
          console.log(
            sections[pageDesigning][sectionIndex].children[elementIndex]
          );
          setCopiedElement(
            sections[pageDesigning][sectionIndex].children[elementIndex]
          );
        } else {
          const sectionIndex = sections[pageDesigning].findIndex((element) =>
            element.children.some((child) =>
              child.children.some((ren) => ren.id === focusElement)
            )
          );
          const boxIndex = sections[pageDesigning][
            sectionIndex
          ].children.findIndex((section) =>
            section.children.some((child) => child.id === focusElement)
          );
          const elementIndex = sections[pageDesigning][sectionIndex].children[
            boxIndex
          ].children.findIndex((section) => section.id === focusElement);
          console.log(
            sections[pageDesigning][sectionIndex].children[boxIndex].children[
            elementIndex
            ]
          );
          setCopiedElement(
            sections[pageDesigning][sectionIndex].children[boxIndex].children[
            elementIndex
            ]
          );
        }
      }
    };
    document.addEventListener("copy", handleCopy);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("copy", handleCopy);
    };
  }, [focusElement]);

  useEffect(() => {
    const handlePaste = (event) => {
      const deepClone = cloneDeep(sections);
      undoStack.push(deepClone);
      console.log("paste function fired");

      if (boundingBoxDimensions) {
        const updatingBaseElement = [...baseElement];

        selectedElements.forEach((elementId) => {
          const elementIndex = updatingBaseElement.findIndex(
            (elements) => elements.id === elementId
          );

          const clonedElement = cloneDeep(updatingBaseElement[elementIndex]);
          const generateduuid = `${Date.now().toString()}` + `${uuid()}`;

          clonedElement.id = generateduuid;
          clonedElement.position.x += 5;
          clonedElement.position.mx =
            (updatingBaseElement[elementIndex].position.mx
              ? updatingBaseElement[elementIndex].position.mx
              : updatingBaseElement[elementIndex].position.x) + 5;
          clonedElement.position.y += 15;
          clonedElement.position.my =
            (updatingBaseElement[elementIndex].position.my
              ? updatingBaseElement[elementIndex].position.my
              : updatingBaseElement[elementIndex].position.y) + 15;

          updatingBaseElement.push(clonedElement);
        });

        setBaseElement(updatingBaseElement);
      } else if (focusElement && notInputFocus === true) {
        if (copiedElement) {
          const sectionIndex = sections[pageDesigning].findIndex((element) =>
            element.children.some((child) => child.id === copiedElement.id)
          );
          const elementIndex = sections[pageDesigning][
            sectionIndex
          ].children.findIndex((ele) => ele.id === copiedElement.id);
          const elementCopy = cloneDeep(
            sections[pageDesigning][sectionIndex].children[elementIndex]
          ); // Create a shallow copy of the copiedElement
          const updatingBaseElement = [...baseElement];

          const newCopy = {
            ...elementCopy,
            id: Date.now().toString(),
            position: {
              x: elementCopy.position.x + 5,
              y: elementCopy.position.y + 15,
              mx: elementCopy.position.mx + 5,
              my: elementCopy.position.my + 15,
            },
          };

          updatingBaseElement.push(newCopy);
          setBaseElement(updatingBaseElement);
        }
      }
    };

    document.addEventListener("paste", handlePaste);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  }, [
    focusElement,
    sections,
    copiedElement,
    notInputFocus,
    selectedElements,
    baseElement,
    boundingBoxDimensions,
  ]);

  const [dynamicList, setDynamicList] = useState([]);
  const [dynamicItem, setDynamicItem] = useState(null);

  useEffect(() => {
    const token = Cookies.get("token");
    if (pageDesigning === "Product_Dynamic") {
      const fetchProduct = async () => {
        try {
          const response = await fetch(
            "https://ww1.premelo.com/api/web-builder/get-product",
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const json = await response.json();
          setDynamicList(json.product_array);
          setDynamicItem(json.product_array[0]);
        } catch (error) {
          setDynamicList([])
          setDynamicItem(null)
          console.log(error);
        }
      };
      fetchProduct();
    } else if (pageDesigning === "Event_Dynamic") {
      const fetchEvents = async () => {
        try {
          const response = await fetch(
            "https://ww1.premelo.com/api/web-builder/get-event",
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const json = await response.json();
          setDynamicList(json.events);
          setDynamicItem(json.events[0]);
        } catch (error) {
          setDynamicList([])
          setDynamicItem(null)
          console.log(error);
        }
      };
      fetchEvents();
    } else if (pageDesigning === "News_Dynamic") {
      const fetchNews = async () => {
        try {
          const response = await fetch(
            "https://ww1.premelo.com/api/web-builder/get-news",
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            }
          );
          const json = await response.json();
          setDynamicList(json.news);
          setDynamicItem(json.news[0]);
        } catch (error) {
          setDynamicList([])
          setDynamicItem(null)
          console.log(error);
        }
      };
      fetchNews();
    } else {
      setDynamicList([]);
      setDynamicItem(null);
    }
  }, [pageDesigning]);

  useEffect(() => {
    const handleDelete = (event) => {
      // Check if there is a focused element and if the delete key is pressed

      if (focusElement && notInputFocus === true && event.key === "Delete") {
        if (boundingBoxDimensions) {
          console.log("removing from bounding box");
          const updatedElement = baseElement.filter(
            (element) => !selectedElements.includes(element.id)
          );
          setBoundingBoxDimensions(null);
          setSelectedElements([]);
          setBaseElement(updatedElement);
        } else {
          if (focusElementType === "section") {
            console.log("deleting section", focusElement, focusElementType);
            if (focusElement !== "header" && focusElement !== "footer") {
              setSelectedSectionIndex(null);
              setSelectedSection(null);
              setFocusElement(null);
              setFocusElementType(null);
              const sectionIndex = baseSections.findIndex(
                (sect) => sect.id === focusElement
              );
              const updatingBaseSection = [...baseSections];

              const updatedSections = [...sections[pageDesigning]];

              const runningIndex = updatedSections.findIndex(
                (sec) => sec.id === focusElement
              );

              for (let i = runningIndex + 1; i < updatedSections.length; i++) {
                if (updatedSections[i].id !== "footer") {
                  const changingSectionInsertionIndex =
                    updatingBaseSection.findIndex(
                      (sectionssss) => sectionssss.id === updatedSections[i].id
                    );

                  updatingBaseSection[
                    changingSectionInsertionIndex
                  ].position.y -= baseSections[sectionIndex].height;

                  updatingBaseSection[
                    changingSectionInsertionIndex
                  ].position.my -= baseSections[sectionIndex].mheight
                      ? baseSections[sectionIndex].mheight
                      : baseSections[sectionIndex].height;
                }
              }

              updatingBaseSection.splice(sectionIndex, 1);

              setBaseSections(updatingBaseSection);
            }
          } else {
            setSelectedSectionIndex(null);
            setSelectedSection(null);
            setFocusElement(null);
            setFocusElementType(null);

            const updatingElement = [...baseElement];
            const elementIndex = updatingElement.findIndex(
              (element) => element.id === focusElement
            );

            updatingElement.splice(elementIndex, 1);

            setBaseElement(updatingElement);
          }
        }
      }
    };

    document.addEventListener("keydown", handleDelete);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleDelete);
    };
  }, [
    focusElement,
    sections,
    setSections,
    baseElement,
    notInputFocus,
    boundingBoxDimensions,
    selectedElements,
  ]); // Add notInputFocus to the dependency array

  useEffect(() => {
    const handleArrowKey = (event) => {
      // Check if there is a focused element and if an arrow key is pressed
      console.log(event.key);
      if (
        focusElement &&
        notInputFocus === true &&
        editingInputWithArrow === false &&
        ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"].includes(event.key)
      ) {
        if (mobileDesigning) {
          const deepClone = cloneDeep(sections);
          undoStack.push(deepClone);
          console.log("arrow key listening");
          // Prevent the default arrow key behavior
          event.preventDefault();
          // Get the current focused element index and section index

          if (boundingBoxDimensions) {
            setBoundingBoxDimensions((prevDimensions) => {
              const updatedDimensions = {
                width: prevDimensions.width,
                height: prevDimensions.height,
                top: prevDimensions.top,
                left: prevDimensions.left,
              };

              if (event.key === "ArrowLeft") {
                updatedDimensions.left -= 0.5;

                selectedElements.forEach((elementId) => {
                  const updatedElement = [...baseElement];

                  const elementIndex = updatedElement.findIndex(
                    (elements) => elements.id === elementId
                  );

                  const element = updatedElement[elementIndex];

                  if (element.position.mx) {
                    element.position.mx -= 0.5;
                  } else {
                    element.position.mx = element.position.x - 0.5;
                  }

                  setBaseElement(updatedElement);
                });
              } else if (event.key === "ArrowRight") {
                updatedDimensions.left += 0.5;

                selectedElements.forEach((elementId) => {
                  const updatedElement = [...baseElement];

                  const elementIndex = updatedElement.findIndex(
                    (elements) => elements.id === elementId
                  );

                  const element = updatedElement[elementIndex];

                  if (element.position.mx) {
                    element.position.mx += 0.5;
                  } else {
                    element.position.mx = element.position.x + 0.5;
                  }

                  setBaseElement(updatedElement);
                });
              } else if (event.key === "ArrowUp") {
                updatedDimensions.top -= 2.5;

                selectedElements.forEach((elementId) => {
                  const updatedElement = [...baseElement];

                  const elementIndex = updatedElement.findIndex(
                    (elements) => elements.id === elementId
                  );

                  const element = updatedElement[elementIndex];

                  if (element.position.my) {
                    element.position.my -= 2.5;
                  } else {
                    element.position.my = element.position.y - 2.5;
                  }

                  setBaseElement(updatedElement);
                });
              } else if (event.key === "ArrowDown") {
                updatedDimensions.top += 2.5;

                selectedElements.forEach((elementId) => {
                  const updatedElement = [...baseElement];

                  const elementIndex = updatedElement.findIndex(
                    (elements) => elements.id === elementId
                  );

                  const element = updatedElement[elementIndex];

                  if (element.position.my) {
                    element.position.my += 2.5;
                  } else {
                    element.position.my = element.position.y + 2.5;
                  }

                  setBaseElement(updatedElement);
                });
              }

              return updatedDimensions;
            });
          } else {
            const updatedElement = [...baseElement];

            const elementIndex = updatedElement.findIndex(
              (elements) => elements.id === focusElement
            );

            const element = updatedElement[elementIndex];

            if (event.key === "ArrowLeft") {
              if (element.position.mx) {
                element.position.mx -= 0.5;
              } else {
                element.position.mx = element.position.x - 0.5;
              }
            } else if (event.key === "ArrowRight") {
              if (element.position.mx) {
                element.position.mx += 0.5;
              } else {
                element.position.mx = element.position.x + 0.5;
              }
            } else if (event.key === "ArrowUp") {
              if (element.position.my) {
                element.position.my -= 2.5;
              } else {
                element.position.my = element.position.y - 2.5;
              }
            } else if (event.key === "ArrowDown") {
              if (element.position.my) {
                element.position.my += 2.5;
              } else {
                element.position.my = element.position.y + 2.5;
              }
            }

            setBaseElement(updatedElement);
          }
        } else {
          const deepClone = cloneDeep(sections);
          undoStack.push(deepClone);
          console.log("arrow key listening");
          // Prevent the default arrow key behavior
          event.preventDefault();
          // Get the current focused element index and section index

          if (boundingBoxDimensions) {
            setBoundingBoxDimensions((prevDimensions) => {
              const updatedDimensions = {
                width: prevDimensions.width,
                height: prevDimensions.height,
                top: prevDimensions.top,
                left: prevDimensions.left,
              };

              if (event.key === "ArrowLeft") {
                updatedDimensions.left -= 0.15;

                selectedElements.forEach((elementId) => {
                  const updatedElement = [...baseElement];
                  const elementIndex = updatedElement.findIndex(
                    (elements) => elements.id === elementId
                  );
                  const element = updatedElement[elementIndex];
                  element.position.x -= 0.15;
                  setBaseElement(updatedElement);
                });
              } else if (event.key === "ArrowRight") {
                updatedDimensions.left += 0.15;

                selectedElements.forEach((elementId) => {
                  const updatedElement = [...baseElement];
                  const elementIndex = updatedElement.findIndex(
                    (elements) => elements.id === elementId
                  );
                  const element = updatedElement[elementIndex];
                  element.position.x += 0.15;
                  setBaseElement(updatedElement);
                });
              } else if (event.key === "ArrowUp") {
                updatedDimensions.top -= 2.5;

                selectedElements.forEach((elementId) => {
                  const updatedElement = [...baseElement];
                  const elementIndex = updatedElement.findIndex(
                    (elements) => elements.id === elementId
                  );
                  const element = updatedElement[elementIndex];
                  element.position.y -= 2.5;
                  setBaseElement(updatedElement);
                });
              } else if (event.key === "ArrowDown") {
                updatedDimensions.top += 2.5;

                selectedElements.forEach((elementId) => {
                  const updatedElement = [...baseElement];
                  const elementIndex = updatedElement.findIndex(
                    (elements) => elements.id === elementId
                  );
                  const element = updatedElement[elementIndex];
                  element.position.y += 2.5;
                  setBaseElement(updatedElement);
                });
              }

              return updatedDimensions;
            });
          } else {
            const updatedElement = [...baseElement];
            const elementIndex = updatedElement.findIndex(
              (elements) => elements.id === focusElement
            );
            const element = updatedElement[elementIndex];

            // Update the position based on the arrow key pressed

            if (event.key === "ArrowLeft") {
              element.position.x -= 0.15;
            } else if (event.key === "ArrowRight") {
              element.position.x += 0.15;
            } else if (event.key === "ArrowUp") {
              element.position.y -= 2.5;
            } else if (event.key === "ArrowDown") {
              element.position.y += 2.5;
            }
            setBaseElement(updatedElement);
          }
        }
      }
    };

    document.addEventListener("keydown", handleArrowKey);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleArrowKey);
    };
  }, [
    focusElement,
    sections,
    setSections,
    notInputFocus,
    editingInputWithArrow,
  ]); // Add notInputFocus to the dependency array

  const calculateAlignmentGuides = (event, sections, draggingGuideId) => {
    const guides = [];

    sections.forEach((section) => {
      section.children.forEach((element) => {
        const { position, width, height } = element;
        const { x, y } = position;

        const centerVertical = y + height / 2 + 40;
        const centerHorizontal = x + ((width / viewportWidth) * 100) / 2;

        if (element.id !== draggingGuideId) {
          // Exclude the dragging guide
          guides.push(
            {
              x: centerHorizontal,
              y: centerVertical,
              offsetX: 0,
              offsetY: 0,
              width: "100%",
              height: 1,
            }, // Vertical alignment guide
            {
              x: x,
              y: y + 40,
              offsetY: 0,
              offsetX: 0,
              width: "100%",
              height: 1,
            }, // Vertical alignment guide
            {
              x: x + (width / viewportWidth) * 100,
              y: y + height + 40,
              offsetY: 0,
              offsetX: 0,
              width: "100%",
              height: 1,
            } // Vertical alignment guide
          );
        }
      });
    });
    // console.log(guides);
    return guides;
  };

  const calculateDraggingAlignmentGuides = (sections, draggingGuideId) => {
    let guides = [];

    sections.forEach((section) => {
      section.children.forEach((element) => {
        const { position, width, height } = element;
        const { x, y } = position;

        const centerVertical = y + height / 2 + 40;
        const centerHorizontal = x + ((width / viewportWidth) * 100) / 2;

        if (element.id === draggingGuideId) {
          guides = [
            {
              x: centerHorizontal,
              offsetX: ((width / viewportWidth) * 100) / 2,
              y: centerVertical,
              offsetY: height / 2,
              width: 1,
              height: "100%",
              note: "this is for center horizontal from variable alignment",
            },
            {
              x: x,
              offsetX: 0,
              y: y + height + 40,
              offsetY: height - 40,
              width: 1,
              height: "100%",
              note: "this is for begin horizontal from variable alignment",
            },
            {
              x: x + (width / viewportWidth) * 100,
              y: y + 40,
              offsetY: -40,
              offsetX: (width / viewportWidth) * 100,
              width: 1,
              height: "100%",
              note: "this is for end horizontal from variable alignment",
            },
          ];
        }
      });
    });
    // console.log(guides[0]);
    return guides;
  };

  const handleScroll = (event) => {
    const scrollHeight = event.target.scrollTop;
    // console.log("Scroll Height:", scrollHeight);
    setScrollTop(scrollHeight);
  };

  const findSectionIdByMousePosition = (mouseY, mouseX) => {
    if (mobileDesigning) {
      const adjustedMouseY = mouseY + scrollTop - 40;
      console.log(mouseX, mouseY);

      // If the mouse position is not over any box-element, check the sections[pageDesigning]
      for (const section of mobileSections[pageDesigning]) {
        const { id, position, height, mheight } = section;
        const sectionTop = position.my ? position.my : position.y;
        const sectionBottom = sectionTop + (mheight ? mheight : height);
        const footerExist = mobileSections[pageDesigning].findIndex(
          (sect) => sect.id === "footer"
        );
        if (footerExist > -1) {
          const footerIndex = sections[pageDesigning].length - 1;
          const footerSection = sections[pageDesigning][footerIndex];

          const footerTop =
            (sections[pageDesigning][footerIndex - 1].position.my
              ? sections[pageDesigning][footerIndex - 1].position.my
              : sections[pageDesigning][footerIndex - 1].position.y) +
            (sections[pageDesigning][footerIndex - 1].mheight
              ? sections[pageDesigning][footerIndex - 1].mheight
              : sections[pageDesigning][footerIndex - 1].height);
          const footerBottom =
            footerTop +
            (footerSection.mheight
              ? footerSection.mheight
              : footerSection.height);

          if (adjustedMouseY >= footerTop && adjustedMouseY <= footerBottom) {
            return footerSection.id;
          }

          if (adjustedMouseY >= sectionTop && adjustedMouseY <= sectionBottom) {
            // console.log(id)
            return id; // Return section id if mouse is over the section
          }
        } else {
          if (adjustedMouseY >= sectionTop && adjustedMouseY <= sectionBottom) {
            // console.log(id)
            return id; // Return section id if mouse is over the section
          }
        }
      }
    } else {
      const adjustedMouseY = mouseY + scrollTop - 40;
      // console.log(mouseX, mouseY);

      // If the mouse position is not over any box-element, check the sections[pageDesigning]
      for (const section of sections[pageDesigning]) {
        const { id, position, height } = section;
        const sectionTop = position.y;
        const sectionBottom = sectionTop + height;
        const footerExist = sections[pageDesigning].findIndex(
          (sect) => sect.id === "footer"
        );
        if (footerExist > -1) {
          const footerIndex = sections[pageDesigning].length - 1;
          const footerSection = sections[pageDesigning][footerIndex];
          // console.log(sectionTop, sectionBottom, footerIndex, footerSection);

          const footerTop =
            sections[pageDesigning][footerIndex - 1].position.y +
            sections[pageDesigning][footerIndex - 1].height;
          const footerBottom = footerTop + footerSection.height;

          if (adjustedMouseY >= footerTop && adjustedMouseY <= footerBottom) {
            return footerSection.id;
          }

          if (adjustedMouseY >= sectionTop && adjustedMouseY <= sectionBottom) {
            // console.log(id)
            return id; // Return section id if mouse is over the section
          }
        } else {
          if (adjustedMouseY >= sectionTop && adjustedMouseY <= sectionBottom) {
            // console.log(id)
            return id; // Return section id if mouse is over the section
          }
        }
      }
    }

    return null; // Return null if no section or box-element is found at the given position.
  };

  const handleDragStart = (event, elementId, sectionId, touch) => {
    setShowDraggingGuides(true);
    event.stopPropagation();
    setFocusElementType(elementId);
    if (!touch) {
      event.dataTransfer.setData("text/plain", elementId);
    }
    setDraggedElementSectionId(sectionId);
  };

  const handleDrag = (event, elementId, offset, sectionId, draggedY, touch) => {
    // console.log("dragging start")
    console.log(touch);
    if (!touch) {
      event.preventDefault();
    }
    setShowDraggingGuides(true);
    const guides = calculateAlignmentGuides(
      event,
      sections[pageDesigning],
      elementId
    );
    setAlignmentGuides(guides);
    const draggingGuide = calculateDraggingAlignmentGuides(
      sections[pageDesigning],
      elementId
    );
    setDraggingAlignmentGuides(draggingGuide);
    // console.log(alignmentGuides, draggingAlignmentGuides);
    setFocusElement(elementId);
    // console.log(elementId);
    if (mobileDesigning) {
      const sectionIndex = mobileSections[pageDesigning].findIndex((section) =>
        section.children.some((child) => child.id === elementId)
      );

      if (sectionIndex > -1) {
        const updatedElement = [...baseElement];
        const elementIndex = updatedElement.findIndex(
          (elements) => elements.id === elementId
        );
        const element = updatedElement[elementIndex];
        if (element.type) {
          setFocusElementType(element.type);
        }
        const sectionsIndex = mobileSections[pageDesigning].findIndex(
          (section) => section.id === sectionId
        );
        console.log(event.clientX, offset.x, viewportWidth);
        if (touch) {
          if (touch.clientX > 5) {
            element.position.mx =
              Math.round(
                (((touch.clientX - offset.x - (viewportWidth - 400) / 2) / 400) *
                  100) /
                (snappingRate / 12)
              ) *
              (snappingRate / 12);
          }
        } else {
          if (event.clientX > 5) {
            element.position.mx =
              Math.round(
                (((event.clientX - offset.x - (viewportWidth - 400) / 2) / 400) *
                  100) /
                (snappingRate / 12)
              ) *
              (snappingRate / 12);
          }
        }
        const footerExist = mobileSections[pageDesigning].findIndex(
          (sect) => sect.id === "footer"
        );
        if (footerExist > -1) {
          if (sectionIndex !== mobileSections[pageDesigning].length - 1) {
            if (touch) {
              if (touch.clientY > 5) {
                element.position.my =
                  Math.round(
                    (touch.clientY -
                      offset.y -
                      40 +
                      scrollTop -
                      (mobileSections[pageDesigning][sectionIndex].position.my
                        ? mobileSections[pageDesigning][sectionIndex].position.my
                        : mobileSections[pageDesigning][sectionIndex].position
                          .y)) /
                    snappingRate
                  ) * snappingRate;
              }
            } else {
              if (event.clientY > 5) {
                element.position.my =
                  Math.round(
                    (event.clientY -
                      offset.y -
                      40 +
                      scrollTop -
                      (mobileSections[pageDesigning][sectionIndex].position.my
                        ? mobileSections[pageDesigning][sectionIndex].position.my
                        : mobileSections[pageDesigning][sectionIndex].position
                          .y)) /
                    snappingRate
                  ) * snappingRate;
              }
            }

            setDraggingSectionPositionY(
              mobileSections[pageDesigning][sectionsIndex].position.my
                ? mobileSections[pageDesigning][sectionsIndex].position.my
                : mobileSections[pageDesigning][sectionsIndex].position.y
            );
          } else if (
            sectionIndex ===
            mobileSections[pageDesigning].length - 1
          ) {
            if (touch) {
              if (touch.clientY > 5) {
                element.position.my =
                  Math.round(
                    (touch.clientY -
                      offset.y -
                      40 +
                      scrollTop -
                      ((mobileSections[pageDesigning][sectionIndex - 1].position
                        .my
                        ? mobileSections[pageDesigning][sectionIndex - 1].position
                          .my
                        : mobileSections[pageDesigning][sectionIndex - 1].position
                          .y) +
                        (mobileSections[pageDesigning][sectionIndex - 1].mheight
                          ? mobileSections[pageDesigning][sectionIndex - 1]
                            .mheight
                          : mobileSections[pageDesigning][sectionIndex - 1]
                            .height))) /
                    snappingRate
                  ) * snappingRate;
              }
            } else {
              if (event.clientY > 5) {
                element.position.my =
                  Math.round(
                    (event.clientY -
                      offset.y -
                      40 +
                      scrollTop -
                      ((mobileSections[pageDesigning][sectionIndex - 1].position
                        .my
                        ? mobileSections[pageDesigning][sectionIndex - 1].position
                          .my
                        : mobileSections[pageDesigning][sectionIndex - 1].position
                          .y) +
                        (mobileSections[pageDesigning][sectionIndex - 1].mheight
                          ? mobileSections[pageDesigning][sectionIndex - 1]
                            .mheight
                          : mobileSections[pageDesigning][sectionIndex - 1]
                            .height))) /
                    snappingRate
                  ) * snappingRate;
              }
            }

            setDraggingSectionPositionY(
              (mobileSections[pageDesigning][sectionIndex - 1].position.my
                ? mobileSections[pageDesigning][sectionIndex - 1].position.my
                : mobileSections[pageDesigning][sectionIndex - 1].position.y) +
              (mobileSections[pageDesigning][sectionIndex - 1].mheight
                ? mobileSections[pageDesigning][sectionIndex - 1].mheight
                : mobileSections[pageDesigning][sectionIndex - 1].height)
            );
          }
        } else {
          if (touch) {
            if (touch.clientY > 5) {
              element.position.my =
                Math.round(
                  (touch.clientY -
                    offset.y -
                    40 +
                    scrollTop -
                    (mobileSections[pageDesigning][sectionIndex].position.my
                      ? mobileSections[pageDesigning][sectionIndex].position.my
                      : mobileSections[pageDesigning][sectionIndex].position.y)) /
                  snappingRate
                ) * snappingRate;
            }
          } else {
            if (event.clientY > 5) {
              element.position.my =
                Math.round(
                  (event.clientY -
                    offset.y -
                    40 +
                    scrollTop -
                    (mobileSections[pageDesigning][sectionIndex].position.my
                      ? mobileSections[pageDesigning][sectionIndex].position.my
                      : mobileSections[pageDesigning][sectionIndex].position.y)) /
                  snappingRate
                ) * snappingRate;
            }
          }

          setDraggingSectionPositionY(
            mobileSections[pageDesigning][sectionsIndex].position.my
              ? mobileSections[pageDesigning][sectionsIndex].position.my
              : mobileSections[pageDesigning][sectionsIndex].position.y
          );
        }

        // Add error handling to ignore negative positions
        setBaseElement(updatedElement);
      }
    } else {

      const sectionIndex = sections[pageDesigning].findIndex((section) =>
        section.children.some((child) => child.id === elementId)
      );

      if (sectionIndex > -1) {
        const updatedElement = [...baseElement];
        const elementIndex = updatedElement.findIndex(
          (elements) => elements.id === elementId
        );
        const element = updatedElement[elementIndex];
        if (element.type) {
          setFocusElementType(element.type);
        }
        const sectionsIndex = sections[pageDesigning].findIndex(
          (section) => section.id === sectionId
        );
        if (touch) {
          if (touch.clientX > 5) {
            element.position.x =
              Math.round(
                ((touch.clientX / viewportWidth) * 100 -
                  (offset.x / viewportWidth) * 100) /
                (snappingRate / 12)
              ) *
              (snappingRate / 12);
          }
        } else {
          if (event.clientX > 5) {
            element.position.x =
              Math.round(
                ((event.clientX / viewportWidth) * 100 -
                  (offset.x / viewportWidth) * 100) /
                (snappingRate / 12)
              ) *
              (snappingRate / 12);
          }
        }

        const footerExist = sections[pageDesigning].findIndex(
          (sect) => sect.id === "footer"
        );
        if (footerExist > -1) {
          if (sectionIndex !== sections[pageDesigning].length - 1) {
            if (touch) {
              if (touch.clientY > 5) {
                element.position.y =
                  Math.round(
                    (touch.clientY -
                      offset.y -
                      40 +
                      scrollTop -
                      sections[pageDesigning][sectionIndex].position.y) /
                    snappingRate
                  ) * snappingRate;
              }
            } else {
              if (event.clientY > 5) {
                element.position.y =
                  Math.round(
                    (event.clientY -
                      offset.y -
                      40 +
                      scrollTop -
                      sections[pageDesigning][sectionIndex].position.y) /
                    snappingRate
                  ) * snappingRate;
              }
            }
            setDraggingSectionPositionY(
              sections[pageDesigning][sectionsIndex].position.y
            );
          } else if (sectionIndex === sections[pageDesigning].length - 1) {
            if (touch) {
              if (touch.clientY > 5) {
                element.position.y =
                  Math.round(
                    (touch.clientY -
                      offset.y -
                      40 +
                      scrollTop -
                      (sections[pageDesigning][sectionIndex - 1].position.y +
                        sections[pageDesigning][sectionIndex - 1].height)) /
                    snappingRate
                  ) * snappingRate;
              }
            } else {
              if (event.clientY > 5) {
                element.position.y =
                  Math.round(
                    (event.clientY -
                      offset.y -
                      40 +
                      scrollTop -
                      (sections[pageDesigning][sectionIndex - 1].position.y +
                        sections[pageDesigning][sectionIndex - 1].height)) /
                    snappingRate
                  ) * snappingRate;
              }
            }
            setDraggingSectionPositionY(
              sections[pageDesigning][sectionIndex - 1].position.y +
              sections[pageDesigning][sectionIndex - 1].height
            );
          }
        } else {
          if (touch) {
            if (touch.clientY > 5) {
              element.position.y =
                Math.round(
                  (touch.clientY -
                    offset.y -
                    40 +
                    scrollTop -
                    sections[pageDesigning][sectionIndex].position.y) /
                  snappingRate
                ) * snappingRate;
            }
          } else {
            if (event.clientY > 5) {
              element.position.y =
                Math.round(
                  (event.clientY -
                    offset.y -
                    40 +
                    scrollTop -
                    sections[pageDesigning][sectionIndex].position.y) /
                  snappingRate
                ) * snappingRate;
            }
          }

          setDraggingSectionPositionY(
            sections[pageDesigning][sectionsIndex].position.y
          );
        }

        // Add error handling to ignore negative positions
        setBaseElement(updatedElement);
      }
    }
  };

  const handleDragEnd = () => {
    setDraggedElementSectionId(null);
    setShowDraggingGuides(false);
    // console.log(sections);
  };

  const [fileUploadingfavicon, setFileUploadingFavicon] = useState(false);

  const handleImageUploadFavicon = async (e) => {
    setFileUploadingFavicon(true);
    const file = e.target.files;
    console.log(file); // Log the file object to the console to inspect its contents

    const formData = new FormData();

    for (let i = 0; i < file.length; i++) {
      formData.append("files[]", file[i]);
    }

    try {
      const response = await axios.post(
        "https://ww1.premelo.com/api/upload-image",
        formData
      );
      const imageUrl = response.data.urls;
      console.log(imageUrl);

      if (imageUrl) {
        setFavicon(imageUrl[0]);
      }
      setFileUploadingFavicon(false);
    } catch (error) {
      console.log(error);
      setFileUploadingFavicon(false);
    }
  };

  const handleClick1 = () => {
    const fileInput = document.getElementById("imageUploadFavicon");
    fileInput.click();
  };

  const [fileUploading, setFileUploading] = useState(false);

  const handleImageUpload = async (e) => {
    setFileUploading(true);
    const file = e.target.files;
    console.log(file); // Log the file object to the console to inspect its contents

    const formData = new FormData();

    for (let i = 0; i < file.length; i++) {
      formData.append("files[]", file[i]);
    }

    try {
      const response = await axios.post(
        "https://ww1.premelo.com/api/upload-image",
        formData
      );
      const imageUrl = response.data.urls;
      console.log(imageUrl);

      const updatingBaseSection = [...baseSections];

      const sectionIndex = updatingBaseSection.findIndex(
        (section) => section.id === focusElement
      );

      if (updatingBaseSection[sectionIndex].slider !== true) {
        console.log(sectionIndex);
        updatingBaseSection[sectionIndex].src = imageUrl;
        setBaseSections(updatingBaseSection);
      } else {
        console.log(sectionIndex);
        updatingBaseSection[sectionIndex][
          `src${updatingBaseSection[sectionIndex].activeSlide}`
        ] = imageUrl;
        setBaseSections(updatingBaseSection);
      }
      setFocusImage(imageUrl);
      setFileUploading(false);
    } catch (error) {
      console.log(error);
      setFileUploading(false);
    }
  };

  const handleClick = () => {
    const fileInput = document.getElementById("imageInput");
    fileInput.click();
  };

  const tolerance = 5; // Adjust this value as needed
  const xtolerance = 1; // Adjust this value as needed
  const filteredGuidesx = alignmentGuides
    .filter((guide) =>
      draggingAlignmentGuides.some(
        (otherGuide) => Math.abs(otherGuide.x - guide.x) <= xtolerance
      )
    )
    .slice(0, 1);
  const filteredGuidesy = alignmentGuides
    .filter((guide) =>
      draggingAlignmentGuides.some(
        (otherGuide) => Math.abs(otherGuide.y - guide.y) <= tolerance
      )
    )
    .slice(0, 1);

  const handleDrop = (event, sectionId, elem) => {
    // console.log(event, sectionId, activeSlide);
    const sectionInd = baseSections.findIndex(
      (se) => se.id === draggedOverSectionId
    );
    const activeSlide = baseSections[sectionInd].activeSlide || null;
    // console.log(sectionInd, baseSections, activeSlide);

    setDraggingAlignmentGuides([]);
    // console.log(event.dataTransfer.getData("text/plain"), sectionId);
    if (!elem) {
      event.preventDefault();
    }
    setShowDraggingGuides(false);
    const elementId = elem || event.dataTransfer.getData("text/plain");
    // const elementId = focusElement;
    if (mobileDesigning) {
      const sectionsIndex = sections[pageDesigning].findIndex(
        (section) => section.id === sectionId
      );
      const sectionsDraggingIndex = sections[pageDesigning].findIndex(
        (section) => section.id === draggedOverSectionId
      );
      console.log(elementId);

      if (elementId === "text-element") {
        setDraggedOverSectionId(null);
        console.log(
          event.clientY,
          mobileSections[pageDesigning][sectionsIndex].position.my
            ? mobileSections[pageDesigning][sectionsIndex].position.y
            : mobileSections[pageDesigning][sectionsIndex].position.y,
          40,
          scrollTop
        );
        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
        };

        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "dynamic-title-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
        };

        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "social-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          social: [
            { icon: "FaFacebook", name: "Facebook", url: "" },
            { icon: "FaInstagram", name: "Instagram", url: "" },
            { icon: "FaWhatsapp", name: "Whatsapp", url: "" },
            { icon: "FaTwitter", name: "Twitter", url: "" },
            { icon: "FaYoutube", name: "Youtube", url: "" },
          ],
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "horizontal-divider-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 50,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "vertical-divider-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          children: [],
          width: 4,
          height: 250,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "icon-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          children: [],
          width: 5,
          height: 50,
          objectFit: "cover",
          src: imageReference,
          content: "",
          icon: "FaFacebook",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "accordian-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          children: [],
          width: 30,
          height: 185,
          accordianContent: [
            {
              name: "Tab 1",
              content:
                "Lorem Ipsum is simply dummy text of the printing a…pe and scrambled it to make a type specimen book.",
            },
            {
              name: "Tab 2",
              content:
                "Lorem Ipsum is simply dummy text of the printing a…pe and scrambled it to make a type specimen book.",
            },
            {
              name: "Tab 3",
              content:
                "Lorem Ipsum is simply dummy text of the printing a…pe and scrambled it to make a type specimen book.",
            },
          ],
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "switch-tab-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          children: [],
          accordianContent: [
            {
              name: "Tab 1",
              content:
                "Lorem Ipsum is simply dummy text of the printing a…pe and scrambled it to make a type specimen book.",
            },
            {
              name: "Tab 2",
              content:
                "Lorem Ipsum is simply dummy text of the printing a…pe and scrambled it to make a type specimen book.",
            },
          ],
          width: 40,
          height: 120,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      // if (elementId === "login-element") {
      //   setDraggedOverSectionId(null);

      //   const newElement = {
      //     id: Date.now().toString(),
      //     type: elementId,

      //     position: {
      //       x: (event.clientX / viewportWidth) * 100,
      //       y:
      //         sectionsIndex === mobileSections[pageDesigning].length - 1 &&
      //         mobileSections[pageDesigning].length !== 1
      //           ? event.clientY -
      //             (mobileSections[pageDesigning][sectionsIndex - 1].position.my
      //               ? mobileSections[pageDesigning][sectionsIndex - 1].position
      //                   .my
      //               : mobileSections[pageDesigning][sectionsIndex - 1].position
      //                   .y) -
      //             (mobileSections[pageDesigning][sectionsIndex - 1].mheight
      //               ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
      //               : mobileSections[pageDesigning][sectionsIndex - 1].height) -
      //             40 +
      //             scrollTop
      //           : event.clientY -
      //             (mobileSections[pageDesigning][sectionsIndex].position.my
      //               ? mobileSections[pageDesigning][sectionsIndex].position.y
      //               : mobileSections[pageDesigning][sectionsIndex].position.y) -
      //             40 +
      //             scrollTop,
      //       mx: (event.clientX / viewportWidth) * 100,
      //       my:
      //         sectionsIndex === mobileSections[pageDesigning].length - 1 &&
      //         mobileSections[pageDesigning].length !== 1
      //           ? event.clientY -
      //             (mobileSections[pageDesigning][sectionsIndex - 1].position.my
      //               ? mobileSections[pageDesigning][sectionsIndex - 1].position
      //                   .my
      //               : mobileSections[pageDesigning][sectionsIndex - 1].position
      //                   .y) -
      //             (mobileSections[pageDesigning][sectionsIndex - 1].mheight
      //               ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
      //               : mobileSections[pageDesigning][sectionsIndex - 1].height) -
      //             40 +
      //             scrollTop
      //           : event.clientY -
      //             (mobileSections[pageDesigning][sectionsIndex].position.my
      //               ? mobileSections[pageDesigning][sectionsIndex].position.y
      //               : mobileSections[pageDesigning][sectionsIndex].position.y) -
      //             40 +
      //             scrollTop,
      //     },
      //     children: [],
      //     width: 20,
      //     height: 80,
      //     objectFit: "cover",
      //     src: imageReference,
      //     content: "",
      //     fontSize: 14,
      //     fontWeight: 300,
      //     textAlign: "left",
      //     lineHeight: 15,
      //     ref: null,
      //     letterSpacing: 1,
      //     backgroundColor: "none",
      //     color: "#1f1f1f",
      //     border: { px: "0", solid: "solid", color: "black" },
      //     shadow: {
      //       horizontal: "0",
      //       vertical: "0",
      //       blur: "0",
      //       color: "black",
      //     },
      //     opacity: 100,
      //     borderRadius: "0",
      //     alignItems: "flex-start",
      //     slide: activeSlide,
      //     parent: { type: "section", id: sectionId },
      //     mobile: { type: "section", id: sectionId },
      //   };
      //   setBaseElement([...baseElement, newElement]);
      // }
      if (elementId === "map-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          children: [],
          width: 50,
          height: 350,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "code-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          customCode: "<div>Create your custom code here.</div>",
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "video-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "dynamic-checkout-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "dynamic-description-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "dynamic-image-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };

        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "dynamic-details-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };

        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "dynamic-horizontal-details-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
          slide: activeSlide,
        };

        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "dynamic-price-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };

        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "dynamic-list-attribute-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };

        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "dynamic-account-header-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };

        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "dynamic-account-tabs-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };

        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "dynamic-cart-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };

        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "dynamic-table-attribute-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };

        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "dynamic-grid-attribute-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
          slide: activeSlide,
        };

        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "button-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          children: [],
          width: 12,
          height: 40,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "#e60953",
          color: "white",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: { horizontal: "0", vertical: "0", blur: "0", color: "black" },
          opacity: 100,
          borderRadius: "50",
          alignItems: "flex-start",
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
          slide: activeSlide,
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "form-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          form: null,
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
          alignItems: "flex-start",
          slide: activeSlide,
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "news-search-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          search_term: "",
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "news-filter-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          filter_tags: [],
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "news-carousel-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          news: [],
          flexWrap: 3,
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "product-search-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          search_term: "",
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "product-filter-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          filter_tags: [],
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "product-carousel-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          products: [],
          flexWrap: 3,
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "event-search-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          search_term: "",
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "event-filter-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          filter_tags: [],
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "event-carousel-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          events: [],
          flexWrap: 3,
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "image-gallery-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          images: [],
          flexWrap: 3,
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "menu-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          images: [],
          flexWrap: 3,
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "event-item-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          company_name: null,
          event_id: null,
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "product-item-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          company_name: null,
          product_sku: null,
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "box-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          children: [],
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          width: 30,
          height: 300,
          holderBackgroundColor: "rgba(200,200,200,1)",
          backgroundImageOpacity: 100,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "image-element") {
        setDraggedOverSectionId(null);

        console.log(event.clientY);
        console.log(sections[pageDesigning][sectionsIndex].position.y);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,

          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
            mx: (event.clientX / viewportWidth) * 100,
            my:
              sectionsIndex === mobileSections[pageDesigning].length - 1 &&
                mobileSections[pageDesigning].length !== 1
                ? event.clientY -
                (mobileSections[pageDesigning][sectionsIndex - 1].position.my
                  ? mobileSections[pageDesigning][sectionsIndex - 1].position
                    .my
                  : mobileSections[pageDesigning][sectionsIndex - 1].position
                    .y) -
                (mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  ? mobileSections[pageDesigning][sectionsIndex - 1].mheight
                  : mobileSections[pageDesigning][sectionsIndex - 1].height) -
                40 +
                scrollTop
                : event.clientY -
                (mobileSections[pageDesigning][sectionsIndex].position.my
                  ? mobileSections[pageDesigning][sectionsIndex].position.my
                  : mobileSections[pageDesigning][sectionsIndex].position.y) -
                40 +
                scrollTop,
          },
          children: [],
          width: 25,
          height: 300,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      } else {
        setDraggedOverSectionId(null);

        // Handle case for existing elements
        const draggedSectionIndex = mobileSections[pageDesigning].findIndex(
          (section) => section.children.some((child) => child.id === elementId)
        );
        const draggedSection =
          mobileSections[pageDesigning][draggedSectionIndex];
        const draggedElementIndex = elementId
          ? draggedSection.children.findIndex(
            (element) => element.id === elementId
          )
          : -1;
        const draggedElement = elementId
          ? draggedSection.children[draggedElementIndex]
          : -1;

        if (
          draggedElement &&
          draggedSectionIndex !== -1 &&
          draggedElementIndex !== -1
        ) {
          const updatedSections = [...mobileSections[pageDesigning]];
          const updatedDraggedSection = { ...draggedSection };
          updatedSections[draggedSectionIndex] = updatedDraggedSection;
          const hoveringSectionId = findSectionIdByMousePosition(
            event.clientY,
            event.clientX
          );
          if (hoveringSectionId !== "footer") {
            const copySections = [...baseSections];
            const originalSectionIndex = copySections.findIndex(
              (sectionsssss) => sectionsssss.id === draggedElementSectionId
            );
            // comment for jonathan: change the section id by doing the math to find out what is the section that is being hovered on top off
            const targetSectionIndex = mobileSections[pageDesigning].findIndex(
              (section) => section.id === hoveringSectionId
            );
            const updatedTargetSection = {
              ...mobileSections[pageDesigning][targetSectionIndex],
            };
            const updatedElement = [...baseElement];
            const elementIndex = updatedElement.findIndex(
              (elements) => elements.id === draggedElement.id
            );
            const element = updatedElement[elementIndex];

            console.log(
              element,
              mobileSections[pageDesigning][targetSectionIndex],
              copySections
            );

            const actIndex = mobileSections[pageDesigning].findIndex(
              (secs) => secs.id === copySections[originalSectionIndex].id
            );

            console.log(targetSectionIndex, actIndex);
            if (
              targetSectionIndex === mobileSections[pageDesigning].length - 1 &&
              actIndex !== mobileSections[pageDesigning].length - 1
            ) {
              element.position.my -=
                (mobileSections[pageDesigning][targetSectionIndex - 1].position
                  .my
                  ? mobileSections[pageDesigning][targetSectionIndex - 1]
                    .position.my
                  : mobileSections[pageDesigning][targetSectionIndex - 1]
                    .position.y) +
                (mobileSections[pageDesigning][targetSectionIndex - 1].mheight
                  ? mobileSections[pageDesigning][targetSectionIndex - 1]
                    .mheight
                  : mobileSections[pageDesigning][targetSectionIndex - 1]
                    .height) -
                (copySections[originalSectionIndex].position.my
                  ? copySections[originalSectionIndex].position.my
                  : copySections[originalSectionIndex].position.y);
            } else if (
              targetSectionIndex !== mobileSections[pageDesigning].length - 1 &&
              actIndex === mobileSections[pageDesigning].length - 1
            ) {
              console.log(
                mobileSections[pageDesigning][targetSectionIndex].position.my
                  ? mobileSections[pageDesigning][targetSectionIndex].position
                    .my
                  : mobileSections[pageDesigning][targetSectionIndex].position
                    .y,
                (mobileSections[pageDesigning][actIndex - 1].position.my
                  ? mobileSections[pageDesigning][actIndex - 1].position.my
                  : mobileSections[pageDesigning][actIndex - 1].position.y) +
                (mobileSections[pageDesigning][actIndex - 1].mheight
                  ? mobileSections[pageDesigning][actIndex - 1].mheight
                  : mobileSections[pageDesigning][actIndex - 1].height)
              );
              element.position.my -=
                (mobileSections[pageDesigning][targetSectionIndex].position.my
                  ? mobileSections[pageDesigning][targetSectionIndex].position
                    .my
                  : mobileSections[pageDesigning][targetSectionIndex].position
                    .y) -
                ((mobileSections[pageDesigning][actIndex - 1].position.my
                  ? mobileSections[pageDesigning][actIndex - 1].position.my
                  : mobileSections[pageDesigning][actIndex - 1].position.y) +
                  (mobileSections[pageDesigning][actIndex - 1].mheight
                    ? mobileSections[pageDesigning][actIndex - 1].mheight
                    : mobileSections[pageDesigning][actIndex - 1].height));
            } else {
              element.position.my -=
                (mobileSections[pageDesigning][targetSectionIndex].position.my
                  ? mobileSections[pageDesigning][targetSectionIndex].position
                    .my
                  : mobileSections[pageDesigning][targetSectionIndex].position
                    .y) -
                (copySections[originalSectionIndex].position.my
                  ? copySections[originalSectionIndex].position.my
                  : copySections[originalSectionIndex].position.y);
            }
            element.slide = activeSlide;
            element.mobile.type = "section";
            element.mobile.id = hoveringSectionId;

            setBaseElement(updatedElement);
          } else if (hoveringSectionId === "footer") {
            const copySections = [...baseSections];
            const originalSectionIndex = copySections.findIndex(
              (sectionsssss) => sectionsssss.id === draggedElementSectionId
            );
            // comment for jonathan: change the section id by doing the math to find out what is the section that is being hovered on top off
            const targetSectionIndex = mobileSections[pageDesigning].findIndex(
              (section) => section.id === hoveringSectionId
            );
            const updatedTargetSection = {
              ...mobileSections[pageDesigning][targetSectionIndex],
            };
            const updatedElement = [...baseElement];
            const elementIndex = updatedElement.findIndex(
              (elements) => elements.id === draggedElement.id
            );
            const element = updatedElement[elementIndex];

            console.log(
              element,
              mobileSections[pageDesigning][targetSectionIndex],
              copySections
            );
            const actIndex = mobileSections[pageDesigning].findIndex(
              (secs) => secs.id === copySections[originalSectionIndex].id
            );

            console.log(targetSectionIndex, actIndex);
            if (
              targetSectionIndex === mobileSections[pageDesigning].length - 1 &&
              actIndex !== mobileSections[pageDesigning].length - 1
            ) {
              element.position.my -=
                (mobileSections[pageDesigning][targetSectionIndex - 1].position
                  .my
                  ? mobileSections[pageDesigning][targetSectionIndex - 1]
                    .position.my
                  : mobileSections[pageDesigning][targetSectionIndex - 1]
                    .position.y) +
                (mobileSections[pageDesigning][targetSectionIndex - 1].mheight
                  ? mobileSections[pageDesigning][targetSectionIndex - 1]
                    .mheight
                  : mobileSections[pageDesigning][targetSectionIndex - 1]
                    .height) -
                (copySections[originalSectionIndex].position.my
                  ? copySections[originalSectionIndex].position.my
                  : copySections[originalSectionIndex].position.y);
            }
            if (
              targetSectionIndex !== mobileSections[pageDesigning].length - 1 &&
              actIndex === mobileSections[pageDesigning].length - 1
            ) {
              console.log(
                mobileSections[pageDesigning][targetSectionIndex].position.my
                  ? mobileSections[pageDesigning][targetSectionIndex].position
                    .my
                  : mobileSections[pageDesigning][targetSectionIndex].position
                    .y,
                (mobileSections[pageDesigning][actIndex - 1].position.my
                  ? mobileSections[pageDesigning][actIndex - 1].position.my
                  : mobileSections[pageDesigning][actIndex - 1].position.y) +
                (mobileSections[pageDesigning][actIndex - 1].mheight
                  ? mobileSections[pageDesigning][actIndex - 1].mheight
                  : mobileSections[pageDesigning][actIndex - 1].height)
              );
              element.position.my -=
                (mobileSections[pageDesigning][targetSectionIndex].position.my
                  ? mobileSections[pageDesigning][targetSectionIndex].position
                    .my
                  : mobileSections[pageDesigning][targetSectionIndex].position
                    .y) -
                ((mobileSections[pageDesigning][actIndex - 1].position.my
                  ? mobileSections[pageDesigning][actIndex - 1].position.my
                  : mobileSections[pageDesigning][actIndex - 1].position.y) +
                  (mobileSections[pageDesigning][actIndex - 1].mheight
                    ? mobileSections[pageDesigning][actIndex - 1].mheight
                    : mobileSections[pageDesigning][actIndex - 1].height));
            }

            element.slide = activeSlide;
            element.mobile.type = "section";
            element.mobile.id = hoveringSectionId;

            setBaseElement(updatedElement);
          }
        }
      }
    } else {
      const sectionsIndex = sections[pageDesigning].findIndex(
        (section) => section.id === sectionId
      );
      const sectionsDraggingIndex = sections[pageDesigning].findIndex(
        (section) => section.id === draggedOverSectionId
      );
      // console.log(elementId);

      if (elementId === "text-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
        };

        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "dynamic-title-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
        };

        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "social-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          social: [
            { icon: "FaFacebook", name: "Facebook", url: "" },
            { icon: "FaInstagram", name: "Instagram", url: "" },
            { icon: "FaWhatsapp", name: "Whatsapp", url: "" },
            { icon: "FaTwitter", name: "Twitter", url: "" },
            { icon: "FaYoutube", name: "Youtube", url: "" },
          ],
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "horizontal-divider-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 50,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "vertical-divider-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          children: [],
          width: 4,
          height: 250,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "icon-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          children: [],
          width: 5,
          height: 50,
          icon: "FaFacebook",
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "accordian-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          children: [],
          accordianContent: [
            {
              name: "Tab 1",
              content:
                "Lorem Ipsum is simply dummy text of the printing a…pe and scrambled it to make a type specimen book.",
            },
            {
              name: "Tab 2",
              content:
                "Lorem Ipsum is simply dummy text of the printing a…pe and scrambled it to make a type specimen book.",
            },
            {
              name: "Tab 3",
              content:
                "Lorem Ipsum is simply dummy text of the printing a…pe and scrambled it to make a type specimen book.",
            },
          ],
          width: 30,
          height: 185,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "switch-tab-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          children: [],
          accordianContent: [
            {
              name: "Tab 1",
              content:
                "Lorem Ipsum is simply dummy text of the printing a…pe and scrambled it to make a type specimen book.",
            },
            {
              name: "Tab 2",
              content:
                "Lorem Ipsum is simply dummy text of the printing a…pe and scrambled it to make a type specimen book.",
            },
          ],
          width: 40,
          height: 120,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      // if (elementId === "login-element") {
      //   setDraggedOverSectionId(null);

      //   const newElement = {
      //     id: Date.now().toString(),
      //     type: elementId,
      //     position: {
      //       x: (event.clientX / viewportWidth) * 100,
      //       y:
      //         sectionsIndex === sections[pageDesigning].length - 1 &&
      //         sections[pageDesigning].length !== 1
      //           ? event.clientY -
      //             sections[pageDesigning][sectionsIndex - 1].position.y -
      //             sections[pageDesigning][sectionsIndex - 1].height -
      //             40 +
      //             scrollTop
      //           : event.clientY -
      //             sections[pageDesigning][sectionsIndex].position.y -
      //             40 +
      //             scrollTop,
      //     },
      //     children: [],
      //     width: 20,
      //     height: 80,
      //     objectFit: "cover",
      //     src: imageReference,
      //     content: "",
      //     fontSize: 14,
      //     fontWeight: 300,
      //     textAlign: "left",
      //     lineHeight: 15,
      //     ref: null,
      //     letterSpacing: 1,
      //     backgroundColor: "none",
      //     color: "#1f1f1f",
      //     border: { px: "0", solid: "solid", color: "black" },
      //     shadow: {
      //       horizontal: "0",
      //       vertical: "0",
      //       blur: "0",
      //       color: "black",
      //     },
      //     opacity: 100,
      //     borderRadius: "0",
      //     alignItems: "flex-start",
      //     slide: activeSlide,
      //     parent: { type: "section", id: sectionId },
      //     mobile: { type: "section", id: sectionId },
      //   };
      //   setBaseElement([...baseElement, newElement]);
      // }
      if (elementId === "map-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          children: [],
          width: 40,
          height: 350,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "code-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          customCode: "<div>Create your custom code here.</div>",
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "video-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "dynamic-checkout-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "dynamic-description-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "dynamic-image-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };

        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "dynamic-details-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };

        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "dynamic-horizontal-details-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
          slide: activeSlide,
        };

        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "dynamic-price-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };

        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "dynamic-list-attribute-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };

        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "dynamic-account-header-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };

        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "dynamic-account-tabs-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };

        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "dynamic-cart-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };

        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "dynamic-table-attribute-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };

        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "dynamic-grid-attribute-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "none",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
          slide: activeSlide,
        };

        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "button-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          children: [],
          width: 12,
          height: 40,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "#e60953",
          color: "white",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: { horizontal: "0", vertical: "0", blur: "0", color: "black" },
          opacity: 100,
          borderRadius: "50",
          alignItems: "flex-start",
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
          slide: activeSlide,
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "form-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          form: null,
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
          alignItems: "flex-start",
          slide: activeSlide,
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "news-search-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          search_term: "",
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "news-filter-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          filter_tags: [],
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "news-carousel-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          news: [],
          flexWrap: 3,
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "product-search-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          search_term: "",
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "product-filter-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          filter_tags: [],
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "product-carousel-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          products: [],
          flexWrap: 3,
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "event-search-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          search_term: "",
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "event-filter-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          filter_tags: [],
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "event-carousel-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          events: [],
          flexWrap: 3,
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "image-gallery-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          images: [],
          flexWrap: 3,
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "menu-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          images: [],
          flexWrap: 3,
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "event-item-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          company_name: null,
          event_id: null,
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "product-item-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          company_name: null,
          product_sku: null,
          children: [],
          width: 20,
          height: 80,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "box-element") {
        setDraggedOverSectionId(null);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          children: [],
          width: 30,
          height: 300,
          holderBackgroundColor: "rgba(200,200,200,1)",
          backgroundImageOpacity: 100,
          objectFit: "cover",
          src: "",
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "lightgrey",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      }
      if (elementId === "image-element") {
        setDraggedOverSectionId(null);

        console.log(event.clientY);
        console.log(sections[pageDesigning][sectionsIndex].position.y);

        const newElement = {
          id: Date.now().toString(),
          type: elementId,
          position: {
            x: (event.clientX / viewportWidth) * 100,
            y:
              sectionsIndex === sections[pageDesigning].length - 1 &&
                sections[pageDesigning].length !== 1
                ? event.clientY -
                sections[pageDesigning][sectionsIndex - 1].position.y -
                sections[pageDesigning][sectionsIndex - 1].height -
                40 +
                scrollTop
                : event.clientY -
                sections[pageDesigning][sectionsIndex].position.y -
                40 +
                scrollTop,
          },
          children: [],
          width: 25,
          height: 300,
          objectFit: "cover",
          src: imageReference,
          content: "",
          fontSize: 14,
          fontWeight: 300,
          textAlign: "left",
          lineHeight: 15,
          ref: null,
          letterSpacing: 1,
          backgroundColor: "white",
          color: "#1f1f1f",
          border: { px: "0", solid: "solid", color: "black" },
          shadow: {
            horizontal: "0",
            vertical: "0",
            blur: "0",
            color: "black",
          },
          opacity: 100,
          borderRadius: "0",
          alignItems: "flex-start",
          slide: activeSlide,
          parent: { type: "section", id: sectionId },
          mobile: { type: "section", id: sectionId },
        };
        setBaseElement([...baseElement, newElement]);
      } else {
        setDraggedOverSectionId(null);

        // Handle case for existing elements
        const draggedSectionIndex = sections[pageDesigning].findIndex(
          (section) => section.children.some((child) => child.id === elementId)
        );
        const draggedSection = sections[pageDesigning][draggedSectionIndex];
        const draggedElementIndex = elementId
          ? draggedSection.children.findIndex(
            (element) => element.id === elementId
          )
          : -1;
        const draggedElement = elementId
          ? draggedSection.children[draggedElementIndex]
          : -1;

        if (
          draggedElement &&
          draggedSectionIndex !== -1 &&
          draggedElementIndex !== -1
        ) {
          const updatedSections = [...sections[pageDesigning]];
          const updatedDraggedSection = { ...draggedSection };

          updatedSections[draggedSectionIndex] = updatedDraggedSection;

          const hoveringSectionId = findSectionIdByMousePosition(
            event.clientY,
            event.clientX
          );

          if (hoveringSectionId !== "footer") {
            // comment for jonathan: change the section id by doing the math to find out what is the section that is being hovered on top off
            const targetSectionIndex = sections[pageDesigning].findIndex(
              (section) => section.id === hoveringSectionId
            );

            // When you pull an item from section to section
            const updatedTargetSection = {
              ...sections[pageDesigning][targetSectionIndex],
            };
            const updatedElement = [...baseElement];
            const elementIndex = updatedElement.findIndex(
              (elements) => elements.id === draggedElement.id
            );
            const element = updatedElement[elementIndex];

            element.position.y -=
              sections[pageDesigning][targetSectionIndex].position.y -
              draggingSectionPositionY;

            // console.log(element.position.y);

            element.slide = activeSlide;
            element.parent.type = "section";
            element.parent.id = hoveringSectionId;
            element.mobile.type = "section";
            element.mobile.id = hoveringSectionId;

            setBaseElement(updatedElement);
          } else if (hoveringSectionId === "footer") {
            // comment for jonathan: change the section id by doing the math to find out what is the section that is being hovered on top off
            const targetSectionIndex = sections[pageDesigning].findIndex(
              (section) => section.id === hoveringSectionId
            );

            const updatedTargetSection = {
              ...sections[pageDesigning][targetSectionIndex],
            };
            const updatedElement = [...baseElement];
            const elementIndex = updatedElement.findIndex(
              (elements) => elements.id === draggedElement.id
            );
            const element = updatedElement[elementIndex];

            element.position.y -=
              sections[pageDesigning][targetSectionIndex - 1].position.y +
              sections[pageDesigning][targetSectionIndex - 1].height -
              draggingSectionPositionY;

            console.log(element.position.y);
            element.slide = activeSlide;
            element.parent.type = "section";
            element.parent.id = hoveringSectionId;
            element.mobile.type = "section";
            element.mobile.id = hoveringSectionId;

            setBaseElement(updatedElement);
          }
        }
      }
    }
  };

  const handleDragOver = (event, sectionId) => {
    event.preventDefault();
    setDraggedOverSectionId(
      findSectionIdByMousePosition(event.clientY, event.clientX)
    );
    // console.log(event.clientY, event.clientX);
  };

  const handleResizeStart = (elementId) => {
    const deepClone = cloneDeep(sections);
    undoStack.push(deepClone);
    setResizingElementId(elementId);
  };

  const handleResize = (newWidth, newHeight, element, section) => {
    if (mobileDesigning) {
      const updatedElement = [...baseElement];
      const elementIndex = updatedElement.findIndex(
        (child) => child.id === focusElement
      );
      const element = updatedElement[elementIndex];
      if (newWidth > 0) {
        element.mwidth = newWidth;
      }
      if (newHeight > 0) {
        element.mheight = newHeight;
      }

      setBaseElement(updatedElement);
    } else {
      const updatedElement = [...baseElement];
      const elementIndex = updatedElement.findIndex(
        (child) => child.id === focusElement
      );
      const element = updatedElement[elementIndex];

      if (newWidth > 0) {
        element.width = newWidth;
      }
      if (newHeight > 0) {
        element.height = newHeight;
      }

      setBaseElement(updatedElement);
    }
  };

  const handleResizeEnd = () => {
    setResizingElementId(null);
    setDraggedOverSectionId(null);
  };

  const createSection = () => {
    const deepClone = cloneDeep(sections);
    undoStack.push(deepClone);
    if (mobileDesigning) {
      return null;
    } else {
      const newSectionHeight = 500;
      const footerExist = sections[pageDesigning].findIndex(
        (footer) => footer.id === "footer"
      );
      const headerExist = sections[pageDesigning].findIndex(
        (header) => header.id === "header"
      );
      if (footerExist !== -1 && headerExist !== -1) {
        console.log(sections[pageDesigning]);
        const existingSectionsHeight = sections[pageDesigning].reduce(
          (totalHeight, section) => totalHeight + section.height,
          0
        );
        const existingMobileSectionsHeight = sections[pageDesigning].reduce(
          (totalHeight, section) =>
            totalHeight + (section.mheight ? section.mheight : section.height),
          0
        );

        const newSection = {
          id: Date.now().toString() + `section_base_${pageDesigning}`,
          position: {
            x: 0,
            y:
              existingSectionsHeight -
              sections[pageDesigning][footerExist].height,
            my:
              existingMobileSectionsHeight -
              (mobileSections[pageDesigning][footerExist].mheight
                ? mobileSections[pageDesigning][footerExist].mheight
                : mobileSections[pageDesigning][footerExist].height),
          },
          height: 500,
          width: "100%",
          parent: { type: "page", id: pageDesigning },
          children: [],
          backgroundColor: "white",
          src: "",
          opacity: "100",
        };

        const updatedPageSections = [...sections[pageDesigning]];

        const updatingBaseSections = [...baseSections];

        const footerIndex = updatingBaseSections.findIndex(
          (footer) => footer.id === "footer"
        );

        updatingBaseSections.splice(footerIndex, 0, newSection);

        updatingBaseSections[footerIndex + 1].position.y =
          sections[pageDesigning][footerExist].position.y + newSectionHeight;

        setBaseSections(updatingBaseSections);
      } else {
        console.log(sections[pageDesigning]);
        const existingSectionsHeight = sections[pageDesigning].reduce(
          (totalHeight, section) => totalHeight + section.height,
          0
        );
        const existingMobileSectionsHeight = sections[pageDesigning].reduce(
          (totalHeight, section) =>
            totalHeight + (section.mheight ? section.mheight : section.height),
          0
        );

        const newSection = {
          id: Date.now().toString() + `section_base_${pageDesigning}`,
          position: {
            x: 0,
            y: existingSectionsHeight,
            my: existingMobileSectionsHeight,
          },
          height: 500,
          width: "100%",
          parent: { type: "page", id: pageDesigning },
          children: [],
          backgroundColor: "white",
          src: "",
          opacity: "100",
        };

        setBaseSections([...baseSections, newSection]);
      }
    }
  };

  const createSliderSection = () => {
    const deepClone = cloneDeep(sections);
    undoStack.push(deepClone);
    if (mobileDesigning) {
      return null;
    } else {
      const newSectionHeight = 500;
      const footerExist = sections[pageDesigning].findIndex(
        (footer) => footer.id === "footer"
      );
      const headerExist = sections[pageDesigning].findIndex(
        (header) => header.id === "header"
      );
      if (footerExist !== -1 && headerExist !== -1) {
        console.log(sections[pageDesigning]);
        const existingSectionsHeight = sections[pageDesigning].reduce(
          (totalHeight, section) => totalHeight + section.height,
          0
        );
        const existingMobileSectionsHeight = sections[pageDesigning].reduce(
          (totalHeight, section) =>
            totalHeight + (section.mheight ? section.mheight : section.height),
          0
        );

        const newSection = {
          id: Date.now().toString() + `section_base_${pageDesigning}`,
          position: {
            x: 0,
            y:
              existingSectionsHeight -
              sections[pageDesigning][footerExist].height,
            my:
              existingMobileSectionsHeight -
              (mobileSections[pageDesigning][footerExist].mheight
                ? mobileSections[pageDesigning][footerExist].mheight
                : mobileSections[pageDesigning][footerExist].height),
          },
          height: newSectionHeight,
          width: "100%",
          children: [],
          backgroundColor: "white",
          parent: { type: "page", id: pageDesigning },
          src: "",
          opacity: "100",
          slider: true,
          numberOfSlides: 1,
          activeSlide: 1,
        };

        const updatedPageSections = [...sections[pageDesigning]];

        const updatingBaseSections = [...baseSections];

        const footerIndex = updatingBaseSections.findIndex(
          (footer) => footer.id === "footer"
        );

        updatingBaseSections.splice(footerIndex, 0, newSection);

        updatingBaseSections[footerIndex + 1].position.y =
          sections[pageDesigning][footerExist].position.y + newSectionHeight;

        setBaseSections(updatingBaseSections);
      } else {
        console.log(sections[pageDesigning]);
        const existingSectionsHeight = sections[pageDesigning].reduce(
          (totalHeight, section) => totalHeight + section.height,
          0
        );
        const existingMobileSectionsHeight = sections[pageDesigning].reduce(
          (totalHeight, section) =>
            totalHeight + (section.mheight ? section.mheight : section.height),
          0
        );

        const newSection = {
          id: Date.now().toString() + `section_base_${pageDesigning}`,
          position: {
            x: 0,
            y: existingSectionsHeight,
            my: existingMobileSectionsHeight,
          },
          height: newSectionHeight,
          width: "100%",
          children: [],
          backgroundColor: "white",
          parent: { type: "page", id: pageDesigning },
          src: "",
          opacity: "100",
          slider: true,
          numberOfSlides: 1,
          activeSlide: 1,
        };

        setBaseSections([...baseSections, newSection]);
      }
    }
  };

  const createSectionAfterIndex = (sectionIndex) => {
    if (mobileDesigning) {
      return null;
    } else {
      const insertionIndex = sectionIndex + 1;
      const newSectionHeight = 500;

      const newSection = {
        id: Date.now().toString(),
        position: {
          x: 0,
          y:
            sections[pageDesigning][sectionIndex].position.y +
            sections[pageDesigning][sectionIndex].height,
          my:
            (sections[pageDesigning][sectionIndex].position.my
              ? sections[pageDesigning][sectionIndex].position.my
              : sections[pageDesigning][sectionIndex].position.y) +
            (sections[pageDesigning][sectionIndex].mheight
              ? sections[pageDesigning][sectionIndex].mheight
              : sections[pageDesigning][sectionIndex].height),
        },
        height: newSectionHeight,
        width: "100%",
        parent: { type: "page", id: pageDesigning },
        children: [],
        backgroundColor: "white",
        src: "",
        opacity: "100",
      };

      const updatedSections = [...sections[pageDesigning]];
      const updatingBaseSections = [...baseSections];
      const baseSectionInsertionIndex = updatingBaseSections.findIndex(
        (sectionssss) => sectionssss.id === updatedSections[sectionIndex].id
      );
      console.log(updatedSections[sectionIndex].id, baseSectionInsertionIndex);
      updatedSections.splice(insertionIndex, 0, newSection);
      updatingBaseSections.splice(baseSectionInsertionIndex + 1, 0, newSection);

      for (let i = insertionIndex + 1; i < updatedSections.length; i++) {
        const changingSectionInsertionIndex = updatingBaseSections.findIndex(
          (sectionssss) => sectionssss.id === updatedSections[i].id
        );
        console.log(updatedSections[i].id, changingSectionInsertionIndex);
        updatingBaseSections[changingSectionInsertionIndex].position.y +=
          newSectionHeight;
        updatingBaseSections[changingSectionInsertionIndex].position.my +=
          newSectionHeight;
      }

      setBaseSections(updatingBaseSections);
    }
  };

  const createSliderAfterIndex = (sectionIndex) => {
    if (mobileDesigning) {
      return null;
    } else {
      const insertionIndex = sectionIndex + 1;
      const newSectionHeight = 500;

      const newSection = {
        id: Date.now().toString() + `section_base_${pageDesigning}`,
        position: {
          x: 0,
          y:
            sections[pageDesigning][sectionIndex].position.y +
            sections[pageDesigning][sectionIndex].height,
          my:
            (sections[pageDesigning][sectionIndex].position.my
              ? sections[pageDesigning][sectionIndex].position.my
              : sections[pageDesigning][sectionIndex].position.y) +
            (sections[pageDesigning][sectionIndex].mheight
              ? sections[pageDesigning][sectionIndex].mheight
              : sections[pageDesigning][sectionIndex].height),
        },
        height: newSectionHeight,
        width: "100%",
        parent: { type: "page", id: pageDesigning },
        children: [],
        backgroundColor: "white",
        src: "",
        opacity: "100",
        slider: true,
        numberOfSlides: 1,
        activeSlide: 1,
      };

      const updatedSections = [...sections[pageDesigning]];
      const updatingBaseSections = [...baseSections];
      const baseSectionInsertionIndex = updatingBaseSections.findIndex(
        (sectionssss) => sectionssss.id === updatedSections[sectionIndex].id
      );
      console.log(updatedSections[sectionIndex].id, baseSectionInsertionIndex);
      updatedSections.splice(insertionIndex, 0, newSection);
      updatingBaseSections.splice(baseSectionInsertionIndex + 1, 0, newSection);

      for (let i = insertionIndex + 1; i < updatedSections.length; i++) {
        const changingSectionInsertionIndex = updatingBaseSections.findIndex(
          (sectionssss) => sectionssss.id === updatedSections[i].id
        );
        console.log(updatedSections[i].id, changingSectionInsertionIndex);
        updatingBaseSections[changingSectionInsertionIndex].position.y +=
          newSectionHeight;
        updatingBaseSections[changingSectionInsertionIndex].position.my +=
          newSectionHeight;
      }

      setBaseSections(updatingBaseSections);
    }
  };

  const createTemplateAfterIndex = (sectionIndex, template) => {
    if (mobileDesigning) {
      return null;
    } else {
      const insertionIndex = sectionIndex + 1;
      const newSectionHeight = JSON.parse(template.section_array)[0].height;
      const newId = Date.now().toString() + `section_base_${pageDesigning}`;
      const newSection = {
        ...JSON.parse(template.section_array)[0],
        id: newId,
        position: {
          x: 0,
          y:
            sections[pageDesigning][sectionIndex].position.y +
            sections[pageDesigning][sectionIndex].height,
          my:
            (sections[pageDesigning][sectionIndex].position.my
              ? sections[pageDesigning][sectionIndex].position.my
              : sections[pageDesigning][sectionIndex].position.y) +
            (sections[pageDesigning][sectionIndex].mheight
              ? sections[pageDesigning][sectionIndex].mheight
              : sections[pageDesigning][sectionIndex].height),
        },
        height: newSectionHeight,
        mheight: JSON.parse(template.section_array)[0].mheight
          ? JSON.parse(template.section_array)[0].mheight
          : newSectionHeight,
        width: "100%",
        parent: { type: "page", id: pageDesigning },
        children: [],
        backgroundColor: "white",
        src: "",
        opacity: "100",
      };

      const updatedSections = [...sections[pageDesigning]];
      const updatedMobileSections = [...mobileSections[pageDesigning]];
      const updatingBaseSections = [...baseSections];
      const baseSectionInsertionIndex = updatingBaseSections.findIndex(
        (sectionssss) => sectionssss.id === updatedSections[sectionIndex].id
      );
      console.log(updatedSections[sectionIndex].id, baseSectionInsertionIndex);
      updatedSections.splice(insertionIndex, 0, newSection);
      updatingBaseSections.splice(baseSectionInsertionIndex + 1, 0, newSection);

      for (let i = insertionIndex + 1; i < updatedSections.length; i++) {
        const changingSectionInsertionIndex = updatingBaseSections.findIndex(
          (sectionssss) => sectionssss.id === updatedSections[i].id
        );
        console.log(updatedSections[i].id, changingSectionInsertionIndex);
        updatingBaseSections[changingSectionInsertionIndex].position.y +=
          newSectionHeight;
        updatingBaseSections[changingSectionInsertionIndex].position.my +=
          JSON.parse(template.section_array)[0].mheight
            ? JSON.parse(template.section_array)[0].mheight
            : newSectionHeight;
      }

      const updatingElementArray = [...JSON.parse(template.element_array)];
      for (const ele of updatingElementArray) {
        const generateduuid = `${Date.now().toString()}` + `${uuid()}`;
        ele.id = generateduuid;
        ele.parent.id = newId;
        ele.mobile.id = newId;
      }

      setCreateTemplateModelIsOpen(false);
      setBaseElement([...baseElement, ...updatingElementArray]);
      setBaseSections(updatingBaseSections);
    }
  };

  const onContentChange = (content, elementId, boxIndex) => {
    const updatingElement = [...baseElement];
    const elementIndex = updatingElement.findIndex(
      (child) => child.id === focusElement
    );
    updatingElement[elementIndex].content = content;
    setBaseElement(updatingElement);
  };

  const calculateBoundingBoxRect = (elements) => {
    let top = Infinity;
    let left = Infinity;
    let right = -Infinity;
    let bottom = -Infinity;

    elements.forEach((element) => {
      if (mobileDesigning) {
        const sectionIndex = mobileSections[pageDesigning].findIndex(
          (section) => section.children.some((child) => child.id === element)
        );
        if (sectionIndex !== -1) {
          const elementIndex = mobileSections[pageDesigning][
            sectionIndex
          ].children.findIndex((child) => child.id === element);
          // console.log(elementRect);
          const footerExist = sections[pageDesigning].findIndex(
            (sect) => sect.id === "footer"
          );
          if (footerExist > -1) {
            if (sectionIndex === mobileSections[pageDesigning].length - 1) {
              const lastIndex = mobileSections[pageDesigning].length - 2;
              const sectionPosition =
                (mobileSections[pageDesigning][lastIndex].position.my
                  ? mobileSections[pageDesigning][lastIndex].position.my
                  : mobileSections[pageDesigning][lastIndex].position.y) +
                (mobileSections[pageDesigning][lastIndex].mheight
                  ? mobileSections[pageDesigning][lastIndex].mheight
                  : mobileSections[pageDesigning][lastIndex].height);

              top = Math.min(
                top,
                (mobileSections[pageDesigning][sectionIndex].children[
                  elementIndex
                ].position.my
                  ? mobileSections[pageDesigning][sectionIndex].children[
                    elementIndex
                  ].position.my
                  : mobileSections[pageDesigning][sectionIndex].children[
                    elementIndex
                  ].position.y) + sectionPosition
              );
              left = Math.min(
                left,
                mobileSections[pageDesigning][sectionIndex].children[
                  elementIndex
                ].position.mx
                  ? mobileSections[pageDesigning][sectionIndex].children[
                    elementIndex
                  ].position.mx
                  : mobileSections[pageDesigning][sectionIndex].children[
                    elementIndex
                  ].position.x
              );
              right = Math.max(
                right,
                (mobileSections[pageDesigning][sectionIndex].children[
                  elementIndex
                ].position.mx
                  ? mobileSections[pageDesigning][sectionIndex].children[
                    elementIndex
                  ].position.mx
                  : mobileSections[pageDesigning][sectionIndex].children[
                    elementIndex
                  ].position.x) +
                (mobileSections[pageDesigning][sectionIndex].children[
                  elementIndex
                ].mwidth
                  ? mobileSections[pageDesigning][sectionIndex].children[
                    elementIndex
                  ].mwidth
                  : mobileSections[pageDesigning][sectionIndex].children[
                    elementIndex
                  ].width)
              );
              bottom = Math.max(
                bottom,
                (mobileSections[pageDesigning][sectionIndex].children[
                  elementIndex
                ].position.my
                  ? mobileSections[pageDesigning][sectionIndex].children[
                    elementIndex
                  ].position.my
                  : mobileSections[pageDesigning][sectionIndex].children[
                    elementIndex
                  ].position.y) +
                (mobileSections[pageDesigning][sectionIndex].children[
                  elementIndex
                ].mheight
                  ? mobileSections[pageDesigning][sectionIndex].children[
                    elementIndex
                  ].mheight
                  : mobileSections[pageDesigning][sectionIndex].children[
                    elementIndex
                  ].height) +
                sectionPosition
              );
            } else {
              top = Math.min(
                top,
                (mobileSections[pageDesigning][sectionIndex].children[
                  elementIndex
                ].position.my
                  ? mobileSections[pageDesigning][sectionIndex].children[
                    elementIndex
                  ].position.my
                  : mobileSections[pageDesigning][sectionIndex].children[
                    elementIndex
                  ].position.y) +
                (mobileSections[pageDesigning][sectionIndex].position.my
                  ? mobileSections[pageDesigning][sectionIndex].position.my
                  : mobileSections[pageDesigning][sectionIndex].position.y)
              );
              left = Math.min(
                left,
                mobileSections[pageDesigning][sectionIndex].children[
                  elementIndex
                ].position.mx
                  ? mobileSections[pageDesigning][sectionIndex].children[
                    elementIndex
                  ].position.mx
                  : mobileSections[pageDesigning][sectionIndex].children[
                    elementIndex
                  ].position.x
              );
              right = Math.max(
                right,
                (mobileSections[pageDesigning][sectionIndex].children[
                  elementIndex
                ].position.mx
                  ? mobileSections[pageDesigning][sectionIndex].children[
                    elementIndex
                  ].position.mx
                  : mobileSections[pageDesigning][sectionIndex].children[
                    elementIndex
                  ].position.x) +
                (mobileSections[pageDesigning][sectionIndex].children[
                  elementIndex
                ].mwidth
                  ? mobileSections[pageDesigning][sectionIndex].children[
                    elementIndex
                  ].mwidth
                  : mobileSections[pageDesigning][sectionIndex].children[
                    elementIndex
                  ].width)
              );
              bottom = Math.max(
                bottom,
                (mobileSections[pageDesigning][sectionIndex].children[
                  elementIndex
                ].position.my
                  ? mobileSections[pageDesigning][sectionIndex].children[
                    elementIndex
                  ].position.my
                  : mobileSections[pageDesigning][sectionIndex].children[
                    elementIndex
                  ].position.y) +
                (mobileSections[pageDesigning][sectionIndex].children[
                  elementIndex
                ].mheight
                  ? mobileSections[pageDesigning][sectionIndex].children[
                    elementIndex
                  ].mheight
                  : mobileSections[pageDesigning][sectionIndex].children[
                    elementIndex
                  ].height) +
                (mobileSections[pageDesigning][sectionIndex].position.my
                  ? mobileSections[pageDesigning][sectionIndex].position.my
                  : mobileSections[pageDesigning][sectionIndex].position.y)
              );
            }
          } else {
            top = Math.min(
              top,
              (mobileSections[pageDesigning][sectionIndex].children[
                elementIndex
              ].position.my
                ? mobileSections[pageDesigning][sectionIndex].children[
                  elementIndex
                ].position.my
                : mobileSections[pageDesigning][sectionIndex].children[
                  elementIndex
                ].position.y) +
              (mobileSections[pageDesigning][sectionIndex].position.my
                ? mobileSections[pageDesigning][sectionIndex].position.my
                : mobileSections[pageDesigning][sectionIndex].position.y)
            );
            left = Math.min(
              left,
              mobileSections[pageDesigning][sectionIndex].children[elementIndex]
                .position.mx
                ? mobileSections[pageDesigning][sectionIndex].children[
                  elementIndex
                ].position.mx
                : mobileSections[pageDesigning][sectionIndex].children[
                  elementIndex
                ].position.x
            );
            right = Math.max(
              right,
              (mobileSections[pageDesigning][sectionIndex].children[
                elementIndex
              ].position.mx
                ? mobileSections[pageDesigning][sectionIndex].children[
                  elementIndex
                ].position.mx
                : mobileSections[pageDesigning][sectionIndex].children[
                  elementIndex
                ].position.x) +
              (mobileSections[pageDesigning][sectionIndex].children[
                elementIndex
              ].mwidth
                ? mobileSections[pageDesigning][sectionIndex].children[
                  elementIndex
                ].mwidth
                : mobileSections[pageDesigning][sectionIndex].children[
                  elementIndex
                ].width)
            );
            bottom = Math.max(
              bottom,
              (mobileSections[pageDesigning][sectionIndex].children[
                elementIndex
              ].position.my
                ? mobileSections[pageDesigning][sectionIndex].children[
                  elementIndex
                ].position.my
                : mobileSections[pageDesigning][sectionIndex].children[
                  elementIndex
                ].position.y) +
              (mobileSections[pageDesigning][sectionIndex].children[
                elementIndex
              ].mheight
                ? mobileSections[pageDesigning][sectionIndex].children[
                  elementIndex
                ].mheight
                : mobileSections[pageDesigning][sectionIndex].children[
                  elementIndex
                ].height) +
              (mobileSections[pageDesigning][sectionIndex].position.my
                ? mobileSections[pageDesigning][sectionIndex].position.my
                : mobileSections[pageDesigning][sectionIndex].position.y)
            );
          }
        }
      } else {
        const sectionIndex = sections[pageDesigning].findIndex((section) =>
          section.children.some((child) => child.id === element)
        );
        if (sectionIndex !== -1) {
          const elementIndex = sections[pageDesigning][
            sectionIndex
          ].children.findIndex((child) => child.id === element);
          // console.log(elementRect);
          const footerExist = sections[pageDesigning].findIndex(
            (sect) => sect.id === "footer"
          );
          if (footerExist > -1) {
            if (sectionIndex === sections[pageDesigning].length - 1) {
              const lastIndex = sections[pageDesigning].length - 2;
              const sectionPosition =
                sections[pageDesigning][lastIndex].position.y +
                sections[pageDesigning][lastIndex].height;

              top = Math.min(
                top,
                sections[pageDesigning][sectionIndex].children[elementIndex]
                  .position.y + sectionPosition
              );
              left = Math.min(
                left,
                sections[pageDesigning][sectionIndex].children[elementIndex]
                  .position.x
              );
              right = Math.max(
                right,
                sections[pageDesigning][sectionIndex].children[elementIndex]
                  .position.x +
                sections[pageDesigning][sectionIndex].children[elementIndex]
                  .width
              );
              bottom = Math.max(
                bottom,
                sections[pageDesigning][sectionIndex].children[elementIndex]
                  .position.y +
                sections[pageDesigning][sectionIndex].children[elementIndex]
                  .height +
                sectionPosition
              );
            } else {
              top = Math.min(
                top,
                sections[pageDesigning][sectionIndex].children[elementIndex]
                  .position.y + sections[pageDesigning][sectionIndex].position.y
              );
              left = Math.min(
                left,
                sections[pageDesigning][sectionIndex].children[elementIndex]
                  .position.x
              );
              right = Math.max(
                right,
                sections[pageDesigning][sectionIndex].children[elementIndex]
                  .position.x +
                sections[pageDesigning][sectionIndex].children[elementIndex]
                  .width
              );
              bottom = Math.max(
                bottom,
                sections[pageDesigning][sectionIndex].children[elementIndex]
                  .position.y +
                sections[pageDesigning][sectionIndex].children[elementIndex]
                  .height +
                sections[pageDesigning][sectionIndex].position.y
              );
            }
          } else {
            top = Math.min(
              top,
              sections[pageDesigning][sectionIndex].children[elementIndex]
                .position.y + sections[pageDesigning][sectionIndex].position.y
            );
            left = Math.min(
              left,
              sections[pageDesigning][sectionIndex].children[elementIndex]
                .position.x
            );
            right = Math.max(
              right,
              sections[pageDesigning][sectionIndex].children[elementIndex]
                .position.x +
              sections[pageDesigning][sectionIndex].children[elementIndex]
                .width
            );
            bottom = Math.max(
              bottom,
              sections[pageDesigning][sectionIndex].children[elementIndex]
                .position.y +
              sections[pageDesigning][sectionIndex].children[elementIndex]
                .height +
              sections[pageDesigning][sectionIndex].position.y
            );
          }
          console.log(
            sections[pageDesigning][sectionIndex].children[elementIndex]
              .position.y,
            sections[pageDesigning][sectionIndex].position.y
          );
        }
      }
    });

    return {
      top,
      left,
      width: right - left,
      height: bottom - top,
    };
  };

  const handleShiftClick = (event, elementId) => {
    if (elementId === "") {
      return; // Ignore empty elementId
    }

    if (event.shiftKey) {
      // Shift key is pressed
      // console.log("shift click pressed", elementId);
      // const shiftClickedElements = getShiftClickedElements(elementId);
      const updatedSelectedElements = selectedElements.includes(elementId)
        ? selectedElements.filter((id) => id !== elementId)
        : [...selectedElements, elementId];
      // console.log(elementId);

      // Check if there are any shift-clicked elements
      if (updatedSelectedElements.length > 0) {
        const boundingBoxRect = calculateBoundingBoxRect(
          updatedSelectedElements
        );
        setBoundingBoxDimensions({
          width: boundingBoxRect.width,
          height: boundingBoxRect.height,
          top: boundingBoxRect.top,
          left: boundingBoxRect.left,
        });
      } else {
        setBoundingBoxDimensions(null);
      }
      // console.log(updatedSelectedElements);
      setSelectedElements(updatedSelectedElements);
    } else {
      // Shift key is not pressed
      // console.log([elementId]);
      setSelectedElements([elementId]);
      setBoundingBoxDimensions(null);
    }
  };

  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [draggingOffset, setDraggingOffset] = useState({ x: 0, y: 0 });
  const [initialPositions, setInitialPositions] = useState([]);
  const [initialBoundingBox, setInitialBoundingBox] = useState([]);
  const [isBoundingBoxDragging, setIsBoundingBoxDragging] = useState(false);

  const handleBoundingBoxDragStart = (event) => {
    setIsDragging(true);
    setIsBoundingBoxDragging(true);
    setInitialBoundingBox(boundingBoxDimensions);
    console.log(boundingBoxDimensions);
    console.log("is dragging is true");
    const offsetX = event.clientX;
    const offsetY = event.clientY;
    const initialPositions = {};

    if (mobileDesigning) {
      selectedElements.forEach((elementId) => {
        const updatingElement = [...baseElement];

        const elementIndex = updatingElement.findIndex(
          (child) => child.id === elementId
        );
        const element = updatingElement[elementIndex];
        initialPositions[elementId] = {
          x: element.position.mx ? element.position.mx : element.position.x,
          y: element.position.my ? element.position.my : element.position.y,
        };
      });
    } else {
      selectedElements.forEach((elementId) => {
        const sectionIndex = sections[pageDesigning].findIndex((section) =>
          section.children.some((child) => child.id === elementId)
        );
        const section = sections[pageDesigning][sectionIndex];
        const elementIndex = section.children.findIndex(
          (child) => child.id === elementId
        );
        const element = section.children[elementIndex];
        initialPositions[elementId] = {
          x: element.position.x,
          y: element.position.y,
        };
      });
    }

    setDragOffset({ x: event.clientX, y: event.clientY });
    setInitialPositions(initialPositions);
    // console.log("with starting at", { x: offsetX, y: offsetY });
  };

  useEffect(() => {
    const handleMouseMove = (event) => {
      if (isDragging) {
        if (mobileDesigning) {
          const xDiff = ((event.clientX - dragOffset.x) / 400) * 100;
          const yDiff = event.clientY - dragOffset.y;
          const updatedBoundingBoxDimensions = {
            width: boundingBoxDimensions.width,
            height: boundingBoxDimensions.height,
            top: boundingBoxDimensions.top + yDiff,
            left:
              (((initialBoundingBox.left * 400) / 100 +
                (event.clientX - dragOffset.x)) /
                400) *
              100,
          };

          console.log(event.clientX - dragOffset.x);
          setBoundingBoxDimensions(updatedBoundingBoxDimensions);
          // Calculate the updated position for each element within the bounding box
          selectedElements.forEach((elementId) => {
            const updatingElement = [...baseElement];

            const elementIndex = updatingElement.findIndex(
              (child) => child.id === elementId
            );
            const element = updatingElement[elementIndex];
            element.position.mx = initialPositions[elementId].x + xDiff;
            element.position.my = initialPositions[elementId].y + yDiff;
            setBaseElement(updatingElement);
          });
        } else {
          const xDiff = ((event.clientX - dragOffset.x) / viewportWidth) * 100;
          const yDiff = event.clientY - dragOffset.y;
          const updatedBoundingBoxDimensions = {
            width: boundingBoxDimensions.width,
            height: boundingBoxDimensions.height,
            top: boundingBoxDimensions.top + yDiff,
            left:
              (((initialBoundingBox.left * viewportWidth) / 100 +
                (event.clientX - dragOffset.x)) /
                viewportWidth) *
              100,
          };

          console.log(event.clientX - dragOffset.x);
          setBoundingBoxDimensions(updatedBoundingBoxDimensions);
          // Calculate the updated position for each element within the bounding box
          selectedElements.forEach((elementId) => {
            const updatingElement = [...baseElement];

            const elementIndex = updatingElement.findIndex(
              (child) => child.id === elementId
            );
            const element = updatingElement[elementIndex];
            element.position.x = initialPositions[elementId].x + xDiff;
            element.position.y = initialPositions[elementId].y + yDiff;
            setBaseElement(updatingElement);
          });
        }
      }
    };
    const handleMouseUp = () => {
      setIsDragging(false);
      setIsBoundingBoxDragging(false);
      setDraggingOffset({ x: 0, y: 0 });
    };

    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isBoundingBoxDragging, dragOffset, isDragging]);

  const handleGroupLeftAlign = (event) => {
    // setBoundingBoxDimensions(null);
    // const boundingBoxRect = boundingBoxRef.current.getBoundingClientRect();
    if (mobileDesigning) {
      console.log(boundingBoxDimensions);
      const updatedSelectedElements = [];

      selectedElements.forEach((elementId) => {
        const updatingBaseElement = [...baseElement];

        const elementIndex = updatingBaseElement.findIndex(
          (elemen) => elemen.id === elementId
        );

        const element = updatingBaseElement[elementIndex];

        element.position.mx = boundingBoxDimensions.left;

        updatedSelectedElements.push(element);

        setBaseElement(updatingBaseElement);
      });

      const maxWidth = Math.max(
        ...updatedSelectedElements.map((element) =>
          element.mwidth ? element.mwidth : element.width
        )
      );

      setBoundingBoxDimensions({
        width: maxWidth,
        height: boundingBoxDimensions.height,
        top: boundingBoxDimensions.top,
        left: boundingBoxDimensions.left,
      });
    } else {
      console.log(boundingBoxDimensions);
      const updatedSelectedElements = [];

      selectedElements.forEach((elementId) => {
        const updatingBaseElement = [...baseElement];

        const elementIndex = updatingBaseElement.findIndex(
          (elemen) => elemen.id === elementId
        );

        const element = updatingBaseElement[elementIndex];

        element.position.x = boundingBoxDimensions.left;

        updatedSelectedElements.push(element);

        setBaseElement(updatingBaseElement);
      });

      const maxWidth = Math.max(
        ...updatedSelectedElements.map((element) => element.width)
      );

      setBoundingBoxDimensions({
        width: maxWidth,
        height: boundingBoxDimensions.height,
        top: boundingBoxDimensions.top,
        left: boundingBoxDimensions.left,
      });
    }
  };

  const handleGroupHMiddleAlign = (event) => {
    // setBoundingBoxDimensions(null);
    if (mobileDesigning) {
      const updatedSelectedElements = [];

      selectedElements.forEach((elementId) => {
        const updatingBaseElement = [...baseElement];

        const elementIndex = updatingBaseElement.findIndex(
          (elemen) => elemen.id === elementId
        );

        const element = updatingBaseElement[elementIndex];

        element.position.mx =
          boundingBoxDimensions.left +
          boundingBoxDimensions.width / 2 -
          (element.mwidth ? element.mwidth : element.width) / 2;

        updatedSelectedElements.push(element);

        setBaseElement(updatingBaseElement);
      });

      const maxWidth = Math.max(
        ...updatedSelectedElements.map((element) =>
          element.mwidth ? element.mwidth : element.width
        )
      );

      const updatedLeft =
        boundingBoxDimensions.left +
        boundingBoxDimensions.width / 2 -
        maxWidth / 2;

      setBoundingBoxDimensions({
        width: maxWidth,
        height: boundingBoxDimensions.height,
        top: boundingBoxDimensions.top,
        left: updatedLeft,
      });
    } else {
      const updatedSelectedElements = [];

      selectedElements.forEach((elementId) => {
        const updatingBaseElement = [...baseElement];

        const elementIndex = updatingBaseElement.findIndex(
          (elemen) => elemen.id === elementId
        );

        const element = updatingBaseElement[elementIndex];

        element.position.x =
          boundingBoxDimensions.left +
          boundingBoxDimensions.width / 2 -
          element.width / 2;

        updatedSelectedElements.push(element);

        setBaseElement(updatingBaseElement);
      });

      const maxWidth = Math.max(
        ...updatedSelectedElements.map((element) => element.width)
      );
      const updatedLeft =
        boundingBoxDimensions.left +
        boundingBoxDimensions.width / 2 -
        maxWidth / 2;
      console.log(...updatedSelectedElements.map((element) => element.width));
      setBoundingBoxDimensions({
        width: maxWidth,
        height: boundingBoxDimensions.height,
        top: boundingBoxDimensions.top,
        left: updatedLeft,
      });
    }
  };

  const handleGroupRightAlign = (event) => {
    if (mobileDesigning) {
      const updatedSelectedElements = [];

      selectedElements.forEach((elementId) => {
        const updatingBaseElement = [...baseElement];

        const elementIndex = updatingBaseElement.findIndex(
          (elemen) => elemen.id === elementId
        );

        const element = updatingBaseElement[elementIndex];

        element.position.mx =
          boundingBoxDimensions.left +
          boundingBoxDimensions.width -
          (element.mwidth ? element.mwidth : element.width);

        updatedSelectedElements.push(element);

        setBaseElement(updatingBaseElement);
      });

      const maxWidth = Math.max(
        ...updatedSelectedElements.map((element) =>
          element.mwidth ? element.mwidth : element.width
        )
      );
      const updatedLeft =
        boundingBoxDimensions.left + boundingBoxDimensions.width - maxWidth;

      setBoundingBoxDimensions({
        width: maxWidth,
        height: boundingBoxDimensions.height,
        top: boundingBoxDimensions.top,
        left: updatedLeft,
      });
    } else {
      const updatedSelectedElements = [];

      selectedElements.forEach((elementId) => {
        const updatingBaseElement = [...baseElement];

        const elementIndex = updatingBaseElement.findIndex(
          (elemen) => elemen.id === elementId
        );

        const element = updatingBaseElement[elementIndex];

        element.position.x =
          boundingBoxDimensions.left +
          boundingBoxDimensions.width -
          element.width;

        updatedSelectedElements.push(element);

        setBaseElement(updatingBaseElement);
      });

      const maxWidth = Math.max(
        ...updatedSelectedElements.map((element) => element.width)
      );
      const updatedLeft =
        boundingBoxDimensions.left + boundingBoxDimensions.width - maxWidth;

      setBoundingBoxDimensions({
        width: maxWidth,
        height: boundingBoxDimensions.height,
        top: boundingBoxDimensions.top,
        left: updatedLeft,
      });
    }
  };

  const handleTheTopAlign = (minHeight) => {
    if (mobileDesigning) {
      selectedElements.forEach((elementId) => {
        const updatingBaseElement = [...baseElement];

        const elementIndex = updatingBaseElement.findIndex(
          (elemen) => elemen.id === elementId
        );

        const element = updatingBaseElement[elementIndex];

        element.position.my = minHeight;

        setBaseElement(updatingBaseElement);
      });

      const boundingBoxRect = calculateBoundingBoxRect(selectedElements);

      setBoundingBoxDimensions({
        width: boundingBoxRect.width,
        height: boundingBoxRect.height,
        top: boundingBoxRect.top,
        left: boundingBoxRect.left,
      });
    } else {
      selectedElements.forEach((elementId) => {
        const updatingBaseElement = [...baseElement];

        const elementIndex = updatingBaseElement.findIndex(
          (elemen) => elemen.id === elementId
        );

        const element = updatingBaseElement[elementIndex];

        element.position.y = minHeight;

        setBaseElement(updatingBaseElement);
      });

      const boundingBoxRect = calculateBoundingBoxRect(selectedElements);

      setBoundingBoxDimensions({
        width: boundingBoxRect.width,
        height: boundingBoxRect.height,
        top: boundingBoxRect.top,
        left: boundingBoxRect.left,
      });
    }
  };

  const handleGroupTopAlign = (event) => {
    // setBoundingBoxDimensions(null);

    if (mobileDesigning) {
      const updatedSelectedElements = [];

      selectedElements.forEach((elementId) => {
        const updatingBaseElement = [...baseElement];

        const elementIndex = updatingBaseElement.findIndex(
          (elemen) => elemen.id === elementId
        );

        const element = updatingBaseElement[elementIndex];

        updatedSelectedElements.push(element);
      });

      const minHeight = Math.min(
        ...updatedSelectedElements.map((element) =>
          element.position.my ? element.position.my : element.position.y
        )
      );
      const maxHeight = Math.max(
        ...updatedSelectedElements.map((element) =>
          element.mheight ? element.mheight : element.height
        )
      );

      handleTheTopAlign(minHeight);
    } else {
      const updatedSelectedElements = [];

      selectedElements.forEach((elementId) => {
        const updatingBaseElement = [...baseElement];

        const elementIndex = updatingBaseElement.findIndex(
          (elemen) => elemen.id === elementId
        );

        const element = updatingBaseElement[elementIndex];

        updatedSelectedElements.push(element);
      });

      const minHeight = Math.min(
        ...updatedSelectedElements.map((element) => element.position.y)
      );
      const maxHeight = Math.max(
        ...updatedSelectedElements.map((element) => element.height)
      );

      handleTheTopAlign(minHeight);
    }
  };

  const handleTheVMiddleAlign = (minTop, middleOffsetTop) => {
    if (mobileDesigning) {
      selectedElements.forEach((elementId) => {
        const updatingBaseElement = [...baseElement];

        const elementIndex = updatingBaseElement.findIndex(
          (elemen) => elemen.id === elementId
        );

        const element = updatingBaseElement[elementIndex];

        element.position.my =
          minTop +
          middleOffsetTop -
          (element.mheight ? element.mheight : element.height) / 2;

        setBaseElement(updatingBaseElement);
      });

      const boundingBoxRect = calculateBoundingBoxRect(selectedElements);

      setBoundingBoxDimensions({
        width: boundingBoxRect.width,
        height: boundingBoxRect.height,
        top: boundingBoxRect.top,
        left: boundingBoxRect.left,
      });
    } else {
      selectedElements.forEach((elementId) => {
        const updatingBaseElement = [...baseElement];

        const elementIndex = updatingBaseElement.findIndex(
          (elemen) => elemen.id === elementId
        );

        const element = updatingBaseElement[elementIndex];

        element.position.y = minTop + middleOffsetTop - element.height / 2;

        setBaseElement(updatingBaseElement);
      });

      const boundingBoxRect = calculateBoundingBoxRect(selectedElements);

      setBoundingBoxDimensions({
        width: boundingBoxRect.width,
        height: boundingBoxRect.height,
        top: boundingBoxRect.top,
        left: boundingBoxRect.left,
      });
    }
  };

  const handleGroupVMiddleAlign = (event) => {
    // setBoundingBoxDimensions(null);

    if (mobileDesigning) {
      const updatedSelectedElements = [];

      selectedElements.forEach((elementId) => {
        const updatingBaseElement = [...baseElement];

        const elementIndex = updatingBaseElement.findIndex(
          (elemen) => elemen.id === elementId
        );

        const element = updatingBaseElement[elementIndex];

        updatedSelectedElements.push(element);
      });

      const minTop = Math.min(
        ...updatedSelectedElements.map((element) =>
          element.position.my ? element.position.my : element.position.y
        )
      );

      const middleOffsetTop = boundingBoxDimensions.height / 2;

      const maxHeight = Math.max(
        ...updatedSelectedElements.map((element) =>
          element.mheight ? element.mheight : element.height
        )
      );

      const updatedTop = minTop + middleOffsetTop - maxHeight / 2;

      handleTheVMiddleAlign(minTop, middleOffsetTop);
    } else {
      const updatedSelectedElements = [];

      selectedElements.forEach((elementId) => {
        const updatingBaseElement = [...baseElement];

        const elementIndex = updatingBaseElement.findIndex(
          (elemen) => elemen.id === elementId
        );

        const element = updatingBaseElement[elementIndex];

        updatedSelectedElements.push(element);
      });

      const minTop = Math.min(
        ...updatedSelectedElements.map((element) => element.position.y)
      );

      const middleOffsetTop = boundingBoxDimensions.height / 2;

      const maxHeight = Math.max(
        ...updatedSelectedElements.map((element) => element.height)
      );

      const updatedTop = minTop + middleOffsetTop - maxHeight / 2;

      handleTheVMiddleAlign(minTop, middleOffsetTop);
    }
  };

  const handleTheBottomAlign = (bottomTop) => {
    if (mobileDesigning) {
      selectedElements.forEach((elementId) => {
        const updatingBaseElement = [...baseElement];

        const elementIndex = updatingBaseElement.findIndex(
          (elemen) => elemen.id === elementId
        );

        const element = updatingBaseElement[elementIndex];

        element.position.my =
          bottomTop - (element.mheight ? element.mheight : element.height);

        setBaseElement(updatingBaseElement);
      });

      const boundingBoxRect = calculateBoundingBoxRect(selectedElements);

      setBoundingBoxDimensions({
        width: boundingBoxRect.width,
        height: boundingBoxRect.height,
        top: boundingBoxRect.top,
        left: boundingBoxRect.left,
      });
    } else {
      selectedElements.forEach((elementId) => {
        const updatingBaseElement = [...baseElement];

        const elementIndex = updatingBaseElement.findIndex(
          (elemen) => elemen.id === elementId
        );

        const element = updatingBaseElement[elementIndex];

        element.position.y = bottomTop - element.height;

        setBaseElement(updatingBaseElement);
      });

      const boundingBoxRect = calculateBoundingBoxRect(selectedElements);

      setBoundingBoxDimensions({
        width: boundingBoxRect.width,
        height: boundingBoxRect.height,
        top: boundingBoxRect.top,
        left: boundingBoxRect.left,
      });
    }
  };

  const handleGroupBottomAlign = (event) => {
    // setBoundingBoxDimensions(null);
    if (mobileDesigning) {
      const updatedSelectedElements = [];

      selectedElements.forEach((elementId) => {
        const updatingBaseElement = [...baseElement];

        const elementIndex = updatingBaseElement.findIndex(
          (elemen) => elemen.id === elementId
        );

        const element = updatingBaseElement[elementIndex];
        // element.position.y = minHeight;
        updatedSelectedElements.push(element);
      });
      const minTop = Math.min(
        ...updatedSelectedElements.map((element) =>
          element.position.my ? element.position.my : element.position.y
        )
      );
      const maxHeight = Math.max(
        ...updatedSelectedElements.map((element) =>
          element.mheight ? element.mheight : element.height
        )
      );
      const bottomTop = minTop + boundingBoxDimensions.height;
      const updatedTop =
        boundingBoxDimensions.top + boundingBoxDimensions.height - maxHeight;

      handleTheBottomAlign(bottomTop);
    } else {
      const updatedSelectedElements = [];

      selectedElements.forEach((elementId) => {
        const updatingBaseElement = [...baseElement];

        const elementIndex = updatingBaseElement.findIndex(
          (elemen) => elemen.id === elementId
        );

        const element = updatingBaseElement[elementIndex];
        // element.position.y = minHeight;
        updatedSelectedElements.push(element);
      });
      const minTop = Math.min(
        ...updatedSelectedElements.map((element) => element.position.y)
      );
      const maxHeight = Math.max(
        ...updatedSelectedElements.map((element) => element.height)
      );
      const bottomTop = minTop + boundingBoxDimensions.height;
      const updatedTop =
        boundingBoxDimensions.top + boundingBoxDimensions.height - maxHeight;

      handleTheBottomAlign(bottomTop);
    }
  };

  const [isFontSizeInputDragging, setIsFontSizeInputDragging] = useState(false);
  const [isWidthInputDragging, setIsWidthInputDragging] = useState(false);
  const [isBorderInputDragging, setIsBorderInputDragging] = useState(false);
  const [isShadowHorizontalInputDragging, setIsShadowHorizontalInputDragging] =
    useState(false);
  const [isShadowVerticalInputDragging, setIsShadowVerticalInputDragging] =
    useState(false);
  const [isShadowBlurInputDragging, setIsShadowBlurInputDragging] =
    useState(false);
  const [isOpacityInputDragging, setIsOpacityInputDragging] = useState(false);
  const [isBorderRadiusInputDragging, setIsBorderRadiusInputDragging] =
    useState(false);
  const [isHeightInputDragging, setIsHeightInputDragging] = useState(false);
  const [isLineHeightInputDragging, setIsLineHeightInputDragging] =
    useState(false);
  const [isLetterSpacingInputDragging, setIsLetterSpacingInputDragging] =
    useState(false);
  const [initialDragY, setInitialDragY] = useState(0);
  const [initialValue, setInitialValue] = useState(0);

  const [sectionDragStart, setSectionDragStart] = useState(null);
  const [sectionDragPosition, setSectionDragPosition] = useState(null);
  const [sectionDragHeight, setSectionDragHeight] = useState(null);

  const handleSectionDragStart = (event, sectionId) => {
    if (event.clientY > 0) {
      const startDragY = event.clientY + scrollTop;

      if (mobileDesigning) {
        const updatingSection = [...baseSections];

        const draggingSectionIndex = updatingSection.findIndex(
          (section) => section.id === sectionId
        );

        setSectionDragStart(startDragY);

        setSectionDragHeight(
          updatingSection[draggingSectionIndex].mheight
            ? updatingSection[draggingSectionIndex].mheight
            : updatingSection[draggingSectionIndex].height
        );
      } else {
        const updatingSection = [...baseSections];

        const draggingSectionIndex = updatingSection.findIndex(
          (section) => section.id === sectionId
        );

        setSectionDragStart(startDragY);

        setSectionDragHeight(updatingSection[draggingSectionIndex].height);
      }
    }
  };

  const handleSectionDragging = (event, sectionId) => {
    if (event.clientY > 0) {
      setDraggedOverSectionId(null);

      if (mobileDesigning) {
        const draggedTo = event.clientY + scrollTop - sectionDragStart;
        const updatingSections = [...baseSections];
        const draggingSectionIndex = sections[pageDesigning].findIndex(
          (section) => section.id === sectionId
        );
        const draggigSectionsIndex = updatingSections.findIndex(
          (section) => section.id === sectionId
        );

        const updatedSections = [...sections[pageDesigning]];

        const newHeight = sectionDragHeight + draggedTo;

        if (sectionId !== "header") {
          if (newHeight >= 0) {
            const deltaY =
              newHeight -
              (sections[pageDesigning][draggingSectionIndex].mheight
                ? sections[pageDesigning][draggingSectionIndex].mheight
                : sections[pageDesigning][draggingSectionIndex].height);

            updatingSections[draggigSectionsIndex].mheight = newHeight;
            for (
              let i = draggingSectionIndex + 1;
              i < updatedSections.length;
              i++
            ) {
              const sectionId = updatedSections[i].id;
              const sectionIndex = updatingSections.findIndex(
                (section) => section.id === sectionId
              );
              updatingSections[sectionIndex].position.my =
                (sections[pageDesigning][i - 1].mheight
                  ? sections[pageDesigning][i - 1].mheight
                  : sections[pageDesigning][i - 1].height) +
                (sections[pageDesigning][i - 1].position.my
                  ? sections[pageDesigning][i - 1].position.my
                  : sections[pageDesigning][i - 1].position.y);
            }

            setBaseSections(updatingSections);
          }
        } else {
          if (newHeight >= 0) {
            updatingSections[draggigSectionsIndex].mheight = newHeight;
            console.log(newHeight);
            const updatedSections = { ...sections };

            for (const page in updatedSections) {
              if (Object.hasOwnProperty.call(updatedSections, page)) {
                const pageSections = updatedSections[page];
                const totalSections = pageSections.length - 1;

                for (let i = 0; i < pageSections.length; i++) {
                  if (
                    pageSections[i].id !== "header" &&
                    pageSections[i].id !== "footer"
                  ) {
                    const changingSectionInsertionIndex =
                      updatingSections.findIndex(
                        (sectionssss) => sectionssss.id === pageSections[i].id
                      );
                    updatingSections[
                      changingSectionInsertionIndex
                    ].position.my =
                      (sections[page][i - 1].mheight
                        ? sections[page][i - 1].mheight
                        : sections[page][i - 1].height) +
                      (sections[page][i - 1].position.my
                        ? sections[page][i - 1].position.my
                        : sections[page][i - 1].position.y);
                  }
                }
              }
            }

            setBaseSections(updatingSections);
          }
        }
      } else {
        const draggedTo = event.clientY + scrollTop - sectionDragStart;

        const updatingSections = [...baseSections];

        const draggingSectionIndex = sections[pageDesigning].findIndex(
          (section) => section.id === sectionId
        );

        const draggigSectionsIndex = updatingSections.findIndex(
          (section) => section.id === sectionId
        );

        const updatedSections = [...sections[pageDesigning]];

        const newHeight = sectionDragHeight + draggedTo;

        if (sectionId !== "header") {

          if (newHeight >= 0) {

            updatingSections[draggigSectionsIndex].height = newHeight;

            for (
              let i = draggingSectionIndex + 1;
              i < updatedSections.length;
              i++
            ) {
              const sectionId = updatedSections[i].id;

              const sectionIndex = updatingSections.findIndex(
                (section) => section.id === sectionId
              );

              updatingSections[sectionIndex].position.y =
                sections[pageDesigning][i - 1].height +
                sections[pageDesigning][i - 1].position.y;

            }

            setBaseSections(updatingSections);
          }

        } else {
          if (newHeight >= 0) {

            updatingSections[draggigSectionsIndex].height = newHeight;

            const updatedSections = { ...sections };

            for (const page in updatedSections) {
              if (Object.hasOwnProperty.call(updatedSections, page)) {
                const pageSections = updatedSections[page];

                for (let i = 0; i < pageSections.length; i++) {
                  if (
                    pageSections[i].id !== "header" &&
                    pageSections[i].id !== "footer"
                  ) {

                    const changingSectionInsertionIndex =
                      updatingSections.findIndex(
                        (sectionssss) => sectionssss.id === pageSections[i].id
                      );

                    updatingSections[changingSectionInsertionIndex].position.y =
                      sections[page][i - 1].height +
                      sections[page][i - 1].position.y;
                  }
                }
              }
            }

            setBaseSections(updatingSections);
          }
        }
      }
      setDraggedOverSectionId(null);
    }
  };

  const [sectionTouchDragging, setSectionTouchDragging] = useState(false);

  const handleTouchSectionDragStart = (e, sectionId) => {
    const touch = e.touches[0];
    if (touch.clientY > 0) {
      const startDragY = touch.clientY + scrollTop;

      if (mobileDesigning) {
        const updatingSection = [...baseSections];

        const draggingSectionIndex = updatingSection.findIndex(
          (section) => section.id === sectionId
        );

        setSectionDragStart(startDragY);

        setSectionDragHeight(
          updatingSection[draggingSectionIndex].mheight
            ? updatingSection[draggingSectionIndex].mheight
            : updatingSection[draggingSectionIndex].height
        );
      } else {
        const updatingSection = [...baseSections];

        const draggingSectionIndex = updatingSection.findIndex(
          (section) => section.id === sectionId
        );
        console.log(startDragY);
        console.log(updatingSection, draggingSectionIndex, sectionId);

        setSectionDragStart(startDragY);

        setSectionDragHeight(updatingSection[draggingSectionIndex].height);
      }
    }
  };

  const handleTouchSectionDragging = (e, sectionId) => {
    const touch = e.touches[0];
    if (touch.clientY > 0) {
      setDraggedOverSectionId(null);

      if (mobileDesigning) {
        const draggedTo = touch.clientY + scrollTop - sectionDragStart;
        const updatingSections = [...baseSections];
        const draggingSectionIndex = sections[pageDesigning].findIndex(
          (section) => section.id === sectionId
        );
        const draggigSectionsIndex = updatingSections.findIndex(
          (section) => section.id === sectionId
        );

        const updatedSections = [...sections[pageDesigning]];

        const newHeight = sectionDragHeight + draggedTo;

        if (sectionId !== "header") {
          if (newHeight >= 0) {
            const deltaY =
              newHeight -
              (sections[pageDesigning][draggingSectionIndex].mheight
                ? sections[pageDesigning][draggingSectionIndex].mheight
                : sections[pageDesigning][draggingSectionIndex].height);

            updatingSections[draggigSectionsIndex].mheight = newHeight;
            for (
              let i = draggingSectionIndex + 1;
              i < updatedSections.length;
              i++
            ) {
              const sectionId = updatedSections[i].id;
              const sectionIndex = updatingSections.findIndex(
                (section) => section.id === sectionId
              );
              updatingSections[sectionIndex].position.my =
                (sections[pageDesigning][i - 1].mheight
                  ? sections[pageDesigning][i - 1].mheight
                  : sections[pageDesigning][i - 1].height) +
                (sections[pageDesigning][i - 1].position.my
                  ? sections[pageDesigning][i - 1].position.my
                  : sections[pageDesigning][i - 1].position.y);
            }

            setBaseSections(updatingSections);
          }
        } else {
          if (newHeight >= 0) {
            updatingSections[draggigSectionsIndex].mheight = newHeight;
            console.log(newHeight);
            const updatedSections = { ...sections };

            for (const page in updatedSections) {
              if (Object.hasOwnProperty.call(updatedSections, page)) {
                const pageSections = updatedSections[page];
                const totalSections = pageSections.length - 1;

                for (let i = 0; i < pageSections.length; i++) {
                  if (
                    pageSections[i].id !== "header" &&
                    pageSections[i].id !== "footer"
                  ) {
                    const changingSectionInsertionIndex =
                      updatingSections.findIndex(
                        (sectionssss) => sectionssss.id === pageSections[i].id
                      );
                    updatingSections[
                      changingSectionInsertionIndex
                    ].position.my =
                      (sections[pageDesigning][i - 1].mheight
                        ? sections[pageDesigning][i - 1].mheight
                        : sections[pageDesigning][i - 1].height) +
                      (sections[pageDesigning][i - 1].position.my
                        ? sections[pageDesigning][i - 1].position.my
                        : sections[pageDesigning][i - 1].position.y);
                  }
                }
              }
            }

            setBaseSections(updatingSections);
          }
        }
      } else {
        const draggedTo = touch.clientY + scrollTop - sectionDragStart;
        const updatingSections = [...baseSections];
        const draggingSectionIndex = sections[pageDesigning].findIndex(
          (section) => section.id === sectionId
        );
        const draggigSectionsIndex = updatingSections.findIndex(
          (section) => section.id === sectionId
        );

        const updatedSections = [...sections[pageDesigning]];

        const newHeight = sectionDragHeight + draggedTo;

        if (sectionId !== "header") {
          if (newHeight >= 0) {
            const deltaY =
              newHeight - sections[pageDesigning][draggingSectionIndex].height;

            updatingSections[draggigSectionsIndex].height = newHeight;

            for (
              let i = draggingSectionIndex + 1;
              i < updatedSections.length;
              i++
            ) {
              const sectionId = updatedSections[i].id;
              const sectionIndex = updatingSections.findIndex(
                (section) => section.id === sectionId
              );

              updatingSections[sectionIndex].position.y =
                sections[pageDesigning][i - 1].height +
                sections[pageDesigning][i - 1].position.y;
            }

            setBaseSections(updatingSections);
          }
        } else {
          if (newHeight >= 0) {
            updatingSections[draggigSectionsIndex].height = newHeight;
            const updatedSections = { ...sections };

            for (const page in updatedSections) {
              if (Object.hasOwnProperty.call(updatedSections, page)) {
                const pageSections = updatedSections[page];
                const totalSections = pageSections.length - 1;

                for (let i = 0; i < pageSections.length; i++) {
                  if (
                    pageSections[i].id !== "header" &&
                    pageSections[i].id !== "footer"
                  ) {
                    const changingSectionInsertionIndex =
                      updatingSections.findIndex(
                        (sectionssss) => sectionssss.id === pageSections[i].id
                      );
                    updatingSections[changingSectionInsertionIndex].position.y =
                      sections[pageDesigning][i - 1].height +
                      sections[pageDesigning][i - 1].position.y;
                  }
                }
              }
            }

            setBaseSections(updatingSections);
          }
        }
      }
      setDraggedOverSectionId(null);
    }
  };

  const [colorDisplay, setColorDisplay] = useState(false);
  const [borderColorDisplay, setBorderColorDisplay] = useState(false);

  const [shadowColorDisplay, setShadowColorDisplay] = useState(false);

  const [backgroundColorDisplay, setBackgroundColorDisplay] = useState(false);

  const handleClickOutsideColorPicker = (event) => {
    const colorPickerContainer = document.getElementById(
      "color-picker-container"
    );
    const colorTrigger = document.getElementById("color-trigger");

    if (
      colorPickerContainer &&
      !colorPickerContainer.contains(event.target) &&
      !colorTrigger.contains(event.target)
    ) {
      setColorDisplay(false);
    }
  };

  useEffect(() => {
    if (colorDisplay) {
      window.addEventListener("click", handleClickOutsideColorPicker);
    } else {
      window.removeEventListener("click", handleClickOutsideColorPicker);
    }

    return () => {
      window.removeEventListener("click", handleClickOutsideColorPicker);
    };
  }, [colorDisplay]);

  const handleClickOutsideShadowColorPicker = (event) => {
    const colorPickerContainer = document.getElementById(
      "shadow-color-picker-container"
    );
    const colorTrigger = document.getElementById("shadow-color-trigger");

    if (
      colorPickerContainer &&
      !colorPickerContainer.contains(event.target) &&
      !colorTrigger.contains(event.target)
    ) {
      setShadowColorDisplay(false);
    }
  };

  useEffect(() => {
    if (shadowColorDisplay) {
      window.addEventListener("click", handleClickOutsideShadowColorPicker);
    } else {
      window.removeEventListener("click", handleClickOutsideShadowColorPicker);
    }

    return () => {
      window.removeEventListener("click", handleClickOutsideShadowColorPicker);
    };
  }, [shadowColorDisplay]);

  const handleClickOutsideBorderColorPicker = (event) => {
    const colorPickerContainer = document.getElementById(
      "border-color-picker-container"
    );
    const colorTrigger = document.getElementById("border-color-trigger");

    if (
      colorPickerContainer &&
      !colorPickerContainer.contains(event.target) &&
      !colorTrigger.contains(event.target)
    ) {
      setBorderColorDisplay(false);
    }
  };
  const handleClickOutsidePagePicker = (event) => {
    const pagePickerContainer = document.getElementById(
      "page-picker-container"
    );
    const pageTrigger = document.getElementById("page-trigger");

    if (
      pagePickerContainer &&
      !pagePickerContainer.contains(event.target) &&
      !pageTrigger.contains(event.target)
    ) {
      setPageDropdown(false);
    }
  };

  useEffect(() => {
    if (pageDropdown) {
      window.addEventListener("click", handleClickOutsidePagePicker);
    } else {
      window.removeEventListener("click", handleClickOutsidePagePicker);
    }

    return () => {
      window.removeEventListener("click", handleClickOutsidePagePicker);
    };
  }, [pageDropdown]);

  useEffect(() => {
    if (borderColorDisplay) {
      window.addEventListener("click", handleClickOutsideBorderColorPicker);
    } else {
      window.removeEventListener("click", handleClickOutsideBorderColorPicker);
    }

    return () => {
      window.removeEventListener("click", handleClickOutsideBorderColorPicker);
    };
  }, [borderColorDisplay]);

  const handleClickOutsideBackgroundColorPicker = (event) => {
    const colorPickerContainer = document.getElementById(
      "background-color-picker-container"
    );
    const colorTrigger = document.getElementById("background-color-trigger");

    if (
      colorPickerContainer &&
      !colorPickerContainer.contains(event.target) &&
      !colorTrigger.contains(event.target)
    ) {
      setBackgroundColorDisplay(false);
    }
  };

  useEffect(() => {
    if (backgroundColorDisplay) {
      window.addEventListener("click", handleClickOutsideBackgroundColorPicker);
    } else {
      window.removeEventListener(
        "click",
        handleClickOutsideBackgroundColorPicker
      );
    }

    return () => {
      window.removeEventListener(
        "click",
        handleClickOutsideBackgroundColorPicker
      );
    };
  }, [backgroundColorDisplay]);

  const [isMouseDown, setIsMouseDown] = useState(false);
  const [startMouseDown, setStartMouseDown] = useState(null);
  const [currentMouseDown, setCurrentMouseDown] = useState(null);
  const [selectedFromBoundingBox, setSelectedFromBoundingBox] = useState(false);

  useEffect(() => {
    const updatedSelectedElements = [];

    const handleMouseDown = (e) => {
      setIsMouseDown(true);
      console.log(activeTools)

      const resizeHandle = document.querySelector(".resize-handle");
      if (mobileDesigning) {
        // Calculate the top position of the resize handle
        const resizeHandleTop = resizeHandle.offsetTop;

        const top = boundingBoxDimensions ? boundingBoxDimensions.top : 0;
        const left = boundingBoxDimensions
          ? (boundingBoxDimensions.left * 400) / 100 + (viewportWidth - 400) / 2
          : 0;
        const width = boundingBoxDimensions
          ? (boundingBoxDimensions.width * 400) / 100 +
          (viewportWidth - 400) / 2
          : 0;
        const height = boundingBoxDimensions ? boundingBoxDimensions.height : 0;
        // Check if the click occurred within the bounding box
        if (
          e.clientX >= left &&
          e.clientX <= left + width &&
          e.clientY - 42 + scrollTop >= top &&
          e.clientY - 42 + scrollTop <= top + height
        ) {
          setStartMouseDown(null);
        } else if (activeTools !== 1) {
          setStartMouseDown(null);
        } else if (openLeftToolbar && e.clientX <= 480) {
          setStartMouseDown(null);
        } else if (openRightToolbar && e.clientX >= viewportWidth - 100) {
          setStartMouseDown(null);
        } else if (
          e.clientY + scrollTop - 40 >= resizeHandleTop &&
          e.clientY + scrollTop - 40 <=
          resizeHandleTop + resizeHandle.offsetHeight
        ) {
          setStartMouseDown(null);
        } else if (
          e.clientX >= activeWindowStat.x - 10 &&
          e.clientX <= activeWindowStat.x + 400 + 10 &&
          e.clientY - 42 >= activeWindowStat.y - 42 - 10 &&
          e.clientY - 42 <= activeWindowStat.y + 600 + 42 + 10 &&
          focusElementType !== "section"
        ) {
          setStartMouseDown(null);
        } else {
          // Click didn't occur within the bounding box

          // Check if the click occurred within the boundaries of any element
          let clickedOnElement = false;
          for (const section of mobileSections[pageDesigning]) {
            if (section.id === "footer") {
              for (const child of section.children) {
                const lastIndex = mobileSections[pageDesigning].length - 2;
                const sectionPosition =
                  (mobileSections[pageDesigning][lastIndex].position.my
                    ? mobileSections[pageDesigning][lastIndex].position.my
                    : mobileSections[pageDesigning][lastIndex].position.y) +
                  (mobileSections[pageDesigning][lastIndex].mheight
                    ? mobileSections[pageDesigning][lastIndex].mheight
                    : mobileSections[pageDesigning][lastIndex].height);
                const childTop =
                  sectionPosition +
                  (child.position.my ? child.position.my : child.position.y) -
                  10;
                const childHeight =
                  sectionPosition +
                  (child.position.my ? child.position.my : child.position.y) +
                  (child.mheight ? child.mheight : child.height) +
                  10;
                const childLeft =
                  ((child.position.mx ? child.position.mx : child.position.x) *
                    400) /
                  100 -
                  10 +
                  (viewportWidth - 400) / 2;
                const childWidth =
                  (((child.position.mx ? child.position.mx : child.position.x) +
                    (child.mwidth ? child.mwidth : child.width)) *
                    400) /
                  100 +
                  10 +
                  (viewportWidth - 400) / 2;

                if (
                  e.clientX >= childLeft &&
                  e.clientX <= childWidth &&
                  e.clientY - 42 + scrollTop >= childTop &&
                  e.clientY - 42 + scrollTop <= childHeight
                ) {
                  // Click occurred within an element, set startMouseDown to null
                  setStartMouseDown(null);
                  clickedOnElement = true;
                  break; // Exit the loop
                }
              }
            } else {
              for (const child of section.children) {
                const childTop =
                  (section.position.my
                    ? section.position.my
                    : section.position.y) +
                  (child.position.my ? child.position.my : child.position.y) -
                  10;
                const childHeight =
                  (section.position.my
                    ? section.position.my
                    : section.position.y) +
                  (child.position.my ? child.position.my : child.position.y) +
                  (child.mheight ? child.mheight : child.height) +
                  10;
                const childLeft =
                  ((child.position.mx ? child.position.mx : child.position.x) *
                    400) /
                  100 -
                  10 +
                  (viewportWidth - 400) / 2;
                const childWidth =
                  (((child.position.mx ? child.position.mx : child.position.x) +
                    (child.mwidth ? child.mwidth : child.width)) *
                    400) /
                  100 +
                  10 +
                  (viewportWidth - 400) / 2;

                if (
                  e.clientX >= childLeft &&
                  e.clientX <= childWidth &&
                  e.clientY - 42 + scrollTop >= childTop &&
                  e.clientY - 42 + scrollTop <= childHeight
                ) {
                  // Click occurred within an element, set startMouseDown to null
                  setStartMouseDown(null);
                  clickedOnElement = true;
                  break; // Exit the loop
                }
              }
            }
            if (clickedOnElement) {
              break; // Exit the outer loop if clicked on an element
            }
          }

          // If the click didn't occur within any element, apply the actions
          if (!clickedOnElement) {
            setStartMouseDown({ x: e.clientX, y: e.clientY });
            setSelectedElements([]);
            setBoundingBoxDimensions(null);
          }
        }
      } else {
        // Calculate the top position of the resize handle
        const resizeHandleTop = resizeHandle.offsetTop;

        const top = boundingBoxDimensions ? boundingBoxDimensions.top : 0;
        const left = boundingBoxDimensions
          ? (boundingBoxDimensions.left * viewportWidth) / 100
          : 0;
        const width = boundingBoxDimensions
          ? (boundingBoxDimensions.width * viewportWidth) / 100
          : 0;
        const height = boundingBoxDimensions ? boundingBoxDimensions.height : 0;
        // console.log(
        //   activeWindowStat,
        //   e.clientX >= activeWindowStat.x - 10,
        //   e.clientX <= activeWindowStat.x + 400 + 10,
        //   e.clientY - 42 + scrollTop,
        //   activeWindowStat.y - 42 - 10,
        //   e.clientY - 42 <= activeWindowStat.y + 600 - 42 + 10,
        //   focusElementType
        // );
        // Check if the click occurred within the bounding box
        if (
          e.clientX >= left &&
          e.clientX <= left + width &&
          e.clientY - 42 + scrollTop >= top &&
          e.clientY - 42 + scrollTop <= top + height
        ) {
          // Click occurred within the bounding box, set startMouseDown to null
          console.log("1");
          setStartMouseDown(null);
        } else if (openLeftToolbar && e.clientX <= 480) {
          console.log("2");
          setStartMouseDown(null);
        } else if (activeTools !== 1) {
          setStartMouseDown(null);
        } else if (openRightToolbar && e.clientX >= viewportWidth - 100) {
          console.log("3");
          setStartMouseDown(null);
        } else if (
          e.clientY + scrollTop - 40 >= resizeHandleTop &&
          e.clientY + scrollTop - 40 <=
          resizeHandleTop + resizeHandle.offsetHeight
        ) {
          console.log("4");
          setStartMouseDown(null);
        } else if (
          e.clientX >= activeWindowStat.x - 10 &&
          e.clientX <= activeWindowStat.x + 400 + 10 &&
          e.clientY - 42 >= activeWindowStat.y - 42 - 10 &&
          e.clientY - 42 <= activeWindowStat.y + 600 + 42 + 10 &&
          focusElementType !== "section"
        ) {
          console.log("5");
          setStartMouseDown(null);
        } else {
          // Click didn't occur within the bounding box

          // Check if the click occurred within the boundaries of any element
          let clickedOnElement = false;
          for (const section of sections[pageDesigning]) {
            if (section.id === "footer") {
              for (const child of section.children) {
                const lastIndex = sections[pageDesigning].length - 2;
                const sectionPosition =
                  sections[pageDesigning][lastIndex].position.y +
                  sections[pageDesigning][lastIndex].height;
                const childTop = sectionPosition + child.position.y - 10;
                const childHeight =
                  sectionPosition + child.position.y + child.height + 10;
                const childLeft = (child.position.x * viewportWidth) / 100 - 10;
                const childWidth =
                  ((child.position.x + child.width) * viewportWidth) / 100 + 10;

                if (
                  e.clientX >= childLeft &&
                  e.clientX <= childWidth &&
                  e.clientY - 42 + scrollTop >= childTop &&
                  e.clientY - 42 + scrollTop <= childHeight
                ) {
                  // Click occurred within an element, set startMouseDown to null
                  setStartMouseDown(null);
                  clickedOnElement = true;
                  break; // Exit the loop
                }
              }
            } else {
              for (const child of section.children) {
                const childTop = section.position.y + child.position.y - 10;
                const childHeight =
                  section.position.y + child.position.y + child.height + 10;
                const childLeft = (child.position.x * viewportWidth) / 100 - 10;
                const childWidth =
                  ((child.position.x + child.width) * viewportWidth) / 100 + 10;

                if (
                  e.clientX >= childLeft &&
                  e.clientX <= childWidth &&
                  e.clientY - 42 + scrollTop >= childTop &&
                  e.clientY - 42 + scrollTop <= childHeight
                ) {
                  // Click occurred within an element, set startMouseDown to null
                  setStartMouseDown(null);
                  clickedOnElement = true;
                  break; // Exit the loop
                }
              }
            }
            if (clickedOnElement) {
              break; // Exit the outer loop if clicked on an element
            }
          }
          // console.log("6");

          // If the click didn't occur within any element, apply the actions
          if (!clickedOnElement) {
            console.log("7");
            setStartMouseDown({ x: e.clientX, y: e.clientY });
            setSelectedElements([]);
            setBoundingBoxDimensions(null);
          }
        }
      }
    };

    const handleMouseUp = () => {
      if (isMouseDown) {
        setIsMouseDown(false);
        setStartMouseDown(null);
        setCurrentMouseDown(null);
        if (updatedSelectedElements.length > 0) {
          setSelectedFromBoundingBox(true);
        }
      }
    };

    const handleMouseMove = (e) => {
      if (mobileDesigning) {
        const updatedSelectedElements = [];
        if (isMouseDown) {
          const upper = startMouseDown
            ? e.clientY > startMouseDown.y
              ? startMouseDown.y - 42 + scrollTop
              : e.clientY - 42 + scrollTop
            : 0;
          const lower = startMouseDown
            ? e.clientY > startMouseDown.y
              ? e.clientY - 42 + scrollTop
              : startMouseDown.y - 42 + scrollTop
            : 0;
          const lefter = startMouseDown
            ? e.clientX > startMouseDown.x
              ? startMouseDown.x
              : e.clientX
            : 0;
          const righter = startMouseDown
            ? e.clientX > startMouseDown.x
              ? e.clientX
              : startMouseDown.x
            : 0;

          for (const section of mobileSections[pageDesigning]) {
            if (section.id === "footer") {
              for (const child of section.children) {
                const lastIndex = mobileSections[pageDesigning].length - 2;
                const sectionPosition =
                  (mobileSections[pageDesigning][lastIndex].position.my
                    ? mobileSections[pageDesigning][lastIndex].position.my
                    : mobileSections[pageDesigning][lastIndex].position.y) +
                  (mobileSections[pageDesigning][lastIndex].mheight
                    ? mobileSections[pageDesigning][lastIndex].mheight
                    : mobileSections[pageDesigning][lastIndex].height);
                const childTop =
                  sectionPosition +
                  (child.position.my ? child.position.my : child.position.y);
                const childHeight =
                  sectionPosition +
                  (child.position.my ? child.position.my : child.position.y) +
                  (child.mheight ? child.mheight : child.height);
                const childLeft =
                  ((child.position.mx ? child.position.mx : child.position.x) *
                    400) /
                  100 +
                  (viewportWidth - 400) / 2;
                const childWidth =
                  (((child.position.mx ? child.position.mx : child.position.x) +
                    (child.mwidth ? child.mwidth : child.width)) *
                    400) /
                  100 +
                  (viewportWidth - 400) / 2;
                if (childTop > upper || childHeight > upper) {
                  if (childTop < lower || childHeight < lower) {
                    if (childLeft > lefter || childWidth > lefter) {
                      if (childLeft < righter || childWidth < righter) {
                        // console.log(child.id);

                        const elementInDraggingBox =
                          childTop > upper ||
                          (childHeight > upper && childTop < lower) ||
                          (childHeight < lower && childLeft > lefter) ||
                          (childWidth > lefter && childLeft < righter) ||
                          childWidth < righter;

                        if (elementInDraggingBox) {
                          if (!updatedSelectedElements.includes(child.id)) {
                            updatedSelectedElements.push(child.id);
                          }
                        } else {
                          // Deselect the element if it's not inside the dragging box
                          const elementIndex = updatedSelectedElements.indexOf(
                            child.id
                          );
                          if (elementIndex !== -1) {
                            updatedSelectedElements.splice(elementIndex, 1);
                          }
                        }

                        if (updatedSelectedElements.length > 0) {
                          const boundingBoxRect = calculateBoundingBoxRect(
                            updatedSelectedElements
                          );
                          setBoundingBoxDimensions({
                            width: boundingBoxRect.width,
                            height: boundingBoxRect.height,
                            top: boundingBoxRect.top,
                            left: boundingBoxRect.left,
                          });
                        } else {
                          setBoundingBoxDimensions(null);
                        }
                        setSelectedElements(updatedSelectedElements);
                      }
                    }
                  }
                }
              }
            } else {
              for (const child of section.children) {
                const childTop =
                  (section.position.my
                    ? section.position.my
                    : section.position.y) +
                  (child.position.my ? child.position.my : child.position.y);
                const childHeight =
                  (section.position.my
                    ? section.position.my
                    : section.position.y) +
                  (child.position.my ? child.position.my : child.position.y) +
                  (child.mheight ? child.mheight : child.height);
                const childLeft =
                  ((child.position.mx ? child.position.mx : child.position.x) *
                    400) /
                  100 +
                  (viewportWidth - 400) / 2;
                const childWidth =
                  (((child.position.mx ? child.position.mx : child.position.x) +
                    (child.mwidth ? child.mwidth : child.width)) *
                    400) /
                  100 +
                  (viewportWidth - 400) / 2;
                if (childTop > upper || childHeight > upper) {
                  if (childTop < lower || childHeight < lower) {
                    if (childLeft > lefter || childWidth > lefter) {
                      if (childLeft < righter || childWidth < righter) {
                        // console.log(child.id);

                        const elementInDraggingBox =
                          childTop > upper ||
                          (childHeight > upper && childTop < lower) ||
                          (childHeight < lower && childLeft > lefter) ||
                          (childWidth > lefter && childLeft < righter) ||
                          childWidth < righter;

                        if (elementInDraggingBox) {
                          if (!updatedSelectedElements.includes(child.id)) {
                            updatedSelectedElements.push(child.id);
                          }
                        } else {
                          // Deselect the element if it's not inside the dragging box
                          const elementIndex = updatedSelectedElements.indexOf(
                            child.id
                          );
                          if (elementIndex !== -1) {
                            updatedSelectedElements.splice(elementIndex, 1);
                          }
                        }

                        if (updatedSelectedElements.length > 0) {
                          const boundingBoxRect = calculateBoundingBoxRect(
                            updatedSelectedElements
                          );
                          setBoundingBoxDimensions({
                            width: boundingBoxRect.width,
                            height: boundingBoxRect.height,
                            top: boundingBoxRect.top,
                            left: boundingBoxRect.left,
                          });
                        } else {
                          setBoundingBoxDimensions(null);
                        }
                        setSelectedElements(updatedSelectedElements);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        const updatedSelectedElements = [];
        if (isMouseDown) {
          // console.log(isMouseDown, startMouseDown);
          const upper = startMouseDown
            ? e.clientY > startMouseDown.y
              ? startMouseDown.y - 42 + scrollTop
              : e.clientY - 42 + scrollTop
            : 0;
          const lower = startMouseDown
            ? e.clientY > startMouseDown.y
              ? e.clientY - 42 + scrollTop
              : startMouseDown.y - 42 + scrollTop
            : 0;
          const lefter = startMouseDown
            ? e.clientX > startMouseDown.x
              ? startMouseDown.x
              : e.clientX
            : 0;
          const righter = startMouseDown
            ? e.clientX > startMouseDown.x
              ? e.clientX
              : startMouseDown.x
            : 0;

          for (const section of sections[pageDesigning]) {
            if (section.id === "footer") {
              for (const child of section.children) {
                const lastIndex = sections[pageDesigning].length - 2;
                const sectionPosition =
                  sections[pageDesigning][lastIndex].position.y +
                  sections[pageDesigning][lastIndex].height;
                const childTop = sectionPosition + child.position.y;
                const childHeight =
                  sectionPosition + child.position.y + child.height;
                const childLeft = (child.position.x * viewportWidth) / 100;
                const childWidth =
                  ((child.position.x + child.width) * viewportWidth) / 100;
                if (childTop > upper || childHeight > upper) {
                  if (childTop < lower || childHeight < lower) {
                    if (childLeft > lefter || childWidth > lefter) {
                      if (childLeft < righter || childWidth < righter) {
                        // console.log(child.id);

                        const elementInDraggingBox =
                          childTop > upper ||
                          (childHeight > upper && childTop < lower) ||
                          (childHeight < lower && childLeft > lefter) ||
                          (childWidth > lefter && childLeft < righter) ||
                          childWidth < righter;

                        if (elementInDraggingBox) {
                          if (!updatedSelectedElements.includes(child.id)) {
                            updatedSelectedElements.push(child.id);
                          }
                        } else {
                          // Deselect the element if it's not inside the dragging box
                          const elementIndex = updatedSelectedElements.indexOf(
                            child.id
                          );
                          if (elementIndex !== -1) {
                            updatedSelectedElements.splice(elementIndex, 1);
                          }
                        }

                        if (updatedSelectedElements.length > 0) {
                          const boundingBoxRect = calculateBoundingBoxRect(
                            updatedSelectedElements
                          );
                          setBoundingBoxDimensions({
                            width: boundingBoxRect.width,
                            height: boundingBoxRect.height,
                            top: boundingBoxRect.top,
                            left: boundingBoxRect.left,
                          });
                        } else {
                          setBoundingBoxDimensions(null);
                        }
                        setSelectedElements(updatedSelectedElements);
                      }
                    }
                  }
                }
              }
            } else {
              for (const child of section.children) {
                const childTop = section.position.y + child.position.y;
                const childHeight =
                  section.position.y + child.position.y + child.height;
                const childLeft = (child.position.x * viewportWidth) / 100;
                const childWidth =
                  ((child.position.x + child.width) * viewportWidth) / 100;
                if (childTop > upper || childHeight > upper) {
                  if (childTop < lower || childHeight < lower) {
                    if (childLeft > lefter || childWidth > lefter) {
                      if (childLeft < righter || childWidth < righter) {
                        // console.log(child.id);

                        const elementInDraggingBox =
                          childTop > upper ||
                          (childHeight > upper && childTop < lower) ||
                          (childHeight < lower && childLeft > lefter) ||
                          (childWidth > lefter && childLeft < righter) ||
                          childWidth < righter;

                        if (elementInDraggingBox) {
                          if (!updatedSelectedElements.includes(child.id)) {
                            updatedSelectedElements.push(child.id);
                            console.log(child.id);
                          }
                        } else {
                          // Deselect the element if it's not inside the dragging box
                          const elementIndex = updatedSelectedElements.indexOf(
                            child.id
                          );
                          if (elementIndex !== -1) {
                            updatedSelectedElements.splice(elementIndex, 1);
                          }
                        }

                        if (updatedSelectedElements.length > 0) {
                          const boundingBoxRect = calculateBoundingBoxRect(
                            updatedSelectedElements
                          );
                          console.log(updatedSelectedElements);
                          setBoundingBoxDimensions({
                            width: boundingBoxRect.width,
                            height: boundingBoxRect.height,
                            top: boundingBoxRect.top,
                            left: boundingBoxRect.left,
                          });
                        } else {
                          setBoundingBoxDimensions(null);
                        }
                        setSelectedElements(updatedSelectedElements);
                      }
                    }
                  }
                }
              }
            }
          }
        } else {
        }
      }
      setCurrentMouseDown({ x: e.clientX, y: e.clientY });
    };

    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("mousemove", handleMouseMove);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [
    isMouseDown,
    openLeftToolbar,
    openRightToolbar,
    activeWindowStat,
    scrollTop,
    startMouseDown,
    currentMouseDown,
    focusElementType,
    mobileDesigning,
    sections,
    mobileSections,
    baseElement,
    baseSections,
    viewportWidth,
    activeTools,
  ]);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (openLeftToolbar) {
        if (e.clientX > 480) {
          setOpenLeftToolbar(false);
        }
      } else {
        if (e.clientX < 30) {
          setOpenLeftToolbar(true);
        }
      }
    };

    // Add the mousemove event listener
    document.addEventListener("mousemove", handleMouseMove);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, [openLeftToolbar]);

  const [eventSearchTerm, setEventSearchTerm] = useState("");

  const [eventSelectedFilters, setEventSelectedFilters] = useState([]);

  const handleEventSearchTermChange = (text) => {
    setEventSearchTerm(text);
  };

  const [productSearchTerm, setProductSearchTerm] = useState("");

  const [productSelectedFilters, setProductSelectedFilters] = useState([]);

  const handleProductSearchTermChange = (text) => {
    setProductSearchTerm(text);
  };

  const [newsSearchTerm, setNewsSearchTerm] = useState("");

  const [newsSelectedFilters, setNewsSelectedFilters] = useState([]);

  const handleNewsSearchTermChange = (text) => {
    setNewsSearchTerm(text);
  };

  const [searchDrawerInput, setSearchDrawerInput] = useState("");

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const menuElement = baseElement
    ? baseElement.find((element) => element.type === "menu-element")
    : null;

  const dynamicDisplayItems = (section, element, index) => {
    if (element.type === "dynamic-checkout-element") {
      return (
        <DynamicCheckoutElement
          customerToken={customerToken}
          currency={currency}
          setCustomerToken={(stateUpdate) => setCustomerToken(stateUpdate)}
          setPageDesigning={(stateUpdate) => setPageDesigning(stateUpdate)}
          customerAccountDetails={customerAccountDetails}
          setCustomerAccountDetails={(stateUpdate) =>
            setCustomerAccountDetails(stateUpdate)
          }
          mobileDesigning={mobileDesigning}
          dynamicItem={dynamicItem}
          pageDesigning={pageDesigning}
          activeWindowStat={activeWindowStat}
          setActiveWindowStat={(something) => setActiveWindowStat(something)}
          selectedElements={selectedElements}
          focusElement={focusElement}
          style={{ display: "none" }}
          key={element.id}
          setNotInputFocus={(status) => setNotInputFocus(status)}
          setEditingInputWithArrow={(status) =>
            setEditingInputWithArrow(status)
          }
          onFocus={(elementId, event) => {
            if (elementId === null) {
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setSelectedSection();
              setSelectedSectionIndex();

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              handleShiftClick(event, element.id);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
              setFocusBorderRadius(focusedElement.borderRadius);
              // setNotInputFocus(true);
            }
          }}
          onStyleChange={(style_name, style_value) => {
            const updatingElement = [...baseElement];
            const elementIndex = updatingElement.findIndex(
              (child) => child.id === focusElement
            );

            updatingElement[elementIndex][style_name] = style_value;
            setBaseElement(updatingElement);
          }}
          onInputEditing={() => {
            setNotInputFocus(false);
            console.log("this is happening");
          }}
          onInputBlur={() => {
            setNotInputFocus(true);
            console.log("this is happening");
          }}
          className={`canvas-element ${element.type}`}
          element={element}
          onContentChange={(content) => onContentChange(content, element.id)}
          onDrag={(event, elementId, offset, touch) => {
            const sectionIndex = sections[pageDesigning].findIndex((section) =>
              section.children.some((child) => child.id === elementId)
            );
            const section = sections[pageDesigning][sectionIndex];
            const elementIndex = section.children.findIndex(
              (child) => child.id === elementId
            );
            const focusedElement = section.children[elementIndex];

            setFocusElement(elementId);
            setFocusElementType(element.type);
            setFontWeight(focusedElement.fontWeight);
            setLineHeight(focusedElement.lineHeight);
            setLetterSpacing(focusedElement.letterSpacing);
            setTextAlign(focusedElement.textAlign);
            setFontSize(focusedElement.fontSize);
            setFocusWidth(focusedElement.width);
            setFocusHeight(focusedElement.height);
            setFocusBackgroundColor(focusedElement.backgroundColor);
            setFocusColor(focusedElement.color);
            setFocusBorder(focusedElement.border);
            setFocusBoxShadow(focusedElement.shadow);
            setFocusOpacity(focusedElement.opacity);
            setFocusBorderRadius(focusedElement.borderRadius);
            handleDrag(
              event,
              elementId,
              offset,
              section.id,
              draggingSectionPositionY,
              touch
            );
            setNotInputFocus(true);
          }}
          onDragStart={(event, elementId, touch) => {
            handleDragStart(event, element.id, section.id, touch);
          }}
          onDragEnd={handleDragEnd}
          onResizeStart={() => handleResizeStart(element.id)}
          onResize={(newWidth, newHeight) =>
            handleResize(newWidth, newHeight, element, section)
          }
          onResizeEnd={handleResizeEnd}
          ref={element.ref} // Assign the ref to the element
          onRefSet={(elementRef) => {
            if (mobileDesigning) {
              const sectionIndex = mobileSections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...mobileSections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...sections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            }
          }}
        />
      );
    }
    if (element.type === "dynamic-title-element") {
      return (
        <DynamicTitleElement
          mobileDesigning={mobileDesigning}
          dynamicItem={dynamicItem}
          pageDesigning={pageDesigning}
          activeWindowStat={activeWindowStat}
          setActiveWindowStat={(something) => setActiveWindowStat(something)}
          selectedElements={selectedElements}
          focusElement={focusElement}
          style={{ display: "none" }}
          key={element.id}
          setNotInputFocus={(status) => setNotInputFocus(status)}
          setEditingInputWithArrow={(status) =>
            setEditingInputWithArrow(status)
          }
          onFocus={(elementId, event) => {
            if (elementId === null) {
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setSelectedSection();
              setSelectedSectionIndex();

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              handleShiftClick(event, element.id);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
              setFocusBorderRadius(focusedElement.borderRadius);
              // setNotInputFocus(true);
            }
          }}
          onStyleChange={(style_name, style_value) => {
            const updatingElement = [...baseElement];
            const elementIndex = updatingElement.findIndex(
              (child) => child.id === focusElement
            );

            updatingElement[elementIndex][style_name] = style_value;
            setBaseElement(updatingElement);
          }}
          onInputEditing={() => {
            setNotInputFocus(false);
            console.log("this is happening");
          }}
          onInputBlur={() => {
            setNotInputFocus(true);
            console.log("this is happening");
          }}
          className={`canvas-element ${element.type}`}
          element={element}
          onContentChange={(content) => onContentChange(content, element.id)}
          onDrag={(event, elementId, offset, touch) => {
            const sectionIndex = sections[pageDesigning].findIndex((section) =>
              section.children.some((child) => child.id === elementId)
            );
            const section = sections[pageDesigning][sectionIndex];
            const elementIndex = section.children.findIndex(
              (child) => child.id === elementId
            );
            const focusedElement = section.children[elementIndex];

            setFocusElement(elementId);
            setFocusElementType(element.type);
            setFontWeight(focusedElement.fontWeight);
            setLineHeight(focusedElement.lineHeight);
            setLetterSpacing(focusedElement.letterSpacing);
            setTextAlign(focusedElement.textAlign);
            setFontSize(focusedElement.fontSize);
            setFocusWidth(focusedElement.width);
            setFocusHeight(focusedElement.height);
            setFocusBackgroundColor(focusedElement.backgroundColor);
            setFocusColor(focusedElement.color);
            setFocusBorder(focusedElement.border);
            setFocusBoxShadow(focusedElement.shadow);
            setFocusOpacity(focusedElement.opacity);
            setFocusBorderRadius(focusedElement.borderRadius);
            handleDrag(
              event,
              elementId,
              offset,
              section.id,
              draggingSectionPositionY,
              touch
            );
            setNotInputFocus(true);
          }}
          onDragStart={(event, elementId, touch) => {
            handleDragStart(event, element.id, section.id, touch);
          }}
          onDragEnd={handleDragEnd}
          onResizeStart={() => handleResizeStart(element.id)}
          onResize={(newWidth, newHeight) =>
            handleResize(newWidth, newHeight, element, section)
          }
          onResizeEnd={handleResizeEnd}
          ref={element.ref} // Assign the ref to the element
          onRefSet={(elementRef) => {
            if (mobileDesigning) {
              const sectionIndex = mobileSections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...mobileSections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...sections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            }
          }}
        />
      );
    }
    if (element.type === "login-element") {
      return (
        <LoginElement
          customerToken={customerToken}
          setCustomerToken={(stateUpdate) => setCustomerToken(stateUpdate)}
          customerAccountDetails={customerAccountDetails}
          setCustomerAccountDetails={(stateUpdate) =>
            setCustomerAccountDetails(stateUpdate)
          }
          mobileDesigning={mobileDesigning}
          dynamicItem={dynamicItem}
          pageDesigning={pageDesigning}
          activeWindowStat={activeWindowStat}
          setActiveWindowStat={(something) => setActiveWindowStat(something)}
          selectedElements={selectedElements}
          focusElement={focusElement}
          style={{ display: "none" }}
          key={element.id}
          setNotInputFocus={(status) => setNotInputFocus(status)}
          setEditingInputWithArrow={(status) =>
            setEditingInputWithArrow(status)
          }
          onFocus={(elementId, event) => {
            if (elementId === null) {
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setSelectedSection();
              setSelectedSectionIndex();

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              handleShiftClick(event, element.id);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
              setFocusBorderRadius(focusedElement.borderRadius);
              // setNotInputFocus(true);
            }
          }}
          onStyleChange={(style_name, style_value) => {
            const updatingElement = [...baseElement];
            const elementIndex = updatingElement.findIndex(
              (child) => child.id === focusElement
            );

            updatingElement[elementIndex][style_name] = style_value;
            setBaseElement(updatingElement);
          }}
          onInputEditing={() => {
            setNotInputFocus(false);
            console.log("this is happening");
          }}
          onInputBlur={() => {
            setNotInputFocus(true);
            console.log("this is happening");
          }}
          className={`canvas-element ${element.type}`}
          element={element}
          onContentChange={(content) => onContentChange(content, element.id)}
          onDrag={(event, elementId, offset, touch) => {
            const sectionIndex = sections[pageDesigning].findIndex((section) =>
              section.children.some((child) => child.id === elementId)
            );
            const section = sections[pageDesigning][sectionIndex];
            const elementIndex = section.children.findIndex(
              (child) => child.id === elementId
            );
            const focusedElement = section.children[elementIndex];

            setFocusElement(elementId);
            setFocusElementType(element.type);
            setFontWeight(focusedElement.fontWeight);
            setLineHeight(focusedElement.lineHeight);
            setLetterSpacing(focusedElement.letterSpacing);
            setTextAlign(focusedElement.textAlign);
            setFontSize(focusedElement.fontSize);
            setFocusWidth(focusedElement.width);
            setFocusHeight(focusedElement.height);
            setFocusBackgroundColor(focusedElement.backgroundColor);
            setFocusColor(focusedElement.color);
            setFocusBorder(focusedElement.border);
            setFocusBoxShadow(focusedElement.shadow);
            setFocusOpacity(focusedElement.opacity);
            setFocusBorderRadius(focusedElement.borderRadius);
            handleDrag(
              event,
              elementId,
              offset,
              section.id,
              draggingSectionPositionY,
              touch
            );
            setNotInputFocus(true);
          }}
          onDragStart={(event, elementId, touch) => {
            handleDragStart(event, element.id, section.id, touch);
          }}
          onDragEnd={handleDragEnd}
          onResizeStart={() => handleResizeStart(element.id)}
          onResize={(newWidth, newHeight) =>
            handleResize(newWidth, newHeight, element, section)
          }
          onResizeEnd={handleResizeEnd}
          ref={element.ref} // Assign the ref to the element
          onRefSet={(elementRef) => {
            if (mobileDesigning) {
              const sectionIndex = mobileSections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...mobileSections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...sections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            }
          }}
        />
      );
    }
    if (element.type === "switch-tab-element") {
      return (
        <SwitchTabElement
          mobileDesigning={mobileDesigning}
          dynamicItem={dynamicItem}
          pageDesigning={pageDesigning}
          activeWindowStat={activeWindowStat}
          setActiveWindowStat={(something) => setActiveWindowStat(something)}
          selectedElements={selectedElements}
          focusElement={focusElement}
          style={{ display: "none" }}
          key={element.id}
          setNotInputFocus={(status) => setNotInputFocus(status)}
          setEditingInputWithArrow={(status) =>
            setEditingInputWithArrow(status)
          }
          onFocus={(elementId, event) => {
            if (elementId === null) {
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setSelectedSection();
              setSelectedSectionIndex();

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              handleShiftClick(event, element.id);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
              setFocusBorderRadius(focusedElement.borderRadius);
              // setNotInputFocus(true);
            }
          }}
          onStyleChange={(style_name, style_value) => {
            const updatingElement = [...baseElement];
            const elementIndex = updatingElement.findIndex(
              (child) => child.id === focusElement
            );

            updatingElement[elementIndex][style_name] = style_value;
            setBaseElement(updatingElement);
          }}
          onInputEditing={() => {
            setNotInputFocus(false);
            console.log("this is happening");
          }}
          onInputBlur={() => {
            setNotInputFocus(true);
            console.log("this is happening");
          }}
          className={`canvas-element ${element.type}`}
          element={element}
          onContentChange={(content) => onContentChange(content, element.id)}
          onDrag={(event, elementId, offset, touch) => {
            const sectionIndex = sections[pageDesigning].findIndex((section) =>
              section.children.some((child) => child.id === elementId)
            );
            const section = sections[pageDesigning][sectionIndex];
            const elementIndex = section.children.findIndex(
              (child) => child.id === elementId
            );
            const focusedElement = section.children[elementIndex];

            setFocusElement(elementId);
            setFocusElementType(element.type);
            setFontWeight(focusedElement.fontWeight);
            setLineHeight(focusedElement.lineHeight);
            setLetterSpacing(focusedElement.letterSpacing);
            setTextAlign(focusedElement.textAlign);
            setFontSize(focusedElement.fontSize);
            setFocusWidth(focusedElement.width);
            setFocusHeight(focusedElement.height);
            setFocusBackgroundColor(focusedElement.backgroundColor);
            setFocusColor(focusedElement.color);
            setFocusBorder(focusedElement.border);
            setFocusBoxShadow(focusedElement.shadow);
            setFocusOpacity(focusedElement.opacity);
            setFocusBorderRadius(focusedElement.borderRadius);
            handleDrag(
              event,
              elementId,
              offset,
              section.id,
              draggingSectionPositionY,
              touch
            );
            setNotInputFocus(true);
          }}
          onDragStart={(event, elementId, touch) => {
            handleDragStart(event, element.id, section.id, touch);
          }}
          onDragEnd={handleDragEnd}
          onResizeStart={() => handleResizeStart(element.id)}
          onResize={(newWidth, newHeight) =>
            handleResize(newWidth, newHeight, element, section)
          }
          onResizeEnd={handleResizeEnd}
          ref={element.ref} // Assign the ref to the element
          onRefSet={(elementRef) => {
            if (mobileDesigning) {
              const sectionIndex = mobileSections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...mobileSections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...sections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            }
          }}
        />
      );
    }
    if (element.type === "accordian-element") {
      return (
        <AccordianElement
          mobileDesigning={mobileDesigning}
          dynamicItem={dynamicItem}
          pageDesigning={pageDesigning}
          activeWindowStat={activeWindowStat}
          setActiveWindowStat={(something) => setActiveWindowStat(something)}
          selectedElements={selectedElements}
          focusElement={focusElement}
          style={{ display: "none" }}
          key={element.id}
          setNotInputFocus={(status) => setNotInputFocus(status)}
          setEditingInputWithArrow={(status) =>
            setEditingInputWithArrow(status)
          }
          onFocus={(elementId, event) => {
            if (elementId === null) {
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setSelectedSection();
              setSelectedSectionIndex();

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              handleShiftClick(event, element.id);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
              setFocusBorderRadius(focusedElement.borderRadius);
              // setNotInputFocus(true);
            }
          }}
          onStyleChange={(style_name, style_value) => {
            const updatingElement = [...baseElement];
            const elementIndex = updatingElement.findIndex(
              (child) => child.id === focusElement
            );

            updatingElement[elementIndex][style_name] = style_value;
            setBaseElement(updatingElement);
          }}
          onInputEditing={() => {
            setNotInputFocus(false);
            console.log("this is happening");
          }}
          onInputBlur={() => {
            setNotInputFocus(true);
            console.log("this is happening");
          }}
          className={`canvas-element ${element.type}`}
          element={element}
          onContentChange={(content) => onContentChange(content, element.id)}
          onDrag={(event, elementId, offset, touch) => {
            const sectionIndex = sections[pageDesigning].findIndex((section) =>
              section.children.some((child) => child.id === elementId)
            );
            const section = sections[pageDesigning][sectionIndex];
            const elementIndex = section.children.findIndex(
              (child) => child.id === elementId
            );
            const focusedElement = section.children[elementIndex];

            setFocusElement(elementId);
            setFocusElementType(element.type);
            setFontWeight(focusedElement.fontWeight);
            setLineHeight(focusedElement.lineHeight);
            setLetterSpacing(focusedElement.letterSpacing);
            setTextAlign(focusedElement.textAlign);
            setFontSize(focusedElement.fontSize);
            setFocusWidth(focusedElement.width);
            setFocusHeight(focusedElement.height);
            setFocusBackgroundColor(focusedElement.backgroundColor);
            setFocusColor(focusedElement.color);
            setFocusBorder(focusedElement.border);
            setFocusBoxShadow(focusedElement.shadow);
            setFocusOpacity(focusedElement.opacity);
            setFocusBorderRadius(focusedElement.borderRadius);
            handleDrag(
              event,
              elementId,
              offset,
              section.id,
              draggingSectionPositionY,
              touch
            );
            setNotInputFocus(true);
          }}
          onDragStart={(event, elementId, touch) => {
            handleDragStart(event, element.id, section.id, touch);
          }}
          onDragEnd={handleDragEnd}
          onResizeStart={() => handleResizeStart(element.id)}
          onResize={(newWidth, newHeight) =>
            handleResize(newWidth, newHeight, element, section)
          }
          onResizeEnd={handleResizeEnd}
          ref={element.ref} // Assign the ref to the element
          onRefSet={(elementRef) => {
            if (mobileDesigning) {
              const sectionIndex = mobileSections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...mobileSections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...sections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            }
          }}
        />
      );
    }
    if (element.type === "icon-element") {
      return (
        <IconElement
          mobileDesigning={mobileDesigning}
          dynamicItem={dynamicItem}
          pageDesigning={pageDesigning}
          activeWindowStat={activeWindowStat}
          setActiveWindowStat={(something) => setActiveWindowStat(something)}
          selectedElements={selectedElements}
          focusElement={focusElement}
          style={{ display: "none" }}
          key={element.id}
          setNotInputFocus={(status) => setNotInputFocus(status)}
          setEditingInputWithArrow={(status) =>
            setEditingInputWithArrow(status)
          }
          onFocus={(elementId, event) => {
            if (elementId === null) {
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setSelectedSection();
              setSelectedSectionIndex();

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              handleShiftClick(event, element.id);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
              setFocusBorderRadius(focusedElement.borderRadius);
              // setNotInputFocus(true);
            }
          }}
          onStyleChange={(style_name, style_value) => {
            const updatingElement = [...baseElement];
            const elementIndex = updatingElement.findIndex(
              (child) => child.id === focusElement
            );

            updatingElement[elementIndex][style_name] = style_value;
            setBaseElement(updatingElement);
          }}
          onInputEditing={() => {
            setNotInputFocus(false);
            console.log("this is happening");
          }}
          onInputBlur={() => {
            setNotInputFocus(true);
            console.log("this is happening");
          }}
          className={`canvas-element ${element.type}`}
          element={element}
          onContentChange={(content) => onContentChange(content, element.id)}
          onDrag={(event, elementId, offset, touch) => {
            const sectionIndex = sections[pageDesigning].findIndex((section) =>
              section.children.some((child) => child.id === elementId)
            );
            const section = sections[pageDesigning][sectionIndex];
            const elementIndex = section.children.findIndex(
              (child) => child.id === elementId
            );
            const focusedElement = section.children[elementIndex];

            setFocusElement(elementId);
            setFocusElementType(element.type);
            setFontWeight(focusedElement.fontWeight);
            setLineHeight(focusedElement.lineHeight);
            setLetterSpacing(focusedElement.letterSpacing);
            setTextAlign(focusedElement.textAlign);
            setFontSize(focusedElement.fontSize);
            setFocusWidth(focusedElement.width);
            setFocusHeight(focusedElement.height);
            setFocusBackgroundColor(focusedElement.backgroundColor);
            setFocusColor(focusedElement.color);
            setFocusBorder(focusedElement.border);
            setFocusBoxShadow(focusedElement.shadow);
            setFocusOpacity(focusedElement.opacity);
            setFocusBorderRadius(focusedElement.borderRadius);
            handleDrag(
              event,
              elementId,
              offset,
              section.id,
              draggingSectionPositionY,
              touch
            );
            setNotInputFocus(true);
          }}
          onDragStart={(event, elementId, touch) => {
            handleDragStart(event, element.id, section.id, touch);
          }}
          onDragEnd={handleDragEnd}
          onResizeStart={() => handleResizeStart(element.id)}
          onResize={(newWidth, newHeight) =>
            handleResize(newWidth, newHeight, element, section)
          }
          onResizeEnd={handleResizeEnd}
          ref={element.ref} // Assign the ref to the element
          onRefSet={(elementRef) => {
            if (mobileDesigning) {
              const sectionIndex = mobileSections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...mobileSections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...sections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            }
          }}
        />
      );
    }
    if (element.type === "vertical-divider-element") {
      return (
        <VerticalDividerElement
          mobileDesigning={mobileDesigning}
          dynamicItem={dynamicItem}
          pageDesigning={pageDesigning}
          activeWindowStat={activeWindowStat}
          setActiveWindowStat={(something) => setActiveWindowStat(something)}
          selectedElements={selectedElements}
          focusElement={focusElement}
          style={{ display: "none" }}
          key={element.id}
          setNotInputFocus={(status) => setNotInputFocus(status)}
          setEditingInputWithArrow={(status) =>
            setEditingInputWithArrow(status)
          }
          onFocus={(elementId, event) => {
            if (elementId === null) {
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setSelectedSection();
              setSelectedSectionIndex();

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              handleShiftClick(event, element.id);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
              setFocusBorderRadius(focusedElement.borderRadius);
              // setNotInputFocus(true);
            }
          }}
          onStyleChange={(style_name, style_value) => {
            const updatingElement = [...baseElement];
            const elementIndex = updatingElement.findIndex(
              (child) => child.id === focusElement
            );

            updatingElement[elementIndex][style_name] = style_value;
            setBaseElement(updatingElement);
          }}
          onInputEditing={() => {
            setNotInputFocus(false);
            console.log("this is happening");
          }}
          onInputBlur={() => {
            setNotInputFocus(true);
            console.log("this is happening");
          }}
          className={`canvas-element ${element.type}`}
          element={element}
          onContentChange={(content) => onContentChange(content, element.id)}
          onDrag={(event, elementId, offset, touch) => {
            const sectionIndex = sections[pageDesigning].findIndex((section) =>
              section.children.some((child) => child.id === elementId)
            );
            const section = sections[pageDesigning][sectionIndex];
            const elementIndex = section.children.findIndex(
              (child) => child.id === elementId
            );
            const focusedElement = section.children[elementIndex];

            setFocusElement(elementId);
            setFocusElementType(element.type);
            setFontWeight(focusedElement.fontWeight);
            setLineHeight(focusedElement.lineHeight);
            setLetterSpacing(focusedElement.letterSpacing);
            setTextAlign(focusedElement.textAlign);
            setFontSize(focusedElement.fontSize);
            setFocusWidth(focusedElement.width);
            setFocusHeight(focusedElement.height);
            setFocusBackgroundColor(focusedElement.backgroundColor);
            setFocusColor(focusedElement.color);
            setFocusBorder(focusedElement.border);
            setFocusBoxShadow(focusedElement.shadow);
            setFocusOpacity(focusedElement.opacity);
            setFocusBorderRadius(focusedElement.borderRadius);
            handleDrag(
              event,
              elementId,
              offset,
              section.id,
              draggingSectionPositionY,
              touch
            );
            setNotInputFocus(true);
          }}
          onDragStart={(event, elementId, touch) => {
            handleDragStart(event, element.id, section.id, touch);
          }}
          onDragEnd={handleDragEnd}
          onResizeStart={() => handleResizeStart(element.id)}
          onResize={(newWidth, newHeight) =>
            handleResize(newWidth, newHeight, element, section)
          }
          onResizeEnd={handleResizeEnd}
          ref={element.ref} // Assign the ref to the element
          onRefSet={(elementRef) => {
            if (mobileDesigning) {
              const sectionIndex = mobileSections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...mobileSections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...sections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            }
          }}
        />
      );
    }
    if (element.type === "horizontal-divider-element") {
      return (
        <HorizontalDividerElement
          mobileDesigning={mobileDesigning}
          dynamicItem={dynamicItem}
          pageDesigning={pageDesigning}
          activeWindowStat={activeWindowStat}
          setActiveWindowStat={(something) => setActiveWindowStat(something)}
          selectedElements={selectedElements}
          focusElement={focusElement}
          style={{ display: "none" }}
          key={element.id}
          setNotInputFocus={(status) => setNotInputFocus(status)}
          setEditingInputWithArrow={(status) =>
            setEditingInputWithArrow(status)
          }
          onFocus={(elementId, event) => {
            if (elementId === null) {
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setSelectedSection();
              setSelectedSectionIndex();

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              handleShiftClick(event, element.id);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
              setFocusBorderRadius(focusedElement.borderRadius);
              // setNotInputFocus(true);
            }
          }}
          onStyleChange={(style_name, style_value) => {
            const updatingElement = [...baseElement];
            const elementIndex = updatingElement.findIndex(
              (child) => child.id === focusElement
            );

            updatingElement[elementIndex][style_name] = style_value;
            setBaseElement(updatingElement);
          }}
          onInputEditing={() => {
            setNotInputFocus(false);
            console.log("this is happening");
          }}
          onInputBlur={() => {
            setNotInputFocus(true);
            console.log("this is happening");
          }}
          className={`canvas-element ${element.type}`}
          element={element}
          onContentChange={(content) => onContentChange(content, element.id)}
          onDrag={(event, elementId, offset, touch) => {
            const sectionIndex = sections[pageDesigning].findIndex((section) =>
              section.children.some((child) => child.id === elementId)
            );
            const section = sections[pageDesigning][sectionIndex];
            const elementIndex = section.children.findIndex(
              (child) => child.id === elementId
            );
            const focusedElement = section.children[elementIndex];

            setFocusElement(elementId);
            setFocusElementType(element.type);
            setFontWeight(focusedElement.fontWeight);
            setLineHeight(focusedElement.lineHeight);
            setLetterSpacing(focusedElement.letterSpacing);
            setTextAlign(focusedElement.textAlign);
            setFontSize(focusedElement.fontSize);
            setFocusWidth(focusedElement.width);
            setFocusHeight(focusedElement.height);
            setFocusBackgroundColor(focusedElement.backgroundColor);
            setFocusColor(focusedElement.color);
            setFocusBorder(focusedElement.border);
            setFocusBoxShadow(focusedElement.shadow);
            setFocusOpacity(focusedElement.opacity);
            setFocusBorderRadius(focusedElement.borderRadius);
            handleDrag(
              event,
              elementId,
              offset,
              section.id,
              draggingSectionPositionY,
              touch
            );
            setNotInputFocus(true);
          }}
          onDragStart={(event, elementId, touch) => {
            handleDragStart(event, element.id, section.id, touch);
          }}
          onDragEnd={handleDragEnd}
          onResizeStart={() => handleResizeStart(element.id)}
          onResize={(newWidth, newHeight) =>
            handleResize(newWidth, newHeight, element, section)
          }
          onResizeEnd={handleResizeEnd}
          ref={element.ref} // Assign the ref to the element
          onRefSet={(elementRef) => {
            if (mobileDesigning) {
              const sectionIndex = mobileSections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...mobileSections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...sections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            }
          }}
        />
      );
    }
    if (element.type === "social-element") {
      return (
        <SocialElement
          mobileDesigning={mobileDesigning}
          dynamicItem={dynamicItem}
          pageDesigning={pageDesigning}
          activeWindowStat={activeWindowStat}
          setActiveWindowStat={(something) => setActiveWindowStat(something)}
          selectedElements={selectedElements}
          focusElement={focusElement}
          style={{ display: "none" }}
          key={element.id}
          setNotInputFocus={(status) => setNotInputFocus(status)}
          setEditingInputWithArrow={(status) =>
            setEditingInputWithArrow(status)
          }
          onFocus={(elementId, event) => {
            if (elementId === null) {
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setSelectedSection();
              setSelectedSectionIndex();

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              handleShiftClick(event, element.id);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
              setFocusBorderRadius(focusedElement.borderRadius);
              // setNotInputFocus(true);
            }
          }}
          onStyleChange={(style_name, style_value) => {
            const updatingElement = [...baseElement];
            const elementIndex = updatingElement.findIndex(
              (child) => child.id === focusElement
            );

            updatingElement[elementIndex][style_name] = style_value;
            setBaseElement(updatingElement);
          }}
          onInputEditing={() => {
            setNotInputFocus(false);
            console.log("this is happening");
          }}
          onInputBlur={() => {
            setNotInputFocus(true);
            console.log("this is happening");
          }}
          className={`canvas-element ${element.type}`}
          element={element}
          onContentChange={(content) => onContentChange(content, element.id)}
          onDrag={(event, elementId, offset, touch) => {
            const sectionIndex = sections[pageDesigning].findIndex((section) =>
              section.children.some((child) => child.id === elementId)
            );
            const section = sections[pageDesigning][sectionIndex];
            const elementIndex = section.children.findIndex(
              (child) => child.id === elementId
            );
            const focusedElement = section.children[elementIndex];

            setFocusElement(elementId);
            setFocusElementType(element.type);
            setFontWeight(focusedElement.fontWeight);
            setLineHeight(focusedElement.lineHeight);
            setLetterSpacing(focusedElement.letterSpacing);
            setTextAlign(focusedElement.textAlign);
            setFontSize(focusedElement.fontSize);
            setFocusWidth(focusedElement.width);
            setFocusHeight(focusedElement.height);
            setFocusBackgroundColor(focusedElement.backgroundColor);
            setFocusColor(focusedElement.color);
            setFocusBorder(focusedElement.border);
            setFocusBoxShadow(focusedElement.shadow);
            setFocusOpacity(focusedElement.opacity);
            setFocusBorderRadius(focusedElement.borderRadius);
            handleDrag(
              event,
              elementId,
              offset,
              section.id,
              draggingSectionPositionY,
              touch
            );
            setNotInputFocus(true);
          }}
          onDragStart={(event, elementId, touch) => {
            handleDragStart(event, element.id, section.id, touch);
          }}
          onDragEnd={handleDragEnd}
          onResizeStart={() => handleResizeStart(element.id)}
          onResize={(newWidth, newHeight) =>
            handleResize(newWidth, newHeight, element, section)
          }
          onResizeEnd={handleResizeEnd}
          ref={element.ref} // Assign the ref to the element
          onRefSet={(elementRef) => {
            if (mobileDesigning) {
              const sectionIndex = mobileSections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...mobileSections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...sections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            }
          }}
        />
      );
    }
    if (element.type === "map-element") {
      return (
        <MapElement
          mobileDesigning={mobileDesigning}
          dynamicItem={dynamicItem}
          pageDesigning={pageDesigning}
          activeWindowStat={activeWindowStat}
          setActiveWindowStat={(something) => setActiveWindowStat(something)}
          selectedElements={selectedElements}
          focusElement={focusElement}
          style={{ display: "none" }}
          key={element.id}
          setNotInputFocus={(status) => setNotInputFocus(status)}
          setEditingInputWithArrow={(status) =>
            setEditingInputWithArrow(status)
          }
          onFocus={(elementId, event) => {
            if (elementId === null) {
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setSelectedSection();
              setSelectedSectionIndex();

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              handleShiftClick(event, element.id);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
              setFocusBorderRadius(focusedElement.borderRadius);
              // setNotInputFocus(true);
            }
          }}
          onStyleChange={(style_name, style_value) => {
            const updatingElement = [...baseElement];
            const elementIndex = updatingElement.findIndex(
              (child) => child.id === focusElement
            );

            updatingElement[elementIndex][style_name] = style_value;
            setBaseElement(updatingElement);
          }}
          onInputEditing={() => {
            setNotInputFocus(false);
            console.log("this is happening");
          }}
          onInputBlur={() => {
            setNotInputFocus(true);
            console.log("this is happening");
          }}
          className={`canvas-element ${element.type}`}
          element={element}
          onContentChange={(content) => onContentChange(content, element.id)}
          onDrag={(event, elementId, offset, touch) => {
            const sectionIndex = sections[pageDesigning].findIndex((section) =>
              section.children.some((child) => child.id === elementId)
            );
            const section = sections[pageDesigning][sectionIndex];
            const elementIndex = section.children.findIndex(
              (child) => child.id === elementId
            );
            const focusedElement = section.children[elementIndex];

            setFocusElement(elementId);
            setFocusElementType(element.type);
            setFontWeight(focusedElement.fontWeight);
            setLineHeight(focusedElement.lineHeight);
            setLetterSpacing(focusedElement.letterSpacing);
            setTextAlign(focusedElement.textAlign);
            setFontSize(focusedElement.fontSize);
            setFocusWidth(focusedElement.width);
            setFocusHeight(focusedElement.height);
            setFocusBackgroundColor(focusedElement.backgroundColor);
            setFocusColor(focusedElement.color);
            setFocusBorder(focusedElement.border);
            setFocusBoxShadow(focusedElement.shadow);
            setFocusOpacity(focusedElement.opacity);
            setFocusBorderRadius(focusedElement.borderRadius);
            handleDrag(
              event,
              elementId,
              offset,
              section.id,
              draggingSectionPositionY,
              touch
            );
            setNotInputFocus(true);
          }}
          onDragStart={(event, elementId, touch) => {
            handleDragStart(event, element.id, section.id, touch);
          }}
          onDragEnd={handleDragEnd}
          onResizeStart={() => handleResizeStart(element.id)}
          onResize={(newWidth, newHeight) =>
            handleResize(newWidth, newHeight, element, section)
          }
          onResizeEnd={handleResizeEnd}
          ref={element.ref} // Assign the ref to the element
          onRefSet={(elementRef) => {
            if (mobileDesigning) {
              const sectionIndex = mobileSections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...mobileSections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...sections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            }
          }}
        />
      );
    }
    if (element.type === "video-element") {
      return (
        <VideoElement
          mobileDesigning={mobileDesigning}
          dynamicItem={dynamicItem}
          pageDesigning={pageDesigning}
          activeWindowStat={activeWindowStat}
          setActiveWindowStat={(something) => setActiveWindowStat(something)}
          selectedElements={selectedElements}
          focusElement={focusElement}
          style={{ display: "none" }}
          key={element.id}
          setNotInputFocus={(status) => setNotInputFocus(status)}
          setEditingInputWithArrow={(status) =>
            setEditingInputWithArrow(status)
          }
          onFocus={(elementId, event) => {
            if (elementId === null) {
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setSelectedSection();
              setSelectedSectionIndex();

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              handleShiftClick(event, element.id);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
              setFocusBorderRadius(focusedElement.borderRadius);
              // setNotInputFocus(true);
            }
          }}
          onStyleChange={(style_name, style_value) => {
            const updatingElement = [...baseElement];
            const elementIndex = updatingElement.findIndex(
              (child) => child.id === focusElement
            );

            updatingElement[elementIndex][style_name] = style_value;
            setBaseElement(updatingElement);
          }}
          onInputEditing={() => {
            setNotInputFocus(false);
            console.log("this is happening");
          }}
          onInputBlur={() => {
            setNotInputFocus(true);
            console.log("this is happening");
          }}
          className={`canvas-element ${element.type}`}
          element={element}
          onContentChange={(content) => onContentChange(content, element.id)}
          onDrag={(event, elementId, offset, touch) => {
            const sectionIndex = sections[pageDesigning].findIndex((section) =>
              section.children.some((child) => child.id === elementId)
            );
            const section = sections[pageDesigning][sectionIndex];
            const elementIndex = section.children.findIndex(
              (child) => child.id === elementId
            );
            const focusedElement = section.children[elementIndex];

            setFocusElement(elementId);
            setFocusElementType(element.type);
            setFontWeight(focusedElement.fontWeight);
            setLineHeight(focusedElement.lineHeight);
            setLetterSpacing(focusedElement.letterSpacing);
            setTextAlign(focusedElement.textAlign);
            setFontSize(focusedElement.fontSize);
            setFocusWidth(focusedElement.width);
            setFocusHeight(focusedElement.height);
            setFocusBackgroundColor(focusedElement.backgroundColor);
            setFocusColor(focusedElement.color);
            setFocusBorder(focusedElement.border);
            setFocusBoxShadow(focusedElement.shadow);
            setFocusOpacity(focusedElement.opacity);
            setFocusBorderRadius(focusedElement.borderRadius);
            handleDrag(
              event,
              elementId,
              offset,
              section.id,
              draggingSectionPositionY,
              touch
            );
            setNotInputFocus(true);
          }}
          onDragStart={(event, elementId, touch) => {
            handleDragStart(event, element.id, section.id, touch);
          }}
          onDragEnd={handleDragEnd}
          onResizeStart={() => handleResizeStart(element.id)}
          onResize={(newWidth, newHeight) =>
            handleResize(newWidth, newHeight, element, section)
          }
          onResizeEnd={handleResizeEnd}
          ref={element.ref} // Assign the ref to the element
          onRefSet={(elementRef) => {
            if (mobileDesigning) {
              const sectionIndex = mobileSections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...mobileSections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...sections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            }
          }}
        />
      );
    }
    if (element.type === "code-element") {
      return (
        <CodeElement
          mobileDesigning={mobileDesigning}
          dynamicItem={dynamicItem}
          pageDesigning={pageDesigning}
          activeWindowStat={activeWindowStat}
          setActiveWindowStat={(something) => setActiveWindowStat(something)}
          selectedElements={selectedElements}
          focusElement={focusElement}
          style={{ display: "none" }}
          key={element.id}
          setNotInputFocus={(status) => setNotInputFocus(status)}
          setEditingInputWithArrow={(status) =>
            setEditingInputWithArrow(status)
          }
          onFocus={(elementId, event) => {
            if (elementId === null) {
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setSelectedSection();
              setSelectedSectionIndex();

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              handleShiftClick(event, element.id);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
              setFocusBorderRadius(focusedElement.borderRadius);
              // setNotInputFocus(true);
            }
          }}
          onStyleChange={(style_name, style_value) => {
            const updatingElement = [...baseElement];
            const elementIndex = updatingElement.findIndex(
              (child) => child.id === focusElement
            );

            updatingElement[elementIndex][style_name] = style_value;
            setBaseElement(updatingElement);
          }}
          onInputEditing={() => {
            setNotInputFocus(false);
            console.log("this is happening");
          }}
          onInputBlur={() => {
            setNotInputFocus(true);
            console.log("this is happening");
          }}
          className={`canvas-element ${element.type}`}
          element={element}
          onContentChange={(content) => onContentChange(content, element.id)}
          onDrag={(event, elementId, offset, touch) => {
            const sectionIndex = sections[pageDesigning].findIndex((section) =>
              section.children.some((child) => child.id === elementId)
            );
            const section = sections[pageDesigning][sectionIndex];
            const elementIndex = section.children.findIndex(
              (child) => child.id === elementId
            );
            const focusedElement = section.children[elementIndex];

            setFocusElement(elementId);
            setFocusElementType(element.type);
            setFontWeight(focusedElement.fontWeight);
            setLineHeight(focusedElement.lineHeight);
            setLetterSpacing(focusedElement.letterSpacing);
            setTextAlign(focusedElement.textAlign);
            setFontSize(focusedElement.fontSize);
            setFocusWidth(focusedElement.width);
            setFocusHeight(focusedElement.height);
            setFocusBackgroundColor(focusedElement.backgroundColor);
            setFocusColor(focusedElement.color);
            setFocusBorder(focusedElement.border);
            setFocusBoxShadow(focusedElement.shadow);
            setFocusOpacity(focusedElement.opacity);
            setFocusBorderRadius(focusedElement.borderRadius);
            handleDrag(
              event,
              elementId,
              offset,
              section.id,
              draggingSectionPositionY,
              touch
            );
            setNotInputFocus(true);
          }}
          onDragStart={(event, elementId, touch) => {
            handleDragStart(event, element.id, section.id, touch);
          }}
          onDragEnd={handleDragEnd}
          onResizeStart={() => handleResizeStart(element.id)}
          onResize={(newWidth, newHeight) =>
            handleResize(newWidth, newHeight, element, section)
          }
          onResizeEnd={handleResizeEnd}
          ref={element.ref} // Assign the ref to the element
          onRefSet={(elementRef) => {
            if (mobileDesigning) {
              const sectionIndex = mobileSections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...mobileSections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...sections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            }
          }}
        />
      );
    }
    if (element.type === "dynamic-description-element") {
      return (
        <DynamicDescriptionElement
          mobileDesigning={mobileDesigning}
          dynamicItem={dynamicItem}
          pageDesigning={pageDesigning}
          activeWindowStat={activeWindowStat}
          setActiveWindowStat={(something) => setActiveWindowStat(something)}
          selectedElements={selectedElements}
          focusElement={focusElement}
          style={{ display: "none" }}
          key={element.id}
          setNotInputFocus={(status) => setNotInputFocus(status)}
          setEditingInputWithArrow={(status) =>
            setEditingInputWithArrow(status)
          }
          onFocus={(elementId, event) => {
            if (elementId === null) {
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setSelectedSection();
              setSelectedSectionIndex();

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              handleShiftClick(event, element.id);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
              setFocusBorderRadius(focusedElement.borderRadius);
              // setNotInputFocus(true);
            }
          }}
          onStyleChange={(style_name, style_value) => {
            const updatingElement = [...baseElement];
            const elementIndex = updatingElement.findIndex(
              (child) => child.id === focusElement
            );

            updatingElement[elementIndex][style_name] = style_value;
            setBaseElement(updatingElement);
          }}
          onInputEditing={() => {
            setNotInputFocus(false);
            console.log("this is happening");
          }}
          onInputBlur={() => {
            setNotInputFocus(true);
            console.log("this is happening");
          }}
          className={`canvas-element ${element.type}`}
          element={element}
          onContentChange={(content) => onContentChange(content, element.id)}
          onDrag={(event, elementId, offset, touch) => {
            const sectionIndex = sections[pageDesigning].findIndex((section) =>
              section.children.some((child) => child.id === elementId)
            );
            const section = sections[pageDesigning][sectionIndex];
            const elementIndex = section.children.findIndex(
              (child) => child.id === elementId
            );
            const focusedElement = section.children[elementIndex];

            setFocusElement(elementId);
            setFocusElementType(element.type);
            setFontWeight(focusedElement.fontWeight);
            setLineHeight(focusedElement.lineHeight);
            setLetterSpacing(focusedElement.letterSpacing);
            setTextAlign(focusedElement.textAlign);
            setFontSize(focusedElement.fontSize);
            setFocusWidth(focusedElement.width);
            setFocusHeight(focusedElement.height);
            setFocusBackgroundColor(focusedElement.backgroundColor);
            setFocusColor(focusedElement.color);
            setFocusBorder(focusedElement.border);
            setFocusBoxShadow(focusedElement.shadow);
            setFocusOpacity(focusedElement.opacity);
            setFocusBorderRadius(focusedElement.borderRadius);
            handleDrag(
              event,
              elementId,
              offset,
              section.id,
              draggingSectionPositionY,
              touch
            );
            setNotInputFocus(true);
          }}
          onDragStart={(event, elementId, touch) => {
            handleDragStart(event, element.id, section.id, touch);
          }}
          onDragEnd={handleDragEnd}
          onResizeStart={() => handleResizeStart(element.id)}
          onResize={(newWidth, newHeight) =>
            handleResize(newWidth, newHeight, element, section)
          }
          onResizeEnd={handleResizeEnd}
          ref={element.ref} // Assign the ref to the element
          onRefSet={(elementRef) => {
            if (mobileDesigning) {
              const sectionIndex = mobileSections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...mobileSections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...sections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            }
          }}
        />
      );
    }
    if (element.type === "dynamic-image-element") {
      return (
        <DynamicImageElement
          mobileDesigning={mobileDesigning}
          dynamicItem={dynamicItem}
          pageDesigning={pageDesigning}
          activeWindowStat={activeWindowStat}
          setActiveWindowStat={(something) => setActiveWindowStat(something)}
          selectedElements={selectedElements}
          focusElement={focusElement}
          style={{ display: "none" }}
          key={element.id}
          setNotInputFocus={(status) => setNotInputFocus(status)}
          setEditingInputWithArrow={(status) =>
            setEditingInputWithArrow(status)
          }
          onFocus={(elementId, event) => {
            if (elementId === null) {
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setSelectedSection();
              setSelectedSectionIndex();

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              handleShiftClick(event, element.id);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
              setFocusBorderRadius(focusedElement.borderRadius);
              // setNotInputFocus(true);
            }
          }}
          onStyleChange={(style_name, style_value) => {
            const updatingElement = [...baseElement];
            const elementIndex = updatingElement.findIndex(
              (child) => child.id === focusElement
            );

            updatingElement[elementIndex][style_name] = style_value;
            setBaseElement(updatingElement);
          }}
          onInputEditing={() => {
            setNotInputFocus(false);
            console.log("this is happening");
          }}
          onInputBlur={() => {
            setNotInputFocus(true);
            console.log("this is happening");
          }}
          className={`canvas-element ${element.type}`}
          element={element}
          onContentChange={(content) => onContentChange(content, element.id)}
          onDrag={(event, elementId, offset, touch) => {
            const sectionIndex = sections[pageDesigning].findIndex((section) =>
              section.children.some((child) => child.id === elementId)
            );
            const section = sections[pageDesigning][sectionIndex];
            const elementIndex = section.children.findIndex(
              (child) => child.id === elementId
            );
            const focusedElement = section.children[elementIndex];

            setFocusElement(elementId);
            setFocusElementType(element.type);
            setFontWeight(focusedElement.fontWeight);
            setLineHeight(focusedElement.lineHeight);
            setLetterSpacing(focusedElement.letterSpacing);
            setTextAlign(focusedElement.textAlign);
            setFontSize(focusedElement.fontSize);
            setFocusWidth(focusedElement.width);
            setFocusHeight(focusedElement.height);
            setFocusBackgroundColor(focusedElement.backgroundColor);
            setFocusColor(focusedElement.color);
            setFocusBorder(focusedElement.border);
            setFocusBoxShadow(focusedElement.shadow);
            setFocusOpacity(focusedElement.opacity);
            setFocusBorderRadius(focusedElement.borderRadius);
            handleDrag(
              event,
              elementId,
              offset,
              section.id,
              draggingSectionPositionY,
              touch
            );
            setNotInputFocus(true);
          }}
          onDragStart={(event, elementId, touch) => {
            handleDragStart(event, element.id, section.id, touch);
          }}
          onDragEnd={handleDragEnd}
          onResizeStart={() => handleResizeStart(element.id)}
          onResize={(newWidth, newHeight) =>
            handleResize(newWidth, newHeight, element, section)
          }
          onResizeEnd={handleResizeEnd}
          ref={element.ref} // Assign the ref to the element
          onRefSet={(elementRef) => {
            if (mobileDesigning) {
              const sectionIndex = mobileSections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...mobileSections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...sections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            }
          }}
        />
      );
    }
    if (element.type === "dynamic-details-element") {
      return (
        <DynamicDetailsElement
          mobileDesigning={mobileDesigning}
          dynamicItem={dynamicItem}
          pageDesigning={pageDesigning}
          activeWindowStat={activeWindowStat}
          setActiveWindowStat={(something) => setActiveWindowStat(something)}
          selectedElements={selectedElements}
          focusElement={focusElement}
          style={{ display: "none" }}
          key={element.id}
          setNotInputFocus={(status) => setNotInputFocus(status)}
          setEditingInputWithArrow={(status) =>
            setEditingInputWithArrow(status)
          }
          onFocus={(elementId, event) => {
            if (elementId === null) {
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setSelectedSection();
              setSelectedSectionIndex();

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              handleShiftClick(event, element.id);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
              setFocusBorderRadius(focusedElement.borderRadius);
              // setNotInputFocus(true);
            }
          }}
          onStyleChange={(style_name, style_value) => {
            const updatingElement = [...baseElement];
            const elementIndex = updatingElement.findIndex(
              (child) => child.id === focusElement
            );

            updatingElement[elementIndex][style_name] = style_value;
            setBaseElement(updatingElement);
          }}
          onInputEditing={() => {
            setNotInputFocus(false);
            console.log("this is happening");
          }}
          onInputBlur={() => {
            setNotInputFocus(true);
            console.log("this is happening");
          }}
          className={`canvas-element ${element.type}`}
          element={element}
          onContentChange={(content) => onContentChange(content, element.id)}
          onDrag={(event, elementId, offset, touch) => {
            const sectionIndex = sections[pageDesigning].findIndex((section) =>
              section.children.some((child) => child.id === elementId)
            );
            const section = sections[pageDesigning][sectionIndex];
            const elementIndex = section.children.findIndex(
              (child) => child.id === elementId
            );
            const focusedElement = section.children[elementIndex];

            setFocusElement(elementId);
            setFocusElementType(element.type);
            setFontWeight(focusedElement.fontWeight);
            setLineHeight(focusedElement.lineHeight);
            setLetterSpacing(focusedElement.letterSpacing);
            setTextAlign(focusedElement.textAlign);
            setFontSize(focusedElement.fontSize);
            setFocusWidth(focusedElement.width);
            setFocusHeight(focusedElement.height);
            setFocusBackgroundColor(focusedElement.backgroundColor);
            setFocusColor(focusedElement.color);
            setFocusBorder(focusedElement.border);
            setFocusBoxShadow(focusedElement.shadow);
            setFocusOpacity(focusedElement.opacity);
            setFocusBorderRadius(focusedElement.borderRadius);
            handleDrag(
              event,
              elementId,
              offset,
              section.id,
              draggingSectionPositionY,
              touch
            );
            setNotInputFocus(true);
          }}
          onDragStart={(event, elementId, touch) => {
            handleDragStart(event, element.id, section.id, touch);
          }}
          onDragEnd={handleDragEnd}
          onResizeStart={() => handleResizeStart(element.id)}
          onResize={(newWidth, newHeight) =>
            handleResize(newWidth, newHeight, element, section)
          }
          onResizeEnd={handleResizeEnd}
          ref={element.ref} // Assign the ref to the element
          onRefSet={(elementRef) => {
            if (mobileDesigning) {
              const sectionIndex = mobileSections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...mobileSections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...sections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            }
          }}
        />
      );
    }
    if (element.type === "dynamic-horizontal-details-element") {
      return (
        <DynamicHorizontalDetailsElement
          mobileDesigning={mobileDesigning}
          dynamicItem={dynamicItem}
          pageDesigning={pageDesigning}
          activeWindowStat={activeWindowStat}
          setActiveWindowStat={(something) => setActiveWindowStat(something)}
          selectedElements={selectedElements}
          focusElement={focusElement}
          style={{ display: "none" }}
          key={element.id}
          setNotInputFocus={(status) => setNotInputFocus(status)}
          setEditingInputWithArrow={(status) =>
            setEditingInputWithArrow(status)
          }
          onFocus={(elementId, event) => {
            if (elementId === null) {
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setSelectedSection();
              setSelectedSectionIndex();

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              handleShiftClick(event, element.id);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
              setFocusBorderRadius(focusedElement.borderRadius);
              // setNotInputFocus(true);
            }
          }}
          onStyleChange={(style_name, style_value) => {
            const updatingElement = [...baseElement];
            const elementIndex = updatingElement.findIndex(
              (child) => child.id === focusElement
            );

            updatingElement[elementIndex][style_name] = style_value;
            setBaseElement(updatingElement);
          }}
          onInputEditing={() => {
            setNotInputFocus(false);
            console.log("this is happening");
          }}
          onInputBlur={() => {
            setNotInputFocus(true);
            console.log("this is happening");
          }}
          className={`canvas-element ${element.type}`}
          element={element}
          onContentChange={(content) => onContentChange(content, element.id)}
          onDrag={(event, elementId, offset, touch) => {
            const sectionIndex = sections[pageDesigning].findIndex((section) =>
              section.children.some((child) => child.id === elementId)
            );
            const section = sections[pageDesigning][sectionIndex];
            const elementIndex = section.children.findIndex(
              (child) => child.id === elementId
            );
            const focusedElement = section.children[elementIndex];

            setFocusElement(elementId);
            setFocusElementType(element.type);
            setFontWeight(focusedElement.fontWeight);
            setLineHeight(focusedElement.lineHeight);
            setLetterSpacing(focusedElement.letterSpacing);
            setTextAlign(focusedElement.textAlign);
            setFontSize(focusedElement.fontSize);
            setFocusWidth(focusedElement.width);
            setFocusHeight(focusedElement.height);
            setFocusBackgroundColor(focusedElement.backgroundColor);
            setFocusColor(focusedElement.color);
            setFocusBorder(focusedElement.border);
            setFocusBoxShadow(focusedElement.shadow);
            setFocusOpacity(focusedElement.opacity);
            setFocusBorderRadius(focusedElement.borderRadius);
            handleDrag(
              event,
              elementId,
              offset,
              section.id,
              draggingSectionPositionY,
              touch
            );
            setNotInputFocus(true);
          }}
          onDragStart={(event, elementId, touch) => {
            handleDragStart(event, element.id, section.id, touch);
          }}
          onDragEnd={handleDragEnd}
          onResizeStart={() => handleResizeStart(element.id)}
          onResize={(newWidth, newHeight) =>
            handleResize(newWidth, newHeight, element, section)
          }
          onResizeEnd={handleResizeEnd}
          ref={element.ref} // Assign the ref to the element
          onRefSet={(elementRef) => {
            if (mobileDesigning) {
              const sectionIndex = mobileSections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...mobileSections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...sections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            }
          }}
        />
      );
    }
    if (element.type === "dynamic-price-element") {
      return (
        <DynamicPriceElement
          customerToken={customerToken}
          currency={currency}
          setCustomerToken={(stateUpdate) => setCustomerToken(stateUpdate)}
          customerAccountDetails={customerAccountDetails}
          setCustomerAccountDetails={(stateUpdate) =>
            setCustomerAccountDetails(stateUpdate)
          }
          mobileDesigning={mobileDesigning}
          dynamicItem={dynamicItem}
          pageDesigning={pageDesigning}
          activeWindowStat={activeWindowStat}
          setActiveWindowStat={(something) => setActiveWindowStat(something)}
          selectedElements={selectedElements}
          focusElement={focusElement}
          style={{ display: "none" }}
          key={element.id}
          setNotInputFocus={(status) => setNotInputFocus(status)}
          setEditingInputWithArrow={(status) =>
            setEditingInputWithArrow(status)
          }
          onFocus={(elementId, event) => {
            if (elementId === null) {
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setSelectedSection();
              setSelectedSectionIndex();

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              handleShiftClick(event, element.id);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
              setFocusBorderRadius(focusedElement.borderRadius);
              // setNotInputFocus(true);
            }
          }}
          onStyleChange={(style_name, style_value) => {
            const updatingElement = [...baseElement];
            const elementIndex = updatingElement.findIndex(
              (child) => child.id === focusElement
            );

            updatingElement[elementIndex][style_name] = style_value;
            setBaseElement(updatingElement);
          }}
          onInputEditing={() => {
            setNotInputFocus(false);
            console.log("this is happening");
          }}
          onInputBlur={() => {
            setNotInputFocus(true);
            console.log("this is happening");
          }}
          className={`canvas-element ${element.type}`}
          element={element}
          onContentChange={(content) => onContentChange(content, element.id)}
          onDrag={(event, elementId, offset, touch) => {
            const sectionIndex = sections[pageDesigning].findIndex((section) =>
              section.children.some((child) => child.id === elementId)
            );
            const section = sections[pageDesigning][sectionIndex];
            const elementIndex = section.children.findIndex(
              (child) => child.id === elementId
            );
            const focusedElement = section.children[elementIndex];

            setFocusElement(elementId);
            setFocusElementType(element.type);
            setFontWeight(focusedElement.fontWeight);
            setLineHeight(focusedElement.lineHeight);
            setLetterSpacing(focusedElement.letterSpacing);
            setTextAlign(focusedElement.textAlign);
            setFontSize(focusedElement.fontSize);
            setFocusWidth(focusedElement.width);
            setFocusHeight(focusedElement.height);
            setFocusBackgroundColor(focusedElement.backgroundColor);
            setFocusColor(focusedElement.color);
            setFocusBorder(focusedElement.border);
            setFocusBoxShadow(focusedElement.shadow);
            setFocusOpacity(focusedElement.opacity);
            setFocusBorderRadius(focusedElement.borderRadius);
            handleDrag(
              event,
              elementId,
              offset,
              section.id,
              draggingSectionPositionY,
              touch
            );
            setNotInputFocus(true);
          }}
          onDragStart={(event, elementId, touch) => {
            handleDragStart(event, element.id, section.id, touch);
          }}
          onDragEnd={handleDragEnd}
          onResizeStart={() => handleResizeStart(element.id)}
          onResize={(newWidth, newHeight) =>
            handleResize(newWidth, newHeight, element, section)
          }
          onResizeEnd={handleResizeEnd}
          ref={element.ref} // Assign the ref to the element
          onRefSet={(elementRef) => {
            if (mobileDesigning) {
              const sectionIndex = mobileSections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...mobileSections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...sections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            }
          }}
        />
      );
    }
    if (element.type === "dynamic-account-header-element") {
      return (
        <DynamicAccountHeaderElement
          customerToken={customerToken}
          setCustomerToken={(stateUpdate) => setCustomerToken(stateUpdate)}
          customerAccountDetails={customerAccountDetails}
          setCustomerAccountDetails={(stateUpdate) =>
            setCustomerAccountDetails(stateUpdate)
          }
          mobileDesigning={mobileDesigning}
          dynamicItem={dynamicItem}
          pageDesigning={pageDesigning}
          activeWindowStat={activeWindowStat}
          setActiveWindowStat={(something) => setActiveWindowStat(something)}
          selectedElements={selectedElements}
          focusElement={focusElement}
          style={{ display: "none" }}
          key={element.id}
          setNotInputFocus={(status) => setNotInputFocus(status)}
          setEditingInputWithArrow={(status) =>
            setEditingInputWithArrow(status)
          }
          onFocus={(elementId, event) => {
            if (elementId === null) {
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setSelectedSection();
              setSelectedSectionIndex();

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              handleShiftClick(event, element.id);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
              setFocusBorderRadius(focusedElement.borderRadius);
              // setNotInputFocus(true);
            }
          }}
          onStyleChange={(style_name, style_value) => {
            const updatingElement = [...baseElement];
            const elementIndex = updatingElement.findIndex(
              (child) => child.id === focusElement
            );

            updatingElement[elementIndex][style_name] = style_value;
            setBaseElement(updatingElement);
          }}
          onInputEditing={() => {
            setNotInputFocus(false);
            console.log("this is happening");
          }}
          onInputBlur={() => {
            setNotInputFocus(true);
            console.log("this is happening");
          }}
          className={`canvas-element ${element.type}`}
          element={element}
          onContentChange={(content) => onContentChange(content, element.id)}
          onDrag={(event, elementId, offset, touch) => {
            const sectionIndex = sections[pageDesigning].findIndex((section) =>
              section.children.some((child) => child.id === elementId)
            );
            const section = sections[pageDesigning][sectionIndex];
            const elementIndex = section.children.findIndex(
              (child) => child.id === elementId
            );
            const focusedElement = section.children[elementIndex];

            setFocusElement(elementId);
            setFocusElementType(element.type);
            setFontWeight(focusedElement.fontWeight);
            setLineHeight(focusedElement.lineHeight);
            setLetterSpacing(focusedElement.letterSpacing);
            setTextAlign(focusedElement.textAlign);
            setFontSize(focusedElement.fontSize);
            setFocusWidth(focusedElement.width);
            setFocusHeight(focusedElement.height);
            setFocusBackgroundColor(focusedElement.backgroundColor);
            setFocusColor(focusedElement.color);
            setFocusBorder(focusedElement.border);
            setFocusBoxShadow(focusedElement.shadow);
            setFocusOpacity(focusedElement.opacity);
            setFocusBorderRadius(focusedElement.borderRadius);
            handleDrag(
              event,
              elementId,
              offset,
              section.id,
              draggingSectionPositionY,
              touch
            );
            setNotInputFocus(true);
          }}
          onDragStart={(event, elementId, touch) => {
            handleDragStart(event, element.id, section.id, touch);
          }}
          onDragEnd={handleDragEnd}
          onResizeStart={() => handleResizeStart(element.id)}
          onResize={(newWidth, newHeight) =>
            handleResize(newWidth, newHeight, element, section)
          }
          onResizeEnd={handleResizeEnd}
          ref={element.ref} // Assign the ref to the element
          onRefSet={(elementRef) => {
            if (mobileDesigning) {
              const sectionIndex = mobileSections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...mobileSections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...sections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            }
          }}
        />
      );
    }
    if (element.type === "dynamic-account-tabs-element") {
      return (
        <DynamicAccountTabsElement
          customerToken={customerToken}
          currency={currency}
          setCustomerToken={(stateUpdate) => setCustomerToken(stateUpdate)}
          customerAccountDetails={customerAccountDetails}
          setCustomerAccountDetails={(stateUpdate) =>
            setCustomerAccountDetails(stateUpdate)
          }
          mobileDesigning={mobileDesigning}
          dynamicItem={dynamicItem}
          pageDesigning={pageDesigning}
          activeWindowStat={activeWindowStat}
          setActiveWindowStat={(something) => setActiveWindowStat(something)}
          selectedElements={selectedElements}
          focusElement={focusElement}
          style={{ display: "none" }}
          key={element.id}
          setNotInputFocus={(status) => setNotInputFocus(status)}
          setEditingInputWithArrow={(status) =>
            setEditingInputWithArrow(status)
          }
          onFocus={(elementId, event) => {
            if (elementId === null) {
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setSelectedSection();
              setSelectedSectionIndex();

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              handleShiftClick(event, element.id);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
              setFocusBorderRadius(focusedElement.borderRadius);
              // setNotInputFocus(true);
            }
          }}
          onStyleChange={(style_name, style_value) => {
            const updatingElement = [...baseElement];
            const elementIndex = updatingElement.findIndex(
              (child) => child.id === focusElement
            );

            updatingElement[elementIndex][style_name] = style_value;
            setBaseElement(updatingElement);
          }}
          onInputEditing={() => {
            setNotInputFocus(false);
            console.log("this is happening");
          }}
          onInputBlur={() => {
            setNotInputFocus(true);
            console.log("this is happening");
          }}
          className={`canvas-element ${element.type}`}
          element={element}
          onContentChange={(content) => onContentChange(content, element.id)}
          onDrag={(event, elementId, offset, touch) => {
            const sectionIndex = sections[pageDesigning].findIndex((section) =>
              section.children.some((child) => child.id === elementId)
            );
            const section = sections[pageDesigning][sectionIndex];
            const elementIndex = section.children.findIndex(
              (child) => child.id === elementId
            );
            const focusedElement = section.children[elementIndex];

            setFocusElement(elementId);
            setFocusElementType(element.type);
            setFontWeight(focusedElement.fontWeight);
            setLineHeight(focusedElement.lineHeight);
            setLetterSpacing(focusedElement.letterSpacing);
            setTextAlign(focusedElement.textAlign);
            setFontSize(focusedElement.fontSize);
            setFocusWidth(focusedElement.width);
            setFocusHeight(focusedElement.height);
            setFocusBackgroundColor(focusedElement.backgroundColor);
            setFocusColor(focusedElement.color);
            setFocusBorder(focusedElement.border);
            setFocusBoxShadow(focusedElement.shadow);
            setFocusOpacity(focusedElement.opacity);
            setFocusBorderRadius(focusedElement.borderRadius);
            handleDrag(
              event,
              elementId,
              offset,
              section.id,
              draggingSectionPositionY,
              touch
            );
            setNotInputFocus(true);
          }}
          onDragStart={(event, elementId, touch) => {
            handleDragStart(event, element.id, section.id, touch);
          }}
          onDragEnd={handleDragEnd}
          onResizeStart={() => handleResizeStart(element.id)}
          onResize={(newWidth, newHeight) =>
            handleResize(newWidth, newHeight, element, section)
          }
          onResizeEnd={handleResizeEnd}
          ref={element.ref} // Assign the ref to the element
          onRefSet={(elementRef) => {
            if (mobileDesigning) {
              const sectionIndex = mobileSections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...mobileSections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...sections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            }
          }}
        />
      );
    }
    if (element.type === "dynamic-cart-element") {
      return (
        <DynamicCartElement
          customerToken={customerToken}
          currency={currency}
          setCustomerToken={(stateUpdate) => setCustomerToken(stateUpdate)}
          customerAccountDetails={customerAccountDetails}
          setCustomerAccountDetails={(stateUpdate) =>
            setCustomerAccountDetails(stateUpdate)
          }
          mobileDesigning={mobileDesigning}
          dynamicItem={dynamicItem}
          pageDesigning={pageDesigning}
          activeWindowStat={activeWindowStat}
          setActiveWindowStat={(something) => setActiveWindowStat(something)}
          selectedElements={selectedElements}
          focusElement={focusElement}
          style={{ display: "none" }}
          key={element.id}
          setNotInputFocus={(status) => setNotInputFocus(status)}
          setEditingInputWithArrow={(status) =>
            setEditingInputWithArrow(status)
          }
          onFocus={(elementId, event) => {
            if (elementId === null) {
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setSelectedSection();
              setSelectedSectionIndex();

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              handleShiftClick(event, element.id);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
              setFocusBorderRadius(focusedElement.borderRadius);
              // setNotInputFocus(true);
            }
          }}
          onStyleChange={(style_name, style_value) => {
            const updatingElement = [...baseElement];
            const elementIndex = updatingElement.findIndex(
              (child) => child.id === focusElement
            );

            updatingElement[elementIndex][style_name] = style_value;
            setBaseElement(updatingElement);
          }}
          onInputEditing={() => {
            setNotInputFocus(false);
            console.log("this is happening");
          }}
          onInputBlur={() => {
            setNotInputFocus(true);
            console.log("this is happening");
          }}
          className={`canvas-element ${element.type}`}
          element={element}
          onContentChange={(content) => onContentChange(content, element.id)}
          onDrag={(event, elementId, offset, touch) => {
            const sectionIndex = sections[pageDesigning].findIndex((section) =>
              section.children.some((child) => child.id === elementId)
            );
            const section = sections[pageDesigning][sectionIndex];
            const elementIndex = section.children.findIndex(
              (child) => child.id === elementId
            );
            const focusedElement = section.children[elementIndex];

            setFocusElement(elementId);
            setFocusElementType(element.type);
            setFontWeight(focusedElement.fontWeight);
            setLineHeight(focusedElement.lineHeight);
            setLetterSpacing(focusedElement.letterSpacing);
            setTextAlign(focusedElement.textAlign);
            setFontSize(focusedElement.fontSize);
            setFocusWidth(focusedElement.width);
            setFocusHeight(focusedElement.height);
            setFocusBackgroundColor(focusedElement.backgroundColor);
            setFocusColor(focusedElement.color);
            setFocusBorder(focusedElement.border);
            setFocusBoxShadow(focusedElement.shadow);
            setFocusOpacity(focusedElement.opacity);
            setFocusBorderRadius(focusedElement.borderRadius);
            handleDrag(
              event,
              elementId,
              offset,
              section.id,
              draggingSectionPositionY,
              touch
            );
            setNotInputFocus(true);
          }}
          onDragStart={(event, elementId, touch) => {
            handleDragStart(event, element.id, section.id, touch);
          }}
          onDragEnd={handleDragEnd}
          onResizeStart={() => handleResizeStart(element.id)}
          onResize={(newWidth, newHeight) =>
            handleResize(newWidth, newHeight, element, section)
          }
          onResizeEnd={handleResizeEnd}
          ref={element.ref} // Assign the ref to the element
          onRefSet={(elementRef) => {
            if (mobileDesigning) {
              const sectionIndex = mobileSections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...mobileSections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...sections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            }
          }}
        />
      );
    }
    if (element.type === "dynamic-list-attribute-element") {
      return (
        <DynamicListAttributeElement
          mobileDesigning={mobileDesigning}
          dynamicItem={dynamicItem}
          pageDesigning={pageDesigning}
          activeWindowStat={activeWindowStat}
          setActiveWindowStat={(something) => setActiveWindowStat(something)}
          selectedElements={selectedElements}
          focusElement={focusElement}
          style={{ display: "none" }}
          key={element.id}
          setNotInputFocus={(status) => setNotInputFocus(status)}
          setEditingInputWithArrow={(status) =>
            setEditingInputWithArrow(status)
          }
          onFocus={(elementId, event) => {
            if (elementId === null) {
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setSelectedSection();
              setSelectedSectionIndex();

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              handleShiftClick(event, element.id);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
              setFocusBorderRadius(focusedElement.borderRadius);
              // setNotInputFocus(true);
            }
          }}
          onStyleChange={(style_name, style_value) => {
            const updatingElement = [...baseElement];
            const elementIndex = updatingElement.findIndex(
              (child) => child.id === focusElement
            );

            updatingElement[elementIndex][style_name] = style_value;
            setBaseElement(updatingElement);
          }}
          onInputEditing={() => {
            setNotInputFocus(false);
            console.log("this is happening");
          }}
          onInputBlur={() => {
            setNotInputFocus(true);
            console.log("this is happening");
          }}
          className={`canvas-element ${element.type}`}
          element={element}
          onContentChange={(content) => onContentChange(content, element.id)}
          onDrag={(event, elementId, offset, touch) => {
            const sectionIndex = sections[pageDesigning].findIndex((section) =>
              section.children.some((child) => child.id === elementId)
            );
            const section = sections[pageDesigning][sectionIndex];
            const elementIndex = section.children.findIndex(
              (child) => child.id === elementId
            );
            const focusedElement = section.children[elementIndex];

            setFocusElement(elementId);
            setFocusElementType(element.type);
            setFontWeight(focusedElement.fontWeight);
            setLineHeight(focusedElement.lineHeight);
            setLetterSpacing(focusedElement.letterSpacing);
            setTextAlign(focusedElement.textAlign);
            setFontSize(focusedElement.fontSize);
            setFocusWidth(focusedElement.width);
            setFocusHeight(focusedElement.height);
            setFocusBackgroundColor(focusedElement.backgroundColor);
            setFocusColor(focusedElement.color);
            setFocusBorder(focusedElement.border);
            setFocusBoxShadow(focusedElement.shadow);
            setFocusOpacity(focusedElement.opacity);
            setFocusBorderRadius(focusedElement.borderRadius);
            handleDrag(
              event,
              elementId,
              offset,
              section.id,
              draggingSectionPositionY,
              touch
            );
            setNotInputFocus(true);
          }}
          onDragStart={(event, elementId, touch) => {
            handleDragStart(event, element.id, section.id, touch);
          }}
          onDragEnd={handleDragEnd}
          onResizeStart={() => handleResizeStart(element.id)}
          onResize={(newWidth, newHeight) =>
            handleResize(newWidth, newHeight, element, section)
          }
          onResizeEnd={handleResizeEnd}
          ref={element.ref} // Assign the ref to the element
          onRefSet={(elementRef) => {
            if (mobileDesigning) {
              const sectionIndex = mobileSections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...mobileSections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...sections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            }
          }}
        />
      );
    }
    if (element.type === "dynamic-table-attribute-element") {
      return (
        <DynamicTableAttributeElement
          mobileDesigning={mobileDesigning}
          dynamicItem={dynamicItem}
          pageDesigning={pageDesigning}
          activeWindowStat={activeWindowStat}
          setActiveWindowStat={(something) => setActiveWindowStat(something)}
          selectedElements={selectedElements}
          focusElement={focusElement}
          style={{ display: "none" }}
          key={element.id}
          setNotInputFocus={(status) => setNotInputFocus(status)}
          setEditingInputWithArrow={(status) =>
            setEditingInputWithArrow(status)
          }
          onFocus={(elementId, event) => {
            if (elementId === null) {
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setSelectedSection();
              setSelectedSectionIndex();

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              handleShiftClick(event, element.id);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
              setFocusBorderRadius(focusedElement.borderRadius);
              // setNotInputFocus(true);
            }
          }}
          onStyleChange={(style_name, style_value) => {
            const updatingElement = [...baseElement];
            const elementIndex = updatingElement.findIndex(
              (child) => child.id === focusElement
            );

            updatingElement[elementIndex][style_name] = style_value;
            setBaseElement(updatingElement);
          }}
          onInputEditing={() => {
            setNotInputFocus(false);
            console.log("this is happening");
          }}
          onInputBlur={() => {
            setNotInputFocus(true);
            console.log("this is happening");
          }}
          className={`canvas-element ${element.type}`}
          element={element}
          onContentChange={(content) => onContentChange(content, element.id)}
          onDrag={(event, elementId, offset, touch) => {
            const sectionIndex = sections[pageDesigning].findIndex((section) =>
              section.children.some((child) => child.id === elementId)
            );
            const section = sections[pageDesigning][sectionIndex];
            const elementIndex = section.children.findIndex(
              (child) => child.id === elementId
            );
            const focusedElement = section.children[elementIndex];

            setFocusElement(elementId);
            setFocusElementType(element.type);
            setFontWeight(focusedElement.fontWeight);
            setLineHeight(focusedElement.lineHeight);
            setLetterSpacing(focusedElement.letterSpacing);
            setTextAlign(focusedElement.textAlign);
            setFontSize(focusedElement.fontSize);
            setFocusWidth(focusedElement.width);
            setFocusHeight(focusedElement.height);
            setFocusBackgroundColor(focusedElement.backgroundColor);
            setFocusColor(focusedElement.color);
            setFocusBorder(focusedElement.border);
            setFocusBoxShadow(focusedElement.shadow);
            setFocusOpacity(focusedElement.opacity);
            setFocusBorderRadius(focusedElement.borderRadius);
            handleDrag(
              event,
              elementId,
              offset,
              section.id,
              draggingSectionPositionY,
              touch
            );
            setNotInputFocus(true);
          }}
          onDragStart={(event, elementId, touch) => {
            handleDragStart(event, element.id, section.id, touch);
          }}
          onDragEnd={handleDragEnd}
          onResizeStart={() => handleResizeStart(element.id)}
          onResize={(newWidth, newHeight) =>
            handleResize(newWidth, newHeight, element, section)
          }
          onResizeEnd={handleResizeEnd}
          ref={element.ref} // Assign the ref to the element
          onRefSet={(elementRef) => {
            if (mobileDesigning) {
              const sectionIndex = mobileSections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...mobileSections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...sections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            }
          }}
        />
      );
    }
    if (element.type === "dynamic-grid-attribute-element") {
      return (
        <DynamicGridAttributeElement
          mobileDesigning={mobileDesigning}
          dynamicItem={dynamicItem}
          pageDesigning={pageDesigning}
          activeWindowStat={activeWindowStat}
          setActiveWindowStat={(something) => setActiveWindowStat(something)}
          selectedElements={selectedElements}
          focusElement={focusElement}
          style={{ display: "none" }}
          key={element.id}
          setNotInputFocus={(status) => setNotInputFocus(status)}
          setEditingInputWithArrow={(status) =>
            setEditingInputWithArrow(status)
          }
          onFocus={(elementId, event) => {
            if (elementId === null) {
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setSelectedSection();
              setSelectedSectionIndex();

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              handleShiftClick(event, element.id);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
              setFocusBorderRadius(focusedElement.borderRadius);
              // setNotInputFocus(true);
            }
          }}
          onStyleChange={(style_name, style_value) => {
            const updatingElement = [...baseElement];
            const elementIndex = updatingElement.findIndex(
              (child) => child.id === focusElement
            );

            updatingElement[elementIndex][style_name] = style_value;
            setBaseElement(updatingElement);
          }}
          onInputEditing={() => {
            setNotInputFocus(false);
            console.log("this is happening");
          }}
          onInputBlur={() => {
            setNotInputFocus(true);
            console.log("this is happening");
          }}
          className={`canvas-element ${element.type}`}
          element={element}
          onContentChange={(content) => onContentChange(content, element.id)}
          onDrag={(event, elementId, offset, touch) => {
            const sectionIndex = sections[pageDesigning].findIndex((section) =>
              section.children.some((child) => child.id === elementId)
            );
            const section = sections[pageDesigning][sectionIndex];
            const elementIndex = section.children.findIndex(
              (child) => child.id === elementId
            );
            const focusedElement = section.children[elementIndex];

            setFocusElement(elementId);
            setFocusElementType(element.type);
            setFontWeight(focusedElement.fontWeight);
            setLineHeight(focusedElement.lineHeight);
            setLetterSpacing(focusedElement.letterSpacing);
            setTextAlign(focusedElement.textAlign);
            setFontSize(focusedElement.fontSize);
            setFocusWidth(focusedElement.width);
            setFocusHeight(focusedElement.height);
            setFocusBackgroundColor(focusedElement.backgroundColor);
            setFocusColor(focusedElement.color);
            setFocusBorder(focusedElement.border);
            setFocusBoxShadow(focusedElement.shadow);
            setFocusOpacity(focusedElement.opacity);
            setFocusBorderRadius(focusedElement.borderRadius);
            handleDrag(
              event,
              elementId,
              offset,
              section.id,
              draggingSectionPositionY,
              touch
            );
            setNotInputFocus(true);
          }}
          onDragStart={(event, elementId, touch) => {
            handleDragStart(event, element.id, section.id, touch);
          }}
          onDragEnd={handleDragEnd}
          onResizeStart={() => handleResizeStart(element.id)}
          onResize={(newWidth, newHeight) =>
            handleResize(newWidth, newHeight, element, section)
          }
          onResizeEnd={handleResizeEnd}
          ref={element.ref} // Assign the ref to the element
          onRefSet={(elementRef) => {
            if (mobileDesigning) {
              const sectionIndex = mobileSections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...mobileSections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...sections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            }
          }}
        />
      );
    }
    if (element.type === "text-element") {
      return (
        <TextElement
          mobileDesigning={mobileDesigning}
          activeWindowStat={activeWindowStat}
          setActiveWindowStat={(something) => setActiveWindowStat(something)}
          selectedElements={selectedElements}
          focusElement={focusElement}
          style={{ display: "none" }}
          key={element.id}
          setNotInputFocus={(status) => setNotInputFocus(status)}
          setEditingInputWithArrow={(status) =>
            setEditingInputWithArrow(status)
          }
          onFocus={(elementId, event) => {
            if (elementId === null) {
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setSelectedSection();
              setSelectedSectionIndex();

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              handleShiftClick(event, element.id);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
              setFocusBorderRadius(focusedElement.borderRadius);
              // setNotInputFocus(true);
            }
          }}
          onStyleChange={(style_name, style_value) => {
            const updatingElement = [...baseElement];
            const elementIndex = updatingElement.findIndex(
              (child) => child.id === focusElement
            );

            updatingElement[elementIndex][style_name] = style_value;
            setBaseElement(updatingElement);
          }}
          onInputEditing={() => {
            setNotInputFocus(false);
            console.log("this is happening");
          }}
          onInputBlur={() => {
            setNotInputFocus(true);
            console.log("this is happening");
          }}
          className={`canvas-element ${element.type}`}
          element={element}
          onContentChange={(content) => onContentChange(content, element.id)}
          onDrag={(event, elementId, offset, touch) => {
            const sectionIndex = sections[pageDesigning].findIndex((section) =>
              section.children.some((child) => child.id === elementId)
            );
            const section = sections[pageDesigning][sectionIndex];
            const elementIndex = section.children.findIndex(
              (child) => child.id === elementId
            );
            const focusedElement = section.children[elementIndex];

            setFocusElement(elementId);
            setFocusElementType(element.type);
            setFontWeight(focusedElement.fontWeight);
            setLineHeight(focusedElement.lineHeight);
            setLetterSpacing(focusedElement.letterSpacing);
            setTextAlign(focusedElement.textAlign);
            setFontSize(focusedElement.fontSize);
            setFocusWidth(focusedElement.width);
            setFocusHeight(focusedElement.height);
            setFocusBackgroundColor(focusedElement.backgroundColor);
            setFocusColor(focusedElement.color);
            setFocusBorder(focusedElement.border);
            setFocusBoxShadow(focusedElement.shadow);
            setFocusOpacity(focusedElement.opacity);
            setFocusBorderRadius(focusedElement.borderRadius);
            handleDrag(
              event,
              elementId,
              offset,
              section.id,
              draggingSectionPositionY,
              touch
            );
            setNotInputFocus(true);
          }}
          onDragStart={(event, elementId, touch) => {
            handleDragStart(event, element.id, section.id, touch);
          }}
          onDragEnd={handleDragEnd}
          onResizeStart={() => handleResizeStart(element.id)}
          onResize={(newWidth, newHeight) =>
            handleResize(newWidth, newHeight, element, section)
          }
          onResizeEnd={handleResizeEnd}
          ref={element.ref} // Assign the ref to the element
          onRefSet={(elementRef) => {
            if (mobileDesigning) {
              const sectionIndex = mobileSections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...mobileSections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...sections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            }
          }}
        />
      );
    }
    if (element.type === "menu-element") {
      return (
        <div
          style={{
            position: "absolute",
            left: mobileDesigning
              ? element.position.mx
                ? `${element.position.mx}%`
                : `${element.position.x}%`
              : `${element.position.x}%`,
            top: mobileDesigning
              ? element.position.my
                ? element.position.my
                : element.position.y
              : element.position.y,
            width: mobileDesigning
              ? element.mwidth
                ? (element.mwidth * 400) / 100
                : (element.width * 400) / 100
              : (element.width * viewportWidth) / 100,
            height: mobileDesigning
              ? element.mheight
                ? element.mheight
                : element.height
              : element.height,
            zIndex: selectedElements.includes(element.id)
              ? 501
              : focusElement === element.id
                ? 501
                : 499,
            // outline: focus ? "1px solid #5e86c4" : "none",
            display: null,
            // background: element.backgroundColor,
          }}
        >
          <MenuElement
            pageDesigning={pageDesigning}
            basePages={basePages}
            setBasePages={(something) => setBasePages(something)}
            menuIsOpen={menuIsOpen}
            setMenuIsOpen={(something) => setMenuIsOpen(something)}
            mobileDesigning={mobileDesigning}
            sections={sections}
            activeWindowStat={activeWindowStat}
            setActiveWindowStat={(something) => setActiveWindowStat(something)}
            selectedElements={selectedElements}
            focusElement={focusElement}
            style={{ display: "none" }}
            key={element.id}
            setNotInputFocus={(status) => setNotInputFocus(status)}
            setEditingInputWithArrow={(status) =>
              setEditingInputWithArrow(status)
            }
            onFocus={(elementId, event) => {
              if (elementId === null) {
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === elementId)
                );
                const section = sections[pageDesigning][sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === elementId
                );
                const focusedElement = section.children[elementIndex];

                setSelectedSection();
                setSelectedSectionIndex();

                setFocusElement(elementId);
                setFocusElementType(element.type);
                setFontWeight(focusedElement.fontWeight);
                setLineHeight(focusedElement.lineHeight);
                setLetterSpacing(focusedElement.letterSpacing);
                setTextAlign(focusedElement.textAlign);
                setFontSize(focusedElement.fontSize);
                setFocusWidth(focusedElement.width);
                setFocusHeight(focusedElement.height);
                handleShiftClick(event, element.id);
                setFocusBackgroundColor(focusedElement.backgroundColor);
                setFocusColor(focusedElement.color);
                setFocusBorder(focusedElement.border);
                setFocusBoxShadow(focusedElement.shadow);
                setFocusOpacity(focusedElement.opacity);
                setFocusBorderRadius(focusedElement.borderRadius);
                // setNotInputFocus(true);
              }
            }}
            onStyleChange={(style_name, style_value) => {
              const updatingElement = [...baseElement];
              const elementIndex = updatingElement.findIndex(
                (child) => child.id === focusElement
              );

              updatingElement[elementIndex][style_name] = style_value;
              setBaseElement(updatingElement);
            }}
            onInputEditing={() => {
              setNotInputFocus(false);
              console.log("this is happening");
            }}
            onInputBlur={() => {
              setNotInputFocus(true);
              console.log("this is happening");
            }}
            className={`canvas-element ${element.type}`}
            element={element}
            onContentChange={(content) => onContentChange(content, element.id)}
            onDrag={(event, elementId, offset, touch) => {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
              setFocusBorderRadius(focusedElement.borderRadius);
              handleDrag(
                event,
                elementId,
                offset,
                section.id,
                draggingSectionPositionY,
                touch
              );
              setNotInputFocus(true);
            }}
            onDragStart={(event, elementId, touch) => {
              handleDragStart(event, element.id, section.id, touch);
            }}
            onDragEnd={handleDragEnd}
            onResizeStart={() => handleResizeStart(element.id)}
            onResize={(newWidth, newHeight) =>
              handleResize(newWidth, newHeight, element, section)
            }
            onResizeEnd={handleResizeEnd}
            ref={element.ref} // Assign the ref to the element
            onRefSet={(elementRef) => {
              if (mobileDesigning) {
                const sectionIndex = mobileSections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...mobileSections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...sections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              }
            }}
          />
        </div>
      );
    }
    if (element.type === "image-gallery-element") {
      return (
        <div
          style={{
            position: "absolute",
            left: mobileDesigning
              ? element.position.mx
                ? `${element.position.mx}%`
                : `${element.position.x}%`
              : `${element.position.x}%`,
            top: mobileDesigning
              ? element.position.my
                ? element.position.my
                : element.position.y
              : element.position.y,
            width: mobileDesigning
              ? element.mwidth
                ? (element.mwidth * 400) / 100
                : (element.width * 400) / 100
              : (element.width * viewportWidth) / 100,
            height: mobileDesigning
              ? element.mheight
                ? element.mheight
                : element.height
              : element.height,
            zIndex: selectedElements.includes(element.id)
              ? 501
              : focusElement === element.id
                ? 501
                : 499,
            // outline: focus ? "1px solid #5e86c4" : "none",
            display: null,
            // background: element.backgroundColor,
          }}
        >
          <ImageGalleryElement
            mobileDesigning={mobileDesigning}
            activeWindowStat={activeWindowStat}
            setActiveWindowStat={(something) => setActiveWindowStat(something)}
            selectedElements={selectedElements}
            focusElement={focusElement}
            style={{ display: "none" }}
            key={element.id}
            setNotInputFocus={(status) => setNotInputFocus(status)}
            setEditingInputWithArrow={(status) =>
              setEditingInputWithArrow(status)
            }
            onFocus={(elementId, event) => {
              if (elementId === null) {
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === elementId)
                );
                const section = sections[pageDesigning][sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === elementId
                );
                const focusedElement = section.children[elementIndex];

                setSelectedSection();
                setSelectedSectionIndex();

                setFocusElement(elementId);
                setFocusElementType(element.type);
                setFontWeight(focusedElement.fontWeight);
                setLineHeight(focusedElement.lineHeight);
                setLetterSpacing(focusedElement.letterSpacing);
                setTextAlign(focusedElement.textAlign);
                setFontSize(focusedElement.fontSize);
                setFocusWidth(focusedElement.width);
                setFocusHeight(focusedElement.height);
                handleShiftClick(event, element.id);
                setFocusBackgroundColor(focusedElement.backgroundColor);
                setFocusColor(focusedElement.color);
                setFocusBorder(focusedElement.border);
                setFocusBoxShadow(focusedElement.shadow);
                setFocusOpacity(focusedElement.opacity);
                setFocusBorderRadius(focusedElement.borderRadius);
                // setNotInputFocus(true);
              }
            }}
            onStyleChange={(style_name, style_value) => {
              const updatingElement = [...baseElement];
              const elementIndex = updatingElement.findIndex(
                (child) => child.id === focusElement
              );

              updatingElement[elementIndex][style_name] = style_value;
              setBaseElement(updatingElement);
            }}
            onInputEditing={() => {
              setNotInputFocus(false);
              console.log("this is happening");
            }}
            onInputBlur={() => {
              setNotInputFocus(true);
              console.log("this is happening");
            }}
            className={`canvas-element ${element.type}`}
            element={element}
            onContentChange={(content) => onContentChange(content, element.id)}
            onDrag={(event, elementId, offset, touch) => {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
              setFocusBorderRadius(focusedElement.borderRadius);
              handleDrag(
                event,
                elementId,
                offset,
                section.id,
                draggingSectionPositionY,
                touch
              );
              setNotInputFocus(true);
            }}
            onDragStart={(event, elementId, touch) => {
              handleDragStart(event, element.id, section.id, touch);
            }}
            onDragEnd={handleDragEnd}
            onResizeStart={() => handleResizeStart(element.id)}
            onResize={(newWidth, newHeight) =>
              handleResize(newWidth, newHeight, element, section)
            }
            onResizeEnd={handleResizeEnd}
            ref={element.ref} // Assign the ref to the element
            onRefSet={(elementRef) => {
              if (mobileDesigning) {
                const sectionIndex = mobileSections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...mobileSections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...sections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              }
            }}
          />
        </div>
      );
    }
    if (element.type === "event-item-element") {
      return (
        <div
          style={{
            position: "absolute",
            left: mobileDesigning
              ? element.position.mx
                ? `${element.position.mx}%`
                : `${element.position.x}%`
              : `${element.position.x}%`,
            top: mobileDesigning
              ? element.position.my
                ? element.position.my
                : element.position.y
              : element.position.y,
            width: mobileDesigning
              ? element.mwidth
                ? (element.mwidth * 400) / 100
                : (element.width * 400) / 100
              : (element.width * viewportWidth) / 100,
            height: mobileDesigning
              ? element.mheight
                ? element.mheight
                : element.height
              : element.height,
            zIndex: selectedElements.includes(element.id)
              ? 501
              : focusElement === element.id
                ? 501
                : 499,
            // outline: focus ? "1px solid #5e86c4" : "none",
            display: null,
            // background: element.backgroundColor,
          }}
        >
          <EventElement
            customerToken={customerToken}
            currency={currency}
            setCustomerToken={(stateUpdate) => setCustomerToken(stateUpdate)}
            customerAccountDetails={customerAccountDetails}
            setCustomerAccountDetails={(stateUpdate) =>
              setCustomerAccountDetails(stateUpdate)
            }
            mobileDesigning={mobileDesigning}
            activeWindowStat={activeWindowStat}
            setActiveWindowStat={(something) => setActiveWindowStat(something)}
            selectedElements={selectedElements}
            focusElement={focusElement}
            style={{ display: "none" }}
            setNotInputFocus={(status) => setNotInputFocus(status)}
            key={element.id}
            setEditingInputWithArrow={(status) =>
              setEditingInputWithArrow(status)
            }
            onFocus={(elementId, event) => {
              if (elementId === null) {
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === elementId)
                );
                const section = sections[pageDesigning][sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === elementId
                );
                const focusedElement = section.children[elementIndex];

                setSelectedSection();
                setSelectedSectionIndex();

                setFocusElement(elementId);
                setFocusElementType(element.type);
                setFontWeight(focusedElement.fontWeight);
                setLineHeight(focusedElement.lineHeight);
                setLetterSpacing(focusedElement.letterSpacing);
                setTextAlign(focusedElement.textAlign);
                setFontSize(focusedElement.fontSize);
                setFocusWidth(focusedElement.width);
                setFocusHeight(focusedElement.height);
                handleShiftClick(event, element.id);
                setFocusBackgroundColor(focusedElement.backgroundColor);
                setFocusColor(focusedElement.color);
                setNotInputFocus(true);
                setFocusBorder(focusedElement.border);
                setFocusBoxShadow(focusedElement.shadow);
                setFocusOpacity(focusedElement.opacity);
                setFocusBorderRadius(focusedElement.borderRadius);
              }
            }}
            className={`canvas-element ${element.type}`}
            element={element}
            onStyleChange={(style_name, style_value) => {
              const updatingElement = [...baseElement];
              const elementIndex = updatingElement.findIndex(
                (child) => child.id === focusElement
              );

              updatingElement[elementIndex][style_name] = style_value;
              setBaseElement(updatingElement);
            }}
            onEventChange={(event_id) => {
              const updatingElement = [...baseElement];
              const elementIndex = updatingElement.findIndex(
                (child) => child.id === focusElement
              );

              updatingElement[elementIndex].event_id = event_id;
              setBaseElement(updatingElement);
            }}
            onDrag={(event, elementId, offset, touch) => {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setNotInputFocus(true);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusBorderRadius(focusedElement.borderRadius);
              setFocusOpacity(focusedElement.opacity);
              handleDrag(
                event,
                elementId,
                offset,
                section.id,
                draggingSectionPositionY,
                touch
              );
            }}
            onDragStart={(event, elementId, touch) => {
              handleDragStart(event, element.id, section.id, touch);
            }}
            onDragEnd={handleDragEnd}
            onResizeStart={() => handleResizeStart(element.id)}
            onResize={(newWidth, newHeight) =>
              handleResize(newWidth, newHeight, element, section)
            }
            onResizeEnd={handleResizeEnd}
            ref={element.ref} // Assign the ref to the element
            onRefSet={(elementRef) => {
              if (mobileDesigning) {
                const sectionIndex = mobileSections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...mobileSections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...sections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              }
            }}
          />
        </div>
      );
    }
    if (element.type === "news-search-element") {
      return (
        <div
          style={{
            position: "absolute",
            left: mobileDesigning
              ? element.position.mx
                ? `${element.position.mx}%`
                : `${element.position.x}%`
              : `${element.position.x}%`,
            top: mobileDesigning
              ? element.position.my
                ? element.position.my
                : element.position.y
              : element.position.y,
            width: mobileDesigning
              ? element.mwidth
                ? (element.mwidth * 400) / 100
                : (element.width * 400) / 100
              : (element.width * viewportWidth) / 100,
            height: mobileDesigning
              ? element.mheight
                ? element.mheight
                : element.height
              : element.height,
            zIndex: selectedElements.includes(element.id)
              ? 501
              : focusElement === element.id
                ? 501
                : 499,
            // outline: focus ? "1px solid #5e86c4" : "none",
            display: null,
            // background: element.backgroundColor,
          }}
        >
          <NewsSearchElement
            mobileDesigning={mobileDesigning}
            activeWindowStat={activeWindowStat}
            setActiveWindowStat={(something) => setActiveWindowStat(something)}
            selectedElements={selectedElements}
            focusElement={focusElement}
            setNotInputFocus={(status) => setNotInputFocus(status)}
            style={{ display: "none" }}
            key={element.id}
            onStyleChange={(style_name, style_value) => {
              const updatingElement = [...baseElement];
              const elementIndex = updatingElement.findIndex(
                (child) => child.id === focusElement
              );

              updatingElement[elementIndex][style_name] = style_value;
              setBaseElement(updatingElement);
            }}
            setEditingInputWithArrow={(status) =>
              setEditingInputWithArrow(status)
            }
            onFocus={(elementId, event) => {
              if (elementId === null) {
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === elementId)
                );
                const section = sections[pageDesigning][sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === elementId
                );
                const focusedElement = section.children[elementIndex];

                setSelectedSection();
                setSelectedSectionIndex();

                setFocusElement(elementId);
                setFocusElementType(element.type);
                setFontWeight(focusedElement.fontWeight);
                setLineHeight(focusedElement.lineHeight);
                setLetterSpacing(focusedElement.letterSpacing);
                setTextAlign(focusedElement.textAlign);
                setFontSize(focusedElement.fontSize);
                setFocusWidth(focusedElement.width);
                setFocusHeight(focusedElement.height);
                handleShiftClick(event, element.id);
                setFocusBackgroundColor(focusedElement.backgroundColor);
                setNotInputFocus(false);
                setFocusColor(focusedElement.color);
                setNotInputFocus(true);
                setFocusBorder(focusedElement.border);
                setFocusBoxShadow(focusedElement.shadow);
                setFocusOpacity(focusedElement.opacity);
                setFocusBorderRadius(focusedElement.borderRadius);
              }
            }}
            className={`canvas-element ${element.type}`}
            element={element}
            onSearchTermChange={(text) => {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              if (sectionIndex === -1) {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((red) =>
                      red.children.some((some) => some.id === element.id)
                    )
                );
                const boxIndex = sections[pageDesigning][
                  sectionIndex
                ].children.findIndex((ren) =>
                  ren.children.some((blue) => blue.id === element.id)
                );
                const section =
                  sections[pageDesigning][sectionIndex].children[boxIndex];
                const elementIndex = section.children.findIndex(
                  (red) => red.id === element.id
                );

                const updatedSections = [...sections[pageDesigning]];
                const updatedElements = [...section.children];
                // console.log(updatedElements[elementIndex])
                updatedElements[elementIndex].search_term = text;
                updatedSections[sectionIndex] = section;
                handleNewsSearchTermChange(text);
              } else {
                // console.log("is not happening")
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...sections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === focusElement
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].search_term = text;
                updatedSections[sectionIndex] = section;
                handleNewsSearchTermChange(text);
              }
            }}
            onDrag={(event, elementId, offset, touch) => {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setNotInputFocus(true);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusBorderRadius(focusedElement.borderRadius);
              setFocusOpacity(focusedElement.opacity);
              handleDrag(
                event,
                elementId,
                offset,
                section.id,
                draggingSectionPositionY,
                touch
              );
            }}
            onDragStart={(event, elementId, touch) => {
              handleDragStart(event, element.id, section.id, touch);
            }}
            onDragEnd={handleDragEnd}
            onResizeStart={() => handleResizeStart(element.id)}
            onResize={(newWidth, newHeight) =>
              handleResize(newWidth, newHeight, element, section)
            }
            onResizeEnd={handleResizeEnd}
            ref={element.ref} // Assign the ref to the element
            onRefSet={(elementRef) => {
              if (mobileDesigning) {
                const sectionIndex = mobileSections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...mobileSections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...sections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              }
            }}
          />
        </div>
      );
    }
    if (element.type === "news-filter-element") {
      return (
        <div
          style={{
            position: "absolute",
            left: mobileDesigning
              ? element.position.mx
                ? `${element.position.mx}%`
                : `${element.position.x}%`
              : `${element.position.x}%`,
            top: mobileDesigning
              ? element.position.my
                ? element.position.my
                : element.position.y
              : element.position.y,
            width: mobileDesigning
              ? element.mwidth
                ? (element.mwidth * 400) / 100
                : (element.width * 400) / 100
              : (element.width * viewportWidth) / 100,
            height: mobileDesigning
              ? element.mheight
                ? element.mheight
                : element.height
              : element.height,
            zIndex: selectedElements.includes(element.id)
              ? 501
              : focusElement === element.id
                ? 501
                : 499,
            // outline: focus ? "1px solid #5e86c4" : "none",
            display: null,
            // background: element.backgroundColor,
          }}
        >
          <NewsFilterElement
            mobileDesigning={mobileDesigning}
            activeWindowStat={activeWindowStat}
            setActiveWindowStat={(something) => setActiveWindowStat(something)}
            selectedElements={selectedElements}
            focusElement={focusElement}
            style={{ display: "none" }}
            key={element.id}
            setNotInputFocus={(status) => setNotInputFocus(status)}
            onStyleChange={(style_name, style_value) => {
              const updatingElement = [...baseElement];
              const elementIndex = updatingElement.findIndex(
                (child) => child.id === focusElement
              );

              updatingElement[elementIndex][style_name] = style_value;
              setBaseElement(updatingElement);
            }}
            setEditingInputWithArrow={(status) =>
              setEditingInputWithArrow(status)
            }
            onFocus={(elementId, event) => {
              if (elementId === null) {
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === elementId)
                );
                const section = sections[pageDesigning][sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === elementId
                );
                const focusedElement = section.children[elementIndex];

                setSelectedSection();
                setSelectedSectionIndex();

                setFocusElement(elementId);
                setFocusElementType(element.type);
                setFontWeight(focusedElement.fontWeight);
                setLineHeight(focusedElement.lineHeight);
                setLetterSpacing(focusedElement.letterSpacing);
                setTextAlign(focusedElement.textAlign);
                setFontSize(focusedElement.fontSize);
                setFocusWidth(focusedElement.width);
                setFocusHeight(focusedElement.height);
                handleShiftClick(event, element.id);
                setFocusBackgroundColor(focusedElement.backgroundColor);
                setFocusColor(focusedElement.color);
                setNotInputFocus(true);
                setFocusBorder(focusedElement.border);
                setFocusBoxShadow(focusedElement.shadow);
                setFocusOpacity(focusedElement.opacity);
                setFocusBorderRadius(focusedElement.borderRadius);
              }
            }}
            className={`canvas-element ${element.type}`}
            element={element}
            onFilterTermChange={(tags) => {
              console.log(tags);
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              if (sectionIndex === -1) {
                // Handle the case when the section is not found
                console.log("Section not found");
                return;
              }

              if (sectionIndex === -1) {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((red) =>
                      red.children.some((some) => some.id === element.id)
                    )
                );
                const boxIndex = sections[pageDesigning][
                  sectionIndex
                ].children.findIndex((ren) =>
                  ren.children.some((blue) => blue.id === element.id)
                );
                const section =
                  sections[pageDesigning][sectionIndex].children[boxIndex];
                const elementIndex = section.children.findIndex(
                  (red) => red.id === element.id
                );
                if (elementIndex === -1) {
                  // Handle the case when the element is not found in the section
                  console.log("Element not found in section");
                  return;
                }
                const updatedSections = [...sections[pageDesigning]];
                const updatedElements = [...section.children];
                // console.log(updatedElements[elementIndex])
                updatedElements[elementIndex].filter_tags = tags;
                updatedSections[sectionIndex] = section;
                setNewsSelectedFilters(tags);
              } else {
                // console.log("is not happening")
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...sections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === focusElement
                );
                if (elementIndex === -1) {
                  // Handle the case when the element is not found in the section
                  console.log("Element not found in section");
                  return;
                }
                const updatedElements = [...section.children];
                updatedElements[elementIndex].filter_tags = tags;
                updatedSections[sectionIndex] = section;
                setNewsSelectedFilters(tags);
              }
            }}
            onDrag={(event, elementId, offset, touch) => {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setNotInputFocus(true);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusBorderRadius(focusedElement.borderRadius);
              setFocusOpacity(focusedElement.opacity);
              handleDrag(
                event,
                elementId,
                offset,
                section.id,
                draggingSectionPositionY,
                touch
              );
            }}
            onDragStart={(event, elementId, touch) => {
              handleDragStart(event, element.id, section.id, touch);
            }}
            onDragEnd={handleDragEnd}
            onResizeStart={() => handleResizeStart(element.id)}
            onResize={(newWidth, newHeight) =>
              handleResize(newWidth, newHeight, element, section)
            }
            onResizeEnd={handleResizeEnd}
            ref={element.ref} // Assign the ref to the element
            onRefSet={(elementRef) => {
              if (mobileDesigning) {
                const sectionIndex = mobileSections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...mobileSections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...sections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              }
            }}
          />
        </div>
      );
    }
    if (element.type === "news-carousel-element") {
      return (
        <div
          style={{
            position: "absolute",
            left: mobileDesigning
              ? element.position.mx
                ? `${element.position.mx}%`
                : `${element.position.x}%`
              : `${element.position.x}%`,
            top: mobileDesigning
              ? element.position.my
                ? element.position.my
                : element.position.y
              : element.position.y,
            width: mobileDesigning
              ? element.mwidth
                ? (element.mwidth * 400) / 100
                : (element.width * 400) / 100
              : (element.width * viewportWidth) / 100,
            height: mobileDesigning
              ? element.mheight
                ? element.mheight
                : element.height
              : element.height,
            zIndex: selectedElements.includes(element.id)
              ? 501
              : focusElement === element.id
                ? 501
                : 499,
            // outline: focus ? "1px solid #5e86c4" : "none",
            display: null,
            // background: element.backgroundColor,
          }}
        >
          <NewsCarouselElement
            mobileDesigning={mobileDesigning}
            newsSelectedFilters={newsSelectedFilters}
            newsSearchTerm={newsSearchTerm}
            activeWindowStat={activeWindowStat}
            setActiveWindowStat={(something) => setActiveWindowStat(something)}
            selectedElements={selectedElements}
            setNotInputFocus={(status) => setNotInputFocus(status)}
            focusElement={focusElement}
            style={{ display: "none" }}
            key={element.id}
            onStyleChange={(style_name, style_value) => {
              const updatingElement = [...baseElement];
              const elementIndex = updatingElement.findIndex(
                (child) => child.id === focusElement
              );

              updatingElement[elementIndex][style_name] = style_value;
              setBaseElement(updatingElement);
            }}
            setEditingInputWithArrow={(status) =>
              setEditingInputWithArrow(status)
            }
            onFocus={(elementId, event) => {
              if (elementId === null) {
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === elementId)
                );
                const section = sections[pageDesigning][sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === elementId
                );
                const focusedElement = section.children[elementIndex];

                setSelectedSection();
                setSelectedSectionIndex();

                setFocusElement(elementId);
                setFocusElementType(element.type);
                setFontWeight(focusedElement.fontWeight);
                setLineHeight(focusedElement.lineHeight);
                setLetterSpacing(focusedElement.letterSpacing);
                setTextAlign(focusedElement.textAlign);
                setFontSize(focusedElement.fontSize);
                setFocusWidth(focusedElement.width);
                setFocusHeight(focusedElement.height);
                handleShiftClick(event, element.id);
                setFocusBackgroundColor(focusedElement.backgroundColor);
                setFocusColor(focusedElement.color);
                setNotInputFocus(true);
                setFocusBorder(focusedElement.border);
                setFocusBoxShadow(focusedElement.shadow);
                setFocusOpacity(focusedElement.opacity);
                setFocusBorderRadius(focusedElement.borderRadius);
              }
            }}
            className={`canvas-element ${element.type}`}
            element={element}
            onCarouselChange={(news_id) => {
              const updatingElement = [...baseElement];
              const elementIndex = updatingElement.findIndex(
                (child) => child.id === focusElement
              );

              updatingElement[elementIndex].news = news_id;
              setBaseElement(updatingElement);
            }}
            onDrag={(event, elementId, offset, touch) => {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setNotInputFocus(true);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusBorderRadius(focusedElement.borderRadius);
              setFocusOpacity(focusedElement.opacity);
              handleDrag(
                event,
                elementId,
                offset,
                section.id,
                draggingSectionPositionY,
                touch
              );
            }}
            onDragStart={(event, elementId, touch) => {
              handleDragStart(event, element.id, section.id, touch);
            }}
            onDragEnd={handleDragEnd}
            onResizeStart={() => handleResizeStart(element.id)}
            onResize={(newWidth, newHeight) =>
              handleResize(newWidth, newHeight, element, section)
            }
            onResizeEnd={handleResizeEnd}
            ref={element.ref} // Assign the ref to the element
            onRefSet={(elementRef) => {
              if (mobileDesigning) {
                const sectionIndex = mobileSections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...mobileSections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...sections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              }
            }}
          />
        </div>
      );
    }
    if (element.type === "product-search-element") {
      return (
        <div
          style={{
            position: "absolute",
            left: mobileDesigning
              ? element.position.mx
                ? `${element.position.mx}%`
                : `${element.position.x}%`
              : `${element.position.x}%`,
            top: mobileDesigning
              ? element.position.my
                ? element.position.my
                : element.position.y
              : element.position.y,
            width: mobileDesigning
              ? element.mwidth
                ? (element.mwidth * 400) / 100
                : (element.width * 400) / 100
              : (element.width * viewportWidth) / 100,
            height: mobileDesigning
              ? element.mheight
                ? element.mheight
                : element.height
              : element.height,
            zIndex: selectedElements.includes(element.id)
              ? 501
              : focusElement === element.id
                ? 501
                : 499,
            // outline: focus ? "1px solid #5e86c4" : "none",
            display: null,
            // background: element.backgroundColor,
          }}
        >
          <ProductSearchElement
            mobileDesigning={mobileDesigning}
            activeWindowStat={activeWindowStat}
            setActiveWindowStat={(something) => setActiveWindowStat(something)}
            selectedElements={selectedElements}
            focusElement={focusElement}
            style={{ display: "none" }}
            setNotInputFocus={(status) => setNotInputFocus(status)}
            key={element.id}
            onStyleChange={(style_name, style_value) => {
              const updatingElement = [...baseElement];
              const elementIndex = updatingElement.findIndex(
                (child) => child.id === focusElement
              );

              updatingElement[elementIndex][style_name] = style_value;
              setBaseElement(updatingElement);
            }}
            setEditingInputWithArrow={(status) =>
              setEditingInputWithArrow(status)
            }
            onFocus={(elementId, event) => {
              if (elementId === null) {
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === elementId)
                );
                const section = sections[pageDesigning][sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === elementId
                );
                const focusedElement = section.children[elementIndex];

                setSelectedSection();
                setSelectedSectionIndex();

                setFocusElement(elementId);
                setFocusElementType(element.type);
                setFontWeight(focusedElement.fontWeight);
                setLineHeight(focusedElement.lineHeight);
                setLetterSpacing(focusedElement.letterSpacing);
                setTextAlign(focusedElement.textAlign);
                setFontSize(focusedElement.fontSize);
                setFocusWidth(focusedElement.width);
                setFocusHeight(focusedElement.height);
                handleShiftClick(event, element.id);
                setFocusBackgroundColor(focusedElement.backgroundColor);
                setNotInputFocus(false);
                setFocusColor(focusedElement.color);
                setNotInputFocus(true);
                setFocusBorder(focusedElement.border);
                setFocusBoxShadow(focusedElement.shadow);
                setFocusOpacity(focusedElement.opacity);
                setFocusBorderRadius(focusedElement.borderRadius);
              }
            }}
            className={`canvas-element ${element.type}`}
            element={element}
            onSearchTermChange={(text) => {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              if (sectionIndex === -1) {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((red) =>
                      red.children.some((some) => some.id === element.id)
                    )
                );
                const boxIndex = sections[pageDesigning][
                  sectionIndex
                ].children.findIndex((ren) =>
                  ren.children.some((blue) => blue.id === element.id)
                );
                const section =
                  sections[pageDesigning][sectionIndex].children[boxIndex];
                const elementIndex = section.children.findIndex(
                  (red) => red.id === element.id
                );

                const updatedSections = [...sections[pageDesigning]];
                const updatedElements = [...section.children];
                // console.log(updatedElements[elementIndex])
                updatedElements[elementIndex].search_term = text;
                updatedSections[sectionIndex] = section;
                handleProductSearchTermChange(text);
              } else {
                // console.log("is not happening")
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...sections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === focusElement
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].search_term = text;
                updatedSections[sectionIndex] = section;
                handleProductSearchTermChange(text);
              }
            }}
            onDrag={(event, elementId, offset, touch) => {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setNotInputFocus(true);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusBorderRadius(focusedElement.borderRadius);
              setFocusOpacity(focusedElement.opacity);
              handleDrag(
                event,
                elementId,
                offset,
                section.id,
                draggingSectionPositionY,
                touch
              );
            }}
            onDragStart={(event, elementId, touch) => {
              handleDragStart(event, element.id, section.id, touch);
            }}
            onDragEnd={handleDragEnd}
            onResizeStart={() => handleResizeStart(element.id)}
            onResize={(newWidth, newHeight) =>
              handleResize(newWidth, newHeight, element, section)
            }
            onResizeEnd={handleResizeEnd}
            ref={element.ref} // Assign the ref to the element
            onRefSet={(elementRef) => {
              if (mobileDesigning) {
                const sectionIndex = mobileSections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...mobileSections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...sections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              }
            }}
          />
        </div>
      );
    }
    if (element.type === "product-filter-element") {
      return (
        <div
          style={{
            position: "absolute",
            left: mobileDesigning
              ? element.position.mx
                ? `${element.position.mx}%`
                : `${element.position.x}%`
              : `${element.position.x}%`,
            top: mobileDesigning
              ? element.position.my
                ? element.position.my
                : element.position.y
              : element.position.y,
            width: mobileDesigning
              ? element.mwidth
                ? (element.mwidth * 400) / 100
                : (element.width * 400) / 100
              : (element.width * viewportWidth) / 100,
            height: mobileDesigning
              ? element.mheight
                ? element.mheight
                : element.height
              : element.height,
            zIndex: selectedElements.includes(element.id)
              ? 501
              : focusElement === element.id
                ? 501
                : 499,
            // outline: focus ? "1px solid #5e86c4" : "none",
            display: null,
            // background: element.backgroundColor,
          }}
        >
          <ProductFilterElement
            mobileDesigning={mobileDesigning}
            activeWindowStat={activeWindowStat}
            setActiveWindowStat={(something) => setActiveWindowStat(something)}
            selectedElements={selectedElements}
            setNotInputFocus={(status) => setNotInputFocus(status)}
            focusElement={focusElement}
            style={{ display: "none" }}
            key={element.id}
            onStyleChange={(style_name, style_value) => {
              const updatingElement = [...baseElement];
              const elementIndex = updatingElement.findIndex(
                (child) => child.id === focusElement
              );

              updatingElement[elementIndex][style_name] = style_value;
              setBaseElement(updatingElement);
            }}
            setEditingInputWithArrow={(status) =>
              setEditingInputWithArrow(status)
            }
            onFocus={(elementId, event) => {
              if (elementId === null) {
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === elementId)
                );
                const section = sections[pageDesigning][sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === elementId
                );
                const focusedElement = section.children[elementIndex];

                setSelectedSection();
                setSelectedSectionIndex();

                setFocusElement(elementId);
                setFocusElementType(element.type);
                setFontWeight(focusedElement.fontWeight);
                setLineHeight(focusedElement.lineHeight);
                setLetterSpacing(focusedElement.letterSpacing);
                setTextAlign(focusedElement.textAlign);
                setFontSize(focusedElement.fontSize);
                setFocusWidth(focusedElement.width);
                setFocusHeight(focusedElement.height);
                handleShiftClick(event, element.id);
                setFocusBackgroundColor(focusedElement.backgroundColor);
                setFocusColor(focusedElement.color);
                setNotInputFocus(true);
                setFocusBorder(focusedElement.border);
                setFocusBoxShadow(focusedElement.shadow);
                setFocusOpacity(focusedElement.opacity);
                setFocusBorderRadius(focusedElement.borderRadius);
              }
            }}
            className={`canvas-element ${element.type}`}
            element={element}
            onFilterTermChange={(tags) => {
              console.log(tags);
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              if (sectionIndex === -1) {
                // Handle the case when the section is not found
                console.log("Section not found");
                return;
              }

              if (sectionIndex === -1) {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((red) =>
                      red.children.some((some) => some.id === element.id)
                    )
                );
                const boxIndex = sections[pageDesigning][
                  sectionIndex
                ].children.findIndex((ren) =>
                  ren.children.some((blue) => blue.id === element.id)
                );
                const section =
                  sections[pageDesigning][sectionIndex].children[boxIndex];
                const elementIndex = section.children.findIndex(
                  (red) => red.id === element.id
                );
                if (elementIndex === -1) {
                  // Handle the case when the element is not found in the section
                  console.log("Element not found in section");
                  return;
                }
                const updatedSections = [...sections[pageDesigning]];
                const updatedElements = [...section.children];
                // console.log(updatedElements[elementIndex])
                updatedElements[elementIndex].filter_tags = tags;
                updatedSections[sectionIndex] = section;
                setProductSelectedFilters(tags);
              } else {
                // console.log("is not happening")
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...sections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === focusElement
                );
                if (elementIndex === -1) {
                  // Handle the case when the element is not found in the section
                  console.log("Element not found in section");
                  return;
                }
                const updatedElements = [...section.children];
                updatedElements[elementIndex].filter_tags = tags;
                updatedSections[sectionIndex] = section;
                setProductSelectedFilters(tags);
              }
            }}
            onDrag={(event, elementId, offset, touch) => {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setNotInputFocus(true);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusBorderRadius(focusedElement.borderRadius);
              setFocusOpacity(focusedElement.opacity);
              handleDrag(
                event,
                elementId,
                offset,
                section.id,
                draggingSectionPositionY,
                touch
              );
            }}
            onDragStart={(event, elementId, touch) => {
              handleDragStart(event, element.id, section.id, touch);
            }}
            onDragEnd={handleDragEnd}
            onResizeStart={() => handleResizeStart(element.id)}
            onResize={(newWidth, newHeight) =>
              handleResize(newWidth, newHeight, element, section)
            }
            onResizeEnd={handleResizeEnd}
            ref={element.ref} // Assign the ref to the element
            onRefSet={(elementRef) => {
              if (mobileDesigning) {
                const sectionIndex = mobileSections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...mobileSections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...sections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              }
            }}
          />
        </div>
      );
    }
    if (element.type === "product-carousel-element") {
      return (
        <div
          style={{
            position: "absolute",
            left: mobileDesigning
              ? element.position.mx
                ? `${element.position.mx}%`
                : `${element.position.x}%`
              : `${element.position.x}%`,
            top: mobileDesigning
              ? element.position.my
                ? element.position.my
                : element.position.y
              : element.position.y,
            width: mobileDesigning
              ? element.mwidth
                ? (element.mwidth * 400) / 100
                : (element.width * 400) / 100
              : (element.width * viewportWidth) / 100,
            height: mobileDesigning
              ? element.mheight
                ? element.mheight
                : element.height
              : element.height,
            zIndex: selectedElements.includes(element.id)
              ? 501
              : focusElement === element.id
                ? 501
                : 499,
            // outline: focus ? "1px solid #5e86c4" : "none",
            display: null,
            // background: element.backgroundColor,
          }}
        >
          <ProductCarouselElement
            customerToken={customerToken}
            currency={currency}
            setCustomerToken={(stateUpdate) => setCustomerToken(stateUpdate)}
            customerAccountDetails={customerAccountDetails}
            setCustomerAccountDetails={(stateUpdate) =>
              setCustomerAccountDetails(stateUpdate)
            }
            mobileDesigning={mobileDesigning}
            productSelectedFilters={productSelectedFilters}
            productSearchTerm={productSearchTerm}
            setNotInputFocus={(status) => setNotInputFocus(status)}
            activeWindowStat={activeWindowStat}
            setActiveWindowStat={(something) => setActiveWindowStat(something)}
            selectedElements={selectedElements}
            focusElement={focusElement}
            onStyleChange={(style_name, style_value) => {
              const updatingElement = [...baseElement];
              const elementIndex = updatingElement.findIndex(
                (child) => child.id === focusElement
              );

              updatingElement[elementIndex][style_name] = style_value;
              setBaseElement(updatingElement);
            }}
            style={{ display: "none" }}
            key={element.id}
            setEditingInputWithArrow={(status) =>
              setEditingInputWithArrow(status)
            }
            onFocus={(elementId, event) => {
              if (elementId === null) {
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === elementId)
                );
                const section = sections[pageDesigning][sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === elementId
                );
                const focusedElement = section.children[elementIndex];

                setSelectedSection();
                setSelectedSectionIndex();

                setFocusElement(elementId);
                setFocusElementType(element.type);
                setFontWeight(focusedElement.fontWeight);
                setLineHeight(focusedElement.lineHeight);
                setLetterSpacing(focusedElement.letterSpacing);
                setTextAlign(focusedElement.textAlign);
                setFontSize(focusedElement.fontSize);
                setFocusWidth(focusedElement.width);
                setFocusHeight(focusedElement.height);
                handleShiftClick(event, element.id);
                setFocusBackgroundColor(focusedElement.backgroundColor);
                setFocusColor(focusedElement.color);
                setNotInputFocus(true);
                setFocusBorder(focusedElement.border);
                setFocusBoxShadow(focusedElement.shadow);
                setFocusOpacity(focusedElement.opacity);
                setFocusBorderRadius(focusedElement.borderRadius);
              }
            }}
            className={`canvas-element ${element.type}`}
            element={element}
            onCarouselChange={(news_id) => {
              const updatingElement = [...baseElement];
              const elementIndex = updatingElement.findIndex(
                (child) => child.id === focusElement
              );

              updatingElement[elementIndex].products = news_id;
              setBaseElement(updatingElement);
            }}
            onDrag={(event, elementId, offset, touch) => {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setNotInputFocus(true);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusBorderRadius(focusedElement.borderRadius);
              setFocusOpacity(focusedElement.opacity);
              handleDrag(
                event,
                elementId,
                offset,
                section.id,
                draggingSectionPositionY,
                touch
              );
            }}
            onDragStart={(event, elementId, touch) => {
              handleDragStart(event, element.id, section.id, touch);
            }}
            onDragEnd={handleDragEnd}
            onResizeStart={() => handleResizeStart(element.id)}
            onResize={(newWidth, newHeight) =>
              handleResize(newWidth, newHeight, element, section)
            }
            onResizeEnd={handleResizeEnd}
            ref={element.ref} // Assign the ref to the element
            onRefSet={(elementRef) => {
              if (mobileDesigning) {
                const sectionIndex = mobileSections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...mobileSections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...sections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              }
            }}
          />
        </div>
      );
    }
    if (element.type === "event-search-element") {
      return (
        <div
          style={{
            position: "absolute",
            left: mobileDesigning
              ? element.position.mx
                ? `${element.position.mx}%`
                : `${element.position.x}%`
              : `${element.position.x}%`,
            top: mobileDesigning
              ? element.position.my
                ? element.position.my
                : element.position.y
              : element.position.y,
            width: mobileDesigning
              ? element.mwidth
                ? (element.mwidth * 400) / 100
                : (element.width * 400) / 100
              : (element.width * viewportWidth) / 100,
            height: mobileDesigning
              ? element.mheight
                ? element.mheight
                : element.height
              : element.height,
            zIndex: selectedElements.includes(element.id)
              ? 501
              : focusElement === element.id
                ? 501
                : 499,
            // outline: focus ? "1px solid #5e86c4" : "none",
            display: null,
            // background: element.backgroundColor,
          }}
        >
          <EventsSearchElement
            mobileDesigning={mobileDesigning}
            activeWindowStat={activeWindowStat}
            setActiveWindowStat={(something) => setActiveWindowStat(something)}
            selectedElements={selectedElements}
            focusElement={focusElement}
            style={{ display: "none" }}
            setNotInputFocus={(status) => setNotInputFocus(status)}
            key={element.id}
            onStyleChange={(style_name, style_value) => {
              const updatingElement = [...baseElement];
              const elementIndex = updatingElement.findIndex(
                (child) => child.id === focusElement
              );

              updatingElement[elementIndex][style_name] = style_value;
              setBaseElement(updatingElement);
            }}
            setEditingInputWithArrow={(status) =>
              setEditingInputWithArrow(status)
            }
            onFocus={(elementId, event) => {
              if (elementId === null) {
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === elementId)
                );
                const section = sections[pageDesigning][sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === elementId
                );
                const focusedElement = section.children[elementIndex];

                setSelectedSection();
                setSelectedSectionIndex();

                setFocusElement(elementId);
                setFocusElementType(element.type);
                setFontWeight(focusedElement.fontWeight);
                setLineHeight(focusedElement.lineHeight);
                setLetterSpacing(focusedElement.letterSpacing);
                setTextAlign(focusedElement.textAlign);
                setFontSize(focusedElement.fontSize);
                setFocusWidth(focusedElement.width);
                setFocusHeight(focusedElement.height);
                handleShiftClick(event, element.id);
                setFocusBackgroundColor(focusedElement.backgroundColor);
                setNotInputFocus(false);
                setFocusColor(focusedElement.color);
                setNotInputFocus(true);
                setFocusBorder(focusedElement.border);
                setFocusBoxShadow(focusedElement.shadow);
                setFocusOpacity(focusedElement.opacity);
                setFocusBorderRadius(focusedElement.borderRadius);
              }
            }}
            className={`canvas-element ${element.type}`}
            element={element}
            onSearchTermChange={(text) => {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              if (sectionIndex === -1) {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((red) =>
                      red.children.some((some) => some.id === element.id)
                    )
                );
                const boxIndex = sections[pageDesigning][
                  sectionIndex
                ].children.findIndex((ren) =>
                  ren.children.some((blue) => blue.id === element.id)
                );
                const section =
                  sections[pageDesigning][sectionIndex].children[boxIndex];
                const elementIndex = section.children.findIndex(
                  (red) => red.id === element.id
                );

                const updatedSections = [...sections[pageDesigning]];
                const updatedElements = [...section.children];
                // console.log(updatedElements[elementIndex])
                updatedElements[elementIndex].search_term = text;
                updatedSections[sectionIndex] = section;
                handleEventSearchTermChange(text);
              } else {
                // console.log("is not happening")
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...sections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === focusElement
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].search_term = text;
                updatedSections[sectionIndex] = section;
                handleEventSearchTermChange(text);
              }
            }}
            onDrag={(event, elementId, offset, touch) => {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setNotInputFocus(true);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusBorderRadius(focusedElement.borderRadius);
              setFocusOpacity(focusedElement.opacity);
              handleDrag(
                event,
                elementId,
                offset,
                section.id,
                draggingSectionPositionY,
                touch
              );
            }}
            onDragStart={(event, elementId, touch) => {
              handleDragStart(event, element.id, section.id, touch);
            }}
            onDragEnd={handleDragEnd}
            onResizeStart={() => handleResizeStart(element.id)}
            onResize={(newWidth, newHeight) =>
              handleResize(newWidth, newHeight, element, section)
            }
            onResizeEnd={handleResizeEnd}
            ref={element.ref} // Assign the ref to the element
            onRefSet={(elementRef) => {
              if (mobileDesigning) {
                const sectionIndex = mobileSections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...mobileSections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...sections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              }
            }}
          />
        </div>
      );
    }
    if (element.type === "event-filter-element") {
      return (
        <div
          style={{
            position: "absolute",
            left: mobileDesigning
              ? element.position.mx
                ? `${element.position.mx}%`
                : `${element.position.x}%`
              : `${element.position.x}%`,
            top: mobileDesigning
              ? element.position.my
                ? element.position.my
                : element.position.y
              : element.position.y,
            width: mobileDesigning
              ? element.mwidth
                ? (element.mwidth * 400) / 100
                : (element.width * 400) / 100
              : (element.width * viewportWidth) / 100,
            height: mobileDesigning
              ? element.mheight
                ? element.mheight
                : element.height
              : element.height,
            zIndex: selectedElements.includes(element.id)
              ? 501
              : focusElement === element.id
                ? 501
                : 499,
            // outline: focus ? "1px solid #5e86c4" : "none",
            display: null,
            // background: element.backgroundColor,
          }}
        >
          <EventsFilterElement
            mobileDesigning={mobileDesigning}
            activeWindowStat={activeWindowStat}
            setActiveWindowStat={(something) => setActiveWindowStat(something)}
            selectedElements={selectedElements}
            focusElement={focusElement}
            setNotInputFocus={(status) => setNotInputFocus(status)}
            style={{ display: "none" }}
            onStyleChange={(style_name, style_value) => {
              const updatingElement = [...baseElement];
              const elementIndex = updatingElement.findIndex(
                (child) => child.id === focusElement
              );

              updatingElement[elementIndex][style_name] = style_value;
              setBaseElement(updatingElement);
            }}
            key={element.id}
            setEditingInputWithArrow={(status) =>
              setEditingInputWithArrow(status)
            }
            onFocus={(elementId, event) => {
              if (elementId === null) {
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === elementId)
                );
                const section = sections[pageDesigning][sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === elementId
                );
                const focusedElement = section.children[elementIndex];

                setSelectedSection();
                setSelectedSectionIndex();

                setFocusElement(elementId);
                setFocusElementType(element.type);
                setFontWeight(focusedElement.fontWeight);
                setLineHeight(focusedElement.lineHeight);
                setLetterSpacing(focusedElement.letterSpacing);
                setTextAlign(focusedElement.textAlign);
                setFontSize(focusedElement.fontSize);
                setFocusWidth(focusedElement.width);
                setFocusHeight(focusedElement.height);
                handleShiftClick(event, element.id);
                setFocusBackgroundColor(focusedElement.backgroundColor);
                setFocusColor(focusedElement.color);
                setNotInputFocus(true);
                setFocusBorder(focusedElement.border);
                setFocusBoxShadow(focusedElement.shadow);
                setFocusOpacity(focusedElement.opacity);
                setFocusBorderRadius(focusedElement.borderRadius);
              }
            }}
            className={`canvas-element ${element.type}`}
            element={element}
            onFilterTermChange={(tags) => {
              console.log(tags);
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              if (sectionIndex === -1) {
                // Handle the case when the section is not found
                console.log("Section not found");
                return;
              }

              if (sectionIndex === -1) {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((red) =>
                      red.children.some((some) => some.id === element.id)
                    )
                );
                const boxIndex = sections[pageDesigning][
                  sectionIndex
                ].children.findIndex((ren) =>
                  ren.children.some((blue) => blue.id === element.id)
                );
                const section =
                  sections[pageDesigning][sectionIndex].children[boxIndex];
                const elementIndex = section.children.findIndex(
                  (red) => red.id === element.id
                );
                if (elementIndex === -1) {
                  // Handle the case when the element is not found in the section
                  console.log("Element not found in section");
                  return;
                }
                const updatedSections = [...sections[pageDesigning]];
                const updatedElements = [...section.children];
                // console.log(updatedElements[elementIndex])
                updatedElements[elementIndex].filter_tags = tags;
                updatedSections[sectionIndex] = section;
                setEventSelectedFilters(tags);
              } else {
                // console.log("is not happening")
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...sections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === focusElement
                );
                if (elementIndex === -1) {
                  // Handle the case when the element is not found in the section
                  console.log("Element not found in section");
                  return;
                }
                const updatedElements = [...section.children];
                updatedElements[elementIndex].filter_tags = tags;
                updatedSections[sectionIndex] = section;
                setEventSelectedFilters(tags);
              }
            }}
            onDrag={(event, elementId, offset, touch) => {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setNotInputFocus(true);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusBorderRadius(focusedElement.borderRadius);
              setFocusOpacity(focusedElement.opacity);
              handleDrag(
                event,
                elementId,
                offset,
                section.id,
                draggingSectionPositionY,
                touch
              );
            }}
            onDragStart={(event, elementId, touch) => {
              handleDragStart(event, element.id, section.id, touch);
            }}
            onDragEnd={handleDragEnd}
            onResizeStart={() => handleResizeStart(element.id)}
            onResize={(newWidth, newHeight) =>
              handleResize(newWidth, newHeight, element, section)
            }
            onResizeEnd={handleResizeEnd}
            ref={element.ref} // Assign the ref to the element
            onRefSet={(elementRef) => {
              if (mobileDesigning) {
                const sectionIndex = mobileSections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...mobileSections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...sections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              }
            }}
          />
        </div>
      );
    }
    if (element.type === "event-carousel-element") {
      return (
        <div
          style={{
            position: "absolute",
            left: mobileDesigning
              ? element.position.mx
                ? `${element.position.mx}%`
                : `${element.position.x}%`
              : `${element.position.x}%`,
            top: mobileDesigning
              ? element.position.my
                ? element.position.my
                : element.position.y
              : element.position.y,
            width: mobileDesigning
              ? element.mwidth
                ? (element.mwidth * 400) / 100
                : (element.width * 400) / 100
              : (element.width * viewportWidth) / 100,
            height: mobileDesigning
              ? element.mheight
                ? element.mheight
                : element.height
              : element.height,
            zIndex: selectedElements.includes(element.id)
              ? 501
              : focusElement === element.id
                ? 501
                : 499,
            // outline: focus ? "1px solid #5e86c4" : "none",
            display: null,
            // background: element.backgroundColor,
          }}
        >
          <EventsCarouselElement
            mobileDesigning={mobileDesigning}
            eventSelectedFilters={eventSelectedFilters}
            eventSearchTerm={eventSearchTerm}
            setNotInputFocus={(status) => setNotInputFocus(status)}
            activeWindowStat={activeWindowStat}
            setActiveWindowStat={(something) => setActiveWindowStat(something)}
            selectedElements={selectedElements}
            focusElement={focusElement}
            style={{ display: "none" }}
            onStyleChange={(style_name, style_value) => {
              const updatingElement = [...baseElement];
              const elementIndex = updatingElement.findIndex(
                (child) => child.id === focusElement
              );

              updatingElement[elementIndex][style_name] = style_value;
              setBaseElement(updatingElement);
            }}
            key={element.id}
            setEditingInputWithArrow={(status) =>
              setEditingInputWithArrow(status)
            }
            onFocus={(elementId, event) => {
              if (elementId === null) {
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === elementId)
                );
                const section = sections[pageDesigning][sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === elementId
                );
                const focusedElement = section.children[elementIndex];

                setSelectedSection();
                setSelectedSectionIndex();

                setFocusElement(elementId);
                setFocusElementType(element.type);
                setFontWeight(focusedElement.fontWeight);
                setLineHeight(focusedElement.lineHeight);
                setLetterSpacing(focusedElement.letterSpacing);
                setTextAlign(focusedElement.textAlign);
                setFontSize(focusedElement.fontSize);
                setFocusWidth(focusedElement.width);
                setFocusHeight(focusedElement.height);
                handleShiftClick(event, element.id);
                setFocusBackgroundColor(focusedElement.backgroundColor);
                setFocusColor(focusedElement.color);
                setNotInputFocus(true);
                setFocusBorder(focusedElement.border);
                setFocusBoxShadow(focusedElement.shadow);
                setFocusOpacity(focusedElement.opacity);
                setFocusBorderRadius(focusedElement.borderRadius);
              }
            }}
            className={`canvas-element ${element.type}`}
            element={element}
            onCarouselChange={(news_id) => {
              const updatingElement = [...baseElement];
              const elementIndex = updatingElement.findIndex(
                (child) => child.id === focusElement
              );

              updatingElement[elementIndex].events = news_id;
              setBaseElement(updatingElement);
            }}
            onDrag={(event, elementId, offset, touch) => {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setNotInputFocus(true);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusBorderRadius(focusedElement.borderRadius);
              setFocusOpacity(focusedElement.opacity);
              handleDrag(
                event,
                elementId,
                offset,
                section.id,
                draggingSectionPositionY,
                touch
              );
            }}
            onDragStart={(event, elementId, touch) => {
              handleDragStart(event, element.id, section.id, touch);
            }}
            onDragEnd={handleDragEnd}
            onResizeStart={() => handleResizeStart(element.id)}
            onResize={(newWidth, newHeight) =>
              handleResize(newWidth, newHeight, element, section)
            }
            onResizeEnd={handleResizeEnd}
            ref={element.ref} // Assign the ref to the element
            onRefSet={(elementRef) => {
              if (mobileDesigning) {
                const sectionIndex = mobileSections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...mobileSections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...sections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              }
            }}
          />
        </div>
      );
    }
    if (element.type === "form-element") {
      return (
        <div
          style={{
            position: "absolute",
            left: mobileDesigning
              ? element.position.mx
                ? `${element.position.mx}%`
                : `${element.position.x}%`
              : `${element.position.x}%`,
            top: mobileDesigning
              ? element.position.my
                ? element.position.my
                : element.position.y
              : element.position.y,
            width: mobileDesigning
              ? element.mwidth
                ? (element.mwidth * 400) / 100
                : (element.width * 400) / 100
              : (element.width * viewportWidth) / 100,
            height: mobileDesigning
              ? element.mheight
                ? element.mheight
                : element.height
              : element.height,
            zIndex: selectedElements.includes(element.id)
              ? 501
              : focusElement === element.id
                ? 501
                : 499,
            // outline: focus ? "1px solid #5e86c4" : "none",
            display: null,
            // background: element.backgroundColor,
          }}
        >
          <FormElement
            mobileDesigning={mobileDesigning}
            activeWindowStat={activeWindowStat}
            setActiveWindowStat={(something) => setActiveWindowStat(something)}
            selectedElements={selectedElements}
            focusElement={focusElement}
            setNotInputFocus={(status) => {
              setNotInputFocus(status);
              console.log(status);
            }}
            style={{ display: "none" }}
            onStyleChange={(style_name, style_value) => {
              const updatingElement = [...baseElement];
              const elementIndex = updatingElement.findIndex(
                (child) => child.id === focusElement
              );

              updatingElement[elementIndex][style_name] = style_value;
              setBaseElement(updatingElement);
            }}
            key={element.id}
            setEditingInputWithArrow={(status) =>
              setEditingInputWithArrow(status)
            }
            onFocus={(elementId, event) => {
              if (elementId === null) {
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === elementId)
                );
                const section = sections[pageDesigning][sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === elementId
                );
                const focusedElement = section.children[elementIndex];

                setSelectedSection();
                setSelectedSectionIndex();

                setFocusElement(elementId);
                setFocusElementType(element.type);
                setFontWeight(focusedElement.fontWeight);
                setLineHeight(focusedElement.lineHeight);
                setLetterSpacing(focusedElement.letterSpacing);
                setTextAlign(focusedElement.textAlign);
                setFontSize(focusedElement.fontSize);
                setFocusWidth(focusedElement.width);
                setFocusHeight(focusedElement.height);
                handleShiftClick(event, element.id);
                setFocusBackgroundColor(focusedElement.backgroundColor);
                setFocusColor(focusedElement.color);
                setNotInputFocus(true);
                setFocusBorder(focusedElement.border);
                setFocusBoxShadow(focusedElement.shadow);
                setFocusOpacity(focusedElement.opacity);
                setFocusBorderRadius(focusedElement.borderRadius);
              }
            }}
            className={`canvas-element ${element.type}`}
            element={element}
            onFormChange={(form) => {
              const updatingElement = [...baseElement];
              const elementIndex = updatingElement.findIndex(
                (child) => child.id === focusElement
              );

              updatingElement[elementIndex].form = form;
              setBaseElement(updatingElement);
            }}
            onDrag={(event, elementId, offset, touch) => {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setNotInputFocus(true);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusBorderRadius(focusedElement.borderRadius);
              setFocusOpacity(focusedElement.opacity);
              handleDrag(
                event,
                elementId,
                offset,
                section.id,
                draggingSectionPositionY,
                touch
              );
            }}
            onDragStart={(event, elementId, touch) => {
              handleDragStart(event, element.id, section.id, touch);
            }}
            onDragEnd={handleDragEnd}
            onResizeStart={() => handleResizeStart(element.id)}
            onResize={(newWidth, newHeight) =>
              handleResize(newWidth, newHeight, element, section)
            }
            onResizeEnd={handleResizeEnd}
            ref={element.ref} // Assign the ref to the element
            onRefSet={(elementRef) => {
              if (mobileDesigning) {
                const sectionIndex = mobileSections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...mobileSections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...sections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              }
            }}
          />
        </div>
      );
    }
    if (element.type === "product-item-element") {
      return (
        <div
          style={{
            position: "absolute",
            left: mobileDesigning
              ? element.position.mx
                ? `${element.position.mx}%`
                : `${element.position.x}%`
              : `${element.position.x}%`,
            top: mobileDesigning
              ? element.position.my
                ? element.position.my
                : element.position.y
              : element.position.y,
            width: mobileDesigning
              ? element.mwidth
                ? (element.mwidth * 400) / 100
                : (element.width * 400) / 100
              : (element.width * viewportWidth) / 100,
            height: mobileDesigning
              ? element.mheight
                ? element.mheight
                : element.height
              : element.height,
            zIndex: selectedElements.includes(element.id)
              ? 501
              : focusElement === element.id
                ? 501
                : 499,
            // outline: focus ? "1px solid #5e86c4" : "none",
            display: null,
            // background: element.backgroundColor,
          }}
        >
          <ProductElement
            customerToken={customerToken}
            currency={currency}
            setCustomerToken={(stateUpdate) => setCustomerToken(stateUpdate)}
            customerAccountDetails={customerAccountDetails}
            setCustomerAccountDetails={(stateUpdate) =>
              setCustomerAccountDetails(stateUpdate)
            }
            mobileDesigning={mobileDesigning}
            activeWindowStat={activeWindowStat}
            setActiveWindowStat={(something) => setActiveWindowStat(something)}
            selectedElements={selectedElements}
            focusElement={focusElement}
            setNotInputFocus={(status) => setNotInputFocus(status)}
            style={{ display: "none" }}
            onStyleChange={(style_name, style_value) => {
              const updatingElement = [...baseElement];
              const elementIndex = updatingElement.findIndex(
                (child) => child.id === focusElement
              );

              updatingElement[elementIndex][style_name] = style_value;
              setBaseElement(updatingElement);
            }}
            key={element.id}
            setEditingInputWithArrow={(status) =>
              setEditingInputWithArrow(status)
            }
            onFocus={(elementId, event) => {
              if (elementId === null) {
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === elementId)
                );
                const section = sections[pageDesigning][sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === elementId
                );
                const focusedElement = section.children[elementIndex];

                setSelectedSection();
                setSelectedSectionIndex();

                setFocusElement(elementId);
                setFocusElementType(element.type);
                setFontWeight(focusedElement.fontWeight);
                setLineHeight(focusedElement.lineHeight);
                setLetterSpacing(focusedElement.letterSpacing);
                setTextAlign(focusedElement.textAlign);
                setFontSize(focusedElement.fontSize);
                setFocusWidth(focusedElement.width);
                setFocusHeight(focusedElement.height);
                handleShiftClick(event, element.id);
                setFocusBackgroundColor(focusedElement.backgroundColor);
                setFocusColor(focusedElement.color);
                setNotInputFocus(true);
                setFocusBorder(focusedElement.border);
                setFocusBoxShadow(focusedElement.shadow);
                setFocusOpacity(focusedElement.opacity);
                setFocusBorderRadius(focusedElement.borderRadius);
              }
            }}
            className={`canvas-element ${element.type}`}
            element={element}
            onProductChange={(product_sku) => {
              const updatingElement = [...baseElement];
              const elementIndex = updatingElement.findIndex(
                (child) => child.id === focusElement
              );

              updatingElement[elementIndex].product_sku = product_sku;
              setBaseElement(updatingElement);
            }}
            onDrag={(event, elementId, offset, touch) => {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setNotInputFocus(true);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusBorderRadius(focusedElement.borderRadius);
              setFocusOpacity(focusedElement.opacity);
              handleDrag(
                event,
                elementId,
                offset,
                section.id,
                draggingSectionPositionY,
                touch
              );
            }}
            onDragStart={(event, elementId, touch) => {
              handleDragStart(event, element.id, section.id, touch);
            }}
            onDragEnd={handleDragEnd}
            onResizeStart={() => handleResizeStart(element.id)}
            onResize={(newWidth, newHeight) =>
              handleResize(newWidth, newHeight, element, section)
            }
            onResizeEnd={handleResizeEnd}
            ref={element.ref} // Assign the ref to the element
            onRefSet={(elementRef) => {
              if (mobileDesigning) {
                const sectionIndex = mobileSections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...mobileSections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...sections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              }
            }}
          />
        </div>
      );
    }
    if (element.type === "box-element") {
      return (
        <div
          style={{
            position: "absolute",
            left: mobileDesigning
              ? element.position.mx
                ? `${element.position.mx}%`
                : `${element.position.x}%`
              : `${element.position.x}%`,
            top: mobileDesigning
              ? element.position.my
                ? element.position.my
                : element.position.y
              : element.position.y,
            width: mobileDesigning
              ? element.mwidth
                ? (element.mwidth * 400) / 100
                : (element.width * 400) / 100
              : (element.width * viewportWidth) / 100,
            height: mobileDesigning
              ? element.mheight
                ? element.mheight
                : element.height
              : element.height,
            zIndex: selectedElements.includes(element.id)
              ? 501
              : focusElement === element.id
                ? 501
                : 499,
            // outline: focus ? "1px solid #5e86c4" : "none",
            display: null,
            // background: element.backgroundColor,
          }}
        >
          <BoxElement
            mobileDesigning={mobileDesigning}
            activeWindowStat={activeWindowStat}
            setActiveWindowStat={(something) => setActiveWindowStat(something)}
            selectedElements={selectedElements}
            focusElement={focusElement}
            style={{ display: "none" }}
            setNotInputFocus={(status) => setNotInputFocus(status)}
            onStyleChange={(style_name, style_value) => {
              const updatingElement = [...baseElement];
              const elementIndex = updatingElement.findIndex(
                (child) => child.id === focusElement
              );

              updatingElement[elementIndex][style_name] = style_value;
              setBaseElement(updatingElement);
            }}
            key={element.id}
            setEditingInputWithArrow={(status) =>
              setEditingInputWithArrow(status)
            }
            onFocus={(elementId, event) => {
              if (elementId === null) {
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === elementId)
                );
                const section = sections[pageDesigning][sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === elementId
                );
                const focusedElement = section.children[elementIndex];

                setSelectedSection();
                setSelectedSectionIndex();

                setFocusElement(elementId);
                setFocusElementType(element.type);
                setFontWeight(focusedElement.fontWeight);
                setLineHeight(focusedElement.lineHeight);
                setLetterSpacing(focusedElement.letterSpacing);
                setTextAlign(focusedElement.textAlign);
                setFontSize(focusedElement.fontSize);
                setFocusWidth(focusedElement.width);
                setFocusHeight(focusedElement.height);
                handleShiftClick(event, element.id);
                setFocusBackgroundColor(focusedElement.backgroundColor);
                setFocusColor(focusedElement.color);
                setNotInputFocus(true);
                setFocusBorder(focusedElement.border);
                setFocusBoxShadow(focusedElement.shadow);
                setFocusOpacity(focusedElement.opacity);
                setFocusBorderRadius(focusedElement.borderRadius);
              }
            }}
            className={`canvas-element ${element.type}`}
            element={element}
            onContentChange={(content) => onContentChange(content, element.id)}
            onDrag={(event, elementId, offset, touch) => {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setNotInputFocus(true);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusBorderRadius(focusedElement.borderRadius);
              setFocusOpacity(focusedElement.opacity);
              handleDrag(
                event,
                elementId,
                offset,
                section.id,
                draggingSectionPositionY,
                touch
              );
            }}
            onDragStart={(event, elementId, touch) => {
              handleDragStart(event, element.id, section.id, touch);
            }}
            onDragEnd={handleDragEnd}
            onResizeStart={() => handleResizeStart(element.id)}
            onResize={(newWidth, newHeight) =>
              handleResize(newWidth, newHeight, element, section)
            }
            onResizeEnd={handleResizeEnd}
            ref={element.ref} // Assign the ref to the element
            onRefSet={(elementRef) => {
              if (mobileDesigning) {
                const sectionIndex = mobileSections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...mobileSections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...sections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              }
            }}
          />
        </div>
      );
    }
    if (element.type === "shape-element") {
      return (
        <div
          style={{
            position: "absolute",
            left: mobileDesigning
              ? element.position.mx
                ? `${element.position.mx}%`
                : `${element.position.x}%`
              : `${element.position.x}%`,
            top: mobileDesigning
              ? element.position.my
                ? element.position.my
                : element.position.y
              : element.position.y,
            width: mobileDesigning
              ? element.mwidth
                ? (element.mwidth * 400) / 100
                : (element.width * 400) / 100
              : (element.width * viewportWidth) / 100,
            height: mobileDesigning
              ? element.mheight
                ? element.mheight
                : element.height
              : element.height,
            zIndex: selectedElements.includes(element.id)
              ? 501
              : focusElement === element.id
                ? 501
                : 499,
            // outline: focus ? "1px solid #5e86c4" : "none",
            display: null,
            // background: element.backgroundColor,
            // background: "red"
          }}
        >
          <ShapeElement
            scrollTop={scrollTop}
            mobileDesigning={mobileDesigning}
            activeWindowStat={activeWindowStat}
            setActiveTools={(something) => { console.log(something); setActiveTools(something) }}
            setActiveWindowStat={(something) => setActiveWindowStat(something)}
            selectedElements={selectedElements}
            focusElement={focusElement}
            style={{ display: "none" }}
            setNotInputFocus={(status) => setNotInputFocus(status)}
            onStyleChange={(style_name, style_value) => {
              const updatingElement = [...baseElement];
              const elementIndex = updatingElement.findIndex(
                (child) => child.id === focusElement
              );

              updatingElement[elementIndex][style_name] = style_value;
              setBaseElement(updatingElement);
            }}
            key={element.id}
            setEditingInputWithArrow={(status) =>
              setEditingInputWithArrow(status)
            }
            onFocus={(elementId, event) => {
              if (elementId === null) {
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === elementId)
                );
                const section = sections[pageDesigning][sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === elementId
                );
                const focusedElement = section.children[elementIndex];

                setSelectedSection();
                setSelectedSectionIndex();

                setFocusElement(elementId);
                setFocusElementType(element.type);
                setFontWeight(focusedElement.fontWeight);
                setLineHeight(focusedElement.lineHeight);
                setLetterSpacing(focusedElement.letterSpacing);
                setTextAlign(focusedElement.textAlign);
                setFontSize(focusedElement.fontSize);
                setFocusWidth(focusedElement.width);
                setFocusHeight(focusedElement.height);
                handleShiftClick(event, element.id);
                setFocusBackgroundColor(focusedElement.backgroundColor);
                setFocusColor(focusedElement.color);
                setNotInputFocus(true);
                setFocusBorder(focusedElement.border);
                setFocusBoxShadow(focusedElement.shadow);
                setFocusOpacity(focusedElement.opacity);
                setFocusBorderRadius(focusedElement.borderRadius);
              }
            }}
            className={`canvas-element ${element.type}`}
            element={element}
            onContentChange={(content) => onContentChange(content, element.id)}
            onDrag={(event, elementId, offset, touch) => {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setNotInputFocus(true);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusBorderRadius(focusedElement.borderRadius);
              setFocusOpacity(focusedElement.opacity);
              handleDrag(
                event,
                elementId,
                offset,
                section.id,
                draggingSectionPositionY,
                touch
              );
            }}
            onDragStart={(event, elementId, touch) => {
              handleDragStart(event, element.id, section.id, touch);
            }}
            onDragEnd={handleDragEnd}
            onResizeStart={() => handleResizeStart(element.id)}
            onResize={(newWidth, newHeight) =>
              handleResize(newWidth, newHeight, element, section)
            }
            onResizeEnd={handleResizeEnd}
            ref={element.ref} // Assign the ref to the element
            onRefSet={(elementRef) => {
              if (mobileDesigning) {
                const sectionIndex = mobileSections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...mobileSections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              } else {
                const sectionIndex = sections[pageDesigning].findIndex(
                  (section) =>
                    section.children.some((child) => child.id === element.id)
                );
                const updatedSections = [...sections[pageDesigning]];
                const section = updatedSections[sectionIndex];
                const elementIndex = section.children.findIndex(
                  (child) => child.id === element.id
                );
                const updatedElements = [...section.children];
                updatedElements[elementIndex].ref = elementRef;
                updatedSections[sectionIndex] = section;
              }
            }}
          />
        </div>
      );
    }
    if (element.type === "image-element") {
      return (
        <ImageElement
          mobileDesigning={mobileDesigning}
          activeWindowStat={activeWindowStat}
          setActiveWindowStat={(something) => setActiveWindowStat(something)}
          selectedElements={selectedElements}
          focusElement={focusElement}
          // onClick={() => setFocusElement(element.id)}
          onClick={(event) => handleShiftClick(event, element.id)}
          style={{ display: "none" }}
          key={element.id}
          onStyleChange={(style_name, style_value) => {
            const updatingElement = [...baseElement];
            const elementIndex = updatingElement.findIndex(
              (child) => child.id === focusElement
            );

            updatingElement[elementIndex][style_name] = style_value;
            setBaseElement(updatingElement);
          }}
          src=""
          setEditingInputWithArrow={(status) =>
            setEditingInputWithArrow(status)
          }
          onFocus={(elementId, event) => {
            if (elementId === null) {
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setSelectedSection();
              setSelectedSectionIndex();

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              setSrcImage(focusedElement.src);
              setObjectFit(focusedElement.objectFit);
              handleShiftClick(event, element.id);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setNotInputFocus(true);
              setFocusBorderRadius(focusedElement.borderRadius);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
            }
          }}
          className={`canvas-element ${element.type}`}
          element={element}
          onDrag={(event, elementId, offset, touch) => {
            const sectionIndex = sections[pageDesigning].findIndex((section) =>
              section.children.some((child) => child.id === elementId)
            );
            const section = sections[pageDesigning][sectionIndex];
            const elementIndex = section.children.findIndex(
              (child) => child.id === elementId
            );
            const focusedElement = section.children[elementIndex];

            setFocusElement(elementId);
            setFocusElementType(element.type);
            setFontWeight(focusedElement.fontWeight);
            setLineHeight(focusedElement.lineHeight);
            setLetterSpacing(focusedElement.letterSpacing);
            setTextAlign(focusedElement.textAlign);
            setFontSize(focusedElement.fontSize);
            setFocusWidth(focusedElement.width);
            setFocusHeight(focusedElement.height);
            setFocusBackgroundColor(focusedElement.backgroundColor);
            setNotInputFocus(true);
            setFocusBorder(focusedElement.border);
            setFocusBoxShadow(focusedElement.shadow);
            setFocusOpacity(focusedElement.opacity);
            setFocusBorderRadius(focusedElement.borderRadius);
            handleDrag(
              event,
              elementId,
              offset,
              section.id,
              draggingSectionPositionY,
              touch
            );
          }}
          onDragStart={(event, elementId, touch) => {
            handleDragStart(event, element.id, section.id, touch);
          }}
          onDragEnd={handleDragEnd}
          onResizeStart={() => handleResizeStart(element.id)}
          onResize={(newWidth, newHeight) =>
            handleResize(newWidth, newHeight, element, section)
          }
          onResizeEnd={handleResizeEnd}
          setNotInputFocus={(status) => {
            setNotInputFocus(status);
            console.log(status);
          }}
          ref={element.ref} // Assign the ref to the element
          onRefSet={(elementRef) => {
            if (mobileDesigning) {
              const sectionIndex = mobileSections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...mobileSections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...sections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            }
          }}
        />
      );
    }
    if (element.type === "button-element") {
      return (
        <ButtonElement
          mobileDesigning={mobileDesigning}
          activeWindowStat={activeWindowStat}
          setActiveWindowStat={(something) => setActiveWindowStat(something)}
          selectedElements={selectedElements}
          focusElement={focusElement}
          style={{ display: "none" }}
          key={element.id}
          setNotInputFocus={(status) => setNotInputFocus(status)}
          setEditingInputWithArrow={(status) =>
            setEditingInputWithArrow(status)
          }
          onFocus={(elementId, event) => {
            if (elementId === null) {
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === elementId)
              );
              const section = sections[pageDesigning][sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === elementId
              );
              const focusedElement = section.children[elementIndex];

              setSelectedSection();
              setSelectedSectionIndex();

              setFocusElement(elementId);
              setFocusElementType(element.type);
              setFontWeight(focusedElement.fontWeight);
              setLineHeight(focusedElement.lineHeight);
              setLetterSpacing(focusedElement.letterSpacing);
              setTextAlign(focusedElement.textAlign);
              setFontSize(focusedElement.fontSize);
              setFocusWidth(focusedElement.width);
              setFocusHeight(focusedElement.height);
              handleShiftClick(event, element.id);
              setFocusBackgroundColor(focusedElement.backgroundColor);
              setFocusColor(focusedElement.color);
              setFocusBorder(focusedElement.border);
              setFocusBoxShadow(focusedElement.shadow);
              setFocusOpacity(focusedElement.opacity);
              setFocusBorderRadius(focusedElement.borderRadius);
              // setNotInputFocus(true);
            }
          }}
          onInputEditing={() => {
            setNotInputFocus(false);
            console.log("this is happening");
          }}
          onInputBlur={() => {
            setNotInputFocus(true);
            console.log("this is happening");
          }}
          className={`canvas-element ${element.type}`}
          element={element}
          onContentChange={(content) => onContentChange(content, element.id)}
          onDrag={(event, elementId, offset, touch) => {
            const sectionIndex = sections[pageDesigning].findIndex((section) =>
              section.children.some((child) => child.id === elementId)
            );
            const section = sections[pageDesigning][sectionIndex];
            const elementIndex = section.children.findIndex(
              (child) => child.id === elementId
            );
            const focusedElement = section.children[elementIndex];

            setFocusElement(elementId);
            setFocusElementType(element.type);
            setFontWeight(focusedElement.fontWeight);
            setLineHeight(focusedElement.lineHeight);
            setLetterSpacing(focusedElement.letterSpacing);
            setTextAlign(focusedElement.textAlign);
            setFontSize(focusedElement.fontSize);
            setFocusWidth(focusedElement.width);
            setFocusHeight(focusedElement.height);
            setFocusBackgroundColor(focusedElement.backgroundColor);
            setFocusColor(focusedElement.color);
            setFocusBorder(focusedElement.border);
            setFocusBoxShadow(focusedElement.shadow);
            setFocusOpacity(focusedElement.opacity);
            setFocusBorderRadius(focusedElement.borderRadius);
            handleDrag(
              event,
              elementId,
              offset,
              section.id,
              draggingSectionPositionY,
              touch
            );
            setNotInputFocus(true);
          }}
          onDragStart={(event, elementId, touch) => {
            handleDragStart(event, element.id, section.id, touch);
          }}
          onDragEnd={handleDragEnd}
          onResizeStart={() => handleResizeStart(element.id)}
          onResize={(newWidth, newHeight) =>
            handleResize(newWidth, newHeight, element, section)
          }
          onResizeEnd={handleResizeEnd}
          ref={element.ref} // Assign the ref to the element
          onStyleChange={(style_name, style_value) => {
            const updatingElement = [...baseElement];
            const elementIndex = updatingElement.findIndex(
              (child) => child.id === focusElement
            );

            updatingElement[elementIndex][style_name] = style_value;
            setBaseElement(updatingElement);
          }}
          onRefSet={(elementRef) => {
            if (mobileDesigning) {
              const sectionIndex = mobileSections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...mobileSections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            } else {
              const sectionIndex = sections[pageDesigning].findIndex(
                (section) =>
                  section.children.some((child) => child.id === element.id)
              );
              const updatedSections = [...sections[pageDesigning]];
              const section = updatedSections[sectionIndex];
              const elementIndex = section.children.findIndex(
                (child) => child.id === element.id
              );
              const updatedElements = [...section.children];
              updatedElements[elementIndex].ref = elementRef;
              updatedSections[sectionIndex] = section;
            }
          }}
        />
      );
    }
  };

  const [imageModalStates, setImageModalStates] = useState(false);
  const [imageSearchQuery, setImageSearchQuery] = useState("");
  const [imageSearchResult, setImageSearchResult] = useState([
    {
      id: 1484806,
      src: {
        original:
          "https://images.pexels.com/photos/1484806/pexels-photo-1484806.jpeg",
        large2x:
          "https://images.pexels.com/photos/1484806/pexels-photo-1484806.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1484806/pexels-photo-1484806.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1484806/pexels-photo-1484806.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1484806/pexels-photo-1484806.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1484806/pexels-photo-1484806.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1484806/pexels-photo-1484806.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1484806/pexels-photo-1484806.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 4006576,
      src: {
        original:
          "https://images.pexels.com/photos/4006576/pexels-photo-4006576.jpeg",
        large2x:
          "https://images.pexels.com/photos/4006576/pexels-photo-4006576.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/4006576/pexels-photo-4006576.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/4006576/pexels-photo-4006576.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/4006576/pexels-photo-4006576.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/4006576/pexels-photo-4006576.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/4006576/pexels-photo-4006576.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/4006576/pexels-photo-4006576.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 4148984,
      src: {
        original:
          "https://images.pexels.com/photos/4148984/pexels-photo-4148984.jpeg",
        large2x:
          "https://images.pexels.com/photos/4148984/pexels-photo-4148984.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/4148984/pexels-photo-4148984.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/4148984/pexels-photo-4148984.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/4148984/pexels-photo-4148984.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/4148984/pexels-photo-4148984.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/4148984/pexels-photo-4148984.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/4148984/pexels-photo-4148984.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 4064423,
      src: {
        original:
          "https://images.pexels.com/photos/4064423/pexels-photo-4064423.jpeg",
        large2x:
          "https://images.pexels.com/photos/4064423/pexels-photo-4064423.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/4064423/pexels-photo-4064423.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/4064423/pexels-photo-4064423.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/4064423/pexels-photo-4064423.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/4064423/pexels-photo-4064423.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/4064423/pexels-photo-4064423.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/4064423/pexels-photo-4064423.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 2230015,
      src: {
        original:
          "https://images.pexels.com/photos/2230015/pexels-photo-2230015.jpeg",
        large2x:
          "https://images.pexels.com/photos/2230015/pexels-photo-2230015.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/2230015/pexels-photo-2230015.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/2230015/pexels-photo-2230015.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/2230015/pexels-photo-2230015.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/2230015/pexels-photo-2230015.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/2230015/pexels-photo-2230015.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/2230015/pexels-photo-2230015.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 3628700,
      src: {
        original:
          "https://images.pexels.com/photos/3628700/pexels-photo-3628700.jpeg",
        large2x:
          "https://images.pexels.com/photos/3628700/pexels-photo-3628700.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/3628700/pexels-photo-3628700.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/3628700/pexels-photo-3628700.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/3628700/pexels-photo-3628700.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/3628700/pexels-photo-3628700.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/3628700/pexels-photo-3628700.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/3628700/pexels-photo-3628700.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 3732667,
      src: {
        original:
          "https://images.pexels.com/photos/3732667/pexels-photo-3732667.jpeg",
        large2x:
          "https://images.pexels.com/photos/3732667/pexels-photo-3732667.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/3732667/pexels-photo-3732667.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/3732667/pexels-photo-3732667.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/3732667/pexels-photo-3732667.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/3732667/pexels-photo-3732667.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/3732667/pexels-photo-3732667.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/3732667/pexels-photo-3732667.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1181579,
      src: {
        original:
          "https://images.pexels.com/photos/1181579/pexels-photo-1181579.jpeg",
        large2x:
          "https://images.pexels.com/photos/1181579/pexels-photo-1181579.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1181579/pexels-photo-1181579.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1181579/pexels-photo-1181579.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1181579/pexels-photo-1181579.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1181579/pexels-photo-1181579.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1181579/pexels-photo-1181579.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1181579/pexels-photo-1181579.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1181424,
      src: {
        original:
          "https://images.pexels.com/photos/1181424/pexels-photo-1181424.jpeg",
        large2x:
          "https://images.pexels.com/photos/1181424/pexels-photo-1181424.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1181424/pexels-photo-1181424.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1181424/pexels-photo-1181424.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1181424/pexels-photo-1181424.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1181424/pexels-photo-1181424.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1181424/pexels-photo-1181424.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1181424/pexels-photo-1181424.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 3852204,
      src: {
        original:
          "https://images.pexels.com/photos/3852204/pexels-photo-3852204.jpeg",
        large2x:
          "https://images.pexels.com/photos/3852204/pexels-photo-3852204.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/3852204/pexels-photo-3852204.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/3852204/pexels-photo-3852204.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/3852204/pexels-photo-3852204.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/3852204/pexels-photo-3852204.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/3852204/pexels-photo-3852204.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/3852204/pexels-photo-3852204.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1250643,
      src: {
        original:
          "https://images.pexels.com/photos/1250643/pexels-photo-1250643.jpeg",
        large2x:
          "https://images.pexels.com/photos/1250643/pexels-photo-1250643.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1250643/pexels-photo-1250643.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1250643/pexels-photo-1250643.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1250643/pexels-photo-1250643.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1250643/pexels-photo-1250643.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1250643/pexels-photo-1250643.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1250643/pexels-photo-1250643.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1674666,
      src: {
        original:
          "https://images.pexels.com/photos/1674666/pexels-photo-1674666.jpeg",
        large2x:
          "https://images.pexels.com/photos/1674666/pexels-photo-1674666.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1674666/pexels-photo-1674666.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1674666/pexels-photo-1674666.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1674666/pexels-photo-1674666.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1674666/pexels-photo-1674666.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1674666/pexels-photo-1674666.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1674666/pexels-photo-1674666.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 2064826,
      src: {
        original:
          "https://images.pexels.com/photos/2064826/pexels-photo-2064826.jpeg",
        large2x:
          "https://images.pexels.com/photos/2064826/pexels-photo-2064826.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/2064826/pexels-photo-2064826.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/2064826/pexels-photo-2064826.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/2064826/pexels-photo-2064826.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/2064826/pexels-photo-2064826.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/2064826/pexels-photo-2064826.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/2064826/pexels-photo-2064826.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 6646919,
      src: {
        original:
          "https://images.pexels.com/photos/6646919/pexels-photo-6646919.jpeg",
        large2x:
          "https://images.pexels.com/photos/6646919/pexels-photo-6646919.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/6646919/pexels-photo-6646919.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/6646919/pexels-photo-6646919.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/6646919/pexels-photo-6646919.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/6646919/pexels-photo-6646919.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/6646919/pexels-photo-6646919.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/6646919/pexels-photo-6646919.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 4259140,
      src: {
        original:
          "https://images.pexels.com/photos/4259140/pexels-photo-4259140.jpeg",
        large2x:
          "https://images.pexels.com/photos/4259140/pexels-photo-4259140.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/4259140/pexels-photo-4259140.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/4259140/pexels-photo-4259140.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/4259140/pexels-photo-4259140.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/4259140/pexels-photo-4259140.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/4259140/pexels-photo-4259140.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/4259140/pexels-photo-4259140.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 2853592,
      src: {
        original:
          "https://images.pexels.com/photos/2853592/pexels-photo-2853592.jpeg",
        large2x:
          "https://images.pexels.com/photos/2853592/pexels-photo-2853592.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/2853592/pexels-photo-2853592.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/2853592/pexels-photo-2853592.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/2853592/pexels-photo-2853592.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/2853592/pexels-photo-2853592.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/2853592/pexels-photo-2853592.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/2853592/pexels-photo-2853592.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1486213,
      src: {
        original:
          "https://images.pexels.com/photos/1486213/pexels-photo-1486213.jpeg",
        large2x:
          "https://images.pexels.com/photos/1486213/pexels-photo-1486213.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1486213/pexels-photo-1486213.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1486213/pexels-photo-1486213.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1486213/pexels-photo-1486213.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1486213/pexels-photo-1486213.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1486213/pexels-photo-1486213.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1486213/pexels-photo-1486213.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 4057758,
      src: {
        original:
          "https://images.pexels.com/photos/4057758/pexels-photo-4057758.jpeg",
        large2x:
          "https://images.pexels.com/photos/4057758/pexels-photo-4057758.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/4057758/pexels-photo-4057758.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/4057758/pexels-photo-4057758.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/4057758/pexels-photo-4057758.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/4057758/pexels-photo-4057758.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/4057758/pexels-photo-4057758.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/4057758/pexels-photo-4057758.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 5637733,
      src: {
        original:
          "https://images.pexels.com/photos/5637733/pexels-photo-5637733.jpeg",
        large2x:
          "https://images.pexels.com/photos/5637733/pexels-photo-5637733.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/5637733/pexels-photo-5637733.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/5637733/pexels-photo-5637733.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/5637733/pexels-photo-5637733.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/5637733/pexels-photo-5637733.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/5637733/pexels-photo-5637733.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/5637733/pexels-photo-5637733.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 7330926,
      src: {
        original:
          "https://images.pexels.com/photos/7330926/pexels-photo-7330926.jpeg",
        large2x:
          "https://images.pexels.com/photos/7330926/pexels-photo-7330926.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/7330926/pexels-photo-7330926.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/7330926/pexels-photo-7330926.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/7330926/pexels-photo-7330926.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/7330926/pexels-photo-7330926.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/7330926/pexels-photo-7330926.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/7330926/pexels-photo-7330926.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 7533347,
      src: {
        original:
          "https://images.pexels.com/photos/7533347/pexels-photo-7533347.jpeg",
        large2x:
          "https://images.pexels.com/photos/7533347/pexels-photo-7533347.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/7533347/pexels-photo-7533347.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/7533347/pexels-photo-7533347.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/7533347/pexels-photo-7533347.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/7533347/pexels-photo-7533347.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/7533347/pexels-photo-7533347.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/7533347/pexels-photo-7533347.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 7217963,
      src: {
        original:
          "https://images.pexels.com/photos/7217963/pexels-photo-7217963.jpeg",
        large2x:
          "https://images.pexels.com/photos/7217963/pexels-photo-7217963.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/7217963/pexels-photo-7217963.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/7217963/pexels-photo-7217963.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/7217963/pexels-photo-7217963.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/7217963/pexels-photo-7217963.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/7217963/pexels-photo-7217963.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/7217963/pexels-photo-7217963.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 9037592,
      src: {
        original:
          "https://images.pexels.com/photos/9037592/pexels-photo-9037592.jpeg",
        large2x:
          "https://images.pexels.com/photos/9037592/pexels-photo-9037592.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/9037592/pexels-photo-9037592.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/9037592/pexels-photo-9037592.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/9037592/pexels-photo-9037592.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/9037592/pexels-photo-9037592.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/9037592/pexels-photo-9037592.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/9037592/pexels-photo-9037592.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 12118422,
      src: {
        original:
          "https://images.pexels.com/photos/12118422/pexels-photo-12118422.jpeg",
        large2x:
          "https://images.pexels.com/photos/12118422/pexels-photo-12118422.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/12118422/pexels-photo-12118422.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/12118422/pexels-photo-12118422.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/12118422/pexels-photo-12118422.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/12118422/pexels-photo-12118422.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/12118422/pexels-photo-12118422.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/12118422/pexels-photo-12118422.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16055440,
      src: {
        original:
          "https://images.pexels.com/photos/16055440/pexels-photo-16055440.jpeg",
        large2x:
          "https://images.pexels.com/photos/16055440/pexels-photo-16055440.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16055440/pexels-photo-16055440.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16055440/pexels-photo-16055440.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16055440/pexels-photo-16055440.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16055440/pexels-photo-16055440.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16055440/pexels-photo-16055440.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16055440/pexels-photo-16055440.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 9705768,
      src: {
        original:
          "https://images.pexels.com/photos/9705768/pexels-photo-9705768.jpeg",
        large2x:
          "https://images.pexels.com/photos/9705768/pexels-photo-9705768.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/9705768/pexels-photo-9705768.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/9705768/pexels-photo-9705768.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/9705768/pexels-photo-9705768.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/9705768/pexels-photo-9705768.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/9705768/pexels-photo-9705768.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/9705768/pexels-photo-9705768.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 15212752,
      src: {
        original:
          "https://images.pexels.com/photos/15212752/pexels-photo-15212752.jpeg",
        large2x:
          "https://images.pexels.com/photos/15212752/pexels-photo-15212752.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/15212752/pexels-photo-15212752.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/15212752/pexels-photo-15212752.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/15212752/pexels-photo-15212752.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/15212752/pexels-photo-15212752.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/15212752/pexels-photo-15212752.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/15212752/pexels-photo-15212752.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 12495575,
      src: {
        original:
          "https://images.pexels.com/photos/12495575/pexels-photo-12495575.png",
        large2x:
          "https://images.pexels.com/photos/12495575/pexels-photo-12495575.png?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/12495575/pexels-photo-12495575.png?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/12495575/pexels-photo-12495575.png?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/12495575/pexels-photo-12495575.png?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/12495575/pexels-photo-12495575.png?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/12495575/pexels-photo-12495575.png?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/12495575/pexels-photo-12495575.png?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16849804,
      src: {
        original:
          "https://images.pexels.com/photos/16849804/pexels-photo-16849804.jpeg",
        large2x:
          "https://images.pexels.com/photos/16849804/pexels-photo-16849804.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16849804/pexels-photo-16849804.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16849804/pexels-photo-16849804.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16849804/pexels-photo-16849804.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16849804/pexels-photo-16849804.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16849804/pexels-photo-16849804.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16849804/pexels-photo-16849804.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16756606,
      src: {
        original:
          "https://images.pexels.com/photos/16756606/pexels-photo-16756606.jpeg",
        large2x:
          "https://images.pexels.com/photos/16756606/pexels-photo-16756606.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16756606/pexels-photo-16756606.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16756606/pexels-photo-16756606.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16756606/pexels-photo-16756606.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16756606/pexels-photo-16756606.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16756606/pexels-photo-16756606.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16756606/pexels-photo-16756606.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 7656336,
      src: {
        original:
          "https://images.pexels.com/photos/7656336/pexels-photo-7656336.jpeg",
        large2x:
          "https://images.pexels.com/photos/7656336/pexels-photo-7656336.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/7656336/pexels-photo-7656336.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/7656336/pexels-photo-7656336.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/7656336/pexels-photo-7656336.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/7656336/pexels-photo-7656336.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/7656336/pexels-photo-7656336.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/7656336/pexels-photo-7656336.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16881386,
      src: {
        original:
          "https://images.pexels.com/photos/16881386/pexels-photo-16881386.jpeg",
        large2x:
          "https://images.pexels.com/photos/16881386/pexels-photo-16881386.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16881386/pexels-photo-16881386.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16881386/pexels-photo-16881386.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16881386/pexels-photo-16881386.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16881386/pexels-photo-16881386.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16881386/pexels-photo-16881386.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16881386/pexels-photo-16881386.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16907832,
      src: {
        original:
          "https://images.pexels.com/photos/16907832/pexels-photo-16907832.jpeg",
        large2x:
          "https://images.pexels.com/photos/16907832/pexels-photo-16907832.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16907832/pexels-photo-16907832.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16907832/pexels-photo-16907832.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16907832/pexels-photo-16907832.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16907832/pexels-photo-16907832.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16907832/pexels-photo-16907832.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16907832/pexels-photo-16907832.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16938786,
      src: {
        original:
          "https://images.pexels.com/photos/16938786/pexels-photo-16938786.jpeg",
        large2x:
          "https://images.pexels.com/photos/16938786/pexels-photo-16938786.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16938786/pexels-photo-16938786.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16938786/pexels-photo-16938786.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16938786/pexels-photo-16938786.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16938786/pexels-photo-16938786.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16938786/pexels-photo-16938786.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16938786/pexels-photo-16938786.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16941392,
      src: {
        original:
          "https://images.pexels.com/photos/16941392/pexels-photo-16941392.jpeg",
        large2x:
          "https://images.pexels.com/photos/16941392/pexels-photo-16941392.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16941392/pexels-photo-16941392.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16941392/pexels-photo-16941392.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16941392/pexels-photo-16941392.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16941392/pexels-photo-16941392.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16941392/pexels-photo-16941392.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16941392/pexels-photo-16941392.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16671959,
      src: {
        original:
          "https://images.pexels.com/photos/16671959/pexels-photo-16671959.jpeg",
        large2x:
          "https://images.pexels.com/photos/16671959/pexels-photo-16671959.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16671959/pexels-photo-16671959.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16671959/pexels-photo-16671959.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16671959/pexels-photo-16671959.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16671959/pexels-photo-16671959.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16671959/pexels-photo-16671959.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16671959/pexels-photo-16671959.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16733847,
      src: {
        original:
          "https://images.pexels.com/photos/16733847/pexels-photo-16733847.jpeg",
        large2x:
          "https://images.pexels.com/photos/16733847/pexels-photo-16733847.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16733847/pexels-photo-16733847.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16733847/pexels-photo-16733847.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16733847/pexels-photo-16733847.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16733847/pexels-photo-16733847.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16733847/pexels-photo-16733847.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16733847/pexels-photo-16733847.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16166109,
      src: {
        original:
          "https://images.pexels.com/photos/16166109/pexels-photo-16166109.jpeg",
        large2x:
          "https://images.pexels.com/photos/16166109/pexels-photo-16166109.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16166109/pexels-photo-16166109.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16166109/pexels-photo-16166109.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16166109/pexels-photo-16166109.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16166109/pexels-photo-16166109.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16166109/pexels-photo-16166109.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16166109/pexels-photo-16166109.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16853434,
      src: {
        original:
          "https://images.pexels.com/photos/16853434/pexels-photo-16853434.jpeg",
        large2x:
          "https://images.pexels.com/photos/16853434/pexels-photo-16853434.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16853434/pexels-photo-16853434.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16853434/pexels-photo-16853434.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16853434/pexels-photo-16853434.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16853434/pexels-photo-16853434.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16853434/pexels-photo-16853434.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16853434/pexels-photo-16853434.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16922807,
      src: {
        original:
          "https://images.pexels.com/photos/16922807/pexels-photo-16922807.png",
        large2x:
          "https://images.pexels.com/photos/16922807/pexels-photo-16922807.png?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16922807/pexels-photo-16922807.png?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16922807/pexels-photo-16922807.png?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16922807/pexels-photo-16922807.png?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16922807/pexels-photo-16922807.png?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16922807/pexels-photo-16922807.png?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16922807/pexels-photo-16922807.png?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16958033,
      src: {
        original:
          "https://images.pexels.com/photos/16958033/pexels-photo-16958033.jpeg",
        large2x:
          "https://images.pexels.com/photos/16958033/pexels-photo-16958033.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16958033/pexels-photo-16958033.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16958033/pexels-photo-16958033.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16958033/pexels-photo-16958033.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16958033/pexels-photo-16958033.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16958033/pexels-photo-16958033.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16958033/pexels-photo-16958033.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16977372,
      src: {
        original:
          "https://images.pexels.com/photos/16977372/pexels-photo-16977372.jpeg",
        large2x:
          "https://images.pexels.com/photos/16977372/pexels-photo-16977372.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16977372/pexels-photo-16977372.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16977372/pexels-photo-16977372.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16977372/pexels-photo-16977372.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16977372/pexels-photo-16977372.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16977372/pexels-photo-16977372.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16977372/pexels-photo-16977372.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16765277,
      src: {
        original:
          "https://images.pexels.com/photos/16765277/pexels-photo-16765277.jpeg",
        large2x:
          "https://images.pexels.com/photos/16765277/pexels-photo-16765277.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16765277/pexels-photo-16765277.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16765277/pexels-photo-16765277.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16765277/pexels-photo-16765277.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16765277/pexels-photo-16765277.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16765277/pexels-photo-16765277.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16765277/pexels-photo-16765277.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 16462955,
      src: {
        original:
          "https://images.pexels.com/photos/16462955/pexels-photo-16462955.jpeg",
        large2x:
          "https://images.pexels.com/photos/16462955/pexels-photo-16462955.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/16462955/pexels-photo-16462955.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/16462955/pexels-photo-16462955.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/16462955/pexels-photo-16462955.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/16462955/pexels-photo-16462955.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/16462955/pexels-photo-16462955.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/16462955/pexels-photo-16462955.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 837358,
      src: {
        original:
          "https://images.pexels.com/photos/837358/pexels-photo-837358.jpeg",
        large2x:
          "https://images.pexels.com/photos/837358/pexels-photo-837358.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/837358/pexels-photo-837358.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/837358/pexels-photo-837358.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/837358/pexels-photo-837358.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/837358/pexels-photo-837358.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/837358/pexels-photo-837358.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/837358/pexels-photo-837358.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 39866,
      src: {
        original:
          "https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg",
        large2x:
          "https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/39866/entrepreneur-startup-start-up-man-39866.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 445109,
      src: {
        original:
          "https://images.pexels.com/photos/445109/pexels-photo-445109.jpeg",
        large2x:
          "https://images.pexels.com/photos/445109/pexels-photo-445109.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/445109/pexels-photo-445109.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/445109/pexels-photo-445109.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/445109/pexels-photo-445109.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/445109/pexels-photo-445109.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/445109/pexels-photo-445109.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/445109/pexels-photo-445109.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1709003,
      src: {
        original:
          "https://images.pexels.com/photos/1709003/pexels-photo-1709003.jpeg",
        large2x:
          "https://images.pexels.com/photos/1709003/pexels-photo-1709003.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1709003/pexels-photo-1709003.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1709003/pexels-photo-1709003.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1709003/pexels-photo-1709003.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1709003/pexels-photo-1709003.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1709003/pexels-photo-1709003.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1709003/pexels-photo-1709003.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 240561,
      src: {
        original:
          "https://images.pexels.com/photos/240561/pexels-photo-240561.jpeg",
        large2x:
          "https://images.pexels.com/photos/240561/pexels-photo-240561.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/240561/pexels-photo-240561.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/240561/pexels-photo-240561.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/240561/pexels-photo-240561.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/240561/pexels-photo-240561.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/240561/pexels-photo-240561.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/240561/pexels-photo-240561.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 889545,
      src: {
        original:
          "https://images.pexels.com/photos/889545/pexels-photo-889545.jpeg",
        large2x:
          "https://images.pexels.com/photos/889545/pexels-photo-889545.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/889545/pexels-photo-889545.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/889545/pexels-photo-889545.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/889545/pexels-photo-889545.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/889545/pexels-photo-889545.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/889545/pexels-photo-889545.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/889545/pexels-photo-889545.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 699459,
      src: {
        original:
          "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg",
        large2x:
          "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/699459/pexels-photo-699459.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1245055,
      src: {
        original:
          "https://images.pexels.com/photos/1245055/pexels-photo-1245055.jpeg",
        large2x:
          "https://images.pexels.com/photos/1245055/pexels-photo-1245055.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1245055/pexels-photo-1245055.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1245055/pexels-photo-1245055.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1245055/pexels-photo-1245055.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1245055/pexels-photo-1245055.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1245055/pexels-photo-1245055.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1245055/pexels-photo-1245055.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 267961,
      src: {
        original:
          "https://images.pexels.com/photos/267961/pexels-photo-267961.jpeg",
        large2x:
          "https://images.pexels.com/photos/267961/pexels-photo-267961.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/267961/pexels-photo-267961.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/267961/pexels-photo-267961.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/267961/pexels-photo-267961.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/267961/pexels-photo-267961.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/267961/pexels-photo-267961.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/267961/pexels-photo-267961.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 903171,
      src: {
        original:
          "https://images.pexels.com/photos/903171/pexels-photo-903171.jpeg",
        large2x:
          "https://images.pexels.com/photos/903171/pexels-photo-903171.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/903171/pexels-photo-903171.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/903171/pexels-photo-903171.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/903171/pexels-photo-903171.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/903171/pexels-photo-903171.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/903171/pexels-photo-903171.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/903171/pexels-photo-903171.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1087735,
      src: {
        original:
          "https://images.pexels.com/photos/1087735/pexels-photo-1087735.jpeg",
        large2x:
          "https://images.pexels.com/photos/1087735/pexels-photo-1087735.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1087735/pexels-photo-1087735.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1087735/pexels-photo-1087735.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1087735/pexels-photo-1087735.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1087735/pexels-photo-1087735.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1087735/pexels-photo-1087735.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1087735/pexels-photo-1087735.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 746386,
      src: {
        original:
          "https://images.pexels.com/photos/746386/pexels-photo-746386.jpeg",
        large2x:
          "https://images.pexels.com/photos/746386/pexels-photo-746386.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/746386/pexels-photo-746386.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/746386/pexels-photo-746386.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/746386/pexels-photo-746386.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/746386/pexels-photo-746386.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/746386/pexels-photo-746386.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/746386/pexels-photo-746386.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 845457,
      src: {
        original:
          "https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg",
        large2x:
          "https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/845457/pexels-photo-845457.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 34514,
      src: {
        original:
          "https://images.pexels.com/photos/34514/spot-runs-start-la.jpg",
        large2x:
          "https://images.pexels.com/photos/34514/spot-runs-start-la.jpg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/34514/spot-runs-start-la.jpg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/34514/spot-runs-start-la.jpg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/34514/spot-runs-start-la.jpg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/34514/spot-runs-start-la.jpg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/34514/spot-runs-start-la.jpg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/34514/spot-runs-start-la.jpg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1472334,
      src: {
        original:
          "https://images.pexels.com/photos/1472334/pexels-photo-1472334.jpeg",
        large2x:
          "https://images.pexels.com/photos/1472334/pexels-photo-1472334.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1472334/pexels-photo-1472334.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1472334/pexels-photo-1472334.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1472334/pexels-photo-1472334.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1472334/pexels-photo-1472334.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1472334/pexels-photo-1472334.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1472334/pexels-photo-1472334.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 762080,
      src: {
        original:
          "https://images.pexels.com/photos/762080/pexels-photo-762080.jpeg",
        large2x:
          "https://images.pexels.com/photos/762080/pexels-photo-762080.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/762080/pexels-photo-762080.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/762080/pexels-photo-762080.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/762080/pexels-photo-762080.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/762080/pexels-photo-762080.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/762080/pexels-photo-762080.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/762080/pexels-photo-762080.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 762527,
      src: {
        original:
          "https://images.pexels.com/photos/762527/pexels-photo-762527.jpeg",
        large2x:
          "https://images.pexels.com/photos/762527/pexels-photo-762527.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/762527/pexels-photo-762527.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/762527/pexels-photo-762527.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/762527/pexels-photo-762527.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/762527/pexels-photo-762527.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/762527/pexels-photo-762527.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/762527/pexels-photo-762527.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 853151,
      src: {
        original:
          "https://images.pexels.com/photos/853151/pexels-photo-853151.jpeg",
        large2x:
          "https://images.pexels.com/photos/853151/pexels-photo-853151.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/853151/pexels-photo-853151.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/853151/pexels-photo-853151.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/853151/pexels-photo-853151.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/853151/pexels-photo-853151.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/853151/pexels-photo-853151.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/853151/pexels-photo-853151.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 301952,
      src: {
        original:
          "https://images.pexels.com/photos/301952/pexels-photo-301952.jpeg",
        large2x:
          "https://images.pexels.com/photos/301952/pexels-photo-301952.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/301952/pexels-photo-301952.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/301952/pexels-photo-301952.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/301952/pexels-photo-301952.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/301952/pexels-photo-301952.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/301952/pexels-photo-301952.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/301952/pexels-photo-301952.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 2102416,
      src: {
        original:
          "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg",
        large2x:
          "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/2102416/pexels-photo-2102416.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 53214,
      src: {
        original:
          "https://images.pexels.com/photos/53214/trekking-hiking-group-alpine-53214.jpeg",
        large2x:
          "https://images.pexels.com/photos/53214/trekking-hiking-group-alpine-53214.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/53214/trekking-hiking-group-alpine-53214.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/53214/trekking-hiking-group-alpine-53214.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/53214/trekking-hiking-group-alpine-53214.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/53214/trekking-hiking-group-alpine-53214.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/53214/trekking-hiking-group-alpine-53214.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/53214/trekking-hiking-group-alpine-53214.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 601170,
      src: {
        original:
          "https://images.pexels.com/photos/601170/pexels-photo-601170.jpeg",
        large2x:
          "https://images.pexels.com/photos/601170/pexels-photo-601170.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/601170/pexels-photo-601170.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/601170/pexels-photo-601170.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/601170/pexels-photo-601170.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/601170/pexels-photo-601170.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/601170/pexels-photo-601170.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/601170/pexels-photo-601170.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 307847,
      src: {
        original:
          "https://images.pexels.com/photos/307847/pexels-photo-307847.jpeg",
        large2x:
          "https://images.pexels.com/photos/307847/pexels-photo-307847.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/307847/pexels-photo-307847.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/307847/pexels-photo-307847.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/307847/pexels-photo-307847.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/307847/pexels-photo-307847.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/307847/pexels-photo-307847.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/307847/pexels-photo-307847.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 214576,
      src: {
        original:
          "https://images.pexels.com/photos/214576/pexels-photo-214576.jpeg",
        large2x:
          "https://images.pexels.com/photos/214576/pexels-photo-214576.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/214576/pexels-photo-214576.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/214576/pexels-photo-214576.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/214576/pexels-photo-214576.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/214576/pexels-photo-214576.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/214576/pexels-photo-214576.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/214576/pexels-photo-214576.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1822608,
      src: {
        original:
          "https://images.pexels.com/photos/1822608/pexels-photo-1822608.jpeg",
        large2x:
          "https://images.pexels.com/photos/1822608/pexels-photo-1822608.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1822608/pexels-photo-1822608.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1822608/pexels-photo-1822608.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1822608/pexels-photo-1822608.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1822608/pexels-photo-1822608.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1822608/pexels-photo-1822608.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1822608/pexels-photo-1822608.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 247304,
      src: {
        original:
          "https://images.pexels.com/photos/247304/pexels-photo-247304.jpeg",
        large2x:
          "https://images.pexels.com/photos/247304/pexels-photo-247304.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/247304/pexels-photo-247304.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/247304/pexels-photo-247304.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/247304/pexels-photo-247304.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/247304/pexels-photo-247304.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/247304/pexels-photo-247304.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/247304/pexels-photo-247304.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 460295,
      src: {
        original:
          "https://images.pexels.com/photos/460295/pexels-photo-460295.jpeg",
        large2x:
          "https://images.pexels.com/photos/460295/pexels-photo-460295.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/460295/pexels-photo-460295.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/460295/pexels-photo-460295.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/460295/pexels-photo-460295.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/460295/pexels-photo-460295.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/460295/pexels-photo-460295.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/460295/pexels-photo-460295.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 439818,
      src: {
        original:
          "https://images.pexels.com/photos/439818/pexels-photo-439818.jpeg",
        large2x:
          "https://images.pexels.com/photos/439818/pexels-photo-439818.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/439818/pexels-photo-439818.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/439818/pexels-photo-439818.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/439818/pexels-photo-439818.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/439818/pexels-photo-439818.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/439818/pexels-photo-439818.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/439818/pexels-photo-439818.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1125850,
      src: {
        original:
          "https://images.pexels.com/photos/1125850/pexels-photo-1125850.jpeg",
        large2x:
          "https://images.pexels.com/photos/1125850/pexels-photo-1125850.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1125850/pexels-photo-1125850.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1125850/pexels-photo-1125850.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1125850/pexels-photo-1125850.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1125850/pexels-photo-1125850.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1125850/pexels-photo-1125850.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1125850/pexels-photo-1125850.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 571169,
      src: {
        original:
          "https://images.pexels.com/photos/571169/pexels-photo-571169.jpeg",
        large2x:
          "https://images.pexels.com/photos/571169/pexels-photo-571169.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/571169/pexels-photo-571169.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/571169/pexels-photo-571169.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/571169/pexels-photo-571169.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/571169/pexels-photo-571169.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/571169/pexels-photo-571169.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/571169/pexels-photo-571169.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 927451,
      src: {
        original:
          "https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg",
        large2x:
          "https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/927451/pexels-photo-927451.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 1848565,
      src: {
        original:
          "https://images.pexels.com/photos/1848565/pexels-photo-1848565.jpeg",
        large2x:
          "https://images.pexels.com/photos/1848565/pexels-photo-1848565.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/1848565/pexels-photo-1848565.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/1848565/pexels-photo-1848565.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/1848565/pexels-photo-1848565.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/1848565/pexels-photo-1848565.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/1848565/pexels-photo-1848565.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/1848565/pexels-photo-1848565.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 819530,
      src: {
        original:
          "https://images.pexels.com/photos/819530/pexels-photo-819530.jpeg",
        large2x:
          "https://images.pexels.com/photos/819530/pexels-photo-819530.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/819530/pexels-photo-819530.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/819530/pexels-photo-819530.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/819530/pexels-photo-819530.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/819530/pexels-photo-819530.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/819530/pexels-photo-819530.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/819530/pexels-photo-819530.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 886285,
      src: {
        original:
          "https://images.pexels.com/photos/886285/pexels-photo-886285.jpeg",
        large2x:
          "https://images.pexels.com/photos/886285/pexels-photo-886285.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/886285/pexels-photo-886285.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/886285/pexels-photo-886285.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/886285/pexels-photo-886285.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/886285/pexels-photo-886285.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/886285/pexels-photo-886285.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/886285/pexels-photo-886285.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 673649,
      src: {
        original:
          "https://images.pexels.com/photos/673649/pexels-photo-673649.jpeg",
        large2x:
          "https://images.pexels.com/photos/673649/pexels-photo-673649.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/673649/pexels-photo-673649.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/673649/pexels-photo-673649.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/673649/pexels-photo-673649.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/673649/pexels-photo-673649.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/673649/pexels-photo-673649.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/673649/pexels-photo-673649.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
    {
      id: 236171,
      src: {
        original:
          "https://images.pexels.com/photos/236171/pexels-photo-236171.jpeg",
        large2x:
          "https://images.pexels.com/photos/236171/pexels-photo-236171.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=2\u0026h=650\u0026w=940",
        large:
          "https://images.pexels.com/photos/236171/pexels-photo-236171.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=650\u0026w=940",
        medium:
          "https://images.pexels.com/photos/236171/pexels-photo-236171.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=350",
        small:
          "https://images.pexels.com/photos/236171/pexels-photo-236171.jpeg?auto=compress\u0026cs=tinysrgb\u0026h=130",
        portrait:
          "https://images.pexels.com/photos/236171/pexels-photo-236171.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=1200\u0026w=800",
        landscape:
          "https://images.pexels.com/photos/236171/pexels-photo-236171.jpeg?auto=compress\u0026cs=tinysrgb\u0026fit=crop\u0026h=627\u0026w=1200",
        tiny: "https://images.pexels.com/photos/236171/pexels-photo-236171.jpeg?auto=compress\u0026cs=tinysrgb\u0026dpr=1\u0026fit=crop\u0026h=200\u0026w=280",
      },
    },
  ]);

  const handleImageResults = async (e) => {
    e.preventDefault();
    console.log(imageSearchQuery);

    try {
      const response = await fetch(
        `https://api.pexels.com/v1/search/?per_page=80&query=${imageSearchQuery !== "" || imageSearchQuery !== null
          ? imageSearchQuery
          : "people"
        }`,
        {
          method: "GET",
          headers: {
            Authorization:
              "shCfvWEafCZBIccDqbbdQS7yiaWunt6NV3aLmMia5qtZcL1cJBkt7WOZ",
          },
        }
      );
      const json = await response.json();
      setImageSearchResult(json.photos);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className={`web-builder ${activeTools === 2 ? "pen-cursor" : ""}`}
      style={{
        position: "relative",
        height: "100vh",
        "--primary-color": primaryColor,
        // cursor: activeTools === 1 ? "default" : `url('./images/pen_cursor-01-01.png'), auto`
      }}
    >
      {/* <FontLoader
        fonts={[
          {
            font: "Roboto",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Open Sans",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Montserrat",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Lato",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Poppins",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Oswald",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Raleway",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Playfair Display",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Ubuntu",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Merriweather",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Inconsolata",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "PT Serif",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Manrope",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Bebas Neue",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Libre Baskerville",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Josefin Sans",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Dancing Script",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Ephesis",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Teko",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Space Grotesk",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "IBM Plex Mono",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Caveat",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Shadows Into Light",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Playpen Sans",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Permanent Marker",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Black Ops One",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Agbalumo",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Glass Antiqua",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Russo One",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Amatic SC",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Cinzel",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Orbitron",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Kalam",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Saira Condensed",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Great Vibes",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Lobster Two",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Yuji Mai",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Josefin Slab",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Cookie",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Tourney",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Advent Pro",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Macondo",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Playfair Display SC",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Mate SC",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Creepster",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Audiowide",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Monoton",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Fredericka the Great",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Julee",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Niconne",
            weights: [300, 400, 500, 600, 700, 800],
          },
          {
            font: "Rye",
            weights: [300, 400, 500, 600, 700, 800],
          },
        ]}
      /> */}
      <Modal
        isOpen={createTemplateModalIsOpen} // Use the modal state for the specific style_name
        onRequestClose={() => setCreateTemplateModelIsOpen(false)} // Close the modal for the specific style_name
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 60000,
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "0px",
            width: 1000,
            height: 600,
            background: "#fff",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            borderRadius: "10px",
            overflowX: "hidden",
            overflowY: "hidden",
          },
        }}
      >
        <div
          style={{
            background: primaryColor,
            height: "40px",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "10px 10px 0px 0px",
          }}
        >
          <div
            style={{
              margin: "0px 12px",
              fontSize: "16px",
              color: "white",
              fontWeight: 700,
            }}
          >
            Template Drawer
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                background: "rgba(255,255,255,0.5)",
                width: "9px",
                height: "9px",
                borderRadius: "10px",
                marginRight: "7px",
              }}
            />
            <div
              style={{
                background: "rgba(255,255,255,0.5)",
                width: "9px",
                height: "9px",
                borderRadius: "10px",
                marginRight: "7px",
              }}
            />
            <div
              style={{
                background: "white",
                width: "9px",
                height: "9px",
                borderRadius: "10px",
                marginRight: "14px",
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            padding: "10px",
            height: "540px",
            overflowY: "scroll",
            gap: "20px"
          }}
        >
          <div style={{ width: "48.5%" }}>
            {availableTemplates.map((template, index) => {
            if (index % 2 !== 0) {
              return (
              <div
                className="mui-button"
                key={index}
                style={{
                  width: "100%",
                  position: "relative",
                  border: "none",
                  padding: "8px",
                  marginBottom: "15px",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.2)"
                }}
                onClick={() => {
                  createTemplateAfterIndex(templateIndex, template);
                }}
              >
                {template.image ? (
                  <img
                    src={JSON.parse(template.image)}
                    style={{ objectFit: "contain", width: "100%" }}
                  />
                ) : null}
              </div>
            )}})}
          </div>
          <div style={{ width: "48.5%" }}>
            {availableTemplates.map((template, index) => {
            if (index % 2 === 0) {
              return (
              <div
                className="mui-button"
                key={index}
                style={{
                  width: "100%",
                  position: "relative",
                  border: "none",
                  padding: "8px",
                  marginBottom: "15px",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.2)"
                }}
                onClick={() => {
                  createTemplateAfterIndex(templateIndex, template);
                }}
              >
                {template.image ? (
                  <img
                    src={JSON.parse(template.image)}
                    style={{ objectFit: "contain", width: "100%" }}
                  />
                ) : null}
              </div>
            )}})}
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={imageModalStates} // Use the modal state for the specific style_name
        onRequestClose={() => setImageModalStates(false)} // Close the modal for the specific style_name
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 60000,
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "0px",
            width: 800,
            height: 600,
            background: "#fff",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            borderRadius: "10px",
            overflowX: "hidden",
            overflowY: "hidden",
          },
        }}
      >
        <div
          style={{
            background: primaryColor,
            height: "40px",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: "10px 10px 0px 0px",
          }}
        >
          <div
            style={{
              margin: "0px 12px",
              fontSize: "16px",
              color: "white",
              fontWeight: 700,
            }}
          >
            Gallery Drawer
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                background: "rgba(255,255,255,0.5)",
                width: "9px",
                height: "9px",
                borderRadius: "10px",
                marginRight: "7px",
              }}
            />
            <div
              style={{
                background: "rgba(255,255,255,0.5)",
                width: "9px",
                height: "9px",
                borderRadius: "10px",
                marginRight: "7px",
              }}
            />
            <div
              style={{
                background: "white",
                width: "9px",
                height: "9px",
                borderRadius: "10px",
                marginRight: "14px",
              }}
            />
          </div>
        </div>
        <div>
          <form onSubmit={handleImageResults}>
            <input
              placeholder="Search image"
              value={imageSearchQuery}
              onChange={(e) => setImageSearchQuery(e.target.value)}
              style={{
                borderRadius: "6px",
                outline: "1px solid lightgrey",
                border: "none",
                fontSize: "12px",
                color: "grey",
                padding: "10px 20px",
                margin: "10px",
                width: "740px",
              }}
            />
          </form>
          <div
            style={{
              flexWrap: "wrap",
              display: "flex",
              justifyContent: "center",
              overflowY: "scroll",
              height: "510px",
              gap: "5px",
            }}
          >
            {imageSearchResult.map((photo, index) => (
              <div
                className="mui-button"
                style={{
                  width: "190px",
                  height: "200px",
                  objectFit: "cover",
                  position: "relative",
                }}
                key={index}
              >
                <img
                  draggable={false}
                  style={{
                    width: "190px",
                    height: "200px",
                    objectFit: "cover",
                  }}
                  onClick={() => {
                    const imageUrl = photo.src.large2x;
                    console.log(imageUrl);

                    const updatingBaseSection = [...baseSections];

                    const sectionIndex = updatingBaseSection.findIndex(
                      (section) => section.id === focusElement
                    );

                    if (updatingBaseSection[sectionIndex].slider !== true) {
                      console.log(sectionIndex);
                      updatingBaseSection[sectionIndex].src = imageUrl;
                      setBaseSections(updatingBaseSection);
                    } else {
                      console.log(sectionIndex);
                      updatingBaseSection[sectionIndex][
                        `src${updatingBaseSection[sectionIndex].activeSlide}`
                      ] = imageUrl;
                      setBaseSections(updatingBaseSection);
                    }
                    setFocusImage(imageUrl);
                    setImageModalStates(false);
                  }}
                  src={photo.src.large}
                />
                <img
                  src={pexellogo}
                  style={{
                    position: "absolute",
                    top: "3px",
                    left: "5px",
                    width: "40%",
                    zIndex: 50,
                    height: "30px",
                    objectFit: "cover",
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </Modal>
      {/* top toolbar */}
      <div
        style={{
          position: "fixed",
          zIndex: 50,
          width: "100%",
          top: 0,
          left: 0,
          right: 0,
          height: "6vh",
          background: "white",
          color: "rgba(0, 0, 0, 0.8)",
          fontSize: "14px",
          borderBottom: "1px solid lightgrey",
        }}
      >
        <div
          style={{
            height: "5px",
            width: "100%",
            background: primaryColor,
          }}
        />
        <div
          style={{
            height: "calc(6vh - 5px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "25px",
            }}
          >
            <ArrowBackIosIcon
              onClick={() => {
                window.location.href = "https://www.premelo.com/dashboard";
              }}
              className="toolbar-svg"
              style={{ fontSize: "18px" }}
            />
            <div
              style={{
                marginLeft: "20px",
                fontWeight: 700,
                color: "rgba(0, 0, 0, 0.8)",
              }}
            >
              Premelo Page Builder
            </div>
            <div style={{ position: "relative" }}>
              <div
                id="page-trigger"
                onClick={() => setPageDropdown(!pageDropdown)}
                style={{
                  cursor: "pointer",
                  position: "relative",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  border: "1px solid lightgrey",
                  fontSize: "12px",
                  marginLeft: "15px",
                  padding: "3px 15px",
                  color: "rgba(0, 0, 0, 0.8)",
                  fontWeight: 700,
                }}
              >
                {pageDesigning}
                <ArrowDropDownIcon
                  style={{ fontSize: "15px", marginLeft: "10px" }}
                />
              </div>
            </div>
            {pageDesigning === "Product_Dynamic" ||
              pageDesigning === "Event_Dynamic" ||
              pageDesigning === "News_Dynamic" ? (
              <select
                onChange={(e) => {
                  setDynamicItem(dynamicList[e.target.value]);
                }}
                style={{
                  border: "1px solid lightgrey",
                  outline: "none",
                  color: "rgba(0,0,0,0.8)",
                  fontSize: "12px",
                  padding: "2.5px 5px",
                  borderRadius: "5px",
                  width: "150px",
                  fontWeight: 600,
                  marginLeft: "10px",
                }}
              >
                {pageDesigning === "Product_Dynamic"
                  ? dynamicList.map((product, index) => (
                    <option key={index} value={index}>
                      {product.name}
                    </option>
                  ))
                  : null}
                {pageDesigning === "Event_Dynamic"
                  ? dynamicList.map((eve, index) => (
                    <option key={index} value={index}>
                      {eve.event_name}
                    </option>
                  ))
                  : null}
                {pageDesigning === "News_Dynamic"
                  ? dynamicList.map((news, index) => (
                    <option key={index} value={index}>
                      {news.news_title}
                    </option>
                  ))
                  : null}
              </select>
            ) : null}
          </div>

          {/* <button onClick={() => { handleUndo(); console.log("undo hit") }}>Undo</button>
          <button onClick={() => handleRedo()}>Redo</button> */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                fontSize: "12px",
                color: "rgba(0, 0, 0, 0.8)",
                fontWeight: 700,
              }}
            >
              Responsive:
            </div>
            <PersonalVideoIcon
              className="toolbar-svg"
              onClick={() => {
                setSelectedSection(null);
                setFocusElement(null);
                setFocusElementType(null);
                setMobileDesigning(false);
                setOpenGrid(true);
                setSelectedElements([]);
                console.log(sections, mobileSections);
              }}
              style={{ fontSize: "24px", marginLeft: "12px" }}
            />
            <PhoneAndroidIcon
              className="toolbar-svg"
              onClick={() => {
                setSelectedSection(null);
                setFocusElement(null);
                setFocusElementType(null);
                setMobileDesigning(true);
                setOpenGrid(false);
                setSelectedElements([]);
                console.log(sections, mobileSections);
              }}
              style={{ fontSize: "20px", marginLeft: "12px" }}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid4x4Icon
                className="toolbar-svg"
                style={{ fontSize: "16px", marginRight: "10px" }}
                onClick={() => setOpenGrid(!openGrid)}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <HomeRepairServiceIcon
                className="toolbar-svg"
                style={{ fontSize: "16px", marginRight: "10px" }}
                onClick={() => setOpenLeftToolbar(!openLeftToolbar)}
              />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <HandymanIcon
                className="toolbar-svg"
                style={{ fontSize: "16px", marginRight: "20px" }}
                onClick={() => setOpenRightToolbar(!openRightToolbar)}
              />
            </div>
            <div
              className="toolbar-button"
              style={{
                borderRadius: "5px",
                color: "rgba(0, 0, 0, 0.8)",
                border: "1px solid white",
                background: "transparent",
                padding: "3px 15px",
                fontSize: "12px",
                marginRight: "10px",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid lightgrey",
                fontWeight: 700,
              }}
              onClick={() => {
                handleSaveClick();
                setSaveLoading(true);
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  textAlign: "center",
                }}
              >
                <SyncIcon
                  className="publish_loading"
                  style={{
                    fontSize: "24",
                    transition: "0.4s all ease-in-out",
                    opacity: saveLoading ? 1 : 0,
                  }}
                />
              </div>
              <div
                style={{
                  transition: "0.4s all ease-in-out",
                  opacity: saveLoading ? 0 : 1,
                }}
              >
                Save
              </div>
            </div>
            <div
              className="toolbar-button"
              style={{
                borderRadius: "5px",
                color: "rgba(0, 0, 0, 0.8)",
                border: "1px solid white",
                background: "transparent",
                padding: "3px 15px",
                fontSize: "12px",
                marginRight: "25px",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid lightgrey",
                fontWeight: 700,
              }}
              onClick={() => {
                handlePublishClick();
                setPublishLoading(true);
              }}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  bottom: 0,
                  left: 0,
                  right: 0,
                  textAlign: "center",
                }}
              >
                <SyncIcon
                  className="publish_loading"
                  style={{
                    fontSize: "24",
                    transition: "0.4s all ease-in-out",
                    opacity: publishLoading ? 1 : 0,
                  }}
                />
              </div>
              <div
                style={{
                  transition: "0.4s all ease-in-out",
                  opacity: publishLoading ? 0 : 1,
                }}
              >
                Publish
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Dynamic toolbar */}

      {/* left toolbar */}
      <div
        className="toolbar"
        style={{
          position: "fixed",
          top: "53%",
          transform: "translateY(-50%)",
          left: openLeftToolbar ? 10 : -438,
          overflowX: "hidden",
          backgroundColor: "white",
          borderRadius: "5px",
          width: 450,
          height: "80vh",
          zIndex: "5",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          zIndex: 501,
          transition: "0.3s all ease-in-out",
        }}
      >
        <div
          style={{
            height: "60px",
            width: "100%",
            borderRadius: "0px",
            borderBottom: "1px solid lightgrey",
            background: "rgba(255,255,255,0.95)",
            backdropFilter: "blur(15px)",
          }}
        >
          <div
            style={{
              height: "5px",
              width: "100%",
              background: primaryColor,
            }}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              height: "55px",
            }}
          >
            <div
              style={{
                margin: "0px 12px",
                fontSize: "17px",
                color: "rgba(0,0,0,0.8)",
                fontWeight: 700,
              }}
            >
              Module Drawer
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  background: "rgba(100,100,100,0.5)",
                  width: "9px",
                  height: "9px",
                  borderRadius: "10px",
                  marginRight: "7px",
                }}
              />
              <div
                style={{
                  background: "rgba(100,100,100,0.5)",
                  width: "9px",
                  height: "9px",
                  borderRadius: "10px",
                  marginRight: "7px",
                }}
              />
              <div
                style={{
                  background: "grey",
                  width: "9px",
                  height: "9px",
                  borderRadius: "10px",
                  marginRight: "14px",
                }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            padding: "15px",
            background: "rgba(255,255,255,0.7)",
            backdropFilter: "blur(15px)",
          }}
        >
          <input
            placeholder="Search elements"
            value={searchDrawerInput}
            onChange={(e) => setSearchDrawerInput(e.target.value)}
            style={{
              width: "calc(100% - 40px)",
              padding: "10px 20px",
              borderRadius: "5px",
              border: "1px solid lightgrey",
              color: "grey",
              background: "rgba(255,255,255,0.7)",
              outline: "none",
            }}
          />
        </div>
        <div
          style={{
            height: "calc(100% - 127px)",
            overflowY: "auto",
            overflowX: "hidden",
            background: "rgba(255,255,255,0.7)",
            backdropFilter: "blur(15px)",
            borderRadius: "0px 0px 5px 5px",
          }}
        >
          {" "}
          {pageDesigning === "My_Accounts_Dynamic" ? (
            <>
              <div
                style={{
                  color: "grey",
                  padding: "10px 20px",
                  borderBottom: "1px solid lightgrey",
                  textAlign: "left",
                }}
              >
                Account Elements
              </div>

              <div
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  width: "calc(100% - 30px)",
                  padding: "20px",
                  gap: "10px",
                  flexWrap: "wrap",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {"My Account Content"
                  .toLowerCase()
                  .includes(searchDrawerInput.toLowerCase()) ? (
                  <div
                    className="toolbar-element toolbar-button"
                    draggable
                    onDragStart={(event) => {
                      handleDragStart(event, "dynamic-account-tabs-element");
                    }}
                    onTouchStart={(e) => {
                      const touch = e.touches[0];
                      console.log(touch);
                      setDraggingElementDetails({
                        clientX: touch.clientX,
                        clientY: touch.clientY,
                      });
                      setDraggingElement("dynamic-account-tabs-element");
                      setDraggingDisplay(
                        <div
                          className="toolbar-element toolbar-button"
                          style={{
                            background: "white",
                            border: `2px solid ${primaryColor}`,
                            display: "flex",
                            alignItems: "center",
                            width: "calc(47% - 20px)",
                            borderRadius: "8px",
                            display: "flex",
                            padding: "10px",
                            touchAction: "none",
                          }}
                        >
                          <AccountBoxIcon
                            style={{ color: primaryColor, fontSize: "25px" }}
                          />
                          <div style={{ textAlign: "left", marginLeft: "10px" }}>
                            <div
                              style={{
                                color: primaryColor,
                                fontSize: "13px",
                                fontWeight: 700,
                              }}
                            >
                              My Account Content
                            </div>
                            <div
                              style={{
                                color: "grey",
                                fontSize: "9px",
                                fontWeight: 400,
                              }}
                            >
                              A simple text field with highly customizable features
                            </div>
                          </div>
                        </div>
                      );
                    }}
                    onTouchMove={(e) => {
                      const touch = e.touches[0];
                      console.log(touch);
                      setDraggingElementDetails({
                        clientX: touch.clientX,
                        clientY: touch.clientY,
                      });
                      setDraggedOverSectionId(
                        findSectionIdByMousePosition(touch.clientY, touch.clientX)
                      );
                    }}
                    onTouchEnd={(e) => {
                      console.log(draggingElementDetails);
                      const hoveringSectionId = findSectionIdByMousePosition(
                        draggingElementDetails.clientY,
                        draggingElementDetails.clientX
                      );
                      console.log(hoveringSectionId);
                      setDraggingElement("");
                      setDraggingDisplay(null);
                      handleDrop(
                        draggingElementDetails,
                        hoveringSectionId,
                        "dynamic-account-tabs-element"
                      );
                    }}
                    style={{
                      background: "white",
                      border: `2px solid ${primaryColor}`,
                      display: "flex",
                      alignItems: "center",
                      width: "calc(47% - 20px)",
                      borderRadius: "8px",
                      display: "flex",
                      padding: "10px",
                      touchAction: "none",
                    }}
                  >
                    <AccountBoxIcon
                      style={{ color: primaryColor, fontSize: "25px" }}
                    />
                    <div style={{ textAlign: "left", marginLeft: "10px" }}>
                      <div
                        style={{
                          color: primaryColor,
                          fontSize: "13px",
                          fontWeight: 700,
                        }}
                      >
                        My Account Content
                      </div>
                      <div
                        style={{
                          color: "grey",
                          fontSize: "9px",
                          fontWeight: 400,
                        }}
                      >
                        A simple text field with highly customizable features
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
          {pageDesigning === "Checkout_Dynamic" ? (
            <>
              <div
                style={{
                  color: "grey",
                  padding: "10px 20px",
                  borderBottom: "1px solid lightgrey",
                  textAlign: "left",
                }}
              >
                Checkout Element
              </div>
              <div
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  width: "calc(100% - 30px)",
                  padding: "20px",
                  gap: "10px",
                  flexWrap: "wrap",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {"Checkout interface"
                  .toLowerCase()
                  .includes(searchDrawerInput.toLowerCase()) ? (
                  <div
                    className="toolbar-element toolbar-button"
                    draggable
                    onDragStart={(event) => {
                      handleDragStart(event, "dynamic-checkout-element");
                    }}
                    onTouchStart={(e) => {
                      const touch = e.touches[0];
                      console.log(touch);
                      setDraggingElementDetails({
                        clientX: touch.clientX,
                        clientY: touch.clientY,
                      });
                      setDraggingElement("dynamic-checkout-element");
                      setDraggingDisplay(
                        <div
                          className="toolbar-element toolbar-button"
                          style={{
                            background: "white",
                            border: `2px solid ${primaryColor}`,
                            display: "flex",
                            alignItems: "center",
                            width: "calc(47% - 20px)",
                            borderRadius: "8px",
                            display: "flex",
                            padding: "10px",
                            touchAction: "none",
                          }}
                        >
                          <PointOfSaleIcon
                            style={{ color: primaryColor, fontSize: "25px" }}
                          />
                          <div style={{ textAlign: "left", marginLeft: "10px" }}>
                            <div
                              style={{
                                color: primaryColor,
                                fontSize: "13px",
                                fontWeight: 700,
                              }}
                            >
                              Checkout interface
                            </div>
                            <div
                              style={{
                                color: "grey",
                                fontSize: "9px",
                                fontWeight: 400,
                              }}
                            >
                              A simple text field with highly customizable features
                            </div>
                          </div>
                        </div>
                      );
                    }}
                    onTouchMove={(e) => {
                      const touch = e.touches[0];
                      console.log(touch);
                      setDraggingElementDetails({
                        clientX: touch.clientX,
                        clientY: touch.clientY,
                      });
                      setDraggedOverSectionId(
                        findSectionIdByMousePosition(touch.clientY, touch.clientX)
                      );
                    }}
                    onTouchEnd={(e) => {
                      console.log(draggingElementDetails);
                      const hoveringSectionId = findSectionIdByMousePosition(
                        draggingElementDetails.clientY,
                        draggingElementDetails.clientX
                      );
                      console.log(hoveringSectionId);
                      setDraggingElement("");
                      setDraggingDisplay(null);
                      handleDrop(
                        draggingElementDetails,
                        hoveringSectionId,
                        "dynamic-checkout-element"
                      );
                    }}
                    style={{
                      background: "white",
                      border: `2px solid ${primaryColor}`,
                      display: "flex",
                      alignItems: "center",
                      width: "calc(47% - 20px)",
                      borderRadius: "8px",
                      display: "flex",
                      padding: "10px",
                      touchAction: "none",
                    }}
                  >
                    <PointOfSaleIcon
                      style={{ color: primaryColor, fontSize: "25px" }}
                    />
                    <div style={{ textAlign: "left", marginLeft: "10px" }}>
                      <div
                        style={{
                          color: primaryColor,
                          fontSize: "13px",
                          fontWeight: 700,
                        }}
                      >
                        Checkout interface
                      </div>
                      <div
                        style={{
                          color: "grey",
                          fontSize: "9px",
                          fontWeight: 400,
                        }}
                      >
                        A simple text field with highly customizable features
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
          {pageDesigning === "Cart_Dynamic" ? (
            <>
              <div
                style={{
                  color: "grey",
                  padding: "10px 20px",
                  borderBottom: "1px solid lightgrey",
                  textAlign: "left",
                }}
              >
                Cart Element
              </div>
              <div
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  width: "calc(100% - 30px)",
                  padding: "20px",
                  gap: "10px",
                  flexWrap: "wrap",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {"my cart"
                  .toLowerCase()
                  .includes(searchDrawerInput.toLowerCase()) ? (
                  <div
                    className="toolbar-element toolbar-button"
                    draggable
                    onDragStart={(event) => {
                      handleDragStart(event, "dynamic-cart-element");
                    }}
                    onTouchStart={(e) => {
                      const touch = e.touches[0];
                      console.log(touch);
                      setDraggingElementDetails({
                        clientX: touch.clientX,
                        clientY: touch.clientY,
                      });
                      setDraggingElement("dynamic-cart-element");
                      setDraggingDisplay(
                        <div
                          className="toolbar-element toolbar-button"
                          style={{
                            background: "white",
                            border: `2px solid ${primaryColor}`,
                            display: "flex",
                            alignItems: "center",
                            width: "calc(47% - 20px)",
                            borderRadius: "8px",
                            display: "flex",
                            padding: "10px",
                            touchAction: "none",
                          }}
                        >
                          <ShoppingCartIcon
                            style={{ color: primaryColor, fontSize: "25px" }}
                          />
                          <div style={{ textAlign: "left", marginLeft: "10px" }}>
                            <div
                              style={{
                                color: primaryColor,
                                fontSize: "13px",
                                fontWeight: 700,
                              }}
                            >
                              My Cart
                            </div>
                            <div
                              style={{
                                color: "grey",
                                fontSize: "9px",
                                fontWeight: 400,
                              }}
                            >
                              A simple text field with highly customizable features
                            </div>
                          </div>
                        </div>
                      );
                    }}
                    onTouchMove={(e) => {
                      const touch = e.touches[0];
                      console.log(touch);
                      setDraggingElementDetails({
                        clientX: touch.clientX,
                        clientY: touch.clientY,
                      });
                      setDraggedOverSectionId(
                        findSectionIdByMousePosition(touch.clientY, touch.clientX)
                      );
                    }}
                    onTouchEnd={(e) => {
                      console.log(draggingElementDetails);
                      const hoveringSectionId = findSectionIdByMousePosition(
                        draggingElementDetails.clientY,
                        draggingElementDetails.clientX
                      );
                      console.log(hoveringSectionId);
                      setDraggingElement("");
                      setDraggingDisplay(null);
                      handleDrop(
                        draggingElementDetails,
                        hoveringSectionId,
                        "dynamic-cart-element"
                      );
                    }}
                    style={{
                      background: "white",
                      border: `2px solid ${primaryColor}`,
                      display: "flex",
                      alignItems: "center",
                      width: "calc(47% - 20px)",
                      borderRadius: "8px",
                      display: "flex",
                      padding: "10px",
                      touchAction: "none",
                    }}
                  >
                    <ShoppingCartIcon
                      style={{ color: primaryColor, fontSize: "25px" }}
                    />
                    <div style={{ textAlign: "left", marginLeft: "10px" }}>
                      <div
                        style={{
                          color: primaryColor,
                          fontSize: "13px",
                          fontWeight: 700,
                        }}
                      >
                        My Cart
                      </div>
                      <div
                        style={{
                          color: "grey",
                          fontSize: "9px",
                          fontWeight: 400,
                        }}
                      >
                        A simple text field with highly customizable features
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
          {pageDesigning === "Product_Dynamic" ||
            pageDesigning === "Event_Dynamic" ||
            pageDesigning === "News_Dynamic" ? (
            <>
              <div
                style={{
                  color: "grey",
                  padding: "10px 20px",
                  borderBottom: "1px solid lightgrey",
                  textAlign: "left",
                }}
              >
                Dynamic Elements
              </div>
              <div
                style={{
                  overflowY: "auto",
                  overflowX: "hidden",
                  width: "calc(100% - 30px)",
                  padding: "20px",
                  gap: "10px",
                  flexWrap: "wrap",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {"dynamic title"
                  .toLowerCase()
                  .includes(searchDrawerInput.toLowerCase()) ? (
                  <div
                    className="toolbar-element toolbar-button"
                    draggable
                    onDragStart={(event) => {
                      handleDragStart(event, "dynamic-title-element");
                    }}
                    onTouchStart={(e) => {
                      const touch = e.touches[0];
                      console.log(touch);
                      setDraggingElementDetails({
                        clientX: touch.clientX,
                        clientY: touch.clientY,
                      });
                      setDraggingElement("dynamic-title-element");
                      setDraggingDisplay(
                        <div
                          className="toolbar-element toolbar-button"
                          style={{
                            background: "white",
                            border: `2px solid ${primaryColor}`,
                            display: "flex",
                            alignItems: "center",
                            width: "calc(47% - 20px)",
                            borderRadius: "8px",
                            display: "flex",
                            padding: "10px",
                            touchAction: "none",
                          }}
                        >
                          <TextFieldsIcon
                            style={{ color: primaryColor, fontSize: "25px" }}
                          />
                          <div style={{ textAlign: "left", marginLeft: "10px" }}>
                            <div
                              style={{
                                color: primaryColor,
                                fontSize: "13px",
                                fontWeight: 700,
                              }}
                            >
                              Dynamic Title
                            </div>
                            <div
                              style={{
                                color: "grey",
                                fontSize: "9px",
                                fontWeight: 400,
                              }}
                            >
                              A simple text field with highly customizable features
                            </div>
                          </div>
                        </div>
                      );
                    }}
                    onTouchMove={(e) => {
                      const touch = e.touches[0];
                      console.log(touch);
                      setDraggingElementDetails({
                        clientX: touch.clientX,
                        clientY: touch.clientY,
                      });
                      setDraggedOverSectionId(
                        findSectionIdByMousePosition(touch.clientY, touch.clientX)
                      );
                    }}
                    onTouchEnd={(e) => {
                      console.log(draggingElementDetails);
                      const hoveringSectionId = findSectionIdByMousePosition(
                        draggingElementDetails.clientY,
                        draggingElementDetails.clientX
                      );
                      console.log(hoveringSectionId);
                      setDraggingElement("");
                      setDraggingDisplay(null);
                      handleDrop(
                        draggingElementDetails,
                        hoveringSectionId,
                        "dynamic-title-element"
                      );
                    }}
                    style={{
                      background: "white",
                      border: `2px solid ${primaryColor}`,
                      display: "flex",
                      alignItems: "center",
                      width: "calc(47% - 20px)",
                      borderRadius: "8px",
                      display: "flex",
                      padding: "10px",
                      touchAction: "none",
                    }}
                  >
                    <TextFieldsIcon
                      style={{ color: primaryColor, fontSize: "25px" }}
                    />
                    <div style={{ textAlign: "left", marginLeft: "10px" }}>
                      <div
                        style={{
                          color: primaryColor,
                          fontSize: "13px",
                          fontWeight: 700,
                        }}
                      >
                        Dynamic Title
                      </div>
                      <div
                        style={{
                          color: "grey",
                          fontSize: "9px",
                          fontWeight: 400,
                        }}
                      >
                        A simple text field with highly customizable features
                      </div>
                    </div>
                  </div>
                ) : null}
                {"dynamic description"
                  .toLowerCase()
                  .includes(searchDrawerInput.toLowerCase()) ? (
                  <div
                    className="toolbar-element toolbar-button"
                    draggable
                    onDragStart={(event) => {
                      handleDragStart(event, "dynamic-description-element");
                    }}
                    onTouchStart={(e) => {
                      const touch = e.touches[0];
                      console.log(touch);
                      setDraggingElementDetails({
                        clientX: touch.clientX,
                        clientY: touch.clientY,
                      });
                      setDraggingElement("dynamic-description-element");
                      setDraggingDisplay(
                        <div
                          className="toolbar-element toolbar-button"
                          style={{
                            background: "white",
                            border: `2px solid ${primaryColor}`,
                            display: "flex",
                            alignItems: "center",
                            width: "calc(47% - 20px)",
                            borderRadius: "8px",
                            display: "flex",
                            padding: "10px",
                            touchAction: "none",
                          }}
                        >
                          <TextFieldsIcon
                            style={{ color: primaryColor, fontSize: "25px" }}
                          />
                          <div style={{ textAlign: "left", marginLeft: "10px" }}>
                            <div
                              style={{
                                color: primaryColor,
                                fontSize: "13px",
                                fontWeight: 700,
                              }}
                            >
                              Dynamic Description
                            </div>
                            <div
                              style={{
                                color: "grey",
                                fontSize: "9px",
                                fontWeight: 400,
                              }}
                            >
                              A simple text field with highly customizable features
                            </div>
                          </div>
                        </div>
                      );
                    }}
                    onTouchMove={(e) => {
                      const touch = e.touches[0];
                      console.log(touch);
                      setDraggingElementDetails({
                        clientX: touch.clientX,
                        clientY: touch.clientY,
                      });
                      setDraggedOverSectionId(
                        findSectionIdByMousePosition(touch.clientY, touch.clientX)
                      );
                    }}
                    onTouchEnd={(e) => {
                      console.log(draggingElementDetails);
                      const hoveringSectionId = findSectionIdByMousePosition(
                        draggingElementDetails.clientY,
                        draggingElementDetails.clientX
                      );
                      console.log(hoveringSectionId);
                      setDraggingElement("");
                      setDraggingDisplay(null);
                      handleDrop(
                        draggingElementDetails,
                        hoveringSectionId,
                        "dynamic-description-element"
                      );
                    }}
                    style={{
                      background: "white",
                      border: `2px solid ${primaryColor}`,
                      display: "flex",
                      alignItems: "center",
                      width: "calc(47% - 20px)",
                      borderRadius: "8px",
                      display: "flex",
                      padding: "10px",
                      touchAction: "none",
                    }}
                  >
                    <TextFieldsIcon
                      style={{ color: primaryColor, fontSize: "25px" }}
                    />
                    <div style={{ textAlign: "left", marginLeft: "10px" }}>
                      <div
                        style={{
                          color: primaryColor,
                          fontSize: "13px",
                          fontWeight: 700,
                        }}
                      >
                        Dynamic Description
                      </div>
                      <div
                        style={{
                          color: "grey",
                          fontSize: "9px",
                          fontWeight: 400,
                        }}
                      >
                        A simple text field with highly customizable features
                      </div>
                    </div>
                  </div>
                ) : null}
                {"dynamic image"
                  .toLowerCase()
                  .includes(searchDrawerInput.toLowerCase()) ? (
                  <div
                    className="toolbar-element toolbar-button"
                    draggable
                    onDragStart={(event) => {
                      handleDragStart(event, "dynamic-image-element");
                    }}
                    onTouchStart={(e) => {
                      const touch = e.touches[0];
                      console.log(touch);
                      setDraggingElementDetails({
                        clientX: touch.clientX,
                        clientY: touch.clientY,
                      });
                      setDraggingElement("dynamic-image-element");
                      setDraggingDisplay(
                        <div
                          className="toolbar-element toolbar-button"
                          style={{
                            background: "white",
                            border: `2px solid ${primaryColor}`,
                            display: "flex",
                            alignItems: "center",
                            width: "calc(47% - 20px)",
                            borderRadius: "8px",
                            display: "flex",
                            padding: "10px",
                            touchAction: "none",
                          }}
                        >
                          <ImageIcon
                            style={{ color: primaryColor, fontSize: "25px" }}
                          />
                          <div style={{ textAlign: "left", marginLeft: "10px" }}>
                            <div
                              style={{
                                color: primaryColor,
                                fontSize: "13px",
                                fontWeight: 700,
                              }}
                            >
                              Dynamic Image
                            </div>
                            <div
                              style={{
                                color: "grey",
                                fontSize: "9px",
                                fontWeight: 400,
                              }}
                            >
                              A simple text field with highly customizable features
                            </div>
                          </div>
                        </div>
                      );
                    }}
                    onTouchMove={(e) => {
                      const touch = e.touches[0];
                      console.log(touch);
                      setDraggingElementDetails({
                        clientX: touch.clientX,
                        clientY: touch.clientY,
                      });
                      setDraggedOverSectionId(
                        findSectionIdByMousePosition(touch.clientY, touch.clientX)
                      );
                    }}
                    onTouchEnd={(e) => {
                      console.log(draggingElementDetails);
                      const hoveringSectionId = findSectionIdByMousePosition(
                        draggingElementDetails.clientY,
                        draggingElementDetails.clientX
                      );
                      console.log(hoveringSectionId);
                      setDraggingElement("");
                      setDraggingDisplay(null);
                      handleDrop(
                        draggingElementDetails,
                        hoveringSectionId,
                        "dynamic-image-element"
                      );
                    }}
                    style={{
                      background: "white",
                      border: `2px solid ${primaryColor}`,
                      display: "flex",
                      alignItems: "center",
                      width: "calc(47% - 20px)",
                      borderRadius: "8px",
                      display: "flex",
                      padding: "10px",
                      touchAction: "none",
                    }}
                  >
                    <ImageIcon
                      style={{ color: primaryColor, fontSize: "25px" }}
                    />
                    <div style={{ textAlign: "left", marginLeft: "10px" }}>
                      <div
                        style={{
                          color: primaryColor,
                          fontSize: "13px",
                          fontWeight: 700,
                        }}
                      >
                        Dynamic Image
                      </div>
                      <div
                        style={{
                          color: "grey",
                          fontSize: "9px",
                          fontWeight: 400,
                        }}
                      >
                        A simple text field with highly customizable features
                      </div>
                    </div>
                  </div>
                ) : null}
                {pageDesigning === "Event_Dynamic" ||
                  pageDesigning === "News_Dynamic" ? (
                  "dynamic vertical details"
                    .toLowerCase()
                    .includes(searchDrawerInput.toLowerCase()) ? (
                    <div
                      className="toolbar-element toolbar-button"
                      draggable
                      onDragStart={(event) => {
                        handleDragStart(event, "dynamic-details-element");
                      }}
                      onTouchStart={(e) => {
                        const touch = e.touches[0];
                        console.log(touch);
                        setDraggingElementDetails({
                          clientX: touch.clientX,
                          clientY: touch.clientY,
                        });
                        setDraggingElement("dynamic-details-element");
                        setDraggingDisplay(
                          <div
                            className="toolbar-element toolbar-button"
                            style={{
                              background: "white",
                              border: `2px solid ${primaryColor}`,
                              display: "flex",
                              alignItems: "center",
                              width: "calc(47% - 20px)",
                              borderRadius: "8px",
                              display: "flex",
                              padding: "10px",
                              touchAction: "none",
                            }}
                          >
                            <FormatListBulletedIcon
                              style={{ color: primaryColor, fontSize: "25px" }}
                            />
                            <div style={{ textAlign: "left", marginLeft: "10px" }}>
                              <div
                                style={{
                                  color: primaryColor,
                                  fontSize: "13px",
                                  fontWeight: 700,
                                }}
                              >
                                Dynamic Vertical Details
                              </div>
                              <div
                                style={{
                                  color: "grey",
                                  fontSize: "9px",
                                  fontWeight: 400,
                                }}
                              >
                                A simple text field with highly customizable features
                              </div>
                            </div>
                          </div>
                        );
                      }}
                      onTouchMove={(e) => {
                        const touch = e.touches[0];
                        console.log(touch);
                        setDraggingElementDetails({
                          clientX: touch.clientX,
                          clientY: touch.clientY,
                        });
                        setDraggedOverSectionId(
                          findSectionIdByMousePosition(touch.clientY, touch.clientX)
                        );
                      }}
                      onTouchEnd={(e) => {
                        console.log(draggingElementDetails);
                        const hoveringSectionId = findSectionIdByMousePosition(
                          draggingElementDetails.clientY,
                          draggingElementDetails.clientX
                        );
                        console.log(hoveringSectionId);
                        setDraggingElement("");
                        setDraggingDisplay(null);
                        handleDrop(
                          draggingElementDetails,
                          hoveringSectionId,
                          "dynamic-details-element"
                        );
                      }}
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <FormatListBulletedIcon
                        style={{ color: primaryColor, fontSize: "25px" }}
                      />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          Dynamic Vertical Details
                        </div>
                        <div
                          style={{
                            color: "grey",
                            fontSize: "9px",
                            fontWeight: 400,
                          }}
                        >
                          A simple text field with highly customizable features
                        </div>
                      </div>
                    </div>
                  ) : null
                ) : null}
                {pageDesigning === "Event_Dynamic" ||
                  pageDesigning === "News_Dynamic" ? (
                  "dynamic horizontal details"
                    .toLowerCase()
                    .includes(searchDrawerInput.toLowerCase()) ? (
                    <div
                      className="toolbar-element toolbar-button"
                      draggable
                      onDragStart={(event) => {
                        handleDragStart(
                          event,
                          "dynamic-horizontal-details-element"
                        );
                      }}
                      onTouchStart={(e) => {
                        const touch = e.touches[0];
                        console.log(touch);
                        setDraggingElementDetails({
                          clientX: touch.clientX,
                          clientY: touch.clientY,
                        });
                        setDraggingElement("dynamic-horizontal-details-element");
                        setDraggingDisplay(
                          <div
                            className="toolbar-element toolbar-button"
                            style={{
                              background: "white",
                              border: `2px solid ${primaryColor}`,
                              display: "flex",
                              alignItems: "center",
                              width: "calc(47% - 20px)",
                              borderRadius: "8px",
                              display: "flex",
                              padding: "10px",
                              touchAction: "none",
                            }}
                          >
                            <ClearAllIcon
                              style={{ color: primaryColor, fontSize: "25px" }}
                            />
                            <div style={{ textAlign: "left", marginLeft: "10px" }}>
                              <div
                                style={{
                                  color: primaryColor,
                                  fontSize: "13px",
                                  fontWeight: 700,
                                }}
                              >
                                Dynamic Horizontal Details
                              </div>
                              <div
                                style={{
                                  color: "grey",
                                  fontSize: "9px",
                                  fontWeight: 400,
                                }}
                              >
                                A simple text field with highly customizable features
                              </div>
                            </div>
                          </div>
                        );
                      }}
                      onTouchMove={(e) => {
                        const touch = e.touches[0];
                        console.log(touch);
                        setDraggingElementDetails({
                          clientX: touch.clientX,
                          clientY: touch.clientY,
                        });
                        setDraggedOverSectionId(
                          findSectionIdByMousePosition(touch.clientY, touch.clientX)
                        );
                      }}
                      onTouchEnd={(e) => {
                        console.log(draggingElementDetails);
                        const hoveringSectionId = findSectionIdByMousePosition(
                          draggingElementDetails.clientY,
                          draggingElementDetails.clientX
                        );
                        console.log(hoveringSectionId);
                        setDraggingElement("");
                        setDraggingDisplay(null);
                        handleDrop(
                          draggingElementDetails,
                          hoveringSectionId,
                          "dynamic-horizontal-details-element"
                        );
                      }}
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <ClearAllIcon
                        style={{ color: primaryColor, fontSize: "25px" }}
                      />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          Dynamic Horizontal Details
                        </div>
                        <div
                          style={{
                            color: "grey",
                            fontSize: "9px",
                            fontWeight: 400,
                          }}
                        >
                          A simple text field with highly customizable features
                        </div>
                      </div>
                    </div>
                  ) : null
                ) : null}
                {pageDesigning === "Event_Dynamic" ||
                  pageDesigning === "Product_Dynamic" ? (
                  "dynamic price"
                    .toLowerCase()
                    .includes(searchDrawerInput.toLowerCase()) ? (
                    <div
                      className="toolbar-element toolbar-button"
                      draggable
                      onDragStart={(event) => {
                        handleDragStart(event, "dynamic-price-element");
                      }}
                      onTouchStart={(e) => {
                        const touch = e.touches[0];
                        console.log(touch);
                        setDraggingElementDetails({
                          clientX: touch.clientX,
                          clientY: touch.clientY,
                        });
                        setDraggingElement("dynamic-price-element");
                        setDraggingDisplay(
                          <div
                            className="toolbar-element toolbar-button"
                            style={{
                              background: "white",
                              border: `2px solid ${primaryColor}`,
                              display: "flex",
                              alignItems: "center",
                              width: "calc(47% - 20px)",
                              borderRadius: "8px",
                              display: "flex",
                              padding: "10px",
                              touchAction: "none",
                            }}
                          >
                            <MonetizationOnIcon
                              style={{ color: primaryColor, fontSize: "25px" }}
                            />
                            <div style={{ textAlign: "left", marginLeft: "10px" }}>
                              <div
                                style={{
                                  color: primaryColor,
                                  fontSize: "13px",
                                  fontWeight: 700,
                                }}
                              >
                                Dynamic Price
                              </div>
                              <div
                                style={{
                                  color: "grey",
                                  fontSize: "9px",
                                  fontWeight: 400,
                                }}
                              >
                                A simple text field with highly customizable features
                              </div>
                            </div>
                          </div>
                        );
                      }}
                      onTouchMove={(e) => {
                        const touch = e.touches[0];
                        console.log(touch);
                        setDraggingElementDetails({
                          clientX: touch.clientX,
                          clientY: touch.clientY,
                        });
                        setDraggedOverSectionId(
                          findSectionIdByMousePosition(touch.clientY, touch.clientX)
                        );
                      }}
                      onTouchEnd={(e) => {
                        console.log(draggingElementDetails);
                        const hoveringSectionId = findSectionIdByMousePosition(
                          draggingElementDetails.clientY,
                          draggingElementDetails.clientX
                        );
                        console.log(hoveringSectionId);
                        setDraggingElement("");
                        setDraggingDisplay(null);
                        handleDrop(
                          draggingElementDetails,
                          hoveringSectionId,
                          "dynamic-price-element"
                        );
                      }}
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <MonetizationOnIcon
                        style={{ color: primaryColor, fontSize: "25px" }}
                      />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          Dynamic Price
                        </div>
                        <div
                          style={{
                            color: "grey",
                            fontSize: "9px",
                            fontWeight: 400,
                          }}
                        >
                          A simple text field with highly customizable features
                        </div>
                      </div>
                    </div>
                  ) : null
                ) : null}
                {"dynamic list attributes"
                  .toLowerCase()
                  .includes(searchDrawerInput.toLowerCase()) ? (
                  <div
                    className="toolbar-element toolbar-button"
                    draggable
                    onDragStart={(event) => {
                      handleDragStart(event, "dynamic-list-attribute-element");
                    }}
                    onTouchStart={(e) => {
                      const touch = e.touches[0];
                      console.log(touch);
                      setDraggingElementDetails({
                        clientX: touch.clientX,
                        clientY: touch.clientY,
                      });
                      setDraggingElement("dynamic-list-attribute-element");
                      setDraggingDisplay(
                        <div
                          className="toolbar-element toolbar-button"
                          style={{
                            background: "white",
                            border: `2px solid ${primaryColor}`,
                            display: "flex",
                            alignItems: "center",
                            width: "calc(47% - 20px)",
                            borderRadius: "8px",
                            display: "flex",
                            padding: "10px",
                            touchAction: "none",
                          }}
                        >
                          <FormatListBulletedIcon
                            style={{ color: primaryColor, fontSize: "25px" }}
                          />
                          <div style={{ textAlign: "left", marginLeft: "10px" }}>
                            <div
                              style={{
                                color: primaryColor,
                                fontSize: "13px",
                                fontWeight: 700,
                              }}
                            >
                              Dynamic List Attributes
                            </div>
                            <div
                              style={{
                                color: "grey",
                                fontSize: "9px",
                                fontWeight: 400,
                              }}
                            >
                              A simple text field with highly customizable features
                            </div>
                          </div>
                        </div>
                      );
                    }}
                    onTouchMove={(e) => {
                      const touch = e.touches[0];
                      console.log(touch);
                      setDraggingElementDetails({
                        clientX: touch.clientX,
                        clientY: touch.clientY,
                      });
                      setDraggedOverSectionId(
                        findSectionIdByMousePosition(touch.clientY, touch.clientX)
                      );
                    }}
                    onTouchEnd={(e) => {
                      console.log(draggingElementDetails);
                      const hoveringSectionId = findSectionIdByMousePosition(
                        draggingElementDetails.clientY,
                        draggingElementDetails.clientX
                      );
                      console.log(hoveringSectionId);
                      setDraggingElement("");
                      setDraggingDisplay(null);
                      handleDrop(
                        draggingElementDetails,
                        hoveringSectionId,
                        "dynamic-list-attribute-element"
                      );
                    }}
                    style={{
                      background: "white",
                      border: `2px solid ${primaryColor}`,
                      display: "flex",
                      alignItems: "center",
                      width: "calc(47% - 20px)",
                      borderRadius: "8px",
                      display: "flex",
                      padding: "10px",
                      touchAction: "none",
                    }}
                  >
                    <FormatListBulletedIcon
                      style={{ color: primaryColor, fontSize: "25px" }}
                    />
                    <div style={{ textAlign: "left", marginLeft: "10px" }}>
                      <div
                        style={{
                          color: primaryColor,
                          fontSize: "13px",
                          fontWeight: 700,
                        }}
                      >
                        Dynamic List Attributes
                      </div>
                      <div
                        style={{
                          color: "grey",
                          fontSize: "9px",
                          fontWeight: 400,
                        }}
                      >
                        A simple text field with highly customizable features
                      </div>
                    </div>
                  </div>
                ) : null}
                {"dynamic table attributes"
                  .toLowerCase()
                  .includes(searchDrawerInput.toLowerCase()) ? (
                  <div
                    className="toolbar-element toolbar-button"
                    draggable
                    onDragStart={(event) => {
                      handleDragStart(event, "dynamic-table-attribute-element");
                    }}
                    onTouchStart={(e) => {
                      const touch = e.touches[0];
                      console.log(touch);
                      setDraggingElementDetails({
                        clientX: touch.clientX,
                        clientY: touch.clientY,
                      });
                      setDraggingElement("dynamic-table-attribute-element");
                      setDraggingDisplay(
                        <div
                          className="toolbar-element toolbar-button"
                          style={{
                            background: "white",
                            border: `2px solid ${primaryColor}`,
                            display: "flex",
                            alignItems: "center",
                            width: "calc(47% - 20px)",
                            borderRadius: "8px",
                            display: "flex",
                            padding: "10px",
                            touchAction: "none",
                          }}
                        >
                          <BackupTableIcon
                            style={{ color: primaryColor, fontSize: "25px" }}
                          />
                          <div style={{ textAlign: "left", marginLeft: "10px" }}>
                            <div
                              style={{
                                color: primaryColor,
                                fontSize: "13px",
                                fontWeight: 700,
                              }}
                            >
                              Dynamic Table Attributes
                            </div>
                            <div
                              style={{
                                color: "grey",
                                fontSize: "9px",
                                fontWeight: 400,
                              }}
                            >
                              A simple text field with highly customizable features
                            </div>
                          </div>
                        </div>
                      );
                    }}
                    onTouchMove={(e) => {
                      const touch = e.touches[0];
                      console.log(touch);
                      setDraggingElementDetails({
                        clientX: touch.clientX,
                        clientY: touch.clientY,
                      });
                      setDraggedOverSectionId(
                        findSectionIdByMousePosition(touch.clientY, touch.clientX)
                      );
                    }}
                    onTouchEnd={(e) => {
                      console.log(draggingElementDetails);
                      const hoveringSectionId = findSectionIdByMousePosition(
                        draggingElementDetails.clientY,
                        draggingElementDetails.clientX
                      );
                      console.log(hoveringSectionId);
                      setDraggingElement("");
                      setDraggingDisplay(null);
                      handleDrop(
                        draggingElementDetails,
                        hoveringSectionId,
                        "dynamic-table-attribute-element"
                      );
                    }}
                    style={{
                      background: "white",
                      border: `2px solid ${primaryColor}`,
                      display: "flex",
                      alignItems: "center",
                      width: "calc(47% - 20px)",
                      borderRadius: "8px",
                      display: "flex",
                      padding: "10px",
                      touchAction: "none",
                    }}
                  >
                    <BackupTableIcon
                      style={{ color: primaryColor, fontSize: "25px" }}
                    />
                    <div style={{ textAlign: "left", marginLeft: "10px" }}>
                      <div
                        style={{
                          color: primaryColor,
                          fontSize: "13px",
                          fontWeight: 700,
                        }}
                      >
                        Dynamic Table Attributes
                      </div>
                      <div
                        style={{
                          color: "grey",
                          fontSize: "9px",
                          fontWeight: 400,
                        }}
                      >
                        A simple text field with highly customizable features
                      </div>
                    </div>
                  </div>
                ) : null}
                {"Dynamic grid attributes"
                  .toLowerCase()
                  .includes(searchDrawerInput.toLowerCase()) ? (
                  <div
                    className="toolbar-element toolbar-button"
                    draggable
                    onDragStart={(event) => {
                      handleDragStart(event, "dynamic-grid-attribute-element");
                    }}
                    onTouchStart={(e) => {
                      const touch = e.touches[0];
                      console.log(touch);
                      setDraggingElementDetails({
                        clientX: touch.clientX,
                        clientY: touch.clientY,
                      });
                      setDraggingElement("dynamic-grid-attribute-element");
                      setDraggingDisplay(
                        <div
                          className="toolbar-element toolbar-button"
                          style={{
                            background: "white",
                            border: `2px solid ${primaryColor}`,
                            display: "flex",
                            alignItems: "center",
                            width: "calc(47% - 20px)",
                            borderRadius: "8px",
                            display: "flex",
                            padding: "10px",
                            touchAction: "none",
                          }}
                        >
                          <BackupTableIcon
                            style={{ color: primaryColor, fontSize: "25px" }}
                          />
                          <div style={{ textAlign: "left", marginLeft: "10px" }}>
                            <div
                              style={{
                                color: primaryColor,
                                fontSize: "13px",
                                fontWeight: 700,
                              }}
                            >
                              Dynamic Grid Attributes
                            </div>
                            <div
                              style={{
                                color: "grey",
                                fontSize: "9px",
                                fontWeight: 400,
                              }}
                            >
                              A simple text field with highly customizable features
                            </div>
                          </div>
                        </div>
                      );
                    }}
                    onTouchMove={(e) => {
                      const touch = e.touches[0];
                      console.log(touch);
                      setDraggingElementDetails({
                        clientX: touch.clientX,
                        clientY: touch.clientY,
                      });
                      setDraggedOverSectionId(
                        findSectionIdByMousePosition(touch.clientY, touch.clientX)
                      );
                    }}
                    onTouchEnd={(e) => {
                      console.log(draggingElementDetails);
                      const hoveringSectionId = findSectionIdByMousePosition(
                        draggingElementDetails.clientY,
                        draggingElementDetails.clientX
                      );
                      console.log(hoveringSectionId);
                      setDraggingElement("");
                      setDraggingDisplay(null);
                      handleDrop(
                        draggingElementDetails,
                        hoveringSectionId,
                        "dynamic-grid-attribute-element"
                      );
                    }}
                    style={{
                      background: "white",
                      border: `2px solid ${primaryColor}`,
                      display: "flex",
                      alignItems: "center",
                      width: "calc(47% - 20px)",
                      borderRadius: "8px",
                      display: "flex",
                      padding: "10px",
                      touchAction: "none",
                    }}
                  >
                    <BackupTableIcon
                      style={{ color: primaryColor, fontSize: "25px" }}
                    />
                    <div style={{ textAlign: "left", marginLeft: "10px" }}>
                      <div
                        style={{
                          color: primaryColor,
                          fontSize: "13px",
                          fontWeight: 700,
                        }}
                      >
                        Dynamic Grid Attributes
                      </div>
                      <div
                        style={{
                          color: "grey",
                          fontSize: "9px",
                          fontWeight: 400,
                        }}
                      >
                        A simple text field with highly customizable features
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          ) : null}
          <div
            style={{
              color: "grey",
              padding: "10px 20px",
              borderBottom: "1px solid lightgrey",
              textAlign: "left",
            }}
          >
            Elements
          </div>
          <div
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              width: "calc(100% - 30px)",
              padding: "20px",
              gap: "10px",
              flexWrap: "wrap",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {"My Account Header"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "dynamic-account-header-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("dynamic-account-header-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <AppsIcon
                        style={{ color: primaryColor, fontSize: "25px" }}
                      />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          My Account Header
                        </div>
                        <div
                          style={{
                            color: "grey",
                            fontSize: "9px",
                            fontWeight: 400,
                          }}
                        >
                          A simple text field with highly customizable features
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "dynamic-account-header-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <AppsIcon style={{ color: primaryColor, fontSize: "25px" }} />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    My Account Header
                  </div>
                  <div
                    style={{
                      color: "grey",
                      fontSize: "9px",
                      fontWeight: 400,
                    }}
                  >
                    A simple text field with highly customizable features
                  </div>
                </div>
              </div>
            ) : null}
            {"social link element"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "social-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("social-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <ShareIcon style={{ color: primaryColor, fontSize: "25px" }} />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          Social Links Element
                        </div>
                        <div
                          style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                        >
                          A simple text field with highly customizable features
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "social-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <ShareIcon style={{ color: primaryColor, fontSize: "25px" }} />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Social Links Element
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    A simple text field with highly customizable features
                  </div>
                </div>
              </div>
            ) : null}
            {"horizontal divider element"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "horizontal-divider-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("horizontal-divider-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <HorizontalRuleIcon
                        style={{ color: primaryColor, fontSize: "25px" }}
                      />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          Horizontal Divider Element
                        </div>
                        <div
                          style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                        >
                          A simple text field with highly customizable features
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "horizontal-divider-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <HorizontalRuleIcon
                  style={{ color: primaryColor, fontSize: "25px" }}
                />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Horizontal Divider Element
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    A simple text field with highly customizable features
                  </div>
                </div>
              </div>
            ) : null}
            {"vertical divider element"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "vertical-divider-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("vertical-divider-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <HorizontalRuleIcon
                        style={{
                          color: primaryColor,
                          fontSize: "25px",
                          rotate: "90deg",
                        }}
                      />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          Vertical Divider Element
                        </div>
                        <div
                          style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                        >
                          A simple text field with highly customizable features
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "vertical-divider-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <HorizontalRuleIcon
                  style={{
                    color: primaryColor,
                    fontSize: "25px",
                    rotate: "90deg",
                  }}
                />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Vertical Divider Element
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    A simple text field with highly customizable features
                  </div>
                </div>
              </div>
            ) : null}
            {"icons element"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "icon-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("icon-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <AppsIcon style={{ color: primaryColor, fontSize: "25px" }} />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          Icons Element
                        </div>
                        <div
                          style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                        >
                          A simple text field with highly customizable features
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "icon-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <AppsIcon style={{ color: primaryColor, fontSize: "25px" }} />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Icons Element
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    A simple text field with highly customizable features
                  </div>
                </div>
              </div>
            ) : null}
            {"accordian element"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "accordian-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("accordian-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <StorageIcon
                        style={{ color: primaryColor, fontSize: "25px" }}
                      />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          Accordian Element
                        </div>
                        <div
                          style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                        >
                          A simple text field with highly customizable features
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "accordian-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <StorageIcon
                  style={{ color: primaryColor, fontSize: "25px" }}
                />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Accordian Element
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    A simple text field with highly customizable features
                  </div>
                </div>
              </div>
            ) : null}
            {"switch tab element"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "switch-tab-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("switch-tab-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <TabIcon style={{ color: primaryColor, fontSize: "25px" }} />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          Switch Tab Element
                        </div>
                        <div
                          style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                        >
                          A simple text field with highly customizable features
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "switch-tab-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <TabIcon style={{ color: primaryColor, fontSize: "25px" }} />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Switch Tab Element
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    A simple text field with highly customizable features
                  </div>
                </div>
              </div>
            ) : null}
            {/*  
            {"login element"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "login-element");
                }}
                style={{
                  background: "white",
                  border: "2px solid #ad0a49",
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                }}
              >
                <TextFieldsIcon
                  style={{ color: "#ad0a49", fontSize: "25px" }}
                />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: "#ad0a49",
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Login Element
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    A simple text field with highly customizable features
                  </div>
                </div>
              </div>
            ) : null}
            */}
            {"map element"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "map-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("map-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <MapIcon style={{ color: primaryColor, fontSize: "25px" }} />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          Map Element
                        </div>
                        <div
                          style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                        >
                          A simple text field with highly customizable features
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "map-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <MapIcon style={{ color: primaryColor, fontSize: "25px" }} />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Map Element
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    A simple text field with highly customizable features
                  </div>
                </div>
              </div>
            ) : null}
            {"video element"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "video-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("video-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <OndemandVideoIcon
                        style={{ color: primaryColor, fontSize: "25px" }}
                      />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          Video Element
                        </div>
                        <div
                          style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                        >
                          A simple text field with highly customizable features
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "video-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <OndemandVideoIcon
                  style={{ color: primaryColor, fontSize: "25px" }}
                />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Video Element
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    A simple text field with highly customizable features
                  </div>
                </div>
              </div>
            ) : null}
            {"code element"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "code-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("code-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <CodeIcon style={{ color: primaryColor, fontSize: "25px" }} />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          Code Element
                        </div>
                        <div
                          style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                        >
                          A simple text field with highly customizable features
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "code-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <CodeIcon style={{ color: primaryColor, fontSize: "25px" }} />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Code Element
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    A simple text field with highly customizable features
                  </div>
                </div>
              </div>
            ) : null}
            {"text element"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "text-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("text-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <TextFieldsIcon
                        style={{ color: primaryColor, fontSize: "25px" }}
                      />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          Text Element
                        </div>
                        <div
                          style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                        >
                          A simple text field with highly customizable features
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "text-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <TextFieldsIcon
                  style={{ color: primaryColor, fontSize: "25px" }}
                />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Text Element
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    A simple text field with highly customizable features
                  </div>
                </div>
              </div>
            ) : null}

            {"image element"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "image-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("image-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <AddPhotoAlternateIcon
                        style={{ color: primaryColor, fontSize: "25px" }}
                      />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          Image Element
                        </div>
                        <div
                          style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                        >
                          A simple Image field with highly customizable features
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "image-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <AddPhotoAlternateIcon
                  style={{ color: primaryColor, fontSize: "25px" }}
                />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Image Element
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    A simple Image field with highly customizable features
                  </div>
                </div>
              </div>
            ) : null}
            {"Box element"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "box-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("box-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <CheckBoxOutlineBlankIcon
                        style={{ color: primaryColor, fontSize: "25px" }}
                      />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          Box Element
                        </div>
                        <div
                          style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                        >
                          A simple box that can be fully customized and hold child
                          items.
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "box-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <CheckBoxOutlineBlankIcon
                  style={{ color: primaryColor, fontSize: "25px" }}
                />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Box Element
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    A simple box that can be fully customized and hold child
                    items.
                  </div>
                </div>
              </div>
            ) : null}
            {"button element"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "button-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("button-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <SmartButtonIcon
                        style={{ color: primaryColor, fontSize: "25px" }}
                      />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          Button Element
                        </div>
                        <div
                          style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                        >
                          A simple Button that you can customize and link it to any
                          links
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "button-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <SmartButtonIcon
                  style={{ color: primaryColor, fontSize: "25px" }}
                />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Button Element
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    A simple Button that you can customize and link it to any
                    links
                  </div>
                </div>
              </div>
            ) : null}
            {"Form element"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "form-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("form-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <ListAltIcon
                        style={{ color: primaryColor, fontSize: "25px" }}
                      />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          Form Element
                        </div>
                        <div
                          style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                        >
                          A simple form module you can link to any of your created
                          forms and users can fill them up and user's submission will
                          appear on your form submissions tab.
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "form-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <ListAltIcon
                  style={{ color: primaryColor, fontSize: "25px" }}
                />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Form Element
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    A simple form module you can link to any of your created
                    forms and users can fill them up and user's submission will
                    appear on your form submissions tab.
                  </div>
                </div>
              </div>
            ) : null}
            {"Image gallery slider"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "image-gallery-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("image-gallery-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <CollectionsIcon
                        style={{ color: primaryColor, fontSize: "25px" }}
                      />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          Image Gallery Slider
                        </div>
                        <div
                          style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                        >
                          An image gallery slider for you to
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "image-gallery-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <CollectionsIcon
                  style={{ color: primaryColor, fontSize: "25px" }}
                />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Image Gallery Slider
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    An image gallery slider for you to
                  </div>
                </div>
              </div>
            ) : null}
            {"Menu element"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "menu-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("menu-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <MenuIcon style={{ color: primaryColor, fontSize: "25px" }} />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          Menu Element
                        </div>
                        <div
                          style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                        >
                          An image gallery slider for you to
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "menu-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <MenuIcon style={{ color: primaryColor, fontSize: "25px" }} />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Menu Element
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    An image gallery slider for you to
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div
            style={{
              color: "grey",
              padding: "10px 20px",
              borderBottom: "1px solid lightgrey",
              textAlign: "left",
            }}
          >
            Products
          </div>
          <div
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              width: "calc(100% - 30px)",
              padding: "20px",
              gap: "10px",
              flexWrap: "wrap",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {"Single product element"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "product-item-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("product-item-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <CategoryIcon
                        style={{ color: primaryColor, fontSize: "25px" }}
                      />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          Single Product Element
                        </div>
                        <div
                          style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                        >
                          A module to display a singular product, and is fully
                          customizable to your liking.
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "product-item-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <CategoryIcon
                  style={{ color: primaryColor, fontSize: "25px" }}
                />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Single Product Element
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    A module to display a singular product, and is fully
                    customizable to your liking.
                  </div>
                </div>
              </div>
            ) : null}
            {"Product search bar element"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "product-search-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("product-search-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <SearchIcon style={{ color: primaryColor, fontSize: "25px" }} />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          Product Search Bar Element
                        </div>
                        <div
                          style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                        >
                          An product grid for you to display one or more product for
                          your users to see and is linked to the dynamic product page.
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "product-search-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <SearchIcon style={{ color: primaryColor, fontSize: "25px" }} />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Product Search Bar Element
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    An product grid for you to display one or more product for
                    your users to see and is linked to the dynamic product page.
                  </div>
                </div>
              </div>
            ) : null}
            {"product filter tags and categories element"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "product-filter-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("product-filter-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <BlurLinearIcon
                        style={{ color: primaryColor, fontSize: "25px" }}
                      />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          Product Filter Tags & Categories Element
                        </div>
                        <div
                          style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                        >
                          An product grid for you to display one or more product for
                          your users to see and is linked to the dynamic product page.
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "product-filter-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <BlurLinearIcon
                  style={{ color: primaryColor, fontSize: "25px" }}
                />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Product Filter Tags & Categories Element
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    An product grid for you to display one or more product for
                    your users to see and is linked to the dynamic product page.
                  </div>
                </div>
              </div>
            ) : null}
            {"product grid element"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "product-carousel-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("product-carousel-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <ViewModuleIcon
                        style={{ color: primaryColor, fontSize: "25px" }}
                      />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          Product Grid Element
                        </div>
                        <div
                          style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                        >
                          A product grid for you to display one or more products for
                          your users to see and is linked to the dynamic product page.
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "product-carousel-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <ViewModuleIcon
                  style={{ color: primaryColor, fontSize: "25px" }}
                />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Product Grid Element
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    A product grid for you to display one or more products for
                    your users to see and is linked to the dynamic product page.
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div
            style={{
              color: "grey",
              padding: "10px 20px",
              borderBottom: "1px solid lightgrey",
              textAlign: "left",
            }}
          >
            Events
          </div>
          <div
            style={{
              overflowX: "hidden",
              overflowY: "auto",
              width: "calc(100% - 30px)",
              padding: "20px",
              gap: "10px",
              flexWrap: "wrap",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {"Single Event Element"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "event-item-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("event-item-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <LocalActivityIcon
                        style={{ color: primaryColor, fontSize: "25px" }}
                      />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          Single Event Element
                        </div>
                        <div
                          style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                        >
                          A module to display a singular event, and is fully
                          customizable to your liking.
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "event-item-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <LocalActivityIcon
                  style={{ color: primaryColor, fontSize: "25px" }}
                />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Single Event Element
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    A module to display a singular event, and is fully
                    customizable to your liking.
                  </div>
                </div>
              </div>
            ) : null}
            {"Event search bar element"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "event-search-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("event-search-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <SearchIcon style={{ color: primaryColor, fontSize: "25px" }} />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          Event Search Bar Element
                        </div>
                        <div
                          style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                        >
                          An events grid for you to display one or more events for
                          your users to see and is linked to the dynamic events page.
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "event-search-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <SearchIcon style={{ color: primaryColor, fontSize: "25px" }} />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Event Search Bar Element
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    An events grid for you to display one or more events for
                    your users to see and is linked to the dynamic events page.
                  </div>
                </div>
              </div>
            ) : null}
            {"Event filter tags and categories element"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "event-filter-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("event-filter-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <BlurLinearIcon
                        style={{ color: primaryColor, fontSize: "25px" }}
                      />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          Event Filter Tags & Categories Element
                        </div>
                        <div
                          style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                        >
                          An events grid for you to display one or more events for
                          your users to see and is linked to the dynamic events page.
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "event-filter-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <BlurLinearIcon
                  style={{ color: primaryColor, fontSize: "25px" }}
                />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Event Filter Tags & Categories Element
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    An events grid for you to display one or more events for
                    your users to see and is linked to the dynamic events page.
                  </div>
                </div>
              </div>
            ) : null}
            {"Event grid element"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "event-carousel-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("event-carousel-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <ViewModuleIcon
                        style={{ color: primaryColor, fontSize: "25px" }}
                      />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          Event Grid Element
                        </div>
                        <div
                          style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                        >
                          An events grid for you to display one or more events for
                          your users to see and is linked to the dynamic events page.
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "event-carousel-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <ViewModuleIcon
                  style={{ color: primaryColor, fontSize: "25px" }}
                />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Event Grid Element
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    An events grid for you to display one or more events for
                    your users to see and is linked to the dynamic events page.
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div
            style={{
              color: "grey",
              padding: "10px 20px",
              borderBottom: "1px solid lightgrey",
              textAlign: "left",
            }}
          >
            News
          </div>
          <div
            style={{
              overflowX: "hidden",
              overflowY: "auto",
              width: "calc(100% - 30px)",
              padding: "20px",
              gap: "10px",
              flexWrap: "wrap",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {"News search bar element"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "news-search-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("news-search-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <SearchIcon style={{ color: primaryColor, fontSize: "25px" }} />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          News Search Bar Element
                        </div>
                        <div
                          style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                        >
                          An news grid for you to display one or more news for your
                          users to see and is linked to the dynamic news page.
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "news-search-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <SearchIcon style={{ color: primaryColor, fontSize: "25px" }} />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    News Search Bar Element
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    An news grid for you to display one or more news for your
                    users to see and is linked to the dynamic news page.
                  </div>
                </div>
              </div>
            ) : null}
            {"news filter tags and categories element"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "news-filter-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("news-filter-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <BlurLinearIcon
                        style={{ color: primaryColor, fontSize: "25px" }}
                      />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          News Filter Tags & Categories Element
                        </div>
                        <div
                          style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                        >
                          An news grid for you to display one or more news for your
                          users to see and is linked to the dynamic news page.
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "news-filter-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <BlurLinearIcon
                  style={{ color: primaryColor, fontSize: "25px" }}
                />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    News Filter Tags & Categories Element
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    An news grid for you to display one or more news for your
                    users to see and is linked to the dynamic news page.
                  </div>
                </div>
              </div>
            ) : null}
            {"News grid element"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                draggable
                onDragStart={(event) => {
                  handleDragStart(event, "news-carousel-element");
                }}
                onTouchStart={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggingElement("news-carousel-element");
                  setDraggingDisplay(
                    <div
                      className="toolbar-element toolbar-button"
                      style={{
                        background: "white",
                        border: `2px solid ${primaryColor}`,
                        display: "flex",
                        alignItems: "center",
                        width: "calc(47% - 20px)",
                        borderRadius: "8px",
                        display: "flex",
                        padding: "10px",
                        touchAction: "none",
                      }}
                    >
                      <ViewModuleIcon
                        style={{ color: primaryColor, fontSize: "25px" }}
                      />
                      <div style={{ textAlign: "left", marginLeft: "10px" }}>
                        <div
                          style={{
                            color: primaryColor,
                            fontSize: "13px",
                            fontWeight: 700,
                          }}
                        >
                          News Grid Element
                        </div>
                        <div
                          style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                        >
                          A news grid for you to display one or more news for your
                          users to see and is linked to the dynamic news page.
                        </div>
                      </div>
                    </div>
                  );
                }}
                onTouchMove={(e) => {
                  const touch = e.touches[0];
                  console.log(touch);
                  setDraggingElementDetails({
                    clientX: touch.clientX,
                    clientY: touch.clientY,
                  });
                  setDraggedOverSectionId(
                    findSectionIdByMousePosition(touch.clientY, touch.clientX)
                  );
                }}
                onTouchEnd={(e) => {
                  console.log(draggingElementDetails);
                  const hoveringSectionId = findSectionIdByMousePosition(
                    draggingElementDetails.clientY,
                    draggingElementDetails.clientX
                  );
                  console.log(hoveringSectionId);
                  setDraggingElement("");
                  setDraggingDisplay(null);
                  handleDrop(
                    draggingElementDetails,
                    hoveringSectionId,
                    "news-carousel-element"
                  );
                }}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                  touchAction: "none",
                }}
              >
                <ViewModuleIcon
                  style={{ color: primaryColor, fontSize: "25px" }}
                />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    News Grid Element
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    A news grid for you to display one or more news for your
                    users to see and is linked to the dynamic news page.
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div
            style={{
              color: "grey",
              padding: "10px 20px",
              borderBottom: "1px solid lightgrey",
              textAlign: "left",
            }}
          >
            Page Add-ons
          </div>
          <div
            style={{
              overflowY: "auto",
              overflowX: "hidden",
              width: "calc(100% - 30px)",
              padding: "20px",
              gap: "10px",
              flexWrap: "wrap",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {"Create Slider Sections"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                onClick={createSliderSection}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                }}
              >
                <LinearScaleIcon
                  style={{ color: primaryColor, fontSize: "25px" }}
                />
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Create Slider Section
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    A section but with slider features
                  </div>
                </div>
              </div>
            ) : null}
            {"Create new sections"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                onClick={createSection}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                }}
              >
                <div style={{ color: primaryColor, fontSize: "30px" }}> + </div>
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Create New Sections
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    Creates a new section at the bottom of the page, note: you
                    can also create section below a specific section by clicking
                    on the section you'd like to add the section below.
                  </div>
                </div>
              </div>
            ) : null}
            {"toggle header and footer"
              .toLowerCase()
              .includes(searchDrawerInput.toLowerCase()) ? (
              <div
                className="toolbar-element toolbar-button"
                onClick={() => handleToggleHeaderAndFooter()}
                style={{
                  background: "white",
                  border: `2px solid ${primaryColor}`,
                  display: "flex",
                  alignItems: "center",
                  width: "calc(47% - 20px)",
                  borderRadius: "8px",
                  display: "flex",
                  padding: "10px",
                }}
              >
                <div style={{ color: primaryColor, fontSize: "30px" }}> H </div>
                <div style={{ textAlign: "left", marginLeft: "10px" }}>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "13px",
                      fontWeight: 700,
                    }}
                  >
                    Toggle Header & Footer
                  </div>
                  <div
                    style={{ color: "grey", fontSize: "9px", fontWeight: 400 }}
                  >
                    You can easily turn on and off your header and footer
                    allowing you to easily turn your website into a landing
                    page.
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {/* right toolbar */}
      <div
        style={{
          position: "fixed",
          background: "rgba(255,255,255,0.95)",
          zIndex: 501,
          right: openRightToolbar ? 15 : -59,
          top: "53%",
          transform: "translateY(-50%)",
          width: 65,
          height: "80vh",
          transition: "0.3s all ease-in-out",
          backdropFilter: "blur(15px)",
          borderRadius: "5px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
          overflowY: "auto"
        }}
      >
        <div
          style={{
            height: "5px",
            width: "100%",
            background: primaryColor,
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          {/* <div
            className="right_toolbar_item"
            onClick={(event) => {

              let top = Infinity;
              let left = Infinity;
              let bottom = 0;
              let right = 0;
              for (const poi of points) {
                top = Math.min(top, poi.y)
                left = Math.min(left, poi.x)
                bottom = Math.max(bottom, poi.y)
                right = Math.max(right, poi.x)
              }

              const height = bottom - top
              const width = (right - left) / viewportWidth * 100

              const actualLeft = left / viewportWidth * 100

              console.log(top, left, height, width)

              const sectionId = findSectionIdByMousePosition(top, left)
              const sectionIndex = baseSections.findIndex((sect) => sect.id === sectionId)
              const sectionDet = baseSections[sectionIndex]

              const actualSectionIndex = sections[pageDesigning].findIndex((sect) => sect.id === sectionId)

              let adjustedPoint = []

              for (const poi of points) {
                const adjustingPoi = { ...poi };

                adjustingPoi.x = adjustingPoi.x - left;
                adjustingPoi.y = adjustingPoi.y - top;
                adjustingPoi.curvature.x = adjustingPoi.curvature.x - left;
                adjustingPoi.curvature.y = adjustingPoi.curvature.y - top;
                adjustedPoint.push(adjustingPoi)
              }

              console.log(adjustedPoint)
              const newElement = {
                id: Date.now().toString(),
                type: "shape-element",
                position: {
                  x: actualLeft,
                  y: top - (sectionId === "footer" ? sections[pageDesigning][actualSectionIndex - 1].position.y + sections[pageDesigning][actualSectionIndex - 1].height : sectionDet.position.y),
                  mx: actualLeft,
                  my: top - (sectionId === "footer" ? (sections[pageDesigning][actualSectionIndex - 1].position.my ? sections[pageDesigning][actualSectionIndex - 1].position.my :
                    sections[pageDesigning][actualSectionIndex - 1].position.y) + (sections[pageDesigning][actualSectionIndex - 1].mheight ? sections[pageDesigning][actualSectionIndex - 1].mheight :
                      sections[pageDesigning][actualSectionIndex - 1].height) : (sectionDet.position.my ? sectionDet.position.my : sectionDet.position.y)),
                },
                points: adjustedPoint,
                children: [],
                width: width,
                height: height,
                objectFit: "cover",
                src: null,
                content: "",
                parent: { type: "section", id: sectionId },
                mobile: { type: "section", id: sectionId },
                fontSize: 14,
                fontWeight: 300,
                textAlign: "left",
                lineHeight: 15,
                ref: null,
                letterSpacing: 1,
                backgroundColor: "none",
                color: "#1f1f1f",
                border: { px: "0", solid: "solid", color: "black" },
                shadow: {
                  horizontal: "0",
                  vertical: "0",
                  blur: "0",
                  color: "black",
                },
                opacity: 100,
                borderRadius: "0",
                alignItems: "flex-start",
                slide: null,
              };

              setBaseElement([...baseElement, newElement]);
              setActiveTools(1);
              setPoints([])
            }}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              border: "1px solid lightgrey",
              borderRadius: "5px",
              marginTop: "15px",
            }}
          >
            <FaMousePointer
              style={{ fontSize: "14px", color: "grey" }}
            />
          </div>
          <div
            className="right_toolbar_item"
            onClick={(event) => setActiveTools(2)}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              border: "1px solid lightgrey",
              borderRadius: "5px",
              marginTop: "15px",
            }}
          >
            <CreateIcon
              style={{ fontSize: "14px", color: "grey" }}
            />
          </div> */}
          <div
            className="right_toolbar_item"
            onClick={(event) => handleGroupLeftAlign(event)}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              border: "1px solid lightgrey",
              borderRadius: "5px",
              marginTop: "15px",
            }}
          >
            <AlignHorizontalLeftIcon
              style={{ fontSize: "18px", color: "grey" }}
            />
          </div>
          <div
            className="right_toolbar_item"
            onClick={(event) => handleGroupHMiddleAlign(event)}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              border: "1px solid lightgrey",
              borderRadius: "5px",
              marginTop: "8px",
            }}
          >
            <AlignHorizontalCenterIcon
              style={{ fontSize: "18px", color: "grey" }}
            />
          </div>
          <div
            className="right_toolbar_item"
            onClick={(event) => handleGroupRightAlign(event)}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              border: "1px solid lightgrey",
              borderRadius: "5px",
              marginTop: "8px",
            }}
          >
            <AlignHorizontalRightIcon
              style={{ fontSize: "18px", color: "grey" }}
            />
          </div>
          <div
            className="right_toolbar_item"
            onClick={(event) => handleGroupTopAlign(event)}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              border: "1px solid lightgrey",
              borderRadius: "5px",
              marginTop: "8px",
            }}
          >
            <AlignVerticalTopIcon style={{ fontSize: "18px", color: "grey" }} />
          </div>
          <div
            className="right_toolbar_item"
            onClick={(event) => handleGroupVMiddleAlign(event)}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              border: "1px solid lightgrey",
              borderRadius: "5px",
              marginTop: "8px",
            }}
          >
            <AlignVerticalCenterIcon
              style={{ fontSize: "18px", color: "grey" }}
            />
          </div>
          <div
            className="right_toolbar_item"
            onClick={(event) => handleGroupBottomAlign(event)}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
              border: "1px solid lightgrey",
              borderRadius: "5px",
              marginTop: "8px",
            }}
          >
            <AlignVerticalBottomIcon
              style={{ fontSize: "18px", color: "grey" }}
            />
          </div>
        </div>
        <hr
          style={{
            height: "1px",
            width: "100%",
            outline: "none",
            border: "none",
            background: "lightgrey",
            marginTop: "20px",
          }}
        />
        {focusElementType === "section" ? (
          <div>
            <div
              style={{
                fontWeight: 600,
                fontSize: "11px",
                lineHeight: "11px",
                color: "rgba(0,0,0,0.8)",
                borderBottom: "1px solid white",
                marginTop: "15px",
              }}
            >
              <div style={{ textAlign: "center" }}>Sect Customi.</div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "8px",
              }}
              onClick={() => setNotInputFocus(false)}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <input
                  id="imageInput"
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleImageUpload}
                />
                {fileUploading ? (
                  <div
                    className="mui-button"
                    style={{
                      borderRadius: "5px",
                      width: "40px",
                      height: "40px",
                      objectFit: "cover",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress color="primary" size={15} style={{}} />
                  </div>
                ) : focusImage ? (
                  <img
                    className="right_toolbar_item"
                    style={{
                      height: "40px",
                      width: "40px",
                      borderRadius: "5px",
                      objectFit: "cover",
                    }}
                    src={focusImage}
                    onClick={handleClick}
                  />
                ) : (
                  <div
                    className="right_toolbar_item"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: "10px",
                      border: "1px solid lightgrey",
                      borderRadius: "5px",
                    }}
                    onClick={handleClick}
                  >
                    <UploadFileIcon
                      style={{
                        color: "rgba(50,50,50)",
                        fontSize: "18px",
                        color: "grey",
                      }}
                    />
                  </div>
                )}
                <div
                  className="mui-button"
                  onClick={() => setImageModalStates(true)}
                  style={{
                    borderRadius: "5px",
                    padding: "2px",
                    border: "1px solid lightgrey",
                    fontSize: "10px",
                    color: "rgba(0,0,0,0.7)",
                    margin: "5px 0px",
                  }}
                >
                  Gallery
                </div>
                <div
                  style={{
                    fontSize: "10px",
                    color: "rgba(0,0,0,0.7)",
                    textAlign: "center",
                  }}
                  htmlFor="fontWeight"
                >
                  Image
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <input
                  style={{
                    width: "24px",
                    padding: "5px 8px",
                    borderRadius: "5px",
                    fontSize: "10px",
                    border: "1px solid lightgrey",
                    outline: "none",
                  }}
                  type="number"
                  id="height"
                  value={parseInt(focusHeight)}
                  onFocus={() => setNotInputFocus(false)}
                  onBlur={() => setNotInputFocus(true)}
                  min={0}
                  onChange={(e) => {
                    if (mobileDesigning) {
                      const draggingSectionIndex = mobileSections[
                        pageDesigning
                      ].findIndex((section) => section.id === focusElement);

                      const updatedSections = [
                        ...mobileSections[pageDesigning],
                      ];
                      const newHeight = parseInt(e.target.value);

                      // Check if the new height is greater than or equal to zero before updating
                      if (newHeight >= -1) {
                        const deltaY =
                          newHeight -
                          mobileSections[pageDesigning][draggingSectionIndex]
                            .height;

                        for (
                          let i = draggingSectionIndex + 1;
                          i < updatedSections.length;
                          i++
                        ) {
                          updatedSections[i].position.y += deltaY;
                        }
                        updatedSections[draggingSectionIndex].height = parseInt(
                          e.target.value
                        );

                        setFocusHeight(parseInt(e.target.value));
                      }
                    } else {
                      const draggingSectionIndex = sections[
                        pageDesigning
                      ].findIndex((section) => section.id === focusElement);
                      const sectionIndex = baseSections.findIndex(
                        (section) => section.id === focusElement
                      );

                      const updatingBaseSection = [...baseSections];

                      const updatedSections = [...sections[pageDesigning]];

                      const newHeight = parseInt(e.target.value);

                      // Check if the new height is greater than or equal to zero before updating
                      if (newHeight >= -1) {
                        const deltaY =
                          newHeight -
                          sections[pageDesigning][draggingSectionIndex].height;

                        for (
                          let i = draggingSectionIndex + 1;
                          i < updatedSections.length;
                          i++
                        ) {
                          if (updatedSections[i].id !== "footer") {
                            const sectIndex = baseSections.findIndex(
                              (section) => section.id === updatedSections[i].id
                            );
                            updatingBaseSection[sectIndex].position.y += deltaY;
                          }
                        }
                        updatingBaseSection[sectionIndex].height = parseInt(
                          e.target.value
                        );

                        setFocusHeight(parseInt(e.target.value));
                        setBaseSections(updatingBaseSection);
                      }
                    }
                  }}
                />
                <div
                  style={{
                    fontSize: "10px",
                    color: "rgba(0,0,0,0.7)",
                    textAlign: "center",
                  }}
                  htmlFor="textAlign"
                >
                  Height
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <input
                  style={{
                    width: "24px",
                    padding: "5px 8px",
                    borderRadius: "5px",
                    fontSize: "10px",
                    border: "1px solid lightgrey",
                    outline: "none",
                  }}
                  type="number"
                  id="opacity"
                  min="0"
                  max="100"
                  value={focusOpacity}
                  onFocus={() => setNotInputFocus(false)}
                  onBlur={() => setNotInputFocus(true)}
                  onChange={(e) => {
                    const draggingSectionIndex = baseSections.findIndex(
                      (section) => section.id === focusElement
                    );

                    const updatedSections = [...baseSections];

                    updatedSections[draggingSectionIndex].opacity = parseInt(
                      e.target.value
                    );

                    setFocusOpacity(parseInt(e.target.value));
                    setBaseSections(updatedSections);
                  }}
                />
                <div
                  style={{
                    fontSize: "10px",
                    color: "rgba(0,0,0,0.7)",
                    textAlign: "center",
                  }}
                  htmlFor="textAlign"
                >
                  Opacity
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <div
                  id="background-color-trigger"
                  style={{
                    background: focusBackgroundColor,
                    width: "20px",
                    height: "20px",
                    borderRadius: "20px",
                    boxShadow: "0px 0px 5px rgba(0,0,0,0.3)",
                  }}
                  onClick={() => {
                    setBackgroundColorDisplay(!backgroundColorDisplay);
                  }}
                />
                {backgroundColorDisplay ? (
                  <div
                    id="background-color-picker-container"
                    style={{ position: "absolute", right: 45, zIndex: 502 }}
                  >
                    <ChromePicker
                      color={focusBackgroundColor}
                      onChange={(color) => {
                        const sectionIndex = baseSections.findIndex(
                          (section) => section.id === focusElement
                        );
                        const updatedSections = [...baseSections];
                        updatedSections[sectionIndex].backgroundColor =
                          color.hex;
                        setFocusBackgroundColor(color.hex);
                        setBaseSections(updatedSections);
                      }}
                      onChangeComplete={(color) => {
                        const sectionIndex = baseSections.findIndex(
                          (section) => section.id === focusElement
                        );
                        const updatedSections = [...baseSections];
                        updatedSections[sectionIndex].backgroundColor =
                          color.hex;
                        setFocusBackgroundColor(color.hex);
                        setBaseSections(updatedSections);
                      }}
                    />
                  </div>
                ) : null}
                <div
                  style={{
                    fontSize: "10px",
                    color: "rgba(0,0,0,0.7)",
                    textAlign: "center",
                  }}
                  htmlFor="backgroundColor"
                >
                  Backgr.
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      {/* Screen guidelines */}
      <div
        className="non-draggable-div"
        style={{
          position: "fixed",
          zIndex: 48,
          height: "94vh",
          borderLeft: "1px dashed grey",
          width: "10px",
          left: "15%",
          bottom: 0,
          opacity: openGrid ? 1 : 0,
        }}
      />
      <div
        className="non-draggable-div"
        style={{
          position: "fixed",
          zIndex: 48,
          height: "94vh",
          borderLeft: "1px dashed grey",
          width: "10px",
          left: "85%",
          bottom: 0,
          opacity: openGrid ? 1 : 0,
        }}
      />

      {/* display canvas */}
      {/* {showDraggingGuides && (
        <div className="non-draggable-div">
          {filteredGuidesx.map((guide, index) => (
            <div
              className="non-draggable-div"
              key={index}
              style={{
                position: "absolute",
                zIndex: 100,
                left: `${guide.x}%`,
                top: 0,
                width: 1,
                height: "100%",
                background: "blue",
                opacity: 0.3,
              }}
            />
          ))}
          {filteredGuidesy.map((guide, index) => (
            <div
              className="non-draggable-div"
              key={index}
              style={{
                position: "absolute",
                zIndex: 100,
                left: 0,
                top: guide.y,
                width: guide.width,
                height: guide.height,
                background: "green",
                opacity: 0.3,
              }}
            />
          ))}
        </div>
      )} */}

      {mobileDesigning && menuElement ? (
        <div
          style={{
            position: "fixed",
            top: "6vh",
            left: menuIsOpen
              ? viewportWidth / 2 - 200
              : viewportWidth / 2 + 200,
            width: "400px",
            height: "94vh",
            zIndex: 10000,

            background: mobileDesigning
              ? menuElement.mbuttonBackgroundColor ||
              menuElement.buttonBackgroundColor ||
              "rgba(50,50,50)"
              : menuElement.buttonBackgroundColor || "rgba(50,50,50)",

            opacity: menuIsOpen ? 1 : 0,
            transition: "0.3s all ease-in-out",
          }}
        >
          <div style={{ position: "absolute", top: 30, right: 30 }}>
            <CloseIcon
              className="mui-button"
              onClick={() => setMenuIsOpen(false)}
              style={{
                color: mobileDesigning
                  ? menuElement.mbuttonFontColor ||
                  menuElement.buttonFontColor ||
                  "rgba(0,0,0,0.8)"
                  : menuElement.buttonFontColor || "rgba(0,0,0,0.8)",

                fontSize: 40,
              }}
            />
          </div>
          <div style={{ marginTop: "105px" }}>
            {Object.keys(sections).map((key, index) => {
              if (
                key !== "Product_Dynamic" &&
                key !== "Event_Dynamic" &&
                key !== "News_Dynamic" &&
                key !== "Checkout_Dynamic" &&
                key !== "My_Accounts_Dynamic" &&
                key !== "Cart_Dynamic"
              ) {
                return (
                  <div
                    key={index}
                    style={{
                      padding: "20px 50px",
                      textAlign:
                        (mobileDesigning
                          ? menuElement.mbuttonTextAlign || "center"
                          : menuElement.buttonTextAlign || "center") ===
                          "flex-start"
                          ? "left"
                          : (mobileDesigning
                            ? menuElement.mbuttonTextAlign || "center"
                            : menuElement.buttonTextAlign || "center") ===
                            "flex-end"
                            ? "right"
                            : "center",
                      fontSize: mobileDesigning
                        ? menuElement.mbuttonFontSize ||
                        menuElement.buttonFontSize ||
                        14
                        : menuElement.buttonFontSize || 14,

                      fontWeight: mobileDesigning
                        ? menuElement.mbuttonFontWeight ||
                        menuElement.buttonFontWeight ||
                        700
                        : menuElement.buttonFontWeight || 700,

                      lineHeight: `${mobileDesigning
                        ? menuElement.mbuttonLineHeight ||
                        menuElement.buttonLineHeight ||
                        (mobileDesigning
                          ? menuElement.mbuttonFontSize ||
                          menuElement.buttonFontSize ||
                          14
                          : menuElement.buttonFontSize || 14)
                        : menuElement.buttonLineHeight ||
                        (mobileDesigning
                          ? menuElement.mbuttonFontSize ||
                          menuElement.buttonFontSize ||
                          14
                          : menuElement.buttonFontSize || 14)
                        }px`,

                      letterSpacing: `${mobileDesigning
                        ? menuElement.mbuttonLetterSpacing ||
                        menuElement.buttonLetterSpacing ||
                        0
                        : menuElement.buttonLetterSpacing || 0
                        }px`,

                      color: mobileDesigning
                        ? menuElement.mbuttonFontColor ||
                        menuElement.buttonFontColor ||
                        "rgba(0,0,0,0.8)"
                        : menuElement.buttonFontColor || "rgba(0,0,0,0.8)",

                      textShadow: mobileDesigning
                        ? menuElement.mbuttonBoxShadow ||
                        menuElement.buttonBoxShadow ||
                        "0px 0px 0px rgba(0,0,0,0.8)"
                        : menuElement.buttonBoxShadow ||
                        "0px 0px 0px rgba(0,0,0,0.8)",
                      border: "none",

                      margin: "0px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent:
                        (mobileDesigning
                          ? menuElement.mbuttonTextAlign || "center"
                          : menuElement.buttonTextAlign || "center") ===
                          "flex-start"
                          ? "flex-start"
                          : (mobileDesigning
                            ? menuElement.mbuttonTextAlign || "center"
                            : menuElement.buttonTextAlign || "center") ===
                            "flex-end"
                            ? "flex-end"
                            : "center",
                    }}
                  >
                    {key}
                  </div>
                );
              }
            })}
          </div>
        </div>
      ) : null}
      {draggingDisplay ? (
        <div
          style={{
            position: "fixed",
            zIndex: 600,
            top: draggingElementDetails.clientY,
            left: draggingElementDetails.clientX,
            width: 420,
          }}
        >
          {draggingDisplay}
        </div>
      ) : null}

      <div
        onScroll={handleScroll}
        className="canvas"
        style={{
          position: "absolute",
          marginTop: "6vh",
          width: mobileDesigning ? "400px" : "100%",
          height: "94vh",
          overflowY: "auto",
          overflowX: "hidden",
          background: "rgba(0,0,0,0.1)",
          left: mobileDesigning ? `calc(50% - 200px)` : 0,
          transition: "0.4s all ease-in-out",
          borderRadius: mobileDesigning ? "20px" : 0,
          boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
        }}
      >
        {activeTools === 2 ? <>
          {penMouseOver ?
            <div
              className="point_over"
              style={{
                position: "absolute",
                top: penMouseOver.y,
                left: penMouseOver.x,
                height: "3px",
                width: "3px",
                background: "white",
                transform: "translate(-50%, -50%)",
                border: "1px solid #5e86c4",
                zIndex: 499,
              }}
            /> : null}
          {penDrawingOver ?
            <div
              className="point_over"
              style={{
                position: "absolute",
                top: penDrawingOver.y,
                left: penDrawingOver.x,
                height: "3px",
                width: "3px",
                background: "white",
                transform: "translate(-50%, -50%)",
                border: "1px solid #5e86c4",
                zIndex: 499,
              }}
            /> : null}
          <canvas
            ref={canvasRef}
            width={window.innerWidth}
            height={canvasHeight}
            style={{
              border: '0px solid transparent',
              position: "absolute",
              zIndex: 500,
            }}
            onMouseDown={(e) => {
              setIsDrawing(true)
              const vhToPixels = (percent) => (window.innerHeight * percent) / 100;
              const pointToPush = { x: e.clientX, y: (e.clientY + scrollTop - vhToPixels(6)) }
              setPenDrawingOver({ ...pointToPush, curvature: { ...pointToPush } })
            }}
            onMouseUp={(e) => {
              setPenDrawingOver(null)
              setIsDrawing(false)
              const vhToPixels = (percent) => (window.innerHeight * percent) / 100;
              setPoints([...points, penDrawingOver])
              console.log([...points, penDrawingOver])
            }}
            onMouseMove={(e) => {
              const vhToPixels = (percent) => (window.innerHeight * percent) / 100;
              const pointToPush = { x: e.clientX, y: (e.clientY + scrollTop - vhToPixels(6)) }
              setPenMouseOver(pointToPush)
              // console.log(penDrawingOver)
              if (penDrawingOver) {
                const curvature = {
                  x: penDrawingOver.x - (e.clientX - penDrawingOver.x),
                  y: penDrawingOver.y - (e.clientY + scrollTop - vhToPixels(6) - penDrawingOver.y)
                }
                setPenDrawingOver({ ...penDrawingOver, curvature: { ...curvature } })
              }
            }}
          />
          {points.map((point, index) =>
            <div
              className="point_over"
              key={index}
              onClick={() => {
                if (activeTools === 2) {
                  if (points.length >= 2) {
                    const pointToPush = { x: point.x, y: point.y, curvature: { x: point.curvature.x, y: point.curvature.y } }
                    setPoints([...points, pointToPush])
                  }
                }
              }}
              style={{
                position: "absolute",
                top: point.y,
                left: point.x,
                height: "3px",
                width: "3px",
                background: "white",
                transform: "translate(-50%, -50%)",
                border: "1px solid #5e86c4",
                zIndex: 501,
              }}
            />
          )}
        </> : null}
        <div
          style={{
            position: "fixed",
            bottom: mobileDesigning ? 13 : -10,
            width: "150px",
            height: "8px",
            background: "white",
            boxShadow: "0px 0px 10px black",
            zIndex: 520,
            borderRadius: "8px",
            left: `calc(50% - 75px)`,
            opacity: mobileDesigning ? "0.7" : "0",
            transition: "0.4s all ease-in-out",
          }}
        />
        {selectedSection ? (
          mobileDesigning ? null : sections[pageDesigning].length - 1 !==
            selectedSectionIndex ? (
            <div
              className="resize-handle"
              style={{
                position: "absolute",
                zIndex: 520,
                top: selectedSection
                  ? sections
                    ? sections[pageDesigning]
                      ? sections[pageDesigning][selectedSectionIndex]
                        ? sections[pageDesigning][selectedSectionIndex].position
                          .y +
                        sections[pageDesigning][selectedSectionIndex].height -
                        17
                        : 0
                      : 0
                    : 0
                  : 0,
                left: "calc(50% - 215px)",
                cursor: "pointer",
                background: "rgba(50,50,50)",
                border: "1px solid white",
                height: "34px",
                width: "180px",
                opacity: selectedSection ? 1 : 0,
                borderRadius: "50px",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "12px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
              }}
              onDragOver={(event) => {
                event.preventDefault(); // Prevent "no drop" cursor
              }}
              onClick={() => createSectionAfterIndex(selectedSectionIndex)}
            >
              Add section
            </div>
          ) : selectedSection ? (
            selectedSection !== "footer" ? (
              <div
                className="resize-handle"
                style={{
                  position: "absolute",
                  zIndex: 520,
                  top: selectedSection
                    ? sections
                      ? sections[pageDesigning]
                        ? sections[pageDesigning][selectedSectionIndex]
                          ? sections[pageDesigning][selectedSectionIndex]
                            .position.y +
                          sections[pageDesigning][selectedSectionIndex]
                            .height -
                          17
                          : 0
                        : 0
                      : 0
                    : 0,
                  left: "calc(50% - 215px)",
                  cursor: "pointer",
                  background: "rgba(50,50,50)",
                  border: "1px solid white",
                  height: "34px",
                  width: "180px",
                  opacity: selectedSection ? 1 : 0,
                  borderRadius: "50px",
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "12px",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                }}
                onDragOver={(event) => {
                  event.preventDefault(); // Prevent "no drop" cursor
                }}
                onClick={() => createSectionAfterIndex(selectedSectionIndex)}
              >
                Add section
              </div>
            ) : null
          ) : null
        ) : null}
        {selectedSection ? (
          mobileDesigning ? null : sections[pageDesigning].length - 1 !==
            selectedSectionIndex ? (
            <div
              className="resize-handle"
              style={{
                position: "absolute",
                zIndex: 520,
                top: selectedSection
                  ? sections
                    ? sections[pageDesigning]
                      ? sections[pageDesigning][selectedSectionIndex]
                        ? sections[pageDesigning][selectedSectionIndex].position
                          .y +
                        sections[pageDesigning][selectedSectionIndex].height -
                        17
                        : 0
                      : 0
                    : 0
                  : 0,
                left: "calc(50% + 35px)",
                cursor: "pointer",
                background: "rgba(50,50,50)",
                border: "1px solid white",
                height: "34px",
                width: "180px",
                opacity: selectedSection ? 1 : 0,
                borderRadius: "50px",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "12px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
              }}
              onDragOver={(event) => {
                event.preventDefault(); // Prevent "no drop" cursor
              }}
              onClick={() => createSliderAfterIndex(selectedSectionIndex)}
            >
              Add slider
            </div>
          ) : selectedSection ? (
            selectedSection !== "footer" ? (
              <div
                className="resize-handle"
                style={{
                  position: "absolute",
                  zIndex: 520,
                  top: selectedSection
                    ? sections
                      ? sections[pageDesigning]
                        ? sections[pageDesigning][selectedSectionIndex]
                          ? sections[pageDesigning][selectedSectionIndex]
                            .position.y +
                          sections[pageDesigning][selectedSectionIndex]
                            .height -
                          17
                          : 0
                        : 0
                      : 0
                    : 0,
                  left: "calc(50% + 35px)",
                  cursor: "pointer",
                  background: "rgba(50,50,50)",
                  border: "1px solid white",
                  height: "34px",
                  width: "180px",
                  opacity: selectedSection ? 1 : 0,
                  borderRadius: "50px",
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "12px",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                }}
                onDragOver={(event) => {
                  event.preventDefault(); // Prevent "no drop" cursor
                }}
                onClick={() => createSliderAfterIndex(selectedSectionIndex)}
              >
                Add slider
              </div>
            ) : null
          ) : null
        ) : null}
        {selectedSection ? (
          mobileDesigning ? null : sections[pageDesigning].length - 1 !==
            selectedSectionIndex ? (
            <div
              className="resize-handle"
              style={{
                position: "absolute",
                zIndex: 520,
                top: selectedSection
                  ? sections
                    ? sections[pageDesigning]
                      ? sections[pageDesigning][selectedSectionIndex]
                        ? sections[pageDesigning][selectedSectionIndex].position
                          .y +
                        sections[pageDesigning][selectedSectionIndex].height -
                        17
                        : 0
                      : 0
                    : 0
                  : 0,
                left: "calc(50% - 17px)",
                cursor: "pointer",
                background: "rgba(50,50,50)",
                border: "1px solid white",
                height: "34px",
                width: "34px",
                opacity: selectedSection ? 1 : 0,
                borderRadius: "50px",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "12px",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
              }}
              onDragOver={(event) => {
                event.preventDefault(); // Prevent "no drop" cursor
              }}
              onClick={() => {
                setCreateTemplateModelIsOpen(true);
                setTemplateIndex(selectedSectionIndex);
              }}
            >
              <WidgetsIcon style={{ color: "white" }} />
            </div>
          ) : selectedSection ? (
            selectedSection !== "footer" ? (
              <div
                className="resize-handle"
                style={{
                  position: "absolute",
                  zIndex: 520,
                  top: selectedSection
                    ? sections
                      ? sections[pageDesigning]
                        ? sections[pageDesigning][selectedSectionIndex]
                          ? sections[pageDesigning][selectedSectionIndex]
                            .position.y +
                          sections[pageDesigning][selectedSectionIndex]
                            .height -
                          17
                          : 0
                        : 0
                      : 0
                    : 0,
                  left: "calc(50% - 17px)",
                  cursor: "pointer",
                  background: "rgba(50,50,50)",
                  border: "1px solid white",
                  height: "34px",
                  width: "34px",
                  opacity: selectedSection ? 1 : 0,
                  borderRadius: "50px",
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "12px",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
                }}
                onDragOver={(event) => {
                  event.preventDefault(); // Prevent "no drop" cursor
                }}
                onClick={() => {
                  setCreateTemplateModelIsOpen(true);
                  setTemplateIndex(selectedSectionIndex);
                }}
              >
                <WidgetsIcon style={{ color: "white" }} />
              </div>
            ) : null
          ) : null
        ) : null}

        {selectedSection ? (
          mobileDesigning ? (
            selectedSection !== "footer" ? (
              <div
                className="resize-handle"
                style={{
                  position: "absolute",
                  zIndex: 499,
                  top: selectedSection
                    ? (mobileSections[pageDesigning][selectedSectionIndex]
                      .position.my
                      ? mobileSections[pageDesigning][selectedSectionIndex]
                        .position.my
                      : mobileSections[pageDesigning][selectedSectionIndex]
                        .position.y) +
                    (mobileSections[pageDesigning][selectedSectionIndex]
                      .mheight
                      ? mobileSections[pageDesigning][selectedSectionIndex]
                        .mheight
                      : mobileSections[pageDesigning][selectedSectionIndex]
                        .height) -
                    22
                    : 0,
                  right: "50%",
                  cursor: "row-resize",
                  background: "rgba(256,256,256,.4)",
                  border: "1px solid #5e86c4",
                  height: "20px",
                  width: "100%",
                  transform: "translate(50%, 50%)",
                  opacity: selectedSection ? 1 : 0,
                  borderRadius: "7px",
                  touchAction: "none",
                }}
                draggable
                onDragStart={(event) => {
                  handleSectionDragStart(event, selectedSection);
                }}
                onDragOver={(event) => {
                  event.preventDefault(); // Prevent "no drop" cursor
                }}
                onDrag={(event) =>
                  handleSectionDragging(event, selectedSection)
                }
                onTouchStart={(e) =>
                  handleTouchSectionDragStart(e, selectedSection)
                }
                onTouchMove={(e) =>
                  handleTouchSectionDragging(e, selectedSection)
                }
                onTouchEnd={(e) => setSectionTouchDragging(false)}
              />
            ) : (
              <div
                className="resize-handle"
                style={{
                  position: "absolute",
                  zIndex: 499,
                  top: selectedSection
                    ? (sections[pageDesigning][selectedSectionIndex - 1]
                      .position.my
                      ? sections[pageDesigning][selectedSectionIndex - 1]
                        .position.my
                      : sections[pageDesigning][selectedSectionIndex - 1]
                        .position.y) +
                    (sections[pageDesigning][selectedSectionIndex - 1].mheight
                      ? sections[pageDesigning][selectedSectionIndex - 1]
                        .mheight
                      : sections[pageDesigning][selectedSectionIndex - 1]
                        .height) +
                    (sections[pageDesigning][selectedSectionIndex].mheight
                      ? sections[pageDesigning][selectedSectionIndex].mheight
                      : sections[pageDesigning][selectedSectionIndex]
                        .height) -
                    22
                    : 0,
                  right: "50%",
                  cursor: "row-resize",
                  background: "rgba(256,256,256,.4)",
                  border: "1px solid #5e86c4",
                  height: "20px",
                  width: "100%",
                  transform: "translate(50%, 50%)",
                  opacity: selectedSection ? 1 : 0,
                  borderRadius: "7px",
                  touchAction: "none",
                }}
                draggable
                onDragStart={(event) => {
                  handleSectionDragStart(event, selectedSection);
                }}
                onDragOver={(event) => {
                  event.preventDefault(); // Prevent "no drop" cursor
                }}
                onDrag={(event) =>
                  handleSectionDragging(event, selectedSection)
                }
                onTouchStart={(e) =>
                  handleTouchSectionDragStart(e, selectedSection)
                }
                onTouchMove={(e) =>
                  handleTouchSectionDragging(e, selectedSection)
                }
                onTouchEnd={(e) => setSectionTouchDragging(false)}
              />
            )
          ) : selectedSection !== "footer" ? (
            <div
              className="resize-handle"
              style={{
                position: "absolute",
                zIndex: 499,
                top: selectedSection
                  ? sections[pageDesigning][selectedSectionIndex].position.y +
                  sections[pageDesigning][selectedSectionIndex].height -
                  22
                  : 0,
                right: "50%",
                cursor: "row-resize",
                background: "rgba(256,256,256,.4)",
                border: "1px solid #5e86c4",
                height: "20px",
                width: "100%",
                transform: "translate(50%, 50%)",
                opacity: selectedSection ? 1 : 0,
                borderRadius: "7px",
                touchAction: "none",
              }}
              draggable
              onDragStart={(event) => {
                handleSectionDragStart(event, selectedSection);
              }}
              onDragOver={(event) => {
                event.preventDefault(); // Prevent "no drop" cursor
              }}
              onDrag={(event) => handleSectionDragging(event, selectedSection)}
              onTouchStart={(e) =>
                handleTouchSectionDragStart(e, selectedSection)
              }
              onTouchMove={(e) =>
                handleTouchSectionDragging(e, selectedSection)
              }
              onTouchEnd={(e) => setSectionTouchDragging(false)}
            />
          ) : (
            <div
              className="resize-handle"
              style={{
                position: "absolute",
                zIndex: 499,
                top: selectedSection
                  ? sections[pageDesigning][selectedSectionIndex - 1].position
                    .y +
                  sections[pageDesigning][selectedSectionIndex - 1].height +
                  sections[pageDesigning][selectedSectionIndex].height -
                  22
                  : 0,
                right: "50%",
                cursor: "row-resize",
                background: "rgba(256,256,256,.4)",
                border: "1px solid #5e86c4",
                height: "20px",
                width: "100%",
                transform: "translate(50%, 50%)",
                opacity: selectedSection ? 1 : 0,
                borderRadius: "7px",
                touchAction: "none",
              }}
              draggable
              onDragStart={(event) => {
                handleSectionDragStart(event, selectedSection);
              }}
              onDragOver={(event) => {
                event.preventDefault(); // Prevent "no drop" cursor
              }}
              onDrag={(event) => handleSectionDragging(event, selectedSection)}
              onTouchStart={(e) =>
                handleTouchSectionDragStart(e, selectedSection)
              }
              onTouchMove={(e) =>
                handleTouchSectionDragging(e, selectedSection)
              }
              onTouchEnd={(e) => setSectionTouchDragging(false)}
            />
          )
        ) : null}

        {/* shift clicked box ref */}
        {selectedElements.length > 0 &&
          boundingBoxDimensions &&
          (mobileDesigning ? (
            <div
              className="bounding-box"
              style={{
                position: "absolute",
                zIndex: 505,
                top: boundingBoxDimensions.top,
                left: (boundingBoxDimensions.left * 400) / 100,
                width: (boundingBoxDimensions.width * 400) / 100,
                height: boundingBoxDimensions.height,
                outline: "2px dashed lightblue",
                background: "rgba(3, 152, 252,0.2)",
                cursor: "move",
              }}
              ref={boundingBoxRef}
              onMouseDown={handleBoundingBoxDragStart}
            />
          ) : (
            <div
              className="bounding-box"
              style={{
                position: "absolute",
                zIndex: 505,
                top: boundingBoxDimensions.top,
                left: (boundingBoxDimensions.left * viewportWidth) / 100,
                width: (boundingBoxDimensions.width * viewportWidth) / 100,
                height: boundingBoxDimensions.height,
                outline: "2px dashed lightblue",
                background: "rgba(3, 152, 252,0.2)",
                cursor: "move",
              }}
              ref={boundingBoxRef}
              onMouseDown={handleBoundingBoxDragStart}
            />
          ))}

        {startMouseDown ? (
          mobileDesigning ? (
            <div
              className="bounding-box"
              style={{
                position: "absolute",
                zIndex: 100,
                top:
                  startMouseDown && currentMouseDown
                    ? currentMouseDown.y > startMouseDown.y
                      ? startMouseDown.y - 42 + scrollTop
                      : currentMouseDown.y - 42 + scrollTop
                    : 0,
                left:
                  startMouseDown && currentMouseDown
                    ? currentMouseDown.x > startMouseDown.x
                      ? startMouseDown.x - (viewportWidth - 400) / 2
                      : currentMouseDown.x - (viewportWidth - 400) / 2
                    : 0,
                width:
                  startMouseDown && currentMouseDown
                    ? currentMouseDown.x > startMouseDown.x
                      ? currentMouseDown.x - startMouseDown.x
                      : startMouseDown.x - currentMouseDown.x
                    : 0,
                height:
                  startMouseDown && currentMouseDown
                    ? currentMouseDown.y > startMouseDown.y
                      ? currentMouseDown.y - startMouseDown.y
                      : startMouseDown.y - currentMouseDown.y
                    : 0,
                outline: "2px dashed lightblue",
                background: "rgba(3, 152, 252, 0.3)",
                cursor: "default",
              }}
              ref={boundingBoxRef}
              onMouseDown={handleBoundingBoxDragStart}
            />
          ) : (
            <div
              className="bounding-box"
              style={{
                position: "absolute",
                zIndex: 100,
                top:
                  startMouseDown && currentMouseDown
                    ? currentMouseDown.y > startMouseDown.y
                      ? startMouseDown.y - 42 + scrollTop
                      : currentMouseDown.y - 42 + scrollTop
                    : 0,
                left:
                  startMouseDown && currentMouseDown
                    ? currentMouseDown.x > startMouseDown.x
                      ? startMouseDown.x
                      : currentMouseDown.x
                    : 0,
                width:
                  startMouseDown && currentMouseDown
                    ? currentMouseDown.x > startMouseDown.x
                      ? currentMouseDown.x - startMouseDown.x
                      : startMouseDown.x - currentMouseDown.x
                    : 0,
                height:
                  startMouseDown && currentMouseDown
                    ? currentMouseDown.y > startMouseDown.y
                      ? currentMouseDown.y - startMouseDown.y
                      : startMouseDown.y - currentMouseDown.y
                    : 0,
                outline: "2px dashed lightblue",
                background: "rgba(3, 152, 252, 0.3)",
                cursor: "default",
              }}
              ref={boundingBoxRef}
              onMouseDown={handleBoundingBoxDragStart}
            />
          )
        ) : null}

        <div style={{ position: "absolute", width: "100%" }}>
          {mobileDesigning
            ? mobileSections
              ? mobileSections[pageDesigning]
                ? mobileSections[pageDesigning].map((section, index) => {
                  if (section.slider === true) {
                    return (
                      <div
                        style={{
                          position: "relative",
                          height: section.mheight
                            ? section.mheight
                            : section.height,
                          width: section.width,
                        }}
                      >
                        <div
                          key={section.id}
                          className="section"
                          onDrop={(event) => handleDrop(event, section.id)}
                          data-section-id={section.id}
                          onDragOver={(event) =>
                            handleDragOver(event, section.id)
                          }
                          onClick={(event) => {
                            console.log(
                              "a section is clicked",
                              section.id,
                              sections[pageDesigning],
                              index
                            );
                            setEditingInputWithArrow(false);
                            setNotInputFocus(true);
                            setSelectedSection(section.id);
                            setSelectedSectionIndex(index);
                            setFocusElementType("section");
                            setFocusElement(section.id);
                            setFocusHeight(section.height);
                            setFocusImage(
                              section[`src${section.activeSlide}`]
                                ? section[`src${section.activeSlide}`]
                                : null
                            );
                            setFocusBackgroundColor(section.backgroundColor);
                            setFocusBorder(section.border);
                            setFocusBoxShadow("");
                            setFocusOpacity(section.opacity);
                          }}
                          style={{
                            position: "relative",
                            height: section.height,
                            width: section.width,
                            // top: section.position.y,
                            outline:
                              section.id === draggedOverSectionId
                                ? "2px dashed red"
                                : "1px dashed grey",
                            background: section.backgroundColor,
                          }}
                        >
                          {section[`src${section.activeSlide}`] ? (
                            <img
                              draggable={false}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                opacity: section.opacity
                                  ? `${section.opacity}%`
                                  : 1,
                              }}
                              src={section[`src${section.activeSlide}`]}
                            />
                          ) : null}
                          {/* {section.children.map((element) => {
                            if (section.activeSlide === element.slide) {
                              return dynamicDisplayItems(
                                section,
                                element,
                                index
                              );
                            }
                          })} */}
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: "calc(50% - 105px)",
                            width: "210px",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            background: "rgba(50,50,50)",
                            padding: "3px",
                          }}
                        >
                          <div
                            style={{
                              width: "40px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              className="mui-button"
                              style={{
                                display:
                                  section.activeSlide === 1 ? "none" : "flex",
                                color: "white",
                                background: primaryColor,
                                padding: "5px 10px",
                                borderRadius: "5px",
                              }}
                              onClick={() => {
                                const updatingSection = [
                                  ...sections[pageDesigning],
                                ];

                                const updatingBaseSection = [...baseSections];

                                const sectionIndex =
                                  updatingBaseSection.findIndex(
                                    (sectionsss) =>
                                      sectionsss.id === section.id
                                  );

                                updatingBaseSection[
                                  sectionIndex
                                ].activeSlide -= 1;

                                setBaseSections(updatingBaseSection);
                              }}
                            >
                              {"<"}
                            </div>
                          </div>
                          <div
                            className="mui-button"
                            onClick={() => {
                              const updatingBaseSection = [...baseSections];

                              const sectionIndex =
                                updatingBaseSection.findIndex(
                                  (sectionsss) => sectionsss.id === section.id
                                );

                              updatingBaseSection[
                                sectionIndex
                              ].numberOfSlides += 1;

                              setBaseSections(updatingBaseSection);
                            }}
                            style={{
                              color: "white",
                              background: primaryColor,
                              padding: "5px 20px",
                              borderRadius: "5px",
                            }}
                          >
                            Add slides
                          </div>
                          <div
                            style={{
                              width: "40px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              className="mui-button"
                              style={{
                                display:
                                  section.activeSlide ===
                                    section.numberOfSlides
                                    ? "none"
                                    : "flex",
                                color: "white",
                                background: primaryColor,
                                padding: "5px 10px",
                                borderRadius: "5px",
                              }}
                              onClick={() => {
                                const updatingBaseSection = [...baseSections];

                                const sectionIndex =
                                  updatingBaseSection.findIndex(
                                    (sectionsss) =>
                                      sectionsss.id === section.id
                                  );

                                updatingBaseSection[
                                  sectionIndex
                                ].activeSlide += 1;

                                setBaseSections(updatingBaseSection);
                              }}
                            >
                              {">"}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={section.id}
                        className="section"
                        onDrop={(event) => handleDrop(event, section.id)}
                        data-section-id={section.id}
                        onDragOver={(event) =>
                          handleDragOver(event, section.id)
                        }
                        onClick={(event) => {
                          console.log(
                            "a section is clicked",
                            section.id,
                            sections[pageDesigning],
                            index
                          );
                          setEditingInputWithArrow(false);
                          setNotInputFocus(true);
                          setSelectedSection(section.id);
                          setSelectedSectionIndex(index);
                          setFocusElementType("section");
                          setFocusElement(section.id);
                          setFocusHeight(section.height);
                          setFocusImage(section.src ? section.src : null);
                          setFocusBackgroundColor(section.backgroundColor);
                          setFocusBorder(section.border);
                          setFocusBoxShadow("");
                          setFocusOpacity(section.opacity);
                        }}
                        style={{
                          position: "relative",
                          height: section.mheight
                            ? section.mheight
                            : section.height,
                          width: section.width,
                          // top: section.position.y,
                          outline:
                            section.id === draggedOverSectionId
                              ? "2px dashed red"
                              : "1px dashed grey",
                          background: section.backgroundColor,
                        }}
                      >
                        {section.src ? (
                          <img
                            draggable={false}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              opacity: `${section.opacity}%`,
                            }}
                            src={section.src}
                          />
                        ) : null}
                        {/* {section.children.map((element) =>
                          dynamicDisplayItems(section, element, index)
                        )} */}
                      </div>
                    );
                  }
                })
                : null
              : null
            : sections
              ? sections[pageDesigning]
                ? sections[pageDesigning].map((section, index) => {
                  if (section.slider === true) {
                    return (
                      <div
                        style={{
                          position: "relative",
                          height: section.height,
                          width: section.width,
                        }}
                      >
                        <div
                          key={section.id}
                          className="section"
                          onDrop={(event) => handleDrop(event, section.id)}
                          data-section-id={section.id}
                          onDragOver={(event) =>
                            handleDragOver(event, section.id)
                          }
                          onClick={(event) => {
                            console.log(
                              "a section is clicked",
                              section.id,
                              sections[pageDesigning],
                              index
                            );
                            setEditingInputWithArrow(false);
                            setNotInputFocus(true);
                            setSelectedSection(section.id);
                            setSelectedSectionIndex(index);
                            setFocusElementType("section");
                            setFocusElement(section.id);
                            setFocusHeight(section.height);
                            setFocusImage(
                              section[`src${section.activeSlide}`]
                                ? section[`src${section.activeSlide}`]
                                : null
                            );
                            setFocusBackgroundColor(section.backgroundColor);
                            setFocusBorder(section.border);
                            setFocusBoxShadow("");
                            setFocusOpacity(section.opacity);
                          }}
                          style={{
                            position: "relative",
                            height: section.height,
                            width: section.width,
                            // top: section.position.y,
                            outline:
                              section.id === draggedOverSectionId
                                ? "2px dashed red"
                                : "1px dashed grey",
                            background: section.backgroundColor,
                          }}
                        >
                          {section[`src${section.activeSlide}`] ? (
                            <img
                              draggable={false}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                opacity: section.opacity
                                  ? `${section.opacity}%`
                                  : 1,
                              }}
                              src={section[`src${section.activeSlide}`]}
                            />
                          ) : null}
                          {/* {section.children.map((element) => {
                          if (section.activeSlide === element.slide) {
                            return dynamicDisplayItems(section, element, index);
                          }
                        })} */}
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: "calc(50% - 105px)",
                            width: "210px",
                            height: "40px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            background: "rgba(50,50,50)",
                            padding: "3px",
                          }}
                        >
                          <div
                            style={{
                              width: "40px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              className="mui-button"
                              style={{
                                display:
                                  section.activeSlide === 1 ? "none" : "flex",
                                color: "white",
                                background: primaryColor,
                                padding: "5px 10px",
                                borderRadius: "5px",
                              }}
                              onClick={() => {
                                const updatingSection = [
                                  ...sections[pageDesigning],
                                ];

                                const updatingBaseSection = [...baseSections];

                                const sectionIndex =
                                  updatingBaseSection.findIndex(
                                    (sectionsss) => sectionsss.id === section.id
                                  );

                                updatingBaseSection[
                                  sectionIndex
                                ].activeSlide -= 1;

                                setBaseSections(updatingBaseSection);
                              }}
                            >
                              {"<"}
                            </div>
                          </div>
                          <div
                            className="mui-button"
                            onClick={() => {
                              const updatingBaseSection = [...baseSections];

                              const sectionIndex =
                                updatingBaseSection.findIndex(
                                  (sectionsss) => sectionsss.id === section.id
                                );

                              updatingBaseSection[
                                sectionIndex
                              ].numberOfSlides += 1;

                              setBaseSections(updatingBaseSection);
                            }}
                            style={{
                              color: "white",
                              background: primaryColor,
                              padding: "5px 20px",
                              borderRadius: "5px",
                            }}
                          >
                            Add slides
                          </div>
                          <div
                            style={{
                              width: "40px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              className="mui-button"
                              style={{
                                display:
                                  section.activeSlide === section.numberOfSlides
                                    ? "none"
                                    : "flex",
                                color: "white",
                                background: primaryColor,
                                padding: "5px 10px",
                                borderRadius: "5px",
                              }}
                              onClick={() => {
                                const updatingBaseSection = [...baseSections];

                                const sectionIndex =
                                  updatingBaseSection.findIndex(
                                    (sectionsss) => sectionsss.id === section.id
                                  );

                                updatingBaseSection[
                                  sectionIndex
                                ].activeSlide += 1;

                                setBaseSections(updatingBaseSection);
                              }}
                            >
                              {">"}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={section.id}
                        className="section"
                        onDrop={(event) => handleDrop(event, section.id)}
                        data-section-id={section.id}
                        onDragOver={(event) =>
                          handleDragOver(event, section.id)
                        }
                        onClick={(event) => {
                          console.log(
                            "a section is clicked",
                            section.id,
                            sections[pageDesigning],
                            index
                          );
                          setEditingInputWithArrow(false);
                          setNotInputFocus(true);
                          setSelectedSection(section.id);
                          setSelectedSectionIndex(index);
                          setFocusElementType("section");
                          setFocusElement(section.id);
                          setFocusHeight(section.height);
                          setFocusImage(section.src ? section.src : null);
                          setFocusBackgroundColor(section.backgroundColor);
                          setFocusBorder(section.border);
                          setFocusBoxShadow("");
                          setFocusOpacity(section.opacity);
                        }}
                        style={{
                          position: "relative",
                          height: section.height,
                          width: section.width,
                          // top: section.position.y,
                          outline:
                            section.id === draggedOverSectionId
                              ? "2px dashed red"
                              : "1px dashed grey",
                          background: section.backgroundColor,
                        }}
                      >
                        {section.src ? (
                          <img
                            draggable={false}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              opacity: section.opacity
                                ? `${section.opacity}%`
                                : 1,
                            }}
                            src={section.src}
                          />
                        ) : null}
                      </div>
                    );
                  }
                })
                : null
              : null}
        </div>
        {mobileDesigning
          ? mobileSections
            ? mobileSections[pageDesigning]
              ? mobileSections[pageDesigning].map((section, index) => {
                if (section.slider === true) {
                  return (
                    <div
                      style={{
                        position: "relative",
                        height: section.mheight
                          ? section.mheight
                          : section.height,
                        width: section.width,
                      }}
                    >
                      <div
                        key={section.id}
                        className="section"
                        onDrop={(event) => handleDrop(event, section.id)}
                        data-section-id={section.id}
                        onDragOver={(event) =>
                          handleDragOver(event, section.id)
                        }
                        onClick={(event) => {
                          console.log(
                            "a section is clicked",
                            section.id,
                            sections[pageDesigning],
                            index
                          );
                          setEditingInputWithArrow(false);
                          setNotInputFocus(true);
                          setSelectedSection(section.id);
                          setSelectedSectionIndex(index);
                          setFocusElementType("section");
                          setFocusElement(section.id);
                          setFocusHeight(section.height);
                          setFocusImage(
                            section[`src${section.activeSlide}`]
                              ? section[`src${section.activeSlide}`]
                              : null
                          );
                          setFocusBackgroundColor(section.backgroundColor);
                          setFocusBorder(section.border);
                          setFocusBoxShadow("");
                          setFocusOpacity(section.opacity);
                        }}
                        style={{
                          position: "relative",
                          height: section.height,
                          width: section.width,
                          // top: section.position.y,
                          outline:
                            section.id === draggedOverSectionId
                              ? "2px dashed red"
                              : "1px dashed grey",
                          background: "transparent",
                        }}
                      >
                        {section.children.map((element) => {
                          if (section.activeSlide === element.slide) {
                            return dynamicDisplayItems(
                              section,
                              element,
                              index
                            );
                          }
                        })}
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: "calc(50% - 105px)",
                          width: "210px",
                          height: "40px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          background: "rgba(50,50,50)",
                          padding: "3px",
                        }}
                      >
                        <div
                          style={{
                            width: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="mui-button"
                            style={{
                              display:
                                section.activeSlide === 1 ? "none" : "flex",
                              color: "white",
                              background: primaryColor,
                              padding: "5px 10px",
                              borderRadius: "5px",
                            }}
                            onClick={() => {
                              const updatingSection = [
                                ...sections[pageDesigning],
                              ];

                              const updatingBaseSection = [...baseSections];

                              const sectionIndex =
                                updatingBaseSection.findIndex(
                                  (sectionsss) => sectionsss.id === section.id
                                );

                              updatingBaseSection[
                                sectionIndex
                              ].activeSlide -= 1;

                              setBaseSections(updatingBaseSection);
                            }}
                          >
                            {"<"}
                          </div>
                        </div>
                        <div
                          className="mui-button"
                          onClick={() => {
                            const updatingBaseSection = [...baseSections];

                            const sectionIndex =
                              updatingBaseSection.findIndex(
                                (sectionsss) => sectionsss.id === section.id
                              );

                            updatingBaseSection[
                              sectionIndex
                            ].numberOfSlides += 1;

                            setBaseSections(updatingBaseSection);
                          }}
                          style={{
                            color: "white",
                            background: primaryColor,
                            padding: "5px 20px",
                            borderRadius: "5px",
                          }}
                        >
                          Add slides
                        </div>
                        <div
                          style={{
                            width: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="mui-button"
                            style={{
                              display:
                                section.activeSlide === section.numberOfSlides
                                  ? "none"
                                  : "flex",
                              color: "white",
                              background: primaryColor,
                              padding: "5px 10px",
                              borderRadius: "5px",
                            }}
                            onClick={() => {
                              const updatingBaseSection = [...baseSections];

                              const sectionIndex =
                                updatingBaseSection.findIndex(
                                  (sectionsss) => sectionsss.id === section.id
                                );

                              updatingBaseSection[
                                sectionIndex
                              ].activeSlide += 1;

                              setBaseSections(updatingBaseSection);
                            }}
                          >
                            {">"}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={section.id}
                      className="section"
                      onDrop={(event) => handleDrop(event, section.id)}
                      data-section-id={section.id}
                      onDragOver={(event) =>
                        handleDragOver(event, section.id)
                      }
                      onClick={(event) => {
                        console.log(
                          "a section is clicked",
                          section.id,
                          sections[pageDesigning],
                          index
                        );
                        setEditingInputWithArrow(false);
                        setNotInputFocus(true);
                        setSelectedSection(section.id);
                        setSelectedSectionIndex(index);
                        setFocusElementType("section");
                        setFocusElement(section.id);
                        setFocusHeight(section.height);
                        setFocusImage(section.src ? section.src : null);
                        setFocusBackgroundColor(section.backgroundColor);
                        setFocusBorder(section.border);
                        setFocusBoxShadow("");
                        setFocusOpacity(section.opacity);
                      }}
                      style={{
                        position: "relative",
                        height: section.mheight
                          ? section.mheight
                          : section.height,
                        width: section.width,
                        // top: section.position.y,
                        outline:
                          section.id === draggedOverSectionId
                            ? "2px dashed red"
                            : "1px dashed grey",
                        background: "transparent",
                      }}
                    >
                      {section.children.map((element) =>
                        dynamicDisplayItems(section, element, index)
                      )}
                    </div>
                  );
                }
              })
              : null
            : null
          : sections
            ? sections[pageDesigning]
              ? sections[pageDesigning].map((section, index) => {
                if (section.slider === true) {
                  return (
                    <div
                      style={{
                        position: "relative",
                        height: section.height,
                        width: section.width,
                      }}
                    >
                      <div
                        key={section.id}
                        className="section"
                        onDrop={(event) => handleDrop(event, section.id)}
                        data-section-id={section.id}
                        onDragOver={(event) =>
                          handleDragOver(event, section.id)
                        }
                        onClick={(event) => {
                          console.log(
                            "a section is clicked",
                            section.id,
                            sections[pageDesigning],
                            index
                          );
                          setEditingInputWithArrow(false);
                          setNotInputFocus(true);
                          setSelectedSection(section.id);
                          setSelectedSectionIndex(index);
                          setFocusElementType("section");
                          setFocusElement(section.id);
                          setFocusHeight(section.height);
                          setFocusImage(
                            section[`src${section.activeSlide}`]
                              ? section[`src${section.activeSlide}`]
                              : null
                          );
                          setFocusBackgroundColor(section.backgroundColor);
                          setFocusBorder(section.border);
                          setFocusBoxShadow("");
                          setFocusOpacity(section.opacity);
                        }}
                        style={{
                          position: "relative",
                          height: section.height,
                          width: section.width,
                          // top: section.position.y,
                          outline:
                            section.id === draggedOverSectionId
                              ? "2px dashed red"
                              : "1px dashed grey",
                          background: "transparent",
                        }}
                      >
                        {section.children.map((element) => {
                          if (section.activeSlide === element.slide) {
                            return dynamicDisplayItems(section, element, index);
                          }
                        })}
                      </div>
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: "calc(50% - 105px)",
                          width: "210px",
                          height: "40px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          background: "rgba(50,50,50)",
                          padding: "3px",
                        }}
                      >
                        <div
                          style={{
                            width: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="mui-button"
                            style={{
                              display:
                                section.activeSlide === 1 ? "none" : "flex",
                              color: "white",
                              background: primaryColor,
                              padding: "5px 10px",
                              borderRadius: "5px",
                            }}
                            onClick={() => {
                              const updatingSection = [
                                ...sections[pageDesigning],
                              ];

                              const updatingBaseSection = [...baseSections];

                              const sectionIndex =
                                updatingBaseSection.findIndex(
                                  (sectionsss) => sectionsss.id === section.id
                                );

                              updatingBaseSection[
                                sectionIndex
                              ].activeSlide -= 1;

                              setBaseSections(updatingBaseSection);
                            }}
                          >
                            {"<"}
                          </div>
                        </div>
                        <div
                          className="mui-button"
                          onClick={() => {
                            const updatingBaseSection = [...baseSections];

                            const sectionIndex = updatingBaseSection.findIndex(
                              (sectionsss) => sectionsss.id === section.id
                            );

                            updatingBaseSection[
                              sectionIndex
                            ].numberOfSlides += 1;

                            setBaseSections(updatingBaseSection);
                          }}
                          style={{
                            color: "white",
                            background: primaryColor,
                            padding: "5px 20px",
                            borderRadius: "5px",
                          }}
                        >
                          Add slides
                        </div>
                        <div
                          style={{
                            width: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="mui-button"
                            style={{
                              display:
                                section.activeSlide === section.numberOfSlides
                                  ? "none"
                                  : "flex",
                              color: "white",
                              background: primaryColor,
                              padding: "5px 10px",
                              borderRadius: "5px",
                            }}
                            onClick={() => {
                              const updatingBaseSection = [...baseSections];

                              const sectionIndex =
                                updatingBaseSection.findIndex(
                                  (sectionsss) => sectionsss.id === section.id
                                );

                              updatingBaseSection[
                                sectionIndex
                              ].activeSlide += 1;

                              setBaseSections(updatingBaseSection);
                            }}
                          >
                            {">"}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={section.id}
                      className="section"
                      onDrop={(event) => handleDrop(event, section.id)}
                      data-section-id={section.id}
                      onDragOver={(event) => handleDragOver(event, section.id)}
                      onClick={(event) => {
                        console.log(
                          "a section is clicked",
                          section.id,
                          sections[pageDesigning],
                          index
                        );
                        setEditingInputWithArrow(false);
                        setNotInputFocus(true);
                        setSelectedSection(section.id);
                        setSelectedSectionIndex(index);
                        setFocusElementType("section");
                        setFocusElement(section.id);
                        setFocusHeight(section.height);
                        setFocusImage(section.src ? section.src : null);
                        setFocusBackgroundColor(section.backgroundColor);
                        setFocusBorder(section.border);
                        setFocusBoxShadow("");
                        setFocusOpacity(section.opacity);
                      }}
                      style={{
                        position: "relative",
                        height: section.height,
                        width: section.width,
                        // top: section.position.y,
                        outline:
                          section.id === draggedOverSectionId
                            ? "2px dashed red"
                            : "1px dashed grey",
                        background: "transparent",
                      }}
                    >
                      {section.children.map((element) =>
                        dynamicDisplayItems(section, element, index)
                      )}
                    </div>
                  );
                }
              })
              : null
            : null}
      </div>
      {pageDropdown ? (
        <div
          id="page-picker-container"
          style={{
            position: "fixed",
            top: "6vh",
            left: 20,
            background: "rgba(255,255,255)",
            border: "none",
            borderRadius: "0px 0px 5px 5px",
            fontSize: "12px",
            boxShadow: "0px 5px 20px rgba(0,0,0,0.1)",
            zIndex: 501,
          }}
        >
          <div
            style={{
              height: "5px",
              width: "100%",
              background: primaryColor,
            }}
          />
          <div
            style={{
              height: "60px",
              borderBottom: "1px solid lightgrey",
              display: "flex",
              alignItems: "center",
              padding: "10px 15px",
            }}
          >
            <div>
              <input
                id="imageUploadFavicon"
                type="file"
                style={{ display: "none" }}
                onChange={handleImageUploadFavicon}
              />
              {fileUploadingfavicon ? (
                <div
                  className="mui-button"
                  style={{
                    borderRadius: "5px",
                    width: "40px",
                    height: "40px",
                    objectFit: "cover",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress color="primary" size={15} style={{}} />
                </div>
              ) : favicon ? (
                <img
                  className="right_toolbar_item"
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius: "5px",
                    objectFit: "cover",
                    border: "1px solid lightgrey",
                  }}
                  src={favicon}
                  onClick={handleClick1}
                />
              ) : (
                <div
                  className="right_toolbar_item"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px",
                    border: "1px solid lightgrey",
                    borderRadius: "5px",
                    border: "1px solid lightgrey",
                  }}
                  onClick={handleClick1}
                >
                  <UploadFileIcon
                    style={{
                      color: "rgba(50,50,50)",
                      fontSize: "18px",
                      color: "grey",
                    }}
                  />
                </div>
              )}
            </div>
            <div>
              <input
                className="projecct_name_input"
                style={{
                  fontWeight: 700,
                  fontSize: "16px",
                  color: "rgba(0,0,0,0.8)",
                  padding: "5px 15px",
                  borderRadius: "5px",
                  marginTop: "-10px",
                }}
                value={projectName || ""}
                onChange={(e) => setProjectName(e.target.value)}
              />
              <div
                style={{
                  fontWeight: 400,
                  fontSize: "12px",
                  color: "rgba(0,0,0,0.5)",
                  marginTop: "-5px",
                  marginLeft: "16px",
                }}
              >
                {domain ? domain : "wwww.premelo.com"}
              </div>
            </div>
          </div>
          {Object.keys(sections).map((key, index) => (
            <div
              className="page_item"
              key={index}
              style={{
                padding: "8px 20px",
                background:
                  pageDesigning === key ? primaryColor : "transparent",
                color: pageDesigning === key ? "white" : "rgba(0,0,0,0.8)",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              onClick={(e) => {
                e.stopPropagation(); // Stop event propagation

                setSelectedSectionIndex(null);
                setSelectedSection(null);
                setFocusElement(null);
                setFocusElementType(null);
                setPageDesigning(key);
                setPageDropdown(false);
              }}
            >
              <div>
                {key !== "Home" &&
                  key !== "Product_Dynamic" &&
                  key !== "Event_Dynamic" &&
                  key !== "News_Dynamic" &&
                  key !== "Checkout_Dynamic" &&
                  key !== "My_Accounts_Dynamic" &&
                  key !== "Cart_Dynamic" ? (
                  <input
                    className="hover_input"
                    value={key}
                    style={{ background: "transparent", borderRadius: "5px" }}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => {
                      setSelectedElements([]);
                      setSelectedSection(null);
                      setSelectedSectionIndex(null);
                      const updatingPages = [...basePages];
                      const updatingSections = [...baseSections];
                      const pageIndex = updatingPages.findIndex(
                        (pag) => pag.name === key
                      );
                      if (pageDesigning === key) {
                        setPageDesigning(e.target.value);
                      }
                      console.log(pageIndex);
                      if (pageIndex > -1) {
                        updatingPages[pageIndex].name = e.target.value;
                        setBasePages(updatingPages);
                        for (const sec of updatingSections) {
                          if (sec.parent.id === key) {
                            sec.parent.id = e.target.value;
                          }
                          setBaseSections(updatingSections);
                        }
                      }
                    }}
                  />
                ) : (
                  key
                )}
              </div>
              {key !== "Home" &&
                key !== "Product_Dynamic" &&
                key !== "Event_Dynamic" &&
                key !== "News_Dynamic" &&
                key !== "Checkout_Dynamic" &&
                key !== "My_Accounts_Dynamic" &&
                key !== "Cart_Dynamic" ? (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    const updatingPages = [...basePages];
                    const pageIndex = basePages.findIndex(
                      (pag) => pag.name === key
                    );
                    if (pageIndex > -1) {
                      updatingPages.splice(pageIndex, 1);
                      setBasePages(updatingPages);
                    }
                  }}
                >
                  <CloseIcon style={{ color: "red" }} />
                </div>
              ) : null}
            </div>
          ))}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px 15px",
              width: "300px",
              borderTop: "1px solid lightgrey",
            }}
          >
            <input
              type="text"
              value={newPageName}
              onChange={(e) => setNewPageName(e.target.value)}
              style={{
                padding: "5px 15px",
                borderRadius: "5px",
                border: "1px solid lightgrey",
                outline: "none",
                color: "rgba(0,0,0,0.8)",
              }}
              placeholder="Enter new page name"
            />
            <button
              className="mui-button"
              style={{
                background: primaryColor,
                color: "white",
                padding: "5px 18px",
                border: "none",
                outline: "none",
                borderRadius: "5px",
                fontSize: "12px",
              }}
              onClick={handleAddPage}
            >
              Add Page
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default WebBuilder;
