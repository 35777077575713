import React, { Component } from "react";

class KommunicateChat extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const primaryColor = "#62939e";
    (function (d, m) {
      var kommunicateSettings = {
        appId: "31bf99941bf0a3f328da3eccc096d50d",
        automaticChatOpenOnNavigation: true,
      };
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.async = true;
      s.src = "https://widget.kommunicate.io/v2/kommunicate.app";
      var h = document.getElementsByTagName("head")[0];
      h.appendChild(s);
      window.kommunicate = m;
      m._globals = kommunicateSettings;
      kommunicateSettings.onInit = function () {
        var css = `.mck-title{display:none!important;}.km-chat-icon-sidebox{box-shadow: none!important; -webkit-box-shadow: 0px 0px 10px rgba(0,0,0,0.2)!important} .mck-text-box.mck-text{font-size: 12px !important} .km-custom-widget-fill{fill: ${primaryColor}!important} .mck-msg-left .mck-msg-box{font-size: 11px !important;} .mck-msg-right .mck-msg-box{background-color: ${primaryColor}!important; font-size: 11px !important;}.mck-title{background-color: ${primaryColor}!important} .km-custom-widget-background-color{background-color: ${primaryColor}!important} .mck-title-width-wo-faq-with-close-btn{font-size: 15px!important;} .mck-agent-status-text{font-size: 10px!important;} .mck-back-btn-container{width: 35px!important;} .mck-box-open{box-shadow:0px 0px 10px rgba(0,0,0,0.4)!important;} .mck-sidebox-launcher{box-shadow: none!important;}`;
        window.Kommunicate.customizeWidgetCss(css); // use window.Kommunicate for ReactJs
      };
    })(document, window.kommunicate || {});
  }

  render() {
    return <div></div>;
  }
}
export default KommunicateChat;
