import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import React from "react";

const PreviewSkeletonMobile = ({ cards }) => {
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      <div style={{ width: "99%" }}>
        <div
          style={{ display: "flex", marginBottom: "10px", marginTop: "30px" }}
        >
          <Skeleton
            style={{ width: "25px", height: "25px", marginRight: "13px" }}
          />
          <Skeleton width={200} height={25} />
        </div>
        <div
          style={{
            marginBottom: "10px",
            background: "#ffffff",
            padding: "10px",
            borderRadius: "15px",
          }}
        >
          <Skeleton height={20} style={{ marginBottom: "6px" }} />
          <div style={{ display: "flex", marginBottom: "6px" }}>
            <Skeleton width={15} height={20} style={{ marginRight: "10px" }} />
            <Skeleton width={15} height={20} style={{ marginRight: "10px" }} />
            <Skeleton width={250} height={20} style={{ marginRight: "50px" }} />
          </div>
          <div style={{ display: "flex", marginBottom: "6px" }}>
            <Skeleton width={15} height={20} style={{ marginRight: "10px" }} />
            <Skeleton width={15} height={20} style={{ marginRight: "10px" }} />
            <Skeleton width={250} height={20} style={{ marginRight: "50px" }} />
          </div>
          <div style={{ display: "flex", marginBottom: "6px" }}>
            <Skeleton width={15} height={20} style={{ marginRight: "10px" }} />
            <Skeleton width={15} height={20} style={{ marginRight: "10px" }} />
            <Skeleton width={250} height={20} style={{ marginRight: "50px" }} />
          </div>
        </div>
      </div>
    ));
};
export default PreviewSkeletonMobile;
