import React, { useState, useEffect } from "react";
import logo from "./images/CS_Favicon-01.png";
import { useNavigate } from "react-router-dom";
import { Typography, IconButton } from "@mui/material";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { fallDown as Menu } from "react-burger-menu";
import Hamburger from "hamburger-react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const FAQMobile = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const faqs = [
    {
      id: 1,
      title: "Who is Premelo for?",
      answer: (
        <p>
          Premelo is designed for anyone who needs to manage tasks, projects,
          and teams, including freelancers, small businesses, and large
          enterprises.
        </p>
      ),
    },
    {
      id: 2,
      title: "How do I get started with Premelo?",
      answer: (
        <p>
          Getting started with Premelo is easy. Simply sign up for a free
          account and start exploring our features. You can upgrade to our
          premium subscription, Premelo Plus, to unlock even more features and
          benefits.
        </p>
      ),
    },
    {
      id: 3,
      title: "Can I use Premelo on my mobile device?",
      answer: (
        <p>
          Yes, we offer mobile view for mobile devices, simply go to your
          favourite browser and open up premelo.app, so you can manage your
          tasks and projects on-the-go.
        </p>
      ),
    },
    {
      id: 4,
      title: "Do you offer customer support?",
      answer: (
        <p>
          Yes, we offer customer support to all of our users. You can contact us
          through our Help Center, or by emailing us directly at
          info@premelo.com.
        </p>
      ),
    },
    {
      id: 5,
      title: "How will Premelo help me in managing my work in a project?",
      answer: (
        <p>
          Premelo is a powerful project management tool designed to help you
          manage your work, team, and tasks, all in one place. With Premelo, you
          can easily create and assign tasks, track progress, and collaborate
          with your team in real-time. Our intuitive interface makes it easy to
          get started, and our robust features ensure that you can customize
          Premelo to meet the unique needs of your business. Whether you're
          managing deadlines, tracking milestones, or keeping everyone in the
          loop, Premelo has got you covered.
        </p>
      ),
    },
    {
      id: 6,
      title:
        "What is the difference of Premelo and other task management platform",
      answer: (
        <div>
          Premelo offers a number of features and benefits that distinguish it
          from other task management platforms. Here are a few examples:
          <ul>
            <li>
              Customization - With Premelo Plus, you can fully customize your
              project management platform to reflect your branding and
              personality. This includes the ability to create custom workflows,
              change color themes, and even connect to your custom domain.
            </li>
            <li>
              Internal Repositories - Premelo allows you to upload and organize
              files related to your tasks, so you can keep everything in one
              place and access it whenever you need it.
            </li>
            <li>
              Centralized Messaging - With Premelo Plus, you can connect your
              WhatsApp, Facebook, and Instagram accounts to communicate with
              your clients and teammates directly from the platform.
            </li>
            <li>
              Social Media Connect - You can also schedule and post content on
              your social media accounts directly from Premelo Plus.
            </li>
          </ul>
          Overall, Premelo offers a more comprehensive and customizable solution
          for managing your tasks, projects, and team.
        </div>
      ),
    },
    {
      id: 7,
      title: "How much does Premelo cost?",
      answer: (
        <div>
          <p>
            Premelo is totally free! However, we do offer a premium subscription
            service which is Premelo Plus, starts at $10 per user per month.
          </p>
          <p>
            What is Premelo Plus Premelo Plus is a premium subscription package
            that offers additional features and benefits beyond the standard
            Premelo platform. These features include Workflow tool, platform
            customization, domain and hosting, internal repositories,
            centralized messaging, and social media connect. With Premelo Plus,
            you can fully customize your project management platform with your
            own branding, connect to your custom domain, upload and organize
            files related to your tasks, communicate with your team and clients
            through a centralized messaging system, and even schedule and post
            content on your social media accounts. Upgrade to Premelo Plus today
            and start enjoying the benefits of a more powerful and customized
            project management tool.
          </p>
        </div>
      ),
    },
    {
      id: 8,
      title: "What is Workflow?",
      answer: (
        <p>
          Workflow in Premelo is a feature that allows you to create a
          customized series of steps for a project or task, and assign them to
          specific team members or groups. This helps to streamline and automate
          the process of completing tasks, as each step can be assigned to a
          different team member, and the system can automatically move the task
          to the next step once the previous step has been completed. This helps
          to ensure that tasks are completed efficiently and on time, and also
          allows for greater visibility and accountability throughout the task
          or project. The workflow feature is available in the premium version
          of Premelo, known as Premelo Plus.
        </p>
      ),
    },
    {
      id: 9,
      title: "What is Platform Customization?",
      answer: (
        <p>
          Platform customization is a feature of Premelo Plus, our premium
          subscription package, that allows you to fully customize your project
          management platform with your own branding and style. With platform
          customization, you can change the color theme, add your own logo and
          branding, and even connect to your custom domain. This feature helps
          you to create a more professional and personalized experience for your
          team and clients, and can help to increase brand recognition and
          trust.
        </p>
      ),
    },
    {
      id: 10,
      title: "What does the custom domain and hosting do?",
      answer: (
        <div>
          <p>
            The custom domain and hosting feature is included in Premelo Plus,
            our premium subscription package. With this feature, you can connect
            to your custom domain and fully utilize Premelo app on your domain.
            This means that instead of using Premelo's default domain, you can
            use your own custom domain to access and use the platform.
          </p>
          <p>
            In addition, when you share the platform with your clients or team,
            you can have the confidence to share it out because it does not
            carry the branding of Premelo, instead it is your logo and your
            branding. Instead of introducing your client that you are using a
            third-party platform for task management, you can simply share that
            is your own work management system.
          </p>
          <p>
            Overall, the custom domain and hosting feature allows you to create
            a more professional and personalized experience for your team and
            clients, and can help to increase brand recognition and trust.
          </p>
        </div>
      ),
    },
    {
      id: 11,
      title: "11.	What is Social Media connect?",
      answer: (
        <div>
          <p>
            Social Media connect is a feature of Premelo Plus, our premium
            subscription package, that allows you to connect your Facebook page
            and Instagram account to schedule and post content. With Social
            Media connect, you can manage your social media posts and updates
            directly from Premelo, instead of having to switch between different
            platforms.
          </p>
          <p>
            In addition, you can assign the task of posting or updating your
            social media to an assignee, which makes it easy to collaborate and
            delegate tasks among your team members. This feature helps you to
            save time and streamline your social media management process, while
            still maintaining a consistent and professional presence on social
            media.
          </p>
          <p>
            Overall, Social Media connect is a valuable feature for businesses
            and individuals who want to efficiently manage their social media
            presence and engage with their audience.
          </p>
        </div>
      ),
    },
    {
      id: 12,
      title: "What is Centralized Messaging system meant?",
      answer: (
        <div>
          <p>
            Centralized Messaging is a feature of Premelo Plus, our premium
            subscription package, that allows you to connect to your WhatsApp,
            Facebook, and Instagram accounts to communicate with your clients
            and teammates directly from the platform. With Centralized
            Messaging, you can keep all your communication in one place, instead
            of having to switch between different apps or platforms.
          </p>
          <p>
            This feature also allows you to assign messages to team members or
            assignees, so everyone knows who is responsible for each message. In
            addition, you can receive updates when new leads or enquiries come
            in through WhatsApp, Facebook, or Instagram, so you never miss an
            important message or opportunity.
          </p>
          <p>
            Overall, Centralized Messaging is a valuable feature for businesses
            and individuals who want to efficiently manage their communication
            with clients and team members, while keeping everything organized
            and in one place. It can save you time and make communication more
            seamless and effective.
          </p>
        </div>
      ),
    },
    {
      id: 13,
      title: "How many projects can I create from Premelo and Premelo Plus? ",
      answer: (
        <div>
          <p>
            With Premelo, our standard subscription package, you can create up
            to 8 projects.
          </p>
          <p>
            With Premelo Plus, our premium subscription package, you can create
            an unlimited number of projects.
          </p>
        </div>
      ),
    },
    {
      id: 14,
      title: "How many tasks can I create per project?",
      answer: (
        <div>
          <p>
            You can create an unlimited number of tasks per project no matter if
            you are on Premelo or Premelo Plus.
          </p>
        </div>
      ),
    },
    {
      id: 15,
      title: "How many assignees can I assign per task? ",
      answer: (
        <p>
          There is no limitation on the number of assignees that can be assigned
          to a task in Premelo and Premelo Plus. You can assign as many team
          members as necessary to a task to ensure that it is completed
          effectively and efficiently.
        </p>
      ),
    },
    {
      id: 16,
      title: "I can’t use the workflow function why?",
      answer: (
        <div>
          <p>
            Workflow is a premium feature that is only available in Premelo
            Plus.
          </p>
          <p>
            If you have subscribed to Premelo Plus and the Workflow function is
            not available, please contact our technical support team for
            assistance. They will be able to help you troubleshoot the issue and
            get the feature up and running as quickly as possible.
          </p>
        </div>
      ),
    },
    {
      id: 17,
      title: "My calendar is not getting updated with the task. Why?",
      answer: (
        <div>
          <ul>
            <li>
              Task is not assigned to you: Make sure that the task is assigned
              to you, as only the assigned tasks will appear on your calendar.
            </li>
            <li>
              Delay in sync: Sometimes, there may be a delay in syncing your
              tasks with your calendar. Wait for a few minutes and check again
              to see if the task appears on your calendar.
            </li>
            <li>
              Technical issues: There could be technical issues with the
              integration between Premelo and your calendar app. In this case,
              we recommend contacting our technical support team for assistance.
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 18,
      title: "I am not getting notify by my notification. Why?",
      answer: (
        <div>
          <ul>
            <li>
              Notification settings: Check your notification settings in Premelo
              to make sure that you have enabled notifications for the specific
              actions that you want to be notified about, such as task
              assignments, updates, and comments.
            </li>
            <li>
              Delay in sync: Sometimes, there may be a delay in syncing your
              tasks with your notifications. Wait for a few minutes and check
              again to see if the notifcations is being updated.
            </li>
            <li>
              Technical issues: There could be technical issues with the
              integration between Premelo and your notifications. In this case,
              we recommend contacting our technical support team for assistance.
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 19,
      title: "My profile is not being updated. Why?",
      answer: (
        <p>
          Delay in sync: Sometimes, there may be a delay in syncing your profile
          with the server. Wait for a few minutes and check again to see if your
          profile is being updated.
        </p>
      ),
    },
    {
      id: 20,
      title: "I am unable to add attachments to a task. Why?",
      answer: (
        <div>
          <ul>
            <li>
              File type: Check to make sure that the file type you are trying to
              attach is supported such as documents, images, and videos.
            </li>
            <li>
              File size: Check to make sure that the file size you are trying to
              attach is within the 200MB per file limit. Premelo may have a file
              size limit for attachments, and if the file you are trying to
              attach exceeds that limit, it will not be able to be uploaded.
            </li>
            <li>
              Technical issues: There could be technical issues with the system
              that are preventing attachments from being uploaded. In this case,
              we recommend contacting our technical support team for assistance.
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 21,
      title: "I am unable to add comment to a task. Why?",
      answer: (
        <div>
          <ul>
            <li>
              Technical issues: There could be technical issues with the system
              that are preventing comments from being added. In this case, we
              recommend contacting our technical support team for assistance.
            </li>
            <li>
              Internet connectivity: Check your internet connectivity to make
              sure that you are connected to the internet. If your internet
              connection is unstable or slow, you may have difficulty adding
              comments to tasks.
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 22,
      title: "The ChatBot is not answering me correctly. ",
      answer: (
        <div>
          <ul>
            <li>
              Try rephrasing your question: If the ChatBot is not able to
              understand your question, try rephrasing it using simpler language
              or using different keywords.
            </li>
            <li>
              Check your internet connection: Make sure that your internet
              connection is stable and strong enough to support the ChatBot.
              Slow or unstable connections can sometimes interfere with the
              ChatBot's ability to function properly.
            </li>
            <li>
              Technical issues: There could be technical issues with the ChatBot
              or the system that are preventing it from providing accurate
              responses. In this case, we recommend contacting our technical
              support team for assistance.
            </li>
          </ul>
          If you have tried these steps and are still having trouble getting
          accurate responses from the ChatBot in Premelo, please contact our
          technical support team through email for further assistance.
        </div>
      ),
    },
    {
      id: 23,
      title:
        "How do I know my payment to subscribe Premelo Plus is successful?",
      answer: (
        <div>
          <p>
            If you have subscribed to Premelo Plus and made a payment, you
            should receive a confirmation email from our payment processing
            provider. The email will confirm that your payment was successful
            and will include a receipt or invoice for your payment.
          </p>
          <p>
            In addition, you should also see your subscription status updated in
            your Premelo account settings. If your subscription is active and
            you have access to the premium features of Premelo Plus, then your
            payment has been successfully processed.
          </p>
          <p>
            If you have not received a confirmation email or your subscription
            status has not been updated, please check your spam or junk folder
            in case the email was filtered there. If you still do not see a
            confirmation, please contact our support team at info@premelo.com
            and we will assist you in resolving the issue.
          </p>
        </div>
      ),
    },
    {
      id: 24,
      title:
        "I received a confirmation email that my payment was successful, but I still do not have access to Premelo Plus. Why?",
      answer: (
        <div>
          <ul>
            <li>
              Delay in processing: It may take some time for your payment to be
              processed and for your subscription to be activated. Please wait
              for a few hours and try logging in to your account again.
            </li>
            <li>
              Incorrect login information: Make sure that you are logging in
              with the correct username and password associated with your
              account. If you are unsure, you can try resetting your password or
              contact our support team for assistance.
            </li>
          </ul>
          If you have tried these steps and you are still unable to access
          Premelo Plus, please contact our support team at info@premelo.com for
          further assistance.
        </div>
      ),
    },
    {
      id: 25,
      title: "What type of payment is accepted at Premelo?",
      answer: (
        <p>
          At Premelo, we accept payments from most major credit cards, including
          Visa, Mastercard, AMEX, and others.
        </p>
      ),
    },
  ];
  const filteredFaqs = faqs.filter((faq) =>
    faq.title.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const navigate = useNavigate();
  const [primaryColor, setPrimaryColor] = useState("#5AB1BA");

  const [isOpen, setIsOpen] = useState(false);

  function handleToggleMenu() {
    setIsOpen(!isOpen);
  }
  const FAQItem = ({ id, title, answer }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleToggle = () => {
      setIsCollapsed(!isCollapsed);
    };

    return (
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "0.01rem solid #d5dee0",
            padding: "10px 0px",
          }}
          onClick={handleToggle}
          className="mui-button"
        >
          <IconButton onClick={handleToggle}>
            {isCollapsed ? <ChevronRight /> : <ExpandMore />}
          </IconButton>
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: "bold",
            }}
          >
            {title}
          </Typography>
        </div>
        {!isCollapsed && (
          <Typography
            variant="body1"
            style={{
              marginLeft: "40px",
              paddingTop: "6px",
              fontSize: "12px",
              fontWeight: "300",
              paddingBottom: "20px",
            }}
          >
            {answer}
          </Typography>
        )}
      </div>
    );
  };

  return (
    <div id="outer-container" style={{ overflowX: "hidden" }}>
      <Menu
        right
        width={"100%"}
        isOpen={isOpen}
        customCrossIcon={false}
        customBurgerIcon={
          <Hamburger toggled={isOpen} toggle={handleToggleMenu} size={24} />
        }
      >
        <div>
          <div
            onClick={() => {
              navigate("/");
              window.scrollTo(0, 0);
            }}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              style={{
                color: "rgba(0,0,0,0.7)",
                textDecoration: "none",
                padding: "5px 0px",
              }}
            >
              Home
            </div>
            <NavigateNextIcon />
          </div>
          <hr
            style={{
              width: "100%",
              height: "0.01rem",
            }}
          />
          <div
            onClick={() => setIsOpen(false)}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              style={{
                color: "rgba(0,0,0,0.7)",
                textDecoration: "none",
                padding: "5px 0px",
              }}
            >
              Frequently Asked Questions
            </div>
            <NavigateNextIcon />
          </div>
          <hr
            style={{
              width: "100%",
              height: "0.01rem",
            }}
          />
          <div
            onClick={() => {
              navigate("/contactus");
              window.scrollTo(0, 0);
            }}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              style={{
                color: "rgba(0,0,0,0.7)",
                textDecoration: "none",
                padding: "5px 0px",
              }}
            >
              Contact Us
            </div>
            <NavigateNextIcon />
          </div>
          <hr
            style={{
              width: "100%",
              height: "0.01rem",
            }}
          />
          <div
            onClick={() => {
              navigate("/termsandcondition");
              window.scrollTo(0, 0);
            }}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div
              style={{
                color: "rgba(0,0,0,0.7)",
                textDecoration: "none",
                padding: "5px 0px",
              }}
            >
              Terms & Privacy
            </div>
            <NavigateNextIcon />
          </div>
          <div
            style={{
              width: "90%",
              textAlign: "center",
              padding: "15px 20px",
              background: "#d81b5e",
              color: "white",
              marginTop: "35px",
            }}
            onClick={() => {
              navigate("/login");
              window.scrollTo(0, 0);
            }}
          >
            Login
          </div>
          <div
            style={{
              width: "90%",
              textAlign: "center",
              padding: "15px 20px",
              border: "1px solid #d81b5e",
              color: "#d81b5e",
              marginTop: "15px",
            }}
            onClick={() => {
              navigate("/signup");
              window.scrollTo(0, 0);
            }}
          >
            Sign Up
          </div>
        </div>
      </Menu>
      <div style={{ width: "100%" }}>
        <div
          style={{
            background: "white",
            position: "fixed",
            zIndex: "2000",
            top: 0,
            height: "7vh",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
          }}
        >
          <div
            onClick={() => {
              navigate("/");
              window.scrollTo(0, 0);
            }}
            style={{
              height: "6vh",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              padding: "1px 0px",
              marginLeft: "20px",
            }}
          >
            <img src={logo} alt="Premelo logo" style={{ width: "50px" }} />
            <p
              style={{
                fontSize: "25px",
                fontWeight: "600",
                color: "black",
                marginTop: "18px",
              }}
            >
              Premelo
            </p>
          </div>
        </div>
        {/* first segment */}
        <div
          style={{
            marginTop: "7vh",
            padding: "35px 0px",
            textAlign: "center",
            background: "#d5dee0",
          }}
        >
          <div style={{ fontSize: "35px", fontWeight: "600" }}>
            Frequently Asked Questions
          </div>
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="🔍 Search by title, products and more"
            style={{
              border: "none",
              outline: "none",
              padding: "8px 20px",
              textAlign: "center",
              marginTop: "20px",
              borderRadius: "25px",
              height: "30px",
              width: "60%",
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingTop: "20px",
          }}
        >
          <div style={{ width: "90%" }}>
            {filteredFaqs.map((faq) => (
              <FAQItem
                key={faq.id}
                id={faq.id}
                title={faq.title}
                answer={faq.answer}
              />
            ))}
          </div>
        </div>
        {/* Website Footer */}
        <hr
          style={{
            width: "100%",
            height: "0.02rem",
            backgroundColor: "black",
            border: "none",
            margin: "10px auto",
          }}
        />
        <div
          style={{
            width: "100%",
            padding: "10px 0px 20px 0px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", width: "30%", marginLeft: "10%" }}>
            <FacebookIcon
              className="mui-button"
              style={{ fontSize: "20px", marginRight: "15px" }}
              onClick={() =>
                (window.location.href = "https://www.facebook.com/premeloapp")
              }
            />
            <InstagramIcon
              className="mui-button"
              style={{ fontSize: "20px", marginRight: "15px" }}
              onClick={() =>
                (window.location.href =
                  "https://www.instagram.com/premelo_app/")
              }
            />
            <YouTubeIcon
              className="mui-button"
              style={{ fontSize: "20px", marginRight: "15px" }}
              onClick={() =>
                (window.location.href = "https://www.youtube.com/@Premelo-Inc")
              }
            />
          </div>
          <div
            style={{
              display: "flex",
              width: "70%",
              fontSize: "16px",
              fontWeight: "300",
              textAlign: "right",
              justifyContent: "flex-end",
              marginRight: "10%",
            }}
          >
            <div>© 2023 Premelo, Inc.</div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FAQMobile;
