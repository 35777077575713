import React, { useState, useEffect } from "react";
import logo from "./images/CS_Favicon-01.png";
import { useNavigate } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import InfoIcon from "@mui/icons-material/Info";
import PricingMobile from "./PricingMobile";

const Pricing = () => {
  const navigate = useNavigate();
  const [primaryColor, setPrimaryColor] = useState("#5AB1BA");

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/.test(
      userAgent
    );
    setIsMobile(isMobile);
  }, []);
  if (isMobile) {
    return <PricingMobile />;
  }


  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          height: "7vh",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "10px",
        }}
      >
        <div
          style={{
            height: "6vh",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "1px 0px",
            marginLeft: "40px",
          }}
        >
          <img src={logo} alt="Premelo logo" style={{ width: "42px" }} />
          <p
            style={{
              fontSize: "20px",
              fontWeight: "600",
              color: "black",
            }}
          >
            Premelo
          </p>
        </div>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <button
            onClick={() => navigate("/")}
            className="homepage-nav-buttons"
            style={{
              height: "4.5vh",
              padding: "0px 20px",
              borderRadius: "50px",
              border: "none",
              background: "transparent",
              fontWeight: "600",
              fontSize: "12px",
            }}
          >
            Home
          </button>
          <button
            onClick={() => navigate("/pricing")}
            className="homepage-nav-buttons"
            style={{
              height: "4.5vh",
              padding: "0px 20px",
              borderRadius: "50px",
              border: "none",
              background: "transparent",
              fontWeight: "600",
              fontSize: "12px",
              color: "#d81b5e",
            }}
          >
            Pricing
          </button>
          <button
            onClick={() => navigate("/faq")}
            className="homepage-nav-buttons"
            style={{
              height: "4.5vh",
              padding: "0px 20px",
              borderRadius: "50px",
              border: "none",
              background: "transparent",
              fontWeight: "600",
              fontSize: "12px",
            }}
          >
            FAQ
          </button>
          <button
            onClick={() => navigate("/contactus")}
            className="homepage-nav-buttons"
            style={{
              height: "4.5vh",
              padding: "0px 20px",
              borderRadius: "50px",
              border: "none",
              background: "transparent",
              fontWeight: "600",
              fontSize: "12px",
            }}
          >
            Contact
          </button>
          <button
            onClick={() => navigate("/termsandcondition")}
            className="homepage-nav-buttons"
            style={{
              height: "4.5vh",
              padding: "0px 20px",
              borderRadius: "50px",
              border: "none",
              background: "transparent",
              fontWeight: "600",
              fontSize: "12px",
            }}
          >
            Terms & Privacy
          </button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "40px",
          }}
        >
          <button
            className="homepage-nav-side-2"
            style={{
              marginRight: "15px",
              height: "4.5vh",
              padding: "0px 20px",
              borderRadius: "50px",
              border: "none",
              fontSize: "12px",
            }}
            onClick={() => {
              navigate("/signup");
              window.scrollTo(0, 0);
            }}
          >
            Sign Up
          </button>
          <button
            className="homepage-nav-side-1"
            style={{
              height: "4.5vh",
              padding: "0px 30px",
              borderRadius: "50px",
              border: "none",
              fontSize: "12px",
            }}
            onClick={() => {
              navigate("/login");
              window.scrollTo(0, 0);
            }}
          >
            Login
          </button>
        </div>
      </div>
      {/* first segment */}
      <div
        style={{
          padding: "50px 0px",
          textAlign: "center",
          background: "#d5dee0",
        }}
      >
        <div style={{ fontSize: "12px", color: "rgba(0,0,0,0.6)" }}>
          Pricing
        </div>
        <div style={{ fontSize: "35px", fontWeight: "600" }}>
          Revolutionize Your Workflow. Start Free.
        </div>
        <div style={{ fontSize: "12px", fontWeight: "300" }}>
          Experience the power of Premelo's work management platform for free -
          no charge or commitment
        </div>
        <div style={{ fontSize: "12px", fontWeight: "300" }}>
          necessary. Upgrade to Premelo Plus for even more features.
        </div>
        <button
          style={{
            color: "white",
            background: "rgba(0,0,0,1)",
            padding: "10px 35px",
            borderRadius: "25px",
            fontSize: "17px",
            fontWeight: "600",
            marginTop: "20px",
          }}
          className="mui-button-home"
          onClick={() => {
            navigate("/signup");
            window.scrollTo(0, 0);
          }}
        >
          Get started
        </button>
      </div>
      {/* pricing table */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          fontWeight: "300",
        }}
      >
        <div
          style={{
            width: "80%",
            margin: "20px 0px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "12px",
            }}
          >
            <div
              style={{
                width: "30%",
                border: "0.01rem solid #d4d4d4",
                borderRadius: "15px 0px 0px 0px",
                padding: "10px",
              }}
            ></div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
              }}
            >
              <div style={{ fontSize: "18px", fontWeight: "500" }}>Lite</div>
              <div>Perfect for managing</div>
              <div style={{ marginTop: "-5px" }}>teams.</div>
              <hr
                style={{
                  width: "10%",
                  height: "0.02rem",
                  backgroundColor: "#a9b5b8",
                  border: "none",
                  margin: "10px auto",
                }}
              />
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  marginTop: "10px",
                }}
              >
                Free
              </div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
              }}
            >
              <div style={{ fontSize: "18px", fontWeight: "500" }}>Plus</div>
              <div>Scalable pricing for</div>
              <div style={{ marginTop: "-5px" }}>any team sizes.</div>
              <hr
                style={{
                  width: "10%",
                  height: "0.02rem",
                  backgroundColor: "#a9b5b8",
                  border: "none",
                  margin: "10px auto",
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ marginTop: "10px", marginRight: "5px" }}>US$</div>
                <div
                  style={{
                    fontSize: "30px",
                    fontWeight: "600",
                    marginTop: "0px",
                  }}
                >
                  12
                </div>
                <div style={{ marginTop: "17px", marginLeft: "5px" }}>
                  / Seat
                </div>
              </div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                borderRadius: "0px 15px 0px 0px",
                padding: "10px",
              }}
            >
              <div style={{ fontSize: "18px", fontWeight: "500" }}>
                Enterprise
              </div>
              <div>Get the full Premelo</div>
              <div style={{ marginTop: "-5px" }}>with teams {">"} 100</div>
              <hr
                style={{
                  width: "10%",
                  height: "0.02rem",
                  backgroundColor: "#a9b5b8",
                  border: "none",
                  margin: "10px auto",
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ marginTop: "10px", marginRight: "5px" }}>US$</div>
                <div
                  style={{
                    fontSize: "30px",
                    fontWeight: "600",
                    marginTop: "0px",
                  }}
                >
                  7
                </div>
                <div style={{ marginTop: "17px", marginLeft: "5px" }}>
                  / Seat
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "12px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "30%",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                height: "3vh",
              }}
            >
              <div>Custom Domain</div>
              <div>
                <InfoIcon
                  style={{ fontSize: "18px" }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-html="Connect your custom domain and allow your team, <br /> collaborators, and customers to access from your domain."
                />
                <Tooltip id="my-tooltip" place="right" />
              </div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div>-</div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "12px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "30%",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                height: "3vh",
              }}
            >
              <div>Remove Premelo Branding</div>
              <div>
                <InfoIcon
                  style={{ fontSize: "18px" }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-html="Allows users to change and automatically removes <br /> Premelo branding on dashboard."
                />
                <Tooltip id="my-tooltip" place="right" />
              </div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div>-</div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "12px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "30%",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                height: "3vh",
              }}
            >
              <div>Customized Platform</div>
              <div>
                <InfoIcon
                  style={{ fontSize: "18px" }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-html="Provides admins with Premelo's fully customizable <br /> features and tools to brand the dashboard theme."
                />
                <Tooltip id="my-tooltip" place="right" />
              </div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div>-</div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "12px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "30%",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                height: "3vh",
              }}
            >
              <div>Free SSL Certificate</div>
              <div>
                <InfoIcon
                  style={{ fontSize: "18px" }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-html="Users that connect their domain will be provided a <br /> free SSL Certificate making their website appear as <br/> secured."
                />
                <Tooltip id="my-tooltip" place="right" />
              </div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div>-</div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "12px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "30%",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                height: "3vh",
              }}
            >
              <div>Team Size</div>
              <div>
                <InfoIcon
                  style={{ fontSize: "18px" }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-html="Users allowed on the platform are decided base on the <br /> paackage subscribed."
                />
                <Tooltip id="my-tooltip" place="right" />
              </div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div>Limited to 8 Users</div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div>Up to 99 Users</div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div>Unlimited</div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "12px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "30%",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                height: "3vh",
              }}
            >
              <div>Task Management Tools</div>
              <div>
                <InfoIcon
                  style={{ fontSize: "18px" }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-html="Premelo Task Management Tools comes free with any <br /> subscription, allowing anybody and everybody to utilize <br/> our tools."
                />
                <Tooltip id="my-tooltip" place="right" />
              </div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "12px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "30%",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                height: "3vh",
              }}
            >
              <div>Customized Workflow Building</div>
              <div>
                <InfoIcon
                  style={{ fontSize: "18px" }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-html="Premelo's customized workflow building allows <br /> users to create flows of task as if operational in their <br/> own company."
                />
                <Tooltip id="my-tooltip" place="right" />
              </div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "12px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "30%",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                height: "3vh",
              }}
            >
              <div>File Repositories</div>
              <div>
                <InfoIcon
                  style={{ fontSize: "18px" }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-html="As you would use any platform for file storage, you <br /> can now use Premelo's fast and effective file storage to store <br/> and retrieve your files quickly and swiftly."
                />
                <Tooltip id="my-tooltip" place="right" />
              </div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div>Unlimited</div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div>Unlimited</div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div>Unlimited</div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "12px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "30%",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                height: "3vh",
              }}
            >
              <div>Access Control</div>
              <div>
                <InfoIcon
                  style={{ fontSize: "18px" }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-html="Control what your team sees and have access <br /> to in your platform."
                />
                <Tooltip id="my-tooltip" place="right" />
              </div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div>-</div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "12px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "30%",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                height: "3vh",
              }}
            >
              <div>Social Media Connect</div>
              <div>
                <InfoIcon
                  style={{ fontSize: "18px" }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-html="Connect your company's social media platforms <br /> allowing your marketing team to have publishing tools access <br/> all in one platform."
                />
                <Tooltip id="my-tooltip" place="right" />
              </div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div>-</div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontSize: "12px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "30%",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                display: "flex",
                justifyContent: "space-between",
                height: "3vh",
              }}
            >
              <div>Centralized Messaging</div>
              <div>
                <InfoIcon
                  style={{ fontSize: "18px" }}
                  data-tooltip-id="my-tooltip"
                  data-tooltip-html="Provides your social media team access all in one place <br /> to reply to facebook, instagram and whatsapp messages on behalf <br/> of the company."
                />
                <Tooltip id="my-tooltip" place="right" />
              </div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div>-</div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
            </div>
            <div
              style={{
                width: "23%",
                textAlign: "center",
                border: "0.01rem solid #d4d4d4",
                padding: "10px",
                height: "3vh",
              }}
            >
              <div style={{ fontWeight: "400", fontSize: "18px" }}>✓</div>
            </div>
          </div>
        </div>
      </div>

      {/* Website Footer */}
      <hr
        style={{
          width: "100%",
          height: "0.02rem",
          backgroundColor: "black",
          border: "none",
          margin: "10px auto",
        }}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "80%",
            justifyContent: "space-between",
            textAlign: "left",
            padding: "20px 0px",
          }}
        >
          <div style={{ width: "16%", fontSize: "12px", fontWeight: "300" }}>
            <div style={{ fontSize: "15px", fontWeight: "400" }}>Learn</div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/taskmanagement");
                window.scrollTo(0, 0);
              }}
            >
              Task Management
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/teaminvite");
                window.scrollTo(0, 0);
              }}
            >
              Team Invite
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/customworkflow");
                window.scrollTo(0, 0);
              }}
            >
              Custom Workflow
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/platformcustomization");
                window.scrollTo(0, 0);
              }}
            >
              Platform Customization
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/domainandhosting");
                window.scrollTo(0, 0);
              }}
            >
              Domain & Hosting
            </div>
          </div>
          <div style={{ width: "16%", fontSize: "12px", fontWeight: "300" }}>
            <div style={{ fontSize: "15px", fontWeight: "400" }}>Products</div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/workmanagement");
                window.scrollTo(0, 0);
              }}
            >
              Work Management
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/socialmediaconnect");
                window.scrollTo(0, 0);
              }}
            >
              Social Media Connect
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/internalrepositories");
                window.scrollTo(0, 0);
              }}
            >
              Internal Repositories
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/centralizedmessaging");
                window.scrollTo(0, 0);
              }}
            >
              Centralized Messaging
            </div>
          </div>
          <div style={{ width: "11%", fontSize: "12px", fontWeight: "300" }}>
            <div style={{ fontSize: "15px", fontWeight: "400" }}>Support</div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/contactus");
                window.scrollTo(0, 0);
              }}
            >
              Help Center
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/faq");
                window.scrollTo(0, 0);
              }}
            >
              FAQ
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/contactus");
                window.scrollTo(0, 0);
              }}
            >
              System Status
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/chatwithagent");
                window.scrollTo(0, 0);
              }}
            >
              Speak to our agents
            </div>
          </div>
          <div style={{ width: "16%", fontSize: "12px", fontWeight: "300" }}>
            <div style={{ fontSize: "15px", fontWeight: "400" }}>Company</div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/pressandannouncement");
                window.scrollTo(0, 0);
              }}
            >
              Press & Announcement
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/contactus");
                window.scrollTo(0, 0);
              }}
            >
              Careers
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/contactus");
                window.scrollTo(0, 0);
              }}
            >
              Sitemap
            </div>
          </div>
          <div style={{ width: "20%", fontSize: "12px", fontWeight: "300" }}>
            <img
              src={logo}
              alt="premelo logo"
              style={{ width: "30%", marginTop: "-5px" }}
            />
            <div style={{ marginTop: "-5px" }}>
              Premelo is a customizable work management platform that enables
              easy collaboration and real-time updates. Streamline workflow and
              boost productivity today.
            </div>
          </div>
        </div>
      </div>
      <hr
        style={{
          width: "100%",
          height: "0.02rem",
          backgroundColor: "black",
          border: "none",
          margin: "10px auto",
        }}
      />
      <div
        style={{
          width: "100%",
          padding: "10px 0px 20px 0px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", width: "50%", marginLeft: "10%" }}>
          <FacebookIcon
            className="mui-button"
            style={{ fontSize: "20px", marginRight: "15px" }}
            onClick={() =>
              (window.location.href = "https://www.facebook.com/premeloapp")
            }
          />
          <InstagramIcon
            className="mui-button"
            style={{ fontSize: "20px", marginRight: "15px" }}
            onClick={() =>
              (window.location.href = "https://www.instagram.com/premelo_app/")
            }
          />
          <YouTubeIcon
            className="mui-button"
            style={{ fontSize: "20px", marginRight: "15px" }}
            onClick={() =>
              (window.location.href = "https://www.youtube.com/@Premelo-Inc")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            width: "50%",
            fontSize: "12px",
            fontWeight: "300",
            textAlign: "right",
            justifyContent: "flex-end",
            marginRight: "10%",
          }}
        >
          <div
            className="mui-button"
            style={{ marginRight: "20px" }}
            onClick={() => {
              navigate("/termsandcondition");
              window.scrollTo(0, 0);
            }}
          >
            Terms & Privacy
          </div>
          <div
            className="mui-button"
            style={{ marginRight: "20px" }}
            onClick={() => {
              navigate("/contactus");
              window.scrollTo(0, 0);
            }}
          >
            Contact Us
          </div>
          <div>© 2023 Premelo, Inc.</div>
        </div>
      </div>
    </div>
  );
};
export default Pricing;
