import React, { useState, useEffect, useRef } from "react";
import "react-phone-number-input/style.css";
import { useDrop, useDrag } from "react-dnd";
import { DndProvider } from "react-dnd";
import {
  Check,
  ChevronRight,
  Cookie,
  ExpandMore,
  PhoneMissed,
} from "@mui/icons-material";
import { ItemTypes } from "./ItemTypes";
import "./App.css";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Checkbox from "@mui/material/Checkbox";
import PhoneInput from "react-phone-number-input";
import Modal from "react-modal";
import Select from "@mui/material/Select";
import ReactQuill from "react-quill";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import Cookies from "js-cookie";
import { ListItemText, ListItemIcon } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { MentionsInput, Mention } from "react-mentions";
import axios from "axios";
import CheckIcon from "@mui/icons-material/CheckCircleOutline";
import CheckedIcon from "@mui/icons-material/CheckCircle";
import "./CustomDropdown.css";
import KanbanSkeleton from "./KanbanSkeleton";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import CDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import RttIcon from "@mui/icons-material/Rtt";
import { motion } from "framer-motion";

const KanbanContent = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [statusOptions, setStatusOptions] = useState([]);
  const { primaryColor, complementaryColor } = props;
  const [projectName, setProjectName] = useState(props.activeProject);
  const [projectId, setProjectId] = useState(props.activeProject);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [customStatus, setCustomStatus] = useState("");
  const [customColor, setCustomColor] = useState("#701ba1");
  const [addedRowId, setAddedRowId] = useState("");
  const [availableUsers, setAvailableUsers] = useState([]);
  function getFileIcon(fileName) {
    const extension = fileName.split(".").pop().toLowerCase();

    if (extension === "pdf") {
      return (
        <PictureAsPdfIcon
          style={{ fontSize: "40px", color: props.primaryColor }}
        />
      );
    } else if (["jpg", "jpeg", "png"].includes(extension)) {
      return (
        <ImageIcon style={{ fontSize: "40px", color: props.primaryColor }} />
      );
    } else {
      return (
        <InsertDriveFileIcon
          style={{ fontSize: "40px", color: props.primaryColor }}
        />
      );
    }
  }

  function getMessageFileIcon(fileName) {
    const extension = fileName.split(".").pop().toLowerCase();

    if (extension === "pdf") {
      return (
        <PictureAsPdfIcon
          style={{ fontSize: "25px", color: props.primaryColor }}
        />
      );
    } else if (["jpg", "jpeg", "png"].includes(extension)) {
      return (
        <ImageIcon style={{ fontSize: "25px", color: props.primaryColor }} />
      );
    } else {
      return (
        <InsertDriveFileIcon
          style={{ fontSize: "25px", color: props.primaryColor }}
        />
      );
    }
  }

  const handleDefaultSection = () => {
    const newTableData = [
      {
        taskType: "Default",
        data: [],
      },
    ];
    setTableData(newTableData);
    setNewSectionName("");
    setIsLoading(false);
  };

  const fetchCompanyTask = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      `https://ww1.premelo.com/api/retrieve-company-tasks/${props.activeProjectId}`,
      {
        headers: {
          Accept: `application/json`,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    if (data.length === 0) {
      handleDefaultSection(); // call the handleDefaultSection function
      return; // exit the fetchCompanyTask function early
    } else {
      //do the filter here
      //   const filteredData = data.filter(
      //     (task) => task.task_project_id === props.activeProjectId
      //   );

      const taskTypes = [...new Set(data.map((task) => task.task_type))];

      const initialTableData = taskTypes.map((taskType) => ({
        taskType,
        data: data.filter((task) => task.task_type === taskType),
      }));

      console.log("initial table data:", initialTableData);
      setTableData(initialTableData);
      setIsLoading(false);
    }
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    const newData = tableData.map((table) => {
      if (table.taskType === selectedRow.task_type) {
        const updatedData = table.data.map((item) => {
          if (item.id === selectedRow.id) {
            const updatedItem = {
              ...item,
              task_status: option.value,
            };

            fetch(`https://ww1.premelo.com/api/update-task/${updatedItem.id}`, {
              method: "PUT",
              body: JSON.stringify(updatedItem),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            })
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                console.error(error);
              });

            return updatedItem;
          }
          return item;
        });
        return {
          ...table,
          data: updatedData,
        };
      }
      return table;
    });
    console.log(newData);
    setTableData(newData);
    setStatus(option.value);
    setIsOpen(false);
  };

  const handleCustomStatusChange = (event) => {
    console.log(event.target.value);
    setCustomStatus(event.target.value);
  };

  const handleCustomColorChange = (event) => {
    console.log(event.target.value);
    setCustomColor(event.target.value);
  };

  const [isOpen, setIsOpen] = useState(false);
  const handleSortClick = (columnName) => {
    if (sortedColumn === columnName) {
      // if same column, toggle sort direction
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    } else {
      // if new column, set sort column and direction to default (ascending)
      setSortDirection("asc");
      setSortedColumn(columnName);
    }
  };
  const fetchUsers = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/user-available-team-members",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    const transformedResponse = data.map((obj) => {
      return {
        id: obj.user_id,
        first_name: obj.first_name,
        last_name: obj.last_name,
        user_color: obj.user_color,
      };
    });
    const modifiedData = transformedResponse.map((user) => {
      return {
        ...user,
        fullName: `${user.first_name} ${user.last_name}`,
      };
    });
    console.log("available users with fullName:", modifiedData);
    setAvailableUsers(modifiedData);
  };

  const fetchProjectAvailableStatus = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      `https://ww1.premelo.com/api/projects/${props.activeProjectId}/status-options`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    console.log("data from api:", JSON.parse(data.status_options));
    console.log("data from default value:", statusOptions);
    setStatusOptions(JSON.parse(data.status_options));
  };

  const DEFAULT_COLUMNS = [
    {
      key: "task_title",
      label: "Task Title",
      width: "500px",
      textAlign: "left",
    },
    {
      key: "task_description",
      label: "Description",
      width: "200px",
      textAlign: "left",
    },
    {
      key: "task_due_date",
      label: "Due Date",
      width: "200px",
      textAlign: "left",
    },
    {
      key: "task_created_date",
      label: "Created Date",
      width: "200px",
      textAlign: "left",
    },
  ];

  const [showStatusRow, setShowStatusRow] = useState(
    Cookies.get("showStatusRow") === "true" ? true : true
  );
  const [showTaskPurpose, setShowTaskPurpose] = useState(
    Cookies.get("showTaskPurpose") === "true" ? true : false
  );
  const [showPhoneNumber, setShowPhoneNumber] = useState(
    Cookies.get("showPhoneNumber") === "true" ? true : false
  );

  const [columns, setColumns] = useState(DEFAULT_COLUMNS);

  const [selectedRow, setSelectedRow] = useState("");
  const [taskModalIsOpen, setTaskModalIsOpen] = useState(true);
  const [taskTitle, setTaskTitle] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [status, setStatus] = useState("");
  const [purpose, setPurpose] = useState("");
  const [taskAssignedTo, setTaskAssignedTo] = useState("");
  const [isCompleted, setIsCompleted] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [taskId, setTaskId] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [modaldisplay, setModaldisplay] = useState(false);
  const [modalMessages, setModalMessages] = useState({});
  const [taskAssignedBy, setTaskAssignedBy] = useState("");

  function deleteTasks() {
    const token = Cookies.get("token");

    axios
      .delete(`https://ww1.premelo.com/api/deletetasks/${taskId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log("Workflow rule deleted:", response.data.message);
        // TODO: Update state to remove the deleted rule from the UI

        setModaldisplay(false);
        fetchCompanyTask();
      })
      .catch((error) => {
        console.error("Failed to delete workflow rule:", error);
      });
  }

  const handleModalOpen = async (row) => {
    setSelectedRow(row);
    console.log("selected row:", row.id);
    setModaldisplay(true);
    setTaskId(row.id);
    setTaskTitle(row.task_title);
    setTaskDescription(row.task_description);
    setSelectedUsers(row.task_assigned_to);
    setDueDate(row.task_due_date);
    setTaskAssignedTo(row.task_assigned_to);
    setIsCompleted(row.task_is_complete);
    setPhoneNumber(row.task_phone_number);
    setPurpose(row.task_purpose);
    setTaskAssignedBy(row.task_assigned_by);
    setStatus(row.task_status);
    setSelectedOption({
      label: row.task_status,
      label: row.task_status,
      color:
        statusOptions.find((option) => option.value === row.task_status)
          ?.color || props.primaryColor,
    });
    const taskAttachments = await fetch(
      `https://ww1.premelo.com/api/task/${row.id}/attachments`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const response = await fetch(
      `https://ww1.premelo.com/api/task/${row.id}/getmessages`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await response.json();
    console.log("message data:", data);
    console.log(availableUsers);
    setModalMessages(data);

    const attachedInTask = await taskAttachments.json();
    setTaskAttachmentUrl(
      attachedInTask.map((attachment) => ({
        url: attachment.attachment_url,
        fileName: attachment.file_name,
      }))
    );
  };

  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelectedUsers(value);
  };

  const [newTaskRow, setNewTaskRow] = useState(false);

  const Table = ({ title, data, droppedTaskType }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const handleToggleCollapse = () => {
      setIsCollapsed(!isCollapsed);
    };

    const [{ isOver }, drop] = useDrop({
      accept: "task",
      drop: (item, monitor) => {
        const draggedItemDetails = monitor.getItem();
        onDrop(
          item.id,
          item.taskType,
          item.taskTitle,
          item.dueDate,
          item.createdDate,
          droppedTaskType,
          item.taskStatus,
          item.taskPurpose,
          item.taskPhoneNumber,
          item.taskIsComplete,
          item.taskAssignedTo
        );
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
      }),
    });

    const onDrop = (
      id,
      oldTaskType,
      taskTitle,
      dueDate,
      createdDate,
      droppedTaskType,
      taskStatus,
      taskPurpose,
      taskPhoneNumber,
      taskIsComplete,
      taskAssignedTo
    ) => {
      if (oldTaskType === droppedTaskType) {
        return tableData;
      }
      const newTableData = tableData.map((table) => {
        if (table.taskType === oldTaskType) {
          // remove the task with the given id from this table
          const newData = table.data.filter((task) => task.id !== id);
          return {
            ...table,
            data: newData,
          };
        } else if (table.taskType === droppedTaskType) {
          // add the task to this table
          const updatedTask = {
            id: id,
            task_type: droppedTaskType,
            task_title: taskTitle,
            task_created_date: createdDate,
            task_due_date: dueDate,
            task_status: taskStatus,
            task_purpose: taskPurpose,
            task_phone_number: taskPhoneNumber,
            task_is_complete: taskIsComplete,
            task_assigned_to: taskAssignedTo,
          };

          fetch(`https://ww1.premelo.com/api/update-task/${updatedTask.id}`, {
            method: "PUT",
            body: JSON.stringify(updatedTask),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          })
            .then((response) => {
              console.log(response);
            })
            .catch((error) => {
              console.error(error);
            });

          return {
            ...table,
            data: [...table.data, updatedTask],
          };
        } else {
          // no change needed for this table
          return table;
        }
      });
      console.log(newTableData);
      setTableData(newTableData);
    };

    const handleAddRow = (taskType) => {
      // Add a new row to the table data
      const newTableData = tableData.map((table) =>
        table.taskType === taskType
          ? {
              taskType,
              data: [
                ...table.data,
                {
                  id: Math.random().toString(36).substring(7),
                  task_type: taskType,
                  task_project_id: props.activeProjectId,
                  task_title: "",
                  task_description: "",
                  task_created_date: new Date().toISOString().slice(0, 10),
                  task_start_date: "",
                  task_due_date: "",
                  task_due_time: "",
                  task_duration: "",
                  task_assigned_by: 3,
                  task_assigned_to: [],
                  task_is_complete: 0,
                  task_status: "",
                  task_checkbox: false,
                  task_phone_number: "",
                  task_purpose: "",
                  created_at: new Date().toISOString(),
                  updated_at: new Date().toISOString(),
                },
              ],
            }
          : table
      );
      // Update the table data
      console.log(newTableData);
      setTableData(newTableData);
      setNewTaskRow(true);
      //   setIsEditing(true);

      const newRow = newTableData
        .find((table) => table.taskType === taskType)
        ?.data.slice(-1)[0];
      if (newRow) {
        setAddedRowId(newRow.id);
      }
    };

    const sortedData = data.sort((a, b) => {
      const columnA = a[sortedColumn];
      const columnB = b[sortedColumn];

      if (columnA < columnB) {
        return sortDirection === "asc" ? -1 : 1;
      }
      if (columnA > columnB) {
        return sortDirection === "asc" ? 1 : -1;
      }
      return 0;
    });

    return (
      <div
        className="table-container"
        onDragOver={(e) => e.preventDefault()}
        ref={drop}
        style={{ width: "320px", marginRight: "50px" }}
      >
        <div style={{ display: "flex" }}>
          <button
            className="mui-button"
            onClick={handleToggleCollapse}
            style={{
              width: "30px",
              height: "30px",
              marginTop: "19px",
              marginRight: "0px",
              border: "none",
              borderRadius: "10px",
              paddingRight: "28px",
              paddingTop: "2px",
              background: "transparent",
            }}
          >
            {isCollapsed ? <ChevronRight /> : <ExpandMore />}
          </button>
          <h2 style={{ fontSize: "20px" }}>{title}</h2>
        </div>
        <div
          style={{
            display: isCollapsed ? "none" : "block",
            backgroundColor: isOver ? "#efefef" : "rgba(0,0,0,0)",
          }}
        >
          <div>
            {sortedData.map((row) => (
              <TableRow key={row.id} data={row} onUpdate={handleUpdateTask} />
            ))}
          </div>
        </div>
        <button
          className="mui-button"
          style={{
            color: "white",
            background: props.primaryColor,
            border: "none",
            borderRadius: "10px",
            padding: "5px 25px",
          }}
          onClick={() => handleAddRow(title)}
        >
          Add Task
        </button>
      </div>
    );
  };

  const handleUpdateTask = (updatedTask) => {
    const newTableData = tableData.map((table) => {
      const newData = table.data.map((task) => {
        if (task.id === updatedTask.id) {
          return updatedTask;
        }
        return task;
      });
      return {
        ...table,
        data: newData,
      };
    });
    console.log("task is updated", newTableData);
    setTableData(newTableData);
  };

  const TableRow = ({ data, onUpdate, onClick }) => {
    const [{ isDragging }, drag] = useDrag({
      type: "task",
      item: {
        id: data.id,
        taskType: data.task_type,
        taskTitle: data.task_title,
        createdDate: data.task_created_date,
        dueDate: data.task_due_date,
        taskStatus: data.task_status,
        taskPurpose: data.task_purpose,
        taskPhoneNumber: data.task_phone_number,
        taskIsComplete: data.task_is_complete,
        taskAssignedTo: data.task_assigned_to,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [taskTitle, setTaskTitle] = useState(data.task_title);
    const [taskDescription, setTaskDescription] = useState(
      data.task_description || ""
    );
    const [createdDate, setCreatedDate] = useState(data.task_created_date);
    const [dueDate, setDueDate] = useState(data.task_due_date);
    const [phoneNumber, setPhoneNumber] = useState(data.task_phone_number);
    const [isComplete, setIsComplete] = useState(data.task_is_complete);
    const [purpose, setPurpose] = useState(data.task_purpose);
    const [status, setStatus] = useState(data.task_status);
    const [isEditing, setIsEditing] = useState(false);
    const [initialTaskTitle, setInitialTaskTitle] = useState(data.task_title);
    const [selectedUser, setSelectedUser] = useState(data.task_assigned_to);

    useEffect(() => {
      if (addedRowId === data.id) {
        handleEditClick();
      }
    }, [addedRowId, data.id]);

    const activeTableRowRef = useRef(null);
    const taskTitleInputRef = useRef(null);

    useEffect(() => {
      if (isEditing && taskTitleInputRef.current) {
        taskTitleInputRef.current.focus();
      }
    }, [isEditing, taskTitleInputRef]);

    const handleEditClick = () => {
      setIsEditing(true);
    };

    const handleCancelClick = () => {
      if (taskTitle.trim()) {
        handleSaveClick();
      } else {
        setIsEditing(false);

        const rowIndex = tableData.findIndex(
          (table) => table.taskType === data.task_type
        );
        const newData = [...tableData];
        // Remove the row from the table data
        newData[rowIndex].data = newData[rowIndex].data.filter(
          (row) => row.id !== data.id
        );
        // Update the state with the new table data
        setTableData(newData);
      }
    };

    const handleSaveClick = () => {
      setIsEditing(false);
      const token = Cookies.get("token");
      const newTableData = tableData.map((table) => {
        if (table.taskType === data.task_type) {
          const newData = table.data.map((item) => {
            if (item.id === data.id) {
              const updatedItem = {
                ...item,
                task_title: taskTitle,
                task_description: taskDescription,
                task_created_date: createdDate,
                task_due_date: dueDate,
                task_status: status,
                task_purpose: purpose,
                task_phone_number: phoneNumber,
                task_is_complete: isComplete,
                task_assigned_to: selectedUser,
              };

              if (newTaskRow) {
                fetch("https://ww1.premelo.com/api/add-task", {
                  method: "POST",
                  body: JSON.stringify(updatedItem),
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                  },
                })
                  .then((response) => {
                    console.log(response);
                    setNewTaskRow(false);
                  })
                  .catch((error) => {
                    console.error(error);
                  });
                console.log("this is a new task");
                setNewTaskRow(false);
              } else {
                fetch(
                  `https://ww1.premelo.com/api/update-task/${updatedItem.id}`,
                  {
                    method: "PUT",
                    body: JSON.stringify(updatedItem),
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                  .then((response) => {
                    console.log(response);
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              }

              console.log("updated item:", updatedItem);

              return updatedItem;
            }
            return item;
          });
          return {
            ...table,
            data: newData,
          };
        }
        return table;
      });
      console.log("table data changed:", newTableData);
      setTableData(newTableData);

      // Update the initial task title to the new task title
      setInitialTaskTitle(taskTitle);

      if (addedRowId === data.id) {
        setAddedRowId("");
      }
    };

    const handleClickOutside = (event) => {
      if (
        activeTableRowRef.current &&
        !activeTableRowRef.current.contains(event.target)
      ) {
        const token = Cookies.get("token");
        const taskTitleField =
          activeTableRowRef.current.querySelector("input[type='text']");
        const dueDateField =
          activeTableRowRef.current.querySelector("input[type='date']");
        const statusField = activeTableRowRef.current.querySelector(
          "[name='task_status']"
        );
        const purposeField = activeTableRowRef.current.querySelector(
          "[name='task_purpose']"
        );
        const phoneNumberField = activeTableRowRef.current.querySelector(
          "[name='phone_number']"
        );

        if (taskTitleField) {
          const newTaskTitle = taskTitleField.value.trim();
          const newDueDate = dueDateField ? dueDateField.value.trim() : null;
          const newStatus = statusField
            ? statusField.options[statusField.selectedIndex].value
            : null;
          const newPurpose = purposeField
            ? purposeField.options[purposeField.selectedIndex].value
            : null;
          const newPhoneNumber = phoneNumberField
            ? phoneNumberField.value
            : null;

          if (newTaskTitle !== "") {
            const newData = tableData.map((table) => {
              if (table.taskType === data.task_type) {
                const updatedData = table.data.map((item) => {
                  if (item.id === data.id) {
                    const updatedItem = {
                      ...item,
                      task_title: newTaskTitle,
                      task_description: taskDescription,
                      task_created_date: createdDate,
                      task_due_date: newDueDate,
                      task_status: newStatus,
                      task_purpose: newPurpose,
                      task_phone_number: newPhoneNumber,
                      task_is_complete: isComplete,
                      task_assigned_to: selectedUser,
                    };
                    if (newTaskRow) {
                      fetch("https://ww1.premelo.com/api/add-task", {
                        method: "POST",
                        body: JSON.stringify(updatedItem),
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: `Bearer ${token}`,
                        },
                      })
                        .then((response) => {
                          console.log(response);
                          setNewTaskRow(false);
                        })
                        .catch((error) => {
                          console.error(error);
                        });
                      console.log("this is a new task");
                      setNewTaskRow(false);
                    } else {
                      fetch(
                        `https://ww1.premelo.com/api/update-task/${updatedItem.id}`,
                        {
                          method: "PUT",
                          body: JSON.stringify(updatedItem),
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                          },
                        }
                      )
                        .then((response) => {
                          console.log(response);
                        })
                        .catch((error) => {
                          console.error(error);
                        });
                      console.log("put request sent");
                    }

                    return updatedItem;
                  }
                  return item;
                });
                return {
                  ...table,
                  data: updatedData,
                };
              }
              return table;
            });
            setTableData(newData);
            setIsEditing(false);
            if (addedRowId === data.id) {
              setAddedRowId("");
            }
          } else {
            handleCancelClick();
          }
        }
      }
    };

    useEffect(() => {
      if (isEditing) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [isEditing]);

    const handleIsCompleteChange = (event) => {
      const token = Cookies.get("token");
      const { checked } = event.target;
      const newData = tableData.map((table) => {
        if (table.taskType === data.task_type) {
          const updatedData = table.data.map((item) => {
            if (item.id === data.id) {
              const updatedItem = {
                ...item,
                task_is_complete: checked,
              };
              console.log("updated item:", updatedItem);

              fetch(`https://ww1.premelo.com/api/mark-task/${updatedItem.id}`, {
                method: "PUT",
                body: JSON.stringify(updatedItem),
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token}`,
                },
              })
                .then((response) => {
                  console.log(response);
                })
                .catch((error) => {
                  console.error(error);
                });

              return updatedItem;
            }
            return item;
          });
          return {
            ...table,
            data: updatedData,
          };
        }
        return table;
      });
      setTableData(newData);

      setTimeout(() => {
        fetchCompanyTask();
      }, 2000);
    };

    const handleSelectedUserChange = (event) => {
      const newValue = event.target.value;
      if (Array.isArray(data.task_assigned_to)) {
        setSelectedUser([...data.task_assigned_to, newValue]);
      } else {
        setSelectedUser([data.task_assigned_to, newValue]);
      }
    };

    return (
      <div
        onClick={() => handleModalOpen(data)}
        className="kanbans-class"
        ref={activeTableRowRef}
        style={{
          width: "100%",
          border: "1px solid lightgrey",
          background:"rgba(255,255,255,0.3)",
          backdropFilter:"blur(20px)",
          borderRadius: "5px",
          padding: "15px",
          marginBottom: "10px",
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              cursor: "move",
              opacity: isDragging ? 0.5 : 1,
              border: isDragging ? "1px solid grey" : "0px",
            }}
            className={isDragging ? "dragging" : ""}
            ref={drag}
          >
            <div>
              <DragIndicatorIcon />
            </div>
          </div>
          <div style={{ marginTop: "-9.6px", marginRight: "-10px" }}>
            <Checkbox
              name="task_is_complete"
              checked={data.task_is_complete}
              onChange={handleIsCompleteChange}
              icon={
                <div
                  style={{
                    display: "flex",
                    border: "1px solid lightgrey",
                    height: "15px",
                    padding: "5px",
                    borderRadius: "5px",
                    alignItems: "center",
                  }}
                >
                  <CheckIcon sx={{ fontSize: "13px" }} />
                  <p
                    style={{
                      marginTop: "1px",
                      marginBottom: "0px",
                      fontSize: "10px",
                      marginRight: "4px",
                      marginLeft: "3px",
                    }}
                  >
                    Mark complete
                  </p>
                </div>
              }
              checkedIcon={
                <div
                  style={{
                    display: "flex",
                    border: "1px solid rgba(0,0,0,0.3)",
                    height: "20px",
                    padding: "5px",
                    borderRadius: "12px",
                    borderColor: "rgba(51, 169, 53, 0.8)",
                    background: "rgba(51, 169, 53, 0.1)",
                    alignItems: "center",
                  }}
                >
                  <CheckedIcon sx={{ fontSize: "13px" }} />
                  <p
                    style={{
                      marginTop: "1px",
                      marginBottom: "0px",
                      fontSize: "10px",
                      marginRight: "4px",
                      marginLeft: "3px",
                    }}
                  >
                    Mark complete
                  </p>
                </div>
              }
              sx={{
                color: "rgba(0, 0, 0, .4)",
                borderRadius: "50%",
                "&.Mui-checked": {
                  color: "rgba(51, 169, 53, 0.8)",
                },
              }}
            />
          </div>
        </div>
        <div>
          {isEditing ? (
            <input
              type="text"
              value={taskTitle}
              onChange={(e) => setTaskTitle(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSaveClick();
                } else if (e.key === "Escape") {
                  handleCancelClick();
                }
              }}
              ref={taskTitleInputRef}
            />
          ) : (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p>{data.task_title}</p>
            </div>
          )}
        </div>
        <div style={{ display: "flex" }}>
          {showStatusRow && (
            <div style={{ display: "flex", marginRight: "12px" }}>
              {isEditing ? (
                <select
                  type="select"
                  name="task_status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSaveClick();
                    } else if (e.key === "Escape") {
                      handleCancelClick();
                    }
                  }}
                >
                  <option value=""></option>
                  <option value="Urgent">Urgent</option>
                  <option value="Needs attention">Needs attention</option>
                  <option value="New">New</option>
                  <option value="Pending Review">Pending Review</option>
                </select>
              ) : (
                <div>
                  {data.task_status ? (
                    <div style={{ display: "flex" }}>
                      <div
                        className="list-input-status"
                        style={{
                          background:
                            statusOptions.find(
                              (option) => option.value === data.task_status
                            )?.color || "transparent",
                          color: "white",
                        }}
                      >
                        <p style={{ margin: "0px" }} onClick={handleEditClick}>
                          {data.task_status}
                        </p>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  const [tableData, setTableData] = useState([]);
  const [tasks, setTasks] = useState([]);
  useEffect(() => {
    console.log("change active projects");
    setTableData([]);
    setIsLoading(true);
    fetchProjectAvailableStatus();
    fetchUsers();

    // Make API call to get task data

    fetchCompanyTask();
  }, [props.activeProject]);

  const [newSectionName, setNewSectionName] = useState("");

  const handleSectionNameChange = (event) => {
    setNewSectionName(event.target.value);
  };

  const handleAddSection = () => {
    const newTableData = [
      ...tableData,
      {
        taskType: newSectionName,
        data: [],
      },
    ];
    setTableData(newTableData);
    setNewSectionName("");
  };
  const [showCustomizationModal, setShowCustomizationModal] = useState(false);

  const handleCustomizationClick = () => {
    setShowCustomizationModal(true);
  };
  const handleModalClose = () => {
    setShowCustomizationModal(false);
  };

  const addStatus = () => {
    setShowStatusRow((prevState) => !prevState);
    Cookies.set("showStatusRow", !showStatusRow);
  };

  const addTaskPurpose = () => {
    setShowTaskPurpose((prevState) => !prevState);
    Cookies.set("showTaskPurpose", !showTaskPurpose);
  };

  const addPhoneNumber = () => {
    setShowPhoneNumber((prevState) => !prevState);
    Cookies.set("showPhoneNumber", !showPhoneNumber);
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "mention",
  ];
  const fileInput = useRef(null);
  const taskFileInput = useRef(null);

  const token = Cookies.get("token");
  const [message, setMessage] = useState("");
  const [attachmentUrl, setAttachmentUrl] = useState([]);
  const [taskAttachmentUrl, setTaskAttachmentUrl] = useState([]);
  const handleAddMessage = async () => {
    try {
      const urls = attachmentUrl.map((attachment) => ({
        url: attachment.url,
        fileName: attachment.fileName,
      }));
      console.log(urls);

      // the task id is not being updated
      const response = await fetch(
        `https://ww1.premelo.com/api/task/${taskId}/message`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            task_id: taskId,
            message: message,
            attachment_url: urls,
          }),
        }
      );

      const data = await response.json();
      setMessage("");
      setAttachmentUrl([]);
    } catch (error) {
      console.error(error);
    }

    const response = await fetch(
      `https://ww1.premelo.com/api/task/${taskId}/getmessages`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    console.log("message data:", data);
    console.log(availableUsers);
    setModalMessages(data);
    const taskAttachments = await fetch(
      `https://ww1.premelo.com/api/task/${taskId}/attachments`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const attachedInTask = await taskAttachments.json();
    console.log("message data:", attachedInTask);
    setTaskAttachmentUrl(
      attachedInTask.map((attachment) => ({
        url: attachment.attachment_url,
        fileName: attachment.file_name,
      }))
    );
  };

  const handleMentionChange = (event, newValue) => {
    setMessage(newValue);
  };

  const renderSuggestion = (suggestion, search, highlightedDisplay) => {
    return <div style={{ borderRadius: "30px" }}>{highlightedDisplay}</div>;
  };

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("files[]", files[i]);
    }

    try {
      const response = await axios.post(
        "https://ww1.premelo.com/api/upload-image",
        formData
      );

      console.log(response.data);
      const attachmentUrls = response.data.urls.map((url, index) => ({
        url: url,
        fileName: response.data.fileNames[index],
      }));

      // Call the uploadTaskAttachment API to store the attachment URLs and file names
      const taskAttachmentResponse = await fetch(
        `https://ww1.premelo.com/api/upload-attachments/${taskId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            attachment_url: attachmentUrls,
          }),
        }
      );

      const taskAttachments = await fetch(
        `https://ww1.premelo.com/api/task/${taskId}/attachments`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await taskAttachments.json();
      console.log("message data:", data);
      setTaskAttachmentUrl(
        data.map((attachment) => ({
          url: attachment.attachment_url,
          fileName: attachment.file_name,
        }))
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileInputChange = async (event) => {
    const files = event.target.files;
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("files[]", files[i]);
    }

    try {
      const response = await axios.post(
        "https://ww1.premelo.com/api/upload-image",
        formData
      );

      console.log(response.data);
      setAttachmentUrl(
        response.data.urls.map((urls, index) => ({
          url: urls,
          fileName: response.data.fileNames[index],
        }))
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemoveAttachment = (index) => {
    setAttachmentUrl((prev) => prev.filter((_, i) => i !== index));
  };

  useEffect(() => {
    console.log("attachmentUrl", attachmentUrl);
  });

  useEffect(() => {
    setProjectName(props.activeProject);
    setProjectId(props.activeProjectId);
  }, [props.activeProject]);

  const handleCustomStatusCommit = async () => {
    setIsOpen(false);
    setStatus(customStatus);
    setSelectedOption({
      label: customStatus,
      label: customStatus,
      color: customColor,
    });
    console.log("custom status:", customStatus);

    const newData = tableData.map((table) => {
      if (table.taskType === selectedRow.task_type) {
        const updatedData = table.data.map((item) => {
          if (item.id === selectedRow.id) {
            const updatedItem = {
              ...item,
              task_status: customStatus,
            };
            console.log("updated item:", updatedItem);

            fetch(`https://ww1.premelo.com/api/update-task/${updatedItem.id}`, {
              method: "PUT",
              body: JSON.stringify(updatedItem),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            })
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                console.error(error);
              });

            return updatedItem;
          }
          return item;
        });
        return {
          ...table,
          data: updatedData,
        };
      }
      return table;
    });
    setTableData(newData);

    const createdStatus = {
      label: customStatus,
      value: customStatus,
      color: customColor,
    };

    setStatusOptions([...statusOptions, createdStatus]);
    const newOptions = [...statusOptions, createdStatus];

    const response = await fetch(
      `https://ww1.premelo.com/api/projects/${props.activeProjectId}/status-options`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ status_options: newOptions }),
      }
    );

    const responseData = await response.json();
    setStatusOptions(responseData.project.status_options);
  };

  const assignedByUser = availableUsers.find(
    (user) => user.id === taskAssignedBy
  );
  const assignedByInitials = assignedByUser
    ? `${assignedByUser.first_name.charAt(0)}${assignedByUser.last_name.charAt(
        0
      )}`
    : null;
  const assignedByFullName = assignedByUser
    ? `${assignedByUser.first_name} ${assignedByUser.last_name}`
    : null;
  const handleChangeDate = (dues) => {
    console.log("onblur initiated");

    const dateParts = dues.split("/");
    const year = dateParts[2];
    const month = dateParts[0].padStart(2, "0");
    const day = dateParts[1].padStart(2, "0");
    const outputDate = `${year}-${month}-${day}`;

    const newData = tableData.map((table) => {
      if (table.taskType === selectedRow.task_type) {
        const updatedData = table.data.map((item) => {
          if (item.id === selectedRow.id) {
            const updatedItem = {
              ...item,
              task_due_date: outputDate,
            };

            fetch(`https://ww1.premelo.com/api/update-task/${updatedItem.id}`, {
              method: "PUT",
              body: JSON.stringify(updatedItem),
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            })
              .then((response) => {
                console.log(response);
              })
              .catch((error) => {
                console.error(error);
              });

            return updatedItem;
          }
          return item;
        });
        return {
          ...table,
          data: updatedData,
        };
      }
      return table;
    });
    console.log(newData);
    setDueDate(outputDate);
    setTableData(newData);
  };

  return (
    <div>
      <div
        className="crm-container"
        style={{
          width: "100%",
          display: "flex",
          "--primary-color": primaryColor,
          "--complementary-color": complementaryColor,
          justifyContent: "space-between",
          position:"relative",
          zIndex: 5
        }}
      >
        <Modal
          isOpen={showCustomizationModal}
          onRequestClose={() => setShowCustomizationModal(false)}
          shouldCloseOnOverlayClick={true}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 505,
            },
            content: {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "50%",
              height: "55%",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              background: "#fff",
              boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
              borderRadius: "10px",
            },
          }}
        >
          <div
            style={{
              background: "#fff",
              padding: "0px 16px 0px 16px",
            }}
          >
            <h3
              style={{
                fontSize: "24px",
                color: "rgba(0,0,0,0.7)",
                marginTop: "0px",
              }}
            >
              Select a column to add:
            </h3>
            <div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  marginBottom: "15px",
                }}
              >
                <div
                  style={{
                    width: "49%",
                    display: "flex",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                    borderRadius: "20px",
                  }}
                >
                  <div style={{ display: "flex", padding: "20px" }}>
                    <div
                      style={{
                        width: "65px",
                        height: "50px",
                        marginRight: "15px",
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                        borderRadius: "10px",
                        border: "0px solid #000000",
                        backgroundColor: complementaryColor,
                      }}
                    >
                      <FormatListBulletedIcon
                        style={{
                          fontSize: "30px",
                          marginLeft: "10px",
                          marginTop: "10px",
                          color: "white",
                        }}
                      />
                    </div>
                    <div style={{ width: "100%", textAlign: "left" }}>
                      <h1
                        style={{
                          marginTop: "0px",
                          fontSize: "18px",
                          color: "rgba(0,0,0,0.7",
                          marginBottom: "20px",
                        }}
                      >
                        Status column
                      </h1>
                      <p
                        style={{
                          marginTop: "-25px",
                          fontSize: "10px",
                          color: "rgba(0,0,0,0.6)",
                        }}
                      >
                        Easily manage the status of task.
                      </p>
                      <div style={{ textAlign: "left" }}>
                        <button
                          style={{
                            marginTop: "-30px",
                            backgroundColor: primaryColor,
                            borderRadius: "10px",
                            border: "none",
                            padding: "10px 20px",
                            fontSize: "10px",
                          }}
                          className="customization-button"
                          onClick={() => addStatus()}
                        >
                          Add Status
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "49%",
                    display: "flex",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                    borderRadius: "20px",
                  }}
                >
                  <div style={{ display: "flex", padding: "20px" }}>
                    <div
                      style={{
                        width: "65px",
                        height: "50px",
                        marginRight: "15px",
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                        borderRadius: "10px",
                        border: "0px solid #000000",
                        backgroundColor: complementaryColor,
                      }}
                    >
                      <RttIcon
                        style={{
                          fontSize: "30px",
                          marginLeft: "10px",
                          marginTop: "10px",
                          color: "white",
                        }}
                      />
                    </div>
                    <div style={{ width: "100%", textAlign: "left" }}>
                      <h1
                        style={{
                          marginTop: "0px",
                          fontSize: "18px",
                          color: "rgba(0,0,0,0.7",
                          marginBottom: "20px",
                        }}
                      >
                        Purpose column
                      </h1>
                      <p
                        style={{
                          marginTop: "-25px",
                          fontSize: "10px",
                          color: "rgba(0,0,0,0.6)",
                        }}
                      >
                        Easily manage the puspoe of each task.
                      </p>
                      <div style={{ textAlign: "left" }}>
                        <button
                          style={{
                            backgroundColor: primaryColor,
                            borderRadius: "10px",
                            border: "none",
                            padding: "10px 20px",
                            fontSize: "10px",
                          }}
                          className="customization-button"
                          onClick={() => addTaskPurpose()}
                        >
                          Add Task Purpose
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    width: "49%",
                    display: "flex",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                    borderRadius: "20px",
                  }}
                >
                  <div style={{ display: "flex", padding: "20px" }}>
                    <div
                      style={{
                        width: "65px",
                        height: "50px",
                        marginRight: "15px",
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
                        borderRadius: "10px",
                        border: "0px solid #000000",
                        backgroundColor: complementaryColor,
                      }}
                    >
                      <PhoneForwardedIcon
                        style={{
                          fontSize: "30px",
                          marginLeft: "10px",
                          marginTop: "10px",
                          color: "white",
                        }}
                      />
                    </div>
                    <div style={{ width: "100%", textAlign: "left" }}>
                      <h1
                        style={{
                          marginTop: "0px",
                          fontSize: "18px",
                          color: "rgba(0,0,0,0.7",
                          marginBottom: "20px",
                        }}
                      >
                        Phone column
                      </h1>
                      <p
                        style={{
                          marginTop: "-25px",
                          fontSize: "10px",
                          color: "rgba(0,0,0,0.6)",
                        }}
                      >
                        Easily convert your task list into a lead list with just
                        a simple phone column.
                      </p>
                      <div style={{ textAlign: "left" }}>
                        <button
                          style={{
                            backgroundColor: primaryColor,
                            borderRadius: "10px",
                            border: "none",
                            padding: "10px 20px",
                            fontSize: "10px",
                          }}
                          className="customization-button"
                          onClick={() => addPhoneNumber()}
                        >
                          Add Phone Number
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button
                style={{
                  padding: "10px 25px",
                  background: primaryColor,
                  color: "white",
                  fontSize: "10px",
                  fontWeight: "600",
                  border: "none",
                  borderRadius: "10px",
                }}
                className="mui-button"
                onClick={handleModalClose}
              >
                Close
              </button>
            </div>
          </div>
        </Modal>
        <div style={{ width: "100%", overflow: "scroll", height: "91vh" }}>
          <div
            style={{
              textAlign: "right",
              marginBottom: "-60px",
              marginRight: "20px",
            }}
          >
            <button
              className="mui-button"
              style={{
                backgroundColor: complementaryColor,
                height: "30px",
                color: "#ffffff",
                padding: "5px 30px",
                borderRadius: "12px",
                border: "none",
                fontWeight: "600",
                fontSize: "14px",
                cursor: "pointer",
                marginTop: "20px",
                fontSize: "10px",
                marginBottom: "10px",
              }}
              onClick={handleCustomizationClick}
            >
              Customization
            </button>
          </div>
          {isLoading ? null : (
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleAddSection();
              }}
              style={{ marginTop: "30px", display: "flex" }}
            >
              <input
                type="text"
                placeholder="New section"
                required
                value={newSectionName}
                onChange={handleSectionNameChange}
                style={{
                  padding: "5px 18px",
                  borderRadius: "5px",
                  border: "1px solid lightgrey",
                  outline: "none",
                  color: "rgba(0,0,0,0.7)",
                  fontSize: "15px",
                  marginRight: "8px",
                  background: "rgba(255,255,255,0.6)",
                }}
              />
              <div>
                <button
                  className="mui-button"
                  type="submit"
                  style={{
                    padding: "5px",
                    borderRadius: "5px",
                    border: "1px solid lightgrey",
                    outline: "none",
                    color: "rgba(0,0,0,0.7)",
                    fontSize: "15px",
                    marginRight: "8px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "rgba(255,255,255,0.8)",
                  }}
                >
                  <ChevronRight />
                </button>
              </div>
            </form>
          )}
          <div style={{ display: "flex" }}>
            {isLoading && <KanbanSkeleton cards={3} />}
            {tableData.map((table) => (
              <Table
                key={table.taskType}
                title={table.taskType}
                data={table.data}
                //   onAddRow={handleAddRow}
                droppedTaskType={table.taskType}
              />
            ))}
          </div>
        </div>
        {modaldisplay && (
          <motion.div
            animate={{
              scale: modaldisplay ? 1 : 0,
              marginRight: modaldisplay ? "0%" : "-20%",
            }}
            transition={{ type: "bounce", duration: 0.3 }}
            initial={{ scale: 0, marginRight: "-20%" }}
            style={{
              backgroundColor: "#ffffff",
              overflow: "hidden",
              height: "82vh",
              overflowX: "hidden",
              position: "absolute",
              right: 0,
              transitionProperty: "marginRight",
              transitionDuration: "2s",
              width: "40%",
              zIndex: 205,
              borderLeft: "1px solid rgba(0,0,0,0.2)",
            }}
          >
            <div
              style={{
                padding: "1% 4% 0px 4%",
                background: props.secondaryColor,
                height: "75%",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginBottom: "30px",
                  }}
                >
                  <ExitToAppIcon
                    className="mui-button"
                    onClick={() => setModaldisplay(false)}
                    style={{
                      marginTop: "20px",
                      color: props.primaryColor,
                      fontSize: "30px",
                    }}
                  />
                  <button
                    style={{
                      color: "white",
                      background: props.complementaryColor,
                      border: "none",
                      borderRadius: "10px",
                      padding: "5px 25px",
                      marginTop: "20px",
                    }}
                    className="mui-button"
                    onClick={() => deleteTasks()}
                  >
                    Delete Tasks
                  </button>
                </div>
                <form>
                  <div
                    style={{
                      height: "auto",
                      width: "100%",
                      marginTop: "-20px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <div style={{ width: "100%" }}>
                        <input
                          className="task-title-input"
                          style={{
                            color: primaryColor,
                            fontWeight: "600",
                            fontSize: "20px",
                          }}
                          type="text"
                          id="taskTitle"
                          placeholder="Type here to change task title"
                          value={taskTitle}
                          onChange={(e) => setTaskTitle(e.target.value)}
                          onBlur={() => {
                            const newData = tableData.map((table) => {
                              if (table.taskType === selectedRow.task_type) {
                                const updatedData = table.data.map((item) => {
                                  if (item.id === selectedRow.id) {
                                    const updatedItem = {
                                      ...item,
                                      task_title: taskTitle,
                                    };

                                    fetch(
                                      `https://ww1.premelo.com/api/update-task/${updatedItem.id}`,
                                      {
                                        method: "PUT",
                                        body: JSON.stringify(updatedItem),
                                        headers: {
                                          "Content-Type": "application/json",
                                          Authorization: `Bearer ${token}`,
                                        },
                                      }
                                    )
                                      .then((response) => {
                                        console.log(response);
                                      })
                                      .catch((error) => {
                                        console.error(error);
                                      });

                                    return updatedItem;
                                  }
                                  return item;
                                });
                                return {
                                  ...table,
                                  data: updatedData,
                                };
                              }
                              return table;
                            });
                            setTableData(newData);
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        width: "100%",
                        height: "5px",
                        marginTop: "15px",
                        marginBottom: "-14px",
                        fontSize: "11px",
                        color: "rgba(0,0,0,0.5)",
                      }}
                    >
                      <div
                        style={{
                          width: "25%",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "10px",
                        }}
                      >
                        Created By:
                      </div>
                      <div
                        style={{
                          marginTop: "50px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "25%",
                          height: "30px",
                          marginBottom: "50px",
                          marginRight: "-10px",
                          fontSize: "10px",
                        }}
                      >
                        Due Date:
                      </div>
                      <div style={{ width: "25%", fontSize: "10px" }}>
                        Status:
                      </div>
                      <div
                        style={{
                          width: "25%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          fontSize: "10px",
                        }}
                      >
                        Assigned To:
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        width: "100%",
                        height: "50px",
                        marginTop: "15px",
                        marginBottom: "30px",
                      }}
                    >
                      <div
                        style={{
                          width: "25%",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            textAlign: "center",
                            fontWeight: "400",
                            fontSize: "8px",
                            width: "25px",
                            height: "25px",
                            backgroundColor: assignedByUser
                              ? assignedByUser.user_color
                              : null,
                            color: "white",
                            borderRadius: "50%",
                            marginRight: "6px",
                          }}
                        >
                          <p style={{ marginTop: "7px" }}>
                            {assignedByInitials}
                          </p>
                        </div>
                        <p
                          style={{
                            fontWeight: "400",
                            fontSize: "10px",
                            color: "rgba(0,0,0,0.6)",
                          }}
                        >
                          {assignedByFullName}
                        </p>
                      </div>
                      <div
                        style={{
                          marginTop: "50px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "25%",
                          height: "30px",
                          marginBottom: "50px",
                          marginRight: "-10px",
                        }}
                      >
                        <CalendarTodayIcon
                          style={{
                            marginLeft: "0px",
                            marginRight: "5px",
                            color: "rgba(0,0,0,0.4)",
                          }}
                        />
                        <div
                          style={{
                            width: "80%",
                            fontSize: "13px",
                            color: "rgba(0,0,0,0.7)",
                          }}
                        >
                          <CDatePicker
                            selected={dueDate ? new Date(dueDate) : null}
                            onChange={(date) =>
                              handleChangeDate(
                                new Date(date).toLocaleDateString()
                              )
                            }
                            dateFormat="yyyy/MM/dd"
                            className="form-control mui-button"
                            popperPlacement="top-end"
                            placeholderText="Select a date"
                          />
                        </div>
                      </div>
                      <div style={{ width: "25%" }}>
                        <div className="dropdown-container">
                          <button
                            value={status}
                            name="task_status"
                            className="dropdown-toggle mui-button"
                            onClick={(event) => {
                              event.preventDefault();
                              setIsOpen(!isOpen);
                            }}
                            style={{
                              backgroundColor: selectedOption.color,
                              borderRadius: "50px",
                              padding: "8px 20px",
                              border: "none",
                              fontWeight: "600",
                              color: "white",
                              fontSize: "10px",
                            }}
                          >
                            {selectedOption.label || "Select status"}
                          </button>
                          {isOpen && (
                            <div className="dropdown-menu">
                              <div className="dropdown-options">
                                {statusOptions.map((option) => (
                                  <div
                                    className="dropdown-option mui-button"
                                    key={option.value}
                                    onClick={() => handleOptionClick(option)}
                                  >
                                    <div
                                      className="dropdown-option-color"
                                      style={{ backgroundColor: option.color }}
                                    >
                                      <div
                                        className="dropdown-option-label"
                                        style={{
                                          fontSize: "10px",
                                        }}
                                      >
                                        {option.label}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <div className="dropdown-custom">
                                <div className="dropdown-custom-status">
                                  <input
                                    className="custom-input"
                                    style={{
                                      backgroundColor: customColor,
                                      fontSize: "10px",
                                    }}
                                    type="text"
                                    placeholder="Custom status"
                                    value={customStatus}
                                    onChange={handleCustomStatusChange}
                                  />
                                </div>
                                <div className="dropdown-custom-color">
                                  <input
                                    className="color-input"
                                    type="color"
                                    value={customColor}
                                    onChange={handleCustomColorChange}
                                  />
                                </div>
                                <div className="dropdown-custom-commit">
                                  <button
                                    style={{
                                      background: props.complementaryColor,
                                      color: "white",
                                      fontSize: "10px",
                                    }}
                                    className="custom-button mui-button"
                                    onClick={handleCustomStatusCommit}
                                  >
                                    Create
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        style={{
                          width: "25%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Select
                          sx={{
                            boxShadow: "none",
                            ".MuiOutlinedInput-notchedOutline": { border: 0 },
                          }}
                          style={{ width: "100%", height: "50px" }}
                          labelId="assigned-to-label"
                          id="assigned-to-select"
                          multiple
                          value={selectedUsers}
                          onChange={handleSelectChange}
                          onBlur={() => {
                            const newData = tableData.map((table) => {
                              if (table.taskType === selectedRow.task_type) {
                                const updatedData = table.data.map((item) => {
                                  if (item.id === selectedRow.id) {
                                    const updatedItem = {
                                      ...item,
                                      task_assigned_to: selectedUsers,
                                    };

                                    fetch(
                                      `https://ww1.premelo.com/api/update-task/${updatedItem.id}`,
                                      {
                                        method: "PUT",
                                        body: JSON.stringify(updatedItem),
                                        headers: {
                                          "Content-Type": "application/json",
                                          Authorization: `Bearer ${token}`,
                                        },
                                      }
                                    )
                                      .then((response) => {
                                        console.log(response);
                                      })
                                      .catch((error) => {
                                        console.error(error);
                                      });

                                    return updatedItem;
                                  }
                                  return item;
                                });
                                return {
                                  ...table,
                                  data: updatedData,
                                };
                              }
                              return table;
                            });
                            console.log(newData);
                            setTableData(newData);
                          }}
                          renderValue={(selected) => {
                            const selectedUsersNames = selected
                              .map((userId) => {
                                const user = availableUsers.find(
                                  (user) => user.id === userId
                                );
                                return user ? user.fullName : "";
                              })
                              .join(", ");

                            const selectedUsersIds = selected
                              .map((userId) => {
                                const user = availableUsers.find(
                                  (user) => user.id === userId
                                );
                                return user ? `-${user.id}-` : null; // Add delimiter to beginning of user id
                              })
                              .join("");
                            console.log(selectedUsersIds);
                            const taskAssignedByUsersInModal =
                              availableUsers.filter((user) =>
                                selectedUsersIds.includes(`-${user.id}-`)
                              );
                            console.log(taskAssignedByUsersInModal);

                            const taskAssignedByInitialsInModal =
                              taskAssignedByUsersInModal.map((user) => ({
                                initials: `${user.first_name.charAt(
                                  0
                                )}${user.last_name.charAt(0)}`,
                                color: user.user_color || null,
                              }));
                            console.log(taskAssignedByInitialsInModal);
                            return (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  height: "100%",
                                }}
                              >
                                {taskAssignedByInitialsInModal.map(
                                  (initials) => (
                                    <div
                                      style={{
                                        width: "25px",
                                        height: "25px",
                                        borderRadius: "50%",
                                        backgroundColor: initials.color,
                                        marginRight: "-5px",
                                        color: "white",
                                      }}
                                    >
                                      <p
                                        style={{
                                          margin: "2px 0px 0px 7px",
                                          fontSize: "8px",
                                        }}
                                      >
                                        {initials.initials}
                                      </p>
                                    </div>
                                  )
                                )}
                                <p
                                  style={{
                                    fontSize: "10px",
                                    marginLeft: "9px",
                                    fontWeight: "400",
                                    color: "rgba(0,0,0,0.6)",
                                  }}
                                >
                                  {selectedUsersNames}
                                </p>
                              </div>
                            );
                          }}
                        >
                          {availableUsers.map((user) => (
                            <MenuItem key={user.id} value={user.id}>
                              <Checkbox
                                checked={selectedUsers.indexOf(user.id) > -1}
                              />
                              <ListItemText primary={user.fullName} />
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div
                      style={{
                        marginTop: "0px",
                        width: "100%",
                        fontSize: "12px",
                        color: "rgba(0,0,0,0.5)",
                        marginBottom: "10px",
                      }}
                    >
                      <label
                        htmlFor="taskDescription"
                        style={{ fontSize: "10px" }}
                      >
                        Task Description:
                      </label>
                      <ReactQuill
                        value={taskDescription}
                        onChange={setTaskDescription}
                        style={{ height: "100px", marginTop: "50px" }}
                        onBlur={() => {
                          const newData = tableData.map((table) => {
                            if (table.taskType === selectedRow.task_type) {
                              const updatedData = table.data.map((item) => {
                                if (item.id === selectedRow.id) {
                                  const updatedItem = {
                                    ...item,
                                    task_description: taskDescription,
                                  };

                                  fetch(
                                    `https://ww1.premelo.com/api/update-task/${updatedItem.id}`,
                                    {
                                      method: "PUT",
                                      body: JSON.stringify(updatedItem),
                                      headers: {
                                        "Content-Type": "application/json",
                                        Authorization: `Bearer ${token}`,
                                      },
                                    }
                                  )
                                    .then((response) => {
                                      console.log(response);
                                    })
                                    .catch((error) => {
                                      console.error(error);
                                    });

                                  return updatedItem;
                                }
                                return item;
                              });
                              return {
                                ...table,
                                data: updatedData,
                              };
                            }
                            return table;
                          });
                          console.log(newData);
                          setTableData(newData);
                        }}
                        modules={{
                          toolbar: [
                            [{ header: [1, 2, false] }],
                            [
                              "bold",
                              "italic",
                              "underline",
                              "strike",
                              "blockquote",
                            ],
                            [
                              { list: "ordered" },
                              { list: "bullet" },
                              { indent: "-1" },
                              { indent: "+1" },
                            ],
                            ["link", "image"],
                            ["clean"],
                          ],
                        }}
                        formats={formats}
                      />
                    </div>
                    <div
                      style={{
                        textAlign: "left",
                        display: "flex",
                        justifyContent: "flex-start",
                        width: "100%",
                      }}
                    >
                      <button
                        className="attachment-button mui-button"
                        style={{
                          color: "rgba(0,0,0,0.3)",
                          display: "flex",
                          alignItems: "center",
                          background: "transparent",
                          border: "none",
                          height: "45px",
                          width: "200px",
                          fontSize: "10px",
                        }}
                        type="button"
                        onClick={() => taskFileInput.current.click()}
                      >
                        <AttachFileIcon />
                        Add attachments
                      </button>
                      <input
                        type="file"
                        multiple
                        style={{ display: "none" }}
                        ref={taskFileInput}
                        onChange={handleFileUpload}
                      />
                    </div>
                    {taskAttachmentUrl && taskAttachmentUrl.length > 0 && (
                      <div
                        className="attachment-scroll"
                        style={{
                          marginTop: "5px",
                          display: "flex",
                          height: "100px",
                          width: "100%",
                        }}
                      >
                        {taskAttachmentUrl.map((attachment, index) => (
                          <div
                            style={{
                              width: "200px",
                              border: "1px solid rgba(0,0,0,0.15)",
                              borderRadius: "5px",
                              marginRight: "10px",
                              padding: "0px 20px",
                            }}
                          >
                            <a
                              key={index}
                              href={attachment.url}
                              target="_blank"
                              rel="noreferrer"
                              style={{
                                textAlign: "center",
                                textDecoration: "none",
                              }}
                            >
                              <div style={{ marginTop: "10px" }}>
                                {getFileIcon(attachment.fileName)}
                              </div>
                              <div
                                style={{
                                  fontSize: "10px",
                                  textDecoration: "none",
                                  color: props.primaryColor,
                                  marginBottom: "-3px",
                                  lineHeight: "12px",
                                }}
                              >
                                {attachment.fileName.slice(0, 12)}
                              </div>
                              <div
                                style={{
                                  fontSize: "10px",
                                  textDecoration: "none",
                                  color: "rgba(0,0,0,0.3)",
                                }}
                              >
                                {attachment.fileName
                                  .split(".")
                                  .pop()
                                  .toLowerCase()}
                              </div>
                            </a>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </form>
                <div
                  style={{
                    marginTop: "15px",
                    width: "100%",
                  }}
                >
                  {modalMessages.length > 0 ? (
                    modalMessages.map((message) => {
                      const createdAt = new Date(
                        message.message.created_at
                      ).toLocaleDateString();

                      // Get user details for the message user ID
                      const user = availableUsers.find(
                        (user) => user.id === message.message.message_user_id
                      );
                      const nameInitials =
                        user &&
                        user.first_name.charAt(0).toUpperCase() +
                          user.last_name.charAt(0).toUpperCase();

                      const userColor = user && user.user_color;

                      const userFullNameForMessage = user && user.fullName;

                      return (
                        <div
                          key={message.message.id}
                          style={{
                            borderTop: "1px solid rgba(0,0,0,0.15)",
                            marginBottom: "5px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "5px",
                            }}
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <div
                                style={{
                                  background: userColor,
                                  marginRight: "5px",
                                  width: "25px",
                                  height: "25px",
                                  textAlign: "center",
                                  borderRadius: "50%",
                                  color: "white",
                                  fontSize: "8px",
                                }}
                              >
                                <p style={{ marginTop: "6px" }}>
                                  {nameInitials}
                                </p>
                              </div>
                              <p
                                style={{
                                  fontSize: "10px",
                                  color: "rgba(0,0,0,0.7)",
                                }}
                              >
                                {userFullNameForMessage}
                              </p>
                            </div>
                            <p
                              style={{
                                fontSize: "8px",
                                color: "rgba(0,0,0,0.4)",
                                marginTop: "12px",
                                marginLeft: "4px",
                              }}
                            >
                              {createdAt}
                            </p>
                          </div>
                          <div style={{ display: "flex", marginTop: "-20px" }}>
                            <div style={{ width: "40px" }} />
                            <div>
                              <p
                                style={{
                                  fontSize: "12px",
                                  color: "rgba(0,0,0,0.7)",
                                }}
                              >
                                {message.message.message
                                  .split(/@\[([\w\s]+)\]\([\w]+\)/g)
                                  .map((part, index) => {
                                    if (index % 2 !== 0) {
                                      return (
                                        <span
                                          key={index}
                                          style={{ color: props.primaryColor }}
                                        >
                                          {part}
                                        </span>
                                      );
                                    }
                                    return <span key={index}>{part}</span>;
                                  })}
                              </p>
                            </div>
                          </div>
                          <div
                            className="attachment-scroll"
                            style={{ display: "flex" }}
                          >
                            {message.attachments.map((attachment, index) => {
                              return (
                                <div style={{ width: "auto" }}>
                                  <a
                                    href={attachment.attachment_url}
                                    target="_blank"
                                    rel="noreferrer"
                                    key={index}
                                    style={{
                                      display: "flex",
                                      height: "20px",
                                      border: "1px solid rgba(0,0,0,0.15)",
                                      padding: "7px 20px",
                                      borderRadius: "5px",
                                      marginRight: "10px",
                                      textDecoration: "none",
                                      textAlign: "left",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div style={{ marginTop: "6px" }}>
                                      {getMessageFileIcon(attachment.file_name)}
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "10px",
                                        textDecoration: "none",
                                        color: "rgba(0,0,0,0.5)",
                                        marginLeft: "6px",
                                      }}
                                    >
                                      {attachment.file_name.slice(0, 10) +
                                        (attachment.file_name.length > 10
                                          ? " ..."
                                          : "")}
                                    </div>
                                  </a>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                padding: "1% 4% 0px 4%",
                background: props.secondaryColor,
                height: "40%",
                width: "92%",
                borderTop: "1px solid rgba(0,0,0,0.2)",
              }}
            >
              <div className="mention-input" style={{ marginTop: "10px" }}>
                <MentionsInput
                  singleLine={false}
                  style={{
                    height: "50px",
                    width: "100%",
                    suggestions: {
                      list: {
                        backgroundColor: "white",
                        border: "1px solid rgba(0,0,0,0.15)",
                        fontSize: 16,
                      },
                      item: {
                        padding: "5px 15px",
                        borderBottom: "1px solid rgba(0,0,0,0.15)",
                        "&focused": {
                          backgroundColor: primaryColor,
                        },
                      },
                    },
                  }}
                  value={message}
                  onChange={handleMentionChange}
                  suggestionsPortalHost={document.body}
                >
                  <Mention
                    style={{ backgroundColor: primaryColor }}
                    trigger="@"
                    data={availableUsers.map((user) => ({
                      id: user.user_id,
                      ...user,
                      display: user.fullName,
                    }))}
                    renderSuggestion={renderSuggestion}
                    displayTransform={(id, display) => {
                      if (typeof display === "object") {
                        return `@${display.fullName}`;
                      }
                      return `@${display}`;
                    }}
                  />
                </MentionsInput>
                <div className="attachment-scroll" style={{ display: "flex" }}>
                  {attachmentUrl.map((attachment, index) => (
                    <div key={index}>
                      <div
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          display: "flex",
                          height: "20px",
                          border: "1px solid rgba(0,0,0,0.15)",
                          padding: "7px 20px",
                          borderRadius: "5px",
                          marginRight: "10px",
                          textDecoration: "none",
                          textAlign: "left",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ marginTop: "6px" }}>
                          {getMessageFileIcon(attachment.fileName)}
                        </div>
                        <a
                          href={attachment.url}
                          style={{
                            fontSize: "10px",
                            textDecoration: "none",
                            color: "rgba(0,0,0,0.5)",
                            marginLeft: "6px",
                          }}
                        >
                          {attachment.fileName.slice(0, 10) +
                            (attachment.fileName.length > 10 ? " ..." : "")}
                        </a>
                        <button
                          className="mui-button"
                          style={{
                            marginLeft: "10px",
                            background: "transparent",
                            color: primaryColor,
                            border: `1px solid ${primaryColor}`,
                          }}
                          onClick={() => handleRemoveAttachment(index)}
                        >
                          Remove
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <button
                    className="attachment-button mui-button"
                    style={{
                      color: "rgba(0,0,0,0.3)",
                      background: "transparent",
                      border: "none",
                      alignItems: "center",
                      display: "flex",
                      fontSize: "12px",
                    }}
                    type="button"
                    onClick={() => fileInput.current.click()}
                  >
                    <AttachFileIcon />
                    Add attachments
                  </button>
                  <input
                    type="file"
                    multiple
                    style={{ display: "none" }}
                    ref={fileInput}
                    onChange={handleFileInputChange}
                  />
                  <button
                    className="mui-button"
                    style={{
                      background: props.primaryColor,
                      border: "none",
                      color: "white",
                      borderRadius: "10px",
                      padding: "5px 20px",
                      fontSize: "12px",
                    }}
                    onClick={handleAddMessage}
                  >
                    Comment
                  </button>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};
export default KanbanContent;
