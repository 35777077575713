import React, { useState, useEffect, useRef } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { v4 as uuidv4 } from "uuid";
import Modal from "react-modal";
import CancelIcon from "@mui/icons-material/Cancel";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Cookies from "js-cookie";
import ListIcon from "@mui/icons-material/List";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import SettingsIcon from "@mui/icons-material/Settings";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ChromePicker } from "react-color";
import { CircularProgress } from "@mui/material";
import { cloneDeep, isEqual } from "lodash";
import LeakAddIcon from "@mui/icons-material/LeakAdd";
import { ToastContainer, toast } from 'react-toastify';

function Forms(props) {
  const [createFormModal, setCreateFormModal] = useState(false);
  const [newFormName, setNewFormName] = useState("");
  const [allForms, setAllForms] = useState([]);
  const [activeForm, setActiveForm] = useState(null);
  const [fieldDropdown, setFieldDropdown] = useState(false);
  const [choicesInput, setChoicesInput] = useState("");
  const [saving, setSaving] = useState(false);
  const token = Cookies.get("token");
  const [viewingSubmissions, setViewingSubmissions] = useState(false);

  useEffect(() => {
    const handleGetForms = async () => {
      try {
        const response = await fetch(
          "https://ww1.premelo.com/api/web-builder/get-form",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const json = await response.json();
        console.log(json);
        setAllForms(json.forms);
      } catch (error) {
        console.log(error);
      }
    };

    handleGetForms();
  }, []);

  const handleClickOutsideShowClient = (event) => {
    const showFieldContainer = document.getElementById("show_field_container");
    const fieldTrigger = document.getElementById("show_field_trigger");

    if (
      showFieldContainer &&
      !showFieldContainer.contains(event.target) &&
      !fieldTrigger.contains(event.target)
    ) {
      setFieldDropdown(false);
    }
  };

  useEffect(() => {
    if (fieldDropdown) {
      window.addEventListener("click", handleClickOutsideShowClient);
    } else {
      window.removeEventListener("click", handleClickOutsideShowClient);
    }

    return () => {
      window.removeEventListener("click", handleClickOutsideShowClient);
    };
  }, [fieldDropdown]);

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return; // Dragged outside of the droppable area
    }

    const items = [...activeForm.form_array];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    const updatingActiveForm = { ...activeForm };
    updatingActiveForm.form_array = items;
    setActiveForm(updatingActiveForm);
  };

  const getDroppableStyle = (isDraggingOver) => ({
    background: "transparent",
  });

  const getDraggableStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? props.primaryColor : "transparent",
    color: isDragging ? "white" : "rgba(0,0,0,0.6)",
    fontSize: "12px",
    border: isDragging ? "none" : "1px solid rgba(0,0,0,0.6)",
    padding: "3px 10px",
    margin: "5px 0px",
    borderRadius: "5px",
    ...draggableStyle,
  });

  const currentURL = window.location.href;
  const modifiedURL = currentURL.replace(/\/dashboard$/, "");

  return (
    <div style={{ height: "100%", }}>
      {/* Header */}
      <Modal
        isOpen={createFormModal}
        onRequestClose={() => {
          setCreateFormModal(false);
          setNewFormName("");
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(255, 255, 255, 0.5)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 4991,
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "500px",
            height: "200px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",
            borderRadius: "5px",
            padding: "0px",
            borderColor: "transparent",
            outline: "none",
            border: "none",
            zIndex: 4991,
            background: "rgba(255,255,255,0.5)",
            backdropFilter: "blur(20px)",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
          },
        }}
      >
        <div>
          <div
            style={{
              height: "40px",
              width: "100%",
              borderBottom: "1px solid lightgrey",
            }}
          >
            <div
              style={{
                background: props.primaryColor,
                height: "4px",
                width: "100%",
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "36px",
              }}
            >
              <div
                style={{
                  margin: "0px 12px",
                  color: "rgba(0,0,0,0.8)",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Create New Form
              </div>
              <div
                style={{
                  margin: "0px 12px",
                  color: "rgba(0,0,0,0.8)",
                  fontSize: "12px",
                }}
              >
                <CancelIcon
                  className="mui-button"
                  style={{ color: "grey", fontSize: "18px" }}
                  onClick={() => {
                    setCreateFormModal(false);
                    setNewFormName("");
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ padding: "0px 20px", marginTop: "20px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "0px 20px",
                marginTop: "12px",
                textAlign: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  margin: "0px 0px 10px 0px",
                  color: "rgba(0,0,0,0.6)",
                }}
              >
                Name your form
              </div>
              <input
                style={{
                  borderRadius: "8px",
                  outline: "1px solid lightgrey",
                  border: "none",
                  padding: "7px 25px",
                  margin: "0px 0px 10px 0px",
                  width: "70%",
                }}
                value={newFormName}
                onChange={(e) => {
                  setNewFormName(e.target.value);
                  console.log(newFormName);
                }}
              />
              <div>
                <button
                  className="mui-button"
                  style={{
                    background: props.primaryColor,
                    color: "white",
                    borderRadius: "6px",
                    padding: "7px 20px",
                    border: "none",
                    fontSize: "11px",
                    fontWeight: "600",
                  }}
                  onClick={() => {
                    let newForm = {
                      form_title: "",
                      form_array: [],

                      title_styling: {
                        color: "rgba(0,0,0,0.8)",
                        fontSize: 25,
                        fontWeight: 700,
                        textAlign: "center",
                      },
                      field_styling: {
                        color: "rgba(0,0,0,0.6)",
                        borderRadius: 5,
                        border: "black",
                        background: "transparent",
                        fontSize: 14,
                        fontWeight: 700,
                        gap: 10,
                      },
                      button_styling: {
                        content: "Submit",
                        color: "white",
                        background: props.primaryColor,
                        borderRadius: 10,
                        fontSize: 18,
                        fontWeight: 600,
                      },
                    };
                    newForm.form_title = newFormName;
                    const updatedAllForms = [...allForms];
                    updatedAllForms.push(newForm);
                    setAllForms(updatedAllForms);
                    setCreateFormModal(false);
                    setNewFormName("");
                  }}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          background: "rgba(255,255,255,0.7)",
          position:"relative",
          zIndex: 490,
          backdropFilter: "blur(20px)",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
          padding: "30px 0px 10px 0px",
        }}
      >
        <div
          style={{
            color: props.primaryColor,
            fontSize: "22px",
            fontWeight: "600",
            marginLeft: "20px",
            paddingBottom: "1%",
          }}
        >
          Forms
        </div>
        <div
          style={{
            marginTop: "-7px",
            marginLeft: "13px",
          }}
        >
          <AddCircleIcon
            className="mui-button-table"
            style={{
              fontSize: "20px",
              color: props.primaryColor,
            }}
          />
        </div>
      </div>

      {/* body */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "98%",
        }}
      >
        <div
          style={{
            width: "20%",
            padding: "15px",
            height: "87%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <div
            className="mui-button"
            onClick={() => {
              setCreateFormModal(true);
            }}
            style={{
              background: props.primaryColor,
              color: "white",
              width: "100%",
              padding: "8px 0px",
              display: "flex",
              textAlign: "center",
              justifyContent: "center",
              fontSize: "13px",
              marginBottom: "15px",
              borderRadius: "5px",
              position: "relative",
              zIndex: 50,
            }}
          >
            Create new forms
          </div>
          <div style={{ height: "100%" }}>
            {allForms.map((form, index) => (
              <div
                onClick={() => {
                  setActiveForm(form);
                  console.log(form);
                }}
                key={index}
                className="mui-button"
                style={{
                  background: "rgba(255,255,255,0.5)",
                  backdropFilter: "blur(20px)",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                  padding: "10px 15px",
                  borderRadius: "5px",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "rgba(0,0,0,0.6)",
                  marginBottom: "8px",
                  display:"flex",
                  justifyContent:"space-between"
                }}
              >
                <div>{form.form_title}</div>

                <LeakAddIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(
                      `${modifiedURL}/ezylinkf?formId=${form.id}`
                    );
                    toast.info('Ezylink copied to clipboard!', {
                      position: "top-right",
                      autoClose: 1500,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                      });
                  }}
                  className="mui-button"
                  style={{ color: "rgba(0,0,0,0.8)" }}
                />
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            width: "80%",
            padding: "15px 15px 15px 0px",
            height: "87%",
          }}
        >
          {activeForm ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "rgba(0,0,0,0.6)",
                gap: "15px",
                height: "100%",
              }}
            >
              <div
                style={{
                  width: "20%",
                  height: "100%",
                  borderRadius: "5px",
                  background: "rgba(255,255,255,0.7)",
                  backdropFilter: "blur(20px)",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                  position: "relative",
                  zIndex: 499,
                }}
              >
                <div
                  style={{
                    height: "40px",
                    width: "100%",
                    borderRadius: "5px 5px 0px 0px",
                    borderBottom: "1px solid lightgrey",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      background: props.primaryColor,
                      height: "4px",
                      width: "100%",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "36px",
                    }}
                  >
                    <div
                      style={{
                        margin: "0px 12px",
                        color: "rgba(0,0,0,0.8)",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    >
                      Add to form
                    </div>{" "}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div
                        style={{
                          background: "rgba(0,0,0,0.2)",
                          width: "9px",
                          height: "9px",
                          borderRadius: "10px",
                          marginRight: "7px",
                        }}
                      />
                      <div
                        style={{
                          background: "rgba(0,0,0,0.2)",
                          width: "9px",
                          height: "9px",
                          borderRadius: "10px",
                          marginRight: "7px",
                        }}
                      />
                      <div
                        style={{
                          background: "grey",
                          width: "9px",
                          height: "9px",
                          borderRadius: "10px",
                          marginRight: "14px",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div style={{ height: "84%", position: "relative" }}>
                  <div
                    style={{
                      margin: "15px 0px 0px 0px",
                      padding: "0px 10px",
                      color: "rgba(0,0,0,0.6)",
                      fontSize: "14px",
                    }}
                  >
                    <div>Form Alignment:</div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "5px 0px 0px 0px",
                      }}
                    >
                      <div
                        className="mui-button"
                        style={{
                          border: "1px solid rgba(0,0,0,0.6)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "25px",
                          width: "25px",
                          borderRadius: "5px",
                          marginRight: "10px",
                        }}
                      >
                        <FormatAlignLeftIcon
                          onClick={() => {
                            const updatingActiveForm = { ...activeForm };
                            updatingActiveForm.title_styling.textAlign =
                              "flex-start";
                            setActiveForm(updatingActiveForm);
                          }}
                          style={{ color: "rgba(0,0,0,0.6)", fontSize: "15px" }}
                        />
                      </div>
                      <div
                        className="mui-button"
                        style={{
                          border: "1px solid rgba(0,0,0,0.6)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "25px",
                          width: "25px",
                          borderRadius: "5px",
                          marginRight: "10px",
                        }}
                      >
                        <FormatAlignCenterIcon
                          onClick={() => {
                            const updatingActiveForm = { ...activeForm };
                            updatingActiveForm.title_styling.textAlign =
                              "center";
                            setActiveForm(updatingActiveForm);
                          }}
                          style={{ color: "rgba(0,0,0,0.6)", fontSize: "15px" }}
                        />
                      </div>
                      <div
                        className="mui-button"
                        style={{
                          border: "1px solid rgba(0,0,0,0.6)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "25px",
                          width: "25px",
                          borderRadius: "5px",
                        }}
                      >
                        <FormatAlignRightIcon
                          onClick={() => {
                            const updatingActiveForm = { ...activeForm };
                            updatingActiveForm.title_styling.textAlign =
                              "flex-end";
                            setActiveForm(updatingActiveForm);
                          }}
                          style={{ color: "rgba(0,0,0,0.6)", fontSize: "15px" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      margin: "15px 0px 0px 0px",
                      padding: "0px 10px",
                      color: "rgba(0,0,0,0.6)",
                      fontSize: "14px",
                      position: "relative",
                    }}
                  >
                    <div>Title styling:</div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "calc(100% - 20px)",
                        alignItems: "center",
                        height: "100%",
                        color: "rgba(0,0,0,0.6)",
                        fontSize: "12px",
                        border: "1px solid rgba(0,0,0,0.6)",
                        padding: "3px 10px",
                        margin: "5px 0px",
                        borderRadius: "5px",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          color: "rgba(0,0,0,0.7)",
                        }}
                      >
                        Title
                      </div>
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className={`container_trigger_`}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <SettingsIcon
                            style={{
                              color: "rgba(0,0,0,0.6)",
                              cursor: "pointer",
                              position: "relative",
                              zIndex: 5,
                            }}
                          />
                        </div>
                        <div
                          className={`container_display_`}
                          style={{
                            position: "absolute",
                            width: "300px",
                            background: "white",
                            left: 10,
                            // top: 0,
                            cursor: "default",
                            borderRadius: "10px",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                            zIndex: 499,
                          }}
                        >
                          <div
                            style={{
                              background: props.primaryColor,
                              height: "40px",
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              borderRadius: "10px 10px 0px 0px",
                            }}
                          >
                            <div
                              style={{
                                margin: "0px 12px",
                                color: "white",
                                fontSize: "15px",
                              }}
                            >
                              Title settings
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <div
                                style={{
                                  background: "rgba(255,255,255,0.5)",
                                  width: "9px",
                                  height: "9px",
                                  borderRadius: "10px",
                                  marginRight: "7px",
                                }}
                              />
                              <div
                                style={{
                                  background: "rgba(255,255,255,0.5)",
                                  width: "9px",
                                  height: "9px",
                                  borderRadius: "10px",
                                  marginRight: "7px",
                                }}
                              />
                              <div
                                style={{
                                  background: "white",
                                  width: "9px",
                                  height: "9px",
                                  borderRadius: "10px",
                                  marginRight: "14px",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              padding: "10px",
                              height: "400px",
                              overflowY: "auto",
                            }}
                          >
                            <div>Font size:</div>
                            <input
                              type="number"
                              value={activeForm.title_styling.fontSize}
                              onChange={(e) => {
                                const updatingActiveForm = { ...activeForm };
                                updatingActiveForm.title_styling.fontSize =
                                  parseFloat(e.target.value);
                                setActiveForm(updatingActiveForm);
                              }}
                              style={{
                                width: "calc(100% - 20px)",
                                padding: "5px 7px",
                                borderRadius: "8px",
                                outline: "1px solid lightgrey",
                                border: "none",
                              }}
                            />
                            <div
                              style={{
                                margin: "10px 0px 0px 0px",
                              }}
                            >
                              Font weight:
                            </div>
                            <select
                              value={activeForm.title_styling.fontWeight}
                              onChange={(e) => {
                                const updatingActiveForm = { ...activeForm };
                                updatingActiveForm.title_styling.fontWeight =
                                  parseFloat(e.target.value);
                                setActiveForm(updatingActiveForm);
                              }}
                              style={{
                                width: "calc(100% - 5px)",
                                padding: "5px 7px",
                                borderRadius: "8px",
                                outline: "1px solid lightgrey",
                                border: "none",
                              }}
                            >
                              <option value={300}>Light</option>
                              <option value={400}>Normal</option>
                              <option value={700}>Semi-bold</option>
                              <option value={800}>Bold</option>
                            </select>
                            <div
                              style={{
                                margin: "10px 0px 5px 0px",
                              }}
                            >
                              Color:
                            </div>
                            <ChromePicker
                              className="my_chrome_picker"
                              color={activeForm.title_styling.color}
                              onChange={(color) => {
                                console.log(color);
                                const updatingActiveForm = { ...activeForm };
                                updatingActiveForm.title_styling.color = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                                setActiveForm(updatingActiveForm);
                              }}
                              onChangeComplete={(color) => {
                                const updatingActiveForm = { ...activeForm };
                                updatingActiveForm.title_styling.color = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                                setActiveForm(updatingActiveForm);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "calc(100% - 20px)",
                        alignItems: "center",
                        height: "100%",
                        color: "rgba(0,0,0,0.6)",
                        fontSize: "12px",
                        border: "1px solid rgba(0,0,0,0.6)",
                        padding: "3px 10px",
                        margin: "5px 0px",
                        borderRadius: "5px",
                      }}
                    >
                      <div
                        style={{
                          color: "rgba(0,0,0,0.7)",
                        }}
                      >
                        Field styling
                      </div>
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className={`container_trigger_`}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <SettingsIcon
                            style={{
                              color: "rgba(0,0,0,0.6)",
                              cursor: "pointer",
                              position: "relative",
                              zIndex: 5,
                            }}
                          />
                        </div>
                        <div
                          className={`container_display_`}
                          style={{
                            position: "absolute",
                            width: "300px",
                            background: "white",
                            left: 10,
                            // top: 0,
                            cursor: "default",
                            borderRadius: "10px",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                            zIndex: 499,
                          }}
                        >
                          <div
                            style={{
                              background: props.primaryColor,
                              height: "40px",
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              borderRadius: "10px 10px 0px 0px",
                            }}
                          >
                            <div
                              style={{
                                margin: "0px 12px",
                                color: "white",
                                fontSize: "15px",
                              }}
                            >
                              Field settings
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <div
                                style={{
                                  background: "rgba(255,255,255,0.5)",
                                  width: "9px",
                                  height: "9px",
                                  borderRadius: "10px",
                                  marginRight: "7px",
                                }}
                              />
                              <div
                                style={{
                                  background: "rgba(255,255,255,0.5)",
                                  width: "9px",
                                  height: "9px",
                                  borderRadius: "10px",
                                  marginRight: "7px",
                                }}
                              />
                              <div
                                style={{
                                  background: "white",
                                  width: "9px",
                                  height: "9px",
                                  borderRadius: "10px",
                                  marginRight: "14px",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              padding: "10px",
                              height: "400px",
                              overflowY: "auto",
                            }}
                          >
                            <div>Field gap:</div>
                            <input
                              min={0}
                              type="number"
                              value={activeForm.field_styling.gap}
                              onChange={(e) => {
                                const updatingActiveForm = { ...activeForm };
                                updatingActiveForm.field_styling.gap =
                                  parseFloat(e.target.value);
                                setActiveForm(updatingActiveForm);
                              }}
                              style={{
                                width: "calc(100% - 20px)",
                                padding: "5px 7px",
                                borderRadius: "8px",
                                outline: "1px solid lightgrey",
                                border: "none",
                              }}
                            />{" "}
                            <div
                              style={{
                                margin: "10px 0px 0px 0px",
                              }}
                            >
                              Border Radius:
                            </div>
                            <input
                              min={0}
                              type="number"
                              value={activeForm.field_styling.borderRadius}
                              onChange={(e) => {
                                const updatingActiveForm = { ...activeForm };
                                updatingActiveForm.field_styling.borderRadius =
                                  parseFloat(e.target.value);
                                setActiveForm(updatingActiveForm);
                              }}
                              style={{
                                width: "calc(100% - 20px)",
                                padding: "5px 7px",
                                borderRadius: "8px",
                                outline: "1px solid lightgrey",
                                border: "none",
                              }}
                            />
                            <div
                              style={{
                                margin: "10px 0px 0px 0px",
                              }}
                            >
                              Font size:
                            </div>
                            <input
                              min={0}
                              type="number"
                              value={activeForm.field_styling.fontSize}
                              onChange={(e) => {
                                const updatingActiveForm = { ...activeForm };
                                updatingActiveForm.field_styling.fontSize =
                                  parseFloat(e.target.value);
                                setActiveForm(updatingActiveForm);
                              }}
                              style={{
                                width: "calc(100% - 20px)",
                                padding: "5px 7px",
                                borderRadius: "8px",
                                outline: "1px solid lightgrey",
                                border: "none",
                              }}
                            />
                            <div
                              style={{
                                margin: "10px 0px 0px 0px",
                              }}
                            >
                              Font weight:
                            </div>
                            <select
                              value={activeForm.field_styling.fontWeight}
                              onChange={(e) => {
                                const updatingActiveForm = { ...activeForm };
                                updatingActiveForm.field_styling.fontWeight =
                                  parseFloat(e.target.value);
                                setActiveForm(updatingActiveForm);
                              }}
                              style={{
                                width: "calc(100% - 5px)",
                                padding: "5px 7px",
                                borderRadius: "8px",
                                outline: "1px solid lightgrey",
                                border: "none",
                              }}
                            >
                              <option value={300}>Light</option>
                              <option value={400}>Normal</option>
                              <option value={700}>Semi-bold</option>
                              <option value={800}>Bold</option>
                            </select>
                            <div
                              style={{
                                margin: "10px 0px 5px 0px",
                              }}
                            >
                              Color:
                            </div>
                            <ChromePicker
                              className="my_chrome_picker"
                              color={activeForm.field_styling.color}
                              onChange={(color) => {
                                console.log(color);
                                const updatingActiveForm = { ...activeForm };
                                updatingActiveForm.field_styling.color = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                                setActiveForm(updatingActiveForm);
                              }}
                              onChangeComplete={(color) => {
                                const updatingActiveForm = { ...activeForm };
                                updatingActiveForm.field_styling.color = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                                setActiveForm(updatingActiveForm);
                              }}
                            />{" "}
                            <div
                              style={{
                                margin: "10px 0px 5px 0px",
                              }}
                            >
                              Border color:
                            </div>
                            <ChromePicker
                              className="my_chrome_picker"
                              color={activeForm.field_styling.border}
                              onChange={(color) => {
                                console.log(color);
                                const updatingActiveForm = { ...activeForm };
                                updatingActiveForm.field_styling.border = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                                setActiveForm(updatingActiveForm);
                              }}
                              onChangeComplete={(color) => {
                                const updatingActiveForm = { ...activeForm };
                                updatingActiveForm.field_styling.border = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                                setActiveForm(updatingActiveForm);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "calc(100% - 20px)",
                        alignItems: "center",
                        height: "100%",
                        color: "rgba(0,0,0,0.6)",
                        fontSize: "12px",
                        border: "1px solid rgba(0,0,0,0.6)",
                        padding: "3px 10px",
                        margin: "5px 0px",
                        borderRadius: "5px",
                      }}
                    >
                      <div
                        style={{
                          color: "rgba(0,0,0,0.7)",
                        }}
                      >
                        Button settings
                      </div>
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div
                          className={`container_trigger_`}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <SettingsIcon
                            style={{
                              color: "rgba(0,0,0,0.6)",
                              cursor: "pointer",
                              position: "relative",
                              zIndex: 5,
                            }}
                          />
                        </div>
                        <div
                          className={`container_display_`}
                          style={{
                            position: "absolute",
                            width: "300px",
                            background: "white",
                            left: 10,
                            // top: 0,
                            cursor: "default",
                            borderRadius: "10px",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                            zIndex: 499,
                          }}
                        >
                          <div
                            style={{
                              background: props.primaryColor,
                              height: "40px",
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              borderRadius: "10px 10px 0px 0px",
                            }}
                          >
                            <div
                              style={{
                                margin: "0px 12px",
                                color: "white",
                                fontSize: "15px",
                              }}
                            >
                              Button settings
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <div
                                style={{
                                  background: "rgba(255,255,255,0.5)",
                                  width: "9px",
                                  height: "9px",
                                  borderRadius: "10px",
                                  marginRight: "7px",
                                }}
                              />
                              <div
                                style={{
                                  background: "rgba(255,255,255,0.5)",
                                  width: "9px",
                                  height: "9px",
                                  borderRadius: "10px",
                                  marginRight: "7px",
                                }}
                              />
                              <div
                                style={{
                                  background: "white",
                                  width: "9px",
                                  height: "9px",
                                  borderRadius: "10px",
                                  marginRight: "14px",
                                }}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              padding: "10px",
                              height: "400px",
                              overflowY: "auto",
                            }}
                          >
                            <div>Button text:</div>
                            <input
                              min={0}
                              type="text"
                              value={activeForm.button_styling.content}
                              onChange={(e) => {
                                const updatingActiveForm = { ...activeForm };
                                updatingActiveForm.button_styling.content =
                                  e.target.value;
                                setActiveForm(updatingActiveForm);
                              }}
                              style={{
                                width: "calc(100% - 20px)",
                                padding: "5px 7px",
                                borderRadius: "8px",
                                outline: "1px solid lightgrey",
                                border: "none",
                              }}
                            />{" "}
                            <div
                              style={{
                                margin: "10px 0px 0px 0px",
                              }}
                            >
                              Border Radius:
                            </div>
                            <input
                              min={0}
                              type="number"
                              value={activeForm.button_styling.borderRadius}
                              onChange={(e) => {
                                const updatingActiveForm = { ...activeForm };
                                updatingActiveForm.button_styling.borderRadius =
                                  parseFloat(e.target.value);
                                setActiveForm(updatingActiveForm);
                              }}
                              style={{
                                width: "calc(100% - 20px)",
                                padding: "5px 7px",
                                borderRadius: "8px",
                                outline: "1px solid lightgrey",
                                border: "none",
                              }}
                            />
                            <div
                              style={{
                                margin: "10px 0px 0px 0px",
                              }}
                            >
                              Font size:
                            </div>
                            <input
                              min={0}
                              type="number"
                              value={activeForm.button_styling.fontSize}
                              onChange={(e) => {
                                const updatingActiveForm = { ...activeForm };
                                updatingActiveForm.button_styling.fontSize =
                                  parseFloat(e.target.value);
                                setActiveForm(updatingActiveForm);
                              }}
                              style={{
                                width: "calc(100% - 20px)",
                                padding: "5px 7px",
                                borderRadius: "8px",
                                outline: "1px solid lightgrey",
                                border: "none",
                              }}
                            />
                            <div
                              style={{
                                margin: "10px 0px 0px 0px",
                              }}
                            >
                              Font weight:
                            </div>
                            <select
                              value={activeForm.button_styling.fontWeight}
                              onChange={(e) => {
                                const updatingActiveForm = { ...activeForm };
                                updatingActiveForm.button_styling.fontWeight =
                                  parseFloat(e.target.value);
                                setActiveForm(updatingActiveForm);
                              }}
                              style={{
                                width: "calc(100% - 5px)",
                                padding: "5px 7px",
                                borderRadius: "8px",
                                outline: "1px solid lightgrey",
                                border: "none",
                              }}
                            >
                              <option value={300}>Light</option>
                              <option value={400}>Normal</option>
                              <option value={700}>Semi-bold</option>
                              <option value={800}>Bold</option>
                            </select>
                            <div
                              style={{
                                margin: "10px 0px 5px 0px",
                              }}
                            >
                              Color:
                            </div>
                            <ChromePicker
                              className="my_chrome_picker"
                              color={activeForm.button_styling.color}
                              onChange={(color) => {
                                console.log(color);
                                const updatingActiveForm = { ...activeForm };
                                updatingActiveForm.button_styling.color = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                                setActiveForm(updatingActiveForm);
                              }}
                              onChangeComplete={(color) => {
                                const updatingActiveForm = { ...activeForm };
                                updatingActiveForm.button_styling.color = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                                setActiveForm(updatingActiveForm);
                              }}
                            />
                            <div
                              style={{
                                margin: "10px 0px 5px 0px",
                              }}
                            >
                              Background Color:
                            </div>
                            <ChromePicker
                              className="my_chrome_picker"
                              color={activeForm.button_styling.background}
                              onChange={(color) => {
                                console.log(color);
                                const updatingActiveForm = { ...activeForm };
                                updatingActiveForm.button_styling.background = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                                setActiveForm(updatingActiveForm);
                              }}
                              onChangeComplete={(color) => {
                                const updatingActiveForm = { ...activeForm };
                                updatingActiveForm.button_styling.background = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
                                setActiveForm(updatingActiveForm);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      margin: "15px 0px 0px 0px",
                      padding: "0px 10px",
                      color: "rgba(0,0,0,0.6)",
                      fontSize: "14px",
                    }}
                  >
                    <div>Fields:</div>
                    <div style={{}}>
                      <DragDropContext onDragEnd={handleDragEnd}>
                        <Droppable droppableId="formFields">
                          {(provided, snapshot) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={getDroppableStyle(snapshot.isDraggingOver)}
                            >
                              {activeForm.form_array.map((field, index) => (
                                <Draggable
                                  key={field.id}
                                  draggableId={field.id}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      ref={provided.innerRef}
                                      style={getDraggableStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                      )}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          width: "100%",
                                          alignItems: "center",
                                          height: "100%",
                                          color: "rgba(0,0,0,0.7)",
                                        }}
                                      >
                                        <div
                                          style={{
                                            color: snapshot.isDragging
                                              ? "white"
                                              : "rgba(0,0,0,0.7)",
                                          }}
                                        >
                                          {field.type}
                                        </div>
                                        <div
                                          style={{
                                            position: "relative",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <div
                                            className={`container_trigger_`}
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <SettingsIcon
                                              style={{
                                                color: snapshot.isDragging
                                                  ? "white"
                                                  : "rgba(0,0,0,0.6)",
                                                cursor: "pointer",
                                                position: "relative",
                                                zIndex: 5,
                                              }}
                                            />
                                          </div>
                                          <div
                                            className={`container_display_`}
                                            style={{
                                              position: "absolute",
                                              width: "300px",
                                              background: "white",
                                              left: 10,
                                              // top: 0,
                                              cursor: "default",
                                              borderRadius: "10px",
                                              boxShadow:
                                                "0px 0px 10px rgba(0,0,0,0.15)",
                                              zIndex: 499,
                                            }}
                                          >
                                            <div
                                              style={{
                                                background: props.primaryColor,
                                                height: "40px",
                                                width: "100%",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                borderRadius:
                                                  "10px 10px 0px 0px",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  margin: "0px 12px",
                                                  color: "white",
                                                  fontSize: "15px",
                                                }}
                                              >
                                                Field setting
                                              </div>
                                              <div
                                                style={{
                                                  display: "flex",
                                                  justifyContent: "flex-end",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    background:
                                                      "rgba(255,255,255,0.5)",
                                                    width: "9px",
                                                    height: "9px",
                                                    borderRadius: "10px",
                                                    marginRight: "7px",
                                                  }}
                                                />
                                                <div
                                                  style={{
                                                    background:
                                                      "rgba(255,255,255,0.5)",
                                                    width: "9px",
                                                    height: "9px",
                                                    borderRadius: "10px",
                                                    marginRight: "7px",
                                                  }}
                                                />
                                                <div
                                                  style={{
                                                    background: "white",
                                                    width: "9px",
                                                    height: "9px",
                                                    borderRadius: "10px",
                                                    marginRight: "14px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div style={{ padding: "10px" }}>
                                              <div>Placeholder text:</div>
                                              <input
                                                value={field.placeholder}
                                                onChange={(e) => {
                                                  const addingToActiveForm = {
                                                    ...activeForm,
                                                  };
                                                  const fieldIndex =
                                                    addingToActiveForm.form_array.findIndex(
                                                      (fields) =>
                                                        fields.id === field.id
                                                    );
                                                  addingToActiveForm.form_array[
                                                    fieldIndex
                                                  ].placeholder =
                                                    e.target.value;
                                                  setActiveForm(
                                                    addingToActiveForm
                                                  );
                                                }}
                                                onClick={(e) => {
                                                  // Prevent click event propagation
                                                  e.stopPropagation();
                                                }}
                                                style={{
                                                  width: "calc(100% - 20px)",
                                                  padding: "5px 7px",
                                                  borderRadius: "8px",
                                                  outline:
                                                    "1px solid lightgrey",
                                                  border: "none",
                                                }}
                                              />
                                              <div
                                                style={{
                                                  margin: "10px 0px 0px 0px",
                                                }}
                                              >
                                                Width:
                                              </div>
                                              <select
                                                value={field.width}
                                                onChange={(e) => {
                                                  const addingToActiveForm = {
                                                    ...activeForm,
                                                  };
                                                  const fieldIndex =
                                                    addingToActiveForm.form_array.findIndex(
                                                      (fields) =>
                                                        fields.id === field.id
                                                    );
                                                  addingToActiveForm.form_array[
                                                    fieldIndex
                                                  ].width = e.target.value;
                                                  setActiveForm(
                                                    addingToActiveForm
                                                  );
                                                }}
                                                style={{
                                                  width: "calc(100% - 5px)",
                                                  padding: "5px 7px",
                                                  borderRadius: "8px",
                                                  outline:
                                                    "1px solid lightgrey",
                                                  border: "none",
                                                }}
                                              >
                                                <option value={"25%"}>
                                                  25%
                                                </option>
                                                <option value={"33%"}>
                                                  33%
                                                </option>
                                                <option value={"50%"}>
                                                  50%
                                                </option>
                                                <option value={"100%"}>
                                                  100%
                                                </option>
                                              </select>
                                              <div
                                                style={{
                                                  margin: "15px 0px 0px 0px",
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <input
                                                  type="checkbox"
                                                  checked={field.required}
                                                  onChange={(e) => {
                                                    const addingToActiveForm = {
                                                      ...activeForm,
                                                    };
                                                    const fieldIndex =
                                                      addingToActiveForm.form_array.findIndex(
                                                        (fields) =>
                                                          fields.id === field.id
                                                      );
                                                    addingToActiveForm.form_array[
                                                      fieldIndex
                                                    ].required =
                                                      e.target.checked;
                                                    setActiveForm(
                                                      addingToActiveForm
                                                    );
                                                  }}
                                                />
                                                Required field
                                              </div>
                                              {field.type === "select" ? (
                                                <div style={{ width: "100%" }}>
                                                  <div
                                                    style={{
                                                      margin:
                                                        "10px 0px 0px 0px",
                                                    }}
                                                  >
                                                    Choices:
                                                  </div>
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      justifyContent:
                                                        "space-between",
                                                    }}
                                                  >
                                                    <input
                                                      value={choicesInput}
                                                      onChange={(e) =>
                                                        setChoicesInput(
                                                          e.target.value
                                                        )
                                                      }
                                                      style={{
                                                        width:
                                                          "calc(100% - 50px)",
                                                        padding: "5px 7px",
                                                        borderRadius: "8px",
                                                        outline:
                                                          "1px solid lightgrey",
                                                        border: "none",
                                                      }}
                                                    />
                                                    <AddCircleIcon
                                                      onClick={() => {
                                                        let choices = {
                                                          value: "",
                                                        };
                                                        choices.value =
                                                          choicesInput;

                                                        const addingToActiveForm =
                                                          {
                                                            ...activeForm,
                                                          };
                                                        const fieldIndex =
                                                          addingToActiveForm.form_array.findIndex(
                                                            (fields) =>
                                                              fields.id ===
                                                              field.id
                                                          );
                                                        addingToActiveForm.form_array[
                                                          fieldIndex
                                                        ].fields.push(choices);
                                                        setActiveForm(
                                                          addingToActiveForm
                                                        );
                                                      }}
                                                      style={{
                                                        color:
                                                          props.primaryColor,
                                                        cursor: "pointer",
                                                      }}
                                                    />
                                                  </div>
                                                  {field.fields.map(
                                                    (choice, index) => (
                                                      <div
                                                        key={index}
                                                        style={{
                                                          width:
                                                            "calc(100% - 20px)",
                                                          padding: "5px 7px",
                                                          borderRadius: "8px",
                                                          outline:
                                                            "1px solid lightgrey",
                                                          border: "none",
                                                          marginTop: "5px",
                                                        }}
                                                      >
                                                        {choice.value}
                                                      </div>
                                                    )
                                                  )}
                                                </div>
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        margin: "5px 0px 0px 0px",
                      }}
                    >
                      <div
                        id="show_field_trigger"
                        style={{
                          // border: "1px solid rgba(0,0,0,0.6)",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "25px",
                          width: "25px",
                          borderRadius: "5px",
                          marginRight: "10px",
                          width: "calc(100% - 15px)",
                          position: "relative",
                        }}
                      >
                        <AddCircleIcon
                          onClick={() => {
                            setFieldDropdown(!fieldDropdown);
                          }}
                          className="mui-button"
                          style={{ color: "rgba(0,0,0,0.6)", fontSize: "18px" }}
                        />
                        {fieldDropdown ? (
                          <div
                            id="show_field_container"
                            style={{
                              display: "inline-block",
                              position: "absolute",
                              left: "50%",
                              background: "white",
                              borderRadius: "10px",
                              // padding: "10px",
                              boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                              width: "300px",
                              // height: "300px",
                              zIndex: 499,
                            }}
                          >
                            <div
                              style={{
                                background: props.primaryColor,
                                height: "40px",
                                width: "100%",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                borderRadius: "10px 10px 0px 0px",
                              }}
                            >
                              <div
                                style={{
                                  margin: "0px 12px",
                                  color: "white",
                                  fontSize: "15px",
                                }}
                              >
                                Dropdown contents{" "}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <div
                                  style={{
                                    background: "rgba(255,255,255,0.5)",
                                    width: "9px",
                                    height: "9px",
                                    borderRadius: "10px",
                                    marginRight: "7px",
                                  }}
                                />
                                <div
                                  style={{
                                    background: "rgba(255,255,255,0.5)",
                                    width: "9px",
                                    height: "9px",
                                    borderRadius: "10px",
                                    marginRight: "7px",
                                  }}
                                />
                                <div
                                  style={{
                                    background: "white",
                                    width: "9px",
                                    height: "9px",
                                    borderRadius: "10px",
                                    marginRight: "14px",
                                  }}
                                />
                              </div>
                            </div>
                            <div style={{ padding: "10px" }}>
                              <div
                                onClick={() => {
                                  const newStringText = {
                                    id: `text${uuidv4()}${new Date()}`,
                                    type: "string",
                                    placeholder: "Type here",
                                    width: "50%",
                                    required: 0,
                                  };
                                  const addingToActiveForm = { ...activeForm };
                                  addingToActiveForm.form_array.push(
                                    newStringText
                                  );
                                  setActiveForm(addingToActiveForm);
                                  console.log(addingToActiveForm);
                                }}
                                className="mui-button"
                                style={{
                                  border: "1px solid rgba(0,0,0,0.6)",
                                  color: "rgba(0,0,0,0.6)",
                                  fontSize: "13px",
                                  borderRadius: "5px",
                                  padding: "5px",
                                  marginTop: "5px",
                                }}
                              >
                                Text
                              </div>
                              <div
                                onClick={() => {
                                  const newStringText = {
                                    id: `email${uuidv4()}${new Date()}`,
                                    type: "email",
                                    placeholder: "Type here",
                                    width: "50%",
                                    required: 0,
                                  };
                                  const addingToActiveForm = { ...activeForm };
                                  addingToActiveForm.form_array.push(
                                    newStringText
                                  );
                                  setActiveForm(addingToActiveForm);
                                  console.log(addingToActiveForm);
                                }}
                                className="mui-button"
                                style={{
                                  border: "1px solid rgba(0,0,0,0.6)",
                                  color: "rgba(0,0,0,0.6)",
                                  fontSize: "13px",
                                  borderRadius: "5px",
                                  padding: "5px",
                                  marginTop: "5px",
                                }}
                              >
                                Email
                              </div>
                              <div
                                onClick={() => {
                                  const newStringText = {
                                    id: `phnumber${uuidv4()}${new Date()}`,
                                    type: "phone_number",
                                    placeholder: "Type here",
                                    width: "50%",
                                    required: 0,
                                  };
                                  const addingToActiveForm = { ...activeForm };
                                  addingToActiveForm.form_array.push(
                                    newStringText
                                  );
                                  setActiveForm(addingToActiveForm);
                                  console.log(addingToActiveForm);
                                }}
                                className="mui-button"
                                style={{
                                  border: "1px solid rgba(0,0,0,0.6)",
                                  color: "rgba(0,0,0,0.6)",
                                  fontSize: "13px",
                                  borderRadius: "5px",
                                  padding: "5px",
                                  marginTop: "5px",
                                }}
                              >
                                Phone number
                              </div>
                              <div
                                onClick={() => {
                                  const newStringText = {
                                    id: `select${uuidv4()}${new Date()}`,
                                    type: "select",
                                    placeholder: "Select an option",
                                    width: "50%",
                                    required: 0,
                                    fields: [],
                                  };
                                  const addingToActiveForm = { ...activeForm };
                                  addingToActiveForm.form_array.push(
                                    newStringText
                                  );
                                  setActiveForm(addingToActiveForm);
                                  console.log(addingToActiveForm);
                                }}
                                className="mui-button"
                                style={{
                                  border: "1px solid rgba(0,0,0,0.6)",
                                  color: "rgba(0,0,0,0.6)",
                                  fontSize: "13px",
                                  borderRadius: "5px",
                                  padding: "5px",
                                  marginTop: "5px",
                                }}
                              >
                                Choices
                              </div>
                              <div
                                onClick={() => {
                                  const newStringText = {
                                    id: `checkbox${uuidv4()}${new Date()}`,
                                    type: "checkbox",
                                    width: "50%",
                                    required: 0,
                                    placeholder: "Your label",
                                  };
                                  const addingToActiveForm = { ...activeForm };
                                  addingToActiveForm.form_array.push(
                                    newStringText
                                  );
                                  setActiveForm(addingToActiveForm);
                                  console.log(addingToActiveForm);
                                }}
                                className="mui-button"
                                style={{
                                  border: "1px solid rgba(0,0,0,0.6)",
                                  color: "rgba(0,0,0,0.6)",
                                  fontSize: "13px",
                                  borderRadius: "5px",
                                  padding: "5px",
                                  marginTop: "5px",
                                }}
                              >
                                Checkbox
                              </div>
                              <div
                                onClick={() => {
                                  const newStringText = {
                                    id: `message${uuidv4()}${new Date()}`,
                                    type: "message",
                                    placeholder: "Type here",
                                    width: "100%",
                                    required: 0,
                                  };
                                  const addingToActiveForm = { ...activeForm };
                                  addingToActiveForm.form_array.push(
                                    newStringText
                                  );
                                  setActiveForm(addingToActiveForm);
                                  console.log(addingToActiveForm);
                                }}
                                className="mui-button"
                                style={{
                                  border: "1px solid rgba(0,0,0,0.6)",
                                  color: "rgba(0,0,0,0.6)",
                                  fontSize: "13px",
                                  borderRadius: "5px",
                                  padding: "5px",
                                  marginTop: "5px",
                                }}
                              >
                                Message
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ display: "flex", padding: "10px" }}>
                  <div
                    onClick={async () => {
                      const adjustedForm = {
                        ...activeForm,
                        form_array: JSON.stringify(activeForm.form_array),
                        field_styling: JSON.stringify(activeForm.field_styling),
                        button_styling: JSON.stringify(
                          activeForm.button_styling
                        ),
                        title_styling: JSON.stringify(activeForm.title_styling),
                      };
                      console.log(adjustedForm);
                      try {
                        setSaving(true);

                        const response = await fetch(
                          "https://ww1.premelo.com/api/web-builder/create-update-form",
                          {
                            method: "POST",
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: `Bearer ${token}`,
                            },
                            body: JSON.stringify(adjustedForm),
                          }
                        );
                        const json = await response.json();
                        console.log(json.data.form);
                        const formIndex = allForms.findIndex(
                          (form) => form.id === json.data.form.id
                        );
                        if (formIndex !== -1) {
                          const updatingForms = [...allForms];
                          updatingForms[formIndex] = json.data.form;
                          setAllForms(updatingForms);
                        } else {
                          const updatingForms = [...allForms];
                          const formIndex = allForms.findIndex(
                            (form) =>
                              form.form_title === json.data.form.form_title
                          );
                          updatingForms[formIndex] = json.data.form;
                          setAllForms(updatingForms);
                        }
                        setSaving(false);
                      } catch (error) {
                        console.log(error);
                        setSaving(false);
                      }
                    }}
                    style={{
                      background: props.primaryColor,
                      color: "white",
                      fontSize: "13px",
                      padding: "4px 25px",
                      borderRadius: "5px",
                      cursor: "pointer",
                    }}
                  >
                    {saving ? (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <CircularProgress
                          color="secondary"
                          size={20}
                          style={{ marginRight: "5px" }}
                        />
                        Saving
                      </div>
                    ) : (
                      "Save"
                    )}
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "80%",
                  height: "100%",
                  borderRadius: "5px",
                  background: "rgba(255,255,255,0.7)",
                  position:"relative",
                  zIndex: 490,
                  backdropFilter: "blur(20px)",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                }}
              >
                <div
                  style={{
                    height: "40px",
                    width: "100%",
                    borderRadius: "5px 5px 0px 0px",
                    overflow: "hidden",
                    borderBottom: "1px solid lightgrey",
                  }}
                >
                  <div
                    style={{
                      background: props.primaryColor,
                      height: "4px",
                      width: "100%",
                    }}
                  />

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      height: "36px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <div
                        onClick={() => setViewingSubmissions(false)}
                        className="mui-button"
                        style={{
                          margin: "0px 12px",
                          color: "rgba(0,0,0,0.8)",
                          fontSize: "14px",
                          fontWeight: 600,
                          opacity: viewingSubmissions ? 0.5 : 1,
                        }}
                      >
                        Form Preview
                      </div>
                      <div
                        onClick={() => setViewingSubmissions(true)}
                        className="mui-button"
                        style={{
                          margin: "0px 12px",
                          color: "rgba(0,0,0,0.8)",
                          fontSize: "14px",
                          fontWeight: 600,
                          opacity: viewingSubmissions ? 1 : 0.5,
                        }}
                      >
                        View Submissions
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div
                        style={{
                          background: "rgba(0,0,0,0.2)",
                          width: "9px",
                          height: "9px",
                          borderRadius: "10px",
                          marginRight: "7px",
                        }}
                      />
                      <div
                        style={{
                          background: "rgba(0,0,0,0.2)",
                          width: "9px",
                          height: "9px",
                          borderRadius: "10px",
                          marginRight: "7px",
                        }}
                      />
                      <div
                        style={{
                          background: "grey",
                          width: "9px",
                          height: "9px",
                          borderRadius: "10px",
                          marginRight: "14px",
                        }}
                      />
                    </div>
                  </div>
                </div>
                {viewingSubmissions ? (
                  <>
                    <div
                      style={{
                        width: "calc(100% - 20px)",
                        height: "calc(94% - 20px)",
                        padding: "10px",
                        display: "flex",
                        overflowX: "scroll",
                        alignItems: "flex-start",
                        overflowY: "auto",
                      }}
                    >
                      {activeForm.form_array
                        ? activeForm.form_array.map((field, index) => (
                            <div key={index}>
                              <div
                                style={{
                                  textAlign: "center",
                                  width: "200px",
                                  paddingTop: "5px",
                                  paddingBottom: "10px",
                                  borderBottom: "1px solid lightgrey",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: 600,
                                    color: "rgba(0,0,0,0.7)",
                                    width: "200px",
                                  }}
                                >
                                  {field.id.slice(0, 10)} ...
                                </div>
                                <div
                                  style={{
                                    fontSize: "9px",
                                    fontWeight: 400,
                                    color: "rgba(0,0,0,0.5)",
                                    width: "200px",
                                    marginTop: "-5px",
                                  }}
                                >
                                  {field.type}
                                </div>
                              </div>
                              {activeForm.response_array
                                ? activeForm.response_array.map(
                                    (resp, index) => (
                                      <div
                                        key={index}
                                        style={{
                                          fontSize: "10px",
                                          fontWeight: 400,
                                          color: "rgba(0,0,0,0.7)",
                                          width: "200px",
                                          textAlign: "center",
                                          margin: "5px 0px",
                                          borderBottom: "1px solid lightgrey",
                                          height: "20px",
                                        }}
                                      >
                                        {resp[field.id]
                                          ? resp[field.id].toString()
                                          : null}
                                      </div>
                                    )
                                  )
                                : null}
                            </div>
                          ))
                        : null}
                    </div>
                  </>
                ) : (
                  <div
                    style={{
                      width: "90%",
                      height: "100%",
                      margin: "15px 0px 0px 5%",
                    }}
                  >
                    <div
                      style={{
                        color: activeForm.title_styling.color,
                        fontSize: activeForm.title_styling.fontSize,
                        fontWeight: activeForm.title_styling.fontWeight,
                        width: "100%",
                        display: "flex",
                        justifyContent: activeForm.title_styling.textAlign,
                      }}
                    >
                      {activeForm.form_title}
                    </div>
                    {activeForm.form_array.length > 0 ? (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            width: "100%",
                            justifyContent: activeForm.title_styling.textAlign,
                            marginTop: `calc(${activeForm.field_styling.gap}px + 10px)`,
                            gap: `${activeForm.field_styling.gap}px`,
                          }}
                        >
                          {activeForm.form_array.map((fields, index) => {
                            if (fields.type === "string") {
                              return (
                                <input
                                  className="form_input"
                                  key={fields.id}
                                  type="text"
                                  required={fields.required}
                                  placeholder={`${fields.placeholder}`}
                                  style={{
                                    width: `calc(${fields.width} - 3% - ${activeForm.field_styling.gap}px)`,
                                    color: activeForm.field_styling.color,
                                    borderRadius:
                                      activeForm.field_styling.borderRadius,
                                    border: `1px solid ${activeForm.field_styling.border}`,
                                    background:
                                      activeForm.field_styling.background,
                                    fontSize: activeForm.field_styling.fontSize,
                                    fontWeight:
                                      activeForm.field_styling.fontWeight,
                                    padding: "10px",
                                    outline: "none",
                                  }}
                                />
                              );
                            }
                            if (fields.type === "email") {
                              return (
                                <input
                                  className="form_input"
                                  type="email"
                                  key={fields.id}
                                  required={fields.required}
                                  placeholder={`${fields.placeholder}`}
                                  style={{
                                    width: `calc(${fields.width} - 3% - ${activeForm.field_styling.gap}px)`,
                                    color: activeForm.field_styling.color,
                                    borderRadius:
                                      activeForm.field_styling.borderRadius,
                                    border: `1px solid ${activeForm.field_styling.border}`,
                                    background:
                                      activeForm.field_styling.background,
                                    fontSize: activeForm.field_styling.fontSize,
                                    fontWeight:
                                      activeForm.field_styling.fontWeight,
                                    padding: "10px",
                                    outline: "none",
                                  }}
                                />
                              );
                            }
                            if (fields.type === "phone_number") {
                              return (
                                <input
                                  className="form_input"
                                  type="tel"
                                  key={fields.id}
                                  required={fields.required}
                                  placeholder={`${fields.placeholder}`}
                                  style={{
                                    width: `calc(${fields.width} - 3% - ${activeForm.field_styling.gap}px)`,
                                    color: activeForm.field_styling.color,
                                    borderRadius:
                                      activeForm.field_styling.borderRadius,
                                    border: `1px solid ${activeForm.field_styling.border}`,
                                    background:
                                      activeForm.field_styling.background,
                                    fontSize: activeForm.field_styling.fontSize,
                                    fontWeight:
                                      activeForm.field_styling.fontWeight,
                                    padding: "10px",
                                    outline: "none",
                                  }}
                                />
                              );
                            }
                            if (fields.type === "select") {
                              return (
                                <select
                                  className="form_input"
                                  key={fields.id}
                                  required={fields.required}
                                  placeholder={`${fields.placeholder}`}
                                  style={{
                                    width: `calc(${fields.width} - ${activeForm.field_styling.gap}px)`,
                                    color: activeForm.field_styling.color,
                                    borderRadius:
                                      activeForm.field_styling.borderRadius,
                                    border: `1px solid ${activeForm.field_styling.border}`,
                                    background:
                                      activeForm.field_styling.background,
                                    fontSize: activeForm.field_styling.fontSize,
                                    fontWeight:
                                      activeForm.field_styling.fontWeight,
                                    padding: "10px",
                                    outline: "none",
                                  }}
                                >
                                  <option value={""}>Select an option</option>
                                  {fields.fields.map((choice, index) => (
                                    <option
                                      value={`${choice.value}`}
                                      key={index}
                                    >
                                      {choice.value}
                                    </option>
                                  ))}
                                </select>
                              );
                            }
                            if (fields.type === "checkbox") {
                              return (
                                <div
                                  style={{
                                    width: `calc(${fields.width} - ${activeForm.field_styling.gap}px)`,
                                    display: "flex",
                                    alignItems: "center",
                                    color: activeForm.field_styling.color,
                                    fontSize: activeForm.field_styling.fontSize,
                                    fontWeight:
                                      activeForm.field_styling.fontWeight,
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    key={fields.id}
                                    required={fields.required}
                                    placeholder={`${fields.placeholder}`}
                                    style={{
                                      width: `15px`,
                                      height: `15px`,
                                      color: activeForm.field_styling.color,
                                      borderRadius:
                                        activeForm.field_styling.borderRadius,
                                      border: `1px solid ${activeForm.field_styling.color}`,
                                      background:
                                        activeForm.field_styling.background,
                                      fontSize:
                                        activeForm.field_styling.fontSize,
                                      padding: "10px",
                                      outline: "none",
                                      margin: "0px 10px 0px 0px",
                                    }}
                                  />
                                  <div>{fields.placeholder}</div>
                                </div>
                              );
                            }
                            if (fields.type === "message") {
                              return (
                                <textarea
                                  className="form_input"
                                  key={fields.id}
                                  required={fields.required}
                                  placeholder={`${fields.placeholder}`}
                                  style={{
                                    width: `calc(${fields.width} - ${activeForm.field_styling.gap}px)`,
                                    color: activeForm.field_styling.color,
                                    borderRadius:
                                      activeForm.field_styling.borderRadius,
                                    border: `1px solid ${activeForm.field_styling.border}`,
                                    background:
                                      activeForm.field_styling.background,
                                    fontSize: activeForm.field_styling.fontSize,
                                    fontWeight:
                                      activeForm.field_styling.fontWeight,
                                    padding: "10px",
                                    outline: "none",
                                    resize: "none",
                                  }}
                                />
                              );
                            }
                          })}
                        </div>
                        <div
                          style={{
                            marginTop: `calc(${activeForm.field_styling.gap}px + 15px)`,
                            width: "100%",
                            display: "flex",
                            justifyContent: activeForm.title_styling.textAlign,
                          }}
                        >
                          <button
                            style={{
                              padding: "7px 25px",
                              color: activeForm.button_styling.color,
                              background: activeForm.button_styling.background,
                              borderRadius:
                                activeForm.button_styling.borderRadius,
                              fontSize: activeForm.button_styling.fontSize,
                              fontWeight: activeForm.button_styling.fontWeight,
                              border: "none",
                              outline: "none",
                              cursor: "pointer",
                            }}
                          >
                            {activeForm.button_styling.content}
                          </button>
                        </div>
                      </>
                    ) : (
                      <div
                        style={{
                          height: "85%",
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Add fields to your form
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                height: "100%",
                background: "rgba(255,255,255,0.7)",
                position:"relative",
                zIndex: 490,
                backdropFilter: "blur(20px)",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                borderRadius: "5px",
                color: "rgba(0,0,0,0.6)",
              }}
            >
              <div
                style={{
                  height: "40px",
                  width: "100%",
                  borderRadius: "5px 5px 0px 0px",
                  overflow: "hidden",
                  borderBottom: "1px solid lightgrey",
                }}
              >
                <div
                  style={{
                    background: props.primaryColor,
                    height: "4px",
                    width: "100%",
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: "36px",
                  }}
                >
                  <div
                    style={{
                      margin: "0px 12px",
                      color: "rgba(0,0,0,0.8)",
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    Select a form
                  </div>{" "}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <div
                      style={{
                        background: "rgba(0,0,0,0.2)",
                        width: "9px",
                        height: "9px",
                        borderRadius: "10px",
                        marginRight: "7px",
                      }}
                    />
                    <div
                      style={{
                        background: "rgba(0,0,0,0.2)",
                        width: "9px",
                        height: "9px",
                        borderRadius: "10px",
                        marginRight: "7px",
                      }}
                    />
                    <div
                      style={{
                        background: "grey",
                        width: "9px",
                        height: "9px",
                        borderRadius: "10px",
                        marginRight: "14px",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                  height: "calc(100% - 90px)",
                }}
              >
                <ListIcon
                  style={{ color: props.primaryColor, fontSize: "80px" }}
                />
                <div style={{ fontSize: "14px", fontWeight: 600 }}>
                  Select a form to view
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
        <ToastContainer />
    </div>
  );
}

export default Forms;
