import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import React from "react";

const KanbanSkeleton = ({ cards }) => {
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      <div style={{ marginRight: "30px", width: "351px" }}>
        <div
          style={{ display: "flex", marginBottom: "20px", marginTop: "30px" }}
        >
          <Skeleton
            style={{ width: "30px", height: "30px", marginRight: "13px" }}
          />
          <Skeleton width={130} height={30} />
        </div>
        <div
          style={{
            background: "#ffffff",
            borderRadius: "20px",
            padding: "20px",
            marginBottom:"10px"
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <Skeleton
              style={{ width: "30px", height: "30px", marginRight: "13px" }}
            />
            <Skeleton width={150} height={30} />
          </div>
          <div style={{ marginBottom: "5px" }}>
            <Skeleton height={13} style={{ marginBottom: "4px" }} />
            <Skeleton height={13} style={{ width: "70%" }} />
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <Skeleton
              style={{
                width: "100px",
                height: "30px",
                marginRight: "23px",
                borderRadius: "50px",
              }}
            />
            <Skeleton
              style={{
                width: "100px",
                height: "30px",
                marginRight: "23px",
                borderRadius: "50px",
              }}
            />
          </div>
        </div>
        <div
          style={{
            background: "#ffffff",
            borderRadius: "20px",
            padding: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <Skeleton
              style={{ width: "30px", height: "30px", marginRight: "13px" }}
            />
            <Skeleton width={150} height={30} />
          </div>
          <div style={{ marginBottom: "5px" }}>
            <Skeleton height={13} style={{ marginBottom: "4px" }} />
            <Skeleton height={13} style={{ width: "70%" }} />
          </div>
          <div
            style={{
              display: "flex",
            }}
          >
            <Skeleton
              style={{
                width: "100px",
                height: "30px",
                marginRight: "23px",
                borderRadius: "50px",
              }}
            />
            <Skeleton
              style={{
                width: "100px",
                height: "30px",
                marginRight: "23px",
                borderRadius: "50px",
              }}
            />
          </div>
        </div>
      </div>
    ));
};
export default KanbanSkeleton;
