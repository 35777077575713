import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import backgroundImage from "./images/welcome_page_bg.jpg";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { ReactComponent as BackgroundSvg } from "./images/welcome page base-01-01.svg";
import { ReactComponent as ForegroundSvg } from "./images/welcome page upper-01.svg";
import LoginMobile from "./LoginMobile";
import { GoogleLogin } from "@react-oauth/google";

function Login() {

  const responseGoogle = (response) => {
    const credential = response;
    console.log(response);
    if (credential) {
      fetch("https://ww1.premelo.com/api/auth/google", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ credential }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.token) {
            console.log(data.token);
            console.log(data.redirect_url);
            // Store the token in local storage
            Cookies.set("token", data.token, { expires: 365 }); // store token in cookies
          }
          if (data.redirect_url === "/dashboard") {
            navigate("/dashboard"); // navigate to dashboard page
          } else if (data.redirect_url === "/fillinformation") {
            navigate("/fillinformation"); // navigate to fillinformation page
          } else {
            console.log("Unknown redirect URL:", data.redirectUrl);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const errorMessage = (error) => {
    console.log(error);
  };
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const [companyName, setCompanyName] = useState("Premelo");
  // const [companyColor, setCompanyColor] = useState("green");
  const [companyColor, setCompanyColor] = useState("#1450db");
  const svgStyle = {
    fill: companyColor,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 0,
  };
  const svgStyle2 = {
    position: "absolute",
    top: -200,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 5,
  };

  const getCompanyColorByDomain = async () => {
    try {
      console.log(window.location.hostname);
      const response = await axios.get(
        "https://ww1.premelo.com/api/company-color-by-domain", // Replace with your API endpoint
        {
          params: {
            domain: `https://${window.location.hostname}`,
          },
        }
      );
      const data = response.data;
      if (data && data.company_color) {
        setCompanyColor(data.company_color);
        console.log(data.company_color);
      }
      if (data && data.company_name) {
        setCompanyName(data.company_name);
        console.log(data.company_name);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    
    const token = Cookies.get('token');
    if (token) {
      navigate('/dashboard');
    }
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/.test(
      userAgent
    );
    setIsMobile(isMobile);
    getCompanyColorByDomain();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post("https://ww1.premelo.com/api/login", {
        email,
        password,
      });

      const token = response.data.token;
      Cookies.set("token", token, { expires: 365 }); // store token in cookies

      const redirectUrl = response.data.redirect_url;
      if (redirectUrl === "https://www.premelo.com/dashboard") {
        navigate("/dashboard"); // navigate to dashboard page
      } else if (redirectUrl === "https://www.premelo.com/fillinformation") {
        navigate("/fillinformation"); // navigate to fillinformation page
      } else {
        console.log("Unknown redirect URL:", redirectUrl);
      }
    } catch (error) {
      setError("Invalid cred."); // set error message if login fails
    }
  };

  if (isMobile) {
    return <LoginMobile />;
  }

  return (
    <div
      style={{
        alignItems: "center",
        height: "100vh",
        backgroundSize: "cover",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: 50,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <div
          style={{
            color: "white",
            fontSize: "35px",
            fontWeight: "800",
            padding: "25px",
            paddingLeft: "40px",
            zIndex: 20,
          }}
        >
          {companyName}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "75vh",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              justifyContent: "center",
              boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.3)",
              padding: "50px",
              borderRadius: "30px",
              background: "#fff",
            }}
          >
            <h1 style={{ fontSize: "45px", color: "#242424" }}>Login</h1>
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "0px",
                }}
              >
                <label
                  htmlFor="email"
                  style={{
                    width: "350px",
                    color: "#242424",
                    padding: "0px 0px 5px 20px",
                    opacity: "80%",
                  }}
                ></label>
                <input
                  type="email"
                  id="email"
                  placeholder="Email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  style={{
                    borderRadius: "20px",
                    padding: "10px 20px",
                    border: "1px solid #a1a1a1",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "10px",
                }}
              >
                <label
                  htmlFor="password"
                  style={{
                    width: "350px",
                    color: "#242424",
                    padding: "10px 0px 5px 20px",
                    opacity: "80%",
                  }}
                ></label>
                <input
                  type="password"
                  id="password"
                  placeholder="Password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  style={{
                    borderRadius: "20px",
                    padding: "10px 20px",
                    border: "1px solid #a1a1a1",
                  }}
                />
              </div>
              {error && <div style={{ color: "red" }}>{error}</div>}

              <button
                className="mui-button"
                type="submit"
                style={{
                  marginTop: "40px",
                  border: "0px solid #FFFFFF",
                  backgroundColor: companyColor,
                  padding: "15px 40px",
                  color: "#FFFFFF",
                  borderRadius: "30px",
                  fontWeight: "600",
                  textDecoration: "none",
                  transition: "all 0.3s ease",
                }}
              >
                Log in
              </button>
            </form>
            <div
              style={{
                color: "rgba(0,0,0,0.6)",
                fontSize: "14px",
                margin: "20px 0px 20px 0px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                textAlign: "center",
              }}
            >
              <hr
                style={{
                  width: "30%",
                  height: "1px",
                  backgroundColor: "rgba(0,0,0,0.2)",
                  border: "none",
                  margin: "10px auto",
                }}
              />
              Or sign in with
              <hr
                style={{
                  width: "30%",
                  height: "1px",
                  backgroundColor: "rgba(0,0,0,0.2)",
                  border: "none",
                  margin: "10px auto",
                }}
              />
            </div>
            <GoogleLogin onSuccess={responseGoogle} onError={errorMessage} />
            <div style={{ marginTop: "20px" }}>
              <Link
                to="/signup"
                style={{
                  color: "rgba(0,0,0,0.5)",
                  fontSize: "12px",
                  fontWeight: "400",
                  transition: "all 0.3s ease",
                }}
              >
                If you don't have an account, click here to sign up
              </Link>
            </div>
          </div>
        </div>
      </div>
      <BackgroundSvg style={svgStyle} />
      {/* <ForegroundSvg style={svgStyle2} /> */}
    </div>
  );
}

export default Login;
