import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import backgroundImage from "./images/welcome_page_bg.jpg";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { ReactComponent as BackgroundSvg } from "./images/mobile-base-01.svg";
import { GoogleLogin } from "@react-oauth/google";

function LoginMobile() {
  const responseGoogle = (response) => {
    const credential = response;
    console.log(response);
    if (credential) {
      fetch("https://ww1.premelo.com/api/auth/google", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ credential }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (data.token) {
            console.log(data.token);
            console.log(data.redirect_url);
            // Store the token in local storage
            Cookies.set("token", data.token, { expires: 365 }); // store token in cookies
          }
          if (data.redirect_url === "/dashboard") {
            navigate("/dashboard"); // navigate to dashboard page
          } else if (data.redirect_url === "/fillinformation") {
            navigate("/fillinformation"); // navigate to fillinformation page
          } else {
            console.log("Unknown redirect URL:", data.redirectUrl);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  const errorMessage = (error) => {
    console.log(error);
  };
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const [companyName, setCompanyName] = useState("Premelo");
  // const [companyColor, setCompanyColor] = useState("green");
  const [companyColor, setCompanyColor] = useState("#1450db");
  const svgStyle = {
    fill: companyColor,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 0,
    width: "100%",
  };

  const getCompanyColorByDomain = async () => {
    try {
      console.log(window.location.hostname);
      const response = await axios.get(
        "https://ww1.premelo.com/api/company-color-by-domain", // Replace with your API endpoint
        {
          params: {
            domain: `https://${window.location.hostname}`,
          },
        }
      );
      const data = response.data;
      if (data && data.company_color) {
        setCompanyColor(data.company_color);
        console.log(data.company_color);
      }
      if (data && data.company_name) {
        setCompanyName(data.company_name);
        console.log(data.company_name);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Call the function when the sign-up page loads
  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      navigate('/dashboard');
    }
    getCompanyColorByDomain();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post("https://ww1.premelo.com/api/login", {
        email,
        password,
      });

      const token = response.data.token;
      Cookies.set("token", token, { expires: 365 }); // store token in cookies

      const redirectUrl = response.data.redirect_url;
      if (redirectUrl === "https://www.premelo.com/dashboard") {
        navigate("/dashboard"); // navigate to dashboard page
      } else if (redirectUrl === "https://www.premelo.com/fillinformation") {
        navigate("/fillinformation"); // navigate to fillinformation page
      } else {
        console.log("Unknown redirect URL:", redirectUrl);
      }
    } catch (error) {
      setError("Invalid cred."); // set error message if login fails
    }
  };

  return (
    <div
      style={{
        alignItems: "center",
        height: "90vh",
        width: "100%",
        backgroundSize: "cover",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          textAlign: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            color: "white",
            fontSize: "40px",
            fontWeight: "800",
            padding: "25px",
            paddingLeft: "40px",
            zIndex: 20,
            top: 0,
            bottom: 0,
            left: 0,
            textAlign: "left",
            right: 0,
          }}
        >
          {companyName}
        </div>
        <div
          style={{
            zIndex: 200,
            width: "100%",
            alignContent: "center",
            textAlign: "center",
            justifyContent: "center",
            padding: "0px 20px",
          }}
        >
          <div
            style={{
              textAlign: "center",
              background: "white",
              borderRadius: "35px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
              padding: "40px 0px",
              width: "100%",
            }}
          >
            <h1
              style={{
                fontSize: "40px",
                color: "#242424",
                textAlign: "center",
                margin: "0px",
                padding: "0px",
                fontWeight: "600",
              }}
            >
              Login
            </h1>
            <form
              onSubmit={handleSubmit}
              style={{
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  marginTop: "25px",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <input
                  type="email"
                  id="email"
                  placeholder="Email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  style={{
                    margin: "0px",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    border: "1px solid #a1a1a1",
                    width: "70%",
                    fontSize: "12px",
                  }}
                />
              </div>
              <div
                style={{
                  marginTop: "10px",
                  textAlign: "center",
                  justifyContent: "center",
                }}
              >
                <input
                  type="password"
                  id="password"
                  placeholder="Password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  style={{
                    margin: "0px",
                    borderRadius: "20px",
                    padding: "10px 20px",
                    border: "1px solid #a1a1a1",
                    width: "70%",
                    fontSize: "12px",
                  }}
                />
              </div>
              {error && <div style={{ color: "red" }}>{error}</div>}

              <button
                className="mui-button"
                type="submit"
                style={{
                  marginTop: "40px",
                  border: "0px solid #FFFFFF",
                  backgroundColor: companyColor,
                  padding: "8px 50px",
                  color: "#FFFFFF",
                  borderRadius: "30px",
                  fontWeight: "600",
                  fontSize: "16px",
                  textDecoration: "none",
                  transition: "all 0.3s ease",
                }}
              >
                Log in
              </button>
            </form>
            <div
              style={{
                color: "rgba(0,0,0,0.6)",
                fontSize: "14px",
                margin: "20px 0px 20px 0px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                textAlign: "center",
              }}
            >
              <hr
                style={{
                  width: "30%",
                  height: "1px",
                  backgroundColor: "rgba(0,0,0,0.2)",
                  border: "none",
                  margin: "10px auto",
                }}
              />
              Or sign in with
              <hr
                style={{
                  width: "30%",
                  height: "1px",
                  backgroundColor: "rgba(0,0,0,0.2)",
                  border: "none",
                  margin: "10px auto",
                }}
              />
            </div>
            <div style={{display:"flex", justifyContent:"center", textAlign:"center"}}>
              <GoogleLogin onSuccess={responseGoogle} onError={errorMessage} />
            </div>
            <div
              style={{
                marginTop: "10px",
                lineHeight: "13px",
                textAlign: "center",
                justifyContent: "center",
                padding: "0px 40px",
              }}
            >
              <Link
                to="/signup"
                style={{
                  color: "rgba(0,0,0,0.5)",
                  fontSize: "12px",
                  fontWeight: "400",
                  transition: "all 0.3s ease",
                }}
              >
                If you don't have an account, click here to sign up
              </Link>
            </div>
          </div>
        </div>
      </div>
      <BackgroundSvg style={svgStyle} />
    </div>
  );
}

export default LoginMobile;
