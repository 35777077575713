import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Cookies from "js-cookie";
import { Cookie, PresentToAllSharp, PropaneSharp } from "@mui/icons-material";
import { useEffect } from "react";
import moment from "moment";
import { fontWeight } from "@mui/system";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import SaveBig from "./images/save_big.png";
import CardProvider from "./images/card_providers-01.png";
import Logo from "./images/CS_Favicon-01.png";
import "./App.css";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import QueuePlayNextIcon from "@mui/icons-material/QueuePlayNext";
import Modal from "react-modal";
import { isOverflown } from "@mui/x-data-grid/utils/domUtils";
import { CircularProgress } from "@mui/material";

const SubscriptionContent = (props) => {
  const { primaryColor, complementaryColor } = props;
  const [userCount, setUserCount] = useState(10);
  const [plan, setPlan] = useState("price_1MyuVBB9rsZCCn0XY7KwajMU");
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [message, setMessage] = useState("");
  const [activePlan, setActivePlan] = useState("");
  const [activePlanExpired, setActivePlanExpired] = useState("");

  const getActiveSubscription = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/getActiveSubscription",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 404) {
      setMessage("No active subscription found.");
    } else {
      const data = await response.json();

      const formattedDate = moment(data.expires_at).format("DD MMM YYYY");
      setActivePlanExpired(formattedDate);
      if (data.plan === "default") {
        if (data.quantity >= 1 && data.quantity <= 10) {
          setActivePlan("Premelo Plus 1");
        } else if (data.quantity >= 11 && data.quantity <= 20) {
          setActivePlan("Premelo Plus 2");
        } else if (data.quantity >= 21 && data.quantity <= 40) {
          setActivePlan("Premelo Plus 3");
        } else if (data.quantity >= 11 && data.quantity <= 60) {
          setActivePlan("Premelo Plus 4");
        } else if (data.quantity >= 21 && data.quantity <= 100) {
          setActivePlan("Premelo Plus 5");
        } else {
          setActivePlan("Premelo Plus");
        }
      } else {
        setActivePlan("");
      }
    }
  };

  useEffect(() => {
    getActiveSubscription();
  }, []);

  const [name, setName] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const [address, setAddress] = useState("");

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };
  const [city, setCity] = useState("");

  const handleCityChange = (event) => {
    setCity(event.target.value);
  };
  const [state, setState] = useState("");

  const handleStateChange = (event) => {
    setState(event.target.value);
  };
  const [country, setCountry] = useState("");

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };
  const [postal, setPostal] = useState("");

  const handlePostalChange = (event) => {
    setPostal(event.target.value);
  };
  const [paymentSuccessfulMessage, setPaymentSuccessfulMessage] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Use elements.getElement to get a reference to the CardElement.
    const cardElement = elements.getElement(CardElement);

    // Create a payment method using the card element and user's billing info.
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: name,
        address: {
          line1: address,
          city: city,
          state: state,
          country: country,
          postal_code: postal,
        },
      },
    });

    if (error) {
      setErrorMessage(error.message);
      setLoading(false);
    } else {
      console.log("[PaymentMethod]", paymentMethod);
      const token = Cookies.get("token");
      const response = await fetch(
        "https://ww1.premelo.com/api/create-subscription",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paymentMethodId: paymentMethod.id,
            plan_id: plan,
            quantity: userCount,
          }),
        }
      );

      const data = await response.json();
      console.log(data.success);
      if (data.success === true) {
        setPaymentSuccessfulMessage(true);
        setTimeout(() => {
          setPaymentContentFormIsOpen(false);
          setLoading(false);
        }, 3000);
      }
      if (data.success === false) {
        setLoading(false);
      }
      if (!response.ok) {
        const { error } = await response.json();
        console.log(error);
        setLoading(false);
      } else {
        // Subscription created successfully
        const { subscription } = await response.json();
        console.log(subscription);
        setLoading(false);
      }
    }
  };

  const [price, setPrice] = useState("12$");
  const [priceNo, setPriceNo] = useState(12);
  const [paymentContentFormIsOpen, setPaymentContentFormIsOpen] =
    useState(false);

  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;

    if (selectedValue === "<10") {
      setPrice("12$");
      setPriceNo(12);
      setUserCount("10");
      setPlan("price_1MyuVBB9rsZCCn0XY7KwajMU");
    } else if (selectedValue === "11-20") {
      setPrice("11$");
      setPriceNo(11);
      setUserCount("20");
      setPlan("price_1MyuVBB9rsZCCn0XwAnfSQbg");
    } else if (selectedValue === "21-40") {
      setPrice("10$");
      setPriceNo(10);
      setUserCount("40");
      setPlan("price_1MyuVBB9rsZCCn0XYKj6huyl");
    } else if (selectedValue === "41-60") {
      setPrice("9$");
      setPriceNo(9);
      setUserCount("60");
      setPlan("price_1MyuVBB9rsZCCn0XvTytVglO");
    } else if (selectedValue === "61-100") {
      setPrice("8$");
      setPriceNo(8);
      setUserCount("100");
      setPlan("price_1MyuVBB9rsZCCn0Xrkv0f5Fa");
    } else if (selectedValue === "100 above") {
      setPrice("7$");
      setPriceNo(7);
    }
  };
  const countryOptions = {
    "": "",
    AF: "Afghanistan",
    AM: "Armenia",
    AZ: "Azerbaijan",
    BH: "Bahrain",
    BD: "Bangladesh",
    BT: "Bhutan",
    BN: "Brunei",
    KH: "Cambodia",
    CN: "China",
    CY: "Cyprus",
    TL: "Timor-Leste",
    GE: "Georgia",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran",
    IQ: "Iraq",
    IL: "Israel",
    JP: "Japan",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Laos",
    LB: "Lebanon",
    MY: "Malaysia",
    MV: "Maldives",
    MN: "Mongolia",
    MM: "Myanmar",
    NP: "Nepal",
    KP: "North Korea",
    OM: "Oman",
    PK: "Pakistan",
    PS: "Palestine",
    PH: "Philippines",
    QA: "Qatar",
    RU: "Russia",
    SA: "Saudi Arabia",
    SG: "Singapore",
    KR: "South Korea",
    LK: "Sri Lanka",
    SY: "Syria",
    TW: "Taiwan",
    TJ: "Tajikistan",
    TH: "Thailand",
    TR: "Turkey",
    TM: "Turkmenistan",
    AE: "United Arab Emirates",
    UZ: "Uzbekistan",
    VN: "Vietnam",
    YE: "Yemen",
  };
  const paymentContent = (
    <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <div
          style={{
            width: "50%",
            background: "white",
            padding: "0px 40px",
            marginRight: "-80px",
            zIndex: "100",
            borderRadius: "25px",
          }}
        >
          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "-50px",
                marginBottom: "-80px",
              }}
            >
              <img
                style={{ width: "100%" }}
                src={CardProvider}
                alt="Save Big"
              />
            </div>
            <div style={{ width: "96%", marginBottom: "10px" }}>
              <div style={{ fontSize: "10px", color: "rgba(0,0,0,0.4)" }}>
                Name on card
              </div>
              <input
                id="name-input"
                type="text"
                value={name}
                onChange={handleNameChange}
                style={{
                  border: "1px solid rgba(0,0,0,0.2)",
                  padding: "6px 10px",
                  borderRadius: "10px",
                  width: "100%",
                  fontSize: "12px",
                }}
              ></input>
            </div>
            <div style={{ width: "100%", marginBottom: "10px" }}>
              <div style={{ fontSize: "10px", color: "rgba(0,0,0,0.4)" }}>
                Card details
              </div>
              <div
                style={{
                  border: "1px solid rgba(0,0,0,0.2)",
                  padding: "6px 10px",
                  borderRadius: "10px",
                }}
              >
                <CardElement />
              </div>
              {errorMessage && (
                <div
                  style={{ color: "red", fontWeight: "400", fontSize: "10px" }}
                >
                  {errorMessage}
                </div>
              )}
            </div>
            <div style={{ width: "96%", marginBottom: "10px" }}>
              <div style={{ fontSize: "10px", color: "rgba(0,0,0,0.4)" }}>
                Address
              </div>
              <input
                id="address"
                type="text"
                value={address}
                onChange={handleAddressChange}
                style={{
                  border: "1px solid rgba(0,0,0,0.2)",
                  padding: "6px 10px",
                  borderRadius: "10px",
                  width: "100%",
                  fontSize: "12px",
                }}
              ></input>
            </div>
            <div
              style={{
                width: "96%",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <div style={{ width: "45%" }}>
                <div style={{ fontSize: "10px", color: "rgba(0,0,0,0.4)" }}>
                  City
                </div>
                <input
                  id="city"
                  type="text"
                  value={city}
                  onChange={handleCityChange}
                  style={{
                    border: "1px solid rgba(0,0,0,0.2)",
                    padding: "6px 10px",
                    borderRadius: "10px",
                    width: "100%",
                    fontSize: "12px",
                  }}
                ></input>
              </div>
              <div style={{ width: "45%" }}>
                <div style={{ fontSize: "10px", color: "rgba(0,0,0,0.4)" }}>
                  State
                </div>
                <input
                  id="state"
                  type="text"
                  value={state}
                  onChange={handleStateChange}
                  style={{
                    border: "1px solid rgba(0,0,0,0.2)",
                    padding: "6px 10px",
                    borderRadius: "10px",
                    width: "100%",
                    fontSize: "12px",
                  }}
                ></input>
              </div>
            </div>
            <div
              style={{
                width: "96%",
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <div style={{ width: "45%" }}>
                <div style={{ fontSize: "10px", color: "rgba(0,0,0,0.4)" }}>
                  Country
                </div>
                <select
                  id="country"
                  value={country}
                  onChange={handleCountryChange}
                  style={{
                    border: "1px solid rgba(0,0,0,0.2)",
                    padding: "6px 10px",
                    borderRadius: "10px",
                    width: "110%",
                  }}
                >
                  {Object.entries(countryOptions).map(([code, name]) => (
                    <option key={code} value={code}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>
              <div style={{ width: "45%" }}>
                <div style={{ fontSize: "10px", color: "rgba(0,0,0,0.4)" }}>
                  Postal Code
                </div>
                <input
                  id="postal"
                  type="text"
                  value={postal}
                  onChange={handlePostalChange}
                  style={{
                    border: "1px solid rgba(0,0,0,0.2)",
                    padding: "6px 10px",
                    borderRadius: "10px",
                    width: "100%",
                    marginBottom: "40px",
                    fontSize: "12px",
                  }}
                ></input>
              </div>
            </div>
          </form>
        </div>
        <div style={{ width: "50%" }}>
          <div
            style={{
              background: props.primaryColor,
              width: "80%",
              marginLeft: "15%",
              marginTop: "20%",
              borderRadius: "20px",
              paddingTop: "30px",
              paddingBottom: "30px",
            }}
          >
            {paymentSuccessfulMessage ? (
              <div style={{ height: "80px" }}></div>
            ) : null}
            <div
              style={{
                textAlign: "center",
                width: "120px",
                height: "120px",
                background: "white",
                borderRadius: "50%",
                marginLeft: "30%",
              }}
            >
              <img style={{ width: "100%" }} src={Logo} alt="logo" />
            </div>
            {loading ? (
              <CircularProgress
                color="primary"
                size={100}
                style={{ marginLeft: "40%", marginTop: "25%" }}
              />
            ) : (
              <>
                {paymentSuccessfulMessage ? (
                  <div>
                    <div
                      style={{
                        marginTop: "50px",
                        marginLeft: "50px",
                        color: "white",
                        fontWeight: "600",
                        fontSize: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      Payment Succesful!
                    </div>
                  </div>
                ) : (
                  <div>
                    <div
                      style={{
                        marginTop: "50px",
                        marginLeft: "50px",
                        color: "white",
                        fontWeight: "600",
                        fontSize: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      Subscription Details
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        borderTop: "1px solid white",
                        borderBottom: "1px solid white",
                        width: "80%",
                        marginLeft: "50px",
                        padding: "10px 0px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          color: "white",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            marginTop: "0px",
                            marginBottom: "0px",
                            marginRight: "9px",
                            fontWeight: "600",
                            fontSize: "12px",
                          }}
                        >
                          Premelo Plus
                        </p>
                        <p
                          style={{
                            marginTop: "2px",
                            marginBottom: "0px",
                            marginRight: "3px",
                            fontWeight: "400",
                            fontSize: "10px",
                          }}
                        >
                          {userCount}
                        </p>
                        <p
                          style={{
                            marginTop: "2px",
                            marginBottom: "0px",
                            marginRight: "9px",
                            fontWeight: "400",
                            fontSize: "10px",
                          }}
                        >
                          Seats
                        </p>
                      </div>
                      <p
                        style={{
                          marginTop: "0px",
                          marginBottom: "0px",
                          marginRight: "9px",
                          fontWeight: "600",
                          fontSize: "20px",
                          color: "white",
                          marginRight: "30px",
                        }}
                      >
                        {userCount * priceNo}$
                      </p>
                    </div>
                    <div style={{ marginLeft: "50px", marginTop: "20px" }}>
                      <button
                        type="submit"
                        className="subscribe-now-button"
                        disabled={!stripe}
                        style={{
                          padding: "7px 20px",
                          border: "none",
                          fontWeight: "800",
                          borderRadius: "10px",
                          fontSize: "12px",
                          "--primary-color": primaryColor,
                          "--complementary-color": complementaryColor,
                        }}
                        onClick={handleSubmit}
                      >
                        Subscribe now
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );

  const openPaymentContentForm = () => {
    setPaymentContentFormIsOpen(true);
    console.log("buttonclicked");
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          height: "700px",
          padding: "0px 20px",
          width: "80%",
          justifyContent: "center",
          marginLeft: "7%",
          marginTop: "2%",
          "--primary-color": primaryColor,
          "--complementary-color": complementaryColor,
        }}
      >
        <div style={{ width: "32%", padding: "10px 20px" }}>
          <div
            style={{
              background: props.primaryColor,
              height: "80vh",
              borderRadius: "15px",
              width: "100%",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
            }}
          >
            <div
              style={{ padding: "12% 8%", color: "white", fontSize: "30px" }}
            >
              <div style={{ display: "flex", fontWeight: "400" }}>
                <p style={{ marginTop: "0px", marginBottom: "0px" }}>
                  Save BIG
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  fontWeight: "400",
                  marginTop: "-25px",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    marginRight: "10px",
                    fontSize: "20px",
                  }}
                >
                  with
                </p>
                <p
                  style={{
                    fontWeight: "600",
                    fontSize: "25px",
                  }}
                >
                  Premelo Plus
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  fontWeight: "400",
                  alignItems: "center",
                  marginTop: "-23px",
                }}
              >
                <p
                  style={{
                    marginTop: "-4px",
                    marginBottom: "0px",
                    fontWeight: "600",
                    fontSize: "25px",
                    marginRight: "10px",
                  }}
                >
                  Scalable
                </p>
                <p
                  style={{
                    marginTop: "0px",
                    marginBottom: "0px",
                    fontSize: "20px",
                  }}
                >
                  pricing.
                </p>
              </div>
              <select
                className="mui-button"
                onChange={handleSelectChange}
                style={{
                  padding: "7px 15px",
                  color: "white",
                  border: "2px solid white",
                  borderRadius: "10px",
                  backgroundColor: primaryColor,
                }}
              >
                <option value="<10">Less than 10 users</option>
                <option value="11-20">11-20 users</option>
                <option value="21-40">21-40 users</option>
                <option value="41-60">41-60 users</option>
                <option value="61-100">61-100 users</option>
                <option value="100 above">more than 100 users</option>
              </select>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  marginTop: "35px",
                }}
              >
                <img style={{ width: "250px" }} src={SaveBig} alt="Save Big" />
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: "32%", padding: "10px 20px" }}>
          <div
            style={{
              height: "20vh",
              marginTop: "20px",
              marginBottom: "-20px",
              color: "rgba(0,0,0,0.8)",
            }}
          >
            <div style={{ fontSize: "25px" }}>Start now</div>
            <div style={{ display: "flex", fontSize: "25px" }}>
              <p
                style={{
                  marginRight: "10px",
                  marginTop: "-10px",
                  marginBottom: "0px",
                }}
              >
                with
              </p>
              <p
                style={{
                  fontWeight: "800",
                  marginTop: "-10px",
                  marginBottom: "0px",
                }}
              >
                Premelo Plus.
              </p>
            </div>
          </div>
          <div
            style={{
              background: "white",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
              height: "60vh",
              borderRadius: "15px",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "12% 8%",
                color: "rgba(0,0,0,0.7)",
              }}
            >
              <div style={{ fontWeight: "600", fontSize: "30px" }}>
                <div>Lite</div>
                <hr
                  style={{
                    width: "80%",
                    height: "2px",
                    backgroundColor: "rgba(0,0,0,0.2)",
                    border: "none",
                    margin: "10px auto",
                  }}
                />
              </div>
              <div style={{ textAlign: "right" }}>
                <div style={{ fontSize: "37px", marginBottom: "-10px" }}>
                  {/* Free */}
                </div>
                {/* <div>per seat</div> */}
              </div>
            </div>
            <div
              style={{
                padding: "0% 8%",
                marginTop: "-30px",
                color: "rgba(0,0,0,0.7)",
                fontSize: "12px",
              }}
            >
              Premelo Lite is the perfect way for you to experience our scalable
              dashboard, allowing you to test and try our features whilst being
              limited to 8 users.
            </div>
            <div
              style={{
                padding: "0% 8%",
                marginTop: "0px",
                color: "rgba(0,0,0,0.7)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "15px",
                  marginTop: "20px",
                }}
              >
                <TaskAltIcon sx={{ fontSize: "20px" }} />
                <p style={{ margin: "0px 0px 0px 5px", fontSize: "12px" }}>
                  No restriction access
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <TaskAltIcon sx={{ fontSize: "20px" }} />
                <p style={{ margin: "0px 0px 0px 5px", fontSize: "12px" }}>
                  Limited to 8 users
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <TaskAltIcon sx={{ fontSize: "20px" }} />
                <p style={{ margin: "0px 0px 0px 5px", fontSize: "12px" }}>
                  Limited 3 Projects
                </p>
              </div>
            </div>
          </div>
        </div>
        <div style={{ width: "32%", padding: "10px 20px" }}>
          <div
            style={{
              width: "100%",
              padding: "10px 0px",
              textAlign: "right",
              display: "flex",
              justifyContent: "flex-end",
              height: "20vh",
            }}
          >
            <div>
              <div
                style={{
                  fontSize: "13px",
                  color: "rgba(0,0,0,0.6)",
                  marginBottom: "-12px",
                  marginTop: "20px",
                }}
              >
                Active Subscription
              </div>
              {activePlan ? (
                <div
                  style={{
                    background: props.complementaryColor,
                    width: "180px",
                    textAlign: "right",
                    color: "white",
                    fontWeight: "600",
                    borderRadius: "10px",
                  }}
                >
                  <p style={{ padding: "10px 20px", fontSize: "10px" }}>
                    {activePlan}
                  </p>
                </div>
              ) : (
                message && (
                  <p
                    style={{
                      padding: "10px 20px",
                      color: "rgba(0,0,0,0.4)",
                      fontSize: "10px",
                    }}
                  >
                    {message}
                  </p>
                )
              )}
            </div>
          </div>
          <div
            style={{
              background: props.primaryColor,
              height: "60vh",
              borderRadius: "15px",
              width: "100%",
              marginTop: "-20px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "12% 8%",
                color: "white",
              }}
            >
              <div style={{ fontWeight: "600", fontSize: "30px" }}>
                <div>Plus</div>
                <hr
                  style={{
                    width: "80%",
                    height: "2px",
                    backgroundColor: "white",
                    border: "none",
                    margin: "10px auto",
                  }}
                />
              </div>
              <div style={{ textAlign: "right" }}>
                <div style={{ fontSize: "30px", marginBottom: "0px" }}>
                  {price}
                </div>
                <div style={{ fontSize: "12px" }}>per seat</div>
              </div>
            </div>
            <div
              style={{ padding: "0% 8%", marginTop: "-10px", color: "white" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "15px",
                  marginTop: "-30px",
                }}
              >
                <TaskAltIcon sx={{ fontSize: "20px" }} />
                <p style={{ margin: "0px 0px 0px 5px", fontSize: "12px" }}>
                  Connect your own domain
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <TaskAltIcon sx={{ fontSize: "20px" }} />
                <p style={{ margin: "0px 0px 0px 5px", fontSize: "12px" }}>
                  Customize your own Dashboard
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <TaskAltIcon sx={{ fontSize: "20px" }} />
                <p style={{ margin: "0px 0px 0px 5px", fontSize: "12px" }}>
                  Create customized workflow for each team
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <TaskAltIcon sx={{ fontSize: "20px" }} />
                <p style={{ margin: "0px 0px 0px 5px", fontSize: "12px" }}>
                  Unlimited Projects
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <TaskAltIcon sx={{ fontSize: "20px" }} />
                <p style={{ margin: "0px 0px 0px 5px", fontSize: "12px" }}>
                  Scalable setup
                </p>
              </div>
              <div style={{ marginTop: "30px" }}>
                {price === "7$" ? (
                  <div
                    className="upgrade-button"
                    style={{
                      padding: "7px 25px",
                      width: "130px",
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() =>
                      (window.location.href = "mailto:info@premelo.com")
                    }
                  >
                    <MailOutlineIcon style={{ marginRight: "10px" }} />
                    Email us
                  </div>
                ) : (
                  <div
                    className="upgrade-button"
                    style={{
                      padding: "7px 25px",
                      width: "130px",
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={openPaymentContentForm}
                  >
                    <QueuePlayNextIcon style={{ marginRight: "10px" }} />
                    Upgrade
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={paymentContentFormIsOpen}
          onRequestClose={() => setPaymentContentFormIsOpen(false)}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
            content: {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "55%",
              height: "80vh",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              background: "transparent",
              boxShadow: "none",
              borderRadius: "10px",
              border: "none",
              overflow: "hidden",
            },
          }}
        >
          {paymentContent}
        </Modal>
      </div>
    </div>
  );
};

export default SubscriptionContent;
