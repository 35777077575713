import React, { useEffect, useState } from "react";
import WebAssetIcon from "@mui/icons-material/WebAsset";
import "./App.css";
import { v4 as uuid } from "uuid";
import { ArrowBack, ViewAgenda } from "@mui/icons-material";
import Cookies from "js-cookie";
import SettingsIcon from "@mui/icons-material/Settings";
import Modal from "react-modal";
import CancelIcon from "@mui/icons-material/Cancel";
import html2canvas from "html2canvas";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RefreshIcon from "@mui/icons-material/Refresh";
import LockIcon from "@mui/icons-material/Lock";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { ToastContainer, toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

const WebCentral = (props) => {
  const token = Cookies.get("token");

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    // Initial check
    handleResize();

    // Attach event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [openWebsiteSettingsModal, setOpenWebsiteSettingsModal] =
    useState(null);
  const [domainToCheck, setDomainToCheck] = useState(null);
  const [modalDomainStatus, setModalDomainStatus] = useState(null);
  const [creatingWebsite, setCreatingWebsite] = useState(false);
  const [setupDomain, setSetupDomain] = useState(false);
  const [setupDns, setSetupDns] = useState(false);
  const [landingPageDetails, setLandingPageDetails] = useState({
    project_name: "",
    domain_name: "",
  });
  const [responseDomain1, setResponseDomain1] = useState("");
  const [responseDomain2, setResponseDomain2] = useState("");

  const [screenshots, setScreenshots] = useState([]);

  const captureScreenshot = (url) => {
    const targetElement = document.body; // You can specify a specific element to capture
    html2canvas(targetElement)
      .then((canvas) => {
        const screenshotImage = new Image();
        screenshotImage.src = canvas.toDataURL("image/png");
        console.log(screenshotImage);
        return screenshotImage;
      })
      .catch((error) => {
        console.error("Error capturing screenshot:", error);
      });
  };

  // const captureAllScreenshots = (urls) => {
  //   setScreenshots([]); // Clear previous screenshots
  //   for (const url of urls) {
  //     captureScreenshot(url.domain_name);
  //   }
  // };
  useEffect(() => {
    console.log(screenshots);
  }, [screenshots]);

  useEffect(() => {
    if (domainToCheck) {
      const handleGetDomainStatus = async () => {
        try {
          const domainName = domainToCheck.replace("https://", "");
          const domainNameWithoutWWW = domainToCheck
            .replace("https://", "")
            .replace("www.", "");

          const responseDomain1 = await fetch(
            `https://api.vercel.com/v9/projects/landing-page-template/domains/${domainName}?teamId=team_eqXw3eswH4xmghXxuR7kYn01`,
            {
              headers: {
                Authorization: "Bearer 8DoSigQKDrHCYklrOAhwwBks",
              },
              method: "GET",
            }
          );
          const data1 = await responseDomain1.json();

          const responseDomain2 = await fetch(
            `https://api.vercel.com/v9/projects/landing-page-template/domains/${domainNameWithoutWWW}?teamId=team_eqXw3eswH4xmghXxuR7kYn01`,
            {
              headers: {
                Authorization: "Bearer 8DoSigQKDrHCYklrOAhwwBks",
              },
              method: "GET",
            }
          );
          const data2 = await responseDomain2.json();

          if (data1.verified === false) {
            console.log(data1.verification[0]);
            setResponseDomain1(data1.verification[0]);
          }
          if (data2.verified === false) {
            console.log(data2.verification[0]);
            setResponseDomain2(data2.verification[0]);
          }
        } catch (error) {
          console.log(error);
        }
      };
      handleGetDomainStatus();
    }
  }, [domainToCheck]);

  const [companyDomain, setCompanyDomain] = useState("");
  const [manualAddedDomain, setManualAddedDomain] = useState("");
  const [webIdOpen, setWebIdOpen] = useState("");
  const [webIndexOpen, setWebIndexOpen] = useState("");
  const [loading, setLoading] = useState(false);
  const [allWeb, setAllWeb] = useState([]);

  const handleAddDomainToWeb = async () => {
    try {
      setLoading(true);
      const domainName = manualAddedDomain.replace("https://", "");
      const domainNameWithoutWWW = manualAddedDomain
        .replace("https://", "")
        .replace("www.", "");

      try {
        await fetch(
          "https://api.vercel.com/v10/projects/landing-page-template/domains?teamId=team_eqXw3eswH4xmghXxuR7kYn01",
          {
            body: JSON.stringify({
              name: domainName,
            }),
            headers: {
              Authorization: "Bearer 8DoSigQKDrHCYklrOAhwwBks",
              "Content-Type": "application/json",
            },
            method: "POST",
          }
        );
        await fetch(
          "https://api.vercel.com/v10/projects/landing-page-template/domains?teamId=team_eqXw3eswH4xmghXxuR7kYn01",
          {
            body: JSON.stringify({
              name: domainNameWithoutWWW,
              redirect: domainName,
            }),
            headers: {
              Authorization: "Bearer 8DoSigQKDrHCYklrOAhwwBks",
              "Content-Type": "application/json",
            },
            method: "POST",
          }
        );
        const responseDomain1 = await fetch(
          `https://api.vercel.com/v9/projects/landing-page-template/domains/${domainName}?teamId=team_eqXw3eswH4xmghXxuR7kYn01`,
          {
            headers: {
              Authorization: "Bearer 8DoSigQKDrHCYklrOAhwwBks",
            },
            method: "GET",
          }
        );
        const data1 = await responseDomain1.json();

        const responseDomain2 = await fetch(
          `https://api.vercel.com/v9/projects/landing-page-template/domains/${domainNameWithoutWWW}?teamId=team_eqXw3eswH4xmghXxuR7kYn01`,
          {
            headers: {
              Authorization: "Bearer 8DoSigQKDrHCYklrOAhwwBks",
            },
            method: "GET",
          }
        );
        const data2 = await responseDomain2.json();

        if (data1.verified === false) {
          console.log(data1.verification[0]);
          setResponseDomain1(data1.verification[0]);
        }
        if (data2.verified === false) {
          console.log(data2.verification[0]);
          setResponseDomain2(data2.verification[0]);
        }
      } catch (error) {
        console.log(error);
      }

      const response = await fetch(
        "https://ww1.premelo.com/api/web-builder/add-domain-to-web",
        {
          method: "POST",
          body: JSON.stringify({
            domain: domainName,
            web_id: webIdOpen,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const json = await response.json();
      console.log(json);
      if (json) {
        setAllWeb(json.web);
      }
      const webIndex = json.web.findIndex((web) => web.id === webIdOpen);

      setDomainToCheck(json.web[webIndex].domain_name);
      setWebIdOpen(json.web[webIndex].id);
      setWebIndexOpen(webIndex);
      toast.info("Domain added to website!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLandingPageDetails({ ...landingPageDetails, [name]: value });
  };

  useEffect(() => {
    const fetchWebsites = async () => {
      try {
        const response = await fetch(
          "https://ww1.premelo.com/api/web-builder/get-existing-web",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const json = await response.json();
        console.log(json.web);
        setAllWeb(json.web);
        // captureAllScreenshots(json.web);
      } catch (error) {
        console.log(error);
      }
    };

    fetchWebsites();
  }, []);

  const handleSubmit = async () => {
    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/web-builder/create-web-entry",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(landingPageDetails),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create/update client.");
      }

      const data = await response.json();
      console.log("Client created/updated:", data);

      const redirectUrl = `${
        window.location.origin
      }/webbuilder?domain=${encodeURIComponent(
        landingPageDetails.domain_name
      )}`;
      window.location.href = redirectUrl;
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit1 = async () => {
    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/web-builder/create-web-entry",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(landingPageDetails),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to create/update client.");
      }

      const data = await response.json();
      console.log("Client created/updated:", data);

      const redirectUrl = `${window.location.origin}/webbuilder?webID=${data.web_build.id}`;
      console.log(redirectUrl);
      window.location.href = redirectUrl;
    } catch (error) {
      console.error(error);
    }
  };

  const [domainStatus, setDomainStatus] = useState([]);

  useEffect(() => {
    console.log("fetching domain status");
    if (allWeb) {
      const fetchDomainStatus = async (web) => {
        if (web.domain_name) {
          const domainNameWithoutWWW = web.domain_name
            .replace("https://", "")
            .replace("www.", "");

          try {
            const response = await fetch(
              `https://api.vercel.com/v6/domains/${domainNameWithoutWWW}/config?teamId=team_eqXw3eswH4xmghXxuR7kYn01`,
              {
                headers: {
                  Authorization: "Bearer 8DoSigQKDrHCYklrOAhwwBks",
                },
                method: "GET",
              }
            );
            const data = await response.json();
            return data.misconfigured;
          } catch (error) {
            console.log(error);
            return false; // or handle the error as needed
          }
        }
      };

      const fetchAllDomainStatus = async () => {
        const promises = allWeb.map((web) => fetchDomainStatus(web));
        const verifiedStatus = await Promise.all(promises);
        console.log(verifiedStatus);
        setDomainStatus(verifiedStatus);
      };

      fetchAllDomainStatus();
    }
  }, [allWeb]);

  const handleSetupDomain = async () => {
    const domainName = companyDomain.replace("https://", "");
    const domainNameWithoutWWW = companyDomain
      .replace("https://", "")
      .replace("www.", "");

    console.log({ ...landingPageDetails, domain_name: domainName });
    setLandingPageDetails({ ...landingPageDetails, domain_name: domainName });

    try {
      await fetch(
        "https://api.vercel.com/v10/projects/landing-page-template/domains?teamId=team_eqXw3eswH4xmghXxuR7kYn01",
        {
          body: JSON.stringify({
            name: domainName,
          }),
          headers: {
            Authorization: "Bearer 8DoSigQKDrHCYklrOAhwwBks",
            "Content-Type": "application/json",
          },
          method: "POST",
        }
      );
      await fetch(
        "https://api.vercel.com/v10/projects/landing-page-template/domains?teamId=team_eqXw3eswH4xmghXxuR7kYn01",
        {
          body: JSON.stringify({
            name: domainNameWithoutWWW,
            redirect: domainName,
          }),
          headers: {
            Authorization: "Bearer 8DoSigQKDrHCYklrOAhwwBks",
            "Content-Type": "application/json",
          },
          method: "POST",
        }
      );
      const responseDomain1 = await fetch(
        `https://api.vercel.com/v9/projects/landing-page-template/domains/${domainName}?teamId=team_eqXw3eswH4xmghXxuR7kYn01`,
        {
          headers: {
            Authorization: "Bearer 8DoSigQKDrHCYklrOAhwwBks",
          },
          method: "GET",
        }
      );
      const data1 = await responseDomain1.json();

      const responseDomain2 = await fetch(
        `https://api.vercel.com/v9/projects/landing-page-template/domains/${domainNameWithoutWWW}?teamId=team_eqXw3eswH4xmghXxuR7kYn01`,
        {
          headers: {
            Authorization: "Bearer 8DoSigQKDrHCYklrOAhwwBks",
          },
          method: "GET",
        }
      );
      const data2 = await responseDomain2.json();

      if (data1.verified === false) {
        console.log(data1.verification[0]);
        setResponseDomain1(data1.verification[0]);
      }
      if (data2.verified === false) {
        console.log(data2.verification[0]);
        setResponseDomain2(data2.verification[0]);
      }
      setSetupDns(true);
    } catch (error) {
      console.log(error);
    }
  };
  const [manualAddingDomain, setManualAddingDomain] = useState(false);

  return (
    <div style={{ transition: "0.3s all ease-in-out" }}>
      <Modal
        isOpen={openWebsiteSettingsModal}
        onRequestClose={() => {
          setOpenWebsiteSettingsModal(false);
          setDomainToCheck(null);
          setManualAddedDomain("");
          setManualAddingDomain(false);
          setResponseDomain1("");
          setResponseDomain2("");
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 500,
          },
          content: {
            position: "absolute",
            zIndex: 1000,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "70%",
            height: Object.keys(responseDomain1).length > 0 ? "500px" : "400px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            borderRadius: "10px",
            padding: "0px",
            borderColor: "transparent",
            outline: "none",
            border: "none",
            transition: "0.3s all ease-in-out",
          },
        }}
      >
        <div
          style={{
            background: props.primaryColor,
            height: "50px",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ margin: "0px 12px", color: "white", fontSize: "15px" }}>
            Website settings
          </div>
          <div style={{ margin: "0px 12px", color: "white", fontSize: "12px" }}>
            <CancelIcon
              className="mui-button"
              style={{ color: "white", fontSize: "18px" }}
              onClick={() => {
                setOpenWebsiteSettingsModal(false);
                setManualAddingDomain(false);
                setDomainToCheck(null);
                setManualAddedDomain("");
                setResponseDomain1("");
                setResponseDomain2("");
              }}
            />
          </div>
        </div>
        {loading ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "320px",
              padding: "0px 40px",
            }}
          >
            <CircularProgress color="primary" size={50} style={{}} />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: domainToCheck ? "flex-start" : "center",
              justifyContent: "center",
              height:
                Object.keys(responseDomain1).length > 0 ? "420px" : "320px",
              padding: "0px 40px",
            }}
          >
            <div
              style={{
                fontWeight: 600,
                color: "rgba(0,0,0,0.6)",
                fontSize: domainToCheck ? "22px" : "22px",
              }}
            >
              <div
                style={{ display: "flex", gap: "10px", alignItems: "center" }}
              >
                {domainToCheck ? "Domain: " : null}
                {domainToCheck
                  ? domainToCheck
                  : manualAddingDomain
                  ? "Enter your domain"
                  : "No domain set"}
                {domainToCheck ? (
                  <div
                    className="mui-button"
                    style={{
                      padding: "5px 20px",
                      borderRadius: "5px",
                      border: "1px solid lightgrey",
                      fontSize: "8px",
                    }}
                    onClick={() => {
                      setDomainToCheck(null);
                      setManualAddingDomain(true);
                    }}
                  >
                    Change
                  </div>
                ) : null}
              </div>
              {domainToCheck ? (
                webIndexOpen !== "" ? (
                  domainStatus[webIndexOpen] === false ||
                  domainStatus[webIndexOpen] === true ? (
                    <div
                      style={{ fontSize: "16px", display: "flex", gap: "5px" }}
                    >
                      Status:{" "}
                      <div
                        style={{
                          color:
                            domainStatus[webIndexOpen] === false
                              ? "green"
                              : "red",
                        }}
                      >
                        {domainStatus[webIndexOpen] === false
                          ? "Connected"
                          : "Not Connected"}
                      </div>
                    </div>
                  ) : null
                ) : null
              ) : null}
              {domainToCheck ? (
                <div
                  style={{
                    fontSize: "11px",
                    color: "rgba(0,0,0,0.6)",
                    fontWeight: "400",
                  }}
                >
                  Ensure that the below records have been added to your domain
                  provider's DNS settings.
                </div>
              ) : null}
            </div>
            {domainToCheck ? (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "11px",
                    fontWeight: "300",
                    width: "500px",
                    borderRadius: "12px",
                    border: "1px solid lightgrey",
                    background: `white`,
                    textAlign: "center",
                    marginTop: "18px",
                  }}
                >
                  <div
                    style={{
                      width: "80px",
                      padding: "12px 20px",
                      borderRight: "1px solid lightgrey",
                    }}
                  ></div>
                  <div
                    style={{
                      width: "50px",
                      padding: "12px 20px",
                      borderRight: "1px solid lightgrey",
                    }}
                  >
                    A
                  </div>
                  <div
                    style={{
                      width: "50px",
                      padding: "12px 20px",
                      borderRight: "1px solid lightgrey",
                    }}
                  >
                    600
                  </div>
                  <div
                    style={{
                      width: "320px",
                      padding: "12px 20px",
                    }}
                  >
                    76.76.21.21
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "11px",
                    fontWeight: "300",
                    width: "500px",
                    borderRadius: "12px",
                    border: "1px solid lightgrey",
                    background: `white`,
                    textAlign: "center",
                    marginTop: "18px",
                  }}
                >
                  <div
                    style={{
                      width: "80px",
                      padding: "12px 20px",
                      borderRight: "1px solid lightgrey",
                    }}
                  >
                    www
                  </div>
                  <div
                    style={{
                      width: "50px",
                      padding: "12px 20px",
                      borderRight: "1px solid lightgrey",
                    }}
                  >
                    CNAME
                  </div>
                  <div
                    style={{
                      width: "50px",
                      padding: "12px 20px",
                      borderRight: "1px solid lightgrey",
                    }}
                  >
                    600
                  </div>
                  <div
                    style={{
                      width: "320px",
                      padding: "12px 20px",
                    }}
                  >
                    cname.vercel-dns.com.
                  </div>
                </div>

                {Object.keys(responseDomain1).length > 0 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "11px",
                      fontWeight: "300",
                      width: "790px",
                      borderRadius: "12px",
                      border: "1px solid lightgrey",
                      background: `white`,
                      textAlign: "center",
                      marginTop: "18px",
                    }}
                  >
                    <div
                      style={{
                        width: "170px",
                        padding: "12px 20px",
                        borderRight: "1px solid lightgrey",
                      }}
                    >
                      _vercel
                    </div>
                    <div
                      style={{
                        width: "50px",
                        padding: "12px 20px",
                        borderRight: "1px solid lightgrey",
                      }}
                    >
                      {responseDomain1.type}
                    </div>
                    <div
                      style={{
                        width: "50px",
                        padding: "12px 20px",
                        borderRight: "1px solid lightgrey",
                      }}
                    >
                      600
                    </div>
                    <div
                      style={{
                        width: "520px",
                        padding: "12px 20px",
                      }}
                    >
                      {responseDomain1.value}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {Object.keys(responseDomain2).length > 0 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "11px",
                      fontWeight: "300",
                      width: "790px",
                      borderRadius: "12px",
                      border: "1px solid lightgrey",
                      background: `white`,
                      textAlign: "center",
                      marginTop: "18px",
                    }}
                  >
                    <div
                      style={{
                        width: "170px",
                        padding: "12px 20px",
                        borderRight: "1px solid lightgrey",
                      }}
                    >
                      _vercel
                    </div>
                    <div
                      style={{
                        width: "50px",
                        padding: "12px 20px",
                        borderRight: "1px solid lightgrey",
                      }}
                    >
                      {responseDomain2.type}
                    </div>
                    <div
                      style={{
                        width: "50px",
                        padding: "12px 20px",
                        borderRight: "1px solid lightgrey",
                      }}
                    >
                      600
                    </div>
                    <div
                      style={{
                        width: "520px",
                        padding: "12px 20px",
                      }}
                    >
                      {responseDomain2.value}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : manualAddingDomain ? (
              <form
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <input
                  placeholder="https://www."
                  type="text"
                  id="domain_name"
                  name="domain_name"
                  value={manualAddedDomain}
                  required
                  onChange={(e) => {
                    const value = e.target.value;
                    const newValue = value.replace(
                      /(https?:\/\/)?(www\.)?/i,
                      "https://www."
                    );
                    setManualAddedDomain(newValue);
                  }}
                  style={{
                    fontSize: "11px",
                    fontWeight: "300",
                    width: "300px",
                    padding: "12px 15px",
                    borderRadius: "12px",
                    border: "1px solid lightgrey",
                    background: `white`,
                    textAlign: "left",
                    marginTop: "10px",
                  }}
                />
                <div>
                  <button
                    className="mui-button"
                    style={{
                      color: "white",
                      background: props.primaryColor,
                      padding: "8px 30px",
                      borderRadius: "10px",
                      marginTop: "10px",
                      border: "none",
                      outline: "none",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      handleAddDomainToWeb();
                    }}
                  >
                    Add domain
                  </button>
                </div>
              </form>
            ) : (
              <>
                <div style={{ fontSize: "12px", color: "rgba(0,0,0,0.7)" }}>
                  Too start adding your domain click on the button down below
                </div>
                <div
                  className="mui-button"
                  style={{
                    color: "white",
                    background: props.primaryColor,
                    padding: "8px 30px",
                    borderRadius: "10px",
                    marginTop: "10px",
                  }}
                  onClick={() => setManualAddingDomain(true)}
                >
                  Add a domain
                </div>
              </>
            )}
          </div>
        )}
      </Modal>
      {creatingWebsite ? (
        setupDomain ? (
          setupDns ? (
            <div
              style={{
                width: "100%",
                height: windowHeight,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                transition: "0.3s all ease-in-out",
                position: "fixed",
                zIndex: 500,
                background: "rgba(255,255,255)",
                top: 0,
                left: 0,
              }}
            >
              <div style={{ width: "45%", padding: "0px 5% 0px 8%" }}>
                <div
                  style={{
                    fontSize: "30px",
                    fontWeight: "600",
                    color: props.primaryColor,
                    transition: "0.3s all ease-in-out",
                    marginTop: "-100px",
                  }}
                >
                  Final step
                </div>
                <div
                  style={{
                    fontSize: "13px",
                    color: "grey",
                    fontWeight: "300",
                    transition: "0.3s all ease-in-out",
                  }}
                >
                  Head over to your domain's DNS records and add the following
                  records
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "11px",
                    fontWeight: "300",
                    width: "500px",
                    borderRadius: "12px",
                    border: "1px solid lightgrey",
                    background: `white`,
                    textAlign: "center",
                    marginTop: "18px",
                  }}
                >
                  <div
                    style={{
                      width: "80px",
                      padding: "12px 20px",
                      borderRight: "1px solid lightgrey",
                    }}
                  ></div>
                  <div
                    style={{
                      width: "50px",
                      padding: "12px 20px",
                      borderRight: "1px solid lightgrey",
                    }}
                  >
                    A
                  </div>
                  <div
                    style={{
                      width: "50px",
                      padding: "12px 20px",
                      borderRight: "1px solid lightgrey",
                    }}
                  >
                    600
                  </div>
                  <div
                    style={{
                      width: "320px",
                      padding: "12px 20px",
                    }}
                  >
                    76.76.21.21
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "11px",
                    fontWeight: "300",
                    width: "500px",
                    borderRadius: "12px",
                    border: "1px solid lightgrey",
                    background: `white`,
                    textAlign: "center",
                    marginTop: "18px",
                  }}
                >
                  <div
                    style={{
                      width: "80px",
                      padding: "12px 20px",
                      borderRight: "1px solid lightgrey",
                    }}
                  >
                    www
                  </div>
                  <div
                    style={{
                      width: "50px",
                      padding: "12px 20px",
                      borderRight: "1px solid lightgrey",
                    }}
                  >
                    CNAME
                  </div>
                  <div
                    style={{
                      width: "50px",
                      padding: "12px 20px",
                      borderRight: "1px solid lightgrey",
                    }}
                  >
                    600
                  </div>
                  <div
                    style={{
                      width: "320px",
                      padding: "12px 20px",
                    }}
                  >
                    cname.vercel-dns.com.
                  </div>
                </div>
                {Object.keys(responseDomain1).length > 0 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "11px",
                      fontWeight: "300",
                      width: "790px",
                      borderRadius: "12px",
                      border: "1px solid lightgrey",
                      background: `white`,
                      textAlign: "center",
                      marginTop: "18px",
                    }}
                  >
                    <div
                      style={{
                        width: "170px",
                        padding: "12px 20px",
                        borderRight: "1px solid lightgrey",
                      }}
                    >
                      _vercel
                    </div>
                    <div
                      style={{
                        width: "50px",
                        padding: "12px 20px",
                        borderRight: "1px solid lightgrey",
                      }}
                    >
                      {responseDomain1.type}
                    </div>
                    <div
                      style={{
                        width: "50px",
                        padding: "12px 20px",
                        borderRight: "1px solid lightgrey",
                      }}
                    >
                      600
                    </div>
                    <div
                      style={{
                        width: "520px",
                        padding: "12px 20px",
                      }}
                    >
                      {responseDomain1.value}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                {Object.keys(responseDomain2).length > 0 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "11px",
                      fontWeight: "300",
                      width: "790px",
                      borderRadius: "12px",
                      border: "1px solid lightgrey",
                      background: `white`,
                      textAlign: "center",
                      marginTop: "18px",
                    }}
                  >
                    <div
                      style={{
                        width: "170px",
                        padding: "12px 20px",
                        borderRight: "1px solid lightgrey",
                      }}
                    >
                      _vercel
                    </div>
                    <div
                      style={{
                        width: "50px",
                        padding: "12px 20px",
                        borderRight: "1px solid lightgrey",
                      }}
                    >
                      {responseDomain2.type}
                    </div>
                    <div
                      style={{
                        width: "50px",
                        padding: "12px 20px",
                        borderRight: "1px solid lightgrey",
                      }}
                    >
                      600
                    </div>
                    <div
                      style={{
                        width: "520px",
                        padding: "12px 20px",
                      }}
                    >
                      {responseDomain2.value}
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      padding: "7px 25px",
                      background: props.primaryColor,
                      fontSize: "18px",
                      color: "white",
                      borderRadius: "12px",
                      marginTop: "20px",
                      cursor: "pointer",
                      transition: "0.3s all ease-in-out",
                    }}
                    onClick={() => handleSubmit()}
                  >
                    Complete
                  </div>
                </div>
              </div>
              <div
                style={{
                  backgroundImage: `linear-gradient(rgba(255,255,255,0), ${props.primaryColor})`,
                  width: "25%",
                  height: windowHeight,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  style={{
                    height: "70%",
                    width: "80%",
                    background: "rgba(255,255,255,0.6)",
                    borderRadius: "10px",
                    border: "2px solid white",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "5px",
                        background: "white",
                        marginTop: "3px",
                        borderRadius: "10px 10px 0px 0px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "10px",
                          color: "rgba(0,0,0,0.8)",
                          padding: "6px 90px 6px 30px",
                        }}
                      >
                        {landingPageDetails.project_name}
                      </div>
                      <CloseIcon
                        style={{
                          color: "grey",
                          fontSize: "12px",
                          marginRight: "10px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      marginLeft: "5px",
                      padding: "3px 5px",
                      background: "white",
                      fontSize: "10px",
                      color: "rgba(0,0,0,0.8)",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ArrowBackIcon
                      style={{
                        color: "grey",
                        fontSize: "14px",
                        marginRight: "10px",
                      }}
                    />
                    <ArrowForwardIcon
                      style={{
                        color: "grey",
                        fontSize: "14px",
                        marginRight: "10px",
                      }}
                    />
                    <RefreshIcon
                      style={{
                        color: "grey",
                        fontSize: "14px",
                        marginRight: "10px",
                      }}
                    />
                    <div
                      style={{
                        padding: "3px 10px",
                        background: "rgba(0,0,0,0.05)",
                        borderRadius: "20px",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "7px",
                        }}
                      >
                        <LockIcon style={{ color: "grey", fontSize: "12px" }} />
                      </div>
                      {companyDomain}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                height: windowHeight,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                transition: "0.3s all ease-in-out",
                position: "fixed",
                zIndex: 500,
                background: "rgba(255,255,255)",
                top: 0,
                left: 0,
              }}
            >
              <div style={{ width: "45%", padding: "0px 5% 0px 10%" }}>
                <div
                  style={{
                    fontSize: "22px",
                    fontWeight: "600",
                    color: props.primaryColor,
                    transition: "0.3s all ease-in-out",
                    marginTop: "-100px",
                  }}
                >
                  Connect your domain
                </div>
                <div
                  style={{
                    fontSize: "13px",
                    color: "grey",
                    fontWeight: "300",
                    transition: "0.3s all ease-in-out",
                  }}
                >
                  Type in your domain down below and we can get started with
                  building your website!
                </div>
                <div
                  style={{
                    marginTop: "20px",
                    transition: "0.3s all ease-in-out",
                  }}
                >
                  <input
                    placeholder="https://www."
                    type="text"
                    id="domain_name"
                    name="domain_name"
                    value={companyDomain}
                    onChange={(e) => {
                      const value = e.target.value;
                      const newValue = value.replace(
                        /(https?:\/\/)?(www\.)?/i,
                        "https://www."
                      );
                      setCompanyDomain(newValue);
                    }}
                    style={{
                      fontSize: "11px",
                      fontWeight: "300",
                      width: "300px",
                      padding: "12px 15px",
                      borderRadius: "12px",
                      border: "1px solid lightgrey",
                      background: `white`,
                      textAlign: "left",
                    }}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  {companyDomain.length > 0 ? (
                    <div
                      style={{
                        padding: "7px 25px",
                        background: props.primaryColor,
                        fontSize: "18px",
                        color: "white",
                        borderRadius: "12px",
                        marginTop: "20px",
                        cursor: "pointer",
                        transition: "0.3s all ease-in-out",
                      }}
                      onClick={() => handleSetupDomain()}
                    >
                      Continue
                    </div>
                  ) : (
                    <div
                      style={{
                        color: props.primaryColor,
                        textDecoration: "underline",
                        marginTop: "20px",
                        fontSize: "10px",
                        cursor: "pointer",
                      }}
                      className="mui-button"
                      onClick={() => handleSubmit1()}
                    >
                      Skip this step for now, and start building my website.
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  backgroundImage: `linear-gradient(rgba(255,255,255,0), ${props.primaryColor})`,
                  width: "40%",
                  height: windowHeight,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <div
                  style={{
                    height: "70%",
                    width: "80%",
                    background: "rgba(255,255,255,0.6)",
                    borderRadius: "10px",
                    border: "2px solid white",
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        marginLeft: "5px",
                        background: "white",
                        marginTop: "3px",
                        borderRadius: "10px 10px 0px 0px",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontSize: "10px",
                          color: "rgba(0,0,0,0.8)",
                          padding: "6px 90px 6px 30px",
                        }}
                      >
                        {landingPageDetails.project_name}
                      </div>
                      <CloseIcon
                        style={{
                          color: "grey",
                          fontSize: "12px",
                          marginRight: "10px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      marginLeft: "5px",
                      padding: "3px 5px",
                      background: "white",
                      fontSize: "10px",
                      color: "rgba(0,0,0,0.8)",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ArrowBackIcon
                      style={{
                        color: "grey",
                        fontSize: "14px",
                        marginRight: "10px",
                      }}
                    />
                    <ArrowForwardIcon
                      style={{
                        color: "grey",
                        fontSize: "14px",
                        marginRight: "10px",
                      }}
                    />
                    <RefreshIcon
                      style={{
                        color: "grey",
                        fontSize: "14px",
                        marginRight: "10px",
                      }}
                    />
                    <div
                      style={{
                        padding: "3px 10px",
                        background: "rgba(0,0,0,0.05)",
                        borderRadius: "20px",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: "7px",
                        }}
                      >
                        <LockIcon style={{ color: "grey", fontSize: "12px" }} />
                      </div>
                      {companyDomain}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        ) : (
          <div
            style={{
              width: "100%",
              height: windowHeight,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              transition: "0.3s all ease-in-out",
              position: "fixed",
              zIndex: 500,
              background: "rgba(255,255,255)",
              top: 0,
              left: 0,
            }}
          >
            <div style={{ width: "45%", padding: "0px 5% 0px 10%" }}>
              <div
                style={{
                  fontSize: "30px",
                  fontWeight: "600",
                  color: props.primaryColor,
                  transition: "0.3s all ease-in-out",
                  marginTop: "-100px",
                }}
              >
                Give your website a name
              </div>
              <div
                style={{
                  fontSize: "13px",
                  color: "grey",
                  fontWeight: "300",
                  transition: "0.3s all ease-in-out",
                }}
              >
                Let's start by giving your website a name so you can easily
                locate it in the future.
              </div>
              <div
                style={{
                  marginTop: "20px",
                  transition: "0.3s all ease-in-out",
                }}
              >
                <input
                  required
                  placeholder="My lovely website"
                  style={{
                    fontSize: "11px",
                    fontWeight: "300",
                    width: "300px",
                    padding: "12px 15px",
                    outline: "none",
                    borderRadius: "12px",
                    border: "1px solid lightgrey",
                    background: `white`,
                    textAlign: "left",
                  }}
                  type="text"
                  id="project_name"
                  name="project_name"
                  value={landingPageDetails.project_name}
                  onChange={handleInputChange}
                />
              </div>
              <div style={{ display: "flex" }}>
                {landingPageDetails.project_name.length > 0 ? (
                  <div
                    style={{
                      padding: "7px 25px",
                      background: props.primaryColor,
                      fontSize: "18px",
                      color: "white",
                      borderRadius: "12px",
                      marginTop: "20px",
                      cursor: "pointer",
                      transition: "0.3s all ease-in-out",
                    }}
                    onClick={() => setSetupDomain(true)}
                  >
                    Continue
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div
              style={{
                backgroundImage: `linear-gradient(rgba(255,255,255,0), ${props.primaryColor})`,
                width: "40%",
                height: windowHeight,
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div
                style={{
                  height: "70%",
                  width: "80%",
                  background: "rgba(255,255,255,0.6)",
                  borderRadius: "10px",
                  border: "2px solid white",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "5px",
                      background: "white",
                      marginTop: "3px",
                      borderRadius: "10px 10px 0px 0px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "10px",
                        color: "rgba(0,0,0,0.8)",
                        padding: "6px 90px 6px 30px",
                      }}
                    >
                      {landingPageDetails.project_name}
                    </div>
                    <CloseIcon
                      style={{
                        color: "grey",
                        fontSize: "12px",
                        marginRight: "10px",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    marginLeft: "5px",
                    padding: "3px 5px",
                    background: "white",
                    fontSize: "12px",
                    color: "rgba(0,0,0,0.8)",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <ArrowBackIcon
                    style={{
                      color: "grey",
                      fontSize: "14px",
                      marginRight: "10px",
                    }}
                  />
                  <ArrowForwardIcon
                    style={{
                      color: "grey",
                      fontSize: "14px",
                      marginRight: "10px",
                    }}
                  />
                  <RefreshIcon
                    style={{
                      color: "grey",
                      fontSize: "14px",
                      marginRight: "10px",
                    }}
                  />
                  <div
                    style={{
                      padding: "3px 10px",
                      background: "rgba(0,0,0,0.05)",
                      borderRadius: "20px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "7px",
                      }}
                    >
                      <LockIcon style={{ color: "grey", fontSize: "12px" }} />
                    </div>
                    www.yourdomain.com
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <>
          {/* Header */}
          <div
            style={{
              display: "flex",
              borderBottom: "1px solid rgba(0,0,0,0.1)",
              alignItems: "center",
              background: "rgba(255,255,255,0.7)",
              backdropFilter: "blur(20px)",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
              padding: "30px 0px 10px 0px",
              position: "relative",
              zIndex: 490,
            }}
          >
            <div
              style={{
                color: props.primaryColor,
                fontSize: "22px",
                fontWeight: "600",
                marginLeft: "20px",
                paddingBottom: "1%",
              }}
            >
              Web Central
            </div>
          </div>
          <div style={{ height: windowHeight - 87, overflowY: "auto" }}>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "96%",
                padding: "1% 1.5%",
                gap: "20px",
                overflowY: "scroll",
                overflowX: "hidden",
                alignItems: "flex-start",
              }}
            >
              {allWeb.map((web, index) => (
                <div
                  key={index}
                  className="create_web_hover"
                  style={{
                    width: "30%",
                    height: "250px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "white",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                    borderRadius: "12px",
                    position: "relative",
                    overflow: "hidden",
                    position: "relative",
                    zIndex: 490,
                  }}
                  onClick={() => {
                    if (web.domain_name) {
                      window.location.href = `${
                        window.location.origin
                      }/webbuilder?domain=${encodeURIComponent(
                        web.domain_name
                      )}`;
                    } else {
                      window.location.href = `${window.location.origin}/webbuilder?webID=${web.id}`;
                    }
                  }}
                >
                  <iframe
                    src={`https://${web.domain_name}`}
                    title="Embedded Website"
                    width="1920px"
                    height="1920px" // Adjust the height as needed
                    style={{
                      overflow: "hidden",
                      width: "1920px",
                      height: "1920px",
                      scale: "0.235",
                      position: "absolute",
                      top: "-737px",
                      zIndex: 0,
                    }}
                  ></iframe>
                  <div
                    className="web-button"
                    onClick={() => {
                      window.location.href = `${
                        window.location.origin
                      }/webbuilder?domain=${encodeURIComponent(
                        web.domain_name
                      )}`;
                      console.log(
                        `${
                          window.location.origin
                        }/webbuilder?domain=${encodeURIComponent(
                          web.domain_name
                        )}`
                      );
                    }}
                    style={{
                      zIndex: 5,
                      position: "absolute",
                      top: 0,
                      left: 0,
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className="web-under-button"
                      style={{
                        marginTop: "-60px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                          background: "white",
                          // border: `2px solid ${props.primaryColor}`,
                        }}
                      >
                        <EditIcon style={{ color: props.primaryColor }} />
                      </div>
                      <div
                        style={{
                          marginTop: "0px",
                          color: "white",
                          padding: "4px 25px",
                          borderRadius: "10px",
                        }}
                      >
                        Edit website
                      </div>
                    </div>
                  </div>
                  <div
                    // className="create_web_hover_text"
                    style={{
                      position: "absolute",
                      width: "calc(100% - 20px)",
                      bottom: 0,
                      left: 0,
                      padding: "10px",
                      zIndex: 499,
                      borderTop: "1px solid lightgrey",
                      background: "white",
                      borderRadius: "0px 0px 10px 10px",
                    }}
                  >
                    <div
                      className="blink"
                      style={{
                        position: "absolute",
                        top: 15,
                        right: 15,
                        borderRadius: "50%",
                        background:
                          domainStatus[index] === false ? "green" : "red",
                        height: "8px",
                        width: "8px",
                        boxShadow:
                          domainStatus[index] === false
                            ? "0px 0px 7px green"
                            : "0px 0px 7px red",
                      }}
                    >
                      {/* {domainStatus[index]
                      ? domainStatus[index].toString()
                      : null} */}
                    </div>
                    <div
                      style={{
                        color: props.primaryColor,
                        fontSize: "18px",
                        fontWeight: 700,
                      }}
                    >
                      {web.project_name}
                    </div>
                    <div
                      style={{
                        color: "grey",
                        fontSize: "10px",
                        fontWeight: 400,
                      }}
                    >
                      {web.domain_name}
                    </div>
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenWebsiteSettingsModal(true);
                      setDomainToCheck(web.domain_name);
                      setWebIdOpen(web.id);
                      setWebIndexOpen(index);
                    }}
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      zIndex: 500,
                    }}
                  >
                    <SettingsIcon style={{ color: props.primaryColor }} />
                  </div>
                </div>
              ))}
              <div
                className="create_web_hover"
                style={{
                  width: "30%",
                  height: "250px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "white",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                  borderRadius: "12px",
                }}
                onClick={() => {
                  setCreatingWebsite(true);
                }}
              >
                <WebAssetIcon
                  style={{
                    fontSize: "120px",
                    transition: "0.3s all ease-in-out",
                    color: props.primaryColor,
                  }}
                  className="create_web_hover_icon"
                />
                <div
                  className="create_web_hover_text"
                  style={{
                    fontSize: "13px",
                    color: props.primaryColor,
                    fontWeight: "600",
                  }}
                >
                  Create new website
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <ToastContainer />
    </div>
  );
};

export default WebCentral;
