import { height, width } from "@mui/system";
import React from "react";
import { useState, useCallback, useRef } from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import logo from "./images/CS_Favicon-01.png";
import "react-phone-number-input/style.css";
import { useEffect } from "react";
import Cookies from "js-cookie";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Modal from "react-modal";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { TwitterPicker } from "react-color";
import NextPlanIcon from "@mui/icons-material/NextPlan";
import axios from "axios";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import LockIcon from "@mui/icons-material/Lock";
import { Country, State, City } from "country-state-city";
import CurrencyList from "currency-list";
import { useStripe } from "@stripe/react-stripe-js";
import imageReference from "./images/imagePlaceholder.png";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { CircularProgress } from "@mui/material";

const Settings = (props) => {
  const { primaryColor, complementaryColor } = props;

  const [paymentContentFormIsOpen, setPaymentContentFormIsOpen] =
    useState(false);

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div
        style={{ height: "20px" }}
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && <div>{children}</div>}
      </div>
    );
  };

  const a11yProps = (index) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  };

  const CustomTabs = styled(Tabs)({
    borderBottom: "1px solid #e8e8e8",
    "& .MuiTabs-indicator": {
      backgroundColor: primaryColor,
    },
    "& .MuiTabs-indicator:hover": {
      backgroundColor: complementaryColor,
    },
  });

  const CustomTab = styled(Tab)({
    textTransform: "none",
    fontWeight: "800",
    fontSize: "12px",
    color: "rgba(0,0,0,0.4)",
    "&:hover": {
      color: complementaryColor,
      opacity: 1,
    },
    "&.Mui-selected": {
      color: primaryColor,
      fontSize: "14px",
    },
  });
  const [value, setValue] = useState(2);
  const handleChange = (event, newValue) => {
    console.log("newValue:", newValue);
    setValue(newValue);
  };

  //Phone Number
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [availableUsers, setAvailableUsers] = useState(
    props.availableUsersWithEmail
  );
  const handlephonenumber = (event) => {
    setPhoneNumber(event.target.value);
  };

  //profilepicture
  const [editor, setEditor] = useState(null);
  const handleEditorReady = (editor) => {
    setEditor(editor);
  };

  const fetchUsersWithEmail = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/user-available-team-members-with-email",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    const modifiedData = data.map((user) => {
      return {
        ...user,
        fullName: `${user.first_name} ${user.last_name}`,
      };
    });
    setAvailableUsers(modifiedData);
  };

  const handleDeleteClick = () => {
    console.log("delete clicked");
  };
  const handleUpdateClick = () => {
    console.log("update clicked");
  };

  const [domain, setDomain] = useState("");
  const [companyColor, setCompanyColor] = useState(props.primaryColor);
  const [domainCompany, setDomainCompany] = useState("");
  const [companyDomain, setCompanyDomain] = useState(
    props.companyDomain ? props.companyDomain : ""
  );
  const [userCount, setUserCount] = useState("8");
  const [unlock, setUnlock] = useState(false);
  const getActiveSubscription = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/getActiveSubscription",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 404) {
      console.log("No active subscription found.");
      setUnlock(true);
    } else {
      const data = await response.json();
      setUserCount(data.quantity);
      setUnlock(false);
      const formattedDate = moment(data.expires_at).format("DD MMM YYYY");
    }
  };

  const [shippingDetails, setShippingDetails] = useState([
    { name: "Every country", region: [], type: "Flat rate", value: "10" },
  ]);

  const [currencyDetails, setCurrencyDetails] = useState({
    currency: "USD",
    display: "$",
  });

  const [stripeAccountDetials, setStripeAccountDetails] = useState(null);

  const stripe = useStripe();

  const getCurrencyAndShipping = async () => {
    const token = Cookies.get("token");
    const response = await fetch("https://ww1.premelo.com/api/stripe/getcands", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    const json = await response.json();
    if (json.commerce_array.shipping) {
      setShippingDetails(json.commerce_array.shipping);
    }
    if (json.commerce_array.currency) {
      setCurrencyDetails(json.commerce_array.currency);
    }
    if (json.commerce_array.stripe_account_id) {
      setStripeAccountDetails(json.commerce_array.stripe_account_id);
    }

    // Now, you can retrieve the Stripe account details
    // const account = await stripe.accounts.retrieve(
    //   `${json.commerce_array.stripe_account_id}`
    // );
    console.log(stripe);
  };

  useEffect(() => {
    getActiveSubscription();
    fetchUsersDetails();
    getCurrencyAndShipping();
  }, []);

  const handleOpenModal = () => {
    setPaymentContentFormIsOpen(true);
    setDomainCompany(personalCompanyName);
    console.log(personalCompanyName);
  };

  const handleCompanyDomainChange = (event) => {
    setCompanyDomain(event.target.value);
  };

  const formatDomain = (domain) => {
    const regex = /^(?:https?:\/\/)?([\w.-]+)\.([\w.-]+)\.(\w+)$/i;
    const match = domain.match(regex);

    if (match) {
      const subdomain = match[1];
      const domainName = match[2].replace(".", "");
      const tld = match[3];
      return `www.${subdomain}${domainName}.${tld}`;
    } else {
      return domain;
    }
  };

  const [companyImageUrl, setCompanyImageUrl] = useState(null);
  const [personalImageUrl, setPersonalImageUrl] = useState(
    props.profileImageUrl
  );
  const [personalFirstName, setPersonalFirstName] = useState("");
  const [personalLastName, setPersonalLastName] = useState("");
  const [personalCompanyName, setPersonalCompanyName] = useState("");
  const [personalEmail, setPersonalEmail] = useState("");
  const [personalPhoneNumber, setPersonalPhoneNumber] = useState("");
  const [personalBio, setPersonalBio] = useState("");

  const fetchUsersDetails = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/profile-settings-details",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    setPersonalImageUrl(data.profile_photo);
    setPersonalFirstName(data.first_name);
    setPersonalLastName(data.last_name);
    setPersonalCompanyName(data.company_name);
    setPersonalEmail(data.email);
    setPersonalPhoneNumber(data.phone_number);
    setPersonalBio(data.bio);
    const dotComIndex = window.location.hostname;
    const encodedCompanyName = encodeURIComponent(data.company_name);
    const encodedColor = encodeURIComponent(props.primaryColor);
    {
      props.primaryColor
        ? setDomain(
            `https://${dotComIndex}/signup?companyname=${encodedCompanyName}&companycolor=${encodedColor}`
          )
        : setDomain(
            `https://${dotComIndex}/signup?companyname=${encodedCompanyName}`
          );
    }
    // setDomain(
    //   `http://${dotComIndex}/signup?companyname=${encodedCompanyName}&companycolor=${encodedColor}`
    // );
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files;
    console.log(e.target.files);

    const formData = new FormData();

    for (let i = 0; i < file.length; i++) {
      formData.append("files[]", file[i]);
    }

    try {
      const response = await axios.post(
        "https://ww1.premelo.com/api/upload-image",
        formData
      );
      const imageUrl = response.data.urls;
      setCompanyImageUrl(imageUrl[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const handlePersonalImageUpload = async (e) => {
    const file = e.target.files;
    console.log(e.target.files);

    const formData = new FormData();

    for (let i = 0; i < file.length; i++) {
      formData.append("files[]", file[i]);
    }

    try {
      const response = await axios.post(
        "https://ww1.premelo.com/api/upload-image",
        formData
      );
      const imageUrl = response.data.urls;
      setPersonalImageUrl(imageUrl[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const [customizationSuccess, setCustomizationSuccess] = useState(false);

  const handleClick = () => {
    const fileInput = document.getElementById("imageInput");
    fileInput.click();
  };

  const handlePersonalClick = () => {
    const fileInput = document.getElementById("personalImageInput");
    fileInput.click();
  };
  const [dnsZoneIsOpen, setDnsZoneIsOpen] = useState(false);

  const handleCompanyCustomizationSubmit = async () => {
    const token = Cookies.get("token");
    try {
      const response = await axios.put(
        "https://ww1.premelo.com/api/company-customization",
        {
          ...(companyColor !== props.primaryColor && {
            company_color: companyColor,
          }),
          ...(companyImageUrl && { company_logo: companyImageUrl }),
          ...(companyDomain &&
            companyDomain !== "https://www.yourwebsite.com" &&
            companyDomain !== props.companyDomain && {
              company_domain: companyDomain,
            }),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCustomizationSuccess(true);
      setTimeout(() => {
        setCustomizationSuccess(false);
      }, 3000);
    } catch (error) {
      console.log(error);
    }
    const domainName = companyDomain.replace("https://", "");
    const domainNameWithoutWWW = companyDomain
      .replace("https://", "")
      .replace("www.", "");

    if (
      domainNameWithoutWWW === "yourwebsite.com" ||
      domainNameWithoutWWW === "" ||
      companyDomain === props.companyDomain
    ) {
      return;
    } else {
      await fetch(
        "https://api.vercel.com/v10/projects/premelo-crm-123/domains?teamId=team_6ZIQL5X06mBh5ybT8YD3IEQG",
        {
          body: JSON.stringify({
            name: domainName,
          }),
          headers: {
            Authorization: "Bearer 0jytwZWqE7ttKdzeKrRKN1FF",
            "Content-Type": "application/json",
          },
          method: "POST",
        }
      );
      await fetch(
        "https://api.vercel.com/v10/projects/premelo-crm-123/domains?teamId=team_6ZIQL5X06mBh5ybT8YD3IEQG",
        {
          body: JSON.stringify({
            name: domainNameWithoutWWW,
            redirect: domainName,
          }),
          headers: {
            Authorization: "Bearer 0jytwZWqE7ttKdzeKrRKN1FF",
            "Content-Type": "application/json",
          },
          method: "POST",
        }
      );
      setDnsZoneIsOpen(true);
      console.log("please add this to your DNS zone");
    }
  };

  const handleCompanyDomainChangeConfirmation = (e) => {
    const domainInput = e.target.value;
    const regex = /^http(s)?:\/\/(www\.)?([^\/]+)/i;
    const match = domainInput.match(regex);

    if (match && match.length > 3) {
      const companyDomain = `https://www.${match[3]}`;
      setCompanyDomain(companyDomain);
    } else {
      setCompanyDomain(domainInput);
    }
  };
  const [isUpdated, setIsUpdated] = useState(false);
  const [shippingChanged, setShippingChanged] = useState(false);
  const [commerceChanged, setCommerceChanged] = useState(false);
  const handleUpdateProfile = async () => {
    const token = Cookies.get("token");
    try {
      const response = await axios.put(
        "https://ww1.premelo.com/api/update-profile-settings-details",
        {
          profile_photo: personalImageUrl,
          first_name: personalFirstName,
          last_name: personalLastName,
          phone_number: personalPhoneNumber,
          bio: personalBio,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
    } catch (error) {
      console.log(error);
    }
    setIsUpdated(true);
    setTimeout(() => {
      setIsUpdated(false);
    }, 3000);
  };

  const profileContent = (
    <div style={{}}>
      <div
        style={{
          borderBottom: "1px solid rgba(0,0,0,0.2",
          paddingBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div
            style={{
              fontSize: "20px",
              color: "rgba(0, 0, 0,0.7)",
              fontWeight: "600",
            }}
          >
            Profile
          </div>
          <div style={{ fontSize: "10px", color: "rgba(0,0,0,0.4)" }}>
            Update your personal information here
          </div>
        </div>
        {isUpdated ? (
          <div
            className="mui-button"
            style={{
              padding: "5px 25px",
              background: "#28993d",
              color: "white",
              height: "20px",
              borderRadius: "10px",
              fontSize: "12px",
            }}
          >
            Success
          </div>
        ) : (
          <div
            className="mui-button"
            onClick={handleUpdateProfile}
            style={{
              padding: "5px 25px",
              background: complementaryColor,
              color: "white",
              height: "20px",
              borderRadius: "10px",
              fontSize: "12px",
            }}
          >
            Save
          </div>
        )}
      </div>
      {/* {Profile Row} */}
      <div
        style={{
          display: "flex",
          fontSize: "20px",
          color: "rgba(0, 0, 0,0.7)",
          paddingTop: "20px",
          borderBottom: "1px solid rgba(0,0,0,0.2",
          paddingBottom: "20px",
        }}
      >
        <div style={{ width: "400px" }}>
          <div
            style={{
              fontWeight: "600",
              fontSize: "17px",
              color: "rgba(0,0,0,0.6)",
            }}
          >
            Profile Photo
          </div>
          <div style={{ fontSize: "10px", color: "rgba(0,0,0,0.4)" }}>
            This will be displayed on your profile.
          </div>
          <div style={{ marginTop: "15px" }}>
            <button
              className="mui-button"
              style={{
                color: "white",
                backgroundColor: props.primaryColor,
                border: "none",
                fontSize: "10px",
                fontWeight: "600",
                padding: "10px 30px",
                borderRadius: "10px",
              }}
              onClick={handlePersonalClick}
            >
              Update
            </button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            width: "40%",
          }}
        >
          <input
            id="personalImageInput"
            type="file"
            style={{ display: "none" }}
            onChange={handlePersonalImageUpload}
          />
          <div
            className="mui-button"
            style={{
              width: "140px",
              height: "140px",
              border: "2px solid rgba(125, 125, 125,0.5)",
              borderRadius: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              backgroundColor: "white",
              textAlign: "center",
              color: "rgba(125, 125, 125,0.5)",
              marginTop: "15px",
            }}
            onClick={handlePersonalClick}
          >
            {personalImageUrl ? (
              <img
                src={personalImageUrl}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
                alt="profile photo"
              />
            ) : (
              "Profile Picture"
            )}
          </div>
        </div>
      </div>
      {/* {First Row} */}
      <div
        style={{
          display: "flex",
          paddingTop: "20px",
          borderBottom: "1px solid rgba(0,0,0,0.2",
          paddingBottom: "20px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "400px" }}>
          <div
            style={{
              width: "400px",
              fontWeight: "600",
              fontSize: "17px",
              color: "rgba(0,0,0,0.6)",
            }}
          >
            Your name
          </div>
          <div style={{ fontSize: "10px", color: "rgba(0,0,0,0.4)" }}>
            This will be the name other users will see.
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            width: "40%",
          }}
        >
          <input
            className="profile-input"
            placeholder="First Name"
            type="text"
            name="firstname"
            value={personalFirstName}
            onChange={(e) => setPersonalFirstName(e.target.value)}
            style={{
              fontSize: "11px",
              width: "45%",
              borderRadius: "10px",
              border: "1px solid rgba(125, 125, 125,0.5)",
              padding: "7px",
            }}
          />
          <input
            className="profile-input"
            placeholder="Last Name"
            type="text"
            name="lastname"
            value={personalLastName}
            onChange={(e) => setPersonalLastName(e.target.value)}
            style={{
              fontSize: "11px",
              width: "45%",
              borderRadius: "10px",
              border: "1px solid rgba(125, 125, 125,0.5)",
              padding: "7px",
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          paddingTop: "20px",
          borderBottom: "1px solid rgba(0,0,0,0.2",
          paddingBottom: "20px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "400px" }}>
          <div
            style={{
              width: "400px",
              fontWeight: "600",
              fontSize: "17px",
              color: "rgba(0,0,0,0.6)",
            }}
          >
            Company name
          </div>
          <div style={{ fontSize: "10px", color: "rgba(0,0,0,0.4)" }}>
            Your assigned Company
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            width: "40%",
          }}
        >
          <input
            className="profile-input"
            placeholder="Company Name"
            type="text"
            name="companyname"
            value={personalCompanyName}
            onChange={(e) => setPersonalCompanyName(e.target.value)}
            disabled
            style={{
              fontSize: "11px",
              width: "100%",
              borderRadius: "10px",
              border: "1px solid rgba(125, 125, 125,0.5)",
              padding: "7px",
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          paddingTop: "20px",
          borderBottom: "1px solid rgba(0,0,0,0.2",
          paddingBottom: "20px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "400px" }}>
          <div
            style={{
              width: "400px",
              fontWeight: "600",
              fontSize: "17px",
              color: "rgba(0,0,0,0.6)",
            }}
          >
            Email Address
          </div>
          <div style={{ fontSize: "11px", color: "rgba(0,0,0,0.4)" }}>
            {/* Your assigned Company */}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            width: "40%",
          }}
        >
          <input
            className="profile-input"
            placeholder="example@example.com"
            type="text"
            name="email"
            value={personalEmail}
            disabled
            onChange={(e) => setPersonalEmail(e.target.value)}
            style={{
              fontSize: "11px",
              width: "100%",
              borderRadius: "10px",
              border: "1px solid rgba(125, 125, 125,0.5)",
              padding: "7px",
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          paddingTop: "20px",
          borderBottom: "1px solid rgba(0,0,0,0.2",
          paddingBottom: "20px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "400px" }}>
          <div
            style={{
              width: "400px",
              fontWeight: "600",
              fontSize: "17px",
              color: "rgba(0,0,0,0.6)",
            }}
          >
            Phone number
          </div>
          <div style={{ fontSize: "10px", color: "rgba(0,0,0,0.4)" }}>
            Phone number that will be used for further integration.
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            width: "40%",
          }}
        >
          <input
            className="profile-input"
            placeholder="Phone Number"
            type="tel"
            name="phone"
            value={personalPhoneNumber}
            onChange={(e) => setPersonalPhoneNumber(e.target.value)}
            style={{
              fontSize: "11px",
              width: "100%",
              borderRadius: "10px",
              border: "1px solid rgba(125, 125, 125,0.5)",
              padding: "7px",
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          paddingTop: "20px",
          borderBottom: "1px solid rgba(0,0,0,0.2",
          paddingBottom: "20px",
          alignItems: "center",
        }}
      >
        <div style={{ width: "400px" }}>
          <div
            style={{
              width: "400px",
              fontWeight: "600",
              fontSize: "17px",
              color: "rgba(0,0,0,0.6)",
            }}
          >
            Your bio
          </div>
          <div style={{ fontSize: "10px", color: "rgba(0,0,0,0.4)" }}>
            Help others know you by reading your bio.
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            width: "40%",
          }}
        >
          <textarea
            className="profile-input"
            placeholder="About me"
            type="tel"
            name="phone"
            value={personalBio}
            onChange={(e) => setPersonalBio(e.target.value)}
            rows={8}
            style={{
              fontSize: "11px",
              width: "100%",
              borderRadius: "10px",
              border: "1px solid rgba(125, 125, 125,0.5)",
              padding: "7px",
            }}
          />
        </div>
      </div>
    </div>
  );
  const teamContent = (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          style={{
            fontSize: "30px",
            color: "rgba(0, 0, 0,0.7)",
            fontWeight: "600",
            fontSize: "17px",
          }}
        >
          All users
        </div>
        <div
          className="mui-button"
          style={{
            display: "flex",
            alignItems: "center",
            background: props.primaryColor,
            color: "white",
            fontSize: "12px",
            fontWeight: "600",
            padding: "5px 25px",
            borderRadius: "10px",
          }}
          onClick={handleOpenModal}
        >
          <PersonAddIcon
            style={{ color: "white", marginRight: "10px", fontSize: "18px" }}
          />
          Invite
        </div>
      </div>
      <div
        style={{
          display: "flex",
          marginTop: "40px",
          color: "rgba(0,0,0,0.6)",
          fontSize: "12px",
          fontWeight: "600",
          borderBottom: "2px solid rgba(0,0,0,0.2)",
          paddingBottom: "5px",
        }}
      >
        <div style={{ width: "25%" }}>Name</div>
        <div style={{ width: "25%" }}>Email</div>
        <div style={{ width: "25%" }}>Designation</div>
        <div style={{ width: "20%" }}>Joined date</div>
        <div
          style={{ width: "5%", color: "rgba(0,0,0,0.4)", textAlign: "right" }}
        >
          {availableUsers.length}/{userCount}
        </div>
      </div>
      {availableUsers.map((user) => {
        const useInitials = `${user.first_name.charAt(
          0
        )}${user.last_name.charAt(0)}`;

        const dateString = user.created_at;
        const dateObject = new Date(dateString);
        const formattedDate = dateObject.toLocaleString("en-US", {
          day: "numeric",
          month: "long",
          year: "numeric",
        });

        return (
          <div
            key={user.user_id}
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "15px",
              color: "rgba(0,0,0,0.5)",
              fontSize: "12px",
              fontWeight: "400",
              borderBottom: "1px solid rgba(0,0,0,0.1)",
              paddingBottom: "13px",
            }}
          >
            <div
              style={{ display: "flex", alignItems: "center", width: "25%" }}
            >
              <div
                style={{
                  height: "25px",
                  width: "25px",
                  borderRadius: "50%",
                  background: `${user.user_color}`,
                  color: "white",
                  marginRight: "10px",
                }}
              >
                <div
                  style={{
                    marginTop: "6px",
                    marginLeft: "5px",
                    fontSize: "10px",
                  }}
                >
                  {useInitials}
                </div>
              </div>
              {user.fullName}
            </div>
            <div style={{ width: "25%" }}>{user.email}</div>
            <div style={{ width: "25%" }}>{user.position_in_company}</div>
            <div style={{ width: "25%" }}>{formattedDate}</div>
          </div>
        );
      })}
    </div>
  );
  const [companyImageUrlFromParent, setCompanyImageUrlFromParent] = useState(
    props.companyImageUrl
  );

  const customizationContent = (
    <div
      style={{
        position: "relative",
        width: "100%",
        overflow: "hidden",
        height: "100%",
      }}
    >
      <div
        style={{
          borderBottom: "1px solid rgba(0,0,0,0.2",
          paddingBottom: "20px",
          position: "relative",
        }}
      >
        <div
          style={{
            fontSize: "20px",
            color: "rgba(0, 0, 0,0.7)",
            fontWeight: "600",
          }}
        >
          Customize your Platform
        </div>
        <div style={{ fontSize: "10px", color: "rgba(0,0,0,0.4)" }}>
          Enjoy the flexibility that comes with Premelo Plus.
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "80vh",
        }}
      >
        <div style={{ width: "30%" }}>
          <div style={{ marginTop: "20px" }}>
            <div style={{ color: "rgba(0,0,0,0.7)", fontSize: "10px" }}>
              Company logo
            </div>
            <input
              id="imageInput"
              type="file"
              style={{ display: "none" }}
              onChange={handleImageUpload}
            />
            <div
              className="mui-button"
              style={{
                width: "80px",
                height: "80px",
                border: "2px solid rgba(125, 125, 125,0.5)",
                borderRadius: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                backgroundColor: "white",
                textAlign: "center",
                color: "rgba(125, 125, 125,0.5)",
                marginTop: "15px",
              }}
              onClick={handleClick}
            >
              {companyImageUrl ? (
                <img
                  src={companyImageUrl}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  alt="company logo"
                />
              ) : props.companyImageUrl ? (
                <img
                  src={props.companyImageUrl}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  alt="company logo"
                />
              ) : (
                <img
                  src={logo}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  alt="company logo"
                />
              )}
            </div>
          </div>
          <div style={{ marginTop: "30px" }}>
            <div
              style={{
                color: "rgba(0,0,0,0.7)",
                fontSize: "10px",
                marginBottom: "10px",
              }}
            >
              UI Color tone
            </div>
            <TwitterPicker
              style={{ backgroundColor: "transparent" }}
              onChange={(color, event) => setCompanyColor(color.hex)}
            />
          </div>
          <div style={{ marginTop: "30px" }}>
            {/* <div
              style={{
                color: "rgba(0,0,0,0.7)",
                fontSize: "10px",
                marginBottom: "10px",
              }}
            >
              Connect domain
            </div>
            <input
              placeholder="https://www."
              type="text"
              name="companyDomain"
              value={companyDomain}
              onChange={(e) => {
                const value = e.target.value;
                const newValue = value.replace(
                  /(https?:\/\/)?(www\.)?/i,
                  "https://www."
                );
                setCompanyDomain(newValue);
              }}
              style={{
                fontSize: "14px",
                width: "80%",
                borderRadius: "10px",
                border: "1px solid rgba(125, 125, 125,0.5)",
                padding: "7px",
              }}
              disabled={props.companyDomain}
            />
            <div style={{ marginTop: "10px" }}>
              {companyDomain !== "https://www." &&
                companyDomain !== "" &&
                companyDomain !== "https://www.premelo.com" && (
                  <div>
                    <p
                      style={{
                        color: "rgba(0,0,0,0.4)",
                        fontSize: "12px",
                        lineHeight: "12px",
                        marginBottom: "-6px",
                      }}
                    >
                      Please ensure that you have added your A & CName records
                      in your domain's DNS records.
                    </p>
                    <div
                      style={{
                        display: "flex",
                        fontSize: "12px",
                        lineHeight: "12px",
                        justifyContent: "space-between",
                        width: "80%",
                      }}
                    >
                      <div>
                        <p
                          style={{
                            background: "white",
                            padding: "10px 10px",
                            borderRadius: "10px",
                            border: "1px solid rgba(0,0,0,0.4)",
                            marginBottom: "-10px",
                          }}
                        >
                          @
                        </p>
                        <p
                          style={{
                            background: "white",
                            padding: "10px 10px",
                            borderRadius: "10px",
                            border: "1px solid rgba(0,0,0,0.4)",
                          }}
                        >
                          www
                        </p>
                      </div>
                      <div>
                        <p
                          style={{
                            background: "white",
                            padding: "10px 10px",
                            borderRadius: "10px",
                            border: "1px solid rgba(0,0,0,0.4)",
                            marginBottom: "-10px",
                          }}
                        >
                          A
                        </p>
                        <p
                          style={{
                            background: "white",
                            padding: "10px 10px",
                            borderRadius: "10px",
                            border: "1px solid rgba(0,0,0,0.4)",
                          }}
                        >
                          CNAME
                        </p>
                      </div>
                      <div>
                        <p
                          style={{
                            background: "white",
                            padding: "10px 10px",
                            borderRadius: "10px",
                            border: "1px solid rgba(0,0,0,0.4)",
                            marginBottom: "-10px",
                          }}
                        >
                          76.76.21.21
                        </p>
                        <p
                          style={{
                            background: "white",
                            padding: "10px 10px",
                            borderRadius: "10px",
                            border: "1px solid rgba(0,0,0,0.4)",
                          }}
                        >
                          cname.vercel-dns.com.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
            </div> */}
            {customizationSuccess ? (
              <div
                className="mui-button"
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: "#28993d",
                  color: "white",
                  fontSize: "10px",
                  fontWeight: "600",
                  padding: "5px 25px",
                  borderRadius: "5px",
                  width: "80px",
                  marginTop: "15px",
                }}
                // onClick={handleOpenModal}
              >
                <CheckCircleIcon
                  style={{
                    color: "white",
                    marginRight: "6px",
                    fontSize: "14px",
                  }}
                />
                Success
              </div>
            ) : (
              <div
                className="mui-button"
                onClick={() => handleCompanyCustomizationSubmit()}
                style={{
                  display: "flex",
                  alignItems: "center",
                  background: props.primaryColor,
                  color: "white",
                  fontSize: "10px",
                  fontWeight: "600",
                  padding: "5px 25px",
                  borderRadius: "5px",
                  width: "80px",
                  marginTop: "15px",
                }}
                // onClick={handleOpenModal}
              >
                <NextPlanIcon
                  style={{
                    color: "white",
                    marginRight: "6px",
                    fontSize: "14px",
                  }}
                />
                Proceed
              </div>
            )}
          </div>
          <div></div>
        </div>
        <div
          style={{
            width: "70%",
            background: "white",
            marginTop: "20px",
            borderRadius: "10px",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              background: companyColor,
              borderRadius: "10px 10px 0px 0px",
              padding: "10px 20px",
              color: "white",
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            <div style={{ marginLeft: "20px" }}>{companyDomain}</div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  height: "8px",
                  width: "8px",
                  borderRadius: "50%",
                  background: "white",
                  marginLeft: "10px",
                }}
              ></div>
              <div
                style={{
                  height: "8px",
                  width: "8px",
                  borderRadius: "50%",
                  background: "white",
                  marginLeft: "10px",
                }}
              ></div>
              <div
                style={{
                  height: "8px",
                  width: "8px",
                  borderRadius: "50%",
                  background: "white",
                  marginLeft: "10px",
                }}
              ></div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                width: "15%",
                height: "100%",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    border: "2px solid rgba(125, 125, 125,0.5)",
                    borderRadius: "100px",
                    display: "flex",
                    alignItems: "center",
                    overflow: "hidden",
                    backgroundColor: "white",
                    textAlign: "center",
                    color: "rgba(125, 125, 125,0.5)",
                    marginTop: "20px",
                    fontSize: "8px",
                  }}
                >
                  {companyImageUrl ? (
                    <img
                      src={companyImageUrl}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      alt="company logo"
                    />
                  ) : props.companyImageUrl ? (
                    <img
                      src={props.companyImageUrl}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      alt="company logo"
                    />
                  ) : (
                    <img
                      src={logo}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                      alt="company logo"
                    />
                  )}
                </div>
              </div>
              <div
                style={{
                  width: "70%",
                  background: "rgba(0,0,0,0.1)",
                  height: "12px",
                  marginLeft: "25px",
                  marginTop: "20px",
                  borderRadius: "15px",
                }}
              ></div>
              <div
                style={{
                  width: "70%",
                  background: "rgba(0,0,0,0.1)",
                  height: "12px",
                  marginLeft: "25px",
                  marginTop: "15px",
                  borderRadius: "15px",
                }}
              ></div>
              <div
                style={{
                  width: "70%",
                  background: "rgba(0,0,0,0.1)",
                  height: "12px",
                  marginLeft: "25px",
                  marginTop: "15px",
                  borderRadius: "15px",
                }}
              ></div>
              <div
                style={{
                  width: "70%",
                  background: "rgba(0,0,0,0.1)",
                  height: "12px",
                  marginLeft: "25px",
                  marginTop: "15px",
                  borderRadius: "15px",
                }}
              ></div>
            </div>
            <div
              style={{
                height: "58vh",
                width: "83%",
                marginTop: "10px",
                background: `${companyColor}1A`,
                borderRadius: "15px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  color: companyColor,
                  fontSize: "14px",
                  fontWeight: "600",
                  marginTop: "30px",
                  textAlign: "center",
                }}
              >
                Dashboard
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    width: "20%",
                    background: "rgba(0,0,0,0.1)",
                    height: "12px",
                    marginTop: "10px",
                    borderRadius: "15px",
                  }}
                ></div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  color: companyColor,
                  fontSize: "14px",
                  fontWeight: "600",
                  marginTop: "20px",
                  marginLeft: "40px",
                }}
              >
                Recently used
              </div>
              <div
                style={{
                  width: "93%",
                  display: "flex",
                  flexWrap: "wrap",
                  padding: "0px 37px",
                }}
              >
                <div
                  style={{
                    width: "14%",
                    flexBasis: "17%",
                    background: "rgba(0,0,0,0.1)",
                    height: "120px",
                    marginTop: "10px",
                    borderRadius: "15px",
                    marginRight: "15px",
                  }}
                ></div>
                <div
                  style={{
                    width: "14%",
                    flexBasis: "17%",
                    background: "rgba(0,0,0,0.1)",
                    height: "120px",
                    marginTop: "10px",
                    borderRadius: "15px",
                    marginRight: "15px",
                  }}
                ></div>
                <div
                  style={{
                    width: "14%",
                    flexBasis: "17%",
                    background: "rgba(0,0,0,0.1)",
                    height: "120px",
                    marginTop: "10px",
                    borderRadius: "15px",
                    marginRight: "15px",
                  }}
                ></div>
                <div
                  style={{
                    width: "14%",
                    flexBasis: "17%",
                    background: "rgba(0,0,0,0.1)",
                    height: "120px",
                    marginTop: "10px",
                    borderRadius: "15px",
                    marginRight: "15px",
                  }}
                ></div>
                <div
                  style={{
                    width: "14%",
                    flexBasis: "17%",
                    background: "rgba(0,0,0,0.1)",
                    height: "120px",
                    marginTop: "10px",
                    borderRadius: "15px",
                    marginRight: "15px",
                  }}
                ></div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  color: companyColor,
                  fontSize: "14px",
                  fontWeight: "600",
                  marginTop: "20px",
                  marginLeft: "40px",
                }}
              >
                To-do List
              </div>
              <div
                style={{
                  width: "93%",
                  flexWrap: "wrap",
                  padding: "0px 37px",
                }}
              >
                <div
                  style={{
                    width: "98%",
                    background: "rgba(0,0,0,0.1)",
                    height: "15px",
                    marginTop: "10px",
                    borderRadius: "15px",
                    marginRight: "15px",
                  }}
                ></div>
                <div
                  style={{
                    width: "63%",
                    background: "rgba(0,0,0,0.1)",
                    height: "15px",
                    marginTop: "10px",
                    borderRadius: "15px",
                    marginRight: "15px",
                  }}
                ></div>
                <div
                  style={{
                    width: "82%",
                    background: "rgba(0,0,0,0.1)",
                    height: "15px",
                    marginTop: "10px",
                    borderRadius: "15px",
                    marginRight: "15px",
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {unlock && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backdropFilter: "blur(6px)",
            zIndex: 999,
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            color: "rgba(0,0,0,0.8)",
          }}
        >
          <div
            style={{
              background: "rgba(1,1,1,0.2)",
              border: "1px solid rgba(0,0,0,0.2)",
              borderRadius: "12px",
              padding: "35px",
              marginTop: "60px",
            }}
          >
            <LockIcon style={{ fontSize: "80px" }} />
            <div style={{ fontSize: "30px", fontWeight: "600" }}>
              This feature is only available
            </div>
            <div style={{ fontSize: "20px", fontWeight: "400" }} v>
              on Premelo Plus
            </div>
          </div>
        </div>
      )} */}
    </div>
  );

  let Country = require("country-state-city").Country;
  let State = require("country-state-city").State;

  const [ecommerceActiveTab, setEcommerceActiveTab] = useState(0);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [editingTitle, setEditingTitle] = useState(false);
  const [editingDescription, setEditingDescription] = useState(false);
  const [editingFooter, setEditingFooter] = useState(false);
  const textareaRef = useRef(null);
  const handleTextareaChange = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const textareaRef3 = useRef(null);
  const handleTextareaChange3 = () => {
    if (textareaRef3.current) {
      textareaRef3.current.style.height = "auto"; // Reset height
      textareaRef3.current.style.height = `${textareaRef3.current.scrollHeight}px`;
    }
  };

  const textareaRef2 = useRef(null);
  const handleTextareaChange2 = () => {
    if (textareaRef2.current) {
      textareaRef2.current.style.height = "auto"; // Reset height
      textareaRef2.current.style.height = `${textareaRef2.current.scrollHeight}px`;
    }
  };

  const [email, setEmails] = useState({
    mail_host: null,
    mail_port: null,
    mail_email: null,
    mail_password: null,
    mail_name: null,
    emails: [
      {
        trigger: "Pending Payment",
        subject: "Premelo | Order received",
        header_image: null,
        content_title: "Your order has been received",
        content_description:
          "Your order have been made, and we are awaiting confirmation on paymentl.",
        footer: "Build with premelo",
      },
      {
        trigger: "Payment Failed",
        subject: "Premelo | Your payment has failed",
        header_image: null,
        content_title: "Payment failed",
        content_description:
          "It seems your payment didn't went through, if you would like to retry, head over to our website.",
        footer: "Build with premelo",
      },
      {
        trigger: "Order Processing",
        subject: "Premelo | Your order is being processed",
        header_image: null,
        content_title: "Order succesful",
        content_description:
          "We have received your order and our minions are preparing your order.",
        footer: "Build with premelo",
      },
      {
        trigger: "Shipping",
        subject: "Premelo | Your order is on it's way",
        header_image: null,
        content_title: "Order is shipping",
        content_description:
          "Your order has been sent out, and is on it's way to your doorsteps.",
        footer: "Build with premelo",
      },
      {
        trigger: "Tracking number added",
        subject: "Premelo | Tracking details",
        header_image: null,
        content_title: "You can now track your order.",
        content_description:
          "You can use the above tracking number to track your every step of the way now.",
        footer: "Build with premelo",
      },
      {
        trigger: "Completed",
        subject: "Premelo | Order completed",
        header_image: null,
        content_title: "Order completed",
        content_description:
          "We have received confirmation that the package have been received, if you enjoy shopping with us, do leave us a review and come back next time.",
        footer: "Build with premelo",
      },
    ],
  });
  const token = Cookies.get("token");
  const handleUpdateEmailDetails = async () => {
    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/create-update-email",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(email),
        }
      );

      const json = await response.json();
      setInputHasChanged(false)
      console.log(json);
    } catch (error) {
      console.log(error);
    }
  };

  const getEmailDetails = async () => {
    try {
      const response = await fetch("https://ww1.premelo.com/api/get-email", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        const json = await response.json();
        setEmails(json.email);
      } else if (response.status === 401) {
        console.log("No entry yet");
      } else {
        console.log("No entry yet");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getEmailDetails();
  }, []);

  const [activeEmailIndex, setActiveEmailIndex] = useState(0);

  const [fileUploading, setFileUploading] = useState(false);

  const handleImageUpload2 = async (e) => {
    setFileUploading(true);
    const file = e.target.files;
    console.log(e.target.files);

    const formData = new FormData();

    for (let i = 0; i < file.length; i++) {
      formData.append("files[]", file[i]);
    }

    try {
      const response = await axios.post(
        "https://ww1.premelo.com/api/upload-image",
        formData
      );

      const imageUrl = response.data.urls;

      const updatingEmails = { ...email };
      updatingEmails.emails[activeEmailIndex].header_image = imageUrl;
      setEmails(updatingEmails);

      setFileUploading(false);
    } catch (error) {
      console.log(error);
      setFileUploading(false);
    }
  };

  const handleClick2 = () => {
    const fileInput = document.getElementById("imageInput2");
    fileInput.click();
  };

  useEffect(() => {
    console.log(shippingDetails);
  }, [shippingDetails]);

  useEffect(() => {
    console.log(currencyDetails);
  }, [currencyDetails]);

  const [inputHasChanged, setInputHasChanged] = useState(false);

  const ecommerceContent = (
    <div
      style={{
        position: "relative",
        width: "100%",
        overflow: "hidden",
        height: "100%",
      }}
    >
      <div
        style={{
          borderBottom: "1px solid rgba(0,0,0,0.2",
          paddingBottom: "20px",
          position: "relative",
        }}
      >
        <div
          style={{
            fontSize: "20px",
            color: "rgba(0, 0, 0,0.7)",
            fontWeight: "600",
          }}
        >
          Setup your Ecommerce Essentials
        </div>
        <div style={{ fontSize: "10px", color: "rgba(0,0,0,0.4)" }}>
          Here you will have full control of your platform's ecommerce setup,
          ranging from as simple as currency choice, to your payment gateway
          setup
        </div>
      </div>
      <div
        style={{
          display: "flex",
          borderBottom: "1px solid rgba(0,0,0,0.2",
          width: "100%",
        }}
      >
        <div
          className="mui-button"
          style={{
            fontSize: "10px",
            padding: "5px 20px",
            color:
              ecommerceActiveTab === 0 ? props.primaryColor : "rgba(0,0,0,0.4)",
          }}
          onClick={() => setEcommerceActiveTab(0)}
        >
          Commerce Website
        </div>
        {/* <div
          className="mui-button"
          style={{
            fontSize: "10px",
            padding: "5px 20px",
            color:
              ecommerceActiveTab === 1 ? props.primaryColor : "rgba(0,0,0,0.4)",
          }}
          onClick={() => setEcommerceActiveTab(1)}
        >
          Eazy-link
        </div> */}
        <div
          className="mui-button"
          style={{
            fontSize: "10px",
            padding: "5px 20px",
            color:
              ecommerceActiveTab === 2 ? props.primaryColor : "rgba(0,0,0,0.4)",
          }}
          onClick={() => setEcommerceActiveTab(2)}
        >
          Shipping
        </div>
        <div
          className="mui-button"
          style={{
            fontSize: "10px",
            padding: "5px 20px",
            color:
              ecommerceActiveTab === 3 ? props.primaryColor : "rgba(0,0,0,0.4)",
          }}
          onClick={() => setEcommerceActiveTab(3)}
        >
          Payment
        </div>
        <div
          className="mui-button"
          style={{
            fontSize: "10px",
            padding: "5px 20px",
            color:
              ecommerceActiveTab === 4 ? props.primaryColor : "rgba(0,0,0,0.4)",
          }}
          onClick={() => setEcommerceActiveTab(4)}
        >
          Emails
        </div>
      </div>
      {ecommerceActiveTab === 3 ? (
        <div style={{}}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                fontSize: "20px",
                color: "rgba(0, 0, 0,0.7)",
                fontWeight: "600",
                marginTop: "15px",
                marginBottom: "10px",
              }}
            >
              Payment gateway setup
            </div>
            <div
              style={{
                width: "50px",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              {stripeAccountDetials ? (
                stripeAccountDetials.payouts_enabled === true ? (
                  <div
                    style={{
                      height: "6px",
                      width: "6px",
                      borderRadius: "10px",
                      boxShadow: "0px 0px 7px green",
                      background: "green",
                      marginLeft: "8px",
                      marginBottom: "8px",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      height: "6px",
                      width: "6px",
                      borderRadius: "10px",
                      boxShadow: "0px 0px 7px orange",
                      background: "orange",
                      marginLeft: "8px",
                      marginBottom: "8px",
                    }}
                  />
                )
              ) : (
                <div
                  style={{
                    height: "6px",
                    width: "6px",
                    borderRadius: "10px",
                    boxShadow: "0px 0px 7px red",
                    background: "red",
                    marginLeft: "8px",
                    marginBottom: "8px",
                  }}
                />
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              gap: "15px",
              fontSize: "11px",
              padding: "5px 20px 5px 0px",
              color: "rgba(0,0,0,0.5)",
              borderTop: "1px solid rgba(0,0,0,0.2)",
            }}
          >
            {stripeAccountDetials ? (
              <div>
                {stripeAccountDetials.payouts_enabled === true ? (
                  <div
                    style={{
                      fontSize: "18px",
                      padding: "5px 0px 0px 0px",
                      color: "rgba(0,0,0,0.7)",
                      fontWeight: 700,
                    }}
                  >
                    You're all set!
                  </div>
                ) : (
                  <div
                    style={{
                      fontSize: "18px",
                      padding: "5px 0px 0px 0px",
                      color: "rgba(0,0,0,0.7)",
                      fontWeight: 700,
                    }}
                  >
                    You're almost ready
                  </div>
                )}

                {stripeAccountDetials.payouts_enabled === true ? (
                  <div style={{ marginTop: "-5px" }}>
                    Your websites is now ready to receive payments
                  </div>
                ) : (
                  <div style={{ marginTop: "-5px" }}>
                    You still have pending document requested from stripe.
                  </div>
                )}
                <div
                  style={{
                    fontSize: "10px",
                    padding: "5px 0px",
                    color: "rgba(0,0,0,0.7)",
                    fontWeight: 700,
                  }}
                >
                  Stripe Account ID: {stripeAccountDetials.id}
                </div>
                {stripeAccountDetials.payouts_enabled === true ? null : (
                  <div style={{ display: "flex" }}>
                    <div
                      onClick={() => {
                        const token = Cookies.get("token");
                        const handleUpdateStripe = async () => {
                          try {
                            const response = await fetch(
                              "https://ww1.premelo.com/api/stripe/create-stripe",
                              {
                                method: "POST",
                                headers: {
                                  Authorization: `Bearer ${token}`,
                                  "Content-Type": "application/json",
                                },
                              }
                            );
                            const json = await response.json();

                            window.open(json.account_link.url, "_blank");
                          } catch (error) {
                            console.log(error);
                          }
                        };
                        handleUpdateStripe();
                      }}
                      className="mui-button"
                      style={{
                        fontSize: "10px",
                        padding: "8px 30px",
                        color: "white",
                        background: props.primaryColor,
                        fontWeight: "600",
                        marginTop: "15px",
                        marginBottom: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      Complete setup
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <div>Setup payment gateway</div>
                <div>
                  With premelo's partnership along Stripe user's can now easily
                  setup their stripe account with breeze.
                </div>
                <div style={{ display: "flex" }}>
                  <div
                    onClick={() => {
                      const token = Cookies.get("token");
                      const handleSetupStripeAccount = async () => {
                        try {
                          const response = await fetch(
                            "https://ww1.premelo.com/api/stripe/create-stripe",
                            {
                              method: "POST",
                              headers: {
                                Authorization: `Bearer ${token}`,
                                "Content-Type": "application/json",
                              },
                            }
                          );
                          const json = await response.json();

                          window.open(json.account_link.url, "_blank");
                        } catch (error) {
                          console.log(error);
                        }
                      };
                      handleSetupStripeAccount();
                    }}
                    className="mui-button"
                    style={{
                      fontSize: "10px",
                      padding: "8px 30px",
                      color: "white",
                      background: props.primaryColor,
                      fontWeight: "600",
                      marginTop: "15px",
                      marginBottom: "10px",
                      borderRadius: "10px",
                    }}
                  >
                    Setup now
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : ecommerceActiveTab === 0 ? (
        <div style={{}}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                fontSize: "20px",
                color: "rgba(0, 0, 0,0.7)",
                fontWeight: "600",
                marginTop: "15px",
                marginBottom: "10px",
              }}
            >
              E-commerce settings
            </div>
            {commerceChanged ? (
              <div
                onClick={() => {
                  setCommerceChanged(false);
                  const token = Cookies.get("token");
                  const handleCurrencyUpdate = async () => {
                    try {
                      const response = await fetch(
                        "https://ww1.premelo.com/api/stripe/create-update-cannds",
                        {
                          method: "POST",
                          headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({ currency: currencyDetails }),
                        }
                      );
                      const json = await response.json();
                      console.log(json);
                    } catch (error) {
                      console.log(error);
                    }
                  };
                  handleCurrencyUpdate();
                }}
                className="mui-button"
                style={{
                  fontSize: "10px",
                  padding: "8px 20px",
                  color: "white",
                  background: props.primaryColor,
                  fontWeight: "600",
                  marginTop: "15px",
                  marginBottom: "10px",
                  borderRadius: "10px",
                }}
              >
                Save changes
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              gap: "15px",
              fontSize: "11px",
              padding: "5px 20px 5px 0px",
              color: "rgba(0,0,0,0.5)",
              borderTop: "1px solid rgba(0,0,0,0.2)",
            }}
          >
            <div style={{ width: "130px" }}>Currency:</div>
            <select
              value={currencyDetails.currency}
              onChange={(e) => {
                setCommerceChanged(true);
                setCurrencyDetails({
                  ...currencyDetails,
                  currency: e.target.value,
                });
              }}
              style={{
                fontSize: "11px",
                width: "300px",
                borderRadius: "10px",
                border: "1px solid rgba(125, 125, 125,0.5)",
                padding: "7px",
                marginRight: "15px",
              }}
            >
              {Object.keys(CurrencyList.getAll("en_US")).map((currencyCode) => {
                const currency = CurrencyList.getAll("en_US")[currencyCode];
                return (
                  <option value={currencyCode} key={currencyCode}>
                    {currency.name} ({currencyCode})
                  </option>
                );
              })}
            </select>
          </div>
          <div
            style={{
              display: "flex",
              gap: "15px",
              fontSize: "11px",
              padding: "5px 20px 5px 0px",
              color: "rgba(0,0,0,0.5)",
            }}
          >
            <div style={{ width: "130px" }}>Currency display:</div>
            <input
              value={currencyDetails.display}
              onChange={(e) => {
                setCommerceChanged(true);
                setCurrencyDetails({
                  ...currencyDetails,
                  display: e.target.value,
                });
              }}
              style={{
                fontSize: "11px",
                width: "50px",
                borderRadius: "10px",
                border: "1px solid rgba(125, 125, 125,0.5)",
                padding: "7px",
                marginRight: "15px",
              }}
              placeholder="USD"
            />
          </div>
        </div>
      ) : ecommerceActiveTab === 2 ? (
        <div style={{}}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                fontSize: "20px",
                color: "rgba(0, 0, 0,0.7)",
                fontWeight: "600",
                marginTop: "15px",
                marginBottom: "10px",
              }}
            >
              Shipping Zones
            </div>
            {shippingChanged ? (
              <div
                className="mui-button"
                onClick={() => {
                  setShippingChanged(false);
                  const token = Cookies.get("token");
                  const handleShippingUpdate = async () => {
                    try {
                      const response = await fetch(
                        "https://ww1.premelo.com/api/stripe/create-update-cannds",
                        {
                          method: "POST",
                          headers: {
                            Authorization: `Bearer ${token}`,
                            "Content-Type": "application/json",
                          },
                          body: JSON.stringify({ shipping: shippingDetails }),
                        }
                      );
                      const json = await response.json();
                      console.log(json);
                    } catch (error) {
                      console.log(error);
                    }
                  };
                  handleShippingUpdate();
                }}
                style={{
                  fontSize: "10px",
                  padding: "8px 20px",
                  color: "white",
                  background: props.primaryColor,
                  fontWeight: "600",
                  marginTop: "15px",
                  marginBottom: "10px",
                  borderRadius: "10px",
                }}
              >
                Save changes
              </div>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              fontSize: "11px",
              padding: "5px 20px",
              color: "rgba(0,0,0,0.5)",
              borderTop: "1px solid rgba(0,0,0,0.2)",
              borderBottom: "1px solid rgba(0,0,0,0.2)",
            }}
          >
            <div style={{ width: "30%" }}>Zones</div>
            <div style={{ width: "40%" }}>Regions</div>
            <div style={{ width: "30%" }}>Rate</div>
          </div>
          {shippingDetails.map((shipping, shippingIndex) => (
            <div
              style={{
                borderBottom: "1px solid rgba(0,0,0,0.2)",
              }}
            >
              <div
                key={shippingIndex}
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  fontSize: "14px",
                  padding: "10px 20px",
                  color: "rgba(0,0,0,0.5)",
                }}
              >
                <div style={{ width: "30%" }}>
                  {shipping.name === "Every country" ? (
                    shippingDetails.length > 1 ? (
                      "Rest of the world"
                    ) : (
                      "Every country"
                    )
                  ) : (
                    <select
                      placeholder="Country"
                      value={shipping.name}
                      onChange={(e) => {
                        const updatingCountry = [...shippingDetails];
                        updatingCountry[shippingIndex].name = e.target.value;
                        setShippingDetails(updatingCountry);
                        setShippingChanged(true);
                      }}
                      style={{
                        fontSize: "11px",
                        width: "50%",
                        borderRadius: "10px",
                        border: "1px solid rgba(125, 125, 125,0.5)",
                        padding: "7px",
                      }}
                    >
                      {Country.getAllCountries().map((country) => (
                        <option value={country.isoCode}>{country.name}</option>
                      ))}
                    </select>
                  )}
                </div>
                <div style={{ width: "40%" }}>
                  {shipping.region.length > 0 ? "Other states" : "All states"}
                  {shippingIndex !== 0 ? (
                    <div
                      className="mui-button"
                      onClick={() => {
                        const newRegion = {
                          region: "",
                          type: "Flat rate",
                          value: 10,
                        };
                        const addingToZone = [...shippingDetails];
                        addingToZone[shippingIndex].region.push(newRegion);
                        setShippingDetails(addingToZone);
                        setShippingChanged(true);
                      }}
                      style={{
                        textDecoration: "underline",
                        fontSize: "9px",
                        color: "rgba(0,0,0,0.7)",
                      }}
                    >
                      Add custom region
                    </div>
                  ) : null}
                </div>
                <div style={{ width: "30%" }}>
                  {shipping.region.length === 0 ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <select
                        value={shipping.type}
                        onChange={(e) => {
                          const updatingCountry = [...shippingDetails];
                          updatingCountry[shippingIndex].type = e.target.value;
                          setShippingDetails(updatingCountry);
                          setShippingChanged(true);
                        }}
                        style={{
                          fontSize: "11px",
                          width: "35%",
                          borderRadius: "10px",
                          border: "1px solid rgba(125, 125, 125,0.5)",
                          padding: "7px",
                          marginRight: "15px",
                        }}
                      >
                        <option value={"Flat rate"}>Flat rate</option>
                        <option value={"Free shipping"}>Free shipping</option>
                        <option value={"Disable purchase"}>
                          Disable purchase
                        </option>
                      </select>
                      {shipping.type === "Flat rate" ? (
                        <>
                          $
                          <input
                            value={shipping.value}
                            onChange={(e) => {
                              const updatingCountry = [...shippingDetails];
                              updatingCountry[shippingIndex].value = parseFloat(
                                e.target.value
                              );
                              setShippingDetails(updatingCountry);
                              setShippingChanged(true);
                            }}
                            style={{
                              fontSize: "11px",
                              width: "25%",
                              borderRadius: "10px",
                              border: "1px solid rgba(125, 125, 125,0.5)",
                              padding: "7px",
                              marginLeft: "5px",
                            }}
                          />
                        </>
                      ) : null}
                    </div>
                  ) : (
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <select
                          value={shipping.type}
                          onChange={(e) => {
                            const updatingCountry = [...shippingDetails];
                            updatingCountry[shippingIndex].type =
                              e.target.value;
                            setShippingDetails(updatingCountry);
                            setShippingChanged(true);
                          }}
                          style={{
                            fontSize: "11px",
                            width: "35%",
                            borderRadius: "10px",
                            border: "1px solid rgba(125, 125, 125,0.5)",
                            padding: "7px",
                            marginRight: "15px",
                          }}
                        >
                          <option value={"Flat rate"}>Flat rate</option>
                          <option value={"Free shipping"}>Free shipping</option>
                          <option value={"Disable purchase"}>
                            Disable purchase
                          </option>
                        </select>
                        {shipping.type === "Flat rate" ? (
                          <>
                            $
                            <input
                              value={shipping.value}
                              onChange={(e) => {
                                const updatingCountry = [...shippingDetails];
                                updatingCountry[shippingIndex].value =
                                  parseFloat(e.target.value);
                                setShippingDetails(updatingCountry);
                                setShippingChanged(true);
                              }}
                              style={{
                                fontSize: "11px",
                                width: "25%",
                                borderRadius: "10px",
                                border: "1px solid rgba(125, 125, 125,0.5)",
                                padding: "7px",
                                marginLeft: "5px",
                              }}
                            />
                          </>
                        ) : null}
                      </div>
                      <div
                        style={{
                          fontSize: "9px",
                          color: "rgba(0,0,0,0.7)",
                        }}
                      >
                        Applies to states not defined
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {shipping.region.map((regions, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    fontSize: "14px",
                    padding: "0px 20px 10px 20px",
                    color: "rgba(0,0,0,0.5)",
                  }}
                >
                  <div style={{ width: "30%" }}></div>
                  <div
                    style={{
                      width: "40%",
                      borderTop: "1px solid rgba(0,0,0,0.2)",
                      paddingTop: "10px",
                    }}
                  >
                    <select
                      placeholder="States"
                      value={regions.region}
                      onChange={(e) => {
                        const updatingState = [...shippingDetails];
                        updatingState[shippingIndex].region[index].region =
                          e.target.value;
                        setShippingDetails(updatingState);
                        setShippingChanged(true);
                      }}
                      style={{
                        fontSize: "11px",
                        width: "25%",
                        borderRadius: "10px",
                        border: "1px solid rgba(125, 125, 125,0.5)",
                        padding: "7px",
                      }}
                    >
                      {State.getStatesOfCountry(shipping.name).map((state) => (
                        <option value={state.isoCode}>{state.name}</option>
                      ))}
                    </select>
                  </div>
                  <div
                    style={{
                      width: "30%",
                      borderTop: "1px solid rgba(0,0,0,0.2)",
                      paddingTop: "10px",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <select
                        value={regions.type}
                        onChange={(e) => {
                          const updatingState = [...shippingDetails];
                          updatingState[shippingIndex].region[index].type =
                            e.target.value;
                          setShippingDetails(updatingState);
                          setShippingChanged(true);
                        }}
                        style={{
                          fontSize: "11px",
                          width: "35%",
                          borderRadius: "10px",
                          border: "1px solid rgba(125, 125, 125,0.5)",
                          padding: "7px",
                          marginRight: "15px",
                        }}
                      >
                        <option value={"Flat rate"}>Flat rate</option>
                        <option value={"Free shipping"}>Free shipping</option>
                        <option value={"Disable purchase"}>
                          Disable purchase
                        </option>
                      </select>
                      {regions.type === "Flat rate" ? (
                        <>
                          $
                          <input
                            value={regions.value}
                            onChange={(e) => {
                              const updatingState = [...shippingDetails];
                              updatingState[shippingIndex].region[index].value =
                                parseFloat(e.target.value);
                              setShippingDetails(updatingState);
                              setShippingChanged(true);
                            }}
                            style={{
                              fontSize: "11px",
                              width: "25%",
                              borderRadius: "10px",
                              border: "1px solid rgba(125, 125, 125,0.5)",
                              padding: "7px",
                              marginLeft: "5px",
                            }}
                          />
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
          <div style={{ display: "flex" }}>
            <div
              className="mui-button"
              style={{
                fontSize: "10px",
                padding: "8px 20px",
                color: "white",
                background: props.primaryColor,
                fontWeight: "600",
                marginTop: "15px",
                marginBottom: "10px",
                borderRadius: "10px",
              }}
              onClick={() => {
                const newZone = {
                  name: "",
                  region: [],
                  value: 10,
                  type: "Flat rate",
                };
                setShippingDetails([...shippingDetails, newZone]);
                setShippingChanged(true);
              }}
            >
              Add new zones
            </div>
          </div>
        </div>
      ) : ecommerceActiveTab === 4 ? (
        <div style={{ display: "flex", gap: "20px" }}>
          <div
            style={{ width: "25%", borderRight: "1px solid rgba(0,0,0,0.2)" }}
          >
            <div
              style={{
                fontSize: "20px",
                color: "rgba(0, 0, 0,0.7)",
                fontWeight: "600",
                marginTop: "15px",
              }}
            >
              Mail settings
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.4)",
                fontSize: "10px",
              }}
            >
              You're allowed to leave the settings blanks if you do not want to
              set it up.
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleUpdateEmailDetails();
              }}
            >
              <div style={{ marginTop: "15px" }}>
                <div style={{ fontSize: "10px", color: "rgba(0,0,0,0.4)" }}>
                  Mail Host
                </div>
                <div>
                  <input
                    className="placeholder_text"
                    value={email.mail_host}
                    onChange={(e) => {
                      setEmails({ ...email, mail_host: e.target.value });
                    }}
                    required
                    style={{
                      fontSize: "10px",
                      color: "rgba(0,0,0,0.8)",
                      width: "calc(80% - 20px)",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      outline: "none",
                      border: "1px solid lightgrey",
                    }}
                    placeholder="smtp.gmail.com"
                  />
                </div>
              </div>
              <div style={{ marginTop: "15px" }}>
                <div style={{ fontSize: "10px", color: "rgba(0,0,0,0.4)" }}>
                  Mail Port
                </div>
                <div>
                  <input
                    className="placeholder_text"
                    value={email.mail_port}
                    onChange={(e) => {
                      setEmails({ ...email, mail_port: e.target.value });
                    }}
                    required
                    style={{
                      fontSize: "10px",
                      color: "rgba(0,0,0,0.8)",
                      width: "calc(80% - 20px)",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      outline: "none",
                      border: "1px solid lightgrey",
                    }}
                    placeholder="587"
                  />
                </div>
              </div>
              <div style={{ marginTop: "15px" }}>
                <div style={{ fontSize: "10px", color: "rgba(0,0,0,0.4)" }}>
                  Mail Email
                </div>
                <div>
                  <input
                    className="placeholder_text"
                    value={email.mail_email}
                    onChange={(e) => {
                      setEmails({ ...email, mail_email: e.target.value });
                    }}
                    required
                    type="email"
                    style={{
                      fontSize: "10px",
                      color: "rgba(0,0,0,0.8)",
                      width: "calc(80% - 20px)",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      outline: "none",
                      border: "1px solid lightgrey",
                    }}
                    placeholder="noreply@premelo.com"
                  />
                </div>
              </div>
              <div style={{ marginTop: "15px" }}>
                <div style={{ fontSize: "10px", color: "rgba(0,0,0,0.4)" }}>
                  Mail Secure Password
                </div>
                <div>
                  <input
                    className="placeholder_text"
                    value={email.mail_password}
                    onChange={(e) => {
                      setEmails({ ...email, mail_password: e.target.value });
                    }}
                    required
                    type="password"
                    style={{
                      fontSize: "10px",
                      color: "rgba(0,0,0,0.8)",
                      width: "calc(80% - 20px)",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      outline: "none",
                      border: "1px solid lightgrey",
                    }}
                    placeholder="password"
                  />
                </div>
              </div>
              <div style={{ marginTop: "15px" }}>
                <div style={{ fontSize: "10px", color: "rgba(0,0,0,0.4)" }}>
                  Mail from name
                </div>
                <div>
                  <input
                    className="placeholder_text"
                    value={email.mail_name}
                    onChange={(e) => {
                      setEmails({ ...email, mail_name: e.target.value });
                    }}
                    required
                    style={{
                      fontSize: "10px",
                      color: "rgba(0,0,0,0.8)",
                      width: "calc(80% - 20px)",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      outline: "none",
                      border: "1px solid lightgrey",
                    }}
                    placeholder="Premelo"
                  />
                </div>
              </div>
              <button
              type="submit"
                className="mui-button"
                style={{
                  background: props.primaryColor,
                  padding: "5px 25px",
                  borderRadius: "5px",
                  color: "white",
                  fontSize: "15px",
                  fontWeight: 600,
                  border: "none",
                  outline: "none",
                  marginTop: "15px",
                }}
              >
                Save
              </button>
            </form>
          </div>
          <div style={{ width: "75%" }}>
            <div
              style={{
                fontSize: "20px",
                color: "rgba(0, 0, 0,0.7)",
                fontWeight: "600",
                marginTop: "15px",
              }}
            >
              Email triggers
            </div>
            <div
              style={{
                color: "rgba(0,0,0,0.4)",
                fontSize: "10px",
                borderBottom: "1px solid rgba(0,0,0,0.2)",
                paddingBottom: "15px",
              }}
            >
              Here is where you can customize the message that will be sent to
              your customer along every stage of their purchase to inform your
              customers
            </div>
            <div style={{ display: "flex", gap: "15px", marginTop: "10px" }}>
              <div style={{ width: "25%" }}>
                {email.emails.map((em, index) => (
                  <div
                    onClick={() => {
                      setActiveEmailIndex(index);
                    }}
                    className="mui-button"
                    key={index}
                    style={{
                      padding: "10px",
                      background:
                        activeEmailIndex === index
                          ? props.primaryColor
                          : "rgba(255, 255, 255, 0.8)",
                      borderRadius: "5px",
                      marginTop: "5px",
                      border: "1px solid lightgrey",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "13px",
                        fontWeight: 600,
                        color: activeEmailIndex === index ? "white" : "grey",
                      }}
                    >
                      {em.trigger}
                    </div>
                    <div
                      style={{
                        fontSize: "9px",
                        fontWeight: 400,
                        color:
                          activeEmailIndex === index
                            ? "white"
                            : "rgba(0,0,0,0.4)",
                      }}
                    >
                      {em.subject}
                    </div>
                  </div>
                ))}{inputHasChanged ?
                <div>
                <button
                onClick={(e)=>{e.preventDefault(); handleUpdateEmailDetails();}}
                  className="mui-button"
                  style={{
                    background: props.primaryColor,
                    padding: "5px 25px",
                    borderRadius: "5px",
                    color: "white",
                    fontSize: "15px",
                    fontWeight: 600,
                    border: "none",
                    outline: "none",
                    marginTop: "15px",
                  }}
                >
                  Save
                </button>
                  </div> : null}
              </div>
              <div
                style={{
                  width: "75%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div style={{ width: "80%" }}>
                  <input
                    value={email.emails[activeEmailIndex].subject || ""}
                    placeholder="Email Subject"
                    onChange={(e) => {
                      const updatingEmails = { ...email };
                      updatingEmails.emails[activeEmailIndex].subject =
                        e.target.value;
                      setEmails(updatingEmails);
                      setInputHasChanged(true)
                    }}
                    style={{
                      fontSize: "14px",
                      fontWeight: 600,
                      color: "rgba(0,0,0,0.8)",
                      textAlign: "left",
                      padding: "3px 10px",
                      borderRadius: "5px",
                      border: "1px solid lightgrey",
                      outline: "none",
                      marginBottom: "15px",
                      display: "flex",
                      width: "calc(100% - 20px)",
                      background: "transparent",
                    }}
                  />
                  <div
                    style={{
                      border: "1px solid lightgrey",
                      borderRadius: "5px",
                      paddingBottom: "30px",
                      overflow: "hidden",
                    }}
                  >
                    <input
                      id="imageInput2"
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        handleImageUpload2(e);
                        setInputHasChanged(true);
                      }}
                    />
                    <div style={{ position: "relative" }}>
                      {fileUploading ? (
                        <div
                          style={{
                            width: "100%",
                            height: "100px",
                            objectFit: "cover",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                          }}
                        >
                          <CircularProgress
                            color="primary"
                            size={50}
                            style={{}}
                          />
                        </div>
                      ) : email.emails[activeEmailIndex].header_image ? (
                        <img
                          className="header_image"
                          src={email.emails[activeEmailIndex].header_image}
                          style={{
                            width: "100%",
                            height: "100px",
                            objectFit: "cover",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                          }}
                        />
                      ) : (
                        <img
                          className="header_image"
                          src={
                            email.emails[activeEmailIndex].header_image
                              ? email.emails[activeEmailIndex].header_image
                              : imageReference
                          }
                          style={{
                            width: "100%",
                            height: "100px",
                            objectFit: "cover",
                            boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                          }}
                        />
                      )}
                      <div
                        className="header_upload mui-button"
                        onClick={handleClick2}
                        style={{
                          position: "absolute",
                          top: 8,
                          right: 8,
                          height: "25px",
                          width: "25px",
                          borderRadius: "50%",
                          background: "white",
                          border: "1px solid lightgrey",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <FileUploadIcon
                          style={{ color: "grey", fontSize: "20px" }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "70%",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            position: "relative",
                            justifyContent: "center",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <textarea
                            className="email_textarea"
                            ref={textareaRef}
                            value={
                              email.emails[activeEmailIndex].content_title || ""
                            }
                            placeholder="Email Subject"
                            onChange={(e) => {
                              handleTextareaChange();
                              const updatingEmails = { ...email };
                              updatingEmails.emails[
                                activeEmailIndex
                              ].content_title = e.target.value;
                              setEmails(updatingEmails);
                              setInputHasChanged(true);
                            }}
                            onBlur={() => setEditingTitle(false)}
                            style={{
                              fontSize: "19px",
                              fontWeight: 600,
                              color: "rgba(0,0,0,0.8)",
                              textAlign: "center",
                              marginTop: "40px",
                              background: "transparent",
                              resize: "none",
                              padding: editingTitle ? "5px" : "0px",
                              overflow: "hidden",
                              paddingBottom: "0px",
                              borderRadius: "5px",
                              textDecoration: "none",
                              height: "auto",
                              outline: "1px solid lightgrey",
                              transform: editingTitle
                                ? "none"
                                : "translate(50%, 0)",
                              width: editingTitle ? "calc(100% - 20px)" : "0px",
                              opacity: editingTitle ? 1 : 0,
                            }}
                          />
                          <div
                            className="email_textarea"
                            onClick={() => {
                              setEditingTitle(true);
                              textareaRef.current.focus();
                            }}
                            style={{
                              display: editingTitle ? "none" : "flex",
                              justifyContent: "center",
                              fontSize: "19px",
                              fontWeight: 600,
                              color: "rgba(0,0,0,0.8)",
                              textAlign: "center",
                              marginTop: "40px",
                              background: "transparent",
                              width: "calc(100% - 20px)",
                              resize: "none",
                              padding: "5px",
                              overflow: "hidden",
                              paddingBottom: "0px",
                              borderRadius: "5px",
                              transform: editingTitle
                                ? "translate(50%, 0)"
                                : "none",
                              width: editingTitle ? "0px" : "calc(100% - 20px)",
                            }}
                          >
                            {email.emails[activeEmailIndex].content_title ||
                              "Email title"}
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            position: "relative",
                            justifyContent: "center",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <textarea
                            className="email_textarea"
                            ref={textareaRef2}
                            value={
                              email.emails[activeEmailIndex]
                                .content_description || ""
                            }
                            placeholder="Email Description"
                            onChange={(e) => {
                              handleTextareaChange2();
                              const updatingEmails = { ...email };
                              updatingEmails.emails[
                                activeEmailIndex
                              ].content_description = e.target.value;
                              setEmails(updatingEmails);
                              setInputHasChanged(true);
                            }}
                            onBlur={() => setEditingDescription(false)}
                            style={{
                              fontSize: "10px",
                              fontWeight: 400,
                              color: "rgba(0,0,0,0.8)",
                              textAlign: "center",
                              marginBottom: "40px",
                              background: "transparent",
                              resize: "none",
                              padding: editingDescription ? "5px" : "0px",
                              overflow: "hidden",
                              paddingBottom: "0px",
                              borderRadius: "5px",
                              textDecoration: "none",
                              height: "auto",
                              outline: "1px solid lightgrey",
                              transform: editingDescription
                                ? "none"
                                : "translate(50%, 0)",
                              width: editingDescription
                                ? "calc(100% - 20px)"
                                : "0px",
                              opacity: editingDescription ? 1 : 0,
                            }}
                          />
                          <div
                            className="email_textarea"
                            onClick={() => {
                              setEditingDescription(true);
                              textareaRef2.current.focus();
                            }}
                            style={{
                              display: editingDescription ? "none" : "flex",
                              justifyContent: "center",
                              fontSize: "10px",
                              fontWeight: 400,
                              color: "rgba(0,0,0,0.8)",
                              textAlign: "center",
                              marginBottom: "40px",
                              background: "transparent",
                              width: "calc(100% - 20px)",
                              resize: "none",
                              padding: "5px",
                              overflow: "hidden",
                              paddingBottom: "0px",
                              borderRadius: "5px",
                              transform: editingDescription
                                ? "translate(50%, 0)"
                                : "none",
                              width: editingDescription
                                ? "0px"
                                : "calc(100% - 20px)",
                            }}
                          >
                            {email.emails[activeEmailIndex]
                              .content_description ||
                              "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."}
                          </div>
                        </div>
                        {/* <textarea
                          className="email_textarea"
                          ref={textareaRef2}
                          value={
                            email.emails[activeEmailIndex]
                              .content_description || ""
                          }
                          placeholder="Email Subject"
                          onChange={(e) => {
                            handleTextareaChange2();
                            const updatingEmails = { ...email };
                            updatingEmails.emails[
                              activeEmailIndex
                            ].content_description = e.target.value;
                            setEmails(updatingEmails);
                          }}
                          style={{
                            fontSize: "10px",
                            fontWeight: 400,
                            color: "rgba(0,0,0,0.8)",
                            textAlign: "center",
                            marginBottom: "40px",
                            background: "transparent",
                            width: "calc(100% - 20px)",
                            resize: "none",
                            padding: "5px",
                            overflow: "hidden",
                            borderRadius: "5px",
                            textDecoration: "none",
                            height: "auto",
                          }}
                        /> */}
                        <hr
                          style={{
                            background: "lightgrey",
                            width: "100%",
                            height: "1px",
                            outline: "none",
                            border: "none",
                          }}
                        />
                        <div
                          style={{
                            display: "flex",
                            position: "relative",
                            justifyContent: "center",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <input
                            className="email_textarea"
                            ref={textareaRef3}
                            value={email.emails[activeEmailIndex].footer || ""}
                            placeholder="Email Description"
                            onChange={(e) => {
                              const updatingEmails = { ...email };
                              updatingEmails.emails[activeEmailIndex].footer =
                                e.target.value;
                              setEmails(updatingEmails);
                              setInputHasChanged(true);
                            }}
                            onBlur={() => setEditingFooter(false)}
                            style={{
                              fontSize: "8px",
                              fontWeight: 400,
                              color: "rgba(0,0,0,0.8)",
                              textAlign: "center",
                              background: "transparent",
                              resize: "none",
                              padding: editingFooter ? "5px" : "0px",
                              overflow: "hidden",
                              paddingBottom: "0px",
                              borderRadius: "5px",
                              textDecoration: "none",
                              outline: "1px solid lightgrey",
                              transform: editingFooter
                                ? "none"
                                : "translate(50%, 0)",
                              width: editingFooter
                                ? "calc(100% - 20px)"
                                : "0px",
                              opacity: editingFooter ? 1 : 0,
                            }}
                          />
                          <div
                            className="email_textarea"
                            onClick={() => {
                              setEditingFooter(true);
                              textareaRef3.current.focus();
                            }}
                            style={{
                              display: editingFooter ? "none" : "flex",
                              justifyContent: "center",
                              fontSize: "8px",
                              fontWeight: 400,
                              color: "rgba(0,0,0,0.8)",
                              textAlign: "center",
                              background: "transparent",
                              width: "calc(100% - 20px)",
                              resize: "none",
                              padding: "5px",
                              overflow: "hidden",
                              paddingBottom: "0px",
                              borderRadius: "5px",
                              transform: editingFooter
                                ? "translate(50%, 0)"
                                : "none",
                              width: editingFooter
                                ? "0px"
                                : "calc(100% - 20px)",
                            }}
                          >
                            {email.emails[activeEmailIndex].footer ||
                              "Build with premelo"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
  const [copied, setCopied] = useState(false);
  const handleCopyClick = () => {
    navigator.clipboard.writeText(domain);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  const handleCopyNotClick = () => {
    setCopied(false);
  };
  const [activeTab, setActiveTab] = useState();

  const [profileTab, setProfileTab] = useState(true);
  const [teamTab, setTeamTab] = useState(false);
  const [customizationTab, setCustomizationTab] = useState(false);
  const [ecommerceTab, setEcommerceTab] = useState(false);

  const handleChangeProfileTab = () => {
    setProfileTab(true);
    setTeamTab(false);
    setCustomizationTab(false);
    setEcommerceTab(false);
  };
  const handleChangeTeamTab = () => {
    setProfileTab(false);
    setTeamTab(true);
    setCustomizationTab(false);
    setEcommerceTab(false);
  };
  const handleChangeCustomizationTab = () => {
    setProfileTab(false);
    setTeamTab(false);
    setCustomizationTab(true);
    setEcommerceTab(false);
  };
  const handleChangeEcommerceTab = () => {
    setProfileTab(false);
    setTeamTab(false);
    setCustomizationTab(false);
    setEcommerceTab(true);
  };

  return (
    <div style={{}}>
      <div
        style={{
          display: "flex",
          borderBottom: "1px solid rgba(0,0,0,0.1)",
          alignItems: "flex-end",
          background: "rgba(255,255,255,0.5)",
          backdropFilter: "blur(20px)",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
          padding: "30px 0px 10px 0px",
        }}
      >
        <div
          style={{
            color: props.primaryColor,
            fontSize: "25px",
            fontWeight: "600",
            marginLeft: "20px",
            marginRight: "30px",
          }}
        >
          Settings
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            className="mui-button"
            onClick={() => handleChangeProfileTab()}
            style={{
              marginRight: "40px",
              color: "rgba(0,0,0,0.4)",
              marginLeft: "20px",
              marginBottom: "10px",
              fontSize: "10px",
              color: profileTab ? props.primaryColor : "grey",
            }}
          >
            Profile
          </div>
          <div
            className="mui-button"
            onClick={() => handleChangeTeamTab()}
            style={{
              marginRight: "40px",
              color: "rgba(0,0,0,0.4)",
              marginBottom: "10px",
              fontSize: "10px",
              color: teamTab ? props.primaryColor : "grey",
            }}
          >
            Team
          </div>
          {props.isAdmin ? (
            <div
              className="mui-button"
              onClick={() => handleChangeCustomizationTab()}
              style={{
                marginRight: "40px",
                color: "rgba(0,0,0,0.4)",
                marginBottom: "10px",
                fontSize: "10px",
                color: customizationTab ? props.primaryColor : "grey",
              }}
            >
              Customization
            </div>
          ) : null}
          <div
            className="mui-button"
            onClick={() => handleChangeEcommerceTab()}
            style={{
              marginRight: "40px",
              color: "rgba(0,0,0,0.4)",
              marginBottom: "10px",
              fontSize: "10px",
              color: ecommerceTab ? props.primaryColor : "grey",
            }}
          >
            Ecommerce
          </div>
        </div>
      </div>
      <div
        style={{
          height: "85vh",
          width: "calc(100% - 30px)",
          padding: "0px 15px",
        }}
      >
        <div
          className="to-do-scroll"
          style={{
            height: "calc(100% - 30px)",
            padding: "15px",
            width: "calc(100% - 30px)",
            marginTop: "1.5%",
            background: "rgba(255,255,255,0.4)",
            backdropFilter: "blur(10px)",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
            borderRadius: "10px",
          }}
        >
          <div>
            {profileTab ? profileContent : null}
            {teamTab ? teamContent : null}
            {customizationTab ? customizationContent : null}
            {ecommerceTab ? ecommerceContent : null}
          </div>
        </div>
      </div>
      <Modal
        isOpen={paymentContentFormIsOpen}
        onRequestClose={() => setPaymentContentFormIsOpen(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30%",
            height: availableUsers.length >= userCount ? "18.5%" : "26%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "white",
            boxShadow: "none",
            borderRadius: "10px",
            border: "none",
            overflow: "hidden",
          },
        }}
      >
        {availableUsers.length >= userCount ? (
          <div
            style={{
              padding: "20px",
              display: "flex",
              alignItems: "center",
              height: "75%",
            }}
          >
            <div style={{ width: "30%" }}>
              <RunningWithErrorsIcon
                style={{ fontSize: "90px", color: primaryColor }}
              />
            </div>
            <div>
              <div
                style={{
                  fontSize: "20px",
                  color: "rgba(0, 0, 0,0.7)",
                  fontWeight: "600",
                }}
              >
                User limit reached.
              </div>
              <div style={{ fontSize: "12px", color: "rgba(0,0,0,0.6)" }}>
                To add more users, please reachout to your admin to upgrade your
                account.
              </div>
            </div>
          </div>
        ) : (
          <div style={{ padding: "20px" }}>
            <div
              style={{
                fontSize: "20px",
                color: "rgba(0, 0, 0,0.7)",
                fontWeight: "600",
              }}
            >
              Inviting your team
            </div>
            <div style={{ fontSize: "12px", color: "rgba(0,0,0,0.6)" }}>
              is now simpler!
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  border: "1px solid rgba(0,0,0,0.2)",
                  color: "rgba(0,0,0,0.6)",
                  padding: "6px",
                  borderRadius: "10px",
                  marginTop: "10px",
                  width: "75%",
                  marginRight: "20px",
                  overflow: "hidden",
                  fontSize: "12px",
                }}
              >
                {domain}
              </div>
              {copied ? (
                <div
                  className="mui-button"
                  style={{
                    padding: "6px 25px",
                    background: "#28993d",
                    borderRadius: "10px",
                    color: "white",
                    marginTop: "20px",
                    width: "18%",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={handleCopyNotClick}
                >
                  <ContentCopyIcon
                    style={{ fontSize: "15px", marginRight: "7px" }}
                  />
                  Copied!
                </div>
              ) : (
                <div
                  className="mui-button"
                  style={{
                    padding: "6px 25px",
                    background: props.primaryColor,
                    borderRadius: "10px",
                    color: "white",
                    marginTop: "20px",
                    width: "18%",
                    textAlign: "center",
                  }}
                  onClick={handleCopyClick}
                >
                  Copy
                </div>
              )}
            </div>
            <div
              style={{
                fontSize: "12px",
                color: "rgba(0,0,0,0.6)",
                marginTop: "10px",
              }}
            >
              Hit copy and share your link to invite new team members.
            </div>
          </div>
        )}
      </Modal>
      <Modal
        isOpen={dnsZoneIsOpen}
        onRequestClose={() => setDnsZoneIsOpen(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30%",
            height: "37%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "white",
            boxShadow: "none",
            borderRadius: "10px",
            border: "none",
            overflow: "hidden",
          },
        }}
      >
        <div style={{ padding: "20px" }}>
          <div
            style={{
              fontSize: "20px",
              color: "rgba(0, 0, 0,0.7)",
              fontWeight: "600",
            }}
          >
            Just one more step.
          </div>
          <div style={{ fontSize: "10px", color: "rgba(0,0,0,0.6)" }}>
            head to your domain's DNS zone and add this records.
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "left",
              width: "92%",
              marginTop: "14px",
              padding: "2px 15px",
            }}
          >
            <div
              style={{
                width: "26%",
                color: "rgba(0,0,0,0.7)",
                fontSize: "16px",
              }}
            >
              Type
            </div>
            <div
              style={{
                width: "26%",
                color: "rgba(0,0,0,0.7)",
                fontSize: "16px",
              }}
            >
              Host
            </div>
            <div
              style={{
                width: "48%",
                color: "rgba(0,0,0,0.7)",
                fontSize: "16px",
              }}
            >
              Value
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "left",
              width: "92%",
              background: `${primaryColor}1A`,
              borderRadius: "12px",
              padding: "5px 15px",
            }}
          >
            <div
              style={{
                width: "26%",
                color: "rgba(0,0,0,0.7)",
                fontSize: "14px",
              }}
            >
              A
            </div>
            <div
              style={{
                width: "26%",
                color: "rgba(0,0,0,0.7)",
                fontSize: "14px",
              }}
            >
              @
            </div>
            <div
              style={{
                width: "48%",
                color: "rgba(0,0,0,0.7)",
                fontSize: "14px",
              }}
            >
              76.76.21.21
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              textAlign: "left",
              width: "92%",
              background: `${primaryColor}1A`,
              borderRadius: "12px",
              marginTop: "10px",
              padding: "5px 15px",
            }}
          >
            <div
              style={{
                width: "26%",
                color: "rgba(0,0,0,0.7)",
                fontSize: "14px",
              }}
            >
              CNAME
            </div>
            <div
              style={{
                width: "26%",
                color: "rgba(0,0,0,0.7)",
                fontSize: "14px",
              }}
            >
              www
            </div>
            <div
              style={{
                width: "48%",
                color: "rgba(0,0,0,0.7)",
                fontSize: "14px",
              }}
            >
              cname.vercel-dns.com.
            </div>
          </div>
          <div
            style={{
              fontSize: "10px",
              color: "rgba(0,0,0,0.6)",
              marginTop: "20px",
            }}
          >
            Once you've added the DNS records, your domain might take 24 hours
            to connect.
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Settings;
