import React, { useState, useEffect } from "react";
import logo from "./images/CS_Favicon-01.png";
import bg1 from "./images/bg_home_image.jpg";
import wbg1 from "./images/wider_bg.png";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SettingsIcon from "@mui/icons-material/Settings";
import GridViewIcon from "@mui/icons-material/GridView";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { SliderPicker } from "react-color";
import { ReactComponent as FolderIcon } from "./icons/folder.svg";
import "./App.css";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/CheckCircleOutline";
import CheckedIcon from "@mui/icons-material/CheckCircle";
import Calendar from "short-react-calendar";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import {
  Animator,
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  FadeIn,
  FadeOut,
  Move,
  MoveIn,
  MoveOut,
  Sticky,
  StickyIn,
  StickyOut,
  Zoom,
  ZoomIn,
  ZoomOut,
} from "react-scroll-motion";
import { ReactComponent as Test1 } from "./icons/testimonial logo-01.svg";
import { ReactComponent as Test2 } from "./icons/testimonial logo-02.svg";
import { ReactComponent as Test3 } from "./icons/testimonial logo-03.svg";
import { ReactComponent as Test4 } from "./icons/testimonial logo-04.svg";
import { ReactComponent as Test5 } from "./icons/testimonial logo-05.svg";
import dingSound from "./audio/Ding.wav";
import workflow from "./images/lightbulb workflow-01.png";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import points1 from "./images/image1of4.png";
import points2 from "./images/image2of4.png";
import points3 from "./images/image3of4.png";
import points4 from "./images/image4of4.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import HomepageMobile from "./HomepageMobile";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import StorefrontIcon from "@mui/icons-material/Storefront";
import InsightsIcon from "@mui/icons-material/Insights";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PollIcon from "@mui/icons-material/Poll";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { v4 as uuid } from "uuid";
import Cookies from "js-cookie";

const Homepage = () => {
  useEffect(() => {
    const recordAnalytics = async () => {
      const generateduuid = `${Date.now().toString()}` + `${uuid()}`;

      const userAgent = window.navigator.userAgent.toLowerCase();
      const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/.test(
        userAgent
      );
      try {
        // Check if the user has a cookie named 'user_id'
        const userId = Cookies.get("analytics");

        if (userId) {
          // Existing user logic
          console.log("Existing User");
        } else {
          // New user logic
          console.log("New User");

          // Set a cookie named 'analytics' with an expiration of 7 days
          Cookies.set("analytics", generateduuid, { expires: 7 });
        }

        const response = await fetch(
          "https://ww1.premelo.com/api/record-analytics",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              page: `home ${isMobile ? "mobile" : null} page`,
              user: userId ? "existing" : "unique",
            }),
          }
        );
        const json = await response.json();
        console.log(json);

        // Handle the response as needed
      } catch (error) {
        console.log(error);
      }
    };

    recordAnalytics();
  }, []);
  const navigate = useNavigate();
  const [primaryColor, setPrimaryColor] = useState("#5AB1BA");
  const [hideLogo, setHideLogo] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/.test(
      userAgent
    );
    setIsMobile(isMobile);
    const audio = new Audio(dingSound);
    const handleScroll = () => {
      setHideLogo(window.scrollY > 2300);
      if (window.scrollY === 2300) {
        audio.play();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  if (isMobile) {
    return <HomepageMobile />;
  }

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <div style={{ width: "100%" }}>
        <div style={{ position: "relative" }}>
          <img
            src={bg1}
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              objectFit: "cover",
            }}
          />
          <div
            style={{
              position: "relative",
              zIndex: 1,
              height: "7vh",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: "10px",
            }}
          >
            <div
              style={{
                height: "6vh",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                padding: "1px 0px",
                marginLeft: "40px",
              }}
            >
              <img src={logo} alt="Premelo logo" style={{ width: "42px" }} />
              <p
                style={{
                  fontSize: "20px",
                  fontWeight: "600",
                  color: "black",
                }}
              >
                Premelo
              </p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <button
                onClick={() => {
                  navigate("/");
                  window.scrollTo(0, 0);
                }}
                className="homepage-nav-buttons"
                style={{
                  height: "4.5vh",
                  padding: "0px 20px",
                  borderRadius: "50px",
                  border: "none",
                  background: "transparent",
                  fontWeight: "600",
                  fontSize: "12px",
                  color: "#d81b5e",
                }}
              >
                Home
              </button>
              <button
                onClick={() => {
                  navigate("/faq");
                  window.scrollTo(0, 0);
                }}
                className="homepage-nav-buttons"
                style={{
                  height: "4.5vh",
                  padding: "0px 20px",
                  borderRadius: "50px",
                  border: "none",
                  background: "transparent",
                  fontWeight: "600",
                  fontSize: "12px",
                }}
              >
                FAQ
              </button>
              <button
                onClick={() => {
                  navigate("/contactus");
                  window.scrollTo(0, 0);
                }}
                className="homepage-nav-buttons"
                style={{
                  height: "4.5vh",
                  padding: "0px 20px",
                  borderRadius: "50px",
                  border: "none",
                  background: "transparent",
                  fontWeight: "600",
                  fontSize: "12px",
                }}
              >
                Contact
              </button>
              <button
                onClick={() => {
                  navigate("/termsandcondition");
                  window.scrollTo(0, 0);
                }}
                className="homepage-nav-buttons"
                style={{
                  height: "4.5vh",
                  padding: "0px 20px",
                  borderRadius: "50px",
                  border: "none",
                  background: "transparent",
                  fontWeight: "600",
                  fontSize: "12px",
                }}
              >
                Terms & Privacy
              </button>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "40px",
              }}
            >
              <button
                className="homepage-nav-side-2"
                style={{
                  marginRight: "15px",
                  height: "4.5vh",
                  padding: "0px 20px",
                  borderRadius: "50px",
                  border: "none",
                  fontSize: "12px",
                }}
                onClick={() => {
                  navigate("/signup");
                  window.scrollTo(0, 0);
                }}
              >
                Sign Up
              </button>
              <button
                className="homepage-nav-side-3"
                style={{
                  height: "4.5vh",
                  padding: "0px 30px",
                  borderRadius: "50px",
                  border: "none",
                  fontSize: "12px",
                }}
                onClick={() => {
                  navigate("/login");
                  window.scrollTo(0, 0);
                }}
              >
                Login
              </button>
            </div>
          </div>
          <div
            style={{
              position: "relative",
              zIndex: 1,
              padding: "50px 0px 400px 0px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "80%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  fontSize: "40px",
                  fontWeight: "800",
                  color: "black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Start{" "}
                <div style={{ color: "#d81b5e", margin: "0px 10px" }}>
                  Digitalizing
                </div>{" "}
                today
              </div>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "300",
                  color: "black",
                  marginTop: "0px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <div style={{ width: "70%" }}>
                  Premelo was built with the intent to make digitalization a
                  free cause. And so it is now available to everybody.
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "20px",
                  marginTop: "20px",
                }}
              >
                <div
                  className="homepage-nav-side-3"
                  style={{
                    borderRadius: "10px",
                    border: "none",
                    marginTop: "20px",
                    fontSize: "14px",
                    padding: "10px",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                    width: "80px",
                    height: "110px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    navigate("/signup");
                    window.scrollTo(0, 0);
                  }}
                >
                  <WysiwygIcon style={{ fontSize: "60px" }} />
                  <div
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginBottom: "-2px",
                    }}
                  >
                    Build a
                  </div>
                  <div style={{ marginTop: "-5px" }}>Website</div>
                </div>
                <div
                  className="homepage-nav-side-3"
                  style={{
                    borderRadius: "10px",
                    border: "none",
                    marginTop: "20px",
                    fontSize: "14px",
                    padding: "10px",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                    width: "80px",
                    height: "110px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    navigate("/signup");
                    window.scrollTo(0, 0);
                  }}
                >
                  <StorefrontIcon style={{ fontSize: "60px" }} />
                  <div
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginBottom: "-2px",
                    }}
                  >
                    Sell
                  </div>
                  <div style={{ marginTop: "-5px" }}>Product</div>
                </div>
                <div
                  className="homepage-nav-side-3"
                  style={{
                    borderRadius: "10px",
                    border: "none",
                    marginTop: "20px",
                    fontSize: "14px",
                    padding: "10px",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                    width: "80px",
                    height: "110px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    navigate("/signup");
                    window.scrollTo(0, 0);
                  }}
                >
                  <InsightsIcon style={{ fontSize: "60px" }} />
                  <div
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginBottom: "-2px",
                    }}
                  >
                    Track
                  </div>
                  <div style={{ marginTop: "-5px" }}>Sales</div>
                </div>
                <div
                  className="homepage-nav-side-3"
                  style={{
                    borderRadius: "10px",
                    border: "none",
                    marginTop: "20px",
                    fontSize: "14px",
                    padding: "10px",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                    width: "80px",
                    height: "110px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    navigate("/signup");
                    window.scrollTo(0, 0);
                  }}
                >
                  <ManageAccountsIcon style={{ fontSize: "60px" }} />
                  <div
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginBottom: "-2px",
                    }}
                  >
                    Manage
                  </div>
                  <div style={{ marginTop: "-5px" }}>Tasks</div>
                </div>
                <div
                  className="homepage-nav-side-3"
                  style={{
                    borderRadius: "10px",
                    border: "none",
                    marginTop: "20px",
                    fontSize: "14px",
                    padding: "10px",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                    width: "80px",
                    height: "110px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    navigate("/signup");
                    window.scrollTo(0, 0);
                  }}
                >
                  <PollIcon style={{ fontSize: "60px" }} />
                  <div
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginBottom: "-2px",
                    }}
                  >
                    Make
                  </div>
                  <div style={{ marginTop: "-5px" }}>Survey</div>
                </div>
                <div
                  className="homepage-nav-side-3"
                  style={{
                    borderRadius: "10px",
                    border: "none",
                    marginTop: "20px",
                    fontSize: "14px",
                    padding: "10px",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                    width: "80px",
                    height: "110px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    navigate("/signup");
                    window.scrollTo(0, 0);
                  }}
                >
                  <CalendarMonthIcon style={{ fontSize: "60px" }} />
                  <div
                    style={{
                      fontSize: "10px",
                      marginTop: "5px",
                      marginBottom: "-2px",
                    }}
                  >
                    Manage
                  </div>
                  <div style={{ marginTop: "-5px" }}>Events</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            height: "800px",
            position: "relative",
          }}
        >
          <img src={wbg1} style={{ height: "100%", objectFit: "contain" }} />
          <div style={{ position: "absolute", left: "20%", top: 20 }}>
            <div
              style={{
                fontSize: "50px",
                fontWeight: "800",
                color: "black",
                marginTop: "80px",
              }}
            >
              Create Amazing websites
            </div>
            <div
              style={{
                fontSize: "40px",
                fontWeight: "800",
                color: "black",
                marginTop: "-15px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              quickly with readied{" "}
              <div style={{ color: "#d81b5e", margin: "0px 10px" }}>
                Templates.
              </div>
            </div>
            <div
              style={{
                fontSize: "25px",
                fontWeight: "800",
                color: "black",
                marginTop: "5px",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              And it's all free-to-use.
            </div>
          </div>
        </div>
        {/* first segment */}
        <div
          style={{
            width: "100%",
          }}
        >
          <div
            style={{
              width: "100%",
              textAlign: "center",
            }}
          >
            <div
              style={{
                fontSize: "40px",
                fontWeight: "800",
                color: "black",
                marginTop: "80px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Manage your team with
              <div style={{ color: "#d81b5e", marginLeft: "15px" }}>
                Premelo.
              </div>
            </div>
            <div
              style={{
                fontSize: "12px",
                fontWeight: "300",
                color: "black",
                marginTop: "0px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <div style={{ width: "70%" }}>
                Premelo creates a personalized and branded work management
                platform for companies. Connect your domain, customize your
                branding, and manage tasks with ease. The ultimate solution for
                streamlining workflow and improving productivity.
              </div>
            </div>
            <button
              className="homepage-nav-side-1"
              style={{
                // height: "4.5vh",
                padding: "10px 30px",
                borderRadius: "50px",
                border: "none",
                marginTop: "20px",
                fontSize: "14px",
              }}
              onClick={() => {
                navigate("/signup");
                window.scrollTo(0, 0);
              }}
            >
              Get started
            </button>
          </div>
          <div
            style={{
              width: "100%",
              height: "80vh",
              textAlign: "center",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "50px",
              }}
            >
              <div style={{ width: "60%" }}>
                <SliderPicker
                  style={{
                    boxShadow: "none",
                    marginBottom: "-100px",
                  }}
                  color={primaryColor}
                  onChange={(color) => setPrimaryColor(color.hex)}
                  onChangeComplete={(color) => setPrimaryColor(color.hex)}
                />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: "80vh",
                display: "flex",
                justifyContent: "center",
                marginTop: "50px",
                zIndex: 0,
              }}
            >
              <div
                style={{
                  background: "white",
                  width: "65%",
                  height: "80vh",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "space-between",
                  height: "100%",
                  boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                  borderRadius: "15px",
                }}
              >
                <div
                  style={{
                    width: "15%",
                    height: "100%",
                    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                    borderRadius: "15px 0px 0px 15px",
                  }}
                >
                  <img
                    src={logo}
                    alt="premelo logo"
                    style={{
                      width: "40%",
                      marginTop: "15px",
                      borderRadius: "50%",
                      boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
                    }}
                  />
                  <div
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "10px",
                      marginTop: "20px",
                      textAlign: "left",
                      marginLeft: "15px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <GridViewIcon
                      style={{ fontSize: "15px", marginRight: "5px" }}
                    />
                    Dashboard
                  </div>
                  <div
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "10px",
                      marginTop: "17px",
                      textAlign: "left",
                      marginLeft: "15px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ContentCopyIcon
                      style={{ fontSize: "15px", marginRight: "5px" }}
                    />
                    Projects
                  </div>
                  <div
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "10px",
                      marginTop: "17px",
                      textAlign: "left",
                      marginLeft: "15px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CalendarTodayIcon
                      style={{ fontSize: "15px", marginRight: "5px" }}
                    />
                    Calendar
                  </div>
                  <div
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "10px",
                      marginTop: "17px",
                      textAlign: "left",
                      marginLeft: "15px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <NotificationsNoneIcon
                      style={{ fontSize: "15px", marginRight: "5px" }}
                    />
                    Notification
                  </div>
                  <div
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "10px",
                      marginTop: "17px",
                      textAlign: "left",
                      marginLeft: "15px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <MonetizationOnIcon
                      style={{ fontSize: "15px", marginRight: "5px" }}
                    />
                    Subscription
                  </div>
                </div>
                <div
                  style={{
                    width: "85%",
                    height: "100%",
                    background: `${primaryColor}1A`,
                    borderRadius: "0px 15px 15px 0px",
                    textAlign: "center",
                  }}
                >
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "25px",
                      fontWeight: 600,
                      marginTop: "30px",
                      marginRight: "16%",
                    }}
                  >
                    Dashboard
                  </div>
                  <div
                    style={{
                      fontSize: "10px",
                      color: "rgba(0,0,0,0.6)",
                      marginTop: "-4px",
                      marginRight: "16%",
                    }}
                  >
                    Welcome back, User
                  </div>
                  <div
                    style={{
                      padding: "0px 30px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        fontSize: "14px",
                        textAlign: "left",
                        marginTop: "10px",
                        color: "rgba(0,0,0,0.6)",
                      }}
                    >
                      Recently used.
                    </div>
                    <div
                      style={{
                        fontSize: "12px",
                        marginTop: "10px",
                        color: "white",
                        background: primaryColor,
                        borderRadius: "6px",
                        padding: "4px 10px",
                      }}
                    >
                      Create Project
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "0px 2% 0px 1%",
                      marginTop: "10px",
                    }}
                  >
                    <div
                      style={{ width: "19%", marginRight: "5px" }}
                      className="folder-icon-1"
                    >
                      <div className="child-div-2">
                        <div style={{ width: "90%", textAlign: "left" }}>
                          <h2
                            style={{
                              fontWeight: "300",
                              fontSize: "6px",
                              marginBottom: "-5px",
                            }}
                          >
                            Project name:
                          </h2>
                          <span
                            style={{
                              fontWeight: "800",
                              fontSize: "10px",
                              marginTop: "-20px",
                              lineHeight: "1px",

                              color: "rgba(0,0,0,0.6)",
                            }}
                          >
                            Cayden Studios
                          </span>
                          <div style={{ marginTop: "5px" }}>
                            <div
                              style={{
                                backgroundColor: primaryColor,
                                color: "white",
                                display: "inline-flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "25px",
                                height: "25px",
                                borderRadius: "50%",
                                marginRight: "5px",
                                fontWeight: "600",
                                fontSize: "16px",
                                border: "2px solid #ffffff",
                                marginRight: "-11px",
                              }}
                            >
                              <p
                                style={{ fontWeight: "300", fontSize: "10px" }}
                              >
                                PA
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <FolderIcon />
                    </div>
                    <div
                      style={{ width: "19%", marginRight: "5px" }}
                      className="folder-icon-1"
                    >
                      <div className="child-div-2">
                        <div style={{ width: "90%", textAlign: "left" }}>
                          <h2
                            style={{
                              fontWeight: "300",
                              fontSize: "6px",
                              marginBottom: "-5px",
                            }}
                          >
                            Project name:
                          </h2>
                          <span
                            style={{
                              fontWeight: "800",
                              fontSize: "10px",
                              marginTop: "-20px",
                              lineHeight: "10px",
                              color: "rgba(0,0,0,0.6)",
                            }}
                          >
                            Smartlend
                          </span>
                          <div style={{ marginTop: "5px" }}>
                            <div
                              style={{
                                backgroundColor: primaryColor,
                                color: "white",
                                display: "inline-flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "25px",
                                height: "25px",
                                borderRadius: "50%",
                                marginRight: "5px",
                                fontWeight: "600",
                                fontSize: "16px",
                                border: "2px solid #ffffff",
                                marginRight: "-11px",
                              }}
                            >
                              <p
                                style={{ fontWeight: "300", fontSize: "10px" }}
                              >
                                PA
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <FolderIcon />
                    </div>
                    <div
                      style={{ width: "19%", marginRight: "5px" }}
                      className="folder-icon-1"
                    >
                      <div className="child-div-2">
                        <div style={{ width: "90%", textAlign: "left" }}>
                          <h2
                            style={{
                              fontWeight: "300",
                              fontSize: "6px",
                              marginBottom: "-5px",
                            }}
                          >
                            Project name:
                          </h2>
                          <span
                            style={{
                              fontWeight: "800",
                              fontSize: "10px",
                              marginTop: "-20px",
                              lineHeight: "10px",
                              color: "rgba(0,0,0,0.6)",
                            }}
                          >
                            AWS EC2
                          </span>
                          <div style={{ marginTop: "5px" }}>
                            <div
                              style={{
                                backgroundColor: primaryColor,
                                color: "white",
                                display: "inline-flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "25px",
                                height: "25px",
                                borderRadius: "50%",
                                marginRight: "5px",
                                fontWeight: "600",
                                fontSize: "16px",
                                border: "2px solid #ffffff",
                                marginRight: "-11px",
                              }}
                            >
                              <p
                                style={{ fontWeight: "300", fontSize: "10px" }}
                              >
                                PA
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <FolderIcon />
                    </div>
                    <div
                      style={{ width: "19%", marginRight: "5px" }}
                      className="folder-icon-1"
                    >
                      <div className="child-div-2">
                        <div style={{ width: "90%", textAlign: "left" }}>
                          <h2
                            style={{
                              fontWeight: "300",
                              fontSize: "6px",
                              marginBottom: "-5px",
                            }}
                          >
                            Project name:
                          </h2>
                          <span
                            style={{
                              fontWeight: "800",
                              fontSize: "10px",
                              marginTop: "-20px",
                              lineHeight: "10px",
                              color: "rgba(0,0,0,0.6)",
                            }}
                          >
                            Company Video pr.
                          </span>
                          <div style={{ marginTop: "5px" }}>
                            <div
                              style={{
                                backgroundColor: primaryColor,
                                color: "white",
                                display: "inline-flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "25px",
                                height: "25px",
                                borderRadius: "50%",
                                marginRight: "5px",
                                fontWeight: "600",
                                fontSize: "16px",
                                border: "2px solid #ffffff",
                                marginRight: "-11px",
                              }}
                            >
                              <p
                                style={{ fontWeight: "300", fontSize: "10px" }}
                              >
                                PA
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <FolderIcon />
                    </div>
                    <div style={{ width: "19%" }} className="folder-icon-1">
                      <div className="child-div-2">
                        <div style={{ width: "90%", textAlign: "left" }}>
                          <h2
                            style={{
                              fontWeight: "300",
                              fontSize: "6px",
                              marginBottom: "-5px",
                            }}
                          >
                            Project name:
                          </h2>
                          <span
                            style={{
                              fontWeight: "800",
                              fontSize: "10px",
                              marginTop: "-20px",
                              lineHeight: "10px",
                              color: "rgba(0,0,0,0.6)",
                            }}
                          >
                            Campaign Design
                          </span>
                          <div style={{ marginTop: "5px" }}>
                            <div
                              style={{
                                backgroundColor: primaryColor,
                                color: "white",
                                display: "inline-flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "25px",
                                height: "25px",
                                borderRadius: "50%",
                                marginRight: "5px",
                                fontWeight: "600",
                                fontSize: "16px",
                                border: "2px solid #ffffff",
                                marginRight: "-11px",
                              }}
                            >
                              <p
                                style={{ fontWeight: "300", fontSize: "10px" }}
                              >
                                PA
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <FolderIcon />
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0px 30px",
                    }}
                  >
                    <div>
                      <div
                        style={{
                          fontSize: "14px",
                          textAlign: "left",
                          marginTop: "10px",
                          color: "rgba(0,0,0,0.6)",
                        }}
                      >
                        To-do list
                      </div>
                      <div
                        className="task-item"
                        style={{
                          padding: "0.1px 10px 0px 0px",
                          borderBottom: "1px solid rgba(0, 0, 0, .05)",
                          textAlign: "left",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "-3px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              marginLeft: "-10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  marginTop: "-3px",
                                  marginRight: "6px",
                                }}
                              >
                                <Checkbox
                                  style={{ marginTop: "5px" }}
                                  icon={
                                    <CheckIcon style={{ fontSize: "15px" }} />
                                  }
                                  checkedIcon={
                                    <CheckedIcon style={{ fontSize: "15px" }} />
                                  }
                                />
                              </div>
                              <div style={{ width: "220px" }}>
                                <div>
                                  <h3
                                    style={{
                                      color: "#474747",
                                      fontSize: "10px",
                                      fontWeight: "600",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Frontal Campaign P..
                                  </h3>
                                </div>
                                <div>
                                  <p
                                    style={{
                                      color: "#7C7C7C",
                                      fontSize: "7px",
                                      marginTop: "-15px",
                                    }}
                                  >
                                    Could you prepare the frontal campa...
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div style={{ width: "100px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <div
                                  style={{
                                    padding: "2px 15px",
                                    borderRadius: "5px",
                                    marginTop: "-1px",
                                    background: `${primaryColor}7A`,
                                    color: "white",
                                  }}
                                >
                                  <p
                                    style={{
                                      margin: "0px",
                                      fontWeight: "600",
                                      fontSize: "8px",
                                    }}
                                  >
                                    Newly added
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="task-item"
                        style={{
                          padding: "0.1px 10px 0px 0px",
                          borderBottom: "1px solid rgba(0, 0, 0, .05)",
                          textAlign: "left",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "-3px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              marginLeft: "-10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  marginTop: "-3px",
                                  marginRight: "6px",
                                }}
                              >
                                <Checkbox
                                  style={{ marginTop: "5px" }}
                                  icon={
                                    <CheckIcon style={{ fontSize: "15px" }} />
                                  }
                                  checkedIcon={
                                    <CheckedIcon style={{ fontSize: "15px" }} />
                                  }
                                />
                              </div>
                              <div style={{ width: "220px" }}>
                                <div>
                                  <h3
                                    style={{
                                      color: "#474747",
                                      fontSize: "10px",
                                      fontWeight: "600",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Frontal Campaign P..
                                  </h3>
                                </div>
                                <div>
                                  <p
                                    style={{
                                      color: "#7C7C7C",
                                      fontSize: "7px",
                                      marginTop: "-15px",
                                    }}
                                  >
                                    Could you prepare the frontal campa...
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div style={{ width: "100px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <div
                                  style={{
                                    padding: "2px 15px",
                                    borderRadius: "5px",
                                    marginTop: "-1px",
                                    background: `${primaryColor}7A`,
                                    color: "white",
                                  }}
                                >
                                  <p
                                    style={{
                                      margin: "0px",
                                      fontWeight: "600",
                                      fontSize: "8px",
                                    }}
                                  >
                                    Newly added
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="task-item"
                        style={{
                          padding: "0.1px 10px 0px 0px",
                          borderBottom: "1px solid rgba(0, 0, 0, .05)",
                          textAlign: "left",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "-3px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              marginLeft: "-10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  marginTop: "-3px",
                                  marginRight: "6px",
                                }}
                              >
                                <Checkbox
                                  style={{ marginTop: "5px" }}
                                  icon={
                                    <CheckIcon style={{ fontSize: "15px" }} />
                                  }
                                  checkedIcon={
                                    <CheckedIcon style={{ fontSize: "15px" }} />
                                  }
                                />
                              </div>
                              <div style={{ width: "220px" }}>
                                <div>
                                  <h3
                                    style={{
                                      color: "#474747",
                                      fontSize: "10px",
                                      fontWeight: "600",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Frontal Campaign P..
                                  </h3>
                                </div>
                                <div>
                                  <p
                                    style={{
                                      color: "#7C7C7C",
                                      fontSize: "7px",
                                      marginTop: "-15px",
                                    }}
                                  >
                                    Could you prepare the frontal campa...
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div style={{ width: "100px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <div
                                  style={{
                                    padding: "2px 15px",
                                    borderRadius: "5px",
                                    marginTop: "-1px",
                                    background: `${primaryColor}7A`,
                                    color: "white",
                                  }}
                                >
                                  <p
                                    style={{
                                      margin: "0px",
                                      fontWeight: "600",
                                      fontSize: "8px",
                                    }}
                                  >
                                    Newly added
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="task-item"
                        style={{
                          padding: "0.1px 10px 0px 0px",
                          borderBottom: "1px solid rgba(0, 0, 0, .05)",
                          textAlign: "left",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "-3px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              marginLeft: "-10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  marginTop: "-3px",
                                  marginRight: "6px",
                                }}
                              >
                                <Checkbox
                                  style={{ marginTop: "5px" }}
                                  icon={
                                    <CheckIcon style={{ fontSize: "15px" }} />
                                  }
                                  checkedIcon={
                                    <CheckedIcon style={{ fontSize: "15px" }} />
                                  }
                                />
                              </div>
                              <div style={{ width: "220px" }}>
                                <div>
                                  <h3
                                    style={{
                                      color: "#474747",
                                      fontSize: "10px",
                                      fontWeight: "600",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Frontal Campaign P..
                                  </h3>
                                </div>
                                <div>
                                  <p
                                    style={{
                                      color: "#7C7C7C",
                                      fontSize: "7px",
                                      marginTop: "-15px",
                                    }}
                                  >
                                    Could you prepare the frontal campa...
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div style={{ width: "100px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <div
                                  style={{
                                    padding: "2px 15px",
                                    borderRadius: "5px",
                                    marginTop: "-1px",
                                    background: `${primaryColor}7A`,
                                    color: "white",
                                  }}
                                >
                                  <p
                                    style={{
                                      margin: "0px",
                                      fontWeight: "600",
                                      fontSize: "8px",
                                    }}
                                  >
                                    Newly added
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="task-item"
                        style={{
                          padding: "0.1px 10px 0px 0px",
                          borderBottom: "1px solid rgba(0, 0, 0, .05)",
                          textAlign: "left",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "-3px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              marginLeft: "-10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  marginTop: "-3px",
                                  marginRight: "6px",
                                }}
                              >
                                <Checkbox
                                  style={{ marginTop: "5px" }}
                                  icon={
                                    <CheckIcon style={{ fontSize: "15px" }} />
                                  }
                                  checkedIcon={
                                    <CheckedIcon style={{ fontSize: "15px" }} />
                                  }
                                />
                              </div>
                              <div style={{ width: "220px" }}>
                                <div>
                                  <h3
                                    style={{
                                      color: "#474747",
                                      fontSize: "10px",
                                      fontWeight: "600",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Frontal Campaign P..
                                  </h3>
                                </div>
                                <div>
                                  <p
                                    style={{
                                      color: "#7C7C7C",
                                      fontSize: "7px",
                                      marginTop: "-15px",
                                    }}
                                  >
                                    Could you prepare the frontal campa...
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div style={{ width: "100px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <div
                                  style={{
                                    padding: "2px 15px",
                                    borderRadius: "5px",
                                    marginTop: "-1px",
                                    background: `${primaryColor}7A`,
                                    color: "white",
                                  }}
                                >
                                  <p
                                    style={{
                                      margin: "0px",
                                      fontWeight: "600",
                                      fontSize: "8px",
                                    }}
                                  >
                                    Newly added
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="task-item"
                        style={{
                          padding: "0.1px 10px 0px 0px",
                          borderBottom: "1px solid rgba(0, 0, 0, .05)",
                          textAlign: "left",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "-3px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              marginLeft: "-10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  marginTop: "-3px",
                                  marginRight: "6px",
                                }}
                              >
                                <Checkbox
                                  style={{ marginTop: "5px" }}
                                  icon={
                                    <CheckIcon style={{ fontSize: "15px" }} />
                                  }
                                  checkedIcon={
                                    <CheckedIcon style={{ fontSize: "15px" }} />
                                  }
                                />
                              </div>
                              <div style={{ width: "220px" }}>
                                <div>
                                  <h3
                                    style={{
                                      color: "#474747",
                                      fontSize: "10px",
                                      fontWeight: "600",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Frontal Campaign P..
                                  </h3>
                                </div>
                                <div>
                                  <p
                                    style={{
                                      color: "#7C7C7C",
                                      fontSize: "7px",
                                      marginTop: "-15px",
                                    }}
                                  >
                                    Could you prepare the frontal campa...
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div style={{ width: "100px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <div
                                  style={{
                                    padding: "2px 15px",
                                    borderRadius: "5px",
                                    marginTop: "-1px",
                                    background: `${primaryColor}7A`,
                                    color: "white",
                                  }}
                                >
                                  <p
                                    style={{
                                      margin: "0px",
                                      fontWeight: "600",
                                      fontSize: "8px",
                                    }}
                                  >
                                    Newly added
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="task-item"
                        style={{
                          padding: "0.1px 10px 0px 0px",
                          borderBottom: "1px solid rgba(0, 0, 0, .05)",
                          textAlign: "left",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "-3px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              marginLeft: "-10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                              }}
                            >
                              <div
                                style={{
                                  marginTop: "-3px",
                                  marginRight: "6px",
                                }}
                              >
                                <Checkbox
                                  style={{ marginTop: "5px" }}
                                  icon={
                                    <CheckIcon style={{ fontSize: "15px" }} />
                                  }
                                  checkedIcon={
                                    <CheckedIcon style={{ fontSize: "15px" }} />
                                  }
                                />
                              </div>
                              <div style={{ width: "220px" }}>
                                <div>
                                  <h3
                                    style={{
                                      color: "#474747",
                                      fontSize: "10px",
                                      fontWeight: "600",
                                      marginTop: "10px",
                                    }}
                                  >
                                    Frontal Campaign P..
                                  </h3>
                                </div>
                                <div>
                                  <p
                                    style={{
                                      color: "#7C7C7C",
                                      fontSize: "7px",
                                      marginTop: "-15px",
                                    }}
                                  >
                                    Could you prepare the frontal campa...
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div style={{ width: "100px" }}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <div
                                  style={{
                                    padding: "2px 15px",
                                    borderRadius: "5px",
                                    marginTop: "-1px",
                                    background: `${primaryColor}7A`,
                                    color: "white",
                                  }}
                                >
                                  <p
                                    style={{
                                      margin: "0px",
                                      fontWeight: "600",
                                      fontSize: "8px",
                                    }}
                                  >
                                    Newly added
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Floating Project */}
          <motion.div
            animate={{
              left: 170,
            }}
            transition={{ type: "bounce", duration: 15 }}
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "space-between",
              width: "350px",
              height: "9vh",
              borderRadius: "15px",
              boxShadow: "0px 0px 50px rgba(0,0,0,0.2)",
              zIndex: 5,
              background: "white",
              left: 40,
              marginTop: "-400px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  background: primaryColor,
                  width: "7vh",
                  height: "7vh",
                  borderRadius: "12px",
                  marginLeft: "1vh",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <DocumentScannerIcon
                  style={{ color: "white", fontSize: "30px" }}
                />
              </div>
              <div style={{ marginLeft: "10px" }}>
                <div
                  style={{
                    fontSize: "13px",
                    fontWeight: "600",
                    textAlign: "left",
                    color: "rgba(0,0,0,0.7)",
                  }}
                >
                  My Project Name.
                </div>
                <div
                  style={{
                    fontSize: "10px",
                    fontWeight: "300",
                    textAlign: "left",
                    color: "rgba(0,0,0,0.4)",
                  }}
                >
                  This is a sample project description
                </div>
              </div>
            </div>
          </motion.div>
          {/* Floating Project #2 */}
          <motion.div
            animate={{
              left: 300,
            }}
            transition={{ type: "bounce", duration: 15 }}
            style={{
              position: "absolute",
              display: "flex",
              justifyContent: "space-between",
              width: "270px",
              height: "7vh",
              borderRadius: "15px",
              boxShadow: "0px 0px 50px rgba(0,0,0,0.2)",
              zIndex: 4,
              background: "white",
              left: 400,
              marginTop: "-310px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  background: primaryColor,
                  width: "5vh",
                  height: "5vh",
                  borderRadius: "12px",
                  marginLeft: "1vh",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <DocumentScannerIcon
                  style={{ color: "white", fontSize: "18px" }}
                />
              </div>
              <div style={{ marginLeft: "10px" }}>
                <div
                  style={{
                    fontSize: "10px",
                    fontWeight: "600",
                    textAlign: "left",
                    color: "rgba(0,0,0,0.7)",
                  }}
                >
                  Premelo Projects
                </div>
                <div
                  style={{
                    fontSize: "7px",
                    fontWeight: "300",
                    textAlign: "left",
                    color: "rgba(0,0,0,0.4)",
                  }}
                >
                  This is a sample project description
                </div>
              </div>
            </div>
          </motion.div>
          {/* floating calender */}
          <div
            style={{
              marginTop: "-250px",
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <motion.div
              animate={{
                right: 100,
              }}
              transition={{ type: "bounce", duration: 15 }}
              style={{
                position: "absolute",
                padding: "20px",
                textAlign: "left",
                boxShadow: "0px 0px 50px rgba(0,0,0,0.2)",
                borderRadius: "15px",
                background: "white",
                zIndex: 5,
                right: 0,
                width: "280px",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  color: primaryColor,
                  fontSize: "18px",
                  fontWeight: 600,
                  background: "white",
                  zIndex: 10,
                  height: "50px",
                  width: "90%",
                }}
              >
                Personalized Calendar
              </div>
              <div style={{ marginTop: "-17px" }}>
                <Calendar />
              </div>
            </motion.div>
          </div>
        </div>
        <div style={{ marginTop: "450px" }}></div>
      </div>
      <div
        style={{
          background: "black",
          color: "white",
          padding: "100px 0px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "80%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: "30px",
              fontWeight: "400",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "-20px",
            }}
          >
            It's entirely{" "}
            <div
              style={{ color: "#d81b5e", margin: "0px 10px", fontWeight: 800 }}
            >
              FREE
            </div>{" "}
            to use
          </div>
          <div
            style={{
              fontSize: "60px",
              fontWeight: "800",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            Nothing{" "}
            <div style={{ color: "#d81b5e", margin: "0px 10px" }}>stopping</div>{" "}
            you now
          </div>
          <div
            style={{
              fontSize: "14px",
              fontWeight: "300",
              marginTop: "10px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <div style={{ width: "70%" }}>
              We never ask for your credit card information, so we never charge
              you anything.
            </div>
          </div>
          <div
            style={{
              fontSize: "14px",
              fontWeight: "300",
              marginTop: "25px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <div style={{ width: "70%" }}>
              <div style={{ textDecoration: "line-through" }}>Try now</div>
            </div>
          </div>
          <button
            className="homepage-nav-side-1"
            style={{
              padding: "10px 30px",
              borderRadius: "50px",
              border: "none",
              fontSize: "18px",
              fontWeight: "600",
              marginTop: "5px",
            }}
            onClick={() => {
              navigate("/login");
              window.scrollTo(0, 0);
            }}
          >
            Start now
          </button>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          background: "#f0f0f0",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          paddingBottom: "80px",
        }}
      >
        <div style={{ width: "100%" }}>
          <div
            style={{ fontSize: "35px", fontWeight: "600", marginTop: "80px" }}
          >
            Take your team's management to
          </div>
          <div
            style={{ fontSize: "35px", fontWeight: "600", marginTop: "-15px" }}
          >
            greater heights.
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              textAlign: "center",
              marginTop: "40px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "80%",
              }}
            >
              <div style={{ width: "30%", textAlign: "left" }}>
                <img src={points1} alt="" style={{ width: "100%" }} />
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    marginTop: "10px",
                  }}
                >
                  Manage Tasks
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "300",
                    marginTop: "10px",
                  }}
                >
                  Premelo lets you create multiple projects and tasks with ease,
                  and track progress in real-time. Keep your team organized and
                  on the same page.
                </div>
                <div
                  style={{
                    textDecoration: "underline",
                    fontSize: "15px",
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "300",
                  }}
                  className="mui-button"
                  onClick={() => {
                    navigate("/workmanagement");
                    window.scrollTo(0, 0);
                  }}
                >
                  Get Started
                  <ArrowForwardIcon
                    style={{ marginLeft: "5px", fontSize: "18px" }}
                  />
                </div>
              </div>
              <div style={{ width: "30%", textAlign: "left" }}>
                <img src={points2} alt="" style={{ width: "100%" }} />
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    marginTop: "10px",
                  }}
                >
                  Customized Workflow
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "300",
                    marginTop: "10px",
                  }}
                >
                  Tailor your workflow to each project's unique needs and
                  requirements, ensuring maximum efficiency and productivity.
                </div>
                <div
                  style={{
                    textDecoration: "underline",
                    fontSize: "15px",
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "300",
                  }}
                  className="mui-button"
                  onClick={() => {
                    navigate("/customworkflow");
                    window.scrollTo(0, 0);
                  }}
                >
                  Learn how
                  <ArrowForwardIcon
                    style={{ marginLeft: "5px", fontSize: "18px" }}
                  />
                </div>
              </div>
              <div style={{ width: "30%", textAlign: "left" }}>
                <img src={points3} alt="" style={{ width: "100%" }} />
                <div
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    marginTop: "10px",
                  }}
                >
                  Branding Tools
                </div>
                <div
                  style={{
                    fontSize: "12px",
                    fontWeight: "300",
                    marginTop: "10px",
                  }}
                >
                  With Premelo's branding tools, set your logo and brand colors
                  for a fully branded workspace that aligns with your company's
                  image.
                </div>
                <div
                  style={{
                    textDecoration: "underline",
                    fontSize: "15px",
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "300",
                  }}
                  className="mui-button"
                  onClick={() => {
                    navigate("/platformcustomization");
                    window.scrollTo(0, 0);
                  }}
                >
                  Learn more
                  <ArrowForwardIcon
                    style={{ marginLeft: "5px", fontSize: "18px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          padding: "80px 0px",
          textAlign: "center",
          fontWeight: "500",
          fontSize: "30px",
        }}
      >
        <div>Transform Your Workspace with the #1 Tailored</div>
        <div>Team Management Tool</div>
        <div style={{ marginTop: "25px" }}>
          <button
            style={{
              color: "white",
              background: "rgba(0,0,0,1)",
              padding: "10px 35px",
              borderRadius: "25px",
              fontSize: "17px",
              fontWeight: "600",
            }}
            className="mui-button-home"
            onClick={() => {
              navigate("/signup");
              window.scrollTo(0, 0);
            }}
          >
            Get started
          </button>
        </div>
      </div>
      <hr
        style={{
          width: "100%",
          height: "0.02rem",
          backgroundColor: "black",
          border: "none",
          margin: "10px auto",
        }}
      />
      {/* Website Footer */}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "80%",
            justifyContent: "space-between",
            textAlign: "left",
            padding: "20px 0px",
          }}
        >
          <div style={{ width: "16%", fontSize: "12px", fontWeight: "300" }}>
            <div style={{ fontSize: "15px", fontWeight: "400" }}>Learn</div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/taskmanagement");
                window.scrollTo(0, 0);
              }}
            >
              Task Management
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/teaminvite");
                window.scrollTo(0, 0);
              }}
            >
              Team Invite
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/customworkflow");
                window.scrollTo(0, 0);
              }}
            >
              Custom Workflow
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/platformcustomization");
                window.scrollTo(0, 0);
              }}
            >
              Platform Customization
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/domainandhosting");
                window.scrollTo(0, 0);
              }}
            >
              Domain & Hosting
            </div>
          </div>
          <div style={{ width: "16%", fontSize: "12px", fontWeight: "300" }}>
            <div style={{ fontSize: "15px", fontWeight: "400" }}>Products</div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/workmanagement");
                window.scrollTo(0, 0);
              }}
            >
              Work Management
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/socialmediaconnect");
                window.scrollTo(0, 0);
              }}
            >
              Social Media Connect
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/internalrepositories");
                window.scrollTo(0, 0);
              }}
            >
              Internal Repositories
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/centralizedmessaging");
                window.scrollTo(0, 0);
              }}
            >
              Centralized Messaging
            </div>
          </div>
          <div style={{ width: "11%", fontSize: "12px", fontWeight: "300" }}>
            <div style={{ fontSize: "15px", fontWeight: "400" }}>Support</div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/contactus");
                window.scrollTo(0, 0);
              }}
            >
              Help Center
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/faq");
                window.scrollTo(0, 0);
              }}
            >
              FAQ
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/contactus");
                window.scrollTo(0, 0);
              }}
            >
              System Status
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/chatwithagent");
                window.scrollTo(0, 0);
              }}
            >
              Speak to our agents
            </div>
          </div>
          <div style={{ width: "16%", fontSize: "12px", fontWeight: "300" }}>
            <div style={{ fontSize: "15px", fontWeight: "400" }}>Company</div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/pressandannouncement");
                window.scrollTo(0, 0);
              }}
            >
              Press & Announcement
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/contactus");
                window.scrollTo(0, 0);
              }}
            >
              Careers
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/contactus");
                window.scrollTo(0, 0);
              }}
            >
              Sitemap
            </div>
          </div>
          <div style={{ width: "20%", fontSize: "12px", fontWeight: "300" }}>
            <img
              src={logo}
              alt="premelo logo"
              style={{ width: "30%", marginTop: "-5px" }}
            />
            <div style={{ marginTop: "-5px" }}>
              Premelo is a customizable work management platform that enables
              easy collaboration and real-time updates. Streamline workflow and
              boost productivity today.
            </div>
          </div>
        </div>
      </div>
      <hr
        style={{
          width: "100%",
          height: "0.02rem",
          backgroundColor: "black",
          border: "none",
          margin: "10px auto",
        }}
      />
      <div
        style={{
          width: "100%",
          padding: "10px 0px 20px 0px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", width: "50%", marginLeft: "10%" }}>
          <FacebookIcon
            className="mui-button"
            style={{ fontSize: "20px", marginRight: "15px" }}
            onClick={() =>
              (window.location.href = "https://www.facebook.com/premeloapp")
            }
          />
          <InstagramIcon
            className="mui-button"
            style={{ fontSize: "20px", marginRight: "15px" }}
            onClick={() =>
              (window.location.href = "https://www.instagram.com/premelo_app/")
            }
          />
          <YouTubeIcon
            className="mui-button"
            style={{ fontSize: "20px", marginRight: "15px" }}
            onClick={() =>
              (window.location.href = "https://www.youtube.com/@Premelo-Inc")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            width: "50%",
            fontSize: "12px",
            fontWeight: "300",
            textAlign: "right",
            justifyContent: "flex-end",
            marginRight: "10%",
          }}
        >
          <div
            className="mui-button"
            style={{ marginRight: "20px" }}
            onClick={() => {
              navigate("/termsandcondition");
              window.scrollTo(0, 0);
            }}
          >
            Terms & Privacy
          </div>
          <div
            className="mui-button"
            style={{ marginRight: "20px" }}
            onClick={() => {
              navigate("/contactus");
              window.scrollTo(0, 0);
            }}
          >
            Contact Us
          </div>
          <div>© 2023 Premelo, Inc.</div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
