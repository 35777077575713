import React from "react";
import logo from "./images/CS_Favicon-01.png";

const MobileScreen = () => {
  return (
    <div style={{ textAlign: "center", padding: "0% 5%" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          marginTop: "35%",
        }}
      >
        <img src={logo} style={{ width: "200px" }} />
      </div>
      <h1 style={{ color: "rgba(0,0,0,0.7)", lineHeight: "32px" }}>
        Our web application
      </h1>
      <p style={{ color: "rgba(0,0,0,0.5)", lineHeight: "20px" }}>
        Is limited to access from a computer or tablet.
      </p>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
          marginTop: "30px",
        }}
      >
        <a href={"https://www.premelo.com"}
          style={{
            color: "white",
            background: "#036bfc",
            padding: "7px 30px",
            width: "150px",
            borderRadius: "15px",
          }}
        >
          Return
        </a>
      </div>
    </div>
  );
};

export default MobileScreen;
