import React, { useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { MarginTwoTone } from "@mui/icons-material";
import logo from "./images/CS_Favicon-01.png";
import CSVReader from "react-csv-reader";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "react-modal";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Cookies from "js-cookie";
import "./App.css";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import axios from "axios";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { CircularProgress } from "@mui/material";
import LeakAddIcon from "@mui/icons-material/LeakAdd";
import { ToastContainer, toast } from "react-toastify";

const ProductMobile = (props) => {
  const fileInputRef = useRef(null);
  const [allProduct, setAllProduct] = useState([]);
  const token = Cookies.get("token");

  const [currency, setCurrency] = useState("$");
  useEffect(() => {
    const getCurrencyAndShipping = async () => {
      const token = Cookies.get("token");
      const response = await fetch(
        "https://ww1.premelo.com/api/stripe/getcands",
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      if (json.commerce_array) {
        if (json.commerce_array.currency) {
          if (json.commerce_array.currency.display) {
            setCurrency(json.commerce_array.currency.display);
          }
        }
      }
    };
    getCurrencyAndShipping();
  }, []);

  const [selectedProduct, setSelectedProduct] = useState({
    img_src: "",
    name: "",
    description: "",
    sku: "",
    unit_price: "",
    weight: "",
    length: "",
    width: "",
    height: "",
    categories: "",
    tags: "",
    brand: "",
    size: "",
    stock: "",
    added_attribute: [],
    set_variation: false,
    variation: [],
  });

  const [isFormVisible, setIsFormVisible] = useState(false);

  const [fileUploading, setFileUploading] = useState(false);

  const handleImageUpload = async (e) => {
    setFileUploading(true);
    const file = e.target.files;
    console.log(e.target.files);

    const formData = new FormData();

    for (let i = 0; i < file.length; i++) {
      formData.append("files[]", file[i]);
    }

    try {
      const response = await axios.post(
        "https://ww1.premelo.com/api/upload-image",
        formData
      );
      const imageUrl = response.data.urls;
      const updatedProduct = { ...selectedProduct };
      updatedProduct.img_src = imageUrl;
      setSelectedProduct(updatedProduct);
      setFileUploading(false);
    } catch (error) {
      console.log(error);
      setFileUploading(false);
    }
  };

  const handleClick = () => {
    const fileInput = document.getElementById("imageInput");
    fileInput.click();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSelectedProduct({ ...selectedProduct, [name]: value });
  };

  // useEffect(() => {
  //   const updateProductArray = async () => {
  //     try {
  //       const response = await fetch(
  //         "https://ww1.premelo.com/api/web-builder/update-product",
  //         {
  //           method: "POST",
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({ product_array: allProduct }),
  //         }
  //       );
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   updateProductArray();
  //   console.log("changes in all products");
  // }, [allProduct]);

  const currentURL = window.location.href;
  const modifiedURL = currentURL.replace(/\/dashboard$/, "");

  const handleDeleteProduct = async () => {
    const index = allProduct
      ? allProduct.findIndex((product) => product.sku === selectedProduct.sku)
      : -1;

    if (index !== -1) {
      const updatingProduct = [...allProduct];
      updatingProduct.splice(index, 1);
      setAllProduct(updatingProduct);

      setIsFormVisible(false);
      setSelectedProduct({
        img_src: "",
        name: "",
        description: "",
        sku: "",
        unit_price: "",
        weight: "",
        length: "",
        width: "",
        height: "",
        categories: "",
        tags: "",
        brand: "",
        size: "",
        stock: "",
        added_attribute: [],
        set_variation: false,
        variation: [],
      });

      try {
        const response = await fetch(
          "https://ww1.premelo.com/api/web-builder/update-product",
          {
            body: JSON.stringify({
              product_array: updatingProduct,
            }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            method: "POST",
          }
        );
        if (!response.ok) {
          // setSignUpError(true);
          throw new Error("Network response was not ok");
        }

        // Clear the form data after adding the product
      } catch (error) {
        // setSignUpError(true);
        console.error("Error signing up:", error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const index = allProduct
      ? allProduct.findIndex((product) => product.sku === selectedProduct.sku)
      : -1;

    console.log(index);
    const updatedAllProduct = [...allProduct];
    if (index !== -1) {
      // Remove empty attributes from selectedProduct.added_attributes
      console.log(selectedProduct.added_attribute);
      const nonEmptyAttributes = selectedProduct.added_attribute.filter(
        (attr) => attr.attribute_name.trim() !== ""
      );

      // Update selectedProduct with non-empty attributes
      selectedProduct.added_attribute = nonEmptyAttributes;

      updatedAllProduct[index] = selectedProduct;
      setAllProduct(updatedAllProduct);
      console.log(updatedAllProduct);
    } else {
      updatedAllProduct.push(selectedProduct);
      setAllProduct(updatedAllProduct);
    }

    try {
      const response = await fetch(
        "https://ww1.premelo.com/api/web-builder/update-product",
        {
          body: JSON.stringify({
            product_array: updatedAllProduct,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "POST",
        }
      );
      if (!response.ok) {
        // setSignUpError(true);
        throw new Error("Network response was not ok");
      }

      // Clear the form data after adding the product
    } catch (error) {
      // setSignUpError(true);
      console.error("Error signing up:", error);
    }

    setIsFormVisible(false);
    setSelectedProduct({
      img_src: "",
      name: "",
      description: "",
      sku: "",
      unit_price: "",
      weight: "",
      length: "",
      width: "",
      height: "",
      categories: "",
      tags: "",
      brand: "",
      size: "",
      stock: "",
      added_attribute: [],
      set_variation: false,
      variation: [],
    });

    console.log(
      "handle submit for updating products",
      updatedAllProduct[index]
    );
  };

  const [selectedFilters, setSelectedFilters] = useState([]);
  const [productId, setProductId] = useState(0);
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await fetch(
          "https://ww1.premelo.com/api/web-builder/get-product",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const json = await response.json();
        setAllProduct(json.product_array);
        setProductId(json.product_id);
        if (json.message === "Company record not found!") {
          setAllProduct([]);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchProducts();
  }, []);

  // Function to handle changes in selected filters
  const handleFilterChange = (filter) => {
    if (selectedFilters.includes(filter)) {
      // Filter is already selected, remove it
      setSelectedFilters(selectedFilters.filter((f) => f !== filter));
    } else {
      // Filter is not selected, add it
      setSelectedFilters([...selectedFilters, filter]);
    }
  };

  // Function to filter the products based on selected filters
  const filteredProducts = allProduct
    ? allProduct.filter((product) => {
        const tags = product.tags || ""; // Default to an empty string if tags is null
        const categories = product.categories || ""; // Default to an empty string if categories is null

        const tagssAndCategories = [
          ...tags.split(" | "),
          ...categories.split(" | "),
        ];

        // Check if selectedFilters is null or empty
        if (!selectedFilters || selectedFilters.length === 0) {
          return true; // Return true if no filters are selected
        }

        // Use selectedFilters.some only if it's not null or empty
        return selectedFilters.some((filter) =>
          tagssAndCategories.includes(filter)
        );
      })
    : [];

  const [searchQuery, setSearchQuery] = useState("");

  // Function to filter invoices based on search query
  const searchedProducts = filteredProducts
    ? filteredProducts.filter((product) => {
        const { name } = product;
        const lowerCaseSearchQuery = searchQuery.toLowerCase();

        return name && name.toLowerCase().includes(lowerCaseSearchQuery);
      })
    : [];

  // Function to handle search input change
  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const [importedData, setImportedData] = useState([]);

  const handleForce = (data) => {
    console.log(data);
    setImportedData(data);
    setModalIsOpen(true);
  };

  const handleProcessImportedData = (data) => {
    if (data && data.length > 0) {
      processCsvData(data);
    }
  };

  const processCsvData = async (importedProducts) => {
    // Create a new array to hold the updated products
    const updatedAllProduct = [...allProduct];

    // Iterate over the imported products
    importedProducts.forEach((importedProduct) => {
      // Check if the sku of the imported product already exists in the allProduct array
      const existingProductIndex = updatedAllProduct.findIndex(
        (product) => product.sku === importedProduct.sku
      );

      // If the product with the same sku exists, update its properties
      if (existingProductIndex !== -1) {
        updatedAllProduct[existingProductIndex] = {
          ...updatedAllProduct[existingProductIndex],
          ...importedProduct,
        };
      } else {
        // If the product with the same sku does not exist, add it as a new product
        updatedAllProduct.push(importedProduct);
      }
    });

    // Update the state with the new array of products

    // try {
    //   const response = await fetch(
    //     "http://localhost:8000/laravel/api/createandupdateproducts",
    //     {
    //       body: JSON.stringify({
    //         product_data: updatedAllProduct,
    //       }),
    //       headers: {
    //         "Content-Type": "application/json",
    //         Authorization: `Bearer ${token}`,
    //       },
    //       method: "POST",
    //     }
    //   );
    //   if (!response.ok) {
    //     // setSignUpError(true);
    //     throw new Error("Network response was not ok");
    //   }

    //   // Clear the form data after adding the product

    setAllProduct(updatedAllProduct);
    // } catch (error) {
    //   // setSignUpError(true);
    //   console.error("Error signing up:", error);
    // }

    console.log(updatedAllProduct);
  };

  const handleImportButtonClick = () => {
    // Trigger the file input when the custom import button is clicked
    fileInputRef.current.click();
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [addProductModalIsOpen, setAddProductModalIsOpen] = useState(false);

  const [newProductData, setNewProductData] = useState({
    img_src: "",
    name: "",
    description: "",
    sku: "",
    unit_price: "",
    weight: "",
    length: "",
    width: "",
    height: "",
    categories: "",
    tags: "",
    brand: "",
    size: "",
    stock: "",
    added_attribute: [],
    set_variation: false,
    variation: [],
  });

  const handleNewProductInputChange = (e) => {
    const { name, value } = e.target;
    setNewProductData({ ...newProductData, [name]: value });
  };

  return (
    <div style={{ position: "relative", height: "100%", fontSize: "30px" }}>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setModalIsOpen(false);
          fileInputRef.current.value = "";
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "70%",
            height: "90%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "white",
            boxShadow: "none",
            borderRadius: "10px",
            border: "none",
            overflowX: "hidden",
            overflowY: "auto",
            padding: "none",
          },
        }}
      >
        <div
          style={{
            width: "100%",
            height: "90%",
          }}
        >
          <div
            style={{
              fontSize: "30px",
              fontWeight: "600",
              color: "rgba(0,0,0,0.7)",
              borderBottom: "1px solid lightgrey",
              padding: "10px 0px",
              width: "97%",
              marginLeft: "1.5%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                marginLeft: "10px",
                color: props.primaryColor,
                fontSize: "20px",
                fontWeight: "600",
              }}
            >
              Product Import List
            </div>
            <div style={{ marginRight: "10px" }}>
              <CloseIcon
                style={{ fontSize: "28px", cursor: "pointer" }}
                onClick={() => {
                  setModalIsOpen(false);
                  fileInputRef.current.value = "";
                }}
              />
            </div>
          </div>
          {importedData.length > 0 ? (
            <div
              style={{
                width: "97%",
                padding: "0 1.5%",
                display: "flex",
                flexDirection: "column",
                height: "calc(100% - 100px)",
                overflowY: "scroll",
              }}
            >
              {importedData.map((product, index) => (
                <div
                  key={index}
                  className="invoice_item"
                  style={{
                    borderBottom: "1px solid lightgrey",
                    padding: "20px",
                    height: "auto",
                  }}
                >
                  <div
                    style={{
                      //   background: "rgba(256,256,256,0.8)",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "auto",
                        paddingRight: "1%",
                      }}
                    >
                      <img
                        src={product.img_src}
                        style={{
                          height: "80px",
                          width: "80px",
                          objectFit: "cover",
                          border: "1px solid lightgrey",
                          borderRadius: "10px",
                          marginRight: "5px",
                        }}
                      />
                    </div>
                    <div style={{ width: "90%", paddingRight: "1%" }}>
                      <div
                        style={{
                          fontWeight: "600",
                          color: props.primaryColor,
                          fontSize: "20px",
                        }}
                      >
                        <div style={{ fontSize: "12px", color: "lightgrey" }}>
                          Product name:
                        </div>
                        <div>{product.name}</div>
                      </div>
                      <div
                        style={{
                          fontWeight: "300",
                          color: "rgba(0,0,0,0.6)",
                          fontSize: "10px",
                          marginTop: "0px",
                        }}
                      >
                        {product.description}
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", marginTop: "10px" }}>
                    <div
                      style={{
                        width: "33%",
                        paddingRight: "1%",
                      }}
                    >
                      <div style={{ fontSize: "12px", color: "lightgrey" }}>
                        Stock:
                      </div>
                      <div style={{ display: "flex" }}>
                        {product.stock > 10 ? (
                          <div style={{ color: "#0d8c22", fontWeight: "600" }}>
                            In stock
                          </div>
                        ) : product.stock <= 10 && product.stock > 0 ? (
                          <div style={{ color: "#e62e2e", fontWeight: "600" }}>
                            Low stock
                          </div>
                        ) : product.stock === 0 ? (
                          <div style={{ color: "#e62e2e", fontWeight: "600" }}>
                            No stock
                          </div>
                        ) : (
                          <></>
                        )}

                        <div style={{ marginLeft: "5px", fontSize: "12px" }}>
                          ({product.stock})
                        </div>
                      </div>
                    </div>
                    <div style={{ width: "33%", paddingRight: "1%" }}>
                      <div style={{ fontSize: "12px", color: "lightgrey" }}>
                        Categories:
                      </div>
                      <div style={{ fontSize: "14px" }}>
                        {product.categories}
                      </div>
                    </div>

                    <div style={{ width: "33%", paddingRight: "1%" }}>
                      <div style={{ fontSize: "12px", color: "lightgrey" }}>
                        Tags:
                      </div>
                      <div style={{ fontSize: "14px" }}>{product.tags}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <>no data</>
          )}
        </div>
        <div style={{ textAlign: "center" }}>
          <button
            style={{
              background: "white",
              padding: "8px 25px",
              color: props.primaryColor,
              cursor: "pointer",
              borderRadius: "10px",
              border: "1px solid #d1ae6e",
              outline: "none",
              fontSize: "18px",
              marginRight: "10px",
            }}
            onClick={() => {
              setModalIsOpen(false);
              fileInputRef.current.value = "";
            }}
          >
            Cancel
          </button>
          <button
            style={{
              background: props.primaryColor,
              padding: "8px 25px",
              color: "white",
              cursor: "pointer",
              borderRadius: "10px",
              border: "none",
              outline: "none",
              fontSize: "18px",
            }}
            onClick={() => {
              handleProcessImportedData(importedData);
              setModalIsOpen(false);
              fileInputRef.current.value = "";
            }}
          >
            Import
          </button>
        </div>
      </Modal>
      <div
        style={{
          fontSize: "30px",
          fontWeight: "600",
          marginTop: "-35px",
          marginLeft: "20px",
          marginBottom: "15px",
          paddingBottom: "1%",
          color: "white",
          display: "flex",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: isFormVisible ? "30px" : "0px",
            marginTop: "7px",
            opacity: isFormVisible ? "1" : "0",
            transition: "all 0.4s ease-in-out",
            cursor: isFormVisible ? "pointer" : "none",
            marginLeft: isFormVisible ? "10px" : "0px",
          }}
          onClick={() => {
            setIsFormVisible(false);
            setSelectedProduct({
              img_src: "",
              name: "",
              description: "",
              sku: "",
              unit_price: "",
              weight: "",
              length: "",
              width: "",
              height: "",
              categories: "",
              tags: "",
              brand: "",
              size: "",
              stock: "",
              added_attribute: [],
              set_variation: false,
              variation: [],
            });
          }}
        >
          <ArrowBackIosNewIcon style={{ color: "white" }} />
        </div>
        <div
          style={{
            color: "white",
            fontSize: "30px",
            fontWeight: "600",
            marginLeft: isFormVisible ? "5px" : "0px",
            transition: "all 0.4s ease-in-out",
            cursor: isFormVisible ? "pointer" : "none",
          }}
          onClick={() => {
            setIsFormVisible(false);
            setSelectedProduct({
              img_src: "",
              name: "",
              description: "",
              sku: "",
              unit_price: "",
              weight: "",
              length: "",
              width: "",
              height: "",
              categories: "",
              tags: "",
              brand: "",
              size: "",
              stock: "",
              added_attribute: [],
              set_variation: false,
              variation: [],
            });
          }}
        >
          Products
        </div>
        <AddCircleIcon
          style={{
            marginTop: "0px",
            fontSize: "40px",
            marginRight: "20px",
            color: "white",
            marginLeft: "10px",
          }}
          onClick={() => {
            const attributeNamesFromOtherProducts = new Set();

            // Step 1: Extract unique attribute_name values from products with non-empty added_attributes
            if (allProduct) {
              allProduct.forEach((p) => {
                if (p.added_attribute && p.added_attribute.length > 0) {
                  p.added_attribute.forEach((attr) => {
                    attributeNamesFromOtherProducts.add(attr.attribute_name);
                  });
                }
              });

              // Convert the Set to an array
              const attributeNamesArray = [...attributeNamesFromOtherProducts];

              // Step 2: Add attributes with empty attribute_value to selectedProduct
              const updatedAddedAttributes = attributeNamesArray.map(
                (attrName) => ({
                  attribute_name: attrName,
                  attribute_value: "",
                })
              );

              setSelectedProduct({
                img_src: "",
                name: "",
                description: "",
                sku: "",
                unit_price: "",
                weight: "",
                length: "",
                width: "",
                height: "",
                categories: "",
                tags: "",
                brand: "",
                size: "",
                stock: "",
                added_attribute: updatedAddedAttributes,
                set_variation: false,
                variation: [],
              });
            } else {
              setSelectedProduct({
                img_src: "",
                name: "",
                description: "",
                sku: "",
                unit_price: "",
                weight: "",
                length: "",
                width: "",
                height: "",
                categories: "",
                tags: "",
                brand: "",
                size: "",
                stock: "",
                added_attribute: [],
                set_variation: false,
                variation: [],
              });
            }
            setIsFormVisible(true);
          }}
        />
      </div>
      {isFormVisible ? (
        <></>
      ) : (
        <input
          placeholder="Search all Products"
          type="text"
          value={searchQuery}
          onChange={handleSearchInputChange}
          style={{
            fontSize: "16px",
            padding: "10px 20px",
            width: "calc(94% - 40px)",
            border: "none",
            borderRadius: "10px",
            background: "rgba(255,255,255,1)",
            backdropFilter: "blur(20px)",
            border: "none",
            outline: "none",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
            marginLeft: "3%",
          }}
        />
      )}
      {/* <div>{selectedFilters}</div> */}

      {isFormVisible ? (
        <form
          onSubmit={handleSubmit}
          style={{
            width: "100%",
            height: "80vh",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <div
            style={{
              padding: "20px",
              width: "calc(94% - 40px)",
              marginLeft: "3%",
              borderRadius: "10px",
              border: "none",
              background: "rgba(255,255,255,1)",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
              backdropFilter: "blur(15px)",
            }}
          >
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <div
                style={{
                  color: props.primaryColor,
                  fontSize: "25px",
                  fontWeight: "600",
                  marginRight: "15px",
                }}
              >
                {selectedProduct.name}
              </div>
              <div
                style={{
                  color: "rgba(0,0,0,0.4)",
                  fontSize: "15px",
                  fontWeight: "400",
                }}
              >
                {selectedProduct.sku}
              </div>
            </div>
            <div style={{ fontSize: "18px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  marginTop: "25px",
                }}
              >
                <div style={{ marginRight: "25px" }} onClick={handleClick}>
                  <input
                    id="imageInput"
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleImageUpload}
                  />
                  {fileUploading ? (
                    <div
                      style={{
                        boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                        borderRadius: "10px",
                        width: "120px",
                        height: "120px",
                        objectFit: "cover",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress color="primary" size={50} style={{}} />
                    </div>
                  ) : selectedProduct.img_src ? (
                    <img
                      src={selectedProduct.img_src}
                      style={{
                        boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                        borderRadius: "10px",
                        width: "120px",
                        height: "120px",
                        objectFit: "cover",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                        borderRadius: "10px",
                        width: "120px",
                        height: "120px",
                        objectFit: "cover",
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FileUploadIcon
                        style={{ color: "lightgrey", fontSize: "50px" }}
                      />
                    </div>
                  )}
                </div>
                <div style={{ width: "100%" }}>
                  <div style={{ width: "100%", marginTop: "15px" }}>
                    <input
                      required
                      type="text"
                      name="name"
                      value={selectedProduct.name}
                      onChange={handleChange}
                      placeholder="Product name"
                      style={{
                        fontSize: "20px",
                        background: "none",
                        border: "1px solid lightgrey",
                        outline: "none",
                        borderRadius: "10px",
                        width: "calc(100% - 10px)",
                        color: "rgba(0,0,0,0.7)",
                        fontWeight: "600",
                        padding: "5px",
                      }}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                      width: "100%",
                    }}
                  >
                    <input
                      required
                      type="text"
                      name="sku"
                      placeholder="sku"
                      value={selectedProduct.sku}
                      onChange={handleChange}
                      style={{
                        fontSize: "16px",
                        background: "none",
                        border: "1px solid lightgrey",
                        outline: "none",
                        borderRadius: "10px",
                        width: "calc(100% - 10px)",
                        color: "rgba(0,0,0,0.7)",
                        fontWeight: "600",
                        padding: "5px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "15px",
                    }}
                  >
                    <div style={{ marginRight: "5px" }}>{currency} </div>
                    <input
                      required
                      type="number"
                      name="unit_price"
                      placeholder="0.00"
                      value={selectedProduct.unit_price}
                      onChange={handleChange}
                      style={{
                        fontSize: "16px",
                        background: "none",
                        border: "1px solid lightgrey",
                        outline: "none",
                        borderRadius: "10px",
                        width: "100px",
                        color: "rgba(0,0,0,0.7)",
                        fontWeight: "600",
                        padding: "5px 10px",
                        marginRight: "20px",
                      }}
                    />
                    <div>Variable Product:</div>
                    <input
                      type="checkbox"
                      checked={selectedProduct.set_variation}
                      onChange={(e) => {
                        const updatedProduct = { ...selectedProduct };
                        setSelectedProduct({
                          ...selectedProduct,
                          set_variation: e.target.checked,
                        });
                      }}
                      style={{
                        border: "1px solid lightgrey",
                        outline: "none",
                      }}
                    />
                  </div>
                  <div>
                    <textarea
                      className="remove_textarea_resize"
                      type="text"
                      name="description"
                      value={selectedProduct.description}
                      onChange={handleChange}
                      style={{
                        fontSize: "12px",
                        background: "none",
                        border: "1px solid lightgrey",
                        outline: "none",
                        borderRadius: "10px",
                        width: "calc(100% - 10px)",
                        color: "rgba(0,0,0,0.7)",
                        fontWeight: "400",
                        marginTop: "15px",
                        height: "120px",
                        padding: "5px",
                      }}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        marginRight: "5px",
                        width: "180px",
                        fontSize: "12px",
                      }}
                    >
                      Stock:
                    </div>
                    <input
                      type="text"
                      name="stock"
                      value={selectedProduct.stock}
                      onChange={handleChange}
                      style={{
                        fontSize: "14px",
                        background: "none",
                        border: "1px solid lightgrey",
                        outline: "none",
                        borderRadius: "10px",
                        width: "100px",
                        color: "rgba(0,0,0,0.7)",
                        fontWeight: "400",
                        marginTop: "15px",
                        padding: "5px",
                        marginRight: "5px",
                      }}
                    />
                    <div
                      className="blink"
                      style={{
                        width: "10px",
                        height: "10px",
                        background:
                          selectedProduct.stock < 10 ? "red" : "#0d8c22",
                        borderRadius: "50%",
                        boxShadow:
                          selectedProduct.stock < 10
                            ? "0px 0px 8px red"
                            : "0px 0px 8px #0d8c22",
                      }}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        marginRight: "5px",
                        width: "180px",
                        fontSize: "12px",
                      }}
                    >
                      Categories:
                    </div>
                    <input
                      type="text"
                      name="categories"
                      value={selectedProduct.categories}
                      onChange={handleChange}
                      style={{
                        fontSize: "14px",
                        background: "none",
                        border: "1px solid lightgrey",
                        outline: "none",
                        borderRadius: "10px",
                        width: "600px",
                        color: "rgba(0,0,0,0.7)",
                        fontWeight: "400",
                        marginTop: "15px",
                        padding: "5px",
                      }}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        marginRight: "5px",
                        width: "180px",
                        fontSize: "12px",
                      }}
                    >
                      Tags:
                    </div>
                    <input
                      type="text"
                      name="tags"
                      value={selectedProduct.tags}
                      onChange={handleChange}
                      style={{
                        fontSize: "14px",
                        background: "none",
                        border: "1px solid lightgrey",
                        outline: "none",
                        borderRadius: "10px",
                        width: "600px",
                        color: "rgba(0,0,0,0.7)",
                        fontWeight: "400",
                        marginTop: "15px",
                        padding: "5px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {selectedProduct.set_variation ? (
            <div
              style={{
                padding: "20px",
                width: "calc(94% - 40px)",
                marginLeft: "3%",
                borderRadius: "10px",
                border: "none",
                background: "rgba(255,255,255,1)",
                boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
                marginTop: "10px",
              }}
            >
              <div
                style={{
                  color: props.primaryColor,
                  fontSize: "22px",
                  fontWeight: "600",
                  marginRight: "15px",
                  borderBottom: "1px solid lightgrey",
                  paddingBottom: "5px",
                }}
              >
                Product Variation
              </div>
              <div
                style={{ width: "100%", height: "320px", overflowY: "scroll" }}
              >
                {Object.keys(selectedProduct.variation).length > 0 ? (
                  <div style={{ marginTop: "10px" }}>
                    {Object.keys(selectedProduct.variation).map(
                      (key, index) => (
                        <div key={index} style={{ marginTop: "20px" }}>
                          <div
                            style={{
                              color: "grey",
                              fontSize: "14px",
                              marginBottom: "5px",
                              display: "flex",
                            }}
                          >
                            <input
                              className="attribute_input"
                              type="text"
                              name="variation_key"
                              value={key}
                              placeholder="Variation name"
                              onChange={(e) => {
                                const newVariationKey = e.target.value;
                                setSelectedProduct((prevSelectedProduct) => {
                                  const updatedVariation = {
                                    ...prevSelectedProduct.variation,
                                  };
                                  updatedVariation[newVariationKey] =
                                    updatedVariation[key]; // Copy the values of the old key to the new key
                                  delete updatedVariation[key]; // Delete the old key
                                  return {
                                    ...prevSelectedProduct,
                                    variation: updatedVariation,
                                  };
                                });
                              }}
                              onBlur={() => console.log(key)}
                              style={{
                                fontSize: "14px",
                                background: "none",
                                border: "1px solid lightgrey",
                                outline: "none",
                                borderRadius: "10px",
                                width: "60%",
                                color: "rgba(0,0,0,0.7)",
                                fontWeight: "400",
                                padding: "10px",
                                marginRight: "10px",
                              }}
                            />
                            <div
                              style={{
                                background: props.primaryColor,
                                color: "white",
                                padding: "5px 20px",
                                borderRadius: "12px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "pointer",
                                marginRight: "20px",
                              }}
                              onClick={() => {
                                const updatedProduct = { ...selectedProduct };
                                const newVariation = {
                                  variation_value: "",
                                  variation_price: "",
                                };
                                updatedProduct.variation[key].push(
                                  newVariation
                                );
                                setSelectedProduct(updatedProduct);
                              }}
                            >
                              Create
                            </div>

                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <RemoveCircleOutlineIcon
                                className="hover_rotate"
                                onClick={() => {
                                  const updatedVariation = {
                                    ...selectedProduct.variation,
                                  };
                                  delete updatedVariation[key]; // Remove the specified key
                                  setSelectedProduct((prevSelectedProduct) => ({
                                    ...prevSelectedProduct,
                                    variation: updatedVariation,
                                  }));
                                }}
                                style={{
                                  fontSize: "28px",
                                  transition: "0.3s all ease-in-out",
                                }}
                              />
                            </div>
                          </div>
                          <div style={{ marginTop: "15px", fontSize: "14px" }}>
                            {selectedProduct.variation[key].map(
                              (variation, index) => (
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    alignItems: "center",
                                    marginTop: "5px",
                                  }}
                                >
                                  <input
                                    className="attribute_input"
                                    type="text"
                                    name="variation_value"
                                    value={variation.variation_value}
                                    placeholder="Variation value"
                                    onChange={(e) => {
                                      const updatedProductAttribute = {
                                        ...selectedProduct,
                                      };
                                      updatedProductAttribute.variation[key][
                                        index
                                      ].variation_value = e.target.value;

                                      setSelectedProduct(
                                        updatedProductAttribute
                                      );
                                    }}
                                    onBlur={() => console.log(selectedProduct)}
                                    style={{
                                      fontSize: "14px",
                                      background: "none",
                                      border: "1px solid lightgrey",
                                      outline: "none",
                                      borderRadius: "10px",
                                      width: "60%",
                                      color: "rgba(0,0,0,0.7)",
                                      fontWeight: "400",
                                      padding: "10px",
                                      marginRight: "20px",
                                    }}
                                  />{" "}
                                  {currency}
                                  <input
                                    className="attribute_input"
                                    type="number"
                                    name="variation_value"
                                    value={variation.variation_price}
                                    placeholder="Price dif."
                                    onChange={(e) => {
                                      const updatedProductAttribute = {
                                        ...selectedProduct,
                                      };
                                      updatedProductAttribute.variation[key][
                                        index
                                      ].variation_price = e.target.value;

                                      setSelectedProduct(
                                        updatedProductAttribute
                                      );
                                    }}
                                    onBlur={() => console.log(selectedProduct)}
                                    style={{
                                      fontSize: "14px",
                                      background: "none",
                                      border: "1px solid lightgrey",
                                      outline: "none",
                                      borderRadius: "10px",
                                      width: "60px",
                                      color: "rgba(0,0,0,0.7)",
                                      fontWeight: "400",
                                      padding: "10px",
                                      marginLeft: "10px",
                                      marginRight: "20px",
                                    }}
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <RemoveCircleOutlineIcon
                                      className="hover_rotate"
                                      onClick={() => {
                                        const updatedProduct = {
                                          ...selectedProduct,
                                        };
                                        updatedProduct.variation[key].splice(
                                          index,
                                          1
                                        );
                                        setSelectedProduct(updatedProduct);
                                      }}
                                      style={{
                                        fontSize: "28px",
                                        transition: "0.3s all ease-in-out",
                                      }}
                                    />
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )
                    )}
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "15px",
                      }}
                    >
                      <div
                        style={{
                          background: props.primaryColor,
                          width: "50px",
                          height: "50px",
                          borderRadius: "60px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          const updatedProductVariation = {
                            ...selectedProduct.variation,
                          };
                          updatedProductVariation[""] = []; // Add an empty array with an empty key
                          setSelectedProduct((prevSelectedProduct) => ({
                            ...prevSelectedProduct,
                            variation: updatedProductVariation, // Update the variation object
                          }));
                        }}
                      >
                        <AddIcon style={{ fontSize: "35px", color: "white" }} />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                    }}
                  >
                    <div
                      style={{
                        background: props.primaryColor,
                        width: "50px",
                        height: "50px",
                        borderRadius: "60px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        const updatedProductVariation = {
                          ...selectedProduct.variation,
                        };
                        updatedProductVariation[""] = []; // Add an empty array with an empty key
                        console.log(updatedProductVariation);
                        setSelectedProduct((prevSelectedProduct) => ({
                          ...prevSelectedProduct,
                          variation: updatedProductVariation, // Update the variation object
                        }));
                      }}
                    >
                      <AddIcon style={{ fontSize: "35px" }} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}

          <div
            style={{
              padding: "20px",
              width: "calc(94% - 40px)",
              marginLeft: "3%",
              borderRadius: "10px",
              border: "none",
              background: "rgba(255,255,255,1)",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
              marginTop: "10px",
            }}
          >
            <div
              style={{
                color: props.primaryColor,
                fontSize: "22px",
                fontWeight: "600",
                marginRight: "15px",
                borderBottom: "1px solid lightgrey",
                paddingBottom: "5px",
              }}
            >
              Product Attribute
            </div>
            <div
              style={{ width: "100%", height: "320px", overflowY: "scroll" }}
            >
              {selectedProduct.added_attribute.length > 0 ? (
                <div style={{ marginTop: "10px" }}>
                  {selectedProduct.added_attribute.map((attribute, index) => (
                    <div key={index} style={{ marginTop: "20px" }}>
                      <div
                        style={{
                          color: "grey",
                          fontSize: "14px",
                          marginBottom: "5px",
                        }}
                      >
                        Attribute {index + 1}
                      </div>
                      <div style={{ display: "flex", width: "100%" }}>
                        <input
                          className="attribute_input"
                          type="text"
                          name="attribute_name"
                          value={attribute.attribute_name}
                          placeholder="Attribute name"
                          onChange={(e) => {
                            const updatedProductAttribute = {
                              ...selectedProduct,
                            };
                            updatedProductAttribute.added_attribute[
                              index
                            ].attribute_name = e.target.value;

                            setSelectedProduct(updatedProductAttribute);
                          }}
                          style={{
                            fontSize: "14px",
                            background: "none",
                            border: "1px solid lightgrey",
                            outline: "none",
                            borderRadius: "10px",
                            width: "40%",
                            color: "rgba(0,0,0,0.7)",
                            fontWeight: "400",
                            padding: "10px",
                            marginRight: "10px",
                          }}
                        />

                        <input
                          className="attribute_input"
                          type="text"
                          name="attribute_value"
                          value={attribute.attribute_value}
                          placeholder="Attribute value"
                          onChange={(e) => {
                            const updatedProductAttribute = {
                              ...selectedProduct,
                            };
                            updatedProductAttribute.added_attribute[
                              index
                            ].attribute_value = e.target.value;

                            setSelectedProduct(updatedProductAttribute);
                          }}
                          style={{
                            fontSize: "14px",
                            background: "none",
                            border: "1px solid lightgrey",
                            outline: "none",
                            borderRadius: "10px",
                            width: "60%",
                            color: "rgba(0,0,0,0.7)",
                            fontWeight: "400",
                            padding: "10px",
                          }}
                        />
                      </div>
                    </div>
                  ))}
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "15px",
                    }}
                  >
                    <div
                      style={{
                        background: props.primaryColor,
                        width: "50px",
                        height: "50px",
                        borderRadius: "60px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        const updatedSelectedProduct = { ...selectedProduct };
                        const newProductAttribute = {
                          attribute_name: "",
                          attribute_value: "",
                        };
                        updatedSelectedProduct.added_attribute.push(
                          newProductAttribute
                        );
                        setSelectedProduct(updatedSelectedProduct);
                      }}
                    >
                      <AddIcon style={{ fontSize: "35px", color: "white" }} />
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "white",
                  }}
                >
                  <div
                    style={{
                      background: props.primaryColor,
                      width: "50px",
                      height: "50px",
                      borderRadius: "60px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      const updatedSelectedProduct = { ...selectedProduct };
                      const newProductAttribute = {
                        attribute_name: "",
                        attribute_value: "",
                      };
                      updatedSelectedProduct.added_attribute.push(
                        newProductAttribute
                      );
                      setSelectedProduct(updatedSelectedProduct);
                    }}
                  >
                    <AddIcon style={{ fontSize: "35px" }} />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
              width: "94%",
              marginLeft: "3%",
              marginTop: "10px",
              paddingBottom: "80px",
            }}
          >
            {allProduct ? (
              allProduct.some(
                (product) => product.sku === selectedProduct.sku
              ) ? (
                <button
                  type="submit"
                  style={{
                    background: props.primaryColor,
                    padding: "8px 25px",
                    color: "white",
                    cursor: "pointer",
                    borderRadius: "10px",
                    border: "none",
                    outline: "none",
                    fontSize: "18px",
                  }}
                >
                  Save
                </button>
              ) : (
                <button
                  type="submit"
                  style={{
                    background: props.primaryColor,
                    padding: "8px 25px",
                    color: "white",
                    cursor: "pointer",
                    borderRadius: "10px",
                    border: "none",
                    outline: "none",
                    fontSize: "18px",
                  }}
                >
                  Add product
                </button>
              )
            ) : (
              <button
                type="submit"
                style={{
                  background: props.primaryColor,
                  padding: "8px 25px",
                  color: "white",
                  cursor: "pointer",
                  borderRadius: "10px",
                  border: "none",
                  outline: "none",
                  fontSize: "18px",
                }}
              >
                Add product
              </button>
            )}
            <button
              onClick={(e) => {
                e.preventDefault();
                console.log("delete product");
                handleDeleteProduct();
              }}
              style={{
                background: "red",
                padding: "8px 25px",
                color: "white",
                cursor: "pointer",
                borderRadius: "10px",
                border: "none",
                outline: "none",
                fontSize: "18px",
              }}
            >
              Delete
            </button>
          </div>
        </form>
      ) : (
        <div
          style={{
            width: "94%",
            marginLeft: "3%",
            marginTop: "10px",
            height: "75vh",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "calc(100%)",
              overflowY: "scroll",
              background: "rgba(255,255,255,1)",
              backdropFilter: "blur(10px)",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
              borderRadius: "10px",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            <div
              style={{
                borderBottom: "1px solid lightgrey",
                width: "97%",
                height: "35px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "0 1%",
                fontSize: "12px",
              }}
            >
              <div
                style={{
                  width: "94%",
                  marginLeft: "3%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "60%" }}>Product</div>
                <div style={{ width: "30%" }}>Stock Status</div>
                <div style={{ width: "10%" }}>EzyLink</div>
              </div>
            </div>
            {searchedProducts.length > 0 ? (
              searchedProducts.map((product, index) => (
                <div
                  className="invoice_item"
                  key={index}
                  style={{
                    borderBottom:
                      index !== searchedProducts.length - 1
                        ? "1px solid lightgrey"
                        : "none",
                    width: "97%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "5px 1%",
                    fontSize: "12px",
                    color: "rgba(0,0,0,0.8)",
                  }}
                  onClick={() => {
                    const attributeNamesFromOtherProducts = new Set();

                    // Step 1: Extract unique attribute_name values from products with non-empty added_attributes
                    allProduct.forEach((p) => {
                      if (p.added_attribute && p.added_attribute.length > 0) {
                        p.added_attribute.forEach((attr) => {
                          attributeNamesFromOtherProducts.add(
                            attr.attribute_name
                          );
                        });
                      }
                    });

                    // Step 2: Add attributes with empty attribute_value to selectedProduct
                    const existingAttributeNames = new Set(
                      product.added_attribute.map((attr) => attr.attribute_name)
                    );

                    const attributesToAdd = Array.from(
                      attributeNamesFromOtherProducts
                    ).filter(
                      (attrName) => !existingAttributeNames.has(attrName)
                    );

                    const updatedAddedAttributes = [
                      ...product.added_attribute,
                      ...attributesToAdd.map((attrName) => ({
                        attribute_name: attrName,
                        attribute_value: "",
                      })),
                    ];

                    const updatedSelectedProduct = {
                      ...product,
                      added_attribute: updatedAddedAttributes,
                    };

                    setSelectedProduct(updatedSelectedProduct);
                    setIsFormVisible(true);
                  }}
                >
                  <div
                    style={{
                      width: "94%",
                      marginLeft: "3%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "60%",
                        display: "flex",
                        alignItems: "center",
                        paddingRight: "1%",
                      }}
                    >
                      <img
                        src={product.img_src}
                        style={{
                          height: "50px",
                          width: "50px",
                          objectFit: "cover",
                          border: "1px solid lightgrey",
                          borderRadius: "10px",
                          marginRight: "10px",
                        }}
                      />
                      <div
                        style={{
                          fontWeight: "700",
                          color: props.primaryColor,
                          fontSize: "13px",
                        }}
                      >
                        {product.name}
                      </div>
                    </div>
                    <div
                      style={{
                        width: "30%",
                        paddingRight: "1%",
                        display: "flex",
                      }}
                    >
                      {product.stock > 10 ? (
                        <div style={{ color: "#0d8c22", fontWeight: "600" }}>
                          In stock
                        </div>
                      ) : product.stock <= 10 && product.stock > 0 ? (
                        <div style={{ color: "#e62e2e", fontWeight: "600" }}>
                          Low stock
                        </div>
                      ) : (
                        <div style={{ color: "#e62e2e", fontWeight: "600" }}>
                          No stock
                        </div>
                      )}

                      <div style={{ marginLeft: "5px" }}>
                        ({product.stock ? product.stock : 0})
                      </div>
                    </div>
                    <div
                      style={{
                        width: "10%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <LeakAddIcon
                        onClick={(e) => {
                          e.stopPropagation();
                          const encodedSKU = encodeURIComponent(
                            product ? product.sku : ""
                          );
                          const url = `${modifiedURL}/ezylinkp?productId=${productId}&sku=${encodedSKU}`;
                          navigator.clipboard.writeText(url);
                          toast.info("Ezylink copied to clipboard!", {
                            position: "top-right",
                            autoClose: 1500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          });
                        }}
                        className="mui-button"
                        style={{ color: "rgba(0,0,0,0.8)" }}
                      />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div
                style={{
                  textAlign: "center",
                  color: "rgba(0,0,0,0.3)",
                  marginTop: "100px",
                  width: "100%",
                  fontSize: "20px",
                }}
              >
                <div>No Products Created</div>
                <div style={{ padding: "0px 40px", fontSize: "12px" }}>
                  Start creating your first product by clicking on the plus icon
                  above.
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default ProductMobile;
