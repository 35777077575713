import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "./images/CS_Favicon-01.png";
import DefaultProfile from "./images/defaultprofile.jpg";
import { ReactComponent as MyIcon } from "./icons/menu_1.svg";
import { ReactComponent as MyIcon1 } from "./icons/menu_2.svg";
import { ReactComponent as MyIcon2 } from "./icons/menu_3.svg";
import { ReactComponent as MyIcon3 } from "./icons/menu_4.svg";
import { ReactComponent as MyIcon4 } from "./icons/menu_5.svg";
import { ReactComponent as MyIcon5 } from "./icons/menu_6.svg";
import { ReactComponent as FolderIcon } from "./icons/folder.svg";
import Cookies from "js-cookie";
import Modal from "react-modal";
import React, { useState, useCallback } from "react";
import "./App.css";
import { useEffect } from "react";
import { MentionsInput, Mention } from "react-mentions";
import DashContent from "./DashContent";
import LogoutIcon from "@mui/icons-material/Logout";
import CRM from "./TaskContent";
import ProjectContent from "./ProjectContent";
import KanbanContent from "./KanbanContent";
import { ChromePicker } from "react-color";
import ProjectBarLoadout from "./ProjectBarLoadout";
import NotificationContent from "./NotificationContent";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SubscriptionContent from "./SubscriptionContent";
import GridViewIcon from "@mui/icons-material/GridView";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import SettingsIcon from "@mui/icons-material/Settings";
import AddIcon from "@mui/icons-material/Add";
import CrossIcon from "@mui/icons-material/Clear";
import Projects from "./Projects";
import { ListItemText, ListItemIcon } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/CheckCircleOutline";
import CheckedIcon from "@mui/icons-material/CheckCircle";
import Select from "@mui/material/Select";
import Settings from "./Settings";
import CalendarContent from "./CalendarContent";
import { GridView, SettingsInputComponentSharp } from "@mui/icons-material";
import { TwitterPicker } from "react-color";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import LanIcon from "@mui/icons-material/Lan";
import GroupsIcon from "@mui/icons-material/Groups";
import GroupIcon from "@mui/icons-material/Group";
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import WorkIcon from "@mui/icons-material/Work";
import HubIcon from "@mui/icons-material/Hub";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import TerminalIcon from "@mui/icons-material/Terminal";
import PaymentIcon from "@mui/icons-material/Payment";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import HandshakeIcon from "@mui/icons-material/Handshake";
import HandymanIcon from "@mui/icons-material/Handyman";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import EmailIcon from "@mui/icons-material/Email";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import DescriptionIcon from "@mui/icons-material/Description";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import BusinessIcon from "@mui/icons-material/Business";
import CallIcon from "@mui/icons-material/Call";
import CalculateIcon from "@mui/icons-material/Calculate";
import CookieIcon from "@mui/icons-material/Cookie";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import HeadsetIcon from "@mui/icons-material/Headset";
import PreviewSkeleton from "./PreviewSkeleton";
import DashboardMobile from "./DashboardMobile";
import SettingsMobile from "./SettingsMobile";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import KommunicateChat from "./chat";
import InventoryIcon from "@mui/icons-material/Inventory";
import ShareIcon from "@mui/icons-material/Share";
import MarkUnreadChatAltIcon from "@mui/icons-material/MarkUnreadChatAlt";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import MyClient from "./MyClient";
import SalesLead from "./SalesLead";
import WebIcon from "@mui/icons-material/Web";
import WebCentral from "./WebCentral";
import Products from "./Products";
import ListIcon from "@mui/icons-material/List";
import Forms from "./Forms";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import Events from "./Events";
import News from "./News";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import { Particles } from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";
import Orders from "./Orders";
import StoreIcon from "@mui/icons-material/Store";
import { v4 as uuid } from "uuid";

function Dashboard() {
  const [isMobile, setIsMobile] = useState(false);

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    // Initial check
    handleResize();

    // Attach event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const recordAnalytics = async () => {
      const generateduuid = `${Date.now().toString()}` + `${uuid()}`;

      const userAgent = window.navigator.userAgent.toLowerCase();
      const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/.test(
        userAgent
      );
      try {
        // Check if the user has a cookie named 'user_id'
        const userId = Cookies.get("analytics");

        if (userId) {
          // Existing user logic
          console.log("Existing User");
        } else {
          // New user logic
          console.log("New User");

          // Set a cookie named 'analytics' with an expiration of 7 days
          Cookies.set("analytics", generateduuid, { expires: 7 });
        }

        const response = await fetch(
          "https://ww1.premelo.com/api/record-analytics",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              page: `dashboard ${isMobile ? "mobile" : null} page`,
              user: userId ? "unique" : "new",
            }),
          }
        );
        const json = await response.json();
        console.log(json);

        // Handle the response as needed
      } catch (error) {
        console.log(error);
      }
    };

    recordAnalytics();
  }, []);
  const [showBot, setShowBot] = useState(false);
  const handleChatClick = () => {
    setShowBot(!showBot);
  };
  const [primaryColor, setPrimaryColor] = useState(
    Cookies.get("company_color") ?? "#1872d9"
  );
  const [companyLogo, setCompanyLogo] = useState(
    Cookies.get("company_logo") ?? null
  );
  const [domain, setDomain] = useState("");
  const token = Cookies.get("token");
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState("");
  const [userId, setUserId] = useState("");
  const [profile_photo, setProfilePhoto] = useState(DefaultProfile);
  const [showText, setShowText] = useState(false);
  const [projectTitle, setProjectTitle] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [availableUsersWithEmail, setAvailableUsersWithEmail] = useState([]);

  const [availableUsers, setAvailableUsers] = useState([]);
  const [taskAssignedTo, setTaskAssignedTo] = useState("");
  const [paymentContentFormIsOpen, setPaymentContentFormIsOpen] =
    useState(false);
  const [projectSelectedUsers, setProjectSelectedUsers] = useState([]);
  const [projectType, setProjectType] = useState("");

  const [copied, setCopied] = useState(false);
  const [personalCompanyName, setPersonalCompanyName] = useState("");
  const [menuItems, setMenuItems] = useState([
    {
      label: "Dashboard",
      path: "/dashboard",
    },
    {
      label: "Projects",
      path: "/projects",
    },
    {
      label: "Calendar",
      path: "/calendar",
    },
    {
      label: "Notification",
      path: "/notification",
    },
    {
      label: "My Client",
      path: "/myclient",
    },
    {
      label: "Sales Lead",
      path: "/saleslead",
    },
    {
      label: "Web Builder",
      path: "/webbuilder",
    },
    {
      label: "Products",
      path: "/products",
    },
    {
      label: "Orders",
      path: "/orders",
    },
    {
      label: "Forms",
      path: "/forms",
    },
    {
      label: "Events",
      path: "/events",
    },
    {
      label: "News",
      path: "/news",
    },
  ]);
  const secondaryColor = `rgba(${
    parseInt(primaryColor?.slice(1, 3), 16) ?? 0
  }, ${parseInt(primaryColor?.slice(3, 5), 16) ?? 0}, ${
    parseInt(primaryColor?.slice(5, 7), 16) ?? 0
  }, 0.05)`;

  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    //await loadFull(engine);
    await loadSlim(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  const primaryRGB = primaryColor
    ? primaryColor
        .substring(1)
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
    : [0, 0, 0];

  const complementaryRGB = primaryRGB.map((color) => 255 - color);
  const complementaryColor = `rgb(${complementaryRGB.join(",")})`;
  const handleCopyClick = () => {
    navigator.clipboard.writeText(domain);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  const handleCopyNotClick = () => {
    setCopied(false);
  };
  const handleProjectTypeChange = (event) => {
    const { value } = event.target;
    setProjectType(value);
  };
  const [companyDomain, setCompanyDomain] = useState("");
  const fetchUsersDetails = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/profile-settings-details",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    const dotComIndex = window.location.hostname;
    const encodedCompanyName = encodeURIComponent(data.company_name);
    const encodedColor = encodeURIComponent(primaryColor);
    {
      primaryColor
        ? setDomain(
            `https://${dotComIndex}/signup?companyname=${encodedCompanyName}&companycolor=${encodedColor}`
          )
        : setDomain(
            `https://${dotComIndex}/signup?companyname=${encodedCompanyName}`
          );
    }
    // setDomain(
    //   `http://${dotComIndex}/signup?companyname=${encodedCompanyName}&companycolor=${encodedColor}`
    // );
  };
  const fetchCompanyDetails = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/company-details",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    console.log(data.company_logo);
    const logoUrl = data.company_logo;
    setCompanyLogo(logoUrl);
    setCompanyDomain(data.company_domain);
    console.log(data.company_domain);

    if (data.company_color) {
      Cookies.set("company_color", data.company_color);
      setPrimaryColor(data.company_color);
    }
    if (logoUrl) {
      Cookies.set("company_logo", logoUrl);
      setCompanyLogo(logoUrl);
    }
  };

  const fetchUsersWithEmail = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/user-available-team-members-with-email",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    const modifiedData = data.map((user) => {
      return {
        ...user,
        fullName: `${user.first_name} ${user.last_name}`,
      };
    });
    setAvailableUsersWithEmail(modifiedData);
    setAvailableUsers(modifiedData);
  };

  const getIcon = (label) => {
    switch (label) {
      case "Dashboard":
        return (
          <GridViewIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
              fontSize: "21px",
            }}
          />
        );
      case "Projects":
        return (
          <ContentCopyIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
              fontSize: "21px",
            }}
          />
        );
      case "News":
        return (
          <NewspaperIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
              fontSize: "21px",
            }}
          />
        );
      case "Calendar":
        return (
          <CalendarTodayIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
              fontSize: "21px",
            }}
          />
        );
      case "Kanban":
        return (
          <MyIcon3
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
              fontSize: "21px",
            }}
          />
        );
      case "Notification":
        return (
          <NotificationsNoneIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
              fontSize: "21px",
            }}
          />
        );
      case "Subscription":
        return (
          <MonetizationOnIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
              fontSize: "21px",
            }}
          />
        );
      case "Logout":
        return (
          <SettingsIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              width: "40px",
              color: "rgba(0,0,0,.6)",
              fontSize: "21px",
            }}
          />
        );
      case "My Client":
        return (
          <RecentActorsIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
              fontSize: "21px",
            }}
          />
        );
      case "Forms":
        return (
          <ListIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
              fontSize: "21px",
            }}
          />
        );
      case "Sales Lead":
        return (
          <ContactPhoneIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
              fontSize: "21px",
            }}
          />
        );
      case "Web Builder":
        return (
          <WebIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
              fontSize: "21px",
            }}
          />
        );
      case "Products":
        return (
          <InventoryIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
              fontSize: "21px",
            }}
          />
        );
      case "Orders":
        return (
          <StoreIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
              fontSize: "21px",
            }}
          />
        );
      case "Events":
        return (
          <LocalActivityIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              width: "40px",
              color: "rgba(0,0,0,.4)",
              fontSize: "21px",
            }}
          />
        );
      default:
        return null;
    }
  };

  const [notifications, setNotifications] = useState([]);
  const unreadNotifications = notifications.filter(
    (notification) => !notification.read
  ).length;
  const [userCount, setUserCount] = useState("8");
  const getActiveSubscription = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/getActiveSubscription",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 404) {
      console.log("No active subscription found.");
    } else {
      const data = await response.json();
      setUserCount(data.quantity);
    }
  };
  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/.test(
      userAgent
    );
    setIsMobile(isMobile);
    getActiveSubscription();
    fetchCompanyDetails();
    fetchUsersDetails();
    const token = Cookies.get("token");
    if (!token) {
      Cookies.remove("token");
      navigate("/login"); // programmatically navigate to /home
    }
    if (token) {
      axios
        .get("/api/user-data", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          const { name, is_admin, menu_items, profile_photo } = response.data;
          setName(name);
          setIsAdmin(is_admin ? true : false);
          console.log(is_admin ? true : false);
          // setMenuItems(menu_items);
          setProfilePhoto(profile_photo);
          {
            is_admin
              ? setMenuItems([
                  {
                    label: "Dashboard",
                    path: "/dashboard",
                  },
                  {
                    label: "Projects",
                    path: "/projects",
                  },
                  {
                    label: "Calendar",
                    path: "/calendar",
                  },
                  {
                    label: "Notification",
                    path: "/notification",
                  },
                  // {
                  //   label: "Subscription",
                  //   path: "/subscription",
                  // },
                  {
                    label: "My Client",
                    path: "/myclient",
                  },
                  {
                    label: "Sales Lead",
                    path: "/saleslead",
                  },
                  {
                    label: "Web Builder",
                    path: "/webbuilder",
                  },
                  {
                    label: "Products",
                    path: "/products",
                  },
                  {
                    label: "Orders",
                    path: "/orders",
                  },
                  {
                    label: "Forms",
                    path: "/forms",
                  },
                  {
                    label: "Events",
                    path: "/events",
                  },
                  {
                    label: "News",
                    path: "/news",
                  },
                ])
              : setMenuItems([
                  {
                    label: "Dashboard",
                    path: "/dashboard",
                  },
                  {
                    label: "Projects",
                    path: "/projects",
                  },
                  {
                    label: "Calendar",
                    path: "/calendar",
                  },
                  {
                    label: "Notification",
                    path: "/notification",
                  },
                  {
                    label: "My Client",
                    path: "/myclient",
                  },
                  {
                    label: "Sales Lead",
                    path: "/saleslead",
                  },
                  {
                    label: "Web Builder",
                    path: "/webbuilder",
                  },
                  {
                    label: "Products",
                    path: "/products",
                  },
                  {
                    label: "Orders",
                    path: "/orders",
                  },
                  {
                    label: "Forms",
                    path: "/forms",
                  },
                  {
                    label: "Events",
                    path: "/events",
                  },
                  {
                    label: "News",
                    path: "/news",
                  },
                ]);
          }
        })
        .catch((error) => {
          console.error(error);
          Cookies.remove("token");
          navigate("/home"); // programmatically navigate to /home
        });
    }
    fetchCompanyProjects();
    fetchUserNotification();
    fetchUsersWithEmail();
    console.log(isAdmin);
  }, []);

  // setInterval(() => {
  //   fetchUserNotification();
  // }, 30000);

  const [name, setName] = useState("");
  const [LogoutModal, setLogoutModal] = useState(false);
  const openModal = () => {
    setLogoutModal(true); // Show the modal
  };

  const handleColorChange = (newColor) => {
    setPrimaryColor(newColor.hex);
  };

  const handleLogout = () => {
    axios
      .post(
        "https://ww1.premelo.com/api/logout",
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        Cookies.remove("token");
        navigate("/home"); // programmatically navigate to /home
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleProjectListUpdate = (projects) => {
    setUserProjects(projects);
  };

  const handleProjectListSelected = (
    projectId,
    projectName,
    projectColor,
    projectIcon
  ) => {
    handleProjectView(projectId, projectName, projectColor, projectIcon);
  };
  const [projectFormModalIsOpen, setProjectFormModalIsOpen] = useState(false);
  const openProjectFormModal = () => {
    setProjectFormModalIsOpen(true); // Show the modal
  };

  const [userProjects, setUserProjects] = useState([]);
  const [selectedLink, setSelectedLink] = useState(
    localStorage.getItem("selectedLink") || "/dashboard"
  );

  useEffect(() => {
    // Store the selectedLink value in localStorage whenever it changes
    localStorage.setItem("selectedLink", selectedLink);
  }, [selectedLink]);

  const [rightColumnContent, setRightColumnContent] = useState(
    <DashContent
      primaryColor={primaryColor}
      complementaryColor={complementaryColor}
      secondaryColor={secondaryColor}
      onProjectListChange={handleProjectListUpdate}
      onProjectListSelected={handleProjectListSelected}
      userProjects={userProjects}
      openProjectFormModal={openProjectFormModal}
    />
  );
  const [selectedProject, setSelectedProject] = useState("");
  const [activeProject, setActiveProject] = useState(
    localStorage.getItem("activeProject") || ""
  );

  useEffect(() => {
    localStorage.setItem("activeProject", activeProject);
  }, [activeProject]);

  const [activeProjectId, setActiveProjectId] = useState(
    localStorage.getItem("activeProjectId") || ""
  );

  useEffect(() => {
    localStorage.setItem("activeProjectId", activeProjectId);
  }, [activeProjectId]);

  const [activeProjectColor, setActiveProjectColor] = useState(
    localStorage.getItem("activeProjectColor") || ""
  );

  useEffect(() => {
    localStorage.setItem("activeProjectColor", activeProjectColor);
  }, [activeProjectColor]);

  const [activeProjectIcon, setActiveProjectIcon] = useState(
    localStorage.getItem("activeProjectIcon") || ""
  );

  useEffect(() => {
    localStorage.setItem("activeProjectIcon", activeProjectIcon);
  }, [activeProjectIcon]);

  const handleProjectButtonClick = async (e) => {
    e.preventDefault();
    const token = Cookies.get("token");

    // Get the user ID based on the selected full name
    const selectedUser = availableUsers.find(
      (user) => user.fullName === taskAssignedTo
    );
    const projectAssignedToId = selectedUser ? selectedUser.id : "";

    const newProject = {
      project_assigned_to: projectSelectedUsers,
      project_name: projectTitle,
      project_description: projectDescription,
      project_type: projectType,
      project_color: projectColor,
      project_icon: projectIconSelected,
    };

    try {
      const response = await fetch("https://ww1.premelo.com/api/add-project", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newProject),
      });

      if (response.ok) {
        console.log("Project added successfully");
      } else {
        console.error("Error adding task");
      }
    } catch (error) {
      console.error("Error adding task:", error);
    }
    fetchCompanyProjects();
    setProjectFormModalIsOpen(false);
  };

  const fetchCompanyProjects = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/retrieve-company-projects",
      {
        headers: {
          Accept: `application/json`,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    console.log(data.projects);
    setUserProjects(data.projects);
  };

  useEffect(() => {
    console.log("selected link changed");
    switch (selectedLink) {
      case "/dashboard":
        setRightColumnContent(
          <DashContent
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            secondaryColor={secondaryColor}
            onProjectListChange={handleProjectListUpdate}
            onProjectListSelected={handleProjectListSelected}
            openProjectFormModal={openProjectFormModal}
            userProjects={userProjects}
          />
        );
        break;
      case "/projects":
        setRightColumnContent(
          <Projects
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            secondaryColor={secondaryColor}
            activeProject={activeProject}
            activeProjectId={activeProjectId}
            activeProjectColor={activeProjectColor}
            activeProjectIcon={activeProjectIcon}
            onProjectListChange={handleProjectListUpdate}
            onProjectListSelected={handleProjectListSelected}
            openProjectFormModal={openProjectFormModal}
          />
        );
        break;
      case "/calendar":
        setRightColumnContent(
          <CalendarContent
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            secondaryColor={secondaryColor}
          />
        );
        break;
      case "/kanban":
        setRightColumnContent(
          <KanbanContent
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            secondaryColor={secondaryColor}
          />
        );
        break;
      case "/notification":
        setRightColumnContent(
          <NotificationContent
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            secondaryColor={secondaryColor}
          />
        );
        break;
      case "/forms":
        setRightColumnContent(
          <Forms
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            secondaryColor={secondaryColor}
          />
        );
        break;
      case "/subscription":
        setRightColumnContent(
          <SubscriptionContent
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            secondaryColor={secondaryColor}
          />
        );
        break;
      case "/task":
        setRightColumnContent(
          <ProjectBarLoadout
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            secondaryColor={secondaryColor}
            activeProject={activeProject}
            activeProjectId={activeProjectId}
            activeProjectColor={activeProjectColor}
            activeProjectIcon={activeProjectIcon}
            onProjectListChange={handleProjectListUpdate}
            onProjectListSelected={handleProjectListSelected}
            onProjectDelete={() =>
              setRightColumnContent(
                <DashContent
                  primaryColor={primaryColor}
                  complementaryColor={complementaryColor}
                  secondaryColor={secondaryColor}
                  onProjectListChange={handleProjectListUpdate}
                  onProjectListSelected={handleProjectListSelected}
                  openProjectFormModal={openProjectFormModal}
                  userProjects={userProjects}
                />
              )
            }
          />
        );
        break;
      case "/settings":
        setRightColumnContent(
          <Settings
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            secondaryColor={secondaryColor}
            availableUsersWithEmail={availableUsersWithEmail}
            profileImageUrl={profile_photo}
            companyImageUrl={companyLogo}
            isAdmin={isAdmin}
            companyDomain={companyDomain}
          />
        );
        break;
      case "/myclient":
        setRightColumnContent(
          <MyClient
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            secondaryColor={secondaryColor}
          />
        );
        break;
      case "/events":
        setRightColumnContent(
          <Events
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            secondaryColor={secondaryColor}
          />
        );
        break;
      case "/saleslead":
        setRightColumnContent(
          <SalesLead
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            secondaryColor={secondaryColor}
          />
        );
        break;
      case "/webbuilder":
        setRightColumnContent(
          <WebCentral
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            secondaryColor={secondaryColor}
          />
        );
        break;
      case "/products":
        setRightColumnContent(
          <Products
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            secondaryColor={secondaryColor}
          />
        );
        break;
      case "/orders":
        setRightColumnContent(
          <Orders
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            secondaryColor={secondaryColor}
          />
        );
        break;
      case "/news":
        setRightColumnContent(
          <News
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            secondaryColor={secondaryColor}
          />
        );
        break;
      default:
        setRightColumnContent(
          <DashContent
            primaryColor={primaryColor}
            complementaryColor={complementaryColor}
            secondaryColor={secondaryColor}
            onProjectListChange={handleProjectListUpdate}
            onProjectListSelected={handleProjectListSelected}
            openProjectFormModal={openProjectFormModal}
            userProjects={userProjects}
          />
        );
    }
  }, [
    selectedLink,
    Cookies.get("selectedProjectId"),
    Cookies.get("selectedProjectName"),
  ]);

  const projectIconResized = (label) => {
    switch (label) {
      case "Project1":
        return (
          <DocumentScannerIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project2":
        return (
          <LanIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project3":
        return (
          <GroupsIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project4":
        return (
          <GroupIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project5":
        return (
          <AccessibilityNewIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project6":
        return (
          <AccountTreeIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project7":
        return (
          <AirplanemodeActiveIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project8":
        return (
          <ArchitectureIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project9":
        return (
          <AssignmentIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project10":
        return (
          <AudiotrackIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project11":
        return (
          <AccountBalanceIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project12":
        return (
          <WorkIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project13":
        return (
          <HubIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project14":
        return (
          <VolunteerActivismIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project15":
        return (
          <TerminalIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project16":
        return (
          <PaymentIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project17":
        return (
          <CameraAltIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project18":
        return (
          <ReportProblemIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project19":
        return (
          <Diversity3Icon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project20":
        return (
          <SportsEsportsIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project21":
        return (
          <LocalShippingIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project22":
        return (
          <RocketLaunchIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project23":
        return (
          <HandshakeIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project24":
        return (
          <HandymanIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project25":
        return (
          <SelfImprovementIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project26":
        return (
          <ElectricBoltIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project27":
        return (
          <EmailIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project28":
        return (
          <EscalatorWarningIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project29":
        return (
          <DescriptionIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project30":
        return (
          <DirectionsBoatIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project31":
        return (
          <BusinessIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project32":
        return (
          <CallIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project33":
        return (
          <CalculateIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project34":
        return (
          <CookieIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project35":
        return (
          <DesignServicesIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );
      case "Project36":
        return (
          <HeadsetIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "40px",
            }}
          />
        );

      default:
        return null;
    }
  };

  const openNotification = () => {
    setSelectedLink("/notification");
  };

  const openSettings = () => {
    setSelectedLink("/settings");
  };

  const handleProjectView = (
    projectId,
    projectName,
    projectColor,
    projectIcon
  ) => {
    setSelectedLink("/task");
    Cookies.set("selectedProjectId", projectId);
    Cookies.set("selectedProjectName", projectName);
    Cookies.set("selectedProjectIcon", projectIcon);
    Cookies.set("selectedProjectColor", projectColor);
    setActiveProject(projectName); // set the active project in Dashboard state
    setActiveProjectId(projectId); // set the active project in Dashboard state
    setActiveProjectColor(projectColor); // set the active project in Dashboard state
    setActiveProjectIcon(projectIcon); // set the active project in Dashboard state
    console.log("click on project", projectIcon);
  };

  const fetchUserNotification = async () => {
    const token = Cookies.get("token");
    const response = await fetch("https://ww1.premelo.com/api/notifications", {
      headers: {
        Accept: `application/json`,
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    console.log("notifications:", data);
    setNotifications(data);
  };

  const [projectIconSelected, setProjectIconSelected] = useState("Project1");

  const [selectIcon, setSelectIcon] = useState(false);
  const [projectColor, setProjectColor] = useState(primaryColor);
  const projectIcon = (label) => {
    switch (label) {
      case "Project1":
        return (
          <DocumentScannerIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project2":
        return (
          <LanIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project3":
        return (
          <GroupsIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project4":
        return (
          <GroupIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project5":
        return (
          <AccessibilityNewIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project6":
        return (
          <AccountTreeIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project7":
        return (
          <AirplanemodeActiveIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project8":
        return (
          <ArchitectureIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project9":
        return (
          <AssignmentIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project10":
        return (
          <AudiotrackIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project11":
        return (
          <AccountBalanceIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project12":
        return (
          <WorkIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project13":
        return (
          <HubIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project14":
        return (
          <VolunteerActivismIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project15":
        return (
          <TerminalIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project16":
        return (
          <PaymentIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project17":
        return (
          <CameraAltIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project18":
        return (
          <ReportProblemIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project19":
        return (
          <Diversity3Icon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project20":
        return (
          <SportsEsportsIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project21":
        return (
          <LocalShippingIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project22":
        return (
          <RocketLaunchIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project23":
        return (
          <HandshakeIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project24":
        return (
          <HandymanIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project25":
        return (
          <SelfImprovementIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project26":
        return (
          <ElectricBoltIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project27":
        return (
          <EmailIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project28":
        return (
          <EscalatorWarningIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project29":
        return (
          <DescriptionIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project30":
        return (
          <DirectionsBoatIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project31":
        return (
          <BusinessIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project32":
        return (
          <CallIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project33":
        return (
          <CalculateIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project34":
        return (
          <CookieIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project35":
        return (
          <DesignServicesIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );
      case "Project36":
        return (
          <HeadsetIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              fontSize: "60px",
            }}
          />
        );

      default:
        return null;
    }
  };

  const handleSelectIcon = () => {
    setSelectIcon(true);
  };

  const handleProjectIconSelected = (iconLabel) => {
    setProjectIconSelected(iconLabel);
    setSelectIcon(false);
  };

  const [domainCompany, setDomainCompany] = useState("");
  const handleOpenModal = () => {
    setPaymentContentFormIsOpen(true);
    setDomainCompany(personalCompanyName);
    console.log(personalCompanyName);
  };

  const projectFormModalContent = (
    <div style={{ height: "80vh" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginRight: "20px",
          marginTop: "20px",
        }}
      >
        <CrossIcon
          className="mui-button"
          style={{ fontSize: "40px" }}
          onClick={() => setProjectFormModalIsOpen(false)}
        />
      </div>
      <form>
        <div
          style={{
            display: "flex",
            wdith: "100%",
            height: "80vh",
            marginTop: "40px",
          }}
        >
          {/* Left column to input */}
          <div
            style={{
              height: "100%",
              width: "35%",
              paddingLeft: "5%",
              marginTop: "-40px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
            }}
          >
            <div style={{ display: "flex", alignItems: "end" }}>
              <div
                className="mui-button"
                style={{
                  marginTop: "20%",
                  width: "90px",
                  height: "90px",
                  borderRadius: "20px",
                  backgroundColor: projectColor,
                  color: "white",
                  marginRight: "25px",
                }}
                onClick={handleSelectIcon}
              >
                <div style={{ marginTop: "16px", marginLeft: "16px" }}>
                  {projectIcon(projectIconSelected)}
                </div>
              </div>
              <TwitterPicker
                style={{
                  width: "80px",
                  boxShadow: "none",
                  marginBottom: "-100px",
                }}
                onChange={(color, event) => setProjectColor(color.hex)}
              />
            </div>
            <div
              style={{
                fontSize: "14px",
                color: "rgba(0,0,0,0.7)",
              }}
            >
              <h1>New project</h1>
            </div>
            <div
              style={{
                justifyContent: "flex-start",
                alignItems: "center",
                width: "80%",
                marginBottom: "20px",
              }}
            >
              <div style={{ color: "rgba(0,0,0,0.4)", fontSize: "10px" }}>
                Project name:
              </div>
              <div>
                <input
                  style={{
                    height: "30px",
                    width: "80%",
                    border: "1px solid rgba(0,0,0,0.2)",
                    borderRadius: "10px",
                    padding: "0px 15px",
                    fontSize: "12px",
                  }}
                  type="text"
                  id="projectTitle"
                  value={projectTitle}
                  onChange={(e) => setProjectTitle(e.target.value)}
                />
              </div>
            </div>
            <div style={{ marginTop: "-10px" }}>
              <div style={{ color: "rgba(0,0,0,0.4)", fontSize: "10px" }}>
                Project type:
              </div>
              <div>
                <Select
                  style={{
                    width: "50%",
                    height: "30px",
                    borderRadius: "10px",
                    fontSize: "14px",
                    fontSize: "12px",
                  }}
                  value={projectType}
                  onChange={handleProjectTypeChange}
                >
                  <MenuItem
                    value={"Creative request"}
                    style={{ fontSize: "12px" }}
                  >
                    Creative request
                  </MenuItem>
                  <MenuItem
                    value={"Team management"}
                    style={{ fontSize: "12px" }}
                  >
                    Team management
                  </MenuItem>
                  <MenuItem
                    value={"Programming setup"}
                    style={{ fontSize: "12px" }}
                  >
                    Programming setup
                  </MenuItem>
                  <MenuItem
                    value={"Leads management"}
                    style={{ fontSize: "12px" }}
                  >
                    Leads management
                  </MenuItem>
                </Select>
              </div>
            </div>
            <div style={{ marginTop: "30px" }}>
              <button
                className="mui-button"
                style={{
                  backgroundColor: primaryColor,
                  color: "#ffffff",
                  fontWeight: "600",
                  padding: "8px 25px",
                  borderRadius: "10px",
                  border: "none",
                  fontSize: "12px",
                }}
                onClick={handleProjectButtonClick}
              >
                Create Project
              </button>
            </div>
          </div>
          {/* Right column to display preview */}
          <div
            style={{
              height: "100%",
              width: "60%",
              marginLeft: "-40px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
              borderRadius: "20px",
              marginTop: "-20px",
            }}
          >
            {selectIcon ? (
              <div style={{ marginTop: "50px", marginLeft: "50px" }}>
                <div
                  style={{
                    color: "rgba(0,0,0,0.7)",
                    fontSize: "25px",
                    borderBottom: "2px solid rgba(0,0,0,0.2)",
                    width: "70%",
                    paddingBottom: "20px",
                    marginBottom: "20px",
                  }}
                >
                  Select an Icon for your Project.
                </div>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    color: "rgba(0,0,0,0.5)",
                    width: "800px",
                    gap: "20px",
                  }}
                >
                  <DocumentScannerIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project1")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <LanIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project2")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <GroupsIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project3")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <GroupIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project4")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <AccessibilityNewIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project5")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <AccountTreeIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project6")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <AirplanemodeActiveIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project7")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <ArchitectureIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project8")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <AssignmentIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project9")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <AudiotrackIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project10")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <AccountBalanceIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project11")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <WorkIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project12")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <HubIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project13")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <VolunteerActivismIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project14")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <TerminalIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project15")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <PaymentIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project16")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <CameraAltIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project17")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <ReportProblemIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project18")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <Diversity3Icon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project19")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <SportsEsportsIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project20")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <LocalShippingIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project21")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <RocketLaunchIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project22")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <HandshakeIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project23")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <HandymanIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project24")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <SelfImprovementIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project25")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <ElectricBoltIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project26")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <EmailIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project27")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <EscalatorWarningIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project28")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <DescriptionIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project29")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <DirectionsBoatIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project30")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <BusinessIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project31")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <CallIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project32")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <CalculateIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project33")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <CookieIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project34")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <DesignServicesIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project35")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                  <HeadsetIcon
                    className="mui-button"
                    onClick={() => handleProjectIconSelected("Project36")}
                    style={{
                      marginTop: "-3px",
                      marginRight: "10px",
                      fontSize: "60px",
                    }}
                  />
                </div>
              </div>
            ) : (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    marginTop: "20px",
                    marginLeft: "20px",
                  }}
                >
                  <div
                    style={{
                      background: projectColor,
                      height: "60px",
                      width: "60px",
                      borderRadius: "10px",
                      color: "white",
                    }}
                  >
                    <div style={{ marginTop: "12px", marginLeft: "10px" }}>
                      {projectIconResized(projectIconSelected)}
                    </div>
                  </div>
                  <div style={{ marginLeft: "20px" }}>
                    <div
                      style={{
                        fontSize: "22px",
                        fontWeight: "600",
                        color: "rgba(0,0,0,0.7)",
                        marginTop: "4px",
                      }}
                    >
                      {projectTitle ? projectTitle : "Project Name"}
                    </div>
                    <div
                      style={{
                        fontSize: "10px",
                        fontWeight: "400",
                        color: "rgba(0,0,0,0.7)",
                        marginTop: "-5px",
                      }}
                    >
                      Lorem Ipsum is simply dummy text.
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    marginLeft: "2%",
                    width: "96%",
                    borderBottom: "1px solid rgba(0,0,0,0.1)",
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "12px",
                      marginRight: "20px",
                      marginLeft: "5px",
                    }}
                  >
                    Task
                  </div>
                  <div
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "12px",
                      marginRight: "20px",
                    }}
                  >
                    Kanban
                  </div>
                  <div
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "12px",
                      marginRight: "20px",
                    }}
                  >
                    Calendar
                  </div>
                  <div
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "12px",
                      marginRight: "20px",
                    }}
                  >
                    Workflow
                  </div>
                  <div
                    style={{
                      color: "rgba(0,0,0,0.5)",
                      fontSize: "12px",
                      marginRight: "20px",
                    }}
                  >
                    Repository
                  </div>
                </div>
                <div style={{ marginLeft: "30px" }}>
                  <PreviewSkeleton cards={2} />
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );

  if (isMobile) {
    return <DashboardMobile />;
  }

  const handleChangeToDashboard = () => {
    setRightColumnContent(
      <DashContent
        primaryColor={primaryColor}
        complementaryColor={complementaryColor}
        secondaryColor={secondaryColor}
        onProjectListChange={handleProjectListUpdate}
        onProjectListSelected={handleProjectListSelected}
        openProjectFormModal={openProjectFormModal}
        userProjects={userProjects}
      />
    );
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: windowHeight,
        "--primary-color": primaryColor,
        "--complementary-color": complementaryColor,
        position: "relative",
      }}
    >
      {/* {showBot ? (
        <div
          style={{
            position: "absolute",
            background: "white",
            boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
            bottom: 20,
            right: 20,
            zIndex: 10,
            borderRadius: "10px",
            width: "300px",
            height: "50vh",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "6vh",
              background: primaryColor,
              color: "white",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "10px 10px 0px 0px",
            }}
          >
            <div style={{ fontSize: "14px", marginLeft: "10px" }}>
              Need help?
            </div>
            <div
              className="mui-button"
              onClick={handleChatClick}
              style={{
                marginRight: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <CancelIcon style={{ fontSize: "17px" }} />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: "44vh",
              borderRadius: "0px 0px 10px 10px",
            }}
          >
            <Chatbot
              config={config}
              actionProvider={ActionProvider}
              messageParser={MessageParser}
              // saveMessages={saveMessages}
              // messageHistory={loadMessages()}
              placeholderText={"Write your message ...."}
            />
          </div>
        </div>
      ) : (
        <div
          className="mui-button"
          onClick={handleChatClick}
          style={{
            position: "absolute",
            background: primaryColor,
            width: "40px",
            height: "40px",
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            bottom: 20,
            right: 20,
            borderRadius: "15px",
            zIndex: 10,
            boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
          }}
        >
          <ChatIcon
            style={{ fontSize: "25px", color: "white", marginLeft: "7px" }}
          />
        </div>
      )} */}
      {/* Left column */}
      <div
        // className="nav-parent"
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          width: "180px",
          padding: "0px 10px 0px 15px",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
          position: "relative",
          backgroundColor: "rgba(255,255,255,0.8)",
          zIndex: 50,
          backdropFilter: "blur(20px)",
        }}
        // onMouseEnter={() => setShowText(true)}
        // onMouseLeave={() => setShowText(false)}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "0px",
            marginTop: "20px",
          }}
        >
          <div
            className="mui-button"
            style={{
              width: "70px",
              height: "70px",
              border: "2px solid rgba(125, 125, 125,0.5)",
              borderRadius: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              backgroundColor: "white",
              textAlign: "center",
              color: "rgba(125, 125, 125,0.5)",
              marginTop: "0px",
            }}
            onClick={handleChangeToDashboard}
          >
            {companyLogo ? (
              <img
                src={companyLogo}
                style={{
                  width: "100%",
                  objectFit: "cover", // or "cover"
                  height: "100%",
                }}
                alt="company logo"
              />
            ) : (
              <img
                src={logo}
                style={{
                  width: "100%",
                  objectFit: "cover", // or "cover"
                  height: "100%",
                }}
                alt="company logo"
              />
            )}
          </div>
          {/* <h1
            style={{
              color: "#fff",
              marginTop: "15px",
              marginLeft: "10px",
              fontSize: "23px",
              lineHeight: "29px",
            }}
          >
            Cayden Studios
          </h1> */}
        </div>

        {/* Navigation bar */}
        <nav
          className="second-step"
          style={{ width: "100%", marginTop: "30px" }}
        >
          <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
            {menuItems.map((item, index) => (
              <div
                className="project-item"
                key={index}
                style={{
                  // boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.2)",
                  borderRadius: "20px",
                  marginBottom: "14px",
                  // backgroundColor: "rgba(255, 255, 255, 0.2)",
                }}
              >
                <Link
                  onClick={() => {
                    setSelectedLink(item.path);
                    console.log(`${item.path} clicked`);
                  }}
                  style={{
                    color: "#fff",
                    textDecoration: "none",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {getIcon(item.label)}
                  <span
                    style={{
                      fontSize: "10px",
                      marginTop: "0px",
                      color: "rgba(0,0,0,0.6)",
                      fontWeight: "600",
                    }}
                  >
                    {item.label}
                  </span>
                </Link>
              </div>
            ))}
          </ul>
        </nav>
        <hr style={{ width: "90%", border: "1px solid rgba(0,0,0,0.1)" }} />
        <div
          style={{
            fontSize: "10px",
            marginTop: "0px",
            color: "rgba(0,0,0,0.1)",
            fontWeight: "600",
            textAlign: "left",
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
            alignItems: "center",
            marginBottom: "12px",
            marginTop: "10px",
          }}
          data-tooltip-id="my-tooltip"
          data-tooltip-html="Coming soon."
        >
          <AccountBalanceIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              width: "40px",
              color: "rgba(0,0,0,.1)",
              fontSize: "21px",
            }}
          />
          Accounting
        </div>
        <div
          style={{
            fontSize: "10px",
            marginTop: "0px",
            color: "rgba(0,0,0,0.1)",
            fontWeight: "600",
            textAlign: "left",
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
            alignItems: "center",
            marginBottom: "12px",
          }}
          data-tooltip-id="my-tooltip"
          data-tooltip-html="Coming soon."
        >
          <ShareIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              width: "40px",
              color: "rgba(0,0,0,.1)",
              fontSize: "21px",
            }}
          />
          Social Connect
        </div>
        <div
          style={{
            fontSize: "10px",
            marginTop: "0px",
            color: "rgba(0,0,0,0.1)",
            fontWeight: "600",
            textAlign: "left",
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
            alignItems: "center",
            marginBottom: "12px",
          }}
          data-tooltip-id="my-tooltip"
          data-tooltip-html="Coming soon."
        >
          <MarkUnreadChatAltIcon
            style={{
              marginTop: "-3px",
              marginRight: "10px",
              width: "40px",
              color: "rgba(0,0,0,.1)",
              fontSize: "21px",
            }}
          />
          Central Message
        </div>
        <Tooltip id="my-tooltip" place="right" style={{ fontSize: "10px" }} />
        {/* <div
          className="mui-button"
          onClick={openProjectFormModal}
          style={{
            borderTop: "1px solid rgba(0,0,0,0.1)",
            textAlign: "left",
            width: "90%",
            display: "flex",
            padding: "7px 0px",
          }}
        >
          <AddIcon style={{ marginRight: "8px", color: "rgba(0,0,0,0.4" }} />
          <span
            style={{
              fontSize: "12px",
              color: "rgba(0,0,0,0.6)",
              fontWeight: "600",
              marginTop: "2px",
            }}
          >
            Create Project
          </span>
        </div> */}
        {/* <div
          className="to-do-scroll"
          style={{
            borderTop: "1px solid rgba(0,0,0,0.1)",
            textAlign: "left",
            width: "90%",
            height: "250px",
            overflowY: "scroll",
          }}
        >
          <div
            style={{
              marginTop: "5px",
              width: "100%",
              overflow: "hidden",
            }}
          >
            {userProjects.map((project, index) => (
              <div
                key={project.id}
                className="project-nav"
                style={{
                  marginTop: "12px",
                  width: "90%",
                  marginBottom: "20px",
                }}
                onClick={() =>
                  handleProjectView(
                    project.id,
                    project.project_name,
                    project.project_color,
                    project.project_icon
                  )
                }
              >
                <div
                  className="project-item"
                  key={index}
                  style={{
                    margin: "0px 0px -7px 0px",
                    borderRadius: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      background: `${project.project_color}`,
                      width: "8px",
                      height: "8px",
                      borderRadius: "100px",
                      marginRight: "8px",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "12px",
                      color: "rgba(0,0,0,0.6)",
                      fontWeight: "600",
                      marginTop: "2px",
                      marginBottom: "0px",
                    }}
                  >
                    {project.project_name.slice(0, 12) +
                      (project.project_name.length > 12 ? " ..." : "")}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div> */}
        {/* <div>
          <ChromePicker color={primaryColor} onChange={handleColorChange} />
        </div> */}
        <div
          style={{
            borderRadius: "20px",
            width: "100%",
            marginTop: "auto",
            marginBottom: "20px",
          }}
        >
          <div
            className="mui-button third-step"
            style={{
              display: "flex",
              alignItems: "center",
              background: primaryColor,
              color: "white",
              fontSize: "12px",
              fontWeight: "600",
              padding: "8px 0px",
              borderRadius: "10px",
              marginTop: "auto",
              marginBottom: "10px",
              width: "90%",
            }}
            onClick={() => setLogoutModal(true)}
          >
            <PersonAddIcon
              style={{
                color: "white",
                marginRight: "10px",
                fontSize: "18px",
                marginLeft: "10px",
                marginRight: "20px",
              }}
            />
            Logout
          </div>
          <div
            className="mui-button third-step"
            style={{
              display: "flex",
              alignItems: "center",
              background: primaryColor,
              color: "white",
              fontSize: "12px",
              fontWeight: "600",
              padding: "8px 0px",
              borderRadius: "10px",
              marginTop: "auto",
              marginBottom: "10px",
              width: "90%",
            }}
            onClick={handleOpenModal}
          >
            <LogoutIcon
              style={{
                color: "white",
                marginRight: "10px",
                fontSize: "18px",
                marginLeft: "10px",
                marginRight: "20px",
              }}
            />
            Invite
          </div>
          <div
            className="mui-button"
            onClick={() => setSelectedLink("/settings")}
            style={{
              borderRadius: "10px",
              border: "1px solid rgba(0,0,0,0.8)",
              padding: "10px 0px",
              alignItems: "center",
              width: "90%",
            }}
          >
            <Link
              // onClick={openModal}
              style={{
                color: "#ffffff",
                textDecoration: "none",
                display: "flex",
                marginLeft: "10px",
                margin: "-2px 0px -5px 0px",
              }}
            >
              {getIcon("Logout")}
              <span
                style={{
                  fontSize: "12px",
                  color: "rgba(0,0,0,0.8)",
                  fontWeight: "600",
                }}
              >
                Settings
              </span>
            </Link>
          </div>
        </div>
      </div>
      {/* Right column */}
      <div
        style={{
          height: windowHeight,
          width: "100%",
          overflow: "scroll",
          overflowX: "hidden",
          // background:"red"
        }}
      >
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            background: {
              color: {
                value: "transparent",
              },
            },
            fpsLimit: 120,
            particles: {
              color: {
                value: primaryColor,
              },
              links: {
                color: primaryColor,
                distance: 400,
                enable: true,
                opacity: 1,
                width: 0,
              },
              move: {
                direction: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: false,
                speed: 2,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 800,
                },
                value: 5,
              },
              opacity: {
                value: 0.2,
              },
              shape: {
                type: "circle",
              },
              size: {
                value: { min: 10, max: 120 },
              },
              collisions: {
                enable: true,
              }, // Enable particle collisions
            },
            detectRetina: true,
          }}
        />
        <div
          // className="right-column-dash"
          style={{
            height: "100%",
            overflowY: "scroll",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              height: "60px",
              position: "absolute",
              right: 0,
              zIndex: 499,
            }}
          >
            <div
              style={{
                display: "flex",
                marginTop: "20px",
                marginRight: "20px",
              }}
            >
              <Badge
                className="mui-button"
                color="primary"
                onClick={openNotification}
                style={{ marginTop: "14px", marginRight: "20px", zIndex: 0 }}
                badgeContent={unreadNotifications}
              >
                <NotificationsIcon sx={{ fontSize: 20 }} />
              </Badge>
              <img
                className="mui-button"
                onClick={openSettings}
                src={profile_photo}
                alt="User Icon"
                style={{
                  objectFit: "cover",
                  width: "40px",
                  height: "40px",
                  marginRight: "15px",
                  boxShadow: "0px 0px 30px rgba(0, 0, 0, 0.2)",
                  borderRadius: "50px",
                  border: "0px solid #000000",
                }}
              />
              {/* <span
                style={{
                  color: primaryColor,
                  fontSize: "15px",
                  marginTop: "13px",
                  fontWeight: "600",
                }}
              >
                {name}
              </span> */}
            </div>
          </div>
          {rightColumnContent}
        </div>
      </div>
      <Modal
        isOpen={LogoutModal}
        onRequestClose={() => setLogoutModal(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 505
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "25%",
            height: "20%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",
            borderRadius: "4px",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            borderRadius: "20px",
          },
        }}
      >
        <h2 style={{ marginTop: "5px", textAlign: "center" }}>
          Confirm logout
        </h2>
        <div style={{ marginTop: "-30px", textAlign: "center" }}>
          <p>Confirm if you'd like to logout</p>
        </div>
        <button
          style={{
            marginTop: "10px",
            padding: "10px 50px",
            borderRadius: "20px",
            backgroundColor: primaryColor,
            color: "#ffffff",
          }}
          onClick={handleLogout}
        >
          Confirm
        </button>
      </Modal>
      <Modal
        isOpen={paymentContentFormIsOpen}
        onRequestClose={() => setPaymentContentFormIsOpen(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            zIndex: 505,
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30%",
            height: availableUsers.length >= userCount ? "18.5%" : "26%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "white",
            boxShadow: "none",
            borderRadius: "10px",
            border: "none",
            overflow: "hidden",
          },
        }}
      >
        {availableUsers.length >= userCount ? (
          <div
            style={{
              padding: "20px",
              display: "flex",
              alignItems: "center",
              height: "75%",
            }}
          >
            <div style={{ width: "30%" }}>
              <RunningWithErrorsIcon
                style={{ fontSize: "90px", color: primaryColor }}
              />
            </div>
            <div>
              <div
                style={{
                  fontSize: "20px",
                  color: "rgba(0, 0, 0,0.7)",
                  fontWeight: "600",
                }}
              >
                User limit reached.
              </div>
              <div style={{ fontSize: "12px", color: "rgba(0,0,0,0.6)" }}>
                To add more users, please reachout to your admin to upgrade your
                account.
              </div>
            </div>
          </div>
        ) : (
          <div style={{ padding: "20px" }}>
            <div
              style={{
                fontSize: "20px",
                color: "rgba(0, 0, 0,0.7)",
                fontWeight: "600",
              }}
            >
              Inviting your team
            </div>
            <div style={{ fontSize: "12px", color: "rgba(0,0,0,0.6)" }}>
              is now simpler!
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  border: "1px solid rgba(0,0,0,0.2)",
                  color: "rgba(0,0,0,0.6)",
                  padding: "6px",
                  borderRadius: "10px",
                  marginTop: "10px",
                  width: "75%",
                  marginRight: "20px",
                  overflow: "hidden",
                  fontSize: "12px",
                }}
              >
                {domain}
              </div>
              {copied ? (
                <div
                  className="mui-button"
                  style={{
                    padding: "6px 25px",
                    background: "#28993d",
                    borderRadius: "10px",
                    color: "white",
                    marginTop: "20px",
                    width: "18%",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={handleCopyNotClick}
                >
                  <ContentCopyIcon
                    style={{ fontSize: "15px", marginRight: "7px" }}
                  />
                  Copied!
                </div>
              ) : (
                <div
                  className="mui-button"
                  style={{
                    padding: "6px 25px",
                    background: primaryColor,
                    borderRadius: "10px",
                    color: "white",
                    marginTop: "20px",
                    width: "18%",
                    textAlign: "center",
                  }}
                  onClick={handleCopyClick}
                >
                  Copy
                </div>
              )}
            </div>
            <div
              style={{
                fontSize: "12px",
                color: "rgba(0,0,0,0.6)",
                marginTop: "10px",
              }}
            >
              Hit copy and share your link to invite new team members.
            </div>
          </div>
        )}
      </Modal>
      <Modal
        isOpen={projectFormModalIsOpen}
        onRequestClose={() => setProjectFormModalIsOpen(false)}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            height: "100%",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "#fff",
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            borderRadius: "10px",
          },
        }}
      >
        {projectFormModalContent}
      </Modal>
      <KommunicateChat primaryColor={primaryColor} />
    </div>
  );
}

export default Dashboard;
