import React, { useState, useEffect } from "react";
import logo from "./images/CS_Favicon-01.png";
import { useNavigate } from "react-router-dom";
import { Typography, IconButton } from "@mui/material";
import { ChevronRight, ExpandMore } from "@mui/icons-material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FAQMobile from "./FAQMobile";

const FAQ = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const faqs = [
    {
      id: 1,
      title: "Who is Premelo for?",
      answer: (
        <p>
          Premelo is builtto be usable by any and everybody that's looking to
          digitalize their business, whether you're looking to manage tasks,
          events, products, or even build a website or a landing page it is all
          doable with Premelo.
        </p>
      ),
    },
    {
      id: 2,
      title: "How do I get started with Premelo?",
      answer: (
        <p>
          Getting started with Premelo is easy. Simply sign up for a free
          account and start exploring our features.
        </p>
      ),
    },
    {
      id: 3,
      title: "Can I use Premelo on my mobile device?",
      answer: (
        <p>
          Yes, we offer mobile view for mobile devices, but mobile view is
          limited to certain feature, to use the whole Premelo Suite, you'd have
          to use the desktop version as certain function doesnt work very well
          on mobile.
        </p>
      ),
    },
    {
      id: 4,
      title: "Do you offer customer support?",
      answer: (
        <p>
          Yes we do, our dev teams are always checking the email
          info@premelo.com, therefore we invite everybody that is facing any
          issue to make your reports to our email that way our dev teams can get
          to fixing the problem as soon as possible.
        </p>
      ),
    },
    {
      id: 5,
      title: "How will Premelo help me in managing my work in a project?",
      answer: (
        <p>
          Premelo is a powerful project management tool designed to help you
          manage your work, team, and tasks, all in one place. With Premelo, you
          can easily create and assign tasks, track progress, and collaborate
          with your team in real-time. Our intuitive interface makes it easy to
          get started, and our robust features ensure that you can customize
          Premelo to meet the unique needs of your business. Whether you're
          managing deadlines, tracking milestones, or keeping everyone in the
          loop, Premelo has got you covered.
        </p>
      ),
    },
    {
      id: 6,
      title:
        "What is the difference of Premelo and other task management platform",
      answer: (
        <div>
          Premelo offers a number of features and benefits that distinguish it
          from other task management platforms. Here are a few examples:
          <ul>
            <li>
              Customization - With Premelo Plus, you can fully customize your
              project management platform to reflect your branding and
              personality. This includes the ability to create custom workflows,
              change color themes, and even connect to your custom domain.
            </li>
            <li>
              Internal Repositories - Premelo allows you to upload and organize
              files related to your tasks, so you can keep everything in one
              place and access it whenever you need it.
            </li>
            <li>
              Centralized Messaging - With Premelo Plus, you can connect your
              WhatsApp, Facebook, and Instagram accounts to communicate with
              your clients and teammates directly from the platform.
            </li>
            <li>
              Social Media Connect - You can also schedule and post content on
              your social media accounts directly from Premelo Plus.
            </li>
          </ul>
          Overall, Premelo offers a more comprehensive and customizable solution
          for managing your tasks, projects, and team.
        </div>
      ),
    },
    {
      id: 7,
      title: "How much does Premelo cost?",
      answer: (
        <div>
          <p>
            Premelo is entirely free, we do not have a premium tier or any other
            upgrades, what you see is what you get, and what you get is what
            Premelo thinks is immediately needed to get your business up and
            running digitally.
          </p>
        </div>
      ),
    },
    {
      id: 8,
      title: "What is Workflow?",
      answer: (
        <p>
          Workflow in Premelo is a feature that allows you to create a
          customized series of steps for a project or task, and assign them to
          specific team members or groups. This helps to streamline and automate
          the process of completing tasks, as each step can be assigned to a
          different team member, and the system can automatically move the task
          to the next step once the previous step has been completed. This helps
          to ensure that tasks are completed efficiently and on time, and also
          allows for greater visibility and accountability throughout the task
          or project. The workflow feature is available in the premium version
          of Premelo, known as Premelo Plus.
        </p>
      ),
    },
    {
      id: 9,
      title: "What is Platform Customization?",
      answer: (
        <p>
          Platform customization is available to everybody, when you customize
          your dashboard, any and everybody that logs in to your company
          dashboard will have the same setup. You can customize the logo, the
          branding, the brand color.
        </p>
      ),
    },
    {
      id: 10,
      title: "How do i get started with building a website",
      answer: (
        <div>
          <p>
            Building a website is currently available on the dashboard menu
            panel on the left, just hover on to the web builder and click on it,
            and from there just click on create a website, from here on you can
            just follow the instructions to setup your domain and you can start
            building your website.
          </p>
        </div>
      ),
    },
    {
      id: 11,
      title: "11.	What is Social Media connect?",
      answer: (
        <div>
          <p>
            Social media connect is currently under maintenance as we are
            working hard to provide more new integrations where users can
            control business pages and contents.
          </p>
        </div>
      ),
    },
    {
      id: 12,
      title: "What is Centralized Messaging system meant?",
      answer: (
        <div>
          <p>
            Centralize Messaging is currently under maintenance, Centralized
            Messaging would be provided to all users to control and manage
            messages coming in from facebook, instagram and whatsapp for any
            business
          </p>
        </div>
      ),
    },
    {
      id: 13,
      title: "How many projects can I create from Premelo and Premelo Plus? ",
      answer: (
        <div>
          <p>
            You can create as many projects as you like as there is no limit to
            that.
          </p>
        </div>
      ),
    },
    {
      id: 14,
      title: "How many tasks can I create per project?",
      answer: (
        <div>
          <p>You can create an unlimited number of tasks per project.</p>
        </div>
      ),
    },
    {
      id: 15,
      title: "How many assignees can I assign per task? ",
      answer: (
        <p>
          There is no limitation on the number of assignees that can be assigned
          to a task in Premelo and Premelo Plus. You can assign as many team
          members as necessary to a task to ensure that it is completed
          effectively and efficiently.
        </p>
      ),
    },
    {
      id: 16,
      title: "I can't see my website, why is that?",
      answer: (
        <div>
          <p>
            Do ensure that you have properly configured your DNS records in your
            domain to ensure that your domain is properly connected to your
            site. That is typically the only reason that could affect your
            website from being live. But if you do have any other queries on
            that we invite you to leave an email and our dev team will get back
            to you as soon as possible.
          </p>
        </div>
      ),
    },
    {
      id: 17,
      title: "My calendar is not getting updated with the task. Why?",
      answer: (
        <div>
          <ul>
            <li>
              Task is not assigned to you: Make sure that the task is assigned
              to you, as only the assigned tasks will appear on your calendar.
            </li>
            <li>
              Delay in sync: Sometimes, there may be a delay in syncing your
              tasks with your calendar. Wait for a few minutes and check again
              to see if the task appears on your calendar.
            </li>
            <li>
              Technical issues: There could be technical issues with the
              integration between Premelo and your calendar app. In this case,
              we recommend contacting our technical support team for assistance.
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 18,
      title: "I am not getting notify by my notification. Why?",
      answer: (
        <div>
          <ul>
            <li>
              Notification settings: Check your notification settings in Premelo
              to make sure that you have enabled notifications for the specific
              actions that you want to be notified about, such as task
              assignments, updates, and comments.
            </li>
            <li>
              Delay in sync: Sometimes, there may be a delay in syncing your
              tasks with your notifications. Wait for a few minutes and check
              again to see if the notifcations is being updated.
            </li>
            <li>
              Technical issues: There could be technical issues with the
              integration between Premelo and your notifications. In this case,
              we recommend contacting our technical support team for assistance.
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 19,
      title: "My profile is not being updated. Why?",
      answer: (
        <p>
          Delay in sync: Sometimes, there may be a delay in syncing your profile
          with the server. Wait for a few minutes and check again to see if your
          profile is being updated.
        </p>
      ),
    },
    {
      id: 20,
      title: "I am unable to add attachments to a task. Why?",
      answer: (
        <div>
          <ul>
            <li>
              File type: Check to make sure that the file type you are trying to
              attach is supported such as documents, images, and videos.
            </li>
            <li>
              File size: Check to make sure that the file size you are trying to
              attach is within the 200MB per file limit. Premelo may have a file
              size limit for attachments, and if the file you are trying to
              attach exceeds that limit, it will not be able to be uploaded.
            </li>
            <li>
              Technical issues: There could be technical issues with the system
              that are preventing attachments from being uploaded. In this case,
              we recommend contacting our technical support team for assistance.
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 21,
      title: "I am unable to add comment to a task. Why?",
      answer: (
        <div>
          <ul>
            <li>
              Technical issues: There could be technical issues with the system
              that are preventing comments from being added. In this case, we
              recommend contacting our technical support team for assistance.
            </li>
            <li>
              Internet connectivity: Check your internet connectivity to make
              sure that you are connected to the internet. If your internet
              connection is unstable or slow, you may have difficulty adding
              comments to tasks.
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 22,
      title: "The ChatBot is not answering me correctly. ",
      answer: (
        <div>
          <ul>
            <li>
              Try rephrasing your question: If the ChatBot is not able to
              understand your question, try rephrasing it using simpler language
              or using different keywords.
            </li>
            <li>
              Check your internet connection: Make sure that your internet
              connection is stable and strong enough to support the ChatBot.
              Slow or unstable connections can sometimes interfere with the
              ChatBot's ability to function properly.
            </li>
            <li>
              Technical issues: There could be technical issues with the ChatBot
              or the system that are preventing it from providing accurate
              responses. In this case, we recommend contacting our technical
              support team for assistance.
            </li>
          </ul>
          If you have tried these steps and are still having trouble getting
          accurate responses from the ChatBot in Premelo, please contact our
          technical support team through email for further assistance.
        </div>
      ),
    },
    {
      id: 23,
      title:
        "How do I know my payment to subscribe Premelo Plus is successful?",
      answer: (
        <div>
          <p>
            If you have subscribed to Premelo Plus and made a payment, you
            should receive a confirmation email from our payment processing
            provider. The email will confirm that your payment was successful
            and will include a receipt or invoice for your payment.
          </p>
          <p>
            In addition, you should also see your subscription status updated in
            your Premelo account settings. If your subscription is active and
            you have access to the premium features of Premelo Plus, then your
            payment has been successfully processed.
          </p>
          <p>
            If you have not received a confirmation email or your subscription
            status has not been updated, please check your spam or junk folder
            in case the email was filtered there. If you still do not see a
            confirmation, please contact our support team at info@premelo.com
            and we will assist you in resolving the issue.
          </p>
        </div>
      ),
    },
    {
      id: 24,
      title:
        "I received a confirmation email that my payment was successful, but I still do not have access to Premelo Plus. Why?",
      answer: (
        <div>
          <ul>
            <li>
              Delay in processing: It may take some time for your payment to be
              processed and for your subscription to be activated. Please wait
              for a few hours and try logging in to your account again.
            </li>
            <li>
              Incorrect login information: Make sure that you are logging in
              with the correct username and password associated with your
              account. If you are unsure, you can try resetting your password or
              contact our support team for assistance.
            </li>
          </ul>
          If you have tried these steps and you are still unable to access
          Premelo Plus, please contact our support team at info@premelo.com for
          further assistance.
        </div>
      ),
    },
    {
      id: 25,
      title: "What type of payment method is accepted at Premelo?",
      answer: (
        <div>
          <p>
            At Premelo we have partnered with Stripe to provide a wide array of
            payment methods from every country in the world. If you would like
            to configure the available payment methods for your website, first
            head to your dashbaoard setting page and click on Ecommerce on you
            top bar menu, from there click on the payment sub-menu.
          </p>
          <p>
            Follow the instructions to setup your payment tab and your Ecommerce
            website settings which would include your accepted currency.
          </p>
          <p>
            Once you're done with that, you can head over to your stripe account
            that you have registered and click on the settings where you will
            see payment methods, this would be where you can enable and disable
            certain payment methods for your website.
          </p>
        </div>
      ),
    },
    {
      id: 26,
      title: "How do i change the currency?",
      answer: (
        <div>
          <p>
            Head over to the settings panel where you can click on Ecommerce on
            the top bar menu, from here you should see Commerce Website in the
            submenu. Click on the Commerce Website sub menu.
          </p>
          <p>
            Set the currency you would like your platform to use, and change
            what you'd like the currency display to be.
          </p>
          <p>
            If for example you'd like to display USD instead of $ sign. You
            should type USD in the currency display input.
          </p>
        </div>
      ),
    },
    {
      id: 27,
      title: "What is dynamic pages.",
      answer: (
        <div>
          <p>
            Dynamic pages are automatically filled pages that are used
            throughout your website, when user clicks on any of the products
            they will arrive on to your Product_Dynamic page and this is all
            fully customizable by you.
          </p>
        </div>
      ),
    },
    {
      id: 28,
      title: "Can i configure the emails?",
      answer: (
        <div>
          <p>
            Yes the emails that are sent automatically upon order statuses are
            all configurable in the settings panel, this is where you can adjust
            the image, subject, title, description and footer.
          </p>
        </div>
      ),
    },
  ];
  const filteredFaqs = faqs.filter((faq) =>
    faq.title.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const navigate = useNavigate();
  const [primaryColor, setPrimaryColor] = useState("#5AB1BA");

  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/.test(
      userAgent
    );
    setIsMobile(isMobile);
  }, []);
  if (isMobile) {
    return <FAQMobile />;
  }

  const FAQItem = ({ id, title, answer }) => {
    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleToggle = () => {
      setIsCollapsed(!isCollapsed);
    };

    return (
      <div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            borderBottom: "0.01rem solid #d5dee0",
          }}
          onClick={handleToggle}
          className="mui-button"
        >
          <IconButton onClick={handleToggle}>
            {isCollapsed ? <ChevronRight /> : <ExpandMore />}
          </IconButton>
          <Typography
            variant="subtitle1"
            style={{
              fontWeight: "bold",
            }}
          >
            {title}
          </Typography>
        </div>
        {!isCollapsed && (
          <Typography
            variant="body1"
            style={{
              marginLeft: "40px",
              paddingTop: "6px",
              fontSize: "12px",
              fontWeight: "300",
              paddingBottom: "20px",
            }}
          >
            {answer}
          </Typography>
        )}
      </div>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          height: "7vh",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingTop: "10px",
        }}
      >
        <div
          style={{
            height: "6vh",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            padding: "1px 0px",
            marginLeft: "40px",
          }}
        >
          <img src={logo} alt="Premelo logo" style={{ width: "42px" }} />
          <p
            style={{
              fontSize: "20px",
              fontWeight: "600",
              color: "black",
            }}
          >
            Premelo
          </p>
        </div>
        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <button
            onClick={() => navigate("/")}
            className="homepage-nav-buttons"
            style={{
              height: "4.5vh",
              padding: "0px 20px",
              borderRadius: "50px",
              border: "none",
              background: "transparent",
              fontWeight: "600",
              fontSize: "12px",
            }}
          >
            Home
          </button>
          <button
            onClick={() => navigate("/faq")}
            className="homepage-nav-buttons"
            style={{
              height: "4.5vh",
              padding: "0px 20px",
              borderRadius: "50px",
              border: "none",
              background: "transparent",
              fontWeight: "600",
              fontSize: "12px",
              color: "#d81b5e",
            }}
          >
            FAQ
          </button>
          <button
            onClick={() => navigate("/contactus")}
            className="homepage-nav-buttons"
            style={{
              height: "4.5vh",
              padding: "0px 20px",
              borderRadius: "50px",
              border: "none",
              background: "transparent",
              fontWeight: "600",
              fontSize: "12px",
            }}
          >
            Contact
          </button>
          <button
            onClick={() => navigate("/termsandcondition")}
            className="homepage-nav-buttons"
            style={{
              height: "4.5vh",
              padding: "0px 20px",
              borderRadius: "50px",
              border: "none",
              background: "transparent",
              fontWeight: "600",
              fontSize: "12px",
            }}
          >
            Terms & Privacy
          </button>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "40px",
          }}
        >
          <button
            className="homepage-nav-side-2"
            style={{
              marginRight: "15px",
              height: "4.5vh",
              padding: "0px 20px",
              borderRadius: "50px",
              border: "none",
              fontSize: "12px",
            }}
            onClick={() => {
              navigate("/signup");
              window.scrollTo(0, 0);
            }}
          >
            Sign Up
          </button>
          <button
            className="homepage-nav-side-1"
            style={{
              height: "4.5vh",
              padding: "0px 30px",
              borderRadius: "50px",
              border: "none",
              fontSize: "12px",
            }}
            onClick={() => {
              navigate("/login");
              window.scrollTo(0, 0);
            }}
          >
            Login
          </button>
        </div>
      </div>
      {/* first segment */}
      <div
        style={{
          padding: "35px 0px",
          textAlign: "center",
          background: "#d5dee0",
        }}
      >
        <div style={{ fontSize: "35px", fontWeight: "600" }}>
          Frequently Asked Questions
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <div style={{ fontSize: "12px", fontWeight: "300", width: "70%" }}>
            Here you will find answers to some of the most commonly asked
            questions about our work management platform, Premelo. If you have
            any additional questions that are not covered here, please do not
            hesitate to contact our support team for further assistance.
          </div>
        </div>
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="🔍 Search by title, products and more"
          style={{
            border: "none",
            outline: "none",
            padding: "8px 20px",
            textAlign: "center",
            marginTop: "20px",
            borderRadius: "25px",
            height: "30px",
            width: "30%",
          }}
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: "40px",
        }}
      >
        <div style={{ width: "60%" }}>
          {filteredFaqs.map((faq) => (
            <FAQItem
              key={faq.id}
              id={faq.id}
              title={faq.title}
              answer={faq.answer}
            />
          ))}
        </div>
      </div>
      {/* Website Footer */}
      <hr
        style={{
          width: "100%",
          height: "0.02rem",
          backgroundColor: "black",
          border: "none",
          margin: "50px 0px 10px 0px",
        }}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "80%",
            justifyContent: "space-between",
            textAlign: "left",
            padding: "20px 0px",
          }}
        >
          <div style={{ width: "16%", fontSize: "12px", fontWeight: "300" }}>
            <div style={{ fontSize: "15px", fontWeight: "400" }}>Learn</div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/taskmanagement");
                window.scrollTo(0, 0);
              }}
            >
              Task Management
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/teaminvite");
                window.scrollTo(0, 0);
              }}
            >
              Team Invite
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/customworkflow");
                window.scrollTo(0, 0);
              }}
            >
              Custom Workflow
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/platformcustomization");
                window.scrollTo(0, 0);
              }}
            >
              Platform Customization
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/domainandhosting");
                window.scrollTo(0, 0);
              }}
            >
              Domain & Hosting
            </div>
          </div>
          <div style={{ width: "16%", fontSize: "12px", fontWeight: "300" }}>
            <div style={{ fontSize: "15px", fontWeight: "400" }}>Products</div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/workmanagement");
                window.scrollTo(0, 0);
              }}
            >
              Work Management
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/socialmediaconnect");
                window.scrollTo(0, 0);
              }}
            >
              Social Media Connect
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/internalrepositories");
                window.scrollTo(0, 0);
              }}
            >
              Internal Repositories
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/centralizedmessaging");
                window.scrollTo(0, 0);
              }}
            >
              Centralized Messaging
            </div>
          </div>
          <div style={{ width: "11%", fontSize: "12px", fontWeight: "300" }}>
            <div style={{ fontSize: "15px", fontWeight: "400" }}>Support</div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/contactus");
                window.scrollTo(0, 0);
              }}
            >
              Help Center
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/faq");
                window.scrollTo(0, 0);
              }}
            >
              FAQ
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/contactus");
                window.scrollTo(0, 0);
              }}
            >
              System Status
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/chatwithagent");
                window.scrollTo(0, 0);
              }}
            >
              Speak to our agents
            </div>
          </div>
          <div style={{ width: "16%", fontSize: "12px", fontWeight: "300" }}>
            <div style={{ fontSize: "15px", fontWeight: "400" }}>Company</div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/pressandannouncement");
                window.scrollTo(0, 0);
              }}
            >
              Press & Announcement
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/contactus");
                window.scrollTo(0, 0);
              }}
            >
              Careers
            </div>
            <div
              className="mui-button"
              style={{ marginTop: "10px" }}
              onClick={() => {
                navigate("/contactus");
                window.scrollTo(0, 0);
              }}
            >
              Sitemap
            </div>
          </div>
          <div style={{ width: "20%", fontSize: "12px", fontWeight: "300" }}>
            <img
              src={logo}
              alt="premelo logo"
              style={{ width: "30%", marginTop: "-5px" }}
            />
            <div style={{ marginTop: "-5px" }}>
              Premelo is a customizable work management platform that enables
              easy collaboration and real-time updates. Streamline workflow and
              boost productivity today.
            </div>
          </div>
        </div>
      </div>
      <hr
        style={{
          width: "100%",
          height: "0.02rem",
          backgroundColor: "black",
          border: "none",
          margin: "10px auto",
        }}
      />
      <div
        style={{
          width: "100%",
          padding: "10px 0px 20px 0px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", width: "50%", marginLeft: "10%" }}>
          <FacebookIcon
            className="mui-button"
            style={{ fontSize: "20px", marginRight: "15px" }}
            onClick={() =>
              (window.location.href = "https://www.facebook.com/premeloapp")
            }
          />
          <InstagramIcon
            className="mui-button"
            style={{ fontSize: "20px", marginRight: "15px" }}
            onClick={() =>
              (window.location.href = "https://www.instagram.com/premelo_app/")
            }
          />
          <YouTubeIcon
            className="mui-button"
            style={{ fontSize: "20px", marginRight: "15px" }}
            onClick={() =>
              (window.location.href = "https://www.youtube.com/@Premelo-Inc")
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            width: "50%",
            fontSize: "12px",
            fontWeight: "300",
            textAlign: "right",
            justifyContent: "flex-end",
            marginRight: "10%",
          }}
        >
          <div
            className="mui-button"
            style={{ marginRight: "20px" }}
            onClick={() => {
              navigate("/termsandcondition");
              window.scrollTo(0, 0);
            }}
          >
            Terms & Privacy
          </div>
          <div
            className="mui-button"
            style={{ marginRight: "20px" }}
            onClick={() => {
              navigate("/contactus");
              window.scrollTo(0, 0);
            }}
          >
            Contact Us
          </div>
          <div>© 2023 Premelo, Inc.</div>
        </div>
      </div>
    </div>
  );
};
export default FAQ;
