import React, { useState, useCallback } from "react";
import axios from "axios";
import Modal from "react-modal";
import CS_Favicon from "./images/CS_Favicon.png";
import backgroundImage from "./images/welcome_page_bg.jpg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useDropzone } from "react-dropzone";
import AvatarEditor from "react-avatar-editor";
import { useEffect } from "react";
import "./App.css";
import MobileScreen from "./MobileScreen";
import { ReactComponent as BackgroundSvg } from "./images/mobile-base-01.svg";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { CircularProgress } from "@mui/material";

axios.defaults.baseURL = "https://ww1.premelo.com";

const FillInformationMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [purposeOfUse, setPurposeOfUse] = useState("");
  const [positionInCompany, setPositionInCompany] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [profilePhotoPreview, setProfilePhotoPreview] = useState(null);
  const [editor, setEditor] = useState(null);
  const [isAdmin, setIsAdmin] = useState(true);

  const [companyName, setCompanyName] = useState("");
  // const [companyColor, setCompanyColor] = useState("green");
  const [companyColor, setCompanyColor] = useState("#1450db");
  const svgStyle = {
    fill: companyColor,
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 0,
    width: "100%",
  };

  const getCompanyColorByDomain = async () => {
    try {
      console.log(window.location.hostname);
      const response = await axios.get(
        "https://ww1.premelo.com/api/company-color-by-domain", // Replace with your API endpoint
        {
          params: {
            domain: `https://${window.location.hostname}`,
          },
        }
      );
      const data = response.data;
      if (data && data.company_color) {
        setCompanyColor(data.company_color);
        console.log(data.company_color);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Call the function when the sign-up page loads
  useEffect(() => {
    getCompanyColorByDomain();
  }, []);

  useEffect(() => {
    fetchAdminState();
  }, []);

  const fetchAdminState = async () => {
    const token = Cookies.get("token");
    const response = await fetch("https://ww1.premelo.com/api/is-admin", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    console.log(data.is_admin);
    setIsAdmin(data.is_admin);
    if (data.is_admin === false) {
      const company = await fetch("https://ww1.premelo.com/api/company-name", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const name = await company.json();
      console.log(name);
      setCompanyName(name.company_name);
    }
  };

  const [fileUploading, setFileUploading] = useState(false);

  const handleImageUploadElement = async (e) => {
    setFileUploading(true);
    const file = e.target.files;

    const formData = new FormData();

    for (let i = 0; i < file.length; i++) {
      formData.append("files[]", file[i]);
    }

    try {
      const response = await axios.post(
        "https://ww1.premelo.com/api/upload-image",
        formData
      );
      const imageUrl = response.data.urls;
      setProfilePhoto(imageUrl[0]);
      setFileUploading(false);
    } catch (error) {
      console.log(error);
      setFileUploading(false);
    }
  };

  const handleClickImageInput = () => {
    const fileInput = document.getElementById("imageInput2");
    fileInput.click();
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    const formData = new FormData();

    acceptedFiles.forEach((file) => {
      formData.append("files[]", file);
    });

    try {
      const response = await axios.post(
        "https://ww1.premelo.com/api/upload-image",
        formData
      );

      console.log(response.data.urls);
      setProfilePhoto(response.data.urls[0]);
      setProfilePhotoPreview(URL.createObjectURL(acceptedFiles[0]));
    } catch (error) {
      console.error(error);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    multiple: false,
    maxSize: 10000000,
  });

  const handleEditorReady = (editor) => {
    setEditor(editor);
  };

  const handleReupload = (event) => {
    event.preventDefault();
    setProfilePhoto(null);
    setProfilePhotoPreview(null);
    // Trigger file input prompt
    document.getElementById("profile-photo-input").click();
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // setProfilePhoto(file);
      setProfilePhotoPreview(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const colors = [
      "#1450db",
      "#14dbc7",
      "#0c8214",
      "#82530c",
      "#820c53",
      "#1450db",
      "#14dbc7",
      "#0c8214",
      "#82530c",
      "#820c53",
      "#1450db",
      "#14dbc7",
      "#0c8214",
      "#82530c",
      "#820c53",
    ];
    const randomIndex = Math.floor(Math.random() * colors.length);
    const autoColor = colors[randomIndex];

    try {
      const token = Cookies.get("token");
      const response = await axios.put(
        "https://ww1.premelo.com/api/personal-information",
        {
          first_name: firstName,
          last_name: lastName,
          phone_number: phoneNumber,
          user_color: autoColor,
          company_name: companyName,
          purpose_of_use: purposeOfUse,
          position_in_company: positionInCompany,
          profile_photo: profilePhoto ? profilePhoto.length > 0 ? profilePhoto : null : null,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        setModalMessage(response.data.message); // Set the message to display in the modal
        setModalIsOpen(true); // Show the modal
      } else if (response.status === 401) {
        setModalMessage(response.data.message); // Set the message to display in the modal
        setModalIsOpen(true); // Show the modal
        setError(response.data.message);
      } else {
        console.log("No entry yet");
      }
    } catch (error) {
      console.log(error.response.data.message);
      setError(error.response.data.message);
    }
  };

  return (
    <div
      style={{
        alignItems: "center",
        height: "90vh",
        width: "100%",
        backgroundSize: "cover",
        position: "relative",
        overflowX: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          textAlign: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            color: "white",
            fontSize: "40px",
            fontWeight: "800",
            padding: "25px",
            paddingLeft: "40px",
            zIndex: 20,
            top: 0,
            bottom: 0,
            left: 0,
            textAlign: "left",
            right: 0,
          }}
        >
          {companyName}
        </div>
        <div
          style={{
            zIndex: 200,
            width: "100%",
            alignContent: "center",
            textAlign: "center",
            justifyContent: "center",
            padding: "0px 20px",
          }}
        >
          <div
            style={{
              textAlign: "center",
              background: "white",
              borderRadius: "35px",
              boxShadow: "0px 0px 10px rgba(0,0,0,0.3)",
              padding: "40px 0px",
              width: "100%",
            }}
          >
            <h1 style={{ fontSize: "25px", color: "#242424" }}>Welcome!</h1>
            <div
              style={{
                marginTop: "-30px",
                marginBottom: "15px",
                padding: "0px 30px",
                marginTop: "-15px",
              }}
            >
              <p
                style={{
                  fontSize: "10px",
                  lineHeight: "10px",
                  margin: "0px",
                }}
              >
                Please fill in the below information, to proceed to your
                dashboard.
              </p>
            </div>
            <form
              onSubmit={handleSubmit}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "0px",
                }}
              >
                {/* {fileUploading ? (
                  <>
                    <div
                      className="mui-button"
                      style={{
                        width: "140px",
                        height: "140px",
                        border: "2px solid rgba(125, 125, 125,0.5)",
                        borderRadius: "100px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        overflow: "hidden",
                        backgroundColor: "white",
                        textAlign: "center",
                        color: "rgba(125, 125, 125,0.5)",
                      }}
                      onClick={handleReupload}
                    >
                      <img
                        src={profilePhotoPreview}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        alt="company logo"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      {...getRootProps({
                        style: {
                          width: "140px",
                          height: "140px",
                          border: "2px solid rgba(125, 125, 125,0.5)",
                          borderRadius: "100px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          overflow: "hidden",
                          backgroundColor: "white",
                          textAlign: "center",
                          color: "rgba(125, 125, 125,0.5)",
                        },
                      })}
                    >
                      <input
                        {...getInputProps()}
                        id="file-input"
                        onChange={handleFileInputChange}
                      />
                      <p
                        style={{ marginTop: "180px" }}
                        className="upload-profile"
                      >
                        +
                      </p>
                    </div>
                  </>
                )} */}
                <input
                  id="imageInput2"
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    handleImageUploadElement(e);
                  }}
                />
                {fileUploading ? (
                  <div
                    style={{
                      width: "140px",
                      height: "140px",
                      objectFit: "cover",
                      borderRadius: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                    }}
                  >
                    <CircularProgress color="primary" size={50} style={{}} />
                  </div>
                ) : profilePhoto ? (
                  <div
                    onClick={handleClickImageInput}
                    style={{
                      width: "140px",
                      height: "140px",
                      border: "2px solid rgba(125, 125, 125,0.5)",
                      borderRadius: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "white",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={profilePhoto}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                ) : (
                  <div
                    onClick={handleClickImageInput}
                    style={{
                      width: "140px",
                      height: "140px",
                      border: "2px solid rgba(125, 125, 125,0.5)",
                      borderRadius: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      overflow: "hidden",
                      backgroundColor: "white",
                      textAlign: "center",
                      color: "rgba(125, 125, 125,0.5)",
                    }}
                  >
                    <FileUploadIcon
                      style={{
                        color: "lightgrey",
                        fontSize: "50px",
                      }}
                    />
                  </div>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "15px",
                  width: "70%",
                }}
              >
                <label
                  htmlFor="name"
                  style={{
                    width: "350px",
                    color: "#242424",
                    padding: "0px 0px 5px 20px",
                    opacity: "80%",
                  }}
                ></label>
                <input
                  type="text"
                  id="first_name"
                  placeholder="First Name"
                  required
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                  style={{
                    borderRadius: "20px",
                    padding: "10px 20px",
                    border: "1px solid #7d7d7d",
                    fontSize: "10px",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "5px",
                  width: "70%",
                }}
              >
                <label
                  htmlFor="name"
                  style={{
                    width: "350px",
                    color: "#242424",
                    padding: "0px 0px 5px 20px",
                    opacity: "80%",
                  }}
                ></label>
                <input
                  type="text"
                  id="last_name"
                  placeholder="Last Name"
                  required
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                  style={{
                    borderRadius: "20px",
                    padding: "10px 20px",
                    border: "1px solid #7d7d7d",
                    fontSize: "10px",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "5px",
                  width: "70%",
                }}
              >
                <label
                  htmlFor="name"
                  style={{
                    width: "350px",
                    color: "#242424",
                    padding: "0px 0px 5px 20px",
                    opacity: "80%",
                  }}
                ></label>
                <input
                  type="tel"
                  id="phone_number"
                  required
                  placeholder="Phone Number"
                  value={phoneNumber}
                  onChange={(event) => setPhoneNumber(event.target.value)}
                  style={{
                    borderRadius: "20px",
                    padding: "10px 20px",
                    border: "1px solid #7d7d7d",
                    fontSize: "10px",
                  }}
                />
              </div>
              {isAdmin && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "5px",
                    width: "70%",
                  }}
                >
                  <label
                    htmlFor="name"
                    style={{
                      width: "350px",
                      color: "#242424",
                      padding: "0px 0px 5px 20px",
                      opacity: "80%",
                    }}
                  ></label>
                  <input
                    type="text"
                    id="company_name"
                    placeholder="Company Name"
                    required
                    value={companyName}
                    onChange={(event) => setCompanyName(event.target.value)}
                    style={{
                      borderRadius: "20px",
                      padding: "10px 20px",
                      border: "1px solid #7d7d7d",
                      fontSize: "10px",
                    }}
                  />
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "5px",
                  width: "70%",
                }}
              >
                <label
                  htmlFor="name"
                  style={{
                    width: "350px",
                    color: "#242424",
                    padding: "0px 0px 5px 20px",
                    opacity: "80%",
                  }}
                ></label>
                <input
                  type="text"
                  id="purpose_of_use"
                  placeholder="Purpose of Use"
                  value={purposeOfUse}
                  onChange={(event) => setPurposeOfUse(event.target.value)}
                  style={{
                    borderRadius: "20px",
                    padding: "10px 20px",
                    border: "1px solid #7d7d7d",
                    fontSize: "10px",
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "5px",
                  width: "70%",
                }}
              >
                <label
                  htmlFor="name"
                  style={{
                    width: "350px",
                    color: "#242424",
                    padding: "0px 0px 5px 20px",
                    opacity: "80%",
                  }}
                ></label>
                <input
                  type="text"
                  id="position_in_company"
                  placeholder="Designation"
                  value={positionInCompany}
                  onChange={(event) => setPositionInCompany(event.target.value)}
                  style={{
                    borderRadius: "20px",
                    padding: "10px 20px",
                    border: "1px solid #7d7d7d",
                    fontSize: "10px",
                  }}
                />
              </div>
              {error ? (
                <div
                  style={{ color: "red", fontSize: "12px", fontWeight: 600 }}
                >
                  {error}
                </div>
              ) : null}
              <button
                type="submit"
                className="mui-button"
                style={{
                  marginTop: "40px",
                  border: "0px solid #FFFFFF",
                  backgroundColor: "#3333FF",
                  padding: "15px 40px",
                  color: "#FFFFFF",
                  borderRadius: "30px",
                  fontWeight: "600",
                  textDecoration: "none",
                  transition: "all 0.3s ease",
                }}
              >
                Submit
              </button>
            </form>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={() => setModalIsOpen(false)}
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 300,
                },
                content: {
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "70%",
                  height: "18%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#fff",
                  borderRadius: "4px",
                  boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
                  borderRadius: "20px",
                },
              }}
            >
              <h2
                style={{
                  marginTop: "5px",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                Information updated
              </h2>
              <div
                style={{
                  marginTop: "-30px",
                  textAlign: "center",
                  fontSize: "12px",
                }}
              >
                <p>{modalMessage}</p>
              </div>
              <button
                className="mui-button"
                style={{
                  marginTop: "10px",
                  padding: "10px 50px",
                  borderRadius: "20px",
                }}
                onClick={() => {
                  setModalIsOpen(false);
                  // Redirect to login page
                  navigate("/dashboard");
                }}
              >
                Login
              </button>
            </Modal>
          </div>
        </div>
        <BackgroundSvg style={svgStyle} />
      </div>
    </div>
  );
};

export default FillInformationMobile;
