import React, { useState, useEffect } from "react";
import logo from "./images/CS_Favicon-01.png";
import { useNavigate } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import InfoIcon from "@mui/icons-material/Info";
import KommunicateChat from "./chatContact";
import CloseIcon from "@mui/icons-material/Close";
import ChatWithAgentMobile from "./ChatWithAgentMobile";

const ChatWithAgent = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  };
  const [primaryColor, setPrimaryColor] = useState("#5AB1BA");
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/.test(
      userAgent
    );
    setIsMobile(isMobile);
  }, []);
  if (isMobile) {
    return <ChatWithAgentMobile />;
  }

  return (
    <div style={{ width: "100%" }}>
      {/* first segment */}
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          background: "#d5dee0",
        }}
      >
        <div
          style={{
            width: "80%",
            padding: "35px 0px",
            textAlign: "left",
          }}
        >
          <div style={{ fontSize: "35px", fontWeight: "600" }}>
            Chat with Premelo Agent.
          </div>
          <div style={{ fontSize: "12px", fontWeight: "300" }}>
            Now you can reach our agents with just a simple message, no more
            contact forms or whatnot, our agent typically replies in 5-10
            minutes to all your queries, whether you need
          </div>
          <div style={{ fontSize: "12px", fontWeight: "300" }}>
            help in creating a project to as simple as tying your shoe-laces,
            our agents are here
          </div>
        </div>
        <div style={{ marginTop: "20px" }}>
          <CloseIcon
            className="mui-button"
            style={{ fontSize: "30px" }}
            onClick={handleClick}
          />
        </div>
      </div>
      {/* Chat with Agent */}
      <div
        style={{
          width: "90%",
          height: "78vh",
          display: "flex",
          justifyContent: "center",
          paddingTop: "10px",
        }}
      >
        <iframe
          style={{
            border: "none",
            height: "80vh",
            width: "85%",
            color: "red",
          }}
          src="/km-script.html"
          seamless="true"
        ></iframe>
      </div>
    </div>
  );
};
export default ChatWithAgent;
