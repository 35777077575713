import React, { useEffect, useState, useRef } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { MentionsInput, Mention } from "react-mentions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "quill-mention/dist/quill.mention.min.css";
import Select from "@mui/material/Select";
import { ListItemText, ListItemIcon } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import "./App.css";
import "./CustomDropdown.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ImageIcon from "@mui/icons-material/Image";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

const NotificationContent = (props) => {
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    // Initial check
    handleResize();

    // Attach event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  function getFileIcon(fileName) {
    const extension = fileName.split(".").pop().toLowerCase();

    if (extension === "pdf") {
      return (
        <PictureAsPdfIcon
          style={{ fontSize: "40px", color: props.primaryColor }}
        />
      );
    } else if (["jpg", "jpeg", "png"].includes(extension)) {
      return (
        <ImageIcon style={{ fontSize: "40px", color: props.primaryColor }} />
      );
    } else {
      return (
        <InsertDriveFileIcon
          style={{ fontSize: "40px", color: props.primaryColor }}
        />
      );
    }
  }

  function getMessageFileIcon(fileName) {
    const extension = fileName.split(".").pop().toLowerCase();

    if (extension === "pdf") {
      return (
        <PictureAsPdfIcon
          style={{ fontSize: "25px", color: props.primaryColor }}
        />
      );
    } else if (["jpg", "jpeg", "png"].includes(extension)) {
      return (
        <ImageIcon style={{ fontSize: "25px", color: props.primaryColor }} />
      );
    } else {
      return (
        <InsertDriveFileIcon
          style={{ fontSize: "25px", color: props.primaryColor }}
        />
      );
    }
  }

  const [notifications, setNotifications] = useState([]);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [availableUsersForNotification, setAvailableUsersForNotification] =
    useState([]);
  const [userProjects, setUserProjects] = useState([]);
  const [userTask, setUserTask] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedRow, setSelectedRow] = useState("");
  const [taskId, setTaskId] = useState("");
  const [taskTitle, setTaskTitle] = useState("");
  const [taskDescription, setTaskDescription] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [status, setStatus] = useState("");
  const [taskAssignedTo, setTaskAssignedTo] = useState("");
  const [isCompleted, setIsCompleted] = useState("");
  const [purpose, setPurpose] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [taskAttachmentUrl, setTaskAttachmentUrl] = useState([]);
  const [modalMessages, setModalMessages] = useState({});
  const [message, setMessage] = useState("");
  const [attachmentUrl, setAttachmentUrl] = useState([]);
  const [taskAssignedBy, setTaskAssignedBy] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [customStatus, setCustomStatus] = useState("");
  const [customColor, setCustomColor] = useState("#701ba1");
  const [selectedOption, setSelectedOption] = useState("");
  const handleCustomStatusChange = (event) => {
    setCustomStatus(event.target.value);
  };

  const handleCustomColorChange = (event) => {
    setCustomColor(event.target.value);
  };
  const handleFileInputChange = async (event) => {
    const files = event.target.files;
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("files[]", files[i]);
    }

    try {
      const response = await axios.post(
        "https://ww1.premelo.com/api/upload-image",
        formData
      );

      console.log(response.data);
      setAttachmentUrl(
        response.data.urls.map((urls, index) => ({
          url: urls,
          fileName: response.data.fileNames[index],
        }))
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddMessage = async () => {
    try {
      const urls = attachmentUrl.map((attachment) => ({
        url: attachment.url,
        fileName: attachment.fileName,
      }));

      // the task id is not being updated
      const response = await fetch(
        `https://ww1.premelo.com/api/task/${taskId}/message`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            task_id: taskId,
            message: message,
            attachment_url: urls,
          }),
        }
      );

      const data = await response.json();
      setMessage("");
      setAttachmentUrl([]);
    } catch (error) {
      console.error(error);
    }

    const response = await fetch(
      `https://ww1.premelo.com/api/task/${taskId}/getmessages`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    console.log(availableUsers);
    setModalMessages(data);
    const taskAttachments = await fetch(
      `https://ww1.premelo.com/api/task/${taskId}/attachments`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const attachedInTask = await taskAttachments.json();
    setTaskAttachmentUrl(
      attachedInTask.map((attachment) => ({
        url: attachment.attachment_url,
        fileName: attachment.file_name,
      }))
    );
  };

  const handleMentionChange = (event, newValue) => {
    setMessage(newValue);
  };
  const handleRemoveAttachment = (index) => {
    setAttachmentUrl((prev) => prev.filter((_, i) => i !== index));
  };

  const renderSuggestion = (suggestion, search, highlightedDisplay) => {
    return <div style={{ borderRadius: "30px" }}>{highlightedDisplay}</div>;
  };

  const [showStatusRow, setShowStatusRow] = useState(
    Cookies.get("showStatusRow") === "true" ? true : false
  );
  const [showTaskPurpose, setShowTaskPurpose] = useState(
    Cookies.get("showTaskPurpose") === "true" ? true : false
  );
  const [showPhoneNumber, setShowPhoneNumber] = useState(
    Cookies.get("showPhoneNumber") === "true" ? true : false
  );
  const token = Cookies.get("token");

  const fileInput = useRef(null);
  const taskFileInput = useRef(null);
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "image",
    "mention",
  ];

  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelectedUsers(value);
  };

  const handleChangeDate = (dues) => {
    console.log("onblur initiated");

    const dateParts = dues.split("/");
    const year = dateParts[2];
    const month = dateParts[0].padStart(2, "0");
    const day = dateParts[1].padStart(2, "0");
    const outputDate = `${year}-${month}-${day}`;

    const newData = userTask.map((item) => {
      if (item.id === selectedRow.id) {
        const updatedItem = {
          ...item,
          task_due_date: outputDate,
        };
        fetch(`https://ww1.premelo.com/api/update-task/${updatedItem.id}`, {
          method: "PUT",
          body: JSON.stringify(updatedItem),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.error(error);
          });
        return updatedItem;
      } else {
        return item;
      }
    });
    setDueDate(outputDate);
    setUserTask(newData);
  };

  const handleCustomStatusCommit = async () => {
    console.log("custom status:", customStatus);

    const newData = userTask.map((item) => {
      if (item.id === selectedRow.id) {
        const updatedItem = {
          ...item,
          task_status: customStatus,
        };
        fetch(`https://ww1.premelo.com/api/update-task/${updatedItem.id}`, {
          method: "PUT",
          body: JSON.stringify(updatedItem),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.error(error);
          });
        return updatedItem;
      } else {
        return item;
      }
    });
    setStatus(customStatus);
    setUserTask(newData);

    const createdStatus = {
      label: customStatus,
      value: customStatus,
      color: customColor,
    };

    setStatusOptions([...statusOptions, createdStatus]);
    const newOptions = [...statusOptions, createdStatus];

    const response = await fetch(
      `https://ww1.premelo.com/api/projects/${props.activeProjectId}/status-options`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ status_options: newOptions }),
      }
    );

    const responseData = await response.json();
    setStatusOptions(responseData.project.status_options);
  };

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append("files[]", files[i]);
    }

    try {
      const response = await axios.post(
        "https://ww1.premelo.com/api/upload-image",
        formData
      );

      console.log(response.data);
      const attachmentUrls = response.data.urls.map((url, index) => ({
        url: url,
        fileName: response.data.fileNames[index],
      }));

      // Call the uploadTaskAttachment API to store the attachment URLs and file names
      const taskAttachmentResponse = await fetch(
        `https://ww1.premelo.com/api/upload-attachments/${taskId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            attachment_url: attachmentUrls,
          }),
        }
      );

      const taskAttachments = await fetch(
        `https://ww1.premelo.com/api/task/${taskId}/attachments`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await taskAttachments.json();
      console.log("message data:", data);
      setTaskAttachmentUrl(
        data.map((attachment) => ({
          url: attachment.attachment_url,
          fileName: attachment.file_name,
        }))
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setStatus(option.value);
    setIsOpen(false);
  };

  const handleTaskViewClick = async (calEvent) => {
    setTaskAttachmentUrl([]);
    setModalMessages([]);
    console.log(calEvent, "onSelectEvent");
    setSelectedRow(calEvent);
    setTaskId(calEvent.id);
    setTaskTitle(calEvent.task_title);
    setTaskDescription(calEvent.task_description);
    setSelectedUsers(calEvent.task_assigned_to);
    setDueDate(calEvent.task_due_date);
    setTaskAssignedTo(calEvent.task_assigned_to);
    setIsCompleted(calEvent.task_is_complete);
    setPhoneNumber(calEvent.task_phone_number);
    setPurpose(calEvent.task_purpose);
    setStatus(calEvent.task_status);
    setTaskAssignedBy(calEvent.task_assigned_by);
    setSelectedOption({
      label: calEvent.task_status,
      label: calEvent.task_status,
      color:
        statusOptions.find((option) => option.value === calEvent.task_status)
          ?.color || props.primaryColor,
    });

    const taskAttachments = await fetch(
      `https://ww1.premelo.com/api/task/${calEvent.id}/attachments`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const response = await fetch(
      `https://ww1.premelo.com/api/task/${calEvent.id}/getmessages`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await response.json();
    console.log("message data:", data);
    console.log(availableUsers);
    setModalMessages(data);

    const attachedInTask = await taskAttachments.json();
    setTaskAttachmentUrl(
      attachedInTask.map((attachment) => ({
        url: attachment.attachment_url,
        fileName: attachment.file_name,
      }))
    );
  };

  const fetchUserNotification = async () => {
    const token = Cookies.get("token");
    const response = await fetch("https://ww1.premelo.com/api/notifications", {
      headers: {
        Accept: `application/json`,
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    console.log("notifications:", data);
    setNotifications(data);
  };

  const fetchUsers = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/user-available-team-members",
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    const modifiedData = data.map((user) => {
      return {
        ...user,
        fullName: `${user.first_name} ${user.last_name}`,
      };
    });
    console.log(modifiedData, "available users with fullname");
    setAvailableUsers(modifiedData);
    setAvailableUsersForNotification(data);
  };

  const fetchCompanyProjects = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/retrieve-company-projects",
      {
        headers: {
          Accept: `application/json`,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    const allStatusOptions = data.projects.flatMap((project) =>
      JSON.parse(project.status_options)
    );

    // Remove duplicates and set to setStatusOptions
    const uniqueStatusOptions = Array.from(new Set(allStatusOptions));

    setStatusOptions(uniqueStatusOptions);
    setUserProjects(data.projects);
  };

  const fetchCompanyTask = async () => {
    const token = Cookies.get("token");
    const response = await fetch(
      "https://ww1.premelo.com/api/retrieve-company-tasks",
      {
        headers: {
          Accept: `application/json`,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    const data = await response.json();
    console.log(data); // log the data variable
    setUserTask(data);
  };

  useEffect(() => {
    fetchCompanyTask();
    fetchCompanyProjects();
    fetchUsers();
    fetchUserNotification();
  }, []);

  const assignedByUser = availableUsers.find(
    (user) => user.user_id === taskAssignedBy
  );
  const assignedByInitials = assignedByUser
    ? `${assignedByUser.first_name.charAt(0)}${assignedByUser.last_name.charAt(
        0
      )}`
    : null;
  const assignedByFullName = assignedByUser
    ? `${assignedByUser.first_name} ${assignedByUser.last_name}`
    : null;

  const taskFormModalContent = (
    <div>
      <div
        style={{
          padding: "0% 5% 0px 5%",
          justifyContent: "flex-end",
          width: "100%",
          backgroundColor: "transparent",
          overflow: "scroll",
          height: "70vh",
          overflowX: "hidden",
        }}
      >
        <div className="modal">
          <div className="modal-content">
            <form>
              <div
                style={{
                  height: "auto",
                  width: "100%",
                  marginTop: "-10px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <div style={{ width: "100%" }}>
                  <div style={{ marginTop: "30px", width: "100%" }}>
                    <input
                      className="task-title-input"
                      style={{
                        color: props.primaryColor,
                        fontWeight: "600",
                        fontSize: "20px",
                      }}
                      type="text"
                      id="taskTitle"
                      placeholder="Type here to change task title"
                      value={taskTitle}
                      onChange={(e) => setTaskTitle(e.target.value)}
                      onBlur={() => {
                        const newData = userTask.map((item) => {
                          if (item.id === selectedRow.id) {
                            const updatedItem = {
                              ...item,
                              task_title: taskTitle,
                            };
                            const updatedItemForCalendar = {
                              ...item,
                              task_title: taskTitle,
                              title: taskTitle,
                            };
                            fetch(
                              `https://ww1.premelo.com/api/update-task/${updatedItem.id}`,
                              {
                                method: "PUT",
                                body: JSON.stringify(updatedItem),
                                headers: {
                                  "Content-Type": "application/json",
                                  Authorization: `Bearer ${token}`,
                                },
                              }
                            )
                              .then((response) => {
                                console.log(response);
                              })
                              .catch((error) => {
                                console.error(error);
                              });
                            return updatedItemForCalendar;
                          } else {
                            return item;
                          }
                        });
                        setUserTask(newData);
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    width: "90%",
                    height: "5px",
                    marginTop: "15px",
                    marginBottom: "-14px",
                    paddingRight: "9%",
                    fontSize: "11px",
                    color: "rgba(0,0,0,0.5)",
                  }}
                >
                  <div
                    style={{
                      width: "25%",
                      display: "flex",
                      alignItems: "center",
                      fontSize: "10px",
                    }}
                  >
                    Created By:
                  </div>
                  <div
                    style={{
                      marginTop: "50px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "25%",
                      height: "30px",
                      marginBottom: "50px",
                      marginRight: "-10px",
                      fontSize: "10px",
                    }}
                  >
                    Due Date:
                  </div>
                  <div style={{ width: "25%", fontSize: "10px" }}>Status:</div>
                  <div
                    style={{
                      width: "25%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: "10px",
                    }}
                  >
                    Assigned To:
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    width: "90%",
                    height: "50px",
                    marginTop: "15px",
                    marginBottom: "30px",
                    paddingRight: "9%",
                  }}
                >
                  <div
                    style={{
                      width: "25%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        textAlign: "center",
                        fontWeight: "400",
                        fontSize: "8px",
                        width: "25px",
                        height: "25px",
                        backgroundColor: assignedByUser
                          ? assignedByUser.user_color
                          : null,
                        color: "white",
                        borderRadius: "50%",
                        marginRight: "6px",
                      }}
                    >
                      <p style={{ marginTop: "7px" }}>{assignedByInitials}</p>
                    </div>
                    <p
                      style={{
                        fontWeight: "400",
                        fontSize: "10px",
                        color: "rgba(0,0,0,0.6)",
                      }}
                    >
                      {assignedByFullName}
                    </p>
                  </div>
                  <div
                    style={{
                      marginTop: "50px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "25%",
                      height: "30px",
                      marginBottom: "50px",
                      marginRight: "-10px",
                    }}
                  >
                    <CalendarTodayIcon
                      style={{
                        marginLeft: "0px",
                        marginRight: "5px",
                        color: "rgba(0,0,0,0.4)",
                      }}
                    />
                    <div style={{ width: "80%", fontSize: "10px" }}>
                      <DatePicker
                        style={{ fontSize: "10px" }}
                        selected={new Date(dueDate)}
                        onChange={(date) =>
                          handleChangeDate(new Date(date).toLocaleDateString())
                        }
                        dateFormat="yyyy/MM/dd"
                        className="form-control mui-button"
                        popperPlacement="top-end"
                        placeholderText="Select a date"
                      />
                    </div>
                  </div>
                  <div style={{ width: "25%" }}>
                    <div className="dropdown-container">
                      <button
                        value={status}
                        name="task_status"
                        className="dropdown-toggle"
                        onClick={(event) => {
                          event.preventDefault();
                          // setIsOpen(!isOpen);
                        }}
                        style={{
                          backgroundColor: selectedOption.color,
                          borderRadius: "50px",
                          padding: "8px 20px",
                          border: "none",
                          fontWeight: "600",
                          color: "white",
                          fontSize: "10px",
                        }}
                      >
                        {selectedOption.label || "Select status"}
                      </button>
                      {isOpen && (
                        <div className="dropdown-menu">
                          <div className="dropdown-options">
                            {statusOptions.map((option) => (
                              <div
                                className="dropdown-option"
                                key={option.value}
                                onClick={() => handleOptionClick(option)}
                              >
                                <div
                                  className="dropdown-option-color"
                                  style={{ backgroundColor: option.color }}
                                >
                                  <div className="dropdown-option-label">
                                    {option.label}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="dropdown-custom">
                            <div className="dropdown-custom-status">
                              <input
                                className="custom-input"
                                style={{ backgroundColor: customColor }}
                                type="text"
                                placeholder="Custom status"
                                value={customStatus}
                                onChange={handleCustomStatusChange}
                              />
                            </div>
                            <div className="dropdown-custom-color">
                              <input
                                className="color-input"
                                type="color"
                                value={customColor}
                                onChange={handleCustomColorChange}
                              />
                            </div>
                            <div className="dropdown-custom-commit">
                              <button
                                type="button"
                                style={{
                                  background: props.complementaryColor,
                                  color: "white",
                                }}
                                className="custom-button"
                                onClick={handleCustomStatusCommit}
                              >
                                Create
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "25%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Select
                      sx={{
                        boxShadow: "none",
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      }}
                      style={{
                        width: "100%",
                        height: "50px",
                        border: "0px solid black",
                      }}
                      labelId="assigned-to-label"
                      id="assigned-to-select"
                      multiple
                      value={selectedUsers}
                      onChange={handleSelectChange}
                      onBlur={() => {
                        const newData = userTask.map((item) => {
                          if (item.id === selectedRow.id) {
                            const updatedItem = {
                              ...item,
                              task_assigned_to: selectedUsers,
                            };
                            fetch(
                              `https://ww1.premelo.com/api/update-task/${updatedItem.id}`,
                              {
                                method: "PUT",
                                body: JSON.stringify(updatedItem),
                                headers: {
                                  "Content-Type": "application/json",
                                  Authorization: `Bearer ${token}`,
                                },
                              }
                            )
                              .then((response) => {
                                console.log(response);
                              })
                              .catch((error) => {
                                console.error(error);
                              });
                            return updatedItem;
                          } else {
                            return item;
                          }
                        });
                        setUserTask(newData);
                      }}
                      renderValue={(selected) => {
                        const selectedUsersNames = selected
                          .map((userId) => {
                            const user = availableUsers.find(
                              (user) => user.user_id === userId
                            );
                            return user ? user.fullName : null;
                          })
                          .join(", ");

                        const selectedUsersIds = selected
                          .map((userId) => {
                            const user = availableUsers.find(
                              (user) => user.id === userId
                            );
                            return user ? `-${user.id}-` : null; // Add delimiter to beginning of user id
                          })
                          .join("");
                        console.log(selectedUsersIds);
                        const taskAssignedByUsersInModal =
                          availableUsers.filter((user) =>
                            selectedUsersIds.includes(`-${user.id}-`)
                          );
                        console.log(taskAssignedByUsersInModal);

                        const taskAssignedByInitialsInModal =
                          taskAssignedByUsersInModal.map((user) => ({
                            initials: `${user.first_name.charAt(
                              0
                            )}${user.last_name.charAt(0)}`,
                            color: user.user_color || null,
                          }));

                        console.log(taskAssignedByInitialsInModal);

                        return (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            {taskAssignedByInitialsInModal.map((initials) => (
                              <div
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  borderRadius: "50%",
                                  backgroundColor: initials.color,
                                  marginRight: "-5px",
                                  color: "white",
                                }}
                              >
                                <p
                                  style={{
                                    margin: "2px 0px 0px 7px",
                                    fontSize: "8px",
                                  }}
                                >
                                  {initials.initials}
                                </p>
                              </div>
                            ))}
                            <p
                              style={{
                                fontSize: "10px",
                                marginLeft: "9px",
                                fontWeight: "400",
                                color: "rgba(0,0,0,0.6)",
                              }}
                            >
                              {selectedUsersNames}
                            </p>
                          </div>
                        );
                      }}
                    >
                      {availableUsers.map((user) => (
                        <MenuItem key={user.id} value={user.user_id}>
                          <Checkbox
                            checked={selectedUsers.indexOf(user.user_id) > -1}
                          />
                          <ListItemText primary={user.fullName} />
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <div
                  style={{
                    marginTop: "0px",
                    width: "92%",
                    marginRight: "55px",
                  }}
                >
                  <div
                    style={{
                      fontSize: "10px",
                      color: "rgba(0,0,0,0.5)",
                      marginBottom: "10px",
                    }}
                  >
                    Task Description:
                  </div>
                  <ReactQuill
                    placeholder="What is this task about?"
                    value={taskDescription}
                    onChange={setTaskDescription}
                    style={{ height: "100px", marginTop: "50px" }}
                    onBlur={() => {
                      const newData = userTask.map((item) => {
                        if (item.id === selectedRow.id) {
                          const updatedItem = {
                            ...item,
                            task_description: taskDescription,
                          };
                          fetch(
                            `https://ww1.premelo.com/api/update-task/${updatedItem.id}`,
                            {
                              method: "PUT",
                              body: JSON.stringify(updatedItem),
                              headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${token}`,
                              },
                            }
                          )
                            .then((response) => {
                              console.log(response);
                            })
                            .catch((error) => {
                              console.error(error);
                            });
                          return updatedItem;
                        } else {
                          return item;
                        }
                      });
                      setUserTask(newData);
                    }}
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, false] }],
                        ["bold", "italic", "underline", "strike", "blockquote"],
                        [
                          { list: "ordered" },
                          { list: "bullet" },
                          { indent: "-1" },
                          { indent: "+1" },
                        ],
                        ["link", "image"],
                        ["clean"],
                      ],
                    }}
                    formats={formats}
                    toolbarPlacement="bottom"
                  />
                </div>
                <div
                  style={{
                    textAlign: "left",
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "100%",
                  }}
                >
                  <button
                    className="attachment-button"
                    style={{
                      color: "rgba(0,0,0,0.3)",
                      display: "flex",
                      alignItems: "center",
                      background: "transparent",
                      border: "none",
                      height: "45px",
                      width: "200px",
                      fontSize: "10px",
                    }}
                    type="button"
                    onClick={() => taskFileInput.current.click()}
                  >
                    <AttachFileIcon />
                    Add attachments
                  </button>
                  <input
                    type="file"
                    multiple
                    style={{ display: "none" }}
                    ref={taskFileInput}
                    onChange={handleFileUpload}
                  />
                </div>
                {taskAttachmentUrl && taskAttachmentUrl.length > 0 && (
                  <div
                    className="attachment-scroll"
                    style={{
                      marginTop: "5px",
                      display: "flex",
                      height: "100px",
                      width: "92%",
                      marginRight: "7%",
                    }}
                  >
                    {taskAttachmentUrl.map((attachment, index) => (
                      <div
                        style={{
                          width: "200px",
                          border: "1px solid rgba(0,0,0,0.15)",
                          borderRadius: "5px",
                          marginRight: "10px",
                          padding: "0px 20px",
                        }}
                      >
                        <a
                          key={index}
                          href={attachment.url}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            textAlign: "center",
                            textDecoration: "none",
                          }}
                        >
                          <div style={{ marginTop: "10px" }}>
                            {getFileIcon(attachment.fileName)}
                          </div>
                          <div
                            style={{
                              fontSize: "10px",
                              textDecoration: "none",
                              color: props.primaryColor,
                              marginBottom: "-3px",
                              lineHeight: "12px",
                            }}
                          >
                            {attachment.fileName.slice(0, 12)}
                          </div>
                          <div
                            style={{
                              fontSize: "10px",
                              textDecoration: "none",
                              color: "rgba(0,0,0,0.3)",
                            }}
                          >
                            {attachment.fileName.split(".").pop().toLowerCase()}
                          </div>
                        </a>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </form>
            <div
              style={{
                width: "92%",
                marginRight: "7%",
              }}
            >
              {modalMessages.length > 0
                ? modalMessages.map((message) => {
                    const createdAt = new Date(
                      message.message.created_at
                    ).toLocaleDateString();

                    // Get user details for the message user ID
                    const user = availableUsers.find(
                      (user) => user.user_id === message.message.message_user_id
                    );
                    const nameInitials =
                      user &&
                      user.first_name.charAt(0).toUpperCase() +
                        user.last_name.charAt(0).toUpperCase();

                    const userColor = user && user.user_color;

                    const userFullNameForMessage = user && user.fullName;

                    console.log(nameInitials);
                    return (
                      <div
                        key={message.message.id}
                        style={{
                          borderTop: "1px solid rgba(0,0,0,0.15)",
                          marginBottom: "5px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginTop: "5px",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div
                              style={{
                                background: userColor,
                                marginRight: "5px",
                                width: "25px",
                                height: "25px",
                                textAlign: "center",
                                borderRadius: "50%",
                                color: "white",
                                fontSize: "8px",
                              }}
                            >
                              <p style={{ marginTop: "6px" }}>{nameInitials}</p>
                            </div>
                            <p
                              style={{
                                fontSize: "10px",
                                color: "rgba(0,0,0,0.7)",
                              }}
                            >
                              {userFullNameForMessage}
                            </p>
                          </div>
                          <p
                            style={{
                              fontSize: "8px",
                              color: "rgba(0,0,0,0.4)",
                              marginTop: "12px",
                              marginLeft: "4px",
                            }}
                          >
                            {createdAt}
                          </p>
                        </div>
                        <div style={{ display: "flex", marginTop: "-20px" }}>
                          <div style={{ width: "40px" }} />
                          <div>
                            <p
                              style={{
                                fontSize: "12px",
                                color: "rgba(0,0,0,0.7)",
                              }}
                            >
                              {message.message.message
                                .split(/@\[([\w\s]+)\]\([\w]+\)/g)
                                .map((part, index) => {
                                  if (index % 2 !== 0) {
                                    return (
                                      <span
                                        key={index}
                                        style={{ color: props.primaryColor }}
                                      >
                                        {part}
                                      </span>
                                    );
                                  }
                                  return <span key={index}>{part}</span>;
                                })}
                            </p>
                          </div>
                        </div>
                        <div
                          className="attachment-scroll"
                          style={{ display: "flex" }}
                        >
                          {message.attachments.map((attachment, index) => {
                            return (
                              <div style={{ width: "auto" }}>
                                <a
                                  href={attachment.attachment_url}
                                  target="_blank"
                                  rel="noreferrer"
                                  key={index}
                                  style={{
                                    display: "flex",
                                    height: "20px",
                                    border: "1px solid rgba(0,0,0,0.15)",
                                    padding: "7px 20px",
                                    borderRadius: "5px",
                                    marginRight: "10px",
                                    textDecoration: "none",
                                    textAlign: "left",
                                    alignItems: "center",
                                  }}
                                >
                                  <div style={{ marginTop: "6px" }}>
                                    {getMessageFileIcon(attachment.file_name)}
                                  </div>
                                  <div
                                    style={{
                                      fontSize: "10px",
                                      textDecoration: "none",
                                      color: "rgba(0,0,0,0.5)",
                                      marginLeft: "6px",
                                    }}
                                  >
                                    {attachment.file_name.slice(0, 40)}
                                  </div>
                                </a>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "92%",
          marginRight: "7%",
          borderTop: "1px solid rgba(0,0,0,0.2)",
          padding: "0px 0px 0px 5%",
        }}
      >
        <div
          className="mention-input"
          style={{ marginTop: "10px", fontSize: "10px" }}
          onClick={console.log(availableUsers)}
        >
          <MentionsInput
            singleLine={false}
            style={{
              height: "50px",
              width: "100%",
              suggestions: {
                list: {
                  backgroundColor: "white",
                  border: "1px solid rgba(0,0,0,0.15)",
                  fontSize: 10,
                },
                item: {
                  padding: "5px 15px",
                  borderBottom: "1px solid rgba(0,0,0,0.15)",
                  "&focused": {
                    backgroundColor: props.primaryColor,
                  },
                },
              },
            }}
            value={message}
            onChange={handleMentionChange}
            suggestionsPortalHost={document.body}
          >
            <Mention
              style={{ backgroundColor: props.primaryColor }}
              trigger="@"
              data={availableUsers.map((user) => ({
                id: user.user_id,
                ...user,
                display: user.fullName ? `${user.fullName}` : "",
              }))}
              renderSuggestion={renderSuggestion}
              displayTransform={(id, display) => {
                if (typeof display === "object") {
                  return `@${display.fullName}`;
                }
                return `@${display}`;
              }}
            />
          </MentionsInput>
          <div style={{ display: "flex" }}>
            {attachmentUrl.map((attachment, index) => (
              <div key={index}>
                <div
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    display: "flex",
                    height: "20px",
                    border: "1px solid rgba(0,0,0,0.15)",
                    padding: "7px 20px",
                    borderRadius: "5px",
                    marginRight: "10px",
                    textDecoration: "none",
                    textAlign: "left",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginTop: "6px" }}>
                    {getMessageFileIcon(attachment.fileName)}
                  </div>
                  <a
                    href={attachment.url}
                    style={{
                      fontSize: "10px",
                      textDecoration: "none",
                      color: "rgba(0,0,0,0.5)",
                      marginLeft: "6px",
                    }}
                  >
                    {attachment.fileName.slice(0, 10) +
                      (attachment.fileName.length > 10 ? " ..." : "")}
                  </a>
                  <button
                    className="mui-button"
                    style={{
                      marginLeft: "10px",
                      background: "transparent",
                      color: props.primaryColor,
                      border: `1px solid ${props.primaryColor}`,
                      fontSize: "12px",
                    }}
                    onClick={() => handleRemoveAttachment(index)}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <button
              className="attachment-button"
              style={{
                color: "rgba(0,0,0,0.3)",
                background: "transparent",
                border: "none",
                alignItems: "center",
                display: "flex",
                fontSize: "12px",
              }}
              type="button"
              onClick={() => fileInput.current.click()}
            >
              <AttachFileIcon />
              Add attachments
            </button>
            <input
              type="file"
              multiple
              style={{ display: "none" }}
              ref={fileInput}
              onChange={handleFileInputChange}
            />
            <button
              style={{
                background: props.primaryColor,
                border: "none",
                color: "white",
                borderRadius: "10px",
                padding: "5px 20px",
                fontSize: "12px",
              }}
              onClick={handleAddMessage}
            >
              Comment
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div style={{}}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          background: "rgba(255,255,255,0.7)",
          backdropFilter: "blur(20px)",
          boxShadow: "0px 0px 10px rgba(0,0,0,0.15)",
          padding: "30px 0px 10px 0px",
          position: "relative",
          zIndex: 100,
        }}
      >
        <div
          className="mui-button"
          style={{
            color: props.primaryColor,
            fontSize: "22px",
            fontWeight: "600",
            marginLeft: "20px",
            paddingBottom: "1%",
          }}
        >
          Notification
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          className="to-do-scroll"
          style={{
            width: "50%",
            height: windowHeight * 0.91,
            overflowY: "scroll",
            position: "relative",
            zIndex: 490,
          }}
        >
          {notifications
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
            .map((notification) => {
              const project = userProjects.find(
                (project) => project.id === notification.project_id
              );
              const projectName = project ? project.project_name : "";
              const task = userTask.find(
                (tasks) => tasks.id === notification.task_id
              );
              const taskStatus = task ? task.task_status : "";
              const users = availableUsersForNotification.find(
                (user) => user.user_id === notification.user_id
              );
              console.log("availableusersfornotification:", users);
              const userFullName = users
                ? `${users.first_name} ${users.last_name}`
                : "";

              const notificationDate = new Date(notification.created_at);
              const formattedTime = notificationDate.toLocaleTimeString([], {
                hour: "numeric",
                minute: "2-digit",
              });
              const formattedDate =
                notificationDate.getDate() === new Date().getDate()
                  ? "Today"
                  : `${
                      notificationDate.getMonth() + 1
                    }.${notificationDate.getDate()}.${notificationDate
                      .getFullYear()
                      .toString()
                      .slice(-2)}`;

              return (
                <div
                  style={{
                    borderBottom: "1px solid rgba(0,0,0,0.1)",
                    padding: "0px 20px",
                  }}
                  key={notification.id}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "-10px",
                      }}
                    >
                      <div
                        style={{
                          background: props.complementaryColor,
                          height: "7px",
                          width: "7px",
                          borderRadius: "50%",
                          marginRight: "10px",
                        }}
                      />
                      <div
                        style={{
                          fontSize: "8px",
                          color: "rgba(0,0,0,0.5)",
                          marginTop: "2px",
                        }}
                      >
                        {projectName}
                      </div>
                    </div>
                    <div>
                      {taskStatus ? (
                        <div style={{ display: "flex", marginTop: "4px" }}>
                          <div
                            style={{
                              padding: "6px 30px",
                              borderRadius: "10px",
                              marginTop: "0px",
                              background: `${
                                statusOptions.find(
                                  (option) => option.value === taskStatus
                                )?.color || "transparent"
                              }2A`,
                              color:
                                statusOptions.find(
                                  (option) => option.value === taskStatus
                                )?.color || "transparent",
                            }}
                          >
                            <p
                              style={{
                                margin: "0px",
                                fontWeight: "600",
                                fontSize: "10px",
                              }}
                            >
                              {taskStatus}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div style={{ height: "35px" }}></div>
                      )}
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "13px",
                      fontWeight: "600",
                      color: "rgba(0,0,0,0.7)",
                      marginTop: "-13px",
                    }}
                  >
                    {notification.task_title}
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        backgroundColor: users ? users.user_color : null,
                        color: "white",
                        display: "inline-flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%",
                        marginRight: "5px",
                        fontWeight: "600",
                        fontSize: "16px",
                        border: "2px solid #ffffff",
                        marginRight: "10px",
                      }}
                      key={users ? users.user_id : null}
                    >
                      <p style={{ fontWeight: "400", fontSize: "10px" }}>
                        {users ? users.first_name.charAt(0) : null}
                        {users ? users.last_name.charAt(0) : null}
                      </p>
                    </div>
                    <p
                      style={{
                        fontWeight: "600",
                        color: "rgba(0,0,0,0.7)",
                        fontSize: "13px",
                        marginRight: "8px",
                      }}
                    >
                      {userFullName}
                    </p>
                    <p
                      style={{
                        fontWeight: "400",
                        color: "rgba(0,0,0,0.4)",
                        fontSize: "10px",
                      }}
                    >
                      {notification.notifiable_type}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      marginBottom: "20px",
                      marginTop: "-12px",
                    }}
                  >
                    <div style={{ width: "50px" }} />
                    <div style={{ width: "90%" }}>
                      <p
                        style={{
                          fontSize: "10px",
                          color: "rgba(0,0,0,0.5)",
                          margin: "0px",
                        }}
                      >
                        {notification.message
                          ? (() => {
                              const tempElement = document.createElement("div");
                              tempElement.innerHTML = notification.message;
                              const plainText = tempElement.textContent;
                              return plainText;
                            })()
                          : "No description"}
                        {/* {notification.message} */}
                      </p>
                      <p
                        style={{
                          fontSize: "8px",
                          color: "rgba(0,0,0,0.5)",
                          margin: "10px 0px 0px 0px",
                        }}
                      >
                        {formattedDate} {formattedTime}
                        {notificationDate.getHours() >= 12}
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "right",
                      marginTop: "-30px",
                      marginBottom: "10px",
                    }}
                  >
                    <OpenInNewIcon
                      className="mui-button"
                      style={{ fontSize: "30px", color: "rgba(0,0,0,0.2)" }}
                      onClick={() => handleTaskViewClick(task)}
                    />
                  </div>
                </div>
              );
            })}
        </div>
        <div
          style={{
            width: "50%",
            height: windowHeight * 0.91,
            background: "rgba(255,255,255,0.7)",
            backdropFilter: "blur(20px)",
            position: "relative",
            zIndex: 490,
          }}
        >
          {selectedRow ? (
            <div>{taskFormModalContent}</div>
          ) : (
            <div
              style={{
                marginTop: "45%",
                fontSize: "15px",
                color: "rgba(0,0,0,0.4)",
                alignItems: "center",
                textAlign: "center",
              }}
            >
              No notification selected
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default NotificationContent;
